import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import withStyles from '@mui/styles/withStyles';
import { colors } from '@shield-ui/styles';
import { TextField } from '@shield-ui/controls';
import isValid from 'date-fns/isValid';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';

function styles(theme) {
  return {
    root: {
      margin: theme.spacing(0, 0.5),
      backgroundColor: colors.semantic.inputBackground,
      width: '100%',
      textAlign: 'center',
      fontSize: '0.8em',
      borderRadius: 3,
      padding: '6px 14px',
      textTransform: 'uppercase',
      border: `1px solid ${colors.semantic.inputBorder}`,
      transition: '0.2s ease',
      '&:hover': {
        borderColor: colors.semantic.inputBorderHover,
      },
    },
    focused: {
      border: `1px solid ${colors.semantic.inputBorderFocus}`,
    },
  };
}

class DateFromDateTimeSecondsInput extends React.PureComponent {
  onChange = (e) => {
    const { onChange, dateTimeBase, dateRange = {} } = this.props;
    const value = e.target.value;
    const seconds = parseInt(value, 10);

    if (!value || !_.isNumber(seconds)) {
      onChange({
        value: undefined,
      });
      return;
    }

    const workingDate = new Date(dateTimeBase);
    workingDate.setSeconds(workingDate.getSeconds() + seconds);
    const dateValue = workingDate.toISOString();

    // ensure date range maybe
    const startDate = new Date(dateRange.gte);
    const endDate = new Date(dateRange.lte);
    if (
      isValid(startDate) &&
      differenceInMilliseconds(workingDate, startDate) < 0
    ) {
      onChange({
        value: dateRange.gte,
      });
      return;
    } else if (
      isValid(endDate) &&
      differenceInMilliseconds(endDate, workingDate) < 0
    ) {
      onChange({
        value: dateRange.lte,
      });
      return;
    }

    onChange({
      value: dateValue,
    });
  };

  render() {
    const { value, placeholder, onBlur, dateTimeBase } = this.props;

    let inputValue = '';
    if (value && dateTimeBase) {
      inputValue = moment(value).diff(moment(dateTimeBase), 'seconds');
    }

    return (
      <TextField
        onChange={this.onChange}
        type="number"
        placeholder={placeholder}
        value={inputValue}
        onBlur={onBlur}
      />
    );
  }
}
DateFromDateTimeSecondsInput.propTypes = {
  dateTimeBase: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  dateRange: PropTypes.object,
  value: PropTypes.string,
  onChange: PropTypes.func,
};
DateFromDateTimeSecondsInput.defaultProps = {
  placeholder: 'Seconds',
};

export default withStyles(styles)(DateFromDateTimeSecondsInput);
