import React from 'react';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from '@shield-ui/styles';

const useStyles = makeStyles(
  () => {
    const bgSize = 3;
    const gapSize = 12;

    return {
      container: {
        width: '100%',
        height: '100%',
        position: 'relative',
      },
      horz: {
        position: 'absolute',
        width: '100%',
        left: 0,
        top: `calc(50% - ${bgSize / 4}px)`,
        height: bgSize / 2,
        background: `rgba(255, 255, 255, 0.1)`,
        transition: '.1s ease',
      },
      vert: {
        position: 'absolute',
        height: '100%',
        top: 0,
        left: `calc(50% - ${bgSize / 4}px)`,
        width: bgSize / 2,
        background: `rgba(255, 255, 255, 0.1)`,
        transition: '.1s ease',
      },
      horzActive: {
        background: colors.hues.blues['80'],
        top: `calc(50% - ${bgSize / 2}px)`,
        height: bgSize,
      },
      vertActive: {
        background: colors.hues.blues['80'],
        left: `calc(50% - ${bgSize / 2}px)`,
        width: bgSize,
      },
      vertBottomOnly: {
        height: '50%',
        top: '50%',
      },
      vertTopOnly: {
        height: '50%',
        top: 0,
      },
      horzRightOnly: {
        width: '50%',
        left: '50%',
      },
      horzLeftOnly: {
        width: '50%',
        left: 0,
      },
      content: {
        background: 'black',
        position: 'relative',
        width: `calc(100% - ${gapSize * 2}px)`,
        height: `calc(100% - ${gapSize * 2}px)`,
        zIndex: 2,
        top: gapSize,
        left: gapSize,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    };
  },
  {
    name: 'cell-background',
  }
);

export interface CellBackgroundProps extends React.HTMLProps<HTMLDivElement>{
  horzDisplay?: 'both' | 'none' | 'leftOnly' | 'rightOnly';
  vertDisplay?: 'both' | 'none' | 'topOnly' | 'bottomOnly';
  selectedHorz?: boolean;
  selectedVert?: boolean;
}

export const CellBackground: React.FC<CellBackgroundProps> = (props) => {
  const {
    horzDisplay = 'both',
    vertDisplay = 'both',
    selectedHorz,
    selectedVert,
    ...rest
  } = props;

  const classes = useStyles();
  return (
    <div {...rest} className={classes.container}>
      <div className={classes.content}>{props.children}</div>
      {horzDisplay !== 'none' && (
        <div
          className={cx(
            classes.horz,
            selectedHorz && classes.horzActive,
            horzDisplay === 'leftOnly' && classes.horzLeftOnly,
            horzDisplay === 'rightOnly' && classes.horzRightOnly
          )}
        />
      )}
      {vertDisplay !== 'none' && (
        <div
          className={cx(
            classes.vert,
            selectedVert && classes.vertActive,
            vertDisplay === 'topOnly' && classes.vertTopOnly,
            vertDisplay === 'bottomOnly' && classes.vertBottomOnly
          )}
        />
      )}
    </div>
  );
};

export default CellBackground;
