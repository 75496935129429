import { createActionPrefix } from 'redux-nano';

// Action Constants
const prefix = createActionPrefix('SNACKBAR');
export const SHOW_ERROR = prefix('SHOW_ERROR');
export const SHOW_SUCCESS = prefix('SHOW_SUCCESS');
export const RESOLVE_SNACK_MESSAGES = prefix('RESOLVE_SNACK_MESSAGES');

export const showErrorSnack = SHOW_ERROR;
export const showSuccessSnack = SHOW_SUCCESS;
export const resolveSnackMessages = RESOLVE_SNACK_MESSAGES;
