import { useState, useEffect, useCallback } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Grow } from '@shield-ui/core';
import { useCachedState } from '@shield-ui/hooks';
import {
  HeatGrid,
  Cell,
  LabelType,
  ResultType,
  RenderCellArgs,
  RenderLabelCellArgs,
  OnSelectionActionArgs,
} from '@shield-ui/heatgrid';
import _ from 'lodash';
import { showError } from '../../lib/messages';
import {
  AxisDefinition,
  AxisOption,
  allAxisDefinitions,
  testDefinitionCodeAxisDefinition,
  dateHistogramAxisDefinition,
} from './AxisDefinitions';
import {
  fetchHeatgridData,
  calcCellBackground,
  calcCellLabel,
  getTotalEvaluationCount,
  getReliabilityFailureCount,
  QueryType,
  reliabilityOrderMap,
} from './utils';
import AxisDropdownChip from './AxisDropdownChip/AxisDropdownChip';
import { DateTypes } from './common';
import {
  CellDropdownChip,
  CellFormValues,
  initialValues as defaultCellOptions,
} from './CellDropdownChip/CellDropdownChip';
import { prettifyString } from '@shield-ui/utils';
import tracker from '../../lib/tracker';

const useStyles = makeStyles((theme) => ({
  control: {
    display: 'flex',
    marginTop: 10,
    marginBottom: 16,
  },
  dropdownChip: {
    marginLeft: 10,
    marginRight: 10,
  },
}));

export interface HeatmapProps {
  query: QueryType;
  filterValues: object;
  setFilterValues: (values: object) => void;
  jumpToContent: (args: {
    contentPath: string;
    filterValues: object;
    setGoBack: boolean;
    goBackLabel: string;
  }) => void;
}

function Heatmap(p: HeatmapProps) {
  const classes = useStyles();
  // Default x-axis config
  const [xAxisDefinitionId, setXAxisDefinitionId] = useCachedState(
    'Heatmap-xAxisDefinitionId',
    testDefinitionCodeAxisDefinition.id
  );
  const [xAxisOption, setXAxisOption] = useCachedState(
    'Heatmap-xAxisOption',
    undefined as AxisOption
  );
  // Default y-axis config
  const [yAxisDefinitionId, setYAxisDefinitionId] = useCachedState(
    'Heatmap-yAxisDefinitionId',
    dateHistogramAxisDefinition.id
  );
  const [yAxisOption, setYAxisOption] = useCachedState('Heatmap-yAxisOption', {
    dateType: DateTypes.Week,
  } as AxisOption);
  // Default cell config
  const [cellOption, setCellOption] = useCachedState<CellFormValues>(
    'Heatmap-cellOptions',
    defaultCellOptions
  );
  // Rendered cell labels
  const [xAxisLabels, setXAxisLabels] = useState<LabelType[]>([]);
  const [yAxisLabels, setYAxisLabels] = useState<LabelType[]>([]);
  // Evaluation states per x and y axis
  const [cellData, setCellData] = useState<ResultType[]>([]);
  // Is heatmap ready to be displayed?
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const xAxisDefinition = allAxisDefinitions[xAxisDefinitionId as string];
  const yAxisDefinition = allAxisDefinitions[yAxisDefinitionId as string];

  const handleChangeAxisX = (
    axisDefinition: AxisDefinition,
    axisOption?: AxisOption
  ) => {
    setXAxisDefinitionId(axisDefinition.id);
    if (axisOption) {
      setXAxisOption(axisOption);
    }
    tracker.trackEvent(
      'Heatgrid',
      'SetAxis',
      'X',
      axisDefinition.getDropdownLabel(axisOption)
    );
  };

  const handleChangeAxisY = (
    axisDefinition: AxisDefinition,
    axisOption?: AxisOption
  ) => {
    setYAxisDefinitionId(axisDefinition.id);
    if (axisOption) {
      setYAxisOption(axisOption);
    }
    tracker.trackEvent(
      'Heatgrid',
      'SetAxis',
      'Y',
      axisDefinition.getDropdownLabel(axisOption)
    );
  };

  const handleChangeCellOption = (values: CellFormValues) => {
    setCellOption(values);
    tracker.trackEvent('Heatgrid', 'SetCellOption', 'Values', values);
  };

  useEffect(() => {
    setIsLoaded(false);

    fetchHeatgridData(
      xAxisDefinition,
      yAxisDefinition,
      xAxisOption,
      yAxisOption,
      p.query,
      p.filterValues
    )
      .then((results) => {
        xAxisDefinition
          .prepareAxisLabels(
            _.uniq(results.map((result) => result.xLabelId)),
            xAxisOption as AxisOption
          )
          .then((axisLabels) => {
            setXAxisLabels(axisLabels);
          });

        yAxisDefinition
          .prepareAxisLabels(
            _.uniq(results.map((result) => result.yLabelId)),
            yAxisOption as AxisOption
          )
          .then((axisLabels) => {
            setYAxisLabels(axisLabels);
          });

        setCellData(results);
        setIsLoaded(true);
      })
      .catch(showError);
  }, [p.query, xAxisDefinitionId, yAxisDefinitionId, xAxisOption, yAxisOption]);

  const getCellLabel = useCallback(
    (result: ResultType) => {
      return calcCellLabel(result, cellOption);
    },
    [cellOption]
  );

  const getCellBackground = useCallback(
    (result: ResultType) => {
      return calcCellBackground(result, cellOption);
    },
    [cellOption]
  );

  const handleClickApplySelectedAsFilters = (args: OnSelectionActionArgs) => {
    let newFilterValues = _.cloneDeep(p.filterValues || {});
    let selectedXLabels = args.selectedXLabels;
    let selectedYLabels = args.selectedYLabels;

    if (xAxisDefinition.id === yAxisDefinition.id) {
      selectedXLabels = selectedYLabels = _.unionBy(
        selectedXLabels,
        selectedYLabels,
        (label) => label.id
      );
    }

    if (args.selectedXLabels.length > 0) {
      newFilterValues = xAxisDefinition.buildQueryFilter(
        newFilterValues,
        selectedXLabels,
        xAxisOption
      );
    }
    if (args.selectedYLabels.length > 0) {
      newFilterValues = yAxisDefinition.buildQueryFilter(
        newFilterValues,
        selectedYLabels,
        yAxisOption
      );
    }
    p.setFilterValues(newFilterValues);

    tracker.trackEvent(
      'Heatgrid',
      'ApplySelectedAsFilters',
      'SelectedAxisLabels',
      {
        x: {
          labels: selectedXLabels.map((label) => label.label),
          axisOption: xAxisOption,
        },
        y: {
          labels: selectedYLabels.map((label) => label.label),
          axisOption: yAxisOption,
        },
      }
    );
  };

  const handleClickSeeResultsForCell = (contentData: ResultType) => {
    let newFilterValues = _.cloneDeep(p.filterValues || {});
    newFilterValues = xAxisDefinition.buildQueryFilter(
      newFilterValues,
      [contentData.xLabelId].map((id) => ({
        id,
        label: "Doesn't matter",
      })),
      xAxisOption
    );
    newFilterValues = yAxisDefinition.buildQueryFilter(
      newFilterValues,
      [contentData.yLabelId].map((id) => ({
        id,
        label: "Doesn't matter",
      })),
      yAxisOption
    );
    p.jumpToContent({
      filterValues: newFilterValues,
      contentPath: '/results',
      setGoBack: true,
      goBackLabel: 'Back to HeatGrid',
    });

    tracker.trackEvent('Heatgrid', 'SeeResultsForCell', 'SelectedAxisLabels', {
      x: {
        labelId: contentData.xLabelId,
        axisOption: xAxisOption,
      },
      y: {
        labelId: contentData.yLabelId,
        axisOption: yAxisOption,
      },
    });
  };

  const renderCellTooltip = (result: ResultType) => {
    const { data } = result;
    const totalCount = getTotalEvaluationCount(data);
    return (
      <div>
        <table cellSpacing={0} cellPadding={5}>
          <tr>
            <th>Evaluation State</th>
            <th>Count (%) </th>
            <th>Reliability (%)</th>
          </tr>
          {data.map((d) => {
            const reliabilityFailureCount = getReliabilityFailureCount(
              data,
              d.evaluationState
            );
            const reliabilityCount = totalCount - reliabilityFailureCount;
            return (
              <tr>
                <td>{prettifyString(d.evaluationState)}</td>
                <td>
                  {`${d.count} (${Math.floor(100 * (d.count / totalCount))}%)`}
                </td>
                <td>
                  {reliabilityOrderMap[d.evaluationState] === undefined
                    ? '-'
                    : `${reliabilityCount} (${Math.floor(
                        100 * (reliabilityCount / totalCount)
                      )}%)`}
                </td>
              </tr>
            );
          })}
          <tr>
            <td>Total</td>
            <td>{totalCount}</td>
            <td>-</td>
          </tr>
        </table>
      </div>
    );
  };

  return (
    <>
      <div className={classes.control}>
        <AxisDropdownChip
          className={classes.dropdownChip}
          title="x-axis"
          label={xAxisDefinition.getDropdownLabel(xAxisOption as AxisOption)}
          selectedAxisDefinition={xAxisDefinition}
          selectedAxisOption={xAxisOption as AxisOption}
          onChange={handleChangeAxisX}
        />
        <AxisDropdownChip
          className={classes.dropdownChip}
          title="y-axis"
          label={yAxisDefinition.getDropdownLabel(yAxisOption as AxisOption)}
          selectedAxisDefinition={yAxisDefinition}
          selectedAxisOption={yAxisOption as AxisOption}
          onChange={handleChangeAxisY}
        />
        <CellDropdownChip
          value={cellOption}
          onChange={handleChangeCellOption}
        />
        <Grow />
      </div>
      <div>
        <HeatGrid
          /* little hack so things deselect in between re-renders */
          key={isLoaded ? 1 : 0}
          isLoading={!isLoaded}
          xLabels={xAxisLabels}
          yLabels={yAxisLabels}
          results={cellData}
          width="calc(100vw - 160px)"
          height="calc(100vh - 340px)"
          selectionProps={{
            primaryAction: {
              label: 'Apply Selected as Filters',
              onClick: handleClickApplySelectedAsFilters,
            },
          }}
          renderLabelCell={(args: RenderLabelCellArgs) => {
            const axisDef =
              args.axis === 'x' ? xAxisDefinition : yAxisDefinition;
            const labelCellProps = axisDef.labelCellProps || {};
            return <Cell {...args.cellProps} {...labelCellProps} />;
          }}
          renderCell={(args: RenderCellArgs) => (
            <Cell
              {...args.cellProps}
              label={getCellLabel}
              background={getCellBackground}
              actions={[
                {
                  label: 'See Results for Cell',
                  onClick: handleClickSeeResultsForCell,
                },
              ]}
              renderTooltip={renderCellTooltip}
            />
          )}
        />
      </div>
    </>
  );
}

export default Heatmap;
