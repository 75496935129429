import { MultiselectQueryFilter } from '@shield-ui/query-filters';
import {fetchEnvironmentOptions} from "../../../services/environments";


export default new MultiselectQueryFilter({
  id: 'testDefinitionEnvironments',
  controlLabel: 'Test Environments',
  esField: 'test_definition.environments.id',
  description: '',
  isMulti: true,
  behaviorConfig: {
    fetchOptions: fetchEnvironmentOptions
  },
});
