import sdkClient from '../../../../lib/hmdsdkClient';
import * as google_protobuf_struct_pb from 'google-protobuf/google/protobuf/struct_pb';
import {
  RobotLogDocumentService,
  PassthruSearchRequest,
} from '@hmd/sdk/api/search/robotlogdocument/v1';

const configResponse = (response, metric_name, plotOptions) => {
  const { secondBreakdown, groupByTime } = plotOptions;
  const apiData = {};
  response.aggregations[groupByTime].buckets.forEach((timeBucket) => {
    timeBucket[secondBreakdown].buckets.forEach((bucket) => {
      if (isFinite(bucket[metric_name].q1)) {
        if (apiData[`${bucket.key}`]) {
          apiData[`${bucket.key}`]['q1'].push(bucket[metric_name].q1);
          apiData[`${bucket.key}`]['median'].push(bucket[metric_name].q2);
          apiData[`${bucket.key}`]['q3'].push(bucket[metric_name].q3);
          apiData[`${bucket.key}`]['lowerfence'].push(bucket[metric_name].min);
          apiData[`${bucket.key}`]['upperfence'].push(bucket[metric_name].max);
          apiData[`${bucket.key}`]['x'].push(timeBucket.key_as_string);
        } else {
          apiData[`${bucket.key}`] = {
            q1: [bucket[metric_name].q1],
            median: [bucket[metric_name].q2],
            q3: [bucket[metric_name].q3],
            lowerfence: [bucket[metric_name].min],
            upperfence: [bucket[metric_name].max],
            x: [timeBucket.key_as_string],
          };
        }
      }
    });
  });

  return createPlotlyConfigs(apiData);
};

const configAllTimeResponse = (response, metric_name, plotOptions) => {
  const { secondBreakdown } = plotOptions;
  const apiData = {};
  response.aggregations[secondBreakdown].buckets.forEach((bucket) => {
    if (isFinite(bucket[metric_name].q1)) {
      if (apiData[`${bucket.key}`]) {
        apiData[`${bucket.key}`]['q1'].push(bucket[metric_name].q1);
        apiData[`${bucket.key}`]['median'].push(bucket[metric_name].q2);
        apiData[`${bucket.key}`]['q3'].push(bucket[metric_name].q3);
        apiData[`${bucket.key}`]['lowerfence'].push(bucket[metric_name].min);
        apiData[`${bucket.key}`]['upperfence'].push(bucket[metric_name].max);
      } else {
        apiData[`${bucket.key}`] = {
          q1: [bucket[metric_name].q1],
          median: [bucket[metric_name].q2],
          q3: [bucket[metric_name].q3],
          lowerfence: [bucket[metric_name].min],
          upperfence: [bucket[metric_name].max],
        };
      }
    }
  });
  return createPlotlyConfigs(apiData);
};

const createPlotlyConfigs = (apiData) => {
  const plotlyData = [];
  for (const key in apiData) {
    plotlyData.push({
      name: key,
      q1: apiData[key]['q1'],
      median: apiData[key]['median'],
      q3: apiData[key]['q3'],
      lowerfence: apiData[key]['lowerfence'],
      upperfence: apiData[key]['upperfence'],
      ...(apiData[key]['x'] && { x: apiData[key]['x'] }),
      type: 'box',
      showlegend: true,
    });
  }
  return plotlyData;
};

export default function fetchData(query, plotConfig): Promise<object[]> {
  const { plotOptions } = plotConfig;
  const { secondBreakdown, groupByTime } = plotOptions;
  const metric_name = plotConfig.plotOptions.metricAnalysis.value;

  const dict = {
    'software_info.commit_hash': 'software_info.commit_hash',
    'software_info.branch_name': 'software_info.branch_name',
    'phones.name': 'phones.name.keyword',
    'robots.name': 'robots.name.keyword',
    'test_definition.code': 'test_definition.code.keyword',
  };
  const req = new PassthruSearchRequest();
  const aggChunk = {
    [`${secondBreakdown}`]: {
      terms: {
        field: dict[secondBreakdown],
      },
      aggs: {
        [`${metric_name}`]: {
          boxplot: {
            field: metric_name,
          },
        },
      },
    },
  };
  const payload =
    groupByTime === 'allTime'
      ? { query, aggs: aggChunk }
      : {
          query,
          aggs: {
            [groupByTime]: {
              date_histogram: {
                field: 'created_at',
                calendar_interval: groupByTime,
              },
              aggs: aggChunk,
            },
          },
        };
  const value = google_protobuf_struct_pb.Struct.fromJavaScript(payload);
  req.setBody(value);
  return sdkClient
    .unary(RobotLogDocumentService.PassthruSearch, req)
    .then((res) => {
      const response = res.getResponse().toJavaScript();

      //get response and create easy access hashmap
      const data =
        groupByTime === 'allTime'
          ? configAllTimeResponse(response, metric_name, plotOptions)
          : configResponse(response, metric_name, plotOptions);
      return data;
    })
    .catch
    //(e) => console.error(e)
    ();
}
