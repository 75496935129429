import React, { cloneElement } from 'react';
import cx from 'classnames';
import withStyles from '@mui/styles/withStyles';
import { NoRobot, NoFlight } from '@shield-ui/icons';

const Icon = ({ name, className }) => {
  const importedIcons = {
    noFlight: <NoFlight />,
    noDrone: <NoRobot />,
  };
  const defaultIcon = <NoRobot />;
  const selectedIcon = importedIcons[name] || defaultIcon;
  const stylizedSelectedIcon = cloneElement(selectedIcon, { className });
  return stylizedSelectedIcon;
};

const styles = (theme) => ({
  container: {
    padding: '2em',
    pointerEvents: 'none',
    width: '100%',
    height: '60%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    width: '127px',
  },
  textContainer: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    marginTop: theme.spacing(3),
    padding: theme.spacing(1, 2),
  },
});

export default withStyles(styles)(Empty);
function Empty({ children, classes, iconName, ...rest }) {
  return (
    <div {...rest} className={cx(classes.container, rest.className)}>
      {iconName && <Icon className={classes.icon} name={iconName} />}
      <div className={classes.textContainer}>{children}</div>
    </div>
  );
}
