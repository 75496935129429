import React, { useCallback } from 'react';
import { Select } from '@shield-ui/controls';
import makeStyles from '@mui/styles/makeStyles';
import { useEnsureListsAreLoaded } from '../../../redux/lists/util';
import ShieldAvatar from '../../users/ShieldAvatar';
import { useTeamParentOptions } from '../../../services/teams';

const useStyles = makeStyles((theme) => ({
  icon: {
    width: 24,
    height: 24,
    fontSize: 13,
  },
  label: {
    paddingLeft: theme.spacing(1),
  },
}));

export default function TeamSelect(props) {
  useEnsureListsAreLoaded();
  const classes = useStyles();
  const {data: teamParentOptions} = useTeamParentOptions();

  const getLabel = useCallback(
    (opt) => {
      return (
        <div>
          <ShieldAvatar className={classes.icon} team={{ name: opt.label }} />
          <span className={classes.label}>{opt.label}</span>
        </div>
      );
    },
    [classes]
  );

  return (
    <Select
      suggestions={teamParentOptions}
      getOptionLabel={getLabel}
      {...props}
    />
  );
}


