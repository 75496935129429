import { createActionPrefix } from 'redux-nano';
import {
  addToMyFavoriteCollections,
  removeFromMyFavoriteCollections,
} from '../../services/authUser';
import { showErrorSnack } from '../snackbar/actions';
import { refreshCollectionForPage } from '../collection/actions';

const prefix = createActionPrefix('AUTH');
export const SET_CURRENT_USER = prefix('SET_CURRENT_USER');
export const SET_COLLECTION_IS_FAVORITE = prefix('SET_COLLECTION_IS_FAVORITE');

export const setCurrentUser = SET_CURRENT_USER;

export function addFavoriteCollection({ collectionId }) {
  return function dispatchAddFavoriteCollection(dispatch) {
    dispatch(SET_COLLECTION_IS_FAVORITE({ collectionId, isFavorite: true }));

    addToMyFavoriteCollections({ collectionIds: [collectionId] }, (err) => {
      if (err) {
        dispatch(showErrorSnack(err));
        dispatch(
          SET_COLLECTION_IS_FAVORITE({ collectionId, isFavorite: false })
        );
      } else {
        if (window.location.href.indexOf(collectionId) > -1) {
          dispatch(refreshCollectionForPage({ collectionId }));
        }
      }
    });
  };
}

export function removeFavoriteCollection({ collectionId }) {
  return function dispatchRemoveFavoriteCollection(dispatch) {
    dispatch(SET_COLLECTION_IS_FAVORITE({ collectionId, isFavorite: false }));

    removeFromMyFavoriteCollections(
      { collectionIds: [collectionId] },
      (err) => {
        if (err) {
          dispatch(showErrorSnack(err));
          dispatch(
            SET_COLLECTION_IS_FAVORITE({ collectionId, isFavorite: true })
          );
        } else {
          if (window.location.href.indexOf(collectionId) > -1) {
            dispatch(refreshCollectionForPage({ collectionId }));
          }
        }
      }
    );
  };
}
