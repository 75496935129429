import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import ParentRow from './ParentRow';
import ChildRow from './ChildRow';
import Event from './Event';
import AddUpdateEventForm from './AddUpdateEventForm';
import { HangarEventType } from '@shield-ui/hangar-service';
//import { Stethoscope } from '@shield-ui/icons';
import { ContainerProps } from './containerEventManager';
import { Props as ChildRowDetailsProps } from './ChildRowDetails';

interface Props
  extends Pick<ContainerProps, 'robotLog' | 'currentUser' | 'deleteEvent'>,
    Pick<ChildRowDetailsProps, 'timeCodeOnClick'> {
  userEvents: HangarEventType[];
}

const useStyles = makeStyles((theme) => ({
  diagnoseButton: {
    marginRight: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
  buttonOverride: {
    fontSize: 14,
    padding: theme.spacing(1, 3),
  },
}));

function CurrentUserEventGroup(props: Props) {
  const { userEvents = [], robotLog, currentUser } = props;
  const [isExpanded, setIsExpanded] = useState(true);
  const [isAdding, setIsAdding] = useState(false);
  const classes = useStyles();

  const variableProps = {
    onClick: undefined,
    isCurrentUserRow: true,
    EndNode: undefined,
  };

  if (userEvents.length && !isAdding) {
    variableProps.onClick = () => setIsExpanded(!isExpanded);
  }

  if (!isAdding) {
    variableProps.EndNode = (
      <>
        {/*
        <Button
          className={cx(classes.diagnoseButton, classes.buttonOverride)}
          onClick={(evt) => {
            evt.stopPropagation();
            evt.preventDefault();
          }}
        >
          <Stethoscope className={classes.icon} /> Diagnose
        </Button>
        */}
        <Button
          className={classes.buttonOverride}
          color="primary"
          variant="contained"
          onClick={(evt) => {
            evt.stopPropagation();
            evt.preventDefault();
            setIsAdding(true);
            setIsExpanded(true);
          }}
        >
          Add
        </Button>
      </>
    );
  } else {
    variableProps.EndNode = (
      <Button
        className={classes.buttonOverride}
        color="secondary"
        onClick={(evt) => {
          evt.stopPropagation();
          evt.preventDefault();
          setIsAdding(false);
        }}
      >
        Done
      </Button>
    );
  }

  return (
    <ParentRow
      isExpanded={isExpanded}
      label={currentUser.name}
      avatarUser={currentUser}
      count={userEvents.length}
      isCurrentUserRow
      {...variableProps}
    >
      {isAdding && (
        <ChildRow isExpanded>
          <AddUpdateEventForm robotLog={robotLog} />
        </ChildRow>
      )}
      {userEvents.map((event) => {
        return <Event {...props} key={event.id} event={event} />;
      })}
    </ParentRow>
  );
}

export default CurrentUserEventGroup;
