import React, { createRef, Component, RefObject, ChangeEvent } from 'react';
import cx from 'classnames';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Theme } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { colors } from '@shield-ui/styles';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(1, 2),
      backgroundColor: 'transparent',
      borderRadius: theme.shape.borderRadius,
      border: `1px solid  ${colors.hues.grays[150]}`,
      transition: '0.1s ease',
      cursor: 'pointer',
      userSelect: 'none',
      '&:hover': {
        borderColor: colors.hues.grays[140],
        backgroundColor: colors.hues.grays[190],
      },
    },
    containerDisabled: {
      cursor: 'not-allowed',
      color: colors.hues.grays[50],
      background: 'rgba(255, 255, 255, 0.1)',
      '&:hover': {
        borderColor: colors.hues.grays[150],
        background: 'rgba(255, 255, 255, 0.1)',
      },
    },
    icon: {
      fontSize: 16,
      color: colors.hues.grays[110],
      marginRight: theme.spacing(1),
    },
    input: {
      display: 'none',
    },
  });

export interface Props
  extends React.HTMLProps<HTMLInputElement>,
    WithStyles<typeof styles> {
  onFiles: (files: File[], event: ChangeEvent) => void;
  showPrefixIcon?: boolean;
}

class FileInput extends Component<Props> {
  input: RefObject<HTMLInputElement> = createRef();

  inputOnChange = (event) => {
    this.props.onFiles(Array.from(this.input.current.files), event);
  };

  onClick = () => {
    this.input.current.click();
  };

  render() {
    const {
      classes,
      className,
      disabled,
      children,
      showPrefixIcon,
      onFiles,
      ...rest
    } = this.props;
    return (
      <div
        className={cx(
          classes.container,
          className,
          disabled && classes.containerDisabled
        )}
        onClick={this.onClick}
      >
        {showPrefixIcon && <FileCopyIcon className={classes.icon} />}
        <div>{children}</div>
        <input
          ref={this.input}
          className={classes.input}
          onChange={this.inputOnChange}
          multiple
          type="file"
          alt="FileInput"
          autoComplete="off"
          tabIndex={-1}
          disabled={disabled}
          {...rest}
        />
      </div>
    );
  }
}
export default withStyles(styles)(FileInput);
