import { Artifact } from '@hmd/sdk/api/artifacts/v1';
import { MetadataView } from '../../components/metadata/MetadataView';
import { useMemo, useState } from 'react';
import { structValueToBasicStructure } from '../../services/passThruSearch';
import {
  useSessionLogIdsByArtifactId,
  updateArtifactRuleSets,
} from '../../services/newArtifacts';
import { colors } from '@shield-ui/styles';
import { makeStyles } from '@mui/styles';
import SectionHeader from '../../components/SectionHeader';
import { SessionLogLinks } from '../../components/SessionLogLinks';
import { getLocalDateTime } from '../../lib/connectedUtils';
import {
  getStorageTier,
  useStorageTierRuleSets,
} from '../../services/storageTierRuleSets';
import { FormControl, Select, MenuItem, Button } from '@mui/material';
import { getDaysFromSecondsString } from '../../lib/connectedUtils';

const useStyles = makeStyles((theme) => ({
  rowLabel: {
    display: 'table-cell',
    paddingRight: theme.spacing(2.5),
    fontWeight: 400,
    color: colors.hues.grays[60],
    paddingBottom: theme.spacing(1),
    width: 220,
  },
  rowValue: {
    display: 'table-cell',
    fontWeight: 500,
    wordBreak: 'break-word',
  },
  tableView: {
    padding: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  button: {
    paddingLeft: theme.spacing(1),
  },
  ruleSet: {
    display: 'flex',
    alignItems: 'center',
  },
}));

type Props = {
  artifact: Artifact.AsObject;
  sessionLogId: string;
};

const findStorageTierRuleSetFromSets = (
  storageTierRuleSets,
  storageTierRuleSetSlug
) => {
  return storageTierRuleSets.find(
    (ruleSet) => ruleSet.slug === storageTierRuleSetSlug
  );
};

const getStorageTierPreview = (storageTierRule, artifact) => {
  const { storageTierRulesList } = storageTierRule;
  const frontDivider = (index) => (index === 0 ? '' : ' | ');

  return (
    <>
      {storageTierRulesList.map((rule, index) => {
        if (
          storageTierRulesList[index - 1] &&
          storageTierRulesList[index - 1].operation === artifact.storageTier
        ) {
          //next rule after current active rule code
          const createdAtTime = new Date(
            artifact.lifeCycleDetails.createTime.seconds * 1000
          );
          const today = new Date();
          const timeUntilNextTier =
            rule.timedelta - (today.getTime() - createdAtTime.getTime()) / 1000;
          return (
            frontDivider(index) +
            `${getStorageTier(rule.operation)} (${getDaysFromSecondsString(
              rule.timedelta
            )} in ${getDaysFromSecondsString(timeUntilNextTier)})`
          );
        } else if (rule.operation === artifact.storageTier) {
          //active rule code
          return (
            <b>
              {frontDivider(index) +
                `${getStorageTier(rule.operation)} (${getDaysFromSecondsString(
                  rule.timedelta
                )})`}
            </b>
          );
        } else {
          //inactive rule code
          return (
            frontDivider(index) +
            `${getStorageTier(rule.operation)} (${getDaysFromSecondsString(
              rule.timedelta
            )})`
          );
        }
      })}
    </>
  );
};

export const ArtifactExtraInfo = ({ artifact, sessionLogId }: Props) => {
  const classes = useStyles();
  const metadata = useMemo(
    () => structValueToBasicStructure(artifact.metadata),
    [artifact]
  );

  const { data: sessionLogs = [], error } = useSessionLogIdsByArtifactId([
    artifact.id,
  ]);

  const { isLoading: isRuleSetsLoading, data: storageTierRuleSets = [] } =
    useStorageTierRuleSets();

  const { mutate: updateArtifactRuleSet } = updateArtifactRuleSets();

  const [selectedRuleSetSlug, setSelectedRuleSetSlug] = useState<string>(
    artifact.storageTierRuleSetSlug
  );

  const handleRuleSetSave = () => {
    updateArtifactRuleSet({
      artifactId: artifact.id,
      newRuleSetSlug: selectedRuleSetSlug,
      sessionLogId,
    });
  };
  const otherSessionLogs = useMemo(
    () => sessionLogs.filter((sessionLog) => sessionLog !== sessionLogId),
    [sessionLogs, sessionLogId]
  );

  const rows = [];
  if (artifact.fileUri) {
    rows.push({
      display: 'Storage Path',
      value: artifact.fileUri,
    });
  }
  if (artifact.lifeCycleDetails.updateTime) {
    rows.push({
      display: 'Last Modified',
      value: getLocalDateTime(
        artifact.lifeCycleDetails.updateTime.seconds * 1000
      ),
    });
  }
  rows.push({
    display: 'Artifact ID',
    value: artifact.id,
  });

  rows.push({
    display: 'Storage Tier',
    value: getStorageTier(artifact.storageTier),
  });

  return (
    <>
      <table className={classes.tableView}>
        <tbody>
          {rows.map((r) => (
            <tr key={r.display}>
              <td className={classes.rowLabel}>{r.display}</td>
              <td className={classes.rowValue}>{r.value}</td>
            </tr>
          ))}
          {!isRuleSetsLoading && (
            <>
              <tr>
                <td className={classes.rowLabel}>Storage Tier RuleSet</td>
                <td className={classes.ruleSet}>
                  <FormControl sx={{ minWidth: 120 }} size="small">
                    <Select
                      value={selectedRuleSetSlug}
                      onChange={(e) => setSelectedRuleSetSlug(e.target.value)}
                      style={{ height: '30px', fontSize: '14px' }}
                    >
                      {storageTierRuleSets?.map((ruleSet) => (
                        <MenuItem
                          style={{ height: '30px' }}
                          key={ruleSet.slug}
                          value={ruleSet.slug}
                        >
                          {ruleSet.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl className={classes.button}>
                    <Button
                      size="small"
                      color="secondary"
                      onClick={handleRuleSetSave}
                    >
                      Save
                    </Button>
                  </FormControl>
                </td>
              </tr>
              <tr>
                <td className={classes.rowLabel}></td>
                <td className={classes.rowValue}>
                  {getStorageTierPreview(
                    findStorageTierRuleSetFromSets(
                      storageTierRuleSets,
                      artifact.storageTierRuleSetSlug
                    ),
                    artifact
                  )}
                </td>
              </tr>
            </>
          )}
          {otherSessionLogs.length > 0 && (
            <tr>
              <td className={classes.rowLabel}>Session Logs</td>
              <td className={classes.rowValue}>
                <SessionLogLinks sessionLogIds={otherSessionLogs} />
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <SectionHeader>Metadata</SectionHeader>
      <MetadataView includeTitle={false} metadata={metadata} />
    </>
  );
};
