import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { Button } from '@mui/material';

const styles = (theme) => ({
  root: {
    borderRadius: 25,
    fontSize: 13,
    minWidth: 100,
    fontWeight: 300,
  },
});

function SectionCancelButton({ children, classes, ...rest }) {
  return (
    <Button
      variant="text"
      size="small"
      classes={classes}
      tabIndex={-1}
      {...rest}
    >
      {children}
    </Button>
  );
}
SectionCancelButton.propTypes = Button.propTypes;

export default withStyles(styles)(SectionCancelButton);
