import flatten from 'lodash/flatten';
import { useMemo, useState } from 'react';
import PlayerContent from './PlayerContent';
import { useSessionLogArtifacts } from '../../../services/newArtifacts';
import { Select, MenuItem, InputLabel } from '@mui/material';

const extensions = [
  // auto
  'mp3',
  'wav',
  'ogg',
  'aac',
  // Video
  '3g2',
  '3gp',
  'aaf',
  'asf',
  'avchd',
  'avi',
  'drc',
  'flv',
  'm2v',
  'm3u8',
  'm4p',
  'm4v',
  'mkv',
  'mng',
  'mov',
  'mp2',
  'mp4',
  'mpe',
  'mpeg',
  'mpg',
  'mpv',
  'mxf',
  'nsv',
  'ogg',
  'ogv',
  'qt',
  'rm',
  'rmvb',
  'roq',
  'svi',
  'vob',
  'webm',
  'wmv',
  'yuv',
];

export default function VideoPlayer({
  robotLogId,
  innerRef,
}: {
  robotLogId: string;
  innerRef: React.RefObject<HTMLVideoElement>;
}) {
  const { isLoading, error, data } = useSessionLogArtifacts(robotLogId);
  const [activeMediaIndex, setActiveMediaIndex] = useState(0);

  const mediaArtifacts = useMemo(() => {
    const robotLogIds = Object.keys(data || {});
    if (robotLogIds.length === 0) {
      return [];
    }
    const artifacts =
      robotLogIds.map((id) => {
        return (
          data?.[id]?.artifacts
            ?.filter((link) => {
              const filename = link.artifact?.filename || '';
              const ext = filename.split('.').pop();
              return extensions.indexOf(ext) > -1;
            })
            .map((link) => link.artifact) || []
        );
      }) || [];

    return flatten(artifacts);
  }, [data]);

  let videoUrl = '';
  if (mediaArtifacts.length > 0) {
    videoUrl = mediaArtifacts[activeMediaIndex].publicUri;
  }

  return (
    <>
      <PlayerContent
        key={activeMediaIndex}
        isLoading={isLoading}
        serviceError={error}
        videoPath={videoUrl}
        innerRef={innerRef}
      />
      {mediaArtifacts.length > 1 && (
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
          <InputLabel style={{ marginRight: 4, fontSize: 11 }}>
            Media Source:{' '}
          </InputLabel>
          <Select
            size="small"
            style={{ fontSize: 12 }}
            value={activeMediaIndex}
            onChange={(evt) => {
              setActiveMediaIndex(evt.target.value as number);
            }}
          >
            {mediaArtifacts.map((a, index) => (
              <MenuItem key={a.id} value={index}>
                {a.filename}
              </MenuItem>
            ))}
          </Select>
        </div>
      )}
    </>
  );
}
