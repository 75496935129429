import _ from 'lodash';
import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import {
  getTagOptions,
  getCauseOptions,
  getRobotOptions,
  getRobotProductOptions,
  getSoftwareVersionOptions,
  getRobotLogTestOptions,
  getEnvironmentOptions,
} from '../../../../../redux/lists/selectors';
import {
  ensureLabelListItems,
  ensureTicketListItems,
  ensureComponents,
  ensureLogDefinitions,
} from '../../../../../redux/lists/actions';
import {
  componentOptionsSelector,
  componentOwnerParentOptionsSelector,
  logDefinitionOptionsSelector,
} from '../../../../../redux/lists/selectors';

function mapStateToProps(state) {
  return {
    listsAreInitialized: state.lists.isInitialized,
    tagOptions: getTagOptions(state),
    causeOptions: getCauseOptions(state),
    robotOptions: getRobotOptions(state),
    robotProductOptions: getRobotProductOptions(state),
    softwareVersionOptions: getSoftwareVersionOptions(state),
    robotLogTestOptions: getRobotLogTestOptions(state),
    environmentOptions: getEnvironmentOptions(state),
    softwareInfos: _.get(state, 'lists.listTypeMap.softwareInfos'),
    labels: _.get(state, 'lists.listTypeMap.labels'),
    tickets: _.get(state, 'lists.listTypeMap.tickets'),
    componentOptions: componentOptionsSelector(state),
    componentOwnerParentOptions: componentOwnerParentOptionsSelector(state),
    logDefinitionOptions: logDefinitionOptionsSelector(state),
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ensureLabelListItems,
      ensureTicketListItems,
      ensureComponents,
      ensureLogDefinitions,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps);
