import React, {useState} from 'react';
import {Select, Button, Form, Space } from 'antd';
import plotTypeManager from './plotTypeManager';
import {EditOutlined} from '@ant-design/icons'

const {Option} = Select;
type Props = { 
	onCreateRequest: (params:string) => void;
}
export const ControlBar = (props:Props) => {
	const[localPlotType, setLocalPlotType] = useState("dataOverTime");
	
	return(
		<div>
			<Form 
				layout="horizontal" 
				style={{marginBottom:20}}
				initialValues={{plotSelector:localPlotType}}
			>
				<Space size={"middle"}>
					<Form.Item name="plotSelector">
						<Select 
							value={localPlotType}
							size={"large"}
							style={{width:200,}} 
							onChange={(value) => {setLocalPlotType(value)}}
						>
							{plotTypeManager.getOptions().map((item)=> {
								return(
									<Option style={{fontSize:'12px'}} key={item.value} value={item.value}>
										<span style={{color:"#0D59F2", marginInline:'4px'}}> 
											{item.icon } 
										</span>
										{item.label}
									</Option>
								)
							})}
						</Select>
					</Form.Item>
					<Form.Item>
						<Button type={"primary"} size={"large"} onClick={() => props.onCreateRequest(localPlotType)}>
							<EditOutlined/>
							Create
						</Button>
					</Form.Item>
				</Space>
			</Form>
		</div>
		
	)
}

