import _ from 'lodash';
import { alpha } from '@mui/material/styles';
import { AxisDefinition, AxisOption } from './AxisDefinitions';
import sdkClient from '../../lib/hmdsdkClient';
import { Struct } from 'google-protobuf/google/protobuf/struct_pb';
import { EvaluationState } from '@hmd/sdk/api/tests/evaluations/v1';
import {
  RobotLogDocumentService,
  PassthruSearchRequest,
} from '@hmd/sdk/api/search/robotlogdocument/v1';
import { showError } from '../../lib/messages';
import { DateTypes } from './common';
import { LabelType, LabelIdType, ResultType } from '@shield-ui/heatgrid';
import { CellFormValues } from './CellDropdownChip/CellDropdownChip';
import {
  getEvaluationStatusColor,
  consolidatedFailureColors,
} from '../../services/evaluations';
import { colors } from '@shield-ui/styles';

export type QueryType = {
  bool?: {
    filter?: object[];
  };
  [key: string]: string | object;
};

export const fetchHeatgridData = (
  xAxisDefinition: AxisDefinition,
  yAxisDefinition: AxisDefinition,
  xAxisOption: AxisOption,
  yAxisOption: AxisOption,
  query: QueryType,
  filterValues: object
) => {
  // Build query, fetch data, and update data state

  let realQuery = query;
  if (query && query.bool && query.bool.filter) {
    realQuery = _.cloneDeep(query);
    realQuery.bool.filter.push(
      {
        exists: {
          field: 'test_definition.code',
        },
      },
      {
        exists: {
          field: 'test_evaluation.evaluation_state',
        },
      }
    );
  }

  let reqBody = {
    size: 0,
    query: realQuery,
    aggs: {},
  };

  let xAxisAggsPath = ['aggs'];
  let xFilterPath = ['aggs'];
  if (xAxisDefinition.nestedPath) {
    reqBody['aggs'] = {
      xNested: {
        nested: {
          path: xAxisDefinition.nestedPath,
        },
        aggs: {},
      },
    };
    xFilterPath = [...xFilterPath, 'xNested', 'aggs'];
    xAxisAggsPath = [...xAxisAggsPath, 'xNested', 'aggs'];
  }

  if (xAxisDefinition.buildAggsFilterDsl) {
    reqBody = _.set(reqBody, xFilterPath, {
      filter: {
        ...xAxisDefinition.buildAggsFilterDsl(filterValues, xAxisOption),
        aggs: {},
      },
    });
    xAxisAggsPath = [...xAxisAggsPath, 'filter', 'aggs'];
  }

  reqBody = _.set(reqBody, xAxisAggsPath, {
    xAxis: {
      ...xAxisDefinition.buildAggsDsl(xAxisOption),
      aggs: {},
    },
  });

  let yAxisAggsPath = [...xAxisAggsPath, 'xAxis', 'aggs'];
  let yFilterPath = [...xAxisAggsPath, 'xAxis', 'aggs'];
  if (xAxisDefinition.nestedPath) {
    reqBody = _.set(reqBody, yAxisAggsPath, {
      root: {
        reverse_nested: {},
        aggs: {},
      },
    });
    yAxisAggsPath = [...yAxisAggsPath, 'root', 'aggs'];
    yFilterPath = [...yFilterPath, 'root', 'aggs'];
  }

  if (yAxisDefinition.nestedPath) {
    reqBody = _.set(reqBody, yAxisAggsPath, {
      yNested: {
        nested: {
          path: yAxisDefinition.nestedPath,
        },
        aggs: {},
      },
    });
    yAxisAggsPath = [...yAxisAggsPath, 'yNested', 'aggs'];
    yFilterPath = [...yFilterPath, 'yNested', 'aggs'];
  }

  if (yAxisDefinition.buildAggsFilterDsl) {
    reqBody = _.set(reqBody, yFilterPath, {
      filter: {
        ...yAxisDefinition.buildAggsFilterDsl(filterValues, yAxisOption),
        aggs: {},
      },
    });
    yAxisAggsPath = [...yAxisAggsPath, 'filter', 'aggs'];
  }

  reqBody = _.set(reqBody, yAxisAggsPath, {
    yAxis: {
      ...yAxisDefinition.buildAggsDsl(yAxisOption),
      aggs: {},
    },
  });

  let dataAggsPath = [...yAxisAggsPath, 'yAxis', 'aggs'];
  if (yAxisDefinition.nestedPath) {
    reqBody = _.set(reqBody, dataAggsPath, {
      root: {
        reverse_nested: {},
        aggs: {},
      },
    });
    dataAggsPath = [...dataAggsPath, 'root', 'aggs'];
  }

  reqBody = _.set(reqBody, dataAggsPath, {
    evaluationStates: {
      terms: {
        field: 'test_evaluation.evaluation_state',
        min_doc_count: 1,
      },
    },
  });

  const req = new PassthruSearchRequest();
  const body = Struct.fromJavaScript(reqBody);

  req.setBody(body);

  return new Promise<ResultType[]>((resolve) => {
    sdkClient.unary(RobotLogDocumentService.PassthruSearch, req).then((res) => {
      const resJson = res.getResponse().toJavaScript();
      const results: ResultType[] = [];

      // Build the path to the xAxis.buckets JSON element
      let xBucketsPath = ['aggregations'];
      if (xAxisDefinition.nestedPath) {
        xBucketsPath.push('xNested');
      }
      if (xAxisDefinition.buildAggsFilterDsl) {
        xBucketsPath.push('filter');
      }
      xBucketsPath = [...xBucketsPath, 'xAxis', 'buckets'];

      // Process xAxis buckets
      const xBuckets = _.get(resJson, xBucketsPath, []);
      for (let i = 0; i < xBuckets.length; i++) {
        // Build the path to the yAxis.buckets JSON element
        let yBucketsPath = [];
        if (xAxisDefinition.nestedPath) {
          yBucketsPath.push('root');
        }
        if (yAxisDefinition.nestedPath) {
          yBucketsPath.push('yNested');
        }
        if (yAxisDefinition.buildAggsFilterDsl) {
          yBucketsPath.push('filter');
        }
        yBucketsPath = [...yBucketsPath, 'yAxis', 'buckets'];

        // Process yAxis buckets
        const yBuckets = _.get(xBuckets[i], yBucketsPath, []);
        for (let j = 0; j < yBuckets.length; j++) {
          // Build the path to the evaluationStates.buckets JSON element
          let resultBucketsPath = [];
          if (yAxisDefinition.nestedPath) {
            resultBucketsPath.push('root');
          }
          resultBucketsPath = [
            ...resultBucketsPath,
            'evaluationStates',
            'buckets',
          ];

          // Process evaluationStates buckets
          const resultBucketsUnsorted = _.get(
            yBuckets[j],
            resultBucketsPath,
            []
          );
          if (resultBucketsUnsorted.length > 0) {
            const resultBuckets = sortEvaluationStateBuckets(
              resultBucketsUnsorted
            );
            results.push({
              xLabelId: xBuckets[i].key,
              yLabelId: yBuckets[j].key,
              data: resultBuckets.reduce((acc, bucket) => {
                return [
                  ...acc,
                  { evaluationState: bucket.key, count: bucket.doc_count },
                ];
              }, []),
            });
          }
        }
      }

      resolve(results);
    });
  });
};

export const fetchRequirementTypes = _.once(
  (axisDefinitionId: string) =>
    new Promise((resolve, reject) => {
      const reqBody = {
        size: 0,
        aggs: {
          requirements: {
            nested: {
              path: 'test_definition.requirements',
            },
            aggs: {
              types: {
                terms: {
                  field: 'test_definition.requirements.requirement_type',
                  missing: 'N/A',
                },
              },
            },
          },
        },
      };
      const req = new PassthruSearchRequest();
      const body = Struct.fromJavaScript(reqBody);

      req.setBody(body);
      sdkClient
        .unary(RobotLogDocumentService.PassthruSearch, req)
        .then((res) => {
          const resJson = res.getResponse().toJavaScript();
          resolve({
            [axisDefinitionId]: _.get(
              resJson,
              ['aggregations', 'requirements', 'types', 'buckets'],
              []
            ).map((b) => ({ requirementType: b.key })) as AxisOption[],
          });
        })
        .catch((e) => {
          showError(e);
          reject(e);
        });
    })
);

//sort results to show in perceived order
const evaluationStateOrderMap = {
  PASS: 0,
  MINOR_FAILURE: 1,
  MODERATE_FAILURE: 2,
  MAJOR_FAILURE: 3,
  CRITICAL_FAILURE: 4,
  CATASTROPHIC_FAILURE: 5,
  EXECUTION_FAILURE: 6,
  CALCULATION_FAILURE: 7,
  INCOMPLETE: 8,
  EVALUATION_STATE_UNSPECIFIED: 9,
};

export const reliabilityOrderMap = {
  MINOR_FAILURE: 0,
  MODERATE_FAILURE: 1,
  MAJOR_FAILURE: 2,
  CRITICAL_FAILURE: 3,
  CATASTROPHIC_FAILURE: 4,
};

/**
 * Worst to the left, best to the right
 */
const sortEvaluationStateBuckets = (bucketsUnsorted: any[]) => {
  return _.sortBy(
    bucketsUnsorted,
    (bucket) => evaluationStateOrderMap[bucket.key]
  ).reverse();
};

export const getTotalEvaluationCount = (data: any[]) => {
  return data.reduce((totalCount, d) => totalCount + d.count, 0);
};

export const getEvaluationCount = (data: any[], evaluationState) => {
  const evaluation = data.find((d) => d.evaluationState === evaluationState);
  if (evaluation) {
    return evaluation.count;
  }
  return 0;
};

export const getEvaluationStateString = (evaluationStateNumber: number) => {
  return Object.keys(EvaluationState).find(
    (key) => EvaluationState[key] === evaluationStateNumber
  );
};

export const getReliabilityFailureCount = (
  data: any[],
  evaluationState: string
) => {
  const order = reliabilityOrderMap[evaluationState];
  if (order === undefined) {
    return 0;
  }

  return Object.keys(reliabilityOrderMap).reduce((count, reliability) => {
    if (order <= reliabilityOrderMap[reliability]) {
      return count + getEvaluationCount(data, reliability);
    }
    return count;
  }, 0);
};

export const calcCellLabel = (
  result: ResultType,
  cellOption: CellFormValues
) => {
  const { data } = result;
  const totalCount = getTotalEvaluationCount(data);
  if (cellOption.cellContent === 'passFail') {
    const passCount = getEvaluationCount(data, 'PASS');
    const passRate = passCount / totalCount;
    if (cellOption.passingCountDisplay === 'percent') {
      return `${Math.floor(passRate * 100)}%`;
    }
    if (cellOption.passingCountDisplay === 'absolute') {
      return `${passCount}/${totalCount}`;
    }
  }

  if (cellOption.cellContent === 'reliability') {
    const evaluationState = getEvaluationStateString(
      cellOption.reliabilityFailureType
    );
    const reliabilityCount =
      totalCount - getReliabilityFailureCount(data, evaluationState);
    const reliabilityRate = reliabilityCount / totalCount;
    if (cellOption.reliabilityCountDisplay === 'percent') {
      return `${Math.floor(reliabilityRate * 100)}%`;
    }
    if (cellOption.reliabilityCountDisplay === 'absolute') {
      return `${reliabilityCount}/${totalCount}`;
    }
  }

  if (cellOption.cellContent === 'weightedAverage') {
    const wDataSum = data.reduce((acc, d) => {
      if (cellOption.weights[d.evaluationState]) {
        return acc + d.count * cellOption.weights[d.evaluationState];
      }
      return acc;
    }, 0);
    const wSum = Object.values(cellOption.weights).reduce((acc, weight) => {
      return acc + weight;
    }, 0);
    const wAvg = wDataSum / wSum;

    return `${wAvg.toFixed(2)}`;
  }
};

export const calcCellBackground = (
  result: ResultType,
  cellOption: CellFormValues
) => {
  const { data } = result;
  const totalCount = getTotalEvaluationCount(data);

  if (cellOption.cellContent === 'passFail') {
    if (cellOption.failureBreakdown === 'consolidated') {
      const passCount = getEvaluationCount(data, 'PASS');
      const passRate = passCount / totalCount;
      return [
        {
          color: consolidatedFailureColors.FAIL,
          percent: (1 - passRate) * 100,
        },
        {
          color: consolidatedFailureColors.PASS,
          percent: passRate * 100,
        },
      ];
    }

    if (cellOption.failureBreakdown === 'individual') {
      return data.reduce((acc, d) => {
        return [
          ...acc,
          {
            color: getEvaluationStatusColor(EvaluationState[d.evaluationState]),
            percent: (d.count / totalCount) * 100,
          },
        ];
      }, []);
    }
  }

  if (cellOption.cellContent === 'reliability') {
    const evaluationState = getEvaluationStateString(
      cellOption.reliabilityFailureType
    );
    const reliabilityFailureCount = getReliabilityFailureCount(
      data,
      evaluationState
    );
    const reliabilityFailureRate = reliabilityFailureCount / totalCount;

    const minOpacity = 0.2;
    const opacity =
      reliabilityFailureRate === 0
        ? 0
        : minOpacity + (1 - minOpacity) * reliabilityFailureRate;
    return [
      {
        color: alpha(
          getEvaluationStatusColor(EvaluationState[evaluationState]),
          opacity
        ),
        percent: 100,
      },
    ];
  }

  if (cellOption.cellContent === 'weightedAverage') {
    const wDataSum = data.reduce((acc, d) => {
      if (cellOption.weights[d.evaluationState]) {
        return acc + d.count * cellOption.weights[d.evaluationState];
      }
      return acc;
    }, 0);
    const wSum = Object.values(cellOption.weights).reduce((acc, weight) => {
      return acc + weight;
    }, 0);
    const maxCount = data.reduce((acc, d) => {
      if (d.count > acc) {
        return d.count;
      }
      return acc;
    }, 0);
    const wAvg = wDataSum / wSum;
    const wAvgScaled = wAvg / maxCount;
    const minOpacity = 0.2;
    const opacity =
      wAvgScaled === 0 ? 0 : minOpacity + (1 - minOpacity) * wAvgScaled;

    return [
      {
        color: alpha(colors.semantic.error, opacity),
        percent: 100,
      },
    ];
  }
};

const monthStrings = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const getDateAxisLabels = (
  labelIds: LabelIdType[],
  dateType: DateTypes
) => {
  let prevDate = new Date(0);

  let emptyLabel: LabelType = undefined;
  const labels: LabelType[] = labelIds.reduce((acc, labelId) => {
    const date = new Date(labelId);
    if (date.getUTCFullYear() <= 1970) {
      emptyLabel = {
        id: labelId,
        label: '-',
      };
    } else if (dateType === DateTypes.Month) {
      acc.push({
        id: labelId,
        label: `${monthStrings[date.getUTCMonth()]}, ${date.getUTCFullYear()}`,
      });
    } else {
      if (prevDate.getUTCFullYear() < date.getUTCFullYear()) {
        acc.push({
          id: labelId,
          label: `${
            date.getUTCMonth() + 1
          }/${date.getUTCDate()}/${date.getUTCFullYear()}`,
        });
      } else {
        acc.push({
          id: labelId,
          label: `${date.getUTCMonth() + 1}/${date.getUTCDate()}`,
        });
      }
    }

    prevDate = date;
    return acc;
  }, []);

  if (emptyLabel) {
    return [...labels, emptyLabel];
  }
  return labels;
};
