import { Collapse, Modal } from 'antd';

import { getCachedItem, setCachedItem } from '@shield-ui/utils';
import { useEffect, useMemo, useState } from 'react';
import { Artifact } from '@hmd/sdk/api/artifacts/v1';
import _ from 'lodash';
import { NestedArtifactList } from './NestedArtifactList';
import {
  SessionLogAndArtifacts,
  useArtifactDeleteMutation,
} from '../../services/newArtifacts';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Collapsible } from '../../components/Collapsible';

const { Panel } = Collapse;

const FAVORITES_CACHE_KEY = 'robot_log_artifacts_favorites';

const FAVORITE_FOR_TYPE_VALUE = 'ALL';
// constant names, multiple variants
const FAVORITE_BY_NAME_MAP = {
  'rosbag-topic': true,
  rosbag: true,
  roslog: true,
  file: true,
  'plotly-json': true,
  'phone-dat': true,
  'trace-events-json': true,
};

type ArtifactListViewProps = {
  sessionLogs: SessionLogAndArtifacts;
  sessionLogId: string;
};

export const ArtifactListView = ({
  sessionLogs,
  sessionLogId,
}: ArtifactListViewProps) => {
  const [modal, contextHolder] = Modal.useModal();
  const { mutate: deleteArtifact } = useArtifactDeleteMutation(sessionLogId);

  const [favorites, setFavorites] = useState({});

  const IsFavorite = (artifact: Artifact.AsObject) => {
    const { artifactTypeSlug, filename } = artifact;

    const favVal = favorites[artifactTypeSlug];
    return (
      (!FAVORITE_BY_NAME_MAP[artifactTypeSlug] &&
        favVal === FAVORITE_FOR_TYPE_VALUE) ||
      (_.isArray(favVal) && favVal.includes(filename))
    );
  };

  const addFavorite = (artifact: Artifact.AsObject) => {
    const { artifactTypeSlug, filename } = artifact;

    const newFavorites = {
      ...favorites,
    };
    if (!FAVORITE_BY_NAME_MAP[artifactTypeSlug]) {
      newFavorites[artifactTypeSlug] = FAVORITE_FOR_TYPE_VALUE;
    } else {
      if (_.isArray(newFavorites[artifactTypeSlug])) {
        newFavorites[artifactTypeSlug].push(filename);
      } else {
        newFavorites[artifactTypeSlug] = [artifactTypeSlug];
      }
    }
    setCachedItem(FAVORITES_CACHE_KEY, newFavorites);
    setFavorites(newFavorites);
  };

  const removeFavorite = (artifact: Artifact.AsObject) => {
    const { artifactTypeSlug, filename } = artifact;

    const newFavorites = {
      ...favorites,
    };

    if (!FAVORITE_BY_NAME_MAP[artifactTypeSlug]) {
      delete newFavorites[artifactTypeSlug];
    } else {
      if (_.isArray(newFavorites[artifactTypeSlug])) {
        newFavorites[artifactTypeSlug] = newFavorites[artifactTypeSlug].filter(
          (n) => n !== filename
        );
      } else {
        delete newFavorites[artifactTypeSlug];
      }
    }
    setCachedItem(FAVORITES_CACHE_KEY, newFavorites);
    setFavorites(newFavorites);
  };

  const favoriteSessionLogArtifacts: SessionLogAndArtifacts = useMemo(() => {
    if (!sessionLogs) {
      return {};
    }

    const newSessionLogs = Object.values(sessionLogs).reduce(
      (acc, sessionLog) => {
        const newArtifacts = sessionLog.artifacts.filter((artifact) => {
          return IsFavorite(artifact.artifact);
        });

        if (newArtifacts.length > 0) {
          acc[sessionLog.id] = {
            ...sessionLog,
            artifacts: newArtifacts,
          };
        }

        return acc;
      },
      {}
    );

    return newSessionLogs;
  }, [sessionLogs, favorites]);

  const hasFavorites = useMemo(() => {
    return Object.keys(favoriteSessionLogArtifacts).length > 0;
  }, [favoriteSessionLogArtifacts]);

  useEffect(() => {
    const cachedFavorites = getCachedItem(FAVORITES_CACHE_KEY);
    if (cachedFavorites) {
      setFavorites(cachedFavorites);
    }
  }, []);

  const onArtifactDelete = (artifact: Artifact.AsObject) => {
    modal.confirm({
      title: 'Delete Artifact',
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to delete "${artifact.filename}"?`,
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: () => {
        deleteArtifact(artifact.id);
      },
    });
  };

  return (
    <>
      {contextHolder}
      {hasFavorites && (
        <Collapsible header="Favorites" isOpen>
          <NestedArtifactList
            sessionLogId={sessionLogId}
            sessionLogs={favoriteSessionLogArtifacts}
            favorites={favorites}
            IsFavorite={IsFavorite}
            addFavorite={addFavorite}
            removeFavorite={removeFavorite}
            onDelete={onArtifactDelete}
          />
        </Collapsible>
      )}
      <NestedArtifactList
        sessionLogId={sessionLogId}
        sessionLogs={sessionLogs}
        favorites={favorites}
        IsFavorite={IsFavorite}
        addFavorite={addFavorite}
        removeFavorite={removeFavorite}
        onDelete={onArtifactDelete}
      />
    </>
  );
};
