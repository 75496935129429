import React from 'react';
import _ from 'lodash';
import { semanticDataTypes } from '../../../../forms/defs';
import { getValueFromData } from '../../../../../services/analysis';
import { colors } from '@shield-ui/styles';
import { makeKeySafe } from '../../../../../lib/documentSearch';

function expand({ row, column }) {
  const { columnUid, dataInputValues = {} } = column;

  const analysis = _.get(row, [columnUid, 'edges', 0, 'node']);
  if (!analysis) {
    return {
      noData: true,
    };
  }

  const { data, testPassed } = analysis;
  return {
    testPassed,
    value: getValueFromData({
      data,
      // empty string or undefined turns into undefined so the default "value" is used
      key: dataInputValues['dataValueKey'] || undefined,
    }),
  };
}

function Cell(props) {
  const { noData, testPassed, value } = expand(props);

  if (noData) {
    return null;
  }


  let color;
  const hasValue = !_.isUndefined(value);
  const display = hasValue ? value : testPassed ? 'PASS' : 'FAIL';
  if (!testPassed) {
    const errColor = colors.hues.reds[80];
    color = errColor;
  }

  return <span style={{ color }}>{display}</span>;
}

const config = {
  columnKey: 'analysis',
  label: 'Piper Expectation',
  getLabel: ({ column }) =>
    _.get(column, 'dataInputValues.analysisName', '? Expectation'),
  dataInputOptions: [
    {
      parameter: 'analysisName',
      dataType: semanticDataTypes['Analysis.name'],
      required: true,
    },
  ],
  getElasticSort: ({ column, orderByDirection }) => {
    const key = _.get(column, 'dataInputValues.analysisName');
    if (!key) {
      return;
    }

    return [
      {
        [`analyses.${makeKeySafe(key)}`]: {
          order: orderByDirection,
          missing: '_last',
        },
      },
    ];
  },
  description:
    'Select a Piper Expectation to display pass/fail and values for. The most recent Piper Execution will be displayed.',
  getValue: (props) => {
    const { noData, testPassed, value } = expand(props);

    if (noData) {
      return '';
    }

    return `${value} ${testPassed ? ' (PASS)' : ' (FAIL)'}`.trim();
  },
  Cell,
  getFragment: ({ column }) => {
    const { columnUid, dataInputValues = {} } = column;
    if (!columnUid || !dataInputValues) {
      return '';
    }

    return `
       ${columnUid}: analyses(first:1,names:["${dataInputValues.analysisName}"],sort:created_at_desc) {
        edges {
          node {
            data
            testPassed
          }
        }
       }
      `;
  },
};

export default config;
