import React, { useState, useRef } from 'react';
import { Button, Grid, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ChildReviewAddForm from './ChildReviewAddForm';
import { Link } from 'react-router-dom';
import ChildReviewList from './ChildReviewList';


const useStyles = makeStyles((theme) => ({
  reviewContainer: {
    overflowX: 'auto',
    paddingTop: 20,
    paddingBottom:25,

    borderTop: `1px solid rgba(5, 5, 5, .2)`,
  },
  selectContainer: {
    paddingTop: 20,
    paddingBottom:25,
    borderTop: `1px solid rgba(5, 5, 5, .2)`,
  },
  reviewFlightButton: {
    fontSize: 13,
  },
  nameHeader:{
    color:'lightgray',
    fontSize:16,
  }

}));
export default function ChildReview({ reviews, name, refetch, childId }) {
  const classes = useStyles();
  const [isAdding, setIsAdding] = useState(false);

	const addForm = useRef(null)

  const handleClick = () => {
    setIsAdding(true);
  };

	const closeAddForm = (event) => {
		if(addForm.current && isAdding && !addForm.current.contains(event.target)){
			setIsAdding(false);
		}
	}

	document.addEventListener('mousedown', closeAddForm)

  return (
    <React.Fragment>
      {!isAdding && !reviews.length && (
        //Name & review flight
        <Grid
          container
          alignItems="baseline"
          className={classes.reviewContainer}
        >
          <Grid item xs={8}>
            <Link
              to={`/app/flights/${childId}`}
              className={classes.nameHeader}
            >
              {name}
            </Link>
          </Grid>

          <Grid item xs={4}>
            <Box
              display="flex"
              justifyContent="flex-end"
            >
              <Button
                variant="contained"
                color="secondary"
                className={classes.reviewFlightButton}
                onClick={handleClick}
              >
                Review Flight
              </Button>
            </Box>
          </Grid>
        </Grid>
      )}

      {isAdding && !reviews.length && (
        //Render select dropdown 
        <div ref={addForm}>
          <Grid className={classes.selectContainer} >
            
            <Link
              to={`${childId}`}
              className={classes.nameHeader}
            >
              {name}
            </Link>
              <ChildReviewAddForm
                robotLogId={childId}
                reviewList={reviews}
                onRefresh={refetch}
              />
          </Grid>
        </div>
      )}

      {!!reviews.length &&(
        //Render reviewList
        <Grid className={classes.reviewContainer}>
          <Link
            to={`/app/flights/${childId}`}
            className={classes.nameHeader}
          >
            {name}
          </Link>
					<ChildReviewList
						reviewList={reviews}
            onRefresh={refetch}
					/>
        </Grid>
      )}
    </React.Fragment>
  );
}
