import React, { useState } from 'react';
import async from 'async';
import _ from 'lodash';
import { Button} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { countAndPluralize } from '@shield-ui/utils';
import SectionSubmitButton from '../components/SectionSubmitButton';
import {
  createRobotLogReview,
} from '../../../services/reviews';
import TeamUserCheckSelect from '../../../components/connected-controls/TeamUserCheckSelect';
import container from '../../../containers/authUser.ts';
import PropTypes from 'prop-types';

function styles() {
  return {
    selectContainer: {
      marginTop: 5,
      paddingTop: 10,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    selectWrapper: {
      flex: 1,
      marginRight: 20,
    },
  };
}

function ChildReviewAddForm (props) {
  const [reviewUserIds, setReviewUserIds] = useState([]);
  const [reviewTeamIds, setReviewTeamIds] = useState([]);

  const onChangeUserIds = (value) => {
    setReviewUserIds(value);
  };

  const onChangeTeamIds = (value) => {
    setReviewTeamIds(value);
  };

  const onError = (err) => {
    const { showErrorSnack } = props;
    showErrorSnack(err);
  };

  const onSuccess = (message) => {
    const { showSuccessSnack, onRefresh } = props;
    showSuccessSnack({ message });
    onRefresh();
  };

  const assignToMe = () => {
    const { currentUser, robotLogId } = props;

    const variables = {
      robotLogId: robotLogId,
      reviewerId: currentUser.id,
    };

    createRobotLogReview(variables, (err) => {
      if (err) {
        return onError(err);
      }
      onSuccess('You are assigned to this review');
    });
  };

  const submitCreateReviews = () => {
    const {robotLogId, onRefresh} = props;

    if (reviewUserIds.length === 0 && reviewTeamIds.length === 0) {
      onError(
        new Error(
          'Reviewer is required, please select a user or team for review'
        )
      );
      return;
    }

    async.waterfall(
      [
        (callback) => {
          async.eachLimit(
            reviewUserIds,
            2,
            (userId, callback) => {
              createRobotLogReview(
                {
                  robotLogId: robotLogId,
                  reviewerId: userId,
                },
                callback
              );
            },
            callback
          );
        },
        (callback) => {
          async.eachLimit(
            reviewTeamIds,
            2,
            (teamId, callback) => {
              createRobotLogReview(
                {
                  robotLogId: robotLogId,
                  reviewerTeamId: teamId,
                },
                callback
              );
            },
            callback
          );
        },
      ],
      (err) => {
        if (err) {
          onError(err);
          // refresh because multiple and maybe some succeeded?
          onRefresh();
          return;
        }

        const messages = [];
        if (reviewTeamIds.length > 1) {
          messages.push(countAndPluralize(reviewTeamIds.length), 'team');
        }
        if (reviewUserIds.length > 1) {
          messages.push(countAndPluralize(reviewUserIds.length), 'user');
        }

        setReviewUserIds([]);
        setReviewTeamIds([]);
        onSuccess(`Successfully added ${messages.join(' and ')} for review`);
      }
    );
  };

  const { reviewList, currentUser, classes,  } = props;


  const alreadyAdded = reviewList.reduce(
    (acc, review) => {
      const { reviewer, reviewerTeam } = review;
      if (reviewer) {
        acc.user[reviewer.id] = true;
      } else if (reviewerTeam) {
        acc.team[reviewerTeam.id] = true;
      }
      return acc;
    },
    {
      user: {},
      team: {},
    }
  );
  const canAssignToMe = !alreadyAdded.user[currentUser.id];
  const pendingCount = reviewTeamIds.length + reviewUserIds.length;

  return (
    <div>
      <React.Fragment >
						<div className={classes.selectContainer}>
							<div className={classes.selectWrapper}>
								<TeamUserCheckSelect
									placeholder="Search to assign reviewers"
									userIdValues={reviewUserIds}
									teamIdValues={reviewTeamIds}
									onChangeUserIds={onChangeUserIds}
									onChangeTeamIds={onChangeTeamIds}
									disabledUserIds={_.keys(alreadyAdded.user)}
									disabledTeamIds={_.keys(alreadyAdded.team)}
								/>
							</div>
							<SectionSubmitButton
								disabled={!pendingCount}
								onClick={submitCreateReviews}
							>
								{pendingCount > 1 ? 'Add Reviewers' : 'Add Reviewer'}
							</SectionSubmitButton>
						</div>
						{canAssignToMe && (
							<div style={{ marginTop: 10 }}>
								<Button size="small" onClick={assignToMe} tabIndex={-1}>
									Assign to Me
								</Button>
							</div>
						)}
      </React.Fragment>
    </div>
  );
}

ChildReviewAddForm.propTypes = {
  robotLogId: PropTypes.string.isRequired,
  reviewList: PropTypes.array, 
  onRefresh: PropTypes.func,
  currentUser: PropTypes.object
}

ChildReviewAddForm.defaultProps = {
  reviewList: [],
  onRefresh: _.noop,
}


export default withStyles(styles)(container(ChildReviewAddForm));
