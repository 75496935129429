import _ from 'lodash';
import Cell from './Cell';

const config = {
  columnKey: 'parents',
  label: 'Parents',
  description:
    'Parent Robot Logs (e.g. another flight, test instance, or trial, boot session)',
  sortKey: 'parents.name',
  defaultWidth: 300,
  Cell,
  getValue: ({ row }) => {
    return _.get(row, 'parents', [])
      .map((rl) => rl.name)
      .join('\n');
  },
  fragment: `
        parents {
            id
            name
        }
    `,
};

export default config;
