import React, { useState, useMemo } from 'react';
import _ from 'lodash';
import { useDebounce } from '@shield-ui/hooks';
import makeStyles from '@mui/styles/makeStyles';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import SearchInput from '../shared/SearchInput';
import { QueryFilterItem, ManagementProps } from './types';
import ManageFilter from './ManageFilter';
import { doesInputMatchBase } from '@shield-ui/utils';
import { colors } from '@shield-ui/styles';
import { IconButton } from '@mui/material';
import { AntButton } from '@shield-ui/buttons';
import CloseIcon from '@mui/icons-material/Close';
import { CheckOutlined } from '@ant-design/icons';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 720,
    height: 'calc(100vh - 32px)',
    marginTop: 16,
    marginBottom: 16,
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${colors.hues.grays[200]}`,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    letterSpacing: '0.5px',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 2, 1, 2),
    background: colors.hues.grays[190],
  },
  controls: {
    padding: theme.spacing(1, 2, 2, 2),
    background: colors.hues.grays[190],
    display: 'flex',
    alignItems: 'center',
  },
  closeButton: {
    marginLeft: theme.spacing(2),
  },
  resetButton: {
    marginLeft: theme.spacing(2),
  },
  content: {
    flex: 1,
    overflowY: 'auto',
  },
  categoryLabel: {
    background: colors.hues.grays[160],
    padding: theme.spacing(0.5, 3),
    boxShadow: `0px 3px 5px ${colors.hues.grays[190]}`,
    letterSpacing: '-0.2px',
    fontSize: 13,
  },
  categoryFilters: {},
}));

interface Props {
  queryFilterItems: QueryFilterItem[];
  values: object;
  setValues: (args: object) => void;
  onClose: (evt) => void;
  isOpen: boolean;
  management: ManagementProps;
}

type ItemCategory = {
  label: string;
  queryFilterItems: QueryFilterItem[];
};

function ManageFiltersModal(props: Props) {
  const { queryFilterItems, values, setValues, management, isOpen, onClose } =
    props;
  const [filterString, setFilterString] = useState('');
  const debouncedFilterString = useDebounce(filterString, 250);
  const classes = useStyles();

  const itemCategories = useMemo((): ItemCategory[] => {
    let allItems = !debouncedFilterString
      ? queryFilterItems
      : queryFilterItems.filter((qfi) => {
          const { category = 'General', queryFilter } = qfi;

          const label = queryFilter.getControlLabel();
          const desc = queryFilter.getDescription();

          return (
            doesInputMatchBase(debouncedFilterString, category) ||
            doesInputMatchBase(debouncedFilterString, label) ||
            (desc && doesInputMatchBase(debouncedFilterString, desc))
          );
        });

    allItems = _.sortBy(allItems, (qfi) => qfi.queryFilter.getControlLabel());

    const groups = _.groupBy(allItems, (qfi) => qfi.category || 'General');
    const groupLabels = _.sortBy(Object.keys(groups), (key) => {
      if (management.categoryOrder) {
        const index = management.categoryOrder.indexOf(key);
        if (index > -1) {
          return index;
        }
        return key;
      }
    });

    return groupLabels.map((groupLabel) => {
      return {
        label: groupLabel,
        queryFilterItems: groups[groupLabel],
      };
    });
  }, [debouncedFilterString, queryFilterItems]);

  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      // lower zIndex to not fight with other portals of children
      // higher than some table elements
      style={{ zIndex: 350 }}
      // modal was fighting focus with other portals being launched from child elements (e.g. datepicker calendar)
      disableEnforceFocus
    >
      <Paper className={classes.paper}>
        <div className={classes.header}>
          Manage Filters
          <IconButton
            onClick={onClose}
            size="small"
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className={classes.controls}>
          <SearchInput
            placeholder="Find a filter"
            value={filterString}
            onChange={(evt) => setFilterString(evt.target.value)}
          />
          {management.defaultIds && (
            <AntButton
              className={classes.resetButton}
              size="large"
              icon={<CheckOutlined />}
              onClick={() => management.setIds(management.defaultIds)}
            >
              Defaults
            </AntButton>
          )}
        </div>
        <div className={classes.content}>
          {itemCategories.map((cat) => (
            <div key={cat.label}>
              <div className={classes.categoryLabel}>{cat.label}</div>
              <div className={classes.categoryFilters}>
                {cat.queryFilterItems.map((qfi) => (
                  <ManageFilter
                    key={qfi.queryFilter.getId()}
                    queryFilterItem={qfi}
                    values={values}
                    setValues={setValues}
                    management={management}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </Paper>
    </Modal>
  );
}

export default ManageFiltersModal;
