import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { colors } from '@shield-ui/styles';
import OmnisearchResults, {
  OmnisearchResultsJsonProps,
} from '../OmnisearchResults/OmnisearchResults';
import { debounce } from 'lodash';
import { TextField } from '@shield-ui/controls';
import { InputAdornment, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchBoxContainer: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    searchBox: {},
    searchResultsContainer: {
      padding: theme.spacing(1),
    },
    adornmentContainer: {
      width: 20,
    },
  })
);

const StyledInputAdornment = withStyles(() => ({
  positionStart: {
    marginRight: '1px',
  },
}))(InputAdornment);

type OmnisearchProps = {
  onSearchRequest: (
    input: string,
    callback: (results: OmnisearchResultsJsonProps) => void
  ) => void;
  placeholder: string;
};

function Omnisearch(props: OmnisearchProps) {
  const { onSearchRequest, placeholder } = props;
  const classes = useStyles();
  const [results, setResults] = useState({ header: '', hits: [] });
  const [isLoading, setIsLoading] = useState(false);
  const adornmentParentRef = useRef(null);
  const [adornmentParentSize, setAdornmentParentSize] = useState(0);
  //use callback to send data to this component
  const callBackFunc = (data) => {
    setIsLoading(false);
    setResults(data);
  };
  const debounceRequest = useCallback(
    debounce((newValue) => {
      setIsLoading(true);
      onSearchRequest(newValue, callBackFunc);
    }, 700),
    [onSearchRequest]
  );

  useEffect(() => {
    onSearchRequest('', setResults);
    const { clientHeight, clientWidth } = adornmentParentRef.current;
    setAdornmentParentSize(Math.min(clientHeight, clientWidth));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div className={classes.searchBoxContainer}>
        <TextField
          placeholder={placeholder}
          onChange={(event) => {
            debounceRequest(event.target.value);
          }}
          autoFocus
          startAdornment={
            <StyledInputAdornment position="start">
              <div
                className={classes.adornmentContainer}
                ref={adornmentParentRef}
              >
                {isLoading ? (
                  <CircularProgress size={0.8 * adornmentParentSize} />
                ) : (
                  <SearchIcon style={{ color: colors.hues.gray }} />
                )}
              </div>
            </StyledInputAdornment>
          }
        ></TextField>
      </div>
      <div className={classes.searchResultsContainer}>
        <OmnisearchResults header={results.header} hits={results.hits} />
      </div>
    </div>
  );
}
export { OmnisearchProps };
export default Omnisearch;
