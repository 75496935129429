import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import withStyles from '@mui/styles/withStyles';
import history from '../../../routes/history';
import ModalHeader from '../../modals/Header';
import { deleteCollection } from '../../../services/collections';
import { deleteRuleSet } from '../../../services/ruleSets';
import { Paper, Button } from '@mui/material';
import { ProgressSubmitButton } from '@shield-ui/buttons';

function styles(theme) {
  return {
    container: {
      padding: theme.spacing(3),
      width: 600,
      // not sure why this gets a focus outline
      outline: 'none',
    },
    message: {
      fontSize: '1.15em',
      color: theme.palette.error.light,
      marginBottom: theme.spacing(5),
    },
    collectionName: {
      display: 'block',
      width: '100%',
      fontSize: '1.1em',
      fontWeight: 500,
      marginBottom: theme.spacing(4),
    },
    collectionDescription: {
      marginBottom: 10,
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    cancelButton: {
      marginRight: 20,
    },
  };
}

class DeleteCollectionModal extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    collection: PropTypes.object.isRequired,
    title: PropTypes.string,
  };

  static defaultProps = {
    title: 'Delete Collection',
  };

  state = {
    submitting: false,
  };

  onConfirm = () => {
    const { id, collection, showErrorSnack, showSuccessSnack, hideModal } =
      this.props;

    const ruleSetId = _.get(collection, 'ruleSet.id');

    deleteCollection({ id }, (err) => {
      if (err) {
        return showErrorSnack(err);
      }

      if (ruleSetId) {
        deleteRuleSet({ id: ruleSetId }, _.noop);
      }

      hideModal();
      if (window.location.href.indexOf(id) > -1) {
        history.push(`/app/collections`);
      }
      setTimeout(() => {
        showSuccessSnack({
          message: `Successfully deleted collection ${collection.name}`,
        });
      }, 50);
    });
  };

  render() {
    const { hideModal, classes, title, collection } = this.props;
    const { submitting } = this.state;
    const { name } = collection;

    return (
      <Paper className={classes.container}>
        <ModalHeader onClose={hideModal}>{title}</ModalHeader>
        <div className={classes.message}>
          Are you sure you want to delete this collection? It will remove it for{' '}
          <strong>ALL</strong> users and is a irreversible action.
        </div>
        <div className={classes.collectionName}>Collection Name: {name}</div>
        {/*!!ruleSet && !!ruleSet.robotLogSearchVariables && <RuleSetPreview robotLogSearchVariables={ruleSet.robotLogSearchVariables} />*/}

        <div className={classes.buttonContainer}>
          <Button className={classes.cancelButton} onClick={hideModal}>
            Cancel
          </Button>
          <ProgressSubmitButton
            onClick={this.onConfirm}
            isSubmitting={submitting}
          >
            Yes, Delete Collection
          </ProgressSubmitButton>
        </div>
      </Paper>
    );
  }
}

export default withStyles(styles)(DeleteCollectionModal);
