import _ from 'lodash';

const config = {
  columnKey: 'phoneName',
  label: 'Phone Name',
  description: 'Phone Name or Serial Number for Default',
  sortKey: 'phones.name.keyword',
  getValue: function ({ row }) {
    return _.get(row, 'phone.name', '');
  },
  fragment: `
        phone {
            name
        }
    `,
};
export default config;
