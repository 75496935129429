import React, { useEffect, useState } from 'react';
import { ErrorMessageContent, LoadingIndicator } from '@shield-ui/core';
import container, { ContainerProps } from './PipelineExplorer/container';
import PipelineViewer, {
  PipelineViewerProps,
} from './PipelineExplorer/PipelineViewer';
import {
  buildPipelineViewerData,
  getFavorites,
  SolidOutputType,
  toggleMergeFavorite,
} from './PipelineExplorer/data';
import { MODAL_TYPES } from '../modals/modalConstants';
import { ViewMode } from './PipelineExplorer/PipelineViewer/ControlsHeader';
import CreateMetricOrExpectationModal from './CreateMetricOrExpectationModal';
import {
  createMetricExpectationMutation,
  getMetric,
  getExpectation,
} from '../../services/analysis';
import EditMetricOrExpectationModal from './EditMetricOrExpectationModal';
import {
  HangarAnalysisType,
  HangarMetricType,
} from '@shield-ui/hangar-service';
import { Metric } from '@hmd/sdk/api/analysis/metric/v1';
import { Expectation } from '@hmd/sdk/api/analysis/expectation/v1';

interface Props extends ContainerProps {
  robotLogId: string;
  initialViewMode?: ViewMode;
}

const hangarMetricToMetric = (
  hangarMetric: HangarMetricType
): Metric.AsObject => {
  return {
    id: hangarMetric.id,
    name: hangarMetric.name,
    sessionLogId: hangarMetric.robotLogId,
    value: hangarMetric.value,
    valueDataType: hangarMetric.dataType,
    pipelineExecutionId: hangarMetric.pipelineExecutionId,
  };
};

const hangarAnalysisToExpectation = (
  hangarAnalysis: HangarAnalysisType
): Expectation.AsObject => {
  const expectationData: { value: number; threshold: number } =
    hangarAnalysis.data;
  return {
    id: hangarAnalysis.id,
    name: hangarAnalysis.name,
    sessionLogId: hangarAnalysis.robotLogId,
    value: expectationData.value,
    threshold: expectationData.threshold,
    passed: hangarAnalysis.testPassed,
    pipelineExecutionId: hangarAnalysis.pipelineExecutionId,
  };
};

const PipelineExplorer: React.FC<Props> = (props) => {
  const { robotLogId, errorMessage, fetching, fetched, initialViewMode } =
    props;

  const [favorites, setFavorites] = useState(getFavorites());
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [createModalData, setCreateModalData] = useState({});
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editModalData, setEditModalData] = useState({});

  const { mutate: createMetricOrExpectation } = createMetricExpectationMutation(
    {
      onSuccess: () => {
        refresh();
        setCreateModalOpen(false);
      },
    }
  );

  const refresh = () => {
    const { robotLogId, refreshResultsForRobotLog } = props;
    refreshResultsForRobotLog({ robotLogId });
  };

  useEffect(() => {
    // don't refresh if we already have our list
    if (!props.fetched) {
      refresh();
    }
  }, []);

  useEffect(() => {
    if (props.robotLogId !== robotLogId) {
      refresh();
    }
  }, [props.robotLogId]);

  const onShowPipelineExecution = (pipelineExecution) => {
    props.showModal({
      modalType: MODAL_TYPES.PIPELINE_EXECUTION_DETAILS,
      modalContextProps: {
        pipelineExecutionId: pipelineExecution.id,
      },
    });
  };

  const onToggleFavorite: PipelineViewerProps['onToggleFavorite'] = (
    output
  ) => {
    setFavorites(toggleMergeFavorite(favorites, output));
  };

  const onCreateMetricOrExpectation = () => {
    setCreateModalOpen(true);
  };

  const onEditMetricOrExpectation = async (
    type: SolidOutputType,
    id: string
  ) => {
    let editData;

    if (type === SolidOutputType.metric) {
      const editMetric = props.metricResults.find((metric) => metric.id === id);
      editData = hangarMetricToMetric(editMetric);
    } else if (type === SolidOutputType.analysis) {
      const editExpectation = props.analysisResults.find(
        (analysis) => analysis.id === id
      );

      editData = hangarAnalysisToExpectation(editExpectation);
    }

    setEditModalData(editData);
    setEditModalOpen(true);
  };

  const createMetricOrExpectationHandler = (data) => {
    createMetricOrExpectation({
      ...data,
      sessionLogId: robotLogId,
    });
    setCreateModalOpen(false);
  };

  const editMetricOrExpectationHandler = (data) => {
    createMetricOrExpectation({
      ...data,
      sessionLogId: robotLogId,
    });
    setEditModalOpen(false);
  };

  if (errorMessage) {
    return <ErrorMessageContent>{errorMessage}</ErrorMessageContent>;
  }
  if (fetching) {
    return <LoadingIndicator />;
  }
  if (fetched) {
    const { treeOutput, flatOutput } = buildPipelineViewerData({
      analyses: props.analysisResults,
      metrics: props.metricResults,
      artifacts: props.artifactResults,
      pipelineExecutions: props.pipelineExecutionResults,
      favorites: favorites,
    });
    return (
      <>
        <CreateMetricOrExpectationModal
          visible={createModalOpen}
          onCancel={() => setCreateModalOpen(false)}
          onCreate={createMetricOrExpectationHandler}
        />
        <EditMetricOrExpectationModal
          visible={editModalOpen}
          onCancel={() => setEditModalOpen(false)}
          data={editModalData}
          onUpdate={editMetricOrExpectationHandler}
        />
        <PipelineViewer
          initialViewMode={initialViewMode}
          treeOutput={treeOutput}
          flatOutput={flatOutput}
          onShowPipelineExecution={onShowPipelineExecution}
          onToggleFavorite={onToggleFavorite}
          onCreateMetricOrExpectation={onCreateMetricOrExpectation}
          onEditMetricOrExpectation={onEditMetricOrExpectation}
        />
      </>
    );
  }

  return null;
};

export { ViewMode };
export default container(PipelineExplorer);
