import { Tag, Button, Progress } from 'antd';
import makeStyles from '@mui/styles/makeStyles';
import { Fade } from '@mui/material';
import { colors } from '@shield-ui/styles';
import { formatBytes } from '@shield-ui/utils';
import { ArtifactTypeSelect } from './ArtifactTypeSelect';
import {
  canChangeArtifactType,
  UploadArtifactType,
} from '../UploadFlights/common';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      position: 'relative',
      padding: theme.spacing(0.25, 2),
      display: 'flex',
      alignItems: 'center',
    },
    actions: {
      marginRight: theme.spacing(1),
      opacity: 1,
      transition: '0.12s',
    },
    progress: {
      marginLeft: theme.spacing(1),
    },
    name: {
      marginRight: theme.spacing(2),
      maxWidth: 480,
      overflow: 'hidden',
      wordWrap: 'break-word',
      fontSize: 11.5,
    },
    artifactTypeContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      width: 200,
      flexShrink: 0,
      marginRight: theme.spacing(2),
    },
    artifactTypeSelect: {
      width: '100%',
    },
    artifactTypeNoChange: {
      color: colors.hues.grays[60],
    },
    tagContainer: {
      flexShrink: 0,
    },
    tinyLabel: {
      fontSize: 10.5,
      color: colors.hues.grays[80],
      //textAlign: 'end',
      textTransform: 'uppercase',
    },
  };
});

interface Props {
  artifact: UploadArtifactType;
  isSubmitting: boolean;
  onRemove?: () => void;
  onChangeArtifactType?: (t: string) => void;
  showProgress?: boolean;
}

const getUploadStatusTag = (
  artifact: UploadArtifactType,
  isSubmitting: boolean
) => {
  const { uploadStatus } = artifact.meta;
  let color: string;

  if (uploadStatus === 'pending' && isSubmitting) {
    color = 'blue';
  } else if (uploadStatus === 'uploading') {
    color = 'lime';
  } else if (uploadStatus === 'complete') {
    color = 'green';
  } else if (uploadStatus === 'error') {
    color = 'red';
  }

  if (color) {
    return <Tag color={color}>{uploadStatus}</Tag>;
  }

  return null;
};

export const ArtifactUploadItem = ({
  artifact,
  isSubmitting,
  onRemove,
  onChangeArtifactType,
  showProgress,
}: Props) => {
  const classes = useStyles();

  return (
    <Fade in={true}>
      <div className={classes.container}>
        <div className={classes.actions}>
          <Button
            size="small"
            shape="circle"
            type="ghost"
            onClick={onRemove}
            disabled={isSubmitting}
          >
            X
          </Button>
        </div>
        <div className={classes.artifactTypeContainer}>
          <ArtifactTypeSelect
            placeholder="Artifact Type"
            size="large"
            className={classes.artifactTypeSelect}
            value={artifact.artifactTypeSlug}
            onChange={onChangeArtifactType}
            showArrow={canChangeArtifactType(artifact)}
            disabled={!canChangeArtifactType(artifact) || isSubmitting}
          />
        </div>
        <div className={classes.name}>{artifact.file.name}</div>
        <div className={classes.tagContainer}>
          <Tag color="blue">{formatBytes(artifact.file.size)}</Tag>
          {getUploadStatusTag(artifact, isSubmitting)}
          {artifact.meta.progress && showProgress && (
            <Progress
              type="circle"
              className={classes.progress}
              width={30}
              percent={artifact.meta.progress}
            />
          )}
        </div>
      </div>
    </Fade>
  );
};
