import React, { Fragment } from 'react';

const reg = new RegExp(/^\[.*\]/);

const styles = {
  projectKey: {
    opacity: 0.9,
    marginRight: 8,
    fontWeight: '300',
    fontSize: '.9em',
    letterSpacing: '1px',
  },
};

export default function TicketName({ name }) {
  const tagMatch = name.match(reg);
  if (!tagMatch) {
    return <span>{name}</span>;
  }

  const tag = tagMatch[0];

  return (
    <Fragment>
      <span style={styles.projectKey}>{tag}</span>
      {name.replace(tag, '')}
    </Fragment>
  );
}
