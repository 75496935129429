import { MODAL_TYPES } from './modalConstants';
import CreateUpdateCollectionModal from '../collections/CreateUpdateCollectionModal/CreateUpdateCollectionModal';
import DeleteCollectionModal from '../collections/DeleteCollectionModal/DeleteCollectionModal';
import UserListModal from '../users/UserListModal';
import PipelineExecutionDetailsModal from '../pipelines/PipelineExecutionDetailsModal';
import CollectionExportsModal from '../collections/CollectionExportsModal/CollectionExportsModal';
const MODAL_CACHE = {};

registerModalComponent(
  MODAL_TYPES.CREATE_UPDATE_COLLECTION,
  CreateUpdateCollectionModal
);
registerModalComponent(MODAL_TYPES.DELETE_COLLECTION, DeleteCollectionModal);
registerModalComponent(MODAL_TYPES.USER_LIST, UserListModal);
registerModalComponent(
  MODAL_TYPES.PIPELINE_EXECUTION_DETAILS,
  PipelineExecutionDetailsModal
);
registerModalComponent(MODAL_TYPES.COLLECTION_EXPORTS, CollectionExportsModal);

export function registerModalComponent(modalType, Component) {
  MODAL_CACHE[modalType] = Component;
}

export function getModalComponent(modalType) {
  return MODAL_CACHE[modalType];
}
