import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  DeleteRequest,
  AssertionService,
  AssertionType,
  SearchRequest,
} from '@hmd/sdk/api/tests/evaluations/assertions/v1';
import sdkClient from '../../lib/hmdsdkClient';
import _ from 'lodash';
import {
  getEvaluationStatusColor,
  getEvaluationStatusName,
} from '../../services/evaluations';
import CreateAssertion from './CreateAssertion';
import { colors } from '@shield-ui/styles';
import { CloseOutlined } from '@ant-design/icons';
import { showConfirmPrompt, showError, showSuccess } from '../../lib/messages';
import { Button } from 'antd';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { prettifyString, getPrettyFullDate } from '@shield-ui/utils';

import { useUserOptions } from '../../services/users';
import tracker from '../../lib/tracker';

const useStyles = makeStyles(() => ({
  detailsContainer: {
    marginBottom: 20,
  },
  detailsTitle: {
    fontSize: 18,
    marginBottom: 8,
  },
  detailsContent: {
    fontSize: 12,
  },
  assertion: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  assertionBadge: {
    display: 'flex',
    alignItems: 'center',
  },
  assertionsContainer: {
    margin: 2,
  },
  assertionsTitle: {
    fontSize: 18,
  },
  assertionContentContainer: {
    marginBottom: 4,
  },
  assertionsTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  circle: {
    width: 25,
    height: 25,
    borderRadius: '50%',
    marginRight: 10,
    flexShrink: 0,
  },
  evaluationState: {
    fontSize: 15,
    marginBottom: 4,
  },
  userTime: {
    fontSize: 12,
    margin: 2,
    color: colors.hues.grays[60],
    display: 'flex',
    justifyContent: 'space-between',
  },
  message: {
    color: colors.hues.grays[60],
    marginBottom: 4,
  },
  assertionsList: {
    overflow: 'auto',
    maxHeight: 400,
  },
  singleDetail: {
    marginBottom: 5,
  },
}));

const getUsernameById = (userId: string, userOptions: any) => {
  if (!userOptions?.length) {
    return null;
  }
  const opt = _.find(userOptions, { value: userId });

  return opt ? opt.label : 'Unknown User';
};

const deleteEvaluationAssertion = (onDelete, id) => {
  showConfirmPrompt({
    title: 'Delete Evaluation Assertion',
    body: 'Are you sure you want to delete this evaluation assertion?',
    confirmLabel: 'Delete',
    onConfirm: () => {
      const req = new DeleteRequest();
      req.setId(id);
      tracker.trackEvent('EvaluationAssertion', 'Delete', 'id', id);
      sdkClient
        .unary(AssertionService.Delete, req)
        .then(() => {
          onDelete();
          showSuccess('Deleted Evaluation Assertion');
        })
        .catch(showError);
    },
  });
};

const fetchRobotLogEvaluationAssertions = (robotLogId, callback) => {
  const req = new SearchRequest();
  req.setRobotLogIdsList([robotLogId]);
  sdkClient
    .unary(AssertionService.Search, req)
    .then((res) => {
      const assertions = res.getAssertionsList();
      assertions.sort(function compare(a, b) {
        const dateA = new Date(
          a.getLifeCycleDetails().getCreateTime().toDate()
        );
        const dateB = new Date(
          b.getLifeCycleDetails().getCreateTime().toDate()
        );
        return dateB.getTime() - dateA.getTime();
      });

      callback(assertions);
    })
    .catch(showError);
};

type Props = {
  evaluation: any;
  isEvaluationOpen: boolean;
  closeEvaluation: (e) => void;
  onAssertionChange: () => void;
  setIsManualAssertion: (boolean) => void;
};

export default function EvaluationModal(props: Props) {
  const {
    evaluation,
    isEvaluationOpen,
    closeEvaluation,
    onAssertionChange,
    setIsManualAssertion,
  } = props;
  const [assertions, setAssertions] = useState(null);
  const classes = useStyles();
  const {data: userOptions} = useUserOptions();
  const [isCreateAssertionOpen, setIsCreateAssertionOpen] = useState(false);
  //4576
  const evaluationCallback = (asrts) => {
    setAssertions(asrts);
    if (asrts.length > 0) {
      setIsManualAssertion(true);
    } else {
      setIsManualAssertion(false);
    }
  };

  useEffect(() => {
    fetchRobotLogEvaluationAssertions(
      evaluation.getRobotLogId(),
      evaluationCallback
    );
  }, [evaluation]);

  const openCreateAssertion = () => {
    setIsCreateAssertionOpen(true);
  };

  const closeCreateAssertion = () => {
    setIsCreateAssertionOpen(false);
    fetchRobotLogEvaluationAssertions(
      evaluation.getRobotLogId(),
      evaluationCallback
    );
  };

  const formatMessage = (message) => {
    if (message.length > 0) {
      return `: ${message}`;
    } else {
      return '';
    }
  };

  const onDeleteorCreate = () => {
    onAssertionChange();
    fetchRobotLogEvaluationAssertions(
      evaluation.getRobotLogId(),
      evaluationCallback
    );
  };

  return (
    <Dialog
      open={isEvaluationOpen}
      onClose={closeEvaluation}
      fullWidth={true}
      style={{ padding: 5, overflowY: 'visible' }}
    >
      <DialogTitle>Evaluation</DialogTitle>
      <DialogContent>
        <div className={classes.detailsContainer}>
          <div className={classes.detailsTitle}>Details</div>
          <div className={classes.detailsContent}>
            {evaluation.getDetailsList().map((detail, key) => {
              return (
                <div key={key} className={classes.singleDetail}>
                  {detail}
                </div>
              );
            })}
          </div>
        </div>
        {assertions && (
          <div className={classes.assertionsContainer}>
            <div className={classes.assertionsTitleContainer}>
              <div className={classes.assertionsTitle}>
                Evaluation Overrides
              </div>
              <div>
                <Button
                  size="large"
                  htmlType="submit"
                  type="primary"
                  shape="round"
                  onClick={openCreateAssertion}
                >
                  Add
                </Button>
              </div>
              <CreateAssertion
                robotLogId={evaluation.getRobotLogId()}
                isCreateAssertionOpen={isCreateAssertionOpen}
                closeCreateAssertion={closeCreateAssertion}
                onCreate={onDeleteorCreate}
              />
            </div>
            <div className={classes.assertionsList}>
              {assertions.map((assertion, i) => (
                <div className={classes.assertion} key={i}>
                  <div className={classes.assertionBadge}>
                    <div
                      className={classes.circle}
                      style={{
                        backgroundColor: getEvaluationStatusColor(
                          assertion.getEvaluationState()
                        ),
                      }}
                    ></div>
                    <div className={classes.assertionContentContainer}>
                      <div className={classes.evaluationState}>
                        {prettifyString(
                          getEvaluationStatusName(
                            assertion.getEvaluationState()
                          )
                        )}
                      </div>
                      <div className={classes.message}>
                        {
                          Object.keys(AssertionType)[
                            assertion.getAssertionType()
                          ]
                        }
                        {formatMessage(assertion.getMessage())}
                      </div>
                      <div className={classes.userTime}>
                        {
                          <>
                            {getUsernameById(
                              assertion.getLifeCycleDetails().getCreateUserId(),
                              userOptions
                            )}
                          </>
                        }
                        {`  ${getPrettyFullDate(
                          assertion
                            .getLifeCycleDetails()
                            .getCreateTime()
                            .toDate()
                        )}`}
                      </div>
                    </div>
                  </div>
                  <div>
                    <Button
                      danger
                      onClick={() =>
                        deleteEvaluationAssertion(
                          onDeleteorCreate,
                          assertion.getId()
                        )
                      }
                    >
                      <CloseOutlined />
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button type="text" size="large" onClick={closeEvaluation}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
