import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import _ from 'lodash';
import { getStringFromErrorLike, prettifyString } from '@shield-ui/utils';
import {
  HangarCollectionType,
  HangarErrorLevel,
  HangarLogEventRecordType,
  HangarRobotLogType,
} from '@shield-ui/hangar-service';
import { gql } from '@apollo/client';
import client from '../apollo-client';
import {
  diagnosticBuildMergedLogEvents,
  diagnosticGetLeafLogEventsFromMergedRecord,
  HangarLogEventRecordTypeMerged,
  searchLogEvents,
} from '../services/logEvents';
import {
  ComponentDisplayCountType,
  ComponentExternalIdCountMap,
} from '@shield-ui/sysml';
import { RootState } from './store';

export const DIAGNOSTIC_EXPLORER_FEATURE_KEY = 'diagnosticExplorer';

export enum CardListViewModes {
  root = 0,
  leaf = 1,
}
enum MainContentFocusModes {
  systemDiagram = 0,
  cardDetails = 1,
  stats = 2,
}

export const enums = {
  MainContentFocusModes,
  CardListViewModes,
};

export type CurrentContext = {
  type: 'collection' | 'robotLog';
  robotLogId?: HangarRobotLogType['id'];
  collectionId?: HangarCollectionType['id'];
};

interface DiagnosticExplorerState {
  contextName: string; // E.g. Collection Name
  currentContext?: CurrentContext;
  fetching: boolean;
  fetched: boolean;
  fetchingMessage?: string;
  errorMessage?: string;
  robotLogs: HangarRobotLogType[];
  logEvents: HangarLogEventRecordType[];
  childLogEvents: HangarLogEventRecordType[];
  mainContentFocus: {
    mode: MainContentFocusModes;
    contextProps?: {
      logEventCard?: LogEventCard;
    };
  };
  filters: {
    componentOwners: string[];
    componentExternalIds: number[];
    rootLogDefinitionExternalIds: number[];
    leafLogDefinitionExternalIds: number[];
    levels: HangarErrorLevel[];
  };
  options: {
    cardListViewMode: CardListViewModes;
  };
}

const initialDiagnosticExplorerState: DiagnosticExplorerState = {
  contextName: '',
  currentContext: undefined,
  fetching: false,
  // Need times
  robotLogs: [],
  fetched: false,
  fetchingMessage: '',
  logEvents: [],
  childLogEvents: [],
  mainContentFocus: {
    mode: MainContentFocusModes.systemDiagram,
    contextProps: {},
  },
  filters: {
    componentOwners: [],
    componentExternalIds: [],
    rootLogDefinitionExternalIds: [],
    leafLogDefinitionExternalIds: [],
    levels: [HangarErrorLevel.Fatal, HangarErrorLevel.Error],
  },
  options: {
    cardListViewMode: CardListViewModes.leaf,
  },
};

/**
 * Given an array of root logEvents, pluck out all of the leaves in flattened array
 * We clear this cache when the collection context changes (when refresh is called)
 */
const getLeafLogEventsCached = _.memoize(
  diagnosticGetLeafLogEventsFromMergedRecord
);

type GetBasicsReturnValue = Promise<{
  name: HangarCollectionType['name'];
  robotLogs: HangarRobotLogType[];
}>;

const rlFragment = `
  id
  name
  startTime
`;

function getCollectionBasics(collectionId): GetBasicsReturnValue {
  return client
    .query({
      query: gql`
        query ($collectionId: String!, $sessionLogTypeSlugs: [String]) {
          collection(id: $collectionId) {
            name
            robotLogs(
              sort: start_time_desc
              sessionLogTypeSlugs: $sessionLogTypeSlugs
            ) {
              edges {
                node {
                  id
                  name
                  startTime
                }
              }
            }
          }
        }
      `,
      variables: {
        collectionId,
        sessionLogTypeSlugs: ['flight'],
      },
      fetchPolicy: 'no-cache',
    })
    .then((r) => {
      return {
        name: _.get(r, 'data.collection.name', 'Collection'),
        robotLogs: _.get(r, 'data.collection.robotLogs.edges', []).map(
          (edge) => edge.node
        ),
      };
    });
}

function getRobotLogBasics(robotLogId): GetBasicsReturnValue {
  // query represents test > trial > flight relationship but will work if the
  // robot log id is any of these
  return client
    .query({
      query: gql`
        query($robotLogId: String!) {
          robotLog(id: $robotLogId) {
            ${rlFragment}
            sessionLogTypeSlug
            children {
              ${rlFragment}
              sessionLogTypeSlug
              children {
                ${rlFragment}
                sessionLogTypeSlug
              }
            }
          }
        }
      `,
      variables: {
        robotLogId,
      },
      fetchPolicy: 'no-cache',
    })
    .then((r) => {
      const getAllFlightRobotLogs = (robotLog, flatList = []) => {
        if (robotLog.sessionLogTypeSlug === 'flight') {
          flatList.push(robotLog);
        }
        if (robotLog.children) {
          robotLog.children.forEach((childRobotLog) => {
            getAllFlightRobotLogs(childRobotLog, flatList);
          });
        }
        return flatList;
      };

      return {
        name: r.data.robotLog.name,
        robotLogs: getAllFlightRobotLogs(r.data.robotLog),
      };
    });
}

function getContextBasics(context: CurrentContext): GetBasicsReturnValue {
  if (context.type === 'collection') {
    return getCollectionBasics(context.collectionId);
  } else if (context.type === 'robotLog') {
    return getRobotLogBasics(context.robotLogId);
  }
}

/**
 * Refresh
 * This "Searches" and loads everything needed to drive the DiagnosticExplorer
 */
export interface RefreshLogEventsArgs {
  context: CurrentContext;
}
type RefreshLogEventsPayload = Pick<
  DiagnosticExplorerState,
  'robotLogs' | 'logEvents' | 'childLogEvents' | 'contextName'
>;

export const refreshLogEvents = createAsyncThunk(
  `${DIAGNOSTIC_EXPLORER_FEATURE_KEY}/refreshLogEvents`,
  async (args: RefreshLogEventsArgs, thunkAPI) => {
    const { context } = args;

    getLeafLogEventsCached.cache.clear();

    const { robotLogs, name } = await getContextBasics(context);
    if (!robotLogs.length) {
      return {
        contextName: name,
        robotLogs,
        logEvents: [],
        childLogEvents: [],
      };
    }
    const chunkSize = 100;
    const bagNames = _.map(robotLogs, 'name');
    const allLogEvents = [];
    const allChildren = [];

    const chunks = _.chunk(bagNames, chunkSize);

    for (let i = 0; i < chunks.length; i++) {
      const chunkBagNames = chunks[i];

      const soFar = chunkSize * i;
      thunkAPI.dispatch(
        diagnosticExplorerSlice.actions.setFetchingDisplay({
          message: `${Math.round(
            (i / chunks.length) * 100
          )}% - Fetching diagnostics for ${soFar + 1} to ${
            soFar + chunkBagNames.length
          } of ${bagNames.length} flights`,
        })
      );

      const { logEvents, children } = await searchLogEvents({
        sourceExternalIds: chunkBagNames,
        resultsPerPage: 99999,
      });
      allLogEvents.push(...logEvents);
      allChildren.push(...children);
    }

    /*
    let reqCount = 0;
    let totalCount = undefined;
    const perPage = 1;

    while (
      // first loop
      totalCount === undefined ||
      // keep going until we get them all
      allLogEvents.length < totalCount ||
      // fail safe to make sure we don't req more times that we should (e.g. something disappeared)
      reqCount * perPage > totalCount
    ) {
      reqCount += 1;
      const { logEvents, children, recordCount } = await searchLogEvents({
        sourceExternalIds: bagNames,
        resultsPerPage: perPage,
        pageNumber: reqCount,
      });

      if (totalCount === undefined) {
        totalCount = recordCount || 0;
      }

      allLogEvents.push(...logEvents);
      allChildren.push(...children);
    }
     */

    return {
      contextName: name,
      robotLogs,
      logEvents: allLogEvents,
      childLogEvents: allChildren,
    };
  }
);

export const diagnosticExplorerSlice = createSlice({
  name: DIAGNOSTIC_EXPLORER_FEATURE_KEY,
  initialState: initialDiagnosticExplorerState,
  reducers: {
    setFetchingDisplay: (state, action: PayloadAction<{ message: string }>) => {
      const { message } = action.payload;
      state.fetchingMessage = message;
    },
    setFilters: (
      state,
      action: PayloadAction<{
        filters: Partial<DiagnosticExplorerState['filters']>;
      }>
    ) => {
      const { filters } = action.payload;
      state.filters = {
        ...state.filters,
        ...filters,
      };
    },
    setOptions: (
      state,
      action: PayloadAction<{
        options: Partial<DiagnosticExplorerState['options']>;
      }>
    ) => {
      const { options } = action.payload;
      state.options = {
        ...state.options,
        ...options,
      };
    },
    switchMainContentFocus: (
      state,
      action: PayloadAction<{
        mode: DiagnosticExplorerState['mainContentFocus']['mode'];
        contextProps?: Partial<
          DiagnosticExplorerState['mainContentFocus']['contextProps']
        >;
      }>
    ) => {
      const { mode, contextProps = {} } = action.payload;

      state.mainContentFocus = {
        mode,
        contextProps,
      };
    },
  },
  extraReducers: {
    [refreshLogEvents.pending.type]: (
      state,
      action: PayloadAction<object, string, { arg: RefreshLogEventsArgs }>
    ) => {
      const { context } = action.meta.arg;

      Object.keys(initialDiagnosticExplorerState).forEach((k) => {
        state[k] = initialDiagnosticExplorerState[k];
      });
      state.currentContext = context;
      state.fetching = true;
    },
    [refreshLogEvents.rejected.type]: (
      state,
      action: PayloadAction<
        object,
        string,
        { arg: RefreshLogEventsArgs },
        Error
      >
    ) => {
      const { error } = action;
      state.errorMessage = getStringFromErrorLike(error);
    },
    [refreshLogEvents.fulfilled.type]: (
      state,
      action: PayloadAction<
        RefreshLogEventsPayload,
        string,
        { arg: RefreshLogEventsArgs }
      >
    ) => {
      const { robotLogs, logEvents, childLogEvents, contextName } =
        action.payload;

      state.fetching = false;
      state.fetched = true;
      state.contextName = contextName;
      state.robotLogs = robotLogs;
      state.logEvents = logEvents;
      state.childLogEvents = childLogEvents;
    },
  },
});
// Export simple actions from slice
export const { setFilters, setOptions, switchMainContentFocus } =
  diagnosticExplorerSlice.actions;

// Selectors
export function diagnosticExplorerStateSelector(
  state
): DiagnosticExplorerState {
  return state[DIAGNOSTIC_EXPLORER_FEATURE_KEY];
}

/**
 * Base selectors used in composition below
 * @param state
 */
const logEventsSelector = (state: RootState) =>
  state[DIAGNOSTIC_EXPLORER_FEATURE_KEY].logEvents;
const childLogEventsSelector = (state: RootState) =>
  state[DIAGNOSTIC_EXPLORER_FEATURE_KEY].childLogEvents;
const robotLogsSelector = (state: RootState) =>
  state[DIAGNOSTIC_EXPLORER_FEATURE_KEY].robotLogs;
const cardListViewModeSelector = (state: RootState) =>
  state[DIAGNOSTIC_EXPLORER_FEATURE_KEY].options.cardListViewMode;
const filtersSelector = (
  state: RootState
): DiagnosticExplorerState['filters'] =>
  state[DIAGNOSTIC_EXPLORER_FEATURE_KEY].filters;

/**
 * Merges the children and records logEvents as returned by the backend
 * into a nested tree
 */
const mergedLogEventsSelector = createSelector(
  logEventsSelector,
  childLogEventsSelector,
  (logEvents, childLogEvents) => {
    return diagnosticBuildMergedLogEvents(logEvents, childLogEvents);
  }
);

/**
 * Filter logEvents based on selected filters
 * This used to serve a the single filter but now cards are filtered and things are built off cards
 * Might need to rethink the selector layers here
 */
export const filteredLogEventsSelector = createSelector(
  mergedLogEventsSelector,
  filtersSelector,
  (logEvents, filters: DiagnosticExplorerState['filters']) => {
    const {
      componentOwners,
      levels,
      componentExternalIds,
      rootLogDefinitionExternalIds,
      leafLogDefinitionExternalIds,
    } = filters;

    if (
      _.isEmpty(rootLogDefinitionExternalIds) &&
      _.isEmpty(leafLogDefinitionExternalIds) &&
      _.isEmpty(componentOwners) &&
      _.isEmpty(levels) &&
      _.isEmpty(componentExternalIds)
    ) {
      return logEvents;
    }

    const componentTreeFilterMatch = (
      logEvent: HangarLogEventRecordTypeMerged
    ) => {
      const doesMatch =
        (!componentOwners.length ||
          componentOwners.includes(logEvent.componentOwner)) &&
        (!levels.length || levels.includes(logEvent.level)) &&
        (!componentExternalIds.length ||
          componentExternalIds.includes(logEvent.componentExternalId));
      if (doesMatch) {
        return true;
      }

      return !!_.find(logEvent.logEvents, componentTreeFilterMatch);
    };

    return logEvents.filter((logEvent) => {
      if (!componentTreeFilterMatch(logEvent)) {
        return false;
      }

      if (
        rootLogDefinitionExternalIds.length &&
        !rootLogDefinitionExternalIds.includes(logEvent.logDefinitionExternalId)
      ) {
        return false;
      }

      if (leafLogDefinitionExternalIds.length) {
        const leaves = getLeafLogEventsCached(logEvent);
        const match = _.find(leaves, (leafLogEvent) => {
          return leafLogDefinitionExternalIds.includes(
            leafLogEvent.logDefinitionExternalId
          );
        });
        if (!match) {
          return false;
        }
      }

      return true;
    });
  }
);

export type LogEventCard = {
  logEvent: HangarLogEventRecordTypeMerged;
  rootLogEvent: HangarLogEventRecordTypeMerged;
  robotLog: HangarRobotLogType;
  isFirstForRobotLog?: boolean;
  isLastForRobotLog?: boolean;
};

/**
 *
 * Based on the view mode of the card list return a flat view of cards to view
 */
export const logEventCardsSelector = createSelector(
  cardListViewModeSelector,
  filteredLogEventsSelector,
  filtersSelector,
  robotLogsSelector,
  (cardListViewMode, filteredLogEvents, filters, robotLogs): LogEventCard[] => {
    const groupBySource = _.groupBy(filteredLogEvents, 'sourceExternalId');
    const markLast = (cards: LogEventCard[]) => {
      if (!cards.length) {
        return;
      }

      _.last(cards).isLastForRobotLog = true;
    };

    // process all the matching roots / leafs for each robotLog at a time
    const cards = robotLogs.reduce((cards: LogEventCard[], robotLog) => {
      const rootLogEvents = groupBySource[robotLog.name];
      if (!rootLogEvents || !rootLogEvents.length) {
        return cards;
      }

      markLast(cards);

      if (cardListViewMode === CardListViewModes.leaf) {
        let isFirst = true;
        rootLogEvents.forEach((rootLogEvent) => {
          const leaves = getLeafLogEventsCached(rootLogEvent);
          leaves.forEach((leafLogEvent) => {
            cards.push({
              logEvent: leafLogEvent,
              rootLogEvent,
              robotLog,
              isFirstForRobotLog: isFirst,
            });
            isFirst = false;
          });
        });
      } else if (cardListViewMode === CardListViewModes.root) {
        rootLogEvents.forEach((rootLogEvent, index) => {
          cards.push({
            logEvent: rootLogEvent,
            rootLogEvent,
            robotLog,
            isFirstForRobotLog: index === 0,
          });
        });
      }

      return cards;
    }, [] as LogEventCard[]);

    return cards.filter((logEventCard) => {
      const { logEvent } = logEventCard;
      return (
        (!filters.componentExternalIds.length ||
          filters.componentExternalIds.includes(
            logEvent.componentExternalId
          )) &&
        (!filters.componentOwners.length ||
          filters.componentOwners.includes(logEvent.componentOwner)) &&
        (!filters.levels.length || filters.levels.includes(logEvent.level))
      );
    });
  }
);

/**
 * Return the componentExternalId counts based on our filtered logEvents (per level)
 * This is used to drive the counts in the SYSML DAG
 */
export const componentExternalIdCountMapSelector = createSelector(
  logEventCardsSelector,
  (logEventCards): ComponentExternalIdCountMap => {
    const levelToType: Partial<
      Record<HangarErrorLevel, ComponentDisplayCountType>
    > = {
      [HangarErrorLevel.Fatal]: 'fatal',
      [HangarErrorLevel.Error]: 'error',
      [HangarErrorLevel.Warn]: 'warn',
      [HangarErrorLevel.Info]: 'info',
      [HangarErrorLevel.Debug]: 'debug',
    };
    const map = logEventCards.reduce((acc, logEventCard) => {
      const { logEvent } = logEventCard;
      const { componentExternalId, level } = logEvent;
      if (!acc[componentExternalId]) {
        acc[componentExternalId] = {};
      }
      const type: ComponentDisplayCountType = levelToType[level] || 'debug';

      if (!acc[componentExternalId][type]) {
        acc[componentExternalId][type] = 0;
      }
      acc[componentExternalId][type] += 1;
      return acc;
    }, {});

    return Object.keys(map).reduce((acc, id) => {
      const typeMap = map[id];
      acc[id] = Object.keys(typeMap).reduce((list, type) => {
        list.push({
          type,
          count: typeMap[type],
        });
        return list;
      }, []);

      return acc;
    }, {});
  }
);

/**
 * Return the occurrence of each unique log definition per robot log
 * E.g if a single bag has 100 of log definition 100, then we will have 1 bag that has that definition.
 *
 * Return the raw count stats based on our filtered logEvents
 * E.g. if we have 1 bag with diagnostics and it had 100 of the same root definition
 * we get a count of 100 for that
 */
export const filteredLogDefinitionStatsSelector = createSelector(
  cardListViewModeSelector,
  logEventCardsSelector,
  robotLogsSelector,
  (cardListViewMode, logEventCards, robotLogs) => {
    const counts = {
      root: {},
      leaf: {},
    };
    const uniqueDefs = {
      root: {},
      leaf: {},
    };
    const uniqueSources = {};
    const logDefNames = {};

    const delimiter = '~~';

    const addTo = (logEventType, logEvent: HangarLogEventRecordTypeMerged) => {
      const {
        logDefinitionExternalId: defId,
        logDefinitionName,
        sourceExternalId,
        componentOwner,
      } = logEvent;
      logDefNames[defId] = logDefinitionName;

      const defComponent = `${defId}${delimiter}${componentOwner}`;

      // count
      if (!counts[logEventType][defComponent]) {
        counts[logEventType][defComponent] = 0;
      }
      counts[logEventType][defComponent] += 1;

      // occurrence
      const occurUniqueKey = `${defComponent}${delimiter}${sourceExternalId}`;
      uniqueDefs[logEventType][occurUniqueKey] = true;
      uniqueSources[sourceExternalId] = true;
    };

    const alreadySeenLeafRoots = [];
    logEventCards.forEach((logEventCard) => {
      if (cardListViewMode === CardListViewModes.root) {
        const { rootLogEvent } = logEventCard;
        const leaves = getLeafLogEventsCached(rootLogEvent);
        addTo('root', rootLogEvent);
        leaves.forEach((leafLogEvent) => {
          addTo('leaf', leafLogEvent);
        });
      } else {
        const { logEvent, rootLogEvent } = logEventCard;

        addTo('leaf', logEvent);
        // only count these once
        if (!alreadySeenLeafRoots.includes(rootLogEvent)) {
          alreadySeenLeafRoots.push(rootLogEvent);
          addTo('root', rootLogEvent);
        }
      }
    });

    /**
     * Take the unique map of things like <ID>~~<ROBOT_LOG> and split out
     * the ID counting it once
     */
    const formatUniqueDefs = (defSourceMap) => {
      return _.reduce(
        defSourceMap,
        (acc, value, key) => {
          const split = key.split(delimiter);
          const defComponent = split.slice(0, -1).join(delimiter);
          if (!acc[defComponent]) {
            acc[defComponent] = 0;
          }
          acc[defComponent] += 1;
          return acc;
        },
        {}
      );
    };

    /**
     * Turn a count map into an array of pertinent values
     */
    const countMapToSortedArray = (countMap) => {
      return _.sortBy(
        _.map(countMap, (count, logDefOwner) => {
          const [logDefinitionExternalId, componentOwner] =
            logDefOwner.split(delimiter);
          return {
            display: `${prettifyString(
              logDefNames[logDefinitionExternalId]
            )} (${componentOwner})`,
            logDefinitionExternalId,
            componentOwner,
            count,
          };
        }),
        'count'
      ).reverse();
    };

    return {
      totalRobotLogs: robotLogs.length,
      uniqueRobotLogs: Object.keys(uniqueSources).length,
      logDefinitionTotalCounts: {
        root: countMapToSortedArray(counts.root),
        leaf: countMapToSortedArray(counts.leaf),
      },
      logDefinitionOccurrenceCounts: {
        root: countMapToSortedArray(formatUniqueDefs(uniqueDefs.root)),
        leaf: countMapToSortedArray(formatUniqueDefs(uniqueDefs.leaf)),
      },
    };
  }
);

/**
 * For use by the front end, use complete merged log events to use options that will drive the front end
 * We could use controls that have all defs, components, etc.. but this way we are smarter and only
 * show applicable options
 */
export const filterOptionsSelector = createSelector(
  mergedLogEventsSelector,
  (mergedLogEvents) => {
    const componentOwners = [];
    const rootLogDefinitionExternalIds = [];
    const leafLogDefinitionExternalIds = [];
    const defIdMap = {};

    const loop = (logEventList, isUnderRoot = false) => {
      logEventList.forEach((logEvent) => {
        const { componentOwner, logDefinitionExternalId, logDefinitionName } =
          logEvent;

        if (componentOwner && !componentOwners.includes(componentOwner)) {
          componentOwners.push(componentOwner);
        }

        if (
          !isUnderRoot &&
          !rootLogDefinitionExternalIds.includes(logDefinitionExternalId)
        ) {
          rootLogDefinitionExternalIds.push(logDefinitionExternalId);
          defIdMap[logDefinitionExternalId] = logDefinitionName;
        }

        if (
          logEvent.logEvents.length === 0 &&
          !leafLogDefinitionExternalIds.includes(logDefinitionExternalId)
        ) {
          leafLogDefinitionExternalIds.push(logDefinitionExternalId);
          defIdMap[logDefinitionExternalId] = logDefinitionName;
        }

        loop(logEvent.logEvents, true);
      });
    };

    loop(mergedLogEvents);

    return {
      componentOwnerOptions: _.sortBy(
        componentOwners.map((owner) => ({
          label: owner,
          value: owner,
        })),
        'label'
      ),
      rootLogDefinitionOptions: _.sortBy(
        rootLogDefinitionExternalIds.map((id) => ({
          label: prettifyString(defIdMap[id]),
          value: id,
        })),
        'label'
      ),
      leafLogDefinitionOptions: _.sortBy(
        leafLogDefinitionExternalIds.map((id) => ({
          label: prettifyString(defIdMap[id]),
          value: id,
        })),
        'label'
      ),
    };
  }
);
