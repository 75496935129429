import { createActionPrefix } from 'redux-nano';
import _ from 'lodash';
import { showErrorSnack } from '../snackbar/actions';
import { serializeStateKeys } from './constants';

const prefix = createActionPrefix('TABLES');
export const SET_PAGINATION = prefix('SET_PAGINATION');
export const RESET_PAGINATION = prefix('RESET_PAGINATION');
export const SET_SORT = prefix('SET_SORT');
export const SET_COLUMNS = prefix('SET_COLUMNS');
export const RESET_COLUMNS = prefix('RESET_COLUMNS');
export const UPDATE_COLUMN_WIDTHS = prefix('UPDATE_COLUMN_WIDTHS');
export const UPDATE_COLUMN_ORDER = prefix('UPDATE_COLUMN_ORDER');
export const UPDATE_PINNED_COLUMNS = prefix('UPDATE_PINNED_COLUMNS');
export const SET_PAGE_SIZE = prefix('SET_PAGE_SIZE');
export const SET_TABLE_SEARCH_RESULT = prefix('SET_TABLE_SEARCH_RESULT');
export const SET_VISIBLE_MODAL = prefix('SET_VISIBLE_MODAL');
export const RESET_TABLE_SEARCH_RESULT = prefix('RESET_TABLE_SEARCH_RESULT');
export const INITIALIZE_TABLE_CACHE = prefix('INITIALIZE_TABLE_CACHE');
export const REMOVE_TABLE_CACHE = prefix('REMOVE_TABLE_CACHE');
export const TOGGLE_FILTERS_PANEL = prefix('TOGGLE_FILTERS_PANEL');
export const SET_FILTERS = prefix('SET_FILTERS');
export const RESET_FILTERS = prefix('RESET_FILTERS');
export const FORCE_RESULT_REFRESH = prefix('FORCE_RESULT_REFRESH');
export const PROMPT_LOAD_SAVE_STATE = prefix('PROMPT_LOAD_SAVE_STATE');
export const LOAD_SAVE_STATE = prefix('LOAD_SAVE_STATE');

export function setColumns({ columns, getColumnDefinition, tableCacheKey, movedColumn }) {
  return (dispatch, getState) => {
    const state = getState();
    const sort = _.get(state, ['tables', 'cache', tableCacheKey, 'sort'], {});
    const defaultSort = _.get(state, [
      'tables',
      'cache',
      tableCacheKey,
      'defaultSort',
    ]);
    const { orderByColumn } = sort;

    // when settings new columns, ensure the sort value is at least of a column visible
    // or set it to the default one if there are no columns with sort values
    let sortFallback = null;
    const sortExists = _.find(columns, ({ columnKey }) => {
      const def = getColumnDefinition({ columnKey });

      if (def && def.sortKey && !sortFallback) {
        sortFallback = columnKey;
      }

      return columnKey === orderByColumn;
    });

    if (!sortExists) {
      dispatch(
        setSort({
          tableCacheKey,
          orderByColumn: sortFallback || defaultSort || undefined,
        })
      );
    }

    dispatch(
      SET_COLUMNS({
        tableCacheKey,
        columns,
        movedColumn
      })
    );
  };
}

const SHARE_STATE_VERSION = 3;

export function getShareUrl({ tableCacheKey, callback }) {
  return function dispatchGetShareUrl(dispatch, getState) {
    const state = getState();
    const table = state.tables.cache[tableCacheKey];

    // Everything serialized
    const saveState = _.pick(table, serializeStateKeys);
    saveState.tableCacheKey = tableCacheKey;
    saveState.ver = SHARE_STATE_VERSION;

    const payload = { tables: saveState };

    const shareUrl = [
      window.location.protocol,
      '//',
      window.location.host,
      window.location.pathname,
      '#',
      encodeURIComponent(JSON.stringify(payload)),
    ].join('');

    return callback(null, { url: shareUrl });
  };
}

export function testShareUrlLoad() {
  return function dispatchTestShareUrlLoad(dispatch, getState) {
    const routeHash = window.location.hash;

    // nothing to do
    if (!routeHash) {
      return;
    }

    const hashPayload = routeHash.split('#')[1];
    if (!hashPayload) {
      return;
    }

    try {
      const parsedState = JSON.parse(decodeURIComponent(hashPayload));
      const { tables } = parsedState;

      if (!tables) {
        return dispatch(
          showErrorSnack(
            `Share state is invalid shape for tables, missing 'tables' property`
          )
        );
      }

      const saveState = tables;
      if (saveState.ver !== SHARE_STATE_VERSION) {
        return dispatch(
          showErrorSnack(
            `Trying to load save state from version ${saveState.ver} but current version is ${SHARE_STATE_VERSION}. This is out of date and can't be loaded`
          )
        );
      }

      const state = getState();
      const initializedTable = state.tables.cache[saveState.tableCacheKey];

      if (!initializedTable) {
        return dispatch(
          showErrorSnack(
            `Trying to load save state for table ${saveState.tableCacheKey} but we don't have that table type initialized`
          )
        );
      }

      // dispatch(loadSaveState({ saveState }))
      dispatch(PROMPT_LOAD_SAVE_STATE({ saveState }));
    } catch (e) {
      dispatch(
        showErrorSnack({
          message: `Problem loading save state from # on url ${e}`,
        })
      );
    }
  };
}

export function loadSaveState({ saveState }) {
  return function dispatchLoadSaveState(dispatch) {
    if (window.location.hash && window.location.hash.length > 1) {
      window.location.hash = '#';
    }

    dispatch(LOAD_SAVE_STATE({ saveState }));
  };
}

export const setSort = SET_SORT;
export const setPagination = SET_PAGINATION;
export const resetPagination = RESET_PAGINATION;
export const resetColumns = RESET_COLUMNS;
export const updateColumnWidths = UPDATE_COLUMN_WIDTHS;
export const updateColumnOrder = UPDATE_COLUMN_ORDER;
export const updatePinnedColumns = UPDATE_PINNED_COLUMNS;
export const setPageSize = SET_PAGE_SIZE;
export const setTableSearchResult = SET_TABLE_SEARCH_RESULT;
export const resetTableSearchResult = RESET_TABLE_SEARCH_RESULT;
export const initializeTableCache = INITIALIZE_TABLE_CACHE;
export const removeTableCache = REMOVE_TABLE_CACHE;
export const setVisibleModal = SET_VISIBLE_MODAL;
export const toggleFiltersPanel = TOGGLE_FILTERS_PANEL;
export const setFilters = SET_FILTERS;
export const resetFilters = RESET_FILTERS;
export const forceResultRefresh = FORCE_RESULT_REFRESH;
