import simpleObjectGetValue from '../common/simpleObjectGetValue';
import Cell, { parseResultsFromRow } from './Cell';

const config = {
  columnKey: 'analyzerOverview',
  label: 'Analysis Overview',
  description: 'Number of passed and ran expectations',
  defaultWidth: 200,
  Cell,
  getValue: ({ row }) => simpleObjectGetValue(parseResultsFromRow(row)),
  fragment: `
        analyses {
            edges {
                node {
                    name
                    runStatus
                    testPassed
                }
            }
        }
    `,
};

export default config;
