import React, { useState, useMemo, useCallback } from 'react';
import _ from 'lodash';
import SearchInput from '../shared/SearchInput';

import {
  KeywordsControlData,
  KeywordsQueryFilterValue,
} from '../../filterTypes';
import { ControlProps } from '../common';

import { Tooltip } from 'antd';
import { colors } from '@shield-ui/styles';

interface Props
  extends ControlProps<KeywordsQueryFilterValue>,
    KeywordsControlData {}

export default function Keywords(props: Props) {
  const [tmpInputValue, setTmpInputValue] = useState(undefined);
  const [isValidQuery, setIsValidQuery] = useState(true);
  const [explanation, setExplanation] = useState('');
  const { value, onChange, placeholder = 'Search', validateInput } = props;

  const debouncedOnChange = useMemo(() => {
    return _.debounce((value, onChange) => {
      validateInput(value).then((res) => {
        if (res.isValid) {
          onChange({ input: value });
          setTmpInputValue(undefined);
          setIsValidQuery(true);
          setExplanation('');
        } else {
          setIsValidQuery(false);
          setExplanation(res['message']);
        }
      });
    }, 1000);
  }, []);

  const inputOnChange = useCallback(
    (evt) => {
      setTmpInputValue(evt.target.value);
      debouncedOnChange(evt.target.value, onChange);
    },
    [setTmpInputValue]
  );

  let v = tmpInputValue;
  if (v === undefined) {
    v = value ? value.input : '';
  }

  let borderStyle = {};
  if (!isValidQuery) {
    borderStyle = { borderColor: colors.hues.red };
  }

  return (
    <>
      <Tooltip
        placement="bottomLeft"
        title={explanation}
        visible={!isValidQuery}
        overlayStyle={{ maxWidth: '30vw' }}
      >
        <SearchInput
          placeholder={placeholder}
          value={v}
          onChange={inputOnChange}
          style={borderStyle}
        />
      </Tooltip>
    </>
  );
}
