import React from 'react';
import _ from 'lodash';
import masterNotesConfig from '../participants';
import { PARTICIPANT_ROLE_CHOICES } from '../../../../../services/participants';

function participantFilter(participant) {
  return (
    _.get(participant, 'participantRole') === PARTICIPANT_ROLE_CHOICES.PILOT
  );
}

const config = {
  ...masterNotesConfig,
  columnKey: 'participantsPilot',
  label: 'Pilot',
  sortKey: 'participant_pilots.name.keyword',
  description: 'Pilot participants in a flight',
  Cell: ({ row }) => {
    return (
      <masterNotesConfig.Cell row={row} participantFilter={participantFilter} />
    );
  },
  getValue: ({ row }) => {
    return masterNotesConfig.getValue({ row, participantFilter });
  },
};

export default config;
