import React from 'react';
import _ from 'lodash';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {
  registerConfirmPromptComponent,
  unsetConfirmPromptComponent,
} from '../lib/messages';

function styles() {
  return {
    content: {
      minWidth: 480,
    },
  };
}

class ConfirmPrompt extends React.Component {
  state = {
    isOpen: false,
    title: '',
    body: '',
    confirmLabel: '',
    onConfirm: _.noop,
  };

  reset() {
    this.setState({
      isOpen: false,
      onConfirm: _.noop,
    });

    setTimeout(() => {
      const { isOpen } = this.state;
      if (isOpen) {
        return;
      }

      this.setState({
        title: '',
        body: '',
        confirmLabel: '',
      });
    }, 500);
  }

  handleRequest(showProps) {
    this.setState({
      ...showProps,
      isOpen: true,
    });
  }

  handleClose = () => {
    this.reset();
  };

  handleConfirm = () => {
    const { onConfirm } = this.state;

    onConfirm();
    this.reset();
  };

  componentDidMount() {
    registerConfirmPromptComponent(this);
  }

  componentWillUnmount() {
    unsetConfirmPromptComponent();
  }

  render() {
    const { isOpen, title, body, confirmLabel } = this.state;
    const { classes } = this.props;

    return (
      <Dialog
        open={isOpen}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title || 'Are you sure...'}
        </DialogTitle>
        <DialogContent classes={{ root: classes.content }}>
          <DialogContentText id="alert-dialog-description">
            {body || 'Do you want to proceed with this action?'}
          </DialogContentText>
          <DialogActions>
            <Button onClick={this.handleClose} size='small' style={{ marginRight: 16 }}>
              Cancel
            </Button>
            <Button
              onClick={this.handleConfirm}
              size='small'
              color="primary"
              variant="contained"
              autoFocus
            >
              {confirmLabel || 'Proceed'}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ConfirmPrompt);
