import { useState } from 'react';
import cx from 'classnames';
import Paper from '@mui/material/Paper';
import { LazyLog } from 'react-lazylog';
import makeStyles from '@mui/styles/makeStyles';
import EmptyContainer from '../../../components/EmptyContainer';
import { AntButton } from '@shield-ui/buttons';
import { Artifact } from '@hmd/sdk/api/artifacts/v1';
import { useArtifactContents } from '../../../services/newArtifacts';

const useStyles = makeStyles((theme) => ({
  generic: {
    width: '100%',
    height: 600,
    cursor: 'auto',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  logWrapper: {
    flex: 1,
  },
  wrapperFullscreen: {
    zIndex: 99999,
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    padding: '1vh 1vw',
    background: 'rgba(0, 0, 0, .5)',
    top: 0,
    left: 0,
  },
  controls: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(0.5),
  },
}));

type Props = {
  artifact: Artifact.AsObject;
};

const TextExpansion = ({ artifact }: Props) => {
  const classes = useStyles();
  const [isFullscreen, setIsFullscreen] = useState(false);

  const { data } = useArtifactContents(artifact.id);

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  return (
    <div
      className={cx(classes.generic, isFullscreen && classes.wrapperFullscreen)}
    >
      {artifact.publicUri === '' ? (
        <EmptyContainer iconName="noFlight">
          {artifact.filename} signed url failure
        </EmptyContainer>
      ) : data === '' ? (
        <EmptyContainer iconName="noFlight">
          {artifact.filename} empty
        </EmptyContainer>
      ) : (
        <Paper className={classes.content}>
          <div className={classes.controls}>
            <AntButton onClick={toggleFullscreen}>
              {isFullscreen ? 'Cancel Fullscreen' : 'Fullscreen'}
            </AntButton>
          </div>
          <div className={classes.logWrapper}>
            <LazyLog
              url={artifact.publicUri}
              fetchOptions={{
                headers: {
                  ...artifact.publicHeadersMap.reduce((acc, header) => {
                    acc[header[0]] = header[1];
                    return acc;
                  }, {}),
                },
              }}
              extraLines={1}
              selectableLines
              enableSearch
              caseInsensitive
              overscanRowCount={200}
              style={{
                backgroundColor: 'rgba(0, 0, 0, .5)',
              }}
            />
          </div>
        </Paper>
      )}
    </div>
  );
};

export default TextExpansion;
