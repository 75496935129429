import _ from 'lodash';
import {
  BaseFilterValue,
  BaseQueryFilter,
  BaseFilterProps,
} from './baseQueryFilter';
import { ListOperator, QueryFilterTypes } from './common';

type FieldOption = { value: string; label: string };

export enum AttributeItemOperator {
  equals = 'eq',
  notEquals = 'ne',
  lessThan = 'lt',
  greaterThan = 'gt',
}

type AttributeItem = {
  field?: string;
  operator?: AttributeItemOperator;
  value?: string;
};

export interface AttributeValueCollectorQueryFilterValue
  extends BaseFilterValue {
  items?: AttributeItem[];
  listOperator?: ListOperator;
}

export interface AttributeValueCollectorControlData {
  fieldOptions: FieldOption[];
  fieldPlaceholder: string;
  valuePlaceholder: string;
  itemOperators: AttributeItemOperator[];
  defaultItemOperator: AttributeItemOperator;
  getItemOperatorDisplay: (AttributeItemOperator) => string;
  getValidItemCountFromValue: (
    AttributeValueCollectorQueryFilterValue
  ) => number;
}

export interface AttributeValueCollectorQueryFilterProps
  extends BaseFilterProps {
  // The parent field of which we are operating on child fields for
  esField?: string;
  dynamicFields?: boolean;
  fetchFieldOptions?: () => Promise<FieldOption[]>;
  itemOperators?: AttributeItemOperator[];
}

export class AttributeValueCollectorQueryFilter extends BaseQueryFilter<
  AttributeValueCollectorQueryFilterValue,
  AttributeValueCollectorQueryFilterProps,
  AttributeValueCollectorControlData
> {
  type = QueryFilterTypes.attributeValueCollector;
  _ensurePromise = undefined;

  initControlData(
    props: AttributeValueCollectorQueryFilterProps
  ): AttributeValueCollectorControlData {
    const itemOperators = props.itemOperators || [
      AttributeItemOperator.equals,
      AttributeItemOperator.notEquals,
      AttributeItemOperator.greaterThan,
      AttributeItemOperator.lessThan,
    ];
    return {
      defaultItemOperator: itemOperators[0],
      itemOperators,
      fieldPlaceholder: 'Field key',
      valuePlaceholder: 'Value',
      fieldOptions: [],
      getItemOperatorDisplay: this.getItemOperatorDisplay.bind(this),
      getValidItemCountFromValue: this.getValidItemCountFromValue.bind(this),
    };
  }

  ensureControlData(
    value: AttributeValueCollectorQueryFilterValue
  ): Promise<AttributeValueCollectorControlData> {
    if (this._ensurePromise) {
      return this._ensurePromise;
    }

    if (this.props.fetchFieldOptions) {
      this._ensurePromise = this.props
        .fetchFieldOptions()
        .then((fieldOptions) => {
          this.setControlData({ fieldOptions });
          return this.getControlData();
        });
    } else {
      this._ensurePromise = Promise.resolve({});
    }

    return this._ensurePromise;
  }

  getValidItemCountFromValue(
    value: AttributeValueCollectorQueryFilterValue
  ): number {
    if (!value) {
      return 0;
    }
    const { items = [] } = value;

    return items.reduce((acc, item) => {
      return acc + (this.isCompleteItem(item) ? 1 : 0);
    }, 0);
  }

  isCompleteItem(item: AttributeItem) {
    return item.field && item.operator && item.value;
  }

  hasValue(value: AttributeValueCollectorQueryFilterValue = {}): boolean {
    if (!value) {
      return false;
    }
    const { items } = value;

    return !!_.find(items, this.isCompleteItem);
  }

  getElasticQuery(
    value: AttributeValueCollectorQueryFilterValue
  ): object | void {
    if (!value || !this.props.esField) {
      return;
    }
    const { items = [], listOperator } = value;
    const validItems = items.filter(this.isCompleteItem);

    if (validItems.length < 1) {
      return;
    }

    const itemQueries = validItems.map((item): object => {
      // combine the esField root with the explicit field configured by the user
      const fieldKey = `${this.props.esField}.${item.field}`;
      // note, some of these fields are numbers... but it looks like ES handles conversions so we don't have to
      const val = item.value;

      if (item.operator === AttributeItemOperator.lessThan) {
        return {
          range: {
            [fieldKey]: {
              lt: val,
            },
          },
        };
      } else if (item.operator === AttributeItemOperator.greaterThan) {
        return {
          range: {
            [fieldKey]: {
              gt: val,
            },
          },
        };
      } else if (item.operator === AttributeItemOperator.notEquals) {
        return {
          bool: {
            must_not: {
              term: {
                [fieldKey]: {
                  value: val,
                },
              },
            },
          },
        };
      }
      // AttributeItemOperator.equals
      return {
        term: {
          [fieldKey]: {
            value: val,
          },
        },
      };
    });

    // no need to join
    if (itemQueries.length === 1) {
      return itemQueries[0];
    }

    const operatorKey = listOperator === ListOperator.and ? 'filter' : 'should';
    return {
      bool: {
        [operatorKey]: itemQueries,
      },
    };
  }

  getItemOperatorDisplay(operator: AttributeItemOperator) {
    if (operator === AttributeItemOperator.equals) {
      return '=';
    } else if (operator === AttributeItemOperator.greaterThan) {
      return '>';
    } else if (operator === AttributeItemOperator.lessThan) {
      return '<';
    } else if (operator === AttributeItemOperator.notEquals) {
      return '≠';
    }
  }

  getValuePreview(value: AttributeValueCollectorQueryFilterValue): string {
    if (!value) {
      return '';
    }
    const { items } = value;

    const validItems = items.filter(this.isCompleteItem);

    if (!validItems.length) {
      return '';
    }

    const first = validItems[0];
    const previewBase = `${first.field} ${this.getItemOperatorDisplay(
      first.operator
    )} ${first.value}`;

    if (validItems.length > 1) {
      return `${previewBase} (+${validItems.length - 1})`;
    }

    return previewBase;
  }
}
