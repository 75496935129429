import makeStyles from '@mui/styles/makeStyles';
import {
  Alert,
  Button,
  Col,
  Form,
  Popconfirm,
  Row,
  Space,
  Spin,
  Switch,
  Typography,
} from 'antd';
import React from 'react';
import { useEffect, useMemo, useState } from 'react';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import {
  MetadataFieldObj,
  convertMetadataToFields,
} from '../../services/passThruSearch';
import { SessionLogProduct, VBATMetadata } from '../../services/robotLogs';
import {
  EditableMetadataRow,
  MetadataKeyInputProps,
  MetadataValueInputProps,
} from './MetadataEditableRow';
import { isEqual } from 'lodash';

const useStyles = makeStyles((theme) => {
  return {
    metadataTable: {
      tableLayout: 'fixed',
      width: '100%',
      borderCollapse: 'collapse',
      fontSize: 12,
      '& a': {
        verticalAlign: 'middle',
      },
      '& tr': {
        borderBottom: '1px solid',
        borderBottomColor: theme.palette.divider,
        height: 40,
        '&:hover': {
          backgroundColor: theme.palette.action.hover,
        },
        '& td': {
          padding: '8px 8px',
          overflowWrap: 'break-word',
          '&:first-child': {
            width: '30%',
          },

          '&:nth-child(2)': {
            width: 'calc(30% - 95px)',
          },

          '&:last-child': {
            width: '95px',
            textAlign: 'right',
          },
        },
      },

      '& .edit-button': {
        opacity: 0,
      },
      '& tr:hover .edit-button': {
        opacity: 1,
      },
    },
  };
});

type MetadataTableViewProps = {
  platform_id?: string;
  productType?: SessionLogProduct;
  metadata: VBATMetadata | any;
  isLoading: boolean;
  isError: boolean;
  error: any;
  updateMetadata: (metadata: VBATMetadata | any) => void;
  keyInput: React.ComponentType<MetadataKeyInputProps>;
  valueInput: React.ComponentType<MetadataValueInputProps>;
};

export const MetadataEditor: React.FC<MetadataTableViewProps> = ({
  metadata,
  isLoading,
  isError,
  error,
  updateMetadata,
  platform_id,
  productType,
  keyInput,
  valueInput,
}) => {
  const classes = useStyles();

  const [metadataFields, setMetadataFields] = useState<MetadataFieldObj[]>([]);
  const [jsonDirty, setJsonDirty] = useState(false);
  const [jsonTemp, setJsonTemp] = useState<VBATMetadata>({});

  useEffect(() => {
    setMetadataFields(convertMetadataToFields(metadata));
  }, [metadata]);

  const excludedKeys = useMemo(() => {
    return metadataFields.map((field) => field.metadata_key);
  }, [metadata]);

  const onMetadataChange = (records: MetadataFieldObj[]) => {
    const metadataObj = records.reduce((acc, { metadata_key: key, value }) => {
      acc[key] = value;
      return acc;
    }, {} as Record<string, string>);

    updateMetadata(metadataObj);
  };

  const [editingKey, setEditingKey] = useState(null);
  const [showJSON, setShowJSON] = useState(false);

  const isFieldDisabled = (record: MetadataFieldObj) =>
    editingKey !== null && record.metadata_key !== editingKey;

  const onEdit = (key: string) => {
    setEditingKey(key);
  };

  const onCancel = (key: string) => {
    if (key === '') {
      const newData = [...metadataFields];
      const index = newData.findIndex((item) => key === item.metadata_key);
      if (index > -1) {
        newData.splice(index, 1);
        setMetadataFields(newData);
      }
    }
    setEditingKey(null);
  };

  const onSave = async (field: MetadataFieldObj) => {
    const newData = [...metadataFields];
    const index = newData.findIndex(
      (item) => field.metadata_key === item.metadata_key
    );
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        ...field,
      });
      onMetadataChange(newData);
      setEditingKey(null);
    } else {
      newData.push(field);
      onMetadataChange(newData);
      setEditingKey(null);
    }
  };

  const onJSONChange = (data: any) => {
    if (!data.jsObject) return;
    setJsonTemp(data.jsObject);
    if (!isEqual(data.jsObject, metadata)) {
      return setJsonDirty(true);
    }
    setJsonDirty(false);
  };

  const onJSONSave = () => {
    updateMetadata(jsonTemp);
    setJsonDirty(false);
  };

  const addField = () => {
    const newData = [...metadataFields];
    const field = { metadata_key: '', value: '' };
    newData.unshift(field);
    setMetadataFields(newData);
    setEditingKey(field.metadata_key);
  };

  const stringifiedMetadata = useMemo(() => {
    return JSON.stringify(metadata, null, 2);
  }, [metadata]);

  if (isError && error instanceof Error) {
    return <Alert message={error.message} type="error" />;
  }

  if (isLoading) {
    return <Spin />;
  }

  return (
    <Row gutter={16}>
      <Col className="gutter-row" span={24}>
        <Typography.Title level={3}>
          Metadata
          {/* {showJSON ? ( */}
          {/* <Button
              style={{ float: 'right' }}
              type="primary"
              disabled={!jsonDirty}
              onClick={onJSONSave}
            >
              Save
            </Button>
          ) : ( */}
          <Button
            disabled={editingKey !== null || showJSON}
            style={{ float: 'right' }}
            type="primary"
            onClick={addField}
          >
            Add Field
          </Button>
          {/* )} */}
        </Typography.Title>
        <Space align="end"></Space>
        <Row wrap={false}>
          <Col flex="auto">
            <Form.Item>
              <Switch
                checkedChildren="JSON"
                unCheckedChildren="Fields"
                checked={showJSON}
                onChange={setShowJSON}
              />
            </Form.Item>
          </Col>
          <Col flex="none"></Col>
        </Row>
        {showJSON ? (
          <pre>
            <code>{stringifiedMetadata}</code>
          </pre>
        ) : !isLoading && metadataFields.length === 0 ? (
          <div style={{ textAlign: 'center' }}>
            <Typography.Text>No metadata to display</Typography.Text>
          </div>
        ) : showJSON ? (
          <pre>
            <code>{stringifiedMetadata}</code>
          </pre>
        ) : (
          <table className={classes.metadataTable}>
            <tbody>
              {metadataFields.map((field) => (
                <EditableMetadataRow
                  key={field.metadata_key}
                  field={field}
                  editing={editingKey === field.metadata_key}
                  disabled={isFieldDisabled(field)}
                  onEdit={onEdit}
                  onCancel={onCancel}
                  onSave={onSave}
                  productType={productType}
                  platform_id={platform_id}
                  excludedKeys={excludedKeys}
                  keyInput={keyInput}
                  valueInput={valueInput}
                />
              ))}
            </tbody>
          </table>
        )}
      </Col>
    </Row>
  );
};
