import React from 'react';
import cx from 'classnames';
import withStyles from '@mui/styles/withStyles';
import FormLabel from '@mui/material/FormLabel';
import { colors } from '@shield-ui/styles';

function styles() {
  return {
    container: {
      marginBottom: 15,
    },
    labelContainer: {
      margin: `0 0 3px 5px`,
    },
    label: {
      fontSize: `.875em`,
    },
    labelAsterisk: {
      color: colors.hues.red,
    },
  };
}

function InputGroup({
  label,
  children,
  classes,
  className = undefined,
  required = false,
}) {
  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.labelContainer}>
        <FormLabel
          component="label"
          classes={{ root: classes.label, asterisk: classes.labelAsterisk }}
          required={required}
        >
          {label}
        </FormLabel>
      </div>
      {children}
    </div>
  );
}

export default withStyles(styles)(InputGroup);
