import React from 'react';
import history from '../../../../../routes/history';
import withStyles from '@mui/styles/withStyles';
import { Link } from '@mui/material';
import { getSessionLogDisplayName } from '../../../../../services/robotLogs';

function styles(theme) {
  return {
    link: {
      marginBottom: 5,
      cursor: 'pointer',
    },
  };
}

class RobotLogLink extends React.PureComponent {
  onClick = (evt) => {
    const { robotLog } = this.props;
    const { id } = robotLog;
    const path = `/app/flights/${id}`;

    evt.preventDefault();
    evt.stopPropagation();

    // handles ctrlKey, middleClick, or commandKey (mac) click
    if (evt.ctrlKey || evt.type === 'auxclick' || evt.metaKey) {
      window.open(path);
      return;
    }
    history.push(path);
  };

  render() {
    const { robotLog, classes } = this.props;

    return (
      <Link className={classes.link} onClick={this.onClick} underline="hover">
        {getSessionLogDisplayName(robotLog)}
      </Link>
    );
  }
}

export default withStyles(styles)(RobotLogLink);
