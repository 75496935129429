import _ from 'lodash';
import async from 'async';
import { createActionPrefix } from 'redux-nano';
import { gql } from '@apollo/client';
import { runQuery } from '../../apollo-client';
import { showErrorSnack } from '../snackbar/actions';
import ticketApi from '../../lib/ticketApi';
import { getLabels } from '../../services/labels';
import { getTickets } from '../../services/tickets';
import { fetchRobots } from '../../services/robots';
import { fetchEnvironments } from '../../services/environments';

const prefix = createActionPrefix('LISTS');
export const SET_LISTS = prefix('SET_LISTS');
export const SET_LIST_REQUESTING = prefix('SET_LIST_REQUESTING');
export const SET_LIST_ITEMS = prefix('SET_LIST_ITEMS');

const listsQuery = gql`
  query {
    tags(sort: name_asc) {
      edges {
        node {
          id
          name
          description
          severity {
            name
          }
        }
      }
    }
    severities(sort: name_asc) {
      edges {
        node {
          id
          name
        }
      }
    }
    causes(sort: name_asc) {
      edges {
        node {
          id
          name
          description
        }
      }
    }
    missions(sort: name_asc) {
      edges {
        node {
          id
          name
        }
      }
    }
    robotLogTests(sort: code_asc) {
      edges {
        node {
          id
          code
        }
      }
    }
    softwareInfos {
      edges {
        node {
          version
          branchName
        }
      }
    }
  }
`;

function processEdges(edges = []) {
  return edges.map((edge) => {
    const node = _.omit(edge.node, ['__typename']);

    _.forEach(node, (value, key) => {
      if (_.isArray(value) && _.isObject(value[0])) {
        node[key] = node[key].map((item) => _.omit(item, ['__typename']));
      }
    });

    return node;
  });
}

export function refreshLists() {
  return function dispatchSetLists(dispatch) {
    dispatch(SET_LIST_REQUESTING());

    // Some that have moved into a shared / cached item
    [
      [fetchRobots, 'robots'],
      [fetchEnvironments, 'environments'],
    ].forEach(([req, key]) => {
      req()
        .then((response) => {
          dispatch(
            SET_LISTS({
              listTypeMap: {
                [key]: processEdges(_.get(response, ['data', key, 'edges'])),
              },
            })
          );
        })
        .catch((e) => console.error(e));
    });

    runQuery({
      query: listsQuery,
      fetchPolicy: 'no-cache',
      callback: (err, results) => {
        if (err) {
          dispatch(showErrorSnack(err));
          return;
        }

        const listTypeMap = [
          'tags',
          'severities',
          'causes',
          'missions',
          'robotLogTests',
          'softwareInfos',
        ].reduce((acc, queryType) => {
          acc[queryType] = processEdges(
            _.get(results, ['data', queryType, 'edges'])
          );
          return acc;
        }, {});

        dispatch(SET_LISTS({ listTypeMap }));
      },
    });
  };
}

/**
 * Ensure any labels are stored in our lists
 * @param labelIds
 * @param callback
 */
export function ensureLabelListItems({ labelIds, callback = _.noop }) {
  return function dispatchFetchLabelListItems(dispatch) {
    if (_.isEmpty(labelIds)) {
      return;
    }

    getLabels({ ids: labelIds }, (err, res) => {
      if (err) {
        dispatch(showErrorSnack(err));
        callback(err);
        return;
      }

      const labels = processEdges(_.get(res, 'data.labels.edges'));

      dispatch(
        SET_LIST_ITEMS({
          listKey: 'labels',
          items: labels,
        })
      );

      callback(null, res);
    });
  };
}

/**
 * Ensure any tickets are stored in our lists
 * @param ticketPlatformIds
 * @param callback
 */
const alreadyEnsuredTicketPlatformIds = [];
export function ensureTicketListItems({
  ticketPlatformIds,
  callback = _.noop,
}) {
  return function dispatchFetchTicketListItems(dispatch, getState) {
    if (_.isEmpty(ticketPlatformIds)) {
      return;
    }

    const state = getState();
    const tickets = state.lists.listTypeMap.tickets;

    const ticketMap = _.keyBy(tickets, 'platformId');
    const missingIds = ticketPlatformIds.filter(
      (id) => !(ticketMap[id] || alreadyEnsuredTicketPlatformIds.includes(id))
    );

    if (!missingIds.length) {
      return;
    }
    alreadyEnsuredTicketPlatformIds.push(...missingIds);

    // fetches tickets from Hangar because 99% of the time this is where they will be
    // sets them in the ticket list for use throughout the app
    getTickets({ platformIds: missingIds }, (err, res) => {
      if (err) {
        dispatch(showErrorSnack(err));
        callback(err);
        return;
      }

      const tickets = processEdges(_.get(res, 'data.tickets.edges'));
      dispatch(
        SET_LIST_ITEMS({
          listKey: 'tickets',
          items: tickets,
        })
      );

      // if any platformIds were missing from the result to Hangar, we will go check our 3rd-party service
      // this is slow and expensive but will fill in any holes in the data
      const foundMap = _.keyBy(tickets, 'platformId');
      const missingIds = ticketPlatformIds.filter(
        (platformId) => !foundMap[platformId]
      );

      async.eachLimit(
        missingIds,
        3,
        (platformId, callback) => {
          ticketApi
            .post(platformId + '')
            .then((res) => {
              const { data: tickets } = res;
              dispatch(
                SET_LIST_ITEMS({
                  listKey: 'tickets',
                  items: tickets,
                })
              );
            })
            .catch((e) => {
              console.error('problem filling in missing tickets', e);
            });
        },
        () => {
          callback(null, res);
        }
      );
    });
  };
}

export const ensureAllAnalysisNames = _.once(() => {
  return function dispatchEnsureAllAnalysisNames(dispatch) {
    runQuery({
      query: gql`
        query {
          analysisMeta {
            names
          }
        }
      `,
      callback: (err, results) => {
        if (err) {
          return console.error(err);
        }

        dispatch(
          SET_LISTS({
            listTypeMap: {
              analysisNames: _.get(results, 'data.analysisMeta.names', []),
            },
          })
        );
      },
    });
  };
});

export const ensureAllMetricNames = _.once(() => {
  return function dispatchEnsureAllAnalysisNames(dispatch) {
    runQuery({
      query: gql`
        query {
          metricMeta {
            names
          }
        }
      `,
      callback: (err, results) => {
        if (err) {
          return console.error(err);
        }

        dispatch(
          SET_LISTS({
            listTypeMap: {
              metricNames: _.get(results, 'data.metricMeta.names', []),
            },
          })
        );
      },
    });
  };
});

export const ensureComponents = _.once(() => {
  return function dispatchEnsureComponents(dispatch) {
    runQuery({
      query: gql`
        query {
          components {
            externalId
            name
            product
            owner
          }
        }
      `,
      callback: (err, results) => {
        if (err) {
          return console.error(err);
        }

        dispatch(
          SET_LISTS({
            listTypeMap: {
              components: _.get(results, 'data.components', []),
            },
          })
        );
      },
    });
  };
});
export const ensureLogDefinitions = _.once(() => {
  return function dispatchEnsureLogDefinitions(dispatch) {
    runQuery({
      query: gql`
        query {
          logDefinitions {
            externalId
            name
            description
          }
        }
      `,
      callback: (err, results) => {
        if (err) {
          return console.error(err);
        }

        dispatch(
          SET_LISTS({
            listTypeMap: {
              logDefinitions: _.get(results, 'data.logDefinitions', []),
            },
          })
        );
      },
    });
  };
});

/**
 * @function
 * @param {string} listKey
 * @param {array} items (array of objects to set in the list)
 */
export const setListItems = SET_LIST_ITEMS;
