import _ from 'lodash';
import React from 'react';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { HangarMetricType } from '@shield-ui/hangar-service';
import JsonViewer from '../../../JsonViewer';

const useStyles = makeStyles((theme) => ({
  basic: {
    overflow: 'auto',
    whiteSpace: 'break-spaces',
    fontFamily: 'monospace',
    background: 'rgba(0, 0, 0, 0.15)',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

export default function MetricExpanded({
  metric,
}: {
  metric: HangarMetricType;
}) {
  const { value } = metric;
  const classes = useStyles();

  return (
    <>
      {_.isObject(value) ? (
        <JsonViewer src={value} style={{ maxHeight: 500 }} />
      ) : (
        <Typography variant="body1" gutterBottom className={classes.basic}>
          {value}
        </Typography>
      )}
      <div>Output Metric</div>
    </>
  );
}
