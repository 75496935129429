import React, { useState, useMemo, useCallback } from 'react';
import _ from 'lodash';
import { Button } from 'antd';
import makeStyles from '@mui/styles/makeStyles';
import FilterIcon from '@mui/icons-material/FilterList';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { MoreMenuButton } from '@shield-ui/buttons';
import { SimpleMenuItem } from '@shield-ui/core';
import ManageFiltersModal from './ManageFiltersModal';
import { QueryFilterItem, ContextMenuState, ManagementProps } from './types';
import SortableBarContainer from './SortableBarContainer';
import SortableQueryFilterControl from './SortableQueryFilterControl';
import StaticBarContainer from './StaticBarContainer';
import StaticQueryFilterControl from './StaticQueryFilterControl';
import RightClickMenu from './RightClickMenu';
import MoreAction from './MoreAction';

export type ControlBarProps = {
  queryFilterItems: QueryFilterItem[];
  values: object;
  setValues: (args: object) => void;
  resetValues?: () => void;
  // Controls things like which controls are visible
  management?: ManagementProps;
  beforeMenuItems?: SimpleMenuItem[];
  afterMenuItems?: SimpleMenuItem[];
};

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
  },
  moreButton: {
    position: 'absolute',
    right: -18,
    top: 4,
    zIndex: 2,
  },
  controlsWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    minHeight: theme.spacing(6),
  },
  actionButton: {
    margin: `4px 8px`,
    textTransform: 'uppercase',
  },
}));

export default function ControlBar(props: ControlBarProps) {
  const {
    values,
    setValues,
    resetValues,
    queryFilterItems,
    management,
    beforeMenuItems,
    afterMenuItems,
  } = props;
  const [manageFiltersIsVisible, setManageFilters] = useState(false);
  const [contextMenu, setContextMenu] = useState<ContextMenuState | undefined>(
    undefined
  );
  const classes = useStyles();

  const isSortable = management && !management.disableSort;

  // open / close of managing the extra filters
  const onClose = useCallback(() => setManageFilters(false), []);

  // just compute the menu items when we need to
  const menuItems = useMemo(() => {
    let menu = [];
    if (management) {
      menu.push({
        Icon: FilterIcon,
        label: 'More Filters',
        onClick: () => setManageFilters(true),
      });
    }

    if (resetValues) {
      menu.push({
        Icon: DeleteIcon,
        label: 'Clear All',
        onClick: resetValues,
        disabled: !values || Object.keys(values).length === 0,
      });
    }

    if (beforeMenuItems) {
      menu = beforeMenuItems.concat(menu);
    }
    if (afterMenuItems) {
      menu = menu.concat(afterMenuItems);
    }

    return menu;
  }, [values, setValues, beforeMenuItems, afterMenuItems]);

  const qfItemMap = useMemo(
    () => _.keyBy(queryFilterItems, (qf) => qf.queryFilter.getId()),
    [queryFilterItems]
  );

  // flattened list of the visible ids
  const visibleIds = useMemo(() => {
    const tmpIds = management
      ? management.ids
      : queryFilterItems.map((qfi) => qfi.queryFilter.getId());
    // ensure we actually have query filter items for every id passed in management
    // cached, old ids causes issues otherwise
    return tmpIds.filter((id) => !!qfItemMap[id]);
  }, [management && management.ids]);

  // calculate the "More Count" for the More button.
  // These are controls with a value, that are not currently visible on the control bar
  const moreCount = useMemo(() => {
    if (!values) {
      return 0;
    }

    return queryFilterItems.reduce((count, qfi) => {
      const { queryFilter } = qfi;

      const id = queryFilter.getId();
      if (visibleIds.includes(id)) {
        return count;
      }

      const hasValue = queryFilter.hasValue(values[queryFilter.getId()]);
      if (hasValue) {
        count = count + 1;
      }

      return count;
    }, 0);
  }, [queryFilterItems, values, visibleIds]);

  return (
    <>
      <div className={classes.container}>
        {!!menuItems.length && (
          <MoreMenuButton
            menuItems={menuItems}
            buttonProps={{ className: classes.moreButton }}
          />
        )}
        {isSortable && (
          <SortableBarContainer
            ids={visibleIds}
            setIds={management.setIds}
            renderItem={({ id, index, activeIndex }) => (
              <SortableQueryFilterControl
                index={index}
                activeIndex={activeIndex}
                key={id}
                id={id}
                queryFilterItem={qfItemMap[id]}
                values={values}
                setValues={setValues}
                setContextMenu={setContextMenu}
              />
            )}
            renderDragContent={({ id }) => (
              <StaticQueryFilterControl
                key={id}
                queryFilterItem={qfItemMap[id]}
                values={values}
                setValues={setValues}
                setContextMenu={setContextMenu}
              />
            )}
            renderPostfix={() => (
              <>
                <MoreAction
                  onClick={() => setManageFilters(true)}
                  count={moreCount}
                />
                {values && Object.keys(values).length > 0 && (
                  <Button
                    danger
                    //type='primary'
                    className={classes.actionButton}
                    onClick={resetValues}
                  >
                    Clear All
                  </Button>
                )}
              </>
            )}
          />
        )}
        {!isSortable && (
          <StaticBarContainer
            ids={visibleIds}
            renderItem={(id) => (
              <StaticQueryFilterControl
                key={id}
                queryFilterItem={qfItemMap[id]}
                values={values}
                setValues={setValues}
                setContextMenu={setContextMenu}
              />
            )}
          />
        )}
      </div>
      {!!management && (
        <ManageFiltersModal
          isOpen={manageFiltersIsVisible}
          queryFilterItems={queryFilterItems}
          values={values}
          setValues={setValues}
          onClose={onClose}
          management={management}
        />
      )}
      {!!contextMenu && (
        <RightClickMenu
          queryFilterItem={qfItemMap[contextMenu.queryFilterId]}
          contextMenu={contextMenu}
          values={values}
          setValues={setValues}
          management={management}
          onClose={() => setContextMenu(undefined)}
        />
      )}
    </>
  );
}
