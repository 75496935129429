import React, { ElementType } from 'react';
import cx from 'classnames';
import { lighten } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Markdown from 'markdown-to-jsx';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Divider from '@mui/material/Divider';
import { colors } from '@shield-ui/styles';

interface MarkdownContentProps {
  md: string;
  className?: string;
  // set to true if this is full page and not just some note type thing
  fullPageContent?: boolean;
  onClick?: (evt) => void;
}

const headingVariants: TypographyProps['variant'][] = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
];
const listVariants: ElementType[] = ['ul', 'ol'];
//LinkWrapper is used to open links in a new tab
const LinkWrapper = (props) => <Link href={props.href} target="_blank" rel="noreferrer"> {props.children} </Link>;

const commonOverrides = {
  a: LinkWrapper,
  hr: Divider,
  table: Table,
  thead: TableHead,
  tbody: TableBody,
  tr: TableRow,
  th: TableCell, // td in thead
  td: TableCell,
  // blockquote: () => {}
  // code: () => {},
  // pre: () => {},
};

const options = {
  overrides: {
    ...commonOverrides,
  },
};

const fullPageOptions = {
  overrides: {
    ...headingVariants.reduce((acc, headingVariant) => {
      acc[headingVariant] = {
        component: Typography,
        props: { variant: headingVariant, gutterBottom: true },
      };
      return acc;
    }, {}),
    // Wrap a UL in our Typography tag
    // but body1 renders a <p> by default but <p>'s cannot be nested
    // so change the actual element to a 'div'
    ...listVariants.reduce((acc, listVariant) => {
      // toString for Typescript key in object
      acc[listVariant.toString()] = {
        component: ({ children, ...rest }) => {
          return (
            <Typography
              component={listVariant}
              variant="body1"
              {...rest}
              gutterBottom
            >
              {children}
            </Typography>
          );
        },
      };
      return acc;
    }, {}),
    p: {
      component: Typography,
      props: {
        variant: 'body1',
        gutterBottom: true,
        style: { marginBottom: 16 },
      },
    },
    ...commonOverrides,
  },
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    '& code': {
      backgroundColor: `rgba(255, 255, 255, 0.1)`,
    },
    '& blockquote': {
      marginLeft: theme.spacing(4),
      paddingLeft: theme.spacing(2),
      borderLeft: `2px solid rgba(255, 255, 255, 0.2)`,
    },
  },
  fullPageWrapper: {
    '& > p': {
      marginBottom: theme.spacing(2),
    },
  },
  smallWrapper: {
    fontSize: theme.typography.fontSize,
    '& p': {
      margin: theme.spacing(0, 0, 0.5, 0),
    },
    '& h1': {
      fontSize: '1.6em',
      margin: theme.spacing(0, 0, 1.5, 0),
      color: lighten(colors.hues.gray, 0.6),
    },
    '& h2': {
      fontSize: '1.4em',
      margin: theme.spacing(0, 0, 1.25, 0),
      color: lighten(colors.hues.gray, 0.5),
    },
    '& h3': {
      fontSize: '1.25em',
      margin: theme.spacing(0, 0, 0.75, 0),
      color: lighten(colors.hues.gray, 0.4),
    },
    '& h4': {
      fontSize: '1.2em',
      margin: theme.spacing(0, 0, 0.75, 0),
      color: lighten(colors.hues.gray, 0.35),
    },
    '& h5': {
      fontSize: '1.15em',
      margin: theme.spacing(0, 0, 0.5, 0),
      color: lighten(colors.hues.gray, 0.3),
    },
    '& h6': {
      fontSize: '1.1em',
      margin: theme.spacing(0, 0, 0.5, 0),
      color: lighten(colors.hues.gray, 0.25),
    },
    '& ul, ol': {
      paddingLeft: theme.spacing(3),
    },
  },
}));

export default function MarkdownContent(props: MarkdownContentProps) {
  const { md, fullPageContent, className, onClick } = props;
  const classes = useStyles();

  if (fullPageContent) {
    return (
      <div
        className={cx(classes.wrapper, classes.fullPageWrapper, className)}
        onClick={onClick}
      >
        <Markdown options={fullPageOptions}>
          {/* newline in the beginning is a hack to force everything to be a "paragraph" even if no MD.
          otherwise things were just rendering in a plain span*/}
          {'\n' + md}
        </Markdown>
      </div>
    );
  } else {
    return (
      <div
        className={cx(classes.wrapper, classes.smallWrapper, className)}
        onClick={onClick}
      >
        <Markdown options={options}>
          {/* newline in the beginning is a hack to force everything to be a "paragraph" even if no MD.
          otherwise things were just rendering in a plain span*/}
          {'\n' + md}
        </Markdown>
      </div>
    );
  }
}
