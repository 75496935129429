import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { InputNumber, Row, Col } from 'antd';
import { FormValues } from './common';
import { SessionLogType } from '@hmd/sdk/api/session_log/session_log_type/v1';
import { countAndPluralize } from '@shield-ui/utils';
import TagsPreview from '../TagsPreview';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from '@shield-ui/styles';
import { listSessionLogTypes } from '../../../services/robotLogs';
const useStyles = makeStyles(() => ({
  typeLabel: {
    fontSize: '0.8em',
    color: colors.hues.grays[30],
    textAlign: 'right',
  },
}));

interface ChildRobotLogExpectationsProps {
  value?: FormValues['childRobotLogExpectations'];
  onChange?: (v: FormValues['childRobotLogExpectations']) => void;
  isPreview?: boolean;
}
export default function ChildRobotLogExpectations({
  value = [],
  onChange,
  isPreview,
}: ChildRobotLogExpectationsProps) {
  const classes = useStyles();
  const defualtSL = new Map<string, SessionLogType>();
  const [sessionLogTypesHash, setSessionLogTypesHash] = useState(defualtSL);
  const [opts, setOpts] = useState([]);

  useEffect(() => {
    listSessionLogTypes().then((res) => {
      const sessionLogTypes = res.getSessionLogTypesList();

      const hash = new Map(sessionLogTypes.map((obj) => [obj.getSlug(), obj]));

      if (hash.has('trial')) {
        hash.delete('trial');
      }
      if (hash.has('test')) {
        hash.delete('test');
      }
      setSessionLogTypesHash(hash);
      const optstemp = Array.from(hash.keys()).map((key) => {
        return {
          display: hash.get(key).getName(),
          value: hash.get(key).getSlug(),
        };
      });
      setOpts(optstemp);
    });
  }, []);

  if (isPreview && sessionLogTypesHash.size > 0) {
    const displayTags = value.map((ex) => {
      return countAndPluralize(
        ex.count,
        sessionLogTypesHash.get(ex.sessionLogTypeSlug).getName()
      );
    });
    return <TagsPreview tags={displayTags} />;
  }

  return (
    <Row align="middle" gutter={[8, 8]} style={{ marginBottom: 16 }}>
      {opts.map((opt) => {
        const cur = _.find(value, { sessionLogTypeSlug: opt.value });
        return (
          <React.Fragment key={opt.value}>
            <Col span={6}>
              <div className={classes.typeLabel}>{opt.display}</div>
            </Col>
            <Col span={16}>
              <InputNumber
                min={0}
                max={1000}
                size="small"
                value={cur ? cur.count : undefined}
                onChange={(v) => {
                  let newValue = [...value];
                  if (cur) {
                    newValue = value.filter((item) => item !== cur);
                  }
                  if (typeof v === 'number' && v > 0) {
                    newValue.push({
                      count: v,
                      sessionLogTypeSlug: opt.value,
                    });
                  }
                  onChange(newValue);
                }}
              />
            </Col>
          </React.Fragment>
        );
      })}
    </Row>
  );
}
