import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { Paper } from '@mui/material';
import SectionHeader from './SectionHeader';
import { colors } from '@shield-ui/styles';

function styles(theme) {
  return {
    container: {
      marginTop: theme.spacing(1),
      padding: theme.spacing(2),
    },
    content: {
      fontSize: 13,
    },
    inner: {
      marginLeft: theme.spacing(1),
    },
    rowsContainer: {
      display: 'table',
    },
    row: {
      display: 'table-row',
    },
    rowLabel: {
      display: 'table-cell',
      paddingRight: theme.spacing(2.5),
      fontWeight: 400,
      color: colors.hues.grays[60],
      paddingBottom: theme.spacing(1),
      width: 220,
    },
    rowValue: {
      display: 'table-cell',
      fontWeight: 500,
      wordBreak: 'break-word',
    },
  };
}

function InfoSection({
  classes,
  children,
  rows,
  title,
  paperContainer,
  AfterRows,
}) {
  const content = (
    <div className={classes.content}>
      {title && <SectionHeader>{title}</SectionHeader>}
      <div className={classes.inner}>
        {children}
        {rows && (
          <div className={classes.rowsContainer}>
            {rows.map((row) => (
              <div key={row.display} className={classes.row}>
                <div className={classes.rowLabel}>{row.display}</div>
                <div className={classes.rowValue}>{row.value}</div>
              </div>
            ))}
          </div>
        )}
        {AfterRows}
      </div>
    </div>
  );

  if (!paperContainer) {
    return content;
  }

  return <Paper className={classes.container}>{content}</Paper>;
}

InfoSection.propTypes = {
  title: PropTypes.string,
  paperContainer: PropTypes.bool,
  children: PropTypes.node,
  AfterRows: PropTypes.node,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      display: PropTypes.string,
      value: PropTypes.any,
    })
  ),
};

export default withStyles(styles)(InfoSection);
