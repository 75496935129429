import React from 'react';
import withStyles from '@mui/styles/withStyles';

function styles(theme) {
  return {
    container: {
      marginBottom: theme.spacing(0.5),
      marginRight: theme.spacing(1.5),
    },
    label: {
      fontSize: 12,
      lineHeight: '15px',
      color: theme.palette.text.secondary,
    },
    selectContainer: {
      width: 'auto',
      minWidth: 130,
    },
  };
}

function HeaderControl({ label, classes, children }) {
  return (
    <div className={classes.container}>
      <div className={classes.label}>{label}</div>
      <div className={classes.selectContainer}>{children}</div>
    </div>
  );
}

export default withStyles(styles)(HeaderControl);
