import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import {
  showErrorSnack,
  showSuccessSnack,
} from '../../../redux/snackbar/actions';

function mapStateToProps(state) {
  return {
    currentUser: state.authUser.currentUser,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showErrorSnack,
      showSuccessSnack,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps);
