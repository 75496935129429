import React from 'react';
import { Global, css } from '@emotion/react';
import colors from './colors';
// we are using material-ui and antd frameworks, this is our configuration / overloading of antd styles
import './antd-theme.less';

export enum GlobalClassNames {
  'shieldai-scrollbar-container' = 'shieldai-scrollbar-container',
}

/**
 * These will interfere with components...
 *
 * USE VERY SPARSELY !!!
 */
const styles = css`
  html,
  body,
  #root {
    height: 100%;
  }
  body {
    background-color: ${colors.semantic.bodyBackground};
    color: ${colors.semantic.bodyText};
  }
  /* Custom Scrollbar for webkit based browsers (Chrome, Safari, Opera, etc...) */
  ::-webkit-scrollbar {
    width: 6px;
    height: 8px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
    opacity: 0.1;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${colors.hues.gray};
  }
  ::-webkit-scrollbar-button:start:decrement,
  ::-webkit-scrollbar-button:end:increment {
    display: block;
    height: 16px;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb:vertical,
  ::-webkit-scrollbar-thumb:horizontal {
    -webkit-border-radius: 8px;
  }
  .${GlobalClassNames['shieldai-scrollbar-container']} {
    scrollbar-width: thin;
    scrollbar-color: ${colors.hues.gray} transparent;
  }
`;

export default function GlobalStyles() {
  return <Global styles={styles} />;
}
