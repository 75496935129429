import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import {
  showErrorSnack,
  showSuccessSnack,
} from '../../../redux/snackbar/actions';
import {
  getEnvironmentOptions,
  getMissionOptions,
  getRobotLogTestOptions,
  getRobotOptions
} from '../../../redux/lists/selectors';

function mapStateToProps(state) {
  return {
    currentUser: state.authUser.currentUser,
    listsInitialized: state.lists.isInitialized,
    environmentOptions: getEnvironmentOptions(state),
    missionOptions: getMissionOptions(state),
    robotLogTestOptions: getRobotLogTestOptions(state),
    robotOptions: getRobotOptions(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showErrorSnack,
      showSuccessSnack,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps);
