import React from 'react';
import { useEffect, useState } from 'react';
import { structValueToBasicStructure } from '../../services/passThruSearch';
import {
  detectSessionLogProduct,
  updateSessionLogMetadataMutation,
  useSessionLog,
  VBATMetadata,
} from '../../services/robotLogs';
import { SessionLogMetadataKeyInput } from './SessionLogMetadataKeyInput';
import { SessionLogMetadataValueInput } from './SessionLogMetadataValueInput';
import { MetadataEditor } from './MetadataEditor';

type MetadataTableViewProps = {
  sessionLogId: string;
};

export const SessionLogMetadataEditor: React.FC<MetadataTableViewProps> = ({
  sessionLogId,
}) => {
  const {
    data: sessionLog,
    isLoading,
    isError,
    error,
  } = useSessionLog(sessionLogId);

  const { mutate: updateMetadata } =
    updateSessionLogMetadataMutation(sessionLogId);

  const [metadata, setMetadata] = useState<VBATMetadata>({});

  useEffect(() => {
    if (!sessionLog) return;
    setMetadata(structValueToBasicStructure(sessionLog?.metadata));
  }, [sessionLog]);

  const productType = detectSessionLogProduct(sessionLog?.name ?? '');

  return (
    <MetadataEditor
      metadata={metadata}
      isLoading={isLoading}
      isError={isError}
      error={error}
      productType={productType}
      updateMetadata={updateMetadata}
      keyInput={SessionLogMetadataKeyInput}
      valueInput={SessionLogMetadataValueInput}
    />
  );
};
