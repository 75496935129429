import _ from 'lodash';
import { colors } from '@shield-ui/styles';
import { prettifyString } from '@shield-ui/utils';
import { severityColors } from '../lib/sharedStyles';
import sdkClient from '../lib/hmdsdkClient';
import {
  EvaluationState,
  GetRequest,
  EvaluationService,
} from '@hmd/sdk/api/tests/evaluations/v1';

const evalState = EvaluationState;

const evaluationStatusColorMap = {
  [evalState.PASS]: severityColors.PASS,
  [evalState.MINOR_FAILURE]: severityColors.MINOR,
  [evalState.MODERATE_FAILURE]: severityColors.MODERATE,
  [evalState.MAJOR_FAILURE]: severityColors.MAJOR,
  [evalState.CRITICAL_FAILURE]: severityColors.CRITICAL,
  [evalState.CATASTROPHIC_FAILURE]: severityColors.CATASTROPHIC,
  [evalState.EXECUTION_FAILURE]: '#bd1515',
  [evalState.CALCULATION_FAILURE]: '#bd1595',
  [evalState.INCOMPLETE]: colors.hues.grays[40],
};

export const evaluationStateOrder = [
  evalState.PASS,
  evalState.MINOR_FAILURE,
  evalState.MODERATE_FAILURE,
  evalState.MAJOR_FAILURE,
  evalState.CRITICAL_FAILURE,
  evalState.CATASTROPHIC_FAILURE,
  evalState.EXECUTION_FAILURE,
  evalState.INCOMPLETE,
  evalState.CALCULATION_FAILURE,
];
export const consolidatedFailureColors = {
  PASS: evaluationStatusColorMap[evalState.PASS],
  FAIL: colors.semantic.error,
};

export const getAllRobotLogDocumentEvaluationOptions = _.once(
  (): Promise<{ value: string; label: string }[]> => {
    const options = _.sortBy(Object.entries(evalState), ([k, v]) => {
      const index = evaluationStateOrder.indexOf(v);
      if (index === -1) {
        return 999;
      }
      return index;
    })
      .filter(([k, v]) => {
        return v !== evalState.EVALUATION_STATE_UNSPECIFIED;
      })
      .map(([k, v]) => {
        return {
          value: k, // robot_log_document stores this as ALL caps enum key
          label: getEvaluationStatusName(v),
        };
      });

    return Promise.resolve(options);
  }
);

export function getEvaluationStatusColor(status): string {
  return evaluationStatusColorMap[status] || colors.hues.grays[40];
}

export const getEvaluationStatusName = (status) => {
  const evaluationStatusObject = evalState;

  return prettifyString(
    Object.keys(evaluationStatusObject).find(
      (key) => evaluationStatusObject[key] === status
    ) || 'Unknown'
  );
};

export const fetchRobotLogEvaluation = async (robotLogId: string) => {
  const req = new GetRequest();
  req.setRobotLogId(robotLogId);
  req.setRefresh(true);
  return sdkClient.unary(EvaluationService.Get, req);
};
