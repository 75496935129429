import _ from 'lodash';
import { getLocalDateTime } from '../../../../../lib/connectedUtils';

const config = {
  columnKey: 'createdAt',
  label: 'Created Time',
  // this happens to be the same between hangar search and document search
  sortKey: 'created_at',
  description: 'When the item was originally created',
  getValue: ({ row }) => getLocalDateTime(_.get(row, 'createdAt')),
  fragment: 'createdAt',
};

export default config;
