import { lighten } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import { IconButton } from '@mui/material';
import { colors } from '@shield-ui/styles';

const styles = (theme) => {
  return {
    root: {
      color: lighten(colors.hues.gray, 0.5),
      width: theme.spacing(4),
      padding: 0,
      height: theme.spacing(4),
      border: `1px solid ${colors.semantic.inputBorder}`,
      margin: '0 4px',
      '&:last-child': {
        marginRight: '0',
      },
      '&:hover': {
        color: lighten(colors.hues.gray, 0.7),
      },
    },
  };
};

export default withStyles(styles)(IconButton);
