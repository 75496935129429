import _ from 'lodash';
import { LabelIdType, LabelType } from '@shield-ui/heatgrid';
import {
  MultiselectQueryFilterValue,
  UNSET_OPTION_VALUE,
  NestedQueryFilterValue,
  NestedValueSet,
} from '@shield-ui/query-filters';
import {
  default as frequencyGroupNestedQueryFilter,
  frequencyGroups as frequencyGroupNameQueryFilter,
} from '../../../lib/queryFilters/robotLog/testDefinitionFrequencyGroups';
import store from '../../../redux/store';
import {
  ensureFrequencyGroups,
  selectFrequencyGroups,
} from '../../../redux/testDefinitions.slice';
import { AxisDefinition, AxisOption } from './AxisDefinition';

export const frequencyGroupAxisDefinition: AxisDefinition = {
  id: 'FrequencyGroup',
  getDropdownLabel: () => 'Frequency Groups',
  nestedPath: 'test_definition.test_frequency_group_details',
  buildAggsDsl: () => ({
    terms: {
      field:
        'test_definition.test_frequency_group_details.test_frequency_group.id',
      missing: 'N/A',
    },
  }),
  prepareAxisLabels: (labelIds: LabelIdType[]) => {
    return new Promise<LabelType[]>((resolve) => {
      store.dispatch(ensureFrequencyGroups()).then(() => {
        const frequencyGroups = selectFrequencyGroups(store.getState());
        const labelMap = frequencyGroups.reduce((acc, fg) => {
          return {
            ...acc,
            [fg.id]: fg.name,
          };
        }, {});

        let emptyLabel: LabelType = undefined;
        const labels: LabelType[] = _.sortBy(
          labelIds.reduce((acc, fgId) => {
            if (fgId === 'N/A') {
              emptyLabel = {
                id: fgId,
                label: '-',
              };
              return acc;
            }
            return [
              ...acc,
              {
                id: fgId,
                label: labelMap[fgId],
              },
            ];
          }, []),
          (label) => label.label
        );

        if (emptyLabel) {
          resolve([...labels, emptyLabel]);
        }
        resolve(labels);
      });
    });
  },
  buildAggsFilterDsl: (filterValues: object, axisOption?: AxisOption) => {
    const queryFilterPath = [
      frequencyGroupNestedQueryFilter.getId(),
      'esQuery',
      'nested',
      'query',
      'bool',
      'filter',
    ];
    const queryFilter = _.get(filterValues, queryFilterPath, []);
    return {
      filter: {
        bool: {
          filter: [...queryFilter],
        },
      },
    };
  },
  buildQueryFilter: (
    filterValues: object,
    selectedLabels: LabelType[],
    axisOption?: AxisOption
  ) => {
    // build the NestedValueSet for this nested QF.
    const newFrequencyGroupValues: MultiselectQueryFilterValue = {
      includeOptionValues: selectedLabels.map((label) => {
        if (label.id === 'N/A') {
          return UNSET_OPTION_VALUE;
        }
        return label.id;
      }),
    };
    frequencyGroupNameQueryFilter.ensureControlData(newFrequencyGroupValues);

    const nestedValueSet: NestedValueSet = _.get(
      filterValues,
      [frequencyGroupNestedQueryFilter.getId(), 'nestedValueSets', 0],
      {}
    );
    nestedValueSet[frequencyGroupNameQueryFilter.getId()] =
      newFrequencyGroupValues;

    // Build the Nested value and compute esQuery
    const newValue: NestedQueryFilterValue = {
      nestedValueSets: [nestedValueSet],
    };
    newValue.esQuery =
      frequencyGroupNestedQueryFilter.getElasticQuery(newValue);

    // set the new value for this and return any other existing filter values
    return {
      ...filterValues,
      [frequencyGroupNestedQueryFilter.getId()]: newValue,
    };
  },
};
