import { useEffect, useState } from 'react';
import JsonViewer from '../../../components/JsonViewer';
import { useArtifactContents } from '../../../services/newArtifacts';

const JSONExpansion = ({ artifact }) => {
  const { data } = useArtifactContents(artifact.id);

  const [cleanedData, setCleanedData] = useState(null);

  useEffect(() => {
    // If data is a blob or file type, convert to JSON
    if (data && (data instanceof Blob || data instanceof File)) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCleanedData(JSON.parse(reader.result as string));
      };
      reader.readAsText(data);
    } else {
      setCleanedData(data);
    }
  }, [data]);

  return (
    <JsonViewer
      src={cleanedData}
      style={{
        height: 360,
      }}
    />
  );
};

export default JSONExpansion;
