import React from 'react';
import Typography from '@mui/material/Typography';
import cx from 'classnames';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from '@shield-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(0.5),
    marginRight: 0,
    paddingLeft: theme.spacing(1),
    borderLeft: `2px solid ${colors.hues.gray}`,
  },
  message: {
    fontSize: 13,
  },
  messageSmall: {
    fontSize: 11,
  },
  messageLarge: {
    fontSize: 18,
    fontWeight: 300,
    marginLeft: 4,
  },
  rootPrimary: {
    borderColor: theme.palette.primary.main,
  },
  rootWarning: {
    borderColor: theme.palette.warning.main,
  },
  rootError: {
    borderColor: theme.palette.error.main,
  },
}));

type Props = {
  variant?: 'default' | 'primary' | 'warning' | 'error';
  size?: 'small' | 'normal' | 'large';
  children: React.ReactNode;
};

function Blockquote({ variant, size, children }: Props) {
  const classes = useStyles();

  return (
    <blockquote
      className={cx(
        classes.root,
        variant === 'primary' && classes.rootPrimary,
        variant === 'warning' && classes.rootWarning,
        variant === 'error' && classes.rootError
      )}
    >
      <Typography
        variant="body1"
        className={cx(
          classes.message,
          size === 'small' && classes.messageSmall,
          size === 'large' && classes.messageLarge
        )}
      >
        {children}
      </Typography>
    </blockquote>
  );
}

export default Blockquote;
