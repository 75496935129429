import { getTimezoneMinDisplay } from '@shield-ui/utils';
import { getLocalDateTime } from '../../../../../lib/connectedUtils';
import Cell from './Cell';

const config = {
  columnKey: 'flightTimeLocal',
  label: `Time (${getTimezoneMinDisplay()})`,
  sortKey: 'start_time',
  description: 'Start time of flight',
  defaultWidth: 180,
  Cell,
  getValue: ({ row }) => {
    return getLocalDateTime(row.startTime);
  },
  fragment: `
        startTime
        endTime
    `,
};

export default config;
