import _ from 'lodash';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { ensureAllAnalysisNames } from '../../../redux/lists/actions';
import { hasFetchedListType } from '../../../redux/lists/util';

function mapStateToProps(state) {
  return {
    analysisNames: _.get(state, 'lists.listTypeMap.analysisNames', []),
    isLoading: !hasFetchedListType('analysisNames'),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ensureAllAnalysisNames,
    },
    dispatch
  );
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ContainerProps = ConnectedProps<typeof connector>;
export default connector;
