import { useEffect } from 'react';
import _ from 'lodash';
import store from '../store';
import { refreshLists } from './actions';
import { getListTypeMap } from './selectors';
import { INITIAL_EMPTY_LIST } from './index';

export const ensureListsAreLoaded = _.once(() => {
  const state = store.getState();

  const { isRequesting, isInitialized } = state.lists;
  if (isInitialized || isRequesting) {
    return;
  }

  store.dispatch(refreshLists());
});

export const useEnsureListsAreLoaded = () => {
  useEffect(ensureListsAreLoaded);
};

// Check to see if the original const array of the list has been replaced by a fetch
// this fixed array is used so we can do a simple equality check because two empty arrays are not equal
// This was a cleaner, easier solution that adding a loading state variable for each type of list
export const hasFetchedListType = (listTypeKey) => {
  const map = getListTypeMap(store.getState());

  if (!map[listTypeKey]) {
    console.error(`Asking for unknown listTypeKey ${listTypeKey}`);
    return false;
  }
  return map[listTypeKey] !== INITIAL_EMPTY_LIST;
};
