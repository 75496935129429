import React from "react";
import { Select, SelectProps } from 'antd';
import SimpleMultiSelectPreview from "./SimpleMultiSelectPreview";

export interface SimpleMultiselectProps extends SelectProps {
  isPreview?: boolean;
  style?: object;
}

export default function SimpleMultiSelect(props:SimpleMultiselectProps) {
  const { options, value, isPreview = false, style, ...rest } = props;

  if (isPreview) {
    return <SimpleMultiSelectPreview value={value} style={style} options={options} />
  }

  return (
    <Select
      mode='multiple'
      options={options}
      value={value}
      filterOption={true}
      optionFilterProp='label'
      {...rest}
    />
  );
}
