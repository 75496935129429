import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { FlatLogEventObjectMerged } from '../../../services/logEvents';
import LogEventTree from './LogEventTree';
type Props = {
  logEvent: FlatLogEventObjectMerged;
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
}));

function LogEventExtraDetails(props: Props) {
  const { logEvent } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <LogEventTree logEvent={logEvent} />
    </div>
  );
}

export default LogEventExtraDetails;
