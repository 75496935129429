import React from 'react';
import { Grow } from '@shield-ui/core';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import PipelineRow from './PipelineRow';
import { filterGroups } from './filter';

/**
 * List of pipeline execution pipeline names (e.g. hmc-grep)
 */
export default function GroupList(props) {
  const { groups, onGroupClick, searchString } = props;
  return (
    <div>
      {filterGroups(groups, searchString).map((group) => {
        const { label } = group;
        return (
          <PipelineRow
            key={label}
            onClick={() => {
              // Skip directly to the RUN if the GROUP only has one RUN in it
              const run =
                group.pipelineRuns.length === 1
                  ? group.pipelineRuns[0]
                  : undefined;
              onGroupClick(group, run);
            }}
          >
            <div>{label}</div>
            <Grow />
            <KeyboardArrowRight />
          </PipelineRow>
        );
      })}
    </div>
  );
}
