import { Typography, Popconfirm, AutoCompleteProps } from 'antd';
import { isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react';
import { SessionLogProduct, VBATMetadataKey } from '../../services/robotLogs';
import { MetadataFieldObj } from '../../services/passThruSearch';

export interface MetadataKeyInputProps extends AutoCompleteProps {
  value: string;
  field: MetadataFieldObj;
  onChange: (key: string) => void;
  excludedKeys?: VBATMetadataKey[];
  disabled?: boolean;
  showKeys?: boolean;
  productType?: SessionLogProduct;
  platform_id?: string;
  style?: React.CSSProperties;
}

export interface MetadataValueInputProps extends AutoCompleteProps {
  value: string;
  field: MetadataFieldObj;
  onChange: (value: string) => void;
  productType?: SessionLogProduct;
  platform_id?: string;
  style?: React.CSSProperties;
}

interface EditableRowProps extends React.HTMLAttributes<HTMLElement> {
  field: MetadataFieldObj;
  onEdit: (key: string) => void;
  onCancel: (key: string) => void;
  onSave: (field: MetadataFieldObj) => void;
  editing: boolean;
  disabled: boolean;
  productType?: SessionLogProduct;
  platform_id?: string;
  excludedKeys: VBATMetadataKey[];
  keyInput: React.ComponentType<MetadataKeyInputProps>;
  valueInput: React.ComponentType<MetadataValueInputProps>;
}

export const EditableMetadataRow: React.FC<EditableRowProps> = React.memo(
  ({
    field,
    onEdit,
    onCancel,
    onSave,
    editing,
    disabled,
    productType,
    excludedKeys,
    keyInput,
    valueInput,
    ...restProps
  }) => {
    const [metadataField, setMetadataField] = useState(field);
    const [isDirty, setIsDirty] = useState(false);

    const KeyInput = keyInput;
    const ValueInput = valueInput;

    useEffect(() => {
      setMetadataField(field);
    }, [field]);

    const onEditClick = () => {
      onEdit(field.metadata_key);
    };

    const onSaveClick = () => {
      if (
        !isEmpty(metadataField.metadata_key) &&
        !isEmpty(metadataField.value)
      ) {
        onSave(metadataField);
      }
    };

    const onCancelClick = () => {
      onCancel(field.metadata_key);
      setMetadataField(field);
    };

    const onKeyChange = (value: string) => {
      setMetadataField({ ...metadataField, metadata_key: value });
      setIsDirty(true);
    };

    const onValueChange = (value: string) => {
      setMetadataField({ ...metadataField, value });
      setIsDirty(true);
    };

    const isNewField = field.metadata_key === '';

    const isNotSavable =
      isEmpty(metadataField.metadata_key) || isEmpty(metadataField.value);

    if (editing) {
      return (
        <tr key={field.metadata_key} {...restProps}>
          <td>
            {isNewField ? (
              <KeyInput
                value={metadataField.metadata_key}
                field={field}
                onChange={onKeyChange}
                disabled={disabled}
                productType={productType}
                excludedKeys={excludedKeys}
                style={{ width: '100%' }}
              />
            ) : (
              field.metadata_key
            )}
          </td>
          <td>
            <ValueInput
              value={metadataField.value}
              field={metadataField}
              onChange={onValueChange}
              productType={productType}
            />
          </td>
          <td>
            <span className="metadata-row-options">
              <Typography.Link
                onClick={() => onSaveClick()}
                style={{ marginRight: 8 }}
                disabled={isNotSavable}
              >
                Save
              </Typography.Link>
              {isDirty ? (
                <Popconfirm
                  title="Sure to cancel?"
                  onConfirm={() => onCancelClick()}
                >
                  <Typography.Link>Cancel</Typography.Link>
                </Popconfirm>
              ) : (
                <Typography.Link onClick={() => onCancelClick()}>
                  Cancel
                </Typography.Link>
              )}
            </span>
          </td>
        </tr>
      );
    }

    return (
      <tr key={field.metadata_key} {...restProps}>
        <td>{field.metadata_key}</td>
        <td>{field.value}</td>
        <td>
          <Typography.Link
            className="edit-button"
            style={{ float: 'right' }}
            disabled={disabled}
            onClick={onEditClick}
          >
            Edit
          </Typography.Link>
        </td>
      </tr>
    );
  }
);
