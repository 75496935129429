import { GridSpacing } from '@mui/material';

interface SpacingType {
  defaultGridSpacing: GridSpacing;
  unit: number;
}

const spacing: SpacingType = {
  defaultGridSpacing: 8,
  unit: 8,
};

export default spacing;
