import React from 'react';
import classNames from 'classnames';
import withStyles from '@mui/styles/withStyles';
import _ from 'lodash';
import { REVIEW_STATUS_DISPLAYS } from '../../../../../services/reviews';
import { reviewStatusColors } from '../../../../../lib/sharedStyles';

function styles() {
  return {
    row: {
      borderLeft: `3px solid #bbb`,
      marginTop: 12,
      marginBottom: 12,
      paddingLeft: 8,
    },
    ..._.reduce(
      reviewStatusColors,
      (acc, value, key) => {
        acc[key] = {
          borderColor: value,
          color: value,
        };
        return acc;
      },
      {}
    ),
    status: {
      fontSize: 10,
      width: 100,
      marginBottom: 2,
      textTransform: 'uppercase',
    },
    name: {
      fontSize: 16,
      color: 'white',
    },
  };
}

export function getParsedReviews(row, relationshipKey) {
  let reviews = _.get(row, [relationshipKey, 'edges'], []).map((edge) => {
    const review = edge.node;
    const status = _.get(review, 'reviewStatus', '');
    const displayStatus =
      REVIEW_STATUS_DISPLAYS[status] ||
      _.upperFirst(status.replace('_', ' ').toLowerCase());

    return {
      displayStatus,
      reviewStatus: status,
      createdAt: review.createdAt,
      name:
        _.get(review, 'reviewer.name', '') ||
        _.get(review, 'reviewerTeam.name', ''),
    };
  });

  reviews = _.sortBy(reviews, 'createdAt');

  return reviews;
}

function ReviewsCell({ classes, row, relationshipKey }) {
  const reviews = getParsedReviews(row, relationshipKey);

  return reviews.map(({ displayStatus, reviewStatus, name }, i) => {
    return (
      <div key={i} className={classNames(classes.row, classes[reviewStatus])}>
        <div className={classes.status}>{displayStatus}</div>
        <div className={classes.name}>{name}</div>
      </div>
    );
  });
}
export default withStyles(styles)(ReviewsCell);
