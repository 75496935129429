import React, {
  useEffect,
  useCallback,
  useMemo,
  useState,
  useRef,
} from 'react';
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
  MRT_ToggleDensePaddingButton,
} from 'material-react-table';
import _ from 'lodash';
import history from '../../../routes/history';
import { colors } from './table/common';
import { Box, IconButton, lighten, Tooltip } from '@mui/material';
import { ValueTypes } from '../../../lib/dcoAttributes';
import ColumnIcon from '@mui/icons-material/ViewColumn';
import { TABLE_MODAL_TYPES } from '../../../redux/tables/constants';
import { LoadingIndicator } from '@shield-ui/core';
import { isSortable } from './utils';

export const ROW_DISPLAY_PROPERTIES = {
  IS_UNREAD: 'TABLE_ROW_IS_UNREAD',
};

const fixedColumnDefs = (colName, props) => {
  const { getColumnDefinition, widths } = props;

  if (!colName) {
    return;
  }
  const def = getColumnDefinition({ columnKey: colName });

  if (!def) {
    console.warn(`Missing definition for column: ${colName}`);
    return;
  }
  const label = def.getLabel ? def.getLabel({ column: colName }) : def.label;
  const columnWidth = widths
    ? widths[colName]
      ? widths[colName]
      : def.defaultWidth
    : def.defaultWidth;

  return {
    id: colName,
    accessorFn: (row) => def.getValue({ row }),
    header: label,
    Header: <span style={{ whiteSpace: 'normal' }}>{label}</span>,
    size: columnWidth,
    enablePinning: false,
    enableColumnDragging: false,
    enableSorting: !!isSortable(def),
    ...(def.Cell && {
      Cell: ({ row }) => (
        <div>
          <def.Cell {...props} row={row.original} />
        </div>
      ),
    }),
    //align quantitative values to the right
    ...(def.valueType === ValueTypes.number && {
      muiTableBodyCellProps: {
        align: 'right',
      },
    }),
  };
};

const regularColumnDefs = (column, props) => {
  const { getColumnDefinition, widths } = props;

  if (!column) {
    return;
  }
  const { columnKey } = column;
  const def = getColumnDefinition({ columnKey });

  if (!def) {
    console.warn(`Missing definition for column: ${columnKey}`);
    return;
  }

  const label = def.getLabel ? def.getLabel({ column }) : def.label;
  const columnId = column.columnUid || columnKey;
  const columnWidth = widths
    ? widths[columnId]
      ? widths[columnId]
      : def.defaultWidth
    : def.defaultWidth;
  return {
    id: columnId,
    accessorFn: (row) => def.getValue({ row, column }),
    header: label,
    Header: <span style={{ whiteSpace: 'normal' }}>{label}</span>,
    size: columnWidth,
    ...(def.Cell && {
      Cell: ({ row }) => (
        <div>
          <def.Cell {...props} row={row.original} column={column} />
        </div>
      ),
    }),
    enableSorting: !!isSortable(def),
    //align quantitative values to the right
    ...(def.valueType === ValueTypes.number && {
      muiTableBodyCellProps: {
        align: 'right',
      },
    }),
  };
};

const buildColumnDefinitions = (props) => {
  const { columns, prependContextColumns, tableFixedColumnNames } = props;
  const columnDefs = [];

  tableFixedColumnNames?.map((colName) => {
    columnDefs.push(fixedColumnDefs(colName, props));
  });

  prependContextColumns?.map((column) => {
    columnDefs.push(regularColumnDefs(column, props));
  });

  columns?.map((column) => {
    columnDefs.push(regularColumnDefs(column, props));
  });

  return _.compact(columnDefs);
};

const onCurrentPageChange = (props, pageNumber) => {
  const { setPagination, tableCacheKey, pagination, result } = props;
  const { startCursor, endCursor } = result;

  if (pagination.after === endCursor || pagination.before === startCursor) {
    console.warn(
      `trying to change pages while the previous request is still processing`
    );
    return;
  }

  setPagination({
    tableCacheKey,
    page: pageNumber,
    after: endCursor,
    before: startCursor,
  });
};

const onPageSizeChange = (props, pageSize) => {
  const { setPageSize, tableCacheKey } = props;
  setPageSize({
    tableCacheKey,
    pageSize,
  });
};

const onPinnedColumnsChange = (props, newPinnedState) => {
  const { tableCacheKey, updatePinnedColumns } = props;
  updatePinnedColumns({
    tableCacheKey,
    newPinnedState,
  });
};

const onColumnOrderChange = (props, columnNameOrder) => {
  const { tableCacheKey, updateColumnOrder } = props;
  updateColumnOrder({
    tableCacheKey,
    columnNameOrder,
  });
};

const onColumnSizingChange = (props, colSizingState) => {
  const { tableCacheKey, updateColumnWidths } = props;
  updateColumnWidths({
    tableCacheKey,
    colSizingState,
  });
};

const pinnedColumnBorder = (column, borderSide, columnPinning) => {
  if (Object.keys(columnPinning).length === 0) {
    return 'none';
  }

  if (borderSide === 'right') {
    return columnPinning.left.includes(column.id)
      ? `1px solid ${colors.border}`
      : 'none';
  } else if (borderSide === 'left') {
    return columnPinning.right.includes(column.id)
      ? `1px solid ${colors.border}`
      : 'none';
  }
};

const onColumnConfig = (props) => {
  const { setVisibleModal, tableCacheKey } = props;
  setVisibleModal({
    tableCacheKey,
    visibleControlsModal: TABLE_MODAL_TYPES.columnConfig,
  });
};

function shouldIgnoreEvent(evt) {
  return evt.target.nodeName === 'A' || evt.target.parentNode.nodeName === 'A';
}

const ReactTable = (props) => {
  const {
    result,
    pinnedColumns,
    tableColumnOrder: columnOrder,
    tableSorting,
    tableCacheKey,
    setSort,
    onRowContextMenu,
    widths,
    onRowClick,
    enableTopToolbar = true,
    renderExtraTableControls,
  } = props;
  const { rows, total: totalRows, isLoading: dataLoading } = result;

  //data and refetching state
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  //table states
  const columns = useMemo(
    () => buildColumnDefinitions(props),
    [
      props.tableFixedColumns,
      props.columns,
      props.widths,
      props.prependContextColumns,
      tableCacheKey,
    ]
  );

  //using local state to manage column pinning, sorting, and pagination due to how react-table's onChange returns an
  //updater function rather than new state https://www.material-react-table.com/docs/guides/table-state-management#add-side-effects-in-set-state-callbacks .
  const [columnPinning, setColumnPinning] = useState(pinnedColumns);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: props.pageSize || 15,
  });
  const [selectedRow, setSelectedRow] = useState({});
  const [mouseDownEvt, setMouseDownEvt] = useState(null);
  const [columnSizing, setColumnSizing] = useState(widths);
  //useEffect because results don't always load on initial render
  useEffect(() => {
    setIsLoading(dataLoading);
    setData(rows);
  }, [result]);

  useEffect(() => {
    onCurrentPageChange(props, pagination.pageIndex);
  }, [pagination.pageIndex, props.setPagination, tableCacheKey]);

  useEffect(() => {
    onPageSizeChange(props, pagination.pageSize);
  }, [pagination.pageSize, props.setPageSize, tableCacheKey]);

  useEffect(() => {
    setColumnPinning(pinnedColumns);
  }, [pinnedColumns, tableCacheKey]);

  useEffect(() => {
    onPinnedColumnsChange(props, columnPinning);
  }, [columnPinning, tableCacheKey]);

  // the state returned from getSortState turns out to be somewhat unreliable
  // certain columns would not correctly flip from asc/desc
  // this was reworked to work around this glitchy behavior in material-react-table
  const onSortingChange = useCallback(
    (getSortState) => {
      const sortCols = getSortState();
      const col = sortCols[0];

      // some kevlar, maybe unncessary
      if (!col || !col.id) {
        return;
      }

      // grab the current table value
      const curCol = tableSorting[0];

      const updates = {
        tableCacheKey,
        orderByColumn: col.id,
        orderByDirection: '',
      };

      if (curCol.id === col.id) {
        // invert current value
        updates.orderByDirection = curCol.desc ? 'asc' : 'desc';
      } else {
        // default for new column
        updates.orderByDirection = 'asc';
      }
      setSort(updates);
    },
    [setSort, tableCacheKey, tableSorting]
  );

  useEffect(() => {
    setColumnSizing(widths);
  }, [widths, tableCacheKey]);

  useEffect(() => {
    onColumnSizingChange(props, columnSizing);
  }, [columnSizing, tableCacheKey]);

  return (
    <div>
      {isLoading ? (
        <div>
          <LoadingIndicator />
        </div>
      ) : (
        <div>
          <MaterialReactTable
            columns={columns}
            data={data}
            enableHiding={false}
            enableFilters={false}
            enableColumnOrdering
            onColumnOrderChange={(colOrderState) =>
              onColumnOrderChange(props, colOrderState)
            }
            enablePinning
            onColumnPinningChange={setColumnPinning}
            enableColumnResizing
            columnResizeMode="onChange"
            rowCount={totalRows}
            manualPagination
            onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
            manualSorting
            onSortingChange={onSortingChange}
            onColumnSizingChange={setColumnSizing}
            state={{
              pagination,
              columnPinning,
              columnOrder,
              sorting: tableSorting,
              //columnSizing - instead of setting width state here, we change it in props and use that value to define 'size' when creating column definitions
            }}
            enableTopToolbar={enableTopToolbar}
            renderToolbarInternalActions={({ table }) => (
              <Box>
                {renderExtraTableControls && renderExtraTableControls()}
                <MRT_FullScreenToggleButton table={table} />
                <MRT_ToggleDensePaddingButton table={table} />
                <Tooltip title="Configure Columns" arrow>
                  <IconButton onClick={() => onColumnConfig(props)}>
                    <ColumnIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            muiTableHeadCellDragHandleProps={{
              sx: {
                padding: '0px',
              },
            }}
            muiTableHeadCellColumnActionsButtonProps={{
              sx: {
                padding: '0px',
              },
            }}
            muiTableHeadCellProps={({ column }) => ({
              sx: {
                fontWeight: 500,
                //add a border dividing pinned headers from rest of table
                borderRight: pinnedColumnBorder(column, 'right', columnPinning),
                borderLeft: pinnedColumnBorder(column, 'left', columnPinning),
                borderBottom: `1px solid ${colors.border}`,
                '& .Mui-TableHeadCell-Content-Actions': {
                  opacity: 0,
                  transition: 'opacity 0.1s ease-in-out',
                },
                '& .Mui-TableHeadCell-ResizeHandle-Wrapper': {
                  opacity: 0,
                  padding: 0,
                  transition: 'opacity 0.1s ease-in-out',
                },
                '&:hover .Mui-TableHeadCell-Content-Actions, &:hover .Mui-TableHeadCell-ResizeHandle-Wrapper':
                  {
                    opacity: 1,
                  },
              },
            })}
            muiTableBodyRowProps={({ row }) => ({
              onAuxClick: (evt) => {
                if (shouldIgnoreEvent(evt)) {
                  return;
                }
                onRowClick({ row: row.original });
                window.open(row.original.url);
              },
              onMouseDown: (evt) => {
                if (shouldIgnoreEvent(evt)) {
                  return;
                }
                // only left click
                if (evt.button !== 0) {
                  return;
                }

                setSelectedRow(row.original);
                setMouseDownEvt(evt);
              },
              onMouseUp: (evt) => {
                if (shouldIgnoreEvent(evt)) {
                  return;
                }

                // left click only
                if (evt.button !== 0) {
                  return;
                }
                const clientX = evt.clientX;
                const clientY = evt.clientY;

                // clicked on a different row and ended up here e.g. we didn't have a mouseDown event
                if (selectedRow !== row.original) {
                  return;
                }

                // Some arbitrary number that represents a decent amount of movement between mouse down and mouse up
                // like the user was dragging or something
                if (
                  Math.abs(clientX - mouseDownEvt.clientX) > 4 ||
                  Math.abs(clientY - mouseDownEvt.clientY) > 4
                ) {
                  return;
                }

                if (evt.ctrlKey || evt.metaKey) {
                  onRowClick({ row: row.original });
                  window.open(row.original.url);
                  return;
                }

                onRowClick({ row: row.original });
                history.push(row.original.url);
              },
              onContextMenu: (evt) => {
                onRowContextMenu({ row: row.original, evt });
              },
              sx: (theme) => ({
                cursor: 'pointer',
                //unread behavior
                '& td:first-of-type': {
                  borderLeft: row.original[ROW_DISPLAY_PROPERTIES.IS_UNREAD]
                    ? `3px solid ${theme.palette.secondary.dark}`
                    : `none`,
                },
              }),
            })}
            muiTableBodyCellProps={({ column }) => ({
              sx: (theme) => ({
                //add a border dividing pinned columns from rest of table
                borderRight: pinnedColumnBorder(column, 'right', columnPinning),
                borderLeft: pinnedColumnBorder(column, 'left', columnPinning),
                borderBottom: `1px solid ${colors.border}`,
              }),
            })}
            muiTableBodyProps={{
              sx: (theme) => ({
                //zebra columns
                '& td:nth-of-type(even)': {
                  backgroundColor: lighten(
                    theme.palette.background.default,
                    0.01
                  ),
                },
              }),
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ReactTable;
