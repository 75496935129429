import _ from 'lodash';
import { gql } from '@apollo/client';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getStringFromErrorLike } from '@shield-ui/utils';
import {
  HangarRobotLogType,
  HangarAnalysisType,
  HangarMetricType,
  HangarPipelineExecutionType,
} from '@shield-ui/hangar-service';
import client from '../apollo-client';
import { ANALYSIS_RUN_STATUSES } from '../services/analysis';
import { Artifact } from '@hmd/sdk/api/artifacts/v1';
import { getSessionLogAndArtifacts } from '../services/newArtifacts';
import { structValueToBasicStructure } from '../services/passThruSearch';

export const PIPELINE_EXPLORER_FEATURE_KEY = 'pipelineExplorer';

export interface ArtifactResult extends Artifact.AsObject {
  metadataObject: Record<string, any>;
}

export interface PipelineExplorerContext {
  fetching: boolean;
  fetched: boolean;
  analysisResults: HangarAnalysisType[];
  metricResults: HangarMetricType[];
  artifactResults: ArtifactResult[];
  pipelineExecutionResults: HangarPipelineExecutionType[];
  errorMessage?: string;
}

const defaultPipelineExplorerContext: PipelineExplorerContext = {
  fetching: false,
  fetched: false,
  analysisResults: [],
  metricResults: [],
  artifactResults: [],
  pipelineExecutionResults: [],
};

interface PipelineExplorerState {
  forRobotLog: Record<HangarRobotLogType['id'], PipelineExplorerContext>;
}

const initialPipelineExplorerState: PipelineExplorerState = {
  forRobotLog: {},
};

interface ClearResultsForRobotLogPayload {
  robotLogId: HangarRobotLogType['id'];
}

export interface RefreshResultsForRobotLogArgs {
  robotLogId: HangarRobotLogType['id'];
  skipClearOldResults?: boolean;
}

function mapEdgesAndJsonParse(
  result: object,
  pickPath: string,
  jsonKeys: string[] = []
) {
  return _.get(result, pickPath, []).map((edge) => {
    const node = {
      ...edge.node,
    };

    jsonKeys.forEach((key) => {
      //node[`${key}_json`] = node[key];
      node[key] = JSON.parse(node[key]);
    });

    return node;
  });
}

async function getArtifactsLinked(
  sessionLogId: string
): Promise<ArtifactResult[]> {
  const res = await getSessionLogAndArtifacts(sessionLogId);

  // This means that this sessionLog has NO artifacts
  if (!res[sessionLogId]) {
    return [];
  }
  const { artifacts } = res[sessionLogId];

  return artifacts
    .filter((al) => {
      return al.artifact.artifactTypeSlug === 'plotly-json';
    })
    .map((al) => {
      return {
        ...al.artifact,
        metadataObject: structValueToBasicStructure(al.artifact.metadata),
      };
    });
}

export const refreshResultsForRobotLog = createAsyncThunk(
  `${PIPELINE_EXPLORER_FEATURE_KEY}/refreshResultsForRobotLog`,
  async (args: RefreshResultsForRobotLogArgs, thunkAPI) => {
    const { robotLogId } = args;

    const query = gql`
      query ($robotLogId: UUID!) {
        analyses(robotLogIds: [$robotLogId], sort: created_at_desc) {
          edges {
            node {
              id
              name
              framework
              data
              testPassed
              runStatus
              pipelineExecutionId
              createdAt
            }
          }
        }
        metrics(robotLogIds: [$robotLogId], sort: created_at_desc) {
          edges {
            node {
              id
              name
              dataType
              value
              pipelineExecutionId
              createdAt
            }
          }
        }
      }
    `;

    const result = await client.query({
      query,
      variables: {
        robotLogId,
      },
      fetchPolicy: 'network-only',
    });

    const artifactResults = await getArtifactsLinked(robotLogId);

    const analysisResults = mapEdgesAndJsonParse(
      result,
      'data.analyses.edges',
      ['data']
    ).filter((a) => {
      // we have this whole pending kind of thing with analysis that we don't support
      // displaying correctly in the pipeline explorer. Just filter these out, they will show up soon
      return !a.runStatus || a.runStatus === ANALYSIS_RUN_STATUSES.DONE;
    });
    const metricResults = mapEdgesAndJsonParse(result, 'data.metrics.edges', [
      'value',
    ]);

    // after we have all our objects, go get all the relevant pipeline executions
    let pipelineExecutionIds = analysisResults
      .map((a) => a.pipelineExecutionId)
      .concat(metricResults.map((m) => m.pipelineExecutionId))
      .concat(
        artifactResults.map(
          (a) =>
            a.metadataObject.pipelineExecutionId ||
            a.metadataObject['pipeline_execution_id']
        )
      );

    pipelineExecutionIds = _.uniq(_.compact(pipelineExecutionIds));

    const executionsQuery = gql`
      query ($ids: [UUID]!) {
        pipelineExecutions(ids: $ids) {
          edges {
            node {
              id
              name
              description
              createdAt
            }
          }
        }
      }
    `;

    const peResult = await client.query({
      query: executionsQuery,
      variables: {
        ids: pipelineExecutionIds,
      },
      fetchPolicy: 'network-only',
    });

    const pipelineExecutionResults = mapEdgesAndJsonParse(
      peResult,
      'data.pipelineExecutions.edges',
      []
    );

    return {
      analysisResults,
      metricResults,
      artifactResults,
      pipelineExecutionResults,
    };
  }
);

type RefreshResultsForRobotLogPayload = Pick<
  PipelineExplorerContext,
  | 'analysisResults'
  | 'metricResults'
  | 'artifactResults'
  | 'pipelineExecutionResults'
>;

export const pipelineExplorerSlice = createSlice({
  name: PIPELINE_EXPLORER_FEATURE_KEY,
  initialState: initialPipelineExplorerState,
  reducers: {
    // delete this from state, say when you are unmounting a component, changing pages, etc...
    clearResultsForRobotLog: (
      state,
      action: PayloadAction<ClearResultsForRobotLogPayload>
    ) => {
      const { robotLogId } = action.payload;
      delete state.forRobotLog[robotLogId];
    },
  },
  extraReducers: {
    [refreshResultsForRobotLog.pending.type]: (
      state,
      action: PayloadAction<
        object,
        string,
        { arg: RefreshResultsForRobotLogArgs }
      >
    ) => {
      const { robotLogId } = action.meta.arg;

      state.forRobotLog[robotLogId] = {
        ...defaultPipelineExplorerContext,
        fetching: true,
      };
    },
    [refreshResultsForRobotLog.rejected.type]: (
      state,
      action: PayloadAction<
        object,
        string,
        { arg: RefreshResultsForRobotLogArgs },
        Error
      >
    ) => {
      const { error } = action;
      const { robotLogId } = action.meta.arg;

      state.forRobotLog[robotLogId].fetching = false;
      state.forRobotLog[robotLogId].errorMessage =
        getStringFromErrorLike(error);
    },
    [refreshResultsForRobotLog.fulfilled.type]: (
      state,
      action: PayloadAction<
        RefreshResultsForRobotLogPayload,
        string,
        { arg: RefreshResultsForRobotLogArgs }
      >
    ) => {
      const { robotLogId, skipClearOldResults } = action.meta.arg;
      const {
        analysisResults,
        metricResults,
        artifactResults,
        pipelineExecutionResults,
      } = action.payload;

      // when we get the results, clear out old results unless a flag was passed in
      // this is the default behavior since most UIs would be built around displaying an
      // pipeline explorer for a single robot log
      if (!skipClearOldResults) {
        Object.keys(state.forRobotLog).forEach((id) => {
          if (id === robotLogId) {
            return;
          }
          state.forRobotLog[id] = undefined;
        });
      }

      state.forRobotLog[robotLogId] = {
        fetching: false,
        fetched: true,
        analysisResults,
        metricResults,
        artifactResults,
        pipelineExecutionResults,
      };
    },
  },
});

export const { clearResultsForRobotLog } = pipelineExplorerSlice.actions;

export function getContextForRobotLogId(
  state,
  robotLogId
): PipelineExplorerContext {
  return state[PIPELINE_EXPLORER_FEATURE_KEY].forRobotLog[robotLogId];
}
