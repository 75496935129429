import Cell, { getLabelsFromRow } from './Cell';

const config = {
  columnKey: 'labels',
  label: 'Labels',
  description: 'Labels applied to the source item',
  defaultWidth: 240,
  getElasticSort: ({ orderByDirection }) => {
    return [
      {
        'labels.name.keyword': {
          order: orderByDirection,
          missing: '_last',
        },
      },
    ];
  },
  Cell,
  getValue: ({ row }) => {
    return getLabelsFromRow(row)
      .map((label) => {
        return label.name;
      })
      .join('\n');
  },
  fragment: `
    labels {
        edges {
            node {
              id
              name
            }
        }
    }
    `,
};

export default config;
