import React from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ControlBarLayoutPreview from './ControlBarLayoutPreview';

interface Props {
  onClick: (evt) => void;
  count?: number;
}

export default function MoreAction(props: Props) {
  const { onClick, count = 0 } = props;

  return (
    <ControlBarLayoutPreview
      label={count > 0 ? undefined : 'More'}
      valuePreview={count > 0 ? `More (${count})` : undefined}
      Icon={MoreHorizIcon}
      onClick={onClick}
    />
  );
}
