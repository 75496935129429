import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { Provider as ReduxProvider } from 'react-redux';
import { QueryClientProvider } from '@tanstack/react-query';
import { Helmet } from 'react-helmet';
import { StyleRoot } from '@shield-ui/styles';
import { ErrorBoundaryApp } from '@shield-ui/core';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { parse, stringify } from 'query-string';
import { initTracker } from './lib/tracker';
import history from './routes/history';
import store from './redux/store';
import Routes from './routes/Routes';
import ModalController from './components/modals/ModalController';
import SnackbarController from './components/snackbar/SnackbarController';
import ConfirmPrompt from './components/ConfirmPrompt';
import client from './apollo-client';
import { queryClient } from './queryClient';
import './global.css';

export default function App() {
  useEffect(() => {
    initTracker();
  }, []);

  return (
    <ErrorBoundaryApp>
      <StyleRoot>
        <QueryClientProvider client={queryClient}>
          <ReduxProvider store={store}>
            <ModalController />
            <SnackbarController />
            <ConfirmPrompt />
            <Helmet>
              {/*Default Page Title */}
              <title>Vision: ShieldAI</title>
            </Helmet>
            <ApolloProvider client={client}>
              <Router history={history}>
                <QueryParamProvider
                  adapter={ReactRouter5Adapter}
                  options={{
                    searchStringToObject: parse,
                    objectToSearchString: stringify,
                  }}
                >
                  <Routes />
                </QueryParamProvider>
              </Router>
            </ApolloProvider>
          </ReduxProvider>
        </QueryClientProvider>
      </StyleRoot>
    </ErrorBoundaryApp>
  );
}
