import _ from 'lodash';
import { LabelIdType, LabelType } from '@shield-ui/heatgrid';
import {
  MultiselectQueryFilterValue,
  UNSET_OPTION_VALUE,
} from '@shield-ui/query-filters';
import { default as environmentQueryFilter } from '../../../lib/queryFilters/robotLog/testDefinitionEnvironments';
import { fetchEnvironments } from '../../../services/environments';
import { AxisDefinition, AxisOption } from './AxisDefinition';

export const environmentAxisDefinition: AxisDefinition = {
  id: 'Environment',
  getDropdownLabel: () => 'Environments',
  buildAggsDsl: () => ({
    terms: {
      field: 'test_definition.environments.id',
      missing: 'N/A',
    },
  }),
  prepareAxisLabels: (labelIds: LabelIdType[]) => {
    return fetchEnvironments().then((res) => {
      const envMap = _.get(res, 'data.environments.edges', []).reduce(
        (acc, e) => ({
          ...acc,
          [e.node.id]: e.node,
        }),
        {}
      );

      let emptyLabel: LabelType = undefined;
      const labels: LabelType[] = _.sortBy(
        labelIds.reduce((acc, envId) => {
          if (envId === 'N/A') {
            emptyLabel = {
              id: envId,
              label: '-',
            };
          } else if (envMap[envId]) {
            acc.push({
              id: envId.toString(),
              label: envMap[envId].name,
            });
          } else {
            acc.push({
              id: envId,
              label: envId,
            });
          }
          return acc;
        }, []),
        (label) => label.label
      );

      if (emptyLabel) {
        return [...labels, emptyLabel];
      }
      return labels;
    });
  },
  buildQueryFilter: (
    filterValues: object,
    selectedLabels: LabelType[],
    axisOption?: AxisOption
  ) => {
    // build the QF value.
    const newEnvironmentValues: MultiselectQueryFilterValue = {
      includeOptionValues: selectedLabels.map((label) => {
        if (label.id === 'N/A') {
          return UNSET_OPTION_VALUE;
        }
        return label.id;
      }),
    };

    newEnvironmentValues.esQuery =
      environmentQueryFilter.getElasticQuery(newEnvironmentValues);

    // set the new value for this and return any other existing filter values
    return {
      ...filterValues,
      [environmentQueryFilter.getId()]: newEnvironmentValues,
    };
  },
};
