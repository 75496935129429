import _ from 'lodash';
import { getLocalDateTime } from '../../../../../lib/connectedUtils';

const config = {
  columnKey: 'viewedLastAt',
  label: 'Last View',
  description: 'The last time you viewed this log',
  //sortKey: 'my_user_robot_log_detail_viewed_last_at',
  // XXX - no analog with document search
  getValue: ({ row }) => {
    const ts = _.get(row, 'myUserRobotLogDetail.viewedLastAt');
    return getLocalDateTime(ts);
  },
  fragment: `
        myUserRobotLogDetail {
            viewedLastAt
        }
    `,
};

export default config;
