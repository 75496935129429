import { MultiselectQueryFilter } from '@shield-ui/query-filters';
import { fetchEnvironmentOptions } from "../../../services/environments";

export default new MultiselectQueryFilter({
  id: 'environments',
  controlLabel: 'Environments',
  esField: 'environments.id',
  behaviorConfig: {
    fetchOptions: () => {
      return fetchEnvironmentOptions();
    },
  },
});
