import React from 'react';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from '@shield-ui/styles';
import ExpandIcon from './ExpandIcon';
import { onEventStopIfUserSelection } from '@shield-ui/utils';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  isExpanded?: boolean;
}

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(1),
    padding: theme.spacing(2.5, 0, 2.5, 4),
    display: 'flex',
    flexDirection: 'row',
    //alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    background: colors.hues.grays[170],
  },
  clickable: {
    transition: '0.15s ease',
    cursor: 'pointer',
    '&:hover': {
      background: colors.hues.grays[160],
    },
  },
  expandStatusContainer: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(2.5),
  },
  children: {
    flex: 1,
  },
}));

function ChildRow(props: Props) {
  const { children, isExpanded, onClick, ...rest } = props;

  const classes = useStyles();
  return (
    <div
      className={cx(classes.container, !!onClick && classes.clickable)}
      onClick={onEventStopIfUserSelection(onClick)}
      {...rest}
    >
      <ExpandIcon
        isExpanded={isExpanded}
        className={classes.expandStatusContainer}
      />
      <div className={classes.children}>{children}</div>
    </div>
  );
}

export default ChildRow;
