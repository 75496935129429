import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import { AntButton } from '@shield-ui/buttons';
import { colors } from '@shield-ui/styles';
import { useDidMount } from '@shield-ui/hooks';
import {
  TypeToFindMultiselectControlData,
  TypeToFindMultiselectQueryFilterValue,
} from '../../filterTypes';
import { ControlProps } from '../common';
import ResetButton from '../shared/ResetButton';
import SearchInput from '../shared/SearchInput';
import CheckSelectOptions from '../shared/CheckSelectOptions';
import ActionsContainer from '../shared/ActionsContainer';
import ListOperatorSwitch from '../shared/ListOperatorSwitch';
import IncludeExcludeMultiselectTabs from '../shared/IncludeExcludeMultiselectTabs';

interface Props
  extends ControlProps<TypeToFindMultiselectQueryFilterValue>,
    TypeToFindMultiselectControlData {}

const useStyles = makeStyles((theme) => ({
  searchWrapper: {
    marginBottom: theme.spacing(2),
  },
  sectionHeader: {
    fontSize: 10,
    color: colors.hues.grays[80],
    marginBottom: theme.spacing(0.5),
  },
  extraSection: {
    marginTop: theme.spacing(1.5),
    paddingTop: theme.spacing(1),
    borderTop: `1px solid ${colors.hues.grays[140]}`,
  },
}));

function safeBuildOptions(values, cache) {
  return values.map((val) => cache[val]).filter((opt) => !!opt);
}

interface TypeToFindContentProps extends Props {
  variant: 'include' | 'exclude';
}

function TypeToFindContent(props: TypeToFindContentProps) {
  const classes = useStyles();
  const {
    variant,
    onChange,
    onClear,
    value = {},
    hasValue,
    suggestedOptionValues,
    searchOptionValues,
    optionsCache,
    isMulti,
    onSearch,
    onSearchSuggestedOptions,
    searchInput,
    isSearching,
    disableListOperator,
  } = props;

  let optionValueKey = '';
  let operatorKey = '';
  if (variant === 'include') {
    optionValueKey = 'includeOptionValues';
    operatorKey = 'includeOperator';
  } else if (variant === 'exclude') {
    optionValueKey = 'excludeOptionValues';
    operatorKey = 'excludeOperator';
  }

  const optionValues = (value && value[optionValueKey]) || [];

  // ensure we have fetched our suggested options if configured
  useDidMount(onSearchSuggestedOptions);

  const { options, extraOptions, noResultsMessage, header } = useMemo(() => {
    const dynamicValues =
      searchInput.length > 0 ? searchOptionValues : suggestedOptionValues;

    if (
      optionValues.length > suggestedOptionValues.length &&
      searchInput.length === 0
    ) {
      return {
        header: '',
        noResultsMessage: 'Fetching Options...',
        options: safeBuildOptions(optionValues, optionsCache),
        extraOptions: [],
      };
    }

    return {
      header: searchInput.length > 0 ? 'Search Results' : 'Suggestions',
      noResultsMessage:
        searchInput.length > 0 ? 'No matching results' : 'No suggestions',
      options: safeBuildOptions(dynamicValues, optionsCache),
      extraOptions: safeBuildOptions(
        _.difference(optionValues, dynamicValues),
        optionsCache
      ),
    };
  }, [value, optionsCache, searchOptionValues, suggestedOptionValues]);

  const onChangeOption = useCallback(
    (evt, item) => {
      const { value: itemValue } = item;

      if (optionValues.includes(itemValue)) {
        onChange({
          [optionValueKey]: optionValues.filter((v) => v !== itemValue),
        });
      } else {
        onChange({
          [optionValueKey]: optionValues.concat([itemValue]),
        });
      }
    },
    [value, onChange]
  );

  let SelectAll;
  if (options.length > 1 && searchInput.length > 0 && !isSearching) {
    const onSelectAll = () => {
      const newOptionValues = options.map((opt) => opt.value);
      optionValues.forEach((v) => {
        if (!newOptionValues.includes(v)) {
          newOptionValues.push(v);
        }
      });

      onChange({
        includeOptionValues: newOptionValues,
      });
    };

    SelectAll = (
      <AntButton size="small" onClick={onSelectAll}>
        Select All
      </AntButton>
    );
  }

  return (
    <div>
      <div className={classes.searchWrapper}>
        <SearchInput
          isSearching={isSearching}
          suffix={SelectAll}
          placeholder="Search"
          value={searchInput}
          onChange={(evt) => onSearch({ input: evt.target.value })}
        />
      </div>
      {header && <div className={classes.sectionHeader}>{header}</div>}
      <CheckSelectOptions
        options={options}
        selectedValues={optionValues}
        onChangeOption={onChangeOption}
        noResultsMessage={noResultsMessage}
      />
      {extraOptions.length > 0 && (
        <div className={classes.extraSection}>
          <div className={classes.sectionHeader}>Additional Current Values</div>
          <CheckSelectOptions
            options={extraOptions}
            selectedValues={optionValues}
            onChangeOption={onChangeOption}
          />
        </div>
      )}
      <ActionsContainer
        Left={
          isMulti &&
          !disableListOperator && (
            <ListOperatorSwitch
              value={value && value[operatorKey]}
              onChange={(v) => onChange({ [operatorKey]: v })}
            />
          )
        }
        Right={<ResetButton onClick={onClear} disabled={!hasValue} />}
      />
    </div>
  );
}

export default function TypeToFindTypeMultiselect(props: Props) {
  const { value, isMulti, disableIncludes, disableExcludes } = props;

  return (
    <IncludeExcludeMultiselectTabs
      isMulti={isMulti}
      includeOptionValues={value && value.includeOptionValues}
      excludeOptionValues={value && value.excludeOptionValues}
      renderIncludes={() => <TypeToFindContent variant="include" {...props} />}
      renderExcludes={() => <TypeToFindContent variant="exclude" {...props} />}
      disableIncludes={disableIncludes}
      disabledExcludes={disableExcludes}
    />
  );
}
