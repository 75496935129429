import React from 'react';

function NoResultsMessage({ children }) {
  const noResultsStyle = {
    textAlign: 'center',
    padding: '30px 0',
  };

  return (
    <div style={{ border: 'none', boxShadow: 'none' }}>
      <div style={noResultsStyle}>{children || 'No Results'}</div>
    </div>
  );
}

export default NoResultsMessage;
