/* eslint-disable react-hooks/rules-of-hooks */
import {
  ParticipantType,
  ParticipantTypeService,
  CreateRequest,
  GetRequest,
  ListRequest,
  UpdateRequest,
} from '@hmd/sdk/api/session_log/participant_type/v1';
import sdkClient from '../lib/hmdsdkClient';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { FieldMask } from 'google-protobuf/google/protobuf/field_mask_pb';
import _ from 'lodash';
import { queryClient } from '../queryClient';

const PARTICIPANT_TYPES_QUERY_KEY = 'participantTypes';

export const fetchParticipantTypes = async () => {
  const req = new ListRequest();
  req.setPageSize(200);
  const response = await sdkClient.query(ParticipantTypeService.List, req);
  return response.toObject().participantTypesList;
};

function getFetchParticipantTypesQueryClientParams() {
  return {
    queryKey: [PARTICIPANT_TYPES_QUERY_KEY],
    queryFn: () => fetchParticipantTypes(),
  };
}

// hook for getting all participant types
export const useParticipantTypes = () =>
  useQuery(getFetchParticipantTypesQueryClientParams());

// for working with this as a promise
export const getParticipantTypes = () =>
  queryClient.fetchQuery(getFetchParticipantTypesQueryClientParams());

const TYPE_OPTIONS_QUERY_KEY = 'participantType_options';

function fetchParticipantTypeOptions() {
  return {
    queryKey: [TYPE_OPTIONS_QUERY_KEY],
    queryFn: async () => {
      const res = await getParticipantTypes();
      const opts = res.map((val) => {
        return {
          label: val.name,
          value: val.slug,
        };
      });
      return _.sortBy(opts, 'label');
    },
  };
}

//Promise for type options
export const getParticipantTypeOptions = () =>
  queryClient.fetchQuery(fetchParticipantTypeOptions());

//Hook for working with type options
export const useParticipantTypeOptions = () =>
  useQuery(fetchParticipantTypeOptions());

type NewParticipantType = {
  id?: string;
  slug?: string;
  name: string;
  description?: string;
};

export async function listParticipantTypes(): Promise<ParticipantType[]> {
  const req = new ListRequest();

  let allParticipantTypes = [];
  let nextPageToken = 'temp';
  while (nextPageToken !== '') {
    const res = await sdkClient.unary(ParticipantTypeService.List, req);
    req.setPageToken(res.getNextPageToken());
    allParticipantTypes = allParticipantTypes.concat(
      res.getParticipantTypesList()
    );
    nextPageToken = res.getNextPageToken();
  }
  return allParticipantTypes;
}

export const getParticipantTypeBySlug = (slug: string) => {
  const req = new GetRequest();
  req.setSlug(slug);
  return sdkClient.unary(ParticipantTypeService.Get, req);
};

export const createParticipantTypeMutation = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({ name, slug, description }: NewParticipantType) => {
      const req = new CreateRequest();
      const participantType = new ParticipantType();
      participantType.setName(name);
      participantType.setSlug(slug);
      participantType.setDescription(description);
      req.setParticipantType(participantType);
      const response = await sdkClient.unary(
        ParticipantTypeService.Create,
        req
      );
      return response;
    },
    onError: (error) => {
      return error;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [PARTICIPANT_TYPES_QUERY_KEY],
      });
    },
  });
  return mutation;
};

export const updateParticipantTypeMutation = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({ id, name, description }: NewParticipantType) => {
      const req = new UpdateRequest();
      const participantType = new ParticipantType();
      participantType.setId(id);
      participantType.setName(name);
      participantType.setDescription(description);
      req.setParticipantType(participantType);

      const mask = new FieldMask();
      mask.setPathsList(['Name', 'Description']);
      req.setUpdateMask(mask);

      const response = await sdkClient.unary(
        ParticipantTypeService.Update,
        req
      );
      return response;
    },
    onError: (error) => {
      return error;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [PARTICIPANT_TYPES_QUERY_KEY],
      });
    },
  });
  return mutation;
};
