import React from 'react';

type Props = {
  splitOn: string;
  input: string;
};

function WordBreakString(props: Props) {
  const { input, splitOn } = props;

  const pieces = input.split(splitOn);

  return (
    <>
      {pieces.map((piece, i) => (
        <wbr key={i}>
          {piece}
          {splitOn}
        </wbr>
      ))}
    </>
  );
}
export default WordBreakString;
