import { useLayoutEffect } from 'react';

export default function useBeforeunload (handler:(evt:Event) => void) {
  useLayoutEffect(() => {
    window.addEventListener('beforeunload', handler);
    return () => {
      window.removeEventListener('beforeunload', handler);
    }
  }, [handler]);
};
