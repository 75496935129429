import withStyles from '@mui/styles/withStyles';
import { Chip } from '@mui/material';
import { colors } from '@shield-ui/styles';

function styles() {
  const common = {
    backgroundColor: colors.semantic.selected,
    color: colors.hues.grays[200],
    '&:focus': {
      backgroundColor: colors.semantic.selected,
    },
    '&:hover': {
      backgroundColor: colors.semantic.selectedHover,
    },
  };

  return {
    root: {
      borderRadius: 2,
      height: 30,
      marginRight: 4,
      marginBottom: 4,
      ...common,
    },
    /*
    clickable: {
      ...common,
    },
    deletable: {
      ...common,
    }
     */
  };
}

export default withStyles(styles)(Chip);
