import React, { useState, useCallback } from 'react';
import cx from 'classnames';
import MoreIcon from '@mui/icons-material/MoreVert';
import { ButtonBase, ButtonBaseProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from '@shield-ui/styles';
import { SimpleMenu, SimpleMenuItem } from '@shield-ui/core';

const useStyles = makeStyles((theme) => ({
  moreAction: {
    width: 20,
    padding: `2px 0`,
    borderRadius: 2,
    border: `1px solid rgba(255, 255, 255, 0.1)`,
    transition: '0.1s ease',
    color: colors.hues.grays[20],
    '&:hover': {
      background: colors.brand.primaryShade['120'],
    },
  },
}));

interface Props {
  buttonProps?: Omit<ButtonBaseProps, 'onClick'>;
  menuItems: SimpleMenuItem[];
}

function MoreMenuButton(props: Props) {
  const { menuItems, buttonProps = {} } = props;
  const [menuAnchor, setMenuAnchor] = useState(undefined);

  const classes = useStyles();

  const onShowMenu = useCallback((evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    setMenuAnchor(evt.target);
  }, []);

  const onHideMenu = useCallback((evt) => {
    // The entire portal and dialog are rendered as the child of the the parent
    // so clicking outside of the dialog to dismiss the popover
    // results in onClick for the parent to be fired
    // eg putting this in an accordion would cause the accordion to open up
    evt.stopPropagation();
    evt.preventDefault();
    setMenuAnchor(undefined);
  }, []);

  return (
    <>
      <ButtonBase
        {...buttonProps}
        className={cx(classes.moreAction, buttonProps.className)}
        onClick={onShowMenu}
      >
        <MoreIcon />
      </ButtonBase>
      <SimpleMenu
        anchorEl={menuAnchor}
        onClose={onHideMenu}
        menuItems={menuItems}
      />
    </>
  );
}

export default MoreMenuButton;
