import React, { useMemo } from 'react';
import _ from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import { darken } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Grow } from '@shield-ui/core';
import {
  Account,
  CloudImport,
  Cube,
  Flight,
  Folders,
  Settings,
  ShieldAiGrayLogo,
  Stethoscope,
} from '@shield-ui/icons';
import NavItem from './NavItem';
import { SIDEBAR_WIDTH } from './common';
import VisionOmnisearch from './VisionOmnisearch';
import container from '../../containers/authUser';
import config from '../../lib/config';
import {
  useSessionLogTypes,
  SYSTEM_DEFAULT_SESSION_LOG_TYPES,
} from '../../services/sessionLogTypes';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    height: '100%',
    flexDirection: 'row',
  },
  sidebar: {
    height: '100%',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    backgroundColor: darken(theme.palette.background.default, 0.2),
    width: SIDEBAR_WIDTH,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    //overflowY: 'auto',
    //overflowX: 'hidden',
  },
  logout: {
    cursor: 'pointer',
  },
  logoutText: {
    color: 'unset',
  },
  main: {
    flex: 1,
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    position: 'relative',
    scrollBehavior: 'smooth',
  },
  logo: {
    fontSize: SIDEBAR_WIDTH * 0.4,
    marginBottom: theme.spacing(1),
  },
  icon: {
    fontSize: SIDEBAR_WIDTH * 0.45,
  },
  iconSmall: {
    fontSize: SIDEBAR_WIDTH * 0.35,
  },
  environmentDisplay: {
    fontSize: 16,
    fontFamily: 'monospace',
    textTransform: 'uppercase',
    textShadow: '0px 1px 1px rgba(0, 0, 0, 0.5)',
    padding: `2px 4px`,
    borderRadius: 2,
    position: 'absolute',
    top: 8,
    left: 8,
    whiteSpace: 'nowrap',
    background: '#F22484',
    zIndex: 2,
  },
}));

function AppShell({ children, logout, currentUser }) {
  const classes = useStyles();
  const { data: sessionLogTypes } = useSessionLogTypes();

  const sessionLogSubMenu = useMemo(() => {
    const list = [
      {
        to: '/app/session-logs/flight',
        label: 'Flights',
        hexColor: sessionLogTypes?.find((slt) => slt.slug === 'flight')
          ?.hexColor,
      },
    ];

    if (sessionLogTypes) {
      const sorted = _.sortBy(sessionLogTypes, 'name');
      sorted.forEach((slt) => {
        if (SYSTEM_DEFAULT_SESSION_LOG_TYPES.indexOf(slt.slug) === -1) {
          list.push({
            to: `/app/session-logs/${slt.slug}`,
            label: `${slt.name}s`,
            hexColor: slt.hexColor,
          });
        }
      });
    }
    return list;
  }, [sessionLogTypes]);

  return (
    <div className={classes.container}>
      <nav className={classes.sidebar}>
        {config.environmentDisplay.showDisplay === true && (
          <div
            className={classes.environmentDisplay}
            style={{ background: config.environmentDisplay.color }}
          >
            {config.environmentDisplay.label}
          </div>
        )}
        <RouterLink to="/">
          <ShieldAiGrayLogo className={classes.logo} />
        </RouterLink>

        <VisionOmnisearch currentUser={currentUser} />

        <NavItem
          label="Session Logs"
          Icon={<Flight className={classes.icon} />}
          to="/app/session-logs/flight"
          subMenu={sessionLogSubMenu}
          subMenuStyle={{
            bottom: 'unset',
            top: -24,
          }}
        />
        <NavItem
          label="Testing"
          Icon={<Stethoscope className={classes.icon} />}
          to="/app/session-logs/test"
          subMenu={[
            {
              to: '/app/session-logs/test',
              label: 'Tests',
              hexColor: sessionLogTypes?.find((slt) => slt.slug === 'test')
                ?.hexColor,
            },
            {
              to: '/app/session-logs/trial',
              label: 'Trials',
              hexColor: sessionLogTypes?.find((slt) => slt.slug === 'trial')
                ?.hexColor,
            },
            {
              to: '/app/test-definitions',
              label: 'Test Definition Management',
            },
          ]}
        />
        <NavItem
          to="/app/collections"
          label="Collections"
          Icon={<Folders className={classes.icon} />}
        />
        <NavItem
          to="/app/configuration"
          label="Configuration"
          Icon={<Cube className={classes.icon} />}
          subMenu={[
            {
              to: '/app/configuration',
              label: 'Configs',
            },
            {
              to: '/app/configuration/policy',
              label: 'Config Policies',
            },
          ]}
        />
        <NavItem
          to={'/app/import'}
          subMenu={[
            {
              to: '/app/upload-vbat-flights',
              label: 'Upload VBAT Flights',
            },
            {
              to: '/app/import',
              label: 'FTS Import',
            },
            {
              to: '/app/upload-flights',
              label: 'Upload Flight Artifacts',
            },
          ]}
          label="Import"
          Icon={<CloudImport className={classes.icon} />}
        />
        <Grow />
        <NavItem
          to="/app/admin"
          label="Admin"
          Icon={<Settings className={classes.iconSmall} />}
          subMenu={[
            {
              to: '/app/admin',
              label: 'Admin',
            },
            {
              to: '/app/artifacts-explorer',
              label: 'Artifacts Explorer',
            },
          ]}
        />
        <NavItem
          subMenu={[
            {
              to: '/app/feature-flags',
              label: 'Feature Flags',
            },
            {
              href: 'https://gov.teams.microsoft.us/l/channel/19%3agcch%3aafc0c54b36a641859032aceeac8e6d94%40thread.skype/Help%2520-%2520Vision?groupId=35011cff-f7e8-49d0-ba52-4710317c511f&tenantId=d16fb4e4-c36b-4544-98b0-51142c1a2093',
              label: 'Teams Support Channel',
            },
            {
              onClick: logout,
              label: 'Logout',
            },
          ]}
          subMenuStyle={{
            top: 'unset',
            bottom: 8,
          }}
          label="User"
          Icon={<Account className={classes.iconSmall} />}
        />
      </nav>
      <main className={classes.main}>{children}</main>
    </div>
  );
}

export default container(AppShell);
