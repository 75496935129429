import React, { ReactNode, useState, useEffect, Children } from 'react';
import _ from 'lodash';
import cx from 'classnames';
import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { colors } from '@shield-ui/styles';
import { useElementDimensions } from '@shield-ui/hooks';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0.5, 0),
    overflowX: 'hidden',
    width: '100%',
    position: 'relative',
  },
  content: {
    position: 'relative',
    display: 'inline-flex',
    flexDirection: 'row',
    transition: '0.25s ease-in-out',
  },
  navigator: {
    position: 'absolute',
    height: '100%',
    width: 48,
    top: 0,
    zIndex: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 32,
    backgroundColor: alpha(colors.hues.grays[200], 0.4),
    cursor: 'pointer',
    transition: '0.15s ease',
    opacity: 0.4,
    '&:hover': {
      opacity: 1,
      backgroundColor: alpha(colors.hues.grays[200], 0.8),
    },
    '& > svg': {
      transition: '0.15s ease',
      fontSize: 48,
    },
    '&:hover > svg': {
      opacity: 1,
      transform: 'scale(1.3)',
    },
  },
  navigatorLeft: {
    left: 0,
  },
  navigatorRight: {
    right: 0,
  },
}));

type Props = {
  // child nodes you expect to scroll through, it is expected they be a consistent width
  children: ReactNode;
  selectedCalloutId?: string;
  // use width if you expect the parent container to change dynamically without the page width changing
  width?: number;
};

function CalloutCarouselCarousel(props: Props) {
  const { children, width, selectedCalloutId } = props;
  const classes = useStyles();
  const childCount = Children.count(children);
  const [childOffset, setChildOffset] = useState(0);
  const [containerDimensions, containerRef] = useElementDimensions();
  const [contentDimensions, contentRef, contentNode] = useElementDimensions();

  // when this mounts, if we have a selected callout... show it!
  // contentNode arg so this runs once we get the element
  useEffect(() => {
    if (!contentNode) {
      return;
    }
    if (!selectedCalloutId) {
      return;
    }
    const children = Array.from(
      contentNode.querySelectorAll(`[data-callout-id]`)
    );
    const match = _.find(children, (child) => {
      return child instanceof HTMLElement && selectedCalloutId === child.dataset.calloutId;
    });
    const matchIndex = children.indexOf(match);

    if (matchIndex >= 3) {
      setChildOffset(matchIndex - 2);
    }
  }, [contentNode, selectedCalloutId]);

  let hasPrev = false;
  let hasNext = false;
  let childWidth = 0;
  let visibleCount = childCount;

  const containerWidth = width || containerDimensions.width;

  if (childCount > 1 && containerWidth < contentDimensions.width) {
    // requires fixed child widths
    childWidth = contentDimensions.width / childCount;
    visibleCount = Math.max(1, Math.floor(containerWidth / childWidth));
    hasPrev = childOffset > 0;
    hasNext = childOffset + visibleCount < childCount;
  }

  return (
    <div
      className={classes.container}
      style={{ width: width }}
      ref={containerRef}
    >
      {hasPrev && (
        <div
          className={cx(classes.navigator, classes.navigatorLeft)}
          onClick={() => {
            const offset = Math.max(0, childOffset - visibleCount);
            setChildOffset(offset);
          }}
        >
          <ChevronLeft />
        </div>
      )}
      <div
        // force the node to change if the number of children change
        // this will ensure we get refreshed dimensions for contentRef
        key={childCount}
        className={classes.content}
        ref={contentRef}
        style={{ left: -(childWidth * childOffset) }}
      >
        {children}
      </div>

      {hasNext && (
        <div
          className={cx(classes.navigator, classes.navigatorRight)}
          onClick={() => {
            const offset = Math.min(
              childOffset + visibleCount,
              childCount - visibleCount
            );
            setChildOffset(offset);
          }}
        >
          <ChevronRight />
        </div>
      )}
    </div>
  );
}

export default CalloutCarouselCarousel;
