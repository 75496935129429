import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { Chip } from '@mui/material';
import { getDynamicColorFromString } from '@shield-ui/utils';

function styles(theme) {
  return {
    root: {
      height: 24,
      boxShadow: `1px 1px 4px 0px rgba(22, 22, 22, .5)`,
    },
    label: {
      color: '#000',
      letterSpacing: '-0.2px',
      padding: theme.spacing(0, 1),
      overflow: 'visible',
      fontSize: 12,
    },
    deleteIcon: {
      width: 18,
      height: 18,
      color: 'rgba(0, 0, 0, 0.25)',
      '&:hover': {
        color: 'rgba(0, 0, 0, 0.325)',
      },
    },
  };
}

function AppliedLabel({ name, color, classes, onDelete }) {
  const chipColor = color || getDynamicColorFromString(name);

  return (
    <Chip
      classes={classes}
      onDelete={onDelete}
      label={name}
      style={{ backgroundColor: chipColor }}
    />
  );
}

export default withStyles(styles)(AppliedLabel);
