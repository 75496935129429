import React from 'react';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import Tooltip from '@mui/material/Tooltip';
import TimelineIcon from '@mui/icons-material/Timeline';
import { colors } from '@shield-ui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: 30,
    display: 'flex',
    height: '100%',
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  indicator: {
    width: 10,
    height: 10,
    borderRadius: '50%',
    background: 'gray',
  },
  indicatorPassed: {
    backgroundColor: theme.palette.success.main,
  },
  indicatorFailed: {
    backgroundColor: theme.palette.error.main,
  },
  icon: {
    fontSize: 16,
    color: colors.hues.grays[140],
  },
  metricText: {
    fontWeight: 700,
  },
  metricPlus: {
    fontSize: '0.8em',
  },
}));

export default function OutputIndicator({
  type,
  tooltipText = '',
}: {
  type: 'metric' | 'plot' | 'testPassed' | 'testFailed';
  tooltipText?: string;
}) {
  const classes = useStyles();
  return (
    <Tooltip title={tooltipText}>
      <div className={classes.container}>
        {type === 'metric' && (
          <div className={cx(classes.icon, classes.metricText)}>
            N<span className={classes.metricPlus}>+</span>
          </div>
        )}
        {type === 'plot' && <TimelineIcon className={classes.icon} />}
        {type === 'testPassed' && (
          <div className={cx(classes.indicator, classes.indicatorPassed)} />
        )}
        {type === 'testFailed' && (
          <div className={cx(classes.indicator, classes.indicatorFailed)} />
        )}
      </div>
    </Tooltip>
  );
}
