import { MultiselectQueryFilter } from '@shield-ui/query-filters';
import { robotLogDocumentFields } from './fields';

export default new MultiselectQueryFilter({
  id: 'names',
  controlLabel: 'Names',
  esField: robotLogDocumentFields.name.field,
  unsetValueDisabled: true,
  behaviorConfig: {
    isCreatable: true,
  },
});
