import React from 'react';
import _ from 'lodash';
import {
  LayoutProps,
  QueryFilterClasses,
  QueryFilterItem,
} from '@shield-ui/query-filters';
import analyses from './analyses';
import artifacts from './artifacts';
import childrenCount from './childrenCount';
import childrenNames from './childrenNames';
import collections from './collections';
import createdAt from './createdAt';
import diagnostics from './diagnostics';
import duration from './duration';
import environments from './environments';
import events from './events';
import flightTypes from './flightTypes';
import labels from './labels';
//import kernelVersions from './kernelVersions';
import keywords from './keywords';
import manual from './manual';
import metrics from './metrics';
import names from './names';
//import nvidiaBaseImageCommitHashes from './nvidiaBaseImageCommitHashes';
import parentNames from './parentNames';
import participants from './participants';
//import phones from './phones';
//import phoneSoftwareInfo from './phoneSoftwareInfo';
//import robotProducts from './robotProducts';
//import robots from './robots';
import robotLogData from './robotLogData';
import robotLogReviews from './robotLogReviews';
//import robotLogTests from './robotLogTests';
//import softwareInfoBranchNames from './softwareInfoBranchNames';
//import softwareInfoCommitHashes from './softwareInfoCommitHashes';
//import softwareInfoVersions from './softwareInfoVersions';
import startTime from './startTime';
import startTimeOfDay from './startTimeOfDay';
import testDefinitionCode from './testDefinitionCodes';
import testDefinitionOwners from './testDefinitionOwners';
import testDefinitionRequirements from './testDefinitionRequirements';
import testDefinitionPlatformsAndSubTypes from './testDefinitionPlatformsAndSubTypes';
import testDefinitionTags from './testDefinitionTags';
import KeywordsComponent from './KeywordsComponent';
import testDefinitionEnvironments from './testDefinitionEnvironments';
import testEvaluations from './testEvaluations';
import testDefinitionFrequencyGroups from './testDefinitionFrequencyGroups';
import { MultiselectQueryFilter } from '@shield-ui/query-filters';
import { prettifyString } from '@shield-ui/utils';
import { getMetadataFieldOptions } from 'apps/vision/src/app/services/passThruSearch';
import { VBATMetadataKey } from '../../../services/robotLogs';
//import { SYSTEM_DEFAULT_SESSION_LOG_TYPES } from '../../../services/sessionLogTypes';
//import sessionLogTypes from './sessionLogTypes';
import { entityQueryFilters } from './entities';

interface SessionLogQueryFilterItem extends QueryFilterItem {
  queryFilter: QueryFilterClasses;
  validSessionLogTypeSlugs?: string[];
  category: string;
  ControlBarLayoutComponent?: React.ElementType<LayoutProps>;
  isDefault?: boolean;
}

const categories = {
  general: 'General',
  testing: 'Testing',
  attributes: 'Attributes',
  data: 'Data',
  //nova: 'Nova Meta',
  vbat: 'VBAT Meta',
  entities: 'Entities',
  advanced: 'Advanced',
};

export const categoryOrder = [
  categories.general,
  categories.data,
  categories.entities,
  categories.testing,
  //categories.nova,
  categories.vbat,
  categories.advanced,
];

const masterList: SessionLogQueryFilterItem[] = [
  {
    queryFilter: keywords,
    ControlBarLayoutComponent: KeywordsComponent,
    category: categories.general,
    isDefault: true,
  },
  {
    queryFilter: startTime,
    category: categories.general,
    isDefault: true,
  },
  {
    queryFilter: labels,
    category: categories.general,
    isDefault: true,
  },
  {
    queryFilter: robotLogReviews,
    category: categories.testing,
    isDefault: true,
  },
  /*
  {
    queryFilter: flightTypes,
    validSessionLogTypeSlugs: ['flight'],
    category: categories.testing,
    isDefault: true,
  },
  */
  {
    queryFilter: environments,
    category: categories.testing,
    isDefault: true,
  },
  {
    queryFilter: events,
    category: categories.testing,
    isDefault: true,
  },
  /*
  {
    queryFilter: robots,
    category: categories.nova,
    isDefault: true,
  },
  */
  {
    queryFilter: names,
    category: categories.general,
  },
  {
    queryFilter: artifacts,
    category: categories.data,
  },
  {
    queryFilter: analyses,
    category: categories.data,
  },
  {
    queryFilter: metrics,
    category: categories.data,
  },
  {
    queryFilter: robotLogData,
    category: categories.general,
  },
  /*
  {
    queryFilter: kernelVersions,
    category: categories.nova,
    validSessionLogTypeSlugs: ['flight'],
  },
  {
    queryFilter: nvidiaBaseImageCommitHashes,
    category: categories.nova,
    validSessionLogTypeSlugs: ['flight'],
  },
  */
  {
    queryFilter: collections,
    category: categories.general,
  },
  {
    queryFilter: duration,
    category: categories.general,
  },
  {
    queryFilter: createdAt,
    category: categories.general,
  },
  {
    queryFilter: startTimeOfDay,
    category: categories.general,
  },
  {
    queryFilter: participants,
    category: categories.testing,
  },
  /*
  {
    queryFilter: diagnostics,
    validSessionLogTypeSlugs: ['flight'],
    category: categories.data,
  },
  {
    queryFilter: robotLogTests,
    category: categories.testing,
    validSessionLogTypeSlugs: SYSTEM_DEFAULT_SESSION_LOG_TYPES,
    // robots and software
  },
  {
    queryFilter: robotProducts,
    category: categories.nova,
    validSessionLogTypeSlugs: SYSTEM_DEFAULT_SESSION_LOG_TYPES,
  },
  {
    queryFilter: softwareInfoBranchNames,
    category: categories.nova,
    validSessionLogTypeSlugs: SYSTEM_DEFAULT_SESSION_LOG_TYPES,
  },
  {
    queryFilter: softwareInfoCommitHashes,
    category: categories.nova,
    validSessionLogTypeSlugs: SYSTEM_DEFAULT_SESSION_LOG_TYPES,
  },
  {
    queryFilter: softwareInfoVersions,
    category: categories.nova,
    validSessionLogTypeSlugs: SYSTEM_DEFAULT_SESSION_LOG_TYPES,
    // phones
  },
  */
  {
    queryFilter: testEvaluations,
    category: categories.testing,
    validSessionLogTypeSlugs: ['test', 'trial'],
  },
  // TEST DEFINITION STUFF
  ...[
    testDefinitionCode,
    testDefinitionPlatformsAndSubTypes,
    testDefinitionFrequencyGroups,
    testDefinitionTags,
    testDefinitionEnvironments,
    testDefinitionOwners,
    testDefinitionRequirements,
  ].map((queryFilter) => ({
    queryFilter,
    category: categories.testing,
  })),
  /*
  {
    queryFilter: phones,
    validSessionLogTypeSlugs: ['flight'],
    category: categories.nova,
  },
  {
    queryFilter: phoneSoftwareInfo,
    validSessionLogTypeSlugs: ['flight'],
    category: categories.nova,
  },
  */
  {
    queryFilter: manual,
    category: categories.advanced,
  },
  {
    queryFilter: makeMetadataMultiselectQueryFilter('Model'),
    category: categories.vbat,
  },
  {
    queryFilter: makeMetadataMultiselectQueryFilter('Aircraft'),
    category: categories.vbat,
  },
  {
    queryFilter: makeMetadataMultiselectQueryFilter('Leaf Firmware'),
    category: categories.vbat,
  },
  {
    queryFilter: makeMetadataMultiselectQueryFilter('Primary Mission'),
    category: categories.vbat,
  },
  {
    queryFilter: makeMetadataMultiselectQueryFilter('Configuration'),
    category: categories.vbat,
  },
  {
    queryFilter: makeMetadataMultiselectQueryFilter('Autopilot Firmware'),
    category: categories.vbat,
  },
  {
    queryFilter: parentNames,
    category: categories.general,
  },
  {
    queryFilter: childrenNames,
    category: categories.general,
  },
  {
    queryFilter: childrenCount,
    category: categories.general,
  },
];

export const deprecatedQueryFilterIds = [
  'participantObservers',
  'participantPilots',
];

export const getDefaultVisibleIds = _.memoize((sessionLogTypeSlugs) => {
  return getQueryFilterItems(sessionLogTypeSlugs).reduce((acc, item) => {
    if (item.isDefault) {
      acc.push(item.queryFilter.getId());
    }
    return acc;
  }, []);
});

export const getQueryFilterItemsWithEntities = async (sessionLogTypeSlugs) => {
  const entities = await entityQueryFilters();
  const entityFilters = entities.map((entity) => ({
    queryFilter: entity,
    category: categories.entities,
  }));
  return getQueryFilterItems(sessionLogTypeSlugs).concat(entityFilters);
};

export const getQueryFilterItems = _.memoize((sessionLogTypeSlugs) => {
  return masterList.reduce((acc, item) => {
    if (
      // user did not pass in any types to match
      !sessionLogTypeSlugs ||
      // item has no types (ie valid for all types
      _.isEmpty(item.validSessionLogTypeSlugs) ||
      // otherwise, make sure we overlap
      _.intersection(item.validSessionLogTypeSlugs, sessionLogTypeSlugs)
        .length > 0
    ) {
      acc.push(item);
    }
    return acc;
  }, []);
});

export function getAllQueryFilterItems() {
  return masterList;
}

const getQueryFilterItemMap = _.once(() => {
  return _.keyBy(masterList, (item) => item.queryFilter.getId());
});

export function getQueryFilterItem(id: string): SessionLogQueryFilterItem {
  return getQueryFilterItemMap()[id];
}

/**
 * Take a list of query filter ids and filter out any deprecated ids that don't exist today
 * @param ids
 */
export function filterListForDeprecatedFilters(
  ids?: string[]
): string[] | undefined {
  if (!ids) {
    return undefined;
  }

  return ids.filter((id) => {
    return deprecatedQueryFilterIds.indexOf(id) === -1;
  });
}

/**
 * Take a values object for query filters, and filter out any values for old query filters
 * @param values
 */
export function filterValuesforDeprecatedFilters(
  values?: object
): object | undefined {
  if (!values) {
    return undefined;
  }

  const shouldFilter = deprecatedQueryFilterIds.reduce((result, id) => {
    if (id in values) {
      return true;
    }
    return result;
  }, false);

  if (!shouldFilter) {
    return values;
  }

  return Object.keys(values).reduce((acc, key) => {
    if (deprecatedQueryFilterIds.indexOf(key) === -1) {
      acc[key] = values[key];
    }
    return acc;
  }, {});
}

export function makeMetadataMultiselectQueryFilter(
  field: VBATMetadataKey | string
) {
  return new MultiselectQueryFilter({
    id: `metadata.${field}`,
    controlLabel: prettifyString(field.toString()),
    esField: `robot_log_data.${field}`,
    behaviorConfig: {
      fetchOptions: () => getMetadataFieldOptions(field),
    },
  });
}
