import _ from 'lodash';
import {
  BaseFilterValue,
  BaseQueryFilter,
  BaseFilterProps,
} from './baseQueryFilter';
import { QueryFilterTypes } from './common';

export interface ValueRangeQueryFilterValue extends BaseFilterValue {
  minValue?: number;
  maxValue?: number;
}

export interface ValueRangeControlData {
  minThreshold: number;
  maxThreshold: number;
  numberOfInBetweenMarksToDisplay: number;
  formatValue: (number) => string;
}

export interface ValueRangeQueryFilterProps extends BaseFilterProps {
  minThreshold?: number;
  maxThreshold?: number;
  numberOfInBetweenMarksToDisplay?: number;
  formatValue?: (number) => string;
  // Default false: Behavior is not not include the minThreshold in the query
  // if True, then we include the value in our query.
  enforceMinEdge?: boolean;
  enforceMaxEdge?: boolean;
  esAggField?: boolean;
}

function defaultFormatStep(v: number): string {
  return v.toString();
}

export class ValueRangeQueryFilter extends BaseQueryFilter<
  ValueRangeQueryFilterValue,
  ValueRangeQueryFilterProps,
  ValueRangeControlData
> {
  type = QueryFilterTypes.valueRange;

  initControlData(props: ValueRangeQueryFilterProps): ValueRangeControlData {
    return {
      formatValue: props.formatValue || defaultFormatStep,
      minThreshold: _.get(props, 'minThreshold', 0),
      maxThreshold: _.get(props, 'maxThreshold', 100),
      numberOfInBetweenMarksToDisplay: _.get(
        props,
        'numberOfInBetweenMarksToDisplay',
        3
      ),
    };
  }

  hasValue(value: ValueRangeQueryFilterValue = {}): boolean {
    return (
      typeof value.minValue === 'number' || typeof value.maxValue === 'number'
    );
  }

  getElasticQuery(value: ValueRangeQueryFilterValue): object | void {
    if (!this.props.esField || !value) {
      return;
    }
    const { enforceMinEdge, enforceMaxEdge } = this.props;
    const { minThreshold, maxThreshold } = this.getControlData();

    const v: { gte?: number; lte?: number } = {};
    if (typeof value.minValue === 'number') {
      v.gte = value.minValue;
    } else if (enforceMinEdge) {
      v.gte = minThreshold;
    }

    if (typeof value.maxValue === 'number') {
      v.lte = value.maxValue;
    } else if (enforceMaxEdge) {
      v.lte = maxThreshold;
    }

    if (Object.keys(v).length === 0) {
      return;
    }

    if (this.props.esAggField) {
      return {
        bool: {
          filter: {
            script: {
              script:{
                source: "int count = doc[params.esField].size();return (count >= params.gte && count <= params.lte)",
                params: {...v,
                  esField: this.props.esField
                }
              }
            },
          },
        },
      };
    } else {
      return {
        range: {
          [this.props.esField]: v,
        },
      };
    }
  }

  getValuePreview(value: ValueRangeQueryFilterValue): string {
    if (!this.hasValue(value)) {
      return '';
    }
    const { formatValue } = this.getControlData();

    const hasMin = typeof value.minValue === 'number';
    const hasMax = typeof value.maxValue === 'number';

    if (hasMin && hasMax) {
      return `${formatValue(value.minValue)} - ${formatValue(value.maxValue)}`;
    } else if (hasMin) {
      return `Minimum ${formatValue(value.minValue)}`;
    } else if (hasMax) {
      return `Maximum ${formatValue(value.maxValue)}`;
    }
  }
}
