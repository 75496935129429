import { createActionPrefix } from 'redux-nano';
import history from '../../routes/history';
import config from '../../lib/config';
import { showErrorSnack } from '../snackbar/actions';
import {
  setCachedItem,
  getCachedItem,
  removeCachedItem,
} from '@shield-ui/utils';

const Action = createActionPrefix('SETTINGS');
const fetch = window.fetch;
export const SET_HOUR_12 = Action('SET_HOUR_12');

const BEARER_TOKEN_STORAGE_KEY = 'AUTH_BEARER_TOKEN';

// Communication to Hangar works with the cookie / credentials set directly as a result of the login request
// However, we can't pull that cookie dynamically out of another host
// So we capture it and store in local storage for later use
// This isn't the most durable, but should roughly work as the APP in general handles making sure the token stays valid
// Might need to put some generic handling in our HMDSClient to auto-logout / invalidate token if we get an invalid response though.
// We might also get gas-lit if for instance we login and get our cookies set in the GraphIQL for Hangar, then flip to Vision.
export function getBearerToken() {
  return getCachedItem(BEARER_TOKEN_STORAGE_KEY);
}

// Action Helpers
export function login(profile, next) {
  return (dispatch) => {
    // Do nothing if not using auth
    if (!config.useAuth) {
      history.push(next || '/');
      return;
    }

    const {
      providerIdToken,
      accessToken,
      providerName,
      providerJwt,
      pictureOverride,
    } = profile;
    console.info('Hangar Login Args', profile);

    // Confirm Google JWT and authenticate with hangar
    const url = `${config.api.base}/auth/login`;
    const body = JSON.stringify({
      provider_name: providerName,
      provider_id_token: providerIdToken,
      access_token: accessToken,
      provider_jwt: providerJwt,

      // Optional Args
      ...(pictureOverride && { picture_override: pictureOverride }),
    });
    const headers = { 'Content-Type': 'application/json' };

    fetch(url, {
      method: 'POST',
      headers,
      body,
      credentials: 'include',
    })
      .then((response) => {
        if (response.status !== 200) {
          const error = Error(`${response.statusText}`);
          error.status = response && response.status;
          console.error('Login Error', error);

          // Decipher informative hangar error responses
          const errorJson = response.json();
          return errorJson;
        }
        return response.json();
      })
      .then((json) => {
        // On Failure
        if (json.error) {
          console.error('Hangar Login Error', json.error);
          if (json.message) {
            dispatch(showErrorSnack(json.message));
          } else {
            dispatch(showErrorSnack('Unknown Hangar Login Error'));
          }

          // On Success
        } else {
          console.debug('Hangar Login', json);
          setCachedItem(BEARER_TOKEN_STORAGE_KEY, json.token);
          // Sets UserId to be tracked
          // something wonky going on with google api, relocation so this object gets cleaned out for FTS upload
          window.location = next || '/';
        }
      })
      .catch((e) => {
        console.error('ERROR', e);
        dispatch(showErrorSnack(e.message));
      });
  };
}

export const logout = (dispatch) => (state) => {
  // Do nothing if not using auth
  if (!config.useAuth) return;

  // Revoke hangar login session
  const url = `${config.api.base}/auth/logout`;
  fetch(url, {
    method: 'POST',
    credentials: 'include',
  })
    .then((response) => {
      if (response.status !== 200) {
        const error = Error(`${response.statusText}`);
        error.status = response && response.status;
        throw error;
      }
      return response.json();
    })
    .then((json) => {
      console.debug('Logout', json);
      removeCachedItem(BEARER_TOKEN_STORAGE_KEY);
      history.push('/app/login');
    })
    .catch((e) => {
      console.error(e);
    });
};
