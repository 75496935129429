import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from '@shield-ui/styles';
import EvaluationModal from './EvaluationModal';
import { prettyTimeDiffNow, prettifyString } from '@shield-ui/utils';
import PersonIcon from '@mui/icons-material/Person';
import { CircularProgress } from '@mui/material';
import {
  getEvaluationStatusColor,
  getEvaluationStatusName,
} from '../../services/evaluations';
import { Tooltip } from 'antd';
import { BadgeProps } from './types';

const useStyles = makeStyles(() => ({
  container: {
    cursor: 'pointer',
    backgroundColor: colors.hues.grays[190],
    alignItems: 'center',
    borderRadius: 3,
    padding: 5,
    maxWidth: 300,
    '&:hover': {
      backgroundColor: colors.hues.grays[180],
    },
  },

  evaluationState: {
    fontSize: 15,
    margin: 2,
  },
  userTime: {
    fontSize: 12,
    margin: 2,
    color: colors.hues.grays[60],
  },
  circle: {
    width: 25,
    height: 25,
    borderRadius: '50%',
    margin: 5,
    flexShrink: 0,
    marginRight: 10,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 45,
  },
  info: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default function PageContent(props: BadgeProps) {
  const {
    openEvaluation,
    isLoading,
    evaluation,
    isManualAssertion,
    isEvaluationOpen,
    closeEvaluation,
    onAssertionChange,
    setIsManualAssertion,
  } = props;
  const classes = useStyles();

  return (
    <>
      <div className={classes.container} onClick={openEvaluation}>
        <div className={classes.content}>
          <div className={classes.info}>
            {isLoading ? (
              <div className={classes.circle}>
                {' '}
                <CircularProgress size={25} />{' '}
              </div>
            ) : (
              evaluation && (
                <div
                  className={classes.circle}
                  style={{
                    backgroundColor: getEvaluationStatusColor(
                      evaluation.getEvaluationState()
                    ),
                  }}
                ></div>
              )
            )}
            {isLoading ? (
              <div className={classes.evaluationState}>
                Computing Evaluation
              </div>
            ) : (
              evaluation && (
                <div>
                  <div className={classes.evaluationState}>
                    {prettifyString(
                      getEvaluationStatusName(evaluation.getEvaluationState())
                    )}
                  </div>
                  <div className={classes.userTime}>
                    {`${prettyTimeDiffNow(
                      evaluation.getLastInputTime().toDate()
                    )}`}
                  </div>
                </div>
              )
            )}
          </div>
          {evaluation && isManualAssertion && !isLoading ? (
            <Tooltip
              placement="top"
              title={'User has overwritten the default evaluation'}
              color={colors.hues.grays[140]}
            >
              <PersonIcon style={{ marginRight: 10 }} />
            </Tooltip>
          ) : null}
        </div>
      </div>
      {evaluation && (
        <div>
          <EvaluationModal
            evaluation={evaluation}
            isEvaluationOpen={isEvaluationOpen}
            closeEvaluation={closeEvaluation}
            onAssertionChange={onAssertionChange}
            setIsManualAssertion={setIsManualAssertion}
          />
        </div>
      )}
    </>
  );
}
