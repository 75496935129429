import React from 'react';
import { Tag } from 'antd';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(1, 0),
  },
  tag: {
    margin: theme.spacing(0.25, .5),
  }
}))

export type TagType = 'general' | 'equipment' | 'environmental' | 'component';

const colors: Record<TagType, string> = {
  equipment: 'magenta',
  environmental: 'red',
  component: 'volcano',
  general: 'gold',
}

interface Props {
  tags: string[];
  tagType?: TagType;
  style?: object;
  onClick?: (tag:string) => void;
}

export default function TagsPreview(props:Props) {
  const { tags, tagType, onClick, style } = props;
  const classes = useStyles();

  if (!tags.length) {
    return null;
  }

  const color = colors[tagType];

  return (
    <div style={style} className={classes.container}>
      {tags.map((t) => (
        <Tag
          className={classes.tag}
          key={t}
          color={color}
          onClick={onClick ? () => onClick(t) : undefined}
        >
          {t}
        </Tag>
      ))}
    </div>
  )
}
