import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { showErrorSnack } from '../../../redux/snackbar/actions';

import {
  setListItems,
  ensureTicketListItems,
} from '../../../redux/lists/actions';

function mapStateToProps(state) {
  return {
    tickets: state.lists.listTypeMap.tickets,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setListItems,
      ensureTicketListItems,
      showErrorSnack,
    },
    dispatch
  );
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ContainerProps = ConnectedProps<typeof connector>;
export default connector;
