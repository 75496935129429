import { useRef, useState, useLayoutEffect, useCallback } from 'react';

export interface FullscreenState {
  isFullscreen: boolean;
  el: Element;
}

function getState() {
  const element = document.fullscreenElement;
  return {
    isFullscreen: !!element,
    el: element,
  };
}

export function useFullscreenStatus() {
  const [fullscreenState, setState] = useState<FullscreenState>(getState());

  useLayoutEffect(() => {
    const onChange = () => {
      setState(getState());
    };

    window.addEventListener('fullscreenchange', onChange);
    return () => window.removeEventListener('fullscreenchange', onChange);
  });

  return [fullscreenState];
}

export function useFullscreenRef<T>(): [ref:React.Ref<T>, isFullscreen:boolean, toggleFullscreen: () => void] {
  const [fullscreenState] = useFullscreenStatus();
  const ref = useRef();

  const isFullscreen = (fullscreenState.isFullscreen && ref.current && ref.current === fullscreenState.el);

  const toggleFullscreen = useCallback(() => {
     if (isFullscreen) {
      document.exitFullscreen();
    } else if (ref.current) {
      const el = ref.current as HTMLDivElement;
      el.requestFullscreen();
    }
  }, [ref, isFullscreen]);


  return [ref, isFullscreen, toggleFullscreen];
}
