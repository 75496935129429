import { createTheme, lighten, darken, alpha } from '@mui/material/styles';
import colors from './colors';
import commonStyles from './commonStyles';
import spacing from './spacing';
import { defaultFontFamily, headerFontFamily } from './fonts';

const buttonPads = {
  small: [5.5, 13],
  regular: [11, 26],
  large: [13, 28],
};
const buttonPaddingSmall = `${buttonPads.small[0]}px ${buttonPads.small[1]}px`;
const buttonPaddingRegular = `${buttonPads.regular[0]}px ${buttonPads.regular[1]}px`;
const buttonPaddingLarge = `${buttonPads.large[0]}px ${buttonPads.large[1]}px`;

const fontSizeBase = 16;
const getRemSize = (size) => `${size / 16}rem`;

const muiTheme = createTheme({
  spacing: spacing.unit,
  // https://material-ui.com/customization/palette/
  palette: {
    mode: 'dark',
    primary: {
      main: colors.brand.primary,
      contrastText: colors.semantic.bodyText,
    },
    secondary: {
      main: colors.brand.secondary,
    },
    text: {
      primary: colors.semantic.bodyText,
      // secondary: lighten(colors.hues.gray, 0.5),
      // disabled: lighten(colors.hues.gray, 0.9),
    },
    action: {
      // active, hover, selected
      disabled: alpha(colors.hues.gray, 0.5),
      disabledBackground: alpha(colors.hues.gray, 0.3),
    },
    divider: colors.hues.gray, // did this to get table row divider, table takes divider color and changes with by darkening. Our mock shows 190 gray, but we use this and it's pretty close by default
    error: {
      main: colors.hues.red,
    },
    warning: {
      main: colors.hues.orange,
    },
    success: {
      main: colors.hues.green,
    },
    info: {
      main: colors.hues.yellow,
      contrastText: colors.hues.grays[180],
    },
    background: {
      default: colors.semantic.bodyBackground,
      paper: colors.hues.grays[180],
    },
  },
  // https://material-ui.com/customization/typography/
  typography: {
    fontFamily: defaultFontFamily,
    htmlFontSize: fontSizeBase,
    h1: {
      fontFamily: headerFontFamily,
      fontSize: getRemSize(90),
      lineHeight: 1,
      fontWeight: 700,
      letterSpacing: 4,
      textTransform: 'uppercase',
      color: lighten(colors.hues.gray, 0.95),
    },
    h2: {
      fontFamily: headerFontFamily,
      fontSize: getRemSize(64),
      lineHeight: 1,
      fontWeight: 500,
      letterSpacing: 2,
      textTransform: 'uppercase',
      color: lighten(colors.hues.gray, 0.95),
    },
    h3: {
      fontFamily: headerFontFamily,
      fontSize: getRemSize(40),
      lineHeight: 1,
      fontWeight: 500,
      letterSpacing: 1,
      textTransform: 'uppercase',
      color: lighten(colors.hues.gray, 0.95),
    },
    h4: {
      fontSize: getRemSize(32),
      lineHeight: 42 / 32,
      fontWeight: 700,
      letterSpacing: 1,
      textTransform: 'capitalize',
      color: lighten(colors.hues.gray, 0.2),
    },
    h5: {
      fontSize: getRemSize(26),
      lineHeight: 34 / 26,
      fontWeight: 600,
      letterSpacing: 1,
      textTransform: 'capitalize',
      color: lighten(colors.hues.gray, 0.2), // SAI GRAY 80
    },
    h6: {
      fontSize: getRemSize(18),
      lineHeight: 26 / 18,
      fontWeight: 600,
      letterSpacing: 1,
      textTransform: 'capitalize',
      color: lighten(colors.hues.gray, 0.2), // SAI GRAY 80
    },
    body1: {
      fontSize: getRemSize(18),
      lineHeight: 26 / 18,
      letterSpacing: 0.5,
      color: lighten(colors.hues.gray, 0.8), // SAI GRAY 20
    },
    body2: {
      fontSize: getRemSize(14),
      lineHeight: 17 / 14,
      letterSpacing: 0.5,
      color: colors.hues.gray,
    },
    overline: {
      fontSize: getRemSize(14),
      lineHeight: 17 / 14,
      textTransform: 'uppercase',
      letterSpacing: 1,
      color: lighten(colors.hues.gray, 0.2), // SAI GRAY 80
    },
    button: {
      fontSize: getRemSize(16),
      lineHeight: 26 / 16,
      fontWeight: 500,
      letterSpacing: 2,
      textTransform: 'uppercase',
      color: '#fff',
    },
    caption: {},
    subtitle1: {},
    subtitle2: {},
  },
  // https://material-ui.com/customization/globals/
  components: {
    MuiButton: {
      styleOverrides:{
        root: {
          //color: lighten(colors.hues.gray, 0.5),
          transition: '0.1s ease',
          padding: buttonPaddingRegular,
          minWidth: 120,
          borderRadius: 100,
        },
        contained: {
          padding: buttonPaddingRegular,
          //backgroundColor: lighten(colors.hues.gray, 0.7),
          '&:hover': {
            backgroundColor: lighten(colors.hues.gray, 0.9),
          },
          '&.Mui-disabled': {
            color: colors.hues.grays[120],
          },
        },
        outlined: {
          padding: buttonPaddingRegular,
          color: lighten(colors.hues.gray, 0.5),
          borderColor: lighten(colors.hues.gray, 0.5),
          '&:hover': {},
        },
        text: {
          padding: `${buttonPads.regular[0]}px 0`,
          minWidth: 0,
          '&:hover': {
            color: '#fff',
            backgroundColor: 'transparent',
          },
        },
        textPrimary: {
          color: lighten(colors.hues.gray, 0.5),
          //color: colors.semantic.linkColor,
          '&:hover': {
            color: '#fff',
            //color: colors.semantic.linkColorHover,
            backgroundColor: 'transparent',
          },
        },
        textSecondary: {
          '&:hover': {
            color: lighten(colors.brand.secondary, 0.7),
            backgroundColor: 'transparent',
          },
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: lighten(colors.brand.primary, 0.5),
          },
        },
        outlinedPrimary: {
          '&:hover': {
            color: lighten(colors.brand.primary, 0.5),
            borderColor: lighten(colors.brand.primary, 0.5),
          },
        },
        sizeSmall: {
          minWidth: 60,
          padding: buttonPaddingSmall,
          fontSize: 12,
          '&.Mui-textSizeSmall': {
            padding: `${buttonPads.small[0]}px 0`,
            minWidth: 0,
          },
        },
        sizeLarge: {
          minWidth: 160,
          padding: buttonPaddingLarge,
          fontSize: 22,
          '&.Mui-textSizeLarge': {
            padding: `${buttonPads.large[0]}px 0`,
            minWidth: 0,
          },
        },
      }
    },
    MuiTableCell: {
      styleOverrides:{
        head: {
          color: colors.hues.gray,
          textTransform: 'uppercase',
          fontSize: 12,
          letterSpacing: 1,
          fontWeight: 400,
          border: 0,
        },
      }
    },
    MuiLink: {
      styleOverrides:{
        root: {
          color: colors.semantic.linkColor,
          cursor: 'pointer',
        },
      }
    },
    MuiAccordion: {
      styleOverrides:{
        root: {
          '&:before': {
            backgroundColor: lighten(colors.semantic.bodyBackground, 0.15),
          },
        },
      }
    },
    MuiTooltip: {
      styleOverrides:{
        tooltip: {
          backgroundColor: colors.hues.grays[150],
          fontSize: 12,
          lineHeight: 20 / 12,
          letterSpacing: 0.5,
          padding: spacing.unit,
          boxShadow:
            '0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)',
        },
      }
    },
    MuiListItemText: {
      styleOverrides:{
        primary: {
          color: darken(colors.semantic.bodyText, 0.15),
        },
      }
    },
    MuiOutlinedInput: {
      styleOverrides:{
        root: {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.semantic.inputBorderHover,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.semantic.inputBorderFocus,
          },
        },
        notchedOutline: {
          borderColor: colors.semantic.inputBorder,
        },
      }
    },
    MuiDivider: {
      styleOverrides:{
        root: {
          backgroundColor: colors.hues.grays[170],
        },
      }
    },
    MuiChip: {
      styleOverrides:{
        root: {
          backgroundColor: colors.semantic.inputChipBackground,
          borderRadius: 4,
          margin: `2px 4px 2px 0`,
        },
        label: {
          fontSize: 14,
          letterSpacing: 0.5,
        },
      }
    },
    MuiInputBase: {
      styleOverrides:{
        input: {
          '&::placeholder': {
            ...commonStyles.placeholder,
          },
        },
        root: {
          '&.Mui-focused': {},
        },
      }
    },
    MuiPaper:{
      styleOverrides:{
        root:{
          backgroundImage:'none'
        }
      }
    }
  },
});

/**
 * Automatically generate responsive font sizes for the theme passed in
 * Can get rid of this if we ever do it ourselves but a pretty nice to have freebie for now
 *
 * Even though responsive is usually mobile first, this works like how we design... Desktop first.
 */
// Turn this off because it makes the sizes defined the smallest but our designs are for desktop
// muiTheme = responsiveFontSizes(muiTheme);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.muiTheme = muiTheme;

export default muiTheme;
