import React from 'react';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from '@shield-ui/styles';
import { RollbackOutlined } from '@ant-design/icons';

type Props = {
  onDismiss: () => void;
  onGoBack: () => void;
  label?: string;
};

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(4),
    padding: theme.spacing(2),
    background: colors.hues.grays[150],
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 0,
  },
  backButton: {
    background: colors.hues.orange,
    color: 'black',
    marginLeft: 66,
  }
}));

export default function GoBackToast(props: Props) {
  const { onDismiss, onGoBack, label } = props;
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Button onClick={onDismiss}>
        Dismiss
      </Button>
      <Button
        onClick={onGoBack}
        variant="contained"
        color="inherit"
        className={classes.backButton}
      >
        <RollbackOutlined style={{marginRight: 16}}/>
        {label || 'Go Back'}
      </Button>
    </div>
  );
}
