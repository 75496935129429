import { FetchResult, gql } from '@apollo/client';
import _ from 'lodash';
import client, { runQuery } from '../apollo-client';
import tracker from '../lib/tracker';
import { setCachedItem, getCachedItem } from '@shield-ui/utils';
import {
  HangarMutations,
  HangarMutationsCreateCollectionArgs,
} from '@shield-ui/hangar-service';

const collectionFragment = `
  id
  name
  description
  lastRobotLogsRefreshAt
  collectionType
  isSystemDefault
  isPubliclyEditable
  systemDefaultResolutionScheme
  includedRobotLogs { id, name }
  excludedRobotLogs { id, name }
  uiData
  createdAt
  createdById
  isPrivateForCreator
  createdBy {
    name
    picture
    providerName
  },
  dashboardEmbedUrls {
    name
    url
    sourceUrl
  }
  ruleSet {
    id
    robotLogSearchVariables
    searchVersion
  }
  favoriteForUsersTotalCount
  favoriteForUsers {
    name
    picture
    providerName
  }
  labels {
    edges {
      node {
        id
        name
      }
    }
  }
`;

const commonCreateUpdateVariablesFragment = `
  name: $name,
  description: $description,
  isPrivateForCreator: $isPrivateForCreator,
  isPubliclyEditable: $isPubliclyEditable,
  ruleSetId: $ruleSetId,
  uiData: $uiData,
`;

const createCollectionMutation = gql`
  mutation createCollection(
    $name: String!,
    $description: String!,
    $isPrivateForCreator: Boolean,
    $isPubliclyEditable: Boolean,
    $ruleSetId: UUID,
    $collectionType: CollectionTypeChoice,
    $uiData: JSONString
  ) {
    createCollection(${commonCreateUpdateVariablesFragment}, collectionType:$collectionType) {
      ${collectionFragment}
    }
  }
`;
const updateCollectionMutation = gql`
  mutation updateCollection(
    $id: UUID!,
    $name: String,
    $description: String,
    $isPrivateForCreator: Boolean,
    $isPubliclyEditable: Boolean,
    $ruleSetId: UUID,
    $uiData: JSONString
  ) {
    updateCollection(id: $id,${commonCreateUpdateVariablesFragment}) {
      ${collectionFragment}
    }
  }
`;
const cloneCollectionMutation = gql`
    mutation cloneCollection(
        $id: UUID!,
        $name: String!,
        $description: String,
        $isPrivateForCreator: Boolean,
        $isPubliclyEditable: Boolean,
    ) {
        cloneCollection(id: $id, name: $name, description: $description, isPrivateForCreator: $isPrivateForCreator, isPubliclyEditable: $isPubliclyEditable) {
            ${collectionFragment}
        }
    }
`;

const deleteCollectionMutation = gql`
  mutation deleteCollection($id: String!) {
    deleteCollection(id: $id) {
      id
    }
  }
`;

const refreshRobotLogsForCollectionMutation = gql`
  mutation refreshRobotLogsForCollection($collectionId: UUID!) {
    refreshRobotLogsForCollection(collectionId: $collectionId) {
      id
      lastRobotLogsRefreshAt
    }
  }
`;

export const mutations = {
  create: (
    variables: HangarMutationsCreateCollectionArgs
  ): Promise<FetchResult<Pick<HangarMutations, 'createCollection'>>> =>
    client.mutate({
      mutation: createCollectionMutation,
      variables,
    }),
};

function runMutation({ variables, mutation, event, callback = _.noop }) {
  client.mutate({ mutation, variables }).then(
    (res) => {
      callback(null, res);
      tracker.trackEvent('Collection', event);
      return res;
    },
    (err) => {
      callback(err);
    }
  );
}

const getCollectionQuery = gql`
    query collection($id: String!) {
        collection(id: $id) {
            ${collectionFragment}
        },
    }
`;

const searchCollectionsQuery = gql`
  query collection(
    $textLike: String
    $names: [String]
    $first: Int
    $collectionTypes: [CollectionTypeChoice]
    $sort: [CollectionSortEnum]
  ) {
    collections(
      textLike: $textLike
      names: $names
      sort: $sort
      first: $first
      collectionTypes: $collectionTypes
    ) {
      edges {
        node {
          id
          name
          description
          lastRobotLogsRefreshAt
        }
      }
    }
  }
`;

export function getCollection(variables, callback) {
  client
    .query({
      query: getCollectionQuery,
      variables,
      fetchPolicy: 'network-only',
    })
    .then(
      (val) => {
        if (typeof callback === 'function') {
          callback(null, val);
        }
      },
      (err) => {
        if (typeof callback === 'function') {
          callback(err);
        }
      }
    );
}

export function searchCollections(variables, callback) {
  runQuery({
    query: searchCollectionsQuery,
    variables,
    fetchPolicy: 'network-only',
    callback,
  });
}

export function queryCollections(variables) {
  return client.query({
    query: searchCollectionsQuery,
    variables,
    fetchPolicy: 'network-only',
  });
}

export function createCollection(variables, callback) {
  runMutation({
    variables,
    mutation: createCollectionMutation,
    event: 'CollectionCreate',
    callback,
  });
}

export function updateCollection(variables, callback) {
  runMutation({
    variables,
    mutation: updateCollectionMutation,
    event: 'CollectionUpdate',
    callback,
  });
}
export function cloneCollection(variables, callback) {
  runMutation({
    variables,
    mutation: cloneCollectionMutation,
    event: 'CollectionClone',
    callback,
  });
}
export function deleteCollection(variables, callback) {
  runMutation({
    variables,
    mutation: deleteCollectionMutation,
    event: 'CollectionDelete',
    callback,
  });
}

export function refreshRobotLogsForCollection(variables, callback) {
  runMutation({
    variables,
    mutation: refreshRobotLogsForCollectionMutation,
    event: 'CollectionRefresh',
    callback,
  });
}

export function alterIncludedExcludedMappings({
  variables,
  mutation,
  callback,
}) {
  if (
    ![
      'addIncludedRobotLogsToCollection',
      'addExcludedRobotLogsToCollection',
      'removeIncludedRobotLogsFromCollection',
      'removeExcludedRobotLogsFromCollection',
    ].includes(mutation)
  ) {
    callback(
      new Error(
        `invalid mutation for alterIncludedExcludedMappings ${mutation}`
      )
    );
    return;
  }

  const mutationToRun = gql`
      mutation ${mutation} ($collectionId:UUID!, $robotLogIds:[UUID]!) {
          ${mutation} (collectionId: $collectionId, robotLogIds:$robotLogIds) {
          includedRobotLogs { id, name }
          excludedRobotLogs { id, name }
          lastRobotLogsRefreshAt
      }
    }
  `;

  runMutation({
    variables,
    mutation: mutationToRun,
    event: `CollectionMapping-${mutation}`,
    callback,
  });
}

const RECENT_COLLECTIONS_CACHE_KEY = 'recentCollections';

export const getRecentCollections = () => {
  const cached = getCachedItem(RECENT_COLLECTIONS_CACHE_KEY) as {
    recentCollections: { id: string }[];
  };
  if (cached !== undefined) {
    return cached.recentCollections;
  }
  return [];
};

//Add the recently viewed collection to local storage
//Called in redux/collection/actions.ts
export const setRecentCollections = (collection, limit = 10) => {
  let recentCollections = getRecentCollections();

  //iterate the recentCollections array to see if the collection already exists
  const index = _.findIndex(recentCollections, { id: collection.id });

  //append to the front of the recentCollection array
  if (index === -1) {
    recentCollections = [collection].concat(recentCollections);
  }
  //remove at current position and relocate to the front of the recentCollection array
  else {
    const temp = recentCollections[index];
    recentCollections.splice(index, 1);
    recentCollections = [temp].concat(recentCollections);
  }

  if (recentCollections.length > limit) {
    recentCollections = recentCollections.slice(0, -1);
  }

  setCachedItem(RECENT_COLLECTIONS_CACHE_KEY, {
    recentCollections: recentCollections,
  });
};
