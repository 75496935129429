import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { hideModal } from '../../redux/modal/actions';
import { showSuccessSnack, showErrorSnack } from '../../redux/snackbar/actions';

// Only really common things should be added and passed through to all modals
// Put your modal specific dispatch / state in containers wrapping discrete modal components
function mapStateToProps(state) {
  return {
    visibleModalType: state.modal.visibleModalType,
    modalContextProps: state.modal.modalContextProps,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hideModal,
      showSuccessSnack,
      showErrorSnack,
    },
    dispatch
  );
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ContainerProps = ConnectedProps<typeof connector>;
export default connector;
