import React from 'react';
import _ from 'lodash';
import withStyles from '@mui/styles/withStyles';
import {
  updateRobotLogReview,
  deleteRobotLogReview,
} from '../../../services/reviews';
import ReviewList from '../../../components/reviews/ReviewList';
import { showConfirmPrompt } from '../../../lib/messages';
import container from '../../../containers/authUser.ts';
import PropTypes from 'prop-types';

function styles() {
  return {
    controlContainer: {
      marginTop: 5,
      paddingTop: 10,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  };
}

function ChildReviewList(props) {

  const onSuccess = (message) => {
    const { showSuccessSnack, onRefresh } = props;
    showSuccessSnack({ message });
    onRefresh();
  };

  const onError = (err) => {
    const { showErrorSnack } = props;
    showErrorSnack(err);
  };
  const updateReviewStatus = (id, status) => {
    const variables = {
      id: id,
      reviewStatus: status,
    };

    updateRobotLogReview(variables, (err) => {
      if (err) {
        return onError(err);
      }
      onSuccess('Review status was updated');
    });
  };

  const deleteReview = (id) => {
    showConfirmPrompt({
      title: 'Delete Review?',
      body: 'This action is irreversible. Are you sure you want to remove this Review?',
      confirmLabel: 'Delete',
      onConfirm: () => {
        deleteRobotLogReview({ id }, (err) => {
          if (err) {
            return onError(err);
          }
          onSuccess('Review was removed');
        });
      },
    });
  };

  const { reviewList, currentUser, classes } = props;

  return (
    <React.Fragment>
      <div className={classes.controlContainer}>
        <ReviewList
        reviews={reviewList}
        currentUser={currentUser}
        updateReviewStatus={updateReviewStatus}
        deleteReview={deleteReview}
        />
      </div>
    </React.Fragment>
  )
}

ChildReviewList.propTypes = {
  reviewList: PropTypes.array, 
  onRefresh: PropTypes.func,
  currentUser: PropTypes.object
}

ChildReviewList.defaultProps = {
  reviewList: [],
  onRefresh: _.noop,
}


export default withStyles(styles)(container(ChildReviewList));
