import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { Radio, Select, Form, InputNumber } from 'antd';
import AntPopoverConfigProvider from './AntPopoverConfigProvider';
import { EvaluationState } from '@hmd/sdk/api/tests/evaluations/v1';
import { prettifyString } from '@shield-ui/utils';
import { DropdownChip } from '@shield-ui/controls';
import { getEvaluationStateString } from '../utils';
const { Option } = Select;

const useStyles = makeStyles((theme) => ({
  container: {
    width: 270,
    padding: 5,
  },
  section: {
    marginBottom: 10,
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 20,
  },
  radioTitle: {
    fontSize: 14,
    fontWeight: 500,
  },
  sectionOptions: {
    width: '100%',
    marginTop: 8,
    marginBottom: 24,
  },
  indentLeft: {
    marginLeft: 20,
  },
  sectionInactive: {
    //opacity: 0.2,
  },
  radioGroup: {
    fontSize: 8,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  chip: {
    width: 250,
  },
}));

const getLabel = (values: CellFormValues): string => {
  if (values.cellContent === 'reliability') {
    // This is not the right way to get this value... the value types for failureTypeReliability is super wonky here
    const evaluationState = prettifyString(
      getEvaluationStateString(values.reliabilityFailureType)
    ).replace(/Failure/, '');
    return evaluationState + ' ' + getCellContentLabel(values.cellContent);
  }

  return getCellContentLabel(values.cellContent);
};

function getCellContentLabel(content: CellFormValues['cellContent']): string {
  if (content === 'passFail') {
    return 'Pass / Fail';
  } else if (content === 'reliability') {
    return 'Reliability';
  } else if (content === 'weightedAverage') {
    return 'Weighted Average';
  }
}

export type CellFormValues = {
  cellContent: 'passFail' | 'reliability' | 'weightedAverage';
  failureBreakdown: 'consolidated' | 'individual';
  passingCountDisplay: 'absolute' | 'percent';
  reliabilityFailureType: any;
  reliabilityCountDisplay: 'absolute' | 'percent';
  weights: { [evaluationState: string]: number };
};

const reliabilityStates = Object.keys(EvaluationState).filter(
  (evaluationState) =>
    [
      EvaluationState.MINOR_FAILURE,
      EvaluationState.MAJOR_FAILURE,
      EvaluationState.MODERATE_FAILURE,
      EvaluationState.CRITICAL_FAILURE,
      EvaluationState.CATASTROPHIC_FAILURE,
    ].includes(EvaluationState[evaluationState])
);

export const initialValues: CellFormValues = {
  cellContent: 'passFail',
  failureBreakdown: 'consolidated',
  passingCountDisplay: 'absolute',
  reliabilityFailureType: 3,
  reliabilityCountDisplay: 'absolute',
  weights: reliabilityStates.reduce(
    (acc, evaluationState) => ({
      ...acc,
      [evaluationState]: 1.0,
    }),
    {}
  ),
};

export interface CellDropdownChipProps {
  value: CellFormValues;
  onChange: (values: CellFormValues) => void;
}

export function CellDropdownChip(props: CellDropdownChipProps) {
  const { value, onChange } = props;
  const classes = useStyles();
  const [form] = Form.useForm();

  const CellControls = () => {
    return (
      <div className={classes.container}>
        <div className={classes.title}>Cell Display Options</div>
        <AntPopoverConfigProvider>
          <Form
            form={form}
            name="cellDropdownForm"
            onValuesChange={(
              changedValues: Partial<CellFormValues>,
              newValues: CellFormValues
            ) => {
              onChange(newValues);
            }}
            initialValues={value}
            autoComplete="off"
            layout="vertical"
            labelCol={{ span: 20 }}
            wrapperCol={{ span: 20 }}
          >
            <Form.Item name="cellContent">
              <Radio.Group>
                <Radio value="passFail">
                  <div className={classes.radioTitle}>
                    {getCellContentLabel('passFail')}
                  </div>
                  <div
                    className={cx(
                      classes.sectionOptions,
                      value.cellContent !== 'passFail' &&
                        classes.sectionInactive
                    )}
                  >
                    <Form.Item
                      //label="Failure Breakdown"
                      name="failureBreakdown"
                    >
                      <Radio.Group size="middle">
                        <Radio.Button value="consolidated">
                          Consolidated
                        </Radio.Button>
                        <Radio.Button value="individual">
                          Individual
                        </Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item
                      //label="Passing Count Display"
                      name="passingCountDisplay"
                    >
                      <Radio.Group size="middle">
                        <Radio.Button value="absolute">Absolute</Radio.Button>
                        <Radio.Button value="percent">Percent</Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                </Radio>

                <Radio value="reliability">
                  <div className={classes.radioTitle}>
                    {getCellContentLabel('reliability')}
                  </div>
                </Radio>
                <div
                  className={cx(
                    classes.sectionOptions,
                    classes.indentLeft,
                    value.cellContent !== 'reliability' &&
                      classes.sectionInactive
                  )}
                >
                  <Form.Item
                    //label="Reliability Failure Type"
                    name="reliabilityFailureType"
                  >
                    <Select style={{ width: 220 }}>
                      {Object.entries(EvaluationState).map((eType) => {
                        if (eType[1] > 2 && eType[1] < 8) {
                          return (
                            <Option key={eType[1]} value={eType[1]}>
                              {prettifyString(eType[0]).replace(
                                /Failure/,
                                'Reliability'
                              )}
                            </Option>
                          );
                        }
                        return null;
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    //label="Reliability Count Display"
                    name="reliabilityCountDisplay"
                  >
                    <Radio.Group>
                      <Radio.Button value="absolute">Absolute</Radio.Button>
                      <Radio.Button value="percent">Percent</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <Radio value="weightedAverage">
                  <div className={classes.radioTitle}>
                    {getCellContentLabel('weightedAverage')}
                  </div>
                  <div
                    className={cx(
                      classes.sectionOptions,
                      value.cellContent !== 'weightedAverage' &&
                        classes.sectionInactive
                    )}
                  >
                    <table cellSpacing={0} cellPadding={3} width={240}>
                      {reliabilityStates.map((evaluationState) => {
                        return (
                          <tr>
                            <td>{prettifyString(evaluationState)}</td>
                            <td>
                              <Form.Item
                                noStyle
                                name={['weights', evaluationState]}
                              >
                                <InputNumber min={0} />
                              </Form.Item>
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
        </AntPopoverConfigProvider>
      </div>
    );
  };

  return (
    <div>
      <DropdownChip
        className={classes.chip}
        title="cell"
        label={getLabel(value)}
        placeholder={'CellDropdownChip placeholder'}
        renderDropdown={CellControls}
        icon="settings"
      />
    </div>
  );
}
