import React, { useCallback, useLayoutEffect, useMemo } from 'react';
import { QueryFilterClasses } from '../filterTypes';
import { useForceUpdate } from '@shield-ui/hooks';
import {
  LayoutProps,
  ControlProps,
  PassthruLayoutProps,
  defaultGetQueryFilterComponent,
} from './common';
import { buildFullQueryFromValues } from '../utils';
import EmptyLayout from './EmptyLayout';

interface Props {
  queryFilter: QueryFilterClasses;
  values: Record<string, any>;
  setValues: (value: any) => void;
  getQueryFilterControlComponent?: (
    queryFilter: QueryFilterClasses
  ) => React.ElementType;
  LayoutComponent?: React.ElementType<LayoutProps>;
  ControlComponent?: React.ElementType;
  layoutProps?: PassthruLayoutProps;
}

/**
 * This is the default React implementation of a QueryFilter, it translates
 * the QueryFilter into a Layout and mounts an explicit Control Component for this specific
 * type of query filter.
 * @param props
 * @constructor
 */
export default function QueryFilterControl(props: Props) {
  const {
    queryFilter,
    values = {},
    setValues,
    // overload the default layout
    LayoutComponent = EmptyLayout,
    // pass a function that returns the correct ControlComponent to mount
    getQueryFilterControlComponent = defaultGetQueryFilterComponent,
    ControlComponent,
    layoutProps = {},
  } = props;

  // because queryFilter exists outside of react tree, we don't notice its internal props changing
  // we use it's event emitter when it's state changes to force render updates
  // EmptyLayout means control is always mounted, so let's make sure we always ensureControlData
  // We have this in the top level component, rather than the layout, so if we get an update the entire component tree gets renderer
  const onForceUpdate = useForceUpdate();

  const onChange = useCallback(
    (updates) => {
      const currentValue = values[queryFilter.getId()] || {};
      const newValue = {
        ...currentValue,
        ...updates,
      };

      // map in the esQuery by default
      newValue['esQuery'] = queryFilter.getElasticQuery(newValue);
      setValues({
        [queryFilter.getId()]: newValue,
      });
    },
    [values, setValues]
  );

  // queryFilter's may implement this event emitter that can fire
  // mutations to the value at a non-standard time
  if (queryFilter.getImplementsInternalValueChange()) {
    useLayoutEffect(() => {
      queryFilter.events.on('internalValueChange', onChange);
      return () => {
        queryFilter.events.off('internalValueChange', onChange);
      };
    }, [onChange]);
  }

  const onClear = useCallback(() => {
    setValues({
      [queryFilter.getId()]: queryFilter.getUnsetValue(),
    });
  }, [setValues]);

  const queries = useMemo(() => {
    let partialQuery;
    if (values[queryFilter.getId()]) {
      const partialValues = {
        ...(values || {}),
      };
      delete partialValues[queryFilter.getId()];
      partialQuery = buildFullQueryFromValues({ values: partialValues });
    }

    const fullQuery = buildFullQueryFromValues({ values });

    return {
      full: fullQuery,
      partial: partialQuery || fullQuery,
    };
  }, [values, queryFilter.getId()]);

  const value = values[queryFilter.getId()];

  // Underlying Control. Specific to the queryFilter type
  let Control;
  const C = ControlComponent || getQueryFilterControlComponent(queryFilter);
  if (C) {
    const controlProps: ControlProps<any> = {
      onChange: onChange,
      onClear: onClear,
      value: value,
      // this is only passed through for "Nested" QueryFilter currently
      getQueryFilterControlComponent: getQueryFilterControlComponent,
      hasValue: queryFilter.hasValue(value),
      fullQuery: queries.full,
      partialQuery: queries.partial,
    };

    Control = <C {...controlProps} {...queryFilter.getControlData()} />;
  }

  // Common Layout Component that encapsulates the individual query filter control
  return (
    <LayoutComponent
      onClear={onClear}
      label={queryFilter.getControlLabel()}
      queryFilter={queryFilter}
      value={value}
      onForceUpdate={onForceUpdate}
      valuePreview={queryFilter.getValuePreview(value)}
      ControlElement={Control}
      {...layoutProps}
    />
  );
}
