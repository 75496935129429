import { Modal, Form, InputNumber, Input } from 'antd';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';

const CreateMetricOrExpectationModal = ({ visible, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  const [isExpectation, setIsExpectation] = useState(false);

  const handleThresholdChange = (value) => {
    setIsExpectation(value !== null);
  };

  const handleOk = () => {
    form.validateFields().then((values) => {
      form.resetFields();
      onCreate(values);
    });
  };

  return (
    <Modal
      visible={visible}
      title={isExpectation ? 'Create Expectation' : 'Create Metric'}
      okText={isExpectation ? 'Create Expectation' : 'Create Metric'}
      onCancel={onCancel}
      onOk={handleOk}
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        <Form.Item
          name="name"
          label="Name"
          rules={[
            { required: true, message: 'Please enter the name of the metric' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="value"
          label="Value"
          rules={[
            { required: true, message: 'Please enter the value of the metric' },
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item name="threshold" label="Threshold">
          <InputNumber onChange={(value) => handleThresholdChange(value)} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateMetricOrExpectationModal;
