import React from 'react';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  StartContent?: React.ReactNode;
  EndContent?: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  el: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(6),
  },
  space: {
    flex: 1,
  },
}));

function ControlBarContainer(props: Props) {
  const { className, StartContent, EndContent, ...rest } = props;
  const classes = useStyles();
  return (
    <div className={cx(className, classes.el)} {...rest}>
      {StartContent}
      <div className={classes.space} />
      {EndContent}
    </div>
  );
}

export default ControlBarContainer;
