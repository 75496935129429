import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { stopEventPropagation } from "@shield-ui/utils";
import TestEvaluationBadge from "../../../../test-evaluations/TestEvaluationBadge";
import TestDefinitionBadge from "../../../../test-definitions/TestDefinitionBadge";

const useStyles = makeStyles({
  container: {
    flexDirection: 'column',
    display: 'flex',
  },
  space: {
    height: 4,
  }
});

function canHaveEvaluation (sessionLogTypeSlug) {
  return sessionLogTypeSlug === 'test' || sessionLogTypeSlug === 'trial';
}

export default function TestCodeCell({ row }) {
  const classes = useStyles();
  if (row.robotLogTest && row.robotLogTest.code) {
    return <>{row.robotLogTest.code}</>
  }

  const { id, testDefinitionId } = row;

  if (!testDefinitionId) {
    return null;
  }

  return (
    <div
      className={classes.container}
      onClick={stopEventPropagation}
      onMouseDown={stopEventPropagation}
      onContextMenu={stopEventPropagation}
    >
      <TestDefinitionBadge testDefinitionId={testDefinitionId} />
      <div className={classes.space} />
      {canHaveEvaluation(row.sessionLogTypeSlug) && <TestEvaluationBadge robotLogId={id} type='table'/>}
    </div>
  );
}
