import React from 'react';
import withStyles from '@mui/styles/withStyles';
import PipelineExplorer from '../../../components/pipelines/PipelineExplorer';

function styles(theme) {
  return {
    explorerContainer: {
      marginTop: theme.spacing(1),
    },
  };
}

function AnalysisSection({ classes, robotLogId }) {
  return (
    <div className={classes.explorerContainer}>
      <PipelineExplorer robotLogId={robotLogId} />
    </div>
  );
}

export default withStyles(styles)(AnalysisSection);
