import {
  NestedQueryFilter,
  MultiselectQueryFilter,
} from '@shield-ui/query-filters';
import { getPropertyValues } from '../../../api/entitySchemas';
import { fetchEntitySchemas } from '../../../services/entitySchemas';

// Asynchronously fetch schemas and convert them to nested filters
const convertEntitySchemasToFilters = async () => {
  const entitySchemas = await fetchEntitySchemas();

  const queryFilters = entitySchemas
    .map((schema) => {
      const jsonSchema = schema.schema; // Convert each schema to a NestedQueryFilter
      const queryFilters = Object.keys(jsonSchema.properties)
        .map((property) => {
          const field = jsonSchema.properties[property];

          // do not map to nested property if not a simple type
          if (field.type === 'object' || field.type === 'array') {
            return null;
          }

          // Convert each property to MultiselectQueryFilter
          return new MultiselectQueryFilter({
            id: `entity.${schema.slug}.${property}`,
            controlLabel: jsonSchema.properties[property].title || property,
            esField: `entity.${schema.slug}_${property}.keyword`,
            description: jsonSchema.properties[property].description || '',
            unsetValueDisabled: true,
            behaviorConfig: {
              match: true,
              fetchOptions: async () => {
                return (await getPropertyValues(schema.id, property)).map(
                  (value) => ({
                    label: value,
                    value,
                  })
                );
              },
            },
          });
        })
        .filter((qf) => !!qf);

      // If there are no simple query filters, don't create a nested filter
      if (!queryFilters.length) {
        return null;
      }

      return new NestedQueryFilter({
        id: schema.id,
        controlLabel: schema.name,
        esField: `entity`,
        description: schema.description || '',
        notExistsControl: {
          enabled: true,
        },
        queryFilters,
      });
    })
    .filter((nqf) => !!nqf);

  return queryFilters;
};

export const entityQueryFilters = convertEntitySchemasToFilters;
