import { ValueRangeQueryFilter } from '@shield-ui/query-filters';

export default new ValueRangeQueryFilter({
  id: 'startTimeOfDayPST',
  controlLabel: 'Time Of Day (PST)',
  esField: 'start_time_pst_hour_of_day',
  description:
    'Useful for trying to segregate flights within certain hours (e.g. Night Flights)',
  minThreshold: 0,
  maxThreshold: 24,
  formatValue: (v) => {
    return `${v}:00`;
  },
});
