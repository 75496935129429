import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { fonts } from '@shield-ui/styles';

const useStyles = makeStyles((theme) => ({
  header: {
    margin: theme.spacing(2, 0),
    letterSpacing: '0.5px',
    borderBottom: `1px solid rgba(255, 255, 255, 0.025)`,
    fontSize: 20,
    fontFamily: fonts.headerFontFamily,
  },
}));

function SectionHeader({ children }) {
  const classes = useStyles();
  return <div className={classes.header}>{children}</div>;
}

export default SectionHeader;
