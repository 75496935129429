import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  name: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    margin: theme.spacing(0, 1),
  },
}));

export default function OutputRowName({ children }) {
  const classes = useStyles();

  return <div className={classes.name}>{children}</div>;
}
