import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import { Theme } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Button, Typography } from '@mui/material';
import { ShieldAiColorFull } from '@shield-ui/icons';
import config from '../../lib/config';
import container, { ContainerProps } from '../../containers/authUser';
import mapi, { getMicrosoftProfilePictureUrl } from '../../lib/mapi';
import { showErrorSnack } from '../../redux/snackbar/actions';
import { Redirect } from 'react-router-dom';

const styles = (theme: Theme) =>
  createStyles({
    page: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    center: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    bottom: {
      padding: theme.spacing(3),
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    bottomRowLeft: {
      width: 300,
      color: 'rgba(255,255,255,0.25)',
    },
    logo: {
      fontSize: 36,
      width: 'auto',
    },
    loginContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(8),
      width: 480,
      alignItems: 'center',
      flexDirection: 'column',
    },
    loginButtonMicrosoft: {
      margin: theme.spacing(2, 0),
    },
    hmc: {
      textTransform: 'uppercase',
    },
  });

interface LoginPageProps extends ContainerProps, WithStyles<typeof styles> {}

class LoginPage extends Component<LoginPageProps> {
  onClickMicrosoftSignIn = () => {
    const { showErrorSnack } = this.props;
    mapi
      .signIn()
      .then((res) => {
        const { uniqueId: userId } = res;

        const profile = {
          accessToken: res.idToken.rawIdToken,
          providerJwt: res.idToken.rawIdToken,
          providerIdToken: res.uniqueId,
          providerName: 'microsoft',
          pictureOverride: getMicrosoftProfilePictureUrl(userId),
        };
        const next = _.get(this.props, 'location.state.referrer', '');
        this.props.login(profile, next);
      })
      .catch((e) => {
        console.error('Microsoft sign in error');
        showErrorSnack(e.message);
        console.error(e);
      });
  };

  onClickMicrosoftSignOut = () => {
    mapi.signOut().catch((e) => {
      console.error('Microsoft sign out error');
      showErrorSnack(e.message);
      console.error(e);
    });
  };

  render() {
    const { classes } = this.props;

    // If a SystemUser is configured for a network denied environment, redirect from /app/login to the flights page
    const { systemUser } = config;
    if (systemUser) {
      return (
        <Redirect
          to={{
            pathname: '/app/robot-logs/flight',
            search: '',
            state: { referrer: window.location.pathname },
          }}
        />
      );
    }

    return (
      <div className={classes.page}>
        <Helmet>
          <title>Login: Vision</title>
        </Helmet>
        <div className={classes.center}>
          <Typography variant="h6" className={classes.hmc}>
            Hivemind Design Software
          </Typography>
          <Typography variant="h1">Vision</Typography>
          <div className={classes.loginContainer}>
            <Button
              variant="contained"
              color="primary"
              className={classes.loginButtonMicrosoft}
              onClick={this.onClickMicrosoftSignIn}
            >
              Sign In Microsoft
            </Button>
            {/* <Button
              onClick={this.onClickMicrosoftSignOut}
              variant="contained"
              color="secondary"
              size="small"
              className={classes.loginButtonMicrosoft}
            >
              Sign Out Microsoft
            </Button> */}
          </div>
          <Typography variant="body2">
            By clicking enter you agree to our terms and conditions
          </Typography>
        </div>
        <div className={classes.bottom}>
          <div className={classes.bottomRowLeft}>
            <div>Branch: {config.branchName}</div>
            <div>
              Version: {config.softwareVersion}-{config.commitHash}
            </div>
          </div>
          <ShieldAiColorFull className={classes.logo} />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(container(LoginPage));
