import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from '@shield-ui/styles';
import OutputRow, { OutputRowProps } from './OutputRow';
import HighlightText from './HighlightText';
import { doesInputMatchBase } from '@shield-ui/utils';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: 8,
  },
  solidTreeLabel: {
    padding: 4,
    fontSize: 10,
    color: colors.hues.grays[80],
  },
}));

function getFilteredOutputs(outputs, searchString) {
  if (!searchString) {
    return outputs;
  }
  return outputs.filter((output) =>
    doesInputMatchBase(searchString, output.name)
  );
}

export type SolidGroupsProps = {
  outputs: OutputRowProps['output'][];
  searchString: string;
  onToggleFavorite: OutputRowProps['onToggleFavorite'];
  onEditClick: OutputRowProps['onEditClick'];
  label?: string;
  solids?: [];
};

export default function SolidGroups({
  outputs,
  searchString,
  onToggleFavorite,
  onEditClick,
  label = '',
  solids = [],
}) {
  const classes = useStyles();

  const filteredOutputs = getFilteredOutputs(outputs, searchString);

  let outputList;
  if (filteredOutputs.length > 0) {
    outputList = filteredOutputs.map((output, i) => (
      <OutputRow
        key={output.name}
        output={output}
        searchString={searchString}
        onToggleFavorite={onToggleFavorite}
        onEditClick={onEditClick}
      />
    ));
  }

  return (
    <div className={classes.container}>
      {/* We show the solid label most of the time other than searching and no match*/}
      {(!searchString || doesInputMatchBase(searchString, label)) && (
        <div className={classes.solidTreeLabel}>
          <HighlightText str={label} searchString={searchString} />
        </div>
      )}
      {/* Render all the outputs */}
      {outputList}
      {/* Render all the nested solid groups */}
      {solids.map((solid) => (
        <SolidGroups
          key={solid.label}
          {...solid}
          searchString={searchString}
          onToggleFavorite={onToggleFavorite}
          onEditClick={onEditClick}
        />
      ))}
    </div>
  );
}
