import React, { Fragment } from 'react';
import { inspect } from 'util';
import ReactDom from 'react-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import AutoComplete from 'react-autocomplete/dist/react-autocomplete'; // XXX For whatever reason it was importing from "build" which had some invalid objects in it
import { colors } from '@shield-ui/styles';
import { MenuItem } from '@mui/material';
import { lighten } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import { Link } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/RefreshRounded';
import { getTickets } from '../../../services/tickets';
import TicketName from './TicketName';
import TicketStatus from './TicketStatus';
import ticketApi from '../../../lib/ticketApi';
import { TextField } from '@shield-ui/controls';

const MIN_SEARCH_LENGTH = 3;
export const ICON_SIZE = 24;

const styles = {
  wrapperStyle: {
    display: 'block',
  },
  menuStyle: {
    background: colors.semantic.inputBackground,
    padding: 2,
    minWidth: 300,
    maxWidth: '100%',
    color: 'white',
    opacity: 1,
    position: 'absolute',
    top: 48,
    left: 0,
    zIndex: 1000,
    maxHeight: 300,
    overflowY: 'auto',
  },
  showIcon: {},
  hideIcon: {
    opacity: 0,
  },
};

function calcStyles(theme) {
  const padding = 6;

  styles.menuStyle.boxShadow = theme.shadows[2];

  return {
    container: {
      width: '100%',
      position: 'relative',
    },
    iconPlacement: {
      right: 32,
      position: 'absolute',
      top: padding + ICON_SIZE / 4,
    },
    icon: {
      zIndex: 2,
      color: colors.hues.gray,
      position: 'absolute',
    },
    '@keyframes spin': {
      from: { transform: 'rotate(0deg)' },
      to: { transform: 'rotate(360deg)' },
    },
    iconSpin: {
      animationName: '$spin',
      animationDuration: '1000ms',
      animationIterationCount: 'infinite',
      animationTimingFunction: 'linear',
    },
    inputRoot: {
      paddingLeft: ICON_SIZE * 1.5,
    },
    inputRootFocused: {
      borderColor: colors.semantic.inputBorderFocus,
      '&:hover': {
        borderColor: colors.semantic.inputBorderFocus,
      },
    },
    menuItem: {
      color: 'white',
      display: 'flex',
      maxWidth: '100%',
    },
    menuItemLeft: {
      fontSize: 14,
      flex: 1,
      marginRight: 7,
      overflow: 'hidden',
    },
    menuItemHighlighted: {
      background: lighten(colors.semantic.inputBackground, 0.1),
      color: colors.semantic.bodyText,
    },
    menuItemLink: {
      marginLeft: 10,
      fontSize: 13,
    },
    message: {
      textAlign: 'center',
      padding: 4,
      color: colors.semantic.bodyText,
      fontWeight: 300,
      fontSize: '.95em',
    },
  };
}

const NO_SUGGESTIONS_TAG_ID_KEY = '__NONE';

class TicketSearch extends React.Component {
  static propTypes = {
    onSelect: PropTypes.func,
    onEmptyBackspace: PropTypes.func,
    placeholder: PropTypes.string,
    suggestionsTagId: PropTypes.string,
    // Only one of (selectedPlatformIds, selectedTicketKeys) is used throughout this component.
    // In V1, tickets are identified by platformId.
    // In V2, tickets are identified by platformId + platform (ticketKey).
    // TicketMultiSelect still uses V1, since the required changes are too much and there's a good chance of regression.
    // TicketCollector uses V2.
    isV2: PropTypes.bool,
    selectedPlatformIds: PropTypes.array,
    selectedTicketKeys: PropTypes.array,
  };

  static defaultProps = {
    onSelect: _.noop,
    onEmptyBackspace: _.noop,
    placeholder:
      'Search for an ADO work item | Enter work item ID | Enter work item URL',
    isV2: false,
    selectedPlatformIds: [],
    selectedTicketKeys: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      focused: false,
      searching: false,
      value: '',
      items: [],
      containerHeight: 48,
      suggestionCache: {},
    };

    this.inputRef = React.createRef();
    this.doSearch = _.throttle(this._doSearch.bind(this), 100);
  }

  componentWillUnmount() {
    this.doSearch.cancel();
  }

  componentDidMount() {
    this.measure();
  }

  componentDidUpdate() {
    this.measure();
  }

  measure() {
    const { containerHeight } = this.state;
    if (this.inputRef.current) {
      // eslint-disable-next-line react/no-find-dom-node
      const node = ReactDom.findDOMNode(this.inputRef.current);
      const parentHeight = node.parentNode.offsetHeight;
      if (parentHeight !== containerHeight) {
        this.setState({
          containerHeight: parentHeight,
        });
      }
    }
  }

  focus = () => {
    if (this.inputRef && this.inputRef.current) {
      this.inputRef.current.focus();
    }
  };

  getStorageSuggestionsTagIdKey() {
    const { suggestionsTagId } = this.props;

    return suggestionsTagId
      ? `TAG_ID-${suggestionsTagId}`
      : NO_SUGGESTIONS_TAG_ID_KEY;
  }

  loadSuggestionsCheck() {
    const { suggestionsTagId } = this.props;
    const { suggestionCache } = this.state;
    const storageKey = this.getStorageSuggestionsTagIdKey();
    const fetchingKey = `FETCH_LOCK-${storageKey}`;

    if (suggestionCache[storageKey] || this[fetchingKey]) {
      return;
    }

    this[fetchingKey] = true;

    getTickets(
      {
        first: 25,
        eventTagIds: suggestionsTagId ? [suggestionsTagId] : undefined,
        sort: 'applied_to_events_count_desc',
        statusesNotIn: ['Removed', 'Done'],
      },
      (err, result) => {
        if (err) {
          delete this[fetchingKey];
          console.error(err);
          return;
        }
        this.setState({
          suggestionCache: {
            ...suggestionCache,
            [storageKey]: _.map(
              _.get(result, 'data.tickets.edges', []),
              (edge) => {
                return edge.node;
              }
            ),
          },
        });
      }
    );
  }

  onFocus = () => {
    this.setState({ focused: true });
    this.loadSuggestionsCheck();
  };

  onBlur = () => {
    this.setState({ focused: false });
  };

  _doSearch({ value }) {
    ticketApi
      .post(value)
      .then((res) => {
        const { value: curValue } = this.state;
        if (value !== curValue) {
          return;
        }

        const { data: tickets } = res;
        const total = tickets.length;

        if (!tickets) {
          this.setState({
            searching: false,
            items: [],
          });
          return console.warn('response doesnt look right', res);
        }

        const items = tickets.map((t) => ({ key: `${t.platform}/${t.platformId}`, ...t }));
        const moreResultCount = Math.max(0, total - tickets.length);

        if (moreResultCount) {
          items.push({
            message: `${moreResultCount} more results`,
            messageType: 'moreResults',
          });
        } else if (items.length === 0) {
          items.push({
            message: 'No matches',
            messageType: 'noMatches',
          });
        }

        this.setState({
          searching: false,
          items,
        });
      })
      .catch((e) => {
        const { value: curValue } = this.state;

        console.warn('search problem', e);
        if (value === curValue) {
          this.setState({
            searching: false,
            items: [],
          });
        }
      });
  }

  onChange = (e) => {
    const value = e.target.value;

    if (value.length === 0) {
      this.setState({
        searching: false,
        value,
        items: [],
      });
    } else if (value.length < MIN_SEARCH_LENGTH) {
      this.setState({
        searching: false,
        value,
        items: [
          {
            message: `Type at least ${MIN_SEARCH_LENGTH} characters`,
            messageType: 'typeMore',
          },
        ],
      });
    } else {
      const { items } = this.state;
      const updates = { value, searching: true };
      if (
        _.find(items, { messageType: 'typeMore' }) ||
        _.find(items, { messageType: 'noResults' })
      ) {
        updates.items = [];
      }

      this.hasSearched = true;
      this.setState(updates);
      this.doSearch({ value });
    }
  };

  onSelect = (itemValue, item) => {
    const { onSelect } = this.props;
    const { items, suggestionCache } = this.state;
   
    let ticketKey = { platformId: item.platformId };
    if (this.props.isV2) {
      ticketKey = { platform: item.platform, platformId: item.platformId };
    }

    // items are when it comes back from our query
    let ticket = _.find(items, ticketKey);

    // suggestions are stored and from the database
    if (!ticket) {
      const suggestions = _.flatten(_.values(suggestionCache));
      ticket = _.find(suggestions, ticketKey);
    }

    if (!ticket) {
      console.warn(`couldn't find ticket for platform ${inspect(ticketKey)}`);
      // maybe a race condition, couldnt find anything
      return;
    }

    onSelect({ ticket });

    this.setState(
      {
        value: '',
        items: [],
        searching: false,
      },
      () => {
        if (this.inputRef.current) {
          this.inputRef.current.blur();
        }
      }
    );
  };

  itemOnClickPreventCheck = (evt) => {
    if (evt.target.nodeName === 'A') {
      evt.stopPropagation();
    }
  };

  renderItem = (item, isHighlighted) => {
    const { classes } = this.props;
    const { message, messageType, name, status, platform, platformId, link } = item;

    if (message) {
      return (
        <div
          key={message}
          className={classNames(classes.message, classes[messageType])}
        >
          {message}
        </div>
      );
    }

    /*
            XXX - Extra wrapped div so we can intercept onClick events if the cell has interactive items (e.g. <a>
            The internals of the react-autocomplete lib overload the onClick event we specify on MenuItem because
            it is not expecting this use case
            By intercepting it closer to the target DOM Node (<a>) we can prevent default and keep it from bubbling up to be
            captured by react-autocomplete
         */
    return (
      <div key={`${platform}/${platformId}`}>
        <MenuItem
          className={classNames(
            classes.menuItem,
            isHighlighted && classes.menuItemHighlighted
          )}
          onClick={this.itemOnClickPreventCheck}
        >
          <div className={classes.menuItemLeft}>
            <TicketName name={name} />
          </div>
          <TicketStatus status={status} />
          <Link
            href={link}
            target="_blank"
            className={classes.menuItemLink}
            underline="hover">
            View
          </Link>
        </MenuItem>
      </div>
    );
  };

  onKeyDown = (evt) => {
    const { value } = this.state;
    const { onEmptyBackspace } = this.props;

    if (evt.keyCode === 8 && !value) {
      onEmptyBackspace(evt);
    }
  };

  renderInput = (props) => {
    const { searching, focused } = this.state;
    const { classes, disabled, placeholder, renderInput } = this.props;

    const IconComponent = (
      <Fragment>
        <SearchIcon
          onClick={this.focus}
          className={classes.icon}
          style={searching ? styles.hideIcon : styles.showIcon}
        />
        {this.hasSearched && (
          <RefreshIcon
            className={classNames(classes.icon, classes.iconSpin)}
            style={searching ? styles.showIcon : styles.hideIcon}
          />
        )}
      </Fragment>
    );

    const inputProps = {
      ...props,
      disabled,
      placeholder,
      onFocus: (evt) => {
        this.onFocus(evt);
        props.onFocus(evt);
      },
      onBlur: (evt) => {
        this.onBlur(evt);
        props.onBlur(evt);
      },
      onKeyDown: (evt) => {
        this.onKeyDown(evt);
        props.onKeyDown(evt);
      },
    };

    if (renderInput) {
      return renderInput({
        inputRef: this.inputRef,
        inputProps,
        IconComponent,
        focused,
        searching,
      });
    }

    return (
      <Fragment>
        <div className={classes.iconPlacement}>{IconComponent}</div>
        <TextField
          inputRef={this.inputRef}
          className={classNames(focused && classes.inputRootFocused)}
          classes={{
            input: classes.inputInput,
          }}
          inputProps={inputProps}
        />
      </Fragment>
    );
  };

  getItemValue(item) {
    return item.platformId || item.message;
  }

  isItemSelectable(item) {
    return !item.message;
  }

  render() {
    const { value, items, focused, containerHeight, suggestionCache } =
      this.state;
    const { classes, selectedTicketKeys, selectedPlatformIds } = this.props;

    let suggestions;
    if (value.length === 0) {
      const suggestionStorageKey = this.getStorageSuggestionsTagIdKey();
      suggestions = suggestionCache[suggestionStorageKey];
    }

    let itemOptions = suggestions || items;
    itemOptions = itemOptions.filter((item) => {
      if (this.props.isV2) {
        return !selectedTicketKeys.find(key => key.platform === item.platform && key.platformId === item.platformId);
      } 
      return !selectedPlatformIds.find(platformId => platformId === item.platformId);
    });

    return (
      <div className={classes.container}>
        <AutoComplete
          getItemValue={this.getItemValue}
          value={value}
          items={itemOptions}
          open={focused && !!itemOptions.length}
          onChange={this.onChange}
          onSelect={this.onSelect}
          renderItem={this.renderItem}
          renderInput={this.renderInput}
          isItemSelectable={this.isItemSelectable}
          wrapperStyle={styles.wrapperStyle}
          menuStyle={{
            ...styles.menuStyle,
            top: containerHeight,
          }}
        />
      </div>
    );
  }
}

export default withStyles(calcStyles)(TicketSearch);
