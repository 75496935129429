import React from 'react';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { KeyboardArrowRight } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    transition: '0.15s ease',
  },
  iconRotated: {
    transform: 'rotate(90deg)',
  },
}));

interface Props extends React.HTMLProps<HTMLDivElement> {
  isExpanded: boolean;
}

export default function ExpandIcon(props: Props) {
  const { isExpanded, className, ...rest } = props;
  const classes = useStyles();
  return (
    <div className={cx(classes.container, className)} {...rest}>
      <KeyboardArrowRight
        className={cx(classes.icon, isExpanded && classes.iconRotated)}
      />
    </div>
  );
}
