import {
  BaseFilterValue,
  BaseQueryFilter,
  BaseFilterProps,
} from './baseQueryFilter';
import { QueryFilterTypes, QueryFilterClasses, QFValues } from './common';

export interface GroupQueryFilterValue extends BaseFilterValue {
  individualValues?: QFValues;
}

export interface GroupControlData {
  queryFilters: QueryFilterClasses[];
}

export interface GroupQueryFilterProps extends BaseFilterProps {
  queryFilters: QueryFilterClasses[];
}

/**
 * Create an arbitrary group of individual queryFilters. Unlike the NestedQueryFilter, no
 * special ElasticSearch logic is applied when building the ElasticQuery
 */
export class GroupQueryFilter extends BaseQueryFilter<
  GroupQueryFilterValue,
  GroupQueryFilterProps,
  GroupControlData
> {
  type = QueryFilterTypes.group;

  constructor(props) {
    super(props);

    // when our children fire this event, make sure the parent does
    // because that's what UI systems are built off of (parent events)
    props.queryFilters.forEach((qf) => {
      qf.events.on('updatedControlData', () => {
        this.events.emit('updatedControlData', this.getControlData());
      });
    });
  }

  initControlData(props: GroupQueryFilterProps): GroupControlData {
    return {
      queryFilters: props.queryFilters,
    };
  }

  // controlData is handled in children
  hasValue(value: GroupQueryFilterValue): boolean {
    const { queryFilters } = this.props;

    if (!value) {
      return false;
    }
    const { individualValues } = value;

    if (!individualValues || Object.keys(individualValues).length === 0) {
      return false;
    }

    let hasValue;
    queryFilters.forEach((qf) => {
      if (!hasValue) {
        hasValue = qf.hasValue(individualValues[qf.getId()]);
      }
    });

    return hasValue;
  }

  // ensureControlData ensures that all of our child controls have their controlData initialized as well
  // just like in baseQueryFilter, _ensurePromise is captured to ensure we only call and get updates once
  ensureControlData(value: GroupQueryFilterValue): Promise<GroupControlData> {
    if (!this.hasValue(value)) {
      return;
    }

    const { individualValues } = value;
    const { queryFilters } = this.props;

    const promises = queryFilters.map((qf) => {
      return qf.ensureControlData(individualValues[qf.getId()]);
    });

    return Promise.all(promises).then(() => this.getControlData());
  }

  getElasticQuery(value: GroupQueryFilterValue): object | void {
    if (!this.hasValue(value)) {
      return;
    }

    const { individualValues } = value;
    const { queryFilters } = this.props;

    const elasticQueries = queryFilters.reduce((acc, qf) => {
      const qfElasticQuery = qf.getElasticQuery(individualValues[qf.getId()]);
      if (qfElasticQuery) {
        acc.push(qfElasticQuery);
      }
      return acc;
    }, []);

    if (elasticQueries.length === 1) {
      return elasticQueries[0];
    }

    return {
      bool: {
        must: elasticQueries,
      },
    };
  }

  getValuePreview(value: GroupQueryFilterValue): string {
    if (!this.hasValue(value)) {
      return '';
    }

    const { individualValues } = value;
    const { queryFilters } = this.props;

    const labelsWithValues = [];
    const previews = queryFilters.reduce((acc, qf) => {
      const preview = qf.getValuePreview(individualValues[qf.getId()]);
      if (preview) {
        acc.push(preview);
        labelsWithValues.push(qf.getControlLabel());
      }
      return acc;
    }, []);

    if (previews.length === 1) {
      return previews[0];
    } else if (previews.length > 1) {
      return `${labelsWithValues[0]} (+${labelsWithValues.length - 1})`;
    }
    return '';
  }
}
