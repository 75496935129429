import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import authUser from './authUser';
import collection from './collection';
import flight from './flight';
import lists from './lists';
import modal from './modal';
import settings from './settings';
import snackbar from './snackbar';
import tables from './tables';
import {
  PIPELINE_EXPLORER_FEATURE_KEY,
  pipelineExplorerSlice,
} from './pipelineExplorer.slice';
import {
  EVENT_MANAGER_FEATURE_KEY,
  eventManagerSlice,
} from './eventManager.slice';
import {
  DIAGNOSTIC_EXPLORER_FEATURE_KEY,
  diagnosticExplorerSlice,
} from './diagnosticExplorer.slice';
import {
  COLLECTION_CREATE_FEATURE_KEY,
  collectionCreateSlice,
} from './collectionCreate.slice';
import {
  TEST_DEFINITIONS_FEATURE_KEY,
  testDefinitionsSlice,
} from './testDefinitions.slice';
import { SYSTEMS_FEATURE_KEY, systemsSlice } from './systems.slice';

const store = configureStore({
  reducer: {
    authUser,
    collection,
    flight,
    lists,
    modal,
    settings,
    snackbar,
    tables,
    [COLLECTION_CREATE_FEATURE_KEY]: collectionCreateSlice.reducer,
    [DIAGNOSTIC_EXPLORER_FEATURE_KEY]: diagnosticExplorerSlice.reducer,
    [EVENT_MANAGER_FEATURE_KEY]: eventManagerSlice.reducer,
    [PIPELINE_EXPLORER_FEATURE_KEY]: pipelineExplorerSlice.reducer,
    [TEST_DEFINITIONS_FEATURE_KEY]: testDefinitionsSlice.reducer,
    [SYSTEMS_FEATURE_KEY]: systemsSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
