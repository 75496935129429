import React, { useCallback, useState } from 'react';
import { doesInputMatchBase } from '@shield-ui/utils';
import {
  MultiselectQueryFilterControlData,
  MultiselectQueryFilterValue,
} from '../../filterTypes';
import { makeCreatableValueFromUserInput } from '../../filterTypes/multiselectUtils';
import { ControlProps } from '../common';
import { AntButton } from '@shield-ui/buttons';
import SearchInput from '../shared/SearchInput';
import ResetButton from '../shared/ResetButton';
import CheckSelectOptions from '../shared/CheckSelectOptions';
import ActionsContainer from '../shared/ActionsContainer';
import IncludeExcludeMultiselectTabs from '../shared/IncludeExcludeMultiselectTabs';
import ListOperatorSwitch from '../shared/ListOperatorSwitch';
import CreatableControls from './CreatableControls';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  controlsWrapper: {
    marginBottom: theme.spacing(2),
  },
}));

interface Props
  extends ControlProps<MultiselectQueryFilterValue>,
    MultiselectQueryFilterControlData {}

const SHOW_TEXT_FILTER_MIN_LEN = 10;

function filterOptions(input, options) {
  return options.filter((opt) => {
    return (
      doesInputMatchBase(input, opt.label) ||
      (opt.description && doesInputMatchBase(input, opt.description))
    );
  });
}

interface IncludeExcludeProps extends Props {
  variant: 'include' | 'exclude';
  displayOptions: Props['options'];
  optionFilterValue: string;
  onChangeOptionFilter: (inputStr: string) => void;
}

function IncludeExcludeContent(props: IncludeExcludeProps) {
  const {
    displayOptions = [],
    value,
    options = [],
    onChange,
    variant,
    onClear,
    hasValue,
    optionFilterValue,
    isMulti,
    isCreatable,
    isHybrid,
    placeholder,
    onChangeOptionFilter,
    addCreatableOptions,
  } = props;

  const classes = useStyles();

  let optionValueKey = '';
  let operatorKey = '';
  if (variant === 'include') {
    optionValueKey = 'includeOptionValues';
    operatorKey = 'includeOperator';
  } else if (variant === 'exclude') {
    optionValueKey = 'excludeOptionValues';
    operatorKey = 'excludeOperator';
  }

  const optionValues = (value && value[optionValueKey]) || [];

  const onChangeOption = (evt, item) => {
    const { value: itemValue } = item;

    if (optionValues.includes(itemValue)) {
      onChange({
        [optionValueKey]: optionValues.filter((v) => v !== itemValue),
      });
    } else {
      onChange({
        [optionValueKey]: optionValues.concat([itemValue]),
      });
    }
  };

  let SelectAll;
  if (displayOptions.length < options.length && displayOptions.length > 1) {
    const onSelectAll = () => {
      const newOptionValues = displayOptions.map((opt) => opt.value);
      optionValues.forEach((v) => {
        if (!newOptionValues.includes(v)) {
          newOptionValues.push(v);
        }
      });

      onChange({
        [optionValueKey]: newOptionValues,
      });
    };

    SelectAll = (
      <AntButton size="small" onClick={onSelectAll}>
        Select All
      </AntButton>
    );
  }

  const shouldShowOperatorSwitch =
    isMulti && ((value && value[operatorKey]) || optionValues.length > 1);

  const onAddCreatable = (addingOptionValues) => {
    addCreatableOptions(addingOptionValues);
    const newValues = addingOptionValues.map(makeCreatableValueFromUserInput);

    onChange({
      [optionValueKey]: optionValues.concat(newValues),
    });
  };

  let mainControls = undefined;
  if (isHybrid) {
    // TODO improve UI here... it filters but adds also
    mainControls = (
      <CreatableControls
        onAdd={onAddCreatable}
        placeholder={placeholder}
        onInputChange={onChangeOptionFilter}
      />
    );
  } else if (isCreatable) {
    mainControls = (
      <CreatableControls onAdd={onAddCreatable} placeholder={placeholder} />
    );
  } else if (!isCreatable && options.length > SHOW_TEXT_FILTER_MIN_LEN) {
    mainControls = (
      <div className={classes.controlsWrapper}>
        <SearchInput
          suffix={SelectAll}
          placeholder={placeholder}
          value={optionFilterValue}
          onChange={(evt) => onChangeOptionFilter(evt.target.value)}
        />
      </div>
    );
  }

  return (
    <div>
      {mainControls}
      <CheckSelectOptions
        options={displayOptions}
        selectedValues={optionValues}
        onChangeOption={onChangeOption}
        noResultsMessage={
          options.length === 0 ? 'No options' : 'No Matching Options'
        }
      />
      <ActionsContainer
        Left={
          shouldShowOperatorSwitch && (
            <ListOperatorSwitch
              value={value && value[operatorKey]}
              onChange={(v) => onChange({ [operatorKey]: v })}
            />
          )
        }
        Right={<ResetButton onClick={onClear} disabled={!hasValue} />}
      />
    </div>
  );
}

export default function Multiselect(props: Props) {
  const [optionFilterValue, setOptionFilterValue] = useState('');
  const {
    options = [],
    value,
    disableIncludes,
    disableExcludes,
    isMulti,
  } = props;

  const onChangeOptionFilter = useCallback(
    (val: string) => {
      setOptionFilterValue(val);
    },
    [setOptionFilterValue]
  );

  const displayOptions =
    optionFilterValue.length < 2
      ? options
      : filterOptions(optionFilterValue, options);

  const commonProps = {
    ...props,
    displayOptions,
    // filter
    optionFilterValue,
    onChangeOptionFilter,
  };

  return (
    <IncludeExcludeMultiselectTabs
      isMulti={isMulti}
      disableIncludes={disableIncludes}
      disabledExcludes={disableExcludes}
      includeOptionValues={value?.includeOptionValues}
      excludeOptionValues={value?.excludeOptionValues}
      renderIncludes={() => (
        <IncludeExcludeContent variant="include" {...commonProps} />
      )}
      renderExcludes={() => (
        <IncludeExcludeContent variant="exclude" {...commonProps} />
      )}
    />
  );
}
