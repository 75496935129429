import client, { runQuery, runMutation } from '../apollo-client';
import { gql } from '@apollo/client';
import { ticketFragment } from './fragments';

/*
const createTicketMutation = gql`
  mutation createTicket(
    $name: String!,
    $link: String!,
    $status: String,
    $platform: String!,
    $platformId: String!,
  ) {
    createTicket(
      name: $name,
      link: $link,
      status: $status,
      platform: $platform,
      platformId: $platformId
    ) {
      ${ticketFragment}
    }
  }
`
const updateTicketMutation = gql`
  mutation updateTicket(
    $id: UUID!,
    $name: String,
    $status: String,
    $link: String,
    $platform: String,
    $platformId: String,
  ) {
    updateTicket(
      id: $id,
      name: $name,
      link: $link,
      status: $status,
      platform: $platform,
      platformId: $platformId
    ) {
      ${ticketFragment}
    }
  }
`
 */
const createOrUpdateTicketMutation = gql`
  mutation createOrUpdateTicket(
    $id: UUID,
    $name: String,
    $status: String,
    $link: String,
    $platform: String,
    $platformId: String,
  ) {
    createOrUpdateTicket(
      id: $id,
      name: $name,
      status: $status,
      link: $link,
      platform: $platform,
      platformId: $platformId,
    ) {
      ${ticketFragment}
    }
  }
`;

const getTicketsQuery = gql`
    query tickets(
        $textLike: String,
        $platformIds:[String],
        $eventTagIds:[UUID],
        $statusesIn: [String],
        $statusesNotIn: [String],
        $sort:[TicketSortEnum],
        $first:Int
    ) {
        tickets(
            textLike:$textLike,
            platformIds:$platformIds,
            eventTagIds:$eventTagIds,
            statusesIn:$statusesIn,
            statusesNotIn:$statusesNotIn,
            sort:$sort,
            first:$first
        ) {
            edges {
                node {
                    ${ticketFragment}
                }
            }
        }
    }
`;
/*
export function createTicket (variables, callback) {
  runMutation({
    variables,
    mutation: createTicketMutation,
    tracking: ['Flight', 'TicketCreate'],
    callback,
  })
}

export function updateTicket (variables, callback) {
  runMutation({
    variables,
    mutation: updateTicketMutation,
    tracking: ['Flight', 'TicketUpdate'],
    callback,
  })
}
 */

export function createOrUpdateTicket(variables, callback) {
  runMutation({
    variables,
    mutation: createOrUpdateTicketMutation,
    tracking: ['Flight', 'TicketCreateOrUpdate'],
    callback,
  });
}

export function getTickets(variables, callback) {
  runQuery({
    variables,
    query: getTicketsQuery,
    callback,
  });
}

export function queryTickets(variables) {
  return client.query({
    variables,
    query: getTicketsQuery,
  });
}
