import React from 'react';
import { Select } from '@shield-ui/controls';
import { useEnsureListsAreLoaded } from '../../../redux/lists/util';
import container from './container';
import { Option, filterOption } from '../TagSelect/TagSelect';

function CauseSelect(props) {
  const { withDescriptiveOptions, ...rest } = props;
  useEnsureListsAreLoaded();

  const extra = !props.causeOptions.length
    ? {
        placeholder: 'Loading...',
        disabled: true,
      }
    : undefined;

  const components = withDescriptiveOptions ? { Option } : undefined;

  return (
    <Select
      suggestions={props.causeOptions}
      filterOption={filterOption}
      components={components}
      {...rest}
      {...extra}
    />
  );
}

export default container(CauseSelect);
