import React from 'react';
import container from './container';
import InnerPage from './InnerPage';
import { isValidUuid4 } from '@shield-ui/utils';
import { getIdForName } from '../../services/robotLogs';

function getIdOrName(props) {
  return props.match.params.robotLogIdOrName;
}

class FlightPageRoute extends React.Component {
  state = {
    // cache robotLogNames to ids
    // page now supports routes having our canonical sessionLog name or id for legacy purposes
    nameToIdMap: {},
    errorMessage: undefined,
  };

  componentDidMount() {
    this.updateReduxTest();
  }

  componentDidUpdate(prevProps) {
    if (getIdOrName(prevProps) !== getIdOrName(this.props)) {
      this.updateReduxTest();
    }
  }

  resetTest(robotLogId) {
    const { pageRobotLogId, resetFlightPage } = this.props;
    if (robotLogId !== pageRobotLogId) {
      resetFlightPage({
        robotLogId,
      });
    }
  }

  updateReduxTest() {
    const idOrName = getIdOrName(this.props);

    if (this.state.errorMessage) {
      this.setState({ errorMessage: undefined });
    }

    // route has an ID in it
    if (isValidUuid4(idOrName)) {
      this.resetTest(idOrName);
    } else {
      // Route has a name in it
      getIdForName(decodeURIComponent(idOrName))
        .then((id) => {
          this.setState({
            nameToIdMap: {
              ...this.state.nameToIdMap,
              [idOrName]: id,
            },
          });
          this.resetTest(id);
        })
        .catch((err) => {
          console.error(err);
          this.setState({
            errorMessage: `Unable to find sessionLogId from name "${idOrName}" - ${err.statusMessage}`,
          });
        });
    }
  }

  getRouteRobotLogId() {
    // if is an id, return that
    const idOrName = getIdOrName(this.props);
    if (isValidUuid4(idOrName)) {
      return idOrName;
    } else {
      // else get from cache
      return this.state.nameToIdMap[idOrName];
    }
  }

  render() {
    const { errorMessage } = this.state;
    const { pageRobotLogId } = this.props;
    const robotLogId = this.getRouteRobotLogId();

    if (errorMessage) {
      return <div>Error: {errorMessage}</div>;
    }

    if (!robotLogId || robotLogId !== pageRobotLogId) {
      return null;
    }

    return <InnerPage {...this.props} robotLogId={pageRobotLogId} />;
  }
}

export default container(FlightPageRoute);
