import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Button, Typography } from '@mui/material';
import _ from 'lodash';
import cx from 'classnames';
import memoizeOne from 'memoize-one';
import withStyles from '@mui/styles/withStyles';
import BackIcon from '@mui/icons-material/ArrowRightAlt';
import { ensureListsAreLoaded } from '../../../redux/lists/util';

const SIDE_HOVER_ENABLED = false;

function styles(theme) {
  const WIDTH = 480;

  return {
    drawer: {
      position: 'fixed',
      height: '100%',
      width: WIDTH,
      zIndex: 1000,
      backgroundColor: theme.palette.background.paper,
      top: 0,
      right: -WIDTH + (SIDE_HOVER_ENABLED ? 19 : 0),
      transition: '0.25s ease',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    drawerOpen: {
      right: 0,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2, 3),
    },
    topContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 15,
    },
    title: {
      marginLeft: 5,
      fontSize: 24,
      flex: 1,
    },
    body: {
      marginBottom: 170,
    },
  };
}

class FiltersPanel extends React.PureComponent {
  static propTypes = {
    setFilters: PropTypes.func.isRequired,
    resetFilters: PropTypes.func.isRequired,
    tableCacheKey: PropTypes.string.isRequired,
    filtersVisible: PropTypes.bool.isRequired,
    toggleFiltersPanel: PropTypes.func.isRequired,
    filtersVariables: PropTypes.object,
    FiltersPanelContentComponent: PropTypes.elementType.isRequired,
    hasAnyFilterSet: PropTypes.bool,
    title: PropTypes.string,
  };

  static defaultProps = {
    hasAnyFilterSet: false,
    filtersVariables: {},
    title: 'Filters',
  };

  state = {
    /**
     * Updated here and then updates to redux are debounced for efficiency in redraws and requests
     */
    tmpFilters: {},
    // used to delay the initial filter opening into an animation rather than
    // just being open
    isMounted: false,
  };

  constructor(props) {
    super(props);

    this.drawerRef = React.createRef();

    this.engageHoverReadyDelay = _.debounce(() => {
      this.hoverReady = true;

      // Make sure drawer scroll resets itself to the top
      if (this.drawerRef && this.drawerRef.current) {
        this.drawerRef.current.scrollTop = 0;
      }
    }, 500);

    this.clearHoverReady = () => {
      this.engageHoverReadyDelay.cancel();
      this.hoverReady = false;
    };

    if (props.filtersVisible) {
      ensureListsAreLoaded();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.filtersVisible && this.props.filtersVisible) {
      ensureListsAreLoaded();
    }

    if (prevProps.filtersVisible && !this.props.filtersVisible) {
      this.engageHoverReadyDelay();
    } else if (!prevProps.filtersVisible && this.props.filtersVisible) {
      this.clearHoverReady();
    }
  }

  componentDidMount() {
    window.addEventListener('keyup', this.onEscapeKey);

    if (!this.props.filtersVisible) {
      this.engageHoverReadyDelay();
    }

    setTimeout(() => {
      this.setState({ isMounted: true });
    }, 200);
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.onEscapeKey);
  }

  mergeFilters = memoizeOne((filtersVariables, tmpFilters) => {
    return {
      ...filtersVariables,
      ...tmpFilters,
    };
  });

  /**
   * Rather than immediately updating our state in redux, we instead set an internal state
   * This is a sort of pre-caching so the UI can feel fast and responsive with quick updates
   * and we debounce the change to the variables in the store.
   * When the variables change in the store, this likely triggers updates (queries) so we want those to be slightly throttled
   * @param filters
   */
  setFilters = (filters) => {
    // atomic syncronous action guaranteed
    this.setState((prevState) => {
      return {
        ...prevState,
        tmpFilters: {
          ...prevState.tmpFilters,
          ...filters,
        },
      };
    });

    this.syncTempFilters();
  };

  /**
   * Debounced function that syncs our optimized local variables with the filters in redux
   * @type {function}
   * @return {function}
   */
  syncTempFilters = _.debounce(() => {
    const { tableCacheKey, setFilters } = this.props;
    const { tmpFilters } = this.state;

    setFilters({
      tableCacheKey,
      filtersVariables: tmpFilters,
    });
    this.setState({ tmpFilters: {} });
  }, 300);

  /**
   * Reset all back to NOTHING
   */
  resetFilters = () => {
    const { tableCacheKey, resetFilters } = this.props;
    resetFilters({ tableCacheKey });
  };

  /**
   * If the drawer is open ESC(27) will close it
   * @param evt
   */
  onEscapeKey = (evt) => {
    const { filtersVisible, toggleFiltersPanel, tableCacheKey } = this.props;

    if (filtersVisible && evt.keyCode === 27) {
      toggleFiltersPanel({ tableCacheKey });
      evt.stopPropagation();
      evt.preventDefault();
    }
  };

  /**
   * Hide the drawer
   */
  onClose = () => {
    const { tableCacheKey, toggleFiltersPanel } = this.props;
    toggleFiltersPanel({ tableCacheKey });
  };

  /**
   * Hover over the drawer floating on the side and it pops out
   */
  onClosedHover = () => {
    const { tableCacheKey, toggleFiltersPanel, filtersVisible } = this.props;
    if (filtersVisible || !this.hoverReady) {
      return;
    }
    toggleFiltersPanel({ tableCacheKey });
  };

  /**
   * Generic getOnChange, this means that the control handles all normalization of the expected value
   */
  getOnChangeValue = _.memoize((key) => {
    return (value) => {
      if (_.isArray(value)) {
        this.setFilters({
          [key]: _.isEmpty(value) ? undefined : value,
        });
      } else {
        this.setFilters({
          [key]: value || undefined,
        });
      }
    };
  });

  /**
   * Helper function passed down to all implemented panels
   * Get a memoized onChange callback with the key of of the filter value embed in the onChange callback
   *
   * Reset to UNDEFINED if no value (resting state)
   *
   * @type {function}
   * @returns {function}
   */
  getOnChangeText = _.memoize((key) => {
    return (evtOrValue) => {
      if (!_.isUndefined(_.get(evtOrValue, 'target.value'))) {
        this.setFilters({
          [key]: evtOrValue.target.value || undefined,
        });
      }
    };
  });

  /**
   * Helper function for dealing with mutli selects, passed down to get a single memoized onChange handler
   * for multi select components
   *
   * RESET to UNDEFINED for empty array
   * @type {memoized}
   */
  getOnChangeMultiSelect = _.memoize((key) => {
    return (opts) => {
      if (_.isEmpty(opts)) {
        this.setFilters({
          [key]: undefined,
        });
      } else if (_.isArray(opts)) {
        this.setFilters({
          [key]: opts.map((opt) => opt.value),
        });
      }
    };
  });

  getOnChangeSelect = _.memoize((key) => {
    return (opt) => {
      if (!opt && opt !== false) {
        this.setFilters({
          [key]: undefined,
        });
      } else {
        this.setFilters({
          [key]: opt.value,
        });
      }
    };
  });

  render() {
    const {
      classes,
      title,
      FiltersPanelContentComponent,
      filtersVisible,
      filtersVariables,
      hasAnyFilterSet,
      queryVariables,
    } = this.props;
    const { tmpFilters, isMounted } = this.state;

    return (
      <div
        onMouseEnter={
          !filtersVisible && SIDE_HOVER_ENABLED ? this.onClosedHover : undefined
        }
        className={cx(
          classes.drawer,
          isMounted && filtersVisible && classes.drawerOpen
        )}
        ref={this.drawerRef}
      >
        <div className={classes.container}>
          <div className={classes.topContainer}>
            <IconButton onClick={this.onClose} size="small">
              <BackIcon />
            </IconButton>
            <Typography variant="h3" className={classes.title}>
              {title}
            </Typography>
            {hasAnyFilterSet && (
              <Button onClick={this.resetFilters} size="small">
                Clear All
              </Button>
            )}
          </div>
          <div className={classes.body}>
            <FiltersPanelContentComponent
              filters={this.mergeFilters(filtersVariables, tmpFilters)}
              queryVariables={queryVariables}
              setFilters={this.setFilters}
              getOnChangeText={this.getOnChangeText}
              getOnChangeMultiSelect={this.getOnChangeMultiSelect}
              getOnChangeSelect={this.getOnChangeSelect}
              getOnChangeValue={this.getOnChangeValue}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(FiltersPanel);
