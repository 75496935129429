import {
  AdHocEsQueryFilter,
  AdHocEsQueryFilterValue,
} from './adHocEsQueryFilter';
import {
  AttributeValueCollectorQueryFilter,
  AttributeValueCollectorQueryFilterValue,
} from './attributeValueCollectorQueryFilter';
import {
  DatetimeQueryFilter,
  DatetimeQueryFilterValue,
} from './datetimeQueryFilter';
import { GroupQueryFilter, GroupQueryFilterValue } from './groupQueryFilter';
import {
  KeywordsQueryFilter,
  KeywordsQueryFilterValue,
} from './keywordsQueryFilter';
import {
  MultiselectQueryFilter,
  MultiselectQueryFilterValue,
} from './multiselectQueryFilter';
import { NestedQueryFilter, NestedQueryFilterValue } from './nestedQueryFilter';
import {
  ParentChildMultiselectQueryFilter,
  ParentChildMultiselectQueryFilterValue,
} from './parentChildMultiselectQueryFilter';
import {
  TypeToFindMultiselectQueryFilter,
  TypeToFindMultiselectQueryFilterValue,
} from './typeToFindMultiselectQueryFilter';
import {
  ValueRangeQueryFilter,
  ValueRangeQueryFilterValue,
} from './valueRangeQueryFilter';

export enum QueryFilterTypes {
  unset = 0,
  datetime = 'datetime',
  adHocEs = 'adHocEs',
  attributeValueCollector = 'attributeValueCollector',
  group = 'group',
  keywords = 'keywords',
  multiselect = 'multiselect',
  nested = 'nested',
  parentChildMultiselect = 'parentChildMultiselect',
  typeToFindMultiselect = 'typeToFindMultiselect',
  valueRange = 'valueRange',
}

export type QueryFilterClasses =
  | AdHocEsQueryFilter
  | AttributeValueCollectorQueryFilter
  | DatetimeQueryFilter
  | GroupQueryFilter
  | KeywordsQueryFilter
  | MultiselectQueryFilter
  | NestedQueryFilter
  | ParentChildMultiselectQueryFilter
  | TypeToFindMultiselectQueryFilter
  | ValueRangeQueryFilter;

export type QueryFilterClassValues = AdHocEsQueryFilterValue &
  AttributeValueCollectorQueryFilterValue &
  DatetimeQueryFilterValue &
  GroupQueryFilterValue &
  KeywordsQueryFilterValue &
  MultiselectQueryFilterValue &
  NestedQueryFilterValue &
  ParentChildMultiselectQueryFilterValue &
  TypeToFindMultiselectQueryFilterValue &
  ValueRangeQueryFilterValue;

export type MultiselectValueType = string | number;
export type MultiselectOption = {
  label: string;
  value: MultiselectValueType;
  description?: string;
};
export enum ListOperator {
  unset = 0,
  and = 'and',
  or = 'or',
}
export const defaultKeywordsListOperator = ListOperator.or;

export enum MultiselectIncludeModes {
  // matches if record has property a, b, or c
  any = 'includesAny',
  // matches if record has property a, b, and c
  all = 'includesAll',
}

export enum MultiselectExcludeModes {
  // matches if record does not contain a, b, or c
  any = 'excludesAny',
  // matches if record does not contain a, b, and c
  all = 'excludesAll',
}

// Top level keys that may exist in our full our partial EsQueries
// TODO continue to expand this
type EsTopLevelKeys = 'match_all' | 'bool' | 'should' | 'must';
export type EsQuery = Partial<Record<EsTopLevelKeys, any>>;
export type QFValues = Partial<Record<string, QueryFilterClassValues>>;
