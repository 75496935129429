import React from 'react';
import _ from 'lodash';
import container, { ContainerProps } from './container';
import { IntegratedSelect } from '@shield-ui/controls';
import { getOptionLabel } from '../AnalysisNameSelect/AnalysisNameSelect';

interface Props extends ContainerProps {
  value: string;
  placeholder?: string;
  onChange: (evt) => void;
  isCreatable?: boolean;
}

const namesToSuggestions = _.memoize((names = []) =>
  names.map((name) => ({ value: name, label: name }))
);

class MetricNameSelect extends React.Component<Props> {
  componentDidMount() {
    this.props.ensureAllMetricNames();
  }

  render() {
    const {
      value,
      placeholder,
      onChange,
      metricNames,
      isCreatable = true,
    } = this.props;

    const suggestions = namesToSuggestions(metricNames);
    const isLoading = !suggestions.length;

    if (isCreatable && value && !metricNames.includes(value)) {
      suggestions.push({
        value: value,
        label: value,
      });
    }

    return (
      <IntegratedSelect
        value={value}
        isMulti={false}
        isCreatable={isCreatable}
        placeholder={isLoading ? 'Loading Options...' : placeholder}
        disabled={isLoading}
        suggestions={suggestions}
        onChange={onChange}
        getOptionLabel={getOptionLabel}
      />
    );
  }
}

export default container(MetricNameSelect);
