import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  getContextForRobotLogId,
  deleteEvent,
  refreshResultsForRobotLog,
  getDisplayRecordsForLogEvents,
  selectFilters,
  setFilters,
} from '../../../redux/eventManager.slice';

function mapStateToProps(state, ownProps) {
  const { robotLogId } = ownProps;

  return {
    ...getContextForRobotLogId(state, robotLogId),
    logEventDisplayRecords: getDisplayRecordsForLogEvents(state, robotLogId),
    filters: selectFilters(state),
    currentUser: state.authUser.currentUser,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      refreshResultsForRobotLog,
      deleteEvent,
      setFilters,
    },
    dispatch
  );
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ContainerProps = ConnectedProps<typeof connector>;
export default connector;
