import { MultiselectQueryFilter } from '@shield-ui/query-filters';

export default new MultiselectQueryFilter({
  id: 'softwareInfoCommitHashes',
  controlLabel: 'EdgeAI Commits',
  esField: 'software_info.commit_hash',
  placeholder:
    'Enter a full commit hash e.g. "ee38f7515c8f7adee2a115f2558aaf9ce8b9047c"',
  behaviorConfig: {
    isCreatable: true,
  },
});
