import React from 'react';
import ReactJson from 'react-json-view';

export default function JsonViewer({
  src,
  style = {},
}: {
  src: object;
  style?: object;
}) {
  return (
    <ReactJson
      // way too much fancy shit going on
      // sortKeys sorting arrays by lexical order, clipping strings making it hard to copy and paste
      sortKeys={false}
      displayObjectSize={false}
      displayDataTypes={false}
      collapseStringsAfterLength={1000}
      collapsed={3}
      enableClipboard={false}
      groupArraysAfterLength={100}
      style={{
        background: 'rgba(0, 0, 0, 0.1)',
        fontSize: 12,
        overflowY: 'scroll',
        whiteSpace: 'break-spaces', // preserve new lines in display for string values
        padding: 8,
        ...style,
      }}
      name="data"
      theme="solarized"
      src={src}
    />
  );
}
