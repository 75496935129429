import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { loadSaveState } from '../../../../../redux/tables/actions';

function mapStateToProps(state, ownProps) {
  const { tableCacheKey } = ownProps;

  const saveState = _.get(state, [
    'tables',
    'cache',
    tableCacheKey,
    'pendingSaveState',
  ]);
  return {
    saveState,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadSaveState,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps);
