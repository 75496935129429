import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from "@shield-ui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: theme.spacing(3),
  },
  header: {
    color: colors.hues.grays[80],
    textTransform: 'uppercase',
    margin: 0,
    fontSize: 11,
    letterSpacing: '0.5px',
  },
  content: {
    padding: theme.spacing(0.5, 0, 0, 1),
  }
}))

const PreviewField: React.FC<{label:string, show?:boolean}> = ({label, show= true, children}) => {
  const c = useStyles();

  if (!show) {
    return null;
  }

  return (
    <div className={c.container}>
      <div className={c.header}>{label}</div>
      <div className={c.content}>
        {children}
      </div>
    </div>
  );
}

export default PreviewField;
