import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Inner, { Props as InnerProps } from './Inner';
import { getSessionLogTypes } from '../../services/sessionLogTypes';
import { getDefaultColumnsForSessionLogType } from '../../components/tables/robotLogs2/columns';

type RouteParams = {
  sessionLogTypeSlug: string;
};

type Props = RouteComponentProps<RouteParams>;

interface State extends InnerProps {
  errorMessage?: string;
}

const emptyState: State = {
  display: '',
  cacheKey: '',
  sessionLogTypeSlug: undefined,
  accentColor: undefined,
  errorMessage: undefined,
  defaultColumns: undefined,
};

export default class Page extends React.Component<Props, State> {
  state = {
    ...emptyState,
  };

  componentDidUpdate(prevProps: Readonly<Props>) {
    const prev = prevProps.match.params.sessionLogTypeSlug;
    const next = this.props.match.params.sessionLogTypeSlug;

    if (next && next !== prev) {
      this.refresh(next);
    }
  }

  componentDidMount() {
    this.refresh(this.props.match.params.sessionLogTypeSlug);
  }

  async refresh(sessionLogTypeSlug: string) {
    try {
      const sessionLogTypes = await getSessionLogTypes();

      let match;
      sessionLogTypes.forEach((slt) => {
        if (slt.slug === sessionLogTypeSlug) {
          match = slt;
        }
      });

      if (!match) {
        return this.setState({
          ...emptyState,
          errorMessage: `Invalid sessionLogType ${sessionLogTypeSlug}`,
        });
      }

      this.setState({
        sessionLogTypeSlug,
        display: match.name,
        defaultColumns: getDefaultColumnsForSessionLogType(match.slug),
        accentColor: match.hexColor,
        cacheKey: sessionLogTypeSlug,
        errorMessage: undefined,
      });
    } catch (e) {
      if (e) {
        this.setState({ errorMessage: e.toString() });
      }
    }
  }

  render() {
    const { errorMessage, ...rest } = this.state;
    if (errorMessage) {
      return <div>{errorMessage}</div>;
    }

    if (!rest.sessionLogTypeSlug) {
      return null;
    }

    return <Inner {...rest} />;
  }
}
