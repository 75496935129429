import {
  SessionLogType,
  SessionLogTypeService,
  CreateRequest,
  CreateOrUpdateRequest,
  DeleteRequest,
  GetRequest,
  ListRequest,
} from '@hmd/sdk/api/session_log/session_log_type/v1';
import sdkClient from '../lib/hmdsdkClient';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { FieldMask } from 'google-protobuf/google/protobuf/field_mask_pb';
import { SessionLogService } from '@hmd/sdk/api/session_log/v1';
import { queryClient } from '../queryClient';
import _ from 'lodash';
const SESSION_LOG_TYPES_QUERY_KEY = 'sessionLogTypes';

export const SYSTEM_DEFAULT_SESSION_LOG_TYPES = ['flight', 'trial', 'test'];

export const fetchSessionLogTypes = async () => {
  const req = new ListRequest();
  req.setPageSize(200);
  const response = await sdkClient.query(SessionLogTypeService.List, req);
  return response.toObject().sessionLogTypesList;
};

function getFetchSessionLogTypesQueryClientParams() {
  return {
    queryKey: [SESSION_LOG_TYPES_QUERY_KEY],
    queryFn: () => fetchSessionLogTypes(),
  };
}

// hook for getting all SessionLog types
export const useSessionLogTypes = () =>
  useQuery(getFetchSessionLogTypesQueryClientParams());

// promise for working with sessionLog Types
export const getSessionLogTypes = () =>
  queryClient.fetchQuery(getFetchSessionLogTypesQueryClientParams());

//--------HEXCOLORS-------
const SESSION_LOG_TYPE_COLORS = 'sessionLogTypeColors';

function fetchSessionLogTypeColors() {
  return {
    queryKey: [SESSION_LOG_TYPE_COLORS],
    queryFn: async () => {
      const res = await getSessionLogTypes();
      const sessionLogTypeColors = res.reduce((acc, sessionLogType) => {
        acc[sessionLogType.slug] = sessionLogType.hexColor;
        return acc;
      }, {});
      return sessionLogTypeColors;
    },
  };
}

//Hook for sessionLog types colors
export const useHexColor = () => useQuery(fetchSessionLogTypeColors());

//Promise for sessionLog types colors
export const getHexColor = () =>
  queryClient.fetchQuery(fetchSessionLogTypeColors());

//--------NAMES-------
const SESSION_LOG_TYPE_NAMES = 'sessionLogTypeNames';

function fetchSessionLogTypeNames() {
  return {
    queryKey: [SESSION_LOG_TYPE_NAMES],
    queryFn: async () => {
      const res = await getSessionLogTypes();
      const sessionLogTypeNames = res.reduce((acc, sessionLogType) => {
        acc[sessionLogType.slug] = sessionLogType.name;
        return acc;
      }, {});
      return sessionLogTypeNames;
    },
  };
}

//Hook for sessionLog type names
export const useSessionLogTypeNames = () =>
  useQuery(fetchSessionLogTypeNames());

//Promise for sessionLog type names
export const getSessionLogTypeNames = () =>
  queryClient.fetchQuery(fetchSessionLogTypeNames());

//--------OPTIONS-------
const SESSION_LOG_TYPES_OPTIONS = 'sessionLogTypesOptions';

function fetchSessionLogTypeOptions() {
  return {
    queryKey: [SESSION_LOG_TYPES_OPTIONS],
    queryFn: async () => {
      const res = await getSessionLogTypes();
      const opts = res.map((val) => {
        return {
          label: val.name,
          value: val.slug,
        };
      });
      return opts.sort((a, b) => a.label.localeCompare(b.label));
    },
  };
}

//hook for sessionLog type options
export const useSessionLogTypeOptions = () =>
  useQuery(fetchSessionLogTypeOptions());

//promise for sessionLog type options
export const getSessionLogTypeOptions = () =>
  queryClient.fetchQuery(fetchSessionLogTypeOptions());

type NewSessionLogType = {
  id?: string;
  slug?: string;
  name: string;
  description?: string;
  hexColor: string;
};

export const createSessionLogTypeMutation = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      slug,
      name,
      description,
      hexColor,
    }: NewSessionLogType) => {
      const req = new CreateRequest();
      const sessionLogType = new SessionLogType();
      sessionLogType.setSlug(slug);
      sessionLogType.setName(name);
      sessionLogType.setDescription(description);
      sessionLogType.setHexColor(hexColor);
      req.setSessionLogType(sessionLogType);
      const response = await sdkClient.unary(SessionLogTypeService.Create, req);
      return response;
    },
    onError: (error) => {
      return error;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [SESSION_LOG_TYPES_QUERY_KEY],
      });
    },
  });
  return mutation;
};

export const createOrUpdateSessionLogTypeMutation = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      id,
      name,
      description,
      hexColor,
    }: NewSessionLogType) => {
      const req = new CreateOrUpdateRequest();
      const sessionLogType = new SessionLogType();
      sessionLogType.setId(id);
      sessionLogType.setName(name);
      sessionLogType.setDescription(description);
      sessionLogType.setHexColor(hexColor);
      req.setSessionLogType(sessionLogType);

      const mask = new FieldMask();
      mask.setPathsList(['Name', 'Description', 'HexColor']);
      req.setUpdateMask(mask);

      const response = await sdkClient.unary(
        SessionLogTypeService.CreateOrUpdate,
        req
      );
      return response;
    },
    onError: (error) => {
      return error;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [SESSION_LOG_TYPES_QUERY_KEY],
      });
    },
  });
  return mutation;
};

export const deleteSessionLogTypeMutation = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({ id }: NewSessionLogType) => {
      const req = new DeleteRequest();
      req.setId(id);
      const response = await sdkClient.unary(SessionLogTypeService.Delete, req);
      return response;
    },
    onError: (error) => {
      return error;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [SESSION_LOG_TYPES_QUERY_KEY],
      });
    },
  });
  return mutation;
};

export const getSessionLogTypeBySlug = (slug: string) => {
  const req = new GetRequest();
  req.setSlug(slug);
  return sdkClient.unary(SessionLogService.Get, req);
};
