import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

export default function LoadingIndicator({ message = 'Loading' }) {
  return (
    <div>
      <LinearProgress variant="indeterminate" color="primary" />
      <Typography variant="body2" align="center">
        {message}
      </Typography>
    </div>
  );
}
