import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import withStyles from '@mui/styles/withStyles';
import { severityColors } from '../../../../../lib/sharedStyles';

function styles() {
  return _.reduce(
    severityColors,
    (acc, value, key) => {
      acc[key] = { borderColor: value };
      return acc;
    },
    {
      row: {
        borderLeft: `4px solid #bbb`,
        marginBottom: 7,
        paddingLeft: 8,
      },
    }
  );
}

const SEVERITY_SORT_MAP = {
  CRITICAL: 1,
  MAJOR: 10,
  MODERATE: 20,
  MINOR: 30,
};

export function getParsedEvents(row) {
  let eventTags = _.get(row, 'events.edges', []).map((edge) => {
    return {
      tagName: _.get(edge, 'node.tag.name'),
      severityName: _.get(edge, 'node.tag.severity.name'),
    };
  });

  eventTags = _.sortBy(eventTags, ({ severityName }) => {
    return SEVERITY_SORT_MAP[severityName] || 99;
  });

  return eventTags;
}

function EventTagsCell({ row, classes }) {
  const eventTags = getParsedEvents(row);

  return eventTags.map(({ tagName, severityName }, i) => (
    <div key={i} className={classNames(classes.row, classes[severityName])}>
      {tagName}
    </div>
  ));
}

export default withStyles(styles)(EventTagsCell);
