import sdkClient from '../../../../lib/hmdsdkClient';
import * as google_protobuf_struct_pb from 'google-protobuf/google/protobuf/struct_pb';
import {
  RobotLogDocumentService,
  PassthruSearchRequest,
} from '@hmd/sdk/api/search/robotlogdocument/v1';

const configResponse = (
  response,
  agg_names,
  groupByTime,
  graphType,
  secondBreakdown
) => {
  //easy access hashmap where metric_name:{x:[time array], y:[values]} for plotly Data later
  const apiData = {};
  //if there's a second breakdown, then response will be mapped differently
  secondBreakdown
    ? response.aggregations[groupByTime].buckets.forEach((timeBucket) => {
        timeBucket[secondBreakdown].buckets.forEach((breakdownBucket) => {
          agg_names.forEach((metric_name) => {
            //stats agg
            if (
              breakdownBucket[metric_name].value !== undefined &&
              breakdownBucket[metric_name].value !== null
            ) {
              if (apiData[`${breakdownBucket.key}.${metric_name}`]) {
                apiData[`${breakdownBucket.key}.${metric_name}`]['xAxis'].push(
                  timeBucket.key_as_string
                );
                apiData[`${breakdownBucket.key}.${metric_name}`]['yAxis'].push(
                  breakdownBucket[metric_name].value
                );
              } else {
                apiData[`${breakdownBucket.key}.${metric_name}`] = {
                  xAxis: [timeBucket.key_as_string],
                  yAxis: [breakdownBucket[metric_name].value],
                };
              }
            } //percentiles agg
            else if (breakdownBucket[metric_name].values !== undefined) {
              const percent = metric_name.split('.')[0] + '.0';
              if (breakdownBucket[metric_name].values[percent] !== null) {
                if (apiData[`${breakdownBucket.key}.${metric_name}`]) {
                  apiData[`${breakdownBucket.key}.${metric_name}`][
                    'xAxis'
                  ].push(timeBucket.key_as_string);
                  apiData[`${breakdownBucket.key}.${metric_name}`][
                    'yAxis'
                  ].push(breakdownBucket[metric_name].values[percent]);
                } else {
                  apiData[`${breakdownBucket.key}.${metric_name}`] = {
                    xAxis: [timeBucket.key_as_string],
                    yAxis: [breakdownBucket[metric_name].values[percent]],
                  };
                }
              }
            }
          });
        });
      })
    : response.aggregations[groupByTime].buckets.forEach((bucket) => {
        agg_names.forEach((metric_name) => {
          //sum avg min max aggs
          if (
            bucket[metric_name].value !== undefined &&
            bucket[metric_name].value !== null
          ) {
            if (apiData[metric_name]) {
              apiData[metric_name]['xAxis'].push(bucket.key_as_string);
              apiData[metric_name]['yAxis'].push(bucket[metric_name].value);
            } else {
              apiData[metric_name] = {
                xAxis: [bucket.key_as_string],
                yAxis: [bucket[metric_name].value],
              };
            }
            //percentile aggs
          } else if (bucket[metric_name].values !== undefined) {
            const percent = metric_name.split('.')[0] + '.0';
            if (bucket[metric_name].values[percent] !== null) {
              if (apiData[metric_name]) {
                apiData[metric_name]['xAxis'].push(bucket.key_as_string);
                apiData[metric_name]['yAxis'].push(
                  bucket[metric_name].values[percent]
                );
              } else {
                apiData[metric_name] = {
                  xAxis: [bucket.key_as_string],
                  yAxis: [bucket[metric_name].values[percent]],
                };
              }
            }
          }
        });
      });
  //create plotly configs from apiData hashmap
  return createPlotlyConfigs(graphType, apiData);
};

const createPlotlyConfigs = (graphType, apiData) => {
  const plotlyData = [];
  for (const key in apiData) {
    const name = key.replace('metrics.', '').replace('analyses.', '');
    plotlyData.push({
      x: apiData[key]['xAxis'],
      y: apiData[key]['yAxis'],
      name: name,
      showlegend: true,
      hovertemplate:
        `<i>${name}</i><extra></extra>` +
        `<br><b>X</b>: %{x}<br>` +
        `<b>Y</b>: %{y}`,
      ...(graphType === 'line' && { type: 'scatter', mode: 'lines+markers' }),
      ...(graphType === 'scatter' && { type: 'scatter', mode: 'markers' }),
      ...(graphType === 'bar' && { type: 'bar' }),
      ...(graphType === 'area' && { stackgroup: 'one' }),
    });
  }
  return plotlyData;
};

export default function fetchData(query, plotConfig): Promise<object[]> {
  const { aggType, graphType, groupByTime, secondBreakdown } =
    plotConfig.plotOptions;
  const metric_names = plotConfig.plotOptions.metricAnalysis.map(
    (item) => item.value
  );

  const metric_aggs = {};
  const agg_names = [];
  for (const metric_name of metric_names) {
    for (const agg of aggType) {
      if (!isNaN(agg)) {
        //if agg can be converted to a number, it is a percentile
        metric_aggs[`${agg}.${metric_name}`] = {
          percentiles: {
            field: metric_name,
            percents: [parseInt(agg)],
          },
        };
      } else {
        metric_aggs[`${agg}.${metric_name}`] = {
          [agg]: {
            field: metric_name,
          },
        };
      }
      agg_names.push(`${agg}.${metric_name}`);
    }
  }
  //if secondBreakdown option, then set accordingly
  const combinedAgg = secondBreakdown
    ? {
        [groupByTime]: {
          date_histogram: {
            field: 'created_at',
            calendar_interval: groupByTime,
          },
          aggs: {
            [`${secondBreakdown}`]: {
              terms: {
                field:
                  secondBreakdown === 'software_info.branch_name'
                    ? secondBreakdown
                    : `${secondBreakdown}.keyword`,
              },
              aggs: metric_aggs,
            },
          },
        },
      }
    : //else just create metrics agg
      {
        [groupByTime]: {
          date_histogram: {
            field: 'created_at',
            calendar_interval: groupByTime,
          },
          aggs: metric_aggs,
        },
      };

  const req = new PassthruSearchRequest();
  const payload = {
    query,
    aggs: combinedAgg,
  };

  const value = google_protobuf_struct_pb.Struct.fromJavaScript(payload);
  req.setBody(value);
  return sdkClient
    .unary(RobotLogDocumentService.PassthruSearch, req)
    .then((res) => {
      const response = res.getResponse().toJavaScript();

      //get response and create easy access hashmap
      const data = configResponse(
        response,
        agg_names,
        groupByTime,
        graphType,
        secondBreakdown
      );
      return data as object[];
    })
    .catch
    //(e) => console.error(e);
    //throw e;
    ();
}
