import React from 'react';
import cx from 'classnames';
import { useRouteMatch, Link } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { SIDEBAR_WIDTH } from './common';
import { colors } from '@shield-ui/styles';
import SubMenuItem, { SubMenuItemProps, SUB_MENU_HEIGHT } from "./SubMenuItem";

const NAV_ITEM_HEIGHT = 68;
const NAV_ITEM_BG_SPACE = 3;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: NAV_ITEM_BG_SPACE,
    alignItems: 'center',
    width: SIDEBAR_WIDTH - NAV_ITEM_BG_SPACE,
    height: NAV_ITEM_HEIGHT,
    margin: 0,
    color: colors.hues.grays[60],
    transition: '0.1s ease',
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
  containerActive: {
    color: theme.palette.text.primary,
    backgroundImage: `linear-gradient(to right, ${theme.palette.primary.light} 4%, ${colors.hues.grays[180]} 3.33%)`,
  },
  iconActive: {
    color: theme.palette.primary.light,
  },
  text: {
    marginTop: 4,
    fontSize: 10.25,
    color: 'inherit',
    textAlign: 'center',
  },
  textActive: {
    color: '#fff',
  },
  link: {
    textDecoration: 'none',
  },
  subMenuTarget: {
    '&:hover > $subMenu': {
      display: 'flex',
    },
    position: 'relative',
  },
  subMenu: {
    display: 'none',
    zIndex: 10000,
    flexDirection: 'column',
    position: 'absolute',
    minWidth: 140,
    left: SIDEBAR_WIDTH - 16,
    top: 0, // calculated and overloaded
    padding: theme.spacing(3, 4, 3, 1),
  }
}));


interface Props {
  Icon: React.ReactNode;
  label: string;
  onClick?: () => void;
  to?: string;
  href?: string;
  subMenu?: SubMenuItemProps[]
  subMenuStyle?: object;
}


function NavItem(props:Props) {
  const {
    Icon,
    label,
    onClick,
    to,
    href,
    subMenu,
    subMenuStyle,
  } = props;
  const classes = useStyles();

  const allTos = [];
  if (to) {
    allTos.push(to);
  }
  if (subMenu) {
    subMenu.forEach((sub) => {
      if (sub.to) {
        allTos.push(sub.to);
      }
    })
  }

  const isMatch = useRouteMatch(allTos);

  let content = (
    <div
      onClick={onClick}
      className={cx(classes.container, isMatch && classes.containerActive)}
    >
      <div className={cx(isMatch && classes.iconActive)}>{Icon}</div>
      <div className={cx(classes.text, isMatch && classes.textActive)}>
        {label}
      </div>
    </div>
  );

  const commonProps = {
    className: classes.link,
  };

  if (to) {
    content = (
      <Link to={to} {...commonProps}>
        {content}
      </Link>
    );
  } else if (href) {
    content = (
      <a href={href} {...commonProps}>
        {content}
      </a>
    );
  }

  if (subMenu) {
    const top = ((NAV_ITEM_HEIGHT - (subMenu.length * SUB_MENU_HEIGHT)) / 2) - 20;
    return (
      <div className={classes.subMenuTarget}>
        {content}

        <div className={classes.subMenu} style={subMenuStyle || {top}}>
          {subMenu.map((sub, i) => {
            return <SubMenuItem key={i} {...sub} />
          })}
        </div>
      </div>
    );
  } else {
    return content;
  }
}

export default NavItem;
