import React from 'react';
import { Button } from 'antd';
import { Dialog } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons';
import { colors } from '@shield-ui/styles';

interface Props {
  fullTitle?: string;
  control: React.ReactNode;
  full: React.ReactNode;
  fullDisabled?: boolean;
}

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
  },
  actionPosition: {
    position: 'absolute',
    right: -36,
    top: 0,
  },
  fullContainer: {
    padding: theme.spacing(1),
  },
  fullTopBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  fullTitle: {
    fontSize: 17,
    letterSpacing: '0.2px',
    fontWeight: 500,
    color: colors.hues.grays[40],
  },
}));

export default function OrganismExpander({ control, full, fullTitle, fullDisabled }: Props) {
  const [state, setState] = React.useState({
    isExpanded: false,
    hasBeenExpanded: false,
  });
  const classes = useStyles();

  const show = () => setState({ isExpanded: true, hasBeenExpanded: true });
  const hide = () => setState({ isExpanded: false, hasBeenExpanded: true });

  return (
    <div className={classes.container}>
      {control}
      <div className={classes.actionPosition}>
        <Button
          disabled={fullDisabled}
          onClick={show}
          icon={<FullscreenOutlined />}
        />
      </div>

      <Dialog
        onClose={hide}
        open={state.isExpanded}
        fullWidth
        maxWidth="lg"
      >
        <div className={classes.fullContainer}>
          <div className={classes.fullTopBar}>
            <div className={classes.fullTitle}>{fullTitle}</div>
            <Button onClick={hide} icon={<FullscreenExitOutlined />} />
          </div>
          {state.hasBeenExpanded && full}
        </div>
      </Dialog>
    </div>
  );
}
