import { gql } from '@apollo/client';
import { runQuery } from '../apollo-client';

const pipelineExecutionFragment = `
  id
  name
  description
  data
  createdAt
`;

type GetVariables = {
  id: string;
};

export function getPipelineExecution(variables: GetVariables, callback) {
  runQuery({
    variables,
    query: gql`
      query($id:String!){
        pipelineExecution(id:$id) {
          ${pipelineExecutionFragment}
        }
      }
    `,
    callback,
  });
}
