export enum TagTypes {
  General = 'General',
  Component = 'Component',
  Equipment = 'Equipment',
  Environmental = 'Environmental',
}

export enum OwnerTypes {
  Users = 'Users',
  Teams = 'Teams',
}

export enum DateTypes {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
}
