import { KeywordsQueryFilter } from '@shield-ui/query-filters';
import { robotLogDocumentFields } from './fields';
import sdkClient from '../../../lib/hmdsdkClient';
import {
  RobotLogDocumentService,
  ValidateQueryRequest,
} from '@hmd/sdk/api/search/robotlogdocument/v1';

import { Struct } from 'google-protobuf/google/protobuf/struct_pb';
import { showError } from '../../../lib/messages';
/**
 * We are going to be very explicit
 * @param matchRegex - RegExp
 *
 [
 "notes",
 "artifacts.file_name",
 "children.name",
 "collections.name",
 "environments.name",
 "events.cause.name",
 "events.tag.name",
 "events.tickets.name",
 "labels.name",
 "name",
 "parents.name",
 "participant_observers.name",
 "participant_pilots.name",
 "phone_software_info.version",
 "phones.name",
 "phones.serial_number",
 "robot_log_test.code",
 "robot_log_test.name",
 "robot_log_test.test_code",
 "robots.hostname",
 "robots.name",
 "software_info.branch_name",
 "software_info.version"
 ]
*/
function getSubsetMatchingKeys(matchRegex) {
  return Object.values(robotLogDocumentFields).reduce(
    (acc, { field, type }) => {
      // blacklist
      if (field.startsWith('metrics') || field.startsWith('analyses')) {
        return acc;
      }

      if (field.match(matchRegex)) {
        acc.push(field);
      }
      return acc;
    },
    []
  );
}

function defaultValidateInput(
  query: object
): Promise<{ isValid: boolean; message: string }> {
  const req = new ValidateQueryRequest();
  const jsonString = JSON.stringify(query);
  const obj = JSON.parse(jsonString);
  req.setQuery(Struct.fromJavaScript(obj));
  return sdkClient
    .unary(RobotLogDocumentService.ValidateQuery, req)
    .then((r) => {
      return { isValid: r.getIsValid(), message: r.getExplaination() };
    })
    .catch((e) => {
      showError(e);
      return { isValid: false, message: 'error calling validateQuery' };
    });
}

export default new KeywordsQueryFilter({
  id: 'keywords',
  controlLabel: 'Keyword Search',
  placeholder: 'Keyword Search',
  esFields: {
    /**
     * using wildcards that don't resolve to text fields is bad,
     * in our case a lot of matches were coming up in analyses which are floats/longs
     * "*number"
     */
    searchFields: [
      ...getSubsetMatchingKeys(/(name|version|code|number)$/),
      robotLogDocumentFields.notes.field,
    ],
  },
  validateQuery: defaultValidateInput,
});
