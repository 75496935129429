/* eslint-disable no-loop-func */
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { prettifyString } from '@shield-ui/utils';
import sdkClient from '../lib/hmdsdkClient';

import {
  ArtifactTypeService,
  ListRequest,
} from '@hmd/sdk/api/artifacts/type/v1';
import {
  BatchGetRequest,
  ArtifactService,
  SearchRequest as ArtifactSearchRequest,
  FileState,
  DeleteRequest,
  SearchRequestFilters as ArtifactSearchRequestFilters,
  DateRange,
  FileStateMap,
  Artifact,
  UpdateRequest as ArtifactUpdateRequest,
} from '@hmd/sdk/api/artifacts/v1';
import {
  User,
  UserService,
  GetRequest as GetUserRequest,
} from '@hmd/sdk/api/iam/users/v1';
import {
  ArtifactLink,
  ArtifactLinkService,
  ArtifactLinkView,
  SearchRequest as SearchArtifactLinksRequest,
  SearchRequestFilters,
} from '@hmd/sdk/api/session_log/artifact_link/v1';
import {
  SessionLogService,
  GetRequest as GetSessionRequest,
  BatchGetRequest as BatchGetSessionRequest,
  BatchGetResponse as BatchGetSessionResponse,
} from '@hmd/sdk/api/session_log/v1';
import hmdsdkClient from '../lib/hmdsdkClient';
import { UploadArtifactType } from '../routes/UploadFlights/common';
import { onProgressType } from '@hmd/sdk/helpers/artifacts';
import { queryClient } from '../queryClient';
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';
import { Struct } from 'google-protobuf/google/protobuf/struct_pb';
import { FieldMask } from 'google-protobuf/google/protobuf/field_mask_pb';

export function isPhoneArtifact(artifactTypeSlug) {
  return artifactTypeSlug && artifactTypeSlug.indexOf('phone-') === 0;
}

const DISPLAY_OVERRIDES = {
  'rosbag-original': 'Rosbag', // raw uploaded, prior to any processing
  rosbag: 'Rosbag (Re-indexed)',
  'video-primary': 'Video',
  file: 'Generic File',
  coredump: 'Core Dump',
  'plotly-json': 'Plotly Plot',
  'autopilot-log-tlv': 'VBAT Autopilot Log',
  'telecho-raw-log-csv': 'GCS Telemetry Logs',
  'trillium-payload-video': 'Trillium Payload Video',
  'planck-ace-log-archive': 'Planck Ace Logs',
};

export function getArtifactTypeDisplay(artifactType) {
  if (!artifactType) {
    return;
  }
  const override = DISPLAY_OVERRIDES[artifactType];
  if (override) {
    return override;
  }

  return prettifyString(artifactType);
}

export const SESSION_LOG_ARTIFACTS_QUERY_KEY = 'sessionLogArtifacts';
const ARTIFACT_CONTENTS_QUERY_KEY = 'artifactContents';
const ARTIFACT_TYPES_QUERY_KEY = 'artifactTypes';
const USER_QUERY_KEY = 'user';

export const useArtifactTypes = () =>
  useQuery({
    queryKey: [ARTIFACT_TYPES_QUERY_KEY],
    queryFn: () => fetchArtifactTypes(),
  });

// Keep the params dry between the two places that use this
// so cache is clean
function getSessionLogAndArtifactQueryClientParams(sessionLogId: string) {
  return {
    queryKey: [SESSION_LOG_ARTIFACTS_QUERY_KEY, sessionLogId],
    queryFn: () => fetchSessionLogAndArtifacts(sessionLogId),
  };
}

// hook for getting all artifacts for a sessionLog
export const useSessionLogArtifacts = (sessionLogId: string) =>
  useQuery(getSessionLogAndArtifactQueryClientParams(sessionLogId));

// for working with this as a promise
export const getSessionLogAndArtifacts = (sessionLogId: string) =>
  queryClient.fetchQuery(
    getSessionLogAndArtifactQueryClientParams(sessionLogId)
  );

export const useArtifactContents = (artifactId: string) =>
  useQuery({
    queryKey: [ARTIFACT_CONTENTS_QUERY_KEY, artifactId],
    queryFn: () => fetchArtifactContents(artifactId),
  });

export const useUser = (userId: string) =>
  useQuery({
    queryKey: [USER_QUERY_KEY, userId],
    queryFn: () => fetchUser(userId),
  });

interface IArgs {
  sessionLogId: string;
  artifacts: UploadArtifactType[];
  onChange: (artifacts: UploadArtifactType[]) => void;
}

const updateUploadArtifactTypeArray = (
  artifacts: UploadArtifactType[],
  index: number,
  status: UploadArtifactType['meta']['uploadStatus'],
  evt?: ProgressEvent
) => {
  let artifact = {
    ...artifacts[index],
    meta: {
      ...artifacts[index].meta,
      uploadStatus: status,
    },
  };

  if (evt) {
    artifact = {
      ...artifact,
      meta: {
        ...artifact.meta,
        uploadedBytes: evt.loaded.toString(),
        progress: Math.min(99, Math.round((evt.loaded / evt.total) * 100)),
      },
    };
  }

  if (status === 'complete') {
    artifact = {
      ...artifact,
      meta: {
        ...artifact.meta,
        progress: 100,
      },
    };
  }

  return [
    ...artifacts.slice(0, index),
    {
      ...artifact,
    },
    ...artifacts.slice(index + 1),
  ];
};

export const uploadArtifactFile = async (
  sessionLogId: string,
  artifact: UploadArtifactType,
  onProgress: (evt: ProgressEvent) => onProgressType
) => {
  const response = await hmdsdkClient.artifacts.upload(artifact.file, {
    artifactTypeSlug: artifact.artifactTypeSlug,
    sessionLogIdsToLink: [sessionLogId],
    onProgress: onProgress,
  });
  return response;
};

type UploadArtifactArgs = {
  sessionLogId: string;
  artifacts: UploadArtifactType[];
  onChange: (artifacts: UploadArtifactType[]) => void;
};

export const uploadArtifacts = async ({
  sessionLogId,
  artifacts,
  onChange,
}: UploadArtifactArgs) => {
  const results = [];
  let newArtifacts = artifacts;
  // loop through artifacts
  for (const [index, artifact] of artifacts.entries()) {
    try {
      const response = await hmdsdkClient.artifacts.upload(artifact.file, {
        artifactTypeSlug: artifact.artifactTypeSlug,
        sessionLogIdsToLink: [sessionLogId],
        onProgress: (evt: ProgressEvent) => {
          newArtifacts = updateUploadArtifactTypeArray(
            newArtifacts,
            index,
            'uploading',
            evt
          );
          onChange(newArtifacts);
          return {};
        },
      });

      newArtifacts = updateUploadArtifactTypeArray(
        newArtifacts,
        index,
        'complete'
      );

      onChange(newArtifacts);

      const newArtifact = response.toObject();
      const newArtifactLink: ArtifactLink.AsObject = {
        artifact: newArtifact,
        sessionLogId: sessionLogId,
        artifactId: newArtifact.id,
      };

      results.push(newArtifactLink);
    } catch (e) {
      newArtifacts = updateUploadArtifactTypeArray(
        newArtifacts,
        index,
        'error'
      );

      onChange(newArtifacts);

      console.error(e);
    }
  }

  const queryKey = [SESSION_LOG_ARTIFACTS_QUERY_KEY, sessionLogId];
  queryClient.invalidateQueries(queryKey);

  return results;
};

export const useArtifactUploadMutation = () => {
  const mutation = useMutation<ArtifactLink.AsObject[], Error, IArgs>({
    mutationFn: uploadArtifacts,
  });

  return { ...mutation };
};

export const fetchArtifactTypes = async () => {
  const req = new ListRequest();
  req.setPageSize(200);
  const response = await sdkClient.query(ArtifactTypeService.List, req);
  return response.toObject().artifactTypesList;
};

export const fetchUser = async (userIdOrEmail: string) => {
  const req = new GetUserRequest();

  // Get user by either email jon.munoz@shield.ai or uuid 'cd111575-c6a0-41d9-b8e0-8ba924c9c35d'
  // HMDSCloud CreatedBy and UpdatedBy are email addresses and not uuids
  const re =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;
  if (userIdOrEmail.match(re)) {
    req.setId(userIdOrEmail);
  }
  if (userIdOrEmail.includes('@')) {
    req.setEmail(userIdOrEmail);
  }
  if (!userIdOrEmail) {
    const user = new User().toObject();
    return user;
  }
  const response = await sdkClient.query(UserService.Get, req);
  return response.toObject();
};

type fetchSessionLogsArtifactsFilter = {
  slug?: string;
};

const fetchSessionLogArtifacts = async (
  sessionLogIds: string[],
  filter: fetchSessionLogsArtifactsFilter
): Promise<ArtifactLink.AsObject[]> => {
  // Search for the artitfacts attached to SessionLogs - Build query
  const searchReq = new SearchArtifactLinksRequest();
  const filters = new SearchRequestFilters();

  filters.setSessionLogIdsList(sessionLogIds);
  const artifactFilters = new ArtifactSearchRequestFilters();
  artifactFilters.setShowDeleted(false);
  filters.setArtifactFilters(artifactFilters);
  searchReq.setPageSize(200);
  searchReq.setFilters(filters);

  // Search for the artitfacts attached to SessionLogs - Execute query
  const searchResponse = await sdkClient.query(
    ArtifactLinkService.Search,
    searchReq
  );
  const artifactSearchResults = searchResponse.toObject();
  const artifactLinks = artifactSearchResults.artifactLinksList;

  // Search for the artitfacts attached to SessionLogs - Recurse pages
  let potentialNextPageToken = artifactSearchResults.nextPageToken;
  while (potentialNextPageToken) {
    searchReq.setPageToken(potentialNextPageToken);
    const moreResults = await sdkClient.query(
      ArtifactLinkService.Search,
      searchReq
    );
    const moreResultsResponse = moreResults.toObject();
    const moreArtifactLinks = moreResultsResponse.artifactLinksList;
    artifactLinks.push(...moreArtifactLinks);
    potentialNextPageToken = moreResultsResponse.nextPageToken;
  }

  // Chain ArtifactLinks request to Artifacts BatchGet - Build Query
  const artifactIds = artifactLinks.map((a) => a.artifactId);
  const batchGetRequest = new BatchGetRequest();
  batchGetRequest.setIdsList(artifactIds);

  // Chain ArtifactLinks request to Artifacts BatchGet - Execute Query
  const batchGetRes = await sdkClient.query(
    ArtifactService.BatchGet,
    batchGetRequest
  );

  // Build response of ArtifactLinks as object of form {artifactId, sessionLogId, artifact}
  const artifacts = batchGetRes.getArtifactsList();
  const artifactIdToArtifactLinks: {
    [artifactId: string]: ArtifactLink.AsObject;
  } = {};
  artifactLinks.forEach(
    (al) => (artifactIdToArtifactLinks[al.artifactId] = al)
  );
  artifacts.forEach((a) => {
    const artifactId = a.getId();
    artifactIdToArtifactLinks[artifactId].artifact = a.toObject();
  });
  let allArtifactLinks: ArtifactLink.AsObject[] = Object.values(
    artifactIdToArtifactLinks
  );

  // Setting ArtifactFilters currently causes ArtifactLinkService.Search to perform an expensive query
  // Instead, perform a clientside filter by slug.
  // Once fixed, use the commented snippet below to the top of this function
  //   const artifactFilters = new ArtifactFilters();
  //   artifactFilters.addArtifactTypeSlugs(filter.slug);
  //   filters.setArtifactFilters(artifactFilters)
  if (filter.slug) {
    allArtifactLinks = allArtifactLinks.filter(
      (al) => al.artifact && al.artifact.artifactTypeSlug === filter.slug
    );
  }

  const compareFileState = (a, b) => {
    if (a === FileState.RECEIVED) return -1;
    if (b === FileState.RECEIVED) return 1;
    if (a === FileState.PENDING) return -1;
    if (b === FileState.PENDING) return 1;
    if (a === FileState.DELETED) return -1;
    if (b === FileState.DELETED) return 1;
    if (a === FileState.FILE_STATE_UNSPECIFIED) return -1;
    if (b === FileState.FILE_STATE_UNSPECIFIED) return 1;
    return a - b;
  };

  const compareFileName = (a, b) => {
    return a.localeCompare(b);
  };

  // sort by fileState, then by filename
  return allArtifactLinks.sort((a, b) => {
    const fileStateComparison = compareFileState(
      a?.artifact?.fileState,
      b?.artifact?.fileState
    );
    if (fileStateComparison !== 0) {
      return fileStateComparison;
    } else {
      return compareFileName(a?.artifact?.filename, b?.artifact?.filename);
    }
  });
};

/**
 * Returns map of session log + children and their artifacts
 * If a sessionLog does not have any artifacts, it will not show up in the resulting object
 */
export type SessionLogAndArtifacts = {
  [sessionLogId: string]: {
    name: string;
    id: string;
    artifacts: ArtifactLink.AsObject[];
  };
};

const fetchSessionLogAndArtifacts = async (
  sessionLogId: string
): Promise<SessionLogAndArtifacts> => {
  const sessionLogRequest = new GetSessionRequest();
  sessionLogRequest.setId(sessionLogId);

  const sessionLogResponse = await sdkClient.query(
    SessionLogService.Get,
    sessionLogRequest
  );

  const sessionLog = sessionLogResponse.toObject();

  const childrenIds = sessionLog.childrenList.map((child) => child.id);

  const sessionLogs = sessionLog.childrenList.reduce(
    (acc, child) => ({
      ...acc,
      [child.id]: child,
    }),
    {}
  );

  sessionLogs[sessionLogId] = sessionLog;

  const allSessionLogIds = [sessionLogId, ...childrenIds];

  const artifacts = await fetchSessionLogArtifacts(allSessionLogIds, {});

  const sessionLogAndArtifacts: SessionLogAndArtifacts = artifacts.reduce(
    (acc, artifact) => {
      const sessionLogId = artifact.sessionLogId;
      if (!acc[sessionLogId]) {
        acc[sessionLogId] = {
          name: sessionLogs[sessionLogId].name,
          id: sessionLogId,
          artifacts: [],
        };
      }
      acc[sessionLogId].artifacts.push(artifact);
      return acc;
    },
    {}
  );

  return sessionLogAndArtifacts;
};

const fetchArtifactContents = async (artifactId: string) => {
  const data = await hmdsdkClient.artifacts.getContent(artifactId);
  const contentType = data.headers['content-type'];

  if (contentType === 'application/json') {
    return data.json();
  } else if (contentType === 'application/octet-stream') {
    return data.arrayBuffer();
  } else if (contentType === 'text/plain') {
    return data.text();
  } else {
    return data.blob();
  }
};

const deleteArtifact = async (artifactId: string) => {
  const req = new DeleteRequest();
  req.setId(artifactId);
  await sdkClient.query(ArtifactService.Delete, req);
};

export const useArtifactDeleteMutation = (sessionLogId: string) => {
  const mutation = useMutation(deleteArtifact, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        SESSION_LOG_ARTIFACTS_QUERY_KEY,
        sessionLogId,
      ]);
    },
  });
  return mutation;
};

const ARTIFACTS_QUERY_KEY = 'artifactSearch';

type ArtifactFetchOptions = {
  slug?: string;
  fromDate?: Date;
  toDate?: Date;
  fileName?: string;
  fileState?: FileStateMap[keyof FileStateMap];
  sortBy?: string;
  robotHostname?: string;
  phoneSerialNumber?: string;
};

const fetchArtifacts = async (
  options: ArtifactFetchOptions,
  token?: string
) => {
  const req = new ArtifactSearchRequest();
  const filters = new ArtifactSearchRequestFilters();

  if (!options.toDate || !options.fromDate) {
    throw new Error('Invalid date range');
  }

  if (options.fromDate > options.toDate) {
    throw new Error('Invalid date range, start date must be before end date');
  }

  if (
    options.toDate.getTime() - options.fromDate.getTime() >
    1000 * 60 * 60 * 24 * 30
  ) {
    throw new Error('Invalid date range, must be less than 1 month');
  }

  if (!options.slug) {
    throw new Error('Artifact Type not specified');
  }

  if (!options.sortBy) {
    throw new Error('Sort by not specified');
  }

  filters.setArtifactTypeSlugsList([options.slug]);

  const dateRange = new DateRange();

  const startTime = Timestamp.fromDate(options.fromDate);
  dateRange.setGreaterThanOrEqualTime(startTime);

  const endTime = Timestamp.fromDate(options.toDate);
  dateRange.setLessThanOrEqualTime(endTime);

  filters.setCreateRange(dateRange);

  filters.setOrderBy(options.sortBy);

  if (options.fileName) {
    filters.setFilenamesList([options.fileName]);
  }
  if (options.fileState) {
    filters.setFileStatesList([options.fileState]);
  }

  const metadata = {};

  if (options.robotHostname) {
    metadata['robot_hostname'] = options.robotHostname;
  }
  if (options.phoneSerialNumber) {
    metadata['phone_serial_number'] = options.phoneSerialNumber;
  }

  const metadataStruct = Struct.fromJavaScript(metadata);

  filters.setMetadata(metadataStruct);

  if (token) {
    req.setPageToken(token);
  }

  req.setFilters(filters);

  const res = await sdkClient.query(ArtifactService.Search, req);

  return res.toObject();
};

export const useArtifacts = (
  options: ArtifactFetchOptions,
  overrides?: any
) => {
  const query = useInfiniteQuery(
    [ARTIFACTS_QUERY_KEY, options],
    ({ pageParam }) => fetchArtifacts(options, pageParam),
    {
      getNextPageParam: (lastPage) =>
        typeof lastPage.nextPageToken === 'string' &&
        lastPage.nextPageToken !== ''
          ? lastPage.nextPageToken
          : undefined,
      ...overrides,
    }
  );

  return query;
};

const fetchSessionLogIdsByArtifactId = async (artifactIds: string[]) => {
  const req = new SearchArtifactLinksRequest();
  const filters = new SearchRequestFilters();

  filters.setArtifactIdsList(artifactIds);

  req.setView(ArtifactLinkView.BASIC);
  req.setFilters(filters);

  const res = await sdkClient.query(ArtifactLinkService.Search, req);

  const sessionLogIds = res
    .toObject()
    .artifactLinksList.map((link) => link.sessionLogId);

  return sessionLogIds;
};

const SESSION_LOG_IDS_BY_ARTIFACT_ID_QUERY_KEY = 'sessionLogIdsByArtifactId';

export const useSessionLogIdsByArtifactId = (artifactIds: string[]) => {
  const query = useQuery(
    [SESSION_LOG_IDS_BY_ARTIFACT_ID_QUERY_KEY, artifactIds],
    () => fetchSessionLogIdsByArtifactId(artifactIds)
  );

  return query;
};


type updateStorageTierOnArtifact = {
  artifactId: string; //need the id of the artifact to update
  newRuleSetSlug: string; //and slug of the new storageTierRuleSet to associate with the artifact
  sessionLogId: string; //to update the artifact on session log in cache
};

export const updateArtifactRuleSets = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({ artifactId, newRuleSetSlug, sessionLogId}: updateStorageTierOnArtifact) => {
      const req = new ArtifactUpdateRequest();
      const artifact = new Artifact();
      artifact.setId(artifactId);
      artifact.setStorageTierRuleSetSlug(newRuleSetSlug);
      req.setArtifact(artifact);

      const mask = new FieldMask();
      mask.setPathsList(['StorageTierRuleSetSlug']);
      req.setUpdateMask(mask);
      const response = await sdkClient.query(ArtifactService.Update, req);
      return response.toObject();
    },
    onError: (error) => {
      return error;
    },
    onSuccess: (data, variables) => {
      queryClient.setQueryData(
        [SESSION_LOG_ARTIFACTS_QUERY_KEY, variables.sessionLogId],
        (oldData:any) => oldData ? {
          ...oldData,
          [variables.sessionLogId]:{
            ...oldData[variables.sessionLogId],
            artifacts: oldData[variables.sessionLogId].artifacts.map((a) => {
              if (a.artifactId === variables.artifactId) {
                return {
                  ...a,
                  artifact: {
                    ...data
                  }
                }
              } else {
                return a
              }
            })
          }
        } : oldData
      );
    },
  });
  return mutation;
};