import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import CheckSelectWrapper from './CheckSelectWrapper';
import Label from './Label';

function styles(theme) {
  return {
    beforeContainer: {
      width: 28,
      display: 'flex',
      alignItems: 'center',
    },
    expandIcon: {
      transition: '0.2s ease',
      '&:hover': {
        color: theme.palette.primary.light,
        backgroundColor: theme.palette.action.hover,
      },
    },
  };
}

function ChildGroupRow(props) {
  const { classes, isExpanded, label, parentValue, onToggleExpanded, ...rest } =
    props;

  function onToggle(evt) {
    onToggleExpanded(evt, { isExpanded: !isExpanded, parentValue });
    evt.stopPropagation();
    evt.preventDefault();
  }

  return (
    <CheckSelectWrapper {...rest}>
      <div className={classes.beforeContainer}>
        {isExpanded ? (
          <ExpandLess className={classes.expandIcon} onClick={onToggle} />
        ) : (
          <ExpandMore className={classes.expandIcon} onClick={onToggle} />
        )}
      </div>
      <Label {...rest}>{label}</Label>
    </CheckSelectWrapper>
  );
}
ChildGroupRow.propTypes = {
  ...CheckSelectWrapper.propTypes,
  ...Label.propTypes,
  isExpanded: PropTypes.bool,
  parentValue: PropTypes.any.isRequired,
  onToggleExpanded: PropTypes.func.isRequired,
};

export default withStyles(styles)(ChildGroupRow);
