import React, { useCallback } from 'react';
import _ from 'lodash';
import { Select } from '@shield-ui/controls';
import { useEnsureListsAreLoaded } from '../../../redux/lists/util';
import container from './container';
import { severityColors } from '../../../lib/sharedStyles';

function SeveritySelect(props) {
  useEnsureListsAreLoaded();

  const getLabel = useCallback((opt) => {
    return (
      <div
        style={{
          borderLeft: `2px solid ${severityColors[opt.label] || '#bbb'}`,
          paddingLeft: 8,
        }}
      >
        {_.upperFirst(opt.label.toLowerCase())}
      </div>
    );
  }, []);

  return (
    <Select
      suggestions={props.severityOptions}
      getOptionLabel={getLabel}
      {...props}
    />
  );
}

export default container(SeveritySelect);
