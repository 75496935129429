import Cell from './Cell';
import getValue from './getValue';

const config = {
  columnKey: 'notes',
  label: 'Notes (All)',
  description: 'Notes of all types',
  defaultWidth: 500,
  Cell,
  getValue,
  parentCellStyle: {
    verticalAlign: 'top',
  },
  fragment: `
    notes {
        edges {
            node {
              displayText
              createdAt
              createdBy {
                name
              }
              noteType
            }
        }
    }
    `,
};

export default config;
