import React, { useState } from 'react';
import { ContainerProps } from './containerEventManager';
import ParentRow from './ParentRow';
import LogEvent from './LogEvent';
import { Props as ChildRowDetailsProps } from './ChildRowDetails';
import { LogEventDisplayRecord } from '../../../redux/eventManager.slice';

interface Props
  extends Pick<ContainerProps, 'robotLog'>,
    Pick<ChildRowDetailsProps, 'timeCodeOnClick'> {
  groupLogEventDisplayRecords: LogEventDisplayRecord[];
  groupLabel: string;
}

function LogEventGroup(props: Props) {
  const { groupLogEventDisplayRecords, groupLabel } = props;
  const [isExpanded, setIsExpanded] = useState(
    groupLogEventDisplayRecords.length < 3
  );

  // https://dev.azure.com/shieldai/ShieldAI/_git/EdgeAI
  // pikachu/workspace/src/planning/mode_control/flightmode/src/flightmodes/prearm.cpp

  return (
    <ParentRow
      isExpanded={isExpanded}
      onClick={() => setIsExpanded(!isExpanded)}
      label={groupLabel}
      count={groupLogEventDisplayRecords.length}
      avatarTeam={{
        name: groupLogEventDisplayRecords[0].leafLogEvent.componentOwner,
      }}
    >
      {groupLogEventDisplayRecords.map((displayRecord, i) => {
        return (
          <LogEvent key={i} logEventDisplayRecord={displayRecord} {...props} />
        );
      })}
    </ParentRow>
  );
}

export default LogEventGroup;
