import { gql } from '@apollo/client';
import { runMutation } from '../apollo-client';

const userRobotLogDetailFragment = `
  id
  robotLogId
  userId
  viewedCount
  viewedLastAt
`;

const userRobotLogViewedMutation = gql`
  mutation userRobotLogViewed(
    $robotLogId: UUID!,
  ) {
    userRobotLogViewed(robotLogId: $robotLogId) {
      ${userRobotLogDetailFragment}
    }
  }
`;

export function userRobotLogViewed(variables, callback) {
  runMutation({
    variables,
    mutation: userRobotLogViewedMutation,
    callback,
  });
}
