import { connect } from 'react-redux';
import { getSeverityOptions } from '../../../redux/lists/selectors';

function mapStateToProps(state) {
  return {
    severityOptions: getSeverityOptions(state),
  };
}

export default connect(mapStateToProps);
