import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { showError } from '../lib/messages';
import { RootState } from './store';
import sdkClient from '../lib/hmdsdkClient';
import {
  ProjectService,
  ListRequest,
  Project,
} from '@hmd/sdk/api/systems/projects/v1';

export const SYSTEMS_FEATURE_KEY = 'systems';

export type ProjectAsObject = ReturnType<typeof Project.toObject>;

interface State {
  projects: ProjectAsObject[];
}

const initialState: State = {
  projects: undefined,
};

export const ensureProjects = createAsyncThunk(
  `${SYSTEMS_FEATURE_KEY}/fetchProjects`,
  (arg, thunkAPI) => {
    const state = thunkAPI.getState();
    if (state[SYSTEMS_FEATURE_KEY].projects) {
      return Promise.resolve(state[SYSTEMS_FEATURE_KEY].projects);
    }

    const req = new ListRequest();
    req.setPageSize(200);

    return sdkClient
      .unary(ProjectService.List, req)
      .then((result) => {
        return result.getProjectsList().map((project) => project.toObject());
      })
      .catch((e) => {
        showError(e);
        return [];
      });
  }
);

export const systemsSlice = createSlice({
  name: SYSTEMS_FEATURE_KEY,
  initialState,
  reducers: {},
  extraReducers: {
    [ensureProjects.fulfilled.type]: (
      state,
      action: PayloadAction<ProjectAsObject[]>
    ) => {
      state.projects = action.payload;
    },
  },
});

/************************************************************************************
- BEGIN Selectors
************************************************************************************/

export const selectProjects = (state: RootState) =>
  state[SYSTEMS_FEATURE_KEY].projects;
