import React from 'react';
import { Select } from '@shield-ui/controls';
import { useDidMount } from '@shield-ui/hooks';
import container from './componentsContainer';

function ComponentSelect(props) {
  const { ensureComponents, componentOptions, ...rest } = props;

  useDidMount(ensureComponents);

  const extra = !componentOptions.length
    ? {
        placeholder: 'Loading...',
        disabled: true,
      }
    : undefined;

  return (
    <Select
      suggestions={componentOptions}
      placeholder="Components"
      {...rest}
      {...extra}
    />
  );
}

export default container(ComponentSelect);
