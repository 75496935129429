import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { colors } from '@shield-ui/styles';
import {
  useHexColor,
  useSessionLogTypeNames,
} from 'apps/vision/src/app/services/sessionLogTypes';

function styles() {
  return {
    sectionHeader: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 8,
      borderLeft: `3px solid ${colors.hues.gray}`,
    },
  };
}

function TypeCell({ row, classes }) {
  const type = row.sessionLogTypeSlug;
  const {
    data: hexColorList,
    isLoading: colorsIsLoading,
    isError: colorsIsError,
    error: colorsError,
  } = useHexColor();
  const {
    data: sessionLogTypesNames,
    isLoading: namesIsLoading,
    isError: namesIsError,
    error: namesError,
  } = useSessionLogTypeNames();

  if (colorsIsLoading || namesIsLoading) {
    return null;
  }

  if (colorsIsError) {
    return <span>Error: {colorsError.message}</span>;
  }

  if (namesIsError) {
    return <span>Error: {namesError.message}</span>;
  }

  const hexColor = hexColorList[type];
  const name = sessionLogTypesNames[type];

  return (
    <div className={classes.sectionHeader} style={{ borderColor: hexColor }}>
      {name}
    </div>
  );
}

export default withStyles(styles)(TypeCell);
