import _ from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import { prettifyString } from '@shield-ui/utils';

export const getCurrentUser = (state) => state.authUser.currentUser;
export const getListTypeMap = (state) => state.lists.listTypeMap;

function genericIdMap(listTypeMap, listTypeKey, labelProp = 'name') {
  return _.get(listTypeMap, [listTypeKey], []).map((item) => {
    return {
      label: item[labelProp],
      value: item.id,
      ...item,
    };
  });
}

const SEVERITY_ORDER = ['CRITICAL', 'MAJOR', 'MODERATE', 'MINOR'];
export const getSeverityOptions = createSelector(
  getListTypeMap,
  (listTypeMap) => {
    return _.sortBy(genericIdMap(listTypeMap, 'severities'), (opt) => {
      const index = SEVERITY_ORDER.indexOf(opt.label);
      return index === -1 ? 99 : index;
    });
  }
);

export const getTagOptions = createSelector(getListTypeMap, (listTypeMap) =>
  genericIdMap(listTypeMap, 'tags')
);

export const getCauseOptions = createSelector(getListTypeMap, (listTypeMap) =>
  genericIdMap(listTypeMap, 'causes')
);

export const getRobotOptions = createSelector(getListTypeMap, (listTypeMap) =>
  genericIdMap(listTypeMap, 'robots', 'hostname')
);

export const getRobotProductOptions = createSelector(
  getListTypeMap,
  (listTypeMap) => {
    const robots = _.get(listTypeMap, 'robots', []);
    const rbp = _.uniqBy(robots, 'product');
    const products = rbp.map((r) => ({ label: r.product, value: r.product }));
    return products;
  }
);

export const getRobotLogTestOptions = createSelector(
  getListTypeMap,
  (listTypeMap) => genericIdMap(listTypeMap, 'robotLogTests', 'code')
);

const topRegex = new RegExp(/^(SWAT|SAFE)/);
export const getEnvironmentOptions = createSelector(
  getListTypeMap,
  (listTypeMap) =>
    _.sortBy(genericIdMap(listTypeMap, 'environments'), (opt) => {
      if (opt.label.match(topRegex)) {
        return `0${opt.label}`;
      }
      return opt.label;
    })
);
export const getMissionOptions = createSelector(getListTypeMap, (listTypeMap) =>
  genericIdMap(listTypeMap, 'missions')
);

const getLogDefinitionList = (state) => state.lists.listTypeMap.logDefinitions;
export const getLogDefinitionMap = createSelector(
  getLogDefinitionList,
  (logDefinitions) => {
    return _.keyBy(logDefinitions, 'externalId');
  }
);
export const logDefinitionOptionsSelector = createSelector(
  getLogDefinitionList,
  (logDefinitions) => {
    return _.sortBy(
      logDefinitions.map((logDefinition) => ({
        label: prettifyString(logDefinition.name),
        value: logDefinition.externalId,
        description: logDefinition.description,
      })),
      'label'
    );
  }
);

export const getComponentsSelector = (state) =>
  state.lists.listTypeMap.components;
export const getComponentMap = createSelector(
  getComponentsSelector,
  (components) => {
    return _.keyBy(components, 'externalId');
  }
)
export const componentOptionsSelector = createSelector(
  getComponentsSelector,
  (components) => {
    return _.sortBy(
      components.map((c) => ({
        label: prettifyString(c.name),
        value: c.externalId,
      })),
      'label'
    );
  }
);
export const componentOwnerOptionsSelector = createSelector(
  getComponentsSelector,
  (components) => {
    return _.uniqBy(
      _.sortBy(
        components.map((c) => ({
          label: c.owner,
          value: c.owner,
        })),
        'label'
      ),
      'value'
    );
  }
);
export const componentOwnerParentOptionsSelector = createSelector(
  getComponentsSelector,
  (components) => {
    const groups = _.groupBy(_.sortBy(components, 'name'), 'owner');

    return _.sortBy(
      _.map(groups, (groupComponents, owner) => {
        return {
          value: owner,
          label: owner,
          childValues: _.map(groupComponents, 'externalId'),
        };
      }),
      'label'
    );
  }
);

export const getSoftwareVersionOptions = createSelector(
  getListTypeMap,
  (listTypeMap) => {
    let options = _.get(listTypeMap, 'softwareInfos', []).reduce((acc, si) => {
      if (si && si.version) {
        acc.push({
          label: si.version,
          value: si.version,
        });
      }
      return acc;
    }, []);

    options = _.uniqBy(options, 'label');

    return _.sortBy(options, (option) => {
      let ret = 0;
      try {
        const [major, minor, patch, minorPatch] = option.label.split('.');
        ret -= (major || 0) * 1000000;
        ret -= (minor || 0) * 10000;
        ret -= (patch || 0) * 100;
        ret -= minorPatch || 0;
      } catch (e) {
        console.warn(e);
      }

      return ret;
    });
  }
);
