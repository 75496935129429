import React, { useState } from 'react';
import { ProgressSubmitButton } from '@shield-ui/buttons';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Paper } from '@mui/material';
import ModalHeader from '../../modals/Header';
import { copyToClipboard } from '@shield-ui/utils';
import { getCollectionRobotLogNames } from '../../../services/robotLogs';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(3),
      width: 500,
      // not sure why this gets a focus outline
      outline: 'none',
    },
  })
);

const copyLogNamesInCollection = (callback, props) => {
  const { collection, showErrorSnack, showSuccessSnack, hideModal } = props;

  callback(true);

  getCollectionRobotLogNames(collection.id, (err, res) => {
    callback(false);

    if (err) {
      return showErrorSnack(err);
    }
    const { robotLogs } = res.data;

    if (!robotLogs) {
      return showErrorSnack({
        message: `Could not find robotLogs in collection: ${collection.name}`,
      });
    } else if (robotLogs.edges.length === 0) {
      return showErrorSnack({
        message: `No robotLogs to copy in collection: ${collection.name}`,
      });
    } else {
      const robotLogNames = robotLogs.edges.map((log) => log.node.name);
      const concatNames = robotLogNames.join('\n');
      copyToClipboard(
        concatNames,
        () => {
          showSuccessSnack({
            message: `${robotLogNames.length} Names Copied From Collection: ${collection.name} `,
          });
          hideModal();
        },
        null
      );
    }
  });
};

function CollectionExportsModal(props, ref) {
  const { hideModal } = props;
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <Paper className={classes.container}>
      <ModalHeader onClose={hideModal}>{'Exports'}</ModalHeader>
      <ProgressSubmitButton
        isSubmitting={isSubmitting}
        color="primary"
        variant="contained"
        onClick={() => {
          copyLogNamesInCollection(setIsSubmitting, props);
        }}
      >
        Copy Names to Clipboard
      </ProgressSubmitButton>
    </Paper>
  );
}

export default React.forwardRef(CollectionExportsModal);
