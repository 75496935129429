import React from 'react';
import _ from 'lodash';
import withStyles from '@mui/styles/withStyles';

function styles() {
  return {
    row: {
      marginTop: 10,
      marginBottom: 10,
    },
  };
}

export function parseCauseNamesFromRow(row) {
  return _.compact(
    _.get(row, 'events.edges', []).map((edge) => {
      return _.get(edge, 'node.cause.name');
    })
  );
}

function EventCausesCell({ row, classes }) {
  const causeNames = parseCauseNamesFromRow(row);

  return causeNames.map((name, i) => (
    <div key={i} className={classes.row}>
      {name}
    </div>
  ));
}

export default withStyles(styles)(EventCausesCell);
