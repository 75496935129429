import React from 'react';
import { Switch, Route } from 'react-router-dom';
import LoginPage from './Login/LoginPage';
import AuthRoutes from './AuthRoutes';

function Routes() {
  return (
    <Switch>
      <Route path="/app/login" component={LoginPage} />
      <Route component={AuthRoutes} />
    </Switch>
  );
}

export default Routes;
