import React, { Component, Children } from 'react';

type GraduallyAppearingListProps = {
  onComplete?: () => void;
  appearMode?: 'timeBetween' | 'timeTotal';
  timeBetweenAppear?: number;
  timeTotalAppear?: number;
  children: React.ReactNode[];
};
type GraduallyAppearingListState = {
  visibleIndex: number;
  done: boolean;
};

/**
 * Mounts children with time between so they are rolled out one after another.
 * This is useful for a list of content that you want to slowly roll out to the user.
 */
class GraduallyAppearingList extends Component<
  GraduallyAppearingListProps,
  GraduallyAppearingListState
> {
  static defaultProps = {
    appearMode: 'timeTotal',
    timeBetweenAppear: 90,
    timeTotalAppear: 500,
  };

  state = {
    visibleIndex: 0,
    done: false,
  };
  timer = undefined;
  timeout = undefined;

  componentDidUpdate() {
    this.setTimer();
  }

  componentDidMount() {
    this.setTimer();
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  getTimeout(): number {
    if (this.timeout) {
      return this.timeout;
    }

    const { timeTotalAppear, timeBetweenAppear, appearMode, children } =
      this.props;
    let timeout = 50;
    if (appearMode === 'timeBetween') {
      timeout = timeBetweenAppear;
    } else if (appearMode === 'timeTotal') {
      timeout = Math.round(timeTotalAppear / Children.count(children));
    }

    this.timeout = timeout;

    return this.timeout;
  }

  setTimer(): void {
    const { done } = this.state;
    if (done || this.timer) {
      return;
    }
    const timeout = this.getTimeout();

    this.timer = setTimeout(this.showTest.bind(this), timeout);
  }

  showTest(): void {
    delete this.timer;
    const { children, onComplete } = this.props;
    const { visibleIndex } = this.state;

    const newIndex = visibleIndex + 1;
    const childCount = Children.count(children);

    if (newIndex >= childCount) {
      this.setState(
        {
          visibleIndex: newIndex,
          done: true,
        },
        onComplete
      );
    } else {
      this.setState({
        visibleIndex: newIndex,
      });
    }
  }

  render() {
    const { children } = this.props;
    const { visibleIndex, done } = this.state;

    return Children.map(children, (child, index) => {
      if (done || visibleIndex >= index) {
        return child;
      }
    });
  }
}

export default GraduallyAppearingList;
