import { Select, SelectProps } from 'antd';
import { useArtifactTypes } from '../../services/newArtifacts';

interface ArtifactTypeSelectProps extends SelectProps<string> {
  includeAllOption?: boolean;
  filteredSlugs?: string[];
}

export const ArtifactTypeSelect = ({
  includeAllOption,
  filteredSlugs = [],
  ...props
}: ArtifactTypeSelectProps) => {
  const { isLoading, data } = useArtifactTypes();

  const filteredArtifactTypes = data
    ?.filter((artifactType) =>
      filteredSlugs.length !== 0
        ? filteredSlugs?.includes(artifactType.slug)
        : true
    )
    .sort((a, b) => a.slug.localeCompare(b.slug));

  // TODO: add error states
  return (
    <Select
      style={{ width: '100%' }}
      loading={isLoading}
      {...props}
      showSearch
      filterOption={true}
    >
      {includeAllOption && (
        <Select.Option value="">All Artifact Types</Select.Option>
      )}
      {filteredArtifactTypes?.map((artifactType) => (
        <Select.Option key={artifactType.slug} value={artifactType.slug}>
          {artifactType.name}
        </Select.Option>
      ))}
    </Select>
  );
};
