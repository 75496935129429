import { useState, useCallback, useEffect, useMemo } from 'react';
import _ from 'lodash';
import memoizeOne from 'memoize-one';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { smartFormatDuration } from '@shield-ui/utils';
import { getUnsetSentinel } from '@shield-ui/hangar-service';
import FtsLink from './FtsLink';
import FlightPropDropdown from './RobotLogPropDropdown';
import {
  createOrUpdateRobotLog,
  detectSessionLogProduct,
  SessionLogProduct,
  updateSessionLog,
  updateSessionLogEnvironmentsMutation,
  useSessionLog,
} from '../../../services/robotLogs';
import HeaderControl from './HeaderControl';
import container from './container';
import { SessionLog } from '@hmd/sdk/api/session_log/v1';
import { FieldMask } from 'google-protobuf/google/protobuf/field_mask_pb';
import { TimePicker, DatePicker, Select, Modal } from 'antd';
import moment from 'moment';
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';
import { colors } from '@shield-ui/styles';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useEnvironments } from '../../../services/environments';

const useStyles = makeStyles((theme) => {
  return {
    propContainerStyle: {
      padding: '0px 4px 4px 37px',
      lineHeight: '24px',
    },
    controlContent: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginTop: theme.spacing(0.5),
    },
    readOnlyProps: {
      margin: theme.spacing(1, 0),
    },
    datePicker: {
      outline: 'transparent',
      borderRadius: '1px',
      '&:hover': {
        outline: 'solid',
        outlineWidth: '1px',
        color: colors.hues.gray,
      },
      '& .ant-picker-input > input': {
        fontWeight: 'bold',
      },
    },
    hostNameSelect: {
      outline: 'transparent',
      borderRadius: '1px',
      '&:hover': {
        outline: 'solid',
        outlineWidth: '1px',
        color: colors.hues.gray,
      },
      '& .ant-select-selection-item': {
        fontWeight: 'bold',
      },
    },
    divider: {
      display: 'inline-block',
      margin: '0 10px',
      fontSize: 12,
      '&:after': {
        content: '""',
        width: 1,
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        height: 10,
        display: 'inline-block',
        marginLeft: 5,
        marginRight: 8,
      },
    },
  };
});

const getParticipants = memoizeOne((robotLog) => {
  return _.get(robotLog, 'participants.edges', []).map((edge) => edge.node);
});

const FlightPropReadOnly = withStyles({
  root: {
    fontSize: 12,
    '&:after': {
      content: '""',
      width: 1,
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      height: 10,
      display: 'inline-block',
      marginLeft: 10,
      marginRight: 10,
    },
    '&:last-of-type:after': {
      width: 0,
      marginRight: 0,
    },
  },
})(
  ({
    classes,
    label,
    value,
  }: {
    classes: any;
    label: string;
    value: string;
  }) => {
    if (!value) {
      return null;
    }
    return (
      <span className={classes.root}>
        {`${label}: `}
        <b>{value}</b>
      </span>
    );
  }
);

const HeaderContent = ({
  robotLog,
  refetch,
  robotLogId,
  robotLogTestOptions,
  showSuccessSnack,
  robotOptions,
}) => {
  const classes = useStyles();
  const [modal, contextHolder] = Modal.useModal();

  const [metadata, setMetadata] = useState({});

  const [duration, setDuration] = useState(null);

  const [newStartTime, setNewStartTime] = useState(
    robotLog.startTime ? moment(robotLog.startTime) : null
  );
  const [newEndTime, setNewEndTime] = useState(
    robotLog.endTime ? moment(robotLog.endTime) : null
  );

  const [prevStartToMoment, setPrevStartToMoment] = useState(
    robotLog.startTime ? moment(robotLog.startTime) : null
  );
  const [prevEndToMoment, setPrevEndToMoment] = useState(
    robotLog.endTime ? moment(robotLog.endTime) : null
  );

  const setStartTime = (time: moment.Moment) => {
    setPrevStartToMoment(newStartTime);
    setNewStartTime(time);
  };

  const setEndTime = (time: moment.Moment) => {
    setPrevEndToMoment(newEndTime);
    setNewEndTime(time);
  };

  useEffect(() => {
    if (newStartTime && newEndTime) {
      setDuration(getDuration(newStartTime, newEndTime));
    }
  }, [newStartTime, newEndTime]);

  const { mutate: updateEnvironments } =
    updateSessionLogEnvironmentsMutation(robotLogId);

  const { data: sessionLog } = useSessionLog(robotLogId);
  const { data: environments = [] } = useEnvironments();

  const [selectedEnvironments, setSelectedEnvironments] = useState(
    sessionLog?.environmentIdsList || []
  );

  useEffect(() => {
    setSelectedEnvironments(sessionLog?.environmentIdsList || []);
  }, [sessionLog]);

  const environmentSuggestions = useMemo(() => {
    return environments.map((env) => ({
      value: env.id,
      label: env.name,
    }));
  }, [environments]);

  const handleEnvironmentChange = (envs: { value: string }[]) => {
    const updatedEnvs = envs.map((env) => env.value);
    setSelectedEnvironments(updatedEnvs);
    updateEnvironments(updatedEnvs);
  };

  const getUpdateFlightProp = useCallback(
    _.memoize((name) => {
      return (opt) => {
        const value = _.get(opt, 'value', null);

        const update: any = { id: robotLog.id };
        if (name === 'environmentId') {
          // Magically Unset Variables!
          update.environmentId = value || getUnsetSentinel('uuid');
        } else if (name === 'robotLogTestId') {
          // Magically Unset Variables!
          update.robotLogTestId = value || getUnsetSentinel('uuid');
        } else {
          update.robotLogData = JSON.stringify({ [name]: value });
        }

        createOrUpdateRobotLog(update, (err) => {
          if (err) {
            return console.error(err);
          }
          refetch();
          showSuccessSnack(`Property ${name} updated`);
        });
      };
    }),
    [refetch, showSuccessSnack, robotLog]
  );

  const getDuration = (start: moment.Moment, end: moment.Moment) => {
    if (!start || !end) {
      return null;
    }
    const durationMs = end.diff(start);
    if (durationMs < 0) {
      return 0;
    }
    return durationMs;
  };

  if (!robotLog) {
    return null;
  }

  const {
    softwareInfo,
    robot,
    phone,
    phoneSoftware,
    robotLogData,
    robotLogTestId,
    sessionLogTypeSlug,
  } = robotLog;

  // memoized
  const participants = getParticipants(robotLog);

  const sessionLogProduct = detectSessionLogProduct(robotLog.name);

  const updateTimeRange = (start: moment.Moment, end: moment.Moment) => {
    setStartTime(start);
    setEndTime(end);
    const sl = new SessionLog();
    sl.setId(robotLog.id);
    const startTimeStamp = Timestamp.fromDate(
      new Date(start.toISOString(true))
    );
    const endTimeStamp = Timestamp.fromDate(new Date(end.toISOString(true)));
    sl.setStartTime(startTimeStamp);
    sl.setEndTime(endTimeStamp);
    const mask = new FieldMask();
    mask.setPathsList(['StartTime', 'EndTime']);
    updateSessionLog(sl, mask).then((res) => {
      refetch();
    });
  };

  const updateDate = (start: moment.Moment, end: moment.Moment) => {
    const sl = new SessionLog();
    sl.setId(robotLog.id);
    const startTimeStamp = Timestamp.fromDate(
      new Date(start.toISOString(true))
    );
    const endTimeStamp = Timestamp.fromDate(new Date(end.toISOString(true)));
    sl.setStartTime(startTimeStamp);
    sl.setEndTime(endTimeStamp);
    const mask = new FieldMask();
    mask.setPathsList(['StartTime', 'EndTime']);
    updateSessionLog(sl, mask).then((res) => {
      refetch();
    });
  };

  const updateHostname = (value: string) => {
    const sl = new SessionLog();
    sl.setId(robotLog.id);
    sl.setRobotIdsList([value]);
    const mask = new FieldMask();
    mask.setPathsList(['RobotIds']);
    updateSessionLog(sl, mask).then((res) => {
      refetch();
    });
  };

  const onHostnameChange = (value: string) => {
    updateHostname(value);
  };

  const onTimeChange = (times, timeStrings) => {
    const startTime = timeStrings[0];
    const endTime = timeStrings[1];
    const startMom = newStartTime ? newStartTime.clone() : moment();
    //initialize a null endTime to have same date as startTime, since end date is based on startDate (same day or +1)
    const endMom = newEndTime ? newEndTime.clone() : startMom.clone();

    if (
      //if startTime is greater, endTime goes into next day
      startTime > endTime &&
      startMom.get('date') === endMom.get('date')
    ) {
      //todo: add a fallback to set dates in case they end up being > 1 day apart
      endMom.add(1, 'days');
    } else if (
      //else remember to subtract a day if user changes startTime < endTime
      startTime < endTime &&
      endMom.format('MM/DD/YYYY') > startMom.format('MM/DD/YYY')
    ) {
      endMom.subtract(1, 'days');
    }

    const sTime = startMom.set({
      hour: times[0].get('hour'),
      minute: times[0].get('minute'),
      second: times[0].get('second'),
    });
    const eTime = endMom.set({
      hour: times[1].get('hour'),
      minute: times[1].get('minute'),
      second: times[1].get('second'),
    });

    setStartTime(sTime);
    setEndTime(eTime);

    const newDuration = getDuration(sTime, eTime);
    const durationLimit = 28800000; //8 hours
    const startEndTimeChanged =
      sTime !== prevStartToMoment || eTime !== prevEndToMoment;
    if (startEndTimeChanged) {
      if (newDuration > durationLimit) {
        showConfirm(sTime, eTime);
      } else {
        updateTimeRange(sTime, eTime);
      }
    }
  };

  const onDateChange = (date: moment.Moment) => {
    //why does newStarTime and newEndTime update before setstate?
    const startMom = newStartTime ? newStartTime.clone() : moment();
    const endMom = newEndTime ? newEndTime.clone() : startMom.clone();
    startMom.set({
      year: date.get('year'),
      month: date.get('month'),
      date: date.get('date'),
    });
    endMom.set({
      year: date.get('year'),
      month: date.get('month'),
      date: date.get('date'),
    });
    if (startMom.format('HH:mm:ss') > endMom.format('HH:mm:ss')) {
      //if endDate is already greater than startDate, we want to maintain the difference
      endMom.add(1, 'days');
    }
    setStartTime(startMom);
    setEndTime(endMom);

    updateDate(startMom, endMom);
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const onModalOk = (startTime: moment.Moment, endTime: moment.Moment) => {
    updateTimeRange(startTime, endTime);
  };

  const onModalCancel = (startTime: moment.Moment, endTime: moment.Moment) => {
    setStartTime(startTime);
    setEndTime(endTime);
  };

  const showConfirm = (startTime: moment.Moment, endTime: moment.Moment) => {
    modal.confirm({
      title: <b>Are you sure?</b>,
      icon: <ExclamationCircleOutlined />,
      content: "Flights normally don't exceed 8 hours.",
      onOk: () => onModalOk(startTime, endTime),
      onCancel: () => onModalCancel(newStartTime, newEndTime),
      centered: true,
    });
  };

  const aircraft = _.get(metadata, 'Aircraft');
  const model = _.get(metadata, 'Model');
  const configuration = _.get(metadata, 'Configuration');

  useEffect(() => {
    try {
      if (robotLog && robotLog.robotLogData) {
        setMetadata(JSON.parse(robotLog.robotLogData));
      }
    } catch (e) {
      console.error('error parsing robotLogData', e);
    }
  }, [robotLog]);

  return (
    <>
      {contextHolder}
      <div className={classes.propContainerStyle}>
        <div className={classes.readOnlyProps}>
          {sessionLogProduct !== SessionLogProduct.VBAT && (
            <span className={classes.divider}>
              <span>Hostname:</span>
              <Select
                defaultValue={_.get(robot, 'id', '')}
                style={{ width: 120 }}
                size={'small'}
                options={robotOptions}
                onChange={onHostnameChange}
                bordered={false}
                className={classes.hostNameSelect}
                allowClear={false}
                showSearch
                filterOption={(input, option) =>
                  (option?.label.toString() ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </span>
          )}
          <span className={classes.divider}>
            <span>Date:</span>
            <DatePicker
              value={newStartTime}
              format={'MM/DD/YYYY'}
              bordered={false}
              size={'small'}
              onChange={onDateChange}
              className={classes.datePicker}
              allowClear={false}
            />
          </span>
          <span className={classes.divider}>
            <span>Time:</span>
            <TimePicker.RangePicker
              size="small"
              bordered={false}
              className={classes.datePicker}
              allowClear={false}
              order={false}
              value={[newStartTime, newEndTime]}
              onChange={(values, strings) => onTimeChange(values, strings)}
            />
          </span>
          <FlightPropReadOnly
            label="Duration"
            value={duration ? smartFormatDuration(duration) : '0:00'}
          />
          <FlightPropReadOnly label="Aircraft" value={aircraft} />
          <FlightPropReadOnly label="Model" value={model} />
          <FlightPropReadOnly label="Configuration" value={configuration} />
          <FlightPropReadOnly
            label="Software Version"
            value={_.get(softwareInfo, 'version')}
          />
          <FlightPropReadOnly
            label="Branch"
            value={_.get(softwareInfo, 'branchName')}
          />
          <FlightPropReadOnly
            label="Commit Hash"
            value={_.get(softwareInfo, 'commitHash')}
          />
          {/* <FlightPropReadOnly
          label="Robot Block"
          value={_.get(robotLogDataParsed, 'robot_block')}
        /> */}
          <FlightPropReadOnly
            label="Phone"
            value={_.get(phone, 'name') || _.get(phone, 'serial_number')}
          />
          <FlightPropReadOnly
            label="Phone Software"
            value={_.get(phoneSoftware, 'version')}
          />
          <FtsLink json={robotLogData} />
        </div>
        {sessionLogTypeSlug === 'flight' && (
          <div className={classes.controlContent}>
            <HeaderControl label="Test Code">
              <FlightPropDropdown
                onChange={getUpdateFlightProp('robotLogTestId')}
                suggestions={robotLogTestOptions}
                value={robotLogTestId}
              />
            </HeaderControl>
            <HeaderControl label="Environment">
              <FlightPropDropdown
                isMulti={true}
                notClearable={true}
                isCreatable={true}
                onChange={handleEnvironmentChange}
                suggestions={environmentSuggestions}
                value={selectedEnvironments}
              />
            </HeaderControl>
          </div>
        )}
      </div>
    </>
  );
};

export default container(HeaderContent);
