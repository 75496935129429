import { createReducer } from 'redux-nano';
import update from 'immutability-helper';
import { SHOW_MODAL, HIDE_MODAL } from './actions';

const initialState = {
  //  visibleModalType: 'FLIGHT_COLUMN_CONFIG_MODAL',
  visibleModalType: null,
  modalContextProps: {},
};

export default createReducer(initialState, {
  [SHOW_MODAL]: (state, { payload }) => {
    const { modalType, modalContextProps } = payload;
    return update(state, {
      visibleModalType: { $set: modalType },
      modalContextProps: { $set: modalContextProps || {} },
    });
  },
  [HIDE_MODAL]: (state) => {
    return update(state, {
      visibleModalType: { $set: null },
      modalContextProps: { $set: {} },
    });
  },
});
