import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  FetchResult,
  QueryOptions,
  ApolloLink,
  MutationOptions,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import _ from 'lodash';
import config from './lib/config';
import tracker from './lib/tracker';
import { getBearerToken } from './redux/settings/actions';

// Instantiate required constructor fields
const cache = new InMemoryCache();

// This was added tp support local development to staging
// the authorization headers being set on hangar-stage.hmds.ai were not being set on localhost
// this just circumvents using the cookie Authorization and sets it explicitly
// the BearerToken exists in localStorage for use by the grpc-web HMDSCloud client
const authLink = setContext((_, { headers }) => {
  // If a SystemUser is configured for a network denied environment,
  //   auth with Basic SystemUser.
  //   otherwise, auth with logged in user.
  const authorization = config.systemUser
    ? `Basic ${config.systemUser}`
    : `Bearer ${getBearerToken()}`

  return {
    headers: {
      ...headers,
      Authorization: authorization,
    },
  };
});

const client = new ApolloClient({
  // Provide required constructor fields
  cache: cache,
  link: ApolloLink.from([
    onError((error) => {
      const { networkError = {} as any } = error;
      // Grab all errors and append them to the extraInfo
      const errors = _.get(networkError, 'result.errors');
      console.error('onError apollo link', error);
      if (errors) {
        networkError.extraInfo = errors.map((e) => e.message);
        networkError.extraInfo.push(networkError.message);
        networkError.extraInfo = networkError.extraInfo.join('\n');
        networkError.message += `\n${networkError.extraInfo}`;
        console.error(networkError);
      }
    }),
    ...(config.hangarApolloClientUseLocalStorageAuthorization || config.systemUser
      ? [authLink]
      : []),
    createHttpLink({
      uri: `${config.api.base}/graphql`,
      credentials: 'include',
    }),
  ]),
  // Provide some optional constructor fields
  queryDeduplication: false,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});
export default client;
/**
 *
 * @param {object} - response object from apollo client
 * @param {function} callback
 * @param {function|array} tracking
 * @returns {*}
 */
export type HandleResponseParams = {
  response: FetchResult;
  callback: (err?, response?: object) => void;
  tracking?: (tracker, response) => void | [];
};

function handleResponse({
  response,
  callback,
  tracking,
}: HandleResponseParams) {
  if (response.errors) {
    // API specific errors
    return callback(response.errors);
  }

  if (_.isFunction(tracking)) {
    tracking(tracker, response);
  } else if (_.isArray(tracking)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    tracker.trackEvent(...tracking);
  }

  callback(null, response);
}

/**
 * Run a Mutation against Hangar
 */
interface RunMutationParams extends MutationOptions {
  callback: HandleResponseParams['callback'];
  tracking?: HandleResponseParams['tracking'];
}
export function runMutation(params: RunMutationParams) {
  const { variables, mutation, tracking, callback = _.noop, ...rest } = params;
  client
    .mutate({
      mutation,
      variables,
      ...rest,
    })
    .then(
      (response) => {
        handleResponse({ response, callback, tracking });
      },
      (err) => {
        callback(err);
      }
    );
}

/**
 * Run a Query against Hangar
 */
interface RunQueryParams extends QueryOptions {
  callback: HandleResponseParams['callback'];
  tracking?: HandleResponseParams['tracking'];
}
export function runQuery(params: RunQueryParams) {
  const {
    variables = {},
    query,
    tracking,
    callback = _.noop,
    ...rest
  } = params;
  client
    .query({
      query,
      variables,
      ...rest,
    })
    .then(
      (response) => {
        handleResponse({ response, callback, tracking });
      },
      (err) => {
        callback(err);
      }
    );
}
