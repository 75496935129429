import React from 'react';
import _ from 'lodash';
import RobotLogLink from '../common/RobotLogLink';

function ChildrenCell({ row }) {
  const { children } = row;

  if (_.isEmpty(children)) {
    return null;
  }

  return children.map((childRobotLog, i) => {
    return (
      <div key={childRobotLog.id}>
        <RobotLogLink key={i} robotLog={childRobotLog} />
      </div>
    );
  });
}
export default ChildrenCell;
