import {
  NestedQueryFilter,
  MultiselectQueryFilter,
} from '@shield-ui/query-filters';
import _ from 'lodash';
import { getParticipantTypeOptions } from '../../../services/participantTypes';
import { getUserOptions } from '../../../services/users';
import { getTeamOptions } from '../../../services/teams';

const types = new MultiselectQueryFilter({
  id: 'participants.types',
  controlLabel: 'Types',
  esField: 'participants.participant_type.slug',
  unsetValueDisabled: true,
  behaviorConfig: {
    fetchOptions: () => getParticipantTypeOptions(),
  },
});

const users = new MultiselectQueryFilter({
  id: 'participants.users',
  controlLabel: 'Users',
  esField: 'participants.user_id',
  unsetValueDisabled: true,
  behaviorConfig: {
    fetchOptions: () => getUserOptions(),
    createHybridOptions: {
      isCreatable: true,
      creatableEsField: 'participants.user_freeform.keyword',
    },
  },
});

const teams = new MultiselectQueryFilter({
  id: 'participants.teams',
  controlLabel: 'Teams',
  esField: 'participants.team_id',
  unsetValueDisabled: true,
  behaviorConfig: {
    fetchOptions: () => getTeamOptions(),
    createHybridOptions: {
      isCreatable: true,
      creatableEsField: 'participants.team_freeform.keyword',
    },
  },
});

export default new NestedQueryFilter({
  id: 'participants',
  controlLabel: 'Participants',
  description: 'Participants and their types',
  esField: 'participants',
  notExistsControl: {
    enabled: true,
  },
  queryFilters: [types, users, teams],
});
