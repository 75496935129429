import React from 'react';
import { ParentChildSelect } from '@shield-ui/controls';
import {
  ParentChildMultiselectControlData,
  ParentChildMultiselectQueryFilterValue,
} from '../../filterTypes';
import { ControlProps } from '../common';
import ResetButton from '../shared/ResetButton';
import ActionsContainer from '../shared/ActionsContainer';
import ListOperatorSwitch from '../shared/ListOperatorSwitch';

type Props = ControlProps<ParentChildMultiselectQueryFilterValue> & ParentChildMultiselectControlData;

export default function ParentChildMultiselect(props: Props) {
  const {
    onChange,
    onClear,
    value = {},
    hasValue,
    parentOptions = [],
    childOptions = [],
    shouldImplementOperator,
  } = props;
  const { childOptionValues = [], parentOptionValues = [] } = value;

  const onChangeOperator = (v) => onChange({ operator: v });

  return (
    <div>
      <ParentChildSelect
        headless
        includeClearAction={false}
        childItems={childOptions}
        childValues={childOptionValues}
        parentItems={parentOptions}
        parentValues={parentOptionValues}
        onChangeChildValues={(values) => {
          onChange({ childOptionValues: values });
        }}
        onChangeParentValues={(values) => {
          onChange({ parentOptionValues: values });
        }}
      />
      <ActionsContainer
        Left={
          shouldImplementOperator && (
            <ListOperatorSwitch
              value={value.operator}
              onChange={onChangeOperator}
            />
          )
        }
        Right={<ResetButton onClick={onClear} disabled={!hasValue} />}
      />
    </div>
  );
}
