import { createBrowserHistory, Location } from 'history';

const history = createBrowserHistory();

let CURRENT_ROUTE = window.location.pathname;
let PREVIOUS_ROUTE;

history.listen((loc: Location) => {
  if (CURRENT_ROUTE) {
    PREVIOUS_ROUTE = CURRENT_ROUTE;
  }
  CURRENT_ROUTE = loc.pathname;
});

export function getPreviousRoute() {
  return PREVIOUS_ROUTE;
}

export default history;
