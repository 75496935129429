import {
  MultiselectQueryFilter,
  NestedQueryFilter,
} from '@shield-ui/query-filters';
import { getAllArtifactTypeOptions } from '../../../services/artifacts';

const artifactTypes = new MultiselectQueryFilter({
  id: 'artifactTypes',
  esField: 'artifacts.artifact_type_slug',
  controlLabel: 'Types',
  unsetValueDisabled: true,
  behaviorConfig: {
    fetchOptions: () => getAllArtifactTypeOptions(),
  },
});

const artifactFileName = new MultiselectQueryFilter({
  id: 'artifactFileName',
  controlLabel: 'File Name',
  esField: 'artifacts.file_name',
  unsetValueDisabled: true,
  placeholder: 'Type any full or partial filename',
  behaviorConfig: {
    isCreatable: true,
    wildcard: true,
  },
});

export default new NestedQueryFilter({
  id: 'nested-artifacts',
  esField: 'artifacts',
  controlLabel: 'Artifacts',
  description: 'Associated artifact files (e.g. logs)',
  queryFilters: [artifactTypes, artifactFileName],
});
