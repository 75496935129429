import React from 'react';
import format from 'date-fns/format';
import _ from 'lodash';
import withStyles from '@mui/styles/withStyles';
import Flatpickr from 'react-flatpickr';
import cx from 'classnames';
import DateRange from '@mui/icons-material/DateRange';
import Close from '@mui/icons-material/Close';
import 'flatpickr/dist/themes/dark.css';
import './date-picker-calendar.css';
import { colors, commonStyles } from '@shield-ui/styles';
import { safeDate } from '@shield-ui/utils';

const styles = (theme) => ({
  root: {
    border: `1px solid ${colors.semantic.inputBorder}`,
    height: 42,
    fontSize: '1.25em',
    transition: '0.15s ease',
    borderRadius: theme.shape.borderRadius,
    margin: theme.spacing(0.25, 0),
    '&:hover': {
      borderColor: colors.semantic.inputBorderHover,
    },
  },
  rootDisabled: {
    '&:hover': {
      borderColor: colors.semantic.inputBorder,
    },
  },
  flatpickr: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(1.5, 1.5),
    alignItems: 'center',
    display: 'flex',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: colors.semantic.inputBackground,
  },
  input: {
    position: 'absolute',
    visibility: 'hidden',
    width: 0,
  },
  text: {
    color: colors.semantic.bodyText,
    fontSize: 16,
  },
  textDisabled: {
    color: colors.semantic.inputTextDisabled,
  },
  placeholder: {
    ...commonStyles.placeholder,
  },
  iconContainer: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    color: colors.semantic.inputIcon,
    cursor: 'pointer',
  },
  closeContainer: {
    height: '100%',
    alignItems: 'center',
    display: 'flex',
    color: 'white',
    cursor: 'pointer',
  },
  inactive: {
    display: 'none',
  },
});

const defaultOptions = {
  wrap: 'true',
  animate: true,
  // eslint-disable-next-line @typescript-eslint/camelcase
  time_24hr: true,
  enableTime: false,
  defaultHour: 0,
};

function formatDate(date) {
  if (!date) return '';

  date = safeDate(date);

  return format(date, 'MM/dd/yy HH:mm');
}

function DatePicker(props) {
  const {
    value,
    onValueUpdate = _.noop,
    placeholder,
    onClose,
    onClear,
    options,
    classes,
    disabled,
  } = props;

  return (
    <div className={cx(classes.root, disabled && classes.rootDisabled)}>
      <Flatpickr
        className={classes.flatpickr}
        options={{
          ...defaultOptions,
          ...options,
        }}
        onValueUpdate={(dateRange) =>
          onValueUpdate(dateRange.length === 1 ? dateRange[0] : null)
        }
        onClose={onClose}
      >
        <div className={classes.iconContainer} data-toggle>
          {value ? (
            <div className={cx(classes.text, disabled && classes.textDisabled)}>
              {formatDate(value)}
            </div>
          ) : (
            <div className={classes.placeholder}>{placeholder}</div>
          )}
          <DateRange className={cx({ [classes.inactive]: !!value })} />
        </div>
        {!disabled && (
          <div className={classes.closeContainer} data-clear>
            <Close
              className={cx({ [classes.inactive]: !value })}
              onClick={onClear}
            />
          </div>
        )}
        <input className={classes.input} data-input disabled={disabled} />
      </Flatpickr>
    </div>
  );
}

// flat picker craziness. just firing infinite onChanges without memo
export default React.memo(withStyles(styles)(DatePicker));
