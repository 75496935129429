import Cell, { getFtsLink } from './Cell';

const config = {
  columnKey: 'ftsSheet',
  label: 'FTS Sheet',
  description: 'Link to FTS Sheet',
  defaultWidth: 300,
  Cell,
  getValue: ({ row }) => getFtsLink(row),
  fragment: 'robotLogData',
};
export default config;
