import React, {useState, useRef} from 'react';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { colors } from '@shield-ui/styles';
import { stopEventPropagation } from '@shield-ui/utils';
import { Settings } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  container: {
    fontSize: 14,
  },
  title: {
    fontSize: '.875em',
    //textTransform: 'uppercase',
    color: colors.hues.grays[40],
    marginBottom: 2,
  },
  chip: {
    display: 'inline-flex',
    alignItems: 'center',
    minWidth: 60,
    padding: theme.spacing(1),
    border: `1px solid ${colors.semantic.inputBorder}`,
    borderRadius: theme.shape.borderRadius,
    transition: '0.15s ease',
    '&:hover': {
      borderColor: colors.semantic.inputBorderHover,
    },
    cursor: 'pointer',
  },
  label: {
    color: 'white',
    flex: 1,
  },
  placeholder: {
    flex: 1,
    fontSize: '.85em',
    fontWeight: 300,
    color: colors.hues.grays[40],
  },
  chipOpen: {
    borderColor: colors.semantic.inputBorderFocus,
    '&:hover': {
      borderColor: colors.semantic.inputBorderFocus,
    }
  },
  icon: {
    fontSize: '1.3em',
    lineHeight: '0.5em', // center hack
    marginLeft: theme.spacing(1),
  },
  popper: {
    zIndex: 1000,
  },
  dropdown: {
    marginTop: 2,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 800,
    maxHeight: '50vh',
    background: colors.hues.grays[190],
    border: `1px solid ${colors.hues.grays[180]}`,
    padding: theme.spacing(1),
    boxShadow:
      '0 6.4px 14.4px 0 rgba(0,0,0,.132), 0 1.2px 3.6px 0 rgba(0,0,0,.108)',
    overflow: 'auto',
  },
}));

function getPlacement(isOpened, el) {
  if (!el || !isOpened) {
    return 'bottom-start';
  }
  const w = window.innerWidth;
  const { left } = el.getBoundingClientRect();
  return left / w < 0.5 ? 'bottom-start' : 'bottom-end';
}

export type DropdownChipRenderDropdownProps = {
  onClose: () => void;
}

export type DropdownChipProps = {
  className?: string;
  title?: string;
  label?: string;
  placeholder?: string;
  icon?: 'settings' | React.ReactNode;
  renderDropdown?: (p:DropdownChipRenderDropdownProps) => React.ReactNode;
}

export function DropdownChip(props: DropdownChipProps) {
  const { className, label, title, placeholder, icon, renderDropdown } = props;
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const chipRef = useRef();

  return (
    <div className={classes.container}>
      {title && <div className={classes.title}>{title}</div>}
      <div
        ref={chipRef}
        onClick={() => setIsOpen(!isOpen)}
        className={cx(classes.chip, isOpen && classes.chipOpen, className)}
      >
        {label ?
          <div className={classes.label}>{label}</div> :
          <div className={classes.placeholder}>{placeholder}</div>
        }
        {icon &&
          <div className={classes.icon}>
            {icon === 'settings' ? <Settings fontSize='inherit' />: icon}
          </div>}
      </div>
      {isOpen && (
        <Popper
          className={classes.popper}
          open={isOpen}
          anchorEl={chipRef.current}
          placement={getPlacement(isOpen, chipRef.current)}
        >
          <ClickAwayListener onClickAway={() => setIsOpen(false)}>
            <div
              className={classes.dropdown}
              // this stops dnd-kit from interpreting drag events
              // while the popover is open
              onPointerDown={stopEventPropagation}
            >
              {renderDropdown({
                onClose: () => setIsOpen(false)
              })}
            </div>
          </ClickAwayListener>
        </Popper>
      )}
    </div>
  );
}
