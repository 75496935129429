import React, { useMemo } from 'react';
import { Tooltip } from '@mui/material';
import OndemandVideo from '@mui/icons-material/OndemandVideo';
import Attachment from '@mui/icons-material/Attachment';
import WorkOutline from '@mui/icons-material/WorkOutline';
import PhoneIcon from '@mui/icons-material/PhoneAndroid';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import HorizontalSplitIcon from '@mui/icons-material/HorizontalSplit';
import InsertChartOutlinedOutlinedIcon from '@mui/icons-material/InsertChartOutlinedOutlined';
import ShieldAvatar from '../users/ShieldAvatar';
import { isPhoneArtifact } from '../../services/artifacts';
import { formatBytes } from '@shield-ui/utils';
import { Artifact } from '@hmd/sdk/api/artifacts/v1';
import { useArtifactTypes, useUser } from '../../services/newArtifacts';

type Props = {
  artifact: Artifact.AsObject;
  includeTooltip: boolean;
  [Key: string]: any;
};

function ArtifactIcon({ artifact, includeTooltip, ...rest }: Props) {
  const { artifactTypeSlug, size } = artifact;

  const { data: user } = useUser(artifact.lifeCycleDetails?.createUserId);

  const { data: artifactTypes } = useArtifactTypes();

  const artifactType = useMemo(
    () => artifactTypes?.find((type) => type.slug === artifactTypeSlug),
    [artifactTypes, artifactTypeSlug]
  );

  let Icon: React.ElementType = Attachment;

  switch (artifactTypeSlug) {
    case 'video-primary':
      Icon = OndemandVideo;
      break;
    case 'rosbag':
      Icon = WorkOutline;
      break;
    case 'coredump':
      Icon = BrokenImageIcon;
      break;
    case 'plotly-json':
      Icon = InsertChartOutlinedOutlinedIcon;
      break;
    case 'trace-events-json':
      Icon = HorizontalSplitIcon;
      break;
    default:
      break;
  }

  if (isPhoneArtifact(artifactTypeSlug)) {
    Icon = PhoneIcon;
  }

  // Builds ShieldAvatar if the account_type is a user account
  if (user && user.accountType === 'OAUTH_ACCOUNT') {
    Icon = ShieldAvatar;
    rest.style = {
      height: 20,
      width: 20,
      borderRadius: '50%',
    };
    rest.user = user;
  }

  if (!includeTooltip) {
    return <Icon {...rest} />;
  }
  return (
    <Tooltip
      title={
        <React.Fragment>
          <div>
            Type: <b>{artifactType?.name ?? 'File'}</b>
          </div>
          <div>
            Size: <b>{formatBytes(size)}</b>
          </div>
          {user && user.name && (
            <div>
              CreatedBy: <b>{user.name}</b>
            </div>
          )}
        </React.Fragment>
      }
    >
      <span> 
        {/* Tooltip needs span in order to wrap ShieldAvatar */}
       <Icon {...rest} />
      </span>
    </Tooltip>
  );
}
export default ArtifactIcon;
