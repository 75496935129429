import React from 'react';
import _ from 'lodash';
import withStyles from '@mui/styles/withStyles';
import { Typography } from '@mui/material';

function styles(theme) {
  return {
    progressMeter: {
      display: 'flex',
      height: 30,
      transition: '2s ease',
      backgroundColor: `rgba(255, 255, 255, .2)`,
      width: '100%',
      '&:hover': {
        opacity: 1,
      },
    },
    completeChunk: {
      height: '100%',
      backgroundColor: theme.palette.primary.main,
    },
    pendingChunk: {
      height: '100%',
      backgroundColor: '#e04c7e',
    },
    countSubTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      color: '#ccc',
      marginTop: 5,
    },
    hr: {
      borderColor: '#666',
    },
    row: {
      marginBottom: 15,
    },
  };
}

class ReviewStats extends React.Component {
  renderItem({ label, totalCount, progressCount, assignedCount }) {
    const { classes } = this.props;

    const completeValue = (100 * progressCount) / totalCount;
    const pendingValue = (100 * assignedCount) / totalCount;

    return (
      <div className={classes.row} key={label}>
        <Typography variant="overline">{label}</Typography>
        <div className={classes.progressMeter}>
          <div
            className={classes.completeChunk}
            style={{ width: `${completeValue}%` }}
          />
          <div
            className={classes.pendingChunk}
            style={{ width: `${pendingValue}%` }}
          />
        </div>
        <div className={classes.countSubTitle}>
          <div>
            Complete: {progressCount} / {totalCount}
          </div>
          <div>{!!assignedCount && `Assigned: ${assignedCount}`}</div>
        </div>
      </div>
    );
  }

  render() {
    const { reviews, reviewStats, classes, type } = this.props;

    if (!reviews || !reviewStats) {
      return null;
    }

    const reviewStatMap = _.keyBy(reviewStats.byReviewBreakdown, 'reviewId');
    const { totalCount, totalProgressCount } = reviewStats;

    return (
      <React.Fragment>
        {type === "review" ? 
          this.renderItem({
            label: 'Total Complete Reviews for Children',
            totalCount,
            progressCount: totalProgressCount,
          })
        :
          <>
            {this.renderItem({
              label: 'Total Complete Reviews for Collection',
              totalCount,
              progressCount: totalProgressCount,
            })}
            <br />
            <hr className={classes.hr} />
            <br />
            {reviews.map((review) => {
              const label =
                _.get(review, 'reviewerTeam.name') ||
                _.get(review, 'reviewer.name');
              const stats = reviewStatMap[review.id];
              if (!label || !stats) {
                return null;
              }

              return this.renderItem({
                label,
                totalCount,
                ...stats,
              });
            })}
          </>
        }

      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ReviewStats);
