import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import { QueryFilterItem, ManagementProps } from './types';
import QueryFilterControl from '../QueryFilterControl';
import { colors } from '@shield-ui/styles';
import ModalControlLayout from './ModalControlLayout';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0, 1),
    display: 'flex',
    borderBottom: `1px solid ${colors.hues.grays[200]}`,
    alignItems: 'flex-start',
  },
  checkbox: {
    padding: theme.spacing(1),
    margin: theme.spacing(0.5),
  },
  name: {
    width: '40%',
    marginTop: 4,
    fontWeight: 500,
    flexShrink: 0,
    letterSpacing: '0.4px',
    padding: theme.spacing(1.5),
  },
  controlWrapper: {
    padding: theme.spacing(0.5),
    flex: 1,
    borderLeft: `1px solid ${colors.hues.grays[200]}`,
  },
}));

interface Props {
  queryFilterItem: QueryFilterItem;
  values: object;
  setValues: (args: object) => void;
  management: ManagementProps;
}

function ManageFilter(props: Props) {
  const { queryFilterItem, values, setValues, management } = props;
  const { queryFilter } = queryFilterItem;
  const classes = useStyles();

  const qfId = queryFilter.getId();
  const label = queryFilter.getControlLabel();
  const description = queryFilter.getDescription();

  return (
    <div className={classes.container}>
      <Checkbox
        className={classes.checkbox}
        color="primary"
        checked={management.ids.includes(qfId)}
        onChange={(evt) => {
          const { checked } = evt.target;

          if (checked) {
            management.setIds(management.ids.concat([qfId]));
          } else {
            management.setIds(management.ids.filter((id) => id !== qfId));
          }
        }}
      />
      <Tooltip title={description}>
        <div className={classes.name}>{label}</div>
      </Tooltip>
      <div className={classes.controlWrapper}>
        <QueryFilterControl
          key={qfId}
          queryFilter={queryFilter}
          values={values}
          setValues={setValues}
          LayoutComponent={ModalControlLayout}
        />
      </div>
    </div>
  );
}

export default ManageFilter;
