import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Close';

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: 16,
    marginLeft: theme.spacing(0.5),
  },
}));

interface Props {
  onClick: () => void;
  disabled?: boolean;
}

export default function ResetButton(props: Props) {
  const { onClick, disabled } = props;
  const classes = useStyles();
  return (
    <Button size="small" disabled={disabled} onClick={onClick}>
      Clear <ClearIcon className={classes.icon} />
    </Button>
  );
}
