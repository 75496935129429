import React from 'react';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import { Modal } from '@mui/material';
import container, { ContainerProps } from './modalControllerContainer';
import { getModalComponent } from './modalCache';

const styles = createStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export type ModalControllerComponentProps = Pick<
  ContainerProps,
  'hideModal' | 'showSuccessSnack' | 'showErrorSnack'
>;

interface ModalRootProps extends WithStyles<typeof styles>, ContainerProps {}

class ModalRoot extends React.Component<ModalRootProps> {
  onHideModal = () => {
    const { hideModal } = this.props;
    hideModal();
  };

  render() {
    const {
      visibleModalType,
      modalContextProps = {},
      showSuccessSnack,
      showErrorSnack,
      classes,
    } = this.props;

    const Component = getModalComponent(visibleModalType);

    return (
      <Modal
        open={!!(Component && visibleModalType)}
        onClose={this.onHideModal}
        className={classes.modal}
      >
        {Component ? (
          <div>
            <Component
              hideModal={this.onHideModal}
              showSuccessSnack={showSuccessSnack}
              showErrorSnack={showErrorSnack}
              {...modalContextProps}
            />
          </div>
        ) : (
          <div />
        )}
      </Modal>
    );
  }
}

export default container(withStyles(styles)(ModalRoot));
