import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Slider, InputNumber } from 'antd';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    width: '100%',
  },
  slider: {
    flex: 1,
  },
});

type Props = {
  min: number;
  max: number;
  value: number;
  onChange: (value: number | undefined) => void;
};

export default function SliderIntegerStep(props: Props) {
  const [tmpValue, setTempValue] = useState(undefined);
  const classes = useStyles();
  const { min, max, value, onChange } = props;

  const onFinalChange = (newValue) => {
    onChange(newValue || undefined);
    setTempValue(undefined);
  };

  const displayValue = typeof tmpValue === 'number' ? tmpValue : value;

  return (
    <div className={classes.container}>
      <Slider
        className={classes.slider}
        min={min}
        max={max}
        onChange={setTempValue}
        onAfterChange={onFinalChange}
        value={typeof displayValue === 'number' ? displayValue : min}
      />
      <InputNumber
        min={min}
        max={max}
        style={{ margin: '0 16px' }}
        value={displayValue}
        onChange={onFinalChange}
      />
    </div>
  );
}
