import padStart from 'lodash/padStart';
import ms from 'ms';
import moment, { Moment } from 'moment';
import formatDistance from 'date-fns/formatDistance';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';

export const getIsoDate = (dateToConvert) => {
  const date = new Date(dateToConvert);
  const getYear = date.toLocaleString('default', { year: 'numeric' });
  const getMonth = date.toLocaleString('default', { month: '2-digit' });
  const getDay = date.toLocaleString('default', { day: '2-digit' });
  const dateFormat = getYear + '-' + getMonth + '-' + getDay;
  return dateFormat;
};

export const formatDurationAsHourMinute = (durationMs) => {
  return `${Math.floor(durationMs / ms('1 hour'))}h ${Math.floor(
    (durationMs % ms('1 hour')) / ms('1 minute')
  )}m`;
};

export const formatDurationAsMinuteSecond = (durationMs) => {
  return `${Math.floor(durationMs / ms('1 minute'))}m ${Math.floor(
    (durationMs % ms('1 minute')) / ms('1 second')
  )}s`;
};

export const abbrevFormatDuration = (inputSeconds: number): string => {
  const isNegative = inputSeconds < 0;
  const seconds = Math.abs(inputSeconds);
  const sec = seconds % 60;
  const min = Math.floor(seconds / 60) % 60;
  const hr = Math.floor(seconds / 60 / 60);

  let ret = isNegative ? '-' : '';

  if (hr > 0) {
    ret += `${hr}h`;
  }
  if (min > 0) {
    ret += `${min}m`;
  }
  if (sec > 0 || (hr === 0 && min === 0)) {
    ret += `${sec}s`;
  }
  return ret;
};

export const minFormatDuration = (inputSeconds: number): string => {
  const isNegative = inputSeconds < 0;
  const seconds = Math.abs(inputSeconds);
  const sec = seconds % 60;
  const min = Math.floor(seconds / 60) % 60;
  const hr = Math.floor(seconds / 60 / 60);

  const pad = (num) => padStart(num.toString(), 2, '0');
  if (hr > 0) {
    return `${isNegative ? '-' : ''}${pad(hr)}:${pad(min)}:${pad(sec)}`;
  }
  return `${isNegative ? '-' : ''}${pad(min)}:${pad(sec)}`;
};

export const smartFormatDuration = (durationMs) => {
  return durationMs >= ms('1 hour')
    ? formatDurationAsHourMinute(durationMs)
    : formatDurationAsMinuteSecond(durationMs);
};

export function getShortDateLocal(date) {
  return moment.utc(date).local().format('MM/DD/YYYY');
}

export const getShortDate = (date) => {
  const workingDate = safeDate(date);
  return (
    workingDate &&
    workingDate.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    })
  );
};

export function getPrettyTimeCode(seconds: number): string {
  const ss = `${seconds % 60}`.padStart(2, '0');
  const mm = `${Math.floor(seconds / 60) % 60}`.padStart(2, '0');
  const hh = `${Math.floor(seconds / 60 / 60) % 24}`.padStart(2, '0');

  return hh === '00' ? `${mm}:${ss}` : `${hh}:${mm}:${ss}`;
}

export const getTimeString = (date, hour12) => {
  const workingDate = safeDate(date);
  hour12 = hour12 || false;
  return (
    workingDate &&
    workingDate.toLocaleTimeString('en-US', {
      hour12,
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    })
  );
};

type DateLike = Date | number | string;

export const safeDate = (date: DateLike): Date => {
  if (date instanceof Date) {
    return date;
  }

  const ret = new Date(date);
  if (!isValid(ret)) {
    console.error(`Invalid date (${date}) passed to safe date`);
    // making an incorrect date object to move forward
    // we are only hitting this if we are bugged
    return new Date();
  }
  return ret;
};

export function getFormattedDateTime(date: DateLike) {
  if (!date) {
    return;
  }
  return format(safeDate(date), 'MM/dd/yy HH:mm:ss');
}
/**
 * Get the delta in seconds between two date like objects
 */
export const getTimeDifferenceInSeconds = (
  higherDateLike: DateLike,
  lowerDateLike: DateLike
): number => {
  if (!higherDateLike || !lowerDateLike) {
    return 0;
  }

  const workingHigherDate = safeDate(higherDateLike);
  const workingLowerDate = safeDate(lowerDateLike);

  return (workingHigherDate.getTime() - workingLowerDate.getTime()) / 1000;
};

/**
 * Given two dates return a nicely formatted representation of the delta between the two (e.g. 4hr 3min)
 */
export function getDifferenceInDuration(
  higherDateLike: DateLike,
  lowerDateLike: DateLike
): string {
  if (!higherDateLike || !lowerDateLike) {
    return '';
  }
  return formatDistance(safeDate(higherDateLike), safeDate(lowerDateLike));
}

export function getTimezoneMinDisplay() {
  try {
    const x = new Date();
    return x.toLocaleString('en', { timeZoneName: 'short' }).split(' ').pop();
  } catch (e) {
    return '';
  }
}
export const prettyTimeDiffNow = (timestamp: DateLike) => {
  return getPrettyTimeDifferenceSeconds(
    moment(timestamp).utc().unix(),
    moment().utc().unix()
  );
};

export const getPrettyTimeDifferenceSeconds = (
  startTime: number,
  endTime: number
) => {
  if (startTime === 0 || endTime === 0) {
    return '';
  }

  let seconds = endTime - startTime;
  if (seconds < 0) {
    seconds = 0;
  }
  const secondsHour = 3600;
  const secondsDay = 3600 * 24;
  const secondsYear = 3600 * 24 * 365;

  //seconds
  if (seconds < 60) {
    return `${seconds}s ago`;
  }
  //minutes
  else if (seconds < secondsHour) {
    const m = Math.floor(seconds / 60);
    return `${m}m ago`;
  }
  //hours
  else if (seconds < secondsDay) {
    const h = Math.floor(seconds / secondsHour);
    return `${h}h ago`;
  }
  //days
  else if (seconds < secondsYear) {
    const d = Math.floor(seconds / secondsDay);
    return `${d}d ago`;
  }
  //year
  else {
    return '>1y ago';
  }
};

export const getPrettyFullDate = (timestamp) => {
  const date = new Date(timestamp);
  const prettyTimeMonthDay = date.toLocaleString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
  const prettyTimeHourMinute = date.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
  });

  return `${prettyTimeMonthDay} at ${prettyTimeHourMinute}`;
};
