import { Modal, Form, InputNumber, Input } from 'antd';
import React, { useEffect, useState } from 'react';

const MetricEditModal = ({ visible, metric, onUpdate, onCancel }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(metric);
  }, [form, metric]);

  const handleOk = () => {
    form.validateFields().then((values) => {
      form.resetFields();
      onUpdate({ ...metric, ...values });
    });
  };

  return (
    <Modal
      open={visible}
      title="Edit Metric"
      okText="Update"
      onCancel={onCancel}
      onOk={handleOk}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={metric}
      >
        <Form.Item name="name" label="Name">
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="value"
          label="Value"
          rules={[
            { required: true, message: 'Please enter the value of the metric' },
          ]}
        >
          <InputNumber />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const ExpectationEditModal = ({ visible, expectation, onUpdate, onCancel }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(expectation);
  }, [expectation]);

  const handleOk = () => {
    form.validateFields().then((values) => {
      form.resetFields();
      onUpdate({ ...expectation, ...values });
    });
  };

  return (
    <Modal
      visible={visible}
      title="Edit Expectation"
      okText="Update"
      onCancel={onCancel}
      onOk={handleOk}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={expectation}
      >
        <Form.Item name="name" label="Name">
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="value"
          label="Value"
          rules={[
            {
              required: true,
              message: 'Please enter the value of the expectation',
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          name="threshold"
          label="Threshold"
          rules={[
            {
              required: true,
              message: 'Please enter the threshold of the expectation',
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const EditMetricOrExpectationModal = ({
  visible,
  data,
  onUpdate,
  onCancel,
}) => {
  if (data?.threshold !== undefined) {
    return (
      <ExpectationEditModal
        visible={visible}
        expectation={data}
        onUpdate={onUpdate}
        onCancel={onCancel}
      />
    );
  } else {
    return (
      <MetricEditModal
        visible={visible}
        metric={data}
        onUpdate={onUpdate}
        onCancel={onCancel}
      />
    );
  }
};

export default EditMetricOrExpectationModal;
