import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import withStyles from '@mui/styles/withStyles';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { colors, commonStyles } from '@shield-ui/styles';

function styles(theme) {
  return {
    root: {
      display: 'flex',
      padding: `10px 12px`,
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: colors.semantic.inputBackground,
      border: `1px solid ${colors.semantic.inputBorder}`,
      borderRadius: theme.shape.borderRadius,
      margin: theme.spacing(0.25, 0),
      transition: '.2s ease',
      cursor: 'pointer',
      '&:hover': {
        borderColor: colors.semantic.inputBorderHover,
      },
    },
    rootDisabled: {
      '&:hover': {
        borderColor: colors.semantic.inputBorder,
      },
    },
    value: {
      fontSize: 14,
    },
    valueDisabled: {
      color: colors.semantic.inputTextDisabled,
    },
    placeholder: {
      ...commonStyles.placeholder,
    },
    icon: {
      color: colors.semantic.inputIcon,
      marginRight: 6, // make it align with the icon in integrated react-select
    },
  };
}

function NonInteractiveDropdownBase({
  placeholder,
  valueDisplay,
  disabled,
  classes,
  ...rest
}) {
  return (
    <div
      className={cx(classes.root, disabled && classes.rootDisabled)}
      {...rest}
    >
      {valueDisplay ? (
        <span className={cx(classes.value, disabled && classes.valueDisabled)}>
          {valueDisplay}
        </span>
      ) : (
        <span className={classes.placeholder}>{placeholder}</span>
      )}
      <ExpandMore className={classes.icon} />
    </div>
  );
}

NonInteractiveDropdownBase.propTypes = {
  placeholder: PropTypes.string,
  valueDisplay: PropTypes.string,
};

export default withStyles(styles)(NonInteractiveDropdownBase);
