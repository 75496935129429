import _ from 'lodash';
import { createReducer } from 'redux-nano';
import update from 'immutability-helper';
import { SET_LISTS, SET_LIST_REQUESTING, SET_LIST_ITEMS } from './actions';

function getItemId(item) {
  // platformId is for tickets because they work non-standard
  return item.id || item.platformId;
}

export const INITIAL_EMPTY_LIST = [];

export default createReducer(
  {
    isRequesting: false,
    isInitialized: false,
    listTypeMap: {
      causes: INITIAL_EMPTY_LIST,
      components: INITIAL_EMPTY_LIST,
      environment: INITIAL_EMPTY_LIST,
      logDefinitions: INITIAL_EMPTY_LIST,
      missions: INITIAL_EMPTY_LIST,
      robotLogTests: INITIAL_EMPTY_LIST,
      robots: INITIAL_EMPTY_LIST,
      severities: INITIAL_EMPTY_LIST,
      softwareInfos: INITIAL_EMPTY_LIST,
      tags: INITIAL_EMPTY_LIST,
      // not fetched, manually populated
      analysisNames: INITIAL_EMPTY_LIST,
      labels: INITIAL_EMPTY_LIST,
      metricNames: INITIAL_EMPTY_LIST,
      tickets: INITIAL_EMPTY_LIST,
    },
  },
  {
    [SET_LIST_REQUESTING]: (state) => {
      return update(state, {
        isRequesting: { $set: true },
      });
    },
    [SET_LISTS]: (state, action) => {
      const { listTypeMap } = action.payload;

      return update(state, {
        listTypeMap: { $merge: listTypeMap },
        isRequesting: { $set: false },
        isInitialized: { $set: true },
      });
    },
    [SET_LIST_ITEMS]: (state, action) => {
      const { listKey, items } = action.payload;

      const list = _.get(state, ['listTypeMap', listKey], []).slice();
      const idToIndexMap = list.reduce((acc, item, index) => {
        acc[getItemId(item)] = index;
        return acc;
      }, {});

      items.forEach((item) => {
        const existingIndex = idToIndexMap[getItemId(item)];
        if (!_.isUndefined(existingIndex)) {
          list[existingIndex] = item;
        } else {
          list.push(item);
        }
      });

      return update(state, {
        listTypeMap: {
          [listKey]: { $set: list },
        },
      });
    },
  }
);
