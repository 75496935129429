import React, { useState } from 'react';
import {
  DndContext,
  DragOverlay,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import makeStyles from '@mui/styles/makeStyles';

type Props = {
  ids: string[];
  setIds: (ids: string[]) => void;
  renderItem: (args: {
    id: string;
    index: number;
    activeIndex?: number;
  }) => React.ReactNode;
  renderDragContent: (args: { id: string }) => React.ReactNode;
  renderPostfix?: () => React.ReactNode;
};

const useStyles = makeStyles((theme) => ({
  controlsWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    minHeight: theme.spacing(6),
  },
}));

export default function SortableBarContainer(props: Props) {
  const { ids, setIds, renderItem, renderDragContent, renderPostfix } = props;
  // maintain an activeId state so can use DragOverlay and leave our original element
  // in the dom to prevent a lot of graphic ghosting / glitch affects
  const [activeId, setActiveId] = useState<string | null>(null);

  const classes = useStyles();

  // dnd-kit sensors
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );

  function handleDragStart({ active }) {
    setActiveId(active.id);
  }

  function handleDragCancel() {
    setActiveId(null);
  }

  function handleDragEnd(e) {
    const { active, over } = e;

    if (over) {
      const oldIndex = ids.indexOf(active.id);
      const newIndex = ids.indexOf(over.id);
      if (oldIndex !== newIndex) {
        const newIds = arrayMove(ids, oldIndex, newIndex) as string[];
        setIds(newIds);
      }
    }
    setActiveId(null);
  }

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
      autoScroll={false}
      sensors={sensors}
      collisionDetection={closestCenter}
    >
      <SortableContext items={ids} strategy={horizontalListSortingStrategy}>
        <div className={classes.controlsWrapper}>
          {ids.map((id, index) =>
            renderItem({
              id,
              index,
              activeIndex: activeId ? ids.indexOf(activeId) : undefined,
            })
          )}
          {renderPostfix && renderPostfix()}
        </div>
      </SortableContext>
      <DragOverlay>
        {activeId ? (
          <div style={{ opacity: 0.5 }}>
            {renderDragContent({ id: activeId })}
          </div>
        ) : null}
      </DragOverlay>
    </DndContext>
  );
}
