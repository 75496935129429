import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { resolveSnackMessages } from '../../redux/snackbar/actions';

function mapStateToProps(state) {
  return {
    snackbarQueue: state.snackbar.snackbarQueue,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resolveSnackMessages,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps);
