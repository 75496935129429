import React from 'react';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import { Typography } from '@mui/material';

function styles() {
  return createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 40,
    },
    accent: {
      width: 4,
      height: 27,
      borderRadius: 1,
      marginRight: 8,
      marginTop: 2,
    },
    title: {
      flex: 1,
      marginRight: 20,
      fontSize: 28,
    },
  });
}

interface Props extends WithStyles<typeof styles> {
  title: string;
  style?: object;
  accentColor?: string;
}

const PageHeader: React.FC<Props> = (props) => {
  const {
    classes,
    children,
    title,
    style,
    accentColor,
  } = props;

  return (
    <div className={classes.container} style={style}>
      {accentColor && (
        <div
          className={classes.accent}
          style={{ backgroundColor: accentColor }}
        />
      )}
      <Typography variant="h2" className={classes.title}>
        {title}
      </Typography>
      {children && <div>{children}</div>}
    </div>
  );
}

export default withStyles(styles)(PageHeader);
