import React, { createRef, Component, RefObject } from 'react';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Theme } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { colors } from '@shield-ui/styles';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      border: `1px solid  ${theme.palette.background.paper}`,
      width: '100%',
      height: '100%',
      transition: '0.1s ease',
      cursor: 'pointer',
      userSelect: 'none',
      '&:hover': {
        borderColor: colors.hues.grays[150],
        backgroundColor: colors.hues.grays[170],
      },
    },
    icon: {
      fontSize: 48,
      color: colors.hues.grays[80],
      marginTop: theme.spacing(2)
    },
    input: {
      display: 'none',
    },
  });

export interface UploadTarget extends WithStyles<typeof styles> {
  onFiles: (files, event) => void;
  prompt?: React.ReactNode;
}

class DropPageTargetInput extends Component<UploadTarget> {
  input: RefObject<HTMLInputElement> = createRef();

  inputOnChange = (event) => {
    this.props.onFiles(Array.from(this.input.current.files), event);
  };

  onClick = () => {
    this.input.current.click();
  };

  render() {
    const { classes, prompt } = this.props;
    return (
      <div className={classes.container} onClick={this.onClick}>
        {prompt || <div>Browse Files</div>}
        <FileCopyIcon className={classes.icon}/>
        <input
          ref={this.input}
          className={classes.input}
          onChange={this.inputOnChange}
          type="file"
          multiple
          alt="Drop Page Target Input"
          autoComplete="off"
          tabIndex={-1}
        />
      </div>
    );
  }
}
export default withStyles(styles)(DropPageTargetInput);
