import _ from 'lodash';
import analysis from './columns/analysis';
import analyzerOverview from './columns/analyzerOverview.js';
// import artifactCount from './columns/artifactCount';
// import artifactsBag from './columns/artifactsBag';
import blockInfo from './columns/blockInfo';
import children from './columns/children';
import childrenCount from './columns/childrenCount';
import createdTime from '../generic/common_columns/createdAt';
import environment from './columns/environment';
import eventCauses from './columns/eventCauses';
import eventTags from './columns/eventTags';
import eventTickets from './columns/eventTickets';
import flightTimeLocal from './columns/flightTimeLocal';
import ftsSheet from './columns/ftsSheet';
import labels from '../generic/common_columns/labels';
import metric from './columns/metric';
import missionType from './columns/missionType';
import name from './columns/name';
import uniqueName from './columns/uniqueName';
import notes from './columns/notes';
import notesObserver from './columns/notesObserver';
import notesPostReview from './columns/notesPostReview';
import parent from './columns/parent';
import participantsObserver from './columns/participantsObserver';
import participantsPilot from './columns/participantsPilot';
import phoneName from './columns/phoneName';
import phoneSoftwareVersion from './columns/phoneSoftwareVersion';
import reviewStatusGetConfig from '../generic/common_column_factories/reviewStatus';
import robotHostName from './columns/robotHostname';
import robotLogData from './columns/robotLogData';
import robotLogId from './columns/robotLogId';
import robotLogTestCode from './columns/robotLogTestCode';
import robotLogType from './columns/robotLogType';
import softwareInfo from './columns/softwareInfo';
import softwareCommitHash from './columns/softwareCommitHash';
import totalTime from './columns/totalTime';
import viewedLastAt from './columns/viewedLastAt';
import { genericRobotLogDataAttributes } from '../../../lib/robotLogRobotLogDataAttributes';
import { genericDcoAttributes } from '../../../lib/dcoAttributes';
import { SYSTEM_DEFAULT_SESSION_LOG_TYPES } from '../../../services/sessionLogTypes';

// computed later on, this array is modified
export const DEFAULT_COLUMN_STATE = [];
export const FIXED_COLUMN_STATE = [];
export const DEFAULT_PINNED_STATE = { left: ['name'], right: [] };
export const DEFAULT_COLUMN_WIDTHS = {};
const DEFAULT_COLUMN_WIDTH = 200;

// These don't correspond to "filters" per-say but they are factored into the robotLots query
export const DEFAULT_SORT_STATE = {
  orderByColumn: flightTimeLocal.columnKey,
  orderByDirection: 'desc',
};

const DEFAULT_COLUMNS = [
  environment,
  flightTimeLocal,
  robotHostName,
  totalTime,
  reviewStatusGetConfig({
    relationshipKey: 'robotLogReviews',
    label: 'Reviews',
  }),
  eventTags,
];

const FIXED_COLUMNS = [name];

const OTHER_COLUMNS = [
  analysis,
  analyzerOverview,
  // artifactCount,
  // artifactsBag,
  blockInfo,
  children,
  childrenCount,
  createdTime,
  eventCauses,
  eventTickets,
  ftsSheet,
  labels,
  metric,
  missionType,
  notes,
  notesObserver,
  notesPostReview,
  parent,
  participantsObserver,
  participantsPilot,
  phoneName,
  phoneSoftwareVersion,
  robotLogData,
  robotLogId,
  robotLogType,
  robotLogTestCode,
  softwareCommitHash,
  softwareInfo,
  uniqueName,
  viewedLastAt,
  ...genericRobotLogDataAttributes.map(getColumnFromRobotLogDataAttribute),
  ...genericDcoAttributes.map(getColumnFromRobotLogDataAttribute),
];

export const getDefaultColumnsForSessionLogType = _.memoize(
  (sessionLogTypeSlug) => {
    // special, overload the global defaults
    if (
      sessionLogTypeSlug === SYSTEM_DEFAULT_SESSION_LOG_TYPES.test ||
      sessionLogTypeSlug === SYSTEM_DEFAULT_SESSION_LOG_TYPES.trial
    ) {
      return [
        childrenCount,
        robotLogTestCode,
        flightTimeLocal,
        totalTime,
        analyzerOverview,
        eventTags,
      ].map((def) => ({
        columnKey: def.columnKey,
      }));
    }
  }
);

function getColumnFromRobotLogDataAttribute(attr) {
  return {
    columnKey: `robotLogData-${attr.key}`,
    label: attr.label,
    description: attr.description,
    getValue: ({ row }) => '' + _.get(row, ['robotLogData', attr.key], ''),
    getElasticSort: ({ orderByDirection }) => {
      return [
        {
          [`robot_log_data.${attr.key}`]: {
            order: orderByDirection,
            missing: '_last',
          },
        },
      ];
    },
    fragment: `
      robotLogData
    `,
  };
}

// REGISTER ALL OF THE COLUMN DEFINITIONS
const COLUMN_MAP = DEFAULT_COLUMNS.concat(OTHER_COLUMNS)
  .concat(FIXED_COLUMNS)
  .reduce((map, col, i) => {
    if (!col.columnKey) {
      console.warn(col);
      throw new Error('Missing column key for col');
    }
    if (map[col.columnKey]) {
      throw new Error(
        `You are trying to add the same column key for multiple column definitions ${col.columnKey}`
      );
    }

    map[col.columnKey] = col;

    if (DEFAULT_COLUMNS.includes(col)) {
      DEFAULT_COLUMN_STATE.push(_.pick(col, ['columnKey']));
      col.isDefault = true;
    }

    if (FIXED_COLUMNS.includes(col)) {
      FIXED_COLUMN_STATE.push({ columnKey: col.columnKey });
      col.isFixed = true;
    }

    if (!col.defaultWidth) {
      col.defaultWidth = DEFAULT_COLUMN_WIDTH;
    }

    // Dynamic thing e.g select a metric column, and which metric
    if (col.dataInputOptions) {
      col.isDynamic = true;
    }

    col.index = i;
    return map;
  }, {});

export function getColumnDefinition({ columnKey }) {
  return COLUMN_MAP[columnKey];
}

export function getAllColumnDefinitions() {
  return _.sortBy(_.values(COLUMN_MAP), 'index');
}
