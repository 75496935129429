import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import ModalHeader from '../../../../modals/Header';
import container from './container';

import { Paper, Button } from '@mui/material';

function styles(theme) {
  return {
    container: {
      padding: `25px 40px`,
      maxWidth: 600,
      // not sure why this gets a focus outline
      outline: 'none',
    },
    preview: {
      maxHeight: 300,
      overflow: 'auto',
    },
    paragraph: {
      fontSize: 18,
    },
    buttonContainer: {
      marginTop: 30,
      display: 'flex',
      justifyContent: 'flex-end',
      '& > button': {
        marginLeft: 10,
      },
    },
    showPreview: {
      color: theme.palette.primary.light,
      cursor: 'pointer',
    },
  };
}

class LoadSaveStateModal extends React.Component {
  static propTypes = {
    tableCacheKey: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      isShowingPreview: false,
    };
  }

  showPreview = () => {
    this.setState({ isShowingPreview: true });
  };

  proceed = () => {
    const { loadSaveState, hideModal, saveState } = this.props;

    loadSaveState({ saveState });
    hideModal();
  };

  render() {
    const { isShowingPreview } = this.state;

    const { classes, hideModal, saveState } = this.props;

    return (
      <Paper className={classes.container}>
        <ModalHeader onClose={hideModal}>Load Save State</ModalHeader>
        <p className={classes.paragraph}>
          You entered a URL that contains a saveState configured in it. This
          will replace any auto save, session configuration you may have. This
          includes columns, filters, etc... You can{' '}
          <span className={classes.showPreview} onClick={this.showPreview}>
            preview saveState
          </span>{' '}
          if your are unsure.
        </p>
        {isShowingPreview && (
          <pre className={classes.preview}>
            {JSON.stringify(saveState, null, 1)}
          </pre>
        )}
        <div className={classes.buttonContainer}>
          <Button onClick={hideModal}>Cancel</Button>

          <Button variant="contained" color="primary" onClick={this.proceed}>
            Yes, Proceed
          </Button>
        </div>
      </Paper>
    );
  }
}

export default container(withStyles(styles)(LoadSaveStateModal));
