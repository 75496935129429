import React from 'react';
import _ from 'lodash';
import cx from 'classnames';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import Avatar from '@mui/material/Avatar';
import { Account } from '@shield-ui/icons';
import { getShortAcronym, getDynamicColorFromString } from '@shield-ui/utils';
import { getMSGraphPictureUrl } from '../../lib/mapi';
import { HangarTeamType, HangarUserType } from '@shield-ui/hangar-service';

const styles = (theme) => {
  return createStyles({
    container: {
      height: 40,
      width: 40,
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      fontSize: 17,
      color: '#333',
      marginRight: theme.spacing(0.5),
    },
    containerBorder: {
      boxShadow: `0 0 0 2px ${theme.palette.secondary.dark}`,
    },
  });
};

interface Props extends WithStyles<typeof styles> {
  user?: Pick<HangarUserType, 'name' | 'picture' | 'email' | 'providerName'>;
  team?: Pick<HangarTeamType, 'name' | 'picture'>;
  showBorder?: boolean;
  className?: string;
}

class ShieldAvatar extends React.Component<Props> {
  render() {
    const { classes, user, team, showBorder, className, ...rest } = this.props;
    const rootClass = cx(
      classes.container,
      className,
      showBorder && classes.containerBorder
    );

    // Auth required - picture url
    if (user && user.providerName === 'microsoft') {
      return (
        <Avatar
          classes={{ root: rootClass }}
          alt={user.name}
          src={getMSGraphPictureUrl(user.email)}
          {...rest}
        >
          {_.get(user, 'name.0')}
        </Avatar>
      );
    }

    // Auth not required - picture url
    if (user && user.picture) {
      return (
        <Avatar
          classes={{ root: rootClass }}
          alt={user.name}
          src={user.picture}
          {...rest}
        />
      );
    } else if (user && user.name) {
      return (
        <Avatar
          classes={{ root: rootClass }}
          style={{ backgroundColor: getDynamicColorFromString(user.name) }}
          {...rest}
        >
          {getShortAcronym(user.name)}
        </Avatar>
      );
    } else if (team && team.picture) {
      return (
        <Avatar
          classes={{ root: rootClass }}
          alt={team.name}
          src={team.picture}
          {...rest}
        />
      );
    } else if (team && team.name) {
      return (
        <div
          className={rootClass}
          {...rest}
          style={{ backgroundColor: getDynamicColorFromString(team.name) }}
        >
          {getShortAcronym(team.name)}
        </div>
      );
    }

    return <Account className={rootClass} {...rest} />;
  }
}

export default withStyles(styles)(ShieldAvatar);
