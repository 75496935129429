import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import container from './container';
import { ensureListsAreLoaded } from '../../../../../redux/lists/util';
import { getFilterChipDefinition } from './formatDefinitions';
import FilterChip from './FilterChip';
import { Tooltip } from '@mui/material';

function defaultRenderChip(props) {
  return <FilterChip {...props} />;
}

/**
 * Need to make key - label for when we hit the group count and then also a resolution function from the "options"
 */
class FilterChipBuilder extends React.PureComponent {
  static defaultProps = {
    filtersVariables: {},
    groupChipMemberLimit: 2,
    maxChipCount: 8,
    renderChip: defaultRenderChip,
  };
  static propTypes = {
    filtersVariables: PropTypes.object,
    groupChipMemberLimit: PropTypes.number,
    maxChipCount: PropTypes.number,
    onChangeVariables: PropTypes.func,
    onShowAll: PropTypes.func,
    renderChip: PropTypes.func,
  };

  constructor(props) {
    super(props);
    ensureListsAreLoaded();
    const { filtersVariables } = props;

    _.forEach(filtersVariables, (value, key) => {
      const def = getFilterChipDefinition(key);
      if (def && def.ensureListItems) {
        def.ensureListItems(props, value);
      }
    });
  }

  onDelete({ key, value }) {
    const { onChangeVariables, filtersVariables } = this.props;

    if (value && _.isArray(filtersVariables[key])) {
      const newValues = filtersVariables[key].filter((v) => v !== value);
      onChangeVariables({
        [key]: newValues.length ? newValues : undefined,
      });
    } else {
      onChangeVariables({
        [key]: undefined,
      });
    }
  }

  render() {
    const {
      listsAreInitialized,
      filtersVariables,
      maxChipCount,
      groupChipMemberLimit,
      onChangeVariables,
      onShowAll,
      renderChip,
    } = this.props;

    if (!listsAreInitialized || !filtersVariables) {
      return null;
    }

    // Whether or not deleting is implemented
    const skipOnDelete = !onChangeVariables;

    // How many in the "See X More" value there should be
    let moreCount = 0;

    // Order the keys by data type
    const keys = _.sortBy(Object.keys(filtersVariables), (key) => {
      const value = filtersVariables[key];

      // most important context
      if (key === 'robotLogTypes') {
        //this is okay to not refactor because it's deprecated, but still needs to be there for old collections.
        return -1;
      }

      if (_.isString(value)) {
        return 0;
      } else if (_.isArray(value)) {
        return 10 + value.length;
      }
      return 5;
    });

    const rows = keys.reduce((acc, key) => {
      const value = filtersVariables[key];
      if (_.isUndefined(value)) {
        return acc;
      }

      if (acc.length > maxChipCount) {
        moreCount += _.isArray(value) ? value.length : 1;
        return acc;
      }

      const def = getFilterChipDefinition(key);
      if (!def) {
        console.warn(`no def for ${key}`);
        acc.push({
          key,
          label: `${key}??`,
        });
      } else if (def.getValue) {
        acc.push({
          key,
          tooltipText: def.fieldDisplay,
          label: def.getValue({ ...this.props, value }),
        });
      } else if (
        _.isArray(value) &&
        def.fieldDisplay &&
        (value.length > groupChipMemberLimit || !def.getItemValue)
      ) {
        let fieldDisplay = def.fieldDisplay;
        if (value.length > 1) {
          if (def.fieldDisplayPlural) {
            fieldDisplay = def.fieldDisplayPlural;
          } else {
            fieldDisplay = fieldDisplay + 's';
          }
        }

        acc.push({
          key,
          label: `${value.length} ${fieldDisplay}`,
        });
      } else if (_.isArray(value) && def.getItemValue) {
        const toAdd = value.slice(0, maxChipCount - acc.length);

        toAdd.forEach((value) => {
          const label = def.getItemValue({ ...this.props, value });
          if (label) {
            acc.push({
              key,
              value,
              tooltipText: def.fieldDisplay,
              label: label,
            });
          }
        });

        moreCount += value.length - toAdd.length;
      }

      return acc;
    }, []);

    return (
      <React.Fragment>
        {rows.map((row) => {
          const onDelete = skipOnDelete ? undefined : () => this.onDelete(row);
          const idKey = row.value ? `${row.key}:${row.value}` : row.key;

          return (
            <Tooltip
              title={row.tooltipText || ''}
              key={idKey}
              placement="top"
              enterDelay={300}
            >
              {renderChip({
                key: idKey,
                label: row.label,
                onDelete: onDelete,
                onClick: onShowAll,
              })}
            </Tooltip>
          );
        })}
        {moreCount > 0 &&
          renderChip({
            label: `... and ${moreCount} more`,
            onClick: onShowAll,
          })}
      </React.Fragment>
    );
  }
}

export default container(FilterChipBuilder);
