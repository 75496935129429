import config from './config';
import history from '../routes/history';

/**
 * Server renamed tracker js and php api file
 * Setup Geolite for Matomo: https://geolite.maxmind.com/download/geoip/database/GeoLite2-City.tar.gz
 */

// - alias because this is how things work more generically, and in all the examples it uses _paq

let TRACKER;
let LAST_PAGE;

window._paq = window._paq || [];

function log() {
  // 1. Convert args to a normal array
  // eslint-disable-next-line prefer-rest-params
  const args = Array.prototype.slice.call(arguments);
  // 2. Prepend log prefix log string
  args.unshift(`matomo-tracker`);
  // 3. Pass along arguments to console.log
  // eslint-disable-next-line prefer-spread
  console.debug.apply(console, args);
}

function getPageUrl() {
  return window.location.href;
}

// https://developer.matomo.org/guides/tracking-javascript-guide
// https://developer.matomo.org/api-reference/tracking-api
/**
 * Setup the MATOMO tracker to our server
 */
export function initTracker() {
  if (TRACKER) {
    return;
  }
  TRACKER = true;
  LAST_PAGE = getPageUrl();
  log(`init`);

  const _paq = window._paq;
  _paq.push(['setSiteId', config.matomo.siteId]);
  _paq.push(['setTrackerUrl', `${config.matomo.url}/matomo.php`]);
  _paq.push(['enableLinkTracking']);
  _paq.push(['trackPageView']);
  _paq.push(['enableHeartBeatTimer']);

  const g = document.createElement('script');
  const s = document.getElementsByTagName('script')[0];
  g.type = 'text/javascript';
  g.async = true;
  g.defer = true;
  g.src = `${config.matomo.url}/matomo.js`;
  s.parentNode.insertBefore(g, s);

  initHistory();
}

/**
 * Since we are a SPA, do some special things when the 'page' changes
 * https://developer.matomo.org/guides/spa-tracking
 */
function initHistory() {
  history.listen((e) => {
    const pageUrl = getPageUrl();
    log(`tracker history listen location change`, pageUrl, e);

    const _paq = window._paq;
    if (LAST_PAGE) {
      _paq.push(['setReferrerUrl', LAST_PAGE]);
    }
    _paq.push(['setCustomUrl', pageUrl]);
    _paq.push(['setDocumentTitle', document.title]);
    _paq.push(['deleteCustomVariables', 'page']);
    _paq.push(['setPagePerformanceTiming', 0]);
    _paq.push(['trackPageView']);

    LAST_PAGE = pageUrl;
  });
}

/**
 * Set the userId on the session
 * @param {uuid} userId
 */
function setUserId(userId) {
  log(`setUserId`, userId);
  const _paq = window._paq;
  _paq.push(['setUserId', userId]);
}

/**
 * Track an event category and action and some other optional properties, something like a user clicked on something
 * @param {string} category
 * @param {string} action
 * @param {string} [name]
 * @param [value]
 */
function trackEvent(category, action, name, value) {
  log(`trackEvent`, category, action, name, value);
  const _paq = window._paq;
  _paq.push(['trackEvent', category, action, name, value]);
}

export default {
  setUserId,
  trackEvent,
};
