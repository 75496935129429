import React from 'react';
import _ from 'lodash';
import container, {
  ContainerProps,
} from './EventManager/containerEventManager';
import { ErrorMessageContent, LoadingIndicator } from '@shield-ui/core';
import EventList from './EventManager/EventList';
import LogEventList from './EventManager/LogEventList';
import { Props as ChildRowDetailsProps } from './EventManager/ChildRowDetails';

interface Props
  extends ContainerProps,
    Pick<ChildRowDetailsProps, 'timeCodeOnClick'> {
  robotLogId: string;
}

class EventManager extends React.Component<Props> {
  refresh() {
    const { robotLogId, refreshResultsForRobotLog, fetched} = this.props;
    //skip if already fetched (e.g. we mounted, went to a new tab, then came back

    if (!fetched) {
      refreshResultsForRobotLog({ robotLogId});
    }
  }

  componentDidMount() {
    this.refresh();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.robotLogId !== this.props.robotLogId) {
      this.refresh();
    }
  }

  render() {
    const { errorMessage, fetching, fetched, logEvents } = this.props;

    if (errorMessage) {
      return <ErrorMessageContent>{errorMessage}</ErrorMessageContent>;
    }
    if (fetching) {
      return <LoadingIndicator />;
    }
    if (fetched) {
      const commonProps = _.pick(this.props, [
        'currentUser',
        'robotLog',
        'timeCodeOnClick',
      ]);

      return (
        <>
          <EventList
            {...commonProps}
            events={this.props.events}
            eventsChildren={this.props.eventsChildren}
            deleteEvent={this.props.deleteEvent}
          />
          {!!logEvents.length && (
            <LogEventList
              {...commonProps}
              logEventDisplayRecords={this.props.logEventDisplayRecords}
              filters={this.props.filters}
              setFilters={this.props.setFilters}
            />
          )}
        </>
      );
    }

    return null;
  }
}

export default container(EventManager);
