import React, { useState, useRef } from 'react';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import DownArrow from '@mui/icons-material/KeyboardArrowDown';
import { colors } from '@shield-ui/styles';

/**
 * Every Control built around Base should extend these properties
 */
export interface CommonControlProps {
  label: string;
}

/**
 * The every control defaults are extended by the properties that need to be implemented by ever
 * consumer of base
 */
interface BaseProps extends CommonControlProps {
  ControlNode: React.ReactNode;
  valueDisplay?: string;
}

const useStyles = makeStyles((theme) => ({
  target: {
    flexShrink: 0,
    padding: theme.spacing(0.5, 1),
    display: 'flex',
    alignItems: 'flex-end',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors.hues.grays[170],
    },
    minHeight: 40,
  },
  targetText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  tinyLabel: {
    fontSize: 10,
    fontWeight: 300,
    color: colors.hues.grays[40],
  },
  mainLabel: {
    display: 'flex',
    flexDirection: 'row',
  },
  label: {
    color: colors.hues.grays[30],
    maxWidth: 240,
    fontSize: 16,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  labelValueDisplay: {
    fontWeight: 700,
    color: '#fff',
  },
  icon: {
    marginLeft: theme.spacing(1),
    fontSize: 18,
  },
  popper: {
    zIndex: 1000,
  },
  dropdown: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.hues.grays[190],
    minWidth: 200,
    maxWidth: 500,
    padding: theme.spacing(1),
    boxShadow:
      '0 6.4px 14.4px 0 rgba(0,0,0,.132), 0 1.2px 3.6px 0 rgba(0,0,0,.108)',
  },
}));

function getPlacement(isOpened, el) {
  if (!el || !isOpened) {
    return 'bottom-start';
  }
  const w = window.innerWidth;
  const { left } = el.getBoundingClientRect();
  return left / w < 0.5 ? 'bottom-start' : 'bottom-end';
}

function ControlBarBase(props: BaseProps) {
  const classes = useStyles();
  const [isOpened, setIsOpened] = useState(false);
  const targetEl = useRef(null);
  const { label, ControlNode, valueDisplay } = props;

  return (
    <>
      <div
        ref={targetEl}
        className={classes.target}
        onClick={() => setIsOpened(!isOpened)}
        // TODO - keyboard control tabIndex={0} role="button"
      >
        <div className={classes.targetText}>
          {valueDisplay && <div className={classes.tinyLabel}>{label}</div>}
          <div className={classes.mainLabel}>
            <div
              className={cx(
                classes.label,
                valueDisplay && classes.labelValueDisplay
              )}
            >
              {valueDisplay || label}
            </div>
          </div>
        </div>
        <DownArrow className={classes.icon} />
      </div>
      <Popper
        className={classes.popper}
        open={isOpened}
        anchorEl={targetEl.current}
        placement={getPlacement(isOpened, targetEl.current)}
      >
        <ClickAwayListener onClickAway={() => setIsOpened(false)}>
          <div className={classes.dropdown}>{ControlNode}</div>
        </ClickAwayListener>
      </Popper>
    </>
  );
}

export default ControlBarBase;
