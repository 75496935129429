import _ from 'lodash';
import { gql } from '@apollo/client';
import apolloClient from '../../apollo-client';
import sdkClient from '../../lib/hmdsdkClient';

export interface FieldsType {
  name: string;
  flightType: FormFlightTypes;
  artifacts: UploadArtifactType[];
  labelIds: string[];
  meta: {
    isComplete?: boolean;
    robotLogId?: string;
    logs: string[];
  };
}

export interface UploadArtifactType {
  file: File;
  artifactTypeSlug: string;
  meta: {
    progress?: number;
    uploadStatus: 'pending' | 'uploading' | 'complete' | 'error';
    uploadedBytes?: string;
  };
}

export enum FormFlightTypes {
  vbat = 'vbat',
  nova = 'nova',
}

/**
 * Returns an empty form fields for a new form
 */
export function getEmptyFields(): FieldsType {
  return {
    name: '',
    flightType: FormFlightTypes.vbat,
    artifacts: [],
    labelIds: [],
    meta: {
      logs: [],
    },
  };
}

/**
 * Certain artifacts can provide the name for the flight
 */
function getFlightNameFromUploadArtifact(
  uploadArtifact: UploadArtifactType
): string {
  if (uploadArtifact.artifactTypeSlug === 'rosbag-original') {
    return uploadArtifact.file.name;
  }
  if (uploadArtifact.artifactTypeSlug === 'telecho-raw-log-csv') {
    return uploadArtifact.file.name;
  }

  return '';
}

/**
 * A key artifact is one that uniquely describes a flight, and the name for it
 */
export function isKeyArtifactFile(uploadArtifact: UploadArtifactType) {
  return (
    uploadArtifact.artifactTypeSlug === 'rosbag-original' ||
    uploadArtifact.artifactTypeSlug === 'telecho-raw-log-csv'
  );
}

export function canChangeArtifactType(artifact: UploadArtifactType) {
  return (
    artifact.artifactTypeSlug !== 'telecho-raw-log-csv' &&
    artifact.file.name !== 'temp.tlv'
  );
}

const artifactTypeMatchers: Partial<Record<string, RegExp[]>> = {
  // vbat
  'telecho-raw-log-csv': [/TelEchoRec.csv$/],
  'autopilot-log-tlv': [/.tlv$/],
  'trillium-payload-video': [/.ts$/],
  'planck-ace-log-archive': [/.tar$/],
  matlab: [/.mat$/],
  // nova
  'rosbag-original': [/(.bag|.bag.active)$/],
  'px4-ulog': [/.ulg$/],
  coredump: [/^core/],
};

/**
 * Based on the file chosen by the user, get the default ArtifacType we can compute
 */
function getArtifactTypeForFile(file: File): string {
  let artifactTypeSlug: string;

  for (const key of Object.keys(artifactTypeMatchers)) {
    if (artifactTypeSlug) {
      continue;
    }
    const matchers = artifactTypeMatchers[key];
    // eslint-disable-next-line no-loop-func
    matchers.forEach((matcher) => {
      if (file.name.match(matcher)) {
        artifactTypeSlug = key;
      }
    });
  }
  if (artifactTypeSlug) {
    return artifactTypeSlug;
  }

  return 'file';
}

/**
 * Given a user chosen file, turn it into an "UploadArtifact"
 * @param file
 */
export function getUploadArtifactFromFile(file: File): UploadArtifactType {
  return {
    file,
    artifactTypeSlug: getArtifactTypeForFile(file),
    meta: {
      uploadStatus: 'pending',
      uploadedBytes: '0',
    },
  };
}

/**
 * Add artifacts to fields, and set other necessary values along the way
 */
export function addArtifactsToFields(
  fields: FieldsType,
  uploadArtifacts: UploadArtifactType[]
) {
  fields.artifacts = fields.artifacts.concat(uploadArtifacts);

  if (!fields.name) {
    fields.name = fields.artifacts.reduce((acc, artifact) => {
      if (acc) {
        return acc;
      }
      const newName = getFlightNameFromUploadArtifact(artifact);
      if (newName !== '') {
        fields.meta.logs.push(
          `Determined flight name ${newName} from artifact file to be uploaded`
        );
      }
      return newName;
    }, '');
  }

  fields.artifacts = _.sortBy(
    fields.artifacts,
    (a) => {
      return isKeyArtifactFile(a) ? 0 : 1;
    },
    (a) => {
      return canChangeArtifactType(a) ? 1 : 0;
    },
    (a) => {
      return a.file.name;
    }
  );
}

/**
 * Handle a page wide drop, will break files into different field sets based on "key" artifact types
 * @param files
 */
export async function processPageFiles(files: File[]): Promise<FieldsType[]> {
  // handle one tier deep of folders a

  const artifacts = files.map(getUploadArtifactFromFile);
  const artifactSplits: UploadArtifactType[][] = artifacts.reduce(
    (acc, artifact) => {
      let cur = _.last(acc);
      if (isKeyArtifactFile(artifact)) {
        const alreadyHasKeyArtifact = !!_.find(cur, isKeyArtifactFile);
        if (alreadyHasKeyArtifact) {
          cur = [];
          acc.push(cur);
        }
      }
      cur.push(artifact);
      return acc;
    },
    [[]]
  );

  return artifactSplits.map((artifacts) => {
    const form = getEmptyFields();
    addArtifactsToFields(form, artifacts);
    return form;
  });
}

/**
 * Ensure
 * @param robotLogName
 */
export async function ensureFlight(robotLogName: string): Promise<string> {
  const response = await apolloClient.mutate({
    variables: { name: robotLogName },
    mutation: gql`
      mutation createOrUpdateRobotLog($name: String) {
        createOrUpdateRobotLog(name: $name) {
          id
        }
      }
    `,
  });
  return response.data.createOrUpdateRobotLog.id;
}

export async function handleMeta(fields: FieldsType): Promise<null> {
  if (fields.labelIds.length > 0) {
    await apolloClient.mutate({
      variables: {
        robotLogId: fields.meta.robotLogId,
        labelIds: fields.labelIds,
      },
      mutation: gql`
        mutation addLabelsToRobotLog($robotLogId: UUID!, $labelIds: [UUID]) {
          addLabelsToRobotLog(robotLogId: $robotLogId, labelIds: $labelIds) {
            id
          }
        }
      `,
    });
  }

  return null;
}

interface RegisterArtifactArgs {
  robotLogName: string;
  artifact: UploadArtifactType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onProgress: (evt) => any;
}
interface RegisterArtifactResponse {
  artifactId: string;
}
export async function registerArtifact({
  robotLogName,
  artifact,
  onProgress,
}: RegisterArtifactArgs): Promise<RegisterArtifactResponse> {
  const options = {
    metadata: artifact.meta,
    artifactTypeSlug: artifact.artifactTypeSlug,
    sessionLogNamesToLink: [robotLogName],
    onProgress: onProgress,
  };

  let artifactId;
  try {
    const artifactResponse = await sdkClient.artifacts.upload(
      artifact.file,
      options
    );
    artifactId = artifactResponse.getId();
  } catch (e) {
    console.error(e);
  }

  return {
    artifactId: artifactId,
  };
}
