import moment from 'moment';
import _ from 'lodash';
import store from '../redux/store';

/**
 * Takes a UTC Timestamp and returns the local time adjusting hour formatting based on the setting in the store
 * @param utcTimestamp
 * @returns {string} - e.g. 03/17/2019 04:32p
 */
export function getLocalDateTime(utcTimestamp) {
  if (!utcTimestamp) {
    return '';
  }
  const hour12 = _.get(store.getState(), 'settings.hour12');

  const mom = moment.utc(utcTimestamp).local();

  if (!mom.isValid()) {
    return '';
  }
  const timeFormat = hour12 ? 'hh:mma' : 'HH:mm';
  return mom.format(`MM/DD/YYYY ${timeFormat}`);
}

export function getCurrentUserTeams() {
  return _.get(store.getState(), 'authUser.currentUser.teams', []);
}

//Converts seconds to days and rounds down to the nearest whole day
export function getDaysFromSeconds(seconds) {
  return Math.floor(seconds / 60 / 60 / 24)
}

//Converts seconds to days and rounds down to the nearest whole day with 'days' appended
export function getDaysFromSecondsString(seconds) {
  const days = Math.floor(seconds / 60 / 60 / 24)
  return days === 1 ? days + ' day' : days + ' days'
}

