import _ from 'lodash';
import { AxisDefinitionMap } from './AxisDefinition';
import { commitHashAxisDefinition } from './commitHashes';
import { dateHistogramAxisDefinition } from './dateHistogram';
import { environmentAxisDefinition } from './environments';
import { frequencyGroupAxisDefinition } from './frequencyGroups';
import { ownerAxisDefinition } from './owners';
import { platformAxisDefinition } from './platforms';
import { platformSubtypeAxisDefinition } from './platformSubtypes';
import { projectAxisDefinition } from './projects';
import { requirementAxisDefinition } from './requirements';
import { tagAxisDefinition } from './tags';
import { testDefinitionCodeAxisDefinition } from './testDefinitionCodes';

export const tdAxisDefinitions: AxisDefinitionMap = _.keyBy(
  [
    platformAxisDefinition,
    platformSubtypeAxisDefinition,
    frequencyGroupAxisDefinition,
    requirementAxisDefinition,
    projectAxisDefinition,
    tagAxisDefinition,
    ownerAxisDefinition,
    environmentAxisDefinition,
    testDefinitionCodeAxisDefinition,
  ],
  (ad) => ad.id
);

export const robotLogAxisDefinitions: AxisDefinitionMap = _.keyBy(
  [dateHistogramAxisDefinition, commitHashAxisDefinition],
  (ad) => ad.id
);

export const allAxisDefinitions = {
  ...tdAxisDefinitions,
  ...robotLogAxisDefinitions,
};

export * from './AxisDefinition';
export * from './commitHashes';
export * from './dateHistogram';
export * from './environments';
export * from './frequencyGroups';
export * from './owners';
export * from './platforms';
export * from './platformSubtypes';
export * from './projects';
export * from './requirements';
export * from './tags';
export * from './testDefinitionCodes';
