import { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import ParentRow from './ParentRow';
import ChildRow from './ChildRow';
import Event from './Event';
import AddUpdateEventForm from './AddUpdateEventForm';
import { HangarEventType } from '@shield-ui/hangar-service';

import { ContainerProps } from './containerEventManager';
import { Props as ChildRowDetailsProps } from './ChildRowDetails';

interface Props
  extends Pick<
      ContainerProps,
      'robotLog' | 'currentUser' | 'eventsChildren' | 'deleteEvent'
    >,
    Pick<ChildRowDetailsProps, 'timeCodeOnClick'> {
  sessionLogEvents: HangarEventType[];
  name: string;
}

const useStyles = makeStyles((theme) => ({
  diagnoseButton: {
    marginRight: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
  buttonOverride: {
    fontSize: 14,
    padding: theme.spacing(1, 3),
  },
}));

function FlightEventGroup(props: Props) {
  const { sessionLogEvents = [], eventsChildren, name, robotLog } = props;
  const [isExpanded, setIsExpanded] = useState(true);
  const [isAdding, setIsAdding] = useState(false);
  const classes = useStyles();

  const variableProps = {
    onClick: undefined,
    isCurrentUserRow: true,
    EndNode: undefined,
  };

  if (sessionLogEvents.length && !isAdding) {
    variableProps.onClick = () => setIsExpanded(!isExpanded);
  }

  if (!isAdding) {
    variableProps.EndNode = (
      <Button
        className={classes.buttonOverride}
        color="secondary"
        variant="outlined"
        onClick={(evt) => {
          evt.stopPropagation();
          evt.preventDefault();
          setIsAdding(true);
          setIsExpanded(true);
        }}
      >
        Add
      </Button>
    );
  } else {
    variableProps.EndNode = (
      <Button
        className={classes.buttonOverride}
        color="secondary"
        onClick={(evt) => {
          evt.stopPropagation();
          evt.preventDefault();
          setIsAdding(false);
        }}
      >
        Done
      </Button>
    );
  }

  function returnRobotLog() {
    if (name === robotLog.name || !eventsChildren.length) {
      return robotLog;
    } else {
      return eventsChildren.find((e) => {
        if (name === e.name) {
          const { events: _, ...rLog } = e;
          return rLog;
        }
        return false;
      });
    }
  }

  return (
    <ParentRow
      isExpanded={isExpanded}
      flightLabel={name}
      count={sessionLogEvents.length}
      {...variableProps}
    >
      {isAdding && (
        <ChildRow isExpanded>
          <AddUpdateEventForm robotLog={returnRobotLog()} />
        </ChildRow>
      )}
      {sessionLogEvents.map((event) => {
        return (
          <Event
            {...props}
            key={event.id}
            event={event}
            robotLog={returnRobotLog()}
          />
        );
      })}
    </ParentRow>
  );
}

export default FlightEventGroup;
