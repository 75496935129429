import Cell, { getTicketsForRow } from './Cell';

const config = {
  columnKey: 'eventTickets',
  label: 'Event Tickets',
  description: 'Tickets associated with events on this flight',
  defaultWidth: 300,
  sortKey: 'events.tickets.name.keyword',
  Cell,
  getValue: ({ row }) => {
    return getTicketsForRow(row)
      .map((row) => row.link)
      .join('\n');
  },
  fragment: `
    events {
        edges {
            node {
              tickets {
                name
                link
              }
            }
        }
    }
    `,
};

export default config;
