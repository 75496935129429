import React from 'react';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import { gql, useQuery } from '@apollo/client';
import {
  addLabelsToRobotLog,
  removeLabelsFromRobotLog,
} from '../../services/labels';
import LabelMultiSelect from '../../components/connected-controls/labels/LabelMultiSelect';
import RobotLogPropDropdown from './HeaderProps/RobotLogPropDropdown';
import snackBarContainer from '../../containers/snackbar';

const useStyles = makeStyles({
  labelsContainer: {
    marginLeft: 40,
    minWidth: 220,
  },
});

const query = gql`
  query ($robotLogId: String!) {
    robotLog(id: $robotLogId) {
      id
      labels {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

function RobotLogLabels({ robotLogId, showErrorSnack, showSuccessSnack }) {
  const classes = useStyles();
  const { error, data, refetch } = useQuery(query, {
    variables: { robotLogId },
    fetchPolicy: 'cache-first',
  });

  if (error) {
    return <div>Label Fetch Error</div>;
  }

  const labelIds = _.get(data, ['robotLog', 'labels', 'edges'], []).map(
    (e) => e.node.id
  );

  return (
    <div className={classes.labelsContainer}>
      <LabelMultiSelect
        isCreatable
        placeholder="Apply Labels"
        value={labelIds}
        onChange={(opts) => {
          const newValues = _.map(opts, 'value');
          const toAdd = _.difference(newValues, labelIds);
          const toRemove = _.difference(labelIds, newValues);

          if (toAdd.length) {
            addLabelsToRobotLog(
              {
                robotLogId,
                labelIds: toAdd,
              },
              (err) => {
                if (err) {
                  return showErrorSnack(err);
                }
                refetch();
              }
            );
          }
          if (toRemove.length) {
            removeLabelsFromRobotLog(
              {
                robotLogId,
                labelIds: toRemove,
              },
              (err) => {
                if (err) {
                  return showErrorSnack(err);
                }
                refetch();
              }
            );
          }
        }}
        defaultOptionsSort="applied_to_robot_logs_count_desc"
        MultiSelectComponent={RobotLogPropDropdown}
      />
    </div>
  );
}

export default snackBarContainer(RobotLogLabels);
