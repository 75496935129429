import React from 'react';
import _ from 'lodash';
// import Snackbar from '@mui/material/Snackbar'
import Snackbar from './SnackbarPopup';
import container from './container';
import history from '../../routes/history';
import { SNACKBAR_VARIANTS } from '../../redux/snackbar';

class SnackbarController extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      activeSnack: undefined,
    };

    this.removeHistoryListener = history.listen(this.onLocationChange);
  }

  onLocationChange = ({ location }) => {
    const { resolveSnackMessages, snackbarQueue } = this.props;
    const { isOpen } = this.state;

    if (isOpen) {
      this.reset();
    }

    if (!_.isEmpty(snackbarQueue)) {
      resolveSnackMessages({
        messageIds: _.map(snackbarQueue, 'messageId'),
      });
    }
  };

  componentWillUnmount() {
    this.removeHistoryListener();
  }

  reset() {
    this.setState({
      isOpen: false,
      activeSnack: undefined,
    });
  }

  loadSnack(snack) {
    const { resolveSnackMessages } = this.props;
    this.setState(
      {
        isOpen: true,
        activeSnack: snack,
      },
      () => {
        resolveSnackMessages({
          messageIds: [snack.messageId],
        });
      }
    );
  }

  componentDidUpdate(prevProps) {
    const { activeSnack } = this.state;
    const { snackbarQueue } = this.props;

    if (!_.isEmpty(snackbarQueue) && !activeSnack) {
      this.loadSnack(snackbarQueue[0]);
    }
  }

  handleClose = () => {
    const { snackbarQueue } = this.props;
    if (!_.isEmpty(snackbarQueue)) {
      this.loadSnack(snackbarQueue[0]);
    } else {
      this.reset();
    }
  };

  render() {
    const { isOpen, activeSnack } = this.state;

    if (!activeSnack) {
      return null;
    }

    return (
      <Snackbar
        key={activeSnack.messageId}
        open={isOpen}
        handleClose={this.handleClose}
        message={activeSnack.message}
        error={activeSnack.variant === SNACKBAR_VARIANTS.ERROR}
      />
    );
  }
}

export default container(SnackbarController);
