import { Tooltip, Upload, Button, Modal } from 'antd';

import { useState } from 'react';
import {
  getUploadArtifactFromFile,
  UploadArtifactType,
} from '../UploadFlights/common';
import { useArtifactUploadMutation } from '../../services/newArtifacts';
import { ArtifactFileManager } from './ArtifactFileManger';
import { showError } from '../../lib/messages';

type ArtifactUploadButtonProps = {
  sessionLogId: string;
};
export const ArtifactUploadButton = ({
  sessionLogId,
}: ArtifactUploadButtonProps) => {
  const [artifacts, setArtifacts] = useState<UploadArtifactType[]>([]);
  const [uploadModalVisible, setUploadModalVisible] = useState(false);

  const { mutate, isLoading } = useArtifactUploadMutation();

  const onFilesSelected = (files: File[]) => {
    const updatedArtifacts = files.map(getUploadArtifactFromFile);
    setArtifacts(updatedArtifacts);
    setUploadModalVisible(true);

    return false;
  };

  const addFiles = (files: File[]) => {
    const updatedArtifacts = files.map(getUploadArtifactFromFile);
    setArtifacts([...artifacts, ...updatedArtifacts]);
    setUploadModalVisible(true);

    return false;
  };

  const onProgress = (artifacts: UploadArtifactType[]) => {
    setArtifacts(artifacts);

    const erroredArtifacts = artifacts.filter(
      (artifact) => artifact.meta.uploadStatus === 'error'
    );

    if (erroredArtifacts.length > 0) {
      const errorMessage =
        'The following artifacts failed to upload: ' +
        erroredArtifacts.map((artifact) => artifact.file.name).join(', ');

      showError(errorMessage);
    }
  };

  const handleOk = () => {
    mutate(
      {
        sessionLogId,
        artifacts: artifacts,
        onChange: onProgress,
      },
      {
        onSuccess: () => {
          setUploadModalVisible(false);
          setArtifacts([]);
        },
      }
    );
  };

  const handleCancel = () => {
    setUploadModalVisible(false);
    setArtifacts([]);
  };

  return (
    <>
      <Tooltip title="Upload Artifacts">
        <Upload
          beforeUpload={(_, files) => onFilesSelected(files)}
          multiple
          showUploadList={false}
        >
          <Button>Upload Files</Button>
        </Upload>
      </Tooltip>
      <Modal
        title="Upload Artifacts"
        width={800}
        open={uploadModalVisible}
        closable={!isLoading}
        confirmLoading={isLoading}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel} disabled={isLoading}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={isLoading}
            onClick={handleOk}
          >
            Upload
          </Button>,
        ]}
      >
        <ArtifactFileManager
          isSubmitting={isLoading}
          artifacts={artifacts}
          onArtfactChange={setArtifacts}
          onFilesAdded={addFiles}
        />
      </Modal>
    </>
  );
};
