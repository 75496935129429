import { useEffect, useState } from 'react';
import { Radio } from 'antd';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
import cx from 'classnames';
import {
  DropdownChip,
  DropdownChipRenderDropdownProps,
} from '@shield-ui/controls';
import { useDidMount } from '@shield-ui/hooks';
import {
  AxisDefinition,
  AxisDefinitionMap,
  AxisOption,
  tdAxisDefinitions,
  robotLogAxisDefinitions,
} from '../AxisDefinitions';
import { showError } from '../../../lib/messages';

const useStyles = makeStyles((theme) => ({
  section: {
    marginBottom: 10,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 600,
  },
  sectionItem: {
    marginTop: 10,
    marginBottom: 10,
  },
  sectionSubItem: {
    marginTop: 5,
    marginLeft: 20,
  },
  subItemRender: {
    minWidth: 150,
    maxWidth: 200,
  },
  dropdownChip: {
    width: 250,
    overflow: 'hidden',
    textOverflow: 'clip',
    whiteSpace: 'nowrap',
  },
  overflow: {
    overflow: 'hidden',
    textOverflow: 'clip',
    whiteSpace: 'nowrap',
  },
}));

export interface AxisDropdownChipProps {
  className?: string;
  title: string;
  label: string;
  selectedAxisDefinition: AxisDefinition;
  selectedAxisOption: AxisOption;
  onChange: (axisDefinition: AxisDefinition, axisOption?: AxisOption) => void;
}

export function AxisDropdownChip(props: AxisDropdownChipProps) {
  const classes = useStyles();
  const {
    className,
    title,
    label,
    selectedAxisDefinition,
    selectedAxisOption,
  } = props;
  const [dynamicOptions, setDynamicOptions] = useState<{
    [axisDefinitionId: string]: AxisOption[];
  }>({});

  const allAxisDefinitions = {
    ...tdAxisDefinitions,
    ...robotLogAxisDefinitions,
  };

  const defaultOptions = Object.keys(allAxisDefinitions).reduce((acc, id) => {
    const ad = allAxisDefinitions[id];
    if (ad.defaultAxisOption) {
      acc[id] = ad.defaultAxisOption;
      return acc;
    }
    return acc;
  }, {});

  useDidMount(() => {
    const fetchFuncs = [];
    Object.keys(allAxisDefinitions).forEach((id) => {
      const ad = allAxisDefinitions[id];
      if (allAxisDefinitions[id].fetchOptions) {
        fetchFuncs.push(ad.fetchOptions(id));
      }
    });

    Promise.all(fetchFuncs)
      .then((allResults) =>
        setDynamicOptions(
          allResults.reduce((acc, result) => ({ ...acc, ...result }), {})
        )
      )
      .catch(showError);
  });

  const renderAxisDefinitions = (axisDefinitions: AxisDefinitionMap) => {
    return Object.keys(axisDefinitions).map((axisDefinitionId: string) => {
      const axisDefinition = axisDefinitions[axisDefinitionId];
      const isSelected = axisDefinitionId === selectedAxisDefinition.id;
      const axisOption = isSelected
        ? selectedAxisOption
        : defaultOptions[axisDefinitionId]
        ? defaultOptions[axisDefinitionId]
        : dynamicOptions[axisDefinitionId] &&
          dynamicOptions[axisDefinitionId].length > 0
        ? dynamicOptions[axisDefinitionId][0]
        : undefined;

      return (
        <div key={axisDefinitionId}>
          {/* Item - e.g. Tags */}
          <div className={classes.sectionItem}>
            <Radio
              checked={isSelected}
              onChange={(e) =>
                e.target.checked
                  ? props.onChange(axisDefinition, axisOption)
                  : _.noop
              }
            >
              {axisDefinition.getDropdownLabel()}
            </Radio>

            {/* SubItem - e.g. TagTypes */}
            <div className={classes.sectionSubItem}>
              {axisDefinition.renderForm &&
                axisDefinition.renderForm({
                  className: cx(classes.subItemRender, classes.overflow),
                  axisOption: axisOption,
                  options: dynamicOptions[axisDefinitionId],
                  onChange: (axisOption?: AxisOption) => {
                    props.onChange(axisDefinition, axisOption);
                  },
                })}
            </div>
          </div>
        </div>
      );
    });
  };

  const renderDropdown = (p: DropdownChipRenderDropdownProps) => (
    <>
      <div className={classes.section}>
        <span className={classes.sectionTitle}>Test Definition Fields</span>
        {renderAxisDefinitions(tdAxisDefinitions)}
      </div>
      <div className={classes.section}>
        <span className={classes.sectionTitle}>Result Fields</span>
        {renderAxisDefinitions(robotLogAxisDefinitions)}
      </div>
    </>
  );

  return (
    <div className={className}>
      <DropdownChip
        className={cx(classes.dropdownChip, classes.overflow)}
        title={title}
        label={label}
        placeholder={'AxisDropdownChip placeholder'}
        renderDropdown={renderDropdown}
        icon="settings"
      />
    </div>
  );
}

export default AxisDropdownChip;
