import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from '@mui/icons-material/Search';
import { Input, InputProps } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { colors } from '@shield-ui/styles';

const useStyles = makeStyles((theme) => ({
  optionFilterWrapper: {
    flex: 1,
    borderBottom: `1px solid ${colors.hues.grays[180]}`,
  },
  icon: {
    fontSize: 18,
    marginRight: 4,
    color: colors.hues.grays[60],
  },
  spinner: {
    fontSize: 12,
    padding: 4,
    marginRight: 2,
    color: colors.hues.grays[40],
  },
}));

interface Props extends Omit<InputProps, 'prefix'> {
  isSearching?: boolean;
}

export default function SearchInput(props: Props) {
  const { isSearching = false, placeholder = 'Search', ...rest } = props;
  const classes = useStyles();

  return (
    <div className={classes.optionFilterWrapper}>
      <Input
        size="large"
        autoFocus
        prefix={
          isSearching ? (
            <SyncOutlined spin className={classes.spinner} />
          ) : (
            <SearchIcon className={classes.icon} />
          )
        }
        placeholder={placeholder}
        {...rest}
      />
    </div>
  );
}
