import React from 'react';
import { HangarAnalysisType } from '@shield-ui/hangar-service';
import JsonViewer from '../../../JsonViewer';

export default function AnalysisExpanded({
  analysis,
}: {
  analysis: HangarAnalysisType;
}) {
  let status;
  if (!analysis.testPassed) {
    status = 'Failed';
  } else {
    status = 'Passed';
  }

  return (
    <>
      {analysis.data && (
        <JsonViewer src={analysis.data} style={{ maxHeight: 500 }} />
      )}
      <div>Expectation: {status}</div>
    </>
  );
}
