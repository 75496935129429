import { Struct } from 'google-protobuf/google/protobuf/struct_pb';
import { Duration } from 'google-protobuf/google/protobuf/duration_pb';
import { TestDefinition } from '@hmd/sdk/api/tests/definitions/v1';
import { RobotLogType } from '@hmd/sdk/api/common/v1';
import { EvaluationState } from '@hmd/sdk/api/tests/evaluations/v1';
import { ErrorLevel } from '@hmd/sdk/api/analysis/log_event/v1';

export const evaluationStates = Object.entries(EvaluationState).filter(
  ([k, v]) => {
    return (
      k === 'CRITICAL_FAILURE' ||
      k === 'MAJOR_FAILURE' ||
      k === 'MODERATE_FAILURE' ||
      k === 'MINOR_FAILURE'
    );
  }
);

export interface DiagnosticAssertion {
  countThreshold: number;
  evaluationState: typeof EvaluationState[keyof typeof EvaluationState];
  errorLevel: typeof ErrorLevel[keyof typeof ErrorLevel];
  logDefinitionExternalId: number;
  componentExternalId: number;
}

/**
 * Defines our form state
 */
export type FormValues = {
  revisionUpdate: boolean;
  platformId: string;
  platformSubtypeId: string;
  metadata: {
    [key: string]: any;
  };
  name: string;
  description: string;
  executionCriteria: string;
  estimatedExecutionDuration?: number;
  isExecutionReady: boolean;
  // tags
  generalTags: string[];
  componentTags: string[];
  equipmentTags: string[];
  environmentalTags: string[];
  environmentIds: string[];
  requirementIds: string[];
  testFrequencyGroupDetails: {
    testFrequencyGroupId: string;
    minimumExecutionCount: number;
    preferredExecutionCount: number;
  }[];
  // metrics
  metricIds: string[];
  metricGroupIds: string[];
  // diagnostics
  diagnosticAssertions: DiagnosticAssertion[];
  // acceptance
  trialCount: number;
  trialEvaluationTolerance: number;
  reviewIsRequired: boolean;
  childRobotLogExpectations: {
    count: number;
    //robotLogType: typeof RobotLogType[keyof typeof RobotLogType];
    sessionLogTypeSlug: string;
  }[];
  // ownership
  ownerUserIds: string[];
  ownerTeamIds: string[];
  // artifacts
  artifactCollaterals: { artifactId: string; description: string }[];
};

/**
 * Get a blank form, e.g. for a "Create" form
 */
export function getCreateFormValues(): FormValues {
  return {
    revisionUpdate: false,
    platformId: undefined,
    platformSubtypeId: undefined,
    metadata: {},
    name: '',
    description: '',
    executionCriteria: '',
    estimatedExecutionDuration: undefined,
    trialCount: 1,
    trialEvaluationTolerance: 0,
    isExecutionReady: false,
    metricIds: [],
    metricGroupIds: [],
    diagnosticAssertions: [],
    generalTags: [],
    componentTags: [],
    equipmentTags: [],
    environmentalTags: [],
    environmentIds: [],
    requirementIds: [],
    testFrequencyGroupDetails: [],
    reviewIsRequired: false,
    childRobotLogExpectations: [],
    ownerUserIds: [],
    ownerTeamIds: [],
    artifactCollaterals: [],
  };
}

/**
 * Takes our TestDefinition form and converts it into a TestDefinition protobuf object
 */
export function formToProto(v: FormValues): TestDefinition {
  const td = new TestDefinition();

  td.setPlatformId(v.platformId);
  td.setPlatformSubtypeId(v.platformSubtypeId);
  td.setPlatformMetadata(Struct.fromJavaScript(v.metadata));

  td.setName(v.name);
  td.setDescription(v.description);
  td.setExecutionCriteria(v.executionCriteria);
  td.setIsExecutionReady(v.isExecutionReady);
  td.setTrialCount(v.trialCount);
  td.setTrialEvaluationTolerance(v.trialEvaluationTolerance);
  td.setGeneralTagsList(v.generalTags);
  td.setEquipmentTagsList(v.equipmentTags);
  td.setEnvironmentalTagsList(v.environmentalTags);
  td.setComponentTagsList(v.componentTags);
  td.setEnvironmentIdsList(v.environmentIds);
  td.setRequirementIdsList(v.requirementIds);
  td.setTestFrequencyGroupDetailsList(
    v.testFrequencyGroupDetails.map((tfgd) => {
      const detail = new TestDefinition.TestFrequencyGroupDetail();
      detail.setTestFrequencyGroupId(tfgd.testFrequencyGroupId);
      detail.setMinimumExecutionCount(tfgd.minimumExecutionCount);
      detail.setPreferredExecutionCount(tfgd.preferredExecutionCount);
      return detail;
    })
  );

  if (v.estimatedExecutionDuration >= 0) {
    const dur = new Duration();
    dur.setSeconds(v.estimatedExecutionDuration);
    td.setEstimatedExecutionDuration(dur);
  }

  // Acceptance Criteria
  const ac = new TestDefinition.TrialAcceptanceCriteria();
  ac.setReviewIsRequired(v.reviewIsRequired);
  ac.setChildRobotLogExpectationsList(
    v.childRobotLogExpectations.map((ex) => {
      const expectation =
        new TestDefinition.TrialAcceptanceCriteria.ChildRobotLogExpectation();
      expectation.setCount(ex.count);
      expectation.setSessionLogTypeSlug(ex.sessionLogTypeSlug);
      return expectation;
    })
  );
  ac.setMetricIdsList(v.metricIds);
  ac.setMetricGroupIdsList(v.metricGroupIds);
  ac.setLogEventAssertionsList(
    v.diagnosticAssertions.map((da) => {
      const assertion =
        new TestDefinition.TrialAcceptanceCriteria.LogEventAssertion();
      assertion.setCountThreshold(da.countThreshold);
      assertion.setEvaluationState(da.evaluationState);
      assertion.setLevel(da.errorLevel);
      assertion.setLogDefinitionExternalId(da.logDefinitionExternalId);
      assertion.setComponentExternalId(da.componentExternalId);
      return assertion;
    })
  );
  td.setTrialAcceptanceCriteria(ac);

  // Ownership
  const ownership = new TestDefinition.Ownership();
  ownership.setUserIdsList(v.ownerUserIds);
  ownership.setTeamIdsList(v.ownerTeamIds);
  td.setOwnership(ownership);

  // Artifacts
  td.setArtifactCollateralsList(
    v.artifactCollaterals.map((a) => {
      const ac = new TestDefinition.ArtifactCollateral();
      ac.setDescription(a.description);
      ac.setArtifactId(a.artifactId);
      return ac;
    })
  );

  return td;
}

export function updateFormToProto(v: FormValues) {
  return {
    TestDefinition: formToProto(v),
    revisionUpdate: v.revisionUpdate,
  };
}

/**
 * Converts our TestDefinition proto into the initial values for our form
 */
export function protoToForm(td: TestDefinition): FormValues {
  return {
    revisionUpdate: false,
    artifactCollaterals: td.getArtifactCollateralsList().map((a) => ({
      artifactId: a.getArtifactId(),
      description: a.getDescription(),
    })),
    name: td.getName(),
    description: td.getDescription(),
    isExecutionReady: td.getIsExecutionReady(),
    executionCriteria: td.getExecutionCriteria(),

    platformId: td.getPlatformId() || undefined,
    platformSubtypeId: td.getPlatformSubtypeId() || undefined,
    metadata: td.hasPlatformMetadata()
      ? td.getPlatformMetadata().toJavaScript()
      : {},
    trialCount: td.getTrialCount(),
    generalTags: td.getGeneralTagsList(),
    environmentIds: td.getEnvironmentIdsList(),
    requirementIds: td.getRequirementIdsList(),
    testFrequencyGroupDetails: td
      .getTestFrequencyGroupDetailsList()
      .map((d) => ({
        testFrequencyGroupId: d.getTestFrequencyGroupId(),
        preferredExecutionCount: d.getPreferredExecutionCount(),
        minimumExecutionCount: d.getMinimumExecutionCount(),
      })),
    componentTags: td.getComponentTagsList(),
    equipmentTags: td.getEquipmentTagsList(),
    environmentalTags: td.getEnvironmentalTagsList(),
    trialEvaluationTolerance: td.getTrialEvaluationTolerance(),
    estimatedExecutionDuration: td.hasEstimatedExecutionDuration()
      ? td.getEstimatedExecutionDuration().getSeconds()
      : undefined,
    ...(function parseAcceptanceCriteria() {
      const ac =
        td.getTrialAcceptanceCriteria() ||
        new TestDefinition.TrialAcceptanceCriteria();
      return {
        metricGroupIds: ac.getMetricGroupIdsList(),
        metricIds: ac.getMetricIdsList(),
        diagnosticAssertions: ac.getLogEventAssertionsList().map((da) => {
          return {
            countThreshold: da.getCountThreshold(),
            evaluationState: da.getEvaluationState(),
            errorLevel: da.getLevel(),
            logDefinitionExternalId: da.getLogDefinitionExternalId(),
            componentExternalId: da.getComponentExternalId(),
          };
        }),
        reviewIsRequired: ac.getReviewIsRequired(),
        childRobotLogExpectations: ac
          .getChildRobotLogExpectationsList()
          .map((crle) => {
            return {
              count: crle.getCount(),
              //robotLogType: crle.getRobotLogType(),
              sessionLogTypeSlug: crle.getSessionLogTypeSlug(),
            };
          }),
      };
    })(),
    ...(function parseOwnership() {
      if (td.hasOwnership()) {
        const ownership = td.getOwnership();
        return {
          ownerTeamIds: ownership.getTeamIdsList(),
          ownerUserIds: ownership.getUserIdsList(),
        };
      }
      return {
        ownerUserIds: [],
        ownerTeamIds: [],
      };
    })(),
  };
}
