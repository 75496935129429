import React from 'react';
import Typography from '@mui/material/Typography';
import { colors } from '@shield-ui/styles';

export default function ErrorMessageContent({ children }) {
  return (
    <Typography
      variant="body1"
      gutterBottom
      style={{ borderLeft: `5px solid ${colors.hues.red}`, paddingLeft: 10 }}
    >
      {children}
    </Typography>
  );
}
