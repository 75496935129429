import React from 'react';
import _ from 'lodash';
import withStyles from '@mui/styles/withStyles';

function styles() {
  return {
    container: {
      display: 'table',
    },
    row: {
      display: 'table-row',
    },
    block: {
      display: 'table-cell',
      marginLeft: 4,
      fontWeight: 600,
    },
    label: {
      display: 'table-cell',
      color: '#ddd',
      paddingRight: 8,
    },
  };
}

export function parseRowResults(row) {
  return {
    robot_block: _.get(row, 'robotLogData.robot_block', ''),
    backpack_block: _.get(row, 'robotLogData.backpack_block', ''),
    backpack_name: _.get(row, 'robotLogData.backpack_name', ''),
    phone_name: _.get(row, 'robotLogData.phone_name', ''),
    phone_app_version: _.get(row, 'robotLogData.phone_app_version', ''),
    // I think this was the old way of doing it before we split it out into "backpack_block" and "robot_block"
    hardwareConfig: _.get(row, 'robotLogData.hardware_config', ''),
  };
}

const mappings = {
  robot_block: 'Robot Block',
  backpack_name: 'Backpack',
  backpack_block: 'Backpack Block',
  phone_name: 'Phone',
  phone_app_version: 'App Version',
  hardwareConfig: 'HW Config',
};

function BlockInfoCell({ row, classes }) {
  const results = parseRowResults(row);

  return (
    <div className={classes.container}>
      {_.map(mappings, (display, key) => {
        if (!results[key]) {
          return null;
        }
        return (
          <div className={classes.row} key={key}>
            <div className={classes.label}>{display}</div>
            <div className={classes.block}>{results[key]}</div>
          </div>
        );
      })}
    </div>
  );
}

export default withStyles(styles)(BlockInfoCell);
