import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { colors } from '@shield-ui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  containerGutterBottom: {
    marginBottom: theme.spacing(2),
  },
  accent: {
    width: 5,
    height: 22,
    borderRadius: 1,
    marginTop: -5,
  },
  // variant page is default
  clickContainer: {
    color: colors.hues.gray,
    transition: '0.2s ease' ,
    cursor: 'pointer',
    padding: theme.spacing(1, 1, 1, 1),
    '&:hover': {
      color: colors.hues.grays[20],
    },
  },
  label: {
    color: colors.hues.gray,
    fontSize: 16,
    marginLeft: theme.spacing(1),
    textTransform: 'uppercase',
    letterSpacing: 0.5,
    fontWeight: 600,
    marginBottom: 4,
  },
  labelNoUpperCase: {
    textTransform: 'initial',
  },
  // variant page is default, these are overloads
  clickContainerVariantSection: {
    color: colors.hues.grays[60],
    '&:hover': {
      color: colors.hues.grays[5],
    },
  },
  labelVariantSection: {
    color: colors.hues.grays[60],
    fontSize: 21,
    fontWeight: 500,
    lineHeight: 26 / 21,
  },
}));

type SidePanelBackProps = {
  onBack?: () => void;
  linkTo?: string;
  label?: string;
  accentColor?: string;
  gutterBottom?: boolean;
  variant?: 'page' | 'section';
  uppercase?: boolean;
  children?: React.ReactNode;
};

/**
 * children get wrapped in a standard, stylized padded container
 * Content does not
 */
export default function BackHeader(props: SidePanelBackProps) {
  const {
    onBack,
    linkTo,
    label = 'Go Back',
    gutterBottom = false,
    accentColor,
    variant = 'page',
    uppercase = true,
    children,
  } = props;
  const classes = useStyles();

  const clickClass = cx(
    classes.clickContainer,
    variant === 'section' && classes.clickContainerVariantSection
  );

  return (
    <div
      className={cx(
        classes.container,
        gutterBottom && classes.containerGutterBottom
      )}
    >
      {onBack && (
        <div className={clickClass} onClick={() => onBack()}>
          <ArrowBack />
        </div>
      )}
      {linkTo && (
        <Link className={clickClass} to={linkTo}>
          <ArrowBack />
        </Link>
      )}
      {accentColor && (
        <div
          className={classes.accent}
          style={{ backgroundColor: accentColor }}
        />
      )}
      <div
        className={cx(
          classes.label,
          variant === 'section' && classes.labelVariantSection,
          !uppercase && classes.labelNoUpperCase
        )}
      >
        {label}
      </div>
      {children}
    </div>
  );
}
