import React from 'react';
import EvaluationModal from './EvaluationModal';
import {
  getEvaluationStatusColor,
  getEvaluationStatusName,
} from '../../services/evaluations';
import { Tag } from 'antd';
import { CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { BadgeProps } from './types';

const useStyles = makeStyles(() => ({
  tagContainer: {
    cursor: 'pointer',
    minWidth: 200,
    minHeight: 25,
    alignItems: 'center',
    display: 'flex',
    zIndex: 100,
  },
  loadingContainer: {
    minWidth: 200,
    minHeight: 25,
    alignItems: 'center',
    display: 'flex',
  },
}));

export default function BadgeContent(props: BadgeProps) {
  const {
    openEvaluation,
    evaluation,
    isLoading,
    isEvaluationOpen,
    closeEvaluation,
    onAssertionChange,
    setIsManualAssertion,
  } = props;
  const classes = useStyles();
  return (
    <>
      {isLoading ? (
        <div className={classes.loadingContainer}>
          <CircularProgress size={20} thickness={4.5} />
        </div>
      ) : (
        evaluation && (
          <div>
            <div className={classes.tagContainer} onClick={openEvaluation}>
              <Tag
                style={{
                  color: getEvaluationStatusColor(
                    evaluation.getEvaluationState()
                  ),
                  borderColor: getEvaluationStatusColor(
                    evaluation.getEvaluationState()
                  ),
                }}
              >
                {getEvaluationStatusName(evaluation.getEvaluationState())}
              </Tag>
            </div>
            {
              <EvaluationModal
                evaluation={evaluation}
                isEvaluationOpen={isEvaluationOpen}
                closeEvaluation={closeEvaluation}
                onAssertionChange={onAssertionChange}
                setIsManualAssertion={setIsManualAssertion}
              />
            }
          </div>
        )
      )}
    </>
  );
}
