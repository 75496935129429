import React from 'react';
import { smartFormatDuration } from '@shield-ui/utils';
import Range from '../Range/Range';

class DurationRange extends React.Component {
  static defaultProps = {
    min: 0,
    max: 100,
  };
  handleDurationChange = (val) => {
    const { value, onChange, min, max } = this.props;

    const newValue = value ? { ...value } : {};

    if (val[0] === newValue.gte && val[1] === newValue.lte) {
      return;
    }

    newValue.gte = val[0];
    newValue.lte = val[1];

    if (newValue.gte === min) {
      delete newValue.gte;
    }
    if (newValue.lte === max) {
      delete newValue.lte;
    }

    // go back to default / undefined
    if (Object.keys(newValue).length === 0) {
      onChange(undefined);
      return;
    }

    onChange(newValue);
  };

  tipFormatter(value) {
    return smartFormatDuration(value * 1000);
  }

  render() {
    const { value, min, max, disabled } = this.props;

    const marks = {
      0: <strong>0 secs</strong>,
      [max * 0.25]: <strong>{max * 0.25} secs</strong>,
      [max * 0.5]: <strong>{max * 0.5} secs</strong>,
      [max * 0.75]: <strong>{max * 0.75} secs</strong>,
      [max]: <strong style={{ whiteSpace: 'nowrap' }}>{max}+ secs</strong>,
    };

    return (
      <Range
        min={min}
        max={max}
        marks={marks}
        disabled={disabled}
        value={value}
        onAfterChange={this.handleDurationChange}
        tipFormatter={this.tipFormatter}
      />
    );
  }
}

export default DurationRange;
