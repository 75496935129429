import _ from 'lodash';
import { createActionPrefix } from 'redux-nano';
import async from 'async';
import { gql } from '@apollo/client';
import config from '../../lib/config';
import * as analyzerService from '../../services/analysis';
import { showSuccessSnack, showErrorSnack } from '../snackbar/actions';
import history from '../../routes/history';
import { runQuery } from '../../apollo-client';
import { userRobotLogViewed } from '../../services/userRobotLogDetails';
import {
  buildRobotLogsQuery,
  buildDocumentSearchQuery,
} from '../../services/robotLogs';

const prefix = createActionPrefix('FLIGHT');
export const RESET_FLIGHT_PAGE = prefix('RESET_FLIGHT_PAGE');
export const SET_ANALYSES = prefix('SET_ANALYSIS');
export const SET_ANALYZERS = prefix('SET_ANALYZERS');
export const UPDATE_ANALYSES_FILTERS = prefix('UPDATE_ANALYSES_FILTERS');
export const SET_PAGING = prefix('SET_PAGING');

const PAGING_COUNT_TO_GET = 5;

export const pageQuery = gql`
  query robotLog($robotLogId: String!) {
    robotLog(id: $robotLogId) {
      id
      name
      sessionLogTypeSlug
      robotLogTestId
      testDefinitionId
      displayName
    }
  }
`;

export function refreshAnalyses(variables = {}) {
  return function dispatchRefreshAnalysis(dispatch, getState) {
    const { robotLogId } = getState().flight;

    analyzerService.getAnalyses(
      {
        robotLogId,
        ...variables,
      },
      (err, r) => {
        if (err) {
          dispatch(showErrorSnack(err));
          dispatch(SET_ANALYSES({ analyses: [] }));
          return;
        }

        const analyses = _.get(r, 'data.analyses.edges', []).map(
          (edge) => edge.node
        );
        dispatch(SET_ANALYSES({ analyses }));
      }
    );
  };
}

export function ensureAnalyses() {
  return function dispatchEnsureAnalyses(dispatch, getState) {
    const { analysisMap } = getState().flight;

    if (analysisMap) {
      return;
    }

    dispatch(refreshAnalyses());
  };
}

export function refreshAnalyzers() {
  return function dispatchRefreshAnalyzers(dispatch) {
    fetch(`${config.api.base}/rest/doppler/analyzers`, {
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((r) => r.json())
      .then((result) => {
        let { analyzers } = result;

        analyzers = analyzers.filter((analyzer) => {
          return analyzer.framework.toLowerCase() !== 'mock';
        });

        dispatch(SET_ANALYZERS({ analyzers }));
      })
      .catch((e) => {
        dispatch(showErrorSnack(e));
        dispatch(
          SET_ANALYZERS({
            analyzers: [],
          })
        );
      });
  };
}

export function ensureAnalyzers() {
  return function dispatchEnsureAnalyzers(dispatch, getState) {
    const { analyzers } = getState().flight;

    if (analyzers) {
      return;
    }

    dispatch(refreshAnalyzers());
  };
}

export function runAnalyzers({ analyzerNames }) {
  return function dispatchRunAnalyzers(dispatch, getState) {
    const { robotLogId } = getState().flight;

    analyzerService.runAnalyzers({ analyzerNames, robotLogId }, (err, r) => {
      if (err) {
        return dispatch(showErrorSnack(err));
      }

      const analyses = _.get(r, 'data.runAnalyzers', []);
      dispatch(
        showSuccessSnack({
          message: `Request to run ${analyzerNames} sent. Will result in ${analyses.length} new analyses`,
        })
      );

      dispatch(SET_ANALYSES({ analyses }));
    });
  };
}

function fetchPagingResults({ searchVersion, variables, callback }) {
  runQuery({
    variables,
    fetchPolicy: 'network-only',
    query:
      searchVersion === 'documentSearchV1'
        ? buildDocumentSearchQuery()
        : buildRobotLogsQuery(),
    callback: (err, result) => {
      if (err) {
        return callback(err);
      }

      const resultStructure =
        searchVersion === 'documentSearchV1'
          ? result.data.documentSearchRobotLogs
          : result.data.robotLogs;
      const { pageInfo, edges, total } = resultStructure;
      const { startCursor, endCursor } = pageInfo;
      const ids = _.map(edges, (edge) => edge.node.id);

      return callback(null, {
        startCursor,
        endCursor,
        total,
        ids,
      });
    },
  });
}

function wrapToFront({ paging, callback }) {
  fetchPagingResults({
    searchVersion: paging.searchVersion,
    variables: {
      ...paging.queryVariables,
      first: PAGING_COUNT_TO_GET,
    },
    callback: (err, pagingUpdate) => {
      if (err) {
        return callback(err);
      }
      pagingUpdate.index = 0;
      pagingUpdate.robotLogId = _.first(pagingUpdate.ids);
      return callback(null, pagingUpdate);
    },
  });
}

function wrapToEnd({ paging, callback }) {
  fetchPagingResults({
    searchVersion: paging.searchVersion,
    variables: {
      ...paging.queryVariables,
      last: PAGING_COUNT_TO_GET,
    },
    callback: (err, pagingUpdate) => {
      if (err) {
        return callback(err);
      }
      pagingUpdate.index = pagingUpdate.total - 1;
      pagingUpdate.robotLogId = _.last(pagingUpdate.ids);
      return callback(null, pagingUpdate);
    },
  });
}

function goGetPrev({ paging, callback }) {
  if (!paging.queryVariables) {
    return callback(null, {
      index: paging.ids.length - 1,
      robotLogId: _.last(paging.ids),
    });
  }

  if (paging.index <= 1) {
    return wrapToEnd({
      paging,
      callback,
    });
  }

  fetchPagingResults({
    searchVersion: paging.searchVersion,
    variables: {
      ...paging.queryVariables,
      before: paging.startCursor,
      last: PAGING_COUNT_TO_GET,
    },
    callback: (err, pagingUpdate) => {
      if (err) {
        return callback(err);
      }

      if (!pagingUpdate.ids.length) {
        return wrapToEnd({ paging, callback });
      }
      pagingUpdate.index = paging.index - 1;
      pagingUpdate.robotLogId = _.last(pagingUpdate.ids);
      return callback(null, pagingUpdate);
    },
  });
}

function goGetNext({ paging, callback }) {
  // we were called with an explicit list of ids
  if (!paging.queryVariables) {
    return callback(null, {
      index: 0,
      robotLogId: paging.ids[0],
    });
  }

  fetchPagingResults({
    searchVersion: paging.searchVersion,
    variables: {
      ...paging.queryVariables,
      after: paging.endCursor,
      first: PAGING_COUNT_TO_GET,
    },
    callback: (err, pagingUpdate) => {
      if (err) {
        return callback(err);
      }

      if (!pagingUpdate.ids.length) {
        return wrapToFront({ paging, callback });
      }
      pagingUpdate.index = paging.index + 1;
      pagingUpdate.robotLogId = _.first(pagingUpdate.ids);
      return callback(null, pagingUpdate);
    },
  });
}

/**
 * Traverse through flights using "paging" meaning step forward and back
 * @param direction - prev or next
 * @returns {function} dispatchGoToPrevFlight
 */
export function pageToFlight({ direction }) {
  return function dispatchPageToFlight(dispatch, getState) {
    const state = getState();
    const { robotLogId, paging } = state.flight;

    async.waterfall(
      [
        (callback) => {
          const idIndex = paging.ids.indexOf(robotLogId);

          const pagingUpdates = {};
          if (direction === 'prev') {
            pagingUpdates.robotLogId = paging.ids[idIndex - 1];
            pagingUpdates.index = paging.index - 1;
          } else if (direction === 'next') {
            pagingUpdates.robotLogId = paging.ids[idIndex + 1];
            pagingUpdates.index = paging.index + 1;
          }

          // don't have to do an extra request because we were able to figure things out synchronously
          if (pagingUpdates.robotLogId) {
            callback(null, pagingUpdates);
          } else if (direction === 'prev') {
            goGetPrev({ paging, callback });
          } else if (direction === 'next') {
            goGetNext({ paging, callback });
          } else {
            return callback(
              new Error(
                `Invalid direction of (${direction}) provided to paging`
              )
            );
          }
        },
      ],
      (err, pagingUpdates) => {
        if (err) {
          return dispatch(showErrorSnack(err));
        }

        if (pagingUpdates.robotLogId) {
          dispatch(SET_PAGING({ paging: pagingUpdates }));
          history.push(`/app/flights/${pagingUpdates.robotLogId}`);
        }
      }
    );
  };
}

export function resetFlightPage({ robotLogId }) {
  return function dispatchResetFlightPage(dispatch) {
    // make sure the user viewing the page is checked
    userRobotLogViewed({ robotLogId });
    dispatch(RESET_FLIGHT_PAGE({ robotLogId }));

    runQuery({
      query: pageQuery,
      variables: { robotLogId },
      callback: (err, result) => {
        if (err) {
          return dispatch(showErrorSnack(err));
        }
        dispatch(
          RESET_FLIGHT_PAGE({
            robotLogId,
            robotLog: _.get(result, 'data.robotLog'),
          })
        );
      },
    });
  };
}

export const updateAnalysesFilters = UPDATE_ANALYSES_FILTERS;
export const setPaging = SET_PAGING;
