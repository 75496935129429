import { BrowserRouter } from 'react-router-dom';
import StyleRoot from './lib/StyleRoot';
import React from 'react';

const baseStorybookDecorator = (storyFn) => (
  <BrowserRouter>
    <StyleRoot>
      <div style={{ padding: 20, height: '100%' }}>{storyFn()}</div>
    </StyleRoot>
  </BrowserRouter>
);

export default baseStorybookDecorator;
