/**
 * Get a v4 UUID
 * https://stackoverflow.com/a/64976228/6525039
 */
export function uuid4() {
  const array = new Uint8Array(16);
  crypto.getRandomValues(array);

  // manipulate 9th byte
  // tslint:disable-next-line:no-bitwise
  array[8] &= 0b00111111; // clear first two bits
  // tslint:disable-next-line:no-bitwise
  array[8] |= 0b10000000; // set first two bits to 10

  // manipulate 7th byte
  // tslint:disable-next-line:no-bitwise
  array[6] &= 0b00001111; // clear first four bits
  // tslint:disable-next-line:no-bitwise
  array[6] |= 0b01000000; // set first four bits to 0100

  const pattern = 'XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX';
  let idx = 0;

  return pattern.replace(
    /XX/g,
    () => array[idx++].toString(16).padStart(2, '0') // padStart ensures leading zero, if needed
  );
}

export function isValidUuid4(uuidString: string): boolean {
  return !!uuidString.match(/^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);
}
