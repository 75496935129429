import { Descriptions } from 'antd';
import { EntityJSON } from '../../api/entities';
import { EntitySchemaJSON } from '../../services/entitySchemas';

type EntityViewProps = {
  entity: EntityJSON;
  entitySchema: EntitySchemaJSON;
};
export const EntityView = ({ entity, entitySchema }: EntityViewProps) => {
  const properties = entitySchema.schema.properties;
  const fields = Object.keys(properties).map((key) => {
    const value = entity.properties[key];
    const property = properties[key];
    return (
      <Descriptions.Item span={4} label={property?.title || key} key={key}>
        {value}
      </Descriptions.Item>
    );
  });
  return (
    <Descriptions bordered size="small">
      {fields}
    </Descriptions>
  );
};
