import { gql } from '@apollo/client';
import _ from 'lodash';
import { runQuery, runMutation } from '../apollo-client';
import {
  HangarMeasurementDefinitionType,
  HangarMeasurementType,
  HangarQueriesMeasurementsArgs,
} from '@shield-ui/hangar-service';
import {
  smartFormatDuration,
  getNumberDisplayToDecimalPlace,
} from '@shield-ui/utils';

export type UiDataShape = {
  calloutCardOverride?: string;
  calloutCardMaxDecimals?: number;
};
export const DEFAULT_CALLOUT_CARD_MAX_DECIMALS = 2;

const measurementFragment = `
  id
  measurementDefinitionId
  dissection
  statistic
  assessmentCriteria
  uiData
  definitionSpecificValues
  collections {
    id
  }
  createdBy {
    id
    name
  }
  measurementResult {
    title
    description
  }
`;

const measurementResultFragment = `
  id
  collections {
    id
  }
  measurementResult {
    title
    description
    callout
    xaxis {
      title
    }
    yaxis {
      title
    }
    traces {
      name
      robotLogIds
      text
      x
      y
      stdDev
      percentile5th
      percentile95th
      xSortValues
      lastDataPointCreatedAt
    }
  }
`;

function search(variables, fragment, callback) {
  runQuery({
    query: gql`
      query measurements(
        $collectionIds:[UUID],
        $measurementDefinitionIds:[UUID],
        $ids:[UUID],
      ){
        measurements(
          collectionIds:$collectionIds,
          measurementDefinitionIds:$measurementDefinitionIds,
          ids:$ids,
          sort: created_at_desc
        ) {
          edges {
            node {
              ${fragment}
            }
          }
        }
      }
    `,
    fetchPolicy: 'network-only',
    variables,
    callback,
  });
}

export function searchMeasurements(
  variables: HangarQueriesMeasurementsArgs,
  callback
) {
  search(variables, measurementFragment, callback);
}
export function searchMeasurementResults(
  variables: HangarQueriesMeasurementsArgs,
  callback
) {
  search(variables, measurementResultFragment, callback);
}

const mutationArguments = `
  measurementDefinitionId:$measurementDefinitionId,
  collectionIds:$collectionIds,
  dissection: $dissection,
  statistic: $statistic,
  definitionSpecificValues: $definitionSpecificValues,
  assessmentCriteria: $assessmentCriteria,
  uiData: $uiData
`;

function prepareMutationVariables(variables) {
  const ret = { ...variables };

  if (variables.definitionSpecificValues) {
    ret.definitionSpecificValues = JSON.stringify(
      variables.definitionSpecificValues
    );
  }
  if (variables.assessmentCriteria) {
    ret.assessmentCriteria = JSON.stringify(variables.assessmentCriteria);
  }
  if (variables.uiData) {
    ret.uiData = JSON.stringify(variables.uiData);
  }
  return ret;
}

const createPreviewVariableTypes = `
  $measurementDefinitionId: String!,
  $collectionIds: [UUID],
  $dissection: DissectionEnum,
  $statistic: StatisticEnum,
  $definitionSpecificValues: JSONString,
  $assessmentCriteria: JSONString,
  $uiData: JSONString,
`;

export function previewMeasurement(variables, callback) {
  runQuery({
    query: gql`
      query previewMeasurement(${createPreviewVariableTypes}) {
        previewMeasurement(${mutationArguments}) {
          ${measurementFragment}
          ${measurementResultFragment}
        }
      }
    `,
    variables: prepareMutationVariables(variables),
    callback,
  });
}
export function createMeasurement(variables, callback) {
  runMutation({
    mutation: gql`
      mutation createMeasurement(${createPreviewVariableTypes}) {
        createMeasurement(${mutationArguments}) {
          ${measurementFragment}
          ${measurementResultFragment}
        }
      }
    `,
    variables: prepareMutationVariables(variables),
    callback,
  });
}
export function updateMeasurement(variables, callback) {
  runMutation({
    mutation: gql`
      mutation updateMeasurement(
        $id: UUID!,
        $measurementDefinitionId: String!,
        $collectionIds: [UUID],
        $dissection: DissectionEnum,
        $statistic: StatisticEnum,
        $definitionSpecificValues: JSONString,
	      $assessmentCriteria: JSONString,
        $uiData: JSONString,
      ) {
        updateMeasurement(id:$id, ${mutationArguments}) {
          ${measurementFragment}
          ${measurementResultFragment}
        }
      }
    `,
    variables: prepareMutationVariables(variables),
    callback,
  });
}
export function deleteMeasurement(variables, callback) {
  // this used to return the entire measurement fragment
  // but caused issues because the createdBy User was being requested
  // and if it wasn't the auth user, or loaded in memory for some other reason
  // it wouldn't be fetchable.
  // https://dev.azure.com/shieldai/ShieldAI/_workitems/edit/50396
  runMutation({
    mutation: gql`
      mutation deleteMeasurement($id: String!) {
        deleteMeasurement(id: $id) {
          id
        }
      }
    `,
    variables,
    callback,
  });
}

function parseJson(str, defaultResult) {
  if (!str) {
    return defaultResult;
  }
  // Maybe the API changed this field to not be a string anymore
  if (typeof str === typeof defaultResult) {
    return str;
  }

  try {
    const ret = JSON.parse(str);
    // cuz we are dealing with some rough JSON, make sure we get the right type
    if (typeof ret === typeof defaultResult) {
      return ret;
    }
  } catch (e) {
    return defaultResult;
  }
}

export function convertMeasurementJson(measurement: HangarMeasurementType) {
  return {
    ...measurement,
    assessmentCriteria: parseJson(measurement.assessmentCriteria, {}),
    uiData: parseJson(measurement.uiData, {}),
    definitionSpecificValues: parseJson(
      measurement.definitionSpecificValues,
      {}
    ),
  };
}

export function convertMeasurementsJson(measurements: HangarMeasurementType[]) {
  return measurements.map(convertMeasurementJson);
}

export function formatCalloutValue(
  value,
  measurement: HangarMeasurementType,
  definition: HangarMeasurementDefinitionType
): string {
  if (_.isUndefined(value)) {
    return '-';
  }

  value = value || 0;
  if (definition.unit === 'SECOND') {
    return smartFormatDuration(parseFloat(value) * 1000);
  }

  // If this is a percent, tack on a % sign
  if (measurement.statistic.match(/PERCENT/)) {
    return getNumberDisplayToDecimalPlace(value) + '%';
  }

  if (Math.abs(value) > 1000) {
    return getNumberDisplayToDecimalPlace(value, 0);
  }
  if (Math.abs(value) > 100) {
    return getNumberDisplayToDecimalPlace(value, 1);
  }

  const uiData: UiDataShape = measurement.uiData;
  const decimals = _.get(
    uiData,
    'calloutCardMaxDecimals',
    DEFAULT_CALLOUT_CARD_MAX_DECIMALS
  );

  // Up to 6 decimal places
  // 0.0000001 (trailing zeros trimmed)
  return getNumberDisplayToDecimalPlace(value, decimals);
}
