export const semanticDataTypes = {
  // standard form elements
  string: 'string',
  bool: 'bool',
  // connected / enhanced elements
  'Tag.id': 'Tag.id',
  'Severity.id': 'Severity.id',
  'Team.id': 'Team.id',
  'RobotLogReview.review_status': 'RobotLogReview.review_status',
  'RobotLogData.flightType': 'RobotLogData.flightType',
  'Ticket.platform_id': 'Ticket.platform_id',
  'Analysis.name': 'Analysis.name',
  'Metric.name': 'Metric.name',
  'LogEvent.component_external_id': 'LogEvent.component_external_id',
  'LogEvent.component_owner': 'LogEvent.component_owner',
  'LogEvent.log_definition_external_id': 'LogEvent.log_definition_external_id',
  'RobotLog.session_log_type_slug': 'RobotLog.session_log_type_slug',
};

export function getSemanticDataLabel(dataType: string) {
  switch (dataType) {
    case semanticDataTypes['Tag.id']:
      return 'Failure Type';
    case semanticDataTypes['Ticket.platform_id']:
      return 'Ticket';
    case semanticDataTypes['RobotLogData.flightType']:
      return 'Flight Type';
    case semanticDataTypes['RobotLogReview.review_status']:
      return 'Review Status';
    case semanticDataTypes['Analysis.name']:
      return 'Expectation Name';
    case semanticDataTypes['Metric.name']:
      return 'Metric Name';
    case semanticDataTypes['LogEvent.log_definition_external_id']:
      return 'Diagnostic Type';
    case semanticDataTypes['LogEvent.component_external_id']:
      return 'Component';
    case semanticDataTypes['LogEvent.component_owner']:
      return 'Component Owner';
    case semanticDataTypes['RobotLog.session_log_type_slug']:
      return 'Content Type';
  }

  // Severity.id, Team.id, Ticket.id
  return dataType.replace(/\.id$/, '');
}
