import React from 'react';
import _ from 'lodash';
import cx from 'classnames';
import { darken } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import SortIcon from '@mui/icons-material/Sort';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Paper, Tooltip, Button } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Select } from '@shield-ui/controls';
import { colors } from '@shield-ui/styles';
import { TextField } from '@shield-ui/controls';
import { doesInputMatchBase } from '@shield-ui/utils';
import ModalHeader from '../../../modals/Header';
import {
  getExpandedActiveColumns,
  getExpandedDynamicColumns,
  getExpandedInactiveColumns,
  isSortable,
} from '../utils';
import SemanticForm from '../../../forms/SemanticForm';
import PushPinIcon from '@mui/icons-material/PushPin';

const MODAL_PERCENT_HEIGHT = 85;

function styles(theme) {
  return {
    container: {
      padding: theme.spacing(3, 5),
      height: `${MODAL_PERCENT_HEIGHT}%`,
      // not sure why this gets a focus outline
      outline: 'none',
    },
    controls: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: theme.spacing(3),
      alignItems: 'center',
    },
    search: {
      flex: 1,
      marginRight: theme.spacing(4),
    },
    listContainer: {
      display: 'flex',
      flexDirection: 'row',
      padding: theme.spacing(2, 0),
    },
    listInactive: {
      marginLeft: 40,
    },
    listTitle: {
      textTransform: 'uppercase',
      fontSize: 11,
      marginBottom: theme.spacing(0.25),
      color: darken(colors.semantic.bodyText, 0.2),
    },
    listTitleActive: {
      color: colors.semantic.bodyText,
    },
    listSizer: {
      width: 480,
      minHeight: 200,
      height: 200,
      padding: theme.spacing(1, 0),
    },
    droppable: {
      flex: 1,
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    droppableInactive: {
      border: 0,
    },
    droppableOver: {
      background: darken(colors.brand.primary, 0.8),
    },
    item: {
      padding: theme.spacing(0.5, 0),
      marginBottom: 4,
      background: colors.hues.grays[170],
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      userSelect: 'none',
      border: `1px solid ${colors.hues.grays[180]}`,
      '&:hover': {
        borderColor: theme.palette.primary.main,
      },
    },
    itemDragging: {
      background: 'black',
      borderColor: theme.palette.primary.dark,
    },
    itemLabel: {
      flex: 1,
      fontSize: 17,
    },
    dragIcon: {
      fontSize: 32,
      opacity: 0.9,
      color: theme.palette.secondary.main,
      marginRight: theme.spacing(1),
    },
    dragIconInactive: {
      color: darken(colors.semantic.bodyText, 0.4),
    },
    infoIcon: {
      color: darken(colors.semantic.bodyText, 0.4),
      marginRight: 5,
      '&:hover': {
        color: darken(colors.semantic.bodyText, 0.2),
      },
    },
    defaultsButton: {
      marginLeft: 5,
      marginRight: 5,
    },
    dynamicTopControls: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
    dynamicButton: {
      marginLeft: theme.spacing(2),
    },
  };
}

function getListHeight() {
  const h = window.innerHeight;

  // easy/lazy math, will break if other elements start to change
  return h * (MODAL_PERCENT_HEIGHT / 100) - 240;
}

class ColumnConfigModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: '',
      dynamicColumnKeySelectValue: null,
      dynamicDataInputValues: {},
      listHeight: getListHeight(),
    };

    this.calcListHeight = _.throttle(() => {
      this.setState({
        listHeight: getListHeight(),
      });
    }, 100);

    window.addEventListener('resize', this.calcListHeight);
  }

  componentWillUnmount() {
    this.calcListHeight.cancel();
    window.removeEventListener('resize', this.calcListHeight);
  }

  getDoubleClickHandle = _.memoize((uid) => {
    return () => {
      const { columns } = this.props;

      const existingIndex = _.findIndex(
        columns,
        (col) => col.columnUid === uid || col.columnKey === uid
      );

      if (existingIndex === -1) {
        // double click right side to add one
        this.setColumns(columns.concat([{ columnKey: uid }]));
      } else {
        this.setColumns(
          columns.filter(
            (column) => column.columnUid !== uid && column.columnKey !== uid
          )
        );
      }
    };
  });

  filterOnChange = (evt) => {
    this.setState({ filter: evt.target.value });
  };

  resolveFilteredDestinationIndex(index) {
    const { filter } = this.state;
    const { columns, getColumnDefinition } = this.props;
    const activeColumnDefinitions = getExpandedActiveColumns({
      columns,
      getColumnDefinition,
    });

    let destinationIndex = index;

    if (filter) {
      const filteredColumns = this.getFilteredColumns(activeColumnDefinitions);
      const currentColumnAtIndex = filteredColumns[destinationIndex];
      const mainColumnIndex = _.findIndex(columns, ({ columnKey }) => {
        return (
          currentColumnAtIndex && currentColumnAtIndex.columnKey === columnKey
        );
      });

      destinationIndex =
        mainColumnIndex > -1 ? mainColumnIndex : columns.length;
    }

    return destinationIndex;
  }

  onDragEnd = (result) => {
    const { columns} = this.props;
    const { source, destination, draggableId } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    // reorder in the same list
    if (source.droppableId === destination.droppableId) {
      if (source.droppableId === 'inactive') {
        return;
      }
      const destinationIndex = this.resolveFilteredDestinationIndex(
        destination.index
      );
      const reordered = Array.from(columns);
      const [removed] = reordered.splice(source.index, 1);
      reordered.splice(destinationIndex, 0, removed);

      this.setColumns(reordered, removed);
      // add column
    } else if (source.droppableId === 'inactive') {
      const destinationIndex = this.resolveFilteredDestinationIndex(
        destination.index
      );
      const newColumns = Array.from(columns);
      newColumns.splice(destinationIndex, 0, { columnKey: draggableId });

      this.setColumns(newColumns);
      // remove column
    } else {
      const newColumns = Array.from(columns);
      const [removed] = newColumns.splice(source.index, 1);

      this.setColumns(newColumns, removed);
    }
  };

  getFilteredColumns(list) {
    const { filter } = this.state;
    if (!filter) {
      return list;
    }

    return list.filter((col) => {
      return (
        doesInputMatchBase(filter, col.computedLabel) ||
        doesInputMatchBase(filter, col.columnDefinition.description)
      );
    });
  }

  renderList({ listId, label, expandedColumns }) {
    const { listHeight } = this.state;
    const { classes, pinnedColumns: pinnedColumnState} = this.props;
    const leftPinnedState = pinnedColumnState.left || [];
    const rightPinnedState = pinnedColumnState.right || []; 
    const pinnedColumns = leftPinnedState.concat(rightPinnedState);
   
    const styleKey = _.upperFirst(listId);

    return (
      <div className={classes[`list${styleKey}`]}>
        <div className={cx(classes.listTitle, classes[`listTitle${styleKey}`])}>
          {label}
        </div>
        <Droppable droppableId={listId}>
          {(provided, snapshot) => {
            return (
              <div
                ref={provided.innerRef}
                className={cx(
                  classes.droppable,
                  classes.listSizer,
                  classes[`droppable${styleKey}`]
                )}
                style={{
                  height: listId === 'active' ? listHeight : listHeight - 80,
                }}
              >
                {expandedColumns.map((item, index) => {
                  const { columnDefinition: def, column, computedLabel } = item;
                  let uid = def.columnKey;
                  if (column && column.columnUid) {
                    uid = column.columnUid;
                  }

                  return (
                    <Draggable key={uid} draggableId={uid} index={index}>
                      {(provided, snapshot) => (
                        <div
                          className={cx(
                            classes.item,
                            snapshot.isDragging && classes.itemDragging
                          )}
                          style={provided.draggableProps.style}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          onDoubleClick={this.getDoubleClickHandle(uid)}
                        >
                          <DragIndicatorIcon
                            className={cx(
                              classes.dragIcon,
                              classes[`dragIcon${styleKey}`]
                            )}
                          />
                          <div className={classes.itemLabel}>
                            {computedLabel}
                          </div>
                          {pinnedColumns.includes(uid) && (
                            <Tooltip
                              title="Pinned"
                              disableFocusListener
                              disableTouchListener
                              enterDelay={100}
                              placement="top"  
                            >
                              <PushPinIcon className={classes.infoIcon}/>
                            </Tooltip>
                          )}
                          {isSortable(def) && (
                            <Tooltip
                              title="Sortable"
                              disableFocusListener
                              disableTouchListener
                              enterDelay={100}
                              placement="top"
                            >
                              <SortIcon className={classes.infoIcon} />
                            </Tooltip>
                          )}
                          <Tooltip
                            title={def.description}
                            disableFocusListener
                            disableTouchListener
                            enterDelay={100}
                            placement="top"
                          >
                            <InfoIcon className={classes.infoIcon} />
                          </Tooltip>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </div>
    );
  }

  resetColumns = () => {
    const { resetColumns, tableCacheKey, defaultPinnedColumns} = this.props;
    resetColumns({ tableCacheKey, defaultPinnedColumns });
  };

  setColumns(columns, movedColumn = undefined) {
    const { setColumns, tableCacheKey, getColumnDefinition } = this.props;
    setColumns({
      tableCacheKey,
      getColumnDefinition,
      columns,
      movedColumn
    });
  }

  dynamicColumnSelectOnChange = (opt) => {
    this.setState({
      dynamicColumnKeySelectValue: opt ? opt.value : null,
    });
  };

  endAddDynamicColumn = () => {
    this.setState({
      dynamicColumnKeySelectValue: null,
      dynamicDataInputValues: {},
    });
  };

  setDynamicDataInputValues = (values) => {
    this.setState({ dynamicDataInputValues: values });
  };

  createDynamicColumn = () => {
    const { columns } = this.props;
    const { dynamicColumnKeySelectValue, dynamicDataInputValues } = this.state;

    this.setColumns(
      columns.concat([
        {
          columnKey: dynamicColumnKeySelectValue,
          columnUid: `${dynamicColumnKeySelectValue}_${Date.now()}_${Math.round(
            Math.random() * 1000
          )}`,
          dataInputValues: dynamicDataInputValues,
        },
      ])
    );

    this.setState({
      dynamicDataInputValues: {},
    });
  };

  getCurrentDynamicColumnDef() {
    const { getColumnDefinition } = this.props;
    const { dynamicColumnKeySelectValue } = this.state;

    let dynamicColumnDef;
    if (dynamicColumnKeySelectValue) {
      dynamicColumnDef = getColumnDefinition({
        columnKey: dynamicColumnKeySelectValue,
      });
    }
    return dynamicColumnDef;
  }

  dynamicFormIsValid(def, values) {
    const invalidOptions = def.dataInputOptions.filter((option) => {
      return option.required && !values[option.parameter];
    });

    return invalidOptions.length === 0;
  }

  renderDynamicForm() {
    const { classes, getAllColumnDefinitions } = this.props;
    const { dynamicColumnKeySelectValue, dynamicDataInputValues } = this.state;
    const dynamic = getExpandedDynamicColumns({ getAllColumnDefinitions });

    const dynamicColumnDef = this.getCurrentDynamicColumnDef();

    return (
      <div className={classes.listInactive}>
        <div className={classes.listTitle}>Dynamic Columns</div>
        <div className={classes.dynamicTopControls}>
          <Select
            value={dynamicColumnKeySelectValue}
            onChange={this.dynamicColumnSelectOnChange}
            placeholder="Configure a dynamic column"
            suggestions={dynamic.map((item) => ({
              label: item.computedLabel,
              value: item.columnDefinition.columnKey,
            }))}
          />
          {dynamicColumnKeySelectValue && (
            <Button
              size="small"
              className={classes.dynamicButton}
              color="secondary"
              disabled={!dynamicColumnKeySelectValue}
              onClick={this.endAddDynamicColumn}
            >
              Done
            </Button>
          )}
        </div>
        {dynamicColumnKeySelectValue && (
          <div className={classes.listSizer}>
            <SemanticForm
              options={dynamicColumnDef.dataInputOptions}
              values={dynamicDataInputValues}
              onChange={this.setDynamicDataInputValues}
            />
            <br />
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={this.createDynamicColumn}
              disabled={
                !this.dynamicFormIsValid(
                  dynamicColumnDef,
                  dynamicDataInputValues
                )
              }
            >
              Append Dynamic Column
            </Button>
          </div>
        )}
      </div>
    );
  }

  render() {
    const {
      columns,
      getColumnDefinition,
      getAllColumnDefinitions,
      classes,
      hideModal,
    } = this.props;

    const active = getExpandedActiveColumns({ columns, getColumnDefinition });
    const inactive = getExpandedInactiveColumns({
      columns,
      getAllColumnDefinitions,
    });

    return (
      <Paper className={classes.container}>
        <ModalHeader onClose={hideModal}>Configure Table Columns</ModalHeader>

        <div className={classes.controls}>
          <TextField
            placeholder="Filter columns"
            classes={{ root: classes.search }}
            onChange={this.filterOnChange}
          />
          <Tooltip title="Restore all columns to their default state">
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              onClick={this.resetColumns}
              className={classes.defaultsButton}
            >
              Restore Defaults
            </Button>
          </Tooltip>
        </div>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <div className={classes.listContainer}>
            {this.renderList({
              listId: 'active',
              label: 'Visible Columns',
              expandedColumns: this.getFilteredColumns(active),
            })}
            <div>
              {this.renderDynamicForm()}
              {!this.state.dynamicColumnKeySelectValue &&
                this.renderList({
                  listId: 'inactive',
                  label: 'Additional Columns',
                  expandedColumns: this.getFilteredColumns(inactive),
                })}
            </div>
          </div>
        </DragDropContext>
      </Paper>
    );
  }
}

export default withStyles(styles)(ColumnConfigModal);
