import { MultiselectQueryFilter, NestedQueryFilter } from '@shield-ui/query-filters';
import {
  getProjectOptions,
  getRequirementOptions,
  getRequirementStateOptions,
  getRequirementTypeOptions
} from "../../../services/requirements";


export const requirementQueryFilter = new MultiselectQueryFilter({
  id: 'testDefinitionRequirements',
  controlLabel: 'Requirement',
  esField: 'test_definition.requirements.id',
  unsetValueDisabled: true,
  description: '',
  behaviorConfig: {
    fetchOptions: getRequirementOptions,
  },
});
export const requirementTypeQueryFilter = new MultiselectQueryFilter({
  id: 'testDefinitionRequirementTypes',
  controlLabel: 'Requirement Type',
  esField: 'test_definition.requirements.requirement_type',
  unsetValueDisabled: true,
  description: '',
  behaviorConfig: {
    fetchOptions: getRequirementTypeOptions,
  },
});

const requirementStateQueryFilter = new MultiselectQueryFilter({
  id: 'testDefinitionRequirementStates',
  controlLabel: 'States',
  esField: 'test_definition.requirements.state',
  unsetValueDisabled: true,
  description: '',
  behaviorConfig: {
    fetchOptions: getRequirementStateOptions,
  },
});
export const requirementProjectsQueryFilter = new MultiselectQueryFilter({
  id: 'testDefinitionRequirementProjects',
  controlLabel: 'Projects',
  unsetValueDisabled: true,
  esField: 'test_definition.requirements.project.id',
  description: '',
  behaviorConfig: {
    fetchOptions: getProjectOptions,
  },
});



export default new NestedQueryFilter({
  id: 'testDefinitionNestedRequirements',
  controlLabel: 'Test Requirements',
  esField: 'test_definition.requirements',
  queryFilters: [
    requirementTypeQueryFilter,
    requirementProjectsQueryFilter,
    requirementStateQueryFilter,
    requirementQueryFilter,
  ]
})
