import React from 'react';
import { ContextualToggle } from '@shield-ui/controls';
import { ListOperator, defaultKeywordsListOperator } from '../../filterTypes';

const operatorOptions = [
  {
    label: 'AND',
    value: ListOperator.and,
  },
  {
    label: 'OR',
    value: ListOperator.or,
  },
];

interface Props {
  value: ListOperator;
  onChange: (value: ListOperator) => void;
}

export default function ListOperatorSwitch(props: Props) {
  const { value, onChange } = props;

  return (
    <ContextualToggle
      options={operatorOptions}
      onChange={onChange}
      value={value || defaultKeywordsListOperator}
    />
  );
}
