import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import withStyles from '@mui/styles/withStyles';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import ShieldAvatar from '../users/ShieldAvatar';
import FlightActionIconButton from '../buttons/OutlinedIconButton';
import { REVIEW_STATUSES } from '../../services/reviews';
import ReviewStatusDisplay from './ReviewStatusDisplay';
import { reviewStatusColors } from '../../lib/sharedStyles';
import { getShortDate } from '@shield-ui/utils';
import { colors } from '@shield-ui/styles';
import {
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Button,
  Tooltip,
} from '@mui/material';

const styles = (theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    overflowX: 'auto',
  },
  reviewerListItem: {
    fontSize: '0.9em',
  },
  editButton: {
    fontSize: '0.5em',
  },
  menuItemStatusDisplay: {
    fontSize: 14,
  },
  button: {
    margin: theme.spacing(1),
    fontSize: '0.95em',
    width: 160,
    height: 34,
  },
  ..._.reduce(
    reviewStatusColors,
    (acc, value, key) => {
      acc[key] = {
        color: value,
        borderColor: value,
      };
      return acc;
    },
    {}
  ),
  tableRow: {
    backgroundColor: colors.semantic.darkBackground,
    margin: '5px 0',
  },
  propertyContainer: {
    display: 'inline-flex',
    margin: `0 8px`,
    flexDirection: 'column',
  },
  propertyHeader: {
    color: '#bbb',
    fontWeight: 300,
    lineHeight: '0.7em',
    fontSize: 12,
  },
  propertyValue: {
    fontSize: 13,
  },
  emptyList: {
    padding: 30,
    textAlign: 'center',
    color: '#bbb',
  },
  menuItem: {
    minWidth: 160,
  },
});

class ReviewList extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, reviewToUpdate: null });
  };

  getCanEditProps(review) {
    const { currentUser } = this.props;
    const { reviewer, reviewerTeam, requester } = review;

    if (reviewer) {
      const canEdit =
        currentUser.id === reviewer.id || currentUser.id === requester.id;

      if (!canEdit) {
        return {
          canEdit,
          cantEditTooltip:
            'You need to be the requester of the review or the reviewer to be able to change this association',
        };
      }
    } else if (reviewerTeam) {
      const userTeamIds = _.map(currentUser.teams, 'id');
      if (
        requester.id !== currentUser.id &&
        userTeamIds.indexOf(reviewerTeam.id) === -1
      ) {
        return {
          canEdit: false,
          cantEditTooltip: 'You are not a member of the assigned team',
        };
      }
    }

    return {
      canEdit: true,
      cantEditTooltip: undefined,
    };
  }

  renderActionMenu() {
    const { anchorEl, reviewToUpdate } = this.state;
    const { classes, reviews } = this.props;

    const currentReviewer = _.find(reviews, (review) => {
      return reviewToUpdate === review.id;
    });
    const currentReviewStatus = _.get(currentReviewer, 'reviewStatus');

    if (!currentReviewStatus) {
      return null;
    }

    return (
      <Menu
        id="status-update-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={this.handleClose}
      >
        {_.map(REVIEW_STATUSES, (reviewStatus) => {
          if (reviewStatus === currentReviewStatus) {
            return null;
          }
          return (
            <MenuItem
              key={reviewStatus}
              className={classes.menuItem}
              onClick={() => {
                this.props.updateReviewStatus(reviewToUpdate, reviewStatus);
                this.handleClose();
              }}
            >
              <ReviewStatusDisplay
                reviewStatus={reviewStatus}
                className={classes.menuItemStatusDisplay}
              />
            </MenuItem>
          );
        })}
      </Menu>
    );
  }

  render() {
    const { classes, deleteReview, reviews } = this.props;

    const cellStyle = {
      verticalAlign: 'middle',
      fontSize: '0.8em',
      padding: `4px 10px 4px 10px`,
    };
    const iconStyle = {
      display: 'inline-block',
      float: 'left',
      verticalAlign: 'middle',
    };
    const iconCell = {
      width: '40px',
      padding: '14px 0 14px 14px',
      margin: 0,
    };

    if (!reviews.length) {
      return <div className={classes.emptyList}>Add some reviewers below</div>;
    }

    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableBody>
            {_.sortBy(reviews, 'createdAt').map((review) => {
              const {
                reviewStatus,
                reviewer,
                reviewerTeam,
                requester,
                createdAt,
                reviewedTime,
              } = review;
              const { canEdit, cantEditTooltip } = this.getCanEditProps(review);

              const buttonClass = classes[reviewStatus];

              return (
                <TableRow key={review.id} className={classes.tableRow}>
                  <TableCell align="center" style={iconCell}>
                    <span style={iconStyle}>
                      <ShieldAvatar user={reviewer} team={reviewerTeam} />
                    </span>
                  </TableCell>
                  <TableCell component="td" scope="row" style={cellStyle}>
                    <span style={{ fontSize: 14 }}>
                      {reviewer && reviewer.name}
                      {reviewerTeam && reviewerTeam.name}
                    </span>
                  </TableCell>
                  <TableCell style={cellStyle}>
                    <div className={classes.propertyContainer}>
                      <div className={classes.propertyHeader}>Assigned By</div>
                      <div className={classes.propertyValue}>
                        {requester.name}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell style={cellStyle}>
                    <div className={classes.propertyContainer}>
                      <div className={classes.propertyHeader}>Assigned</div>
                      <div className={classes.propertyValue}>
                        {getShortDate(createdAt)}
                      </div>
                    </div>
                    {reviewedTime && (
                      <div className={classes.propertyContainer}>
                        <div className={classes.propertyHeader}>Completed</div>
                        <div className={classes.propertyValue}>
                          {getShortDate(reviewedTime)}
                        </div>
                      </div>
                    )}
                  </TableCell>
                  <TableCell align="right" style={cellStyle}>
                    <Tooltip
                      title={cantEditTooltip || ''}
                      enterDelay={300}
                      disableHoverListener={canEdit}
                      disableFocusListener={canEdit}
                      disableTouchListener={canEdit}
                    >
                      <div>
                        <Button
                          variant="outlined"
                          size="small"
                          disabled={!canEdit}
                          onClick={(e) => {
                            this.setState({ reviewToUpdate: review.id });
                            this.handleClick(e);
                          }}
                          className={classNames(classes.button, buttonClass)}
                        >
                          <ReviewStatusDisplay reviewStatus={reviewStatus} />
                        </Button>

                        {/* You can only edit if you requested the review or are the reviewer (TODO add tooltip) */}
                        {canEdit && this.renderActionMenu()}

                        <FlightActionIconButton
                          disabled={!canEdit}
                          onClick={() => deleteReview(review.id)}
                        >
                          <DeleteIcon />
                        </FlightActionIconButton>
                      </div>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

export default withStyles(styles)(ReviewList);
