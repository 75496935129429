import React from 'react';
import sdkClient from '../../lib/hmdsdkClient';
import {
  AssertionService,
  CreateRequest,
  Assertion,
  AssertionType,
} from '@hmd/sdk/api/tests/evaluations/assertions/v1';
import { EvaluationState } from '@hmd/sdk/api/tests/evaluations/v1';
import { showError, showSuccess } from '../../lib/messages';
import { Select, Form, Input, Button } from 'antd';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import AntPopoverConfigProvider from '../test-definitions/TestDefinitionForm/AntPopoverConfigProvider';
import { prettifyString } from '@shield-ui/utils';
import tracker from '../../lib/tracker';
const { Option } = Select;

const createEvaluationAssertion = (assertion) => {
  const req = new CreateRequest();
  req.setAssertion(assertion);
  tracker.trackEvent('EvaluationAssertion', 'Create');
  return sdkClient
    .unary(AssertionService.Create, req)
    .then((res) => {
      showSuccess(`Assertion Created`);
      return res;
    })
    .catch(showError);
};

type Props = {
  robotLogId: string;
  isCreateAssertionOpen: boolean;
  closeCreateAssertion: () => void;
  onCreate: () => void;
};

export default function CreateAssertion(props: Props) {
  const { robotLogId, isCreateAssertionOpen, closeCreateAssertion, onCreate } =
    props;
  const onFinish = (id: string, values: any) => {
    const createAssertion = new Assertion();
    createAssertion.setAssertionType(values.assertionType);
    createAssertion.setRobotLogId(id);
    createAssertion.setEvaluationState(values.evaluationState);
    createAssertion.setMessage(values.message);
    createEvaluationAssertion(createAssertion).then(() => {
      closeCreateAssertion();
      onCreate();
    });
  };
  return (
    <Dialog
      open={isCreateAssertionOpen}
      maxWidth={'xs'}
      fullWidth={true}
      onClose={closeCreateAssertion}
    >
      <DialogTitle>Add Evaluation Assertion</DialogTitle>
      <AntPopoverConfigProvider>
        <Form
          name="createassertion"
          onFinish={(values) => {
            onFinish(robotLogId, values);
          }}
          labelCol={{ span: 20 }}
          wrapperCol={{ span: 20 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          size="large"
          layout="vertical"
        >
          <DialogContent>
            <Form.Item
              label="Assertion Type"
              name="assertionType"
              rules={[{ required: true }]}
              initialValue={AssertionType.REVIEW}
            >
              <Select defaultValue={AssertionType.REVIEW}>
                {Object.entries(AssertionType).map((aType) => {
                  if (aType[1] > 0) {
                    return (
                      <Option key={aType[1]} value={aType[1]}>
                        {prettifyString(aType[0])}
                      </Option>
                    );
                  }
                  return null;
                })}
              </Select>
            </Form.Item>

            <Form.Item
              label="Evaluation State"
              name="evaluationState"
              rules={[{ required: true }]}
            >
              <Select
                style={{}}
                placement="bottomRight"
                dropdownStyle={{ height: 100, overflowY: 'auto' }}
              >
                {Object.entries(EvaluationState).map((eType) => {
                  if (eType[1] > 0) {
                    return (
                      <Option key={eType[1]} value={eType[1]}>
                        {prettifyString(eType[0])}
                      </Option>
                    );
                  }
                  return null;
                })}
              </Select>
            </Form.Item>

            <Form.Item label="Message" name="message">
              <Input />
            </Form.Item>
          </DialogContent>
          <DialogActions>
            <Button type="text" size="large" onClick={closeCreateAssertion}>
              Cancel
            </Button>
            <Button size="large" htmlType="submit" type="primary" shape="round">
              Create
            </Button>
          </DialogActions>
        </Form>
      </AntPopoverConfigProvider>
    </Dialog>
  );
}
