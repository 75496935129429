import React from 'react';
import { Link } from '@mui/material';

function FtsLinkForFlight({ json }) {
  const ftsLink = json ? JSON.parse(json).fts_sheet_link : '';

  if (!ftsLink) {
    return null;
  }

  return (
    <Link href={ftsLink} target="__blank" underline="hover">
      FTS Link
    </Link>
  );
}

export default FtsLinkForFlight;
