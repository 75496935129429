import React from 'react';
import { Select } from '@shield-ui/controls';
import { useDidMount } from '@shield-ui/hooks';
import container from './componentsContainer';

function ComponentOwnerSelect(props) {
  const { ensureComponents, componentOwnerOptions, ...rest } = props;

  useDidMount(ensureComponents);

  const extra = !componentOwnerOptions.length
    ? {
        placeholder: 'Loading...',
        disabled: true,
      }
    : undefined;

  return (
    <Select
      suggestions={componentOwnerOptions}
      placeholder="Component Owners"
      {...rest}
      {...extra}
    />
  );
}

export default container(ComponentOwnerSelect);
