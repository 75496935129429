import _ from 'lodash';

// defined in <PROJECT_DIR>/public/config.js
const globalConfig = _.get(window, 'shieldai_config', {});

function transformBoolean(val) {
  if (val === 'true') {
    return true;
  } else if (val === 'false') {
    return false;
  }
  return val;
}

const config = {
  systemUser: "" + _.get(globalConfig, 'SystemUser', ''),
  useAuth: _.get(globalConfig, 'Hangar.UseAuth') !== 'false',
  publicUrl: _.get(globalConfig, 'Webpack.PublicUrl', ''),
  publicPath: _.get(globalConfig, 'Webpack.PublicPath', '/'),
  softwareVersion: process.env.NX_SHIELDUI_PACKAGE_VERSION || '',
  commitHash: process.env.NX_SHIELDUI_GIT_HASH || '',
  branchName: process.env.NX_SHIELDUI_GIT_BRANCH || '',
  environmentDisplay: {
    showDisplay: transformBoolean(
      _.get(globalConfig, 'EnvironmentDisplay.ShowDisplay')
    ),
    label: _.get(globalConfig, 'EnvironmentDisplay.Label'),
    color: _.get(globalConfig, 'EnvironmentDisplay.Color'),
  },
  hangarApolloClientUseLocalStorageAuthorization: _.get(globalConfig, 'Hangar.AuthorizationLocalStorage', false),
  api: {
    base: _.get(globalConfig, 'Hangar.BaseUrl', 'http://localhost:8000'),
  },
  hmds: {
    grpcHost: _.get(globalConfig, 'HMDS.GrpcHost', 'http://localhost:5001'),
  },
  microsoftApi: {
    clientId: _.get(globalConfig, 'Microsoft.ClientId'),
    redirectUri: _.get(globalConfig, 'Microsoft.RedirectUri'),
    postLogoutRedirectUri: _.get(
      globalConfig,
      'Microsoft.PostLogoutRedirectUri'
    ),
    tenant: _.get(globalConfig, 'Microsoft.Tenant'),
  },
  matomo: {
    siteId: _.get(globalConfig, 'Matomo.SiteId'),
    url: _.get(globalConfig, 'Matomo.Url', 'https://analytics.hmds.ai'),
  },
};

export default config;
