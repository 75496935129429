import _ from 'lodash';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { getNumberDisplayToDecimalPlace } from '@shield-ui/utils';

const useStyles = makeStyles((theme) => ({
  valueDisplay: {
    fontFamily: 'monospace',
    padding: theme.spacing(0.5, 1.5),
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
    borderRadius: theme.shape.borderRadius,
    fontWeight: 600,
    margin: theme.spacing(0, 1),
  },
}));

export default function OutputRowDisplayValue({ value }) {
  const classes = useStyles();

  if (_.isUndefined(value)) {
    return null;
  }

  let valueDisplay;
  if (_.isNumber(value)) {
    valueDisplay = getNumberDisplayToDecimalPlace(value, 4);
  } else if (_.isArray(value)) {
    valueDisplay = 'Array';
  } else if (_.isObject(value)) {
    valueDisplay = 'Object';
  } else if (_.isNull(value)) {
    valueDisplay = 'NULL';
  } else if (_.isString(value)) {
    if (value.length > 8) {
      valueDisplay = value.slice(0, 7) + '...';
    } else {
      valueDisplay = value;
    }
  }

  return <div className={classes.valueDisplay}>{valueDisplay}</div>;
}
