import React, { Fragment } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Grid } from '@mui/material';
import { MarkdownContent } from '@shield-ui/core';
import { TextField } from '@shield-ui/controls';

const styles = {
  noteContent: {
    padding: `0px 0px`,
    fontSize: '1.4em',
    lineHeight: '1.2em',
    color: '#e1e2e2',
    cursor: 'pointer',
    fontWeight: 500,
    backgroundColor: 'rgba(0,0,0,0)',
  },
};

class NoteContent extends React.PureComponent {
  render() {
    const { value, isEditing, onChange, onEditRequest, classes } = this.props;

    let innerContent;
    if (isEditing) {
      innerContent = (
        <TextField
          placeholder="Enter your flight notes"
          multiline
          autoFocus
          rows={5}
          value={value}
          onChange={onChange}
        />
      );
    } else {
      innerContent = (
        <div onClick={onEditRequest} className={classes.noteContent}>
          <MarkdownContent md={value} />
        </div>
      );
    }

    return (
      <Fragment>
        <Grid item xs={1} />
        <Grid item xs={10}>
          {innerContent}
        </Grid>
        <Grid item xs={1} />
      </Fragment>
    );
  }
}

export default withStyles(styles)(NoteContent);
