import React from 'react';
import PropTypes from 'prop-types';
import MoreButton from '@mui/icons-material/MoreHoriz';
import {
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import OutlinedIconButton from './buttons/OutlinedIconButton';

const styles = {
  borderless: {
    border: 'none',
  },
};

class MoreMenu extends React.Component {
  static propTypes = {
    buttonProps: PropTypes.object,
    borderless: PropTypes.bool,
    menuItems: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        description: PropTypes.string,
        disabled: PropTypes.bool,
        Icon: PropTypes.any,
      })
    ).isRequired,
  };

  static defaultProps = {
    buttonProps: {},
  };

  state = {
    menuAnchor: undefined,
  };

  onShowMenu = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    this.setState({ menuAnchor: evt.target });
  };

  onHideMenu = (evt) => {
    // The entire portal and dialog are rendered as the child of the the parent
    // so clicking outside of the dialog to dismiss the popover
    // results in onClick for the parent to be fired
    // eg putting this in an accordion would cause the accordion to open up
    if (evt) {
      evt.stopPropagation();
      evt.preventDefault();
    }
    this.setState({ menuAnchor: undefined });
  };

  renderMenu() {
    const { menuItems } = this.props;
    const { menuAnchor } = this.state;

    return (
      <Menu
        open={!!menuAnchor}
        anchorEl={menuAnchor}
        onClose={this.onHideMenu}
        // above tooltip
        style={{ zIndex: 1600 }}
        disableAutoFocusItem
      >
        {menuItems.map((menuItem) => {
          return (
            <MenuItem
              key={menuItem.label}
              disabled={menuItem.disabled}
              onClick={(evt) => {
                evt.stopPropagation();
                evt.preventDefault();
                this.onHideMenu();
                menuItem.onClick();
              }}
            >
              {menuItem.Icon && (
                <ListItemIcon>
                  <menuItem.Icon />
                </ListItemIcon>
              )}
              <ListItemText>
                <div>{menuItem.label}</div>
                {menuItem.description && (
                  <Typography variant="body2">
                    {menuItem.description}
                  </Typography>
                )}
              </ListItemText>
            </MenuItem>
          );
        })}
      </Menu>
    );
  }

  render() {
    const { buttonProps = {}, borderless, classes } = this.props;

    return (
      <React.Fragment>
        <OutlinedIconButton
          ref={this.buttonRef}
          size="small"
          className={borderless ? classes.borderless : undefined}
          {...buttonProps}
          onClick={this.onShowMenu}
        >
          <MoreButton />
        </OutlinedIconButton>
        {this.renderMenu()}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(MoreMenu);
