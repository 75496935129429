import _ from 'lodash';
import {
  TeamService,
  ListRequest,
  Team,
  AddUsersRequest,
} from '@hmd/sdk/api/iam/teams/v1';
import sdkClient from '../lib/hmdsdkClient';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { queryClient } from '../queryClient';
import { matchifyString } from '@shield-ui/utils';
import store from '../redux/store';
import { ParentMultiselectOption } from '@shield-ui/query-filters';
import { MultiselectOption } from '@shield-ui/query-filters';

const fetchAllTeams = async (): Promise<Team.AsObject[]> => {
  const teams: Team.AsObject[] = [];
  const pageSize = 200;

  let token = null;

  while (token !== '') {
    // If token is empty, we're done
    if (token === '') {
      return teams;
    }
    const sr = new ListRequest();

    // If token is null, it's the first request
    if (token !== null) {
      sr.setPageToken(token);
    }
    sr.setPageSize(pageSize);

    // Append to team list and set nextPageToken
    let nextToken = '';
    await sdkClient.unary(TeamService.List, sr).then((res) => {
      nextToken = res.getNextPageToken();
      const fetchedTeams = res.getTeamsList();
      for (const u of fetchedTeams) {
        teams.push(u.toObject());
      }
    });
    token = nextToken;
  }
  return _.sortBy(teams, (team) => matchifyString(team.name));
};

const TEAMS_QUERY_KEY = 'teams';

//-----------------

function getFetchTeamsQueryClientParams() {
  return {
    queryKey: [TEAMS_QUERY_KEY],
    queryFn: () => fetchAllTeams(),
  };
}

// hook for getting all teams
export const useTeams = (): UseQueryResult<Team.AsObject[]> =>
  useQuery(getFetchTeamsQueryClientParams());

// for working with this as a promise
export const getTeams = (): Promise<Team.AsObject[]> =>
  queryClient.fetchQuery(getFetchTeamsQueryClientParams());

//-----------------
function mapTeamOptions() {
  return {
    queryKey: [TEAMS_QUERY_KEY, 'options'],
    queryFn: async () => {
      const res = await getTeams();
      const opts = res.map((team) => {
        return {
          value: team.id,
          label: team.name,
          users: team.userIdsList,
        };
      });
      return opts;
    },
  };
}

//Promise for team options
export const getTeamOptions = (): Promise<MultiselectOption[]> =>
  queryClient.fetchQuery(mapTeamOptions());

//Hook for working with team options
export const useTeamOptions = (): UseQueryResult<MultiselectOption[]> =>
  useQuery(mapTeamOptions());

//-----------------
//This is different from teamOptions because it identifies @My team & is used for ParentChildSelect
function mapTeamParentOptions() {
  return {
    queryKey: [TEAMS_QUERY_KEY, 'parentOptions'],
    queryFn: async () => {
      const res = await getTeams();
      const currentUser = store.getState().authUser.currentUser;

      const opts = res.reduce((result, team) => {
        if (team.userIdsList.indexOf(currentUser.id) > -1) {
          result.unshift({
            value: team.id,
            label: `${team.name} (My Team)`,
            childValues: team.userIdsList,
          });
        } else {
          result.push({
            value: team.id,
            label: team.name,
            childValues: team.userIdsList,
          });
        }
        return result;
      }, []);

      return opts;
    },
  };
}

//Promise for working with team parent options
export const getTeamParentOptions = (): Promise<ParentMultiselectOption[]> =>
  queryClient.fetchQuery(mapTeamParentOptions());

//Hook for working with team parent options
export const useTeamParentOptions = (): UseQueryResult<MultiselectOption[]> =>
  useQuery(mapTeamParentOptions());
