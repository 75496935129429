import React, { useState, useRef, useCallback } from 'react';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import DownArrow from '@mui/icons-material/KeyboardArrowDown';
import { colors } from '@shield-ui/styles';
import { LayoutProps } from '../common';
import { useDidMount } from '@shield-ui/hooks';

const useStyles = makeStyles((theme) => ({
  target: {
    flexShrink: 0,
    borderRadius: 2,
    padding: theme.spacing(0.5, 1.25),
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover': {
      backgroundColor: colors.hues.grays[170],
    },
    minHeight: 40,
  },
  targetIsOpened: {
    border: `1px solid ${colors.hues.blues[60]}`,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  targetText: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
  },
  label: {
    color: colors.hues.grays[80],
    fontSize: 14.5,
    minWidth: 90,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  labelValueDisplay: {
    fontWeight: 700,
    color: '#fff',
  },
  icon: {
    marginLeft: theme.spacing(0.25),
    fontSize: 18,
  },
  popper: {
    zIndex: 1000,
  },
  loadingMessage: {
    textAlign: 'center',
    minWidth: 180,
    color: colors.hues.grays[120],
    fontSize: 10,
    padding: theme.spacing(2, 0),
  },
  dropdown: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.hues.grays[190],
    minWidth: 200,
    maxWidth: 800,
    padding: theme.spacing(2),
    boxShadow:
      '0 6.4px 14.4px 0 rgba(0,0,0,.132), 0 1.2px 3.6px 0 rgba(0,0,0,.108)',
  },
}));

// TODO consolidate this component and ControlBarLayout
export default function ModalControlLayout(props: LayoutProps) {
  const { valuePreview, ControlElement, queryFilter, value, onForceUpdate } =
    props;
  const [isOpened, setIsOpened] = useState(false);
  const targetEl = useRef(null);
  const classes = useStyles();

  const onOpen = useCallback(() => {
    queryFilter.ensureControlData(value);
    setIsOpened(true);
  }, []);
  const onClose = useCallback(() => {
    setIsOpened(false);
  }, []);

  useDidMount(() => {
    queryFilter.events.on('updatedControlData', onForceUpdate);

    // when we mount, we ensure control data only if we currently have a value
    if (queryFilter.hasValue(value)) {
      queryFilter.ensureControlData(value);
    }
    return () => {
      queryFilter.events.off('updatedControlData', onForceUpdate);
    };
  });

  return (
    <>
      <div
        ref={targetEl}
        className={cx(classes.target, isOpened && classes.targetIsOpened)}
        onClick={isOpened ? onClose : onOpen}
      >
        <div className={classes.targetText}>
          <div
            className={cx(
              classes.label,
              valuePreview && classes.labelValueDisplay
            )}
          >
            {valuePreview || (isOpened ? 'Cancel' : 'Add')}
          </div>
        </div>
        <DownArrow className={classes.icon} />
      </div>
      {isOpened && (
        <Popper
          className={classes.popper}
          open={isOpened}
          anchorEl={targetEl.current}
          placement="bottom-start"
        >
          <ClickAwayListener onClickAway={onClose}>
            <div className={classes.dropdown}>
              {isOpened && queryFilter.hasControlData() && ControlElement}
              {isOpened && !queryFilter.hasControlData() && (
                <div className={classes.loadingMessage}>Loading...</div>
              )}
            </div>
          </ClickAwayListener>
        </Popper>
      )}
    </>
  );
}
