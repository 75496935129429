import _ from 'lodash';
import { createReducer } from "@reduxjs/toolkit";
import update from 'immutability-helper';
import { SET_CURRENT_USER, SET_COLLECTION_IS_FAVORITE } from './actions';
import {HangarUserType } from "@shield-ui/hangar-service";

type InitialState = {
  currentUser?: HangarUserType;
  favoriteCollectionMap: Record<string, boolean>
}

const initialState: InitialState = {
  currentUser: null,
  favoriteCollectionMap: {},
};

export default createReducer<InitialState>(initialState, {
  [SET_CURRENT_USER]: (state, action) => {
    const { currentUser } = action.payload;

    const favoriteCollectionMap = _.get(
      currentUser,
      'favoriteCollections.edges',
      []
    ).reduce((acc, edge) => {
      const id = _.get(edge, 'node.id');
      if (id) {
        acc[id] = true;
      }
      return acc;
    }, {});
    delete currentUser.favoriteCollections;
    delete currentUser.__typename;

    return update(state, {
      currentUser: { $set: currentUser },
      favoriteCollectionMap: { $set: favoriteCollectionMap },
    });
  },
  [SET_COLLECTION_IS_FAVORITE]: (state, action) => {
    const { collectionId, isFavorite } = action.payload;

    return update(state, {
      favoriteCollectionMap: {
        [collectionId]: { $set: isFavorite },
      },
    });
  },
});
