import { useMemo } from 'react';
import {
  QueryFilterItem,
  MultiselectQueryFilter,
  TypeToFindMultiselectQueryFilter,
} from '@shield-ui/query-filters';
import { TagType } from '@hmd/sdk/api/tests/definitions/v1';
import {
  fetchQueryFilterOptionsForTagsOfType,
  searchForTestDefinitionTags,
} from '../../services/testDefinitions';
import { fetchEnvironmentOptions } from '../../services/environments';
import { getRequirementOptions } from '../../services/requirements';
import { getUserOptions } from '../../services/users';
import { getTeamOptions } from '../../services/teams';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectPlatformSubtypeOptions,
  selectPlatformOptions,
  ensurePlatforms,
  ensurePlatformSubtypes,
} from '../../redux/testDefinitions.slice';
import { RootState } from '../../redux/store';

export function getTagQueryFilterItem(
  label: string,
  id: string,
  tagType,
  elasticField = ''
) {
  const options = elasticField
    ? {
        esField: elasticField,
      }
    : {
        disableExcludes: true,
        disableListOperator: true,
        unsetValueDisabled: true,
      };
  return {
    queryFilter: new TypeToFindMultiselectQueryFilter({
      id,
      controlLabel: label,
      isMulti: true,
      ...options,
      getOptions: async ({ values }) => {
        return values.map((v) => ({ value: v, label: v + '' }));
      },
      searchSuggestedOptions: async () => {
        const res = await fetchQueryFilterOptionsForTagsOfType(tagType);
        return res;
      },
      searchOptions: async ({ input }) => {
        const tags = await searchForTestDefinitionTags(input, tagType);
        return tags.map((t) => ({ value: t, label: t }));
      },
    }),
  };
}

interface UseMultiselectQFProps {
  id: string;
  label: string;
  selector: (state: RootState) => { label: string; value: string }[] | void;
  fetcher: () => void;
}

function useMultiselectQueryFilter(props: UseMultiselectQFProps) {
  const { id, label, selector, fetcher } = props;
  const opts = useSelector(selector);
  const dispatch = useDispatch();

  const qfi = useMemo(() => {
    const config = opts
      ? {
          options: opts,
        }
      : {
          fetchOptions: () => {
            dispatch(fetcher());
            return Promise.resolve([]);
          },
        };

    return {
      queryFilter: new MultiselectQueryFilter({
        controlLabel: label,
        id,
        unsetValueDisabled: true,
        disableExcludes: true,
        behaviorConfig: config,
      }),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opts]);

  return qfi;
}

export function useQueryFilterItems(): QueryFilterItem[] {
  const platformsQueryFilterItem = useMultiselectQueryFilter({
    id: 'platforms',
    label: 'Platforms',
    selector: selectPlatformOptions,
    fetcher: ensurePlatforms,
  });
  const platformSubtypesQueryFilterItem = useMultiselectQueryFilter({
    id: 'platformSubtypes',
    label: 'Platform Subtypes',
    selector: selectPlatformSubtypeOptions,
    fetcher: ensurePlatformSubtypes,
  });

  return useMemo(() => {
    return [
      platformsQueryFilterItem,
      platformSubtypesQueryFilterItem,
      {
        queryFilter: new MultiselectQueryFilter({
          id: 'environments',
          controlLabel: 'Environments',
          unsetValueDisabled: true,
          disableExcludes: true,
          behaviorConfig: {
            fetchOptions: fetchEnvironmentOptions,
          },
        }),
      },
      {
        queryFilter: new MultiselectQueryFilter({
          id: 'requirements',
          controlLabel: 'Requirements',
          unsetValueDisabled: true,
          disableExcludes: true,
          behaviorConfig: {
            fetchOptions: getRequirementOptions,
          },
        }),
      },
      getTagQueryFilterItem(
        'Component Tags',
        'componentTags',
        TagType.COMPONENT
      ),
      getTagQueryFilterItem(
        'Equipment Tags',
        'equipmentTags',
        TagType.EQUIPMENT
      ),
      getTagQueryFilterItem(
        'Environmental Tags',
        'environmentalTags',
        TagType.ENVIRONMENTAL
      ),
      getTagQueryFilterItem('General Tags', 'generalTags', TagType.GENERAL),
      {
        queryFilter: new MultiselectQueryFilter({
          id: 'users',
          controlLabel: 'Owner Users',
          unsetValueDisabled: true,
          disableExcludes: true,
          behaviorConfig: {
            fetchOptions: getUserOptions,
          },
        }),
      },
      {
        queryFilter: new MultiselectQueryFilter({
          id: 'teams',
          controlLabel: 'Owner Teams',
          unsetValueDisabled: true,
          disableExcludes: true,
          behaviorConfig: {
            fetchOptions: getTeamOptions,
          },
        }),
      },
    ];
  }, [platformsQueryFilterItem, platformSubtypesQueryFilterItem]);
}
