import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { setCollectionPageCollection } from '../../../redux/collection/actions';

function mapStateToProps() {
  return {};
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setCollectionPageCollection,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps);
