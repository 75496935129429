import { smartFormatDuration } from '@shield-ui/utils';

const config = {
  columnKey: 'totalTime',
  label: 'Duration',
  description: 'Total duration of the flight',
  sortKey: 'duration_seconds',
  defaultWidth: 160,
  getValue: ({ row }) => {
    if (row.totalTime) {
      // return smartFormatDuration(new Date(row.endTime).getTime() - new Date(row.startTime).getTime())
      return smartFormatDuration(parseInt(row.totalTime, 10) * 1000);
    }

    return null;
  },
  fragment: 'totalTime',
};

export default config;
