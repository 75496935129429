import React from 'react';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { Button, CircularProgress, ButtonProps } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  progressContainer: {
    margin: `0 5px`,
    width: 39,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...theme.shape,
  },
}));

interface ProgressSubmitButtonProps extends ButtonProps {
  isSubmitting?: boolean;
}

function ProgressSubmitButton(props: ProgressSubmitButtonProps) {
  const { className, isSubmitting, children, disabled, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.progressContainer}>
        {isSubmitting && <CircularProgress size={30} thickness={4.5} />}
      </div>
      <Button disabled={disabled || isSubmitting} {...rest}>
        {children}
      </Button>
    </div>
  );
}

export default ProgressSubmitButton;
