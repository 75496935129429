import React, { useState } from 'react';
import { HangarEventType } from '@shield-ui/hangar-service';
import ParentRow from './ParentRow';
import Event from './Event';
import { ContainerProps } from './containerEventManager';
import { Props as ChildRowDetailsProps } from './ChildRowDetails';

interface Props
  extends Pick<ContainerProps, 'robotLog' | 'currentUser' | 'deleteEvent'>,
    Pick<ChildRowDetailsProps, 'timeCodeOnClick'> {
  userEvents: HangarEventType[];
}

function EventGroup(props: Props) {
  const { userEvents } = props;
  const [isExpanded, setIsExpanded] = useState(true);
  const createdBy = userEvents[0].createdBy;

  return (
    <ParentRow
      isExpanded={isExpanded}
      onClick={() => setIsExpanded(!isExpanded)}
      label={createdBy ? createdBy.name : 'Unknown User'}
      avatarUser={createdBy}
      count={userEvents.length}
    >
      {userEvents.map((event) => {
        return <Event {...props} key={event.id} event={event} />;
      })}
    </ParentRow>
  );
}

export default EventGroup;
