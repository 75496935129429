import { createReducer } from 'redux-nano';
import _ from 'lodash';
import update from 'immutability-helper';
import { extractJson } from '@shield-ui/utils';
import {
  RESET_FLIGHT_PAGE,
  SET_ANALYSES,
  SET_ANALYZERS,
  UPDATE_ANALYSES_FILTERS,
  SET_PAGING,
} from './actions';
import { ANALYSIS_PROCESS_STATUS_CATEGORIES } from './constants';
import { getCachedItem, setCachedItem } from '@shield-ui/utils';

const storageKeys = {
  analysesFilters: 'flight.analysesFilters',
  paging: 'flight.paging-v1',
};

// defaults that should not be mutated on reset
const DEFAULT_PRESERVE_STATE = {
  analyzers: undefined,
  analysesFilters: {
    textLike: '',
    specificFramework: undefined,
    processStatusCategory: ANALYSIS_PROCESS_STATUS_CATEGORIES.ALL_WITH_RESULTS,
    ...(getCachedItem(storageKeys.analysesFilters) || {}),
  },
  paging: {
    description: undefined,
    startCursor: undefined,
    endCursor: undefined,
    total: 0,
    index: 0,
    robotLogId: undefined,
    backTo: undefined,
    queryVariables: {},
    searchVersion: undefined,
    ids: [],
    ...(getCachedItem(storageKeys.paging) || {}),
  },
};
// all defaults, including things that can be reset
const DEFAULT_RESET_STATE = {
  robotLogId: undefined,
  robotLog: undefined,
  analysisMap: undefined,
};

export default createReducer(
  {
    ...DEFAULT_PRESERVE_STATE,
    ...DEFAULT_RESET_STATE,
  },
  {
    [RESET_FLIGHT_PAGE]: (state, action) => {
      return update(state, {
        $merge: {
          ...DEFAULT_RESET_STATE,
          // robotLogId importantly
          ...action.payload,
        },
      });
    },
    [SET_ANALYSES]: (state, action) => {
      const { analyses } = action.payload;

      analyses.forEach((originalAnalysis) => {
        const analysis = _.cloneDeep(originalAnalysis);

        if (analysis.data) {
          analysis.parsedData = extractJson(analysis.data);

          const deleteKeys = ['test_passed', 'ran_successfully'];
          deleteKeys.forEach((key) => {
            if (analysis.parsedData[key]) {
              delete analysis.parsedData[key];
            }
          });

          if (Object.keys(analysis.parsedData).length === 0) {
            delete analysis.parsedData;
          }

          // analysis.data = JSON.stringify(analysis.parsedData)
          if (!analysis.summary && !analysis.testPassed) {
            analysis.summary =
              _.get(analysis.parsedData, 'reason') ||
              _.get(analysis.parsedData, 'error_message') ||
              _.get(analysis.parsedData, 'message');
          }
        }
      });

      const analysesMap = _.keyBy(analyses, 'name');
      const setterAction = state.analysisMap ? '$merge' : '$set';

      return update(state, {
        analysisMap: {
          [setterAction]: analysesMap,
        },
      });
    },
    [SET_ANALYZERS]: (state, action) => {
      const { analyzers } = action.payload;

      return update(state, {
        analyzers: { $set: analyzers },
      });
    },
    [UPDATE_ANALYSES_FILTERS]: (state, action) => {
      const { filters } = action.payload;

      const newState = update(state, {
        analysesFilters: { $merge: filters },
      });

      setCachedItem(storageKeys.analysesFilters, newState.analysesFilters);

      return newState;
    },
    [SET_PAGING]: (state, action) => {
      const { paging } = action.payload;

      const newState = update(state, {
        paging: {
          $set: {
            ...state.paging,
            ...paging,
          },
        },
      });

      setCachedItem(storageKeys.paging, newState.paging);

      return newState;
    },
  }
);
