import React, { useState, useEffect, useCallback } from 'react';
import { fetchRobotLogEvaluation } from '../../services/evaluations';
import PageContent from './PageContent';
import BadgeContent from './BadgeContent';
import { showError } from '../../lib/messages';
import { EvaluationState } from '@hmd/sdk/api/tests/evaluations/v1';
type Props = {
  robotLogId: string;
  type: 'page' | 'table';
};

export const getEvaluationStateString = (evaluationStateNumber: number) => {
  return Object.keys(EvaluationState).find(
    (key) => EvaluationState[key] === evaluationStateNumber
  );
};

export default function TestEvaluationBadge(props: Props) {
  const { robotLogId, type } = props;
  const [evaluation, setEvaluation] = useState(null);
  const [isEvaluationOpen, setIsEvaluationOpen] = useState(false);
  const [isManualAssertion, setIsManualAssertion] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onAssertionChange = () => {
    setIsLoading(true);
    fetchRobotLogEvaluation(robotLogId)
      .then((ev) => {
        setEvaluation(ev);
        setIsLoading(false);
      })
      .catch(showError);
  };

  useEffect(onAssertionChange, [robotLogId]);

  const openEvaluation = useCallback(() => {
    setIsEvaluationOpen(true);
  }, [setIsEvaluationOpen]);

  const closeEvaluation = useCallback(
    (e) => {
      e.stopPropagation();
      setIsEvaluationOpen(false);
    },
    [setIsEvaluationOpen]
  );

  const passDownProps = {
    openEvaluation,
    isLoading,
    evaluation,
    isManualAssertion,
    isEvaluationOpen,
    onAssertionChange,
    closeEvaluation,
    setIsManualAssertion,
  };

  if (type === 'page') {
    return <PageContent {...passDownProps} />;
  } else {
    return <BadgeContent {...passDownProps} />;
  }
}
