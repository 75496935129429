import React from 'react';
import {
  EsQuery,
  QueryFilterClasses,
  QueryFilterClassValues,
  QueryFilterTypes,
} from '../filterTypes';
import AdHocEs from './AdHocEs/AdHocEs';
import AttributeValueCollector from './AttributeValueCollector/AttributeValueCollector';
import Datetime from './Datetime/Datetime';
import Group from './Group/Group';
import Keywords from './Keywords/Keywords';
import Multiselect from './Multiselect/Multiselect';
import Nested from './Nested/Nested';
import ParentChildMultiselect from './ParentChildMultiselect/ParentChildMultiselect';
import TypeToFindMultiselect from './TypeToFindMultiselect/TypeToFindMultiselect';
import ValueRange from './ValueRange/ValueRange';

export type QueryFilterReactComponentMap = Partial<
  Record<QueryFilterTypes, React.ElementType>
>;

export const defaultQueryFilterReactComponentMap: QueryFilterReactComponentMap =
  {
    [QueryFilterTypes.adHocEs]: AdHocEs,
    [QueryFilterTypes.attributeValueCollector]: AttributeValueCollector,
    [QueryFilterTypes.datetime]: Datetime,
    [QueryFilterTypes.keywords]: Keywords,
    [QueryFilterTypes.group]: Group,
    [QueryFilterTypes.multiselect]: Multiselect,
    [QueryFilterTypes.nested]: Nested,
    [QueryFilterTypes.parentChildMultiselect]: ParentChildMultiselect,
    [QueryFilterTypes.typeToFindMultiselect]: TypeToFindMultiselect,
    [QueryFilterTypes.valueRange]: ValueRange,
  };

export function defaultGetQueryFilterComponent(
  queryFilter: QueryFilterClasses
) {
  const type = queryFilter.getType();
  return defaultQueryFilterReactComponentMap[type];
}

export interface PassthruLayoutProps {
  onContextMenu?: (evt) => void;
}

export interface LayoutProps extends PassthruLayoutProps {
  onClear: () => void;
  label: string;
  valuePreview?: string;
  ControlElement?: React.ReactNode;
  // moving some of the default wrapper logic into the Layout so we can be optimized in certain situations
  // e.g. controlBar layout won't call ensureControlData until the popover comes down
  onForceUpdate: () => void;
  queryFilter: QueryFilterClasses;
  value?: QueryFilterClassValues;
}

export interface ControlProps<Value> {
  getQueryFilterControlComponent?: (
    queryFilter: QueryFilterClasses
  ) => React.ElementType;
  onChange: (updates: Value) => void;
  onClear: () => void;
  value: Value;
  hasValue: boolean;
  fullQuery?: EsQuery;
  partialQuery?: EsQuery;
}
