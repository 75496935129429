import { TypeToFindMultiselectQueryFilter } from '@shield-ui/query-filters';
import { queryLabels } from '../../../services/labels';

function processResponse(res) {
  return res.data.labels.edges.map((e) => {
    return {
      value: e.node.id,
      label: e.node.name,
    };
  });
}

function getOptions({ values }) {
  return queryLabels({
    ids: values,
  }).then((res) => {
    return processResponse(res);
  });
}

function searchSuggestedOptions() {
  return queryLabels({
    sort: 'applied_to_robot_logs_count_desc',
    first: 10,
  }).then((res) => {
    return processResponse(res);
  });
}

function searchOptions({ input }) {
  return queryLabels({
    textLike: input,
    first: 10,
  }).then((res) => {
    return processResponse(res);
  });
}

export default new TypeToFindMultiselectQueryFilter({
  id: 'labels',
  controlLabel: 'Labels',
  esField: 'labels.id',
  isMulti: true,
  getOptions: getOptions,
  searchSuggestedOptions: searchSuggestedOptions,
  searchOptions: searchOptions,
});
