import { Modal, Form } from 'antd';
import plotTypeManager from './plotTypeManager';

interface Props {
  plotTypeId: string;
  updatePlotConfig: object;
  isUpdate: boolean;
  onSave: (values: object) => void;
  onCancel: () => void;
}

export const CreateOrUpdateModal = (props: Props) => {
  const { plotTypeId, updatePlotConfig, isUpdate, onSave, onCancel } = props;
  const [form] = Form.useForm();
  const initialValues = isUpdate
    ? plotTypeManager.renderUpdateValues(plotTypeId, updatePlotConfig)
    : plotTypeManager.renderInitialValues(plotTypeId);
  const title = isUpdate
    ? ` Update ${plotTypeManager.getLabel(plotTypeId)} Plot`
    : `Create ${plotTypeManager.getLabel(plotTypeId)} Plot`;
  const buttonText = isUpdate ? 'Update' : 'Create';

  return (
    <div>
      <Modal
        title={title}
        open={!!plotTypeId}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onSave(values);
            })
            .catch((info) => {
              console.error('Validate Failed', info);
            });
        }}
        okText={buttonText}
        onCancel={onCancel}
        cancelText="Cancel"
      >
        <Form
          form={form}
          layout="vertical"
          name="plotConfiguration"
          initialValues={initialValues}
        >
          {plotTypeManager.renderForm(plotTypeId)}
        </Form>
      </Modal>
    </div>
  );
};
