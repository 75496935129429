/**************************************
 * auto generated by ./scripts/mappingToFieldsObject.js
 * Do not edit
 **************************************/
export const robotLogDocumentFields = {
  'analyses.ATE_max_allowable_ATE': {
    field: 'analyses.ATE_max_allowable_ATE',
    type: 'float',
  },
  'analyses.ATE_max_allowable_quantile_ATE': {
    field: 'analyses.ATE_max_allowable_quantile_ATE',
    type: 'float',
  },
  'analyses.Arming failure per attempt (%)': {
    field: 'analyses.Arming failure per attempt (%)',
    type: 'float',
  },
  'analyses.Armining failure per attempt (%)': {
    field: 'analyses.Armining failure per attempt (%)',
    type: 'float',
  },
  'analyses.RPE_max_allowable_quantile_RPE': {
    field: 'analyses.RPE_max_allowable_quantile_RPE',
    type: 'float',
  },
  'analyses.Repeated arming failure per trial (%)': {
    field: 'analyses.Repeated arming failure per trial (%)',
    type: 'float',
  },
  'analyses.VIL: Arming Attempt Success': {
    field: 'analyses.VIL: Arming Attempt Success',
    type: 'long',
  },
  'analyses.accel_std_x': {
    field: 'analyses.accel_std_x',
    type: 'float',
  },
  'analyses.accel_std_y': {
    field: 'analyses.accel_std_y',
    type: 'float',
  },
  'analyses.accel_std_z': {
    field: 'analyses.accel_std_z',
    type: 'float',
  },
  'analyses.analyze_bag_limits_max': {
    field: 'analyses.analyze_bag_limits_max',
    type: 'float',
  },
  'analyses.analyze_bag_limits_min': {
    field: 'analyses.analyze_bag_limits_min',
    type: 'float',
  },
  'analyses.angular_rate_std_x': {
    field: 'analyses.angular_rate_std_x',
    type: 'float',
  },
  'analyses.angular_rate_std_y': {
    field: 'analyses.angular_rate_std_y',
    type: 'float',
  },
  'analyses.angular_rate_std_z': {
    field: 'analyses.angular_rate_std_z',
    type: 'float',
  },
  'analyses.arming_duration_max_arming_duration': {
    field: 'analyses.arming_duration_max_arming_duration',
    type: 'float',
  },
  'analyses.assert_max_ATE_value': {
    field: 'analyses.assert_max_ATE_value',
    type: 'float',
  },
  'analyses.assert_max_launch_latency_value': {
    field: 'analyses.assert_max_launch_latency_value',
    type: 'float',
  },
  'analyses.attitude_rate_max_pitch_rate': {
    field: 'analyses.attitude_rate_max_pitch_rate',
    type: 'float',
  },
  'analyses.attitude_rate_max_roll_rate': {
    field: 'analyses.attitude_rate_max_roll_rate',
    type: 'float',
  },
  'analyses.attitude_rate_max_yaw_rate': {
    field: 'analyses.attitude_rate_max_yaw_rate',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_mode_threshold_am_mavros_MAVConnSerial': {
    field: 'analyses.avg_cpu_thread_use_mode_threshold_am_mavros_MAVConnSerial',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_mode_threshold_am_mavros_MAVConnSerial0': {
    field:
      'analyses.avg_cpu_thread_use_mode_threshold_am_mavros_MAVConnSerial0',
    type: 'long',
  },
  'analyses.avg_cpu_thread_use_mode_threshold_am_msckf_msckf_node': {
    field: 'analyses.avg_cpu_thread_use_mode_threshold_am_msckf_msckf_node',
    type: 'long',
  },
  'analyses.avg_cpu_thread_use_mode_threshold_am_nodelet_manager_DFilt': {
    field:
      'analyses.avg_cpu_thread_use_mode_threshold_am_nodelet_manager_DFilt',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_mode_threshold_am_nodelet_manager_EOAquire': {
    field:
      'analyses.avg_cpu_thread_use_mode_threshold_am_nodelet_manager_EOAquire',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_mode_threshold_am_nodelet_manager_EOConv': {
    field:
      'analyses.avg_cpu_thread_use_mode_threshold_am_nodelet_manager_EOConv',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_mode_threshold_am_nodelet_manager_FeatureTracker':
    {
      field:
        'analyses.avg_cpu_thread_use_mode_threshold_am_nodelet_manager_FeatureTracker',
      type: 'float',
    },
  'analyses.avg_cpu_thread_use_mode_threshold_am_nodelet_manager_PollManager': {
    field:
      'analyses.avg_cpu_thread_use_mode_threshold_am_nodelet_manager_PollManager',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_mode_threshold_am_nodelet_manager_depth_loop_clo':
    {
      field:
        'analyses.avg_cpu_thread_use_mode_threshold_am_nodelet_manager_depth_loop_clo',
      type: 'long',
    },
  'analyses.avg_cpu_thread_use_mode_threshold_am_nodelet_manager_dlc_thread': {
    field:
      'analyses.avg_cpu_thread_use_mode_threshold_am_nodelet_manager_dlc_thread',
    type: 'long',
  },
  'analyses.avg_cpu_thread_use_mode_threshold_am_nodelet_manager_downward_surfac':
    {
      field:
        'analyses.avg_cpu_thread_use_mode_threshold_am_nodelet_manager_downward_surfac',
      type: 'float',
    },
  'analyses.avg_cpu_thread_use_mode_threshold_am_nodelet_manager_global_map_run':
    {
      field:
        'analyses.avg_cpu_thread_use_mode_threshold_am_nodelet_manager_global_map_run',
      type: 'float',
    },
  'analyses.avg_cpu_thread_use_mode_threshold_am_nodelet_manager_gscam_stream':
    {
      field:
        'analyses.avg_cpu_thread_use_mode_threshold_am_nodelet_manager_gscam_stream',
      type: 'float',
    },
  'analyses.avg_cpu_thread_use_mode_threshold_am_nodelet_manager_librs_uvcstream':
    {
      field:
        'analyses.avg_cpu_thread_use_mode_threshold_am_nodelet_manager_librs_uvcstream',
      type: 'float',
    },
  'analyses.avg_cpu_thread_use_mode_threshold_am_nodelet_manager_local_map_run':
    {
      field:
        'analyses.avg_cpu_thread_use_mode_threshold_am_nodelet_manager_local_map_run',
      type: 'float',
    },
  'analyses.avg_cpu_thread_use_mode_threshold_am_nodelet_manager_msckf_node': {
    field:
      'analyses.avg_cpu_thread_use_mode_threshold_am_nodelet_manager_msckf_node',
    type: 'long',
  },
  'analyses.avg_cpu_thread_use_mode_threshold_am_nodelet_manager_record': {
    field:
      'analyses.avg_cpu_thread_use_mode_threshold_am_nodelet_manager_record',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_mode_threshold_am_nodelet_manager_smart_environme':
    {
      field:
        'analyses.avg_cpu_thread_use_mode_threshold_am_nodelet_manager_smart_environme',
      type: 'float',
    },
  'analyses.avg_cpu_thread_use_mode_threshold_am_nodelet_manager_vision_pipeline':
    {
      field:
        'analyses.avg_cpu_thread_use_mode_threshold_am_nodelet_manager_vision_pipeline',
      type: 'float',
    },
  'analyses.avg_cpu_thread_use_mode_threshold_am_nodelet_manager_visual_loop_clo':
    {
      field:
        'analyses.avg_cpu_thread_use_mode_threshold_am_nodelet_manager_visual_loop_clo',
      type: 'float',
    },
  'analyses.avg_cpu_thread_use_mode_threshold_am_nodelet_manager_vlc_thread': {
    field:
      'analyses.avg_cpu_thread_use_mode_threshold_am_nodelet_manager_vlc_thread',
    type: 'long',
  },
  'analyses.avg_cpu_thread_use_mode_threshold_exp_mavros_MAVConnSerial': {
    field:
      'analyses.avg_cpu_thread_use_mode_threshold_exp_mavros_MAVConnSerial',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_mode_threshold_exp_mavros_MAVConnSerial0': {
    field:
      'analyses.avg_cpu_thread_use_mode_threshold_exp_mavros_MAVConnSerial0',
    type: 'long',
  },
  'analyses.avg_cpu_thread_use_mode_threshold_exp_msckf_msckf_node': {
    field: 'analyses.avg_cpu_thread_use_mode_threshold_exp_msckf_msckf_node',
    type: 'long',
  },
  'analyses.avg_cpu_thread_use_mode_threshold_exp_nodelet_manager_DFilt': {
    field:
      'analyses.avg_cpu_thread_use_mode_threshold_exp_nodelet_manager_DFilt',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_mode_threshold_exp_nodelet_manager_EOAquire': {
    field:
      'analyses.avg_cpu_thread_use_mode_threshold_exp_nodelet_manager_EOAquire',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_mode_threshold_exp_nodelet_manager_EOConv': {
    field:
      'analyses.avg_cpu_thread_use_mode_threshold_exp_nodelet_manager_EOConv',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_mode_threshold_exp_nodelet_manager_FeatureTracker':
    {
      field:
        'analyses.avg_cpu_thread_use_mode_threshold_exp_nodelet_manager_FeatureTracker',
      type: 'float',
    },
  'analyses.avg_cpu_thread_use_mode_threshold_exp_nodelet_manager_PollManager':
    {
      field:
        'analyses.avg_cpu_thread_use_mode_threshold_exp_nodelet_manager_PollManager',
      type: 'float',
    },
  'analyses.avg_cpu_thread_use_mode_threshold_exp_nodelet_manager_depth_loop_clo':
    {
      field:
        'analyses.avg_cpu_thread_use_mode_threshold_exp_nodelet_manager_depth_loop_clo',
      type: 'long',
    },
  'analyses.avg_cpu_thread_use_mode_threshold_exp_nodelet_manager_dlc_thread': {
    field:
      'analyses.avg_cpu_thread_use_mode_threshold_exp_nodelet_manager_dlc_thread',
    type: 'long',
  },
  'analyses.avg_cpu_thread_use_mode_threshold_exp_nodelet_manager_downward_surfac':
    {
      field:
        'analyses.avg_cpu_thread_use_mode_threshold_exp_nodelet_manager_downward_surfac',
      type: 'float',
    },
  'analyses.avg_cpu_thread_use_mode_threshold_exp_nodelet_manager_global_map_run':
    {
      field:
        'analyses.avg_cpu_thread_use_mode_threshold_exp_nodelet_manager_global_map_run',
      type: 'float',
    },
  'analyses.avg_cpu_thread_use_mode_threshold_exp_nodelet_manager_gscam_stream':
    {
      field:
        'analyses.avg_cpu_thread_use_mode_threshold_exp_nodelet_manager_gscam_stream',
      type: 'float',
    },
  'analyses.avg_cpu_thread_use_mode_threshold_exp_nodelet_manager_librs_uvcstream':
    {
      field:
        'analyses.avg_cpu_thread_use_mode_threshold_exp_nodelet_manager_librs_uvcstream',
      type: 'float',
    },
  'analyses.avg_cpu_thread_use_mode_threshold_exp_nodelet_manager_local_map_run':
    {
      field:
        'analyses.avg_cpu_thread_use_mode_threshold_exp_nodelet_manager_local_map_run',
      type: 'float',
    },
  'analyses.avg_cpu_thread_use_mode_threshold_exp_nodelet_manager_msckf_node': {
    field:
      'analyses.avg_cpu_thread_use_mode_threshold_exp_nodelet_manager_msckf_node',
    type: 'long',
  },
  'analyses.avg_cpu_thread_use_mode_threshold_exp_nodelet_manager_record': {
    field:
      'analyses.avg_cpu_thread_use_mode_threshold_exp_nodelet_manager_record',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_mode_threshold_exp_nodelet_manager_smart_environme':
    {
      field:
        'analyses.avg_cpu_thread_use_mode_threshold_exp_nodelet_manager_smart_environme',
      type: 'float',
    },
  'analyses.avg_cpu_thread_use_mode_threshold_exp_nodelet_manager_vision_pipeline':
    {
      field:
        'analyses.avg_cpu_thread_use_mode_threshold_exp_nodelet_manager_vision_pipeline',
      type: 'float',
    },
  'analyses.avg_cpu_thread_use_mode_threshold_exp_nodelet_manager_visual_loop_clo':
    {
      field:
        'analyses.avg_cpu_thread_use_mode_threshold_exp_nodelet_manager_visual_loop_clo',
      type: 'float',
    },
  'analyses.avg_cpu_thread_use_mode_threshold_exp_nodelet_manager_vlc_thread': {
    field:
      'analyses.avg_cpu_thread_use_mode_threshold_exp_nodelet_manager_vlc_thread',
    type: 'long',
  },
  'analyses.avg_cpu_thread_use_mode_threshold_wp_mavros_MAVConnSerial': {
    field: 'analyses.avg_cpu_thread_use_mode_threshold_wp_mavros_MAVConnSerial',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_mode_threshold_wp_mavros_MAVConnSerial0': {
    field:
      'analyses.avg_cpu_thread_use_mode_threshold_wp_mavros_MAVConnSerial0',
    type: 'long',
  },
  'analyses.avg_cpu_thread_use_mode_threshold_wp_msckf_msckf_node': {
    field: 'analyses.avg_cpu_thread_use_mode_threshold_wp_msckf_msckf_node',
    type: 'long',
  },
  'analyses.avg_cpu_thread_use_mode_threshold_wp_nodelet_manager_DFilt': {
    field:
      'analyses.avg_cpu_thread_use_mode_threshold_wp_nodelet_manager_DFilt',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_mode_threshold_wp_nodelet_manager_EOAquire': {
    field:
      'analyses.avg_cpu_thread_use_mode_threshold_wp_nodelet_manager_EOAquire',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_mode_threshold_wp_nodelet_manager_EOConv': {
    field:
      'analyses.avg_cpu_thread_use_mode_threshold_wp_nodelet_manager_EOConv',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_mode_threshold_wp_nodelet_manager_FeatureTracker':
    {
      field:
        'analyses.avg_cpu_thread_use_mode_threshold_wp_nodelet_manager_FeatureTracker',
      type: 'float',
    },
  'analyses.avg_cpu_thread_use_mode_threshold_wp_nodelet_manager_PollManager': {
    field:
      'analyses.avg_cpu_thread_use_mode_threshold_wp_nodelet_manager_PollManager',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_mode_threshold_wp_nodelet_manager_dlc_thread': {
    field:
      'analyses.avg_cpu_thread_use_mode_threshold_wp_nodelet_manager_dlc_thread',
    type: 'long',
  },
  'analyses.avg_cpu_thread_use_mode_threshold_wp_nodelet_manager_downward_surfac':
    {
      field:
        'analyses.avg_cpu_thread_use_mode_threshold_wp_nodelet_manager_downward_surfac',
      type: 'float',
    },
  'analyses.avg_cpu_thread_use_mode_threshold_wp_nodelet_manager_global_map_run':
    {
      field:
        'analyses.avg_cpu_thread_use_mode_threshold_wp_nodelet_manager_global_map_run',
      type: 'float',
    },
  'analyses.avg_cpu_thread_use_mode_threshold_wp_nodelet_manager_gscam_stream':
    {
      field:
        'analyses.avg_cpu_thread_use_mode_threshold_wp_nodelet_manager_gscam_stream',
      type: 'float',
    },
  'analyses.avg_cpu_thread_use_mode_threshold_wp_nodelet_manager_librs_uvcstream':
    {
      field:
        'analyses.avg_cpu_thread_use_mode_threshold_wp_nodelet_manager_librs_uvcstream',
      type: 'float',
    },
  'analyses.avg_cpu_thread_use_mode_threshold_wp_nodelet_manager_local_map_run':
    {
      field:
        'analyses.avg_cpu_thread_use_mode_threshold_wp_nodelet_manager_local_map_run',
      type: 'float',
    },
  'analyses.avg_cpu_thread_use_mode_threshold_wp_nodelet_manager_record': {
    field:
      'analyses.avg_cpu_thread_use_mode_threshold_wp_nodelet_manager_record',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_mode_threshold_wp_nodelet_manager_smart_environme':
    {
      field:
        'analyses.avg_cpu_thread_use_mode_threshold_wp_nodelet_manager_smart_environme',
      type: 'float',
    },
  'analyses.avg_cpu_thread_use_mode_threshold_wp_nodelet_manager_vision_pipeline':
    {
      field:
        'analyses.avg_cpu_thread_use_mode_threshold_wp_nodelet_manager_vision_pipeline',
      type: 'float',
    },
  'analyses.avg_cpu_thread_use_mode_threshold_wp_nodelet_manager_vlc_thread': {
    field:
      'analyses.avg_cpu_thread_use_mode_threshold_wp_nodelet_manager_vlc_thread',
    type: 'long',
  },
  'analyses.avg_cpu_thread_use_threshold_aviate_PollManager': {
    field: 'analyses.avg_cpu_thread_use_threshold_aviate_PollManager',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_threshold_depth_infiller_forward': {
    field: 'analyses.avg_cpu_thread_use_threshold_depth_infiller_forward',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_threshold_depth_infiller_forward_down': {
    field: 'analyses.avg_cpu_thread_use_threshold_depth_infiller_forward_down',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_threshold_depth_infiller_forward_up': {
    field: 'analyses.avg_cpu_thread_use_threshold_depth_infiller_forward_up',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_threshold_depth_infiller_left': {
    field: 'analyses.avg_cpu_thread_use_threshold_depth_infiller_left',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_threshold_depth_infiller_right': {
    field: 'analyses.avg_cpu_thread_use_threshold_depth_infiller_right',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_threshold_local_planner_PollManager': {
    field: 'analyses.avg_cpu_thread_use_threshold_local_planner_PollManager',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_threshold_local_planner_trajectory_mana': {
    field:
      'analyses.avg_cpu_thread_use_threshold_local_planner_trajectory_mana',
    type: 'long',
  },
  'analyses.avg_cpu_thread_use_threshold_mapping_GlobalMap': {
    field: 'analyses.avg_cpu_thread_use_threshold_mapping_GlobalMap',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_threshold_mapping_LocalMap': {
    field: 'analyses.avg_cpu_thread_use_threshold_mapping_LocalMap',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_threshold_mavros_MAVConnSerial0': {
    field: 'analyses.avg_cpu_thread_use_threshold_mavros_MAVConnSerial0',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_threshold_mavros_PollManager': {
    field: 'analyses.avg_cpu_thread_use_threshold_mavros_PollManager',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_threshold_msckf_PollManager': {
    field: 'analyses.avg_cpu_thread_use_threshold_msckf_PollManager',
    type: 'long',
  },
  'analyses.avg_cpu_thread_use_threshold_nodelet_manager_2_EOConv0': {
    field: 'analyses.avg_cpu_thread_use_threshold_nodelet_manager_2_EOConv0',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_threshold_nodelet_manager_2_EOConv1': {
    field: 'analyses.avg_cpu_thread_use_threshold_nodelet_manager_2_EOConv1',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_threshold_nodelet_manager_2_EOConv2': {
    field: 'analyses.avg_cpu_thread_use_threshold_nodelet_manager_2_EOConv2',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_threshold_nodelet_manager_2_EOConv3': {
    field: 'analyses.avg_cpu_thread_use_threshold_nodelet_manager_2_EOConv3',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_threshold_nodelet_manager_2_PollManager': {
    field:
      'analyses.avg_cpu_thread_use_threshold_nodelet_manager_2_PollManager',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_threshold_nodelet_manager_DLC_NODE': {
    field: 'analyses.avg_cpu_thread_use_threshold_nodelet_manager_DLC_NODE',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_threshold_nodelet_manager_EOConv0': {
    field: 'analyses.avg_cpu_thread_use_threshold_nodelet_manager_EOConv0',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_threshold_nodelet_manager_EOConv1': {
    field: 'analyses.avg_cpu_thread_use_threshold_nodelet_manager_EOConv1',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_threshold_nodelet_manager_EOConv2': {
    field: 'analyses.avg_cpu_thread_use_threshold_nodelet_manager_EOConv2',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_threshold_nodelet_manager_EOConv3': {
    field: 'analyses.avg_cpu_thread_use_threshold_nodelet_manager_EOConv3',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_threshold_nodelet_manager_LC_NODE': {
    field: 'analyses.avg_cpu_thread_use_threshold_nodelet_manager_LC_NODE',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_threshold_nodelet_manager_PollManager': {
    field: 'analyses.avg_cpu_thread_use_threshold_nodelet_manager_PollManager',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_threshold_nodelet_manager_VLC_NODE': {
    field: 'analyses.avg_cpu_thread_use_threshold_nodelet_manager_VLC_NODE',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_threshold_nodelet_manager_depth_loop_clo': {
    field:
      'analyses.avg_cpu_thread_use_threshold_nodelet_manager_depth_loop_clo',
    type: 'long',
  },
  'analyses.avg_cpu_thread_use_threshold_nodelet_manager_dlc_thread': {
    field: 'analyses.avg_cpu_thread_use_threshold_nodelet_manager_dlc_thread',
    type: 'long',
  },
  'analyses.avg_cpu_thread_use_threshold_nodelet_manager_visual_loop_clo': {
    field:
      'analyses.avg_cpu_thread_use_threshold_nodelet_manager_visual_loop_clo',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_threshold_nodelet_manager_vlc_thread': {
    field: 'analyses.avg_cpu_thread_use_threshold_nodelet_manager_vlc_thread',
    type: 'long',
  },
  'analyses.avg_cpu_thread_use_threshold_nvargus-daemon_CaptureSchedule': {
    field:
      'analyses.avg_cpu_thread_use_threshold_nvargus-daemon_CaptureSchedule',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_threshold_rosbag_PollManager': {
    field: 'analyses.avg_cpu_thread_use_threshold_rosbag_PollManager',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_threshold_vision_pipeline_manager_EOConv0': {
    field:
      'analyses.avg_cpu_thread_use_threshold_vision_pipeline_manager_EOConv0',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_threshold_vision_pipeline_manager_EOConv1': {
    field:
      'analyses.avg_cpu_thread_use_threshold_vision_pipeline_manager_EOConv1',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_threshold_vision_pipeline_manager_EOConv2': {
    field:
      'analyses.avg_cpu_thread_use_threshold_vision_pipeline_manager_EOConv2',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_threshold_vision_pipeline_manager_EOConv3': {
    field:
      'analyses.avg_cpu_thread_use_threshold_vision_pipeline_manager_EOConv3',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_threshold_vision_pipeline_manager_EOConvbottom':
    {
      field:
        'analyses.avg_cpu_thread_use_threshold_vision_pipeline_manager_EOConvbottom',
      type: 'float',
    },
  'analyses.avg_cpu_thread_use_threshold_vision_pipeline_manager_EOConvforward':
    {
      field:
        'analyses.avg_cpu_thread_use_threshold_vision_pipeline_manager_EOConvforward',
      type: 'float',
    },
  'analyses.avg_cpu_thread_use_threshold_vision_pipeline_manager_EOConvleft': {
    field:
      'analyses.avg_cpu_thread_use_threshold_vision_pipeline_manager_EOConvleft',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_threshold_vision_pipeline_manager_EOConvright': {
    field:
      'analyses.avg_cpu_thread_use_threshold_vision_pipeline_manager_EOConvright',
    type: 'float',
  },
  'analyses.avg_cpu_thread_use_threshold_vision_pipeline_manager_PollManager': {
    field:
      'analyses.avg_cpu_thread_use_threshold_vision_pipeline_manager_PollManager',
    type: 'float',
  },
  'analyses.avg_power_usage_threshold_total': {
    field: 'analyses.avg_power_usage_threshold_total',
    type: 'float',
  },
  'analyses.battery_avg_power_usage_threshold_total': {
    field: 'analyses.battery_avg_power_usage_threshold_total',
    type: 'float',
  },
  'analyses.building_entry_max_building_entry_time': {
    field: 'analyses.building_entry_max_building_entry_time',
    type: 'long',
  },
  'analyses.building_entry_max_failed_building_entries': {
    field: 'analyses.building_entry_max_failed_building_entries',
    type: 'long',
  },
  'analyses.building_entry_max_heading_efficiency_threshold': {
    field: 'analyses.building_entry_max_heading_efficiency_threshold',
    type: 'long',
  },
  'analyses.building_entry_max_path_efficiency_threshold': {
    field: 'analyses.building_entry_max_path_efficiency_threshold',
    type: 'long',
  },
  'analyses.comms_latency_95th_latency': {
    field: 'analyses.comms_latency_95th_latency',
    type: 'float',
  },
  'analyses.comms_latency_controlled_95th_latency': {
    field: 'analyses.comms_latency_controlled_95th_latency',
    type: 'float',
  },
  'analyses.comms_latency_controlled_count_ping_drops': {
    field: 'analyses.comms_latency_controlled_count_ping_drops',
    type: 'long',
  },
  'analyses.comms_latency_controlled_max_latency': {
    field: 'analyses.comms_latency_controlled_max_latency',
    type: 'float',
  },
  'analyses.comms_latency_controlled_mean_latency': {
    field: 'analyses.comms_latency_controlled_mean_latency',
    type: 'float',
  },
  'analyses.comms_latency_controlled_min_latency': {
    field: 'analyses.comms_latency_controlled_min_latency',
    type: 'float',
  },
  'analyses.comms_latency_controlled_std_latency': {
    field: 'analyses.comms_latency_controlled_std_latency',
    type: 'float',
  },
  'analyses.comms_latency_count_ping_drops': {
    field: 'analyses.comms_latency_count_ping_drops',
    type: 'long',
  },
  'analyses.comms_latency_max_latency': {
    field: 'analyses.comms_latency_max_latency',
    type: 'float',
  },
  'analyses.comms_latency_mean_latency': {
    field: 'analyses.comms_latency_mean_latency',
    type: 'float',
  },
  'analyses.comms_latency_min_latency': {
    field: 'analyses.comms_latency_min_latency',
    type: 'float',
  },
  'analyses.comms_latency_std_latency': {
    field: 'analyses.comms_latency_std_latency',
    type: 'float',
  },
  'analyses.control_error_max_mean_pos_err_xy': {
    field: 'analyses.control_error_max_mean_pos_err_xy',
    type: 'float',
  },
  'analyses.control_error_max_mean_pos_err_xyz': {
    field: 'analyses.control_error_max_mean_pos_err_xyz',
    type: 'float',
  },
  'analyses.control_error_max_mean_vel_err_xy': {
    field: 'analyses.control_error_max_mean_vel_err_xy',
    type: 'float',
  },
  'analyses.control_error_max_mean_vel_err_xyz': {
    field: 'analyses.control_error_max_mean_vel_err_xyz',
    type: 'float',
  },
  'analyses.control_error_max_pos_err_xy': {
    field: 'analyses.control_error_max_pos_err_xy',
    type: 'float',
  },
  'analyses.control_error_max_pos_err_xyz': {
    field: 'analyses.control_error_max_pos_err_xyz',
    type: 'float',
  },
  'analyses.control_error_max_vel_err_xy': {
    field: 'analyses.control_error_max_vel_err_xy',
    type: 'float',
  },
  'analyses.control_error_max_vel_err_xyz': {
    field: 'analyses.control_error_max_vel_err_xyz',
    type: 'float',
  },
  'analyses.core_0_usage': {
    field: 'analyses.core_0_usage',
    type: 'float',
  },
  'analyses.core_10_usage': {
    field: 'analyses.core_10_usage',
    type: 'long',
  },
  'analyses.core_11_usage': {
    field: 'analyses.core_11_usage',
    type: 'long',
  },
  'analyses.core_12_usage': {
    field: 'analyses.core_12_usage',
    type: 'float',
  },
  'analyses.core_13_usage': {
    field: 'analyses.core_13_usage',
    type: 'float',
  },
  'analyses.core_14_usage': {
    field: 'analyses.core_14_usage',
    type: 'float',
  },
  'analyses.core_15_usage': {
    field: 'analyses.core_15_usage',
    type: 'long',
  },
  'analyses.core_16_usage': {
    field: 'analyses.core_16_usage',
    type: 'float',
  },
  'analyses.core_17_usage': {
    field: 'analyses.core_17_usage',
    type: 'float',
  },
  'analyses.core_18_usage': {
    field: 'analyses.core_18_usage',
    type: 'float',
  },
  'analyses.core_19_usage': {
    field: 'analyses.core_19_usage',
    type: 'float',
  },
  'analyses.core_1_usage': {
    field: 'analyses.core_1_usage',
    type: 'float',
  },
  'analyses.core_20_usage': {
    field: 'analyses.core_20_usage',
    type: 'float',
  },
  'analyses.core_21_usage': {
    field: 'analyses.core_21_usage',
    type: 'float',
  },
  'analyses.core_22_usage': {
    field: 'analyses.core_22_usage',
    type: 'float',
  },
  'analyses.core_23_usage': {
    field: 'analyses.core_23_usage',
    type: 'float',
  },
  'analyses.core_24_usage': {
    field: 'analyses.core_24_usage',
    type: 'float',
  },
  'analyses.core_25_usage': {
    field: 'analyses.core_25_usage',
    type: 'float',
  },
  'analyses.core_26_usage': {
    field: 'analyses.core_26_usage',
    type: 'float',
  },
  'analyses.core_27_usage': {
    field: 'analyses.core_27_usage',
    type: 'float',
  },
  'analyses.core_28_usage': {
    field: 'analyses.core_28_usage',
    type: 'float',
  },
  'analyses.core_29_usage': {
    field: 'analyses.core_29_usage',
    type: 'long',
  },
  'analyses.core_2_usage': {
    field: 'analyses.core_2_usage',
    type: 'float',
  },
  'analyses.core_30_usage': {
    field: 'analyses.core_30_usage',
    type: 'long',
  },
  'analyses.core_31_usage': {
    field: 'analyses.core_31_usage',
    type: 'float',
  },
  'analyses.core_32_usage': {
    field: 'analyses.core_32_usage',
    type: 'float',
  },
  'analyses.core_33_usage': {
    field: 'analyses.core_33_usage',
    type: 'float',
  },
  'analyses.core_34_usage': {
    field: 'analyses.core_34_usage',
    type: 'float',
  },
  'analyses.core_35_usage': {
    field: 'analyses.core_35_usage',
    type: 'float',
  },
  'analyses.core_36_usage': {
    field: 'analyses.core_36_usage',
    type: 'float',
  },
  'analyses.core_37_usage': {
    field: 'analyses.core_37_usage',
    type: 'float',
  },
  'analyses.core_38_usage': {
    field: 'analyses.core_38_usage',
    type: 'long',
  },
  'analyses.core_39_usage': {
    field: 'analyses.core_39_usage',
    type: 'float',
  },
  'analyses.core_3_usage': {
    field: 'analyses.core_3_usage',
    type: 'long',
  },
  'analyses.core_40_usage': {
    field: 'analyses.core_40_usage',
    type: 'long',
  },
  'analyses.core_41_usage': {
    field: 'analyses.core_41_usage',
    type: 'float',
  },
  'analyses.core_42_usage': {
    field: 'analyses.core_42_usage',
    type: 'float',
  },
  'analyses.core_43_usage': {
    field: 'analyses.core_43_usage',
    type: 'float',
  },
  'analyses.core_44_usage': {
    field: 'analyses.core_44_usage',
    type: 'long',
  },
  'analyses.core_45_usage': {
    field: 'analyses.core_45_usage',
    type: 'float',
  },
  'analyses.core_46_usage': {
    field: 'analyses.core_46_usage',
    type: 'float',
  },
  'analyses.core_47_usage': {
    field: 'analyses.core_47_usage',
    type: 'float',
  },
  'analyses.core_48_usage': {
    field: 'analyses.core_48_usage',
    type: 'float',
  },
  'analyses.core_49_usage': {
    field: 'analyses.core_49_usage',
    type: 'float',
  },
  'analyses.core_4_usage': {
    field: 'analyses.core_4_usage',
    type: 'float',
  },
  'analyses.core_50_usage': {
    field: 'analyses.core_50_usage',
    type: 'float',
  },
  'analyses.core_51_usage': {
    field: 'analyses.core_51_usage',
    type: 'float',
  },
  'analyses.core_52_usage': {
    field: 'analyses.core_52_usage',
    type: 'long',
  },
  'analyses.core_53_usage': {
    field: 'analyses.core_53_usage',
    type: 'float',
  },
  'analyses.core_54_usage': {
    field: 'analyses.core_54_usage',
    type: 'float',
  },
  'analyses.core_55_usage': {
    field: 'analyses.core_55_usage',
    type: 'float',
  },
  'analyses.core_56_usage': {
    field: 'analyses.core_56_usage',
    type: 'float',
  },
  'analyses.core_57_usage': {
    field: 'analyses.core_57_usage',
    type: 'float',
  },
  'analyses.core_58_usage': {
    field: 'analyses.core_58_usage',
    type: 'float',
  },
  'analyses.core_59_usage': {
    field: 'analyses.core_59_usage',
    type: 'float',
  },
  'analyses.core_5_usage': {
    field: 'analyses.core_5_usage',
    type: 'float',
  },
  'analyses.core_60_usage': {
    field: 'analyses.core_60_usage',
    type: 'long',
  },
  'analyses.core_61_usage': {
    field: 'analyses.core_61_usage',
    type: 'float',
  },
  'analyses.core_62_usage': {
    field: 'analyses.core_62_usage',
    type: 'float',
  },
  'analyses.core_63_usage': {
    field: 'analyses.core_63_usage',
    type: 'float',
  },
  'analyses.core_64_usage': {
    field: 'analyses.core_64_usage',
    type: 'long',
  },
  'analyses.core_65_usage': {
    field: 'analyses.core_65_usage',
    type: 'long',
  },
  'analyses.core_66_usage': {
    field: 'analyses.core_66_usage',
    type: 'float',
  },
  'analyses.core_67_usage': {
    field: 'analyses.core_67_usage',
    type: 'float',
  },
  'analyses.core_68_usage': {
    field: 'analyses.core_68_usage',
    type: 'float',
  },
  'analyses.core_69_usage': {
    field: 'analyses.core_69_usage',
    type: 'long',
  },
  'analyses.core_6_usage': {
    field: 'analyses.core_6_usage',
    type: 'long',
  },
  'analyses.core_70_usage': {
    field: 'analyses.core_70_usage',
    type: 'float',
  },
  'analyses.core_71_usage': {
    field: 'analyses.core_71_usage',
    type: 'float',
  },
  'analyses.core_72_usage': {
    field: 'analyses.core_72_usage',
    type: 'float',
  },
  'analyses.core_73_usage': {
    field: 'analyses.core_73_usage',
    type: 'float',
  },
  'analyses.core_74_usage': {
    field: 'analyses.core_74_usage',
    type: 'float',
  },
  'analyses.core_75_usage': {
    field: 'analyses.core_75_usage',
    type: 'float',
  },
  'analyses.core_76_usage': {
    field: 'analyses.core_76_usage',
    type: 'long',
  },
  'analyses.core_77_usage': {
    field: 'analyses.core_77_usage',
    type: 'float',
  },
  'analyses.core_78_usage': {
    field: 'analyses.core_78_usage',
    type: 'float',
  },
  'analyses.core_79_usage': {
    field: 'analyses.core_79_usage',
    type: 'float',
  },
  'analyses.core_7_usage': {
    field: 'analyses.core_7_usage',
    type: 'float',
  },
  'analyses.core_8_usage': {
    field: 'analyses.core_8_usage',
    type: 'float',
  },
  'analyses.core_9_usage': {
    field: 'analyses.core_9_usage',
    type: 'long',
  },
  'analyses.coredump_count': {
    field: 'analyses.coredump_count',
    type: 'long',
  },
  'analyses.cpu_mem_use_box_plot_param': {
    field: 'analyses.cpu_mem_use_box_plot_param',
    type: 'float',
  },
  'analyses.cpu_mem_use_mode_threshold_app_manual_aviate_avg_cpu': {
    field: 'analyses.cpu_mem_use_mode_threshold_app_manual_aviate_avg_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_mode_threshold_app_manual_launch_sentinel_avg_cpu': {
    field:
      'analyses.cpu_mem_use_mode_threshold_app_manual_launch_sentinel_avg_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_mode_threshold_app_manual_local_planner_avg_cpu': {
    field:
      'analyses.cpu_mem_use_mode_threshold_app_manual_local_planner_avg_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_mode_threshold_app_manual_map_slice_generator_avg_cpu':
    {
      field:
        'analyses.cpu_mem_use_mode_threshold_app_manual_map_slice_generator_avg_cpu',
      type: 'float',
    },
  'analyses.cpu_mem_use_mode_threshold_app_manual_mavros_avg_cpu': {
    field: 'analyses.cpu_mem_use_mode_threshold_app_manual_mavros_avg_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_mode_threshold_app_manual_max_cpu': {
    field: 'analyses.cpu_mem_use_mode_threshold_app_manual_max_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_mode_threshold_app_manual_msckf_avg_cpu': {
    field: 'analyses.cpu_mem_use_mode_threshold_app_manual_msckf_avg_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_mode_threshold_app_manual_nodelet_manager_avg_cpu': {
    field:
      'analyses.cpu_mem_use_mode_threshold_app_manual_nodelet_manager_avg_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_mode_threshold_app_manual_person_detector_avg_cpu': {
    field:
      'analyses.cpu_mem_use_mode_threshold_app_manual_person_detector_avg_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_mode_threshold_app_manual_portal_detector_avg_cpu': {
    field:
      'analyses.cpu_mem_use_mode_threshold_app_manual_portal_detector_avg_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_mode_threshold_app_manual_pose_graph_avg_cpu': {
    field: 'analyses.cpu_mem_use_mode_threshold_app_manual_pose_graph_avg_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_mode_threshold_app_manual_smart_env_class_avg_cpu': {
    field:
      'analyses.cpu_mem_use_mode_threshold_app_manual_smart_env_class_avg_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_mode_threshold_app_manual_smart_environment_classifier_avg_cpu':
    {
      field:
        'analyses.cpu_mem_use_mode_threshold_app_manual_smart_environment_classifier_avg_cpu',
      type: 'float',
    },
  'analyses.cpu_mem_use_mode_threshold_app_manual_total_max_cpu': {
    field: 'analyses.cpu_mem_use_mode_threshold_app_manual_total_max_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_mode_threshold_explore_aviate_avg_cpu': {
    field: 'analyses.cpu_mem_use_mode_threshold_explore_aviate_avg_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_mode_threshold_explore_launch_sentinel_avg_cpu': {
    field:
      'analyses.cpu_mem_use_mode_threshold_explore_launch_sentinel_avg_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_mode_threshold_explore_local_planner_avg_cpu': {
    field: 'analyses.cpu_mem_use_mode_threshold_explore_local_planner_avg_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_mode_threshold_explore_map_slice_generator_avg_cpu': {
    field:
      'analyses.cpu_mem_use_mode_threshold_explore_map_slice_generator_avg_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_mode_threshold_explore_mavros_avg_cpu': {
    field: 'analyses.cpu_mem_use_mode_threshold_explore_mavros_avg_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_mode_threshold_explore_max_cpu': {
    field: 'analyses.cpu_mem_use_mode_threshold_explore_max_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_mode_threshold_explore_msckf_avg_cpu': {
    field: 'analyses.cpu_mem_use_mode_threshold_explore_msckf_avg_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_mode_threshold_explore_nodelet_manager_avg_cpu': {
    field:
      'analyses.cpu_mem_use_mode_threshold_explore_nodelet_manager_avg_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_mode_threshold_explore_person_detector_avg_cpu': {
    field:
      'analyses.cpu_mem_use_mode_threshold_explore_person_detector_avg_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_mode_threshold_explore_portal_detector_avg_cpu': {
    field:
      'analyses.cpu_mem_use_mode_threshold_explore_portal_detector_avg_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_mode_threshold_explore_pose_graph_avg_cpu': {
    field: 'analyses.cpu_mem_use_mode_threshold_explore_pose_graph_avg_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_mode_threshold_explore_smart_env_class_avg_cpu': {
    field:
      'analyses.cpu_mem_use_mode_threshold_explore_smart_env_class_avg_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_mode_threshold_explore_smart_environment_classifier_avg_cpu':
    {
      field:
        'analyses.cpu_mem_use_mode_threshold_explore_smart_environment_classifier_avg_cpu',
      type: 'float',
    },
  'analyses.cpu_mem_use_mode_threshold_explore_total_max_cpu': {
    field: 'analyses.cpu_mem_use_mode_threshold_explore_total_max_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_mode_threshold_waypoint_aviate_avg_cpu': {
    field: 'analyses.cpu_mem_use_mode_threshold_waypoint_aviate_avg_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_mode_threshold_waypoint_launch_sentinel_avg_cpu': {
    field:
      'analyses.cpu_mem_use_mode_threshold_waypoint_launch_sentinel_avg_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_mode_threshold_waypoint_local_planner_avg_cpu': {
    field: 'analyses.cpu_mem_use_mode_threshold_waypoint_local_planner_avg_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_mode_threshold_waypoint_map_slice_generator_avg_cpu': {
    field:
      'analyses.cpu_mem_use_mode_threshold_waypoint_map_slice_generator_avg_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_mode_threshold_waypoint_mavros_avg_cpu': {
    field: 'analyses.cpu_mem_use_mode_threshold_waypoint_mavros_avg_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_mode_threshold_waypoint_max_cpu': {
    field: 'analyses.cpu_mem_use_mode_threshold_waypoint_max_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_mode_threshold_waypoint_msckf_avg_cpu': {
    field: 'analyses.cpu_mem_use_mode_threshold_waypoint_msckf_avg_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_mode_threshold_waypoint_nodelet_manager_avg_cpu': {
    field:
      'analyses.cpu_mem_use_mode_threshold_waypoint_nodelet_manager_avg_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_mode_threshold_waypoint_person_detector_avg_cpu': {
    field:
      'analyses.cpu_mem_use_mode_threshold_waypoint_person_detector_avg_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_mode_threshold_waypoint_portal_detector_avg_cpu': {
    field:
      'analyses.cpu_mem_use_mode_threshold_waypoint_portal_detector_avg_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_mode_threshold_waypoint_pose_graph_avg_cpu': {
    field: 'analyses.cpu_mem_use_mode_threshold_waypoint_pose_graph_avg_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_mode_threshold_waypoint_smart_env_class_avg_cpu': {
    field:
      'analyses.cpu_mem_use_mode_threshold_waypoint_smart_env_class_avg_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_mode_threshold_waypoint_total_max_cpu': {
    field: 'analyses.cpu_mem_use_mode_threshold_waypoint_total_max_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_team_threshold_SE_avg_cpu': {
    field: 'analyses.cpu_mem_use_team_threshold_SE_avg_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_team_threshold_SE_avg_mem': {
    field: 'analyses.cpu_mem_use_team_threshold_SE_avg_mem',
    type: 'float',
  },
  'analyses.cpu_mem_use_threshold_aviate_avg_cpu': {
    field: 'analyses.cpu_mem_use_threshold_aviate_avg_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_threshold_aviate_avg_mem': {
    field: 'analyses.cpu_mem_use_threshold_aviate_avg_mem',
    type: 'float',
  },
  'analyses.cpu_mem_use_threshold_aviate_cpu': {
    field: 'analyses.cpu_mem_use_threshold_aviate_cpu',
    type: 'long',
  },
  'analyses.cpu_mem_use_threshold_aviate_max_cpu': {
    field: 'analyses.cpu_mem_use_threshold_aviate_max_cpu',
    type: 'long',
  },
  'analyses.cpu_mem_use_threshold_aviate_max_mem': {
    field: 'analyses.cpu_mem_use_threshold_aviate_max_mem',
    type: 'float',
  },
  'analyses.cpu_mem_use_threshold_aviate_mem': {
    field: 'analyses.cpu_mem_use_threshold_aviate_mem',
    type: 'float',
  },
  'analyses.cpu_mem_use_threshold_global_map_nodelet_manager_avg_cpu': {
    field: 'analyses.cpu_mem_use_threshold_global_map_nodelet_manager_avg_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_threshold_global_map_nodelet_manager_avg_mem': {
    field: 'analyses.cpu_mem_use_threshold_global_map_nodelet_manager_avg_mem',
    type: 'float',
  },
  'analyses.cpu_mem_use_threshold_global_map_nodelet_manager_avg_mem_gb': {
    field:
      'analyses.cpu_mem_use_threshold_global_map_nodelet_manager_avg_mem_gb',
    type: 'float',
  },
  'analyses.cpu_mem_use_threshold_global_map_nodelet_manager_max_cpu': {
    field: 'analyses.cpu_mem_use_threshold_global_map_nodelet_manager_max_cpu',
    type: 'long',
  },
  'analyses.cpu_mem_use_threshold_global_map_nodelet_manager_max_mem': {
    field: 'analyses.cpu_mem_use_threshold_global_map_nodelet_manager_max_mem',
    type: 'float',
  },
  'analyses.cpu_mem_use_threshold_global_map_nodelet_manager_max_mem_gb': {
    field:
      'analyses.cpu_mem_use_threshold_global_map_nodelet_manager_max_mem_gb',
    type: 'float',
  },
  'analyses.cpu_mem_use_threshold_local_map_nodelet_manager_avg_cpu': {
    field: 'analyses.cpu_mem_use_threshold_local_map_nodelet_manager_avg_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_threshold_local_map_nodelet_manager_avg_mem': {
    field: 'analyses.cpu_mem_use_threshold_local_map_nodelet_manager_avg_mem',
    type: 'float',
  },
  'analyses.cpu_mem_use_threshold_local_map_nodelet_manager_avg_mem_gb': {
    field:
      'analyses.cpu_mem_use_threshold_local_map_nodelet_manager_avg_mem_gb',
    type: 'float',
  },
  'analyses.cpu_mem_use_threshold_local_map_nodelet_manager_max_cpu': {
    field: 'analyses.cpu_mem_use_threshold_local_map_nodelet_manager_max_cpu',
    type: 'long',
  },
  'analyses.cpu_mem_use_threshold_local_map_nodelet_manager_max_mem': {
    field: 'analyses.cpu_mem_use_threshold_local_map_nodelet_manager_max_mem',
    type: 'float',
  },
  'analyses.cpu_mem_use_threshold_local_map_nodelet_manager_max_mem_gb': {
    field:
      'analyses.cpu_mem_use_threshold_local_map_nodelet_manager_max_mem_gb',
    type: 'float',
  },
  'analyses.cpu_mem_use_threshold_local_planner_avg_cpu': {
    field: 'analyses.cpu_mem_use_threshold_local_planner_avg_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_threshold_local_planner_avg_mem': {
    field: 'analyses.cpu_mem_use_threshold_local_planner_avg_mem',
    type: 'float',
  },
  'analyses.cpu_mem_use_threshold_local_planner_max_cpu': {
    field: 'analyses.cpu_mem_use_threshold_local_planner_max_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_threshold_local_planner_max_mem': {
    field: 'analyses.cpu_mem_use_threshold_local_planner_max_mem',
    type: 'float',
  },
  'analyses.cpu_mem_use_threshold_map_keyframe_requester_cpu': {
    field: 'analyses.cpu_mem_use_threshold_map_keyframe_requester_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_threshold_map_keyframe_requester_mem': {
    field: 'analyses.cpu_mem_use_threshold_map_keyframe_requester_mem',
    type: 'float',
  },
  'analyses.cpu_mem_use_threshold_map_slice_generator_avg_cpu': {
    field: 'analyses.cpu_mem_use_threshold_map_slice_generator_avg_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_threshold_map_slice_generator_max_cpu': {
    field: 'analyses.cpu_mem_use_threshold_map_slice_generator_max_cpu',
    type: 'long',
  },
  'analyses.cpu_mem_use_threshold_map_slice_generator_max_mem': {
    field: 'analyses.cpu_mem_use_threshold_map_slice_generator_max_mem',
    type: 'float',
  },
  'analyses.cpu_mem_use_threshold_pose_graph_avg_cpu': {
    field: 'analyses.cpu_mem_use_threshold_pose_graph_avg_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_threshold_pose_graph_max_cpu': {
    field: 'analyses.cpu_mem_use_threshold_pose_graph_max_cpu',
    type: 'long',
  },
  'analyses.cpu_mem_use_threshold_smart_env_class_cpu': {
    field: 'analyses.cpu_mem_use_threshold_smart_env_class_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_threshold_smart_env_class_mem': {
    field: 'analyses.cpu_mem_use_threshold_smart_env_class_mem',
    type: 'float',
  },
  'analyses.cpu_mem_use_threshold_smart_environment_classifier_cpu': {
    field: 'analyses.cpu_mem_use_threshold_smart_environment_classifier_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_threshold_smart_environment_classifier_mem': {
    field: 'analyses.cpu_mem_use_threshold_smart_environment_classifier_mem',
    type: 'float',
  },
  'analyses.cpu_mem_use_threshold_total_avg_cpu': {
    field: 'analyses.cpu_mem_use_threshold_total_avg_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_threshold_total_avg_mem': {
    field: 'analyses.cpu_mem_use_threshold_total_avg_mem',
    type: 'float',
  },
  'analyses.cpu_mem_use_threshold_total_max_cpu': {
    field: 'analyses.cpu_mem_use_threshold_total_max_cpu',
    type: 'float',
  },
  'analyses.cpu_mem_use_threshold_total_max_mem': {
    field: 'analyses.cpu_mem_use_threshold_total_max_mem',
    type: 'float',
  },
  'analyses.depth_camera/forward/camera_info_delta_tov_complete_dropout': {
    field:
      'analyses.depth_camera/forward/camera_info_delta_tov_complete_dropout',
    type: 'float',
  },
  'analyses.depth_camera/forward/camera_info_delta_tov_dropouts': {
    field: 'analyses.depth_camera/forward/camera_info_delta_tov_dropouts',
    type: 'long',
  },
  'analyses.depth_camera/forward/camera_info_delta_tov_max': {
    field: 'analyses.depth_camera/forward/camera_info_delta_tov_max',
    type: 'float',
  },
  'analyses.depth_camera/forward/camera_info_delta_tov_mean': {
    field: 'analyses.depth_camera/forward/camera_info_delta_tov_mean',
    type: 'float',
  },
  'analyses.depth_camera/forward/camera_info_delta_tov_median': {
    field: 'analyses.depth_camera/forward/camera_info_delta_tov_median',
    type: 'float',
  },
  'analyses.depth_camera/forward/camera_info_delta_tov_stdev': {
    field: 'analyses.depth_camera/forward/camera_info_delta_tov_stdev',
    type: 'float',
  },
  'analyses.depth_camera/forward/camera_info_dt_max': {
    field: 'analyses.depth_camera/forward/camera_info_dt_max',
    type: 'float',
  },
  'analyses.depth_camera/forward/camera_info_dt_mean': {
    field: 'analyses.depth_camera/forward/camera_info_dt_mean',
    type: 'float',
  },
  'analyses.depth_camera/forward/camera_info_dt_median': {
    field: 'analyses.depth_camera/forward/camera_info_dt_median',
    type: 'float',
  },
  'analyses.depth_camera/forward/camera_info_dt_stdev': {
    field: 'analyses.depth_camera/forward/camera_info_dt_stdev',
    type: 'float',
  },
  'analyses.depth_camera/forward/camera_info_latency': {
    field: 'analyses.depth_camera/forward/camera_info_latency',
    type: 'float',
  },
  'analyses.depth_camera/forward/camera_info_latency_max': {
    field: 'analyses.depth_camera/forward/camera_info_latency_max',
    type: 'float',
  },
  'analyses.depth_camera/forward/camera_info_latency_mean': {
    field: 'analyses.depth_camera/forward/camera_info_latency_mean',
    type: 'float',
  },
  'analyses.depth_camera/forward/camera_info_latency_median': {
    field: 'analyses.depth_camera/forward/camera_info_latency_median',
    type: 'float',
  },
  'analyses.depth_camera/forward/camera_info_latency_stdev': {
    field: 'analyses.depth_camera/forward/camera_info_latency_stdev',
    type: 'float',
  },
  'analyses.depth_camera/forward/camera_info_timing': {
    field: 'analyses.depth_camera/forward/camera_info_timing',
    type: 'float',
  },
  'analyses.depth_camera/forward_down/camera_info_delta_tov_complete_dropout': {
    field:
      'analyses.depth_camera/forward_down/camera_info_delta_tov_complete_dropout',
    type: 'float',
  },
  'analyses.depth_camera/forward_down/camera_info_delta_tov_dropouts': {
    field: 'analyses.depth_camera/forward_down/camera_info_delta_tov_dropouts',
    type: 'long',
  },
  'analyses.depth_camera/forward_down/camera_info_delta_tov_max': {
    field: 'analyses.depth_camera/forward_down/camera_info_delta_tov_max',
    type: 'float',
  },
  'analyses.depth_camera/forward_down/camera_info_delta_tov_mean': {
    field: 'analyses.depth_camera/forward_down/camera_info_delta_tov_mean',
    type: 'float',
  },
  'analyses.depth_camera/forward_down/camera_info_delta_tov_median': {
    field: 'analyses.depth_camera/forward_down/camera_info_delta_tov_median',
    type: 'float',
  },
  'analyses.depth_camera/forward_down/camera_info_delta_tov_stdev': {
    field: 'analyses.depth_camera/forward_down/camera_info_delta_tov_stdev',
    type: 'float',
  },
  'analyses.depth_camera/forward_down/camera_info_dt_max': {
    field: 'analyses.depth_camera/forward_down/camera_info_dt_max',
    type: 'float',
  },
  'analyses.depth_camera/forward_down/camera_info_dt_mean': {
    field: 'analyses.depth_camera/forward_down/camera_info_dt_mean',
    type: 'float',
  },
  'analyses.depth_camera/forward_down/camera_info_dt_median': {
    field: 'analyses.depth_camera/forward_down/camera_info_dt_median',
    type: 'float',
  },
  'analyses.depth_camera/forward_down/camera_info_dt_stdev': {
    field: 'analyses.depth_camera/forward_down/camera_info_dt_stdev',
    type: 'float',
  },
  'analyses.depth_camera/forward_down/camera_info_latency': {
    field: 'analyses.depth_camera/forward_down/camera_info_latency',
    type: 'float',
  },
  'analyses.depth_camera/forward_down/camera_info_latency_max': {
    field: 'analyses.depth_camera/forward_down/camera_info_latency_max',
    type: 'float',
  },
  'analyses.depth_camera/forward_down/camera_info_latency_mean': {
    field: 'analyses.depth_camera/forward_down/camera_info_latency_mean',
    type: 'float',
  },
  'analyses.depth_camera/forward_down/camera_info_latency_median': {
    field: 'analyses.depth_camera/forward_down/camera_info_latency_median',
    type: 'float',
  },
  'analyses.depth_camera/forward_down/camera_info_latency_stdev': {
    field: 'analyses.depth_camera/forward_down/camera_info_latency_stdev',
    type: 'float',
  },
  'analyses.depth_camera/forward_down/camera_info_timing': {
    field: 'analyses.depth_camera/forward_down/camera_info_timing',
    type: 'float',
  },
  'analyses.depth_camera/forward_up/camera_info_delta_tov_complete_dropout': {
    field:
      'analyses.depth_camera/forward_up/camera_info_delta_tov_complete_dropout',
    type: 'float',
  },
  'analyses.depth_camera/forward_up/camera_info_delta_tov_dropouts': {
    field: 'analyses.depth_camera/forward_up/camera_info_delta_tov_dropouts',
    type: 'long',
  },
  'analyses.depth_camera/forward_up/camera_info_delta_tov_max': {
    field: 'analyses.depth_camera/forward_up/camera_info_delta_tov_max',
    type: 'float',
  },
  'analyses.depth_camera/forward_up/camera_info_delta_tov_mean': {
    field: 'analyses.depth_camera/forward_up/camera_info_delta_tov_mean',
    type: 'float',
  },
  'analyses.depth_camera/forward_up/camera_info_delta_tov_median': {
    field: 'analyses.depth_camera/forward_up/camera_info_delta_tov_median',
    type: 'float',
  },
  'analyses.depth_camera/forward_up/camera_info_delta_tov_stdev': {
    field: 'analyses.depth_camera/forward_up/camera_info_delta_tov_stdev',
    type: 'float',
  },
  'analyses.depth_camera/forward_up/camera_info_dt_max': {
    field: 'analyses.depth_camera/forward_up/camera_info_dt_max',
    type: 'float',
  },
  'analyses.depth_camera/forward_up/camera_info_dt_mean': {
    field: 'analyses.depth_camera/forward_up/camera_info_dt_mean',
    type: 'float',
  },
  'analyses.depth_camera/forward_up/camera_info_dt_median': {
    field: 'analyses.depth_camera/forward_up/camera_info_dt_median',
    type: 'float',
  },
  'analyses.depth_camera/forward_up/camera_info_dt_stdev': {
    field: 'analyses.depth_camera/forward_up/camera_info_dt_stdev',
    type: 'float',
  },
  'analyses.depth_camera/forward_up/camera_info_latency': {
    field: 'analyses.depth_camera/forward_up/camera_info_latency',
    type: 'float',
  },
  'analyses.depth_camera/forward_up/camera_info_latency_max': {
    field: 'analyses.depth_camera/forward_up/camera_info_latency_max',
    type: 'float',
  },
  'analyses.depth_camera/forward_up/camera_info_latency_mean': {
    field: 'analyses.depth_camera/forward_up/camera_info_latency_mean',
    type: 'float',
  },
  'analyses.depth_camera/forward_up/camera_info_latency_median': {
    field: 'analyses.depth_camera/forward_up/camera_info_latency_median',
    type: 'float',
  },
  'analyses.depth_camera/forward_up/camera_info_latency_stdev': {
    field: 'analyses.depth_camera/forward_up/camera_info_latency_stdev',
    type: 'float',
  },
  'analyses.depth_camera/forward_up/camera_info_timing': {
    field: 'analyses.depth_camera/forward_up/camera_info_timing',
    type: 'float',
  },
  'analyses.depth_camera/left/camera_info_delta_tov_complete_dropout': {
    field: 'analyses.depth_camera/left/camera_info_delta_tov_complete_dropout',
    type: 'float',
  },
  'analyses.depth_camera/left/camera_info_delta_tov_dropouts': {
    field: 'analyses.depth_camera/left/camera_info_delta_tov_dropouts',
    type: 'long',
  },
  'analyses.depth_camera/left/camera_info_delta_tov_max': {
    field: 'analyses.depth_camera/left/camera_info_delta_tov_max',
    type: 'float',
  },
  'analyses.depth_camera/left/camera_info_delta_tov_mean': {
    field: 'analyses.depth_camera/left/camera_info_delta_tov_mean',
    type: 'float',
  },
  'analyses.depth_camera/left/camera_info_delta_tov_median': {
    field: 'analyses.depth_camera/left/camera_info_delta_tov_median',
    type: 'float',
  },
  'analyses.depth_camera/left/camera_info_delta_tov_stdev': {
    field: 'analyses.depth_camera/left/camera_info_delta_tov_stdev',
    type: 'float',
  },
  'analyses.depth_camera/left/camera_info_dt_max': {
    field: 'analyses.depth_camera/left/camera_info_dt_max',
    type: 'float',
  },
  'analyses.depth_camera/left/camera_info_dt_mean': {
    field: 'analyses.depth_camera/left/camera_info_dt_mean',
    type: 'float',
  },
  'analyses.depth_camera/left/camera_info_dt_median': {
    field: 'analyses.depth_camera/left/camera_info_dt_median',
    type: 'float',
  },
  'analyses.depth_camera/left/camera_info_dt_stdev': {
    field: 'analyses.depth_camera/left/camera_info_dt_stdev',
    type: 'float',
  },
  'analyses.depth_camera/left/camera_info_latency': {
    field: 'analyses.depth_camera/left/camera_info_latency',
    type: 'float',
  },
  'analyses.depth_camera/left/camera_info_latency_max': {
    field: 'analyses.depth_camera/left/camera_info_latency_max',
    type: 'float',
  },
  'analyses.depth_camera/left/camera_info_latency_mean': {
    field: 'analyses.depth_camera/left/camera_info_latency_mean',
    type: 'float',
  },
  'analyses.depth_camera/left/camera_info_latency_median': {
    field: 'analyses.depth_camera/left/camera_info_latency_median',
    type: 'float',
  },
  'analyses.depth_camera/left/camera_info_latency_stdev': {
    field: 'analyses.depth_camera/left/camera_info_latency_stdev',
    type: 'float',
  },
  'analyses.depth_camera/left/camera_info_timing': {
    field: 'analyses.depth_camera/left/camera_info_timing',
    type: 'float',
  },
  'analyses.depth_camera/right/camera_info_delta_tov_complete_dropout': {
    field: 'analyses.depth_camera/right/camera_info_delta_tov_complete_dropout',
    type: 'float',
  },
  'analyses.depth_camera/right/camera_info_delta_tov_dropouts': {
    field: 'analyses.depth_camera/right/camera_info_delta_tov_dropouts',
    type: 'long',
  },
  'analyses.depth_camera/right/camera_info_delta_tov_max': {
    field: 'analyses.depth_camera/right/camera_info_delta_tov_max',
    type: 'float',
  },
  'analyses.depth_camera/right/camera_info_delta_tov_mean': {
    field: 'analyses.depth_camera/right/camera_info_delta_tov_mean',
    type: 'float',
  },
  'analyses.depth_camera/right/camera_info_delta_tov_median': {
    field: 'analyses.depth_camera/right/camera_info_delta_tov_median',
    type: 'float',
  },
  'analyses.depth_camera/right/camera_info_delta_tov_stdev': {
    field: 'analyses.depth_camera/right/camera_info_delta_tov_stdev',
    type: 'float',
  },
  'analyses.depth_camera/right/camera_info_dt_max': {
    field: 'analyses.depth_camera/right/camera_info_dt_max',
    type: 'float',
  },
  'analyses.depth_camera/right/camera_info_dt_mean': {
    field: 'analyses.depth_camera/right/camera_info_dt_mean',
    type: 'float',
  },
  'analyses.depth_camera/right/camera_info_dt_median': {
    field: 'analyses.depth_camera/right/camera_info_dt_median',
    type: 'float',
  },
  'analyses.depth_camera/right/camera_info_dt_stdev': {
    field: 'analyses.depth_camera/right/camera_info_dt_stdev',
    type: 'float',
  },
  'analyses.depth_camera/right/camera_info_latency': {
    field: 'analyses.depth_camera/right/camera_info_latency',
    type: 'float',
  },
  'analyses.depth_camera/right/camera_info_latency_max': {
    field: 'analyses.depth_camera/right/camera_info_latency_max',
    type: 'float',
  },
  'analyses.depth_camera/right/camera_info_latency_mean': {
    field: 'analyses.depth_camera/right/camera_info_latency_mean',
    type: 'float',
  },
  'analyses.depth_camera/right/camera_info_latency_median': {
    field: 'analyses.depth_camera/right/camera_info_latency_median',
    type: 'float',
  },
  'analyses.depth_camera/right/camera_info_latency_stdev': {
    field: 'analyses.depth_camera/right/camera_info_latency_stdev',
    type: 'float',
  },
  'analyses.depth_camera/right/camera_info_timing': {
    field: 'analyses.depth_camera/right/camera_info_timing',
    type: 'float',
  },
  'analyses.depth_fill_min_fill_percent': {
    field: 'analyses.depth_fill_min_fill_percent',
    type: 'long',
  },
  'analyses.depth_fill_min_mean_fill_percent': {
    field: 'analyses.depth_fill_min_mean_fill_percent',
    type: 'long',
  },
  'analyses.disk_threshold_exceeded': {
    field: 'analyses.disk_threshold_exceeded',
    type: 'long',
  },
  'analyses.downward_max_fill_percent_threshold': {
    field: 'analyses.downward_max_fill_percent_threshold',
    type: 'float',
  },
  'analyses.downward_mean_fill_percent_threshold': {
    field: 'analyses.downward_mean_fill_percent_threshold',
    type: 'float',
  },
  'analyses.downward_min_fill_percent_threshold': {
    field: 'analyses.downward_min_fill_percent_threshold',
    type: 'long',
  },
  'analyses.duplicate_timestamps': {
    field: 'analyses.duplicate_timestamps',
    type: 'long',
  },
  'analyses.eo_bottom_extrinsics_rotation_pitch_reference_diff': {
    field: 'analyses.eo_bottom_extrinsics_rotation_pitch_reference_diff',
    type: 'float',
  },
  'analyses.eo_bottom_extrinsics_rotation_roll_reference_diff': {
    field: 'analyses.eo_bottom_extrinsics_rotation_roll_reference_diff',
    type: 'float',
  },
  'analyses.eo_bottom_extrinsics_rotation_yaw_reference_diff': {
    field: 'analyses.eo_bottom_extrinsics_rotation_yaw_reference_diff',
    type: 'float',
  },
  'analyses.eo_bottom_extrinsics_translation_x_reference_diff': {
    field: 'analyses.eo_bottom_extrinsics_translation_x_reference_diff',
    type: 'float',
  },
  'analyses.eo_bottom_extrinsics_translation_y_reference_diff': {
    field: 'analyses.eo_bottom_extrinsics_translation_y_reference_diff',
    type: 'float',
  },
  'analyses.eo_bottom_extrinsics_translation_z_reference_diff': {
    field: 'analyses.eo_bottom_extrinsics_translation_z_reference_diff',
    type: 'float',
  },
  'analyses.eo_camera/bottom/camera_info_delta_tov_complete_dropout': {
    field: 'analyses.eo_camera/bottom/camera_info_delta_tov_complete_dropout',
    type: 'float',
  },
  'analyses.eo_camera/bottom/camera_info_delta_tov_dropouts': {
    field: 'analyses.eo_camera/bottom/camera_info_delta_tov_dropouts',
    type: 'long',
  },
  'analyses.eo_camera/bottom/camera_info_delta_tov_max': {
    field: 'analyses.eo_camera/bottom/camera_info_delta_tov_max',
    type: 'float',
  },
  'analyses.eo_camera/bottom/camera_info_delta_tov_mean': {
    field: 'analyses.eo_camera/bottom/camera_info_delta_tov_mean',
    type: 'float',
  },
  'analyses.eo_camera/bottom/camera_info_delta_tov_median': {
    field: 'analyses.eo_camera/bottom/camera_info_delta_tov_median',
    type: 'float',
  },
  'analyses.eo_camera/bottom/camera_info_delta_tov_stdev': {
    field: 'analyses.eo_camera/bottom/camera_info_delta_tov_stdev',
    type: 'float',
  },
  'analyses.eo_camera/bottom/camera_info_dt_max': {
    field: 'analyses.eo_camera/bottom/camera_info_dt_max',
    type: 'float',
  },
  'analyses.eo_camera/bottom/camera_info_dt_mean': {
    field: 'analyses.eo_camera/bottom/camera_info_dt_mean',
    type: 'float',
  },
  'analyses.eo_camera/bottom/camera_info_dt_median': {
    field: 'analyses.eo_camera/bottom/camera_info_dt_median',
    type: 'float',
  },
  'analyses.eo_camera/bottom/camera_info_dt_stdev': {
    field: 'analyses.eo_camera/bottom/camera_info_dt_stdev',
    type: 'float',
  },
  'analyses.eo_camera/bottom/camera_info_latency': {
    field: 'analyses.eo_camera/bottom/camera_info_latency',
    type: 'float',
  },
  'analyses.eo_camera/bottom/camera_info_latency_max': {
    field: 'analyses.eo_camera/bottom/camera_info_latency_max',
    type: 'float',
  },
  'analyses.eo_camera/bottom/camera_info_latency_mean': {
    field: 'analyses.eo_camera/bottom/camera_info_latency_mean',
    type: 'float',
  },
  'analyses.eo_camera/bottom/camera_info_latency_median': {
    field: 'analyses.eo_camera/bottom/camera_info_latency_median',
    type: 'float',
  },
  'analyses.eo_camera/bottom/camera_info_latency_stdev': {
    field: 'analyses.eo_camera/bottom/camera_info_latency_stdev',
    type: 'float',
  },
  'analyses.eo_camera/bottom/camera_info_timing': {
    field: 'analyses.eo_camera/bottom/camera_info_timing',
    type: 'float',
  },
  'analyses.eo_camera/bottom/depth_registered/camera_info_delta_tov_complete_dropout':
    {
      field:
        'analyses.eo_camera/bottom/depth_registered/camera_info_delta_tov_complete_dropout',
      type: 'float',
    },
  'analyses.eo_camera/bottom/depth_registered/camera_info_delta_tov_dropouts': {
    field:
      'analyses.eo_camera/bottom/depth_registered/camera_info_delta_tov_dropouts',
    type: 'long',
  },
  'analyses.eo_camera/bottom/depth_registered/camera_info_delta_tov_max': {
    field:
      'analyses.eo_camera/bottom/depth_registered/camera_info_delta_tov_max',
    type: 'float',
  },
  'analyses.eo_camera/bottom/depth_registered/camera_info_delta_tov_mean': {
    field:
      'analyses.eo_camera/bottom/depth_registered/camera_info_delta_tov_mean',
    type: 'float',
  },
  'analyses.eo_camera/bottom/depth_registered/camera_info_delta_tov_median': {
    field:
      'analyses.eo_camera/bottom/depth_registered/camera_info_delta_tov_median',
    type: 'float',
  },
  'analyses.eo_camera/bottom/depth_registered/camera_info_delta_tov_stdev': {
    field:
      'analyses.eo_camera/bottom/depth_registered/camera_info_delta_tov_stdev',
    type: 'float',
  },
  'analyses.eo_camera/bottom/depth_registered/camera_info_dt_max': {
    field: 'analyses.eo_camera/bottom/depth_registered/camera_info_dt_max',
    type: 'float',
  },
  'analyses.eo_camera/bottom/depth_registered/camera_info_dt_mean': {
    field: 'analyses.eo_camera/bottom/depth_registered/camera_info_dt_mean',
    type: 'float',
  },
  'analyses.eo_camera/bottom/depth_registered/camera_info_dt_median': {
    field: 'analyses.eo_camera/bottom/depth_registered/camera_info_dt_median',
    type: 'float',
  },
  'analyses.eo_camera/bottom/depth_registered/camera_info_dt_stdev': {
    field: 'analyses.eo_camera/bottom/depth_registered/camera_info_dt_stdev',
    type: 'float',
  },
  'analyses.eo_camera/bottom/depth_registered/camera_info_latency': {
    field: 'analyses.eo_camera/bottom/depth_registered/camera_info_latency',
    type: 'float',
  },
  'analyses.eo_camera/bottom/depth_registered/camera_info_latency_max': {
    field: 'analyses.eo_camera/bottom/depth_registered/camera_info_latency_max',
    type: 'float',
  },
  'analyses.eo_camera/bottom/depth_registered/camera_info_latency_mean': {
    field:
      'analyses.eo_camera/bottom/depth_registered/camera_info_latency_mean',
    type: 'float',
  },
  'analyses.eo_camera/bottom/depth_registered/camera_info_latency_median': {
    field:
      'analyses.eo_camera/bottom/depth_registered/camera_info_latency_median',
    type: 'float',
  },
  'analyses.eo_camera/bottom/depth_registered/camera_info_latency_stdev': {
    field:
      'analyses.eo_camera/bottom/depth_registered/camera_info_latency_stdev',
    type: 'float',
  },
  'analyses.eo_camera/bottom/depth_registered/camera_info_timing': {
    field: 'analyses.eo_camera/bottom/depth_registered/camera_info_timing',
    type: 'float',
  },
  'analyses.eo_camera/forward/camera_info_delta_tov_complete_dropout': {
    field: 'analyses.eo_camera/forward/camera_info_delta_tov_complete_dropout',
    type: 'float',
  },
  'analyses.eo_camera/forward/camera_info_delta_tov_dropouts': {
    field: 'analyses.eo_camera/forward/camera_info_delta_tov_dropouts',
    type: 'long',
  },
  'analyses.eo_camera/forward/camera_info_delta_tov_max': {
    field: 'analyses.eo_camera/forward/camera_info_delta_tov_max',
    type: 'float',
  },
  'analyses.eo_camera/forward/camera_info_delta_tov_mean': {
    field: 'analyses.eo_camera/forward/camera_info_delta_tov_mean',
    type: 'float',
  },
  'analyses.eo_camera/forward/camera_info_delta_tov_median': {
    field: 'analyses.eo_camera/forward/camera_info_delta_tov_median',
    type: 'float',
  },
  'analyses.eo_camera/forward/camera_info_delta_tov_stdev': {
    field: 'analyses.eo_camera/forward/camera_info_delta_tov_stdev',
    type: 'float',
  },
  'analyses.eo_camera/forward/camera_info_dt_max': {
    field: 'analyses.eo_camera/forward/camera_info_dt_max',
    type: 'float',
  },
  'analyses.eo_camera/forward/camera_info_dt_mean': {
    field: 'analyses.eo_camera/forward/camera_info_dt_mean',
    type: 'float',
  },
  'analyses.eo_camera/forward/camera_info_dt_median': {
    field: 'analyses.eo_camera/forward/camera_info_dt_median',
    type: 'float',
  },
  'analyses.eo_camera/forward/camera_info_dt_stdev': {
    field: 'analyses.eo_camera/forward/camera_info_dt_stdev',
    type: 'float',
  },
  'analyses.eo_camera/forward/camera_info_latency': {
    field: 'analyses.eo_camera/forward/camera_info_latency',
    type: 'float',
  },
  'analyses.eo_camera/forward/camera_info_latency_max': {
    field: 'analyses.eo_camera/forward/camera_info_latency_max',
    type: 'float',
  },
  'analyses.eo_camera/forward/camera_info_latency_mean': {
    field: 'analyses.eo_camera/forward/camera_info_latency_mean',
    type: 'float',
  },
  'analyses.eo_camera/forward/camera_info_latency_median': {
    field: 'analyses.eo_camera/forward/camera_info_latency_median',
    type: 'float',
  },
  'analyses.eo_camera/forward/camera_info_latency_stdev': {
    field: 'analyses.eo_camera/forward/camera_info_latency_stdev',
    type: 'float',
  },
  'analyses.eo_camera/forward/camera_info_timing': {
    field: 'analyses.eo_camera/forward/camera_info_timing',
    type: 'float',
  },
  'analyses.eo_camera/forward/depth_registered/camera_info_delta_tov_complete_dropout':
    {
      field:
        'analyses.eo_camera/forward/depth_registered/camera_info_delta_tov_complete_dropout',
      type: 'float',
    },
  'analyses.eo_camera/forward/depth_registered/camera_info_delta_tov_dropouts':
    {
      field:
        'analyses.eo_camera/forward/depth_registered/camera_info_delta_tov_dropouts',
      type: 'long',
    },
  'analyses.eo_camera/forward/depth_registered/camera_info_delta_tov_max': {
    field:
      'analyses.eo_camera/forward/depth_registered/camera_info_delta_tov_max',
    type: 'float',
  },
  'analyses.eo_camera/forward/depth_registered/camera_info_delta_tov_mean': {
    field:
      'analyses.eo_camera/forward/depth_registered/camera_info_delta_tov_mean',
    type: 'float',
  },
  'analyses.eo_camera/forward/depth_registered/camera_info_delta_tov_median': {
    field:
      'analyses.eo_camera/forward/depth_registered/camera_info_delta_tov_median',
    type: 'float',
  },
  'analyses.eo_camera/forward/depth_registered/camera_info_delta_tov_stdev': {
    field:
      'analyses.eo_camera/forward/depth_registered/camera_info_delta_tov_stdev',
    type: 'float',
  },
  'analyses.eo_camera/forward/depth_registered/camera_info_dt_max': {
    field: 'analyses.eo_camera/forward/depth_registered/camera_info_dt_max',
    type: 'float',
  },
  'analyses.eo_camera/forward/depth_registered/camera_info_dt_mean': {
    field: 'analyses.eo_camera/forward/depth_registered/camera_info_dt_mean',
    type: 'float',
  },
  'analyses.eo_camera/forward/depth_registered/camera_info_dt_median': {
    field: 'analyses.eo_camera/forward/depth_registered/camera_info_dt_median',
    type: 'float',
  },
  'analyses.eo_camera/forward/depth_registered/camera_info_dt_stdev': {
    field: 'analyses.eo_camera/forward/depth_registered/camera_info_dt_stdev',
    type: 'float',
  },
  'analyses.eo_camera/forward/depth_registered/camera_info_latency': {
    field: 'analyses.eo_camera/forward/depth_registered/camera_info_latency',
    type: 'float',
  },
  'analyses.eo_camera/forward/depth_registered/camera_info_latency_max': {
    field:
      'analyses.eo_camera/forward/depth_registered/camera_info_latency_max',
    type: 'float',
  },
  'analyses.eo_camera/forward/depth_registered/camera_info_latency_mean': {
    field:
      'analyses.eo_camera/forward/depth_registered/camera_info_latency_mean',
    type: 'float',
  },
  'analyses.eo_camera/forward/depth_registered/camera_info_latency_median': {
    field:
      'analyses.eo_camera/forward/depth_registered/camera_info_latency_median',
    type: 'float',
  },
  'analyses.eo_camera/forward/depth_registered/camera_info_latency_stdev': {
    field:
      'analyses.eo_camera/forward/depth_registered/camera_info_latency_stdev',
    type: 'float',
  },
  'analyses.eo_camera/forward/depth_registered/camera_info_timing': {
    field: 'analyses.eo_camera/forward/depth_registered/camera_info_timing',
    type: 'float',
  },
  'analyses.eo_camera/left/camera_info_delta_tov_complete_dropout': {
    field: 'analyses.eo_camera/left/camera_info_delta_tov_complete_dropout',
    type: 'float',
  },
  'analyses.eo_camera/left/camera_info_delta_tov_dropouts': {
    field: 'analyses.eo_camera/left/camera_info_delta_tov_dropouts',
    type: 'long',
  },
  'analyses.eo_camera/left/camera_info_delta_tov_max': {
    field: 'analyses.eo_camera/left/camera_info_delta_tov_max',
    type: 'float',
  },
  'analyses.eo_camera/left/camera_info_delta_tov_mean': {
    field: 'analyses.eo_camera/left/camera_info_delta_tov_mean',
    type: 'float',
  },
  'analyses.eo_camera/left/camera_info_delta_tov_median': {
    field: 'analyses.eo_camera/left/camera_info_delta_tov_median',
    type: 'float',
  },
  'analyses.eo_camera/left/camera_info_delta_tov_stdev': {
    field: 'analyses.eo_camera/left/camera_info_delta_tov_stdev',
    type: 'float',
  },
  'analyses.eo_camera/left/camera_info_dt_max': {
    field: 'analyses.eo_camera/left/camera_info_dt_max',
    type: 'float',
  },
  'analyses.eo_camera/left/camera_info_dt_mean': {
    field: 'analyses.eo_camera/left/camera_info_dt_mean',
    type: 'float',
  },
  'analyses.eo_camera/left/camera_info_dt_median': {
    field: 'analyses.eo_camera/left/camera_info_dt_median',
    type: 'float',
  },
  'analyses.eo_camera/left/camera_info_dt_stdev': {
    field: 'analyses.eo_camera/left/camera_info_dt_stdev',
    type: 'float',
  },
  'analyses.eo_camera/left/camera_info_latency': {
    field: 'analyses.eo_camera/left/camera_info_latency',
    type: 'float',
  },
  'analyses.eo_camera/left/camera_info_latency_max': {
    field: 'analyses.eo_camera/left/camera_info_latency_max',
    type: 'float',
  },
  'analyses.eo_camera/left/camera_info_latency_mean': {
    field: 'analyses.eo_camera/left/camera_info_latency_mean',
    type: 'float',
  },
  'analyses.eo_camera/left/camera_info_latency_median': {
    field: 'analyses.eo_camera/left/camera_info_latency_median',
    type: 'float',
  },
  'analyses.eo_camera/left/camera_info_latency_stdev': {
    field: 'analyses.eo_camera/left/camera_info_latency_stdev',
    type: 'float',
  },
  'analyses.eo_camera/left/camera_info_timing': {
    field: 'analyses.eo_camera/left/camera_info_timing',
    type: 'float',
  },
  'analyses.eo_camera/left/depth_registered/camera_info_delta_tov_complete_dropout':
    {
      field:
        'analyses.eo_camera/left/depth_registered/camera_info_delta_tov_complete_dropout',
      type: 'float',
    },
  'analyses.eo_camera/left/depth_registered/camera_info_delta_tov_dropouts': {
    field:
      'analyses.eo_camera/left/depth_registered/camera_info_delta_tov_dropouts',
    type: 'long',
  },
  'analyses.eo_camera/left/depth_registered/camera_info_delta_tov_max': {
    field: 'analyses.eo_camera/left/depth_registered/camera_info_delta_tov_max',
    type: 'float',
  },
  'analyses.eo_camera/left/depth_registered/camera_info_delta_tov_mean': {
    field:
      'analyses.eo_camera/left/depth_registered/camera_info_delta_tov_mean',
    type: 'float',
  },
  'analyses.eo_camera/left/depth_registered/camera_info_delta_tov_median': {
    field:
      'analyses.eo_camera/left/depth_registered/camera_info_delta_tov_median',
    type: 'float',
  },
  'analyses.eo_camera/left/depth_registered/camera_info_delta_tov_stdev': {
    field:
      'analyses.eo_camera/left/depth_registered/camera_info_delta_tov_stdev',
    type: 'float',
  },
  'analyses.eo_camera/left/depth_registered/camera_info_dt_max': {
    field: 'analyses.eo_camera/left/depth_registered/camera_info_dt_max',
    type: 'float',
  },
  'analyses.eo_camera/left/depth_registered/camera_info_dt_mean': {
    field: 'analyses.eo_camera/left/depth_registered/camera_info_dt_mean',
    type: 'float',
  },
  'analyses.eo_camera/left/depth_registered/camera_info_dt_median': {
    field: 'analyses.eo_camera/left/depth_registered/camera_info_dt_median',
    type: 'float',
  },
  'analyses.eo_camera/left/depth_registered/camera_info_dt_stdev': {
    field: 'analyses.eo_camera/left/depth_registered/camera_info_dt_stdev',
    type: 'float',
  },
  'analyses.eo_camera/left/depth_registered/camera_info_latency': {
    field: 'analyses.eo_camera/left/depth_registered/camera_info_latency',
    type: 'float',
  },
  'analyses.eo_camera/left/depth_registered/camera_info_latency_max': {
    field: 'analyses.eo_camera/left/depth_registered/camera_info_latency_max',
    type: 'float',
  },
  'analyses.eo_camera/left/depth_registered/camera_info_latency_mean': {
    field: 'analyses.eo_camera/left/depth_registered/camera_info_latency_mean',
    type: 'float',
  },
  'analyses.eo_camera/left/depth_registered/camera_info_latency_median': {
    field:
      'analyses.eo_camera/left/depth_registered/camera_info_latency_median',
    type: 'float',
  },
  'analyses.eo_camera/left/depth_registered/camera_info_latency_stdev': {
    field: 'analyses.eo_camera/left/depth_registered/camera_info_latency_stdev',
    type: 'float',
  },
  'analyses.eo_camera/left/depth_registered/camera_info_timing': {
    field: 'analyses.eo_camera/left/depth_registered/camera_info_timing',
    type: 'float',
  },
  'analyses.eo_camera/right/camera_info_delta_tov_complete_dropout': {
    field: 'analyses.eo_camera/right/camera_info_delta_tov_complete_dropout',
    type: 'float',
  },
  'analyses.eo_camera/right/camera_info_delta_tov_dropouts': {
    field: 'analyses.eo_camera/right/camera_info_delta_tov_dropouts',
    type: 'long',
  },
  'analyses.eo_camera/right/camera_info_delta_tov_max': {
    field: 'analyses.eo_camera/right/camera_info_delta_tov_max',
    type: 'float',
  },
  'analyses.eo_camera/right/camera_info_delta_tov_mean': {
    field: 'analyses.eo_camera/right/camera_info_delta_tov_mean',
    type: 'float',
  },
  'analyses.eo_camera/right/camera_info_delta_tov_median': {
    field: 'analyses.eo_camera/right/camera_info_delta_tov_median',
    type: 'float',
  },
  'analyses.eo_camera/right/camera_info_delta_tov_stdev': {
    field: 'analyses.eo_camera/right/camera_info_delta_tov_stdev',
    type: 'float',
  },
  'analyses.eo_camera/right/camera_info_dt_max': {
    field: 'analyses.eo_camera/right/camera_info_dt_max',
    type: 'float',
  },
  'analyses.eo_camera/right/camera_info_dt_mean': {
    field: 'analyses.eo_camera/right/camera_info_dt_mean',
    type: 'float',
  },
  'analyses.eo_camera/right/camera_info_dt_median': {
    field: 'analyses.eo_camera/right/camera_info_dt_median',
    type: 'float',
  },
  'analyses.eo_camera/right/camera_info_dt_stdev': {
    field: 'analyses.eo_camera/right/camera_info_dt_stdev',
    type: 'float',
  },
  'analyses.eo_camera/right/camera_info_latency': {
    field: 'analyses.eo_camera/right/camera_info_latency',
    type: 'float',
  },
  'analyses.eo_camera/right/camera_info_latency_max': {
    field: 'analyses.eo_camera/right/camera_info_latency_max',
    type: 'float',
  },
  'analyses.eo_camera/right/camera_info_latency_mean': {
    field: 'analyses.eo_camera/right/camera_info_latency_mean',
    type: 'float',
  },
  'analyses.eo_camera/right/camera_info_latency_median': {
    field: 'analyses.eo_camera/right/camera_info_latency_median',
    type: 'float',
  },
  'analyses.eo_camera/right/camera_info_latency_stdev': {
    field: 'analyses.eo_camera/right/camera_info_latency_stdev',
    type: 'float',
  },
  'analyses.eo_camera/right/camera_info_timing': {
    field: 'analyses.eo_camera/right/camera_info_timing',
    type: 'float',
  },
  'analyses.eo_camera/right/depth_registered/camera_info_delta_tov_complete_dropout':
    {
      field:
        'analyses.eo_camera/right/depth_registered/camera_info_delta_tov_complete_dropout',
      type: 'float',
    },
  'analyses.eo_camera/right/depth_registered/camera_info_delta_tov_dropouts': {
    field:
      'analyses.eo_camera/right/depth_registered/camera_info_delta_tov_dropouts',
    type: 'long',
  },
  'analyses.eo_camera/right/depth_registered/camera_info_delta_tov_max': {
    field:
      'analyses.eo_camera/right/depth_registered/camera_info_delta_tov_max',
    type: 'float',
  },
  'analyses.eo_camera/right/depth_registered/camera_info_delta_tov_mean': {
    field:
      'analyses.eo_camera/right/depth_registered/camera_info_delta_tov_mean',
    type: 'float',
  },
  'analyses.eo_camera/right/depth_registered/camera_info_delta_tov_median': {
    field:
      'analyses.eo_camera/right/depth_registered/camera_info_delta_tov_median',
    type: 'float',
  },
  'analyses.eo_camera/right/depth_registered/camera_info_delta_tov_stdev': {
    field:
      'analyses.eo_camera/right/depth_registered/camera_info_delta_tov_stdev',
    type: 'float',
  },
  'analyses.eo_camera/right/depth_registered/camera_info_dt_max': {
    field: 'analyses.eo_camera/right/depth_registered/camera_info_dt_max',
    type: 'float',
  },
  'analyses.eo_camera/right/depth_registered/camera_info_dt_mean': {
    field: 'analyses.eo_camera/right/depth_registered/camera_info_dt_mean',
    type: 'float',
  },
  'analyses.eo_camera/right/depth_registered/camera_info_dt_median': {
    field: 'analyses.eo_camera/right/depth_registered/camera_info_dt_median',
    type: 'float',
  },
  'analyses.eo_camera/right/depth_registered/camera_info_dt_stdev': {
    field: 'analyses.eo_camera/right/depth_registered/camera_info_dt_stdev',
    type: 'float',
  },
  'analyses.eo_camera/right/depth_registered/camera_info_latency': {
    field: 'analyses.eo_camera/right/depth_registered/camera_info_latency',
    type: 'float',
  },
  'analyses.eo_camera/right/depth_registered/camera_info_latency_max': {
    field: 'analyses.eo_camera/right/depth_registered/camera_info_latency_max',
    type: 'float',
  },
  'analyses.eo_camera/right/depth_registered/camera_info_latency_mean': {
    field: 'analyses.eo_camera/right/depth_registered/camera_info_latency_mean',
    type: 'float',
  },
  'analyses.eo_camera/right/depth_registered/camera_info_latency_median': {
    field:
      'analyses.eo_camera/right/depth_registered/camera_info_latency_median',
    type: 'float',
  },
  'analyses.eo_camera/right/depth_registered/camera_info_latency_stdev': {
    field:
      'analyses.eo_camera/right/depth_registered/camera_info_latency_stdev',
    type: 'float',
  },
  'analyses.eo_camera/right/depth_registered/camera_info_timing': {
    field: 'analyses.eo_camera/right/depth_registered/camera_info_timing',
    type: 'float',
  },
  'analyses.eo_camera_bottom_extrinsics_rotation_pitch_reference_diff': {
    field: 'analyses.eo_camera_bottom_extrinsics_rotation_pitch_reference_diff',
    type: 'float',
  },
  'analyses.eo_camera_bottom_extrinsics_rotation_roll_reference_diff': {
    field: 'analyses.eo_camera_bottom_extrinsics_rotation_roll_reference_diff',
    type: 'float',
  },
  'analyses.eo_camera_bottom_extrinsics_rotation_yaw_reference_diff': {
    field: 'analyses.eo_camera_bottom_extrinsics_rotation_yaw_reference_diff',
    type: 'float',
  },
  'analyses.eo_camera_bottom_extrinsics_translation_x_reference_diff': {
    field: 'analyses.eo_camera_bottom_extrinsics_translation_x_reference_diff',
    type: 'float',
  },
  'analyses.eo_camera_bottom_extrinsics_translation_y_reference_diff': {
    field: 'analyses.eo_camera_bottom_extrinsics_translation_y_reference_diff',
    type: 'float',
  },
  'analyses.eo_camera_bottom_extrinsics_translation_z_reference_diff': {
    field: 'analyses.eo_camera_bottom_extrinsics_translation_z_reference_diff',
    type: 'float',
  },
  'analyses.eo_camera_forward_extrinsics_rotation_pitch_reference_diff': {
    field:
      'analyses.eo_camera_forward_extrinsics_rotation_pitch_reference_diff',
    type: 'float',
  },
  'analyses.eo_camera_forward_extrinsics_rotation_roll_reference_diff': {
    field: 'analyses.eo_camera_forward_extrinsics_rotation_roll_reference_diff',
    type: 'float',
  },
  'analyses.eo_camera_forward_extrinsics_rotation_yaw_reference_diff': {
    field: 'analyses.eo_camera_forward_extrinsics_rotation_yaw_reference_diff',
    type: 'float',
  },
  'analyses.eo_camera_forward_extrinsics_translation_x_reference_diff': {
    field: 'analyses.eo_camera_forward_extrinsics_translation_x_reference_diff',
    type: 'float',
  },
  'analyses.eo_camera_forward_extrinsics_translation_y_reference_diff': {
    field: 'analyses.eo_camera_forward_extrinsics_translation_y_reference_diff',
    type: 'float',
  },
  'analyses.eo_camera_forward_extrinsics_translation_z_reference_diff': {
    field: 'analyses.eo_camera_forward_extrinsics_translation_z_reference_diff',
    type: 'float',
  },
  'analyses.eo_camera_left_extrinsics_rotation_pitch_reference_diff': {
    field: 'analyses.eo_camera_left_extrinsics_rotation_pitch_reference_diff',
    type: 'float',
  },
  'analyses.eo_camera_left_extrinsics_rotation_roll_reference_diff': {
    field: 'analyses.eo_camera_left_extrinsics_rotation_roll_reference_diff',
    type: 'float',
  },
  'analyses.eo_camera_left_extrinsics_rotation_yaw_reference_diff': {
    field: 'analyses.eo_camera_left_extrinsics_rotation_yaw_reference_diff',
    type: 'float',
  },
  'analyses.eo_camera_left_extrinsics_translation_x_reference_diff': {
    field: 'analyses.eo_camera_left_extrinsics_translation_x_reference_diff',
    type: 'float',
  },
  'analyses.eo_camera_left_extrinsics_translation_y_reference_diff': {
    field: 'analyses.eo_camera_left_extrinsics_translation_y_reference_diff',
    type: 'float',
  },
  'analyses.eo_camera_left_extrinsics_translation_z_reference_diff': {
    field: 'analyses.eo_camera_left_extrinsics_translation_z_reference_diff',
    type: 'float',
  },
  'analyses.eo_camera_right_extrinsics_rotation_pitch_reference_diff': {
    field: 'analyses.eo_camera_right_extrinsics_rotation_pitch_reference_diff',
    type: 'float',
  },
  'analyses.eo_camera_right_extrinsics_rotation_roll_reference_diff': {
    field: 'analyses.eo_camera_right_extrinsics_rotation_roll_reference_diff',
    type: 'float',
  },
  'analyses.eo_camera_right_extrinsics_rotation_yaw_reference_diff': {
    field: 'analyses.eo_camera_right_extrinsics_rotation_yaw_reference_diff',
    type: 'float',
  },
  'analyses.eo_camera_right_extrinsics_translation_x_reference_diff': {
    field: 'analyses.eo_camera_right_extrinsics_translation_x_reference_diff',
    type: 'float',
  },
  'analyses.eo_camera_right_extrinsics_translation_y_reference_diff': {
    field: 'analyses.eo_camera_right_extrinsics_translation_y_reference_diff',
    type: 'float',
  },
  'analyses.eo_camera_right_extrinsics_translation_z_reference_diff': {
    field: 'analyses.eo_camera_right_extrinsics_translation_z_reference_diff',
    type: 'float',
  },
  'analyses.eo_forward_extrinsics_rotation_pitch_reference_diff': {
    field: 'analyses.eo_forward_extrinsics_rotation_pitch_reference_diff',
    type: 'float',
  },
  'analyses.eo_forward_extrinsics_rotation_roll_reference_diff': {
    field: 'analyses.eo_forward_extrinsics_rotation_roll_reference_diff',
    type: 'float',
  },
  'analyses.eo_forward_extrinsics_rotation_yaw_reference_diff': {
    field: 'analyses.eo_forward_extrinsics_rotation_yaw_reference_diff',
    type: 'float',
  },
  'analyses.eo_forward_extrinsics_translation_x_reference_diff': {
    field: 'analyses.eo_forward_extrinsics_translation_x_reference_diff',
    type: 'float',
  },
  'analyses.eo_forward_extrinsics_translation_y_reference_diff': {
    field: 'analyses.eo_forward_extrinsics_translation_y_reference_diff',
    type: 'float',
  },
  'analyses.eo_forward_extrinsics_translation_z_reference_diff': {
    field: 'analyses.eo_forward_extrinsics_translation_z_reference_diff',
    type: 'float',
  },
  'analyses.eo_left_extrinsics_rotation_pitch_reference_diff': {
    field: 'analyses.eo_left_extrinsics_rotation_pitch_reference_diff',
    type: 'float',
  },
  'analyses.eo_left_extrinsics_rotation_roll_reference_diff': {
    field: 'analyses.eo_left_extrinsics_rotation_roll_reference_diff',
    type: 'float',
  },
  'analyses.eo_left_extrinsics_rotation_yaw_reference_diff': {
    field: 'analyses.eo_left_extrinsics_rotation_yaw_reference_diff',
    type: 'float',
  },
  'analyses.eo_left_extrinsics_translation_x_reference_diff': {
    field: 'analyses.eo_left_extrinsics_translation_x_reference_diff',
    type: 'float',
  },
  'analyses.eo_left_extrinsics_translation_y_reference_diff': {
    field: 'analyses.eo_left_extrinsics_translation_y_reference_diff',
    type: 'float',
  },
  'analyses.eo_left_extrinsics_translation_z_reference_diff': {
    field: 'analyses.eo_left_extrinsics_translation_z_reference_diff',
    type: 'float',
  },
  'analyses.eo_right_extrinsics_rotation_pitch_reference_diff': {
    field: 'analyses.eo_right_extrinsics_rotation_pitch_reference_diff',
    type: 'float',
  },
  'analyses.eo_right_extrinsics_rotation_roll_reference_diff': {
    field: 'analyses.eo_right_extrinsics_rotation_roll_reference_diff',
    type: 'float',
  },
  'analyses.eo_right_extrinsics_rotation_yaw_reference_diff': {
    field: 'analyses.eo_right_extrinsics_rotation_yaw_reference_diff',
    type: 'float',
  },
  'analyses.eo_right_extrinsics_translation_x_reference_diff': {
    field: 'analyses.eo_right_extrinsics_translation_x_reference_diff',
    type: 'float',
  },
  'analyses.eo_right_extrinsics_translation_y_reference_diff': {
    field: 'analyses.eo_right_extrinsics_translation_y_reference_diff',
    type: 'float',
  },
  'analyses.eo_right_extrinsics_translation_z_reference_diff': {
    field: 'analyses.eo_right_extrinsics_translation_z_reference_diff',
    type: 'float',
  },
  'analyses.esc_analysis_allowed_dropouts': {
    field: 'analyses.esc_analysis_allowed_dropouts',
    type: 'long',
  },
  'analyses.esc_avg_power_usage_threshold_total': {
    field: 'analyses.esc_avg_power_usage_threshold_total',
    type: 'float',
  },
  'analyses.expected_excess_capacity': {
    field: 'analyses.expected_excess_capacity',
    type: 'long',
  },
  'analyses.expected_excess_flight_time_battery_pct_threshold': {
    field: 'analyses.expected_excess_flight_time_battery_pct_threshold',
    type: 'float',
  },
  'analyses.expected_excess_flight_time_voltage_threshold': {
    field: 'analyses.expected_excess_flight_time_voltage_threshold',
    type: 'float',
  },
  'analyses.expected_flight_time_battery_pct_threshold': {
    field: 'analyses.expected_flight_time_battery_pct_threshold',
    type: 'float',
  },
  'analyses.expected_flight_time_threshold': {
    field: 'analyses.expected_flight_time_threshold',
    type: 'float',
  },
  'analyses.expected_flight_time_voltage_threshold': {
    field: 'analyses.expected_flight_time_voltage_threshold',
    type: 'float',
  },
  'analyses.expected_infiller_latency': {
    field: 'analyses.expected_infiller_latency',
    type: 'float',
  },
  'analyses.expected_infiller_rate': {
    field: 'analyses.expected_infiller_rate',
    type: 'float',
  },
  'analyses.expected_infiller_up_latency': {
    field: 'analyses.expected_infiller_up_latency',
    type: 'float',
  },
  'analyses.expected_infiller_up_rate': {
    field: 'analyses.expected_infiller_up_rate',
    type: 'float',
  },
  'analyses.expected_phantom_object_filter_up_latency': {
    field: 'analyses.expected_phantom_object_filter_up_latency',
    type: 'float',
  },
  'analyses.exploration_rate': {
    field: 'analyses.exploration_rate',
    type: 'float',
  },
  'analyses.exploration_time': {
    field: 'analyses.exploration_time',
    type: 'float',
  },
  'analyses.exploration_volume': {
    field: 'analyses.exploration_volume',
    type: 'float',
  },
  'analyses.feature_tracker_max_consecutive_nans': {
    field: 'analyses.feature_tracker_max_consecutive_nans',
    type: 'long',
  },
  'analyses.feature_tracker_proportion_check_max_nan_proportion': {
    field: 'analyses.feature_tracker_proportion_check_max_nan_proportion',
    type: 'float',
  },
  'analyses.feature_tracker_status_check_accepted_rejected_ratio_bottom': {
    field:
      'analyses.feature_tracker_status_check_accepted_rejected_ratio_bottom',
    type: 'float',
  },
  'analyses.feature_tracker_status_check_accepted_rejected_ratio_forward': {
    field:
      'analyses.feature_tracker_status_check_accepted_rejected_ratio_forward',
    type: 'float',
  },
  'analyses.feature_tracker_status_check_accepted_rejected_ratio_left': {
    field: 'analyses.feature_tracker_status_check_accepted_rejected_ratio_left',
    type: 'long',
  },
  'analyses.feature_tracker_status_check_accepted_rejected_ratio_right': {
    field:
      'analyses.feature_tracker_status_check_accepted_rejected_ratio_right',
    type: 'float',
  },
  'analyses.feature_tracker_status_check_max_allowed_gammas_nans': {
    field: 'analyses.feature_tracker_status_check_max_allowed_gammas_nans',
    type: 'long',
  },
  'analyses.feature_tracker_status_check_max_allowed_res_nans': {
    field: 'analyses.feature_tracker_status_check_max_allowed_res_nans',
    type: 'long',
  },
  'analyses.flyaway_dist_limits_max': {
    field: 'analyses.flyaway_dist_limits_max',
    type: 'float',
  },
  'analyses.forward__max_dip_length': {
    field: 'analyses.forward__max_dip_length',
    type: 'long',
  },
  'analyses.forward__mean_dip_length': {
    field: 'analyses.forward__mean_dip_length',
    type: 'long',
  },
  'analyses.forward__mean_fill_rate': {
    field: 'analyses.forward__mean_fill_rate',
    type: 'float',
  },
  'analyses.forward__min_fill_rate': {
    field: 'analyses.forward__min_fill_rate',
    type: 'float',
  },
  'analyses.forward__num_dips': {
    field: 'analyses.forward__num_dips',
    type: 'long',
  },
  'analyses.forward_bright_invalid_consecutive_frames_threshold': {
    field: 'analyses.forward_bright_invalid_consecutive_frames_threshold',
    type: 'long',
  },
  'analyses.forward_down__max_dip_length': {
    field: 'analyses.forward_down__max_dip_length',
    type: 'long',
  },
  'analyses.forward_down__mean_dip_length': {
    field: 'analyses.forward_down__mean_dip_length',
    type: 'long',
  },
  'analyses.forward_down__mean_fill_rate': {
    field: 'analyses.forward_down__mean_fill_rate',
    type: 'float',
  },
  'analyses.forward_down__min_fill_rate': {
    field: 'analyses.forward_down__min_fill_rate',
    type: 'float',
  },
  'analyses.forward_down__num_dips': {
    field: 'analyses.forward_down__num_dips',
    type: 'long',
  },
  'analyses.forward_max_fill_percent_threshold': {
    field: 'analyses.forward_max_fill_percent_threshold',
    type: 'float',
  },
  'analyses.forward_mean_fill_percent_threshold': {
    field: 'analyses.forward_mean_fill_percent_threshold',
    type: 'float',
  },
  'analyses.forward_min_fill_percent_threshold': {
    field: 'analyses.forward_min_fill_percent_threshold',
    type: 'float',
  },
  'analyses.forward_num_frames_low_exposure_threshold': {
    field: 'analyses.forward_num_frames_low_exposure_threshold',
    type: 'long',
  },
  'analyses.forward_num_frames_very_low_exposure_threshold': {
    field: 'analyses.forward_num_frames_very_low_exposure_threshold',
    type: 'long',
  },
  'analyses.forward_up__max_dip_length': {
    field: 'analyses.forward_up__max_dip_length',
    type: 'long',
  },
  'analyses.forward_up__mean_dip_length': {
    field: 'analyses.forward_up__mean_dip_length',
    type: 'long',
  },
  'analyses.forward_up__mean_fill_rate': {
    field: 'analyses.forward_up__mean_fill_rate',
    type: 'float',
  },
  'analyses.forward_up__min_fill_rate': {
    field: 'analyses.forward_up__min_fill_rate',
    type: 'float',
  },
  'analyses.forward_up__num_dips': {
    field: 'analyses.forward_up__num_dips',
    type: 'long',
  },
  'analyses.global_estimate_ATE_max_allowable_mean_ATE': {
    field: 'analyses.global_estimate_ATE_max_allowable_mean_ATE',
    type: 'float',
  },
  'analyses.global_estimate_ATE_max_allowable_quantile_ATE': {
    field: 'analyses.global_estimate_ATE_max_allowable_quantile_ATE',
    type: 'float',
  },
  'analyses.global_map_accuracy_occupied_voxels_threshold': {
    field: 'analyses.global_map_accuracy_occupied_voxels_threshold',
    type: 'float',
  },
  'analyses.global_map_messages_avg_latency_threshold': {
    field: 'analyses.global_map_messages_avg_latency_threshold',
    type: 'float',
  },
  'analyses.global_map_messages_max_latency_threshold': {
    field: 'analyses.global_map_messages_max_latency_threshold',
    type: 'float',
  },
  'analyses.global_map_percentage_voxel_state_fluctuations_threshold': {
    field: 'analyses.global_map_percentage_voxel_state_fluctuations_threshold',
    type: 'float',
  },
  'analyses.global_map_sensor2message_avg_latency_threshold': {
    field: 'analyses.global_map_sensor2message_avg_latency_threshold',
    type: 'float',
  },
  'analyses.global_map_sensor2message_max_latency_threshold': {
    field: 'analyses.global_map_sensor2message_max_latency_threshold',
    type: 'float',
  },
  'analyses.global_map_thread_run_cpu_threshold': {
    field: 'analyses.global_map_thread_run_cpu_threshold',
    type: 'float',
  },
  'analyses.global_map_thread_run_threshold': {
    field: 'analyses.global_map_thread_run_threshold',
    type: 'float',
  },
  'analyses.global_map_thread_run_wall_threshold': {
    field: 'analyses.global_map_thread_run_wall_threshold',
    type: 'float',
  },
  'analyses.gps/position_latency': {
    field: 'analyses.gps/position_latency',
    type: 'float',
  },
  'analyses.gps/position_latency_max': {
    field: 'analyses.gps/position_latency_max',
    type: 'float',
  },
  'analyses.gps/position_latency_mean': {
    field: 'analyses.gps/position_latency_mean',
    type: 'float',
  },
  'analyses.gps/position_latency_median': {
    field: 'analyses.gps/position_latency_median',
    type: 'float',
  },
  'analyses.gps/position_latency_stdev': {
    field: 'analyses.gps/position_latency_stdev',
    type: 'float',
  },
  'analyses.gps_msckf_discrepancy_max_invalid_frac': {
    field: 'analyses.gps_msckf_discrepancy_max_invalid_frac',
    type: 'float',
  },
  'analyses.gpu_latency_kernel_FeatureTracker1_avg': {
    field: 'analyses.gpu_latency_kernel_FeatureTracker1_avg',
    type: 'float',
  },
  'analyses.gpu_latency_kernel_FeatureTracker1_max': {
    field: 'analyses.gpu_latency_kernel_FeatureTracker1_max',
    type: 'float',
  },
  'analyses.gpu_latency_kernel_FeatureTracker2_avg': {
    field: 'analyses.gpu_latency_kernel_FeatureTracker2_avg',
    type: 'float',
  },
  'analyses.gpu_latency_kernel_FeatureTracker2_max': {
    field: 'analyses.gpu_latency_kernel_FeatureTracker2_max',
    type: 'float',
  },
  'analyses.gpu_latency_kernel_FeatureTracker3_avg': {
    field: 'analyses.gpu_latency_kernel_FeatureTracker3_avg',
    type: 'float',
  },
  'analyses.gpu_latency_kernel_FeatureTracker3_max': {
    field: 'analyses.gpu_latency_kernel_FeatureTracker3_max',
    type: 'float',
  },
  'analyses.gpu_latency_kernel_all_avg': {
    field: 'analyses.gpu_latency_kernel_all_avg',
    type: 'float',
  },
  'analyses.gpu_latency_kernel_all_max': {
    field: 'analyses.gpu_latency_kernel_all_max',
    type: 'float',
  },
  'analyses.gpu_latency_kernel_avg': {
    field: 'analyses.gpu_latency_kernel_avg',
    type: 'float',
  },
  'analyses.gpu_latency_kernel_aviate_avg': {
    field: 'analyses.gpu_latency_kernel_aviate_avg',
    type: 'float',
  },
  'analyses.gpu_latency_kernel_aviate_max': {
    field: 'analyses.gpu_latency_kernel_aviate_max',
    type: 'float',
  },
  'analyses.gpu_latency_kernel_debayer_avg': {
    field: 'analyses.gpu_latency_kernel_debayer_avg',
    type: 'float',
  },
  'analyses.gpu_latency_kernel_debayer_max': {
    field: 'analyses.gpu_latency_kernel_debayer_max',
    type: 'float',
  },
  'analyses.gpu_latency_kernel_dt_gpu_work_avg': {
    field: 'analyses.gpu_latency_kernel_dt_gpu_work_avg',
    type: 'float',
  },
  'analyses.gpu_latency_kernel_dt_gpu_work_max': {
    field: 'analyses.gpu_latency_kernel_dt_gpu_work_max',
    type: 'float',
  },
  'analyses.gpu_latency_kernel_global_map_run_avg': {
    field: 'analyses.gpu_latency_kernel_global_map_run_avg',
    type: 'float',
  },
  'analyses.gpu_latency_kernel_global_map_run_max': {
    field: 'analyses.gpu_latency_kernel_global_map_run_max',
    type: 'float',
  },
  'analyses.gpu_latency_kernel_local_map_run_avg': {
    field: 'analyses.gpu_latency_kernel_local_map_run_avg',
    type: 'float',
  },
  'analyses.gpu_latency_kernel_local_map_run_max': {
    field: 'analyses.gpu_latency_kernel_local_map_run_max',
    type: 'float',
  },
  'analyses.gpu_latency_kernel_local_planner_avg': {
    field: 'analyses.gpu_latency_kernel_local_planner_avg',
    type: 'float',
  },
  'analyses.gpu_latency_kernel_local_planner_max': {
    field: 'analyses.gpu_latency_kernel_local_planner_max',
    type: 'float',
  },
  'analyses.gpu_latency_kernel_max': {
    field: 'analyses.gpu_latency_kernel_max',
    type: 'float',
  },
  'analyses.gpu_latency_kernel_rectify_color_avg': {
    field: 'analyses.gpu_latency_kernel_rectify_color_avg',
    type: 'float',
  },
  'analyses.gpu_latency_kernel_rectify_color_max': {
    field: 'analyses.gpu_latency_kernel_rectify_color_max',
    type: 'float',
  },
  'analyses.gpu_latency_kernel_rectify_mono_avg': {
    field: 'analyses.gpu_latency_kernel_rectify_mono_avg',
    type: 'float',
  },
  'analyses.gpu_latency_kernel_rectify_mono_max': {
    field: 'analyses.gpu_latency_kernel_rectify_mono_max',
    type: 'float',
  },
  'analyses.gpu_latency_kernel_register_avg': {
    field: 'analyses.gpu_latency_kernel_register_avg',
    type: 'float',
  },
  'analyses.gpu_latency_kernel_register_max': {
    field: 'analyses.gpu_latency_kernel_register_max',
    type: 'float',
  },
  'analyses.gpu_latency_launch_FeatureTracker1_avg': {
    field: 'analyses.gpu_latency_launch_FeatureTracker1_avg',
    type: 'float',
  },
  'analyses.gpu_latency_launch_FeatureTracker1_max': {
    field: 'analyses.gpu_latency_launch_FeatureTracker1_max',
    type: 'float',
  },
  'analyses.gpu_latency_launch_FeatureTracker2_avg': {
    field: 'analyses.gpu_latency_launch_FeatureTracker2_avg',
    type: 'float',
  },
  'analyses.gpu_latency_launch_FeatureTracker2_max': {
    field: 'analyses.gpu_latency_launch_FeatureTracker2_max',
    type: 'float',
  },
  'analyses.gpu_latency_launch_FeatureTracker3_avg': {
    field: 'analyses.gpu_latency_launch_FeatureTracker3_avg',
    type: 'float',
  },
  'analyses.gpu_latency_launch_FeatureTracker3_max': {
    field: 'analyses.gpu_latency_launch_FeatureTracker3_max',
    type: 'float',
  },
  'analyses.gpu_latency_launch_all_avg': {
    field: 'analyses.gpu_latency_launch_all_avg',
    type: 'float',
  },
  'analyses.gpu_latency_launch_all_max': {
    field: 'analyses.gpu_latency_launch_all_max',
    type: 'float',
  },
  'analyses.gpu_latency_launch_avg': {
    field: 'analyses.gpu_latency_launch_avg',
    type: 'float',
  },
  'analyses.gpu_latency_launch_aviate_avg': {
    field: 'analyses.gpu_latency_launch_aviate_avg',
    type: 'float',
  },
  'analyses.gpu_latency_launch_aviate_max': {
    field: 'analyses.gpu_latency_launch_aviate_max',
    type: 'float',
  },
  'analyses.gpu_latency_launch_debayer_avg': {
    field: 'analyses.gpu_latency_launch_debayer_avg',
    type: 'float',
  },
  'analyses.gpu_latency_launch_debayer_max': {
    field: 'analyses.gpu_latency_launch_debayer_max',
    type: 'float',
  },
  'analyses.gpu_latency_launch_dt_gpu_work_avg': {
    field: 'analyses.gpu_latency_launch_dt_gpu_work_avg',
    type: 'float',
  },
  'analyses.gpu_latency_launch_dt_gpu_work_max': {
    field: 'analyses.gpu_latency_launch_dt_gpu_work_max',
    type: 'float',
  },
  'analyses.gpu_latency_launch_global_map_run_avg': {
    field: 'analyses.gpu_latency_launch_global_map_run_avg',
    type: 'float',
  },
  'analyses.gpu_latency_launch_global_map_run_max': {
    field: 'analyses.gpu_latency_launch_global_map_run_max',
    type: 'float',
  },
  'analyses.gpu_latency_launch_local_map_run_avg': {
    field: 'analyses.gpu_latency_launch_local_map_run_avg',
    type: 'float',
  },
  'analyses.gpu_latency_launch_local_map_run_max': {
    field: 'analyses.gpu_latency_launch_local_map_run_max',
    type: 'float',
  },
  'analyses.gpu_latency_launch_local_planner_avg': {
    field: 'analyses.gpu_latency_launch_local_planner_avg',
    type: 'float',
  },
  'analyses.gpu_latency_launch_local_planner_max': {
    field: 'analyses.gpu_latency_launch_local_planner_max',
    type: 'float',
  },
  'analyses.gpu_latency_launch_max': {
    field: 'analyses.gpu_latency_launch_max',
    type: 'float',
  },
  'analyses.gpu_latency_launch_rectify_color_avg': {
    field: 'analyses.gpu_latency_launch_rectify_color_avg',
    type: 'float',
  },
  'analyses.gpu_latency_launch_rectify_color_max': {
    field: 'analyses.gpu_latency_launch_rectify_color_max',
    type: 'float',
  },
  'analyses.gpu_latency_launch_rectify_mono_avg': {
    field: 'analyses.gpu_latency_launch_rectify_mono_avg',
    type: 'float',
  },
  'analyses.gpu_latency_launch_rectify_mono_max': {
    field: 'analyses.gpu_latency_launch_rectify_mono_max',
    type: 'float',
  },
  'analyses.gpu_latency_launch_register_avg': {
    field: 'analyses.gpu_latency_launch_register_avg',
    type: 'float',
  },
  'analyses.gpu_latency_launch_register_max': {
    field: 'analyses.gpu_latency_launch_register_max',
    type: 'float',
  },
  'analyses.gpu_limits_max_usage': {
    field: 'analyses.gpu_limits_max_usage',
    type: 'float',
  },
  'analyses.gpu_usage_threshold_avg_load': {
    field: 'analyses.gpu_usage_threshold_avg_load',
    type: 'float',
  },
  'analyses.gpu_usage_threshold_max_load': {
    field: 'analyses.gpu_usage_threshold_max_load',
    type: 'float',
  },
  'analyses.hover_stability_angular_velocity_max_element_rms': {
    field: 'analyses.hover_stability_angular_velocity_max_element_rms',
    type: 'float',
  },
  'analyses.hover_stability_angular_velocity_max_magnitude': {
    field: 'analyses.hover_stability_angular_velocity_max_magnitude',
    type: 'float',
  },
  'analyses.hover_stability_position_max_dist_from_mean': {
    field: 'analyses.hover_stability_position_max_dist_from_mean',
    type: 'float',
  },
  'analyses.hover_stability_position_max_element_std': {
    field: 'analyses.hover_stability_position_max_element_std',
    type: 'float',
  },
  'analyses.hover_stability_velocity_max_element_rms': {
    field: 'analyses.hover_stability_velocity_max_element_rms',
    type: 'float',
  },
  'analyses.hover_stability_velocity_max_magnitude': {
    field: 'analyses.hover_stability_velocity_max_magnitude',
    type: 'float',
  },
  'analyses.imu/data_raw_delta_tov_complete_dropout': {
    field: 'analyses.imu/data_raw_delta_tov_complete_dropout',
    type: 'float',
  },
  'analyses.imu/data_raw_delta_tov_dropouts': {
    field: 'analyses.imu/data_raw_delta_tov_dropouts',
    type: 'long',
  },
  'analyses.imu/data_raw_delta_tov_max': {
    field: 'analyses.imu/data_raw_delta_tov_max',
    type: 'float',
  },
  'analyses.imu/data_raw_delta_tov_mean': {
    field: 'analyses.imu/data_raw_delta_tov_mean',
    type: 'float',
  },
  'analyses.imu/data_raw_delta_tov_median': {
    field: 'analyses.imu/data_raw_delta_tov_median',
    type: 'float',
  },
  'analyses.imu/data_raw_delta_tov_stdev': {
    field: 'analyses.imu/data_raw_delta_tov_stdev',
    type: 'float',
  },
  'analyses.imu/data_raw_dropouts': {
    field: 'analyses.imu/data_raw_dropouts',
    type: 'long',
  },
  'analyses.imu/data_raw_latency': {
    field: 'analyses.imu/data_raw_latency',
    type: 'float',
  },
  'analyses.imu/data_raw_latency_max': {
    field: 'analyses.imu/data_raw_latency_max',
    type: 'float',
  },
  'analyses.imu/data_raw_latency_mean': {
    field: 'analyses.imu/data_raw_latency_mean',
    type: 'float',
  },
  'analyses.imu/data_raw_latency_median': {
    field: 'analyses.imu/data_raw_latency_median',
    type: 'float',
  },
  'analyses.imu/data_raw_latency_stdev': {
    field: 'analyses.imu/data_raw_latency_stdev',
    type: 'float',
  },
  'analyses.imu/data_raw_mean': {
    field: 'analyses.imu/data_raw_mean',
    type: 'float',
  },
  'analyses.imu/data_raw_stdev': {
    field: 'analyses.imu/data_raw_stdev',
    type: 'float',
  },
  'analyses.imu/data_raw_timing': {
    field: 'analyses.imu/data_raw_timing',
    type: 'float',
  },
  'analyses.imu_vibration_accel_mean_rms_combined': {
    field: 'analyses.imu_vibration_accel_mean_rms_combined',
    type: 'float',
  },
  'analyses.imu_vibration_accel_mean_rms_x': {
    field: 'analyses.imu_vibration_accel_mean_rms_x',
    type: 'float',
  },
  'analyses.imu_vibration_accel_mean_rms_y': {
    field: 'analyses.imu_vibration_accel_mean_rms_y',
    type: 'float',
  },
  'analyses.imu_vibration_accel_mean_rms_z': {
    field: 'analyses.imu_vibration_accel_mean_rms_z',
    type: 'float',
  },
  'analyses.imu_vibration_angular_rate_mean_rms_combined': {
    field: 'analyses.imu_vibration_angular_rate_mean_rms_combined',
    type: 'float',
  },
  'analyses.imu_vibration_angular_rate_mean_rms_x': {
    field: 'analyses.imu_vibration_angular_rate_mean_rms_x',
    type: 'float',
  },
  'analyses.imu_vibration_angular_rate_mean_rms_y': {
    field: 'analyses.imu_vibration_angular_rate_mean_rms_y',
    type: 'float',
  },
  'analyses.imu_vibration_angular_rate_mean_rms_z': {
    field: 'analyses.imu_vibration_angular_rate_mean_rms_z',
    type: 'float',
  },
  'analyses.imu_vibration_esc_ramp_accel_high_rpm_mean_rms_combined': {
    field: 'analyses.imu_vibration_esc_ramp_accel_high_rpm_mean_rms_combined',
    type: 'float',
  },
  'analyses.imu_vibration_esc_ramp_accel_high_rpm_mean_rms_x': {
    field: 'analyses.imu_vibration_esc_ramp_accel_high_rpm_mean_rms_x',
    type: 'float',
  },
  'analyses.imu_vibration_esc_ramp_accel_high_rpm_mean_rms_y': {
    field: 'analyses.imu_vibration_esc_ramp_accel_high_rpm_mean_rms_y',
    type: 'float',
  },
  'analyses.imu_vibration_esc_ramp_accel_high_rpm_mean_rms_z': {
    field: 'analyses.imu_vibration_esc_ramp_accel_high_rpm_mean_rms_z',
    type: 'float',
  },
  'analyses.imu_vibration_esc_ramp_accel_low_rpm_mean_rms_combined': {
    field: 'analyses.imu_vibration_esc_ramp_accel_low_rpm_mean_rms_combined',
    type: 'float',
  },
  'analyses.imu_vibration_esc_ramp_accel_low_rpm_mean_rms_x': {
    field: 'analyses.imu_vibration_esc_ramp_accel_low_rpm_mean_rms_x',
    type: 'float',
  },
  'analyses.imu_vibration_esc_ramp_accel_low_rpm_mean_rms_y': {
    field: 'analyses.imu_vibration_esc_ramp_accel_low_rpm_mean_rms_y',
    type: 'float',
  },
  'analyses.imu_vibration_esc_ramp_accel_low_rpm_mean_rms_z': {
    field: 'analyses.imu_vibration_esc_ramp_accel_low_rpm_mean_rms_z',
    type: 'float',
  },
  'analyses.imu_vibration_esc_ramp_angular_rate_high_rpm_mean_rms_combined': {
    field:
      'analyses.imu_vibration_esc_ramp_angular_rate_high_rpm_mean_rms_combined',
    type: 'float',
  },
  'analyses.imu_vibration_esc_ramp_angular_rate_high_rpm_mean_rms_x': {
    field: 'analyses.imu_vibration_esc_ramp_angular_rate_high_rpm_mean_rms_x',
    type: 'float',
  },
  'analyses.imu_vibration_esc_ramp_angular_rate_high_rpm_mean_rms_y': {
    field: 'analyses.imu_vibration_esc_ramp_angular_rate_high_rpm_mean_rms_y',
    type: 'float',
  },
  'analyses.imu_vibration_esc_ramp_angular_rate_high_rpm_mean_rms_z': {
    field: 'analyses.imu_vibration_esc_ramp_angular_rate_high_rpm_mean_rms_z',
    type: 'float',
  },
  'analyses.imu_vibration_esc_ramp_angular_rate_low_rpm_mean_rms_combined': {
    field:
      'analyses.imu_vibration_esc_ramp_angular_rate_low_rpm_mean_rms_combined',
    type: 'float',
  },
  'analyses.imu_vibration_esc_ramp_angular_rate_low_rpm_mean_rms_x': {
    field: 'analyses.imu_vibration_esc_ramp_angular_rate_low_rpm_mean_rms_x',
    type: 'float',
  },
  'analyses.imu_vibration_esc_ramp_angular_rate_low_rpm_mean_rms_y': {
    field: 'analyses.imu_vibration_esc_ramp_angular_rate_low_rpm_mean_rms_y',
    type: 'float',
  },
  'analyses.imu_vibration_esc_ramp_angular_rate_low_rpm_mean_rms_z': {
    field: 'analyses.imu_vibration_esc_ramp_angular_rate_low_rpm_mean_rms_z',
    type: 'float',
  },
  'analyses.landing_detection_threshold_max_early_landing_detection_error': {
    field:
      'analyses.landing_detection_threshold_max_early_landing_detection_error',
    type: 'float',
  },
  'analyses.landing_detection_threshold_max_late_landing_detection_error': {
    field:
      'analyses.landing_detection_threshold_max_late_landing_detection_error',
    type: 'float',
  },
  'analyses.latency_max_allowed': {
    field: 'analyses.latency_max_allowed',
    type: 'float',
  },
  'analyses.latency_max_allowed_map_pose': {
    field: 'analyses.latency_max_allowed_map_pose',
    type: 'float',
  },
  'analyses.latency_max_allowed_msckf_pose': {
    field: 'analyses.latency_max_allowed_msckf_pose',
    type: 'float',
  },
  'analyses.latency_max_allowed_odom_pose': {
    field: 'analyses.latency_max_allowed_odom_pose',
    type: 'float',
  },
  'analyses.latency_max_allowed_prop_pose': {
    field: 'analyses.latency_max_allowed_prop_pose',
    type: 'long',
  },
  'analyses.latency_mean_allowed_map_pose': {
    field: 'analyses.latency_mean_allowed_map_pose',
    type: 'float',
  },
  'analyses.latency_mean_allowed_msckf_pose': {
    field: 'analyses.latency_mean_allowed_msckf_pose',
    type: 'float',
  },
  'analyses.latency_mean_allowed_odom_pose': {
    field: 'analyses.latency_mean_allowed_odom_pose',
    type: 'float',
  },
  'analyses.latency_mean_allowed_prop_pose': {
    field: 'analyses.latency_mean_allowed_prop_pose',
    type: 'long',
  },
  'analyses.latency_median_expected': {
    field: 'analyses.latency_median_expected',
    type: 'float',
  },
  'analyses.left-mipi-error__': {
    field: 'analyses.left-mipi-error__',
    type: 'long',
  },
  'analyses.left-mipi-error__forward': {
    field: 'analyses.left-mipi-error__forward',
    type: 'long',
  },
  'analyses.left-mipi-error__forward_down': {
    field: 'analyses.left-mipi-error__forward_down',
    type: 'long',
  },
  'analyses.left-mipi-error__forward_up': {
    field: 'analyses.left-mipi-error__forward_up',
    type: 'long',
  },
  'analyses.left-mipi-error__left': {
    field: 'analyses.left-mipi-error__left',
    type: 'long',
  },
  'analyses.left-mipi-error__right': {
    field: 'analyses.left-mipi-error__right',
    type: 'long',
  },
  'analyses.left__max_dip_length': {
    field: 'analyses.left__max_dip_length',
    type: 'long',
  },
  'analyses.left__mean_dip_length': {
    field: 'analyses.left__mean_dip_length',
    type: 'float',
  },
  'analyses.left__mean_fill_rate': {
    field: 'analyses.left__mean_fill_rate',
    type: 'float',
  },
  'analyses.left__min_fill_rate': {
    field: 'analyses.left__min_fill_rate',
    type: 'float',
  },
  'analyses.left__num_dips': {
    field: 'analyses.left__num_dips',
    type: 'long',
  },
  'analyses.left_bright_invalid_consecutive_frames_threshold': {
    field: 'analyses.left_bright_invalid_consecutive_frames_threshold',
    type: 'long',
  },
  'analyses.left_max_fill_percent_threshold': {
    field: 'analyses.left_max_fill_percent_threshold',
    type: 'float',
  },
  'analyses.left_mean_fill_percent_threshold': {
    field: 'analyses.left_mean_fill_percent_threshold',
    type: 'float',
  },
  'analyses.left_min_fill_percent_threshold': {
    field: 'analyses.left_min_fill_percent_threshold',
    type: 'float',
  },
  'analyses.left_num_frames_low_exposure_threshold': {
    field: 'analyses.left_num_frames_low_exposure_threshold',
    type: 'long',
  },
  'analyses.left_num_frames_very_low_exposure_threshold': {
    field: 'analyses.left_num_frames_very_low_exposure_threshold',
    type: 'long',
  },
  'analyses.local_estimate_ATE_max_allowable_quantile_ATE': {
    field: 'analyses.local_estimate_ATE_max_allowable_quantile_ATE',
    type: 'float',
  },
  'analyses.local_estimate_RPE_max_allowable_quantile_RPE': {
    field: 'analyses.local_estimate_RPE_max_allowable_quantile_RPE',
    type: 'float',
  },
  'analyses.local_map_messages_avg_latency_threshold': {
    field: 'analyses.local_map_messages_avg_latency_threshold',
    type: 'float',
  },
  'analyses.local_map_messages_max_latency_threshold': {
    field: 'analyses.local_map_messages_max_latency_threshold',
    type: 'float',
  },
  'analyses.local_map_percentage_voxel_state_fluctuations_threshold': {
    field: 'analyses.local_map_percentage_voxel_state_fluctuations_threshold',
    type: 'long',
  },
  'analyses.local_map_sensor2message_avg_latency_threshold': {
    field: 'analyses.local_map_sensor2message_avg_latency_threshold',
    type: 'float',
  },
  'analyses.local_map_sensor2message_max_latency_threshold': {
    field: 'analyses.local_map_sensor2message_max_latency_threshold',
    type: 'float',
  },
  'analyses.local_map_thread_run_cpu_threshold': {
    field: 'analyses.local_map_thread_run_cpu_threshold',
    type: 'float',
  },
  'analyses.local_map_thread_run_threshold': {
    field: 'analyses.local_map_thread_run_threshold',
    type: 'float',
  },
  'analyses.local_map_thread_run_wall_threshold': {
    field: 'analyses.local_map_thread_run_wall_threshold',
    type: 'float',
  },
  'analyses.mavros/ambient_light/ambient_light_latency_max': {
    field: 'analyses.mavros/ambient_light/ambient_light_latency_max',
    type: 'float',
  },
  'analyses.mavros/ambient_light/ambient_light_latency_mean': {
    field: 'analyses.mavros/ambient_light/ambient_light_latency_mean',
    type: 'float',
  },
  'analyses.mavros/ambient_light/ambient_light_latency_median': {
    field: 'analyses.mavros/ambient_light/ambient_light_latency_median',
    type: 'float',
  },
  'analyses.mavros/ambient_light/ambient_light_latency_stdev': {
    field: 'analyses.mavros/ambient_light/ambient_light_latency_stdev',
    type: 'float',
  },
  'analyses.mavros/distance_sensor/bottom_sonar_latency': {
    field: 'analyses.mavros/distance_sensor/bottom_sonar_latency',
    type: 'float',
  },
  'analyses.mavros/distance_sensor/bottom_sonar_latency_max': {
    field: 'analyses.mavros/distance_sensor/bottom_sonar_latency_max',
    type: 'float',
  },
  'analyses.mavros/distance_sensor/bottom_sonar_latency_mean': {
    field: 'analyses.mavros/distance_sensor/bottom_sonar_latency_mean',
    type: 'float',
  },
  'analyses.mavros/distance_sensor/bottom_sonar_latency_median': {
    field: 'analyses.mavros/distance_sensor/bottom_sonar_latency_median',
    type: 'float',
  },
  'analyses.mavros/distance_sensor/bottom_sonar_latency_stdev': {
    field: 'analyses.mavros/distance_sensor/bottom_sonar_latency_stdev',
    type: 'float',
  },
  'analyses.mavros/distance_sensor/bottom_sonar_timing': {
    field: 'analyses.mavros/distance_sensor/bottom_sonar_timing',
    type: 'float',
  },
  'analyses.max_CPU_therm_temperature': {
    field: 'analyses.max_CPU_therm_temperature',
    type: 'long',
  },
  'analyses.max_cpu_thread_use_threshold_nodelet_manager_depth_loop_clo': {
    field:
      'analyses.max_cpu_thread_use_threshold_nodelet_manager_depth_loop_clo',
    type: 'long',
  },
  'analyses.max_cpu_thread_use_threshold_nodelet_manager_dlc_thread': {
    field: 'analyses.max_cpu_thread_use_threshold_nodelet_manager_dlc_thread',
    type: 'long',
  },
  'analyses.max_cpu_thread_use_threshold_nodelet_manager_visual_loop_clo': {
    field:
      'analyses.max_cpu_thread_use_threshold_nodelet_manager_visual_loop_clo',
    type: 'float',
  },
  'analyses.max_cpu_thread_use_threshold_nodelet_manager_vlc_thread': {
    field: 'analyses.max_cpu_thread_use_threshold_nodelet_manager_vlc_thread',
    type: 'long',
  },
  'analyses.max_cpu_usage': {
    field: 'analyses.max_cpu_usage',
    type: 'float',
  },
  'analyses.max_expected_infiller_latency': {
    field: 'analyses.max_expected_infiller_latency',
    type: 'float',
  },
  'analyses.max_expected_infiller_up_latency': {
    field: 'analyses.max_expected_infiller_up_latency',
    type: 'float',
  },
  'analyses.max_expected_phantom_object_filter_up_latency': {
    field: 'analyses.max_expected_phantom_object_filter_up_latency',
    type: 'float',
  },
  'analyses.max_idle_time_meas': {
    field: 'analyses.max_idle_time_meas',
    type: 'float',
  },
  'analyses.max_idle_time_setpoint': {
    field: 'analyses.max_idle_time_setpoint',
    type: 'float',
  },
  'analyses.max_missing_events': {
    field: 'analyses.max_missing_events',
    type: 'long',
  },
  'analyses.max_slice_data_rate_threshold': {
    field: 'analyses.max_slice_data_rate_threshold',
    type: 'float',
  },
  'analyses.max_startup_time_dockerd': {
    field: 'analyses.max_startup_time_dockerd',
    type: 'float',
  },
  'analyses.max_startup_time_hme_ready': {
    field: 'analyses.max_startup_time_hme_ready',
    type: 'long',
  },
  'analyses.max_startup_time_kernel': {
    field: 'analyses.max_startup_time_kernel',
    type: 'float',
  },
  'analyses.max_startup_time_microhard': {
    field: 'analyses.max_startup_time_microhard',
    type: 'long',
  },
  'analyses.max_startup_time_radio_local': {
    field: 'analyses.max_startup_time_radio_local',
    type: 'float',
  },
  'analyses.max_startup_time_radio_remote': {
    field: 'analyses.max_startup_time_radio_remote',
    type: 'float',
  },
  'analyses.max_startup_time_ready_to_start': {
    field: 'analyses.max_startup_time_ready_to_start',
    type: 'float',
  },
  'analyses.max_startup_time_run': {
    field: 'analyses.max_startup_time_run',
    type: 'float',
  },
  'analyses.max_startup_time_vision_pipeline': {
    field: 'analyses.max_startup_time_vision_pipeline',
    type: 'long',
  },
  'analyses.min_clock_frequency': {
    field: 'analyses.min_clock_frequency',
    type: 'long',
  },
  'analyses.min_clock_frequency in GHz': {
    field: 'analyses.min_clock_frequency in GHz',
    type: 'float',
  },
  'analyses.min_expected_infiller_rate': {
    field: 'analyses.min_expected_infiller_rate',
    type: 'float',
  },
  'analyses.min_expected_infiller_up_rate': {
    field: 'analyses.min_expected_infiller_up_rate',
    type: 'float',
  },
  'analyses.msckf/navsol_delta_tov_complete_dropout': {
    field: 'analyses.msckf/navsol_delta_tov_complete_dropout',
    type: 'float',
  },
  'analyses.msckf/navsol_delta_tov_dropouts': {
    field: 'analyses.msckf/navsol_delta_tov_dropouts',
    type: 'long',
  },
  'analyses.msckf/navsol_delta_tov_max': {
    field: 'analyses.msckf/navsol_delta_tov_max',
    type: 'float',
  },
  'analyses.msckf/navsol_delta_tov_mean': {
    field: 'analyses.msckf/navsol_delta_tov_mean',
    type: 'float',
  },
  'analyses.msckf/navsol_delta_tov_median': {
    field: 'analyses.msckf/navsol_delta_tov_median',
    type: 'float',
  },
  'analyses.msckf/navsol_delta_tov_stdev': {
    field: 'analyses.msckf/navsol_delta_tov_stdev',
    type: 'float',
  },
  'analyses.msckf/navsol_latency': {
    field: 'analyses.msckf/navsol_latency',
    type: 'float',
  },
  'analyses.msckf/navsol_latency_max': {
    field: 'analyses.msckf/navsol_latency_max',
    type: 'float',
  },
  'analyses.msckf/navsol_latency_mean': {
    field: 'analyses.msckf/navsol_latency_mean',
    type: 'float',
  },
  'analyses.msckf/navsol_latency_median': {
    field: 'analyses.msckf/navsol_latency_median',
    type: 'float',
  },
  'analyses.msckf/navsol_latency_stdev': {
    field: 'analyses.msckf/navsol_latency_stdev',
    type: 'float',
  },
  'analyses.msckf_cpu_timers_threshold_total_avg_cpu': {
    field: 'analyses.msckf_cpu_timers_threshold_total_avg_cpu',
    type: 'float',
  },
  'analyses.msckf_cpu_timers_threshold_total_avg_wall': {
    field: 'analyses.msckf_cpu_timers_threshold_total_avg_wall',
    type: 'float',
  },
  'analyses.msckf_cpu_timers_threshold_total_max_cpu': {
    field: 'analyses.msckf_cpu_timers_threshold_total_max_cpu',
    type: 'float',
  },
  'analyses.msckf_cpu_timers_threshold_total_max_wall': {
    field: 'analyses.msckf_cpu_timers_threshold_total_max_wall',
    type: 'float',
  },
  'analyses.msckf_cpu_timers_threshold_total_spread_cpu': {
    field: 'analyses.msckf_cpu_timers_threshold_total_spread_cpu',
    type: 'float',
  },
  'analyses.msckf_cpu_timers_threshold_total_spread_wall': {
    field: 'analyses.msckf_cpu_timers_threshold_total_spread_wall',
    type: 'float',
  },
  'analyses.msckf_sensor_latency_max_allowed_sensor_latency': {
    field: 'analyses.msckf_sensor_latency_max_allowed_sensor_latency',
    type: 'float',
  },
  'analyses.msckf_sensor_latency_median_expected_combined_uvids': {
    field: 'analyses.msckf_sensor_latency_median_expected_combined_uvids',
    type: 'float',
  },
  'analyses.msckf_sensor_latency_median_expected_dense_tr_eo_bot_dpose_3d': {
    field:
      'analyses.msckf_sensor_latency_median_expected_dense_tr_eo_bot_dpose_3d',
    type: 'long',
  },
  'analyses.msckf_sensor_latency_median_expected_dense_tracker_deltapose_3d': {
    field:
      'analyses.msckf_sensor_latency_median_expected_dense_tracker_deltapose_3d',
    type: 'float',
  },
  'analyses.msckf_sensor_latency_median_expected_downward_surface_info': {
    field:
      'analyses.msckf_sensor_latency_median_expected_downward_surface_info',
    type: 'float',
  },
  'analyses.msckf_sensor_latency_median_expected_esc_status': {
    field: 'analyses.msckf_sensor_latency_median_expected_esc_status',
    type: 'float',
  },
  'analyses.msckf_sensor_latency_median_expected_imu_baro': {
    field: 'analyses.msckf_sensor_latency_median_expected_imu_baro',
    type: 'float',
  },
  'analyses.msckf_sensor_max_allowed_time_between_accepts_altimeters': {
    field: 'analyses.msckf_sensor_max_allowed_time_between_accepts_altimeters',
    type: 'float',
  },
  'analyses.msckf_sensor_max_allowed_time_between_accepts_baro': {
    field: 'analyses.msckf_sensor_max_allowed_time_between_accepts_baro',
    type: 'long',
  },
  'analyses.msckf_sensor_max_allowed_time_between_accepts_gps': {
    field: 'analyses.msckf_sensor_max_allowed_time_between_accepts_gps',
    type: 'float',
  },
  'analyses.msckf_sensor_max_allowed_time_between_accepts_uvid': {
    field: 'analyses.msckf_sensor_max_allowed_time_between_accepts_uvid',
    type: 'float',
  },
  'analyses.novacontroller_log_crash_toa': {
    field: 'analyses.novacontroller_log_crash_toa',
    type: 'float',
  },
  'analyses.novacontroller_log_crashes': {
    field: 'analyses.novacontroller_log_crashes',
    type: 'long',
  },
  'analyses.observed_discrepancy_threshold': {
    field: 'analyses.observed_discrepancy_threshold',
    type: 'float',
  },
  'analyses.overall__max_dip_length': {
    field: 'analyses.overall__max_dip_length',
    type: 'long',
  },
  'analyses.overall__mean_dip_length': {
    field: 'analyses.overall__mean_dip_length',
    type: 'float',
  },
  'analyses.overall__mean_fill_rate': {
    field: 'analyses.overall__mean_fill_rate',
    type: 'float',
  },
  'analyses.overall__min_fill_rate': {
    field: 'analyses.overall__min_fill_rate',
    type: 'float',
  },
  'analyses.overall__num_dips': {
    field: 'analyses.overall__num_dips',
    type: 'long',
  },
  'analyses.overlap_discrepancy_threshold': {
    field: 'analyses.overlap_discrepancy_threshold',
    type: 'float',
  },
  'analyses.planning_frequency_local_planner_min_mean': {
    field: 'analyses.planning_frequency_local_planner_min_mean',
    type: 'float',
  },
  'analyses.planning_frequency_local_planner_min_sigma1': {
    field: 'analyses.planning_frequency_local_planner_min_sigma1',
    type: 'long',
  },
  'analyses.planning_frequency_local_planner_min_sigma3': {
    field: 'analyses.planning_frequency_local_planner_min_sigma3',
    type: 'long',
  },
  'analyses.planning_frequency_teleop_min_mean': {
    field: 'analyses.planning_frequency_teleop_min_mean',
    type: 'float',
  },
  'analyses.planning_frequency_teleop_min_sigma1': {
    field: 'analyses.planning_frequency_teleop_min_sigma1',
    type: 'float',
  },
  'analyses.planning_frequency_teleop_min_sigma3': {
    field: 'analyses.planning_frequency_teleop_min_sigma3',
    type: 'float',
  },
  'analyses.pos_err_limits_mean_max': {
    field: 'analyses.pos_err_limits_mean_max',
    type: 'float',
  },
  'analyses.pos_err_limits_peak_max': {
    field: 'analyses.pos_err_limits_peak_max',
    type: 'float',
  },
  'analyses.pos_err_limits_std_max': {
    field: 'analyses.pos_err_limits_std_max',
    type: 'float',
  },
  'analyses.power_ambient_temp_threshold_max_value': {
    field: 'analyses.power_ambient_temp_threshold_max_value',
    type: 'float',
  },
  'analyses.power_avg_current_sensor_total_threshold': {
    field: 'analyses.power_avg_current_sensor_total_threshold',
    type: 'float',
  },
  'analyses.power_avg_power_usage_threshold_total': {
    field: 'analyses.power_avg_power_usage_threshold_total',
    type: 'float',
  },
  'analyses.power_avg_xavier_power_usage_threshold_total': {
    field: 'analyses.power_avg_xavier_power_usage_threshold_total',
    type: 'float',
  },
  'analyses.power_thermal_resistance_threshold_value': {
    field: 'analyses.power_thermal_resistance_threshold_value',
    type: 'float',
  },
  'analyses.process_died_count': {
    field: 'analyses.process_died_count',
    type: 'long',
  },
  'analyses.propagator_max_allowed_local_estimate_translation_error': {
    field: 'analyses.propagator_max_allowed_local_estimate_translation_error',
    type: 'float',
  },
  'analyses.propagator_max_allowed_propagator_translation_error': {
    field: 'analyses.propagator_max_allowed_propagator_translation_error',
    type: 'float',
  },
  'analyses.qos_0_duration': {
    field: 'analyses.qos_0_duration',
    type: 'float',
  },
  'analyses.rate_thresholds_depth_camera': {
    field: 'analyses.rate_thresholds_depth_camera',
    type: 'float',
  },
  'analyses.rate_thresholds_eo_camera': {
    field: 'analyses.rate_thresholds_eo_camera',
    type: 'float',
  },
  'analyses.redundant_planning_max_flight_dist': {
    field: 'analyses.redundant_planning_max_flight_dist',
    type: 'long',
  },
  'analyses.redundant_planning_max_long_legs': {
    field: 'analyses.redundant_planning_max_long_legs',
    type: 'long',
  },
  'analyses.redundant_planning_max_percent': {
    field: 'analyses.redundant_planning_max_percent',
    type: 'long',
  },
  'analyses.redundant_planning_max_short_legs': {
    field: 'analyses.redundant_planning_max_short_legs',
    type: 'long',
  },
  'analyses.redundant_travel_max_flight_dist': {
    field: 'analyses.redundant_travel_max_flight_dist',
    type: 'float',
  },
  'analyses.redundant_travel_max_long_legs': {
    field: 'analyses.redundant_travel_max_long_legs',
    type: 'long',
  },
  'analyses.redundant_travel_max_percent': {
    field: 'analyses.redundant_travel_max_percent',
    type: 'float',
  },
  'analyses.redundant_travel_max_short_legs': {
    field: 'analyses.redundant_travel_max_short_legs',
    type: 'long',
  },
  'analyses.reexploration_max_flight_dist': {
    field: 'analyses.reexploration_max_flight_dist',
    type: 'long',
  },
  'analyses.reexploration_max_long_legs': {
    field: 'analyses.reexploration_max_long_legs',
    type: 'long',
  },
  'analyses.reexploration_max_percent': {
    field: 'analyses.reexploration_max_percent',
    type: 'long',
  },
  'analyses.reexploration_max_short_legs': {
    field: 'analyses.reexploration_max_short_legs',
    type: 'long',
  },
  'analyses.return_success_max_failed_returns': {
    field: 'analyses.return_success_max_failed_returns',
    type: 'long',
  },
  'analyses.return_to_takeoff_position': {
    field: 'analyses.return_to_takeoff_position',
    type: 'float',
  },
  'analyses.right-mipi-error__': {
    field: 'analyses.right-mipi-error__',
    type: 'long',
  },
  'analyses.right-mipi-error__forward': {
    field: 'analyses.right-mipi-error__forward',
    type: 'long',
  },
  'analyses.right-mipi-error__forward_down': {
    field: 'analyses.right-mipi-error__forward_down',
    type: 'long',
  },
  'analyses.right-mipi-error__forward_up': {
    field: 'analyses.right-mipi-error__forward_up',
    type: 'long',
  },
  'analyses.right-mipi-error__left': {
    field: 'analyses.right-mipi-error__left',
    type: 'long',
  },
  'analyses.right-mipi-error__right': {
    field: 'analyses.right-mipi-error__right',
    type: 'long',
  },
  'analyses.right__max_dip_length': {
    field: 'analyses.right__max_dip_length',
    type: 'long',
  },
  'analyses.right__mean_dip_length': {
    field: 'analyses.right__mean_dip_length',
    type: 'float',
  },
  'analyses.right__mean_fill_rate': {
    field: 'analyses.right__mean_fill_rate',
    type: 'float',
  },
  'analyses.right__min_fill_rate': {
    field: 'analyses.right__min_fill_rate',
    type: 'float',
  },
  'analyses.right__num_dips': {
    field: 'analyses.right__num_dips',
    type: 'long',
  },
  'analyses.right_max_fill_percent_threshold': {
    field: 'analyses.right_max_fill_percent_threshold',
    type: 'float',
  },
  'analyses.right_mean_fill_percent_threshold': {
    field: 'analyses.right_mean_fill_percent_threshold',
    type: 'float',
  },
  'analyses.right_min_fill_percent_threshold': {
    field: 'analyses.right_min_fill_percent_threshold',
    type: 'float',
  },
  'analyses.right_num_frames_low_exposure_threshold': {
    field: 'analyses.right_num_frames_low_exposure_threshold',
    type: 'long',
  },
  'analyses.right_num_frames_very_low_exposure_threshold': {
    field: 'analyses.right_num_frames_very_low_exposure_threshold',
    type: 'long',
  },
  'analyses.rosconsole_L1_destabilized': {
    field: 'analyses.rosconsole_L1_destabilized',
    type: 'long',
  },
  'analyses.rosconsole_backup_estimator': {
    field: 'analyses.rosconsole_backup_estimator',
    type: 'long',
  },
  'analyses.rosconsole_bump_detector': {
    field: 'analyses.rosconsole_bump_detector',
    type: 'long',
  },
  'analyses.rosconsole_camera_param_recycle': {
    field: 'analyses.rosconsole_camera_param_recycle',
    type: 'long',
  },
  'analyses.rosconsole_change_in_setpoint_too_large': {
    field: 'analyses.rosconsole_change_in_setpoint_too_large',
    type: 'long',
  },
  'analyses.rosconsole_crash_detector': {
    field: 'analyses.rosconsole_crash_detector',
    type: 'long',
  },
  'analyses.rosconsole_cumulative_recovery_behaviors': {
    field: 'analyses.rosconsole_cumulative_recovery_behaviors',
    type: 'long',
  },
  'analyses.rosconsole_failed_return': {
    field: 'analyses.rosconsole_failed_return',
    type: 'long',
  },
  'analyses.rosconsole_failsafe_activated': {
    field: 'analyses.rosconsole_failsafe_activated',
    type: 'long',
  },
  'analyses.rosconsole_giving_up_exploration': {
    field: 'analyses.rosconsole_giving_up_exploration',
    type: 'long',
  },
  'analyses.rosconsole_local_map_update_missed': {
    field: 'analyses.rosconsole_local_map_update_missed',
    type: 'long',
  },
  'analyses.rosconsole_lp_timeout': {
    field: 'analyses.rosconsole_lp_timeout',
    type: 'long',
  },
  'analyses.rosconsole_phone_disconnect': {
    field: 'analyses.rosconsole_phone_disconnect',
    type: 'long',
  },
  'analyses.rosconsole_pollmanager_locked': {
    field: 'analyses.rosconsole_pollmanager_locked',
    type: 'float',
  },
  'analyses.rosconsole_pose_graph_death_ils': {
    field: 'analyses.rosconsole_pose_graph_death_ils',
    type: 'long',
  },
  'analyses.rosconsole_preflight_fail': {
    field: 'analyses.rosconsole_preflight_fail',
    type: 'long',
  },
  'analyses.rosconsole_radio_connection_lost': {
    field: 'analyses.rosconsole_radio_connection_lost',
    type: 'long',
  },
  'analyses.rosconsole_rc_manual_taken': {
    field: 'analyses.rosconsole_rc_manual_taken',
    type: 'long',
  },
  'analyses.rosconsole_sensor_dropout': {
    field: 'analyses.rosconsole_sensor_dropout',
    type: 'long',
  },
  'analyses.rosconsole_sentinel_landing': {
    field: 'analyses.rosconsole_sentinel_landing',
    type: 'long',
  },
  'analyses.rosconsole_sequencer_rejecting_msg': {
    field: 'analyses.rosconsole_sequencer_rejecting_msg',
    type: 'long',
  },
  'analyses.rosconsole_skipping_images': {
    field: 'analyses.rosconsole_skipping_images',
    type: 'long',
  },
  'analyses.rosconsole_trajectory_continuity': {
    field: 'analyses.rosconsole_trajectory_continuity',
    type: 'long',
  },
  'analyses.rosconsole_unable_to_arm': {
    field: 'analyses.rosconsole_unable_to_arm',
    type: 'long',
  },
  'analyses.rosconsole_unable_to_arm_msckf_timeout': {
    field: 'analyses.rosconsole_unable_to_arm_msckf_timeout',
    type: 'long',
  },
  'analyses.rosconsole_usb_connect': {
    field: 'analyses.rosconsole_usb_connect',
    type: 'long',
  },
  'analyses.rosconsole_usb_disconnect': {
    field: 'analyses.rosconsole_usb_disconnect',
    type: 'long',
  },
  'analyses.rosconsole_video_stream_started': {
    field: 'analyses.rosconsole_video_stream_started',
    type: 'long',
  },
  'analyses.sec_output_indoor_max': {
    field: 'analyses.sec_output_indoor_max',
    type: 'float',
  },
  'analyses.sec_output_outdoor_max': {
    field: 'analyses.sec_output_outdoor_max',
    type: 'float',
  },
  'analyses.sec_output_unknown_max': {
    field: 'analyses.sec_output_unknown_max',
    type: 'long',
  },
  'analyses.semantic_object_aggregator_publisher_counter_max_people_concurrent':
    {
      field:
        'analyses.semantic_object_aggregator_publisher_counter_max_people_concurrent',
      type: 'long',
    },
  'analyses.semantic_object_aggregator_publisher_counter_max_people_total': {
    field:
      'analyses.semantic_object_aggregator_publisher_counter_max_people_total',
    type: 'long',
  },
  'analyses.semantic_object_aggregator_publisher_counter_max_portals_concurrent':
    {
      field:
        'analyses.semantic_object_aggregator_publisher_counter_max_portals_concurrent',
      type: 'long',
    },
  'analyses.semantic_object_aggregator_publisher_counter_max_portals_total': {
    field:
      'analyses.semantic_object_aggregator_publisher_counter_max_portals_total',
    type: 'long',
  },
  'analyses.semantic_object_aggregator_publisher_latency_max_allowed': {
    field: 'analyses.semantic_object_aggregator_publisher_latency_max_allowed',
    type: 'float',
  },
  'analyses.semantic_object_aggregator_publisher_latency_median_expected': {
    field:
      'analyses.semantic_object_aggregator_publisher_latency_median_expected',
    type: 'float',
  },
  'analyses.semantic_object_detector_confidence_max_allowed': {
    field: 'analyses.semantic_object_detector_confidence_max_allowed',
    type: 'float',
  },
  'analyses.semantic_object_detector_confidence_min_allowed': {
    field: 'analyses.semantic_object_detector_confidence_min_allowed',
    type: 'float',
  },
  'analyses.semantic_object_detector_latency_max_allowed': {
    field: 'analyses.semantic_object_detector_latency_max_allowed',
    type: 'float',
  },
  'analyses.semantic_object_detector_latency_median_expected': {
    field: 'analyses.semantic_object_detector_latency_median_expected',
    type: 'float',
  },
  'analyses.setpoint_jump_max_pos': {
    field: 'analyses.setpoint_jump_max_pos',
    type: 'float',
  },
  'analyses.setpoint_jump_max_yaw': {
    field: 'analyses.setpoint_jump_max_yaw',
    type: 'float',
  },
  'analyses.setpoint_rate_magnitude_max_acc': {
    field: 'analyses.setpoint_rate_magnitude_max_acc',
    type: 'float',
  },
  'analyses.setpoint_rate_magnitude_max_jrk': {
    field: 'analyses.setpoint_rate_magnitude_max_jrk',
    type: 'float',
  },
  'analyses.setpoint_rate_magnitude_max_vel': {
    field: 'analyses.setpoint_rate_magnitude_max_vel',
    type: 'float',
  },
  'analyses.setpoint_rate_magnitude_setpoint_max_acc': {
    field: 'analyses.setpoint_rate_magnitude_setpoint_max_acc',
    type: 'float',
  },
  'analyses.setpoint_rate_magnitude_setpoint_max_jrk': {
    field: 'analyses.setpoint_rate_magnitude_setpoint_max_jrk',
    type: 'float',
  },
  'analyses.setpoint_rate_magnitude_setpoint_max_vel': {
    field: 'analyses.setpoint_rate_magnitude_setpoint_max_vel',
    type: 'float',
  },
  'analyses.setpoint_rate_magnitude_setpoint_max_yaw_rate': {
    field: 'analyses.setpoint_rate_magnitude_setpoint_max_yaw_rate',
    type: 'float',
  },
  'analyses.setpoint_rate_max_acc': {
    field: 'analyses.setpoint_rate_max_acc',
    type: 'float',
  },
  'analyses.setpoint_rate_max_jrk': {
    field: 'analyses.setpoint_rate_max_jrk',
    type: 'float',
  },
  'analyses.setpoint_rate_max_vel': {
    field: 'analyses.setpoint_rate_max_vel',
    type: 'float',
  },
  'analyses.setpoint_time_delta_max_delta_toa': {
    field: 'analyses.setpoint_time_delta_max_delta_toa',
    type: 'float',
  },
  'analyses.setpoint_time_delta_max_delta_tov': {
    field: 'analyses.setpoint_time_delta_max_delta_tov',
    type: 'float',
  },
  'analyses.shield_logs': {
    field: 'analyses.shield_logs',
    type: 'long',
  },
  'analyses.single_core_saturate': {
    field: 'analyses.single_core_saturate',
    type: 'long',
  },
  'analyses.single_core_saturate_old_calc': {
    field: 'analyses.single_core_saturate_old_calc',
    type: 'float',
  },
  'analyses.smart_environment_classifier_durations_indoor_duration_min': {
    field:
      'analyses.smart_environment_classifier_durations_indoor_duration_min',
    type: 'long',
  },
  'analyses.smart_environment_classifier_durations_indoor_false_positive_threshold':
    {
      field:
        'analyses.smart_environment_classifier_durations_indoor_false_positive_threshold',
      type: 'long',
    },
  'analyses.smart_environment_classifier_durations_indoor_percentage': {
    field: 'analyses.smart_environment_classifier_durations_indoor_percentage',
    type: 'float',
  },
  'analyses.smart_environment_classifier_durations_outdoor_duration_min': {
    field:
      'analyses.smart_environment_classifier_durations_outdoor_duration_min',
    type: 'float',
  },
  'analyses.smart_environment_classifier_durations_outdoor_false_positive_threshold':
    {
      field:
        'analyses.smart_environment_classifier_durations_outdoor_false_positive_threshold',
      type: 'float',
    },
  'analyses.smart_environment_classifier_durations_outdoor_percentage': {
    field: 'analyses.smart_environment_classifier_durations_outdoor_percentage',
    type: 'long',
  },
  'analyses.smart_environment_classifier_latency_max_allowed': {
    field: 'analyses.smart_environment_classifier_latency_max_allowed',
    type: 'float',
  },
  'analyses.smart_environment_classifier_latency_median_expected': {
    field: 'analyses.smart_environment_classifier_latency_median_expected',
    type: 'float',
  },
  'analyses.smart_environment_classifier_likelihoods_indoor_max': {
    field: 'analyses.smart_environment_classifier_likelihoods_indoor_max',
    type: 'long',
  },
  'analyses.smart_environment_classifier_likelihoods_outdoor_max': {
    field: 'analyses.smart_environment_classifier_likelihoods_outdoor_max',
    type: 'long',
  },
  'analyses.smart_environment_classifier_likelihoods_unknown_max': {
    field: 'analyses.smart_environment_classifier_likelihoods_unknown_max',
    type: 'long',
  },
  'analyses.smart_environment_classifier_takeoff_max_changes': {
    field: 'analyses.smart_environment_classifier_takeoff_max_changes',
    type: 'long',
  },
  'analyses.smart_environment_classifier_takeoff_min_consistency': {
    field: 'analyses.smart_environment_classifier_takeoff_min_consistency',
    type: 'long',
  },
  'analyses.smoothness_max_accel_allowed_msckf': {
    field: 'analyses.smoothness_max_accel_allowed_msckf',
    type: 'float',
  },
  'analyses.smoothness_max_allowed_accel': {
    field: 'analyses.smoothness_max_allowed_accel',
    type: 'float',
  },
  'analyses.smoothness_max_allowed_jerk_events': {
    field: 'analyses.smoothness_max_allowed_jerk_events',
    type: 'long',
  },
  'analyses.solve_time_walltime_ns_max': {
    field: 'analyses.solve_time_walltime_ns_max',
    type: 'long',
  },
  'analyses.sonar_mfg_analyzer_thresholds_outlier_percentage': {
    field: 'analyses.sonar_mfg_analyzer_thresholds_outlier_percentage',
    type: 'float',
  },
  'analyses.sonar_mfg_analyzer_thresholds_rolling_stdev': {
    field: 'analyses.sonar_mfg_analyzer_thresholds_rolling_stdev',
    type: 'float',
  },
  'analyses.sonar_mfg_verification_thresholds_error_stdev': {
    field: 'analyses.sonar_mfg_verification_thresholds_error_stdev',
    type: 'float',
  },
  'analyses.sonar_mfg_verification_thresholds_mean_squared_error': {
    field: 'analyses.sonar_mfg_verification_thresholds_mean_squared_error',
    type: 'float',
  },
  'analyses.sonar_mfg_verification_thresholds_outlier_percentage': {
    field: 'analyses.sonar_mfg_verification_thresholds_outlier_percentage',
    type: 'float',
  },
  'analyses.sonar_mfg_verification_thresholds_rolling_stdev': {
    field: 'analyses.sonar_mfg_verification_thresholds_rolling_stdev',
    type: 'float',
  },
  'analyses.telemetry_average_limit_kbps': {
    field: 'analyses.telemetry_average_limit_kbps',
    type: 'float',
  },
  'analyses.telemetry_peak_limit_kbps': {
    field: 'analyses.telemetry_peak_limit_kbps',
    type: 'long',
  },
  'analyses.throttled_flight': {
    field: 'analyses.throttled_flight',
    type: 'long',
  },
  'analyses.throttling_threshold_core0_freq': {
    field: 'analyses.throttling_threshold_core0_freq',
    type: 'float',
  },
  'analyses.throttling_threshold_core1_freq': {
    field: 'analyses.throttling_threshold_core1_freq',
    type: 'float',
  },
  'analyses.throttling_threshold_core2_freq': {
    field: 'analyses.throttling_threshold_core2_freq',
    type: 'float',
  },
  'analyses.throttling_threshold_core3_freq': {
    field: 'analyses.throttling_threshold_core3_freq',
    type: 'float',
  },
  'analyses.throttling_threshold_core4_freq': {
    field: 'analyses.throttling_threshold_core4_freq',
    type: 'float',
  },
  'analyses.throttling_threshold_core5_freq': {
    field: 'analyses.throttling_threshold_core5_freq',
    type: 'float',
  },
  'analyses.throttling_threshold_core6_freq': {
    field: 'analyses.throttling_threshold_core6_freq',
    type: 'float',
  },
  'analyses.throttling_threshold_core7_freq': {
    field: 'analyses.throttling_threshold_core7_freq',
    type: 'float',
  },
  'analyses.throttling_threshold_cpu_max_temp': {
    field: 'analyses.throttling_threshold_cpu_max_temp',
    type: 'float',
  },
  'analyses.throttling_threshold_cpu_max_usage': {
    field: 'analyses.throttling_threshold_cpu_max_usage',
    type: 'float',
  },
  'analyses.throttling_threshold_cpu_mean_freq': {
    field: 'analyses.throttling_threshold_cpu_mean_freq',
    type: 'long',
  },
  'analyses.timely_ll_rec_mode_switch': {
    field: 'analyses.timely_ll_rec_mode_switch',
    type: 'float',
  },
  'analyses.total_dips': {
    field: 'analyses.total_dips',
    type: 'long',
  },
  'analyses.unsafe_shutdowns_count': {
    field: 'analyses.unsafe_shutdowns_count',
    type: 'long',
  },
  'analyses.upward_max_fill_percent_threshold': {
    field: 'analyses.upward_max_fill_percent_threshold',
    type: 'float',
  },
  'analyses.upward_mean_fill_percent_threshold': {
    field: 'analyses.upward_mean_fill_percent_threshold',
    type: 'float',
  },
  'analyses.upward_min_fill_percent_threshold': {
    field: 'analyses.upward_min_fill_percent_threshold',
    type: 'float',
  },
  'analyses.verify_barometer_dropped_double_count': {
    field: 'analyses.verify_barometer_dropped_double_count',
    type: 'long',
  },
  'analyses.verify_barometer_dropped_single_count': {
    field: 'analyses.verify_barometer_dropped_single_count',
    type: 'long',
  },
  'analyses.verify_barometer_rate_avg': {
    field: 'analyses.verify_barometer_rate_avg',
    type: 'float',
  },
  'analyses.verify_barometer_rate_min': {
    field: 'analyses.verify_barometer_rate_min',
    type: 'float',
  },
  'analyses.verify_depth_forward_down_dropped_double_count': {
    field: 'analyses.verify_depth_forward_down_dropped_double_count',
    type: 'long',
  },
  'analyses.verify_depth_forward_down_dropped_single_count': {
    field: 'analyses.verify_depth_forward_down_dropped_single_count',
    type: 'long',
  },
  'analyses.verify_depth_forward_down_rate_avg': {
    field: 'analyses.verify_depth_forward_down_rate_avg',
    type: 'float',
  },
  'analyses.verify_depth_forward_down_rate_min': {
    field: 'analyses.verify_depth_forward_down_rate_min',
    type: 'float',
  },
  'analyses.verify_depth_forward_dropped_double_count': {
    field: 'analyses.verify_depth_forward_dropped_double_count',
    type: 'long',
  },
  'analyses.verify_depth_forward_dropped_single_count': {
    field: 'analyses.verify_depth_forward_dropped_single_count',
    type: 'long',
  },
  'analyses.verify_depth_forward_rate_avg': {
    field: 'analyses.verify_depth_forward_rate_avg',
    type: 'float',
  },
  'analyses.verify_depth_forward_rate_min': {
    field: 'analyses.verify_depth_forward_rate_min',
    type: 'float',
  },
  'analyses.verify_depth_forward_up_dropped_double_count': {
    field: 'analyses.verify_depth_forward_up_dropped_double_count',
    type: 'long',
  },
  'analyses.verify_depth_forward_up_dropped_single_count': {
    field: 'analyses.verify_depth_forward_up_dropped_single_count',
    type: 'long',
  },
  'analyses.verify_depth_forward_up_rate_avg': {
    field: 'analyses.verify_depth_forward_up_rate_avg',
    type: 'float',
  },
  'analyses.verify_depth_forward_up_rate_min': {
    field: 'analyses.verify_depth_forward_up_rate_min',
    type: 'float',
  },
  'analyses.verify_depth_left_dropped_double_count': {
    field: 'analyses.verify_depth_left_dropped_double_count',
    type: 'long',
  },
  'analyses.verify_depth_left_dropped_single_count': {
    field: 'analyses.verify_depth_left_dropped_single_count',
    type: 'long',
  },
  'analyses.verify_depth_left_rate_avg': {
    field: 'analyses.verify_depth_left_rate_avg',
    type: 'float',
  },
  'analyses.verify_depth_left_rate_min': {
    field: 'analyses.verify_depth_left_rate_min',
    type: 'float',
  },
  'analyses.verify_depth_right_dropped_double_count': {
    field: 'analyses.verify_depth_right_dropped_double_count',
    type: 'long',
  },
  'analyses.verify_depth_right_dropped_single_count': {
    field: 'analyses.verify_depth_right_dropped_single_count',
    type: 'long',
  },
  'analyses.verify_depth_right_rate_avg': {
    field: 'analyses.verify_depth_right_rate_avg',
    type: 'float',
  },
  'analyses.verify_depth_right_rate_min': {
    field: 'analyses.verify_depth_right_rate_min',
    type: 'float',
  },
  'analyses.verify_eo_bottom_dropped_double_count': {
    field: 'analyses.verify_eo_bottom_dropped_double_count',
    type: 'long',
  },
  'analyses.verify_eo_bottom_dropped_single_count': {
    field: 'analyses.verify_eo_bottom_dropped_single_count',
    type: 'long',
  },
  'analyses.verify_eo_bottom_rate_avg': {
    field: 'analyses.verify_eo_bottom_rate_avg',
    type: 'float',
  },
  'analyses.verify_eo_bottom_rate_min': {
    field: 'analyses.verify_eo_bottom_rate_min',
    type: 'float',
  },
  'analyses.verify_eo_forward_dropped_double_count': {
    field: 'analyses.verify_eo_forward_dropped_double_count',
    type: 'long',
  },
  'analyses.verify_eo_forward_dropped_single_count': {
    field: 'analyses.verify_eo_forward_dropped_single_count',
    type: 'long',
  },
  'analyses.verify_eo_forward_rate_avg': {
    field: 'analyses.verify_eo_forward_rate_avg',
    type: 'float',
  },
  'analyses.verify_eo_forward_rate_min': {
    field: 'analyses.verify_eo_forward_rate_min',
    type: 'float',
  },
  'analyses.verify_eo_left_dropped_double_count': {
    field: 'analyses.verify_eo_left_dropped_double_count',
    type: 'long',
  },
  'analyses.verify_eo_left_dropped_single_count': {
    field: 'analyses.verify_eo_left_dropped_single_count',
    type: 'long',
  },
  'analyses.verify_eo_left_rate_avg': {
    field: 'analyses.verify_eo_left_rate_avg',
    type: 'float',
  },
  'analyses.verify_eo_left_rate_min': {
    field: 'analyses.verify_eo_left_rate_min',
    type: 'float',
  },
  'analyses.verify_eo_right_dropped_double_count': {
    field: 'analyses.verify_eo_right_dropped_double_count',
    type: 'long',
  },
  'analyses.verify_eo_right_dropped_single_count': {
    field: 'analyses.verify_eo_right_dropped_single_count',
    type: 'long',
  },
  'analyses.verify_eo_right_rate_avg': {
    field: 'analyses.verify_eo_right_rate_avg',
    type: 'float',
  },
  'analyses.verify_eo_right_rate_min': {
    field: 'analyses.verify_eo_right_rate_min',
    type: 'float',
  },
  'analyses.verify_gps_dropped_double_count': {
    field: 'analyses.verify_gps_dropped_double_count',
    type: 'long',
  },
  'analyses.verify_gps_dropped_single_count': {
    field: 'analyses.verify_gps_dropped_single_count',
    type: 'long',
  },
  'analyses.verify_gps_rate_avg': {
    field: 'analyses.verify_gps_rate_avg',
    type: 'float',
  },
  'analyses.verify_gps_rate_min': {
    field: 'analyses.verify_gps_rate_min',
    type: 'float',
  },
  'analyses.verify_imu_dropped_double_count': {
    field: 'analyses.verify_imu_dropped_double_count',
    type: 'long',
  },
  'analyses.verify_imu_dropped_single_count': {
    field: 'analyses.verify_imu_dropped_single_count',
    type: 'long',
  },
  'analyses.verify_imu_rate_avg': {
    field: 'analyses.verify_imu_rate_avg',
    type: 'float',
  },
  'analyses.verify_imu_rate_min': {
    field: 'analyses.verify_imu_rate_min',
    type: 'float',
  },
  'analyses.verify_magnetometer_dropped_double_count': {
    field: 'analyses.verify_magnetometer_dropped_double_count',
    type: 'long',
  },
  'analyses.verify_magnetometer_dropped_single_count': {
    field: 'analyses.verify_magnetometer_dropped_single_count',
    type: 'long',
  },
  'analyses.verify_magnetometer_rate_avg': {
    field: 'analyses.verify_magnetometer_rate_avg',
    type: 'float',
  },
  'analyses.verify_magnetometer_rate_min': {
    field: 'analyses.verify_magnetometer_rate_min',
    type: 'float',
  },
  'analyses.verify_position_error_assert_mean_max-pos_err_limits_mean_max': {
    field:
      'analyses.verify_position_error_assert_mean_max-pos_err_limits_mean_max',
    type: 'float',
  },
  'analyses.verify_position_error_assert_peak_max-pos_err_limits_peak_max': {
    field:
      'analyses.verify_position_error_assert_peak_max-pos_err_limits_peak_max',
    type: 'float',
  },
  'analyses.verify_position_error_assert_std_max-pos_err_limits_std_max': {
    field:
      'analyses.verify_position_error_assert_std_max-pos_err_limits_std_max',
    type: 'float',
  },
  'analyses.verify_rpm_dropped_double_count': {
    field: 'analyses.verify_rpm_dropped_double_count',
    type: 'long',
  },
  'analyses.verify_rpm_dropped_single_count': {
    field: 'analyses.verify_rpm_dropped_single_count',
    type: 'long',
  },
  'analyses.verify_rpm_rate_avg': {
    field: 'analyses.verify_rpm_rate_avg',
    type: 'float',
  },
  'analyses.verify_rpm_rate_min': {
    field: 'analyses.verify_rpm_rate_min',
    type: 'float',
  },
  'analyses.verify_sensor_extrinsics_convergence_depth_camera_forward_extrinsics_rotation_pitch':
    {
      field:
        'analyses.verify_sensor_extrinsics_convergence_depth_camera_forward_extrinsics_rotation_pitch',
      type: 'long',
    },
  'analyses.verify_sensor_extrinsics_convergence_depth_camera_forward_extrinsics_rotation_roll':
    {
      field:
        'analyses.verify_sensor_extrinsics_convergence_depth_camera_forward_extrinsics_rotation_roll',
      type: 'long',
    },
  'analyses.verify_sensor_extrinsics_convergence_depth_camera_forward_extrinsics_rotation_yaw':
    {
      field:
        'analyses.verify_sensor_extrinsics_convergence_depth_camera_forward_extrinsics_rotation_yaw',
      type: 'long',
    },
  'analyses.verify_sensor_extrinsics_convergence_depth_camera_forward_extrinsics_translation':
    {
      field:
        'analyses.verify_sensor_extrinsics_convergence_depth_camera_forward_extrinsics_translation',
      type: 'long',
    },
  'analyses.verify_sensor_extrinsics_convergence_eo_bottom_extrinsics_rotation':
    {
      field:
        'analyses.verify_sensor_extrinsics_convergence_eo_bottom_extrinsics_rotation',
      type: 'float',
    },
  'analyses.verify_sensor_extrinsics_convergence_eo_bottom_extrinsics_rotation_pitch':
    {
      field:
        'analyses.verify_sensor_extrinsics_convergence_eo_bottom_extrinsics_rotation_pitch',
      type: 'long',
    },
  'analyses.verify_sensor_extrinsics_convergence_eo_bottom_extrinsics_rotation_roll':
    {
      field:
        'analyses.verify_sensor_extrinsics_convergence_eo_bottom_extrinsics_rotation_roll',
      type: 'long',
    },
  'analyses.verify_sensor_extrinsics_convergence_eo_bottom_extrinsics_rotation_yaw':
    {
      field:
        'analyses.verify_sensor_extrinsics_convergence_eo_bottom_extrinsics_rotation_yaw',
      type: 'long',
    },
  'analyses.verify_sensor_extrinsics_convergence_eo_bottom_extrinsics_translation':
    {
      field:
        'analyses.verify_sensor_extrinsics_convergence_eo_bottom_extrinsics_translation',
      type: 'long',
    },
  'analyses.verify_sensor_extrinsics_convergence_eo_camera_bottom_extrinsics_rotation_pitch':
    {
      field:
        'analyses.verify_sensor_extrinsics_convergence_eo_camera_bottom_extrinsics_rotation_pitch',
      type: 'float',
    },
  'analyses.verify_sensor_extrinsics_convergence_eo_camera_bottom_extrinsics_rotation_roll':
    {
      field:
        'analyses.verify_sensor_extrinsics_convergence_eo_camera_bottom_extrinsics_rotation_roll',
      type: 'float',
    },
  'analyses.verify_sensor_extrinsics_convergence_eo_camera_bottom_extrinsics_rotation_yaw':
    {
      field:
        'analyses.verify_sensor_extrinsics_convergence_eo_camera_bottom_extrinsics_rotation_yaw',
      type: 'float',
    },
  'analyses.verify_sensor_extrinsics_convergence_eo_camera_bottom_extrinsics_translation':
    {
      field:
        'analyses.verify_sensor_extrinsics_convergence_eo_camera_bottom_extrinsics_translation',
      type: 'float',
    },
  'analyses.verify_sensor_extrinsics_convergence_eo_camera_forward_extrinsics_rotation_pitch':
    {
      field:
        'analyses.verify_sensor_extrinsics_convergence_eo_camera_forward_extrinsics_rotation_pitch',
      type: 'float',
    },
  'analyses.verify_sensor_extrinsics_convergence_eo_camera_forward_extrinsics_rotation_roll':
    {
      field:
        'analyses.verify_sensor_extrinsics_convergence_eo_camera_forward_extrinsics_rotation_roll',
      type: 'float',
    },
  'analyses.verify_sensor_extrinsics_convergence_eo_camera_forward_extrinsics_rotation_yaw':
    {
      field:
        'analyses.verify_sensor_extrinsics_convergence_eo_camera_forward_extrinsics_rotation_yaw',
      type: 'float',
    },
  'analyses.verify_sensor_extrinsics_convergence_eo_camera_forward_extrinsics_translation':
    {
      field:
        'analyses.verify_sensor_extrinsics_convergence_eo_camera_forward_extrinsics_translation',
      type: 'float',
    },
  'analyses.verify_sensor_extrinsics_convergence_eo_camera_left_extrinsics_rotation_pitch':
    {
      field:
        'analyses.verify_sensor_extrinsics_convergence_eo_camera_left_extrinsics_rotation_pitch',
      type: 'float',
    },
  'analyses.verify_sensor_extrinsics_convergence_eo_camera_left_extrinsics_rotation_roll':
    {
      field:
        'analyses.verify_sensor_extrinsics_convergence_eo_camera_left_extrinsics_rotation_roll',
      type: 'float',
    },
  'analyses.verify_sensor_extrinsics_convergence_eo_camera_left_extrinsics_rotation_yaw':
    {
      field:
        'analyses.verify_sensor_extrinsics_convergence_eo_camera_left_extrinsics_rotation_yaw',
      type: 'float',
    },
  'analyses.verify_sensor_extrinsics_convergence_eo_camera_left_extrinsics_translation':
    {
      field:
        'analyses.verify_sensor_extrinsics_convergence_eo_camera_left_extrinsics_translation',
      type: 'float',
    },
  'analyses.verify_sensor_extrinsics_convergence_eo_camera_right_extrinsics_rotation_pitch':
    {
      field:
        'analyses.verify_sensor_extrinsics_convergence_eo_camera_right_extrinsics_rotation_pitch',
      type: 'float',
    },
  'analyses.verify_sensor_extrinsics_convergence_eo_camera_right_extrinsics_rotation_roll':
    {
      field:
        'analyses.verify_sensor_extrinsics_convergence_eo_camera_right_extrinsics_rotation_roll',
      type: 'float',
    },
  'analyses.verify_sensor_extrinsics_convergence_eo_camera_right_extrinsics_rotation_yaw':
    {
      field:
        'analyses.verify_sensor_extrinsics_convergence_eo_camera_right_extrinsics_rotation_yaw',
      type: 'float',
    },
  'analyses.verify_sensor_extrinsics_convergence_eo_camera_right_extrinsics_translation':
    {
      field:
        'analyses.verify_sensor_extrinsics_convergence_eo_camera_right_extrinsics_translation',
      type: 'float',
    },
  'analyses.verify_sensor_extrinsics_convergence_eo_forward_extrinsics_rotation':
    {
      field:
        'analyses.verify_sensor_extrinsics_convergence_eo_forward_extrinsics_rotation',
      type: 'float',
    },
  'analyses.verify_sensor_extrinsics_convergence_eo_forward_extrinsics_rotation_pitch':
    {
      field:
        'analyses.verify_sensor_extrinsics_convergence_eo_forward_extrinsics_rotation_pitch',
      type: 'float',
    },
  'analyses.verify_sensor_extrinsics_convergence_eo_forward_extrinsics_rotation_roll':
    {
      field:
        'analyses.verify_sensor_extrinsics_convergence_eo_forward_extrinsics_rotation_roll',
      type: 'float',
    },
  'analyses.verify_sensor_extrinsics_convergence_eo_forward_extrinsics_rotation_yaw':
    {
      field:
        'analyses.verify_sensor_extrinsics_convergence_eo_forward_extrinsics_rotation_yaw',
      type: 'float',
    },
  'analyses.verify_sensor_extrinsics_convergence_eo_forward_extrinsics_translation':
    {
      field:
        'analyses.verify_sensor_extrinsics_convergence_eo_forward_extrinsics_translation',
      type: 'float',
    },
  'analyses.verify_sensor_extrinsics_convergence_eo_left_extrinsics_rotation_pitch':
    {
      field:
        'analyses.verify_sensor_extrinsics_convergence_eo_left_extrinsics_rotation_pitch',
      type: 'float',
    },
  'analyses.verify_sensor_extrinsics_convergence_eo_left_extrinsics_rotation_roll':
    {
      field:
        'analyses.verify_sensor_extrinsics_convergence_eo_left_extrinsics_rotation_roll',
      type: 'float',
    },
  'analyses.verify_sensor_extrinsics_convergence_eo_left_extrinsics_rotation_yaw':
    {
      field:
        'analyses.verify_sensor_extrinsics_convergence_eo_left_extrinsics_rotation_yaw',
      type: 'float',
    },
  'analyses.verify_sensor_extrinsics_convergence_eo_left_extrinsics_translation':
    {
      field:
        'analyses.verify_sensor_extrinsics_convergence_eo_left_extrinsics_translation',
      type: 'float',
    },
  'analyses.verify_sensor_extrinsics_convergence_eo_right_extrinsics_rotation_pitch':
    {
      field:
        'analyses.verify_sensor_extrinsics_convergence_eo_right_extrinsics_rotation_pitch',
      type: 'float',
    },
  'analyses.verify_sensor_extrinsics_convergence_eo_right_extrinsics_rotation_roll':
    {
      field:
        'analyses.verify_sensor_extrinsics_convergence_eo_right_extrinsics_rotation_roll',
      type: 'float',
    },
  'analyses.verify_sensor_extrinsics_convergence_eo_right_extrinsics_rotation_yaw':
    {
      field:
        'analyses.verify_sensor_extrinsics_convergence_eo_right_extrinsics_rotation_yaw',
      type: 'float',
    },
  'analyses.verify_sensor_extrinsics_convergence_eo_right_extrinsics_translation':
    {
      field:
        'analyses.verify_sensor_extrinsics_convergence_eo_right_extrinsics_translation',
      type: 'float',
    },
  'analyses.verify_sonar_bottom_dropped_double_count': {
    field: 'analyses.verify_sonar_bottom_dropped_double_count',
    type: 'long',
  },
  'analyses.verify_sonar_bottom_dropped_single_count': {
    field: 'analyses.verify_sonar_bottom_dropped_single_count',
    type: 'long',
  },
  'analyses.verify_sonar_bottom_rate_avg': {
    field: 'analyses.verify_sonar_bottom_rate_avg',
    type: 'float',
  },
  'analyses.verify_sonar_bottom_rate_min': {
    field: 'analyses.verify_sonar_bottom_rate_min',
    type: 'float',
  },
  'analyses.verify_thermal_rate_avg': {
    field: 'analyses.verify_thermal_rate_avg',
    type: 'long',
  },
  'analyses.verify_thermal_rate_min': {
    field: 'analyses.verify_thermal_rate_min',
    type: 'long',
  },
  'analyses.verify_thermistors_dropped_double_count': {
    field: 'analyses.verify_thermistors_dropped_double_count',
    type: 'long',
  },
  'analyses.verify_thermistors_dropped_single_count': {
    field: 'analyses.verify_thermistors_dropped_single_count',
    type: 'long',
  },
  'analyses.waypoint_completion_max_unacquired_retrotraverse_waypoints': {
    field:
      'analyses.waypoint_completion_max_unacquired_retrotraverse_waypoints',
    type: 'long',
  },
  'analyses.waypoint_completion_max_unacquired_waypoints': {
    field: 'analyses.waypoint_completion_max_unacquired_waypoints',
    type: 'long',
  },
  artifacts: {
    field: 'artifacts',
    type: 'nested',
  },
  'artifacts.artifact_type': {
    field: 'artifacts.artifact_type',
    type: 'keyword',
  },
  'artifacts.file_name': {
    field: 'artifacts.file_name',
    type: 'keyword',
  },
  'children.id': {
    field: 'children.id',
    type: 'keyword',
  },
  'children.name': {
    field: 'children.name',
    type: 'keyword',
  },
  'collections.id': {
    field: 'collections.id',
    type: 'keyword',
  },
  'collections.name': {
    field: 'collections.name',
    type: 'text',
  },
  'collections.name.keyword': {
    field: 'collections.name.keyword',
    type: 'keyword',
  },
  created_at: {
    field: 'created_at',
    type: 'date',
  },
  duration_seconds: {
    field: 'duration_seconds',
    type: 'float',
  },
  end_time: {
    field: 'end_time',
    type: 'date',
  },
  entity: {
    field: 'entity',
    type: 'nested',
  },
  'environments.id': {
    field: 'environments.id',
    type: 'keyword',
  },
  'environments.name': {
    field: 'environments.name',
    type: 'text',
  },
  'environments.name.keyword': {
    field: 'environments.name.keyword',
    type: 'keyword',
  },
  events: {
    field: 'events',
    type: 'nested',
  },
  'events.cause.id': {
    field: 'events.cause.id',
    type: 'keyword',
  },
  'events.cause.name': {
    field: 'events.cause.name',
    type: 'text',
  },
  'events.cause.name.keyword': {
    field: 'events.cause.name.keyword',
    type: 'keyword',
  },
  'events.notes': {
    field: 'events.notes',
    type: 'text',
  },
  'events.notes.keyword': {
    field: 'events.notes.keyword',
    type: 'keyword',
  },
  'events.severity.id': {
    field: 'events.severity.id',
    type: 'keyword',
  },
  'events.tag.id': {
    field: 'events.tag.id',
    type: 'keyword',
  },
  'events.tag.name': {
    field: 'events.tag.name',
    type: 'text',
  },
  'events.tag.name.keyword': {
    field: 'events.tag.name.keyword',
    type: 'keyword',
  },
  'events.tickets.name': {
    field: 'events.tickets.name',
    type: 'text',
  },
  'events.tickets.name.keyword': {
    field: 'events.tickets.name.keyword',
    type: 'keyword',
  },
  'events.tickets.platform_id': {
    field: 'events.tickets.platform_id',
    type: 'keyword',
  },
  'events.tickets.status': {
    field: 'events.tickets.status',
    type: 'text',
  },
  'events.tickets.status.keyword': {
    field: 'events.tickets.status.keyword',
    type: 'keyword',
  },
  'labels.id': {
    field: 'labels.id',
    type: 'keyword',
  },
  'labels.name': {
    field: 'labels.name',
    type: 'text',
  },
  'labels.name.keyword': {
    field: 'labels.name.keyword',
    type: 'keyword',
  },
  log_events: {
    field: 'log_events',
    type: 'nested',
  },
  'metrics.SU_annotation_importer_MOD_annotated_frame_count': {
    field: 'metrics.SU_annotation_importer_MOD_annotated_frame_count',
    type: 'long',
  },
  'metrics.SU_annotation_importer_MOD_door_frame_count': {
    field: 'metrics.SU_annotation_importer_MOD_door_frame_count',
    type: 'long',
  },
  'metrics.SU_annotation_importer_MOD_drone_frame_count': {
    field: 'metrics.SU_annotation_importer_MOD_drone_frame_count',
    type: 'long',
  },
  'metrics.SU_annotation_importer_MOD_people_frame_count': {
    field: 'metrics.SU_annotation_importer_MOD_people_frame_count',
    type: 'long',
  },
  'metrics.SU_annotation_importer_MOD_portal_frame_count': {
    field: 'metrics.SU_annotation_importer_MOD_portal_frame_count',
    type: 'long',
  },
  'metrics.SU_annotation_importer_MOD_unique_door_count': {
    field: 'metrics.SU_annotation_importer_MOD_unique_door_count',
    type: 'long',
  },
  'metrics.SU_annotation_importer_MOD_unique_drone_count': {
    field: 'metrics.SU_annotation_importer_MOD_unique_drone_count',
    type: 'long',
  },
  'metrics.SU_annotation_importer_MOD_unique_people_count': {
    field: 'metrics.SU_annotation_importer_MOD_unique_people_count',
    type: 'long',
  },
  'metrics.SU_annotation_importer_MOD_unique_portal_count': {
    field: 'metrics.SU_annotation_importer_MOD_unique_portal_count',
    type: 'long',
  },
  'metrics.SU_annotation_importer_MOD_unique_vehicle_count': {
    field: 'metrics.SU_annotation_importer_MOD_unique_vehicle_count',
    type: 'long',
  },
  'metrics.SU_annotation_importer_MOD_unique_window_count': {
    field: 'metrics.SU_annotation_importer_MOD_unique_window_count',
    type: 'long',
  },
  'metrics.SU_annotation_importer_MOD_vehicle_frame_count': {
    field: 'metrics.SU_annotation_importer_MOD_vehicle_frame_count',
    type: 'long',
  },
  'metrics.SU_annotation_importer_MOD_window_frame_count': {
    field: 'metrics.SU_annotation_importer_MOD_window_frame_count',
    type: 'long',
  },
  'metrics.SU_annotation_importer_SEC_bodies_of_water_duration_seconds': {
    field:
      'metrics.SU_annotation_importer_SEC_bodies_of_water_duration_seconds',
    type: 'float',
  },
  'metrics.SU_annotation_importer_SEC_bodies_of_water_frame_count': {
    field: 'metrics.SU_annotation_importer_SEC_bodies_of_water_frame_count',
    type: 'long',
  },
  'metrics.SU_annotation_importer_SEC_bright_lights_duration_seconds': {
    field: 'metrics.SU_annotation_importer_SEC_bright_lights_duration_seconds',
    type: 'float',
  },
  'metrics.SU_annotation_importer_SEC_bright_lights_frame_count': {
    field: 'metrics.SU_annotation_importer_SEC_bright_lights_frame_count',
    type: 'long',
  },
  'metrics.SU_annotation_importer_SEC_cluttered_duration_seconds': {
    field: 'metrics.SU_annotation_importer_SEC_cluttered_duration_seconds',
    type: 'float',
  },
  'metrics.SU_annotation_importer_SEC_cluttered_frame_count': {
    field: 'metrics.SU_annotation_importer_SEC_cluttered_frame_count',
    type: 'long',
  },
  'metrics.SU_annotation_importer_SEC_floor_transition_duration_seconds': {
    field:
      'metrics.SU_annotation_importer_SEC_floor_transition_duration_seconds',
    type: 'float',
  },
  'metrics.SU_annotation_importer_SEC_floor_transition_frame_count': {
    field: 'metrics.SU_annotation_importer_SEC_floor_transition_frame_count',
    type: 'long',
  },
  'metrics.SU_annotation_importer_SEC_floor_transition_frames': {
    field: 'metrics.SU_annotation_importer_SEC_floor_transition_frames',
    type: 'long',
  },
  'metrics.SU_annotation_importer_SEC_indoor_duration_seconds': {
    field: 'metrics.SU_annotation_importer_SEC_indoor_duration_seconds',
    type: 'float',
  },
  'metrics.SU_annotation_importer_SEC_indoor_frame_count': {
    field: 'metrics.SU_annotation_importer_SEC_indoor_frame_count',
    type: 'long',
  },
  'metrics.SU_annotation_importer_SEC_indoor_frames': {
    field: 'metrics.SU_annotation_importer_SEC_indoor_frames',
    type: 'long',
  },
  'metrics.SU_annotation_importer_SEC_location_unknown_duration_seconds': {
    field:
      'metrics.SU_annotation_importer_SEC_location_unknown_duration_seconds',
    type: 'float',
  },
  'metrics.SU_annotation_importer_SEC_location_unknown_frame_count': {
    field: 'metrics.SU_annotation_importer_SEC_location_unknown_frame_count',
    type: 'long',
  },
  'metrics.SU_annotation_importer_SEC_mirrors_duration_seconds': {
    field: 'metrics.SU_annotation_importer_SEC_mirrors_duration_seconds',
    type: 'float',
  },
  'metrics.SU_annotation_importer_SEC_mirrors_frame_count': {
    field: 'metrics.SU_annotation_importer_SEC_mirrors_frame_count',
    type: 'long',
  },
  'metrics.SU_annotation_importer_SEC_other_glass_duration_seconds': {
    field: 'metrics.SU_annotation_importer_SEC_other_glass_duration_seconds',
    type: 'float',
  },
  'metrics.SU_annotation_importer_SEC_other_glass_frame_count': {
    field: 'metrics.SU_annotation_importer_SEC_other_glass_frame_count',
    type: 'long',
  },
  'metrics.SU_annotation_importer_SEC_outdoor_duration_seconds': {
    field: 'metrics.SU_annotation_importer_SEC_outdoor_duration_seconds',
    type: 'float',
  },
  'metrics.SU_annotation_importer_SEC_outdoor_frame_count': {
    field: 'metrics.SU_annotation_importer_SEC_outdoor_frame_count',
    type: 'long',
  },
  'metrics.SU_annotation_importer_SEC_outdoor_frames': {
    field: 'metrics.SU_annotation_importer_SEC_outdoor_frames',
    type: 'long',
  },
  'metrics.SU_annotation_importer_SEC_scenario_normal_duration_seconds': {
    field:
      'metrics.SU_annotation_importer_SEC_scenario_normal_duration_seconds',
    type: 'float',
  },
  'metrics.SU_annotation_importer_SEC_scenario_normal_frame_count': {
    field: 'metrics.SU_annotation_importer_SEC_scenario_normal_frame_count',
    type: 'long',
  },
  'metrics.SU_annotation_importer_SEC_scenario_normal_frames': {
    field: 'metrics.SU_annotation_importer_SEC_scenario_normal_frames',
    type: 'long',
  },
  'metrics.SU_annotation_importer_SEC_smoke_or_dust_duration_seconds': {
    field: 'metrics.SU_annotation_importer_SEC_smoke_or_dust_duration_seconds',
    type: 'float',
  },
  'metrics.SU_annotation_importer_SEC_smoke_or_dust_frame_count': {
    field: 'metrics.SU_annotation_importer_SEC_smoke_or_dust_frame_count',
    type: 'long',
  },
  'metrics.SU_annotation_importer_SEC_subterreanean_duration_seconds': {
    field: 'metrics.SU_annotation_importer_SEC_subterreanean_duration_seconds',
    type: 'float',
  },
  'metrics.SU_annotation_importer_SEC_subterreanean_frame_count': {
    field: 'metrics.SU_annotation_importer_SEC_subterreanean_frame_count',
    type: 'long',
  },
  'metrics.SU_annotation_importer_SEC_thin_objects_duration_seconds': {
    field: 'metrics.SU_annotation_importer_SEC_thin_objects_duration_seconds',
    type: 'float',
  },
  'metrics.SU_annotation_importer_SEC_thin_objects_frame_count': {
    field: 'metrics.SU_annotation_importer_SEC_thin_objects_frame_count',
    type: 'long',
  },
  'metrics.SU_annotation_importer_SEC_thin_objects_frames': {
    field: 'metrics.SU_annotation_importer_SEC_thin_objects_frames',
    type: 'long',
  },
  'metrics.SU_annotation_importer_SEC_transparent_fencing_duration_seconds': {
    field:
      'metrics.SU_annotation_importer_SEC_transparent_fencing_duration_seconds',
    type: 'float',
  },
  'metrics.SU_annotation_importer_SEC_transparent_fencing_frame_count': {
    field: 'metrics.SU_annotation_importer_SEC_transparent_fencing_frame_count',
    type: 'long',
  },
  'metrics.SU_annotation_importer_SEC_transparent_fencing_frames': {
    field: 'metrics.SU_annotation_importer_SEC_transparent_fencing_frames',
    type: 'long',
  },
  'metrics.SU_annotation_importer_SEC_wall_hangings_duration_seconds': {
    field: 'metrics.SU_annotation_importer_SEC_wall_hangings_duration_seconds',
    type: 'float',
  },
  'metrics.SU_annotation_importer_SEC_wall_hangings_frame_count': {
    field: 'metrics.SU_annotation_importer_SEC_wall_hangings_frame_count',
    type: 'long',
  },
  'metrics.SU_annotation_importer_SEC_weather_clear_duration_seconds': {
    field: 'metrics.SU_annotation_importer_SEC_weather_clear_duration_seconds',
    type: 'float',
  },
  'metrics.SU_annotation_importer_SEC_weather_clear_frame_count': {
    field: 'metrics.SU_annotation_importer_SEC_weather_clear_frame_count',
    type: 'long',
  },
  'metrics.SU_annotation_importer_SEC_weather_cloudy_duration_seconds': {
    field: 'metrics.SU_annotation_importer_SEC_weather_cloudy_duration_seconds',
    type: 'float',
  },
  'metrics.SU_annotation_importer_SEC_weather_cloudy_frame_count': {
    field: 'metrics.SU_annotation_importer_SEC_weather_cloudy_frame_count',
    type: 'long',
  },
  'metrics.SU_annotation_importer_SEC_weather_partly_cloudy_duration_seconds': {
    field:
      'metrics.SU_annotation_importer_SEC_weather_partly_cloudy_duration_seconds',
    type: 'float',
  },
  'metrics.SU_annotation_importer_SEC_weather_partly_cloudy_frame_count': {
    field:
      'metrics.SU_annotation_importer_SEC_weather_partly_cloudy_frame_count',
    type: 'long',
  },
  'metrics.SU_annotation_importer_SEC_weather_partly_cloudy_frames': {
    field: 'metrics.SU_annotation_importer_SEC_weather_partly_cloudy_frames',
    type: 'long',
  },
  'metrics.SU_annotation_importer_SEC_weather_unknown_duration_seconds': {
    field:
      'metrics.SU_annotation_importer_SEC_weather_unknown_duration_seconds',
    type: 'float',
  },
  'metrics.SU_annotation_importer_SEC_weather_unknown_frame_count': {
    field: 'metrics.SU_annotation_importer_SEC_weather_unknown_frame_count',
    type: 'long',
  },
  'metrics.Time to Arm(in Seconds)': {
    field: 'metrics.Time to Arm(in Seconds)',
    type: 'float',
  },
  'metrics.average_cpu_utilization': {
    field: 'metrics.average_cpu_utilization',
    type: 'float',
  },
  'metrics.average_gpu_utilization': {
    field: 'metrics.average_gpu_utilization',
    type: 'float',
  },
  'metrics.average_memory_utilization': {
    field: 'metrics.average_memory_utilization',
    type: 'float',
  },
  'metrics.avg_entry_rate': {
    field: 'metrics.avg_entry_rate',
    type: 'float',
  },
  'metrics.avg_flip_distance_inside_to_outside': {
    field: 'metrics.avg_flip_distance_inside_to_outside',
    type: 'long',
  },
  'metrics.avg_flip_distance_outside_to_inside': {
    field: 'metrics.avg_flip_distance_outside_to_inside',
    type: 'long',
  },
  'metrics.avg_heading_efficiency': {
    field: 'metrics.avg_heading_efficiency',
    type: 'long',
  },
  'metrics.avg_offset_reached': {
    field: 'metrics.avg_offset_reached',
    type: 'long',
  },
  'metrics.avg_outside_goals_across_unexits': {
    field: 'metrics.avg_outside_goals_across_unexits',
    type: 'long',
  },
  'metrics.avg_path_efficiency': {
    field: 'metrics.avg_path_efficiency',
    type: 'long',
  },
  'metrics.avg_starting_portal_distance': {
    field: 'metrics.avg_starting_portal_distance',
    type: 'float',
  },
  'metrics.avg_successful_building_entry_time': {
    field: 'metrics.avg_successful_building_entry_time',
    type: 'long',
  },
  'metrics.avg_unexit_time': {
    field: 'metrics.avg_unexit_time',
    type: 'long',
  },
  'metrics.barometer_diff_max': {
    field: 'metrics.barometer_diff_max',
    type: 'float',
  },
  'metrics.barometer_dropped_double_count': {
    field: 'metrics.barometer_dropped_double_count',
    type: 'long',
  },
  'metrics.barometer_dropped_double_percent': {
    field: 'metrics.barometer_dropped_double_percent',
    type: 'long',
  },
  'metrics.barometer_dropped_single_count': {
    field: 'metrics.barometer_dropped_single_count',
    type: 'long',
  },
  'metrics.barometer_dropped_single_percent': {
    field: 'metrics.barometer_dropped_single_percent',
    type: 'long',
  },
  'metrics.barometer_rate_avg': {
    field: 'metrics.barometer_rate_avg',
    type: 'float',
  },
  'metrics.barometer_rate_min': {
    field: 'metrics.barometer_rate_min',
    type: 'float',
  },
  'metrics.battery_capacity': {
    field: 'metrics.battery_capacity',
    type: 'long',
  },
  'metrics.battery_cycle_count': {
    field: 'metrics.battery_cycle_count',
    type: 'long',
  },
  'metrics.battery_pct_land': {
    field: 'metrics.battery_pct_land',
    type: 'long',
  },
  'metrics.battery_pct_takeoff': {
    field: 'metrics.battery_pct_takeoff',
    type: 'long',
  },
  'metrics.battery_serial_number': {
    field: 'metrics.battery_serial_number',
    type: 'long',
  },
  'metrics.cpu_throttle_event_count': {
    field: 'metrics.cpu_throttle_event_count',
    type: 'long',
  },
  'metrics.cpu_utilization_over_max_count': {
    field: 'metrics.cpu_utilization_over_max_count',
    type: 'long',
  },
  'metrics.curated_frame_count': {
    field: 'metrics.curated_frame_count',
    type: 'long',
  },
  'metrics.delta_arm_time': {
    field: 'metrics.delta_arm_time',
    type: 'float',
  },
  'metrics.depth_forward_diff_max': {
    field: 'metrics.depth_forward_diff_max',
    type: 'float',
  },
  'metrics.depth_forward_down_diff_max': {
    field: 'metrics.depth_forward_down_diff_max',
    type: 'float',
  },
  'metrics.depth_forward_down_dropped_double_count': {
    field: 'metrics.depth_forward_down_dropped_double_count',
    type: 'long',
  },
  'metrics.depth_forward_down_dropped_double_percent': {
    field: 'metrics.depth_forward_down_dropped_double_percent',
    type: 'long',
  },
  'metrics.depth_forward_down_dropped_single_count': {
    field: 'metrics.depth_forward_down_dropped_single_count',
    type: 'long',
  },
  'metrics.depth_forward_down_dropped_single_percent': {
    field: 'metrics.depth_forward_down_dropped_single_percent',
    type: 'long',
  },
  'metrics.depth_forward_down_rate_avg': {
    field: 'metrics.depth_forward_down_rate_avg',
    type: 'float',
  },
  'metrics.depth_forward_down_rate_min': {
    field: 'metrics.depth_forward_down_rate_min',
    type: 'float',
  },
  'metrics.depth_forward_dropped_double_count': {
    field: 'metrics.depth_forward_dropped_double_count',
    type: 'long',
  },
  'metrics.depth_forward_dropped_double_percent': {
    field: 'metrics.depth_forward_dropped_double_percent',
    type: 'long',
  },
  'metrics.depth_forward_dropped_single_count': {
    field: 'metrics.depth_forward_dropped_single_count',
    type: 'long',
  },
  'metrics.depth_forward_dropped_single_percent': {
    field: 'metrics.depth_forward_dropped_single_percent',
    type: 'long',
  },
  'metrics.depth_forward_rate_avg': {
    field: 'metrics.depth_forward_rate_avg',
    type: 'float',
  },
  'metrics.depth_forward_rate_min': {
    field: 'metrics.depth_forward_rate_min',
    type: 'float',
  },
  'metrics.depth_forward_up_diff_max': {
    field: 'metrics.depth_forward_up_diff_max',
    type: 'float',
  },
  'metrics.depth_forward_up_dropped_double_count': {
    field: 'metrics.depth_forward_up_dropped_double_count',
    type: 'long',
  },
  'metrics.depth_forward_up_dropped_double_percent': {
    field: 'metrics.depth_forward_up_dropped_double_percent',
    type: 'long',
  },
  'metrics.depth_forward_up_dropped_single_count': {
    field: 'metrics.depth_forward_up_dropped_single_count',
    type: 'long',
  },
  'metrics.depth_forward_up_dropped_single_percent': {
    field: 'metrics.depth_forward_up_dropped_single_percent',
    type: 'long',
  },
  'metrics.depth_forward_up_rate_avg': {
    field: 'metrics.depth_forward_up_rate_avg',
    type: 'float',
  },
  'metrics.depth_forward_up_rate_min': {
    field: 'metrics.depth_forward_up_rate_min',
    type: 'float',
  },
  'metrics.depth_left_diff_max': {
    field: 'metrics.depth_left_diff_max',
    type: 'float',
  },
  'metrics.depth_left_dropped_double_count': {
    field: 'metrics.depth_left_dropped_double_count',
    type: 'long',
  },
  'metrics.depth_left_dropped_double_percent': {
    field: 'metrics.depth_left_dropped_double_percent',
    type: 'long',
  },
  'metrics.depth_left_dropped_single_count': {
    field: 'metrics.depth_left_dropped_single_count',
    type: 'long',
  },
  'metrics.depth_left_dropped_single_percent': {
    field: 'metrics.depth_left_dropped_single_percent',
    type: 'long',
  },
  'metrics.depth_left_rate_avg': {
    field: 'metrics.depth_left_rate_avg',
    type: 'float',
  },
  'metrics.depth_left_rate_min': {
    field: 'metrics.depth_left_rate_min',
    type: 'float',
  },
  'metrics.depth_right_diff_max': {
    field: 'metrics.depth_right_diff_max',
    type: 'float',
  },
  'metrics.depth_right_dropped_double_count': {
    field: 'metrics.depth_right_dropped_double_count',
    type: 'long',
  },
  'metrics.depth_right_dropped_double_percent': {
    field: 'metrics.depth_right_dropped_double_percent',
    type: 'long',
  },
  'metrics.depth_right_dropped_single_count': {
    field: 'metrics.depth_right_dropped_single_count',
    type: 'long',
  },
  'metrics.depth_right_dropped_single_percent': {
    field: 'metrics.depth_right_dropped_single_percent',
    type: 'long',
  },
  'metrics.depth_right_rate_avg': {
    field: 'metrics.depth_right_rate_avg',
    type: 'float',
  },
  'metrics.depth_right_rate_min': {
    field: 'metrics.depth_right_rate_min',
    type: 'float',
  },
  'metrics.eo_bottom_diff_max': {
    field: 'metrics.eo_bottom_diff_max',
    type: 'float',
  },
  'metrics.eo_bottom_dropped_double_count': {
    field: 'metrics.eo_bottom_dropped_double_count',
    type: 'long',
  },
  'metrics.eo_bottom_dropped_double_percent': {
    field: 'metrics.eo_bottom_dropped_double_percent',
    type: 'long',
  },
  'metrics.eo_bottom_dropped_single_count': {
    field: 'metrics.eo_bottom_dropped_single_count',
    type: 'long',
  },
  'metrics.eo_bottom_dropped_single_percent': {
    field: 'metrics.eo_bottom_dropped_single_percent',
    type: 'float',
  },
  'metrics.eo_bottom_rate_avg': {
    field: 'metrics.eo_bottom_rate_avg',
    type: 'float',
  },
  'metrics.eo_bottom_rate_min': {
    field: 'metrics.eo_bottom_rate_min',
    type: 'float',
  },
  'metrics.eo_forward_diff_max': {
    field: 'metrics.eo_forward_diff_max',
    type: 'float',
  },
  'metrics.eo_forward_dropped_double_count': {
    field: 'metrics.eo_forward_dropped_double_count',
    type: 'long',
  },
  'metrics.eo_forward_dropped_double_percent': {
    field: 'metrics.eo_forward_dropped_double_percent',
    type: 'long',
  },
  'metrics.eo_forward_dropped_single_count': {
    field: 'metrics.eo_forward_dropped_single_count',
    type: 'long',
  },
  'metrics.eo_forward_dropped_single_percent': {
    field: 'metrics.eo_forward_dropped_single_percent',
    type: 'float',
  },
  'metrics.eo_forward_rate_avg': {
    field: 'metrics.eo_forward_rate_avg',
    type: 'float',
  },
  'metrics.eo_forward_rate_min': {
    field: 'metrics.eo_forward_rate_min',
    type: 'float',
  },
  'metrics.eo_left_diff_max': {
    field: 'metrics.eo_left_diff_max',
    type: 'float',
  },
  'metrics.eo_left_dropped_double_count': {
    field: 'metrics.eo_left_dropped_double_count',
    type: 'long',
  },
  'metrics.eo_left_dropped_double_percent': {
    field: 'metrics.eo_left_dropped_double_percent',
    type: 'long',
  },
  'metrics.eo_left_dropped_single_count': {
    field: 'metrics.eo_left_dropped_single_count',
    type: 'long',
  },
  'metrics.eo_left_dropped_single_percent': {
    field: 'metrics.eo_left_dropped_single_percent',
    type: 'float',
  },
  'metrics.eo_left_rate_avg': {
    field: 'metrics.eo_left_rate_avg',
    type: 'float',
  },
  'metrics.eo_left_rate_min': {
    field: 'metrics.eo_left_rate_min',
    type: 'float',
  },
  'metrics.eo_right_diff_max': {
    field: 'metrics.eo_right_diff_max',
    type: 'float',
  },
  'metrics.eo_right_dropped_double_count': {
    field: 'metrics.eo_right_dropped_double_count',
    type: 'long',
  },
  'metrics.eo_right_dropped_double_percent': {
    field: 'metrics.eo_right_dropped_double_percent',
    type: 'long',
  },
  'metrics.eo_right_dropped_single_count': {
    field: 'metrics.eo_right_dropped_single_count',
    type: 'long',
  },
  'metrics.eo_right_dropped_single_percent': {
    field: 'metrics.eo_right_dropped_single_percent',
    type: 'float',
  },
  'metrics.eo_right_rate_avg': {
    field: 'metrics.eo_right_rate_avg',
    type: 'float',
  },
  'metrics.eo_right_rate_min': {
    field: 'metrics.eo_right_rate_min',
    type: 'float',
  },
  'metrics.error_count': {
    field: 'metrics.error_count',
    type: 'long',
  },
  'metrics.error_x_95_percentile': {
    field: 'metrics.error_x_95_percentile',
    type: 'float',
  },
  'metrics.excess_battery_capacity': {
    field: 'metrics.excess_battery_capacity',
    type: 'long',
  },
  'metrics.excess_flight_time_pct': {
    field: 'metrics.excess_flight_time_pct',
    type: 'float',
  },
  'metrics.excess_flight_time_voltage': {
    field: 'metrics.excess_flight_time_voltage',
    type: 'float',
  },
  'metrics.expected_battery_capacity': {
    field: 'metrics.expected_battery_capacity',
    type: 'long',
  },
  'metrics.expected_battery_life': {
    field: 'metrics.expected_battery_life',
    type: 'float',
  },
  'metrics.expected_full_flight_time_pct': {
    field: 'metrics.expected_full_flight_time_pct',
    type: 'float',
  },
  'metrics.expected_full_flight_time_voltage': {
    field: 'metrics.expected_full_flight_time_voltage',
    type: 'float',
  },
  'metrics.exploration_rate': {
    field: 'metrics.exploration_rate',
    type: 'float',
  },
  'metrics.exploration_time': {
    field: 'metrics.exploration_time',
    type: 'float',
  },
  'metrics.exploration_volume': {
    field: 'metrics.exploration_volume',
    type: 'float',
  },
  'metrics.flight_time': {
    field: 'metrics.flight_time',
    type: 'float',
  },
  'metrics.flight_time_per_battery_pct': {
    field: 'metrics.flight_time_per_battery_pct',
    type: 'float',
  },
  'metrics.formatted_shield_log_errors': {
    field: 'metrics.formatted_shield_log_errors',
    type: 'long',
  },
  'metrics.gps_diff_max': {
    field: 'metrics.gps_diff_max',
    type: 'float',
  },
  'metrics.gps_dropped_double_count': {
    field: 'metrics.gps_dropped_double_count',
    type: 'long',
  },
  'metrics.gps_dropped_double_percent': {
    field: 'metrics.gps_dropped_double_percent',
    type: 'long',
  },
  'metrics.gps_dropped_single_count': {
    field: 'metrics.gps_dropped_single_count',
    type: 'long',
  },
  'metrics.gps_dropped_single_percent': {
    field: 'metrics.gps_dropped_single_percent',
    type: 'long',
  },
  'metrics.gps_rate_avg': {
    field: 'metrics.gps_rate_avg',
    type: 'float',
  },
  'metrics.gps_rate_min': {
    field: 'metrics.gps_rate_min',
    type: 'float',
  },
  'metrics.gpu_throttle_event_count': {
    field: 'metrics.gpu_throttle_event_count',
    type: 'long',
  },
  'metrics.gpu_utilization_over_max_count': {
    field: 'metrics.gpu_utilization_over_max_count',
    type: 'long',
  },
  'metrics.harvested_frame_count': {
    field: 'metrics.harvested_frame_count',
    type: 'long',
  },
  'metrics.imu_diff_max': {
    field: 'metrics.imu_diff_max',
    type: 'float',
  },
  'metrics.imu_dropped_double_count': {
    field: 'metrics.imu_dropped_double_count',
    type: 'long',
  },
  'metrics.imu_dropped_double_percent': {
    field: 'metrics.imu_dropped_double_percent',
    type: 'long',
  },
  'metrics.imu_dropped_single_count': {
    field: 'metrics.imu_dropped_single_count',
    type: 'long',
  },
  'metrics.imu_dropped_single_percent': {
    field: 'metrics.imu_dropped_single_percent',
    type: 'long',
  },
  'metrics.imu_rate_avg': {
    field: 'metrics.imu_rate_avg',
    type: 'float',
  },
  'metrics.imu_rate_min': {
    field: 'metrics.imu_rate_min',
    type: 'float',
  },
  'metrics.jerk_events_per_second': {
    field: 'metrics.jerk_events_per_second',
    type: 'long',
  },
  'metrics.local_planner_mean': {
    field: 'metrics.local_planner_mean',
    type: 'float',
  },
  'metrics.local_planner_sigma1': {
    field: 'metrics.local_planner_sigma1',
    type: 'long',
  },
  'metrics.local_planner_sigma3': {
    field: 'metrics.local_planner_sigma3',
    type: 'long',
  },
  'metrics.long_redundant_planning_legs': {
    field: 'metrics.long_redundant_planning_legs',
    type: 'long',
  },
  'metrics.long_redundant_travel_legs': {
    field: 'metrics.long_redundant_travel_legs',
    type: 'long',
  },
  'metrics.long_reexploration_legs': {
    field: 'metrics.long_reexploration_legs',
    type: 'long',
  },
  'metrics.magnetometer_diff_max': {
    field: 'metrics.magnetometer_diff_max',
    type: 'float',
  },
  'metrics.magnetometer_dropped_double_count': {
    field: 'metrics.magnetometer_dropped_double_count',
    type: 'long',
  },
  'metrics.magnetometer_dropped_double_percent': {
    field: 'metrics.magnetometer_dropped_double_percent',
    type: 'long',
  },
  'metrics.magnetometer_dropped_single_count': {
    field: 'metrics.magnetometer_dropped_single_count',
    type: 'long',
  },
  'metrics.magnetometer_dropped_single_percent': {
    field: 'metrics.magnetometer_dropped_single_percent',
    type: 'long',
  },
  'metrics.magnetometer_rate_avg': {
    field: 'metrics.magnetometer_rate_avg',
    type: 'float',
  },
  'metrics.magnetometer_rate_min': {
    field: 'metrics.magnetometer_rate_min',
    type: 'float',
  },
  'metrics.max_ATE_value': {
    field: 'metrics.max_ATE_value',
    type: 'float',
  },
  'metrics.max_entry_rate': {
    field: 'metrics.max_entry_rate',
    type: 'float',
  },
  'metrics.max_heading_efficiency': {
    field: 'metrics.max_heading_efficiency',
    type: 'long',
  },
  'metrics.max_launch_latency_value': {
    field: 'metrics.max_launch_latency_value',
    type: 'float',
  },
  'metrics.max_offset_reached': {
    field: 'metrics.max_offset_reached',
    type: 'long',
  },
  'metrics.max_outside_goals_across_unexits': {
    field: 'metrics.max_outside_goals_across_unexits',
    type: 'long',
  },
  'metrics.max_path_efficiency': {
    field: 'metrics.max_path_efficiency',
    type: 'long',
  },
  'metrics.max_person_angle': {
    field: 'metrics.max_person_angle',
    type: 'float',
  },
  'metrics.max_person_distance': {
    field: 'metrics.max_person_distance',
    type: 'float',
  },
  'metrics.max_portal_angle': {
    field: 'metrics.max_portal_angle',
    type: 'float',
  },
  'metrics.max_portal_distance': {
    field: 'metrics.max_portal_distance',
    type: 'float',
  },
  'metrics.max_starting_portal_distance': {
    field: 'metrics.max_starting_portal_distance',
    type: 'float',
  },
  'metrics.max_successful_building_entry_time': {
    field: 'metrics.max_successful_building_entry_time',
    type: 'long',
  },
  'metrics.max_unexit_time': {
    field: 'metrics.max_unexit_time',
    type: 'long',
  },
  'metrics.mean_app_manual_nonidle_speed': {
    field: 'metrics.mean_app_manual_nonidle_speed',
    type: 'float',
  },
  'metrics.mean_explore_nonidle_speed': {
    field: 'metrics.mean_explore_nonidle_speed',
    type: 'float',
  },
  'metrics.mean_go_through_portal_nonidle_speed': {
    field: 'metrics.mean_go_through_portal_nonidle_speed',
    type: 'float',
  },
  'metrics.mean_nonidle_speed': {
    field: 'metrics.mean_nonidle_speed',
    type: 'float',
  },
  'metrics.mean_waypoint_following_nonidle_speed': {
    field: 'metrics.mean_waypoint_following_nonidle_speed',
    type: 'float',
  },
  'metrics.memory_utilization_over_max_count': {
    field: 'metrics.memory_utilization_over_max_count',
    type: 'long',
  },
  'metrics.min_entry_rate': {
    field: 'metrics.min_entry_rate',
    type: 'float',
  },
  'metrics.min_flip_distance_inside_to_outside': {
    field: 'metrics.min_flip_distance_inside_to_outside',
    type: 'long',
  },
  'metrics.min_flip_distance_outside_to_inside': {
    field: 'metrics.min_flip_distance_outside_to_inside',
    type: 'long',
  },
  'metrics.min_heading_efficiency': {
    field: 'metrics.min_heading_efficiency',
    type: 'long',
  },
  'metrics.min_offset_reached': {
    field: 'metrics.min_offset_reached',
    type: 'long',
  },
  'metrics.min_outside_goals_across_unexits': {
    field: 'metrics.min_outside_goals_across_unexits',
    type: 'long',
  },
  'metrics.min_path_efficiency': {
    field: 'metrics.min_path_efficiency',
    type: 'long',
  },
  'metrics.min_person_angle': {
    field: 'metrics.min_person_angle',
    type: 'float',
  },
  'metrics.min_person_distance': {
    field: 'metrics.min_person_distance',
    type: 'float',
  },
  'metrics.min_portal_angle': {
    field: 'metrics.min_portal_angle',
    type: 'float',
  },
  'metrics.min_portal_distance': {
    field: 'metrics.min_portal_distance',
    type: 'float',
  },
  'metrics.min_starting_portal_distance': {
    field: 'metrics.min_starting_portal_distance',
    type: 'float',
  },
  'metrics.min_successful_building_entry_time': {
    field: 'metrics.min_successful_building_entry_time',
    type: 'long',
  },
  'metrics.min_unexit_time': {
    field: 'metrics.min_unexit_time',
    type: 'long',
  },
  'metrics.num_acquired_retrotraverse_waypoints': {
    field: 'metrics.num_acquired_retrotraverse_waypoints',
    type: 'long',
  },
  'metrics.num_acquired_waypoints': {
    field: 'metrics.num_acquired_waypoints',
    type: 'long',
  },
  'metrics.num_external_jerk_events': {
    field: 'metrics.num_external_jerk_events',
    type: 'long',
  },
  'metrics.num_failed_building_entries': {
    field: 'metrics.num_failed_building_entries',
    type: 'long',
  },
  'metrics.num_failed_returns': {
    field: 'metrics.num_failed_returns',
    type: 'long',
  },
  'metrics.num_flipped_nodes_inside_to_outside': {
    field: 'metrics.num_flipped_nodes_inside_to_outside',
    type: 'long',
  },
  'metrics.num_flipped_nodes_outside_to_inside': {
    field: 'metrics.num_flipped_nodes_outside_to_inside',
    type: 'long',
  },
  'metrics.num_interrupted_building_entries': {
    field: 'metrics.num_interrupted_building_entries',
    type: 'long',
  },
  'metrics.num_succeeded_building_entries': {
    field: 'metrics.num_succeeded_building_entries',
    type: 'long',
  },
  'metrics.num_total_building_entries': {
    field: 'metrics.num_total_building_entries',
    type: 'long',
  },
  'metrics.num_total_outside_goals': {
    field: 'metrics.num_total_outside_goals',
    type: 'long',
  },
  'metrics.num_total_unexits': {
    field: 'metrics.num_total_unexits',
    type: 'long',
  },
  'metrics.num_trajectory_jerk_events': {
    field: 'metrics.num_trajectory_jerk_events',
    type: 'long',
  },
  'metrics.num_unacquired_retrotraverse_waypoints': {
    field: 'metrics.num_unacquired_retrotraverse_waypoints',
    type: 'long',
  },
  'metrics.num_unacquired_waypoints': {
    field: 'metrics.num_unacquired_waypoints',
    type: 'long',
  },
  'metrics.number_of_esc_drops': {
    field: 'metrics.number_of_esc_drops',
    type: 'long',
  },
  'metrics.percent_flipped_nodes_inside_to_outside': {
    field: 'metrics.percent_flipped_nodes_inside_to_outside',
    type: 'long',
  },
  'metrics.percent_flipped_nodes_outside_to_inside': {
    field: 'metrics.percent_flipped_nodes_outside_to_inside',
    type: 'long',
  },
  'metrics.percent_redundant_planning': {
    field: 'metrics.percent_redundant_planning',
    type: 'long',
  },
  'metrics.percent_redundant_travel': {
    field: 'metrics.percent_redundant_travel',
    type: 'float',
  },
  'metrics.percent_reexploration': {
    field: 'metrics.percent_reexploration',
    type: 'long',
  },
  'metrics.percent_time_idle_meas': {
    field: 'metrics.percent_time_idle_meas',
    type: 'float',
  },
  'metrics.percent_time_idle_setpoint': {
    field: 'metrics.percent_time_idle_setpoint',
    type: 'float',
  },
  'metrics.person_angle_max': {
    field: 'metrics.person_angle_max',
    type: 'float',
  },
  'metrics.person_angle_min': {
    field: 'metrics.person_angle_min',
    type: 'float',
  },
  'metrics.person_distance_max': {
    field: 'metrics.person_distance_max',
    type: 'float',
  },
  'metrics.person_distance_min': {
    field: 'metrics.person_distance_min',
    type: 'float',
  },
  'metrics.portal_angle_max': {
    field: 'metrics.portal_angle_max',
    type: 'float',
  },
  'metrics.portal_angle_min': {
    field: 'metrics.portal_angle_min',
    type: 'float',
  },
  'metrics.portal_distance_max': {
    field: 'metrics.portal_distance_max',
    type: 'float',
  },
  'metrics.portal_distance_min': {
    field: 'metrics.portal_distance_min',
    type: 'float',
  },
  'metrics.rpm_diff_max': {
    field: 'metrics.rpm_diff_max',
    type: 'float',
  },
  'metrics.rpm_dropped_double_count': {
    field: 'metrics.rpm_dropped_double_count',
    type: 'long',
  },
  'metrics.rpm_dropped_double_percent': {
    field: 'metrics.rpm_dropped_double_percent',
    type: 'long',
  },
  'metrics.rpm_dropped_single_count': {
    field: 'metrics.rpm_dropped_single_count',
    type: 'long',
  },
  'metrics.rpm_dropped_single_percent': {
    field: 'metrics.rpm_dropped_single_percent',
    type: 'float',
  },
  'metrics.rpm_rate_avg': {
    field: 'metrics.rpm_rate_avg',
    type: 'float',
  },
  'metrics.rpm_rate_min': {
    field: 'metrics.rpm_rate_min',
    type: 'float',
  },
  'metrics.rs_forward_diff_max': {
    field: 'metrics.rs_forward_diff_max',
    type: 'float',
  },
  'metrics.rs_forward_down_diff_max': {
    field: 'metrics.rs_forward_down_diff_max',
    type: 'float',
  },
  'metrics.rs_forward_down_dropped_double_count': {
    field: 'metrics.rs_forward_down_dropped_double_count',
    type: 'long',
  },
  'metrics.rs_forward_down_dropped_double_percent': {
    field: 'metrics.rs_forward_down_dropped_double_percent',
    type: 'long',
  },
  'metrics.rs_forward_down_dropped_single_count': {
    field: 'metrics.rs_forward_down_dropped_single_count',
    type: 'long',
  },
  'metrics.rs_forward_down_dropped_single_percent': {
    field: 'metrics.rs_forward_down_dropped_single_percent',
    type: 'long',
  },
  'metrics.rs_forward_down_rate_avg': {
    field: 'metrics.rs_forward_down_rate_avg',
    type: 'float',
  },
  'metrics.rs_forward_down_rate_min': {
    field: 'metrics.rs_forward_down_rate_min',
    type: 'float',
  },
  'metrics.rs_forward_dropped_double_count': {
    field: 'metrics.rs_forward_dropped_double_count',
    type: 'long',
  },
  'metrics.rs_forward_dropped_double_percent': {
    field: 'metrics.rs_forward_dropped_double_percent',
    type: 'long',
  },
  'metrics.rs_forward_dropped_single_count': {
    field: 'metrics.rs_forward_dropped_single_count',
    type: 'long',
  },
  'metrics.rs_forward_dropped_single_percent': {
    field: 'metrics.rs_forward_dropped_single_percent',
    type: 'long',
  },
  'metrics.rs_forward_rate_avg': {
    field: 'metrics.rs_forward_rate_avg',
    type: 'float',
  },
  'metrics.rs_forward_rate_min': {
    field: 'metrics.rs_forward_rate_min',
    type: 'float',
  },
  'metrics.rs_forward_up_diff_max': {
    field: 'metrics.rs_forward_up_diff_max',
    type: 'float',
  },
  'metrics.rs_forward_up_dropped_double_count': {
    field: 'metrics.rs_forward_up_dropped_double_count',
    type: 'long',
  },
  'metrics.rs_forward_up_dropped_double_percent': {
    field: 'metrics.rs_forward_up_dropped_double_percent',
    type: 'long',
  },
  'metrics.rs_forward_up_dropped_single_count': {
    field: 'metrics.rs_forward_up_dropped_single_count',
    type: 'long',
  },
  'metrics.rs_forward_up_dropped_single_percent': {
    field: 'metrics.rs_forward_up_dropped_single_percent',
    type: 'long',
  },
  'metrics.rs_forward_up_rate_avg': {
    field: 'metrics.rs_forward_up_rate_avg',
    type: 'float',
  },
  'metrics.rs_forward_up_rate_min': {
    field: 'metrics.rs_forward_up_rate_min',
    type: 'float',
  },
  'metrics.rs_left_diff_max': {
    field: 'metrics.rs_left_diff_max',
    type: 'float',
  },
  'metrics.rs_left_dropped_double_count': {
    field: 'metrics.rs_left_dropped_double_count',
    type: 'long',
  },
  'metrics.rs_left_dropped_double_percent': {
    field: 'metrics.rs_left_dropped_double_percent',
    type: 'long',
  },
  'metrics.rs_left_dropped_single_count': {
    field: 'metrics.rs_left_dropped_single_count',
    type: 'long',
  },
  'metrics.rs_left_dropped_single_percent': {
    field: 'metrics.rs_left_dropped_single_percent',
    type: 'long',
  },
  'metrics.rs_left_rate_avg': {
    field: 'metrics.rs_left_rate_avg',
    type: 'float',
  },
  'metrics.rs_left_rate_min': {
    field: 'metrics.rs_left_rate_min',
    type: 'float',
  },
  'metrics.rs_right_diff_max': {
    field: 'metrics.rs_right_diff_max',
    type: 'float',
  },
  'metrics.rs_right_dropped_double_count': {
    field: 'metrics.rs_right_dropped_double_count',
    type: 'long',
  },
  'metrics.rs_right_dropped_double_percent': {
    field: 'metrics.rs_right_dropped_double_percent',
    type: 'long',
  },
  'metrics.rs_right_dropped_single_count': {
    field: 'metrics.rs_right_dropped_single_count',
    type: 'long',
  },
  'metrics.rs_right_dropped_single_percent': {
    field: 'metrics.rs_right_dropped_single_percent',
    type: 'long',
  },
  'metrics.rs_right_rate_avg': {
    field: 'metrics.rs_right_rate_avg',
    type: 'float',
  },
  'metrics.rs_right_rate_min': {
    field: 'metrics.rs_right_rate_min',
    type: 'float',
  },
  'metrics.short_redundant_planning_legs': {
    field: 'metrics.short_redundant_planning_legs',
    type: 'long',
  },
  'metrics.short_redundant_travel_legs': {
    field: 'metrics.short_redundant_travel_legs',
    type: 'long',
  },
  'metrics.short_reexploration_legs': {
    field: 'metrics.short_reexploration_legs',
    type: 'long',
  },
  'metrics.sonar_bottom_diff_max': {
    field: 'metrics.sonar_bottom_diff_max',
    type: 'float',
  },
  'metrics.sonar_bottom_dropped_double_count': {
    field: 'metrics.sonar_bottom_dropped_double_count',
    type: 'long',
  },
  'metrics.sonar_bottom_dropped_double_percent': {
    field: 'metrics.sonar_bottom_dropped_double_percent',
    type: 'long',
  },
  'metrics.sonar_bottom_dropped_single_count': {
    field: 'metrics.sonar_bottom_dropped_single_count',
    type: 'long',
  },
  'metrics.sonar_bottom_dropped_single_percent': {
    field: 'metrics.sonar_bottom_dropped_single_percent',
    type: 'long',
  },
  'metrics.sonar_bottom_rate_avg': {
    field: 'metrics.sonar_bottom_rate_avg',
    type: 'float',
  },
  'metrics.sonar_bottom_rate_min': {
    field: 'metrics.sonar_bottom_rate_min',
    type: 'float',
  },
  'metrics.startup_time_containerd': {
    field: 'metrics.startup_time_containerd',
    type: 'float',
  },
  'metrics.startup_time_dockerd': {
    field: 'metrics.startup_time_dockerd',
    type: 'float',
  },
  'metrics.startup_time_hme_ready': {
    field: 'metrics.startup_time_hme_ready',
    type: 'long',
  },
  'metrics.startup_time_kernel': {
    field: 'metrics.startup_time_kernel',
    type: 'float',
  },
  'metrics.startup_time_missing_events': {
    field: 'metrics.startup_time_missing_events',
    type: 'long',
  },
  'metrics.startup_time_radio_local_ready': {
    field: 'metrics.startup_time_radio_local_ready',
    type: 'long',
  },
  'metrics.startup_time_radio_remote_ready': {
    field: 'metrics.startup_time_radio_remote_ready',
    type: 'long',
  },
  'metrics.startup_time_ready_to_start': {
    field: 'metrics.startup_time_ready_to_start',
    type: 'float',
  },
  'metrics.startup_time_run': {
    field: 'metrics.startup_time_run',
    type: 'float',
  },
  'metrics.startup_time_runc': {
    field: 'metrics.startup_time_runc',
    type: 'float',
  },
  'metrics.startup_time_vision_pipeline_ready': {
    field: 'metrics.startup_time_vision_pipeline_ready',
    type: 'long',
  },
  'metrics.teleop_max_controls_response_time': {
    field: 'metrics.teleop_max_controls_response_time',
    type: 'float',
  },
  'metrics.teleop_max_perceived_latency': {
    field: 'metrics.teleop_max_perceived_latency',
    type: 'float',
  },
  'metrics.teleop_max_planning_time': {
    field: 'metrics.teleop_max_planning_time',
    type: 'float',
  },
  'metrics.teleop_max_trajectory_ramp_up_time': {
    field: 'metrics.teleop_max_trajectory_ramp_up_time',
    type: 'float',
  },
  'metrics.teleop_mean': {
    field: 'metrics.teleop_mean',
    type: 'float',
  },
  'metrics.teleop_mean_controls_response_time': {
    field: 'metrics.teleop_mean_controls_response_time',
    type: 'float',
  },
  'metrics.teleop_mean_perceived_latency': {
    field: 'metrics.teleop_mean_perceived_latency',
    type: 'float',
  },
  'metrics.teleop_mean_planning_time': {
    field: 'metrics.teleop_mean_planning_time',
    type: 'float',
  },
  'metrics.teleop_mean_trajectory_ramp_up_time': {
    field: 'metrics.teleop_mean_trajectory_ramp_up_time',
    type: 'float',
  },
  'metrics.teleop_sigma1': {
    field: 'metrics.teleop_sigma1',
    type: 'float',
  },
  'metrics.teleop_sigma3': {
    field: 'metrics.teleop_sigma3',
    type: 'float',
  },
  'metrics.thermal_rate_avg': {
    field: 'metrics.thermal_rate_avg',
    type: 'long',
  },
  'metrics.thermal_rate_min': {
    field: 'metrics.thermal_rate_min',
    type: 'long',
  },
  'metrics.thermistors_diff_max': {
    field: 'metrics.thermistors_diff_max',
    type: 'float',
  },
  'metrics.thermistors_dropped_double_count': {
    field: 'metrics.thermistors_dropped_double_count',
    type: 'long',
  },
  'metrics.thermistors_dropped_double_percent': {
    field: 'metrics.thermistors_dropped_double_percent',
    type: 'long',
  },
  'metrics.thermistors_dropped_single_count': {
    field: 'metrics.thermistors_dropped_single_count',
    type: 'long',
  },
  'metrics.thermistors_dropped_single_percent': {
    field: 'metrics.thermistors_dropped_single_percent',
    type: 'long',
  },
  'metrics.thermistors_rate_avg': {
    field: 'metrics.thermistors_rate_avg',
    type: 'float',
  },
  'metrics.thermistors_rate_min': {
    field: 'metrics.thermistors_rate_min',
    type: 'float',
  },
  'metrics.time_to_curate': {
    field: 'metrics.time_to_curate',
    type: 'float',
  },
  'metrics.time_to_curate_per_sample': {
    field: 'metrics.time_to_curate_per_sample',
    type: 'float',
  },
  'metrics.time_to_harvest': {
    field: 'metrics.time_to_harvest',
    type: 'float',
  },
  'metrics.time_to_harvest_per_sample': {
    field: 'metrics.time_to_harvest_per_sample',
    type: 'float',
  },
  'metrics.tmc_error_x_95_percentile': {
    field: 'metrics.tmc_error_x_95_percentile',
    type: 'float',
  },
  'metrics.tmc_max_acc': {
    field: 'metrics.tmc_max_acc',
    type: 'float',
  },
  'metrics.tmc_max_delta_toa': {
    field: 'metrics.tmc_max_delta_toa',
    type: 'float',
  },
  'metrics.tmc_max_delta_tov': {
    field: 'metrics.tmc_max_delta_tov',
    type: 'float',
  },
  'metrics.tmc_max_jrk': {
    field: 'metrics.tmc_max_jrk',
    type: 'float',
  },
  'metrics.tmc_max_pitch': {
    field: 'metrics.tmc_max_pitch',
    type: 'float',
  },
  'metrics.tmc_max_pitch_rate': {
    field: 'metrics.tmc_max_pitch_rate',
    type: 'float',
  },
  'metrics.tmc_max_pos_error_xy': {
    field: 'metrics.tmc_max_pos_error_xy',
    type: 'float',
  },
  'metrics.tmc_max_pos_error_xyz': {
    field: 'metrics.tmc_max_pos_error_xyz',
    type: 'float',
  },
  'metrics.tmc_max_roll': {
    field: 'metrics.tmc_max_roll',
    type: 'float',
  },
  'metrics.tmc_max_roll_rate': {
    field: 'metrics.tmc_max_roll_rate',
    type: 'float',
  },
  'metrics.tmc_max_vel': {
    field: 'metrics.tmc_max_vel',
    type: 'float',
  },
  'metrics.tmc_max_yaw_rate': {
    field: 'metrics.tmc_max_yaw_rate',
    type: 'float',
  },
  'metrics.tmc_mean_acc': {
    field: 'metrics.tmc_mean_acc',
    type: 'float',
  },
  'metrics.tmc_mean_delta_toa': {
    field: 'metrics.tmc_mean_delta_toa',
    type: 'float',
  },
  'metrics.tmc_mean_delta_tov': {
    field: 'metrics.tmc_mean_delta_tov',
    type: 'float',
  },
  'metrics.tmc_mean_jrk': {
    field: 'metrics.tmc_mean_jrk',
    type: 'float',
  },
  'metrics.tmc_mean_pitch': {
    field: 'metrics.tmc_mean_pitch',
    type: 'float',
  },
  'metrics.tmc_mean_pitch_rate': {
    field: 'metrics.tmc_mean_pitch_rate',
    type: 'float',
  },
  'metrics.tmc_mean_pos_error_xy': {
    field: 'metrics.tmc_mean_pos_error_xy',
    type: 'float',
  },
  'metrics.tmc_mean_pos_error_xyz': {
    field: 'metrics.tmc_mean_pos_error_xyz',
    type: 'float',
  },
  'metrics.tmc_mean_roll': {
    field: 'metrics.tmc_mean_roll',
    type: 'float',
  },
  'metrics.tmc_mean_roll_rate': {
    field: 'metrics.tmc_mean_roll_rate',
    type: 'float',
  },
  'metrics.tmc_mean_vel': {
    field: 'metrics.tmc_mean_vel',
    type: 'float',
  },
  'metrics.tmc_mean_yaw_rate': {
    field: 'metrics.tmc_mean_yaw_rate',
    type: 'float',
  },
  'metrics.tmc_measured_max_pitch': {
    field: 'metrics.tmc_measured_max_pitch',
    type: 'float',
  },
  'metrics.tmc_measured_max_pitch_rate': {
    field: 'metrics.tmc_measured_max_pitch_rate',
    type: 'float',
  },
  'metrics.tmc_measured_max_roll': {
    field: 'metrics.tmc_measured_max_roll',
    type: 'float',
  },
  'metrics.tmc_measured_max_roll_rate': {
    field: 'metrics.tmc_measured_max_roll_rate',
    type: 'float',
  },
  'metrics.tmc_measured_max_yaw_rate': {
    field: 'metrics.tmc_measured_max_yaw_rate',
    type: 'float',
  },
  'metrics.tmc_measured_mean_pitch': {
    field: 'metrics.tmc_measured_mean_pitch',
    type: 'float',
  },
  'metrics.tmc_measured_mean_pitch_rate': {
    field: 'metrics.tmc_measured_mean_pitch_rate',
    type: 'float',
  },
  'metrics.tmc_measured_mean_roll': {
    field: 'metrics.tmc_measured_mean_roll',
    type: 'float',
  },
  'metrics.tmc_measured_mean_roll_rate': {
    field: 'metrics.tmc_measured_mean_roll_rate',
    type: 'float',
  },
  'metrics.tmc_measured_mean_yaw_rate': {
    field: 'metrics.tmc_measured_mean_yaw_rate',
    type: 'float',
  },
  'metrics.tmc_pos_error_x_95_percentile': {
    field: 'metrics.tmc_pos_error_x_95_percentile',
    type: 'float',
  },
  'metrics.tmc_pos_error_xy_95_percentile': {
    field: 'metrics.tmc_pos_error_xy_95_percentile',
    type: 'float',
  },
  'metrics.tmc_pos_error_xyz_95_percentile': {
    field: 'metrics.tmc_pos_error_xyz_95_percentile',
    type: 'float',
  },
  'metrics.tmc_pos_error_y_95_percentile': {
    field: 'metrics.tmc_pos_error_y_95_percentile',
    type: 'float',
  },
  'metrics.tmc_pos_error_z_95_percentile': {
    field: 'metrics.tmc_pos_error_z_95_percentile',
    type: 'float',
  },
  'metrics.tmc_setpoint_jump_max_pos': {
    field: 'metrics.tmc_setpoint_jump_max_pos',
    type: 'float',
  },
  'metrics.tmc_setpoint_jump_max_yaw': {
    field: 'metrics.tmc_setpoint_jump_max_yaw',
    type: 'float',
  },
  'metrics.tmc_setpoint_max_acc': {
    field: 'metrics.tmc_setpoint_max_acc',
    type: 'float',
  },
  'metrics.tmc_setpoint_max_delta_toa': {
    field: 'metrics.tmc_setpoint_max_delta_toa',
    type: 'float',
  },
  'metrics.tmc_setpoint_max_delta_tov': {
    field: 'metrics.tmc_setpoint_max_delta_tov',
    type: 'float',
  },
  'metrics.tmc_setpoint_max_jrk': {
    field: 'metrics.tmc_setpoint_max_jrk',
    type: 'float',
  },
  'metrics.tmc_setpoint_max_vel': {
    field: 'metrics.tmc_setpoint_max_vel',
    type: 'float',
  },
  'metrics.tmc_setpoint_max_yaw_rate': {
    field: 'metrics.tmc_setpoint_max_yaw_rate',
    type: 'float',
  },
  'metrics.tmc_setpoint_mean_acc': {
    field: 'metrics.tmc_setpoint_mean_acc',
    type: 'float',
  },
  'metrics.tmc_setpoint_mean_delta_toa': {
    field: 'metrics.tmc_setpoint_mean_delta_toa',
    type: 'float',
  },
  'metrics.tmc_setpoint_mean_delta_tov': {
    field: 'metrics.tmc_setpoint_mean_delta_tov',
    type: 'float',
  },
  'metrics.tmc_setpoint_mean_jrk': {
    field: 'metrics.tmc_setpoint_mean_jrk',
    type: 'float',
  },
  'metrics.tmc_setpoint_mean_vel': {
    field: 'metrics.tmc_setpoint_mean_vel',
    type: 'float',
  },
  'metrics.tmc_setpoint_mean_yaw_rate': {
    field: 'metrics.tmc_setpoint_mean_yaw_rate',
    type: 'float',
  },
  'metrics.tmc_test': {
    field: 'metrics.tmc_test',
    type: 'long',
  },
  'metrics.total_alt_variation': {
    field: 'metrics.total_alt_variation',
    type: 'float',
  },
  'metrics.total_cleared_volume': {
    field: 'metrics.total_cleared_volume',
    type: 'float',
  },
  'metrics.total_redundant_planning_flight_dist': {
    field: 'metrics.total_redundant_planning_flight_dist',
    type: 'long',
  },
  'metrics.total_redundant_travel_flight_dist': {
    field: 'metrics.total_redundant_travel_flight_dist',
    type: 'float',
  },
  'metrics.total_reexploration_flight_dist': {
    field: 'metrics.total_reexploration_flight_dist',
    type: 'long',
  },
  'metrics.total_volume': {
    field: 'metrics.total_volume',
    type: 'float',
  },
  name: {
    field: 'name',
    type: 'keyword',
  },
  notes: {
    field: 'notes',
    type: 'text',
  },
  'parents.id': {
    field: 'parents.id',
    type: 'keyword',
  },
  'parents.name': {
    field: 'parents.name',
    type: 'keyword',
  },
  'participant_observers.id': {
    field: 'participant_observers.id',
    type: 'keyword',
  },
  'participant_observers.name': {
    field: 'participant_observers.name',
    type: 'text',
  },
  'participant_observers.name.keyword': {
    field: 'participant_observers.name.keyword',
    type: 'keyword',
  },
  'participant_pilots.id': {
    field: 'participant_pilots.id',
    type: 'keyword',
  },
  'participant_pilots.name': {
    field: 'participant_pilots.name',
    type: 'text',
  },
  'participant_pilots.name.keyword': {
    field: 'participant_pilots.name.keyword',
    type: 'keyword',
  },
  participants: {
    field: 'participants',
    type: 'nested',
  },
  'participants.participant_type.description': {
    field: 'participants.participant_type.description',
    type: 'text',
  },
  'participants.participant_type.name': {
    field: 'participants.participant_type.name',
    type: 'text',
  },
  'participants.participant_type.slug': {
    field: 'participants.participant_type.slug',
    type: 'keyword',
  },
  'participants.source_type': {
    field: 'participants.source_type',
    type: 'keyword',
  },
  'participants.team_freeform': {
    field: 'participants.team_freeform',
    type: 'text',
  },
  'participants.team_id': {
    field: 'participants.team_id',
    type: 'keyword',
  },
  'participants.user_freeform': {
    field: 'participants.user_freeform',
    type: 'text',
  },
  'participants.user_id': {
    field: 'participants.user_id',
    type: 'keyword',
  },
  'phone_software_info.id': {
    field: 'phone_software_info.id',
    type: 'keyword',
  },
  'phone_software_info.version': {
    field: 'phone_software_info.version',
    type: 'keyword',
  },
  'phones.id': {
    field: 'phones.id',
    type: 'keyword',
  },
  'phones.name': {
    field: 'phones.name',
    type: 'text',
  },
  'phones.name.keyword': {
    field: 'phones.name.keyword',
    type: 'keyword',
  },
  'phones.serial_number': {
    field: 'phones.serial_number',
    type: 'keyword',
  },
  robot_log_data: {
    field: 'robot_log_data',
    type: 'flattened',
  },
  robot_log_reviews: {
    field: 'robot_log_reviews',
    type: 'nested',
  },
  'robot_log_reviews.requester.id': {
    field: 'robot_log_reviews.requester.id',
    type: 'keyword',
  },
  'robot_log_reviews.reviewer.id': {
    field: 'robot_log_reviews.reviewer.id',
    type: 'keyword',
  },
  'robot_log_reviews.reviewer_team.id': {
    field: 'robot_log_reviews.reviewer_team.id',
    type: 'keyword',
  },
  'robot_log_reviews.status': {
    field: 'robot_log_reviews.status',
    type: 'keyword',
  },
  'robot_log_test.code': {
    field: 'robot_log_test.code',
    type: 'text',
  },
  'robot_log_test.code.keyword': {
    field: 'robot_log_test.code.keyword',
    type: 'keyword',
  },
  'robot_log_test.id': {
    field: 'robot_log_test.id',
    type: 'keyword',
  },
  'robot_log_test.name': {
    field: 'robot_log_test.name',
    type: 'text',
  },
  'robot_log_test.name.keyword': {
    field: 'robot_log_test.name.keyword',
    type: 'keyword',
  },
  'robot_log_test.test_code': {
    field: 'robot_log_test.test_code',
    type: 'keyword',
  },
  robot_log_type: {
    field: 'robot_log_type',
    type: 'keyword',
  },
  'robots.generation': {
    field: 'robots.generation',
    type: 'keyword',
  },
  'robots.hostname': {
    field: 'robots.hostname',
    type: 'keyword',
  },
  'robots.id': {
    field: 'robots.id',
    type: 'keyword',
  },
  'robots.name': {
    field: 'robots.name',
    type: 'text',
  },
  'robots.name.keyword': {
    field: 'robots.name.keyword',
    type: 'keyword',
  },
  'robots.product': {
    field: 'robots.product',
    type: 'text',
  },
  'robots.product.keyword': {
    field: 'robots.product.keyword',
    type: 'keyword',
  },
  'software_info.branch_name': {
    field: 'software_info.branch_name',
    type: 'keyword',
  },
  'software_info.commit_hash': {
    field: 'software_info.commit_hash',
    type: 'keyword',
  },
  'software_info.version': {
    field: 'software_info.version',
    type: 'text',
  },
  'software_info.version.keyword': {
    field: 'software_info.version.keyword',
    type: 'keyword',
  },
  start_time: {
    field: 'start_time',
    type: 'date',
  },
  start_time_pst_hour_of_day: {
    field: 'start_time_pst_hour_of_day',
    type: 'long',
  },
  'test_definition.code': {
    field: 'test_definition.code',
    type: 'text',
  },
  'test_definition.code.keyword': {
    field: 'test_definition.code.keyword',
    type: 'keyword',
  },
  'test_definition.environments.id': {
    field: 'test_definition.environments.id',
    type: 'keyword',
  },
  'test_definition.environments.name': {
    field: 'test_definition.environments.name',
    type: 'keyword',
  },
  'test_definition.estimated_execution_duration': {
    field: 'test_definition.estimated_execution_duration',
    type: 'long',
  },
  'test_definition.id': {
    field: 'test_definition.id',
    type: 'keyword',
  },
  'test_definition.name': {
    field: 'test_definition.name',
    type: 'keyword',
  },
  'test_definition.ownership.teams.name': {
    field: 'test_definition.ownership.teams.name',
    type: 'keyword',
  },
  'test_definition.ownership.users.name': {
    field: 'test_definition.ownership.users.name',
    type: 'keyword',
  },
  'test_definition.platform.abbreviation': {
    field: 'test_definition.platform.abbreviation',
    type: 'keyword',
  },
  'test_definition.platform.id': {
    field: 'test_definition.platform.id',
    type: 'keyword',
  },
  'test_definition.platform.name': {
    field: 'test_definition.platform.name',
    type: 'keyword',
  },
  'test_definition.platform_metadata': {
    field: 'test_definition.platform_metadata',
    type: 'object',
  },
  'test_definition.platform_subtype.abbreviation': {
    field: 'test_definition.platform_subtype.abbreviation',
    type: 'keyword',
  },
  'test_definition.platform_subtype.name': {
    field: 'test_definition.platform_subtype.name',
    type: 'keyword',
  },
  'test_definition.requirements': {
    field: 'test_definition.requirements',
    type: 'nested',
  },
  'test_definition.requirements.metadata': {
    field: 'test_definition.requirements.metadata',
    type: 'object',
  },
  'test_definition.requirements.name': {
    field: 'test_definition.requirements.name',
    type: 'keyword',
  },
  'test_definition.requirements.project.display_name': {
    field: 'test_definition.requirements.project.display_name',
    type: 'keyword',
  },
  'test_definition.requirements.project.name': {
    field: 'test_definition.requirements.project.name',
    type: 'keyword',
  },
  'test_definition.requirements.requirement_type': {
    field: 'test_definition.requirements.requirement_type',
    type: 'keyword',
  },
  'test_definition.requirements.state': {
    field: 'test_definition.requirements.state',
    type: 'keyword',
  },
  'test_definition.test_frequency_group_details': {
    field: 'test_definition.test_frequency_group_details',
    type: 'nested',
  },
  'test_definition.test_frequency_group_details.minimum_execution_count': {
    field:
      'test_definition.test_frequency_group_details.minimum_execution_count',
    type: 'integer',
  },
  'test_definition.test_frequency_group_details.preferred_execution_count': {
    field:
      'test_definition.test_frequency_group_details.preferred_execution_count',
    type: 'integer',
  },
  'test_definition.test_frequency_group_details.test_frequency_group.name': {
    field:
      'test_definition.test_frequency_group_details.test_frequency_group.name',
    type: 'keyword',
  },
  'test_evaluation.evaluation_state': {
    field: 'test_evaluation.evaluation_state',
    type: 'keyword',
  },
  'test_evaluation.last_input_time': {
    field: 'test_evaluation.last_input_time',
    type: 'date',
  },
};
