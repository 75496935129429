import { useState } from 'react';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from '@shield-ui/styles';
import { Artifact } from '@hmd/sdk/api/artifacts/v1';

type Props = {
  artifact: Artifact.AsObject;
};

const useStyles = makeStyles((theme) => ({
  iframe: {
    width: '100%',
    height: '100%',
    minHeight: 400,
  },
  wrapperFullscreen: {
    zIndex: 99999,
    position: 'fixed',
    height: '99vh',
    width: '99vw',
    margin: '0.5vh 0.5vw',
    top: 0,
    left: 0,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(0.5, 1),
    backgroundColor: colors.hues.grays[10],
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  wrapperEmbedded: {},
}));

export default function TraceEventsJSON({ artifact }: Props) {
  const classes = useStyles();
  const [isFullscreen, setFullscreen] = useState(false);

  const iframeUrl = `/assets/trace-viewer-embed.html?traceJsonFileUrl=${encodeURIComponent(
    artifact.publicUri.replace(/\+/g, '%2B')
  )}`;

  return (
    <div
      className={cx(
        classes.wrapper,
        isFullscreen ? classes.wrapperFullscreen : classes.wrapperEmbedded
      )}
    >
      <div className={classes.actions}>
        {/*
        <button onClick={() => window.open(iframeUrl)}>
          Open in New Window
        </button>
        */}
        <button onClick={() => setFullscreen(!isFullscreen)}>
          {isFullscreen ? 'Exit ' : ''}Fullscreen
        </button>
      </div>
      {!iframeUrl && <div>Fetching signed url for artifact</div>}
      {iframeUrl && (
        <iframe
          title="CPU Timings"
          className={classes.iframe}
          src={iframeUrl}
        />
      )}
    </div>
  );
}
