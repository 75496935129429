import _ from 'lodash';
import { PipelineGroup, PipelineRun, SolidHandleTree } from '../data';
import { doesInputMatchBase } from '@shield-ui/utils';

export function filterPipelineRuns(
  solidTrees: PipelineRun[] | SolidHandleTree[],
  searchString
) {
  if (!searchString) {
    return solidTrees;
  }

  return solidTrees.filter((solidTree) => {
    const { outputs, solids } = solidTree;

    const m = _.find(outputs, (output) =>
      doesInputMatchBase(searchString, output.name)
    );
    const s = filterPipelineRuns(solids, searchString);

    return m || s.length;
  });
}

export function filterGroups(groups: PipelineGroup[], searchString) {
  if (!searchString) {
    return groups;
  }

  return groups.filter((group) => {
    const { label, pipelineRuns } = group;
    if (doesInputMatchBase(searchString, label)) {
      return true;
    }
    return filterPipelineRuns(pipelineRuns, searchString).length > 0;
  });
}
