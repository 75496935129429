import React from 'react';
import _ from 'lodash';
import { gql, useQuery } from '@apollo/client';
import { LoadingIndicator, ErrorMessageContent } from '@shield-ui/core';
import { noteFragment } from '../../../services/fragments';
import MainContent from './MainContent';

const query = gql`
 query($robotLogId:String!){
      robotLog(id: $robotLogId) {
        id
        name
        sessionLogTypeSlug
        notes (sort: created_at_asc) {
          edges {
            node {
              ${noteFragment}
            }
          }
        }
        children {
           name
           id
           sessionLogTypeSlug
           notes (sort: created_at_asc) {
              edges {
                node {
                  ${noteFragment}
                }
              }
           }
        }
      }
    }
`;

export default function NotesSection({ robotLogId }) {
  const { loading, error, data, refetch } = useQuery(query, {
    variables: { robotLogId },
    fetchPolicy: 'cache-first',
  });

  if (loading) {
    return <LoadingIndicator />;
  }
  if (error) {
    return <ErrorMessageContent>{error.message}</ErrorMessageContent>;
  }

  return <MainContent robotLogId={robotLogId} refetch={refetch} data={data} />;
}
