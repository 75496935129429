import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './range.scss';
import { colors } from '@shield-ui/styles';

const styles = {
  container: { padding: '8px 20px 18px 20px' },
  trackStyle: [
    { backgroundColor: colors.hues.gray },
    { backgroundColor: colors.hues.gray },
  ],
};

// Pure component because of the hack mentioned below
class Range extends React.PureComponent {
  static defaultProps = {
    min: 0,
    max: 100,
  };
  static propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    value: PropTypes.shape({
      gte: PropTypes.number,
      lte: PropTypes.number,
    }),
    onAfterChange: PropTypes.func,
    tipFormatter: PropTypes.func,
    disabled: PropTypes.bool,
  };

  render() {
    const { value, min, max, ...rest } = this.props;

    // XXX-Hack this component is weird, it's not handling controlled state very well using value
    // This means we are instantiating a new slider each time this renders and just setting the defaultValue
    // I don't like it, but it works
    const SliderRange = Slider.createSliderWithTooltip(Slider.Range);

    const { container: containerStyle, ...otherStyles } = styles;

    return (
      <div style={containerStyle}>
        <SliderRange
          {...otherStyles}
          min={min}
          max={max}
          {...rest}
          defaultValue={[_.get(value, 'gte', min), _.get(value, 'lte', max)]}
        />
      </div>
    );
  }
}

export default Range;
