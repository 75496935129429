import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import { DatePicker, Input, Select } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { colors } from '@shield-ui/styles';
import { AntButton } from '@shield-ui/buttons';
import {
  DatetimeAbsoluteInputMode,
  DatetimeMode,
  DatetimeQueryFilter,
  DatetimeQueryFilterControlData,
  DatetimeQueryFilterValue,
} from '../../filterTypes';
import { ControlProps } from '../common';
import SliderIntegerStep from '../shared/SliderIntegerStep';
import ResetButton from '../shared/ResetButton';
import ActionsContainer from '../shared/ActionsContainer';
import MiniTabsController from '../shared/MiniTabsController';

interface Props
  extends ControlProps<DatetimeQueryFilterValue>,
    DatetimeQueryFilterControlData {
  queryFilter: DatetimeQueryFilter;
}

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: 380,
  },
  tabContent: {
    marginTop: theme.spacing(3),
  },
  modeSelect: {
    marginBottom: theme.spacing(3),
  },
  datetimeRow: {
    display: 'flex',
    alignItems: 'center',
    height: 36,
    margin: theme.spacing(2, 0),
  },
  datetimeLabel: {
    width: 40,
    textAlign: 'right',
    color: colors.hues.grays[80],
    marginRight: theme.spacing(2),
  },
  dateInput: {
    width: 240,
  },
  unixPreview: {
    width: 180,
    marginLeft: theme.spacing(1),
    color: colors.hues.grays[60],
    fontSize: 12.5,
    height: 14,
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(2, 1),
    '& > button': {
      margin: theme.spacing(0, 0.5),
    },
  },
  relativeDateHint: {
    color: colors.hues.grays[80],
    fontSize: 13,
    textAlign: 'center',
    paddingTop: theme.spacing(2),
  },
  sliderLabel: {
    marginTop: theme.spacing(1),
    fontWeight: 500,
    letterSpacing: '0.05rem',
  },
  absoluteModeSelect: {
    minWidth: 120,
  },
}));

function getDatePickerOnChange(
  key: keyof DatetimeQueryFilterValue,
  timezone,
  onChange: Props['onChange']
) {
  return (m) => {
    let value;

    if (m) {
      const tzOffset = moment().utcOffset();
      const off = timezone === DatetimeAbsoluteInputMode.local ? 0 : tzOffset;
      value = m
        .utc()
        .add(off, 'minutes')
        .format(DatetimeQueryFilter.dateFormat);
    }

    onChange({ [key]: value });
  };
}

function getDatePickerValue(
  timestamp: string | void,
  timezone: DatetimeAbsoluteInputMode
): moment.Moment {
  if (!timestamp) {
    return;
  }

  const tzOffset = moment().utcOffset();
  const off = timezone === DatetimeAbsoluteInputMode.local ? 0 : tzOffset;
  return moment(timestamp, DatetimeQueryFilter.dateFormat).subtract(
    off,
    'minutes'
  );
}

function getRelativeDateHintText(
  value: DatetimeQueryFilterValue,
  format: string
) {
  if (!value) {
    return '';
  }

  const { relativeNumberOfDays, relativeStartDaysAgo = 0 } = value;
  if (!relativeNumberOfDays) {
    return '';
  }

  const from = moment()
    .subtract(relativeNumberOfDays + relativeStartDaysAgo, 'days')
    .format(format);
  const to =
    relativeStartDaysAgo > 0
      ? moment().subtract(relativeStartDaysAgo, 'days').format(format)
      : 'Now';

  return `Searching ${from} to ${to}`;
}

function getModeDisplay(mode: DatetimeMode): string {
  if (mode === DatetimeMode.absolute) {
    return 'Absolute';
  } else if (mode === DatetimeMode.relative) {
    return 'Relative';
  }
  return _.upperFirst(mode);
}

export default function DatetimeQueryFilterControl(props: Props) {
  const classes = useStyles();

  const {
    onChange,
    value = {},
    modes,
    displayDateFormat,
    getValueMode,
    getValueAbsoluteInputMode,
    hasValue,
    onClear,
  } = props;
  const activeMode = getValueMode(value);
  const absoluteInputMode = getValueAbsoluteInputMode(value);

  const renderModes = {
    [DatetimeMode.relative]: function renderRelative() {
      return (
        <div className={classes.tabContent}>
          <div className={classes.buttonGroup}>
            <AntButton
              onClick={() => {
                onChange({
                  relativeNumberOfDays: 7,
                  relativeStartDaysAgo: undefined,
                });
              }}
            >
              This Week
            </AntButton>
            <AntButton
              onClick={() => {
                onChange({ relativeNumberOfDays: 7, relativeStartDaysAgo: 7 });
              }}
            >
              Last Week
            </AntButton>
            <AntButton
              onClick={() => {
                onChange({
                  relativeNumberOfDays: 30,
                  relativeStartDaysAgo: undefined,
                });
              }}
            >
              This Month
            </AntButton>
          </div>
          <div className={classes.sliderLabel}>Number of Days</div>
          <SliderIntegerStep
            min={0}
            max={100}
            value={value.relativeNumberOfDays}
            onChange={(v) => onChange({ relativeNumberOfDays: v })}
          />
          <div className={classes.sliderLabel}>Offset Days</div>
          <SliderIntegerStep
            min={0}
            max={100}
            value={value.relativeStartDaysAgo}
            onChange={(v) => onChange({ relativeStartDaysAgo: v })}
          />
          <div className={classes.relativeDateHint}>
            {getRelativeDateHintText(value, displayDateFormat)}
          </div>
        </div>
      );
    },
    [DatetimeMode.absolute]: function renderAbsolute() {
      const renderUnixRow = ({
        key,
        label,
        placeholder,
      }: {
        key: keyof Pick<
          DatetimeQueryFilterValue,
          'startDatetime' | 'endDatetime'
        >;
        label: string;
        placeholder?: string;
      }) => {
        const v = value[key];
        let preview, inputValue;
        if (v) {
          const mom = moment.utc(v);
          preview = mom.local().format('MM.DD.YYYY HH:mm:ss');
          inputValue = mom.unix();
        }

        return (
          <div className={classes.datetimeRow}>
            <label className={classes.datetimeLabel}>{label}</label>
            <Input
              type="number"
              size="large"
              className={classes.dateInput}
              value={inputValue}
              placeholder={placeholder}
              onChange={(evt) => {
                const { value } = evt.target;
                if (value) {
                  const unix = parseInt(value, 10);
                  onChange({
                    [key]: moment
                      .unix(unix)
                      .format(DatetimeQueryFilter.dateFormat),
                  });
                } else {
                  onChange({
                    [key]: undefined,
                  });
                }
              }}
            />
            <div className={classes.unixPreview}>{preview}</div>
          </div>
        );
      };

      const renderPickerRow = ({
        key,
        label,
        placeholder,
      }: {
        key: keyof Pick<
          DatetimeQueryFilterValue,
          'startDatetime' | 'endDatetime'
        >;
        label: string;
        placeholder?: string;
      }) => {
        return (
          <div className={classes.datetimeRow}>
            <label className={classes.datetimeLabel}>{label}</label>
            <DatePicker
              className={classes.dateInput}
              placeholder={placeholder}
              format={displayDateFormat}
              value={getDatePickerValue(value[key], absoluteInputMode)}
              onChange={getDatePickerOnChange(key, absoluteInputMode, onChange)}
              showTime={{ format: 'HH:mm' }}
              size={'large' as SizeType}
            />
          </div>
        );
      };

      return (
        <div className={classes.tabContent}>
          {absoluteInputMode === DatetimeAbsoluteInputMode.unix ? (
            <>
              {renderUnixRow({
                key: 'startDatetime',
                label: 'From',
                placeholder: `Start Unix e.g. 1634329355`,
              })}
              {renderUnixRow({
                key: 'endDatetime',
                label: 'To',
                placeholder: `End Unix e.g. 1634329819`,
              })}
            </>
          ) : (
            <>
              {renderPickerRow({
                key: 'startDatetime',
                label: 'From',
                placeholder: 'Start Date',
              })}
              {renderPickerRow({
                key: 'endDatetime',
                label: 'To',
                placeholder: 'End Date',
              })}
            </>
          )}
          <div className={classes.datetimeRow}>
            <label className={classes.datetimeLabel} />
            <Select
              className={classes.absoluteModeSelect}
              options={[
                {
                  label: 'Local Time',
                  value: DatetimeAbsoluteInputMode.local,
                },
                {
                  label: 'UTC Time',
                  value: DatetimeAbsoluteInputMode.utc,
                },
                {
                  label: 'Unix Epoch',
                  value: DatetimeAbsoluteInputMode.unix,
                },
              ]}
              onChange={(v: DatetimeAbsoluteInputMode) =>
                onChange({ absoluteInputMode: v })
              }
              value={absoluteInputMode}
            />
          </div>
        </div>
      );
    },
  };

  return (
    <div className={classes.container}>
      <MiniTabsController
        items={modes.map((mode) => ({
          label: getModeDisplay(mode),
          value: mode,
          renderContent:
            renderModes[mode] ||
            function () {
              return null;
            },
        }))}
        value={activeMode}
        onChange={(v) => onChange({ mode: v })}
      />
      <ActionsContainer
        Right={<ResetButton onClick={onClear} disabled={!hasValue} />}
      />
    </div>
  );
}
