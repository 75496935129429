import Cell from './Cell';
import getValue from './getValue';

const config = {
  columnKey: 'participants',
  label: 'Participants',
  description: 'Participants in a flight',
  Cell,
  getValue,
  fragment: `
    participants {
        edges {
            node { 
              participantRole           
              user {
                name
              }
              userFreeform
            }
        }
    }
    `,
};

export default config;
