import { connect } from 'react-redux';
import { getCauseOptions } from '../../../redux/lists/selectors';

function mapStateToProps(state) {
  return {
    causeOptions: getCauseOptions(state),
  };
}

export default connect(mapStateToProps);
