import RobotLogsTable from '../../../components/tables/RobotLogsTable';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import memoizeOne from 'memoize-one';

interface Props {
  robotLogId: string;
}

const useStyles = makeStyles({
  tableHeader: {
    fontWeight: 500,
    marginLeft: 6,
  },
  tableContainer: {
    paddingTop: 6,
    paddingBottom: 30,
  },
});

export const RelationshipsSectionFlights: React.FC<Props> = ({
  robotLogId,
}) => {
  const classes = useStyles();

  const getChildrenQuery = memoizeOne((robotLogId) => {
    return {
      bool: {
        should: [
          {
            term: {
              'parents.id': robotLogId,
            },
          },
        ],
      },
    };
  });

  const getParentsQuery = memoizeOne((robotLogId) => {
    return {
      bool: {
        should: [
          {
            term: {
              'children.id': robotLogId,
            },
          },
        ],
      },
    };
  });

  const columns = [
    { columnKey: 'name' },
    { columnKey: 'robotLogType' }, //content type
    { columnKey: 'flightTimeLocal' }, //start & end time
    { columnKey: 'totalTime' }, //duration
    { columnKey: 'labels' }, //tags
    { columnKey: 'softwareInfo' }, //branch
    { columnKey: 'softwareCommitHash' }, //commit hash
  ];

  return (
    <div>
      <Typography className={classes.tableHeader} variant="overline">
        Parents
      </Typography>
      <div className={classes.tableContainer}>
        <RobotLogsTable
          skipControls={true}
          additionalElasticQueries={[getParentsQuery(robotLogId)]}
          columns={columns}
          tableCacheKey={'parent-relationships'}
          overrideEmptyTableMsg={'No Parents'}
          enableTopToolbar={false}
        />
      </div>

      <Typography className={classes.tableHeader} variant="overline">
        Children
      </Typography>
      <div className={classes.tableContainer}>
        <RobotLogsTable
          skipControls={true}
          additionalElasticQueries={[getChildrenQuery(robotLogId)]}
          columns={columns}
          tableCacheKey={'children-relationships'}
          overrideEmptyTableMsg={'No Children'}
          enableTopToolbar={false}
        />
      </div>
    </div>
  );
};
