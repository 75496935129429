import React from 'react';
import { Typography } from 'antd';


const PreviewText: React.FC = ({children}) => {
  return (
    <Typography.Paragraph ellipsis={{ rows: 3, expandable: true, symbol: 'more' }}>
      {children}
    </Typography.Paragraph>
  );
}

export default PreviewText;
