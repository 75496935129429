import React from 'react';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from '@shield-ui/styles';

type ValueType = string | number;
type Option = { label: string; value: ValueType };

interface Props {
  options: Option[];
  onChange: (value: ValueType) => void;
  value: ValueType;
}

const useStyles = makeStyles((theme) => {
  const border = `1px solid ${colors.hues.grays[160]}`;
  const rad = 12;
  const selectedBG = colors.semantic.selected; //alpha(colors.brand.primary, 0.4);
  return {
    container: { display: 'flex', userSelect: 'none' },
    toggle: {
      color: colors.hues.grays[30],
      fontWeight: 500,
      cursor: 'pointer',
      padding: theme.spacing(0.5, 1),
      transition: '0.15s ease',
      background: colors.hues.grays[200],
      '&:hover': {
        color: colors.semantic.selected,
      },
      border: border,
      borderRight: 'none',
      '&:first-of-type': {
        borderTopLeftRadius: rad,
        borderBottomLeftRadius: rad,
      },
      '&:last-of-type': {
        borderTopRightRadius: rad,
        borderBottomRightRadius: rad,
        borderRight: border,
      },
    },
    toggleSelected: {
      color: '#000',
      background: selectedBG,
      '&:hover': {
        color: '#000',
        background: selectedBG,
      },
    },
  };
});

export default function ContextualToggle(props: Props) {
  const classes = useStyles();
  const { onChange, options, value } = props;

  return (
    <div className={classes.container}>
      {options.map((opt, index) => {
        return (
          <div
            className={cx(
              classes.toggle,
              value === opt.value && classes.toggleSelected
            )}
            key={opt.value}
            role="button"
            onClick={(evt) => onChange(opt.value)}
          >
            {opt.label}
          </div>
        );
      })}
    </div>
  );
}
