export function copyToClipboard(text, callback, error) {
  navigator.clipboard.writeText(text).then(
    (val) => {
      if (typeof callback === 'function') {
        callback(val);
      }
    },
    (err) => {
      if (typeof error === 'function') {
        error(err);
      }
    }
  );
}
