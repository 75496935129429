import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { stopEventPropagation } from '@shield-ui/utils';
import { LayoutProps, KeywordsQueryFilter } from '@shield-ui/query-filters';
import { colors } from '@shield-ui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: 280,
    position: 'relative',
    margin: `0px 4px`,
    '&:hover > $help': {
      opacity: 1,
    },
  },
  help: {
    zIndex: 2,
    opacity: 0,
    background: 'black',
    padding: '1px 3px',
    transition: '0.1s ease',
    position: 'absolute',
    top: 7,
    right: 3,
    fontSize: 12,
    borderRadius: 4,
    color: colors.semantic.linkColor,
    cursor: 'pointer',
    '&:hover': {
      color: 'black',
      background: colors.semantic.linkColor,
    },
  },
  fields: {
    margin: theme.spacing(2, 0),
    display: 'flex',
    flexWrap: 'wrap',
  },
  sampleRow: {
    paddingBottom: 10,
  },
  sample: {
    fontFamily: 'monospace',
    fontSize: 13,
    background: 'rgba(95, 195, 255, 0.1)',
    margin: `2px 3px`,
    padding: `1px 4px`,
    letterSpacing: '.5px',
    border: '1px solid rgba(255, 255, 255, 0.065)',
  },
}));

const sampleQueries = [
  {
    input: `"domo-06-0024"`,
    desc: `Use quotes to preserve the dashes in this hostname, default search engine behavior is to strip those and turn this into three tokens 'domo' '06' and '0024'.`,
  },
  {
    input: `ghost -voxel`,
    desc: `Use "minus" to disregard matches.`,
  },
  {
    input: `"crashed into"`,
    desc: `Use quotes to ensure the tokens 'crashed' and 'into' appear next to each other in the match, preserving the space.`,
  },
  {
    input: `"crashed into" AND "automode: Returning"`,
    desc: 'Use AND/OR operators to add logic',
  },
  {
    input: `name:*domo-06-0024*`,
    desc: 'Wildcard match on an individual field.',
  },
  {
    input: `labels.name:("comms relay" AND mocap) AND "domo-06-sim1"`,
    desc: 'Match one of two specific values for the field labels.name combined with an AND clause matching a quoted robot hostname.',
  },
  {
    input: `*domo-06-0031__2021-03* AND !battery AND !good`,
    desc: "Pattern match part of a bag name but doesn't match either of the next two tokens.",
  },
];

/**
 * A custom component to wrap the keyword control so it goes "inline" rather than in a dropdown
 */
interface Props extends LayoutProps {
  queryFilter: KeywordsQueryFilter;
}

export default function KeywordsComponent(props: Props) {
  const [helpVisible, setHelpVisible] = useState(false);
  const classes = useStyles();

  const { ControlElement, queryFilter } = props;
  const { searchFields } = queryFilter.props.esFields;

  return (
    <div onPointerDown={stopEventPropagation} className={classes.container}>
      <div className={classes.help} onClick={() => setHelpVisible(true)}>
        Help
      </div>
      {ControlElement}
      {helpVisible && (
        <Dialog
          open={helpVisible}
          onClose={() => setHelpVisible(false)}
          disableEnforceFocus
        >
          <DialogContent>
            <Typography variant="h6">About</Typography>
            <p>
              Keyword search functions similar to a search engine like Google.
              Search engines are good at translating human queries into results
              but sometimes they need to be massaged to help you find exactly
              the data you need. Use these sample queries below as a quick way
              to understand syntax and what features are possible. If you want
              to deep dive all of the features, you can
              <a
                target="_blank"
                href="https://www.elastic.co/guide/en/elasticsearch/reference/current/query-dsl-query-string-query.html#query-string-syntax" rel="noreferrer"
              >
                {' '}
                read more{' '}
              </a>{' '}
              straight from the Elasticsearch docs.
            </p>

            <Typography variant="h6">Sample Queries</Typography>
            <p>Use these sample queries to help you get started</p>
            <ul>
              {sampleQueries.map((q, i) => (
                <li key={i} className={classes.sampleRow}>
                  <span className={classes.sample}>{q.input}</span> {q.desc}
                </li>
              ))}
            </ul>
            <Typography variant="h6">Fields</Typography>
            <p>
              Keyword search queries act against all the fields listed below. If
              you are hitting false positives in your results you can target
              individual fields as another way to narrow things down. For
              example,{' '}
              <span className={classes.sample}>name:*domo-06-0024*</span> would
              search just the {'"name"'} field with {'"domo-06-0024"'} and fuzzy
              matching on both sides.
            </p>
            <p className={classes.fields}>
              {searchFields.map((field) => (
                <span key={field} className={classes.sample}>
                  {field}
                </span>
              ))}
            </p>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setHelpVisible(false)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
