import _ from 'lodash';
import Cell from './Cell';

const config = {
  columnKey: 'robotLogTest',
  label: 'Test Code',
  description: 'Robot Log Test Code ID',
  defaultWidth: 160,
  sortKey: 'robot_log_test.code.keyword',
  getValue: ({ row }) => _.get(row, 'robotLogTest.code', ''),
  Cell,
  fragment: `
        robotLogTest {
            code
        }
        id
        testDefinitionId
        sessionLogTypeSlug
    `,
};

export default config;
