import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { IntegratedSelect } from '@shield-ui/controls';
import { getLabels, createLabel } from '../../../services/labels';
import AppliedLabel from './AppliedLabel';
import container from './labelMultiSelectContainer';

const MIN_CHAR_SEARCH = 1;
const DEFAULT_OPTION_COUNT = 10;
const DEFAULT_OPTIONS_STORE_ACROSS_SESSION = {};

function MultiValue(props) {
  return (
    <AppliedLabel name={props.children} onDelete={props.removeProps.onClick} />
  );
}

class LabelMultiSelect extends React.Component {
  static defaultProps = {
    placeholder: 'Labels',
    // use this if you want to say apply a new label to something
    // false only searches existing ones
    isCreatable: false,
    defaultOptionsSort: 'created_at_desc', // valid to the LabelSortEnum
    onChange: _.noop,
    value: [],
    disabled: false,
  };
  static propTypes = {
    placeholder: PropTypes.string,
    isCreatable: PropTypes.bool,
    defaultOptionsSort: PropTypes.oneOf([
      'created_at_desc',
      'applied_to_collections_count_desc',
      'applied_to_robot_logs_count_desc',
    ]),
    onChange: PropTypes.func,
    value: PropTypes.array,
    disabled: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.loadOptionsThrottled = _.throttle(this.loadOptions.bind(this), 200);
    this.checkForLabelsToFetch(props.value);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.value !== this.props.value ||
      prevProps.labels !== this.props.labels
    ) {
      this.checkForLabelsToFetch(this.props.value);
    }
  }

  checkForLabelsToFetch(value) {
    const { labels, ensureLabelListItems } = this.props;
    const labelMap = _.keyBy(labels, 'id');
    const missingIds = value.filter((id) => !labelMap[id]);

    if (missingIds.length && !this.fetching) {
      this.fetching = true;
      ensureLabelListItems({
        labelIds: missingIds,
        callback: () => {
          this.fetching = false;
        },
      });
    }
  }

  componentWillUnmount() {
    // so we don't try and set state if we unmount and the result hasn't returned yet
    this.fetching = false;
  }

  loadOptions(inputValue, resolveOptions) {
    const { defaultOptionsSort } = this.props;

    const onError = (err) => {
      console.error(err);
      resolveOptions([]);
    };

    const getOptions = (results) => {
      return _.get(results, 'data.labels.edges', []).map((edge) => {
        const label = edge.node;
        return {
          label: label.name,
          value: label.id,
        };
      });
    };

    // DEFAULT OPTIONS
    if (inputValue.length === 0) {
      const cacheKey = defaultOptionsSort;

      if (DEFAULT_OPTIONS_STORE_ACROSS_SESSION[cacheKey]) {
        resolveOptions(DEFAULT_OPTIONS_STORE_ACROSS_SESSION[cacheKey]);
        return;
      }

      getLabels(
        { first: DEFAULT_OPTION_COUNT, sort: defaultOptionsSort },
        (err, results) => {
          if (err) {
            return onError(err);
          }
          const options = getOptions(results);

          if (options.length === DEFAULT_OPTION_COUNT) {
            options.push({
              label: 'Type for more...',
              isDisabled: true,
            });
          }

          DEFAULT_OPTIONS_STORE_ACROSS_SESSION[cacheKey] = options;

          resolveOptions(options);
        }
      );

      return;
    } else if (inputValue.length < MIN_CHAR_SEARCH) {
      resolveOptions([]);
      return;
    }

    getLabels({ textLike: inputValue, first: 20 }, (err, results) => {
      if (err) {
        return onError(err);
      }
      resolveOptions(getOptions(results));
    });
  }

  onChange = (opts, meta) => {
    const { onChange, setListItems } = this.props;

    if (meta.action === 'create-option') {
      const opt = opts.pop();
      createLabel(
        {
          name: opt.value,
        },
        (err, result) => {
          if (err) {
            console.error(err);
          }

          const newLabel = _.get(result, 'data.createLabel');
          if (!newLabel || !newLabel.id) {
            return console.error('label was not created');
          }

          setListItems({
            listKey: 'labels',
            items: [newLabel],
          });

          opts.push({
            value: newLabel.id,
            label: newLabel.name,
          });

          onChange(opts, meta);
        }
      );
    } else {
      onChange(opts, meta);
    }
  };

  getNoOptionsMessage = ({ inputValue }) => {
    if (!inputValue) {
      return 'Type to search labels';
    } else if (inputValue.length < MIN_CHAR_SEARCH) {
      return 'Type more characters to search labels';
    }
    return 'No labels found';
  };

  render() {
    const {
      isCreatable,
      placeholder,
      value,
      MultiSelectComponent,
      labels,
      disabled,
    } = this.props;
    const labelMap = _.keyBy(labels, 'id');

    const suggestions = value.reduce((acc, id) => {
      if (labelMap[id]) {
        acc.push({
          value: id,
          label: labelMap[id].name,
        });
      }
      return acc;
    }, []);

    const Select = MultiSelectComponent || IntegratedSelect;

    return (
      <Select
        components={{ MultiValue }}
        isAsync
        isMulti
        disabled={disabled}
        value={value}
        loadOptions={this.loadOptionsThrottled}
        isCreatable={isCreatable}
        notClearable={isCreatable}
        onChange={this.onChange}
        //cacheOptions
        //defaultOptions
        placeholder={placeholder}
        suggestions={suggestions}
        noOptionsMessage={this.getNoOptionsMessage}
      />
    );
  }
}

export default container(LabelMultiSelect);
