import React from 'react';
import FolderIcon from '@mui/icons-material/FolderOutlined';
import { Button } from '@mui/material';

function CreateCollectionCTA({ children, ...rest }) {
  return (
    <Button
      style={{ borderRadius: 20 }}
      variant="contained"
      size="small"
      {...rest}
    >
      <FolderIcon style={{ marginRight: 12 }} />
      {children}
    </Button>
  );
}

export default CreateCollectionCTA;
