import {
  RobotLogDocumentService,
  PassthruSearchRequest,
} from '@hmd/sdk/api/search/robotlogdocument/v1';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { Struct } from 'google-protobuf/google/protobuf/struct_pb';
import sdkClient from '../lib/hmdsdkClient';
import { get, isArray, isObject } from 'lodash';
import { SessionLogProduct, VBATMetadata, VBATMetadataKey } from './robotLogs';
import { queryClient } from '../queryClient';
import { MultiselectOption } from '@shield-ui/query-filters';

type StructValue = {
  fieldsMap: [string, Node][];
};

type Node = {
  nullValue?: number;
  numberValue?: number;
  stringValue?: string;
  boolValue?: boolean;
  structValue?: StructValue;
  listValue?: {
    valuesList: Node[];
  };
};

type PassThruSearch = {
  aggregations: {
    [key: string]: {
      buckets: Array<{
        doc_count: number;
        key: string;
      }>;
    };
  };
  hits: {
    hits: Array<{
      _id: string;
      _index: string;
      _score: 1;
      _type: string;
      fields: {
        [key: string]: {
          [key: string]: string;
        }[];
      };
    }>;
  };
};

export type MetadataFieldObj = {
  metadata_key: string;
  value: string;
};

export const convertMetadataToFields = (
  metadata: VBATMetadata
): MetadataFieldObj[] => {
  const metadataFields: MetadataFieldObj[] = [];

  if (metadata) {
    Object.entries(metadata).forEach(([key, value]) => {
      if (!isObject(value) && !isArray(value)) {
        metadataFields.push({
          metadata_key: key,
          value: value as string,
        });
      } else {
        metadataFields.push({
          metadata_key: key,
          value: JSON.stringify(value),
        });
      }
    });
  }

  return metadataFields;
};

const convertToBasicStructure = (data: Node) => {
  if ('structValue' in data && data.structValue) {
    return structValueToBasicStructure(data.structValue);
  }
  if ('listValue' in data && data.listValue) {
    return data.listValue.valuesList.map(convertToBasicStructure);
  }
  return (
    data?.nullValue || data?.boolValue || data?.numberValue || data?.stringValue
  );
};

export const structValueToBasicStructure = (data: StructValue) => {
  if (!data) return {};
  return data.fieldsMap.reduce(
    (acc, item) => ({
      ...acc,
      [item[0]]: convertToBasicStructure(item[1]),
    }),
    {}
  );
};

export const arrayToLabels = (data: string[] | Set<string>) =>
  Array.from(data)
    .sort((a, b) => a.toString().localeCompare(b.toString()))
    .map((val) => ({
      label: val,
      value: val,
    }));

const fetchMetadata = async (sessionLogProduct?: SessionLogProduct) => {
  const passthruSearchRequest = new PassthruSearchRequest();
  const queryBody: any = {
    _source: false,
    size: 500,
    fields: ['robot_log_data'],
    sort: [
      {
        _doc: {
          order: 'desc',
        },
      },
    ],
  };

  switch (sessionLogProduct) {
    case SessionLogProduct.VBAT:
      queryBody.query = {
        wildcard: {
          name: {
            value: '*VBAT*',
            case_insensitive: true,
          },
        },
      };
      break;
    case SessionLogProduct.NOVA:
      queryBody.query = {
        wildcard: {
          name: {
            value: `*nova*`,
            case_insensitive: true,
          },
        },
      };
      break;
    default:
      break;
  }
  passthruSearchRequest.setBody(Struct.fromJavaScript(queryBody));
  const request = await sdkClient.query(
    RobotLogDocumentService.PassthruSearch,
    passthruSearchRequest
  );

  const data: PassThruSearch = structValueToBasicStructure(
    request.toObject().response
  );
  return get(data, 'hits.hits', []).map((hit: any) =>
    get(hit, 'fields.robot_log_data[0]', {})
  ) as VBATMetadata[];
};

const METADATA_QUERY_KEY = 'sessionLogMetadata';

export const useVBATMetadata = () =>
  useQuery({
    queryKey: [METADATA_QUERY_KEY, SessionLogProduct.VBAT],
    queryFn: () => fetchMetadata(SessionLogProduct.VBAT),
  });

export const useSessionLogMetadata = (sessionLogProduct: SessionLogProduct) =>
  useQuery({
    queryKey: [METADATA_QUERY_KEY, sessionLogProduct],
    queryFn: () => fetchMetadata(sessionLogProduct),
  });

const fetchLabels = async () => {
  const passthruSearchRequest = new PassthruSearchRequest();
  passthruSearchRequest.setBody(
    Struct.fromJavaScript({
      _source: false,
      size: 500,
      fields: ['labels.name'],
      query: {
        wildcard: {
          name: {
            value: '*VBAT*',
            case_insensitive: true,
          },
        },
      },
    })
  );

  const request = await sdkClient.query(
    RobotLogDocumentService.PassthruSearch,
    passthruSearchRequest
  );

  const data: PassThruSearch = structValueToBasicStructure(
    request.toObject().response
  );

  const labels: string[] = Array.from(
    new Set(
      [].concat(
        ...data.hits.hits.map((hit) => {
          return get(hit, 'fields.["labels.name"]', []);
        })
      )
    )
  );

  return labels;
};

const LABELS_QUERY_KEY = 'labels';

export const useLabels = () =>
  useQuery({
    queryKey: [LABELS_QUERY_KEY],
    queryFn: () => fetchLabels(),
  });

type FieldType = VBATMetadataKey | string;

const fetchMetadataFieldOptions = async (field: FieldType) => {
  const passthruSearchRequest = new PassthruSearchRequest();
  passthruSearchRequest.setBody(
    Struct.fromJavaScript({
      size: 0,
      aggs: {
        [field]: {
          terms: {
            field: 'robot_log_data.' + field,
            "size": 10000
          },
        },
      },
    })
  );
  const request = await sdkClient.query(
    RobotLogDocumentService.PassthruSearch,
    passthruSearchRequest
  );
  const data: PassThruSearch = structValueToBasicStructure(
    request.toObject().response
  );
  const options: MultiselectOption[] = data.aggregations[field].buckets.map(
    (obj) => {
      return {
        value: obj.key,
        label: obj.key,
      };
    }
  );
  return options;
};

const METADATA_FIELDS_QUERY_KEY = 'metadataFieldOptions';

function returnMetadataFieldOptions(field: FieldType) {
  return {
    queryKey: [METADATA_FIELDS_QUERY_KEY, field],
    queryFn: () => fetchMetadataFieldOptions(field),
  };
}

export const useMetadataFieldOptions = (
  field: FieldType
): UseQueryResult<MultiselectOption[]> =>
  useQuery(returnMetadataFieldOptions(field));

export const getMetadataFieldOptions = (
  field: FieldType
): Promise<MultiselectOption[]> =>
  queryClient.fetchQuery(returnMetadataFieldOptions(field));
