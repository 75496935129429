import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import {
  HangarCollectionType,
  HangarRuleSetSearchVersion,
} from '@shield-ui/hangar-service';
import sessionLogTypesQueryFilter from '../lib/queryFilters/robotLog/sessionLogTypes';
import { MultiselectQueryFilterValue } from '@shield-ui/query-filters';
import { showErrorSnack } from './snackbar/actions';
import * as ruleSetsService from '../services/ruleSets';
import * as collectionsService from '../services/collections';
import {
  buildFullQueryFromValues,
  hasAnyEsQueryValues,
} from '@shield-ui/query-filters';

export const COLLECTION_CREATE_FEATURE_KEY = 'collectionCreateV2';

export interface CollectionCreateBaseValues {
  name: string;
  description: string;
  isPrivateForCreator: boolean;
  isPubliclyEditable: boolean;
}

type SidePanelFocus = void | 'rules';

export interface CollectionCreateState {
  isSubmitting: boolean;
  baseValues: CollectionCreateBaseValues;
  ruleSetValues: object; // XXX more type safe based on query filter definitions?
  sidePanelFocus: SidePanelFocus;
  newCollection?: HangarCollectionType;
}

function getSessionLogTypeSlugsState(sessionLogTypeSlugs: string[]) {
  const val = {
    includeOptionValues: sessionLogTypeSlugs,
  };

  const queryFilterValue = {
    ...val,
    esQuery: sessionLogTypesQueryFilter.getElasticQuery(val),
  };

  return {
    [sessionLogTypesQueryFilter.getId()]: queryFilterValue,
  };
}

function getDefaultRuleSetValues(): MultiselectQueryFilterValue {
  return getSessionLogTypeSlugsState([]);
}

const initialState: CollectionCreateState = {
  isSubmitting: false,
  baseValues: {
    name: '',
    description: '',
    isPrivateForCreator: false,
    isPubliclyEditable: false,
  },
  ruleSetValues: getDefaultRuleSetValues(),
  newCollection: undefined,
  sidePanelFocus: undefined,
};

export const submitCollectionCreate = createAsyncThunk(
  `${COLLECTION_CREATE_FEATURE_KEY}/submit`,
  async (args: object, thunkAPI): Promise<HangarCollectionType> => {
    const ccState = selectCreateCollectionState(thunkAPI.getState());

    if (!ccState.baseValues.name) {
      thunkAPI.dispatch(
        showErrorSnack({
          message: 'You must fill out a name for your collection',
        })
      );
    }
    if (
      !sessionLogTypesQueryFilter.hasValue(
        ccState.ruleSetValues[sessionLogTypesQueryFilter.getId()]
      )
    ) {
      thunkAPI.dispatch(
        showErrorSnack({
          message:
            'You must select at least one Content Type for this collection (e.g. Flights)',
        })
      );
    }

    try {
      const ruleSetResults = await ruleSetsService.mutations.create({
        robotLogSearchVariables: JSON.stringify(ccState.ruleSetValues || {}),
        searchVersion: HangarRuleSetSearchVersion.DocumentSearchV1,
      });
      const result = await collectionsService.mutations.create({
        name: ccState.baseValues.name,
        description: ccState.baseValues.description || '',
        isPrivateForCreator: ccState.baseValues.isPrivateForCreator,
        isPubliclyEditable: ccState.baseValues.isPubliclyEditable,
        ruleSetId: ruleSetResults.data.createRuleSet.id,
      });
      return result.data.createCollection;
    } catch (e) {
      thunkAPI.dispatch(showErrorSnack(e));
      throw e;
    }
  }
);

interface CreateCollectionFromTablePagePayload {
  sessionLogTypeSlugs: string[];
  ruleSetValues: Partial<CollectionCreateState['ruleSetValues']>;
}

export const collectionCreateSlice = createSlice({
  name: COLLECTION_CREATE_FEATURE_KEY,
  initialState: initialState,
  reducers: {
    reset: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    },
    // delete this from state, say when you are unmounting a component, changing pages, etc...
    setBaseValues: (
      state,
      action: PayloadAction<Partial<CollectionCreateState['baseValues']>>
    ) => {
      state.baseValues = {
        ...state.baseValues,
        ...action.payload,
      };
    },
    createCollectionFromTablePage: (
      state,
      action: PayloadAction<CreateCollectionFromTablePagePayload>
    ) => {
      state.ruleSetValues = {
        // ensure our defaults
        ...getDefaultRuleSetValues(),
        // layer on the context of which type of robotLog we came from
        ...getSessionLogTypeSlugsState(action.payload.sessionLogTypeSlugs),
        // and finally set make the queryFilters from the control bar
        // match the wizard
        ...action.payload.ruleSetValues,
      };
    },
    setRuleSetValues: (
      state,
      action: PayloadAction<Partial<CollectionCreateState['ruleSetValues']>>
    ) => {
      state.ruleSetValues = {
        ...state.ruleSetValues,
        ...action.payload,
      };
    },
    setSidePanelFocus: (
      state,
      action: PayloadAction<{ focus: SidePanelFocus }>
    ) => {
      state.sidePanelFocus = action.payload.focus;
    },
  },
  extraReducers: {
    [submitCollectionCreate.pending.type]: (state) => {
      state.isSubmitting = true;
    },
    [submitCollectionCreate.fulfilled.type]: (
      state,
      action: PayloadAction<HangarCollectionType>
    ) => {
      state.isSubmitting = false;
      state.newCollection = action.payload;
    },
    [submitCollectionCreate.rejected.type]: (state) => {
      state.isSubmitting = false;
    },
  },
});

// select the entire slice state
export const selectCreateCollectionState: (state) => CollectionCreateState = (
  state
) => state[COLLECTION_CREATE_FEATURE_KEY];

// create a selector of just filter values so we don't recompute the preview too much
export const selectFilterValues = createSelector(
  selectCreateCollectionState,
  (s: CollectionCreateState) => {
    return s['ruleSetValues'];
  }
);

// compute a preview based on ruleset values and a message to accompany
// if we are missing things
export const selectPreviewFields = createSelector(
  selectFilterValues,
  (values) => {
    if (
      !sessionLogTypesQueryFilter.hasValue(
        values[sessionLogTypesQueryFilter.getId()]
      )
    ) {
      return {
        message:
          'You must choose one or more content types for this collection.',
        tableQueries: undefined,
      };
    }

    const otherValuesCheck = { ...values };
    delete otherValuesCheck[sessionLogTypesQueryFilter.getId()];

    if (!hasAnyEsQueryValues(otherValuesCheck)) {
      return {
        message: 'Create some rules to drive the data in this collection.',
        tableQueries: undefined,
      };
    }

    return {
      message: '',
      tableQueries: [buildFullQueryFromValues({ values })],
    };
  }
);
