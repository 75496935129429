import _ from 'lodash';
import {
  getReviewStatusFilterOptions,
  UNASSIGNED_FAKE_REVIEW_STATUS,
} from '../../../../../services/reviews';
import {
  VALID_FLIGHT_TYPE_FILTER_OPTIONS,
} from '../../../../../services/robotLogs';
import strings from '../../../../../lib/strings';
import { getArtifactTypeDisplay } from '../../../../../services/artifacts';
import { smartFormatDuration, getShortDateLocal } from '@shield-ui/utils';
import { TimeOfDayRange } from '@shield-ui/controls';

function findOption(value, options) {
  const opt = _.find(options, { value });
  // Ensure something is always returned, so the CHIP can be shown and the user knows there is something to clear (safety fallback)
  if (!opt) {
    return `Unknown:${value}`;
  }
  return opt.label;
}

function plainValue({ value }) {
  return value;
}

function getItemUserOption({ value, userOptions }) {
  return findOption(value, userOptions);
}

/**
 * getValue or getItemValue will all have all props of the FilterChipBuilder passed down
 * This will include all of our "memoized" option lists from the lists selector (see the container for explicit values
 */
const BUILDER_DEFS = {
  // common
  textLike: {
    fieldDisplay: 'Keywords',
    getValue: ({ value }) => `" ${value} "`,
  },
  // collections but could be common
  isMyFavorite: {
    getValue: ({ value }) => (value ? 'My Favorite' : 'Not My Favorite'),
  },
  isSystemDefault: {
    getValue: ({ value }) =>
      value
        ? `Is ${strings.COLLECTION_SYSTEM_DEFAULT_NAME}`
        : `Not ${strings.COLLECTION_SYSTEM_DEFAULT_NAME}`,
  },
  createdByIds: {
    fieldDisplay: 'Creator User',
    getItemValue: getItemUserOption,
  },
  // robot logs but plenty could be common
  startTime_Range: {
    fieldDisplay: 'Flight Time',
    getValue: ({ value }) => {
      if (value.gte && value.lte) {
        return `${getShortDateLocal(value.gte)} -> ${getShortDateLocal(
          value.lte
        )}`;
      } else if (value.gte) {
        return `After ${getShortDateLocal(value.gte)}`;
      } else if (value.lte) {
        return `Before ${getShortDateLocal(value.lte)}`;
      }
    },
  },
  startTimeRelativeDays_Range: {
    getValue: ({ value }) => {
      if (value.gte && value.lte) {
        return `${value.gte}days -> ${value.lte}days`;
      } else if (value.gte) {
        return `After ${value.gte} days`;
      } else if (value.lte) {
        return `Before ${value.lte} days`;
      }
    },
  },
  totalTime_Range: {
    fieldDisplay: 'Flight Duration',
    getValue: ({ value }) => {
      if (value.gte && value.lte) {
        return `${smartFormatDuration(
          value.gte * 1000
        )} -> ${smartFormatDuration(value.lte * 1000)}`;
      } else if (value.gte) {
        return `Greater than ${smartFormatDuration(value.gte * 1000)}`;
      } else if (value.lte) {
        return `Less than ${smartFormatDuration(value.lte * 1000)}`;
      }
    },
  },
  robotLogTestIds: {
    fieldDisplay: 'Test Code',
    getItemValue: ({ value, robotLogTestOptions }) =>
      findOption(value, robotLogTestOptions),
  },
  environmentIds: {
    fieldDisplay: 'Environment',
    getItemValue: ({ value, environmentOptions }) =>
      findOption(value, environmentOptions),
  },
  flightType: {
    fieldDisplay: 'Flight Type',
    getValue: ({ value }) =>
      findOption(value, VALID_FLIGHT_TYPE_FILTER_OPTIONS),
  },
  robotIds: {
    fieldDisplay: 'Robot',
    getItemValue: ({ value, robotOptions }) => findOption(value, robotOptions),
  },
  robotProducts: {
    fieldDisplay: 'Product',
    getItemValue: ({ value, robotProductOptions }) =>
      findOption(value, robotProductOptions),
  },
  softwareVersions: {
    fieldDisplay: 'SW Version',
    getItemValue: plainValue,
  },
  softwareBranches: {
    fieldDisplay: 'Branch Name',
    getItemValue: plainValue,
  },
  softwareCommitHashes: {
    fieldDisplay: 'Commit Hash',
    fieldDisplayPlural: 'Commit Hashes',
    getItemValue: plainValue,
  },
  tagIdsPresent: {
    fieldDisplay: 'Failure Type',
    getItemValue: ({ value, tagOptions }) => findOption(value, tagOptions),
  },
  causeIdsPresent: {
    fieldDisplay: 'Cause',
    getItemValue: ({ value, causeOptions }) => findOption(value, causeOptions),
  },
  robotLogReviewReviewerIds: {
    fieldDisplay: 'Assigned to User',
    getItemValue: getItemUserOption,
  },
  robotLogReviewReviewerTeamIds: {
    fieldDisplay: 'Assigned to Team',
    getItemValue: ({ value, teamOptions }) => findOption(value, teamOptions),
  },
  robotLogReviewRequesterIds: {
    fieldDisplay: 'Assigned by User',
    getItemValue: getItemUserOption,
  },
  robotLogReviewReviewStatuses: {
    fieldDisplay: 'Review Status',
    fieldDisplayPlural: 'Review Statuses',
    getItemValue: ({ value }) =>
      findOption(value, getReviewStatusFilterOptions()),
  },
  robotLogReviewReviewStatusesNotIn: {
    getValue: () => UNASSIGNED_FAKE_REVIEW_STATUS,
  },
  ticketPlatformIdsPresent: {
    fieldDisplay: 'Ticket',
    getItemValue: ({ value, tickets }) => {
      // string compare because the id retrieved from the database is a string, but in some cases it looks like we have a number
      const match = _.find(
        tickets,
        (ticket) => ticket.platformId.toString() === value.toString()
      );
      return _.get(match, 'name', '1 Ticket');
    },
    ensureListItems: ({ ensureTicketListItems }, ticketPlatformIds) => {
      ensureTicketListItems({ ticketPlatformIds });
    },
  },
  labelIdsPresent: {
    fieldDisplay: 'Label',
    getItemValue: ({ value, labels }) => {
      const match = _.find(labels, { id: value });
      return _.get(match, 'name', '1 Label');
    },
    ensureListItems: ({ ensureLabelListItems }, labelIds) => {
      ensureLabelListItems({ labelIds });
    },
  },
  involvedUserIds: {
    fieldDisplay: 'Involved User',
    getItemValue: getItemUserOption,
  },
  startTimeOfDayRangeIn: {
    fieldDisplay: 'Time of Day',
    getValue: ({ value }) => {
      return TimeOfDayRange.getValueDescription(value);
    },
  },
  artifactArtifactTypeIn: {
    fieldDisplay: 'Artifact Type',
    getItemValue: ({ value }) => getArtifactTypeDisplay(value),
  },
  robotLogTypes: {
    fieldDisplay: 'Content Type',
    getItemValue: ({ value }) => 'Robot Log Item Deprecated',
  },
  logEventComponentOwners: {
    fieldDisplay: 'Diagnostic Component Owner',
    getItemValue: ({ value, componentOwnerParentOptions }) =>
      findOption(value, componentOwnerParentOptions),
    ensureListItems: ({ ensureComponents }) => {
      ensureComponents();
    },
  },
  logEventComponentExternalIds: {
    fieldDisplay: 'Diagnostic Component',
    getItemValue: ({ value, componentOptions }) =>
      findOption(value, componentOptions),
    ensureListItems: ({ ensureComponents }) => {
      ensureComponents();
    },
  },
  logEventLogDefinitionExternalIds: {
    fieldDisplay: 'Diagnostic Log Definition',
    getItemValue: ({ value, logDefinitionOptions }) =>
      findOption(value, logDefinitionOptions),
    ensureListItems: ({ ensureLogDefinitions }) => {
      ensureLogDefinitions();
    },
  },
};

// Same thing, but different key. robotLogs use labelIdsPresent but collections was made to just have labelIds because present seems like an artifact of past times
BUILDER_DEFS.labelIds = BUILDER_DEFS.labelIdsPresent;

// All the keys for robotLogReviews also work for collection reviews
_.forEach(BUILDER_DEFS, (value, key) => {
  if (key.indexOf('robotLogReview') === 0) {
    BUILDER_DEFS[key.replace(/^robotLogReview/, 'collectionReview')] = value;
  }
});

export function getFilterChipDefinition(key) {
  return BUILDER_DEFS[key];
}
