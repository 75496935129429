import _ from 'lodash';
import { createActionPrefix } from 'redux-nano';
import { DocumentNode } from '@apollo/client';
import {
  alterIncludedExcludedMappings,
  getCollection,
  refreshRobotLogsForCollection,
  updateCollection,
  setRecentCollections,
} from '../../services/collections';
import { forceResultRefresh } from '../tables/actions';
import { updateRuleSet } from '../../services/ruleSets';
import { showErrorSnack, showSuccessSnack } from '../snackbar/actions';
import {
  getCollectionSessionLogTypeSlugs,
  getDashboardLayout,
  getLabelIdsForCollectionPage,
  getMergedRuleSetEditingVariables,
} from './selectors';
import {
  addLabelsToCollection,
  removeLabelsFromCollection,
} from '../../services/labels';
import {
  getCollectionReviewProgressStats,
  getCollectionReviews,
} from '../../services/reviews';
import {
  createMeasurement,
  deleteMeasurement as deleteMeasurementMutation,
  searchMeasurementResults,
  searchMeasurements,
  updateMeasurement,
} from '../../services/measurements';
import { getSemanticDataLabel } from '../../components/forms/defs';
import { searchMeasurementDefinitions } from '../../services/measurementDefinitions';
import { setListItems } from '../lists/actions';
import { scrollMainContentToTop } from '../../lib/scrollMainContentToTop';
import { SIDE_PANEL_CONTENT_SCREEN } from './constants';
import {
  HangarCollectionType,
  HangarDissectionEnum,
  HangarLabelType,
  HangarMeasurementDefinitionType,
  HangarMeasurementType,
  HangarRobotLogType,
} from '@shield-ui/hangar-service';
import { MeasurementDashboardGroup } from './types';
import { uuid4 } from '@shield-ui/utils';

const prefix = createActionPrefix('COLLECTION');
export const RESET_COLLECTION_PAGE = prefix('RESET_COLLECTION_PAGE');
export const SET_COLLECTION = prefix('SET_COLLECTION');
export const UPDATE_COLLECTION = prefix('UPDATE_COLLECTION');
export const UPDATE_EDIT_RULE_SET_VARIABLES = prefix(
  'UPDATE_EDIT_RULE_SET_VARIABLES'
);
export const SET_RULE_SET_SELECT_OPTION_ID = prefix(
  'SET_RULE_SET_SELECT_OPTION_ID'
);
export const CLEAR_EDIT_RULE_SET_VARIABLES = prefix(
  'CLEAR_EDIT_RULE_SET_VARIABLES'
);
export const FORCE_METABASE_REFRESH = prefix('FORCE_ANALYTICS_REFRESH');
export const SET_REVIEWS = prefix('SET_REVIEWS');
export const SET_REVIEW_STATS = prefix('SET_REVIEW_STATS');
export const SET_SIDE_PANEL_CONTENT_SCREEN = prefix(
  'SET_SIDE_PANEL_CONTENT_SCREEN'
);
export const SET_COLLECTION_IDS_TO_COMPARE = prefix(
  'SET_COLLECTION_IDS_TO_COMPARE'
);
export const SET_MEASUREMENT_DEFINITIONS = prefix(
  'SET_MEASUREMENT_DEFINITIONS'
);
export const SET_MEASUREMENT_EDITOR_VARIABLES = prefix(
  'SET_MEASUREMENT_EDITOR_VARIABLES'
);
export const RESET_MEASUREMENT_EDITOR_VARIABLES = prefix(
  'RESET_MEASUREMENT_EDITOR_VARIABLES'
);
export const SET_MEASUREMENTS = prefix('SET_MEASUREMENTS');

export const setMeasurementEditorVariables = SET_MEASUREMENT_EDITOR_VARIABLES;
export const resetMeasurementEditorVariables =
  RESET_MEASUREMENT_EDITOR_VARIABLES;
export const setCollectionIdsToCompare = SET_COLLECTION_IDS_TO_COMPARE;

export type AddMeasurementStartArgs = {
  measurementDefinitionId: HangarMeasurementType['id'];
};
export function addMeasurementStart({
  measurementDefinitionId,
}: AddMeasurementStartArgs) {
  return (dispatch, getState) => {
    const state = getState();
    const definitions = state.collection.measurementDefinitions;
    const def = _.find(definitions, { id: measurementDefinitionId });
    if (!def) {
      dispatch(
        showErrorSnack(
          new Error(
            `Could not find definition for id ${measurementDefinitionId}`
          )
        )
      );
      return;
    }

    let dissection = HangarDissectionEnum.All;
    let statistic;
    if (def.dissectionOptions.includes(HangarDissectionEnum.ByWeek)) {
      dissection = HangarDissectionEnum.ByWeek;
      statistic = def.statisticOptions.dissected[0];
    } else {
      statistic = def.statisticOptions.all[0];
    }

    const definitionSpecificValues = {};

    const types = getCollectionSessionLogTypeSlugs(state);
    if (types && types.length > 0) {
      definitionSpecificValues['session_log_type_slug'] = types[0];
    }

    dispatch(
      setMeasurementEditorVariables({
        measurementDefinitionId: def.id,
        dissection,
        statistic,
        definitionSpecificValues,
      })
    );
  };
}

export type EditMeasurementStartArgs = { measurement: HangarMeasurementType };
export function editMeasurementStart({
  measurement,
}: EditMeasurementStartArgs) {
  return (dispatch) => {
    const editVariables = _.pick(measurement, [
      'id',
      'measurementDefinitionId',
      'dissection',
      'statistic',
      'assessmentCriteria',
      'uiData',
      'definitionSpecificValues',
    ]);

    dispatch(
      setSidePanelContentScreen({
        screen: SIDE_PANEL_CONTENT_SCREEN.MEASUREMENTS,
      })
    );
    dispatch(setMeasurementEditorVariables(editVariables));
  };
}

export function showMeasurementAddPanel() {
  return (dispatch) => {
    dispatch(
      setSidePanelContentScreen({
        screen: SIDE_PANEL_CONTENT_SCREEN.MEASUREMENTS,
      })
    );
  };
}

export function showRuleAddPanel() {
  return (dispatch) => {
    dispatch(
      setSidePanelContentScreen({
        screen: SIDE_PANEL_CONTENT_SCREEN.RULES,
      })
    );
  };
}

export function setRuleSetEditingOptionId({ queryFilterId }) {
  return (dispatch) => {
    dispatch(
      setSidePanelContentScreen({ screen: SIDE_PANEL_CONTENT_SCREEN.RULES })
    );
    dispatch(SET_RULE_SET_SELECT_OPTION_ID({ queryFilterId }));
  };
}

export function deleteRuleSetRule({ queryFilterId, callback = _.noop }) {
  return (dispatch) => {
    dispatch(
      updateEditRuleSetVariables({
        variables: { [queryFilterId]: undefined },
      })
    );
    dispatch(submitEditRuleSet({ callback }));
  };
}

export type DeleteMeasurementArgs = {
  measurementId: HangarMeasurementType['id'];
  callback?: (err?, result?) => void;
};
export function deleteMeasurement({
  measurementId,
  callback = _.noop,
}: DeleteMeasurementArgs) {
  return (dispatch, getState) => {
    deleteMeasurementMutation({ id: measurementId }, (err, result) => {
      if (err) {
        dispatch(showErrorSnack(err));
        return callback(err);
      }

      const state = getState();
      dispatch(
        SET_MEASUREMENTS({
          measurements: state.collection.measurements.filter(
            (m) => m.id !== measurementId
          ),
          results: state.collection.results.filter(
            (m) => m.id !== measurementId
          ),
        })
      );

      return callback(null, result);
    });
  };
}

function mergeAndReplaceResults(newObjects, currentObjects) {
  return _.unionBy(newObjects, currentObjects, 'id');
}

function checkForEditorErrors(
  vars,
  definitions: HangarMeasurementDefinitionType[]
): Error | void {
  const def = _.find(definitions, { id: vars.measurementDefinitionId });
  if (!def) {
    return new Error(
      `Could not find definition for id ${vars.measurementDefinitionId}`
    );
  }

  // definitionSpecificValues Make sure we have all our definitionSpecificOptions filled out
  const { definitionSpecificValues = {} } = vars;

  const missingOptions = def.definitionSpecificOptions.filter((option) => {
    return option.required && !definitionSpecificValues[option.parameter];
  });
  if (missingOptions.length) {
    const params = missingOptions.map((option) =>
      getSemanticDataLabel(option.dataType)
    );
    return new Error(`Missing required parameters: ${params.join(', ')}`);
  }

  // assessmentCriteria
  if (vars.assessmentCriteria) {
    let invalidRange = '';
    ['validInsideRange', 'warningInsideRange'].forEach((assessmentKey) => {
      const insideRange = vars.assessmentCriteria[assessmentKey] || {};
      if (
        insideRange.gte &&
        insideRange.lte &&
        insideRange.gte >= insideRange.lte
      ) {
        invalidRange = `Invalid range ${assessmentKey} min (${insideRange.gte}) should be less than max (${insideRange.lte})`;
      }
    });
    if (invalidRange) {
      return new Error(invalidRange);
    }
  }
}

export type SubmitMeasurementEditorArgs = {
  callback: (err?, response?) => void;
};
export function submitMeasurementEditor({
  callback,
}: SubmitMeasurementEditorArgs) {
  return (dispatch, getState) => {
    const { collectionId, measurementDefinitions, measurementEditorVariables } =
      getState().collection;

    // Clone these into a new object so we can mutate it at the end
    const vars = {
      ...measurementEditorVariables,
    };

    const validatorError = checkForEditorErrors(vars, measurementDefinitions);
    if (validatorError) {
      return callback(validatorError);
    }

    const mutationCallback = (err, result) => {
      if (err) {
        dispatch(showErrorSnack(err));
        return callback(err);
      }

      // create or update
      const measurement =
        result.data.createMeasurement || result.data.updateMeasurement;
      // get current values before setting
      const { measurements, results } = getState().collection;
      dispatch(
        SET_MEASUREMENTS({
          measurements: mergeAndReplaceResults([measurement], measurements),
          results: mergeAndReplaceResults([measurement], results),
        })
      );

      callback(null, result);
    };

    if (vars.id) {
      updateMeasurement(vars, mutationCallback);
    } else {
      vars.collectionIds = [collectionId];
      createMeasurement(vars, mutationCallback);
    }
  };
}

export type SetSidePanelContentScreenArgs = {
  screen: SIDE_PANEL_CONTENT_SCREEN;
};
export function setSidePanelContentScreen({
  screen,
}: SetSidePanelContentScreenArgs) {
  return (dispatch) => {
    dispatch(SET_SIDE_PANEL_CONTENT_SCREEN({ screen }));
    scrollMainContentToTop();
  };
}

export type ResetCollectionPageArgs = {
  collectionId: HangarCollectionType['id'];
};
export function resetCollectionPage({ collectionId }: ResetCollectionPageArgs) {
  return function dispatchResetCollectionPage(dispatch) {
    dispatch(RESET_COLLECTION_PAGE());
    dispatch(refreshCollectionForPage({ collectionId }));
    dispatch(fetchMeasurementDefinitions());

    searchMeasurements({ collectionIds: [collectionId] }, (err, result) => {
      if (err) {
        return dispatch(showErrorSnack(err));
      }
      const measurements = _.get(result, 'data.measurements.edges').map(
        (edge) => edge.node
      );
      dispatch(
        SET_MEASUREMENTS({
          measurements,
        })
      );
    });
  };
}

function getErrorMessage(err) {
  if (!err) {
    return 'Unknown Error';
  }

  // maybe we've just passed a pure string down as an error
  if (_.isString(err)) {
    return err;
  }

  // apollo-client
  if (err.graphQLErrors && err.graphQLErrors.length) {
    return err.graphQLErrors.map(getErrorMessage).join(' ');
  }

  // apollo-client
  const networkResultErrors = _.get(err, 'networkError.result.errors');
  if (networkResultErrors && networkResultErrors.length) {
    return networkResultErrors.map(getErrorMessage).join(' ');
  }

  if (err.errors) {
    return err.errors.map(getErrorMessage).join(' ');
  }

  // apollo-client + SDP
  if (err.message && err.path) {
    return `${err.message}: ${err.path.join(':')}`;
  }

  // real standard fall back
  if (err.message) {
    return err.message;
  }

  return JSON.stringify(err);
}

const handleErrorForAlert = (errorLike) => {
  console.error(errorLike);
  return (dispatch) => {
    dispatch(
      showErrorSnack({
        message: getErrorMessage(errorLike),
      })
    );
  };
};

function fetchMeasurementDefinitions() {
  return function dispatchFetchMeasurementDefinitions(dispatch, getState) {
    const state = getState();
    if (state.collection.measurementDefinitions.length > 0) {
      return;
    }

    searchMeasurementDefinitions({}, (err, result) => {
      if (err) {
        dispatch(handleErrorForAlert(err));
      }
      dispatch(
        SET_MEASUREMENT_DEFINITIONS({
          measurementDefinitions: _.get(
            result,
            'data.measurementDefinitions',
            []
          ),
        })
      );
    });
  };
}

type GetMeasurementResultsArgs = {
  ids: HangarMeasurementType['id'][];
  callback: (err?, response?) => void;
};
export function getMeasurementResults({
  ids,
  callback = _.noop,
}: GetMeasurementResultsArgs) {
  return (dispatch, getState) => {
    searchMeasurementResults({ ids }, (err, result) => {
      if (err) {
        callback(err);
        return dispatch(showErrorSnack(err));
      }
      const results = _.get(result, 'data.measurements.edges', []).map(
        (edge) => edge.node
      );
      dispatch(
        SET_MEASUREMENTS({
          results: mergeAndReplaceResults(
            results,
            getState().collection.results
          ),
        })
      );
      return callback(null, result);
    });
  };
}

export function clearMeasurementResults() {
  return (dispatch, getState) => {
    const state = getState();
    const { results } = state.collection;
    if (!results.length) {
      return;
    }

    dispatch(
      SET_MEASUREMENTS({
        results: [],
      })
    );
  };
}

type RefreshCollectionForPageArgs = {
  collectionId: HangarCollectionType['id'];
};
export function refreshCollectionForPage({
  collectionId,
}: RefreshCollectionForPageArgs) {
  return function dispatchRefreshCollectionForPage(dispatch) {
    getCollection({ id: collectionId }, (err, res) => {
      if (err) {
        return dispatch(showErrorSnack(err));
      }
      const { collection } = res.data;

      if (!collection) {
        return dispatch(
          showErrorSnack({
            message: `Could not find collection for id ${collectionId}`,
          })
        );
      }
      setRecentCollections(collection);

      const labels = _.get(collection, 'labels.edges', []).map(
        (edge) => edge.node
      );
      // Optimization so other components don't need to fetch it since we already have it
      dispatch(setListItems({ listKey: 'labels', items: labels }));

      dispatch(SET_COLLECTION({ collection }));
    });
  };
}

export type SetCollectionPageCollectionArgs = {
  collection: HangarCollectionType;
};
export function setCollectionPageCollection({
  collection,
}: SetCollectionPageCollectionArgs) {
  return (dispatch) => {
    dispatch(SET_COLLECTION({ collection }));
  };
}

export type UpdateEditRuleSetVariablesArgs = { variables: object };
export function updateEditRuleSetVariables({
  variables,
}: UpdateEditRuleSetVariablesArgs) {
  return (dispatch) => {
    dispatch(UPDATE_EDIT_RULE_SET_VARIABLES({ variables }));
  };
}

export function clearEditRuleSetVariables() {
  return (dispatch) => {
    dispatch(CLEAR_EDIT_RULE_SET_VARIABLES());
  };
}

export type ReconcileLabelsForCollectionPage = {
  labelIds: HangarLabelType['id'][];
  callback?: (err?, response?) => void;
};
export function reconcileLabelsForCollectionPage({
  labelIds,
  callback = _.noop,
}: ReconcileLabelsForCollectionPage) {
  return function dispatchReconcileLabelsForCollectionPage(dispatch, getState) {
    const state = getState();
    const collection = state.collection.collection;
    const currentLabelIds = getLabelIdsForCollectionPage(state);
    const toAdd = _.difference(labelIds, currentLabelIds);
    const toRemove = _.difference(currentLabelIds, labelIds);

    const cb = (err, result) => {
      callback(err, result);
      if (err) {
        showErrorSnack(err);
      } else {
        const collection =
          _.get(result, 'data.addLabelsToCollection') ||
          _.get(result, 'data.removeLabelsFromCollection');
        dispatch(UPDATE_COLLECTION({ collection }));
        showSuccessSnack({ message: 'Labels for collection updated' });
      }
      callback(err, result);
    };

    if (toAdd.length) {
      addLabelsToCollection(
        {
          collectionId: collection.id,
          labelIds: toAdd,
        },
        cb
      );
    }
    if (toRemove.length) {
      removeLabelsFromCollection(
        {
          collectionId: collection.id,
          labelIds: toRemove,
        },
        cb
      );
    }
  };
}

export type SubmitEditRuleSetArgs = { callback?: (err?, response?) => void };
export function submitEditRuleSet({
  callback = _.noop,
}: SubmitEditRuleSetArgs) {
  return function dispatchSubmitEditRuleSet(dispatch, getState) {
    const state = getState();
    const { ruleSet } = state.collection.collection;

    updateRuleSet(
      {
        id: ruleSet.id,
        robotLogSearchVariables: JSON.stringify(
          getMergedRuleSetEditingVariables(state)
        ),
      },
      (err, result) => {
        if (err) {
          dispatch(showErrorSnack(err));
        } else {
          const ruleSet = result.data.updateRuleSet;
          if (!ruleSet) {
            return;
          }

          const collection = {
            ...state.collection.collection,
            ruleSet,
          };

          // Set collection because it will handle processing properties (i.e. robotLogSearchVariables JSON parse)
          dispatch(SET_COLLECTION({ collection }));
          dispatch(clearEditRuleSetVariables());
          dispatch(
            forceCollectionDisplaysRefresh({ collectionId: collection.id })
          );
        }

        callback(err, result);
      }
    );
  };
}

export type RefreshRobotLogsArgs = { callback?: (err?, response?) => void };
export function refreshRobotLogs({ callback = _.noop }) {
  return function dispatchRefreshRobotLogs(dispatch, getState) {
    const state = getState();
    const collectionId = state.collection.collectionId;
    const collection = state.collection.collection;

    refreshRobotLogsForCollection({ collectionId }, (err, result) => {
      if (err) {
        dispatch(showErrorSnack(err));
      } else {
        dispatch(
          showSuccessSnack({
            message: 'Refreshed robot logs mapped for collection',
          })
        );
        const collectionUpdates = result.data.refreshRobotLogsForCollection;
        dispatch(
          SET_COLLECTION({
            collection: {
              ...collection,
              ...collectionUpdates,
            },
          })
        );

        dispatch(forceCollectionDisplaysRefresh({ collectionId }));
      }
      callback(err, result);
    });
  };
}

export type ForceCollectionDisplaysRefreshArgs = {
  collectionId: HangarCollectionType['id'];
};
export function forceCollectionDisplaysRefresh({ collectionId }) {
  return function dispatchForceCollectionDisplaysRefresh(dispatch, getState) {
    const state = getState();
    const collectionPageCollection = _.get(state, 'collection.collection');
    if (
      collectionPageCollection &&
      collectionPageCollection.id === collectionId
    ) {
      dispatch(forceMetabaseRefresh());
    }
    dispatch(clearMeasurementResults());

    // XXX - logic for driving this key should be put somewhere generic
    const tableCacheKey = `collection-${collectionId}`;
    dispatch(forceResultRefresh({ tableCacheKey }));
  };
}

export type RunIncludedExcludedMutationArgs = {
  robotLogIds: HangarRobotLogType['id'][];
  collectionId: HangarCollectionType['id'];
  mutation: DocumentNode;
  callback?: (err?, response?) => void;
};
export function runIncludedExcludedMutation({
  robotLogIds,
  collectionId,
  mutation,
  callback = _.noop,
}) {
  return function dispatchRunRobotLogRelationshipMutation(dispatch, getState) {
    alterIncludedExcludedMappings({
      variables: { collectionId, robotLogIds },
      mutation,
      callback: (err, result) => {
        callback(err, result);
        if (err) {
          dispatch(showErrorSnack(err));
          return;
        }
        const state = getState();
        const collectionPageCollection = _.get(state, 'collection.collection');

        // Refresh the collection page data if this mutation was run with that context
        if (
          collectionPageCollection &&
          collectionPageCollection.id === collectionId
        ) {
          const collectionUpdates = _.get(result, ['data', mutation], {});
          dispatch(
            SET_COLLECTION({
              collection: {
                ...collectionPageCollection,
                ...collectionUpdates,
              },
            })
          );
        }

        dispatch(forceCollectionDisplaysRefresh({ collectionId }));
      },
    });
  };
}

export function updateUiData({ uiData }) {
  return function dispatchUpdateUiData(dispatch, getState) {
    const { collection } = getState().collection;
    const currentUiData = collection.uiData || {};

    const newUiData = {
      ...currentUiData,
      ...uiData,
    };

    // fires the save update off async
    updateCollection(
      {
        id: collection.id,
        uiData: JSON.stringify(newUiData),
      },
      (err) => {
        if (err) {
          return dispatch(showErrorSnack(err));
        }
      }
    );

    // and immediately updates our store
    dispatch(
      UPDATE_COLLECTION({
        collection: {
          uiData: newUiData,
        },
      })
    );
  };
}

const DASHBOARD_GROUPS_UI_DATA_KEY = 'measurementsDashboardGroups';

export function addDashboardGroup() {
  return (dispatch, getState) => {
    const { groups } = getDashboardLayout(getState());

    const newGroup: MeasurementDashboardGroup = {
      id: uuid4(),
      name: 'New Group',
      description: '',
      items: [],
    };

    dispatch(
      updateUiData({
        uiData: {
          [DASHBOARD_GROUPS_UI_DATA_KEY]: [newGroup, ...groups],
        },
      })
    );
  };
}

export function updateDashboardGroup({ id, name, description }) {
  return (dispatch, getState) => {
    const { groups } = getDashboardLayout(getState());

    const newGroups = groups.map((group) => {
      if (group.id !== id) {
        return group;
      }

      return {
        ...group,
        name,
        description,
      };
    });

    dispatch(
      updateUiData({
        uiData: {
          [DASHBOARD_GROUPS_UI_DATA_KEY]: newGroups,
        },
      })
    );
  };
}

export function removeDashboardGroup({ id }) {
  return (dispatch, getState) => {
    const { groups } = getDashboardLayout(getState());

    const newGroups = groups.filter((group) => group.id !== id);

    dispatch(
      updateUiData({
        uiData: {
          [DASHBOARD_GROUPS_UI_DATA_KEY]: newGroups,
        },
      })
    );
  };
}
export function reorderDashboardGroups({ groups }) {
  return (dispatch) => {
    dispatch(
      updateUiData({
        uiData: {
          [DASHBOARD_GROUPS_UI_DATA_KEY]: groups,
        },
      })
    );
  };
}

export function refreshReviews() {
  return function dispatchRefreshReviews(dispatch, getState) {
    const state = getState();
    const collectionId = state.collection.collectionId;

    getCollectionReviews({ collectionIds: [collectionId] }, (err, result) => {
      if (err) {
        return dispatch(showErrorSnack(err));
      }

      const reviews = _.get(result, 'data.collectionReviews.edges', []).map(
        (edge) => edge.node
      );
      dispatch(SET_REVIEWS({ reviews }));

      getCollectionReviewProgressStats({
        collectionId,
        reviews,
        callback: (err, result) => {
          if (err) {
            dispatch(SET_REVIEW_STATS({ stats: [] }));
            return dispatch(showErrorSnack(err));
          }

          dispatch(SET_REVIEW_STATS({ stats: result }));
        },
      });
    });
  };
}

const forceMetabaseRefresh = FORCE_METABASE_REFRESH;
