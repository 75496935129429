import {
  HangarErrorLevel,
} from '@shield-ui/hangar-service';
import { REVIEW_STATUSES } from '../services/reviews';
import { darken, lighten } from '@mui/material/styles';
import { colors } from '@shield-ui/styles';
import { SessionLogType } from '@hmd/sdk/api/session_log/v1';

export const functionalColors = {
  filters: {
    main: colors.brand.primary,
    bright: lighten(colors.brand.primary, 0.1),
    light: lighten(colors.brand.primary, 0.2),
    lighter: lighten(colors.brand.primary, 0.3),
    lightest: lighten(colors.brand.primary, 0.4),
  },
};

export const severityColors = {
  INCOMPLETE: colors.hues.grays[40],
  CATASTROPHIC: '#bd1515',
  CRITICAL: '#E44545',
  MAJOR: '#F59336',
  MODERATE: '#ECD246',
  MINOR: '#bbd253',
  //PASS: colors.hues.green,
  //PASS: '#3DF5B3',//colors.hues.green,
  PASS: colors.hues.greens[130],
};

export const logEventLevelColors = {
  [HangarErrorLevel.Fatal]: darken(colors.hues.red, 0.3), // fatal
  [HangarErrorLevel.Error]: colors.hues.red,
  [HangarErrorLevel.Warn]: colors.hues.orange,
  [HangarErrorLevel.Info]: colors.hues.green, //'#53D2C0',
  [HangarErrorLevel.Debug]: lighten(colors.hues.blue, 0.6),
  [HangarErrorLevel.ErrorLevelUnspecified]: colors.hues.grays,
};

export const reviewStatusColors = {
  [REVIEW_STATUSES.NOT_STARTED]: '#e5e7e9',
  [REVIEW_STATUSES.IN_PROGRESS]: '#ECD246',
  [REVIEW_STATUSES.COMPLETE]: '#59D47B',
  [REVIEW_STATUSES.APPROVED]: '#6cc0d4',
};
