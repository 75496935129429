import _ from 'lodash';
import config from './config';
import 'isomorphic-fetch';
import { read, utils } from 'xlsx';
import { Client } from '@microsoft/microsoft-graph-client';
import { UserAgentApplication } from 'msal';

import { ImplicitMSALAuthenticationProvider } from '@microsoft/microsoft-graph-client/lib/src/ImplicitMSALAuthenticationProvider';
import { MSALAuthenticationProviderOptions } from '@microsoft/microsoft-graph-client/lib/src/MSALAuthenticationProviderOptions';

const BASE_URL = 'https://graph.microsoft.us/';

class MicrosoftGraphApi {
  constructor() {
    // Singleton
    if (!MicrosoftGraphApi.inst) {
      MicrosoftGraphApi.inst = this;
    }

    this.config = _.get(config, 'microsoftApi');
    this.authority = `https://login.microsoftonline.us/${this.config.tenant}`;

    this.loginRequest = {
      scopes: [
        'openid',
        'profile',
        'email',
        'User.Read', // Login Request Permissions
        'User.read',
        'Files.read',
        'Files.Read.All', // Fts Sheet Ingestion Permissions
      ],
      prompt: 'select_account',
    };

    this.tokenRequest = {
      scopes: [
        'openid',
        'profile',
        'email',
        'User.Read', // Login Request Permissions
        'User.read',
        'Files.read',
        'Files.Read.All', // Fts Sheet Ingestion Permissions
      ],
    };

    const msalConfig = {
      auth: {
        clientId: this.config.clientId,
        authority: this.authority,
        // redirectUri: this.config.redirectUri,
        postLogoutRedirectUri: this.config.postLogoutRedirectUri,
      },
      cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
      },
    };
    this.msalclient = new UserAgentApplication(msalConfig);
    const options = new MSALAuthenticationProviderOptions(
      this.tokenRequest.scopes
    );
    const authProvider = new ImplicitMSALAuthenticationProvider(
      this.msalclient,
      options
    );

    this.client = Client.initWithMiddleware({
      authProvider,
      baseUrl: BASE_URL,
    });

    return MicrosoftGraphApi.inst;
  }

  signIn = async () => {
    const signInRequest = this.msalclient
      .loginPopup(this.loginRequest)
      .then((loginResponse) => {
        return loginResponse;
      });
    return await signInRequest;
  };

  // Signout forces the user to interact with a microsoft popup/redirect
  // I'd much prefer simply revoking the token and redirecting the user to the login screen
  // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/1782
  signOut = async () => {
    await this.msalclient.logout();
  };

  getTokenPopup = async (request) => {
    const getTokenPromise = this.msalclient
      .acquireTokenSilent(request)
      .catch((error) => {
        console.error(error);
        console.error(
          'silent token acquisition fails. acquiring token using popup'
        );

        // On Failure, prompt user with interactive window
        return this.msalclient
          .acquireTokenPopup(request)
          .then((tokenResponse) => {
            return tokenResponse;
          })
          .catch((error) => {
            console.error('Microsoft acquire token failure');
            console.error(error);
          });
      });
    return getTokenPromise;
  };
}
const mapi = new MicrosoftGraphApi();

/// FTS Specific GraphApi Requests
// Convert an excel url to a driveItemId
export const getDriveItemFromUrl = async (ftsURLText) => {
  const shareId = convertToShareId(ftsURLText);
  const url = `/shares/${shareId}/driveItem?select=name,id,createdBy,@microsoft.graph.downloadUrl`;
  const driveItem = await mapi.client.api(url).get();
  return driveItem;
};

// Downloads a spreadsheet from a driveItem
export const getSpreadsheetFromDriveItem = async (driveItem) => {
  // Build url from driveItem
  const downloadUrl = _.get(driveItem, '@microsoft.graph.downloadUrl');

  // Fetch workbook file
  const workbook = await fetch(downloadUrl)
    .then((res) => {
      if (!res.ok) throw Error(`Downloading xlsx from failed`);
      return res.arrayBuffer();
    })
    .then((ab) => {
      const data = new Uint8Array(ab);
      const workbook = read(data, { type: 'array' });
      return workbook;
    });

  // Convert worksheet from workbook to parsable json
  const worksheet = _.get(workbook, ['Sheets', 'Observer Feedback']);
  const formattedWorksheet = utils.sheet_to_json(worksheet, {
    header: 1,
    blankrows: false,
    raw: false,
    defval: '',
  });
  return formattedWorksheet;
};

// Grabs a spreadsheetId from an FTS URL
export const getSpreadsheetIdFromUrl = async (ftsURLText) => {
  const driveItem = await getDriveItemFromUrl(ftsURLText);
  const spreadsheetId = _.get(driveItem, 'id', '');
  return spreadsheetId;
};

// Grabs a spreadsheetId from an FTS URL
export const getSpreadsheetFromUrl = async (ftsURLText) => {
  const driveItem = await getDriveItemFromUrl(ftsURLText);
  const spreadsheet = await getSpreadsheetFromDriveItem(driveItem);
  return { driveItem, spreadsheet };
};

// Converts an FTS URL to an FTS share ID
function convertToShareId(url) {
  const base64 = btoa(url).replace('=', '').replace('/', '_').replace('+', '-');
  const shareId = `u!${base64}`;
  return shareId;
}

// Converts a userId to a profile picture url
// https://graph.microsoft.us/v1.0/users/46fef3a1-8921-46f7-9586-b64e13c27176/photo/$value
export const getMicrosoftProfilePictureUrl = (userId) => {
  return `${BASE_URL}v1.0/users/${userId}/photo/$value`;
};

/// MSGraph rpc endpoints in hangar
// Converts an email into a hangar msgraph proxy picture url
// /msgraph/users -> graph.microsoft.us/v1.0/users
export const getMSGraphPictureUrl = (email) => {
  const hangarUrl = _.get(config, 'api.base');
  const url = `${hangarUrl}/msgraph/users/${email}/photo/$value`;
  return url;
};

export const azureBaseUrl = 'https://devops.shield.ai';

export default mapi;
