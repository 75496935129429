import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  showErrorSnack,
  showSuccessSnack,
} from '../../../redux/snackbar/actions';
import { createEvent, updateEvent } from '../../../redux/eventManager.slice';

function mapStateToProps(state, ownProps) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createEvent,
      updateEvent,
      showErrorSnack,
      showSuccessSnack,
    },
    dispatch
  );
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ContainerProps = ConnectedProps<typeof connector>;
export default connector;
