import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import withStyles from '@mui/styles/withStyles';
import CancelIcon from '@mui/icons-material/Cancel';
import TicketSearch, { ICON_SIZE } from './TicketSearch';
import container from './ticketsList';
import { InputBase, Chip, Tooltip } from '@mui/material';
import { commonStyles, colors } from '@shield-ui/styles';

function calcStyles(theme) {
  const vertPadding = 6;

  return {
    container: {
      width: '100%',
      paddingTop: vertPadding,
      paddingBottom: vertPadding,
      paddingLeft: 12,
      paddingRight: ICON_SIZE + ICON_SIZE * 1.5,
      border: `1px solid ${colors.semantic.inputBorder}`,
      backgroundColor: colors.semantic.inputBackground,
      borderRadius: theme.shape.borderRadius,
      transition: '0.1s ease',
      display: 'flex',
      flexWrap: 'wrap',
      margin: theme.spacing(0.25, 0),
      '&:hover': {
        borderColor: colors.semantic.inputBorderHover,
      },
    },
    containerFocused: {
      borderColor: colors.semantic.inputBorderFocus,
      '&:hover': {
        borderColor: colors.semantic.inputBorderFocus,
      },
    },
    iconPlacement: {
      right: ICON_SIZE + ICON_SIZE * 0.75,
      position: 'absolute',
      top: vertPadding + ICON_SIZE / 4,
    },
    inputRoot: {
      width: '100%',
    },
    inputInput: {
      fontWeight: 400,
      color: 'white',
      fontSize: 18,
      '&::placeholder': {
        ...commonStyles.placeholder,
      },
    },
    chipContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
    },
  };
}

class TicketMultiSelect extends React.Component {
  static defaultProps = {
    onChange: _.noop,
    value: [],
    maxValues: undefined,
  };

  constructor(props) {
    super(props);

    this.onFocus = () => this.setState({ focused: true });
    this.onBlur = () => this.setState({ focused: false });
    this.removeFuncs = {};
  }

  componentDidMount() {
    this.checkForTicketsToFetch();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.checkForTicketsToFetch();
    }
  }

  checkForTicketsToFetch() {
    const { value, ensureTicketListItems } = this.props;
    if (_.isEmpty(value)) {
      return;
    }

    ensureTicketListItems({
      ticketPlatformIds: value,
    });
  }

  getRemoveFunc = _.memoize((platformId) => {
    return () => {
      const { value, onChange } = this.props;

      const newValue = value.filter((v) => v !== platformId);
      onChange(_.isEmpty(newValue) ? undefined : newValue);
    };
  });

  renderInput = ({ inputProps, focused, IconComponent, inputRef }) => {
    const { classes, maxValues } = this.props;
    const tickets = this.getValueTickets();

    const renderInput = !maxValues || maxValues > tickets;

    const maxLen = 38;
    return (
      <div
        className={classNames(
          classes.container,
          focused && classes.containerFocused
        )}
      >
        <div className={classes.iconPlacement}>{IconComponent}</div>
        {tickets.map(({ platformId, name }) => {
          return (
            <Tooltip key={platformId} title={name.length > maxLen ? name : ''}>
              <Chip
                tabIndex={-1}
                label={
                  name.length > maxLen ? `${name.slice(0, maxLen)}...` : name
                }
                onDelete={this.getRemoveFunc(platformId)}
                deleteIcon={<CancelIcon />}
              />
            </Tooltip>
          );
        })}
        <InputBase
          inputRef={inputRef}
          classes={{ root: classes.inputRoot, input: classes.inputInput }}
          inputProps={inputProps}
          // rather than not rendering we hide this because we need blur events and stuff for control
          style={{ display: renderInput ? undefined : 'none' }}
        />
      </div>
    );
  };

  onSelect = ({ ticket }) => {
    const { value, onChange, setListItems } = this.props;

    setListItems({
      listKey: 'tickets',
      items: [ticket],
    });

    onChange(_.uniq(value.concat([ticket.platformId])));
  };

  onEmptyBackspace = () => {
    const { value, onChange } = this.props;

    onChange(value.slice(0, -1));
  };

  getValueTickets() {
    const { value, tickets } = this.props;
    const ticketMap = _.keyBy(tickets, 'platformId');

    if (!value) {
      return [];
    }

    return value.reduce((acc, platformId) => {
      if (ticketMap[platformId]) {
        acc.push(ticketMap[platformId]);
      } else {
        acc.push({
          platformId,
          // TODO - this could be better
          // We can do 1 of 2 things here
          // 1. - Only have the ticket multi select hit up hangar, this would make the results behave differently from the mapping section though (e.g. no URLs, etc...)
          // 2. - Take our stored platformIds and hit up the /tickets/search making sure it can handle them and give us good results
          // XXX - Also, things may be weird if we have a ticket from multiple platforms with the same platformId
          name: `Ticket not mapped to any issues: ${platformId}`,
        });
      }
      return acc;
    }, []);
  }

  render() {
    const { placeholder, value, disabled } = this.props;
    const tickets = this.getValueTickets();

    return (
      <TicketSearch
        renderInput={this.renderInput}
        placeholder={_.isEmpty(tickets) ? placeholder : ''}
        onSelect={this.onSelect}
        onEmptyBackspace={this.onEmptyBackspace}
        selectedPlatformIds={value}
        disabled={disabled}
      />
    );
  }
}

export default withStyles(calcStyles)(container(TicketMultiSelect));
