import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import { getColumnDefinition } from './columns';
import { isSortable } from '../generic/utils';
import { ROW_DISPLAY_PROPERTIES } from '../generic/ReactTable';
import { buildDocumentSearchQuery } from '../../../services/robotLogs';
import { getRobotLogUrl } from '../../../services/robotLogs';
import { buildFullQueryFromValues } from '@shield-ui/query-filters';

/**
 * Handle the post processing of the response from QueryExecutor
 * @param response - from apolloClient
 * @returns {{total: *, count: *, endCursor: *, rows: *, startCursor: *}}
 */
export function processQueryResponse(response) {
  const { data } = response;
  const edges = _.get(data, 'documentSearchRobotLogs.edges', []);
  const total = _.get(data, 'documentSearchRobotLogs.total');
  const endCursor = _.get(data, 'documentSearchRobotLogs.pageInfo.endCursor');
  const startCursor = _.get(
    data,
    'documentSearchRobotLogs.pageInfo.startCursor'
  );

  const robotLogs = edges.map((edge) => {
    const { node } = edge;

    // If robotLogData was part of the fragments requested
    // parse it into an object so it flows down to all rendering component as such (JSON parse in one place)
    if (_.isString(node.robotLogData)) {
      try {
        node.robotLogData = JSON.parse(node.robotLogData);
      } catch (e) {
        console.warn(node.robotLogData);
        console.warn('problem parsing robotLogData json', e);
        node.robotLogData = {};
      }
    }

    // Calculated for TableRow component on where to navigate to
    node.url = getRobotLogUrl(node);

    // special sauce that will filter down to the row background. I'm guessing this should be abstracted a bit more into something like "rowProperties"
    if (!_.get(node, 'myUserRobotLogDetail')) {
      node[ROW_DISPLAY_PROPERTIES.IS_UNREAD] = true;
    }

    return node;
  });

  return {
    rows: robotLogs,
    count: robotLogs.length,
    total,
    endCursor,
    startCursor,
  };
}

export function getGraphqlPropsSelector() {
  const getColumns = (props) => props.mergedColumns;
  const getAdditionalElasticQueries = (props) => props.additionalElasticQueries;
  const getTableContextProps = (props) => props.tableContextProps;
  const getFiltersVariables = (props) => props.filtersVariables;
  const getSort = (props) => props.mergedSort;
  const getPagination = (props) => props.pagination;
  const getPageSize = (props) => props.pageSize;

  const calcSort = createSelector(getSort, getColumns, (sort, columns) => {
    if (!sort) {
      return;
    }

    const { orderByColumn, orderByDirection = 'asc' } = sort;
    if (!orderByColumn) {
      return;
    }

    const columnMatch = _.find(
      columns,
      (col) =>
        col.columnUid === orderByColumn || col.columnKey === orderByColumn
    );
    if (!columnMatch) {
      return;
    }

    const def = getColumnDefinition({ columnKey: columnMatch.columnKey });
    if (!isSortable(def)) {
      return;
    }

    const { sortKey, getElasticSort } = def;

    let esSort;
    if (getElasticSort) {
      esSort = getElasticSort({ column: columnMatch, orderByDirection });
    } else if (sortKey) {
      esSort = [
        {
          [sortKey]: { order: orderByDirection },
        },
      ];
    }

    if (esSort) {
      return JSON.stringify(esSort);
    }

    return undefined;
  });

  const calcTableContextElasticQueries = createSelector(
    getTableContextProps,
    (tableContextProps) => {
      if (!tableContextProps) {
        return [];
      }

      const {
        collectionId,
        sessionLogTypeSlugs = [],
        robotLogIds = [],
      } = tableContextProps;

      const queries = [];

      if (collectionId) {
        queries.push({
          bool: {
            should: [
              {
                term: {
                  'collections.id': collectionId,
                },
              },
            ],
          },
        });
      }
      if (robotLogIds.length) {
        queries.push({
          ids: {
            values: robotLogIds,
          },
        });
      }
      if (sessionLogTypeSlugs.length > 0) {
        queries.push({
          bool: {
            should: sessionLogTypeSlugs.map((t) => ({
              term: {
                session_log_type_slug: t,
              },
            })),
          },
        });
      }
      return queries;
    }
  );

  const calcElasticQuery = createSelector(
    getFiltersVariables,
    getAdditionalElasticQueries,
    calcTableContextElasticQueries,
    (filters, additionalQueries = [], tableContextQueries = []) => {
      return buildFullQueryFromValues({
        values: filters,
        additionalQueries: additionalQueries.concat(tableContextQueries),
      });
    }
  );

  const calcElasticQueryJSONString = createSelector(calcElasticQuery, (query) =>
    JSON.stringify(query)
  );

  const getGraphqlVariables = createSelector(
    calcElasticQueryJSONString,
    calcSort,
    getPagination,
    getPageSize,
    (queryString, sort, pagination = {}, pageSize) => {
      const { after, before, page, lastPage } = pagination;

      const out = {
        query: queryString,
        sort,
      };

      out.first = _.isNumber(pageSize) && !isNaN(pageSize) ? pageSize : 15;
      const goForward = after && page > lastPage;
      const goBack = before && page < lastPage;

      if (after && goForward) {
        out.after = after;
      } else if (before && goBack) {
        out.before = before;
        out.last = _.isNumber(pageSize) ? pageSize : 15;
        out.first = null;
      }

      return out;
    }
  );

  const getGraphqlQuery = createSelector(getColumns, (columns) => {
    let fragments = columns.map((column) => {
      const { columnKey } = column;
      const def = getColumnDefinition({ columnKey });
      if (!def) {
        return '';
      }

      if (def.getFragment) {
        return def.getFragment({ column });
      }
      return def.fragment || '';
    });

    // graphql will handle this, but dedupe requests
    fragments = _.uniq(fragments);
    // so reordering columns doesn't cause refetch
    fragments = _.sortBy(fragments);

    // Data used for "rows" outside of our generic column implementation
    // e.g. userRobotLogDetails is used to add context by changing the background color of a row
    fragments.push(`
            myUserRobotLogDetail {
              viewedLastAt
            }
          `);

    return buildDocumentSearchQuery({ fragments });
  });

  return createSelector(
    getGraphqlQuery,
    getGraphqlVariables,
    calcElasticQuery,
    (graphqlQuery, graphqlVariables, elasticQuery) => {
      return {
        graphqlQuery,
        graphqlVariables,
        elasticQuery,
      };
    }
  );
}
