import _ from 'lodash';
import { Select } from 'antd';
import { TagTypes } from '../common';
import { LabelIdType, LabelType } from '@shield-ui/heatgrid';
import {
  MultiselectQueryFilterValue,
  GroupQueryFilterValue,
  UNSET_OPTION_VALUE,
} from '@shield-ui/query-filters';
import {
  default as tagGroupQueryFilter,
  generalTags as generalTagQueryFilter,
  componentTags as componentTagQueryFilter,
  equipmentTags as equipmentTagQueryFilter,
  environmentalTags as environmentalTagQueryFilter,
} from '../../../lib/queryFilters/robotLog/testDefinitionTags';
import {
  AxisDefinition,
  AxisOption,
  RenderDropdownFormProps,
} from './AxisDefinition';

export const tagAxisDefinition: AxisDefinition = {
  id: 'Tag',
  getDropdownLabel: (axisOption?: AxisOption) => {
    if (axisOption && axisOption.tagType) {
      return `Tags - ${axisOption.tagType}`;
    }
    return 'Tags';
  },
  buildAggsDsl: (axisOption?: AxisOption) => {
    return {
      terms: {
        field: `test_definition.${_.toLower(axisOption?.tagType)}_tags`,
        missing: 'N/A',
      },
    };
  },
  prepareAxisLabels: (labelIds: LabelIdType[]) => {
    return new Promise<LabelType[]>((resolve) => {
      let emptyLabel: LabelType = undefined;
      const labels: LabelType[] = _.sortBy(
        labelIds.reduce((acc, labelId) => {
          if (labelId === 'N/A') {
            emptyLabel = {
              id: labelId,
              label: '-',
            };
            return acc;
          }
          return [
            ...acc,
            {
              id: labelId,
              label: labelId.toString(),
            },
          ];
        }, []),
        (label) => label.label
      );

      if (emptyLabel) {
        resolve([...labels, emptyLabel]);
      }
      resolve(labels);
    });
  },
  buildQueryFilter: (
    filterValues: object,
    selectedLabels: LabelType[],
    axisOption?: AxisOption
  ) => {
    // current individual values or new object
    const individualValues = _.get(
      filterValues,
      [tagGroupQueryFilter.getId(), 'individualValues'],
      {}
    );

    // build the QF value for this grouped QF.
    const newTagValues: MultiselectQueryFilterValue = {
      includeOptionValues: selectedLabels.map((label) => {
        if (label.id === 'N/A') {
          return UNSET_OPTION_VALUE;
        }
        return label.id;
      }),
    };

    switch (axisOption.tagType) {
      case TagTypes.General:
        individualValues[generalTagQueryFilter.getId()] = newTagValues;
        break;
      case TagTypes.Component:
        individualValues[componentTagQueryFilter.getId()] = newTagValues;
        break;
      case TagTypes.Equipment:
        individualValues[equipmentTagQueryFilter.getId()] = newTagValues;
        break;
      case TagTypes.Environmental:
        individualValues[environmentalTagQueryFilter.getId()] = newTagValues;
        break;
    }

    // Build the Groups value and compute esQuery
    const newValue: GroupQueryFilterValue = {
      individualValues,
      esQuery: undefined,
    };
    newValue.esQuery = tagGroupQueryFilter.getElasticQuery(newValue);

    // set the new value for this and return any other existing filter values
    return {
      ...filterValues,
      [tagGroupQueryFilter.getId()]: newValue,
    };
  },
  defaultAxisOption: { tagType: TagTypes.General },
  renderForm: (p: RenderDropdownFormProps) => {
    return (
      <Select
        key={'tags'}
        className={p.className}
        value={p.axisOption?.tagType}
        onSelect={(tagType: TagTypes) => p.onChange({ tagType })}
      >
        {Object.keys(TagTypes).map((tagType) => (
          <Select.Option key={`tag-${tagType}`} value={tagType} label={tagType}>
            {tagType}
          </Select.Option>
        ))}
      </Select>
    );
  },
};
