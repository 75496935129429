import { MultiselectQueryFilter } from '@shield-ui/query-filters';
import { robotLogDocumentFields } from './fields';

export default new MultiselectQueryFilter({
  id: 'childrenNames',
  controlLabel: 'Children',
  esField: robotLogDocumentFields['children.name'].field,
  isMulti: true,
  behaviorConfig: {
    isCreatable: true,
  },
});
