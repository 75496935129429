import React from 'react';
import _ from 'lodash';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import ClearIcon from '@mui/icons-material/Clear';
import { SimpleMenu, SimpleMenuItem } from '@shield-ui/core';
import { QueryFilterItem, ContextMenuState, ManagementProps } from './types';

type Props = {
  onClose: () => void;
  queryFilterItem: QueryFilterItem;
  values: object;
  setValues: (args: object) => void;
  contextMenu: ContextMenuState;
  management?: ManagementProps;
};

export default function RightClickMenu(props: Props) {
  const {
    queryFilterItem,
    values,
    setValues,
    management,
    contextMenu,
    onClose,
  } = props;
  const { queryFilter } = queryFilterItem;
  const label = queryFilter.getControlLabel();
  const value = _.get(values, queryFilter.getId());
  const hasValue = queryFilter.hasValue(value);

  return (
    <SimpleMenu
      title={`Manage ${label}`}
      anchorEl={contextMenu.anchorEl}
      menuItems={(function () {
        const arr: SimpleMenuItem[] = [
          {
            label: 'Clear',
            Icon: DeleteIcon,
            onClick: () => {
              setValues({
                [queryFilter.getId()]: undefined,
              });
            },
            disabled: !hasValue,
          },
        ];

        if (management) {
          arr.push({
            label: 'Remove from Control Bar',
            Icon: ClearIcon,
            onClick: () => {
              management.setIds(
                management.ids.filter((id) => id !== contextMenu.queryFilterId)
              );
            },
          });
        }

        return arr;
      })()}
      onClose={onClose}
    />
  );
}
