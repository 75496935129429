import _ from 'lodash';
import Cell from './Cell';

const config = {
  columnKey: 'children',
  label: 'Children',
  description: 'Children Robot Logs (other flights, test instances, or trials)',
  defaultWidth: 300,
  sortKey: 'children.name',
  Cell,
  getValue: ({ row }) => {
    return _.get(row, 'children', [])
      .map((child) => child.name)
      .join('\n');
  },
  fragment: `
        children {
            id
            name
        }
    `,
};

export default config;
