import {BoxAndWhiskerForm} from './form';
import BoxAndWhiskerPlot from './plot';
import fetchData from './fetchData';
import {BoxPlotOutlined} from '@ant-design/icons';
import {plotConfig, plotOptions,metricAnalysis} from '../common';

interface localPlotConfig extends plotConfig{
	plotOptions:localPlotOptions;
}
interface localPlotOptions extends plotOptions{
	metricAnalysis: metricAnalysis
}

export default function boxAndWhiskerDefintion () {
  return ({
    id:"boxAndWhisker",
    label:"Box and Whisker",
    icon: <BoxPlotOutlined  rotate={90}/>,
    description: "Box and Whisker plots display data distribution through quartiles. They are great for ",
    loadingLayout: () => 	({boxmode: 'group'}),
    renderFormElements: ()=> <BoxAndWhiskerForm/>,
    getUpdateValues: ({plotOptions}:localPlotConfig)=> ({    
      title: plotOptions.title,  
      metricAnalysis: plotOptions.metricAnalysis,
      secondBreakdown: plotOptions.secondBreakdown,
      groupByTime: plotOptions.groupByTime,
    }),
    getInitialValues: ()=> ({
      title:'',
      metricAnalysis: [],
      groupByTime:'allTime',
      secondBreakdown:'',
    }),
    fetchData: (query, plotConfig:localPlotConfig) => fetchData(query,plotConfig),
    renderPlot: (plotConfig:localPlotConfig, response, actions) =>
      <BoxAndWhiskerPlot
        plotConfig={plotConfig}
        response={response}
        actions={actions}
      />,
  })
}