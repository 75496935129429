import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  showErrorSnack,
  showSuccessSnack,
} from '../../../redux/snackbar/actions';
import {
  resetTableSearchResult,
  setTableSearchResult,
  initializeTableCache,
  removeTableCache,
  setSort,
  setPagination,
  resetPagination,
  setColumns,
  setPageSize,
  resetColumns,
  updateColumnOrder,
  updatePinnedColumns,
  updateColumnWidths,
  setVisibleModal,
  toggleFiltersPanel,
  setFilters,
  resetFilters,
  getShareUrl,
  testShareUrlLoad,
} from '../../../redux/tables/actions';
import { selectTablePropsForTableCacheKey } from '../../../redux/tables';

function mapStateToProps(state, ownProps) {
  return selectTablePropsForTableCacheKey(state, ownProps.tableCacheKey);
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      // generic
      showErrorSnack,
      showSuccessSnack,
      // tables
      initializeTableCache,
      removeTableCache,
      resetTableSearchResult,
      setTableSearchResult,
      setSort,
      setPageSize,
      setPagination,
      resetPagination,
      setColumns,
      resetColumns,
      updateColumnOrder,
      updatePinnedColumns,
      updateColumnWidths,
      setVisibleModal,
      toggleFiltersPanel,
      setFilters,
      resetFilters,
      getShareUrl,
      testShareUrlLoad,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps);
