import withStyles from '@mui/styles/withStyles';
import { Chip } from '@mui/material';
import { functionalColors } from '../../../../../lib/sharedStyles';
import { colors } from '@shield-ui/styles';

function styles() {
  return {
    root: {
      margin: 2,
      border: `1px solid ${functionalColors.filters.bright}`,
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: functionalColors.filters.main,
      },
      '&:focus': {
        backgroundColor: functionalColors.filters.main,
      },
    },
    label: {
      maxWidth: 350,
      color: colors.semantic.bodyText,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    deleteIcon: {
      color: functionalColors.filters.light,
      '&:hover': {
        color: functionalColors.filters.lighter,
      },
    },
  };
}

export default withStyles(styles)(Chip);
