import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import container from './container';
import Controls from './Controls';
import QueryExecutor from './QueryExecutor';
import { getCalculateTablePropsSelector } from './utils';
import { getHasAnyFilterSet } from '../../../redux/tables/utils';
import FiltersPanel from './FiltersPanel';
import { Popover, Typography } from '@mui/material';
import { SubNavTabs, calcSubNavTabsPathPrefix } from '@shield-ui/core';
import GoBackToast from './GoBackToast';
import history from '../../../routes/history';
import ArtifactManager from '../../../routes/ArtifactManager/ArtifactManager';
import { Modal } from 'antd';
import ReactTable from './ReactTable';

function styles() {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '100%',
    },
    noResultsMsg: {
      marginLeft: 15,
      color: '#bbb',
    },
  };
}

/**
 * TODO
 *
 * FUTURE
 * - automatically wrap all table methods (e.g. setColumns or setResponse with tableCacheKey)
 */
class TableManager extends React.Component {
  static propTypes = {
    /**
     * Will get initialized once if it is not
     */
    isCacheInitialized: PropTypes.bool.isRequired,
    /**
     * Overload default initialization of an "empty" table cache by setting
     * things like initial columns, sort, control properties, etc...
     */
    initializeProps: PropTypes.object,
    /**
     * The specific query variables coming from outside of the table, e.g. what filters or context we are on
     */
    queryVariables: PropTypes.object,
    /**
     * This is the all important key that all data is stored under
     */
    tableCacheKey: PropTypes.string.isRequired,
    /**
     * Returns the column definition from a column key
     */
    getColumnDefinition: PropTypes.func.isRequired,
    /**
     * Returns a list of ALL columns possible for this type
     */
    getAllColumnDefinitions: PropTypes.func.isRequired,
    /**
     * Takes this.props and should returns: { graphqlQuery: '', graphqlVariables: {} }
     */
    getGraphqlProps: PropTypes.func.isRequired,

    /**
     * Clean out the cached table when this is unmounted, useful for table per endpoint type tables where you don't want to store everything
     */
    removeTableCacheOnUnmount: PropTypes.bool,

    onChangeColumns: PropTypes.func,
    onChangePinnedColumns: PropTypes.func,
    onChangeWidths: PropTypes.func,
    onChangeSort: PropTypes.func,
    onChangeFiltersVariables: PropTypes.func,
    onResult: PropTypes.func,
    /**
     * If implemented, the filters control will be implemented in the table
     */
    FiltersPanelContentComponent: PropTypes.any,
    /**
     * Called when a row is clicked with all the goodies of the table passed in the "event"
     */
    onRowClick: PropTypes.func,
    /**
     * implemented by the parent to build a context menu when the on context menu is fired
     */
    buildContextMenu: PropTypes.func,

    skipControls: PropTypes.bool,
    controlBar: PropTypes.shape({
      queryFilterItems: PropTypes.array,
      defaultVisibleQueryFilterIds: PropTypes.array,
    }),
    additionalContents: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string,
        renderContent: PropTypes.func,
      })
    ),
    renderExtraTableControls: PropTypes.func,
  };

  static defaultProps = {
    removeTableCacheOnUnmount: false,
    onChangeColumns: _.noop,
    onChangePinnedColumns: _.noop,
    onChangeWidths: _.noop,
    onChangeSort: _.noop,
    onChangeFiltersVariables: _.noop,
    onResult: _.noop,
    queryVariables: {} /* optionally passed down to add context */,
    onRowClick: _.noop,
    buildContextMenu: _.noop,
    skipControls: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      hasError: undefined,
      ContextMenu: undefined,
      contextMenuPosition: undefined,
      activeTabPath: undefined,
      goBackContext: undefined,
    };

    this._additionalContentTabs = [];
    if (
      props &&
      props.additionalContents &&
      props.additionalContents.length > 0
    ) {
      this._additionalContentTabs = [
        {
          label: 'Results',
          path: '/results',
          Component: this.renderTable.bind(this),
        },
      ].concat(
        props.additionalContents.map((ac) => ({
          label: ac.label,
          path: ac.path,
          Component: () => ac.renderContent(this.getTabProps()),
        }))
      );
    }
    this.calculateTableProps = getCalculateTablePropsSelector();
    this.ensureInitializedCache();
  }

  ensureInitializedCache() {
    const { initializeTableCache, tableCacheKey, initializeProps } = this.props;

    initializeTableCache({
      tableCacheKey,
      initializeProps,
    });
  }

  // This is just in place to force a rerender so the QueryExecutor will fire a query when we switch back if things changed...
  onTabChange = (tab) => {
    this.setState({ activeTabPath: tab.path });
  };

  resetFilters = () => {
    const { tableCacheKey, resetFilters } = this.props;
    resetFilters({ tableCacheKey });
  };

  setFilters = (filters) => {
    const { setFilters, tableCacheKey } = this.props;

    setFilters({
      tableCacheKey,
      filtersVariables: filters,
    });
  };

  componentDidCatch(error, info) {
    // Display fallback UI
    console.error(error);
    this.setState({ hasError: true, error: error });
  }

  componentDidUpdate(prevProps) {
    const {
      tableCacheKey,
      onChangeColumns,
      onChangePinnedColumns,
      onChangeWidths,
      onChangeSort,
      onChangeFiltersVariables,
      onResult,
    } = this.props;

    if (prevProps.queryVariables !== this.props.queryVariables) {
      this.props.resetPagination({ tableCacheKey });
    }

    // we don't care about diffs if the previous state was not an initialized state
    if (!prevProps.isCacheInitialized) {
      return;
    }

    if (prevProps.sort !== this.props.sort) {
      onChangeSort(this.props);
    }

    //originally: prevProps.columns !== this.props.columns but buggy/fired every render so checking for deep equality of columns instead
    if (!_.isEqual(prevProps.columns, this.props.columns)) {
      onChangeColumns(this.props);
    }
    if (prevProps.filtersVariables !== this.props.filtersVariables) {
      onChangeFiltersVariables(this.props);
    }
    if (prevProps.pinnedColumns !== this.props.pinnedColumns) {
      onChangePinnedColumns(this.props);
    }

    if (prevProps.widths !== this.props.widths) {
      onChangeWidths(this.props);
    }

    // if we just got a table result and it's not loading, fire it back
    if (
      this.props.result &&
      prevProps.result !== this.props.result &&
      !this.props.result.isLoading
    ) {
      onResult({ result: this.props.result });
    }
  }

  componentDidMount() {
    const { testShareUrlLoad, tableCacheKey } = this.props;
    testShareUrlLoad({ tableCacheKey });
  }

  componentWillUnmount() {
    const {
      removeTableCacheOnUnmount,
      resetTableSearchResult,
      removeTableCache,
      tableCacheKey,
    } = this.props;

    // we either nuke the entire table, or just the search results
    if (removeTableCacheOnUnmount && removeTableCache) {
      removeTableCache({ tableCacheKey });
    } else if (resetTableSearchResult) {
      // this used to be in the QueryExecutor but it was moved here so we could do A or B
      // clearing the results after the cache was cleared was an invalid action (warning)
      resetTableSearchResult({ tableCacheKey });
    }
  }

  onRowClick = ({ row }) => {
    const { onRowClick } = this.props;

    onRowClick({
      row,
      tableManagerProps: this.props,
    });
  };

  onCloseRowContextMenu = () => {
    this.setState({
      ContextMenu: undefined,
      contextMenuPosition: undefined,
    });
  };

  onRowContextMenu = ({ row, evt }) => {
    const { buildContextMenu } = this.props;

    const textSelection = window.getSelection().toString();
    if (textSelection) {
      const targetContent = evt.target.innerHTML;
      if (
        textSelection.indexOf(targetContent) > -1 ||
        targetContent.indexOf(textSelection) > -1
      ) {
        console.info(
          'skip right click menu because interacting with highlighted text'
        );
        return;
      }
    }

    const ContextMenu = buildContextMenu({
      row,
      tableManagerProps: this.props,
      onClose: this.onCloseRowContextMenu,
    });

    if (ContextMenu) {
      evt.stopPropagation();
      evt.preventDefault();
      this.setState({
        ContextMenu,
        contextMenuPosition: {
          left: evt.clientX,
          top: evt.clientY,
        },
      });
    }
  };

  isOnNonTableTab() {
    const { additionalContents = [] } = this.props;
    if (!additionalContents.length) {
      return false;
    }
    if (window.location.pathname.endsWith('/results')) {
      return false;
    }
    return !!additionalContents.filter((ac) => {
      return window.location.pathname.endsWith(ac.path);
    }).length;
  }

  jumpToContent = (args = {}) => {
    const { contentPath, filterValues, setGoBack } = args;
    const { filtersVariables: currentFilterVariables } = this.props;
    const currentRoute = window.location.pathname;

    if (contentPath) {
      const route = calcSubNavTabsPathPrefix(this._additionalContentTabs);
      history.replace(route);
    }
    if (filterValues) {
      this.setFilters(args.filterValues);
    }

    if (setGoBack) {
      this.setGoBack({
        filterValues: currentFilterVariables,
        route: currentRoute,
        label: args.goBackLabel,
      });
    }
  };

  setGoBack = (goBackContextArgs) => {
    // filterValues,
    // label
    // route
    this.setState({
      goBackContext: goBackContextArgs,
    });
  };

  clearGoBack = () => {
    this.setState({
      goBackContext: undefined,
    });
  };

  processGoBack = () => {
    if (Object.keys(this.state.goBackContext.filterValues).length === 0) {
      this.resetFilters();
    } else {
      this.setFilters(this.state.goBackContext.filterValues);
    }
    history.replace(this.state.goBackContext.route);
    this.clearGoBack();
  };

  getTabProps() {
    const { getGraphqlProps, filtersVariables } = this.props;

    return {
      ...getGraphqlProps(this.props),
      setFilterValues: this.setFilters,
      filterValues: filtersVariables,
      jumpToContent: this.jumpToContent,
    };
  }

  getTablePassThroughProps() {
    return _.pick(this.props, [
      // values
      'tableCacheKey',
      'result',
      'mergedColumns',
      'mergedSort',
      'columns',
      'pagination',
      'sort',
      'widths',
      'pageSize',
      'prependContextColumns',
      'enableTopToolbar',
      // methods
      'getColumnDefinition',
      'setSort',
      'setPageSize',
      'setPagination',
      'setColumns',
      'setVisibleModal',
      'resetColumns',
      'updateColumnOrder',
      'updatePinnedColumns',
      'updateColumnWidths',
      'renderExtraTableControls',
    ]);
  }

  shouldSkipTableRender() {
    // TODO nontable tab doesn't work because when tab cha    const route = calcSubNavTabsPathPrefix(this._additionalContentTabs)nges we need to render here... probably expose an onChange in subNavTabs
    return (
      !!_.get(this.props, 'visibleControlsModal') || this.isOnNonTableTab()
    );
  }

  renderTable() {
    // generic props from the container and through config to pass down
    const tablePassThroughProps = this.getTablePassThroughProps();

    // memoized properties transcribing our table config into our implementation specific properties
    const tableProps = this.calculateTableProps(this.props);

    return (
      <ReactTable
        {...tablePassThroughProps}
        {...tableProps}
        onRowClick={this.onRowClick}
        onRowContextMenu={this.onRowContextMenu}
      />
    );
  }

  render() {
    const {
      isCacheInitialized,
      getGraphqlProps,
      classes,
      result,
      overrideEmptyTableMsg,
      artifactModal,
      hideModal,
      rowId,
    } = this.props;
    const { hasError, ContextMenu, contextMenuPosition } = this.state;
    if (!isCacheInitialized) {
      return null;
    }
    if (hasError) {
      return (
        <div>
          HAS ERROR<pre>{this.state.error}</pre>
        </div>
      );
    }

    const hasAnyFilterSet = getHasAnyFilterSet(this.props.filtersVariables);

    // memoized selection to calculate graphql variables and query
    const graphqlProps = getGraphqlProps(this.props);

    const queryPassThroughProps = _.pick(this.props, [
      // values
      'tableCacheKey',
      'forceResultRefreshCounter',
      // methods
      'setTableSearchResult',
      'showErrorSnack',
      'processQueryResponse',
    ]);
    const controlsPassThroughProps = _.pick(this.props, [
      // values
      'tableCacheKey',
      'result',
      'mergedColumns',
      'mergedSort',
      'pagination',
      'pageSize',
      'columns',
      'pinnedColumns',
      'defaultPinnedColumns',
      'visibleControlsModal',
      'filtersVariables',
      // methods
      'showErrorSnack',
      'showSuccessSnack',
      'processQueryResponse',
      'getColumnDefinition',
      'getAllColumnDefinitions',
      'setColumns',
      'resetColumns',
      'setSkipTableRender',
      'setVisibleModal',
      'toggleFiltersPanel',
      'setFilters',
      'resetFilters',
      'getShareUrl',
      'ControlBarComponent',
      'menuItems',
    ]);

    const filtersPanelPassThroughProps = _.pick(this.props, [
      // values
      'tableCacheKey',
      'filtersVariables',
      'filtersVisible',
      'queryVariables',
      // methods
      'toggleFiltersPanel',
      'setFilters',
      'resetFilters',
      // config by table type
      'FiltersPanelContentComponent',
    ]);

    const implementsFiltersPanel = !!this.props.FiltersPanelContentComponent;

    let content;

    if (this._additionalContentTabs.length) {
      content = (
        <SubNavTabs
          autoPathPrefix
          onTabChange={this.onTabChange}
          tabs={this._additionalContentTabs}
        />
      );
    } else if (result.rows.length == 0 && overrideEmptyTableMsg) {
      content = (
        <Typography className={classes.noResultsMsg} variant="caption">
          {overrideEmptyTableMsg}
        </Typography>
      );
    } else {
      content = this.renderTable();
    }

    const skipTableRender = this.shouldSkipTableRender();
    return (
      <React.Fragment>
        <div className={classes.container}>
          <QueryExecutor
            {...queryPassThroughProps}
            {...graphqlProps}
            skipTableRender={skipTableRender}
          />
          {!this.props.skipControls && (
            <Controls
              {...graphqlProps}
              {...controlsPassThroughProps}
              skipTableRender={skipTableRender}
              implementsFiltersPanel={implementsFiltersPanel}
              hasAnyFilterSet={hasAnyFilterSet}
              controlBar={this.props.controlBar}
              setFilters={this.setFilters}
              resetFilters={this.resetFilters}
            />
          )}
          <div>{content}</div>
          {implementsFiltersPanel && (
            <FiltersPanel
              {...filtersPanelPassThroughProps}
              hasAnyFilterSet={hasAnyFilterSet}
            />
          )}
        </div>
        <Popover
          open={!!ContextMenu}
          onClose={this.onCloseRowContextMenu}
          anchorReference="anchorPosition"
          anchorPosition={contextMenuPosition}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transitionDuration={0}
        >
          {ContextMenu}
        </Popover>
        <Modal
          centered
          title="Artifact Manager"
          visible={!!artifactModal}
          onOk={() => hideModal()}
          onCancel={() => hideModal()}
          width={700}
        >
          <ArtifactManager sessionLogId={rowId} />
        </Modal>
        {this.state.goBackContext && (
          <GoBackToast
            label={this.state.goBackContext.label}
            onDismiss={this.clearGoBack}
            onGoBack={this.processGoBack}
          />
        )}
      </React.Fragment>
    );
  }
}

export default container(withStyles(styles)(TableManager));
