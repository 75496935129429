import {DataOverTimeForm} from './form';
import DataOverTimePlot from './plot';
import fetchData from './fetchData';
import {LineChartOutlined} from '@ant-design/icons'
import {plotConfig, plotOptions,metricAnalysis} from '../common';

interface localPlotConfig extends plotConfig{
	plotOptions: localPlotOptions;

}
interface localPlotOptions extends plotOptions{
	metricAnalysis: metricAnalysis[];
	aggType: string[];
	graphType:string;
}
export default function dataOverTimeDefinition () {
  return ({
		id:"dataOverTime",
		label:"Data Over Time",
		icon:<LineChartOutlined />,
		loadingLayout: (graphType) => graphType === "bar" ? {barmode:"stack"} : {},
		description:"Data over time plots visualize data over a distributed time period. They are great for displaying changes or trends over time." ,
		renderFormElements: () => <DataOverTimeForm/>,
		getUpdateValues: ({plotOptions}:localPlotConfig)=> ({      
			title: plotOptions.title,
			metricAnalysis: plotOptions.metricAnalysis,
			aggType: plotOptions.aggType,
			groupByTime: plotOptions.groupByTime,
			secondBreakdown: plotOptions.secondBreakdown,
			graphType: plotOptions.graphType
		}),
		getInitialValues: () => ({
			title: '',
			metricAnalysis: [],
			aggType:['avg'],
			groupByTime:'year',
			secondBreakdown:'',
			graphType:'line'
		}),
		fetchData: (query, plotConfig:localPlotConfig) => fetchData(query, plotConfig),
    renderPlot:(plotConfig:localPlotConfig,response,actions) =>( 
    <DataOverTimePlot
      plotConfig={plotConfig}
			response={response}
			actions={actions}
      />
    ),

	})
}
