import React from 'react';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import { getPipelineExecution } from '../../services/pipelineExecutions';
import { ModalControllerComponentProps } from '../modals/ModalController';
import { Paper } from '@mui/material';
import ModalHeader from '../modals/Header';
import { getFormattedDateTime } from '@shield-ui/utils';
import JsonViewer from '../JsonViewer';

const styles = (theme) =>
  createStyles({
    container: {
      padding: theme.spacing(3),
      width: 800,
      maxHeight: '90%',
      // not sure why this gets a focus outline
      outline: 'none',
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      overflowY: 'auto',
      height: '100%',
    },
  });

interface Props
  extends WithStyles<typeof styles>,
    ModalControllerComponentProps {
  pipelineExecutionId: string;
}

class PipelineExecutionDetailsModal extends React.Component<Props> {
  state = {
    pipelineExecution: undefined,
  };

  fetch() {
    const { pipelineExecutionId, showErrorSnack } = this.props;

    getPipelineExecution(
      {
        id: pipelineExecutionId,
      },
      (err, result) => {
        if (err) {
          return showErrorSnack(err);
        }
        this.setState({
          pipelineExecution: result.data.pipelineExecution,
        });
      }
    );
  }

  componentDidMount() {
    this.fetch();
  }

  render() {
    const { hideModal, classes } = this.props;
    const { pipelineExecution } = this.state;

    let data;
    if (pipelineExecution && pipelineExecution.data) {
      try {
        data = JSON.parse(pipelineExecution.data);
      } catch (e) {
        data = e;
      }
    }

    return (
      <Paper className={classes.container}>
        <ModalHeader onClose={hideModal}>
          Pipeline Execution Details
        </ModalHeader>
        {pipelineExecution && (
          <div className={classes.content}>
            <div>{pipelineExecution.description}</div>
            <div>PiperRunID: {pipelineExecution.name}</div>
            <div>
              {pipelineExecution.createdAt &&
                getFormattedDateTime(pipelineExecution.createdAt)}
            </div>
            <JsonViewer src={data} style={{ marginTop: 16 }} />
          </div>
        )}
        {!pipelineExecution && <div>Loading...</div>}
      </Paper>
    );
  }
}

export default withStyles(styles)(PipelineExecutionDetailsModal);
