import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';

import {
  JavaScriptValue,
  Struct,
} from 'google-protobuf/google/protobuf/struct_pb';
import { queryClient } from '../queryClient';
import { EntitySchema } from '@hmd/sdk/api/systems/entity_schema/v1';
import {
  createEntitySchema,
  getEntitySchemas,
  getPropertyValues,
  updateEnitySchema,
} from '../api/entitySchemas';

export const EntitySchemaAsObjectToEntitySchemaJSON = (
  entitySchema: EntitySchema
): EntitySchemaJSON => {
  const schemaStruct = entitySchema.getSchema();
  const uiSchemaOverridesStruct = entitySchema.getUischemaOverrides();

  return {
    id: entitySchema.getId(),
    name: entitySchema.getName(),
    slug: entitySchema.getSlug(),
    schema: schemaStruct ? (schemaStruct.toJavaScript() as JSONSchema) : null,
    uiSchemaOverrides: uiSchemaOverridesStruct
      ? uiSchemaOverridesStruct.toJavaScript()
      : {},
    description: entitySchema.getDescription(),
    lifeCycleDetails: entitySchema.toObject().lifeCycleDetails,
  };
};

const getEntitySchemasList = async () => {
  const entitySchemas = await getEntitySchemas();

  return entitySchemas.map((entitySchema) =>
    EntitySchemaAsObjectToEntitySchemaJSON(entitySchema)
  );
};

const ENTITY_SCHEMAS_QUERY_KEY = 'entitySchemas';

export const fetchEntitySchemas = async () => {
  return await queryClient.fetchQuery(
    [ENTITY_SCHEMAS_QUERY_KEY],
    getEntitySchemasList
  );
};

export const useEntitySchemas = () =>
  useQuery<EntitySchemaJSON[], Error>({
    queryKey: [ENTITY_SCHEMAS_QUERY_KEY],
    queryFn: getEntitySchemasList,
  });

export type SchemaProperty = {
  type: string;
  title?: string;
  description?: string;
  default?: string;
  enum?: string[];
  format?: string;
  items?: {
    type: string;
    enum?: string[];
    format?: string;
  };
  properties?: {
    [key: string]: SchemaProperty;
  };
};

// JSON Schema
export type JSONSchema = {
  $schema?: string;
  title?: string;
  type: string;
  properties?: {
    [key: string]: SchemaProperty;
  };
};

// UI Schema
export type UISchema = {
  [key: string]: any;
};

export type EntitySchemaJSON = {
  id?: string;
  name: string;
  slug: string;
  schema: JSONSchema;
  uiSchemaOverrides?: UISchema;
  description?: string;
  lifeCycleDetails?: EntitySchema.AsObject['lifeCycleDetails'];
};

type MutationParams = {
  onSuccess?: () => void;
  onError?: () => void;
};

export const useCreateEntitySchema = ({ onSuccess, onError }: MutationParams) =>
  useMutation<EntitySchemaJSON, Error, EntitySchemaJSON>({
    mutationFn: async (schema) => {
      const entitySchema = await createEntitySchema(schema);
      return EntitySchemaAsObjectToEntitySchemaJSON(entitySchema);
    },
    onSuccess: () => {
      // Invalidate the query to refetch the data
      queryClient.invalidateQueries({
        queryKey: [ENTITY_SCHEMAS_QUERY_KEY],
      });
      if (onSuccess) onSuccess();
    },
    onError: () => {
      if (onError) onError();
    },
  });

export const useUpdateEntitySchema = ({ onSuccess, onError }: MutationParams) =>
  useMutation<EntitySchemaJSON, Error, EntitySchemaJSON>({
    mutationFn: async (schema) => {
      const entitySchema = await updateEnitySchema(schema);
      return EntitySchemaAsObjectToEntitySchemaJSON(entitySchema);
    },
    onSuccess: () => {
      // Invalidate the query to refetch the data
      queryClient.invalidateQueries({
        queryKey: [ENTITY_SCHEMAS_QUERY_KEY],
      });
      if (onSuccess) onSuccess();
    },
    onError: () => {
      if (onError) onError();
    },
  });

const PROPERTY_VALUES_QUERY_KEY = 'propertyValues';

type GetPropertyValuesParams = {
  schemaId: string;
  property: string;
};

const getPropertyValuesList = async ({
  schemaId,
  property,
}: GetPropertyValuesParams) => {
  const propertyValues = await getPropertyValues(schemaId, property);
  return propertyValues;
};

export const fetchPropertyValues = async ({
  schemaId,
  property,
}: GetPropertyValuesParams) => {
  return await queryClient.fetchQuery(
    ['propertyValues', schemaId, property],
    () => getPropertyValuesList({ schemaId, property })
  );
};

export const usePropertyValues = ({ onSuccess, onError }: MutationParams) =>
  useMutation<string[], Error, GetPropertyValuesParams>({
    mutationFn: getPropertyValuesList,
    onSuccess: () => {
      if (onSuccess) onSuccess();
    },
    onError: () => {
      if (onError) onError();
    },
  });
