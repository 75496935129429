import _ from 'lodash';
import React from 'react';
import { getFormattedDateTime } from '@shield-ui/utils';
import { getSolidHandleFromOutputObject } from '../data';
import makeStyles from '@mui/styles/makeStyles';
import { SolidOutput } from '../data';

const useStyles = makeStyles((theme) => ({
  container: {
    fontSize: 12,
    fontWeight: 300,
  },
}));

export default function StandardExpanded(props:SolidOutput) {
  const { artifact, metric, analysis } = props;
  const classes = useStyles();

  const objs = [analysis, artifact, metric];

  let createdAt = '';
  if (artifact && artifact.lifeCycleDetails && artifact.lifeCycleDetails.createTime) {
    const { seconds } = artifact.lifeCycleDetails.createTime
    createdAt = new Date(seconds * 1000).toTimeString();
  } else if (metric && metric.createdAt) {
    createdAt = metric.createdAt
  } else if (analysis && analysis.createdAt) {
    createdAt = analysis.createdAt
  }

  const solidHandle = _.first(
    _.compact([
      analysis && getSolidHandleFromOutputObject(analysis.data),
      artifact && getSolidHandleFromOutputObject(artifact.metadataObject),
    ])
  );

  return (
    <div className={classes.container}>
      {solidHandle && <div>Solid Handle: {solidHandle}</div>}
      {createdAt && <div>{getFormattedDateTime(createdAt)}</div>}
    </div>
  );
}
