import { AutoComplete } from 'antd';
import { useMemo } from 'react';

import { useCommonPlatformMetadata } from '../../services/testDefinitions';
import { MetadataKeyInputProps } from './MetadataEditableRow';
import { arrayToLabels } from '../../services/passThruSearch';
import { VBATMetadata } from '../../services/robotLogs';
import { DefaultOptionType } from 'antd/lib/select';

interface BaseMetadataKeyInputProps extends MetadataKeyInputProps {
  metadata: VBATMetadata[] | object[];
}

export const MetadataKeyInput: React.FC<BaseMetadataKeyInputProps> = ({
  value = '',
  onChange,
  excludedKeys = [],
  platform_id,
  productType,
  metadata = [],
  ...props
}) => {
  const { data: metadataValues = [] } = useCommonPlatformMetadata(platform_id);

  const keys: DefaultOptionType[] = useMemo(() => {
    const options = new Set<string>();

    metadataValues.forEach((data) =>
      Object.keys(data).forEach((key) => options.add(key))
    );

    return arrayToLabels(options)
      .filter((item) => !excludedKeys.includes(item.value))
      .filter((item) => item.value !== value);
  }, [metadataValues, value]);

  return (
    <AutoComplete
      options={keys}
      value={value}
      filterOption={true}
      onChange={onChange}
      {...props}
    />
  );
};
