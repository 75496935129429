function getPrefixedKey(storageKey) {
  const prefix = 'ai.shield.vision--';
  if (storageKey.indexOf(prefix) === 0) {
    return storageKey;
  }
  return `${prefix}${storageKey}`;
}

export type CacheableStorageType = string | number | boolean | object | object[];

export function setCachedItem<ValueType extends CacheableStorageType = CacheableStorageType>(storageKey: string, obj: ValueType) {
  try {
    storageKey = getPrefixedKey(storageKey);
    window.localStorage.setItem(storageKey, JSON.stringify(obj));
  } catch (e) {
    console.warn(e);
  }
}

export function getCachedItem<ValueType extends CacheableStorageType = CacheableStorageType>(storageKey: string): ValueType {
  try {
    storageKey = getPrefixedKey(storageKey);
    const storedJSON = window.localStorage.getItem(storageKey);
    if (!storedJSON) {
      return undefined;
    }

    return JSON.parse(storedJSON);
  } catch (e) {
    console.warn(e);
  }
}

/**
 * Remove an item from teh cache
 * @param storageKey
 */
export function removeCachedItem(storageKey: string) {
  const prefixedKey = getPrefixedKey(storageKey);
  window.localStorage.removeItem(prefixedKey);
}
