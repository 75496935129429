import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';

import {
  setListItems,
  ensureLabelListItems,
} from '../../../redux/lists/actions';

function mapStateToProps(state) {
  return {
    labels: state.lists.listTypeMap.labels,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setListItems,
      ensureLabelListItems,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps);
