import _, { set } from 'lodash';
import {
  Card,
  Button,
  Modal,
  Select,
  Radio,
  Form,
  Input,
  Typography,
  List,
  Space,
  Tooltip,
} from 'antd';

import { showSuccess, showError } from '../../lib/messages';
import {
  useCreateEntity,
  useCreateEntityLink,
  useDeleteEntityLink,
  useSearchEntitiesByValue,
  useSessionLogEntities,
  useUpdateEntity,
} from '../../services/entities';
import { EntityComponent } from '../../components/entity/EntityComponent';
import { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useEntitySchemas } from '../../services/entitySchemas';
import { EntityView } from '../../components/entity/EntityView';
import { useDebounce } from '@shield-ui/hooks';
import { DisconnectOutlined, EditFilled } from '@ant-design/icons';

const useStyles = makeStyles((theme) => ({
  entityLinkTypeButton: {
    width: '50%',
  },
  entityLinkTypeButtonGroup: {
    width: '100%',
  },
  modal: {
    '& .ant-modal-footer': {
      borderTop: 'none',
    },
  },
  entityButtonsContainer: {
    display: 'flex',
    justifyContent: 'right',
  },
}));
type Props = {
  sessionLogId: string;
};

export const EntityCard = (props: Props) => {
  const classes = useStyles();
  const { sessionLogId } = props;
  const [form] = Form.useForm();

  const [showEntityLinkModal, setShowEntityLinkModal] = useState(false);
  const [editingEntity, setEditingEntity] = useState<any>(null);
  const [selectedEntitySchema, setSelectedEntitySchema] = useState(null);
  const [editFields, setEditFields] = useState<any>({});
  const searchTerm = useDebounce(editFields?.searchTerm, 500);

  const { isLoading: loadingEntities, data: entities = [] } =
    useSessionLogEntities(sessionLogId);

  const { isLoading: loadingEntitySchemas, data: entitySchemas = [] } =
    useEntitySchemas();

  const {
    isLoading: searchedEntitiesLoading,
    data: searchedEntities,
    mutate: searchEntities,
  } = useSearchEntitiesByValue({});

  const { mutate: createEntity } = useCreateEntity({
    onSuccess: () => {
      showSuccess('Entity created!');
      resetFields();
    },
    onError: (error) => showError(`Unable to create entity: ${error?.message}`),
  });
  const { mutate: createEntityLink, data: newEntity } = useCreateEntityLink({
    onSuccess: () => {
      showSuccess('Entity linked!');
      resetFields();
    },
    onError: (error) => showError(`Unable to link entity: ${error?.message}`),
  });

  const { mutate: updateEntity } = useUpdateEntity({
    onSuccess: () => {
      showSuccess('Entity updated!');
      setEditingEntity(null);
    },
    onError: (error) => showError(`Unable to update entity: ${error?.message}`),
  });

  const { mutate: deleteEntityLink } = useDeleteEntityLink({
    onSuccess: () => showSuccess('Entity deleted!'),
    onError: (error) => showError(`Unable to delete entity: ${error?.message}`),
  });

  const resetFields = () => {
    setSelectedEntitySchema(null);
    form.resetFields();
    setShowEntityLinkModal(false);
  };

  const onCancel = () => {
    resetFields();
  };

  const openEntityLinkModal = () => {
    setShowEntityLinkModal(true);
  };

  const updateFields = (changedValues: any, values: any) => {
    setEditFields(values);
  };

  useEffect(() => {
    const currentSchema = entitySchemas.find(
      (schema) => schema.id === editFields?.entitySchemaId
    );
    setSelectedEntitySchema(currentSchema);
  }, [entitySchemas, editFields]);

  useEffect(() => {
    if (selectedEntitySchema?.id !== undefined && searchTerm) {
      searchEntities({
        slug: selectedEntitySchema.slug,
        query: searchTerm,
      });
    }
  }, [selectedEntitySchema, searchTerm]);

  return (
    <>
      <Modal
        title={'Link Entity'}
        open={showEntityLinkModal}
        maskClosable={false}
        onCancel={onCancel}
        className={classes.modal}
        footer={null}
      >
        <Form layout="vertical" onValuesChange={updateFields} form={form}>
          <Form.Item label="Entity Schema" name="entitySchemaId">
            <Select>
              {_.map(entitySchemas, (entitySchema) => (
                <Select.Option value={entitySchema.id} key={entitySchema.id}>
                  {entitySchema.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Entity Link Type" name="linkType">
            <Radio.Group
              className={classes.entityLinkTypeButtonGroup}
              disabled={selectedEntitySchema?.id === undefined}
            >
              <Radio.Button
                type="primary"
                className={classes.entityLinkTypeButton}
                value="existing"
              >
                Existing
              </Radio.Button>
              <Radio.Button
                type="primary"
                className={classes.entityLinkTypeButton}
                value="create"
              >
                Create
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
          {editFields?.linkType === 'existing' &&
            selectedEntitySchema?.id !== undefined && (
              <>
                <Form.Item label="Select Entity" name="searchTerm">
                  <Input.Search placeholder="Search entity values" />
                </Form.Item>

                <List
                  loading={searchedEntitiesLoading}
                  itemLayout="horizontal"
                  dataSource={searchedEntities}
                  renderItem={(entity) => (
                    <List.Item
                      actions={[
                        <Button
                          type="primary"
                          onClick={() =>
                            createEntityLink({
                              sessionLogId: sessionLogId,
                              entityId: entity.id,
                            })
                          }
                        >
                          Link
                        </Button>,
                      ]}
                    >
                      <List.Item.Meta
                        description={
                          <EntityView
                            entity={entity}
                            entitySchema={entity.entitySchema}
                          />
                        }
                      />
                    </List.Item>
                  )}
                />
              </>
            )}
        </Form>
        {selectedEntitySchema && editFields?.linkType === 'create' && (
          <>
            <Typography>Create Entity</Typography>
            <EntityComponent
              entitySchema={selectedEntitySchema}
              data={{}}
              onSave={(data) => {
                createEntity({
                  sessionLogId: sessionLogId,
                  entity: {
                    entitySchemaSlug: selectedEntitySchema.slug,
                    properties: data.formData,
                  },
                });
              }}
            />
          </>
        )}
      </Modal>
      <Card
        title="Entities"
        extra={
          <Button type="primary" ghost onClick={openEntityLinkModal}>
            Add Entity
          </Button>
        }
      >
        <List
          loading={loadingEntities}
          dataSource={entities}
          renderItem={(entity) => (
            <List.Item style={{ display: 'block' }}>
              <Typography.Title
                level={5}
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                {entity.entitySchema.name}
                <Space className={classes.entityButtonsContainer}>
                  <Tooltip title="Edit">
                    <Button
                      type="primary"
                      ghost
                      htmlType="submit"
                      icon={<EditFilled />}
                      onClick={() => setEditingEntity(entity)}
                    />
                  </Tooltip>
                  <Tooltip title="Unlink">
                    <Button
                      type="primary"
                      ghost
                      danger
                      icon={<DisconnectOutlined />}
                      onClick={() => deleteEntityLink(entity._id)}
                    />
                  </Tooltip>
                </Space>
              </Typography.Title>
              <EntityView entity={entity} entitySchema={entity.entitySchema} />
            </List.Item>
          )}
        />
        <Modal
          title={'Edit Entity'}
          open={editingEntity !== null}
          maskClosable={false}
          onCancel={() => setEditingEntity(null)}
          className={classes.modal}
          footer={null}
        >
          {editingEntity && (
            <EntityComponent
              key={editingEntity.id}
              entitySchema={editingEntity.entitySchema}
              data={editingEntity.properties}
              onSave={(updatedProperties) => {
                updateEntity({
                  id: editingEntity.id,
                  entitySchemaSlug: editingEntity.entitySchema.slug,
                  properties: updatedProperties.formData,
                });
              }}
            ></EntityComponent>
          )}
        </Modal>
      </Card>
    </>
  );
};
