import _ from 'lodash';
import { LabelIdType, LabelType } from '@shield-ui/heatgrid';
import {
  MultiselectQueryFilterValue,
  UNSET_OPTION_VALUE,
} from '@shield-ui/query-filters';
import { default as testDefinitionCodeQueryFilter } from '../../../lib/queryFilters/robotLog/testDefinitionCodes';
import { AxisDefinition, AxisOption } from './AxisDefinition';
import history from '../../../routes/history';

export const testDefinitionCodeAxisDefinition: AxisDefinition = {
  id: 'TestDefinitionCode',
  getDropdownLabel: () => 'Individual Test Definition Codes',
  buildAggsDsl: () => ({
    terms: {
      field: 'test_definition.code.keyword',
      missing: 'N/A',
    },
  }),
  prepareAxisLabels: (labelIds: LabelIdType[]) => {
    return new Promise<LabelType[]>((resolve) => {
      let emptyLabel: LabelType = undefined;
      const labels: LabelType[] = _.sortBy(
        labelIds.reduce((acc, labelId) => {
          if (labelId === 'N/A') {
            emptyLabel = {
              id: labelId,
              label: '-',
            };
            return acc;
          }
          return [
            ...acc,
            {
              id: labelId,
              label: labelId.toString(),
            },
          ];
        }, []),
        (label) => label.label
      );

      if (emptyLabel) {
        resolve([...labels, emptyLabel]);
      }
      resolve(labels);
    });
  },
  buildQueryFilter: (
    filterValues: object,
    selectedLabels: LabelType[],
    axisOption?: AxisOption
  ) => {
    // build the QF value.
    const newTestDefinitionCodeValues: MultiselectQueryFilterValue = {
      includeOptionValues: selectedLabels.map((label) => {
        if (label.id === 'N/A') {
          return UNSET_OPTION_VALUE;
        }
        return label.id;
      }),
    };

    newTestDefinitionCodeValues.esQuery =
      testDefinitionCodeQueryFilter.getElasticQuery(
        newTestDefinitionCodeValues
      );

    // set the new value for this and return any other existing filter values
    return {
      ...filterValues,
      [testDefinitionCodeQueryFilter.getId()]: newTestDefinitionCodeValues,
    };
  },
  labelCellProps: {
    actions: [
      {
        label: 'Go to Test Definition Page',
        onClick: (contentData: LabelType) => {
          history.push(`/app/test-definitions/${contentData.label}`);
        },
      },
    ],
  },
};
