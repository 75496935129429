import React from 'react';
import withStyles from '@mui/styles/withStyles';

function styles(theme) {
  return {
    status: {
      backgroundColor: `rgba(11,11,11, 0.1)`,
      fontSize: 11,
      textAlign: 'center',
      minWidth: 60,
      fontWeight: 500,
      letterSpacing: '.2px',
      padding: `4px 8px`,
      border: `1px solid rgba(255, 255, 255, .25)`,
      borderRadius: 4,
    },
  };
}

function TicketStatus({ classes, status }) {
  if (!status) {
    return null;
  }

  return <div className={classes.status}>{status}</div>;
}

export default withStyles(styles)(TicketStatus);
