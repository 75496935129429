import React from 'react';
import { gql } from '@apollo/client';
import _ from 'lodash';
import sdkClient from '../../lib/hmdsdkClient';
import {
  GetRequest,
  TestDefinition,
  TestDefinitionView,
  TestDefinitionService,
} from '@hmd/sdk/api/tests/definitions/v1';
import TestDefinitionPreviewCard from '../../components/test-definitions/TestDefinitionPreviewCard';
import TestEvaluationBadge from '../test-evaluations/TestEvaluationBadge';
import { default as hangarClient } from '../../apollo-client';
import { showError } from '../../lib/messages';
import InfoSection from '../../components/InfoSection';
import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import PreviewText from './TestDefinitionForm/PreviewText';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import tracker from '../../lib/tracker';
import { Grow } from '@shield-ui/core';

function styles() {
  return {
    sectionHeaderContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 16,
    },
    sectionHeader: {
      cursor: 'pointer',
      fontSize: 16,
    },
    name: {
      fontSize: 14,
      marginBottom: 2,
      fontWeight: 400,
    },
    expand: {
      cursor: 'pointer',
    },
  };
}
const query = gql`
  query ($robotLogId: String!) {
    robotLog(id: $robotLogId) {
      id
      testDefinitionId
      sessionLogTypeSlug
    }
  }
`;
type State = {
  isLoading: boolean;
  testDefinition: TestDefinition;
  sessionLogTypeSlug: string;
  isFullView: boolean;
};

interface Props {
  robotLogId: string;
  classes: any;
}

class RobotLogTestDefinitionPreview extends React.Component<Props, State> {
  state = {
    isLoading: false,
    testDefinition: undefined,
    sessionLogTypeSlug: undefined,
    isFullView: false,
  };
  componentDidMount() {
    this.fetchRobotLogAndTestDefinition();
  }
  fetchRobotLogAndTestDefinition = () => {
    const { robotLogId } = this.props;
    this.setState({
      isLoading: true,
    });
    hangarClient
      .query({
        query: query,
        variables: { robotLogId: robotLogId },
        fetchPolicy: 'network-only',
      })
      .then((rl) => {
        const tdId = _.get(rl, ['data', 'robotLog', 'testDefinitionId'], '');
        const sessionLogTypeSlug = _.get(rl, [
          'data',
          'robotLog',
          'sessionLogTypeSlug',
        ]);
        if (tdId !== null) {
          this.fetchTestDefinitionFromId(tdId, sessionLogTypeSlug);
        } else {
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch(showError);
  };
  fetchTestDefinitionFromId = (id, sessionLogTypeSlug) => {
    const req = new GetRequest();
    req.setView(TestDefinitionView.FULL);
    req.setId(id);
    this.setState({
      isLoading: true,
    });
    sdkClient
      .unary(TestDefinitionService.Get, req)
      .then((td) => {
        this.setState({
          isLoading: false,
          sessionLogTypeSlug,
          testDefinition: td,
        });
      })
      .catch(showError);
  };

  shouldShowEvaluation() {
    return (
      this.state.sessionLogTypeSlug === 'test' ||
      this.state.sessionLogTypeSlug === 'trial'
    );
  }

  render() {
    const { classes } = this.props;
    const { testDefinition, isFullView } = this.state;

    if (testDefinition === undefined) {
      return null;
    }

    return (
      <>
        {testDefinition && (
          <InfoSection title="Test Definition">
            <div className={classes.sectionHeaderContainer}>
              <div className={classes.sectionHeader}>
                <Link
                  style={{ paddingRight: 8 }}
                  component={RouterLink}
                  to={`/app/test-definitions/${testDefinition.getCode()}`}
                  underline="hover"
                >
                  {testDefinition.getCode()}
                </Link>
              </div>
              {this.shouldShowEvaluation() && (
                <TestEvaluationBadge
                  robotLogId={this.props.robotLogId}
                  type="table"
                />
              )}
              <Grow />
              <Button
                size="small"
                onClick={() => {
                  if (!isFullView) {
                    tracker.trackEvent('TestDefinitionPreview', 'ClickedMore');
                  }
                  this.setState({ isFullView: !isFullView });
                }}
              >
                {isFullView ? 'Less' : 'More'}
              </Button>
            </div>
            <div>
              <div className={classes.name}>{testDefinition.getName()}</div>
              {isFullView ? (
                <div style={{ marginTop: 16 }}>
                  <TestDefinitionPreviewCard testDefinition={testDefinition} />
                </div>
              ) : (
                <PreviewText>{testDefinition.getDescription()}</PreviewText>
              )}
            </div>
          </InfoSection>
        )}
      </>
    );
  }
}

export default withStyles(styles)(RobotLogTestDefinitionPreview);
