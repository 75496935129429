import _ from 'lodash';
import {
  HangarAnalysisType,
  HangarMetricType,
  HangarPipelineExecutionType,
} from '@shield-ui/hangar-service';
import { Artifact } from '@hmd/sdk/api/artifacts/v1';
import {
  getFormattedDateTime,
  getCachedItem,
  setCachedItem,
} from '@shield-ui/utils';
import { ArtifactResult } from '../../../redux/pipelineExplorer.slice';

export interface TreeSummary {
  artifactPlotCount: number;
  metricCount: number;
  analysisCount: number;
  analysisPassedCount: number;
}

const defaultTreeSummary: TreeSummary = {
  artifactPlotCount: 0,
  metricCount: 0,
  analysisCount: 0,
  analysisPassedCount: 0,
};

export enum SolidOutputType {
  analysis = 1,
  metric = 2,
  artifact = 3,
}

export interface SolidOutput {
  name: string;
  type: SolidOutputType;
  isFavorite: boolean;
  artifact?: ArtifactResult;
  analysis?: HangarAnalysisType;
  // artifacts related to an expectation
  analysisArtifacts?: Artifact[];
  metric?: HangarMetricType;
}

export interface SolidHandleTree {
  label: string;
  solids: SolidHandleTree[];
  outputs: SolidOutput[];
  summary: TreeSummary;
}

export interface PipelineRun extends SolidHandleTree {
  pipelineExecution?: HangarPipelineExecutionType;
}

export interface PipelineGroup {
  label: string;
  pipelineRuns: PipelineRun[];
}

export function getSolidHandleFromOutputObject(obj: object): string {
  // not sure reasons why, but it could be "solid_handle" or "solid handle" in our
  // metadata object / struct
  const sh = _.get(obj, 'solid_handle') || _.get(obj, 'solid handle');

  if (_.isString(sh)) {
    return sh;
  }
  return;
}

/*
function collapseChildren(output: SolidHandleTree[]): SolidHandleTree[] {
  return output.map((r) => {
    const { outputs } = r;
    const children = collapseChildren(r.solids);

    if (!outputs.length && children.length === 1) {
      const child = children[0];
      r.label = `${r.label}.${child.label}`;
      r.solids = [];
      r.outputs = child.outputs;
    }
    return r;
  });
}
*/

function handleItem({ record, treeParts, solids }) {
  const label = treeParts.shift();

  let match: SolidHandleTree = _.find(solids, { label });
  if (!match) {
    match = {
      label,
      solids: [],
      outputs: [],
      summary: { ...defaultTreeSummary },
    };
    solids.push(match);
  }

  if (treeParts.length) {
    handleItem({
      record,
      treeParts,
      solids: match.solids,
    });
  } else {
    match.outputs.push(record);

    // The solid handles don't match so I don't feel like we should be doing too much kung-fu to try and relate them
    // Relating plots to other outputs for the future
    /*
    if (record.artifact) {
      match.outputs.forEach((output) => {
        const analysisPlotName = _.get(output, 'analysis.data.plot_name');
        if (analysisPlotName === record.artifact.fileName) {
          if (!output.analysisArtifacts) {
            output.analysisArtifacts = [];
          }
          output.analysisArtifacts.push(record.artifact);
        }
      });
    }
     */
  }
}

function getPipelineRun(
  groups: PipelineGroup[],
  pipelineExecution?: HangarPipelineExecutionType,
  fallbackDescription?: string
) {
  let group, run;

  if (pipelineExecution) {
    group = pipelineExecution.description || 'Piper Run No Description';
    // run = `Piper Run ${pipelineExecution.name}`;
    try {
      run = getFormattedDateTime(pipelineExecution.createdAt);
    } catch (e) {
      run = pipelineExecution.name;
    }
  } else {
    group = 'Legacy Pipelines';
    run = fallbackDescription || 'Uncategorized';
  }

  let groupMatch = _.find(groups, { label: group });
  if (!groupMatch) {
    groupMatch = {
      label: group,
      pipelineRuns: [],
    };
    groups.push(groupMatch);
  }

  let runMatch = _.find(groupMatch.pipelineRuns, { label: run });

  if (!runMatch) {
    runMatch = {
      label: run,
      pipelineExecution,
      solids: [],
      outputs: [],
      summary: { ...defaultTreeSummary },
    };
    groupMatch.pipelineRuns.push(runMatch);
  }

  return runMatch;
}

function setTreeSummary(node: PipelineRun | SolidHandleTree) {
  node.solids = _.sortBy(node.solids, 'label');
  const { summary, outputs, solids } = node;

  solids.forEach((solid) => {
    // map for the child recursive
    setTreeSummary(solid);

    // aggregate the child counts onto this level
    Object.keys(defaultTreeSummary).forEach((key) => {
      summary[key] += solid.summary[key];
    });
  });

  // mutate the outputs sorting them by name
  node.outputs = _.sortBy(outputs, 'name');

  // count the direct outputs of this level and add to this level's summary
  outputs.forEach((output) => {
    const { analysis, artifact, metric } = output;
    if (analysis) {
      summary.analysisCount += 1;
      summary.analysisPassedCount +=
        analysis.testPassed ? 1 : 0;
    }
    if (artifact && artifact.artifactTypeSlug === 'plotly-json') {
      summary.artifactPlotCount += 1;
    }
    if (metric) {
      summary.metricCount += 1;
    }
  });
}

function getPipelineRunDisplayFromFramework(framework) {
  if (framework === 'PIPER') {
    return 'Piper (Old)';
  } else {
    return _.capitalize(framework);
  }
}

type FavoriteMap = Record<string, boolean>;
type Favorites = Record<SolidOutputType, FavoriteMap>;

const PIPELINE_FAVORITES_CACHE_KEY = 'pipelineExplorerFavorites';
export function getFavorites(): Favorites {
  const defaults = {
    [SolidOutputType.analysis]: {},
    [SolidOutputType.metric]: {},
    [SolidOutputType.artifact]: {},
  };

  const cached = getCachedItem(PIPELINE_FAVORITES_CACHE_KEY) as Favorites;

  return {
    ...defaults,
    ...cached,
  };
}

export function toggleMergeFavorite(favorites: Favorites, output: SolidOutput) {
  const favoriteMap = favorites[output.type];

  if (favoriteMap[output.name]) {
    delete favoriteMap[output.name];
  } else {
    favoriteMap[output.name] = true;
  }

  const newFavorites = {
    ...favorites,
    [output.type]: favoriteMap,
  };

  setCachedItem(PIPELINE_FAVORITES_CACHE_KEY, newFavorites);
  return newFavorites;
}
//update input types
export function buildPipelineViewerData({
  analyses,
  metrics,
  artifacts,
  pipelineExecutions,
  favorites,
}: {
  analyses: HangarAnalysisType[];
  metrics: HangarMetricType[];
  artifacts: ArtifactResult[];
  pipelineExecutions: HangarPipelineExecutionType[];
  favorites: Favorites;
}): { treeOutput: PipelineGroup[]; flatOutput: SolidOutput[] } {
  const groups: PipelineGroup[] = [];
  const flatOutput: SolidOutput[] = [];

  const pipelineExecutionMap = _.keyBy(pipelineExecutions, 'id');

  const setRecord = (
    record: SolidOutput,
    pipelineRun: PipelineRun,
    solidHandle = ''
  ) => {
    // every record added to flat
    flatOutput.push(record);

    // top level, no tree (not from Piper or old)
    if (!solidHandle) {
      pipelineRun.outputs.push(record);
      return;
    }

    // what we expected a piper expectation/solid/metric to look like
    const parts = solidHandle.split('.');
    handleItem({
      record,
      treeParts: parts,
      solids: pipelineRun.solids,
    });
  };

  analyses.forEach((analysis) => {
    const pe = pipelineExecutionMap[analysis.pipelineExecutionId];
    const pipelineRun = getPipelineRun(
      groups,
      pe,
      getPipelineRunDisplayFromFramework(analysis.framework)
    );
    const solidHandle = getSolidHandleFromOutputObject(analysis.data);
    setRecord(
      {
        analysis,
        type: SolidOutputType.analysis,
        name: analysis.name,
        isFavorite: !!favorites[SolidOutputType.analysis][analysis.name],
      },
      pipelineRun,
      solidHandle
    );
  });

  artifacts.forEach((artifact) => {
    const md = artifact.metadataObject;
    // maybe this went from camel to underscores with the facade... unsure
    const peId = md['pipeline_execution_id'] || md.pipelineExecutionId
    const pe = pipelineExecutionMap[peId]; 
    const pipelineRun = getPipelineRun(groups, pe);
    const solidHandle = getSolidHandleFromOutputObject(artifact.metadataObject);

    setRecord(
      {
        artifact,
        type: SolidOutputType.artifact,
        name: artifact.filename,
        isFavorite: !!favorites[SolidOutputType.artifact][artifact.filename],
      },
      pipelineRun,
      solidHandle
    );
  });

  metrics.forEach((metric) => {
    const pe = pipelineExecutionMap[metric.pipelineExecutionId];
    const pipelineRun = getPipelineRun(groups, pe);
    setRecord(
      {
        metric,
        type: SolidOutputType.metric,
        name: metric.name,
        isFavorite: !!favorites[SolidOutputType.metric][metric.name],
      },
      pipelineRun
    );
  });

  // sort everything by favorite, then name
  // unique by type + name
  const uniqueFlatOutput = _.uniqBy(
    _.sortBy(flatOutput, [
      (o) => (o.isFavorite ? 0 : 1),
      (o) => o.name.toLowerCase(),
    ]),
    (o) => o.type + o.name
  );

  // - Push the Favorites to the Front
  const favoriteRun: PipelineRun = {
    label: '★',
    solids: [],
    summary: { ...defaultTreeSummary },
    outputs: uniqueFlatOutput.filter((o) => o.isFavorite),
  };
  const favoriteGroup: PipelineGroup = {
    label: '★ My Favorites',
    pipelineRuns: [favoriteRun],
  };
  groups.unshift(favoriteGroup);

  // Set all the tree summaries
  groups.forEach((group) => {
    group.pipelineRuns.forEach((run) => {
      setTreeSummary(run);
    });
    group.pipelineRuns = _.sortBy(group.pipelineRuns, 'label').reverse();
  });

  return {
    treeOutput: _.sortBy(
      groups,
      (group) => {
        if (group.label.includes('Favorites')) {
          return -1;
        } else if (group.label.includes('Legacy')) {
          return 99;
        }
        return 0;
      },
      'label'
    ),
    // data comes out from the API sorted by created_at_desc (newest top) now we sort
    // things by name, and then unique it so in flat view we get this great flat view
    flatOutput: uniqueFlatOutput,
  };
  //return collapseChildren(output);
}
