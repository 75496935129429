import React, { Fragment } from 'react';
import { Grid, Paper } from '@mui/material';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import { getShortDate } from '@shield-ui/utils';
import NoteHeader from './Header';
import NoteContent from './NoteContent';
import FlightActionIconButton from '../../../components/buttons/IconButton';
import SectionSubmitButton from '../components/SectionSubmitButton';
import SectionCancelButton from '../components/SectionCancelButton';
import SectionSubmitContainer from '../../../components/SubmitSection';
import NoResultsMessage from '../components/NoResultsMessage';

class NotesList extends React.Component {
  headerOnChange = ({ noteType }) => {
    const { updateEditNote } = this.props;

    if (noteType) {
      updateEditNote({ editNoteType: noteType });
    }
  };

  render() {
    const {
      notes,
      editNoteId,
      editDisplayText,
      editNoteType,
      updateEditNote,
      submitEditNote,
      cancelEditNote,
      onDeleteNote,
    } = this.props;

    if (!notes.length) {
      return <NoResultsMessage>No flight notes</NoResultsMessage>;
    }

    return (
      <div>
        {notes.map((r) => {
          const { id, displayText, createdBy, createdAt, noteType } = r.node;

          const isEditMode = id === editNoteId;

          const editRequest = () =>
            updateEditNote({
              editNoteId: id,
              editDisplayText: displayText,
              editNoteType: noteType,
            });

          return (
            <Paper
              key={id}
              style={{
                marginTop: 8,
                marginBottom: 8,
                padding: 18,
                paddingBottom: 30,
              }}
            >
              <Grid container>
                <NoteHeader
                  user={createdBy}
                  isEditing={isEditMode}
                  dateDisplay={getShortDate(createdAt)}
                  onEdit={isEditMode ? undefined : editRequest}
                  noteType={isEditMode ? editNoteType : noteType}
                  onEditRequest={editRequest}
                  onChange={this.headerOnChange}
                />
                <NoteContent
                  isEditing={isEditMode}
                  value={isEditMode ? editDisplayText : displayText}
                  onChange={(e) =>
                    updateEditNote({ editDisplayText: e.target.value })
                  }
                  onEditRequest={editRequest}
                />
                {isEditMode && (
                  <Fragment>
                    <Grid item xs={11}>
                      <SectionSubmitContainer>
                        <SectionCancelButton onClick={cancelEditNote}>
                          Cancel
                        </SectionCancelButton>
                        <FlightActionIconButton
                          aria-label="Delete"
                          tabIndex={-1}
                          onClick={() => onDeleteNote(id)}
                        >
                          <DeleteOutline style={{ fontSize: '0.9em' }} />
                        </FlightActionIconButton>
                        <SectionSubmitButton onClick={submitEditNote}>
                          Update
                        </SectionSubmitButton>
                      </SectionSubmitContainer>
                    </Grid>
                    <Grid item xs={1} />
                  </Fragment>
                )}
              </Grid>
            </Paper>
          );
        })}
      </div>
    );
  }
}

export default NotesList;
