import React, { useCallback, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {useDidMount} from "@shield-ui/hooks";
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { colors } from '@shield-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import sdkClient from '../../lib/hmdsdkClient';
import { GetRequest, TestDefinition, TestDefinitionView, TestDefinitionService } from "@hmd/sdk/api/tests/definitions/v1";
import TestDefinitionPreviewCard from "./TestDefinitionPreviewCard";
import { getRouteLinkForTestDefinition } from "../../services/testDefinitions";

const useStyles = makeStyles(() => ({
  code: {
    letterSpacing: '1px',
    cursor: 'pointer',
    '&:hover': {
      color: colors.semantic.linkColorHover,
    }
  }
}))

interface Props {
  testDefinitionId: string;
}

function fetchTD(testDefinitionId:string, full = false): Promise<TestDefinition> {
  const req = new GetRequest()
  if (full) {
    req.setView(TestDefinitionView.FULL);
  }
  req.setId(testDefinitionId);
  return sdkClient.unary(TestDefinitionService.Get, req)
    .then(td => {
      return td
  })
}

function isFull(td:TestDefinition): boolean {
  return td && td.hasVersionInfo();
}

export default function TestDefinitionBadge(props:Props) {
  const { testDefinitionId } = props;
  const [isShowingFullCard, setIsShowingFullCard] = useState(false);
  const [td, setTd] = useState<TestDefinition>(undefined);
  const classes = useStyles();

  useDidMount(() => {
    fetchTD(testDefinitionId).then((td) => {
      setTd(td);
    })
  })

  const onToggleFullCard = useCallback(() => {
    setIsShowingFullCard(!isShowingFullCard);
    if (!isShowingFullCard && !isFull(td)) {
      fetchTD(testDefinitionId, true).then((td) => {
        setTd(td);
      })
    }
  }, [testDefinitionId, isShowingFullCard, setIsShowingFullCard]);

  if (!td) { return null; }

  return (
    <div>
      <div className={classes.code} onClick={onToggleFullCard}>
        {td.getCode()}
      </div>
      {isShowingFullCard && (
        <Dialog
          open={isShowingFullCard}
          onClose={onToggleFullCard}
          fullWidth={true}
          style={{padding:5, overflowY: 'visible', minWidth: 600}}
        >

          <DialogTitle>Test Code Details</DialogTitle>
              <div style={{marginLeft: 24, fontSize: 16, marginBottom: 8}}>
                <RouterLink
                  component={Link}
                  to={getRouteLinkForTestDefinition(td)}
                >
                  {td.getCode()}
                </RouterLink>
              </div>
          <DialogContent>
            {isFull(td) ? <TestDefinitionPreviewCard testDefinition={td} /> : <div>Loading...</div>}
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}
