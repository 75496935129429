import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { OutlinedInput } from '@mui/material';
import { colors } from '@shield-ui/styles';

const defaultOutlinedInputProps = {
  'aria-autocomplete': 'off',
  autoComplete: 'off',
};

function styles(theme) {
  return {
    input: {
      padding: `10px 12px`,
    },
    root: {
      backgroundColor: colors.semantic.inputBackground,
      borderRadius: 3,
      margin: theme.spacing(0.25, 0),
    },
    multiline: {
      padding: 0,
    },
  };
}

const SuiTextField = (props) => {
  const { classes, inputProps = {}, ...rest } = props;

  return (
    <OutlinedInput
      classes={classes}
      fullWidth
      labelwidth={0} // required...?
      inputProps={{
        ...defaultOutlinedInputProps,
        ...inputProps,
      }}
      {...rest}
    />
  );
};

export default withStyles(styles)(SuiTextField);
