import React from 'react';
import _ from 'lodash';
import { ContainerProps } from './containerEventManager';
import EventGroup from './EventGroup';
import CurrentUserEventGroup from './CurrentUserEventGroup';
import FlightEventGroup from './FlightEventGroup';
import { Props as ChildRowDetailsProps } from './ChildRowDetails';

interface Props
  extends Pick<
      ContainerProps,
      'events' | 'eventsChildren'| 'robotLog' |'currentUser' | 'deleteEvent'
    >,
    Pick<ChildRowDetailsProps, 'timeCodeOnClick'> {}

function EventList(props: Props) {
  const { events, eventsChildren, currentUser, robotLog} = props;

  //Single Flights
  const userGroups = _.groupBy(events, 'createdBy.id');
  const userIds = Object.keys(userGroups);

  //Teaming Flights
  const hasChildren = !!eventsChildren.length
  
  const sessionLogGroups = {};
  sessionLogGroups[robotLog.name] = events;
  eventsChildren.forEach(c => {
    const events = _.get(c, 'events.edges', []).map(
      (e) => e.node
    )
    sessionLogGroups[c.name] = events;
  })
  const sessionLogNames = Object.keys(sessionLogGroups)

  return (
    <>
    {hasChildren ? 
      <>
        {sessionLogNames.map((name) => {
          const sessionLogEvents = sessionLogGroups[name];
          return <FlightEventGroup {...props} key={name} name={name} sessionLogEvents={sessionLogEvents} />
        })}
      </>
    :
    <>
        <CurrentUserEventGroup
          {...props}
          userEvents={userGroups[currentUser.id]}
        />
        {userIds.map((userId) => {
          if (userId === currentUser.id) {
            return null;
          }
          const userEvents = userGroups[userId];
          return <EventGroup {...props} key={userId} userEvents={userEvents} />;
        })}
      </>
    }
    </>
  );
}

export default EventList;
