import { useState, useCallback, useLayoutEffect } from 'react';
import _ from 'lodash';
export interface DimensionObject {
  width: number;
  height: number;
  top: number;
  left: number;
  x: number;
  y: number;
  right: number;
  bottom: number;
}

export type UseDimensionsHook = [
  DimensionObject, // dimensions
  (node: HTMLElement) => void, // ref
  HTMLElement
];

function getDimensionObject(node: HTMLElement): DimensionObject {
  const rect = node.getBoundingClientRect();

  return {
    width: rect.width,
    height: rect.height,
    right: rect.right,
    bottom: rect.bottom,
    top: rect.top || rect.x,
    left: rect.left || rect.y,
    x: rect.x || rect.left,
    y: rect.y || rect.top,
  };
}

/**
 * Get the dimensions of a node and make sure they stay up to date whenever the window changes
 * IF you are concerned with it's dimension changing when it's children do, give it a key.
 */
function useDimensions(): UseDimensionsHook {
  const [dimensions, setDimensions] = useState({
    width: undefined,
    height: undefined,
    top: undefined,
    right: undefined,
    bottom: undefined,
    left: undefined,
    x: undefined,
    y: undefined,
  });
  const [node, setNode] = useState(null);

  const ref = useCallback((node) => {
    setNode(node);
  }, []);

  useLayoutEffect(() => {
    if (node) {
      const measure = _.throttle(() =>
        window.requestAnimationFrame(() =>
          setDimensions(getDimensionObject(node))
        ), 100);
      measure();

      window.addEventListener('resize', measure);
      return () => {
        measure.cancel();
        window.removeEventListener('resize', measure);
      };
    }
  }, [node]);

  return [dimensions, ref, node];
}

export default useDimensions;
