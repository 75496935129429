import { getLocalDateTime } from '../../../../../lib/connectedUtils';
import { NOTE_TYPE_DISPLAYS } from '../../../../../services/notes';
import { parseForNotes } from './Cell';
import _ from 'lodash';

export default function getValue({ row, noteFilter }) {
  return parseForNotes(row, noteFilter)
    .map((note) => {
      const { displayText, createdAt, noteType } = note;
      const name = _.get(note, 'createdBy.name', 'Unknown');
      const noteTypeDisplay = !noteFilter
        ? ` | ${NOTE_TYPE_DISPLAYS[noteType] || ''}`
        : '';

      return [
        `#${name} | ${getLocalDateTime(createdAt)} ${noteTypeDisplay}`,
        displayText,
      ].join('\n');
    })
    .join('\n-----------------------------------------\n');
}
