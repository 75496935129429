import React, { useState, useMemo, useEffect } from 'react';
import {
  Card,
  Popconfirm,
  Modal,
  Form,
  Button,
  Select,
  Space,
  Typography,
  List,
} from 'antd';
import { NewBatchParticipants } from '../../services/participants';
import { useParticipantTypes } from '../../services/participantTypes';
import { useUsers, useUserOptions } from '../../services/users';
import { useTeams, useTeamOptions } from '../../services/teams';
import _ from 'lodash';
import { Participant } from '@hmd/sdk/api/session_log/participant/v1';
import {
  PersonOutlineRounded,
  GroupOutlined,
  Clear,
} from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import { SourceType } from '@hmd/sdk/api/common/v1';
import { User } from '@hmd/sdk/api/iam/users/v1';
import ShieldAvatarSDK from '../../components/users/ShieldAvatarSDK';
import { Team } from '@hmd/sdk/api/iam/teams/v1';
import { SessionLogProduct } from '../../services/robotLogs';

const { Text } = Typography;

const useStyles = makeStyles((theme) => ({
  freeFormIcon: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(0.25),
    fontSize: 26,
    flexShrink: 0,
  },
  freeFormSpan: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modal: {
    '& .ant-modal-footer': {
      borderTop: 'none',
    },
  },
  listItem: {
    '&:hover': {
      '& $clearIcon': {
        opacity: 1,
        transition: 'opacity 0.25s linear',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  },
  clearIcon: {
    size: '16px',
    opacity: 0,
    transition: 'opacity 0.25s linear',
  },
}));

type ParticipantEditViewProps = {
  sessionLogId?: string;
  flightType: SessionLogProduct;
  participants: Participant.AsObject[];
  onDelete: (participant: {
    participantId: string;
    sessionLogId: string;
  }) => void;
  onAdd: (participants: NewBatchParticipants[]) => void;
  cardView?: boolean;
};

interface ParticipantsList extends Participant.AsObject {
  displayName: string;
  idType: 'userId' | 'userFreeform' | 'teamId' | 'teamFreeform';
  participantTypeSlug: string;
  participantTypeName: string;
  user?: User.AsObject;
  team?: Team.AsObject;
}

type labelInValue = {
  value: string;
  label: string;
};

const sortParticipantsAlphabetically = (
  participants: ParticipantsList[]
): ParticipantsList[] => {
  if (participants) {
    participants.sort(function compareFn(a, b) {
      if (a.displayName.toUpperCase() < b.displayName.toUpperCase()) {
        return -1;
      }
      if (a.displayName.toUpperCase() > b.displayName.toUpperCase()) {
        return 1;
      }
      return 0;
    });
  }
  return participants;
};

export default function ParticipantEditView(props: ParticipantEditViewProps) {
  const [form] = Form.useForm();
  const { sessionLogId = '', flightType, cardView } = props;

  const [participants, setParticipants] = useState<Participant.AsObject[]>(
    props.participants
  );
  const [showModal, setShowModal] = useState<boolean | null>(null);
  const [selectedType, setSelectedType] = useState<labelInValue | null>(null);
  const [selectedUsers, setSelectedUsers] = useState<labelInValue[]>([]);
  const [selectedTeams, setSelectedTeams] = useState<labelInValue[]>([]);
  const classes = useStyles();

  const { data: teamOptions } = useTeamOptions();
  const { data: userOptions } = useUserOptions();

  const iconType = (idType, participant) => {
    switch (idType) {
      case 'userId':
        return <ShieldAvatarSDK user={participant.user} />;
      case 'teamId':
        return <ShieldAvatarSDK team={participant.team} />;
      case 'teamFreeform':
        return <GroupOutlined className={classes.freeFormIcon} />;
      case 'userFreeform':
        return <PersonOutlineRounded className={classes.freeFormIcon} />;
    }
  };
  const { isLoading: loadingParticipantTypes, data: participantTypes } =
    useParticipantTypes();
  const { isLoading: loadingUsers, data: users } = useUsers();
  const { isLoading: loadingTeams, data: teams } = useTeams();

  useEffect(() => {
    setParticipants(props.participants);
  }, [props.participants]);

  const participantList: ParticipantsList[] = useMemo(() => {
    if (participants && users && teams && participantTypes) {
      return participants.map((p) => {
        let idTypeArr;
        if (p.teamFreeform) {
          idTypeArr = [p.teamFreeform, 'teamFreeform'];
        } else if (p.userFreeform) {
          idTypeArr = [p.userFreeform, 'userFreeform'];
        } else if (p.userId) {
          const user = users.find((u) => u.id === p.userId);
          idTypeArr = [user.name, 'userId', user];
        } else if (p.teamId) {
          const team = teams.find((t) => t.id === p.teamId);
          idTypeArr = [team.name, 'teamId', team];
        }
        const displayName: string = idTypeArr[0];
        const idType: ParticipantsList['idType'] = idTypeArr[1];
        let user: User.AsObject | undefined;
        let team: Team.AsObject | undefined;
        if (idType === 'userId') {
          user = idTypeArr[2];
        } else if (idType === 'teamId') {
          team = idTypeArr[2];
        }
        const pType = participantTypes.find(
          (type) => type.id === p.participantTypeId
        );
        return {
          ...p,
          displayName: displayName,
          idType: idType,
          participantTypeSlug: pType.slug,
          participantTypeName: pType.name,
          ...(user && { user: user }),
          ...(team && { team: team }),
        };
      });
    }
  }, [participants, users, teams, participantTypes]);

  const confirm = (participantId: string, sessionLogId: string) => {
    props.onDelete({
      participantId: participantId,
      sessionLogId: sessionLogId,
    });
  };

  const onSave = () => {
    const createFieldsArr: NewBatchParticipants[] = [];
    selectedTeams?.forEach((team) => {
      const pFields: NewBatchParticipants = {
        sessionLogId,
        participantTypeId: selectedType.value,
        id: team.value,
        sourceType: SourceType.OBSERVER,
        idType: !team.label ? 'teamFreeform' : 'teamId',
      };
      createFieldsArr.push(pFields);
    });
    selectedUsers?.forEach((user) => {
      const pFields: NewBatchParticipants = {
        sessionLogId,
        participantTypeId: selectedType.value,
        id: user.value,
        sourceType: SourceType.OBSERVER,
        idType: !user.label ? 'userFreeform' : 'userId',
      };
      createFieldsArr.push(pFields);
    });
    form.resetFields();
    reset();
    setShowModal(false);
    props.onAdd(createFieldsArr);
  };

  const reset = () => {
    setSelectedTeams([]);
    setSelectedType(null);
    setSelectedUsers([]);
  };

  const onCancel = () => {
    form.resetFields();
    reset();
    setShowModal(false);
  };

  const specificParticipantTypesPerFlightType = {
    [SessionLogProduct.VBAT]: [
      'aircraft-operator',
      'external-operator',
      'ground-crew',
      'flight-team',
    ],
    [SessionLogProduct.NOVA]: ['pilot', 'observer'],
  };

  const defaultCard = (defaultParticipantTypes) => {
    return (
      <Card
        title="PARTICIPANTS"
        extra={
          <Button
            onClick={() => {
              setShowModal(true);
            }}
          >
            Add
          </Button>
        }
      >
        {defaultParticipantTypes?.length ? (
          defaultParticipantTypes.map((type) => {
            return (
              <Card
                key={type.id}
                title={type.name}
                bordered={false}
                size="small"
              />
            );
          })
        ) : (
          <p>No Participants</p>
        )}
      </Card>
    );
  };

  const cardWithParticipants = (slug, typeName, participants) => {
    return (
      <Card key={slug} title={typeName} bordered={false} size="small">
        {participants?.map((p) => {
          return (
            <List.Item key={p.id} className={classes.listItem}>
              <span className={classes.freeFormSpan}>
                {iconType(p.idType, p)}
                <Text> {p.displayName}</Text>
              </span>
              <span>
                <Popconfirm
                  title="Delete this participant"
                  onConfirm={() => confirm(p.id, sessionLogId)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Clear className={classes.clearIcon} />
                </Popconfirm>
              </span>
            </List.Item>
          );
        })}
      </Card>
    );
  };

  const renderList = () => {
    const defaultTypes =
      specificParticipantTypesPerFlightType[flightType] || []; //list of default participant Types
    if (!participants) {
      const defaultParticipantTypes = participantTypes?.filter((pType) => {
        return defaultTypes.includes(pType.slug.toLowerCase());
      });
      return defaultCard(defaultParticipantTypes);
    } else {
      const participantsGroupedByTypeSlug = _.groupBy(
        participantList,
        'participantTypeSlug'
      ); //Object with slugs as key, array of participants as value {pilot: [...participants], observer:[...participants]}
      const restOfParticipantTypeSlugs = Object.keys(
        participantsGroupedByTypeSlug
      )?.filter((slug) => {
        //Get the rest of participant types on SL minus defaults
        if (!defaultTypes.includes(slug)) {
          return slug;
        }
      });
      return (
        <>
          {defaultTypes?.sort().map((slug) => {
            //render default cards with or without participants
            let participants = participantsGroupedByTypeSlug[slug];
            const typeName = participantTypes?.find(
              (type) => type.slug === slug
            )?.name;
            if (!participants) {
              return (
                <Card
                  key={slug}
                  title={typeName}
                  bordered={false}
                  size="small"
                />
              );
            } else {
              participants = sortParticipantsAlphabetically(participants);
              return cardWithParticipants(slug, typeName, participants);
            }
          })}
          {restOfParticipantTypeSlugs?.sort().map((slug) => {
            let participants = participantsGroupedByTypeSlug[slug];
            const typeName = participantTypes?.find(
              (type) => type.slug === slug
            )?.name;
            participants = sortParticipantsAlphabetically(participants);
            return cardWithParticipants(slug, typeName, participants);
          })}
        </>
      );
    }
  };

  return (
    <>
      {cardView ? (
        <Card
          title="PARTICIPANTS"
          loading={loadingParticipantTypes || loadingUsers || loadingTeams}
          extra={
            <Button ghost type="primary" onClick={() => setShowModal(true)}>
              Add
            </Button>
          }
        >
          {renderList()}
        </Card>
      ) : (
        <>
          <Typography.Title level={3}>
            Participants
            <Button
              ghost
              style={{ float: 'right' }}
              type="primary"
              onClick={() => setShowModal(true)}
            >
              Add
            </Button>
          </Typography.Title>
          {renderList()}
        </>
      )}

      <Modal
        title={'Add Participants'}
        open={showModal}
        maskClosable={false}
        onCancel={onCancel}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{
          disabled: selectedType
            ? selectedTeams.length || selectedUsers.length
              ? false
              : true
            : true,
        }}
        okText="Save"
        className={classes.modal}
        onOk={onSave}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="type" label="Type" required>
            <Select
              showSearch
              labelInValue
              optionFilterProp="label"
              options={participantTypes?.map((pTypes) => ({
                label: (
                  <Space>
                    <Text>{pTypes.name}</Text>
                    <Text style={{ fontSize: '10px' }} type="secondary">
                      {pTypes.description}
                    </Text>
                  </Space>
                ),
                value: pTypes.id,
              }))}
              value={selectedType}
              onChange={setSelectedType}
            />
          </Form.Item>
          <Form.Item name="users" label="Users">
            <Select
              showSearch
              mode="tags"
              optionFilterProp="label"
              labelInValue
              options={userOptions}
              value={selectedUsers}
              onChange={setSelectedUsers}
              disabled={!selectedType}
            />
          </Form.Item>
          <Form.Item name="teams" label="Teams">
            <Select
              showSearch
              mode="tags"
              labelInValue
              optionFilterProp="label"
              options={teamOptions}
              value={selectedTeams}
              onChange={setSelectedTeams}
              disabled={!selectedType}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
