import React from 'react';
import { Button, Dropdown, Menu } from 'antd';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import { LabelIdType, LabelType, SelectionProps } from '../HeatGrid/types';

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
  };
});

export type CornerActionsProps = {
  selectionProps?: SelectionProps;
  selectedX: LabelIdType[];
  selectedY: LabelIdType[];
  xLabels: LabelType[];
  yLabels: LabelType[];
  onDeselectAll: () => void;
};

export function CornerActions(props: CornerActionsProps) {
  const {
    xLabels,
    yLabels,
    selectedX,
    selectedY,
    selectionProps,
    onDeselectAll,
  } = props;
  const classes = useStyles();

  if (selectedX.length === 0 && selectedY.length === 0) {
    return null;
  }

  // wrap any action passed in with something that prepares the args passed to the event
  const getSelectionOnClick = (
    cb: SelectionProps['primaryAction']['onClick']
  ) => {
    return () => {
      const process = (
        selected: LabelIdType[],
        labels: LabelType[]
      ): LabelType[] => {
        if (!selected.length) {
          return [];
        }
        const map = _.keyBy(labels, 'id');
        return selected.map((id) => map[id]);
      };
      return cb({
        selectedXLabels: process(selectedX, xLabels),
        selectedYLabels: process(selectedY, yLabels),
        deselectAll: onDeselectAll,
      });
    };
  };

  let action;
  if (selectionProps) {
    const primaryActionProps = {
      type: 'primary' as const,
      onClick: getSelectionOnClick(selectionProps.primaryAction.onClick),
    };
    const buttonText = selectionProps.primaryAction.label;

    const menu = selectionProps.moreActions &&
      selectionProps.moreActions.length && (
        <Menu>
          {selectionProps.moreActions.map((action) => (
            <Menu.Item
              key={action.label}
              onClick={getSelectionOnClick(action.onClick)}
            >
              {action.label}
            </Menu.Item>
          ))}
        </Menu>
      );
    const C = menu ? Dropdown.Button : Button;

    if (menu) {
      action = (
        <Dropdown.Button {...primaryActionProps} overlay={menu}>
          {buttonText}
        </Dropdown.Button>
      );
    } else {
      action = <Button {...primaryActionProps}>{buttonText}</Button>;
    }
  }

  return (
    <div className={classes.container}>
      {action}
      <div style={{ height: 5 }} />
      <Button type="ghost" onClick={onDeselectAll}>
        Deselect All
      </Button>
    </div>
  );
}

export default CornerActions;
