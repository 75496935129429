import { Button } from 'antd';
import { useSessionLogs } from '../services/robotLogs';
import { useHexColor } from '../services/sessionLogTypes';

type Props = {
  sessionLogIds: string[];
};

export const SessionLogLinks = ({ sessionLogIds }: Props) => {
  const { data: sessionLogs = [], error } = useSessionLogs(sessionLogIds);
  const { data: hexColorList = [] } = useHexColor();

  return (
    <>
      {sessionLogs.map((sessionLog) => (
        <Button
          key={sessionLog.id}
          href={`/app/rl/${sessionLog.name}`}
          target="_blank"
          type="ghost"
          style={{
            borderColor: hexColorList[sessionLog.sessionLogTypeSlug],
            marginRight: 8,
          }}
        >
          {sessionLog.name}
        </Button>
      ))}
    </>
  );
};
