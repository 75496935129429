import React, { useState } from 'react';
import _ from 'lodash';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import ChildRow from './ChildRow';
import { HangarEventType } from '@shield-ui/hangar-service';
import Collapse from '@mui/material/Collapse';
import ChildRowDetails, {
  Props as ChildRowDetailsProps,
} from './ChildRowDetails';
import AddUpdateEventForm from './AddUpdateEventForm';
import { ContainerProps } from './containerEventManager';
import MoreMenu from '../../MoreMenu';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { showConfirmPrompt } from '../../../lib/messages';

interface Props extends Pick<ContainerProps, 'robotLog' | 'deleteEvent'>,
    Pick<ChildRowDetailsProps, 'timeCodeOnClick'> {
  event: HangarEventType;
}

function getDelta(robotLog, timestamp) {
  if (!robotLog || !timestamp) {
    return;
  }

  return Math.round(
    differenceInMilliseconds(
      new Date(timestamp),
      new Date(robotLog.startTime)
    ) / 1000
  );
}

function Event(props: Props) {
  const { event, robotLog, deleteEvent, timeCodeOnClick} = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [isDirtyForm, setIsDirtyForm] = useState(false);
  const highlight = _.get(event, 'tag.severity.name', '').toLowerCase();

  const toggle = () => {
    setIsDirtyForm(false);
    setIsExpanded(!isExpanded);
  };

  return (
    <ChildRow
      isExpanded={isExpanded}
      onClick={isDirtyForm ? undefined : toggle}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {!isExpanded && (
        <ChildRowDetails
          label={_.get(event, 'tag.name', 'Unknown Tag')}
          highlight={highlight}
          timeCodeAtSeconds={getDelta(robotLog, event.startTime)}
          timeCodeOnClick={timeCodeOnClick}
          description={_.get(event, 'tag.description')}
          EndNode={
            isHovering && (
              <MoreMenu
                borderless
                menuItems={[
                  {
                    label: 'Delete Event',
                    onClick: () =>
                      showConfirmPrompt({
                        onConfirm: () => deleteEvent({ id: event.id }),
                      }),
                    Icon: DeleteOutlineIcon,
                  },
                ]}
              />
            )
          }
        />
      )}
      {isExpanded && (
        <Collapse in={isExpanded}>
          <div>
            <AddUpdateEventForm
              {...props}
              eventToUpdate={event}
              onCancel={isDirtyForm ? toggle : undefined}
              onFormStateChange={() => setIsDirtyForm(true)}
            />
          </div>
        </Collapse>
      )}
    </ChildRow>
  );
}

export default Event;
