import React from 'react';
import classNames from 'classnames';
import { useRouteMatch, Link } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from '@shield-ui/styles';

export const SUB_MENU_HEIGHT = 42;

const useStyles = makeStyles((theme) => ({
  subMenuLink: {
    marginLeft: 4,
    minWidth: 160,
    color: 'white',
    padding: theme.spacing(0, 3, 0, 2),
    fontSize: 14,
    height: SUB_MENU_HEIGHT,
    display: 'flex',
    alignItems: 'center',
    transition: '0.1s',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    background: colors.hues.grays[190],
    '&:hover': {
      color: 'white',
      background: theme.palette.primary.main, //colors.hues.grays[170],
    },
    '&:before': {
      content: "'●'",
      fontSize: 12,
      marginRight: theme.spacing(1),
      opacity: 0,
      color: theme.palette.primary.light,
    },
  },
  accent: {
    width: 2,
    height: 16,
    borderRadius: 1,
    marginRight: 6,
    marginLeft: 4,
    // marginTop: 2,
  },
  active: {
    '&:before': {
      opacity: 1,
    },
  },
}));

export interface SubMenuItemProps {
  label: string;
  to?: string;
  hexColor?: string;
  href?: string;
  onClick?: () => void;
}

function SubMenuItem(props: SubMenuItemProps) {
  const classes = useStyles();
  const isMatch = useRouteMatch(
    props.to || props.href || 'no-way-this-matches'
  );

  const cls = classNames(classes.subMenuLink, isMatch && classes.active);

  if (props.href) {
    return (
      <a key={props.href} href={props.href} className={cls}>
        {props.label}
      </a>
    );
  } else if (props.to) {
    return (
      <Link key={props.to} to={props.to} className={cls}>
        <div
          className={classes.accent}
          style={{ backgroundColor: props.hexColor }}
        />
        {props.label}
      </Link>
    );
  } else if (props.onClick) {
    return (
      <div onClick={props.onClick} className={cls}>
        {props.label}
      </div>
    );
  }
  return null;
}

export default SubMenuItem;
