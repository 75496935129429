import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormatAlignJustifyOutlined from '@mui/icons-material/FormatAlignJustifyOutlined';
import FormatIndentIncreaseOutlined from '@mui/icons-material/FormatIndentIncreaseOutlined';
import { TextField } from '@shield-ui/controls';
import { useDebounce } from '@shield-ui/hooks';
import { Button } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  filters: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  toggleButton: {
    marginRight: theme.spacing(2),
  },
  createButton: {
    marginLeft: theme.spacing(2),
    width: 150,
  },
}));

export enum ViewMode {
  tree = 0,
  flat = 1,
}

interface Props {
  searchPlaceholder: string;
  onChangeSearchString: (searchString: string) => void;
  onChangeViewMode: (viewMode: ViewMode) => void;
  viewMode: ViewMode;
  onCreateMetric: () => void;
}

function ControlsHeader(props: Props) {
  const {
    searchPlaceholder = '',
    onChangeSearchString,
    onChangeViewMode,
    viewMode,
    onCreateMetric,
  } = props;
  const classes = useStyles();
  const [searchString, setSearchString] = useState('');

  const debouncedSearchString = useDebounce(searchString, 500);

  // typing is fast, only update our parent every once in a while
  useEffect(
    () =>
      onChangeSearchString(
        debouncedSearchString.length < 3 ? '' : debouncedSearchString
      ),
    [debouncedSearchString, onChangeSearchString]
  );

  return (
    <div className={classes.filters}>
      <Tooltip
        title={`Switch to ${
          viewMode === ViewMode.tree ? 'list' : 'pipeline execution tree'
        } view`}
      >
        <div>
          <IconButton
            className={classes.toggleButton}
            onClick={() => {
              const newMode =
                viewMode === ViewMode.tree ? ViewMode.flat : ViewMode.tree;
              onChangeViewMode(newMode);
            }}
            size="large">
            {viewMode === ViewMode.tree && <FormatIndentIncreaseOutlined />}
            {viewMode === ViewMode.flat && <FormatAlignJustifyOutlined />}
          </IconButton>
        </div>
      </Tooltip>
      <TextField
        placeholder={searchPlaceholder}
        value={searchString}
        onChange={(evt) => setSearchString(evt.target.value)}
      />
      <Tooltip title={`Create new Metric or Expectation`}>
        <div>
          <Button
            variant="contained"
            color="primary"
            className={classes.createButton}
            size="small"
            onClick={onCreateMetric}
          >
            Create Metric
          </Button>
        </div>
      </Tooltip>
    </div>
  );
}

export default ControlsHeader;
