import React from 'react';
import { semanticDataTypes } from '../../../../forms/defs';
import _ from 'lodash';

function getValue({ row, column }) {
  const key = _.get(column, 'dataInputValues.key', '? Key');
  // Supports nested property selection e.g. "foo.bar" = robotLogData.foo.bar
  const val = _.get(row, `robotLogData.${key}`);

  // some rough formatting for different datatypes,
  // hopefully things will be strings and numbers for the most part
  if (_.isObject(val) || _.isBoolean(val)) {
    return JSON.stringify(val);
  }

  return val;
}

const config = {
  columnKey: 'robotLogData',
  label: 'Robot Log Data',
  getLabel: ({ column }) => _.get(column, 'dataInputValues.key', '? Key'),
  dataInputOptions: [
    {
      parameter: 'key',
      dataType: semanticDataTypes.string,
      label: 'Property Key in RobotLogData',
      required: true,
    },
  ],
  getElasticSort: ({ column, orderByDirection }) => {
    const key = _.get(column, 'dataInputValues.key');
    if (!key) {
      return;
    }

    return [
      {
        [`robot_log_data.${key}`]: {
          order: orderByDirection,
          missing: '_last',
        },
      },
    ];
  },
  description:
    'Choose a key from RobotLog.robotLogData to display in the column',
  getValue,
  getFragment: ({ column }) => {
    const { columnUid, dataInputValues = {} } = column;
    if (!columnUid || !dataInputValues) {
      return '';
    }

    return `
      robotLogData
    `;
  },
};

export default config;
