import {Select, Radio, Form, Input} from 'antd';
import { MetricAnalysisSelect } from '../metricAnalysisSelect';

export const BoxAndWhiskerForm = () => {

  return (
		<div>
			<Form.Item label="Plot Title" name="title" requiredMark={"optional"}>
				<Input/>
			</Form.Item>
			{MetricAnalysisSelect()}
			<Form.Item label="Second Breakdown" name="secondBreakdown" rules={[{ required: true, message: 'Please choose a breakdown' }]}>
				<Select 
					allowClear
					placeholder="Second Breakdown"
					showSearch
				>
					<Select.Option value="software_info.commit_hash">Commit Hash</Select.Option>
					<Select.Option value="phones.name">Phone Name</Select.Option>
					<Select.Option value="robots.name">Robot Name</Select.Option>
					<Select.Option value="software_info.branch_name">Software Branch Name</Select.Option>
					<Select.Option value="test_definition.code">Test Definition Code</Select.Option>
				</Select>
			</Form.Item>
			<Form.Item label="Group By" name="groupByTime">
				<Radio.Group name="radioTime" >
					<Radio.Button value="allTime">All Time</Radio.Button>
					<Radio.Button value="year">Year</Radio.Button>
					<Radio.Button value="month">Month</Radio.Button>
					<Radio.Button value="week">Week</Radio.Button>
					<Radio.Button value="day">Day</Radio.Button>
				</Radio.Group>
			</Form.Item>
		</div>
  )

}