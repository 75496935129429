import { colors } from '@shield-ui/styles';
const { lightColorway } = colors;

/**
 * Given an input string, compute a random HUE of a consistent saturation and lightness
 */
export function getDynamicColorFromString(str = '') {
  let charTotal = 0;
  for (let i = 0; i < str.length; i++) {
    charTotal += str.charCodeAt(i);
  }

  // old calc way replaced by specific colorway given by Irene
  // https://medium.com/@pppped/compute-an-arbitrary-color-for-user-avatar-starting-from-his-username-with-javascript-cd0675943b66
  // const h = (charTotal % 20) * 18;
  // return `hsl(${h}, 40%, 40%)`;

  const index = charTotal % lightColorway.length;
  return lightColorway[index];
}

export function invertColor(hexTripletColor: string): string {
  // remove #
  const colorInt = parseInt(hexTripletColor.substring(1), 16); // convert to integer

  // tslint:disable-next-line:no-bitwise
  const invertInt = 0xffffff ^ colorInt; // invert three bytes
  let invertStr = invertInt.toString(16); // convert to hex
  invertStr = ('000000' + invertStr).slice(-6); // pad with leading zeros
  return '#' + invertStr; // prepend #
}
