/*
key: 'orderOfMagnitudeOfNumberOfPeople',
key: 'orderOfMagnitudeOfNumberOfPortals',
key: 'containsGlass',
key: 'containsMirrors',
key: 'containsCurtains',
key: 'containsHaze',
key: 'containsBrightLightSources',
key: 'targetedPortalCollect',
key: 'targetedPersonCollect',
key: 'opportunisticCollect',
key: 'walkthrough',
key: 'peopleData',
 */

export enum ValueTypes {
  string = 0,
  number = 1,
  boolean = 2,
}

export type genericDcoAttribute = {
  key: string;
  label: string;
  description: string;
  valueType: ValueTypes;
};

export const genericDcoAttributes: genericDcoAttribute[] = [
  {
    key: 'orderOfMagnitudeOfNumberOfPeople',
    label: 'Order of Magnitude of Number of People',
    description: 'Order of Magnitude of Number of People',
    valueType: ValueTypes.number,
  },
  {
    key: 'orderOfMagnitudeOfNumberOfPortals',
    label: 'Order of Magnitude of Number of Portals',
    description: 'Order of Magnitude of Number of Portals',
    valueType: ValueTypes.number,
  },
  {
    key: 'containsGlass',
    label: 'Contains Glass',
    description: 'Contains Glass',
    valueType: ValueTypes.boolean,
  },
  {
    key: 'containsMirrors',
    label: 'Contains Mirrors',
    description: 'Contains Mirrors',
    valueType: ValueTypes.boolean,
  },
  {
    key: 'containsCurtains',
    label: 'Contains Curtains',
    description: 'Contains Curtains',
    valueType: ValueTypes.boolean,
  },
  {
    key: 'containsHaze',
    label: 'Contains Haze',
    description: 'Contains Haze',
    valueType: ValueTypes.boolean,
  },
  {
    key: 'containsBrightLightSources',
    label: 'Contains Bright Light Sources',
    description: 'Contains Bright Light Sources',
    valueType: ValueTypes.boolean,
  },
  {
    key: 'targetedPortalCollect',
    label: 'Targeted Portal Collect',
    description: 'Targeted Portal Collect',
    valueType: ValueTypes.boolean,
  },
  {
    key: 'targetedPersonCollect',
    label: 'Targeted Person Collect',
    description: 'Targeted Person Collect',
    valueType: ValueTypes.boolean,
  },
  {
    key: 'opportunisticCollect',
    label: 'Opportunistic Collect',
    description: 'Opportunistic Collect',
    valueType: ValueTypes.boolean,
  },
  {
    key: 'walkthrough',
    label: 'Walkthrough',
    description: 'Walkthrough',
    valueType: ValueTypes.boolean,
  },
  {
    key: 'peopleData',
    label: 'People Data',
    description: 'Contains Glass',
    valueType: ValueTypes.string,
  },
];
