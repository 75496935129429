import _ from 'lodash';
import {
  BaseFilterValue,
  BaseQueryFilter,
  BaseFilterProps,
} from './baseQueryFilter';
import {
  QueryFilterTypes,
  QueryFilterClasses,
  QueryFilterClassValues,
  ListOperator,
} from './common';

// This should be type QFValues... but TS go crazy
export type NestedValueSet = Map<string, QueryFilterClassValues>;

export interface NestedQueryFilterValue extends BaseFilterValue {
  nestedValueSets?: NestedValueSet[];
  operator?: ListOperator;
  notExists?: boolean;
}

export interface NestedControlData {
  queryFilters: QueryFilterClasses[];
  implementsNotExists: boolean;
  notExistsLabel: string;
}

export interface NestedQueryFilterProps extends BaseFilterProps {
  queryFilters: QueryFilterClasses[];
  notExistsControl?: {
    enabled: boolean;
    label?: string;
  };
}

export class NestedQueryFilter extends BaseQueryFilter<
  NestedQueryFilterValue,
  NestedQueryFilterProps,
  NestedControlData
> {
  type = QueryFilterTypes.nested;

  constructor(props) {
    super(props);

    // when our children fire this event, make sure the parent does
    // because that's what UI systems are built off of (parent events)
    props.queryFilters.forEach((qf) => {
      qf.events.on('updatedControlData', () => {
        this.events.emit('updatedControlData', this.getControlData());
      });
    });
  }

  initControlData(props: NestedQueryFilterProps): NestedControlData {
    return {
      queryFilters: props.queryFilters,
      implementsNotExists:
        (props.notExistsControl && props.notExistsControl.enabled) || false,
      notExistsLabel:
        (props.notExistsControl && props.notExistsControl.label) ||
        `No ${props.controlLabel}`,
    };
  }

  _getNestedSetValue(
    nestedValueSet: NestedValueSet,
    qf: QueryFilterClasses
  ): QueryFilterClassValues {
    if (!nestedValueSet) {
      return;
    }
    return nestedValueSet[qf.getId()];
  }

  // controlData is handled in children
  hasValue(value: NestedQueryFilterValue): boolean {
    const { queryFilters } = this.props;

    if (!value) {
      return false;
    }
    const { nestedValueSets, notExists } = value;

    if (notExists) {
      return true;
    }

    if (!nestedValueSets || !nestedValueSets.length) {
      return false;
    }

    return !!_.find(value.nestedValueSets, (nestedValueSet) => {
      return !!_.find(queryFilters, (qf) => {
        return qf.hasValue(this._getNestedSetValue(nestedValueSet, qf));
      });
    });
  }

  // ensureControlData ensures that all of our child controls have their controlData initialized as well
  // just like in baseQueryFilter, _ensurePromise is captured to ensure we only call and get updates once
  ensureControlData(value: NestedQueryFilterValue): Promise<NestedControlData> {
    if (!value || !value.nestedValueSets) {
      return;
    }

    const { queryFilters } = this.props;

    const promises = value.nestedValueSets.reduce((acc, nestedValueSet) => {
      queryFilters.forEach((qf) => {
        acc.push(
          qf.ensureControlData(this._getNestedSetValue(nestedValueSet, qf))
        );
      });
      return acc;
    }, []);

    return Promise.all(promises).then(() => this.getControlData());
  }

  getElasticQuery(value: NestedQueryFilterValue): object | void {
    if (!value) {
      return;
    }
    const { nestedValueSets, operator, notExists } = value;

    if (notExists) {
      return {
        bool: {
          must_not: [
            {
              nested: {
                path: this.props.esField,
                query: {
                  bool: {
                    filter: {
                      exists: {
                        field: this.props.esField,
                      },
                    },
                  },
                },
              },
            },
          ],
        },
      };
    }
    if (!nestedValueSets) {
      return;
    }

    // AND is the default joiner
    const valueSetQueries = nestedValueSets.reduce((acc, nestedValueSet) => {
      // comprise all sub queries for this value set (e.g. 1 or 3 value sets)
      const nestedQueries = this.props.queryFilters.reduce((innerAcc, qf) => {
        const v = this._getNestedSetValue(nestedValueSet, qf);
        const esQuery = qf.getElasticQuery(v);
        if (esQuery) {
          innerAcc.push(esQuery);
        }
        return innerAcc;
      }, []);

      // push this onto the final filter for the entire control if this nestedValueSet has values
      if (nestedQueries.length) {
        acc.push({
          nested: {
            path: this.props.esField,
            query: {
              bool: {
                // All conditions of the NestedQuerySet group MUST match
                filter: nestedQueries,
              },
            },
          },
        });
      }
      return acc;
    }, []);

    if (!valueSetQueries.length) {
      return;
    }

    if (valueSetQueries.length === 1) {
      return valueSetQueries[0];
    }

    // Different handling for AND/OR on all of the value sets
    const operatorKey = operator === ListOperator.and ? 'filter' : 'should';
    return {
      bool: {
        [operatorKey]: valueSetQueries,
      },
    };
  }

  getValuePreview(value: NestedQueryFilterValue): string {
    if (!value) {
      return '';
    }
    const { nestedValueSets, notExists } = value;

    if (notExists) {
      return this.getControlData().notExistsLabel;
    }

    if (!nestedValueSets) {
      return '';
    }

    const valueSetPreviews = nestedValueSets
      .map((nestedValueSet): string => {
        return this.props.queryFilters
          .map((qf): string => {
            const qfValue = this._getNestedSetValue(nestedValueSet, qf);
            return qf.getValuePreview(qfValue);
          })
          .filter((v) => !!v)
          .join(', ');
      })
      .filter((v) => !!v);

    if (valueSetPreviews.length === 1) {
      return valueSetPreviews[0];
    } else if (valueSetPreviews.length > 1) {
      return `${valueSetPreviews[0]} | *${valueSetPreviews.length - 1}`;
    }
    return '';
  }
}
