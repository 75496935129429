import React from 'react';
import { Parser } from 'json2csv';
import _ from 'lodash';
import withStyles from '@mui/styles/withStyles';
import { Paper, CircularProgress, Button } from '@mui/material';
import ModalHeader from '../../../modals/Header';
import apolloClient from '../../../../apollo-client';
import { getExpandedActiveColumns } from '../utils';
import tracker from '../../../../lib/tracker';

function styles() {
  return {
    container: {
      width: 640,
      padding: `25px 30px`,
      // not sure why this gets a focus outline
      outline: 'none',
    },
    downloadTargets: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    downloadCTA: {
      cursor: 'pointer',
      width: '46%',
      height: 120,
      margin: '2%',
      padding: '3%',
      border: `1px solid #eee`,
      borderRadius: 4,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      transition: '0.15s ease',
      textAlign: 'center',
      color: '#eee',
      backgroundColor: 'rgba(0, 0, 0, .15)',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, .1)',
        color: '#fff',
        borderColor: '#fff',
      },
    },
    downloadSectionHeader: {
      margin: `15px 2% 0px 2%`,
      width: '100%',
      fontSize: '1.4em',
      textTransform: 'uppercase',
    },
    downloadType: {
      fontSize: '1.3em',
      fontWeight: 500,
      marginBottom: 6,
    },
    progressContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    progressStatus: {
      marginBottom: 30,
      textAlign: 'center',
    },
    successStatus: {
      marginBottom: 20,
      textAlign: 'center',
      fontSize: '1.2em',
      lineHeight: '1.7em',
    },
    warning: {
      margin: '5px 2% 0 2%',
      fontSize: '0.9em',
      color: '#ffff9b',
      fontWeight: 600,
      letterSpacing: '.1px',
    },
    bigButton: {
      padding: 20,
      '&:hover': {
        color: 'white',
      },
    },
  };
}

class ExportResultsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: undefined,
      isProcessing: false,
    };
  }

  resetProcessing() {
    this.setState({
      status: undefined,
      isProcessing: false,
    });
  }

  startProcessing() {
    this.setState({ isProcessing: true });
  }

  statusFetching() {
    const { result } = this.props;
    const { total } = result;

    this.setState({ status: `Fetching all ${total} records` });
  }

  parseFieldsAndRows(result) {
    const { mergedColumns, getColumnDefinition } = this.props;

    const activeColumnDefinitions = getExpandedActiveColumns({
      columns: mergedColumns,
      getColumnDefinition,
    });

    // Each column the user has visible is a field in our CSV
    const fields = activeColumnDefinitions.map((expandedColumn) => {
      return {
        label: expandedColumn.computedLabel,
        value: expandedColumn.uid,
      };
    });

    // Loop over every row, and ever columnDef configured calling getValue with that robotLog
    const rows = result.rows.map((row) => {
      return activeColumnDefinitions.reduce((acc, expandedColumn) => {
        const { uid, column, columnDefinition } = expandedColumn;
        const { getValue } = columnDefinition;

        if (_.isFunction(getValue)) {
          acc[uid] = getValue({
            row,
            column,
          });
        } else if (!_.isUndefined(row[uid])) {
          acc[uid] = row[uid];
        } else {
          acc[uid] = '';
        }
        return acc;
      }, {});
    });

    return {
      fields,
      rows,
    };
  }

  createDownloadCSV(result) {
    const { rows, fields } = this.parseFieldsAndRows(result);
    tracker.trackEvent('ExportResults', 'CSV', 'count', rows.length);

    const json2csvParser = new Parser({ fields });
    const csv = json2csvParser.parse(rows);
    const filename = `flights_download_${new Date().toISOString()}.csv`;

    const Blob = window.Blob;
    if (Blob) {
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  catcher = (err) => {
    const { showErrorSnack } = this.props;
    this.resetProcessing();
    showErrorSnack(err);
  };

  fetchAll = () => {
    const { graphqlQuery, graphqlVariables, result } = this.props;
    // mutate the variables so we don't include any pagination limiting variables and we get the entire data set
    const cleanedGraphqlVariables = _.omit(graphqlVariables, [
      'first',
      'last',
      'after',
      'before',
    ]);

    // ensure any default values in backend APIs are overloaded... because we want it all!
    cleanedGraphqlVariables.first = result.total || 10000;

    this.statusFetching();

    return apolloClient.query({
      query: graphqlQuery,
      variables: cleanedGraphqlVariables,
      fetchPolicy: 'no-cache',
    });
  };

  // CSV - Rows X-Y of N
  onDownloadExact = () => {
    const { result } = this.props;
    this.createDownloadCSV(result);
  };

  // CSV - Total Rows
  onDownloadAll = () => {
    const { processQueryResponse } = this.props;

    this.startProcessing();

    this.fetchAll()
      .then((response) => {
        this.resetProcessing();
        this.createDownloadCSV(processQueryResponse(response));
      })
      .catch(this.catcher);
  };


  render() {
    const { classes, hideModal, pagination, pageSize, result } = this.props;
    const { isLoading, count, total } = result;
    const { isProcessing, status} = this.state;

    const pageStart = pagination.page * pageSize;
    const pageEnd = pageStart + count;

    const hasMore = !isLoading && count < total;

    const visibleSubtitle = hasMore
      ? `Visible rows ${pageStart}-${pageEnd} of ${total}`
      : `${total} rows`;
    const allSubtitle = `All ${total} records`;

    return (
      <Paper className={classes.container}>
        <ModalHeader onClose={hideModal}>Export :D Table Results</ModalHeader>
        {isProcessing && (
          <div className={classes.progressContainer}>
            <div className={classes.progressStatus}>{status}</div>
            <CircularProgress size={80} />
          </div>
        )}
        {!isProcessing && (
          <div className={classes.downloadTargets}>
            <div className={classes.downloadCTA} onClick={this.onDownloadExact}>
              <div className={classes.downloadType}>{visibleSubtitle}</div>
              <div>Download CSV</div>
            </div>
            {hasMore && (
              <div className={classes.downloadCTA} onClick={this.onDownloadAll}>
                <div className={classes.downloadType}>{allSubtitle}</div>
                <div>Download CSV</div>
              </div>
            )}
          </div>
        )}
        {hasMore && total > 1000 && (
          <div className={classes.warning}>
            {`Note: ${total} is quite a few records! Make sure that's what you want to do before starting a big fetch.`}
          </div>
        )}
      </Paper>
    );
  }
}

export default withStyles(styles)(ExportResultsModal);
