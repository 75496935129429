/* eslint-disable react-hooks/rules-of-hooks */
import _, { isEmpty } from 'lodash';
import { gql } from '@apollo/client';
import { FTS_IMPORT_SOURCE_TYPE_NAME } from './sourceTypes';
import client, { runQuery } from '../apollo-client';
import { SourceType } from '@hmd/sdk/api/common/v1';
import {
  SessionLogService,
  GetRequest as GetSessionRequest,
  CreateRequest as CreateSessionLogRequest,
  UpdateRequest as UpdateSessionLogRequest,
  CreateOrUpdateRequest,
  SessionLog,
  UpdateRequest,
  BatchGetRequest,
  BatchGetIds,
  AddParentsRequest,
  SessionLogServiceAddParents,
} from '@hmd/sdk/api/session_log/v1';

import sdkClient from '../lib/hmdsdkClient';
import { FieldMask } from 'google-protobuf/google/protobuf/field_mask_pb';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Struct } from 'google-protobuf/google/protobuf/struct_pb';
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';
import {
  NoteService,
  CreateRequest as CreateNoteRequest,
  Note,
  NoteType,
} from '@hmd/sdk/api/session_log/notes/v1';
import { uploadArtifacts } from './newArtifacts';
import { UploadArtifactType } from '../routes/UploadFlights/common';
import {
  batchCreateParticipants,
  NewBatchParticipants,
  VBATParticipantAlaris,
} from './participants';
import {
  Event,
  EventService,
  CreateRequest as CreateEventRequest,
} from '@hmd/sdk/api/session_log/event/v1';
import {
  MetricService,
  CreateRequest as CreateMetricRequest,
  Metric,
} from '@hmd/sdk/api/analysis/metric/v1';
import {
  Environment,
  EnvironmentService,
  CreateRequest as CreateEnvironmentRequest,
} from '@hmd/sdk/api/systems/environment/v1';

import {
  SessionLogTypeService,
  ListRequest as SessionLogTypeListRequest,
  ListResponse as SessionLogTypeListResponse,
} from '@hmd/sdk/api/session_log/session_log_type/v1';
import moment from 'moment';

// SECONDS
export const FLIGHT_DURATION_MAX = 900;
export const KNOWN_JSON_DATA_KEYS = [
  'reliability_flight',
  'flight_type',
  'flight_scope',
  'test_id_code',
  'intended_mission',
  'robot_block',
  'backpack_block',
  'fts_sheet_key',
  'parent_bag_name',
  'renav_overlay_rosparams',
  'renav_context_params',
];
export const VALID_FLIGHT_SCOPES = ['In-House', 'Shield-External', 'Customer'];
export const VALID_FLIGHT_TYPES_OPTIONS = [
  { value: 'reliability', label: 'Reliability' },
  { value: 'disarmed', label: 'Disarmed' },
  { value: 'simulation', label: 'Simulation' },
  { value: 'renav', label: 'Renav' },
  { value: 'rostest', label: 'Rostest' },
  { value: 'reprocess', label: 'Reprocess' },
  { value: 'morpheus', label: 'Morpheus' },
];
export const VALID_FLIGHT_TYPE_FILTER_OPTIONS = [
  ...VALID_FLIGHT_TYPES_OPTIONS,
  { value: 'not_disarmed', label: 'Not Disarmed' },
  { value: 'no_flight_type', label: 'No Flight Type' },
];

const isUUID = (str: string) => {
  const uuidRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidRegex.test(str);
};

const findTestsAndEnvs = gql`
  query {
    robotLogTests(sort: code_asc) {
      edges {
        node {
          id
          name
          code
        }
      }
    },
    environments(sort: name_asc) {
      edges {
        node {
          id
          name
        }
      }
    },
    missions(sort: name_asc) {
      edges {
        node {
          id
          name
        }
      }
    },
    causes {
      edges {
        node {
          id,
          name,
          description
        }
      }
    },
    tags {
      edges {
        node {
          id,
          name,
          description,
          severity {
            name,
            id
          }
        }
      }
    },
    users {
        edges {
            node {
                id
                name
                email
            }
        }
    }
    sourceType(name: "${FTS_IMPORT_SOURCE_TYPE_NAME}") {
      id
      name
    }
  }
  `;

const upsertRobotLogQuery = gql`
  mutation createOrUpdateRobotLog(
    $id: UUID
    $parentId: String
    $originalName: String
    $robotLogTestId: String
    $environmentId: String
    $startTime: String
    $robotHostname: String
    $robotLogData: JSONString
    $testDefinitionId: String
    $sessionLogTypeSlug: String
  ) {
    createOrUpdateRobotLog(
      id: $id
      parentId: $parentId
      name: $originalName
      robotLogTestId: $robotLogTestId
      environmentId: $environmentId
      startTime: $startTime
      robotHostname: $robotHostname
      robotLogData: $robotLogData
      testDefinitionId: $testDefinitionId
      sessionLogTypeSlug: $sessionLogTypeSlug
    ) {
      id
      name
      originalName
      environmentId
      robotLogTestId
      testDefinitionId
      sessionLogTypeSlug
    }
  }
`;

export function getIdForName(name: string): Promise<string> {
  const req = new GetSessionRequest();
  req.setName(name);
  return sdkClient.unary(SessionLogService.Get, req).then((r) => {
    return r.getId();
  });
}

export function getSessionLogById(sessionLogId): Promise<SessionLog> {
  const req = new GetSessionRequest();
  req.setId(sessionLogId);
  return sdkClient.unary(SessionLogService.Get, req).then((res) => {
    return res;
  });
}

export function getSessionLogByName(name): Promise<SessionLog> {
  const req = new GetSessionRequest();
  req.setName(name);
  return sdkClient.unary(SessionLogService.Get, req).then((res) => {
    return res;
  });
}

export function getNameForRobotLogId(robotLogId) {
  return client
    .query({
      query: gql`
        query robotLog($id: String!) {
          robotLog(id: $id) {
            name
          }
        }
      `,
      variables: {
        id: robotLogId,
      },
    })
    .then((r) => {
      const name = _.get(r, 'data.robotLog.name');
      if (!name) {
        throw new Error(`Could not find robot log for id ${robotLogId}`);
      }
      return name;
    });
}

export function getChildrenNamesForRobotLogId(robotLogId) {
  return client
    .query({
      query: gql`
        query robotLog($id: String!) {
          robotLog(id: $id) {
            children {
              id
              name
            }
          }
        }
      `,
      variables: {
        id: robotLogId,
      },
    })
    .then((r) => {
      const childrenNames = _.get(r, 'data.robotLog.children').flatMap(
        (c) => c.name
      );
      return childrenNames;
    });
}

export function createOrUpdateRobotLog(variables, callback) {
  client
    .mutate({
      mutation: upsertRobotLogQuery,
      variables,
    })
    .then(
      (val) => {
        if (typeof callback === 'function') {
          callback(null, val);
        }
      },
      (err) => {
        if (typeof callback === 'function') {
          callback(err);
        }
      }
    );
}

export function findRobotLogParameters(callback, error) {
  client
    .query({
      query: findTestsAndEnvs,
      fetchPolicy: 'network-only',
    })
    .then(
      (val) => {
        if (typeof callback === 'function') {
          callback(val);
        }
      },
      (err) => {
        if (typeof error === 'function') {
          error(err);
        }
      }
    );
}

export function getRobotLogUrl(rl: { name: string; id: string }): string {
  if (!rl) {
    return '';
  }
  const suffix = rl.name ? encodeURIComponent(rl.name) : rl.id;
  return `/app/rl/${suffix}`;
}

export function processRobotLogsResponse(response) {
  const { data } = response;
  const edges = _.get(data, 'robotLogs.edges', []);
  const total = _.get(data, 'robotLogs.total');
  const endCursor = _.get(data, 'robotLogs.pageInfo.endCursor');
  const startCursor = _.get(data, 'robotLogs.pageInfo.startCursor');

  const robotLogs = edges.map((edge) => {
    const { node } = edge;

    // could already be an object if we mapped it and this component just renders again?
    if (_.isString(node.robotLogData)) {
      try {
        node.robotLogData = JSON.parse(node.robotLogData);
      } catch (e) {
        console.warn(node.robotLogData);
        console.warn('problem parsing robotLogData json', e);
        node.robotLogData = {};
      }
    }
    return node;
  });

  return {
    robotLogs,
    count: robotLogs.length,
    total,
    endCursor,
    startCursor,
  };
}

// given a name or ID, get the name and id for a robot log
export function getRobotLog(variables, callback) {
  runQuery({
    variables,
    query: gql`
      query robotLog($id: String, $name: String) {
        robotLog(id: $id, name: $name) {
          id
          name
        }
      }
    `,
    callback,
  });
}

export function buildDocumentSearchQuery(opts: { fragments?: [] } = {}) {
  const { fragments = [] } = opts;

  return gql`
    query documentSearchRobotLogs(
      $after: String
      $before: String
      $first: Int
      $last: Int
      $sort: JSONString
      $query: JSONString
    ) {
      documentSearchRobotLogs(
        query: $query
        sort: $sort
        first: $first
        last: $last
        after: $after
        before: $before
      ) {
        pageInfo {
          endCursor,
          startCursor,
          hasPreviousPage
        }
        total
        edges {
          node {
            id
            ${fragments.join('\n')}
          }
        }
      }
    }`;
}

/**
 * Get a robotLogs query in all of its glory of variables and such
 * @param [opts]
 * @param [opts.fragments] {array} - array of fragments to query about the robotLogs model
 * @returns {DocumentNode}
 */
export function buildRobotLogsQuery(opts) {
  const fragments = _.get(opts, 'fragments', []);

  return gql`
      query robotLogs(
          $robotLogIds: [UUID]
          $collectionId: UUID
          $environmentIds: [UUID]
          $robotIds: [UUID]
          $robotProducts: [Product]
          $tagIdsPresent: [UUID]
          $causeIdsPresent: [UUID]
          $labelIdsPresent: [UUID]
          $ticketIdsPresent: [UUID]
          $ticketPlatformIdsPresent: [String]
          $ticketPlatform: String
          $textLike: String
          $startTime_Range: DateTimeRange
          $endTime_Range: DateTimeRange
          $totalTime_Range: IntRange
          $involvedUserIds: [UUID]
          $after: String
          $before: String
          $robotLogTestIds: [UUID]
          $flightType: String
          $sort: [RobotLogSortEnum]
          $softwareVersions: [String]
          $softwareBranches: [String]
          $softwareCommitHashes: [String]
          $first: Int
          $last: Int
          $robotLogReviewReviewStatuses: [ReviewStatus]
          $robotLogReviewReviewStatusesNotIn: [ReviewStatus]
          $robotLogReviewReviewerIds: [UUID]
          $robotLogReviewReviewerTeamIds: [UUID]
          $robotLogReviewRequesterIds: [UUID]
          $startTimeOfDayRangeIn: [TimeRange]
          $artifactArtifactTypeIn: [ArtifactType]
          $logEventComponentOwners: [String]
          $logEventComponentExternalIds: [Int]
          $logEventLogDefinitionExternalIds: [Int]
      ) {
          robotLogs(
              sort: $sort
              first: $first
              last: $last
              after: $after
              before: $before
              robotLogIds: $robotLogIds
              environmentIds: $environmentIds
              robotIds: $robotIds
              robotProducts: $robotProducts
              collectionId: $collectionId
              tagIdsPresent: $tagIdsPresent
              causeIdsPresent: $causeIdsPresent
              labelIdsPresent: $labelIdsPresent
              ticketIdsPresent: $ticketIdsPresent
              ticketPlatformIdsPresent: $ticketPlatformIdsPresent
              ticketPlatform: $ticketPlatform
              textLike: $textLike
              startTime_Range: $startTime_Range
              endTime_Range: $endTime_Range
              totalTime_Range: $totalTime_Range
              involvedUserIds: $involvedUserIds
              robotLogTestIds: $robotLogTestIds
              flightType: $flightType
              robotLogReviewReviewStatuses: $robotLogReviewReviewStatuses
              robotLogReviewReviewStatusesNotIn: $robotLogReviewReviewStatusesNotIn
              robotLogReviewReviewerIds: $robotLogReviewReviewerIds
              robotLogReviewReviewerTeamIds: $robotLogReviewReviewerTeamIds
              robotLogReviewRequesterIds: $robotLogReviewRequesterIds
              softwareVersions: $softwareVersions
              softwareBranches: $softwareBranches
              softwareCommitHashes: $softwareCommitHashes
              startTimeOfDayRangeIn: $startTimeOfDayRangeIn
              artifactArtifactTypeIn: $artifactArtifactTypeIn
              logEventComponentOwners: $logEventComponentOwners
              logEventComponentExternalIds: $logEventComponentExternalIds
              logEventLogDefinitionExternalIds: $logEventLogDefinitionExternalIds
          ) {
              pageInfo {
                  endCursor,
                  startCursor,
                  hasPreviousPage
              }
              total
              edges {
                  node {
                      id
                      ${fragments.join('\n')}
                  }
              }
          }
      }`;
}

export function createOrUpdateSessionLog(
  sessionLog: SessionLog,
  mask: FieldMask
): Promise<SessionLog> {
  const req = new CreateOrUpdateRequest();
  req.setSessionLog(sessionLog);
  req.setUpdateMask(mask);

  return sdkClient.unary(SessionLogService.CreateOrUpdate, req).then((res) => {
    return res;
  });
}

export function updateSessionLog(
  sessionLog: SessionLog,
  mask: FieldMask
): Promise<SessionLog> {
  const req = new UpdateRequest();
  req.setSessionLog(sessionLog);
  req.setUpdateMask(mask);

  return sdkClient.unary(SessionLogService.CreateOrUpdate, req).then((res) => {
    return res;
  });
}

export function getSessionLogDisplayName(robotLog): string {
  if (robotLog) {
    if (robotLog.displayName) {
      return robotLog.displayName;
    } else {
      return robotLog.name;
    }
  }

  return '';
}

//createSelector(buildRobotLogsQuery('name'),variables: { collectionId: collectionId })
export const getCollectionRobotLogNames = (collectionId, callback) => {
  const collectionRobotLogNamesQuery = gql`
    query robotLogs($collectionId: UUID) {
      robotLogs(collectionId: $collectionId) {
        edges {
          node {
            name
          }
        }
      }
    }
  `;
  client
    .query({
      query: collectionRobotLogNamesQuery,
      variables: { collectionId: collectionId },
      fetchPolicy: 'network-only',
    })
    .then(
      (val) => {
        callback(null, val);
      },
      (err) => {
        callback(err);
      }
    );
};

export enum SessionLogProduct {
  VBAT,
  NOVA,
  UNKNOWN,
}

export const detectSessionLogProduct = (name: string) => {
  const normalized = name.toLowerCase();
  if (normalized.includes('vbat')) {
    return SessionLogProduct.VBAT;
  }
  if (normalized.includes('nova')) {
    return SessionLogProduct.NOVA;
  }
  return SessionLogProduct.UNKNOWN;
};

export type VBATMetadata = {
  Model?: string | number;
  Configuration?: string | number;
  Aircraft?: string | number;
  'Autopilot Firmware'?: string | number;
  'Leaf Firmware'?: string | number;
  'Flight Number'?: string | number;
  'Primary Mission'?: string | number;
  [key: string]: any;
};
export const VBATMetricNames = {
  grossTakeOffWeight: 'Gross Takeoff Weight (lb)',
  launchDensityAltitude: 'Launch Density Altitude (ft) (Operator-Reported)',
  launchWindSpeed: 'Launch Wind Speed (kn) (Operator-Reported)',
  launchGustingWinds: 'Launch Gusting Winds (kn) (Operator-Reported)',
  landingDensityAltitude: 'Landing Density Altitude (ft) (Operator-Reported)',
  landingWindSpeed: 'Landing Wind Speed (kn) (Operator-Reported)',
  landingGustingWinds: 'Landing Gusting Winds (kn) (Operator-Reported)',
  centerOfGravity: 'Center of Gravity (in) (Operator-Reported)',
  fuelUsed: 'Fuel Used (lb) (Operator-Reported)',
  gcsEstimatedFuelOut: 'GCS Estimated Fuel Out (lb) (Operator-Reported)',
  gcsEstimatedFuelUsed: 'GCS Estimated Fuel Used (lb) (Operator-Reported)',
  fuelOut: 'Fuel Out (lb) (Operator-Reported)',
  fuelIn: 'Fuel In (lb) (Operator-Reported)',
  dryWeight: 'Dry Weight (lb) (Operator-Reported)',
  landingPressure: 'Landing Pressure (inHG) (Operator-Reported)',
  landingTemp: 'Landing Temperature (degC) (Operator-Reported)',
  launchPressure: 'Launch Pressure (inHG) (Operator-Reported)',
  launchTemp: 'Launch Temperature (degC) (Operator-Reported)',
  launchRh: 'Launch RH (percent) (Operator-Reported)',
  landingRh: 'Landing RH (percent) (Operator-Reported)',
  launchDewPoint: 'Launch Dew Point (degC) (Operator-Reported)',
  landingDewPoint: 'Landing Dew Point (degC) (Operator-Reported)',
  numberOfEOInterventions: 'Number of EO Interventions (Operator-Reported)',
  numberOfUnplannedEOInterventions:
    'Number of Unplanned EO Interventions (Operator-Reported)',
  landingClouds: 'Landing Clouds (ft) (Operator-Reported)',
  launchClouds: 'Launch Clouds (ft) (Operator-Reported)',
  elevation: 'Elevation (ft) (Operator-Reported)',
  numManualLaunches: 'Manual Launches (Operator-Reported)',
  numManualLandings: 'Manual Landings (Operator-Reported)',
  numAutoLaunches: 'Auto Launches (Operator-Reported)',
  numAutoLandings: 'Auto Landings (Operator-Reported)',
  numSorties: 'Number of Sorties',
  wingTime: 'Wing Time (hr) (Operator-Reported)',
  hoverTime: 'Hover Time (hr) (Operator-Reported)',
  maxRPM: 'Max RPM (RPM) (Operator-Reported)',
  lowRPM: 'Low RPM (RPM) (Operator-Reported)',
  flightTime: 'Total Flight Time (hr) (Operator-Reported)',
  engineTime: 'Engine Time (hr) (Operator-Reported)',
  newEngineTime: 'New Engine Time (hr) (Operator-Reported)',
  thrustPercent: 'Thrust Percent (percent) (Operator-Reported)',
  numberOfStarts: 'Number of Starts (Operator-Reported)',
  landingSeaState: 'Landing Sea State (Operator-Reported)',
  launchSeaState: 'Launch Sea State (Operator-Reported)',
  launchShipSOG: 'Launch Ship/SOG (kn) (Operator-Reported)',
  landingShipSOG: 'Landing Ship/SOG (kn) (Operator-Reported)',
  launchPitchRoll: 'Launch Pitch/Roll (degrees) (Operator-Reported)',
  landingPitchRoll: 'Landing Pitch/Roll (degrees) (Operator-Reported)',
  postFlightTotalAirframeTime:
    'Post Flight Total Airframe Time (hr) (Operator-Reported)',
  postFlightTotalEngineTime:
    'Post Flight Total Engine Time (hr) (Operator-Reported)',
  preFlightTotalAirframeTime:
    'Pre Flight Total Airframe Time (hr) (Operator-Reported)',
  preFlightTotalEngineTime:
    'Pre Flight Total Engine Time (hr) (Operator-Reported)',
  groundTime: 'Ground Time (hr) (Operator-Reported)',
  untrackedGroundTime: 'Untracked Ground Time (hr)',
};

export type VBATMetrics = {
  'Gross Takeoff Weight (lb) (Operator-Reported)'?: number;
  'Gross Takeoff Weight (lb)'?: number;
  'Launch Density Altitude (ft) (Operator-Reported)'?: number;
  'Launch Density Altitude (ft)'?: number;
  'Launch Wind Speed (kn) (Operator-Reported)'?: number;
  'Launch Wind Speed (kn)'?: number;
  'Launch Gusting Winds (kn) (Operator-Reported)'?: number;
  'Launch Gusting Winds (kn)'?: number;
  'Landing Density Altitude (ft) (Operator-Reported)'?: number;
  'Landing Density Altitude (ft)'?: number;
  'Landing Wind Speed (kn) (Operator-Reported)'?: number;
  'Landing Wind Speed (kn)'?: number;
  'Landing Gusting Winds (kn) (Operator-Reported)'?: number;
  'Landing Gusting Winds (kn)'?: number;
  'Center of Gravity (in) (Operator-Reported)'?: number;
  'Center of Gravity (in)'?: number;
  'Fuel Used (lb) (Operator-Reported)'?: number;
  'Fuel Used (lb)'?: number;
  'GCS Estimated Fuel Out (lb) (Operator-Reported)'?: number;
  'GCS Estimated Fuel Out (lb)'?: number;
  'GCS Estimated Fuel Used (lb) (Operator-Reported)'?: number;
  'GCS Estimated Fuel Used (lb)'?: number;
  'Fuel Out (lb) (Operator-Reported)'?: number;
  'Fuel Out (lb)'?: number;
  'Fuel In (lb) (Operator-Reported)'?: number;
  'Fuel In (lb)'?: number;
  'Dry Weight (lb) (Operator-Reported)'?: number;
  'Dry Weight (lb)'?: number;
  'Landing Pressure (inHG) (Operator-Reported)'?: number;
  'Landing Pressure (inHG)'?: number;
  'Landing Temperature (degC) (Operator-Reported)'?: number;
  'Landing Temperature (degC)'?: number;
  'Launch Pressure (inHG) (Operator-Reported)'?: number;
  'Launch Pressure (inHG)'?: number;
  'Launch Temperature (degC) (Operator-Reported)'?: number;
  'Launch Temperature (degC)'?: number;
  'Launch RH (percent) (Operator-Reported)'?: number;
  'Launch RH (percent)'?: number;
  'Landing RH (percent) (Operator-Reported)'?: number;
  'Landing RH (percent)'?: number;
  'Launch Dew Point (degC) (Operator-Reported)'?: number;
  'Launch Dew Point (degC)'?: number;
  'Landing Dew Point (degC) (Operator-Reported)'?: number;
  'Landing Dew Point (degC)'?: number;
  'Number of EO Interventions (Operator-Reported)'?: number;
  'Number of EO Interventions'?: number;
  'Number of Unplanned EO Interventions (Operator-Reported)'?: number;
  'Number of Unplanned EO Interventions'?: number;
  'Launch Clouds (ft) (Operator-Reported)'?: number;
  'Launch Clouds (ft)'?: number;
  'Landing Clouds (ft) (Operator-Reported)'?: number;
  'Landing Clouds (ft)'?: number;
  'Elevation (ft) (Operator-Reported)'?: number;
  'Elevation (ft)'?: number;
  'Manual Launches (Operator-Reported)'?: number;
  'Manual Launches'?: number;
  'Manual Landings (Operator-Reported)'?: number;
  'Manual Landings'?: number;
  'Auto Launches (Operator-Reported)'?: number;
  'Auto Launches'?: number;
  'Auto Landings (Operator-Reported)'?: number;
  'Auto Landings'?: number;
  'Number of Sorties'?: number;
  'Hover Time (hr) (Operator-Reported)'?: number;
  'Hover Time (hr)'?: number;
  'Wing Time (hr) (Operator-Reported)'?: number;
  'Wing Time (hr)'?: number;
  'Max RPM (RPM) (Operator-Reported)'?: number;
  'Max RPM (RPM)'?: number;
  'Low RPM (RPM) (Operator-Reported)'?: number;
  'Low RPM (RPM'?: number;
  'Flight Time (hr) (Operator-Reported)'?: number;
  'Flight Time (hr)'?: number;
  'Total Engine Time (hr) (Operator-Reported)'?: number;
  'Total Engine Time (hr)'?: number;
  'New Engine Time (hr) (Operator-Reported)'?: number;
  'New Engine Time (hr)'?: number;
  'Landing Sea State (Operator-Reported)'?: number;
  'Landing Sea State'?: number;
  'Launch Sea State (Operator-Reported)'?: number;
  'Launch Sea State'?: number;
  'Thrust Percent (percent) (Operator-Reported)'?: number;
  'Thrust Percent (percent)'?: number;
  'Number of Starts (Operator-Reported)'?: number;
  'Number of Starts'?: number;
  'Launch Ship/SOG (kn) (Operator-Reported)'?: number;
  'Launch Ship/SOG (kn)'?: number;
  'Landing Ship/SOG (kn) (Operator-Reported)'?: number;
  'Landing Ship/SOG (kn)'?: number;
  'Landing Pitch/Roll (degrees) (Operator-Reported)'?: number;
  'Landing Pitch/Roll (degrees)'?: number;
  'Launch Pitch/Roll (degrees) (Operator-Reported)'?: number;
  'Launch Pitch/Roll (degrees)'?: number;
  'Pre Flight Total Airframe Time (hr) (Operator-Reported)'?: number;
  'Pre Flight Total Airframe Time (hr)'?: number;
  'Pre Flight Total Engine Time (hr) (Operator-Reported)'?: number;
  'Pre Flight Total Engine Time (hr)'?: number;
  'Post Flight Total Airframe Time (hr) (Operator-Reported)'?: number;
  'Post Flight Total Airframe Time (hr)'?: number;
  'Post Flight Total Engine Time (hr) (Operator-Reported)'?: number;
  'Post Flight Total Engine Time (hr)'?: number;
  'Ground Time (hr) (Operator-Reported)'?: number;
  'Ground Time (hr)'?: number;
  'Engine time (hr) (Operator-Reported)'?: number;
  'Engine time (hr)'?: number;
  'Untracked Ground Time (hr)'?: number;
};
// keys of VBATMetadata
export type VBATMetadataKey = keyof VBATMetadata;

export type VBATMetricKey = keyof VBATMetrics;

type NewSessionLog = Partial<
  Omit<SessionLog.AsObject, 'metadata' | 'startTime' | 'endTime'>
> & {
  participants?: NewBatchParticipants[];
  childrenAlarisParticipants?: VBATParticipantAlaris[];
  events?: Event[];
  notes?: string[];
  metadata: VBATMetadata;
  metrics: {
    [key: string]: string | number;
  };
  startTime: string;
  endTime?: string;
  artifacts?: UploadArtifactType[];
  onArtifactsChange?: (artifacts: UploadArtifactType[]) => void;
  onError?: (error: string) => void;
  onEventLog?: (eventLog: string) => void;
};

const SESSION_LOG_QUERY_KEY = 'sessionLogs';

export const createSessionLogMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async ({
      name,
      displayName,
      sessionLogType,
      startTime,
      endTime,
      testDefinitionId,
      environmentIdsList,
      participants,
      childrenAlarisParticipants,
      events,
      labelsList,
      metadata,
      metrics,
      notes,
      artifacts,
      onArtifactsChange,
      onError = () => ({}),
      onEventLog = () => ({}),
    }: NewSessionLog) => {
      const req = new CreateSessionLogRequest();
      const sessionLog = new SessionLog();
      sessionLog.setName(name);
      sessionLog.setDisplayName(displayName);

      sessionLog.setSessionLogType(sessionLogType);
      sessionLog.setTestDefinitionId(testDefinitionId);

      sessionLog.setLabelsList(labelsList);

      const startTimestamp = Timestamp.fromDate(new Date(startTime));
      sessionLog.setStartTime(startTimestamp);

      if (endTime) {
        const endTimestamp = Timestamp.fromDate(new Date(endTime));
        sessionLog.setEndTime(endTimestamp);
      }

      const cleanedMetadata = Object.entries(metadata).reduce(
        (accumulator, [key, value]) => {
          if (key == null || value == null) {
            return accumulator;
          }
          accumulator[String(key)] = String(value);
          return accumulator;
        },
        {}
      );

      const metadataStruct = Struct.fromJavaScript(cleanedMetadata);

      sessionLog.setMetadata(metadataStruct);

      let environmentIds;

      try {
        onEventLog('Creating any non existing environments');
        environmentIds = await createNonExisitingEnvironements(
          environmentIdsList
        );
        onEventLog('Any new environments were created successfully');
      } catch (error) {
        onError('Failed to create environments');
        throw error;
      }

      sessionLog.setEnvironmentIdsList(environmentIds);

      req.setSessionLog(sessionLog);

      let response;

      try {
        onEventLog('Creating session log');
        response = await sdkClient.unary(SessionLogService.Create, req);
        onEventLog('Session log created successfully');
      } catch (error) {
        onError('Failed to create session log');
        throw error;
      }

      const sessionLogId = response.getId();

      try {
        if (notes.length > 0) {
          onEventLog('Creating notes');
          for (const note of notes) {
            if (!note) continue;
            const noteObj = new Note();
            const noteReq = new CreateNoteRequest();
            noteObj.setSessionLogId(sessionLogId);
            noteObj.setContent(note);
            noteObj.setSourceType(SourceType.OBSERVER);
            noteObj.setNoteType(NoteType.OBSERVER);
            noteReq.setNote(noteObj);
            await sdkClient.unary(NoteService.Create, noteReq);
          }
          onEventLog('Notes created successfully');
        }
      } catch (error) {
        onError('Failed to create notes');
        throw error;
      }

      try {
        const metricEntries = Object.entries(metrics);
        if (metricEntries.length > 0) {
          onEventLog('Creating metrics');
          for (const [key, value] of metricEntries) {
            if (value == null) continue;
            const createMetric = new CreateMetricRequest();

            const metric = new Metric();
            metric.setSessionLogId(sessionLogId);
            metric.setName(key);
            metric.setValue(parseFloat(value.toString()));
            metric.setValueDataType('float32');

            createMetric.setMetric(metric);

            await sdkClient.unary(MetricService.Create, createMetric);
          }
          onEventLog('Metrics created successfully');
        }
      } catch (error) {
        onError('Failed to create metrics');
        throw error;
      }

      try {
        if (participants.length > 0) {
          onEventLog('Creating participants');

          await batchCreateParticipants(
            participants.map((participant) => ({
              ...participant,
              sessionLogId,
            }))
          );
          onEventLog('Participants created successfully');
        }
      } catch (error) {
        onError('Failed to create participants');
        throw error;
      }

      try {
        if (childrenAlarisParticipants.length > 0) {
          const positionDict = {};

          for (let i = 0; i < childrenAlarisParticipants.length; i++) {
            const req = new CreateSessionLogRequest();
            const childSessionLog = new SessionLog();
            if (!(childrenAlarisParticipants[i].name in positionDict)) {
              positionDict[childrenAlarisParticipants[i].name] = 1;
            }

            childSessionLog.setName(
              childrenAlarisParticipants[i].name +
                ' ' +
                positionDict[childrenAlarisParticipants[i].name] +
                ' ' +
                name
            );

            positionDict[childrenAlarisParticipants[i].name] =
              positionDict[childrenAlarisParticipants[i].name] + 1;

            childSessionLog.setSessionLogTypeSlug('participant-log');
            const parent = new SessionLog.ParentChildSessionLog();
            parent.setId(sessionLogId);

            childSessionLog.setParentsList([parent]);

            const startTimeDate = new Date(startTime);
            const startTimeMoment = moment(startTimeDate);

            //set startTime
            const startTimestamp = Timestamp.fromDate(startTimeMoment.toDate());
            childSessionLog.setStartTime(startTimestamp);

            if (childrenAlarisParticipants[i].totalTime) {
              const momentTwo = moment(
                startTimeMoment.format('YYYY-MM-DDTHH:mm')
              );

              momentTwo.add(childrenAlarisParticipants[i].totalTime, 'hours');

              const endTimestamp = Timestamp.fromDate(momentTwo.toDate());
              childSessionLog.setEndTime(endTimestamp);
            }

            if (childrenAlarisParticipants[i].phaseOfFlight) {
              const childMetadata = {
                'Phase Of Flight': childrenAlarisParticipants[i].phaseOfFlight,
              };
              const metadataStruct = Struct.fromJavaScript(childMetadata);

              childSessionLog.setMetadata(metadataStruct);
            }

            req.setSessionLog(childSessionLog);

            let response;

            try {
              onEventLog('Creating child session log');
              response = await sdkClient.unary(SessionLogService.Create, req);
              onEventLog('Child participant Session log created successfully');
            } catch (error) {
              onError('Failed to create child participant session log');
              throw error;
            }
            const childSessionLogId = response.getId();

            try {
              if (childrenAlarisParticipants[i].participant) {
                onEventLog('Creating participants');

                await batchCreateParticipants([
                  {
                    ...childrenAlarisParticipants[i].participant,
                    sessionLogId: childSessionLogId,
                  },
                ]);
                onEventLog('Participants created successfully');
              }
            } catch (error) {
              onError('Failed to create participants');
              throw error;
            }

            try {
              //create launch and recovery metrics
              if (childrenAlarisParticipants[i].launch != null) {
                const createMetric = new CreateMetricRequest();

                const metric = new Metric();
                metric.setSessionLogId(childSessionLogId);
                metric.setName('Launch');
                metric.setValue(
                  parseInt(childrenAlarisParticipants[i].launch.toString())
                );
                metric.setValueDataType('int32');

                createMetric.setMetric(metric);

                await sdkClient.unary(MetricService.Create, createMetric);
              }
              if (childrenAlarisParticipants[i].recovery != null) {
                const createMetric2 = new CreateMetricRequest();
                const metric2 = new Metric();
                metric2.setSessionLogId(childSessionLogId);
                metric2.setName('Recovery');
                metric2.setValue(
                  parseInt(childrenAlarisParticipants[i].recovery.toString())
                );
                metric2.setValueDataType('int32');

                createMetric2.setMetric(metric2);

                await sdkClient.unary(MetricService.Create, createMetric2);
              }
              if (childrenAlarisParticipants[i].totalTime != null) {
                const createMetric3 = new CreateMetricRequest();
                const metric3 = new Metric();
                metric3.setSessionLogId(childSessionLogId);
                metric3.setName('Total Time (hr)');
                metric3.setValue(
                  parseInt(childrenAlarisParticipants[i].totalTime.toString())
                );
                metric3.setValueDataType('float32');

                createMetric3.setMetric(metric3);

                await sdkClient.unary(MetricService.Create, createMetric3);
              }
              onEventLog('Child participant Metrics created successfully');
            } catch (error) {
              onError('Failed to create  Child participant metrics');
              throw error;
            }
          }
        }
      } catch (error) {
        onError('Failed to create participant children');
        throw error;
      }

      try {
        if (events.length > 0) {
          onEventLog('Creating Events');
          const eventsWithSessionLogId = events.map((event) => {
            event.setSessionLogId(sessionLogId);
            return event;
          });
          //this is not transactional. PROBLEM??
          for (let i = 0; i < eventsWithSessionLogId.length; i++) {
            const cr = new CreateEventRequest();
            cr.setEvent(eventsWithSessionLogId[i]);
            const response = await sdkClient.unary(EventService.Create, cr);
          }
          onEventLog('Events created successfully');
        }
      } catch (error) {
        onError('Failed to create events');
        throw error;
      }

      try {
        onEventLog('Uploading artifacts');
        await uploadArtifacts({
          artifacts: artifacts,
          sessionLogId: sessionLogId,
          onChange: onArtifactsChange,
        });
        onEventLog('Artifacts uploaded successfully');
      } catch (error) {
        onError('Failed to upload artifacts');
        throw error;
      }

      return response.toObject();
    },
    onError: (error) => {
      return error;
    },
    onSuccess: (data) => {
      const sessionLog = data;
      queryClient.invalidateQueries({
        queryKey: [SESSION_LOG_QUERY_KEY, sessionLog.id],
      });
    },
  });
  return mutation;
};

// get the session log
const fetchSessionLog = async (sessionLogId: string) => {
  const sessionLogRequest = new GetSessionRequest();
  sessionLogRequest.setId(sessionLogId);

  const sessionLogResponse = await sdkClient.query(
    SessionLogService.Get,
    sessionLogRequest
  );

  const sessionLog = sessionLogResponse.toObject();

  return sessionLog;
};

export const useSessionLog = (sessionLogId: string) =>
  useQuery({
    queryKey: [SESSION_LOG_QUERY_KEY, sessionLogId],
    queryFn: () => fetchSessionLog(sessionLogId),
  });

const SESSION_LOGS_QUERY_KEY = 'sessionLogs';

// get the session logs
const fetchSessionLogs = async (sessionLogIds: string[]) => {
  const sessionLogsRequest = new BatchGetRequest();
  const ids = new BatchGetIds();
  ids.setIdsList(sessionLogIds);

  sessionLogsRequest.setBatchGetIds(ids);

  const sessionLogsResponse = await sdkClient.query(
    SessionLogService.BatchGet,
    sessionLogsRequest
  );

  const sessionLogs = sessionLogsResponse.toObject().sessionLogsList;

  return sessionLogs;
};

export const useSessionLogs = (sessionLogIds: string[]) => {
  const sessionLogs = useQuery({
    queryKey: [SESSION_LOGS_QUERY_KEY, sessionLogIds],
    queryFn: () => fetchSessionLogs(sessionLogIds),
  });

  return sessionLogs;
};

const createNonExisitingEnvironements = async (
  environmentIdsList: string[]
) => {
  const newEnvironmentIdsList: string[] = [];
  const nonExistingEnvironmentIdsList = environmentIdsList.filter(
    (environmentId) => !isUUID(environmentId)
  );
  const existingEnvironementIds = environmentIdsList.filter((environmentId) =>
    isUUID(environmentId)
  );

  for (const environmentId of nonExistingEnvironmentIdsList) {
    const createEnvironement = new CreateEnvironmentRequest();
    const environment = new Environment();
    environment.setName(environmentId);
    createEnvironement.setEnvironment(environment);
    const request = await sdkClient.unary(
      EnvironmentService.Create,
      createEnvironement
    );
    newEnvironmentIdsList.push(request.getId());
  }

  return [...existingEnvironementIds, ...newEnvironmentIdsList];
};

export const updateSessionLogEnvironments =
  (sessionLogId: string) => async (environmentIdsList: string[]) => {
    const environmentIds = await createNonExisitingEnvironements(
      environmentIdsList
    );

    const sessionLogRequest = new UpdateSessionLogRequest();
    const sessionLog = new SessionLog();

    sessionLog.setId(sessionLogId);
    sessionLog.setEnvironmentIdsList(environmentIds);

    sessionLogRequest.setSessionLog(sessionLog);

    const response = await sdkClient.unary(
      SessionLogService.Update,
      sessionLogRequest
    );

    return response.toObject();
  };

export const updateSessionLogEnvironmentsMutation = (sessionLogId: string) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(updateSessionLogEnvironments(sessionLogId), {
    onError: (error) => {
      return error;
    },
    onSuccess: (data) => {
      const sessionLog = data;
      queryClient.invalidateQueries({
        queryKey: [SESSION_LOG_QUERY_KEY, sessionLogId],
      });
    },
  });
  return mutation;
};

export const updateSessionLogMetadataMutation = (sessionLogId: string) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    async (metadata: Record<string, string>) => {
      const sessionLogRequest = new UpdateSessionLogRequest();
      const sessionLog = new SessionLog();

      const cleanedMetadata = Object.entries(metadata).reduce(
        (accumulator, [key, value]) => {
          if (key === '' || value === '') {
            return accumulator;
          }
          accumulator[key] = value;
          return accumulator;
        },
        {}
      );

      sessionLog.setId(sessionLogId);
      sessionLog.setMetadata(Struct.fromJavaScript(cleanedMetadata));

      sessionLogRequest.setSessionLog(sessionLog);

      const response = await sdkClient.unary(
        SessionLogService.Update,
        sessionLogRequest
      );

      return response.toObject();
    },
    {
      onError: (error) => {
        return error;
      },
      onSuccess: (data) => {
        const sessionLog = data;
        queryClient.invalidateQueries({
          queryKey: [SESSION_LOG_QUERY_KEY, sessionLogId],
        });
      },
    }
  );
  return mutation;
};

export function listSessionLogTypes(): Promise<SessionLogTypeListResponse> {
  const req = new SessionLogTypeListRequest();

  req.setPageSize(200);

  return sdkClient.unary(SessionLogTypeService.List, req).then((res) => {
    return res;
  });
}

export const asf = () => {
  const req = new SessionLogTypeListRequest();
};

export const alarisData = {
  title: 'AlarisPro flight log options',
  description: 'Dropdown menu options in the AlarisPro flight log form',
  type: 'object',
  properties: {
    pilot: ['p1'],

    aircraft: ['a1', 'a2'],
    mission: ['m1', 'm2'],
    issue: ['issue'],
    project: ['pj1', 'pj2'],
    mission_readiness_reporting: ['mrr1', 'mrr2'],
    location: ['l1', 'l2'],
    autopilot_firmware: ['firm1'],
    leaf_firmware: ['le1'],
    day_night: ['dn1'],
    reliability_code: ['rc1'],
    issue_cause: ['ic1'],
    mishap: ['misa'],
    team: ['team1'],
    ground_crew: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    external_operator: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    payload: {
      type: 'object',
      properties: {
        aircraft: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
      },
    },
  },
};
