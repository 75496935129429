import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { ReactComponent as AccountSvg } from './account.svg';
import { ReactComponent as BootSessionSvg } from './boot-session.svg';
import { ReactComponent as CloudImportSvg } from './cloud-import.svg';
import { ReactComponent as DownloadSvg } from './download.svg';
import { ReactComponent as EmptySvg } from './empty.svg';
import { ReactComponent as FlightSvg } from './flight.svg';
import { ReactComponent as FoldersSvg } from './folders.svg';
import { ReactComponent as LeafSvg } from './leaf.svg';
import { ReactComponent as LogoutSvg } from './logout.svg';
import { ReactComponent as NoFlightSvg } from './no-flight.svg';
import { ReactComponent as NoRobotSvg } from './no-robot.svg';
import { ReactComponent as PipelineSvg } from './pipeline.svg';
import { ReactComponent as PlaySvg } from './play.svg';
import { ReactComponent as RobotSvg } from './robot.svg';
import { ReactComponent as SettingsSvg } from './settings.svg';
import { ReactComponent as ShieldAiColorFullSvg } from './shield-ai-color-full.svg';
import { ReactComponent as ShieldAiGrayLogoSvg } from './shield-ai-gray-logo.svg';
import { ReactComponent as ShieldAiColorLogoSvg } from './shield-ai-color-logo.svg';
import { ReactComponent as SlackLogoSvg } from './slack-logo.svg';
import { ReactComponent as SlackLogoColorSvg } from './slack-logo-color.svg';
import { ReactComponent as StarSvg } from './star.svg';
import { ReactComponent as StarOutlinedSvg } from './star-outlined.svg';
import { ReactComponent as StethoscopeSvg } from './stethoscope.svg';
import { ReactComponent as TeamsLogoSvg } from './microsoft-teams.svg';
import { ReactComponent as TestResultsSvg } from './test-results.svg';
import { ReactComponent as CubeSvg } from './cube.svg';

export function Account(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 36 36">
      <AccountSvg />
    </SvgIcon>
  );
}
export function BootSession(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 32 32">
      <BootSessionSvg />
    </SvgIcon>
  );
}
export function CloudImport(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 33 32">
      <CloudImportSvg />
    </SvgIcon>
  );
}
export function Download(props: SvgIconProps) {
  // View box to give this some padding
  return (
    <SvgIcon {...props} viewBox="-3 -3 24 24">
      <DownloadSvg />
    </SvgIcon>
  );
}
export function Folders(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 33 33">
      <FoldersSvg />
    </SvgIcon>
  );
}
export function NoRobot(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 128 132">
      <NoRobotSvg />
    </SvgIcon>
  );
}
export function Empty(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 677 677">
      <EmptySvg />
    </SvgIcon>
  );
}
export function Flight(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 47 46">
      <FlightSvg />
    </SvgIcon>
  );
}
export function Leaf(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 9 8">
      <LeafSvg />
    </SvgIcon>
  );
}
export function Logout(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 33 33">
      <LogoutSvg />
    </SvgIcon>
  );
}
export function NoFlight(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 130 130">
      <NoFlightSvg />
    </SvgIcon>
  );
}
export function Pipeline(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 17">
      <PipelineSvg />
    </SvgIcon>
  );
}
export function Play(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <PlaySvg />
    </SvgIcon>
  );
}

export function Robot(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <RobotSvg />
    </SvgIcon>
  );
}
export function Settings(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 33 32">
      <SettingsSvg />
    </SvgIcon>
  );
}
export function ShieldAiColorFull(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 571.93 151.3">
      <ShieldAiColorFullSvg />
    </SvgIcon>
  );
}
export function ShieldAiGrayLogo(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 32 32">
      <ShieldAiGrayLogoSvg />
    </SvgIcon>
  );
}
export function ShieldAiColorLogo(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 45 47">
      <ShieldAiColorLogoSvg />
    </SvgIcon>
  );
}
export function SlackLogo(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 33 33">
      <SlackLogoSvg />
    </SvgIcon>
  );
}
export function SlackLogoColor(props: SvgIconProps) {
  // viewBox normalizes whitespace around icon, it's not the same size as the viewbox in the icon
  return (
    <SvgIcon {...props} viewBox="0 0 170 170">
      <SlackLogoColorSvg />
    </SvgIcon>
  );
}
export function Star(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <StarSvg />
    </SvgIcon>
  );
}
export function StarOutlined(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <StarOutlinedSvg />
    </SvgIcon>
  );
}
export function Stethoscope(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 33 32">
      <StethoscopeSvg />
    </SvgIcon>
  );
}
export function TeamsLogo(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="-4 -4 33 33">
      <TeamsLogoSvg />
    </SvgIcon>
  );
}
export function TestResults(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 32 32">
      <TestResultsSvg />
    </SvgIcon>
  );
}
export function Cube(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 32 32">
      <CubeSvg />
    </SvgIcon>
  );
}
