import makeStyles from '@mui/styles/makeStyles';
import { FileInput } from '@shield-ui/controls';
import { UploadArtifactType } from '../UploadFlights/common';
import { ArtifactUploadList } from './ArtifactUploadList';

const useStyles = makeStyles((theme) => {
  return {
    fileInput: {
      marginBottom: theme.spacing(2),
      '& input': {
        display: 'none!important',
      },
    },
  };
});

type Props = {
  onFilesAdded: (files: File[]) => void;
  isSubmitting: boolean;
  artifacts: UploadArtifactType[];
  onArtfactChange: (artifacts: UploadArtifactType[]) => void;
  showProgress?: boolean;
};

export const ArtifactFileManager = ({
  artifacts,
  onFilesAdded,
  isSubmitting,
  onArtfactChange,
  showProgress = true,
}: Props) => {
  const classes = useStyles();
  return (
    <>
      <FileInput
        onFiles={onFilesAdded}
        showPrefixIcon={true}
        className={classes.fileInput}
        disabled={isSubmitting}
      >
        Add Files
      </FileInput>
      <ArtifactUploadList
        artifacts={artifacts}
        isSubmitting={isSubmitting}
        onChange={onArtfactChange}
        showProgress={showProgress}
      />
    </>
  );
};
