import React from 'react';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Close';
import CheckSelectWrapper from '../ParentChildSelect/CheckSelectWrapper';
import ControlBarBase, { CommonControlProps } from './ControlBarBase';

type ValueType = string | number;
type Option = { label: string; value: ValueType };

interface Props extends CommonControlProps {
  options: Option[];
  onChange: (value: ValueType[]) => void;
  value: ValueType[];
}

function getValueDisplay(props): string | undefined {
  const { value = [], options } = props;
  if (value.length) {
    const match = _.find(options, (opt) => value.includes(opt.value));
    if (match) {
      return `${match.label} ${
        value.length > 1 ? `(+${value.length - 1})` : ''
      }`;
    }
  }
  return undefined;
}

const useStyles = makeStyles((theme) => ({
  list: {
    listStyleType: 'none',
    maxHeight: 300,
    margin: theme.spacing(0, 0),
    padding: 0,
    paddingRight: theme.spacing(1),
    overflowY: 'auto',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
    borderTop: 'rgba(255, 255, 255, 0.075)',
    marginBottom: theme.spacing(1),
  },
  icon: {
    fontSize: 16,
    marginLeft: theme.spacing(0.5),
  },
}));

function MultiSelect(props: Props) {
  const classes = useStyles();
  const { onChange, options, value = [] } = props;

  const onChangeItem = (evt, item) => {
    if (value.includes(item.value)) {
      onChange(value.filter((v) => v !== item.value));
    } else {
      onChange(value.concat([item.value]));
    }
  };

  return (
    <>
      <ul className={classes.list}>
        {options.map((opt) => (
          <CheckSelectWrapper
            key={opt.label + opt.value}
            item={opt}
            isChecked={value.includes(opt.value)}
            onChange={onChangeItem}
          >
            {opt.label}
          </CheckSelectWrapper>
        ))}
      </ul>
      <div className={classes.actions}>
        <Button
          size="small"
          disabled={!value.length}
          onClick={() => onChange([])}
        >
          Clear <ClearIcon className={classes.icon} />
        </Button>
      </div>
    </>
  );
}

function ControlBarMultiSelect(props: Props) {
  return (
    <ControlBarBase
      {...props}
      ControlNode={<MultiSelect {...props} />}
      valueDisplay={getValueDisplay(props)}
    />
  );
}

export default ControlBarMultiSelect;
