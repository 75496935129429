import React, { useCallback, useState, useEffect } from 'react';
import { IntegratedSelect } from '@shield-ui/controls';
import {
  useHexColor,
  useSessionLogTypeOptions,
} from '../../services/sessionLogTypes';
import { LoadingIndicator } from '@shield-ui/core';

function SessionLogTypeSelect(props) {
  const { data: hexColorList, isLoading: isHexLoading } = useHexColor();

  const getLabel = useCallback(
    (opt) => {
      return (
        <div
          style={{
            borderLeft: `2px solid ${hexColorList[opt.value] || '#bbb'}`,
            paddingLeft: 8,
            lineHeight: '16px',
          }}
        >
          {opt.label}
        </div>
      );
    },
    [hexColorList, isHexLoading]
  );

  const { data: sessionLogTypeOptions, isLoading } = useSessionLogTypeOptions();

  return (
    <>
      {isLoading || isHexLoading ? (
        <LoadingIndicator />
      ) : (
        <IntegratedSelect
          placeholder="Session Log Types"
          suggestions={sessionLogTypeOptions}
          getOptionLabel={getLabel}
          {...props}
        />
      )}
    </>
  );
}
export default SessionLogTypeSelect;
