import _ from 'lodash';

export default function simpleObjectGetValue(obj) {
  if (_.isArray(obj)) {
    return obj.join('\n');
  }

  return _.reduce(
    obj,
    (acc, value, key) => {
      if (value) {
        acc.push(`${key}:${value}`);
      }
      return acc;
    },
    []
  ).join('\n');
}
