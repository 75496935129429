import React from 'react';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import container, { ContainerProps } from './container';
import { IntegratedSelect } from '@shield-ui/controls';

interface Props extends ContainerProps {
  value: string;
  placeholder?: string;
  onChange: (evt) => void;
  isCreatable?: boolean;
}

const namesToSuggestions = _.memoize((names = []) => {
  return names.map((name) => ({ value: name, label: name }));
});

const useStyles = makeStyles({
  container: { wordBreak: 'break-word', whiteSpace: 'initial' },
});
function WrapLabel({ children }) {
  const classes = useStyles();
  return <div className={classes.container}>{children}</div>;
}

export const getOptionLabel = (opt) => <WrapLabel>{opt.label}</WrapLabel>;

class AnalysisNameSelect extends React.Component<Props> {
  componentDidMount() {
    this.props.ensureAllAnalysisNames();
  }

  render() {
    const {
      value,
      placeholder = 'Expectation Names',
      onChange,
      analysisNames,
      isLoading,
      isCreatable = true,
    } = this.props;

    let suggestions = namesToSuggestions(analysisNames);

    // creatable
    if (isCreatable && value && !analysisNames.includes(value)) {
      suggestions = suggestions.concat([
        {
          value: value,
          label: value,
        },
      ]);
    }

    return (
      <IntegratedSelect
        value={value}
        isMulti={false}
        isCreatable={isCreatable}
        placeholder={placeholder + (isLoading ? ' (Loading...)' : '')}
        suggestions={suggestions}
        onChange={onChange}
        getOptionLabel={getOptionLabel}
      />
    );
  }
}

export default container(AnalysisNameSelect);
