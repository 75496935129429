import React, { useState } from 'react';
import _ from 'lodash';
import { Switch } from 'antd';
import makeStyles from '@mui/styles/makeStyles';
import QueryFilterControl from '../QueryFilterControl';
import {
  NestedControlData,
  NestedQueryFilterValue,
  NestedValueSet,
} from '../../filterTypes';
import { ControlProps } from '../common';
import ModalControlLayout from '../ControlBar/ModalControlLayout';
import MiniTabsController from '../shared/MiniTabsController';
import { colors } from '@shield-ui/styles';
import { AntButton } from '@shield-ui/buttons';
import ListOperatorSwitch from '../shared/ListOperatorSwitch';

interface Props
  extends NestedControlData,
    ControlProps<NestedQueryFilterValue> {}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
  labelsColumn: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 100,
    maxWidth: 140,
    paddingRight: theme.spacing(1),
    marginRight: theme.spacing(1),
    borderRight: '1px solid rgba(255, 255, 255, 0.1)',
  },
  valueSets: {
    display: 'flex',
    flex: 1,
    overflowY: 'auto',
  },
  valueSet: {},
  label: {
    height: 48,
    fontSize: 14,
    color: colors.hues.grays[10],
    padding: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  valueSetControl: {
    padding: theme.spacing(0.5),
    height: 48,
    display: 'flex',
    alignItems: 'center',
  },
  valueSetActions: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(2, 0.5, 1, 0.5),
    '& > button': {
      margin: theme.spacing(0, 1),
    },
  },
  afterTabsComponent: {
    margin: `0px 0 2px 16px`,
  },
  notExistsControlPlaceholder: {
    color: colors.hues.grays[90],
    fontSize: 13,
    letterSpacing: '2px',
    textAlign: 'center',
    paddingLeft: theme.spacing(1),
  },
  notExistsSwitchLabel: {
    fontStyle: 'italic',
    color: colors.hues.grays[30],
    fontSize: 12,
  },
}));

// Get if any of the child values in the nestedValueSet has a value
function getValueSetHasValue(queryFilters, nestedValueSet): boolean {
  if (!nestedValueSet) {
    return false;
  }
  let ret = false;
  queryFilters.forEach((qf) => {
    const id = qf.getId();
    if (qf.hasValue(nestedValueSet[id])) {
      ret = true;
    }
  });
  return ret;
}

// Get the count of nestedValueSets that have a value
function getHasValueCount(queryFilters, nestedValueSets): number {
  const x = nestedValueSets.reduce((acc, nestedValueSet) => {
    return acc + (getValueSetHasValue(queryFilters, nestedValueSet) ? 1 : 0);
  }, 0);

  return x;
}

interface NestedValueSetFormProps {
  index: number;
  queryFilters: Props['queryFilters'];
  nestedValueSet: NestedValueSet;
  getQueryFilterControlComponent: Props['getQueryFilterControlComponent'];
  onAddValueSet: () => void;
  onChangeValueSet: (value: Record<string, any>, index: number) => void;
  onRemoveValueSet: (index: number) => void;
  notExistsProps?: {
    onChange: (notExists: boolean) => void;
    notExists: boolean;
    label: string;
  };
  showValueSetRemove?: boolean;
  showValueSetAdd?: boolean;
}

function NestedValueSetForm(props: NestedValueSetFormProps) {
  const {
    index,
    nestedValueSet,
    queryFilters,
    onAddValueSet,
    onChangeValueSet,
    onRemoveValueSet,
    getQueryFilterControlComponent,
    showValueSetAdd,
    showValueSetRemove,
    notExistsProps,
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.labelsColumn}>
        {queryFilters.map((qf) => (
          <div key={`label-${qf.getId()}`} className={classes.label}>
            {qf.getControlLabel()}
          </div>
        ))}
        <br />
      </div>
      <div className={classes.valueSets}>
        <div className={classes.valueSet}>
          {queryFilters.map((qf) => (
            <div key={qf.getId()} className={classes.valueSetControl}>
              {notExistsProps && notExistsProps.notExists ? (
                <div className={classes.notExistsControlPlaceholder}>n/a</div>
              ) : (
                <QueryFilterControl
                  key={qf.getId()}
                  queryFilter={qf}
                  values={nestedValueSet}
                  setValues={(value) => {
                    onChangeValueSet(value, index);
                  }}
                  LayoutComponent={ModalControlLayout}
                  getQueryFilterControlComponent={
                    getQueryFilterControlComponent
                  }
                />
              )}
            </div>
          ))}
          <div className={classes.valueSetActions}>
            {showValueSetRemove && (
              <AntButton onClick={() => onRemoveValueSet(index)}>
                Remove
              </AntButton>
            )}
            {showValueSetAdd && (
              <AntButton onClick={onAddValueSet}>Add Set</AntButton>
            )}
            {notExistsProps && (
              <>
                <Switch
                  checked={notExistsProps.notExists}
                  onChange={notExistsProps.onChange}
                />
                <div className={classes.notExistsSwitchLabel}>
                  {notExistsProps.label}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default function Nested(props: Props) {
  const {
    onChange,
    value = {},
    getQueryFilterControlComponent,
    queryFilters,
    implementsNotExists,
    notExistsLabel,
  } = props;
  const { nestedValueSets = [], notExists } = value;
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const classes = useStyles();

  const onChangeValueSet = (v, index) => {
    const valueSetToUpdate = nestedValueSets[index] || {};
    const updatedValueSet = {
      ...valueSetToUpdate,
      ...v,
    };

    const newNestedValueSets = [...nestedValueSets];
    newNestedValueSets.splice(index, 1, updatedValueSet);

    onChange({
      nestedValueSets: newNestedValueSets,
    });
  };

  const onRemoveValueSet = (index) => {
    onChange({
      nestedValueSets: nestedValueSets.filter((_, i) => i !== index),
    });
  };

  const onAddValueSet = () => {
    const newNestedValueSets = nestedValueSets.concat([{} as NestedValueSet]);

    onChange({
      nestedValueSets: newNestedValueSets,
    });
    setActiveTabIndex(newNestedValueSets.length - 1);
  };

  const commonProps = {
    queryFilters,
    onAddValueSet,
    onChangeValueSet,
    onRemoveValueSet,
    getQueryFilterControlComponent,
    showValueSetAdd: getValueSetHasValue(queryFilters, _.last(nestedValueSets)),
  };

  if (nestedValueSets.length < 2) {
    const hasValue = getValueSetHasValue(queryFilters, nestedValueSets[0]);

    let notExistsProps;
    if (implementsNotExists && !hasValue) {
      notExistsProps = {
        notExists: notExists || false,
        label: notExistsLabel,
        onChange: (notExists: boolean) => {
          onChange({
            notExists,
            nestedValueSets: undefined,
          });
        },
      };
    }

    return (
      <NestedValueSetForm
        {...commonProps}
        index={0}
        notExistsProps={notExistsProps}
        showValueSetRemove={hasValue}
        nestedValueSet={nestedValueSets[0]}
      />
    );
  }

  return (
    <MiniTabsController
      value={activeTabIndex}
      onChange={setActiveTabIndex}
      AfterTabsComponent={
        getHasValueCount(queryFilters, nestedValueSets) > 1 ? (
          <div className={classes.afterTabsComponent}>
            <ListOperatorSwitch
              value={value.operator}
              onChange={(op) => onChange({ operator: op })}
            />
          </div>
        ) : null
      }
      items={nestedValueSets.map((nestedValueSet, index) => {
        return {
          value: index,
          label: `${index + 1}`,
          renderContent: function RenderNestedValueSetForm() {
            return (
              <NestedValueSetForm
                {...commonProps}
                index={index}
                nestedValueSet={nestedValueSet}
                showValueSetRemove={true}
              />
            );
          },
        };
      })}
    />
  );
}
