import React from 'react';
import TicketMultiSelect from './TicketMultiSelect';

export default function TicketSelect(props) {
  const { onChange, value } = props;
  return (
    <TicketMultiSelect
      onChange={(values) => {
        if (values) {
          onChange(values[0]);
        } else {
          onChange(undefined);
        }
      }}
      value={value ? [value] : undefined}
      maxValues={1}
    />
  );
}
