import React from 'react';
import withStyles from '@mui/styles/withStyles';
import _ from 'lodash';

function styles() {
  return {};
}

export function parseForParticipants(row, participantFilter) {
  let participants = _.get(row, 'participants.edges', []).map(
    (edge) => edge.node
  );

  if (participantFilter) {
    participants = participants.filter(participantFilter);
  }

  return participants;
}

function DisplayParticipant({ participant, showRole }) {
  return (
    <div>
      {participant.userFreeform || _.get(participant, 'user.name')}
      {showRole
        ? ` (${_.upperFirst(participant.participantRole.toLowerCase())})`
        : ''}
    </div>
  );
}

function ParticipantsCell({ row, participantFilter }) {
  const participants = parseForParticipants(row, participantFilter);

  if (!participants.length) {
    return null;
  }

  return (
    <div>
      {participants.map((participant, i) => {
        return (
          <DisplayParticipant
            key={i}
            participant={participant}
            showRole={!participantFilter}
          />
        );
      })}
    </div>
  );
}
export default withStyles(styles)(ParticipantsCell);
