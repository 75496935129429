import _ from 'lodash';

const config = {
  columnKey: 'missionType',
  label: 'Mission Type',
  description:
    'Mission codes associated with test code or manual mission string',
  defaultWidth: 180,
  getValue: ({ row }) => {
    const name = _.get(row, 'robotLogTest.name', '');
    const intendedMission = _.get(row, 'robotLogData.intended_mission', '');

    return name || intendedMission;
  },
  fragment: `
        robotLogTest {
            name
        }
        robotLogData
    `,
};

export default config;
