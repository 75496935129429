import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { BackHeaderWithInput } from '@shield-ui/core';
import _ from 'lodash';
import {
  getSessionLogDisplayName,
  createOrUpdateSessionLog,
} from '../../services/robotLogs';
import { SessionLog } from '@hmd/sdk/api/session_log/v1';
import { FieldMask } from 'google-protobuf/google/protobuf/field_mask_pb';
import { useHexColor } from '../../services/sessionLogTypes';
import { LoadingIndicator } from '@shield-ui/core';

type RobotLogBackHeaderProps = {
  robotLog: any;
  backTo: string;
  CopyUniqueNameButton?: JSX.Element;
  RobotLogLabelSelect?: JSX.Element;
};

const query = gql`
  query ($robotLogId: String!) {
    robotLog(id: $robotLogId) {
      id
      name
      displayName
    }
  }
`;
/**
 * children get wrapped in a standard, stylized padded container
 * Content does not
 */
export default function RobotLogBackHeader(props: RobotLogBackHeaderProps) {
  const { robotLog, backTo, CopyUniqueNameButton, RobotLogLabelSelect } = props;
  const robotLogId = robotLog.id;
  const { error, data, refetch } = useQuery(query, {
    variables: { robotLogId },
    fetchPolicy: 'cache-first',
  });

  const { data: hexColorList, isLoading: isHexLoading } = useHexColor();

  const robotLogGql = _.get(data, ['robotLog']);
  const displayName = getSessionLogDisplayName(robotLogGql);
  const uniqueName = _.get(data, ['robotLog', 'name']);

  const updateDisplayName = (displayNameRes) => {
    const sl = new SessionLog();
    sl.setDisplayName(displayNameRes);
    sl.setId(robotLog.id);
    const mask = new FieldMask();
    mask.setPathsList(['DisplayName']);

    createOrUpdateSessionLog(sl, mask).then(() => {
      refetch();
    });
  };

  return (
    <div>
      {isHexLoading ? (
        <LoadingIndicator />
      ) : (
        <BackHeaderWithInput
          label={displayName}
          gutterBottom={false}
          variant={'page'}
          CopyUniqueNameButton={CopyUniqueNameButton}
          RobotLogLabelSelect={RobotLogLabelSelect}
          uniqueName={uniqueName}
          linkTo={backTo || '../'}
          accentColor={hexColorList[robotLog.sessionLogTypeSlug] || '#00000'}
          uppercase={false}
          onBlur={updateDisplayName}
        ></BackHeaderWithInput>
      )}
    </div>
  );
}
