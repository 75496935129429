import React, { useMemo, useRef } from 'react';
import _ from 'lodash';
import cx from 'classnames';
import Tooltip from '@mui/material/Tooltip';
import {
  Player,
  ControlBar,
  PlaybackRateMenuButton,
  BigPlayButton,
  ProgressControl,
} from 'video-react';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from '@shield-ui/styles';
import { safeDate } from '@shield-ui/utils';
import { Flight } from '@shield-ui/icons';
import config from '../../../lib/config';
import { Artifact } from '@hmd/sdk/api/artifacts/v1';
import { useSessionLog } from '../../../services/robotLogs';
import { SessionLog } from '@hmd/sdk/api/session_log/v1';
import { structValueToBasicStructure } from '../../../services/passThruSearch';

const useStyles = makeStyles(() => {
  return {
    customizeProgressBar: {
      position: 'relative',
      flex: 1,
      '& > div.video-react-control': {
        width: '100%',
      },
    },
    flightTimeProgressBarOverlay: {
      position: 'absolute',
      width: '0%',
      left: '0%',
      top: 12,
      pointerEvents: 'none',
      height: 10,
      backgroundColor: colors.brand.primary,
      opacity: 0.75,
      borderRadius: 2,
    },
    flightTime: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: 4,
      marginRight: 2,
      fontSize: '0.9em',
      minWidth: '45px',
    },
    flightTimeSeekIcon: {
      marginLeft: 4,
      transition: '0.15s ease',
      cursor: 'pointer',
      '&:hover': {
        color: colors.hues.blues[20],
      },
    },
    flightTimeOutOfRange: {
      fontFamily: 'monospace',
      color: colors.hues.yellow,
    },
    flightTimeContainer: {
      display: 'flex',
      alignItems: 'center',
    },
  };
});

const FlightTime = (props) => {
  const {
    flightTimeOffset,
    flightDuration,
    onSeek,
    player: { currentTime },
  } = props;
  const classes = useStyles();

  const flightTimeDisplay = (currentTime - flightTimeOffset).toFixed(1);
  const isDuringFlight =
    currentTime >= flightTimeOffset &&
    currentTime <= flightTimeOffset + flightDuration;

  return (
    <div className={classes.flightTimeContainer}>
      <Tooltip title="Seek to estimated flight start">
        <div onClick={onSeek}>
          <Flight className={classes.flightTimeSeekIcon} />
        </div>
      </Tooltip>
      <Tooltip title="Time code relative to estimated flight start">
        <div
          className={cx(
            classes.flightTime,
            !isDuringFlight && classes.flightTimeOutOfRange
          )}
        >
          {flightTimeDisplay}
        </div>
      </Tooltip>
    </div>
  );
};

const CustomizedProgressControl = (props) => {
  const { classes, startPercent, widthPercent } = props;

  return (
    <div className={classes.customizeProgressBar}>
      <ProgressControl {...props} />
      <div
        className={classes.flightTimeProgressBarOverlay}
        style={{
          left: `${startPercent.toFixed(2)}%`,
          width: `${widthPercent.toFixed(2)}%`,
        }}
      />
    </div>
  );
};

interface RelativeFlightMeta {
  // time in seconds
  offset: number;
  // time in seconds
  duration: number;
  flightProgressWidthPercent: number;
  flightProgressOffsetLeftPercent: number;
}

function getRelativeFlightMeta(
  artifact: Artifact.AsObject,
  sessionLog: SessionLog.AsObject
): RelativeFlightMeta | void {
  const metadata = structValueToBasicStructure(artifact.metadata);

  // required data to be able to compute this
  if (!sessionLog?.startTime || !sessionLog?.endTime || !artifact?.metadata) {
    return;
  }

  // See Hangar:hangar/shield_models/lib/resolution_artifacts/resolution_plans/common.py for context
  const startKey = 'artifact_start_time';
  const endKey = 'artifact_end_time';
  const adjustedStartKey = 'artifact_flight_adjusted_start_time';
  const adjustedEndKey = 'artifact_flight_adjusted_end_time';

  const artifactStart: string =
    metadata[adjustedStartKey] || metadata[startKey];
  const artifactEnd: string = metadata[adjustedEndKey] || metadata[endKey];

  if (!artifactStart || !artifactEnd) {
    return;
  }

  const artifactDuration = differenceInSeconds(
    safeDate(artifactEnd),
    safeDate(artifactStart)
  );
  const flightDuration = differenceInSeconds(
    safeDate(sessionLog.endTime.seconds * 1000),
    safeDate(sessionLog.startTime.seconds * 1000)
  );
  const flightTimeOffset = differenceInSeconds(
    safeDate(sessionLog.startTime.seconds * 1000),
    safeDate(artifactStart)
  );

  let flightBarStartPercent = (flightTimeOffset / artifactDuration) * 100;
  let flightBarWidthPercent = (flightDuration / artifactDuration) * 100;

  if (flightBarStartPercent < 0) {
    // truncate the flight visibility from the width because it happened before the recording started
    flightBarWidthPercent = flightBarWidthPercent + flightBarStartPercent;
    // set the shift to start at the beginning, not negative
    flightBarStartPercent = 0;
  }
  if (flightBarWidthPercent + flightBarStartPercent > 100) {
    // flight goes on "after" the recording ends so we need to truncate it visually at the end of the progress bar
    flightBarWidthPercent = 100 - flightBarStartPercent;
  }

  return {
    offset: flightTimeOffset,
    duration: flightDuration,
    flightProgressOffsetLeftPercent: flightBarStartPercent,
    flightProgressWidthPercent: flightBarWidthPercent,
  };
}

type VideoExpansionProps = {
  artifact: Artifact.AsObject;
  robotLogId?: string;
};

function VideoExpansion(props: VideoExpansionProps) {
  const { artifact, robotLogId } = props;
  const { data: sessionLog } = useSessionLog(robotLogId);
  const classes = useStyles();
  const playerRef = useRef<Player>();
  const src = artifact.publicUri;

  const flightMeta = useMemo(
    () => getRelativeFlightMeta(artifact, sessionLog),
    [artifact, sessionLog]
  );

  return (
    <Player
      ref={playerRef}
      src={src}
      startTime={flightMeta && flightMeta.offset > 0 ? flightMeta.offset : 0}
    >
      <BigPlayButton position="center" />
      <ControlBar autoHide={false} fileName>
        <PlaybackRateMenuButton rates={[4, 2, 1, 0.5, 0.25]} />
        <ProgressControl disabled />
        {flightMeta ? (
          <CustomizedProgressControl
            order={6}
            classes={classes}
            startPercent={flightMeta.flightProgressOffsetLeftPercent}
            widthPercent={flightMeta.flightProgressWidthPercent}
          />
        ) : (
          <ProgressControl />
        )}
        {flightMeta ? (
          <FlightTime
            order={7.4}
            classes={classes}
            onSeek={() => {
              if (!playerRef.current) {
                return;
              }
              playerRef.current.seek(flightMeta.offset);
              playerRef.current.play();
            }}
            flightTimeOffset={flightMeta.offset}
            flightDuration={flightMeta.duration}
          />
        ) : null}
      </ControlBar>
    </Player>
  );
}

export default VideoExpansion;
