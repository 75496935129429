import _ from 'lodash';
import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Grow } from '@shield-ui/core';
import { SolidOutput, SolidOutputType } from '../data';
import PipelineRow from './PipelineRow';
import OutputIndicator from './OutputIndicator';
import OutputRowName from './OutputRowName';
import FavoriteButton from '../../../buttons/FavoriteButton';
import OutputRowDisplayValue from './OutputRowDisplayValue';
import HighlightText from './HighlightText';
import ArtifactExpanded from './ArtifactExpanded';
import AnalysisExpanded from './AnalysisExpanded';
import MetricExpanded from './MetricExpanded';
import StandardExpanded from './StandardExpanded';
import { Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(255, 255, 255, 0.075)',
    },
  },
  name: {
    margin: theme.spacing(0, 1),
  },
  valueDisplay: {
    padding: theme.spacing(0.5, 1.5),
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    borderRadius: theme.shape.borderRadius,
    fontWeight: 600,
    fontSize: '1.05em',
    margin: theme.spacing(0, 1),
  },
  expandedContent: {
    marginTop: theme.spacing(1),
    marginLeft: 40,
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid rgba(0, 0, 0, .15)`,
    marginBottom: theme.spacing(2),
  },
  actionsSpace: {
    width: theme.spacing(1),
  },
}));

export type OutputRowProps = {
  output: SolidOutput;
  searchString: string;
  onToggleFavorite: (output: SolidOutput, evt) => void;
  onEditClick: (type: SolidOutputType, id: string) => void;
};

const getOutputId = (output: SolidOutput) => {
  const { type } = output;
  if (type === SolidOutputType.analysis) {
    return output.analysis.id;
  }
  if (type === SolidOutputType.artifact) {
    return output.artifact.id;
  }
  if (type === SolidOutputType.metric) {
    return output.metric.id;
  }
};

const isOutputEditable = (output: SolidOutput) => {
  if (
    output.type !== SolidOutputType.analysis &&
    output.type !== SolidOutputType.metric
  ) {
    return false;
  }

  // Somewhat of a hack to determine if the output is editable
  if (
    output.type === SolidOutputType.analysis &&
    output?.analysis?.pipelineExecutionId === null &&
    output?.analysis?.framework !== 'JUDITH' &&
    output?.analysis?.framework !== 'JUDY'
  ) {
    return true;
  }
  if (
    output.type === SolidOutputType.metric &&
    output.metric.pipelineExecutionId === null
  ) {
    return true;
  }
  return false;
};

export default function OutputRow({
  output,
  searchString,
  onToggleFavorite,
  onEditClick,
}: OutputRowProps) {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);

  let indicatorType, tooltipText;

  let RowContent, ExpandedContent;

  const outputId = getOutputId(output);

  const isEditable = isOutputEditable(output);

  if (output.type === SolidOutputType.analysis) {
    const { analysis } = output;
    if (analysis.testPassed) {
      indicatorType = 'testPassed';
      tooltipText = 'Expectation Passed';
    } else {
      indicatorType = 'testFailed';
      tooltipText = 'Expectation Failed';
    }
    RowContent = (
      <OutputRowDisplayValue value={_.get(analysis.data, 'value')} />
    );
    if (isExpanded) {
      ExpandedContent = <AnalysisExpanded analysis={analysis} />;
    }
  } else if (output.type === SolidOutputType.artifact) {
    const { artifact } = output;
    indicatorType = 'plot';
    tooltipText = 'Plotly Output';
    if (isExpanded) {
      ExpandedContent = <ArtifactExpanded artifact={artifact} />;
    }
  } else if (output.type === SolidOutputType.metric) {
    const { metric } = output;
    indicatorType = 'metric';
    tooltipText = 'Output Metric';
    RowContent = <OutputRowDisplayValue value={metric.value} />;
    if (isExpanded) {
      ExpandedContent = <MetricExpanded metric={metric} />;
    }
  }

  return (
    <>
      <PipelineRow onClick={() => setIsExpanded(!isExpanded)}>
        <OutputIndicator type={indicatorType} tooltipText={tooltipText} />
        <OutputRowName>
          {searchString && (
            <HighlightText str={output.name} searchString={searchString} />
          )}
          {!searchString && output.name}
        </OutputRowName>
        <Grow />
        {RowContent}
        <div className={classes.actionsSpace} />
        {isEditable ? (
          <Button
            type="text"
            icon={<EditOutlined />}
            onClick={(evt) => {
              evt.preventDefault();
              evt.stopPropagation();
              onEditClick(output.type, outputId);
            }}
          ></Button>
        ) : null}
        <FavoriteButton
          isFavorite={output.isFavorite}
          includeTooltip={false} //perf hack
          size="small"
          onClick={(evt) => {
            evt.preventDefault();
            evt.stopPropagation();
            onToggleFavorite(output, evt);
          }}
        />
        <div className={classes.actionsSpace} />
        {isExpanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
      </PipelineRow>
      {isExpanded && (
        <div className={classes.expandedContent}>
          {ExpandedContent}
          <StandardExpanded {...output} />
        </div>
      )}
    </>
  );
}
