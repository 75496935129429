import React from 'react';
import _ from 'lodash';
import AppliedLabel from '../../../../connected-controls/labels/AppliedLabel';

export function getLabelsFromRow(row) {
  const edges = _.get(row, 'labels.edges', []);
  return _.compact(_.map(edges, 'node'));
}

const style = { display: 'flex', flexWrap: 'wrap' };

function LabelsCell({ row }) {
  return (
    <div style={style}>
      {getLabelsFromRow(row).map((label) => {
        return <AppliedLabel key={label.id} name={label.name} />;
      })}
    </div>
  );
}

export default LabelsCell;
