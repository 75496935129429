import React from 'react';
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from './GlobalStyles';
import muiTheme from './muiTheme';
import '@mui/styles';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

/**
 * Put in the root of your application to standardize all styles and setup the styled theme provider
 */
export default function StyleRoot({ children }) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
        <>
          <CssBaseline />
          <GlobalStyles />
          {children}
        </>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
