import React from 'react';
import { StyledPlot } from '@shield-ui/analysis';
import { LoadingIndicator } from '@shield-ui/core';
import sdkClient from '../../lib/hmdsdkClient';

type Props = {
  artifactId: string;
};

class PlotlyPlotArtifact extends React.Component<Props> {
  state = {
    json: null,
  };

  componentDidMount() {
    const { artifactId } = this.props;

    sdkClient.artifacts
      .getContent(artifactId)
      .then((res) => res.json())
      .then((json) => {
        this.setState({ json });
      })
      .catch((e) => {
        console.error("PlotlyPlot failed", e);
        this.setState({
          json: false
        })
      });
  }

  render() {
    const { json } = this.state;

    if (json === false) return null

    if (!json) {
      return <LoadingIndicator />;
    }

    return <StyledPlot {...json} />;
  }
}

export default PlotlyPlotArtifact;
