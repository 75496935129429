import React from 'react';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import DownArrow from '@mui/icons-material/KeyboardArrowDown';
import { colors } from '@shield-ui/styles';

const useStyles = makeStyles((theme) => ({
  target: {
    flexShrink: 0,
    borderRadius: 2,
    padding: theme.spacing(0.5, 0.75, 0.5, 1.75),
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    border: `1px solid transparent`,
    color: colors.hues.grays[30],
    '&:hover': {
      backgroundColor: colors.hues.grays[170],
    },
    minHeight: 40,
  },
  targetIsOpen: {
    backgroundColor: colors.hues.grays[190],
    borderColor: colors.hues.grays[180],
  },
  targetText: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  tinyLabel: {
    position: 'relative',
    top: -4,
    left: -8,
    height: 0,
    fontSize: 10,
    fontWeight: 300,
    color: colors.hues.grays[40],
    width: '110%',
    letterSpacing: '0.04rem',
    whiteSpace: 'nowrap',
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  hasActiveIndicator: {
    width: 5,
    height: 5,
    borderRadius: '50%',
    backgroundColor: colors.brand.primaryShade['60'],
    marginRight: 4,
    flexShrink: 0,
  },
  mainLabel: {
    display: 'flex',
    flexDirection: 'row',
  },
  label: {
    maxWidth: 240,
    fontSize: 16,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  labelValueDisplay: {
    fontWeight: 700,
    color: '#fff',
  },
  icon: {
    marginLeft: theme.spacing(0.5),
    fontSize: 18,
  },
}));

interface Props {
  // label displays more passive, a control without a value
  label: string;
  // valuePreview is bolder, stands out also informs the control to do things with the label (make it tiny)
  valuePreview?: string;
  onClick?: (evt) => void;
  onContextMenu?: (evt) => void;
  isOpen?: boolean;
  Icon?: React.ElementType;
}

export default function ControlBarLayoutPreview(props: Props) {
  const {
    label,
    valuePreview,
    onClick,
    isOpen = false,
    onContextMenu,
    Icon = DownArrow,
  } = props;
  const classes = useStyles();

  return (
    <div
      className={cx(classes.target, isOpen && classes.targetIsOpen)}
      onClick={onClick}
      onContextMenu={isOpen ? undefined : onContextMenu}
    >
      <div className={classes.targetText}>
        {valuePreview && label && (
          <div className={classes.tinyLabel}>
            <div className={classes.hasActiveIndicator} />
            {label}
          </div>
        )}
        <div className={classes.mainLabel}>
          <div
            className={cx(
              classes.label,
              valuePreview && classes.labelValueDisplay
            )}
          >
            {valuePreview || label}
          </div>
        </div>
      </div>
      <Icon className={classes.icon} />
    </div>
  );
}
