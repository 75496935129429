import { lighten, darken } from '@mui/material/styles';

const lightColorway = [
  '#948BF9',
  '#BB8BF9',
  '#F98BE7',
  '#F98B8E',
  '#F9B38B',
  '#F9D08B',
  '#F9EF8B',
  '#8BF9D1',
  '#8BDDF7',
  '#8BB6F7',
];

const primary = '#0D59F2';
const brand = {
  primary,
  primaryShade: {
    [10]: lighten(primary, 0.9),
    [20]: lighten(primary, 0.8),
    [30]: lighten(primary, 0.7),
    [40]: lighten(primary, 0.6),
    [50]: lighten(primary, 0.5),
    [60]: lighten(primary, 0.4),
    [70]: lighten(primary, 0.3),
    [80]: lighten(primary, 0.2),
    [90]: lighten(primary, 0.1),
    [120]: '#0D4BC6',
    [140]: darken(primary, 0.4),
    [150]: darken(primary, 0.5),
    [160]: darken(primary, 0.6),
    [170]: darken(primary, 0.7),
    [180]: darken(primary, 0.8),
    [190]: darken(primary, 0.9),
    [200]: darken(primary, 1),
  },
  secondary: '#9EBDFA',
  secondaryShade: {},
};

const gray = '#7887A5';
const red = '#F53D43';
const orange = '#F5B13D';
const yellow = '#F5E53D';
const green = '#3DF5B3';
const blue = primary;

const hues = {
  gray: '#7887A5',
  grays: {
    [5]: '#F8F9FA',
    [10]: lighten(gray, 0.9),
    [20]: lighten(gray, 0.8),
    [30]: lighten(gray, 0.7),
    [40]: lighten(gray, 0.6),
    [50]: lighten(gray, 0.5),
    [60]: lighten(gray, 0.4),
    [70]: lighten(gray, 0.3),
    [80]: lighten(gray, 0.2),
    [90]: lighten(gray, 0.1),
    [100]: gray,
    [110]: '#6c7c97',
    [120]: '#636f88',
    [130]: '#58647a',
    [140]: '#4e586b',
    [150]: '#434c5d',
    [160]: '#38404F',
    [170]: '#2e3540',
    [180]: '#232932',
    [190]: '#191D23',
    [200]: '#0E1115',
  },
  red,
  reds: {
    [80]: '#F76469',
    [120]: '#C43136',
  },
  orange,
  oranges: {
    [120]: darken(orange, 0.2),
  },
  yellow,
  yellows: {
    [120]: darken(yellow, 0.2),
  },
  green,
  greens: {
    110: darken(green, 0.1),
    120: darken(green, 0.2),
    130: darken(green, 0.3),
    140: darken(green, 0.4),
  },
  blue: brand.primary,
  blues: brand.primaryShade,
};

const selected = '#9EBDFA';

const colors = {
  brand: brand,
  hues: hues,
  semantic: {
    bodyBackground: '#0E1115',
    bodyText: '#F1F3F6',
    inputBackground: hues.grays[190],
    inputBorder: hues.grays[160],
    inputBorderHover: lighten(hues.gray, 0.6),
    inputBorderFocus: brand.primary,
    inputChipBackground: hues.grays[160],
    inputTextDisabled: 'rgba(255, 255, 255, 0.5)', //material-ui default, matching for now
    inputIcon: hues.gray,
    darkBackground: '#161718',
    fieldBackground: '#242628',
    linkColor: brand.primaryShade[70],
    linkColorHover: brand.primaryShade[60],
    alternateHeaderText: lighten(hues.gray, 0.8), // GRAY 20
    selected: selected,
    selectedHover: darken(selected, 0.2),
    error: hues.reds[80],
  },
  lightColorway,
};

export default colors;
