import React from 'react';
import { LayoutProps } from './common';
import { useDidMount } from '@shield-ui/hooks';

// This mounts just our individual component plain without any window dressing like the control bar or a form layout
export default function EmptyLayout({
  ControlElement,
  value,
  queryFilter,
  onForceUpdate,
}: LayoutProps) {
  useDidMount(() => {
    queryFilter.events.on('updatedControlData', onForceUpdate);
    queryFilter.ensureControlData(value);
    return () => {
      queryFilter.events.off('updatedControlData', onForceUpdate);
    };
  });

  return <>{ControlElement}</>;
}
