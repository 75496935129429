import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    background: 'rgba(255,255,255,0.1)'
  }
})

/* eslint-disable-next-line */
export interface EmptyCellProps {}

export const EmptyCell: React.FC<EmptyCellProps> = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {props.children}
    </div>
  );
}

export default EmptyCell;
