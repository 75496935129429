export default {
  COLLECTION_SYSTEM_DEFAULT_NAME: 'System Preset',
  COLLECTION_IS_PRIVATE_FOR_CREATOR_LABEL: 'Only Visible to You',
  COLLECTION_IS_PUBLICLY_EDITABLE: 'Anybody Can Edit',
  COLLECTION_NAME_LABEL: 'Name',
  COLLECTION_NAME_PLACEHOLDER:
    'Name this something human readable since these are shared',
  COLLECTION_DESCRIPTION_LABEL: 'Description',
  COLLECTION_DESCRIPTION_PLACEHOLDER:
    'A helpful description in text or markdown',
};
