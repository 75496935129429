import simpleObjectGetValue from '../common/simpleObjectGetValue';
import Cell, { parseRowResults } from './Cell';

const config = {
  columnKey: 'blockInfo',
  label: 'Hardware Info',
  description: 'Backpack, robot, and phone info aka hardware config',
  defaultWidth: 190,
  Cell,
  getValue: ({ row }) => simpleObjectGetValue(parseRowResults(row)),
  fragment: 'robotLogData',
};
export default config;
