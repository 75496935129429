import { useMutation, useQuery } from '@tanstack/react-query';

import {
  JavaScriptValue,
  Struct,
} from 'google-protobuf/google/protobuf/struct_pb';
import { queryClient } from '../queryClient';
import { Entity } from '@hmd/sdk/api/systems/entity/v1';
import {
  getSessionLogEntities,
  createEntityLink,
  createEntity,
  searchEntities,
  deleteEntityLink,
  updateEntity,
} from '../api/entities';
import { EntityJSON } from '../api/entities';
import { EntitySchemaAsObjectToEntitySchemaJSON } from './entitySchemas';

const EntityToEntityJSON = (entity: Entity): EntityJSON => {
  let entitySchema: any = null;
  if (entity.hasEntitySchema()) {
    entitySchema = EntitySchemaAsObjectToEntitySchemaJSON(
      entity.getEntitySchema()
    );
  }

  return {
    id: entity.getId(),
    entitySchemaSlug: entity.getEntitySchemaSlug(),
    properties: entity.getProperties().toJavaScript(),
    entitySchema,
  };
};

const SESSION_LOG_ENTITIES_QUERY_KEY = 'sessionLogEntities';

export const useSessionLogEntities = (sessionLogId: string) =>
  useQuery([SESSION_LOG_ENTITIES_QUERY_KEY, sessionLogId], async () => {
    const entities = await getSessionLogEntities(sessionLogId);

    return entities.getSessionLogEntityLinksList().map((entityLink) => {
      return {
        _id: entityLink.getId(),
        ...EntityToEntityJSON(entityLink.getEntity()),
      };
    });
  });

type SearchEntitiesByValueMutation = {
  slug: string;
  query: string;
};

type SearchEntitiesByValueParams = {
  onSuccess?: () => void;
  onError?: () => void;
};

export const useSearchEntitiesByValue = (
  options: SearchEntitiesByValueParams
) =>
  useMutation(
    async ({ slug, query }: SearchEntitiesByValueMutation) => {
      const entities = await searchEntities(slug, query);

      return entities.getEntitiesList().map((entity) => {
        return EntityToEntityJSON(entity);
      });
    },
    {
      onSuccess: () => {
        if (options?.onSuccess) options.onSuccess();
      },
      onError: () => {
        if (options?.onError) options.onError();
      },
    }
  );

export const useCreateEntityLink = ({ onSuccess, onError }) =>
  useMutation(
    async ({
      sessionLogId,
      entityId,
    }: {
      sessionLogId: string;
      entityId: string;
    }) => {
      const response = await createEntityLink(sessionLogId, entityId);

      return EntityToEntityJSON(response.getEntity());
    },
    {
      onSuccess: (_, { sessionLogId }) => {
        queryClient.invalidateQueries([
          SESSION_LOG_ENTITIES_QUERY_KEY,
          sessionLogId,
        ]);
        if (onSuccess) onSuccess();
      },
      onError: (error) => {
        if (onError) onError(error);
      },
    }
  );

type CreateEntityParams = {
  sessionLogId: string;
  entity: EntityJSON;
};

export const useCreateEntity = ({ onSuccess, onError }) =>
  useMutation(
    async ({ sessionLogId, entity }: CreateEntityParams) => {
      const response = await createEntity(entity);

      // link the entity to the session
      const res = await createEntityLink(sessionLogId, response.getId());

      return EntityToEntityJSON(res.getEntity());
    },
    {
      onSuccess: (_, { sessionLogId }) => {
        queryClient.invalidateQueries([
          SESSION_LOG_ENTITIES_QUERY_KEY,
          sessionLogId,
        ]);
        if (onSuccess) onSuccess();
      },
      onError: (error) => {
        if (onError) onError(error);
      },
    }
  );

export const useUpdateEntity = ({ onSuccess, onError }) =>
  useMutation(
    async (entity: EntityJSON) => {
      const response = await updateEntity(entity);

      return EntityToEntityJSON(response);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SESSION_LOG_ENTITIES_QUERY_KEY]);
        if (onSuccess) onSuccess();
      },
      onError: () => {
        if (onError) onError();
      },
    }
  );

export const useDeleteEntityLink = ({ onSuccess, onError }) =>
  useMutation(
    async (entityLinkId: string) => {
      const response = await deleteEntityLink(entityLinkId);

      return;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SESSION_LOG_ENTITIES_QUERY_KEY]);
        if (onSuccess) onSuccess();
      },
      onError: (error) => {
        if (onError) onError(error);
      },
    }
  );

const ALARIS_ENTITY_QUERY_KEY = 'alaris';

function getFetchAlarisEntityQueryClientParams() {
  return {
    queryKey: [ALARIS_ENTITY_QUERY_KEY],
    queryFn: async () => {
      const results = await searchEntities('alaris-flight-options', '');
      if (results.getEntitiesList().length > 0) {
        return results.getEntitiesList()[0];
      }
    },
  };
}

export const useAlarisEntity = () =>
  useQuery(getFetchAlarisEntityQueryClientParams());
