import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { showSuccessSnack, showErrorSnack } from '../redux/snackbar/actions';

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showSuccessSnack,
      showErrorSnack,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps);
