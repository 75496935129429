import { EsQuery, QFValues } from './filterTypes';

// Return all the esQueries as a simple array from all of the current values in a values object
function getValueQueries(values: QFValues): EsQuery[] {
  return !values
    ? []
    : Object.values(values).reduce((acc, val) => {
        if (val && val.esQuery) {
          acc.push(val.esQuery);
        }
        return acc;
      }, []);
}

// Simple test to see if any ESQueries exist in the values
export function hasAnyEsQueryValues(values: QFValues): boolean {
  return getValueQueries(values).length > 0;
}

// Build a full query from values, optionally layering on additional options
// Takes a set of values, plucks all the queries and stuffs them together in a bool
// query that says all conditions must be true
export function buildFullQueryFromValues(args: {
  values?: QFValues;
  additionalQueries?: EsQuery[];
}): EsQuery {
  const { values, additionalQueries = [] } = args;

  const valueQueries = getValueQueries(values);

  const allQueries =
    additionalQueries && additionalQueries.length
      ? valueQueries.concat(additionalQueries)
      : valueQueries;

  if (!allQueries.length) {
    return {
      match_all: {},
    };
  }

  return {
    bool: {
      filter: allQueries,
    },
  };
}
