import Cell from './Cell';
import _ from 'lodash';

const config = {
  columnKey: 'softwareInfo',
  label: 'Software Info',
  description: 'Version and branch info',
  Cell,
  sortKey: 'software_info.branch_name',
  getValue: ({ row }) => {
    const version = _.get(row, 'softwareInfo.version', '');
    const branchName = _.get(row, 'softwareInfo.branchName', '');

    if (version && branchName) {
      return `${version} -> ${branchName}`;
    }

    return version || branchName || '';
  },
  fragment: `
    softwareInfo {
        version
        branchName
    }
    `,
};

export default config;
