import _ from 'lodash';
import {
  MultiselectQueryFilter,
  NestedQueryFilter,
  ParentChildMultiselectQueryFilter,
} from '@shield-ui/query-filters';
import { getUserOptions } from '../../../services/users';
import { getTeamParentOptions } from '../../../services/teams';
import { getReviewStatusFilterOptions } from '../../../services/reviews';

export default new NestedQueryFilter({
  id: 'robotLogReviews',
  controlLabel: 'Reviews',
  esField: 'robot_log_reviews',
  notExistsControl: {
    enabled: true,
  },
  queryFilters: [
    new MultiselectQueryFilter({
      id: 'robotLogReviewsStatus',
      controlLabel: 'Status',
      esField: 'robot_log_reviews.status',
      unsetValueDisabled: true,
      behaviorConfig: {
        options: getReviewStatusFilterOptions(),
      },
    }),
    new MultiselectQueryFilter({
      id: 'robotLogReviewsAssignedBy',
      controlLabel: 'Assigned By',
      esField: 'robot_log_reviews.requester.id',
      unsetValueDisabled: true,
      behaviorConfig: {
        fetchOptions: getUserOptions,
      },
    }),
    new ParentChildMultiselectQueryFilter({
      id: 'robotLogReviewsAssignedTo',
      controlLabel: 'Assigned To',
      esFields: {
        parent: 'robot_log_reviews.reviewer_team.id',
        child: 'robot_log_reviews.reviewer.id',
      },
      fetchChildOptions: getUserOptions,
      fetchParentOptions: getTeamParentOptions,
    }),
  ],
});
