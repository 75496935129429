import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { resetFlightPage } from '../../redux/flight/actions';
import {
  getPageRobotLog,
  getPageRobotLogId,
} from '../../redux/flight/selectors';

function mapStateToProps(state) {
  return {
    pageRobotLog: getPageRobotLog(state),
    pageRobotLogId: getPageRobotLogId(state),
    backTo: state.flight.paging.backTo,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resetFlightPage,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps);
