import React from 'react';
import _ from 'lodash';
import { HangarTicketType, HangarTagType } from '@shield-ui/hangar-service';
import { IconButton, Link } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import CloseIcon from '@mui/icons-material/Close';
import TicketSearch from './TicketSearch';
import TicketName from './TicketName';
import TicketStatus from './TicketStatus';
import container, { ContainerProps } from './ticketsList';
import { createOrUpdateTicket } from '../../../services/tickets';

const styles = (theme) =>
  createStyles({
    container: {
      flex: 1,
      fontSize: 14,
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    headerTitle: {
      marginRight: theme.spacing(3),
      fontSize: 16,
      fontWeight: 300,
      letterSpacing: '1px',
      color: theme.palette.text.secondary,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      background: `rgba(0, 0, 0, .15)`,
      padding: theme.spacing(1, 2),
      alignItems: 'center',
      marginBottom: 1,
      borderRadius: theme.shape.borderRadius,
    },
    rowName: {
      flex: 1,
      margin: theme.spacing(0, 1.5),
    },
    linkTextContent: {
      color: '#fff',
    },
    empty: {
      textAlign: 'center',
      padding: 5,
      fontWeight: 100,
      fontSize: 12,
      color: '#eee',
    },
    linkButton: {
      margin: `0 8px 0 12px`,
    },
  });

export interface TicketKey {
  platform: string;
  platformId: string;
}

interface Props extends ContainerProps, WithStyles<typeof styles> {
  onChange: (e: { value: TicketKey[]; tickets: HangarTicketType[] }) => void;
  suggestionsTagId?: HangarTagType['id'];
  value: TicketKey[];
}

class TicketCollector extends React.Component<Props> {
  componentDidMount() {
    const { ensureTicketListItems, value } = this.props;

    ensureTicketListItems({
      ticketPlatformIds: value.map(v => v.platformId),
    });
  }

  getTickets(ticketKeys) {
    const { tickets } = this.props;
    return tickets.filter(ticket =>
      ticketKeys.find(key => 
        ticket.platform === key.platform && ticket.platformId === key.platformId
      )
    );
  }

  onRemove = (ticketIndex) => {
    const ticketKeys = this.props.value.filter(
      (t, i) => i !== ticketIndex
    );

    this.props.onChange({
      value: ticketKeys,
      tickets: this.getTickets(ticketKeys),
    });
  };

  onSelect = ({ ticket }) => {
    createOrUpdateTicket(_.omit(ticket, ['id']), (err, r) => {
      if (err) {
        return console.error(err);
      }
      const ticket: HangarTicketType = _.get(r, 'data.createOrUpdateTicket');

      const { value, setListItems } = this.props;
      if (ticket) {
        setListItems({
          listKey: 'tickets',
          items: [ticket],
        });
        const newValue = value.concat([{platform: ticket.platform, platformId: ticket.platformId}]);
        this.props.onChange({
          value: newValue,
          tickets: this.getTickets(newValue),
        });
      }
    });
  };

  render() {
    const { classes, suggestionsTagId, value } = this.props;

    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <TicketSearch
            isV2
            selectedTicketKeys={value}
            suggestionsTagId={suggestionsTagId}
            onSelect={this.onSelect}
          />
        </div>
        <div>
          {this.getTickets(value).map((row: HangarTicketType, i) => (
            <div className={classes.row} key={`${row.platform}/${row.platformId}`}>
              <IconButton
                size="small"
                onClick={() => {
                  this.onRemove(i);
                }}
              >
                <CloseIcon style={{ fontSize: 18 }} />
              </IconButton>
              <div className={classes.rowName}>
                <TicketName name={row.name} />
              </div>
              <TicketStatus status={row.status} />
              <Link
                href={row.link}
                target="_blank"
                className={classes.linkButton}
                underline="hover">
                View
              </Link>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default container(withStyles(styles)(TicketCollector));
