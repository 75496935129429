import { MultiselectQueryFilter, NestedQueryFilter, ValueRangeQueryFilter } from '@shield-ui/query-filters';
import { getTestFrequencyGroupOptions} from "../../../services/testDefinitions";

export const frequencyGroups = new MultiselectQueryFilter({
  id: 'testDefinitionFrequencyGroups',
  controlLabel: 'Names',
  esField: 'test_definition.test_frequency_group_details.test_frequency_group.id',
  description: '',
  isMulti: true,
  behaviorConfig: {
    fetchOptions: getTestFrequencyGroupOptions
  },
});

export default new NestedQueryFilter({
  id: 'testDefinitionFrequencyGroupDetails',
  controlLabel: 'Test Frequency Groups',
  esField: 'test_definition.test_frequency_group_details',
  queryFilters: [
    frequencyGroups,
    new ValueRangeQueryFilter({
      id: 'testDefinitionFrequencyGroupMinimumExecutionCount',
      controlLabel: "Minimum Executions",
      esField: 'test_definition.test_frequency_group_details.minimum_execution_count',
      minThreshold: 0,
      maxThreshold: 100,
      numberOfInBetweenMarksToDisplay: 9,
    }),
    new ValueRangeQueryFilter({
      id: 'testDefinitionFrequencyGroupPreferredExecutionCount',
      controlLabel: "Preferred Executions",
      esField: 'test_definition.test_frequency_group_details.preferred_execution_count',
      minThreshold: 0,
      maxThreshold: 100,
      numberOfInBetweenMarksToDisplay: 9,
    }),
  ]
});
