import _ from 'lodash';
import { Radio } from 'antd';
import { DateTypes } from '../common';
import { getDateAxisLabels } from '../utils';
import { LabelIdType, LabelType } from '@shield-ui/heatgrid';
import { DatetimeQueryFilterValue } from '@shield-ui/query-filters';
import { default as startTimeQueryFilter } from '../../../lib/queryFilters/robotLog/startTime';
import {
  AxisDefinition,
  AxisOption,
  RenderDropdownFormProps,
} from './AxisDefinition';

export const dateHistogramAxisDefinition: AxisDefinition = {
  id: 'DateHistogram',
  getDropdownLabel: (axisOption?: AxisOption) => {
    if (axisOption && axisOption.dateType) {
      return `Date Histogram - by ${axisOption.dateType}`;
    }
    return 'Date Histogram';
  },
  buildAggsDsl: (axisOption?: AxisOption) => {
    switch (axisOption.dateType) {
      case DateTypes.Week:
        return {
          date_histogram: {
            field: 'start_time',
            calendar_interval: '1w',
            missing: '1970-01-01',
            min_doc_count: 1,
          },
        };
      case DateTypes.Month:
        return {
          date_histogram: {
            field: 'start_time',
            calendar_interval: '1M',
            missing: '1970-01-01',
            min_doc_count: 1,
          },
        };
    }
    return {
      date_histogram: {
        field: 'start_time',
        fixed_interval: '1d',
        missing: '1970-01-01',
        min_doc_count: 1,
      },
    };
  },
  prepareAxisLabels: (labelIds: LabelIdType[], axisOption: AxisOption) => {
    return new Promise<LabelType[]>((resolve) => {
      resolve(getDateAxisLabels(labelIds, axisOption.dateType));
    });
  },
  buildQueryFilter: (
    filterValues: object,
    selectedLabels: LabelType[],
    axisOption?: AxisOption
  ) => {
    // Find the minimum and maximum start times among the selected labels
    const minStartDate = new Date(
      _.minBy(
        selectedLabels.filter((label) => label.label !== '-'),
        (label) => label.id
      ).id
    );
    const maxStartDate = new Date(
      _.maxBy(
        selectedLabels.filter((label) => label.label !== '-'),
        (label) => label.id
      ).id
    );

    // Compute the maximum end time based on the axis option
    const maxEndDate = new Date(maxStartDate);
    switch (axisOption.dateType) {
      case DateTypes.Day:
        maxEndDate.setUTCDate(maxStartDate.getUTCDate() + 1);
        break;
      case DateTypes.Week:
        maxEndDate.setUTCDate(maxStartDate.getUTCDate() + 7);
        break;
      case DateTypes.Month:
        maxEndDate.setUTCMonth(maxStartDate.getUTCMonth() + 1);
        break;
    }

    // build the QF value.
    const newStartTimeValues: DatetimeQueryFilterValue = {
      startDatetime: minStartDate.toISOString(),
      endDatetime: maxEndDate.toISOString(),
    };

    newStartTimeValues.esQuery =
      startTimeQueryFilter.getElasticQuery(newStartTimeValues);

    // set the new value for this and return any other existing filter values
    return {
      ...filterValues,
      [startTimeQueryFilter.getId()]: newStartTimeValues,
    };
  },
  defaultAxisOption: { dateType: DateTypes.Day },
  renderForm: (p: RenderDropdownFormProps) => {
    return (
      <Radio.Group key={'dates'} value={p.axisOption?.dateType}>
        {Object.keys(DateTypes).map((dateType: DateTypes) => (
          <Radio.Button
            key={`date-${dateType}`}
            value={dateType}
            onClick={() => p.onChange({ dateType })}
          >
            {dateType}
          </Radio.Button>
        ))}
      </Radio.Group>
    );
  },
};
