import React from 'react';
import Link from "@mui/material/Link";
import DropPage from './DropPage';
import UploadTarget from './UploadTarget';

export interface DropPageTargetInputProps {
  onFiles: (files, event) => void;
  onError: (errorLike) => void;
}

export default function DropPageTargetInput(props:DropPageTargetInputProps) {
  const { onFiles, onError } = props;

  return (
    <DropPage onFiles={onFiles} onError={onError}>
      <div style={{height: 200}}>
        <UploadTarget
          onFiles={onFiles}
          prompt={
            <div>
              Drag and drop or <Link underline="hover"> browse files</Link>
            </div>
          }
        />
      </div>
    </DropPage>
  );
}
