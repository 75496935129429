import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { pageToFlight } from '../../../redux/flight/actions';
import { getPagingIsValid } from '../../../redux/flight/selectors';

function mapStateToProps(state) {
  return {
    ...state.flight.paging,
    pagingIsValid: getPagingIsValid(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      pageToFlight,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps);
