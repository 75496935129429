import { GroupQueryFilter } from '@shield-ui/query-filters';
import { TagType } from '@hmd/sdk/api/tests/definitions/v1';
import { getTagQueryFilterItem } from '../../../routes/TestDefinitions/queryFilters';

export const componentTags = getTagQueryFilterItem(
  'Component Tags',
  'testDefinition.component_tags',
  TagType.COMPONENT,
  'test_definition.component_tags'
).queryFilter;
export const equipmentTags = getTagQueryFilterItem(
  'Equipment Tags',
  'testDefinition.equipment_tags',
  TagType.EQUIPMENT,
  'test_definition.equipment_tags'
).queryFilter;
export const environmentalTags = getTagQueryFilterItem(
  'Environmental Tags',
  'testDefinition.environmental_tags',
  TagType.ENVIRONMENTAL,
  'test_definition.environmental_tags'
).queryFilter;
export const generalTags = getTagQueryFilterItem(
  'General Tags',
  'testDefinition.general_tags',
  TagType.GENERAL,
  'test_definition.general_tags'
).queryFilter;

export default new GroupQueryFilter({
  id: 'testDefinitionTags',
  controlLabel: 'Test Tags',
  queryFilters: [componentTags, equipmentTags, environmentalTags, generalTags],
});
