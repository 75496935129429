import { createReducer } from 'redux-nano';
import { SET_HOUR_12 } from './actions';

const initialState = {
  hour12: false,
};

export default createReducer(initialState, {
  [SET_HOUR_12]: (state, { payload: hour12 }) => {
    return {
      ...state,
      hour12,
    };
  },
});
