import _ from 'lodash';
import client from '../apollo-client';
import { gql } from '@apollo/client';

const omnisearchQuery = gql`
  query omnisearch($inputValue: String) {
    omnisearch(inputValue: $inputValue) {
      robotLogs {
        name
        id
        sessionLogTypeSlug
        displayName
      }
      collections {
        name
        id
      }
    }
  }
`;

//search collections and robotLogs by name
export const getSearchResults = (input, limit = 10) => {
  return client
    .query({
      query: omnisearchQuery,
      variables: { inputValue: input },
      fetchPolicy: 'network-only',
    })
    .then((r) => {
      let omnisearchLogs = _.get(r, 'data.omnisearch.robotLogs');
      let omnisearchCollections = _.get(r, 'data.omnisearch.collections');
      if (!omnisearchLogs) {
        throw new Error(`Could not find robot logs`);
      }
      if (!omnisearchCollections) {
        throw new Error(`Could not find collections`);
      }

      if (omnisearchLogs.length > limit) {
        omnisearchLogs = omnisearchLogs.slice(0, limit);
      }
      if (omnisearchCollections.length > limit) {
        omnisearchCollections = omnisearchCollections.slice(0, limit);
      }

      return { robotLogs: omnisearchLogs, collections: omnisearchCollections };
    });
};
