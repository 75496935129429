import React from 'react';
import _ from 'lodash';
import cx from 'classnames';
import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from '@shield-ui/styles';
import { HangarUserType, HangarTeamType } from '@shield-ui/hangar-service';
import Typography from '@mui/material/Typography';
import ShieldAvatar from '../../users/ShieldAvatar';
import ExpandIcon from './ExpandIcon';
import Collapse from '@mui/material/Collapse';
import { Grow } from '@shield-ui/core';
import { Stethoscope } from '@shield-ui/icons';
import { onEventStopIfUserSelection } from '@shield-ui/utils';
import { Link } from 'react-router-dom';

interface Props {
  label?: string;
  isExpanded?: boolean;
  onClick?: (evt) => void;
  count?: number;
  children?: React.ReactNode;
  avatarUser?: HangarUserType;
  avatarTeam?: Pick<HangarTeamType, 'name'>;
  avatarIconType?: 'diagnostic';
  EndNode?: React.ReactNode;
  isCurrentUserRow?: boolean;
  flightLabel? : string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(1),
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    // total element is 56 or 7 theme elements high
    height: theme.spacing(7),
    padding: theme.spacing(0.25, 2, 0.25, 1),
    background: colors.hues.grays[190],
    transition: '0.15s ease',
  },
  rowContainerClickable: {
    cursor: 'pointer',
    '&:hover': {
      background: colors.hues.grays[180],
    },
  },
  rowBackgroundHidden: {
    background: 'transparent',
  },
  children: {
    margin: theme.spacing(1),
  },
  count: {
    width: 16,
    height: 16,
    backgroundColor: colors.hues.grays[160],
    fontSize: 10,
    color: colors.hues.grays[20],
    lineHeight: 12 / 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    marginRight: theme.spacing(1),
  },
  label: {
    color: colors.hues.grays[30],
  },
  labelCurrentUser: {
    color: colors.hues.grays[20],
    fontWeight: 700,
  },
  flightLabel:{
    color: colors.hues.grays[30],
    fontSize: 16,
    fontWeight:500
  },
  expandStatusContainer: {
    width: 32,
    marginRight: theme.spacing(1),
    opacity: 1,
    transition: '0.15s ease',
  },
  expandStatusContainerHidden: {
    opacity: 0,
  },
  avatar: {
    width: 32,
    height: 32,
    marginRight: theme.spacing(2),
  },
  avatarIcon: {
    padding: 4,
    borderRadius: '50%',
    backgroundColor: alpha(colors.hues.red, 0.2),
  },
}));

function ParentRow(props: Props) {
  const classes = useStyles();
  const {
    isExpanded,
    flightLabel,
    label,
    count = 0,
    avatarUser,
    avatarTeam,
    avatarIconType,
    children,
    onClick,
    EndNode,
    isCurrentUserRow,
  } = props;

  let hideExpandIcon = false;
  let hideBackground = false;
  if (
    isCurrentUserRow &&
    count === 0 &&
    _.compact(React.Children.toArray(children)).length === 0
  ) {
    hideExpandIcon = true;
    hideBackground = true;
  }

  let Avatar;
  if (avatarUser) {
    Avatar = <ShieldAvatar user={avatarUser} className={classes.avatar} />;
  } else if (avatarTeam) {
    Avatar = <ShieldAvatar team={avatarTeam} className={classes.avatar} />;
  } else if (avatarIconType === 'diagnostic') {
    Avatar = <Stethoscope className={cx(classes.avatar, classes.avatarIcon)} />;
  }

  return (
    <div className={classes.container}>
      <div
        className={cx(
          classes.rowContainer,
          onClick && classes.rowContainerClickable,
          hideBackground && classes.rowBackgroundHidden
        )}
        onClick={onEventStopIfUserSelection(onClick)}
      >
        <ExpandIcon
          isExpanded={isExpanded}
          className={cx(
            classes.expandStatusContainer,
            hideExpandIcon && classes.expandStatusContainerHidden
          )}
        />
        {Avatar}

        {count > 0 && <div className={classes.count}>{count}</div>}
          {(label && !flightLabel) && 
          <Typography
            variant={isCurrentUserRow ? 'h6' : 'body1'}
            className={cx(
              classes.label,
              isCurrentUserRow && classes.labelCurrentUser
            )}
          >
            {label}
          </Typography>
          }
          {(flightLabel && !label) && 
            <Link
              className={classes.flightLabel}
              to = {`${flightLabel}`}
            >
              {flightLabel}
            </Link>
            

          }
        <Grow />
        {EndNode}
      </div>
      <Collapse in={isExpanded} timeout={150}>
        <div className={classes.children}>{children}</div>
      </Collapse>
    </div>
  );
}

export default ParentRow;
