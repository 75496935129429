import { ValueRangeQueryFilter } from '@shield-ui/query-filters';
import { abbrevFormatDuration } from '@shield-ui/utils';

export default new ValueRangeQueryFilter({
  id: 'duration',
  controlLabel: 'Duration',
  esField: 'duration_seconds',
  maxThreshold: 20 * 60,
  formatValue: (v) => abbrevFormatDuration(Math.floor(v)),
});
