import React from 'react';
import { InputNumber, InputNumberProps } from 'antd';
import { abbrevFormatDuration } from "@shield-ui/utils";

interface Props extends InputNumberProps {
  value?: number,
  isPreview?: boolean;
}
export default function Duration(props:Props) {
  const { value, isPreview, ...rest} = props;

  if (isPreview) {
    if (!value) { return null; }
    return <>{abbrevFormatDuration(value)}</>
  }

  return (
    <div>
      <InputNumber
        min={0}
        max={60 * 60 * 24}
        value={value}
        {...rest}
      />
      {value > 0 && " " + abbrevFormatDuration(value)}
    </div>
  )
}
