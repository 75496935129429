import { useRef, useEffect } from 'react';

/**
 * useEffect API but does not execute the fn the first time the effect would run (e.g. initial mount)
 */
export function useEffectSkipFirst(fn: () => void, args: any[]) {
  const hasRenderedOnce = useRef(false);

  useEffect(() => {
    if (hasRenderedOnce.current) {
      return fn();
    } else {
      hasRenderedOnce.current = true;
    }
  }, args)
}
