import React from 'react';
import { gql, useQuery } from '@apollo/client';
import memoizeOne from 'memoize-one';

const query = gql`
  query ($robotLogId: String!) {
    robotLog(id: $robotLogId) {
      id
      sessionLogTypeSlug
      name
      displayName
      startTime
      endTime
      totalTime
      createdAt
      robotLogData
      environmentId
      robotLogTestId
      robot {
        id
        name
        hostname
      }
      softwareInfo {
        version
        branchName
        commitHash
      }
      phone {
        id
        name
        serialNumber
      }
      phoneSoftware {
        id
        version
      }
      participants {
        edges {
          node {
            id
            participantRole
            robotLogId
            userFreeform
            userId
          }
        }
      }
    }
  }
`;

const getRobotLogDataJson = memoizeOne((robotLogDataString) => {
  try {
    return JSON.parse(robotLogDataString);
  } catch (e) {
    console.warn('Problem parsing robot log data', e);
    return {};
  }
});

// Hmm, i thought apollo would dedeupe but it isn't working
// cache-only is default and only overloaded by a single invocation
// they all share the data, but only one guy fetches
export default function useRobotLogCommonPropsQuery(
  robotLogId,
  fetchPolicy = 'cache-only'
) {
  const { loading, data, ...rest } = useQuery(query, {
    variables: { robotLogId },
    fetchPolicy,
  });

  let robotLogDataParsed;
  if (!loading && data) {
    robotLogDataParsed = getRobotLogDataJson(data.robotLog.robotLogData);
  }

  return {
    loading,
    data,
    robotLogDataParsed,
    ...rest,
  };
}
