import store from "../redux/store";
import { showErrorSnack, showSuccessSnack } from "../redux/snackbar/actions";

let CONFIRM_COMPONENT;

// This is a pseudo-provider api, when this component mounts (part of app frame)
// it registers itself so we can call methods on it
export function registerConfirmPromptComponent(Component) {
  CONFIRM_COMPONENT = Component;
}

export function unsetConfirmPromptComponent() {
  CONFIRM_COMPONENT = undefined;
}

/**
 * @param updateProps
 * @param updateProps.title
 * @param updateProps.body
 * @param updateProps.onConfirm
 * @param updateProps.confirmLabel
 */
type ShowConfirmPromptProps = {
  title?: string;
  body?: string;
  onConfirm: () => void;
  confirmLabel?: string;
};
export function showConfirmPrompt(updateProps: ShowConfirmPromptProps) {
  if (CONFIRM_COMPONENT) {
    CONFIRM_COMPONENT.handleRequest(updateProps);
  }
}

// Generic error snack
export function showError(e:string|{message:string}|{statusMessage:string}|Error) {
  store.dispatch(showErrorSnack(e));
}
// Generic success snack
export function showSuccess(m:string|{message:string}) {
  store.dispatch(showSuccessSnack(m));
}
