import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { Grow } from '@shield-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0.5, 1),
    display: 'flex',
    marginBottom: theme.spacing(1),
    border: `1px solid rgba(255, 255, 255, 0.1)`,
    borderRadius: theme.shape.borderRadius,
    alignItems: 'center',
    fontSize: 12,
    fontWeight: 300,
    cursor: 'pointer',
    height: 50,
    color: theme.palette.secondary.main,
  },
  label: {
    margin: theme.spacing(0, 1),
  },
}));

export default function ViewerHeader(props) {
  const { label, goBack, EndNode } = props;
  const classes = useStyles();

  return (
    <div onClick={goBack} className={classes.container}>
      <KeyboardReturnIcon />
      <div className={classes.label}>{label}</div>
      <Grow />
      {EndNode}
    </div>
  );
}
