import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  componentOptionsSelector,
  componentOwnerOptionsSelector,
  componentOwnerParentOptionsSelector,
} from '../../../redux/lists/selectors';
import { ensureComponents } from '../../../redux/lists/actions';

function mapStateToProps(state) {
  return {
    componentOptions: componentOptionsSelector(state),
    componentOwnerOptions: componentOwnerOptionsSelector(state),
    componentOwnerParentOptions: componentOwnerParentOptionsSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ensureComponents,
    },
    dispatch
  );
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ContainerProps = ConnectedProps<typeof connector>;
export default connector;
