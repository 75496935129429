import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cx from 'classnames';
import withStyles from '@mui/styles/withStyles';
import InboxIcon from '@mui/icons-material/Inbox';
import CheckCircle from '@mui/icons-material/CheckCircle';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import AvTimer from '@mui/icons-material/AvTimer';
import {
  REVIEW_STATUSES,
  REVIEW_STATUS_DISPLAYS,
} from '../../services/reviews';
import { reviewStatusColors } from '../../lib/sharedStyles';

const styles = () => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
  },
  icon: {
    fontSize: '1.4em',
    marginRight: 8,
  },
  ..._.reduce(
    reviewStatusColors,
    (acc, value, key) => {
      acc[key] = {
        color: value,
      };
      return acc;
    },
    {}
  ),
});

function getIcon(reviewStatus, classes) {
  switch (reviewStatus) {
    case REVIEW_STATUSES.NOT_STARTED:
      return <InboxIcon className={classes.icon} />;
    case REVIEW_STATUSES.IN_PROGRESS:
      return (
        <AvTimer
          className={classes.icon}
          variant="indeterminate"
          thickness={4}
        />
      );
    case REVIEW_STATUSES.COMPLETE:
      return <CheckCircleOutline className={classes.icon} />;
    case REVIEW_STATUSES.APPROVED:
      return <CheckCircle className={classes.icon} />;
  }
}

function ReviewStatusDisplay({
  reviewStatus,
  classes,
  className,
  style = undefined,
}) {
  const statusText = REVIEW_STATUS_DISPLAYS[reviewStatus] || reviewStatus;

  return (
    <span
      className={cx(classes.container, classes[reviewStatus], className)}
      style={style}
    >
      {getIcon(reviewStatus, classes)}
      <span>{statusText}</span>
    </span>
  );
}
ReviewStatusDisplay.propTypes = {
  reviewStatus: PropTypes.oneOf(Object.keys(REVIEW_STATUSES)).isRequired,
};

export default withStyles(styles)(ReviewStatusDisplay);
