import React from 'react';
import _ from 'lodash';
import {
  useRequirementGroupedOptions,
  useRequirementProjectPreviews,
} from '../../../services/requirements';
import { Select, SelectProps } from 'antd';
import { Link } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import RequirementsTable from './RequirementsTable';
import { colors } from '@shield-ui/styles';
import { stopEventPropagation } from '@shield-ui/utils';
import { OrganismExpander } from '@shield-ui/controls';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  previewProject: {
    color: colors.hues.grays[40],
    fontSize: 10.5,
  },
  previewRequirement: {
    //marginLeft: theme.spacing(1),
    fontSize: 11,
    '&:hover > a': {
      display: 'inline-block',
    },
  },
  previewLink: {
    display: 'none',
    marginLeft: theme.spacing(0.5),
    fontSize: 11,
  },
}));

interface Props extends SelectProps {
  isPreview?: boolean;
  value?: string[];
  onChange?: (value: string[]) => void;
}

export default function RequirementsMultiSelect(props: Props) {
  const groups = useRequirementGroupedOptions();
  const previews = useRequirementProjectPreviews();
  const classes = useStyles();
  const { onChange, value, isPreview, ...rest } = props;

  // TODO - this preview code is a bit hacky and could used cleaned up
  // going fast for MVP
  if (isPreview) {
    if (!previews.length || !value || !value.length) {
      return null;
    }

    const visible = previews.filter((p) => value.includes(p.id));
    const previewGroups = _.groupBy(visible, 'projectName');

    return (
      <div>
        {Object.keys(previewGroups).map((projectName) => (
          <div key={projectName}>
            <div className={classes.previewProject}>{projectName}</div>
            {previewGroups[projectName].map((preview) => {
              return (
                <div key={preview.name} className={classes.previewRequirement}>
                  {preview.name}
                  {preview.link && (
                    <Link
                      className={classes.previewLink}
                      target="_blank"
                      onClick={stopEventPropagation}
                      href={preview.link}
                      underline="hover">
                      view
                    </Link>
                  )}
                </div>
              );
            })}
          </div>
        ))}
      </div>
    );
  }

  return (
    <OrganismExpander
      control={
        <Select
          mode="multiple"
          onChange={onChange}
          value={value}
          {...rest}
          optionFilterProp="label"
        >
          {groups.map((group) => (
            <Select.OptGroup key={group.groupLabel} label={group.groupLabel}>
              {group.options.map((opt) => (
                <Select.Option key={opt.value} value={opt.value} label={opt.label}>
                  {opt.label}
                </Select.Option>
              ))}
            </Select.OptGroup>
          ))}
        </Select>
      }
      fullTitle={"Requirements"}
      full={
        <RequirementsTable
          selected={value}
          onRowSelect={onChange}
        />
      }
    />
  );
}
