import React from 'react';
import _ from 'lodash';
import async from 'async';
import memoizeOne from 'memoize-one';
import { ControlBar } from '@shield-ui/query-filters';
import { getCachedItem, setCachedItem } from '@shield-ui/utils';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Tooltip from '@mui/material/Tooltip';
import withStyles from '@mui/styles/withStyles';
import FilterIcon from '@mui/icons-material/FilterList';
import ColumnIcon from '@mui/icons-material/ViewColumn';
import ShareIcon from '@mui/icons-material/Link';
import ExportIcon from '@mui/icons-material/CloudDownload';
import { copyToClipboard } from '@shield-ui/utils';
import MoreMenu from '../../MoreMenu';
import ColumnConfig from './controls/ColumnConfigModal';
import ExportResults from './controls/ExportResultsModal';
import FilterChipBuilder from './controls/FilterChipBuilder';
import LoadSaveStateModal from './controls/LoadSaveStateModal';
import { TABLE_MODAL_TYPES } from '../../../redux/tables/constants';
import { functionalColors } from '../../../lib/sharedStyles';
import { filterListForDeprecatedFilters } from '../../../lib/queryFilters/robotLog';

function styles(theme) {
  return {
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginBottom: 3,
    },
    chipsContainer: {
      flex: 1,
      marginRight: 10,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    filtersOn: {
      backgroundColor: functionalColors.filters.lightest,
      '&:hover': {
        backgroundColor: functionalColors.filters.light,
      },
    },
    filtersIcon: {
      marginRight: 10,
      fontSize: 20,
    },
    moreButton: {
      marginLeft: theme.spacing(2),
    },
  };
}

class GenericTableControls extends React.PureComponent {
  state = {
    controlBarVisibleIds: undefined,
  };
  constructor(props) {
    super(props);

    if (props.controlBar) {
      this.state.controlBarVisibleIds =
        filterListForDeprecatedFilters(
          getCachedItem(this.getQueryFilterIdsCacheKey())
        ) || props.controlBar.defaultQueryFilterIds;
    }
  }

  filterDeprecatedFilters(queryFilters) {
    if (queryFilters) {
      const filtered = queryFilters.filter((q) => {
        return !deprecatedFilterKeys.includes(q);
      });
      setCachedItem(this.getQueryFilterIdsCacheKey(), filtered);
      return filtered;
    }
    return queryFilters;
  }

  getControlBarManagementProps = memoizeOne((ids, defaultIds) => {
    return {
      ids,
      categoryOrder: this.props.controlBar.categoryOrder,
      setIds: this.setControlBarQueryFilterIds,
      defaultIds,
    };
  });

  getQueryFilterIdsCacheKey() {
    const { tableCacheKey } = this.props;
    return `${tableCacheKey}~queryFilterIds`;
  }

  setControlBarQueryFilterIds = (ids) => {
    this.setState(
      {
        controlBarVisibleIds: ids,
      },
      () => {
        setCachedItem(this.getQueryFilterIdsCacheKey(), ids);
      }
    );
  };

  hideModal = () => {
    const { setVisibleModal, tableCacheKey } = this.props;
    setVisibleModal({ tableCacheKey, visibleControlsModal: undefined });
  };

  onColumnConfig = () => {
    const { setVisibleModal, tableCacheKey } = this.props;
    setVisibleModal({
      tableCacheKey,
      visibleControlsModal: TABLE_MODAL_TYPES.columnConfig,
    });
  };

  onExport = () => {
    const { setVisibleModal, tableCacheKey } = this.props;
    setVisibleModal({
      tableCacheKey,
      visibleControlsModal: TABLE_MODAL_TYPES.exportResults,
    });
  };

  onShowFilters = () => {
    const { tableCacheKey, toggleFiltersPanel } = this.props;

    toggleFiltersPanel({ tableCacheKey, isVisible: true });
  };

  onGetShareUrl = () => {
    const { tableCacheKey, getShareUrl, showSuccessSnack } = this.props;

    async.waterfall(
      [
        (callback) => getShareUrl({ callback, tableCacheKey }),
        ({ url }, callback) => copyToClipboard(url, callback),
      ],
      (err) => {
        if (err) {
          return console.error(err);
        }
        showSuccessSnack({ message: 'Share state copied to clipboard' });
      }
    );
  };

  renderColumnConfig() {
    return (
      <ColumnConfig
        {..._.omit(this.props, ['classes'])}
        hideModal={this.hideModal}
      />
    );
  }

  renderExportResults() {
    return <ExportResults {...this.props} hideModal={this.hideModal} />;
  }

  getMenuItems() {
    const { result, controlBar } = this.props;

    const extra = ((controlBar && controlBar.extraMenuItems) || []).map(
      (item) => {
        return {
          ...item,
          onClick: () => {
            item.onClick(this.props);
          },
        };
      }
    );

    return [
      {
        Icon: ColumnIcon,
        label: 'Configure Columns',
        onClick: this.onColumnConfig,
      },
      {
        Icon: ExportIcon,
        label: 'Export Table Results',
        onClick: this.onExport,
        disabled: result.isLoading || result.total === 0,
      },
      {
        Icon: ShareIcon,
        label: 'Copy Share Link',
        onClick: this.onGetShareUrl,
      },
    ].concat(extra);
  }

  renderControlBar() {
    const { controlBar, filtersVariables, setFilters, resetFilters } =
      this.props;
    const { queryFilterItems, defaultQueryFilterIds } = controlBar;

    return (
      <ControlBar
        queryFilterItems={queryFilterItems}
        values={filtersVariables}
        setValues={setFilters}
        resetValues={resetFilters}
        management={this.getControlBarManagementProps(
          this.state.controlBarVisibleIds,
          defaultQueryFilterIds
        )}
        afterMenuItems={this.getMenuItems()}
      />
    );
  }

  renderControls() {
    const {
      classes,
      visibleControlsModal,
      implementsFiltersPanel,
      hasAnyFilterSet,
      filtersVariables,
    } = this.props;

    return (
      <div className={classes.wrapper}>
        <div className={classes.chipsContainer}>
          {implementsFiltersPanel && (
            <FilterChipBuilder
              filtersVariables={filtersVariables}
              onChangeVariables={this.props.setFilters}
              onShowAll={this.onShowFilters}
            />
          )}
        </div>
        {implementsFiltersPanel && (
          <Tooltip
            title={
              hasAnyFilterSet
                ? 'Filtering table results'
                : 'Add filters to table results'
            }
          >
            <Button
              size="small"
              color={hasAnyFilterSet ? 'secondary' : undefined}
              variant={hasAnyFilterSet ? 'contained' : undefined}
              onClick={this.onShowFilters}
              disabled={!!visibleControlsModal}
            >
              <FilterIcon className={classes.filtersIcon} fontSize="inherit" />
              Filters
            </Button>
          </Tooltip>
        )}
        <MoreMenu
          buttonProps={{
            className: classes.moreButton,
          }}
          menuItems={this.getMenuItems()}
        />
      </div>
    );
  }

  render() {
    const { classes, visibleControlsModal, tableCacheKey } = this.props;
    return (
      <React.Fragment>
        {this.props.controlBar
          ? this.renderControlBar()
          : this.renderControls()}
        <Modal
          open={!!visibleControlsModal}
          onClose={this.hideModal}
          className={classes.modal}
        >
          <React.Fragment>
            {visibleControlsModal === TABLE_MODAL_TYPES.columnConfig &&
              this.renderColumnConfig()}
            {visibleControlsModal === TABLE_MODAL_TYPES.exportResults &&
              this.renderExportResults()}
            {visibleControlsModal === TABLE_MODAL_TYPES.promptSaveState && (
              <LoadSaveStateModal
                tableCacheKey={tableCacheKey}
                hideModal={this.hideModal}
              />
            )}
          </React.Fragment>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(GenericTableControls);
