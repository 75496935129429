import React, { useState } from 'react';
import _ from 'lodash';
import { lighten } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from '@shield-ui/styles';
import { FlatLogEventObjectMerged } from '../../../services/logEvents';
import ExpandIcon from './ExpandIcon';
import { onEventStopIfUserSelection } from '@shield-ui/utils';
import { prettifyString } from '@shield-ui/utils';

type Props = {
  logEvent: FlatLogEventObjectMerged;
};

const useStyles = makeStyles((theme) => ({
  container: {
    //fontFamily: 'monospace',
    fontSize: 12,
  },
  tierContainer: {
    padding: theme.spacing(0.75, 0.25),
    cursor: 'pointer',
    color: '#fff',
    '&:hover': {
      color: lighten(colors.hues.yellow, 0.7),
      //backgroundColor: 'rgba(255, 255, 255, 0.05)',
    },
  },
  briefMain: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  briefTextWrapper: {
    marginLeft: theme.spacing(0.5),
  },
  briefContext: {
    fontWeight: 600,
    fontSize: '1.1em',
  },
  briefMessage: {
    paddingLeft: theme.spacing(0.5),
  },
  details: {
    margin: theme.spacing(0.5, 0, 1, 5),
  },
  detailRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(0.5),
  },
  detailDisplay: {
    minWidth: 90,
    fontWeight: 600,
    marginRight: theme.spacing(1),
  },
  detailValue: {
    opacity: 0.9,
    wordBreak: 'break-word',
  },
  children: {
    marginLeft: theme.spacing(1.5),
  },
}));

function getDetails(logEvent) {
  const rows = ['file', 'operation', 'line'].map((key) => {
    return {
      display: _.capitalize(key),
      value: logEvent[key],
    };
  });

  if (logEvent.logDefinitionDescription) {
    rows.unshift({
      display: 'Log Definition Description',
      value: logEvent.logDefinitionDescription,
    });
  }

  rows.push(
    {
      display: 'Level',
      value: logEvent.level,
    },
    {
      display: 'Timestamp',
      value: `${logEvent.timestampTime.seconds}.${logEvent.timestampTime.nanos}`,
    }
  );

  rows.unshift({
    display: 'Owner',
    value: logEvent.componentOwner,
  });
  return rows;
}

function LogEventTree({ logEvent }: Props) {
  const [isExpanded, setIsExpanded] = useState(logEvent.logEvents.length === 0);
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div
        className={classes.tierContainer}
        onClick={onEventStopIfUserSelection((evt) => {
          evt.stopPropagation();
          evt.preventDefault();
          setIsExpanded(!isExpanded);
        })}
      >
        <div className={classes.briefMain}>
          <ExpandIcon isExpanded={isExpanded} />
          <div className={classes.briefTextWrapper}>
            <span className={classes.briefContext}>
              {prettifyString(logEvent.componentName)}/
              {prettifyString(logEvent.logDefinitionName)}:
            </span>
            <span className={classes.briefMessage}>{logEvent.message}</span>
          </div>
        </div>
        {isExpanded && (
          <div className={classes.details}>
            {getDetails(logEvent).map((row) => (
              <div key={row.display} className={classes.detailRow}>
                <div className={classes.detailDisplay}>{row.display}</div>
                <div className={classes.detailValue}>{row.value}</div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className={classes.children}>
        {logEvent.logEvents.map((logEvent) => (
          <LogEventTree key={logEvent.id} logEvent={logEvent} />
        ))}
      </div>
    </div>
  );
}

export default LogEventTree;
