import { MultiselectQueryFilter, GroupQueryFilter } from '@shield-ui/query-filters';
import store from '../../../redux/store';
import { ensurePlatforms, selectPlatformOptions, ensurePlatformSubtypes, selectPlatformSubtypeOptions } from "../../../redux/testDefinitions.slice";

/**
 * Using redux + asyncThunks to fetch and select state with the store outside of react components. Redux with no react... nice and simple :D
 */

export const testDefinitionPlatformQueryFilter = new MultiselectQueryFilter({
  id: 'testDefinitionPlatforms',
  controlLabel: 'Test Platforms',
  esField: 'test_definition.platform.id',
  description: '',
  isMulti: true,
  behaviorConfig: {
    fetchOptions: () => {
      return store.dispatch(ensurePlatforms())
        .then(() => {
          return selectPlatformOptions(store.getState()) || [];
        });
    }
  },
});
export const testDefinitionPlatformSubtypeQueryFilter = new MultiselectQueryFilter({
  id: 'testDefinitionPlatformSubtypes',
  controlLabel: 'Test Platform Subtypes',
  esField: 'test_definition.platform_subtype.id',
  description: '',
  isMulti: true,
  behaviorConfig: {
    fetchOptions: () => {
      return store.dispatch(ensurePlatformSubtypes())
        .then(() => {
          return selectPlatformSubtypeOptions(store.getState()) || [];
        });
    }
  },
});

export default new GroupQueryFilter({
  id: 'testDefinitionPlatformsAndSubTypes',
  controlLabel: 'Test Platforms',
  queryFilters: [
    testDefinitionPlatformQueryFilter,
    testDefinitionPlatformSubtypeQueryFilter,
  ]
});
