import React from 'react';
import { PipelineRun } from '../data';
import { Grow } from '@shield-ui/core';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import OutputIndicator from './OutputIndicator';
import PipelineRow from './PipelineRow';
import { filterPipelineRuns } from './filter';
import { countAndPluralize } from '@shield-ui/utils';

/**
 * List of pipeline executions for a pipeline name
 */
export default function RunList(props: {
  pipelineRuns: PipelineRun[];
  onRunClick: (run: PipelineRun) => void;
  searchString: string;
}) {
  const { pipelineRuns, onRunClick, searchString } = props;
  return (
    <div>
      {filterPipelineRuns(pipelineRuns, searchString).map((run) => {
        const { label, summary } = run;

        return (
          <PipelineRow key={label} onClick={() => onRunClick(run)}>
            <div>{label}</div>
            <Grow />
            {summary.metricCount > 0 && (
              <OutputIndicator
                type="metric"
                tooltipText={`${countAndPluralize(
                  summary.metricCount,
                  'Output Metric'
                )}`}
              />
            )}
            {summary.artifactPlotCount > 0 && (
              <OutputIndicator
                type="plot"
                tooltipText={`${countAndPluralize(
                  summary.artifactPlotCount,
                  'Plotly Plot'
                )}`}
              />
            )}
            {summary.analysisCount > 0 && (
              <OutputIndicator
                tooltipText={`${
                  summary.analysisPassedCount
                }/${countAndPluralize(
                  summary.analysisCount,
                  'Expectation'
                )} Passed`}
                type={
                  summary.analysisCount === summary.analysisPassedCount
                    ? 'testPassed'
                    : 'testFailed'
                }
              />
            )}
            <KeyboardArrowRight />
          </PipelineRow>
        );
      })}
    </div>
  );
}
