// New HMDSDK cloud client
import config from './config';
import { getBearerToken, logout } from '../redux/settings/actions';
import history from '../routes/history';

import { Client } from '@hmd/sdk';
import BearerAuthStrategy from '@hmd/sdk/auth/bearer';
import BasicAuthStrategy from '@hmd/sdk/auth/basic';

// If a SystemUser is configured for a network denied environment,
//   auth with SystemUser.
//   otherwise, auth with logged in user.
const auth = config.systemUser
  ? new BasicAuthStrategy(config.systemUser)
  : new BearerAuthStrategy('' + getBearerToken());

const options = {
  defaultErrorHandler: (err) => {
    console.error('Grpc default error handler', err);
    const { status } = err;

    // Redirect to login page on Unauthorized status code
    // Grpc Code Unauthorized = 16
    if (status === 16) {
      history.push('/app/login');
    }
  },
};
const client = new Client(config.hmds.grpcHost, auth, options);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.hmdsdkClient = client;
export default client;
