import { gql } from '@apollo/client';
import _ from 'lodash';
import client from '../apollo-client';
import tracker from '../lib/tracker';

const labelFragment = `
  id
  name
`;

const commonCreateUpdateVariablesFragment = `
  name: $name,
`;

function runMutation({ variables, mutation, event, callback = _.noop }) {
  client
    .mutate({
      mutation,
      variables,
    })
    .then(
      (res) => {
        callback(null, res);
        tracker.trackEvent('Flight', event);
        return res;
      },
      (err) => {
        callback(err);
      }
    );
}

const createLabelMutation = gql`
  mutation createLabel(
    $name: String!,
  ) {
    createLabel(${commonCreateUpdateVariablesFragment}) {
      ${labelFragment}
    }
  }
`;

const updateLabelMutation = gql`
  mutation updateLabel(
    $name: String,
  ) {
    updateLabel(id: $id,${commonCreateUpdateVariablesFragment}) {
      ${labelFragment}
    }
  }
`;

const deleteLabelMutation = gql`
  mutation deleteLabel($id: String!) {
    deleteLabel(id: $id) {
      id
    }
  }
`;

const getLabelsQuery = gql`
    query labels($textLike: String, $ids: [UUID], $names: [String], $first: Int, $sort: [LabelSortEnum]) {
        labels(textLike: $textLike, ids: $ids, names: $names, first: $first, sort:$sort) {
            edges {
                node {
                    ${labelFragment}
                }
            }
        },
    }
`;

export function queryLabels(variables) {
  return client.query({
    query: getLabelsQuery,
    variables,
    fetchPolicy: 'network-only',
  });
}

export function getLabels(variables, callback) {
  // overload default sort
  if (!variables.sort) {
    variables.sort = 'name_asc';
  }

  client
    .query({
      query: getLabelsQuery,
      variables,
      fetchPolicy: 'network-only',
    })
    .then(
      (val) => {
        if (typeof callback === 'function') {
          callback(null, val);
        }
      },
      (err) => {
        if (typeof callback === 'function') {
          callback(err);
        }
      }
    );
}

export function createLabel(variables, callback) {
  runMutation({
    variables,
    mutation: createLabelMutation,
    event: 'LabelCreate',
    callback,
  });
}

export function updateLabel(variables, callback) {
  runMutation({
    variables,
    mutation: updateLabelMutation,
    event: 'LabelUpdate',
    callback,
  });
}

export function deleteLabel(variables, callback) {
  runMutation({
    variables,
    mutation: deleteLabelMutation,
    event: 'LabelDelete',
    callback,
  });
}

/*****************************************************************************************
 * LABELS TO ROBOT LOGS
 ****************************************************************************************/
const changeRobotLogFragment = `
  id
  labels {
    edges {
      node {
        id
        name
      }
    }
  }
`;

const addLabelsToRobotLogMutation = gql`
    mutation addLabelsToRobotLog($robotLogId:UUID!, $labelIds:[UUID]) {
        addLabelsToRobotLog(robotLogId: $robotLogId, labelIds: $labelIds) {
            ${changeRobotLogFragment}
        }
    }
`;

const removeLabelsFromRobotLogMutation = gql`
    mutation removeLabelsFromRobotLog($robotLogId:UUID!, $labelIds:[UUID]) {
        removeLabelsFromRobotLog(robotLogId: $robotLogId, labelIds: $labelIds) {
            ${changeRobotLogFragment}
        }
    }
`;

export function addLabelsToRobotLog(variables, callback) {
  runMutation({
    variables,
    mutation: addLabelsToRobotLogMutation,
    event: 'LabelAddedToRobotLog',
    callback,
  });
}
export function removeLabelsFromRobotLog(variables, callback) {
  runMutation({
    variables,
    mutation: removeLabelsFromRobotLogMutation,
    event: 'LabelRemovedFromRobotLog',
    callback,
  });
}

/*****************************************************************************************
 * LABELS TO COLLECTIONS
 ****************************************************************************************/
const changeCollectionFragment = `
  id
  labels {
    edges {
      node {
        id
        name
      }
    }
  }
`;

const addLabelsToCollectionMutation = gql`
    mutation addLabelsToCollection($collectionId:UUID!, $labelIds:[UUID]) {
        addLabelsToCollection(collectionId: $collectionId, labelIds: $labelIds) {
            ${changeCollectionFragment}
        }
    }
`;

const removeLabelsFromCollectionMutation = gql`
    mutation removeLabelsFromCollection($collectionId:UUID!, $labelIds:[UUID]) {
        removeLabelsFromCollection(collectionId: $collectionId, labelIds: $labelIds) {
            ${changeCollectionFragment}
        }
    }
`;

export function addLabelsToCollection(variables, callback) {
  runMutation({
    variables,
    mutation: addLabelsToCollectionMutation,
    event: 'LabelAddedToCollection',
    callback,
  });
}

export function removeLabelsFromCollection(variables, callback) {
  runMutation({
    variables,
    mutation: removeLabelsFromCollectionMutation,
    event: 'LabelRemovedFromCollection',
    callback,
  });
}
