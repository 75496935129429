import React from 'react';
import PropTypes from 'prop-types';
import async from 'async';
import _ from 'lodash';
import { Button, Paper } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { countAndPluralize } from '@shield-ui/utils';
import SectionSubmitButton from '../../routes/RobotLog/components/SectionSubmitButton';
import {
  createRobotLogReview,
  updateRobotLogReview,
  deleteRobotLogReview,
  createCollectionReview,
  updateCollectionReview,
  deleteCollectionReview,
} from '../../services/reviews';
import ReviewList from './ReviewList';
import { showConfirmPrompt } from '../../lib/messages';
import TeamUserCheckSelect from '../connected-controls/TeamUserCheckSelect';
import container from './container';

function styles(theme) {
  return {
    container: {
      padding: theme.spacing(2),
    },
    controlContainer: {
      marginTop: 20,
      borderTop: `1px solid rgba(5, 5, 5, .2)`,
      paddingTop: 20,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    selectWrapper: {
      flex: 1,
      marginRight: 20,
    },
  };
}

class ReviewSection extends React.Component {
  static propTypes = {
    reviews: PropTypes.array,
    currentUser: PropTypes.object,
    onRefresh: PropTypes.func,
    // one or the other
    robotLogId: PropTypes.string,
    collectionId: PropTypes.string,
  };
  static defaultProps = {
    reviews: [],
    onRefresh: _.noop,
  };

  state = {
    reviewUserIds: [],
    reviewTeamIds: [],
    loading: false,
    error: false,
    reviewers: [],
  };

  constructor(props) {
    super(props);

    if (props.robotLogId && props.collectionId) {
      throw new Error(
        'You cannot use this component with a robotLogId and a collectionId. Use one or the other based on which type of reviews'
      );
    }

    this.mutations = {};
    if (props.robotLogId) {
      this.mutations = {
        create: createRobotLogReview,
        update: updateRobotLogReview,
        delete: deleteRobotLogReview,
      };
    } else if (props.collectionId) {
      this.mutations = {
        create: createCollectionReview,
        update: updateCollectionReview,
        delete: deleteCollectionReview,
      };
    } else {
      throw new Error(
        'You need to provide a robotLogId or collectionId of the context we are working with'
      );
    }
  }

  getCreateVariables() {
    const { robotLogId, collectionId } = this.props;

    if (robotLogId) {
      return { robotLogId };
    } else if (collectionId) {
      return { collectionId };
    }
  }

  refreshReviews() {
    const { onRefresh } = this.props;
    onRefresh();
  }

  onChangeUserIds = (value) => {
    this.setState({
      reviewUserIds: value,
    });
  };

  onChangeTeamIds = (value) => {
    this.setState({
      reviewTeamIds: value,
    });
  };

  onError = (err) => {
    const { showErrorSnack } = this.props;
    showErrorSnack(err);
  };

  onSuccess = (message) => {
    const { showSuccessSnack } = this.props;
    showSuccessSnack({ message });
    this.refreshReviews();
  };

  assignToMe = () => {
    const { currentUser } = this.props;

    const variables = {
      ...this.getCreateVariables(),
      reviewerId: currentUser.id,
    };

    this.mutations.create(variables, (err) => {
      if (err) {
        return this.onError(err);
      }
      this.onSuccess('You are assigned to this review');
    });
  };

  submitCreateReviews = () => {
    const { reviewUserIds, reviewTeamIds } = this.state;

    if (reviewUserIds.length === 0 && reviewTeamIds.length === 0) {
      this.onError(
        new Error(
          'Reviewer is required, please select a user or team for review'
        )
      );
      return;
    }

    async.waterfall(
      [
        (callback) => {
          async.eachLimit(
            reviewUserIds,
            2,
            (userId, callback) => {
              this.mutations.create(
                {
                  ...this.getCreateVariables(),
                  reviewerId: userId,
                },
                callback
              );
            },
            callback
          );
        },
        (callback) => {
          async.eachLimit(
            reviewTeamIds,
            2,
            (teamId, callback) => {
              this.mutations.create(
                {
                  ...this.getCreateVariables(),
                  reviewerTeamId: teamId,
                },
                callback
              );
            },
            callback
          );
        },
      ],
      (err) => {
        if (err) {
          this.onError(err);
          // refresh because multiple and maybe some succeeded?
          this.refreshReviews();
          return;
        }

        const messages = [];
        if (reviewTeamIds.length > 1) {
          messages.push(countAndPluralize(reviewTeamIds.length), 'team');
        }
        if (reviewUserIds.length > 1) {
          messages.push(countAndPluralize(reviewUserIds.length), 'user');
        }

        this.setState({
          reviewUserIds: [],
          reviewTeamIds: [],
        });
        this.onSuccess(
          `Successfully added ${messages.join(' and ')} for review`
        );
      }
    );
  };

  updateReviewStatus = (id, status) => {
    const variables = {
      id: id,
      reviewStatus: status,
    };

    this.mutations.update(variables, (err) => {
      if (err) {
        return this.onError(err);
      }
      this.onSuccess('Review status was updated');
    });
  };

  deleteReview = (id) => {
    showConfirmPrompt({
      title: 'Delete Review?',
      body: 'This action is irreversible. Are you sure you want to remove this Review?',
      confirmLabel: 'Delete',
      onConfirm: () => {
        this.mutations.delete({ id }, (err) => {
          if (err) {
            return this.onError(err);
          }
          this.onSuccess('Review was removed');
        });
      },
    });
  };

  render() {
    const { reviews, currentUser, classes } = this.props;
    const { reviewUserIds, reviewTeamIds } = this.state;

    const alreadyAdded = reviews.reduce(
      (acc, review) => {
        const { reviewer, reviewerTeam } = review;
        if (reviewer) {
          acc.user[reviewer.id] = true;
        } else if (reviewerTeam) {
          acc.team[reviewerTeam.id] = true;
        }
        return acc;
      },
      {
        user: {},
        team: {},
      }
    );
    const canAssignToMe = !alreadyAdded.user[currentUser.id];
    const pendingCount = reviewTeamIds.length + reviewUserIds.length;
      
    return (
      <div>
        <Paper className={classes.container}>
          <ReviewList
            reviews={reviews}
            currentUser={currentUser}
            updateReviewStatus={this.updateReviewStatus}
            deleteReview={this.deleteReview}
          />
          <div className={classes.controlContainer}>
            <div className={classes.selectWrapper}>
              <TeamUserCheckSelect
                placeholder="Search to assign reviewers"
                userIdValues={reviewUserIds}
                teamIdValues={reviewTeamIds}
                onChangeUserIds={this.onChangeUserIds}
                onChangeTeamIds={this.onChangeTeamIds}
                disabledUserIds={_.keys(alreadyAdded.user)}
                disabledTeamIds={_.keys(alreadyAdded.team)}
              />
            </div>
            <SectionSubmitButton
              disabled={!pendingCount}
              onClick={this.submitCreateReviews}
            >
              {pendingCount > 1 ? 'Add Reviewers' : 'Add Reviewer'}
            </SectionSubmitButton>
          </div>
          {canAssignToMe && (
            <div style={{ marginTop: 10 }}>
              <Button size="small" onClick={this.assignToMe} tabIndex={-1}>
                Assign to Me
              </Button>
            </div>
          )}
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(container(ReviewSection));
