import simpleObjectGetValue from '../common/simpleObjectGetValue';
import Cell, { parseCauseNamesFromRow } from './Cell';
const config = {
  columnKey: 'eventCauses',
  label: 'Event Causes',
  description: 'Events > Causes',
  defaultWidth: 240,
  sortKey: 'events.cause.name.keyword',
  Cell,
  getValue: ({ row }) => simpleObjectGetValue(parseCauseNamesFromRow(row)),
  fragment: `
    events {
        edges {
            node {
              cause {
                name
              }
            }
        }
    }
    `,
};

export default config;
