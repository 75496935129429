import React, { Component, ReactNode } from 'react';
import _ from 'lodash';
import { fromEvent } from 'file-selector';
import Modal from '@mui/material/Modal';
import { lighten, Theme } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import { spacing, colors } from '@shield-ui/styles';

// exported for testing
export function isEvtWithFiles(event) {
  if (!event.dataTransfer) {
    return !!event.target && !!event.target.files;
  }

  // https://developer.mozilla.org/en-US/docs/Web/API/DataTransfer/types
  // https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/Recommended_drag_types#file
  return Array.prototype.some.call(
    event.dataTransfer.types,
    (type) => type === 'Files' || type === 'application/x-moz-file'
  );
}

const styles = (theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalContent: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: theme.shape.borderRadius * 2,
      padding: spacing.unit * 5,
      maxWidth: 480,
      color: lighten(colors.hues.gray, 0.5),
      fontSize: 16,
      letterSpacing: 0.5,
      outline: 'none',
    },
  });

export interface DropPageProps extends WithStyles<typeof styles> {
  onFiles: (files, event) => void;
  onError?: (errorLike) => void;
  modalContent?: string | ReactNode;
  children?: ReactNode;
}

class DropPage extends Component<DropPageProps> {
  static defaultProps = {
    modalContent: 'Drop files anywhere on this screen',
    onError: _.noop,
  };

  state = {
    // drag enter/leave happens for every child so a counter greater than 0 means it is on
    dragEnterCounter: 0,
  };

  onDragEnter = (evt) => {
    if (!isEvtWithFiles(evt)) {
      return;
    }
    this.setState({ dragEnterCounter: this.state.dragEnterCounter + 1 });
    evt.preventDefault();
    evt.stopPropagation();
  };
  onDragLeave = (evt) => {
    if (!isEvtWithFiles(evt)) {
      return;
    }
    this.setState({ dragEnterCounter: this.state.dragEnterCounter - 1 });
    evt.preventDefault();
    evt.stopPropagation();
  };

  // Need this to be defined for 'drop' to work correctly and not just do browser default
  onDragOver = (evt) => {
    if (!isEvtWithFiles(evt)) {
      return;
    }
    evt.preventDefault();
    evt.stopPropagation();
  };

  onDrop = (evt) => {
    const { onError } = this.props;

    if (!isEvtWithFiles(evt)) {
      return;
    }
    this.setState({ dragEnterCounter: 0 });
    evt.preventDefault();
    evt.stopPropagation();

    fromEvent(evt)
      .then((files) => this.props.onFiles(files, evt))
      .catch((e) => onError(e));
  };

  componentDidMount(): void {
    document.addEventListener('dragenter', this.onDragEnter, false);
    document.addEventListener('dragleave', this.onDragLeave, false);
    document.addEventListener('dragover', this.onDragOver, false);
    document.addEventListener('drop', this.onDrop, false);
  }

  componentWillUnmount(): void {
    document.removeEventListener('dragenter', this.onDragEnter);
    document.removeEventListener('dragleave', this.onDragLeave);
    document.removeEventListener('dragover', this.onDragOver);
    document.removeEventListener('drop', this.onDrop);
  }

  render() {
    const { children, classes, modalContent } = this.props;
    const { dragEnterCounter } = this.state;

    return (
      <>
        <Modal
          open={!!dragEnterCounter}
          className={classes.modal}
          disableAutoFocus
          disableEnforceFocus
          disableRestoreFocus
        >
          <div className={classes.modalContent}>{modalContent}</div>
        </Modal>
        {children}
      </>
    );
  }
}

export default withStyles(styles)(DropPage);
