/* eslint-disable react-hooks/rules-of-hooks */
import _ from 'lodash';
import { gql } from '@apollo/client';
import memoizeOne from 'memoize-one';
import client from '../apollo-client';
import tracker from '../lib/tracker';
import { HangarArtifact_Artifact_Type } from '@shield-ui/hangar-service';
import { prettifyString } from '@shield-ui/utils';
import sdkClient from '../lib/hmdsdkClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  ArtifactType,
  ArtifactTypeService,
  ListRequest,
  UpdateRequest,
  CreateRequest,
} from '@hmd/sdk/api/artifacts/type/v1';

export const ARTIFACT_TYPES = {
  LOG: 'LOG',
  VIDEO: 'VIDEO',
  FILE: 'FILE',
  // IMAGE: 'IMAGE',
  JUNIT: 'JUNIT',
  SLAMMAP_VIDEO: 'SLAMMAP_VIDEO',
  SLAMMAP_IMAGE: 'SLAMMAP_IMAGE',
  PX4_LOG: 'PX4_LOG',
  PX4_LOG_H5: 'PX4_LOG_H5',
  OBJECT_ANNOTATION: 'OBJECT_ANNOTATION',
  PERF_RECORD: 'PERF_RECORD',
  COREDUMP: 'COREDUMP',
  PHONE_DAT: 'PHONE_DAT',
  PHONE_VIDEO: 'PHONE_VIDEO',
  PHONE_SLAMMAP_IMAGE: 'PHONE_SLAMMAP_IMAGE',
  PHONE_SLAMMAP_KMZ: 'PHONE_SLAMMAP_KMZ',
  PHONE_LOG: 'PHONE_LOG',
  PHONE_SCREENSHOT: 'PHONE_SCREENSHOT',
  PHONE_COCOPILOT_VIDEO: 'PHONE_COCOPILOT_VIDEO',
  PHONE_COCOPILOT_AUDIO: 'PHONE_COCOPILOT_AUDIO',
  PHONE_SCREEN_RECORDING: 'PHONE_SCREEN_RECORDING',
  PHONE_MISSION: 'PHONE_MISSION',
  PLOTLY_PLOT_JSON: 'PLOTLY_PLOT_JSON',
  TRACE_EVENTS_JSON: 'TRACE_EVENTS_JSON',
  ROSLOG: 'ROSLOG',
  ROSBAG: 'ROSBAG',
  SOURCE_LOG: 'SOURCE_LOG',
  ROSBAG_TOPIC: 'ROSBAG_TOPIC',
};

Object.values(HangarArtifact_Artifact_Type).forEach((value) => {
  if (!ARTIFACT_TYPES[value]) {
    ARTIFACT_TYPES[value] = value;
  }
});

export function isPhoneArtifact(artifactType) {
  return artifactType && artifactType.indexOf('PHONE_') === 0;
}

const DISPLAY_OVERRIDES = {
  [ARTIFACT_TYPES.ROSBAG]: 'Rosbag', // raw uploaded, prior to any processing
  [ARTIFACT_TYPES.LOG]: 'Rosbag (Re-indexed)',
  [ARTIFACT_TYPES.SOURCE_LOG]: 'Rosbag (On-Prem)', //deprecated, old on-prem version
  [ARTIFACT_TYPES.VIDEO]: 'Video',
  [ARTIFACT_TYPES.FILE]: 'Generic File',
  [ARTIFACT_TYPES.COREDUMP]: 'Core Dump',
  [ARTIFACT_TYPES.PLOTLY_PLOT_JSON]: 'Plotly Plot',
  [HangarArtifact_Artifact_Type.AutopilotLogTlv]: 'VBAT Autopilot Log',
  [HangarArtifact_Artifact_Type.GcsTelemetryLogsCsv]: 'GCS Telemetry Logs',
  [HangarArtifact_Artifact_Type.TrilliumPayloadVideo]: 'Trillium Payload Video',
  [HangarArtifact_Artifact_Type.PlanckAceLogArchive]: 'Planck Ace Logs',
};

const ARTIFACT_TYPES_QUERY_KEY = 'artifactTypes';

async function listAllArtifactTypes(): Promise<ArtifactType[]> {
  const req = new ListRequest();

  let allArtifactTypes = [];
  let nextPageToken = 'temp';
  while (nextPageToken !== '') {
    const res = await sdkClient.unary(ArtifactTypeService.List, req);
    req.setPageToken(res.getNextPageToken());
    allArtifactTypes = allArtifactTypes.concat(res.getArtifactTypesList());
    nextPageToken = res.getNextPageToken();
  }
  return allArtifactTypes;
}

type NewArtifactType = {
  name: string;
  description: string;
  slug: string;
};

export const updateArtifactTypeMutation = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({ name, slug, description }: NewArtifactType) => {
      const req = new UpdateRequest();
      const artifactType = new ArtifactType();
      artifactType.setName(name);
      artifactType.setSlug(slug);
      artifactType.setDescription(description);
      req.setArtifactType(artifactType);
      const response = await sdkClient.unary(ArtifactTypeService.Update, req);
      return response;
    },
    onError: (error) => {
      return error;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [ARTIFACT_TYPES_QUERY_KEY] });
    },
  });
  return mutation;
};

export const createArtifactTypeMutation = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({ name, slug, description }: NewArtifactType) => {
      const req = new CreateRequest();
      const artifactType = new ArtifactType();
      artifactType.setName(name);
      artifactType.setSlug(slug);
      artifactType.setDescription(description);
      req.setArtifactType(artifactType);
      const response = await sdkClient.unary(ArtifactTypeService.Create, req);
      return response;
    },
    onError: (error) => {
      return error;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [ARTIFACT_TYPES_QUERY_KEY] });
    },
  });
  return mutation;
};

export const getAllArtifactTypeOptions = async () => {
  const ats = await listAllArtifactTypes();

  const opts = ats.map((val) => {
    return {
      id: val.getId(),
      label: val.getName(),
      value: val.getSlug(),
      description: val.getDescription(),
    };
  });

  return _.sortBy(opts, 'label');
};

export const getArtifactTypeOptions = memoizeOne(() => {
  const opts = _.values(ARTIFACT_TYPES)
    .filter((val) => {
      return (
        // remove on prem rosbags
        val !== HangarArtifact_Artifact_Type.SourceLog &&
        // we've never done anything with this... it has no context for anything
        val !== HangarArtifact_Artifact_Type.Image &&
        // never implemented for anything
        val !== HangarArtifact_Artifact_Type.ObjectAnnotation
      );
    })
    .map((val) => {
      return {
        label: getArtifactTypeDisplay(val),
        value: val,
      };
    });

  return _.sortBy(opts, 'label');
});

export function getArtifactTypeDisplay(artifactType) {
  if (!artifactType) {
    return;
  }
  const override = DISPLAY_OVERRIDES[artifactType];
  if (override) {
    return override;
  }

  return prettifyString(artifactType);
}

const deleteArtifactMutation = gql`
  mutation deleteArtifact($id: String!) {
    deleteArtifact(id: $id) {
      id
    }
  }
`;

export function deleteArtifact(artifactId, callback, error) {
  if (!artifactId) {
    return;
  }

  return client
    .mutate({
      mutation: deleteArtifactMutation,
      variables: { id: artifactId },
    })
    .then(
      (res) => {
        if (typeof callback === 'function') {
          callback(res);
          tracker.trackEvent('Flight', 'ArtifactDelete', 'id', artifactId);
        }

        return res;
      },
      (err) => {
        if (typeof error === 'function') {
          error(err);
        }
      }
    );
}
