import _ from 'lodash';
import {
  BaseFilterValue,
  BaseQueryFilter,
  BaseFilterProps,
} from './baseQueryFilter';
import {
  QueryFilterTypes,
  MultiselectValueType,
  MultiselectOption,
  ListOperator,
} from './common';
import { getMultiselectMatchPreview } from './multiselectUtils';

export interface ParentChildMultiselectQueryFilterValue
  extends BaseFilterValue {
  operator?: ListOperator;
  childOptionValues?: MultiselectValueType[];
  parentOptionValues?: MultiselectValueType[];
}

export interface ParentChildMultiselectControlData {
  shouldImplementOperator: boolean; // whether or not the control should show the ability to toggle between AND/OR logic
  childOptions: MultiselectOption[];
  parentOptions: ParentMultiselectOption[];
}

export interface ParentMultiselectOption extends MultiselectOption {
  childValues: MultiselectValueType[];
}

export interface ParentChildMultiselectQueryFilterProps
  extends Omit<BaseFilterProps, 'esField'> {
  esFields?: {
    parent: string;
    child: string;
  };
  // provide an explicit list of options in the constructor
  parentOptions?: ParentMultiselectOption[];
  childOptions?: MultiselectOption[];
  // async populate the options
  fetchParentOptions?: () => Promise<ParentMultiselectOption[]>;
  fetchChildOptions?: () => Promise<MultiselectOption[]>;
  // whether or not the control should show the ability to toggle between AND/OR logic
  shouldImplementOperator?: boolean;
}

export class ParentChildMultiselectQueryFilter extends BaseQueryFilter<
  ParentChildMultiselectQueryFilterValue,
  ParentChildMultiselectQueryFilterProps,
  ParentChildMultiselectControlData
> {
  type = QueryFilterTypes.parentChildMultiselect;
  _ensurePromise = undefined;

  initControlData(
    props: ParentChildMultiselectQueryFilterProps
  ): ParentChildMultiselectControlData {
    return {
      shouldImplementOperator:
        typeof props.shouldImplementOperator === 'undefined'
          ? false
          : props.shouldImplementOperator,
      childOptions: props.fetchChildOptions
        ? undefined
        : props.childOptions || [],
      parentOptions: props.fetchParentOptions
        ? undefined
        : props.parentOptions || [],
    };
  }

  // _ensurePromise used to cache the fact we only need to fetch data once and to dedupe works and created promises
  // on subsequent calls
  ensureControlData(
    value: ParentChildMultiselectQueryFilterValue
  ): Promise<ParentChildMultiselectControlData> {
    if (this._ensurePromise) {
      return this._ensurePromise;
    }

    const promises = [];
    if (this.props.fetchChildOptions) {
      promises.push(
        this.props.fetchChildOptions().then((opts) => {
          return {
            childOptions: opts,
          };
        })
      );
    }
    if (this.props.fetchParentOptions) {
      promises.push(
        this.props.fetchParentOptions().then((opts) => {
          return {
            parentOptions: opts,
          };
        })
      );
    }

    if (!promises.length) {
      this._ensurePromise = Promise.resolve({});
      return this._ensurePromise;
    }

    this._ensurePromise = Promise.all(promises).then((values) => {
      const updates = values.reduce((acc, promiseValue) => {
        return {
          ...acc,
          ...promiseValue,
        };
      }, {});
      this.setControlData(updates);
      return this.getControlData();
    });

    return this._ensurePromise;
  }

  hasControlData(): boolean {
    return (
      _.isArray(this.getControlData().childOptions) &&
      _.isArray(this.getControlData().parentOptions)
    );
  }

  hasValue(value: ParentChildMultiselectQueryFilterValue = {}): boolean {
    return (
      !_.isEmpty(value.childOptionValues) ||
      !_.isEmpty(value.parentOptionValues)
    );
  }

  getElasticQuery(
    value: ParentChildMultiselectQueryFilterValue
  ): object | void {
    const { esFields } = this.props;
    if (!esFields) {
      return;
    }
    if (!this.hasValue(value)) {
      return;
    }

    const { parentOptionValues = [], childOptionValues = [], operator } = value;
    const operatorKey = operator === ListOperator.and ? 'must' : 'should';

    const results = parentOptionValues
      .map((v) => ({
        term: {
          [esFields.parent]: v,
        },
      }))
      .concat(
        childOptionValues.map((v) => ({
          term: {
            [esFields.child]: v,
          },
        }))
      );

    return {
      bool: {
        [operatorKey]: results,
      },
    };
  }

  getValuePreview(value: ParentChildMultiselectQueryFilterValue): string {
    if (!this.hasValue(value)) {
      return '';
    }
    const { childOptionValues = [], parentOptionValues = [] } = value;
    const { parentOptions = [], childOptions = [] } = this.getControlData();

    if (parentOptionValues.length && _.isEmpty(parentOptions)) {
      return '';
    }
    if (childOptionValues.length && _.isEmpty(childOptions)) {
      return '';
    }

    if (parentOptionValues.length && childOptionValues.length) {
      const parentPreview = getMultiselectMatchPreview(
        parentOptionValues,
        parentOptions
      );
      const childPreview = getMultiselectMatchPreview(
        childOptionValues,
        childOptions
      );

      if (parentPreview.length > 75) {
        const totalCount = parentOptionValues.length + childOptionValues.length;
        return getMultiselectMatchPreview(parentOptionValues, parentOptions, {
          totalCountOverride: totalCount,
        });
      }

      return `${parentPreview}, ${childPreview}`;
    } else if (parentOptionValues.length) {
      return getMultiselectMatchPreview(parentOptionValues, parentOptions);
    } else if (childOptionValues.length) {
      return getMultiselectMatchPreview(childOptionValues, childOptions);
    }
    return '';
  }
}
