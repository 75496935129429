import {
  FormContextType,
  GenericObjectType,
  RJSFSchema,
  RJSFValidationError,
  StrictRJSFSchema,
  WidgetProps,
  ariaDescribedByIds,
  enumOptionsValueForIndex,
} from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { IChangeEvent } from '@rjsf/core';
import { Form } from '@rjsf/antd';
import {
  EntitySchemaJSON,
  usePropertyValues,
} from '../../services/entitySchemas';
import { FormEvent, useEffect, useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { AutoComplete } from 'antd';

const useStyles = makeStyles((theme) => ({
  entityComponent: {
    width: '100%',
  },
}));

type Props = {
  entitySchema: EntitySchemaJSON;
  data: object;
  onSave?: (
    data: IChangeEvent<object, RJSFSchema, object>,
    event: FormEvent<any>
  ) => void;
  onError?: (errors: RJSFValidationError[]) => void;
  readOnly?: boolean;
  includeTitle?: boolean;
};

export const EntityComponent: React.FC<Props> = ({
  data,
  entitySchema,
  onSave,
  onError = () => ({}),
  readOnly = false,
  includeTitle = true,
  children,
}) => {
  const classes = useStyles();
  const schema = entitySchema.schema as RJSFSchema;
  if (includeTitle) {
    if (!schema.description) {
      schema.description = entitySchema.description;
    }
    if (!schema.title) {
      schema.title = entitySchema.name;
    }
  }

  const uiSchema = entitySchema?.uiSchemaOverrides || {};

  function AutocompleteTextWidget<
    T = any,
    S extends StrictRJSFSchema = RJSFSchema,
    F extends FormContextType = any
  >({
    name,
    autofocus,
    disabled,
    formContext = {} as F,
    id,
    multiple,
    onBlur,
    onChange,
    onFocus,
    options,
    placeholder,
    readonly,
    value,
    ...props
  }: WidgetProps<T, S, F>) {
    const { data: propertyValues = [], mutate: getPropertyValues } =
      usePropertyValues({});

    const { readonlyAsDisabled = true } = formContext as GenericObjectType;

    const { enumOptions, enumDisabled, emptyValue } = options;

    const handleChange = (nextValue: any) =>
      onChange(enumOptionsValueForIndex<S>(nextValue, enumOptions, emptyValue));

    const handleBlur = () =>
      onBlur(id, enumOptionsValueForIndex<S>(value, enumOptions, emptyValue));

    const handleFocus = () =>
      onFocus(id, enumOptionsValueForIndex<S>(value, enumOptions, emptyValue));

    const extraProps = {
      name: id,
    };

    const optionList = useMemo(() => {
      return propertyValues.map((value) => ({
        label: value,
        value: value,
      }));
    }, [propertyValues]);

    useEffect(() => {
      getPropertyValues({
        schemaId: entitySchema.id,
        property: name,
      });
    }, [name]);

    return (
      <AutoComplete
        autoFocus={autofocus}
        disabled={disabled || (readonlyAsDisabled && readonly)}
        id={id}
        onBlur={!readonly ? handleBlur : undefined}
        onChange={!readonly ? handleChange : undefined}
        onFocus={!readonly ? handleFocus : undefined}
        placeholder={placeholder}
        value={value}
        {...extraProps}
        filterOption={true}
        aria-describedby={ariaDescribedByIds<T>(id)}
        options={optionList}
        style={{
          width: '100%',
        }}
      ></AutoComplete>
    );
  }

  const widgets = {
    AutoCompleteWidget: AutocompleteTextWidget,
  };

  return (
    <Form
      className={classes.entityComponent}
      readonly={readOnly}
      formData={data}
      data-testid="entity-form"
      schema={schema}
      validator={validator}
      uiSchema={{
        'ui:submitButtonOptions': {
          props: {
            disabled: false,
            block: true,
            type: 'primary',
          },
          norender: false,
          submitText: 'Save',
        },
        ...uiSchema,
      }}
      onSubmit={onSave}
      onError={onError}
      widgets={widgets}
    >
      {children}
    </Form>
  );
};
