import _ from 'lodash';
import { ValueTypes } from 'apps/vision/src/app/lib/dcoAttributes';

const config = {
  columnKey: 'childrenCount',
  label: 'Child Count',
  defaultWidth: 140,
  description: 'Display the total number of children for this parent',
  getValue: ({ row }) => _.get(row, 'childrenCount'),
  fragment: 'childrenCount',
  valueType: ValueTypes.number,
  getElasticSort: ({ orderByDirection }) => {
    return [
      {
        _script: {
          type: 'number',
          script: {
            lang: 'painless',
            source: "doc['children.id'].size()",
          },
          order: orderByDirection,
        },
      },
    ];
  },
};

export default config;
