import {
  EntitySchema,
  EntitySchemaService,
  EntitySchemaServiceClient,
  GetRequest,
  CreateRequest,
  BatchGetRequest,
  UpdateRequest,
  ListRequest,
  PropertyValuesRequest,
} from '@hmd/sdk/api/systems/entity_schema/v1';
import sdkClient from '../lib/hmdsdkClient';
import { EntitySchemaJSON } from '../services/entitySchemas';
import { Struct } from 'google-protobuf/google/protobuf/struct_pb';

export const EntitySchemaJSONToEntitySchema = (
  entitySchema: EntitySchemaJSON
): EntitySchema => {
  const newEntitySchema = new EntitySchema();
  if (entitySchema.id) {
    newEntitySchema.setId(entitySchema.id);
  }
  newEntitySchema.setName(entitySchema.name);
  newEntitySchema.setSlug(entitySchema.slug);
  if (entitySchema.schema) {
    const schemaStruct = Struct.fromJavaScript(entitySchema.schema);
    newEntitySchema.setSchema(schemaStruct);
  }
  if (entitySchema.uiSchemaOverrides) {
    const UISchemaStruct = Struct.fromJavaScript(
      entitySchema.uiSchemaOverrides
    );
    newEntitySchema.setUischemaOverrides(UISchemaStruct);
  }
  newEntitySchema.setDescription(entitySchema.description);

  return newEntitySchema;
};

export const getEntitySchemas = async () => {
  const listRequest = new ListRequest();

  const entitySchemas: EntitySchema[] = [];

  const response = await sdkClient.unary(EntitySchemaService.List, listRequest);

  entitySchemas.push(...response.getEntitySchemasList());

  let potentialNextPageToken = response.getNextPageToken();
  while (potentialNextPageToken) {
    const nextPageRequest = new ListRequest();
    nextPageRequest.setPageToken(potentialNextPageToken);
    const nextPageResponse = await sdkClient.unary(
      EntitySchemaService.List,
      nextPageRequest
    );
    potentialNextPageToken = nextPageResponse.getNextPageToken();
    entitySchemas.push(...nextPageResponse.getEntitySchemasList());
  }

  return entitySchemas;
};

export const createEntitySchema = async (entitySchema: EntitySchemaJSON) => {
  const createRequest = new CreateRequest();

  const newEntitySchema = EntitySchemaJSONToEntitySchema(entitySchema);
  createRequest.setEntitySchema(newEntitySchema);

  const response = await sdkClient.unary(
    EntitySchemaService.Create,
    createRequest
  );

  return response;
};

export const updateEnitySchema = async (entitySchema: EntitySchemaJSON) => {
  const updateRequest = new UpdateRequest();

  const newEntitySchema = EntitySchemaJSONToEntitySchema(entitySchema);
  updateRequest.setEntitySchema(newEntitySchema);

  const response = await sdkClient.unary(
    EntitySchemaService.Update,
    updateRequest
  );

  return response;
};

export const getPropertyValues = async (
  entitySchemaId: string,
  property: string
) => {
  const propertyValuesRequest = new PropertyValuesRequest();
  propertyValuesRequest.setId(entitySchemaId);
  propertyValuesRequest.setProperty(property);

  const response = await sdkClient.unary(
    EntitySchemaService.PropertyValues,
    propertyValuesRequest
  );

  return response.toObject().propertyValuesList;
};
