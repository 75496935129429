import React from 'react';
import async from 'async';
import PropTypes from 'prop-types';
import _ from 'lodash';
import withStyles from '@mui/styles/withStyles';
import { Paper, FormControlLabel, Switch, Chip } from '@mui/material';
import strings from '../../../lib/strings';
import history from '../../../routes/history';
import ModalHeader from '../../modals/Header';
import {
  cloneCollection,
  createCollection,
  updateCollection,
  alterIncludedExcludedMappings,
} from '../../../services/collections';
import { createRuleSet } from '../../../services/ruleSets';
import RuleSetPreview from '../RuleSetPreview';
import container from './container';
import { colors } from '@shield-ui/styles';
import { InputGroup, TextField } from '@shield-ui/controls';
import { ProgressSubmitButton } from '@shield-ui/buttons';

function styles(theme) {
  return {
    container: {
      padding: theme.spacing(3),
      width: 600,
      // not sure why this gets a focus outline
      outline: 'none',
    },
    buttonContainer: {
      marginTop: theme.spacing(4),
      display: 'flex',
      justifyContent: 'flex-end',
    },
  };
}

class CreateUpdateCollectionModal extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    description: PropTypes.string,
    robotLogSearchVariables: PropTypes.object,
    isPrivateForCreator: PropTypes.bool,
    isPubliclyEditable: PropTypes.bool,
    title: PropTypes.string,
    setCollectionPageCollection: PropTypes.func,
    includedRobotLogIds: PropTypes.array,
    excludedRobotLogIds: PropTypes.array,
  };

  static defaultProps = {
    title: 'Create a New Collection',
    name: undefined,
    description: undefined,
    isPrivateForCreator: false,
    isPubliclyEditable: false,
    includedRobotLogIds: [],
    excludedRobotLogIds: [],
  };

  state = {
    mounted: false,
    name: '',
    description: '',
    isPrivateForCreator: undefined,
    isPubliclyEditable: false,
    submitting: false,
    redirecting: false,
    nameShowError: false,
    isUpdate: false,
    setCollectionPageCollection: _.noop,
  };

  componentDidMount() {
    const updates = {
      mounted: true,
      isUpdate: !!this.props.id,
    };

    const propCopyKeys = [
      'name',
      'description',
      'isPrivateForCreator',
      'isPubliclyEditable',
    ];
    propCopyKeys.forEach((propToStateKey) => {
      if (this.props[propToStateKey]) {
        updates[propToStateKey] = this.props[propToStateKey];
      }
    });

    this.setState(updates);
  }

  handleChange = (name) => {
    return (evt) => {
      this.setState({
        [name]: evt.target.value,
        [`${name}ShowError`]: false,
      });
    };
  };

  onSubmit = () => {
    const { isUpdate } = this.state;
    const { cloneCollectionId } = this.props;

    if (cloneCollectionId) {
      this.cloneCollection();
    } else if (isUpdate) {
      this.updateCollection();
    } else {
      this.createCollection();
    }
  };

  isValid() {
    const { showErrorSnack } = this.props;
    const { name } = this.state;

    if (!name) {
      this.setState({ nameShowError: true });
      showErrorSnack({ message: 'A name is required to create a collection' });
      return false;
    }

    return true;
  }

  updateCollection() {
    const {
      id,
      showErrorSnack,
      showSuccessSnack,
      hideModal,
      setCollectionPageCollection,
    } = this.props;
    const { name, description, isPrivateForCreator, isPubliclyEditable } =
      this.state;

    if (!this.isValid()) {
      return;
    }
    this.setState({ submitting: true });

    updateCollection(
      {
        id,
        name,
        description,
        isPrivateForCreator,
        isPubliclyEditable,
      },
      (err, result) => {
        this.setState({ submitting: false });

        if (err) {
          return showErrorSnack(err);
        }
        const { updateCollection: collection } = result.data;

        setCollectionPageCollection({ collection });
        showSuccessSnack({ message: `Updated collection: ${name} ` });
        hideModal();
      }
    );
  }

  cloneCollection() {
    const { cloneCollectionId, showErrorSnack, hideModal } = this.props;
    const { name, description, isPrivateForCreator, isPubliclyEditable } =
      this.state;

    if (!this.isValid()) {
      return;
    }
    this.setState({ submitting: true });

    cloneCollection(
      {
        id: cloneCollectionId,
        name,
        description,
        isPrivateForCreator,
        isPubliclyEditable,
      },
      (err, result) => {
        this.setState({
          submitting: true,
          redirecting: !err,
        });
        if (err) {
          return showErrorSnack(err);
        }

        const { cloneCollection: collection } = result.data;
        setTimeout(() => {
          hideModal();
          history.push(`/app/collections/${collection.id}`);
        }, 800);
      }
    );
  }

  createCollection() {
    const {
      showErrorSnack,
      hideModal,
      includedRobotLogIds,
      excludedRobotLogIds,
      robotLogSearchVariables,
    } = this.props;
    const { name, description, isPrivateForCreator, isPubliclyEditable } =
      this.state;

    if (!this.isValid()) {
      return;
    }

    this.setState({ submitting: true });

    let collectionId;
    async.waterfall(
      [
        (callback) => {
          if (
            robotLogSearchVariables &&
            _.keys(robotLogSearchVariables).length > 0
          ) {
            createRuleSet(
              {
                robotLogSearchVariables: JSON.stringify(
                  robotLogSearchVariables
                ),
              },
              callback
            );
          } else {
            return callback(null, null);
          }
        },
        (result, callback) => {
          createCollection(
            {
              name,
              description,
              isPrivateForCreator,
              isPubliclyEditable,
              ruleSetId: _.get(result, 'data.createRuleSet.id'),
            },
            callback
          );
        },
        (result, callback) => {
          collectionId = _.get(result, 'data.createCollection.id');

          if (_.isEmpty(includedRobotLogIds)) {
            return callback(null, null);
          }

          alterIncludedExcludedMappings({
            variables: {
              collectionId,
              robotLogIds: includedRobotLogIds,
            },
            mutation: 'addIncludedRobotLogsToCollection',
            callback,
          });
        },
        (result, callback) => {
          if (_.isEmpty(excludedRobotLogIds)) {
            return callback(null, null);
          }

          alterIncludedExcludedMappings({
            variables: {
              collectionId,
              robotLogIds: excludedRobotLogIds,
            },
            mutation: 'addExcludedRobotLogsToCollection',
            callback,
          });
        },
      ],
      (err) => {
        if (err) {
          showErrorSnack(err);
        }

        if (collectionId) {
          this.setState({ redirecting: true });
          setTimeout(() => {
            hideModal();
            history.push(`/app/collections/${collectionId}`);
          }, 800);
        } else {
          this.setState({ submitting: false });
        }
      }
    );
  }

  onIsPrivateChanged = (evt, checked) => {
    this.setState({
      isPrivateForCreator: checked,
    });
  };

  onIsPubliclyEditableChanged = (evt, checked) => {
    this.setState({
      isPubliclyEditable: checked,
    });
  };

  render() {
    const {
      hideModal,
      classes,
      title,
      robotLogSearchVariables,
  
    } = this.props;
    const {
      mounted,
      name,
      description,
      submitting,
      redirecting,
      isUpdate,
      isPrivateForCreator,
      isPubliclyEditable,
    } = this.state;

    if (!mounted) {
      return null;
    }

    const ctaText = isUpdate ? 'Save' : 'Create';

    return (
      <Paper className={classes.container}>
        <ModalHeader onClose={hideModal}>{title}</ModalHeader>

        <InputGroup label="Name">
          <TextField
            error={this.state.nameShowError}
            placeholder={strings.COLLECTION_NAME_PLACEHOLDER}
            value={name}
            onChange={this.handleChange('name')}
            autoFocus
          />
        </InputGroup>

        <InputGroup label="Description">
          <TextField
            value={description}
            onChange={this.handleChange('description')}
            placeholder={strings.COLLECTION_DESCRIPTION_PLACEHOLDER}
            multiline
            rows={2}
            maxRows={4}
          />
        </InputGroup>

        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={isPrivateForCreator}
              onChange={this.onIsPrivateChanged}
            />
          }
          label={strings.COLLECTION_IS_PRIVATE_FOR_CREATOR_LABEL}
        />

        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={isPubliclyEditable}
              onChange={this.onIsPubliclyEditableChanged}
            />
          }
          label={strings.COLLECTION_IS_PUBLICLY_EDITABLE}
        />
        {!!robotLogSearchVariables && (
          <RuleSetPreview robotLogSearchVariables={robotLogSearchVariables} />
        )}

        <div className={classes.buttonContainer}>
          <ProgressSubmitButton
            onClick={this.onSubmit}
            color="primary"
            isSubmitting={submitting || redirecting}
          >
            {redirecting ? 'Success!, Redirecting...' : ctaText}
          </ProgressSubmitButton>
        </div>
      </Paper>
    );
  }
}

export default container(withStyles(styles)(CreateUpdateCollectionModal));
