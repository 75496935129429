import {
  BaseFilterValue,
  BaseQueryFilter,
  BaseFilterProps,
} from './baseQueryFilter';
import { QueryFilterTypes } from './common';

export interface KeywordsQueryFilterValue extends BaseFilterValue {
  input?: string;
}

export type KeywordsControlData = {
  placeholder: string;
  validateInput: (
    input: string
  ) => Promise<{ isValid: boolean; message: string }>;
};

export interface KeywordsQueryFilterProps
  extends Omit<BaseFilterProps, 'esField'> {
  esFields?: {
    // tokenized document search
    searchFields?: string[];
  };
  placeholder?: string;
  validateQuery?: (
    query: object
  ) => Promise<{ isValid: boolean; message: string }>;
}

export class KeywordsQueryFilter extends BaseQueryFilter<
  KeywordsQueryFilterValue,
  KeywordsQueryFilterProps,
  KeywordsControlData
> {
  type = QueryFilterTypes.keywords;

  hasValue(value: KeywordsQueryFilterValue = {}): boolean {
    return value && value.input && value.input.length > 0;
  }

  initControlData(props: KeywordsQueryFilterProps): KeywordsControlData {
    return {
      placeholder: props.placeholder,
      validateInput: (input: string) => {
        if (input === '' || !props.validateQuery) {
          return Promise.resolve({ isValid: true, message: '' });
        }
        const query = this.getElasticQuery({ input });
        if (!query) {
          return Promise.resolve({ isValid: true, message: '' });
        }

        return props.validateQuery(query);
      },
    };
  }

  getElasticQuery(value: KeywordsQueryFilterValue): object | void {
    if (!this.hasValue(value)) {
      return;
    }
    const { esFields } = this.props;
    if (!esFields) {
      return null;
    }
    const { searchFields = [] } = esFields;
    const { input } = value;

    return {
      query_string: {
        query: input,
        fields: searchFields,
        // synonyms like "ny" become "new york", I don't think this is right for our type of data
        auto_generate_synonyms_phrase_query: false,
      },
    };
  }

  getValuePreview(value: KeywordsQueryFilterValue): string {
    if (!value) {
      return '';
    }
    return value.input || '';
  }
}
