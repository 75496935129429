import React from 'react';
import _ from 'lodash';
import { gql } from '@apollo/client';
import client from '../../apollo-client';

import { HangarUserRobotLogDetailSortEnum } from '@shield-ui/hangar-service';
import { getSessionLogDisplayName } from '../../services/robotLogs';
import {
  useHexColor,
  useSessionLogTypeNames,
} from 'apps/vision/src/app/services/sessionLogTypes';
import { getSearchResults } from '../../services/omnisearch';
import { getRecentCollections } from '../../services/collections';
import { OmnisearchPopover } from '@shield-ui/omnisearch';
import { getRobotLogUrl } from '../../services/robotLogs';
import Spin from 'antd/es/spin';

import { colors } from '@shield-ui/styles';

const recentRobotLogsQuery = gql`
  query userRobotLogDetails(
    $userIds: [UUID]
    $sort: [UserRobotLogDetailSortEnum]
    $first: Int
  ) {
    userRobotLogDetails(userIds: $userIds, sort: $sort, first: $first) {
      edges {
        node {
          id
          userId
          viewedLastAt
          robotLog {
            name
            id
            sessionLogTypeSlug
            displayName
          }
        }
      }
    }
  }
`;

export const getRecentRobotLogs = (userId, limit) => {
  return client
    .query({
      query: recentRobotLogsQuery,
      variables: {
        userId: [userId],
        sort: [HangarUserRobotLogDetailSortEnum.ViewedLastAtDesc],
        first: limit,
      },
      fetchPolicy: 'network-only',
    })
    .then((r) => {
      const edges = _.get(r, 'data.userRobotLogDetails.edges');
      if (!edges) {
        throw new Error(`Could not find recent robot logs`);
      }
      return edges.map((edge) => edge.node.robotLog);
    });
};

//Format the json into omnisearch results format
const formatItems = (items, isRobotLogs) => {
  const formattedItems = [];
  for (const item of items) {
    if (isRobotLogs) {
      formattedItems.push({
        label: getSessionLogDisplayName(item),
        link: getRobotLogUrl(item),
      });
    } else {
      formattedItems.push({
        label: item.name,
        link: '/app/collections/'.concat(item.id),
      });
    }
  }
  return formattedItems;
};

const formatCollections = (collections) => {
  let formattedCollections = [];
  if (collections.length) {
    formattedCollections = [
      {
        groupLabel: createGroupAccentHeader(
          colors.hues.grays[100],
          'Collections'
        ),
        items: formatItems(collections, false),
      },
    ];
  }

  return formattedCollections;
};

const createGroupAccentHeader = (accentColor, displayName) => {
  const styles = {
    accent: {
      width: 10,
      height: 10,
      borderRadius: '50%',
      marginRight: 8,
      marginTop: '2px',
      backgroundColor: accentColor,
    },
    headerContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
  };
  return (
    <div style={styles.headerContainer as React.CSSProperties}>
      <div style={styles.accent} />
      <div>{displayName}</div>
    </div>
  );
};

const getGroupHeader = (slug, hexColorList, sessionLogTypesNames) => {
  const displayName = sessionLogTypesNames[slug];
  const accentColor = hexColorList[slug] || colors.hues.grays[180];
  if (!displayName) {
    return;
  }
  return createGroupAccentHeader(accentColor, displayName + 's');
};

const formatRobotLogs = (robotLogs, hexColorList, sessionLogTypesNames) => {
  return _(robotLogs)
    .groupBy((x) => x.sessionLogTypeSlug)
    .map((value, slug) => ({
      groupLabel: getGroupHeader(slug, hexColorList, sessionLogTypesNames),
      items: formatItems(value, true),
    }))
    .value();
};

const getFormattedSearchResults = (
  input,
  callback,
  hexColorList,
  sessionLogTypesNames
) => {
  getSearchResults(input).then(
    (r) => {
      let formattedResults = formatRobotLogs(
        r.robotLogs,
        hexColorList,
        sessionLogTypesNames
      );
      const formattedCollections = formatCollections(r.collections);

      if (!_.isEmpty(formattedCollections)) {
        formattedResults = formattedResults.concat(formattedCollections);
      }

      if (formattedResults.length) {
        callback({ header: 'Search Results', hits: formattedResults });
      } else {
        callback({ header: 'No Results', hits: [] });
      }
    },
    (err) => {
      callback({ header: err.toString(), hits: [] });
    }
  );
};

const getRecentResults = (
  userId,
  callback,
  hexColorList,
  sessionLogTypesNames
) => {
  getRecentRobotLogs(userId, 10).then(
    (recentRobotLogs) => {
      const formattedRobotLogs = formatRobotLogs(
        recentRobotLogs,
        hexColorList,
        sessionLogTypesNames
      );
      const formattedCollections = formatCollections(getRecentCollections());
      const hits = formattedRobotLogs.concat(formattedCollections);
      callback({ header: 'Recently Viewed', hits: hits });
    },
    (err) => {
      callback({ header: err.toString(), hits: [] });
    }
  );
};

const onSearchRequest = (
  input,
  callback,
  userId,
  hexColorList,
  sessionLogTypesNames
) => {
  if (input === '') {
    getRecentResults(userId, callback, hexColorList, sessionLogTypesNames);
  } else {
    if (input.length > 2) {
      getFormattedSearchResults(
        input,
        callback,
        hexColorList,
        sessionLogTypesNames
      );
    } else {
      callback({ header: 'Please Type More Characters', hits: [] });
    }
  }
};

type VisionOmnisearchProps = {
  currentUser: any;
};

function VisionOmnisearch(props: VisionOmnisearchProps) {
  const { currentUser } = props;
  const {
    data: hexColorList,
    isLoading: colorsIsLoading,
    isError: colorsIsError,
    error: colorsError,
  } = useHexColor();
  const {
    data: sessionLogTypesNames,
    isLoading: namesIsLoading,
    isError: namesIsError,
    error: namesError,
  } = useSessionLogTypeNames();

  if (colorsIsLoading || namesIsLoading) {
    return <Spin />;
  }

  return (
    <OmnisearchPopover
      onSearchRequest={(input, callback) => {
        onSearchRequest(
          input,
          callback,
          currentUser.id,
          hexColorList,
          sessionLogTypesNames
        );
      }}
      placeholder="Search for Flights, Collections, ..."
    />
  );
}

export default VisionOmnisearch;
