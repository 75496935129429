import Cell, { getParsedEvents } from './Cell';
const config = {
  columnKey: 'eventTags',
  label: 'Event Failure Types',
  description: 'Events > Failure Types',
  defaultWidth: 240,
  sortKey: 'events.tag.name.keyword',
  Cell,
  getValue: ({ row }) => {
    return getParsedEvents(row)
      .map(({ tagName, severityName }) => {
        return `${tagName} [${severityName}]`;
      })
      .join('\n');
  },
  fragment: `
    events {
        edges {
            node {
              tag {
                name
                description
                severity {
                  name
                }
              }
            }
        }
    }
    `,
};

export default config;
