import {
  SessionLogEntityLink,
  SessionLogEntityLinkService,
  CreateRequest,
  SearchRequest,
  EntityLinkView,
  DeleteRequest,
} from '@hmd/sdk/api/session_log/entity_link/v1';
import {
  Entity,
  EntityService,
  CreateRequest as CreateEntityRequest,
  UpdateRequest as UpdateEntityRequest,
  SearchRequest as SearchEntityRequest,
  SearchRequestFilters,
} from '@hmd/sdk/api/systems/entity/v1';

import sdkClient from '../lib/hmdsdkClient';
import { Struct } from 'google-protobuf/google/protobuf/struct_pb';
import { EntitySchemaJSON } from '../services/entitySchemas';
import { search } from '@hmd/sdk';

export const getSessionLogEntities = async (sessionId: string) => {
  const searchRequest = new SearchRequest();
  searchRequest.setSessionLogId(sessionId);
  searchRequest.setView(EntityLinkView.FULL);

  const response = await sdkClient.unary(
    SessionLogEntityLinkService.Search,
    searchRequest
  );

  return response;
};

export const createEntityLink = async (sessionId: string, entityId: string) => {
  const createRequest = new CreateRequest();
  const entityLink = new SessionLogEntityLink();
  entityLink.setSessionLogId(sessionId);
  entityLink.setEntityId(entityId);
  createRequest.setSessionLogEntityLink(entityLink);

  const response = await sdkClient.unary(
    SessionLogEntityLinkService.Create,
    createRequest
  );

  return response;
};

export type EntityJSON = {
  id?: string;
  entitySchemaSlug: string;
  properties: object;
  entitySchema?: EntitySchemaJSON;
};

export const createEntity = async (entity: EntityJSON) => {
  const createRequest = new CreateEntityRequest();
  const newEntity = new Entity();
  newEntity.setEntitySchemaSlug(entity.entitySchemaSlug);
  newEntity.setProperties(Struct.fromJavaScript(entity.properties as any));
  createRequest.setEntity(newEntity);

  const response = await sdkClient.unary(EntityService.Create, createRequest);

  return response;
};

export const updateEntity = async (entity: EntityJSON) => {
  const createRequest = new UpdateEntityRequest();
  const newEntity = new Entity();
  newEntity.setId(entity.id);
  newEntity.setEntitySchemaSlug(entity.entitySchemaSlug);
  newEntity.setProperties(Struct.fromJavaScript(entity.properties as any));
  createRequest.setEntity(newEntity);

  const response = await sdkClient.unary(EntityService.Update, createRequest);

  return response;
};

export const searchEntities = async (
  entitySchemaSlug: string,
  query: string
) => {
  const searchRequest = new SearchEntityRequest();
  const filters = new SearchRequestFilters();
  filters.setEntitySchemaSlugsList([entitySchemaSlug]);
  filters.setQuery(query);
  searchRequest.setFilters(filters);
  searchRequest.setView(EntityLinkView.FULL);

  const response = await sdkClient.unary(EntityService.Search, searchRequest);

  return response;
};

export const deleteEntityLink = async (entityLinkId: string) => {
  const deleteRequest = new DeleteRequest();
  deleteRequest.setId(entityLinkId);

  const response = await sdkClient.unary(
    SessionLogEntityLinkService.Delete,
    deleteRequest
  );

  return response;
};
