import React from 'react';
import _ from 'lodash';
import withStyles from '@mui/styles/withStyles';

function styles() {
  return {
    sep: {
      fontWeight: 100,
      color: '#aaa',
      paddingLeft: 5,
      paddingRight: 5,
    },
  };
}

function SoftwareInfoCell({ row, classes }) {
  const { softwareInfo } = row;

  if (!softwareInfo) {
    return null;
  }

  const version = _.get(softwareInfo, 'version', '');
  const branchName = _.get(softwareInfo, 'branchName', '');

  let sep;
  if (version && branchName) {
    sep = <span className={classes.sep}>{' -> '}</span>;
  }

  return (
    <div>
      {version}
      {sep}
      {branchName}
    </div>
  );
}

export default withStyles(styles)(SoftwareInfoCell);
