import moment from 'moment';
import {
  BaseFilterValue,
  BaseQueryFilter,
  BaseFilterProps,
} from './baseQueryFilter';
import { QueryFilterTypes } from './common';

export enum DatetimeMode {
  absolute = 'absolute',
  relative = 'relative',
}

export enum DatetimeAbsoluteInputMode {
  local = 'local',
  utc = 'utc',
  unix = 'unix',
}

export interface DatetimeQueryFilterValue extends BaseFilterValue {
  mode?: DatetimeMode;
  absoluteInputMode?: DatetimeAbsoluteInputMode;
  startDatetime?: string;
  endDatetime?: string;
  relativeNumberOfDays?: number;
  relativeStartDaysAgo?: number;
  startUnix?: number;
  endUnix?: number;
}

export interface DatetimeQueryFilterControlData {
  modes: DatetimeMode[];
  displayDateFormat: string;
  getValueMode: (value: DatetimeQueryFilterValue) => DatetimeMode;
  getValueAbsoluteInputMode: (
    value: DatetimeQueryFilterValue
  ) => DatetimeAbsoluteInputMode;
}

export interface DatetimeQueryFilterProps extends BaseFilterProps {
  modes?: DatetimeQueryFilterControlData['modes'];
  displayDateFormat?: DatetimeQueryFilterControlData['displayDateFormat'];
}

export class DatetimeQueryFilter extends BaseQueryFilter<
  DatetimeQueryFilterValue,
  DatetimeQueryFilterProps,
  DatetimeQueryFilterControlData
> {
  static defaultMode = DatetimeMode.absolute;
  static defaultTimezone = DatetimeAbsoluteInputMode.local;
  static dateFormat = 'YYYY-MM-DDTHH:mm:ssZ';

  type = QueryFilterTypes.datetime;

  initControlData(
    props: DatetimeQueryFilterProps
  ): DatetimeQueryFilterControlData {
    return {
      modes: props.modes || [DatetimeMode.absolute, DatetimeMode.relative],
      displayDateFormat: props.displayDateFormat || 'MM.DD.YY HH:mm',
      getValueMode: this.getValueMode,
      getValueAbsoluteInputMode: this.getValueTimezone,
    };
  }

  getValueMode(value: DatetimeQueryFilterValue): DatetimeMode {
    if (!value || !value.mode) {
      return DatetimeMode.absolute;
    }
    return value.mode;
  }

  getValueTimezone(value: DatetimeQueryFilterValue): DatetimeAbsoluteInputMode {
    if (!value || !value.absoluteInputMode) {
      return DatetimeAbsoluteInputMode.local;
    }
    return value.absoluteInputMode;
  }

  hasValue(value: DatetimeQueryFilterValue): boolean {
    if (!value) {
      return false;
    }

    const mode = this.getValueMode(value);

    if (mode === DatetimeMode.absolute) {
      return !!(value.startDatetime || value.endDatetime);
    } else if (mode === DatetimeMode.relative) {
      return value.relativeNumberOfDays > 0;
    }

    return false;
  }

  getElasticQuery(value: DatetimeQueryFilterValue): object | void {
    if (!this.props.esField) {
      return;
    }
    if (!this.hasValue(value)) {
      return;
    }

    if (value.mode === DatetimeMode.relative) {
      const { relativeNumberOfDays, relativeStartDaysAgo = 0 } = value;
      if (!relativeNumberOfDays) {
        return;
      }

      return {
        range: {
          [this.props.esField]: {
            gte: `now-${relativeNumberOfDays + relativeStartDaysAgo}d/d`,
            lte: `now-${relativeStartDaysAgo}d/d`,
          },
        },
      };
    } else {
      const { startDatetime, endDatetime } = value;

      // weird grpc encoding issue when we have an undefined value
      // we are passing our JSON payload as a gRPC struct in a "passthrough query" to ElasticSearch
      const val: {gte?: string; lte?: string} = {};
      if (startDatetime) {
        val.gte = startDatetime;
      }
      if (endDatetime) {
        val.lte = endDatetime;
      }

      // nothing to do
      if (Object.keys(val).length === 0) {
        return;
      }

      return {
        range: {
          [this.props.esField]: val
        },
      };
    }
  }

  getDateDisplayMoment(
    timestamp: string,
    absoluteInputMode: DatetimeAbsoluteInputMode
  ): moment.Moment {
    if (!timestamp) {
      return;
    }
    const tzOffset = moment().utcOffset();
    const off =
      absoluteInputMode === DatetimeAbsoluteInputMode.local ? 0 : tzOffset;
    return moment(timestamp, DatetimeQueryFilter.dateFormat).subtract(
      off,
      'minutes'
    );
  }

  getValuePreview(value: DatetimeQueryFilterValue): string {
    if (!this.hasValue(value)) {
      return '';
    }
    const mode = this.getValueMode(value);

    if (mode === DatetimeMode.absolute) {
      const { startDatetime, endDatetime, absoluteInputMode } = value;
      const { displayDateFormat } = this.getControlData();

      const startM = this.getDateDisplayMoment(
        startDatetime,
        absoluteInputMode
      );
      const endM = this.getDateDisplayMoment(endDatetime, absoluteInputMode);

      let prefix = '';
      if (absoluteInputMode === DatetimeAbsoluteInputMode.unix) {
        prefix = 'Unix: ';
      } else if (absoluteInputMode === DatetimeAbsoluteInputMode.utc) {
        prefix = 'UTC: ';
      } else if (absoluteInputMode === DatetimeAbsoluteInputMode.local) {
        prefix = '';
      }

      if (startM && endM) {
        if (endM.diff(startM, 'days') < 1) {
          return `${prefix}${startM.format(displayDateFormat)} to ${endM.format(
            displayDateFormat
          )}`;
        } else {
          return `${prefix}${startM.format('MM.DD.YY')} to ${endM.format(
            'MM.DD.YY'
          )}`;
        }
      } else if (startDatetime) {
        return `${prefix}After ${startM.format(displayDateFormat)}`;
      } else if (endDatetime) {
        return `${prefix}Before ${endM.format(displayDateFormat)}`;
      }
    } else if (mode === DatetimeMode.relative) {
      const { relativeNumberOfDays, relativeStartDaysAgo = 0 } = value;

      if (relativeStartDaysAgo === 0) {
        return `Last ${relativeNumberOfDays} days`;
      } else {
        return `${
          relativeNumberOfDays + relativeStartDaysAgo
        } - ${relativeStartDaysAgo} days ago`;
      }
    }

    return '';
  }
}
