import _ from 'lodash';
import { MultiselectQueryFilter } from '@shield-ui/query-filters';
import {fetchAllTestDefinitions} from '../../../services/testDefinitions';

export default new MultiselectQueryFilter({
  id: 'testDefinitionCode',
  controlLabel: 'Test Codes (New)',
  esField: 'test_definition.code.keyword',
  behaviorConfig: {
    fetchOptions: () => {
      return fetchAllTestDefinitions().then((res) => {
        let opts = res.map(td => {
          return {
            value: td.code,
            label: td.code,
          }
        });
        opts = _.uniqBy(opts, 'value');
        opts = _.sortBy(opts, 'label');
        return opts;
      });
    },
  },
});
