import _ from 'lodash';
import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
  AsyncThunkPayloadCreator,
} from '@reduxjs/toolkit';
import { showConfirmPrompt, showError, showSuccess } from '../lib/messages';
import { RootState } from './store';
import tracker from '../lib/tracker';
import sdkClient from '../lib/hmdsdkClient';
import { FieldMask } from 'google-protobuf/google/protobuf/field_mask_pb';
import * as definitionsV1 from '@hmd/sdk/api/tests/definitions/v1';
import * as platformsV1 from '@hmd/sdk/api/tests/definitions/platforms/v1';
import * as platformSubtypesV1 from '@hmd/sdk/api/tests/definitions/platform_subtypes/v1';
import * as frequencyGroupsV1 from '@hmd/sdk/api/tests/frequency_groups/v1';

export const TEST_DEFINITIONS_FEATURE_KEY = 'testDefinitions';

export type PlatformSubtypeAsObject = ReturnType<
  typeof platformSubtypesV1.PlatformSubtype.toObject
>;
export type PlatformAsObject = ReturnType<typeof platformsV1.Platform.toObject>;
export type FrequencyGroupAsObject = ReturnType<
  typeof frequencyGroupsV1.TestFrequencyGroup.toObject
>;

interface State {
  updatingId: string;
  cloningId: string;
  platforms: PlatformAsObject[];
  platformSubtypes: PlatformSubtypeAsObject[];
  frequencyGroups: FrequencyGroupAsObject[];
}

const initialState: State = {
  updatingId: undefined,
  cloningId: undefined,
  platforms: undefined,
  platformSubtypes: undefined,
  frequencyGroups: undefined,
};

export const ensurePlatforms = createAsyncThunk(
  `${TEST_DEFINITIONS_FEATURE_KEY}/fetchPlatforms`,
  (arg, thunkAPI) => {
    const state = thunkAPI.getState();
    if (state[TEST_DEFINITIONS_FEATURE_KEY].platforms) {
      return Promise.resolve(state[TEST_DEFINITIONS_FEATURE_KEY].platforms);
    }

    const req = new platformsV1.ListRequest();
    req.setPageSize(200);

    return sdkClient
      .unary(platformsV1.PlatformService.List, req)
      .then((result) => {
        return result.getPlatformsList().map((subType) => subType.toObject());
      })
      .catch((e) => {
        showError(e);
        return [];
      });
  }
);

export const ensurePlatformSubtypes = createAsyncThunk(
  `${TEST_DEFINITIONS_FEATURE_KEY}/fetchPlatformSubtypes`,
  (args, thunkAPI) => {
    const state = thunkAPI.getState();
    if (state[TEST_DEFINITIONS_FEATURE_KEY].platformSubtypes) {
      return Promise.resolve(
        state[TEST_DEFINITIONS_FEATURE_KEY].platformSubtypes
      );
    }

    const req = new platformSubtypesV1.ListRequest();
    req.setPageSize(200);

    return sdkClient
      .unary(platformSubtypesV1.PlatformSubtypeService.List, req)
      .then((result) => {
        return result
          .getPlatformSubtypesList()
          .map((subType) => subType.toObject());
      })
      .catch((e) => {
        showError(e);
        return [];
      });
  }
);

export const ensureFrequencyGroups = createAsyncThunk(
  `${TEST_DEFINITIONS_FEATURE_KEY}/fetchFrequencyGroups`,
  (arg, thunkAPI) => {
    const state = thunkAPI.getState();
    if (state[TEST_DEFINITIONS_FEATURE_KEY].frequencyGroups) {
      return Promise.resolve(
        state[TEST_DEFINITIONS_FEATURE_KEY].frequencyGroups
      );
    }

    const req = new frequencyGroupsV1.ListRequest();
    req.setPageSize(200);

    return sdkClient
      .unary(frequencyGroupsV1.TestFrequencyGroupService.List, req)
      .then((result) => {
        return result.getTestFrequencyGroupsList().map((fg) => fg.toObject());
      })
      .catch((e) => {
        showError(e);
        return [];
      });
  }
);

interface CreatePlatformSubtypeArgs {
  abbreviation: string;
  name: string;
  description: string;
  platformIdsList: string[];
}

function withGrpcErrorHandling<Args, Returned, ThunkApiConfig>(
  payloadCreator: AsyncThunkPayloadCreator<Returned, Args, ThunkApiConfig>
) {
  return async (args, thunkApi) => {
    try {
      return await payloadCreator(args, thunkApi);
    } catch (e) {
      //showError(e.statusMessage || e.message);
      return thunkApi.rejectWithValue({
        message: e.statusMessage || e.message,
        code: e.status,
      });
    }
  };
}

export const createPlatformSubtype = createAsyncThunk(
  `${TEST_DEFINITIONS_FEATURE_KEY}/createPlatformSubtype`,
  withGrpcErrorHandling(async (args: CreatePlatformSubtypeArgs) => {
    tracker.trackEvent('PlatformSubtype', 'Create', 'Name', args.name);
    const subType = new platformSubtypesV1.PlatformSubtype();
    subType.setAbbreviation(args.abbreviation);
    subType.setName(args.name);
    subType.setDescription(args.description);
    subType.setPlatformIdsList(args.platformIdsList);

    const req = new platformSubtypesV1.CreateRequest();
    req.setPlatformSubtype(subType);

    const result = await sdkClient.unary(
      platformSubtypesV1.PlatformSubtypeService.Create,
      req
    );
    return result.toObject();
  })
);

interface UpdatePlatformSubtypeArgs
  extends Omit<CreatePlatformSubtypeArgs, 'abbreviation'> {
  id: string;
}
export const updatePlatformSubtype = createAsyncThunk(
  `${TEST_DEFINITIONS_FEATURE_KEY}/updatePlatformSubtype`,
  withGrpcErrorHandling(async (args: UpdatePlatformSubtypeArgs) => {
    tracker.trackEvent('PlatformSubtype', 'Update', 'id', args.id);
    const subType = new platformSubtypesV1.PlatformSubtype();
    subType.setId(args.id);
    subType.setName(args.name);
    subType.setDescription(args.description);
    subType.setPlatformIdsList(args.platformIdsList);

    const req = new platformSubtypesV1.UpdateRequest();
    req.setPlatformSubtype(subType);
    const mask = new FieldMask();
    mask.setPathsList(['*']);
    req.setUpdateMask(mask);

    const result = await sdkClient.unary(
      platformSubtypesV1.PlatformSubtypeService.Update,
      req
    );
    return result.toObject();
  })
);

export const deleteTestDefinition = createAsyncThunk(
  `${TEST_DEFINITIONS_FEATURE_KEY}/deleteTestDefinition`,
  async (args: { id: string; onDelete?: (id: string) => void }, thunkAPI) => {
    const { id, onDelete } = args;

    showConfirmPrompt({
      title: 'Delete Test Definition',
      body: 'Are you sure you want to delete this test definition?',
      confirmLabel: 'Delete',
      onConfirm: () => {
        tracker.trackEvent('TestDefinition', 'Delete', 'id', id);
        const req = new definitionsV1.DeleteRequest();
        req.setId(id);

        sdkClient
          .unary(definitionsV1.TestDefinitionService.Delete, req)
          .then(() => {
            if (onDelete) {
              onDelete(id);
            }
            showSuccess('Deleted Test Definition');
          })
          .catch(showError);
      },
    });
  }
);

export const testDefinitionsSlice = createSlice({
  name: TEST_DEFINITIONS_FEATURE_KEY,
  initialState,
  reducers: {
    showUpdatingForm: (state, action: PayloadAction<{ id: string }>) => {
      state.updatingId = action.payload.id;
    },
    showCloningForm: (state, action: PayloadAction<{ id: string }>) => {
      state.cloningId = action.payload.id;
    },
    hideAllForms: (state) => {
      state.updatingId = undefined;
      state.cloningId = undefined;
    },
  },
  extraReducers: {
    [ensurePlatforms.fulfilled.type]: (
      state,
      action: PayloadAction<PlatformAsObject[]>
    ) => {
      state.platforms = _.sortBy(action.payload, 'abbreviation');
    },
    [ensurePlatformSubtypes.fulfilled.type]: (
      state,
      action: PayloadAction<PlatformSubtypeAsObject[]>
    ) => {
      state.platformSubtypes = _.sortBy(action.payload, 'abbreviation');
    },
    [ensureFrequencyGroups.fulfilled.type]: (
      state,
      action: PayloadAction<FrequencyGroupAsObject[]>
    ) => {
      state.frequencyGroups = action.payload;
    },
    [createPlatformSubtype.fulfilled.type]: (
      state,
      action: PayloadAction<PlatformSubtypeAsObject>
    ) => {
      state.platformSubtypes.unshift(action.payload);
      //state.platformSubtypes = _.sortBy(state.platformSubtypes, 'abbreviation');
    },
    [updatePlatformSubtype.fulfilled.type]: (
      state,
      action: PayloadAction<PlatformSubtypeAsObject>
    ) => {
      state.platformSubtypes = state.platformSubtypes.map((subType) => {
        if (action.payload.id === subType.id) {
          // replace
          return action.payload;
        }
        return subType;
      });
    },
  },
});

/************************************************************************************
- BEGIN Selectors
************************************************************************************/

export const selectPlatforms = (state: RootState) =>
  state[TEST_DEFINITIONS_FEATURE_KEY].platforms;
export const selectPlatformSubtypes = (state: RootState) =>
  state[TEST_DEFINITIONS_FEATURE_KEY].platformSubtypes;
export const selectFrequencyGroups = (state: RootState) =>
  state[TEST_DEFINITIONS_FEATURE_KEY].frequencyGroups;

export const selectPlatformSubtypeOptions = createSelector(
  selectPlatformSubtypes,
  (
    platformSubtypes
  ):
    | { value: string; label: string; source: PlatformSubtypeAsObject }[]
    | void => {
    if (!platformSubtypes) {
      return;
    }
    return platformSubtypes.map((platformSubType) => ({
      value: platformSubType.id,
      label: `${platformSubType.name} (${platformSubType.abbreviation})`,
      source: platformSubType, // pointer to the full object
    }));
  }
);

export const selectPlatformOptions = createSelector(
  selectPlatforms,
  (
    platforms
  ): { value: string; label: string; source: PlatformAsObject }[] | void => {
    if (!platforms) {
      return;
    }
    return platforms.map((platform) => ({
      value: platform.id,
      label: `${platform.name} (${platform.abbreviation})`,
      source: platform, // pointer to the full object
    }));
  }
);

export type PlatformMetaField = {
  key: string;
  label: string;
  type: 'text' | 'textArea' | 'number';
};

export const selectPlatformMetaFieldsAllPlatforms = createSelector(
  selectPlatforms,
  (platforms): Record<string, PlatformMetaField[]> | void => {
    if (!platforms) {
      return;
    }

    return platforms.reduce((acc, platform) => {
      const fields = [];

      if (platform.abbreviation === 'REAL') {
        fields.push(
          {
            key: 'missionString',
            label: 'Mission String',
            type: 'text',
          },
          {
            key: 'priority',
            label: 'Priority',
            type: 'number',
          }
        );
      }

      acc[platform.id] = fields;
      return acc;
    }, {});
  }
);
