import React, {useState,useEffect} from 'react';
import plotTypeManager from './plotTypeManager'
import {FormOutlined, DeleteOutlined, LoadingOutlined} from '@ant-design/icons'
import makeStyles from '@mui/styles/makeStyles';
import { StyledPlot } from '@shield-ui/analysis';

const useStyles = makeStyles((theme) => ({
  bg:{
    zIndex:-1,
    filter:'blur(2px)'
  },
  loader:{
    position: 'absolute',
    color: 'white',
    zIndex:2,
    top: '50%',
    left: '50%'
  }
}))


export default function PlotWrapper(props:{plotConfig, query, onUpdate, onDelete}) {
  const {plotConfig, query, onUpdate, onDelete} = props;
  const [isLoading, setIsLoading] = useState(false);
  const [configResponse, setConfigResponse] = useState(undefined)
  const [prevResponse, setPrevResponse] = useState({})
  const classes = useStyles();
  const actions= [
    {
      icon:<FormOutlined />,
      label:"Update",
      key:"update",
      onClick: ()=>onUpdate(plotConfig)
    },
    {
      icon:<DeleteOutlined/>,
      label:"Delete",
      key:"delete",
      onClick: ()=>onDelete(plotConfig)
    }
  ]
  useEffect(() => {
		const fetchData = async() => {
      setPrevResponse(configResponse)
      setIsLoading(true);
      const data = await plotTypeManager.fetchData(query, plotConfig);
      setConfigResponse(data)
      setIsLoading(false);
    }
    fetchData();
	  }, [plotConfig, query]
	)
  const loadingLayout = plotTypeManager.loadinglayout(plotConfig.plotTypeId, plotConfig.plotOptions.graphType)
  return(
    <div style={{minHeight:"400px"}}>
     {isLoading ? 
      <div className={classes.bg}>
        <LoadingOutlined className={classes.loader}/>
        <StyledPlot
          data={prevResponse}
          layout={loadingLayout}
          moreActions={actions}
        />
      </div>
       :
      <div>
       {plotTypeManager.renderPlot(plotConfig, configResponse, actions)}
      </div>
    }
    </div>
  )
}