import _ from 'lodash';

const config = {
  columnKey: 'robotHostname',
  label: 'Robot Hostname',
  description: 'Hostname of robot',
  defaultWidth: 180,
  sortKey: 'robots.hostname',
  getValue: function ({ row }) {
    return _.get(row, 'robot.hostname', '');
  },
  fragment: `
        robot {
            hostname
        }
    `,
};
export default config;
