/* eslint-disable react-hooks/rules-of-hooks */
import { gql } from '@apollo/client';
import _ from 'lodash';
import client from '../apollo-client';
import tracker from '../lib/tracker';
import {
  Participant,
  ParticipantService,
  CreateRequest,
  SearchRequest,
  BatchCreateRequest,
  SearchRequestFilters,
  DeleteRequest,
} from '@hmd/sdk/api/session_log/participant/v1';
import sdkClient from '../lib/hmdsdkClient';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { SourceTypeMap } from '@hmd/sdk/api/common/v1';
import moment, { Moment } from 'moment';

const PARTICIPANT_QUERY_KEY = 'participants';

export const fetchParticipantsForSL = (sessionLogId: string) =>
  useQuery({
    queryKey: [PARTICIPANT_QUERY_KEY, { sl: sessionLogId }],
    queryFn: () => searchParticipants(sessionLogId),
  });

const searchParticipants = async (sessionLogId: string) => {
  const req = new SearchRequest();
  const srf = new SearchRequestFilters();
  srf.setSessionLogIdsList([sessionLogId]);
  req.setFilters(srf);
  const response = await sdkClient.unary(ParticipantService.Search, req);
  return response.toObject().participantsList;
};

type NewParticipant = {
  sessionLogId: string;
  userFreeform?: string;
  userId?: string;
  teamFreeform?: string;
  teamId?: string;
  participantTypeId: string;
};

export type NewBatchParticipants = {
  sessionLogId: string;
  participantTypeId: string;
  idType: 'userId' | 'userFreeform' | 'teamId' | 'teamFreeform';
  id: string;
  sourceType:
    | SourceTypeMap['OBSERVER']
    | SourceTypeMap['REVIEW']
    | SourceTypeMap['AUTOGENERATED']
    | SourceTypeMap['SOURCE_TYPE_UNSPECIFIED'];
};

export type VBATParticipantAlaris = {
  participant: NewBatchParticipants;
  launch: number;
  recovery: number;
  totalTime: number;
  phaseOfFlight: string;
  name: string;
};

export const batchCreateParticipants = async (
  createFieldsArr: NewBatchParticipants[]
) => {
  const createReqs = createFieldsArr.map((e) => {
    const { sessionLogId, participantTypeId, idType, id, sourceType } = e;
    const req = new CreateRequest();
    const participant = new Participant();
    switch (idType) {
      case 'teamFreeform':
        participant.setTeamFreeform(id);
        break;
      case 'teamId':
        participant.setTeamId(id);
        break;
      case 'userFreeform':
        participant.setUserFreeform(id);
        break;
      case 'userId':
        participant.setUserId(id);
        break;
    }
    participant.setSessionLogId(sessionLogId);
    participant.setParticipantTypeId(participantTypeId);
    participant.setSourceType(sourceType);
    req.setParticipant(participant);
    return req;
  });
  const batchCreateRequest = new BatchCreateRequest();
  batchCreateRequest.setRequestsList(createReqs);
  const response = (
    await sdkClient.unary(ParticipantService.BatchCreate, batchCreateRequest)
  ).toObject;
  return response;
};

export const useBatchCreateParticipants = ({ onSuccess, onError }) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: batchCreateParticipants,
    onSuccess: (data, variables) => {
      const { sessionLogId } = variables[0]; //all participants are on the same SL
      queryClient.invalidateQueries({
        queryKey: [PARTICIPANT_QUERY_KEY, { sl: sessionLogId }],
      });
      onSuccess();
    },
    onError: () => {
      onError();
    },
  });
  return mutation;
};

export const createSingleParticipant = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      sessionLogId,
      userFreeform,
      userId,
      teamId,
      teamFreeform,
      participantTypeId,
    }: NewParticipant) => {
      const req = new CreateRequest();
      const participant = new Participant();

      if (userFreeform) {
        participant.setUserFreeform(userFreeform);
      }
      if (userId) {
        participant.setUserId(userId);
      }
      if (teamFreeform) {
        participant.setTeamFreeform(teamFreeform);
      }
      if (teamId) {
        participant.setTeamId(teamId);
      }

      participant.setSessionLogId(sessionLogId);
      participant.setParticipantTypeId(participantTypeId);
      participant.setSourceType(3);
      req.setParticipant(participant);
      const response = await (
        await sdkClient.unary(ParticipantService.Create, req)
      ).toObject;
      return response;
    },
    onSuccess: (data, variables) => {
      const { sessionLogId } = variables;
      queryClient.invalidateQueries({
        queryKey: [PARTICIPANT_QUERY_KEY, { sl: sessionLogId }],
      });
    },
  });
  return mutation;
};

type DeleteParticipantType = {
  participantId: string;
  sessionLogId: string;
};
export const deleteSingleParticipant = ({ onSuccess, onError }) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      participantId,
      sessionLogId,
    }: DeleteParticipantType) => {
      const req = new DeleteRequest();
      req.setId(participantId);
      return await sdkClient.unary(ParticipantService.Delete, req);
    },
    onSuccess: (data, variables) => {
      const { sessionLogId } = variables;
      queryClient.invalidateQueries({
        queryKey: [PARTICIPANT_QUERY_KEY, { sl: sessionLogId }],
      });
      onSuccess();
    },
    onError: () => {
      onError();
    },
  });
  return mutation;
};

export const PARTICIPANT_ROLE_CHOICES = {
  PILOT: 'PILOT',
  OBSERVER: 'OBSERVER',
};

const participantFragment = `
  id
  robotLogId
  participantRole
  userFreeform
  userId
  user {
    id
    name
  }
  sourceTypeId
  sourceType {
    id
    name
  }
`;
const commonCreateUpdateVariablesFragment = `
  participantRole: $participantRole,
  robotLogId: $robotLogId,
  userId: $userId,
  userFreeform: $userFreeform
  sourceTypeId: $sourceTypeId,
`;

const createParticipantMutation = gql`
  mutation createParticipant(
    $participantRole: ParticipantRoleChoice!,
    $robotLogId: UUID!,
    $userId: UUID,
    $userFreeform: String,
    $sourceTypeId: String!,
  ) {
    createParticipant(${commonCreateUpdateVariablesFragment}) {
      ${participantFragment}
    }
  }
`;

const updateParticipantMutation = gql`
  mutation updateParticipant(
    $id: UUID!,
    $participantRole: ParticipantRoleChoice,
    $robotLogId: UUID,
    $userId: UUID,
    $userFreeform: String,
    $sourceTypeId: String
  ) {
    updateParticipant(id: $id,${commonCreateUpdateVariablesFragment}) {
      ${participantFragment}
    }
  }
`;

const deleteParticipantMutation = gql`
  mutation deleteParticipant($id: String!) {
    deleteParticipant(id: $id) {
      id
    }
  }
`;

function runMutation({ variables, mutation, event, callback = _.noop }) {
  return client
    .mutate({
      mutation,
      variables,
    })
    .then(
      (res) => {
        callback(null, res);
        tracker.trackEvent('Flight', event);
        return res;
      },
      (err) => {
        callback(err);
      }
    );
}

export function searchParticipantsBySessionLogId(sessionLogId: string) {
  const sr = new SearchRequest();
  const srf = new SearchRequestFilters();
  const sessionLogIds = [sessionLogId];
  srf.setSessionLogIdsList(sessionLogIds);
  sr.setFilters(srf);

  return sdkClient.unary(ParticipantService.Search, sr);
}

const getParticipantsQuery = gql`
    query participants($robotLogIds: [UUID]) {
        participants(robotLogIds: $robotLogIds) {
            edges {
                node {
                    ${participantFragment}
                }
            }
        },
    }
`;

export function getParticipants(variables, callback) {
  client
    .query({
      query: getParticipantsQuery,
      variables,
      fetchPolicy: 'network-only',
    })
    .then(
      (val) => {
        if (typeof callback === 'function') {
          callback(null, val);
        }
      },
      (err) => {
        if (typeof callback === 'function') {
          callback(err);
        }
      }
    );
}

export function createParticipant(participant: Participant) {
  const cr = new CreateRequest();
  cr.setParticipant(participant);

  return sdkClient.unary(ParticipantService.Create, cr);
}

export function deleteParticipant(participantId) {
  const dr = new DeleteRequest();
  dr.setId(participantId);

  return sdkClient.unary(ParticipantService.Delete, dr);
}

type ParticipantVariables = {
  userId?: string;
  userFreeform?: string;
  robotLogId: string;
  sourceTypeId: string;
  participantRole: string;
};

export function createParticipantOld(
  variables: ParticipantVariables,
  callback?
) {
  return runMutation({
    variables,
    mutation: createParticipantMutation,
    event: 'ParticipantCreate',
    callback,
  });
}

export function updateParticipantOld(variables, callback) {
  return runMutation({
    variables,
    mutation: updateParticipantMutation,
    event: 'ParticipantUpdate',
    callback,
  });
}

export function deleteParticipantOld(variables, callback) {
  return runMutation({
    variables,
    mutation: deleteParticipantMutation,
    event: 'ParticipantDelete',
    callback,
  });
}
// SOURCE_TYPE_IDS_HARDCODED.IN_PERSON_OBSERVATION;
