import _ from 'lodash';
import { Select } from 'antd';
import { fetchRequirementTypes } from '../utils';
import { LabelIdType, LabelType } from '@shield-ui/heatgrid';
import {
  MultiselectQueryFilterValue,
  UNSET_OPTION_VALUE,
  NestedQueryFilterValue,
  NestedValueSet,
} from '@shield-ui/query-filters';
import {
  default as requirementNestedFilter,
  requirementQueryFilter,
} from '../../../lib/queryFilters/robotLog/testDefinitionRequirements';
import sdkClient from '../../../lib/hmdsdkClient';
import {
  BatchGetRequest,
  RequirementService,
} from '@hmd/sdk/api/systems/requirements/v1';
import {
  AxisDefinition,
  AxisOption,
  RenderDropdownFormProps,
} from './AxisDefinition';

export const requirementAxisDefinition: AxisDefinition = {
  id: 'Requirement',
  getDropdownLabel: (axisOption?: AxisOption) => {
    if (
      axisOption &&
      axisOption.requirementType &&
      axisOption.requirementType.length > 0
    ) {
      return `Requirements - ${axisOption.requirementType}`;
    }
    return 'Requirements';
  },
  nestedPath: 'test_definition.requirements',
  buildAggsDsl: () => ({
    terms: {
      field: 'test_definition.requirements.id',
      missing: 'N/A',
    },
  }),
  buildAggsFilterDsl: (filterValues: object, axisOption?: AxisOption) => {
    const queryFilterPath = [
      requirementNestedFilter.getId(),
      'esQuery',
      'nested',
      'query',
      'bool',
      'filter',
    ];
    const queryFilter = _.get(filterValues, queryFilterPath, []);
    return {
      filter: {
        bool: {
          filter: [
            ...queryFilter,
            {
              bool: {
                must: [
                  {
                    term: {
                      'test_definition.requirements.requirement_type':
                        axisOption?.requirementType,
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    };
  },
  prepareAxisLabels: (labelIds: LabelIdType[]) => {
    return new Promise<LabelType[]>((resolve) => {
      const req = new BatchGetRequest();
      req.setIdsList(
        labelIds.filter((labelId) => labelId !== 'N/A') as string[]
      );
      return sdkClient.unary(RequirementService.BatchGet, req).then((res) => {
        const labelMap = res
          .getRequirementsList()
          .reduce((acc, requirement) => {
            return {
              ...acc,
              [requirement.getId()]: requirement,
            };
          }, {});

        let emptyLabel: LabelType = undefined;
        const labels: LabelType[] = _.sortBy(
          labelIds.reduce((acc, requirementId) => {
            if (requirementId === 'N/A') {
              emptyLabel = {
                id: requirementId,
                label: '-',
              };
              return acc;
            } else if (labelMap[requirementId]) {
              return [
                ...acc,
                {
                  id: requirementId,
                  label: labelMap[requirementId].getName(),
                },
              ];
            }
            return acc;
          }, []),
          (label) => label.label
        );

        if (emptyLabel) {
          resolve([...labels, emptyLabel]);
        }
        resolve(labels);
      });
    });
  },
  buildQueryFilter: (
    filterValues: object,
    selectedLabels: LabelType[],
    axisOption?: AxisOption
  ) => {
    // build the NestedValueSet for this nested QF.
    const newRequirementValues: MultiselectQueryFilterValue = {
      includeOptionValues: selectedLabels.map((label) => {
        if (label.id === 'N/A') {
          return UNSET_OPTION_VALUE;
        }
        return label.id;
      }),
    };
    requirementQueryFilter.ensureControlData(newRequirementValues);

    const nestedValueSet: NestedValueSet = _.get(
      filterValues,
      [requirementNestedFilter.getId(), 'nestedValueSets', 0],
      {}
    );
    nestedValueSet[requirementQueryFilter.getId()] = newRequirementValues;

    // Build the Nested value and compute esQuery
    const newValue: NestedQueryFilterValue = {
      nestedValueSets: [nestedValueSet],
    };
    newValue.esQuery = requirementNestedFilter.getElasticQuery(newValue);

    // set the new value for this and return any other existing filter values
    return {
      ...filterValues,
      [requirementNestedFilter.getId()]: newValue,
    };
  },
  fetchOptions: fetchRequirementTypes,
  renderForm: (p: RenderDropdownFormProps) => {
    return (
      <Select
        key={'requirements'}
        className={p.className}
        value={p.axisOption?.requirementType}
        onSelect={(requirementType: string) => p.onChange({ requirementType })}
      >
        {p.options &&
          p.options.map((axisOption) => (
            <Select.Option
              key={`requirement-${axisOption.requirementType}`}
              value={axisOption.requirementType}
              label={axisOption.requirementType}
            >
              {axisOption.requirementType}
            </Select.Option>
          ))}
      </Select>
    );
  },
};
