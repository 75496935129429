import _ from 'lodash';
import Cell from './Cell';

const config = {
  //keeping the robotLogType columnKey so no saved columns are broken
  columnKey: 'robotLogType',
  label: 'Type',
  defaultWidth: 200,
  sortKey: 'session_log_type_slug',
  description:
    'Which type of robot log this is, useful when looking across robot logs of multiple types',
  Cell,
  getValue: ({ row }) => _.get(row, 'sessionLogTypeSlug'),
  fragment: 'sessionLogTypeSlug',
};

export default config;
