import _ from 'lodash';
import { gql } from '@apollo/client';
import {
  NestedQueryFilter,
  TypeToFindMultiselectQueryFilter,
  MultiselectOption,
  MultiselectQueryFilter,
} from '@shield-ui/query-filters';
import { ellipsesStringAt } from '@shield-ui/utils';
import client from '../../../apollo-client';
import { queryTickets } from '../../../services/tickets';
import { robotLogDocumentFields } from './fields';

function processTicketsResponse(res): MultiselectOption[] {
  return res.data.tickets.edges.map((e) => {
    return {
      value: e.node.platformId,
      label: ellipsesStringAt(e.node.name, 80),
    };
  });
}

function getTicketOptions({ values }) {
  return queryTickets({
    platformIds: values,
  }).then((res) => processTicketsResponse(res));
}

function searchSuggestedTicketOptions() {
  return queryTickets({
    first: 10,
    sort: 'applied_to_events_count_desc',
    statusesNotIn: ['Removed', 'Done'],
  }).then((res) => processTicketsResponse(res));
}

function searchTicketOptions({ input }) {
  return queryTickets({
    first: 10,
    textLike: input,
  }).then((res) => processTicketsResponse(res));
}

// this needs to be  nested because it is in nested document in elasticsearch
const eventTickets = new TypeToFindMultiselectQueryFilter({
  id: 'tickets',
  controlLabel: 'Tickets',
  esField: robotLogDocumentFields['events.tickets.platform_id'].field,
  isMulti: true,
  getOptions: getTicketOptions,
  searchSuggestedOptions: searchSuggestedTicketOptions,
  searchOptions: searchTicketOptions,
});

const eventsFetchQueryShared = gql`
  query {
    tags(sort: name_asc) {
      edges {
        node {
          id
          name
          description
          severity {
            name
          }
        }
      }
    }
    severities(sort: name_asc) {
      edges {
        node {
          id
          name
        }
      }
    }
    causes(sort: name_asc) {
      edges {
        node {
          id
          name
          description
        }
      }
    }
  }
`;

const fetchShared = _.memoize(() => {
  return client.query({ query: eventsFetchQueryShared });
});

const eventTags = new MultiselectQueryFilter({
  id: 'events.tags',
  controlLabel: 'Types',
  esField: robotLogDocumentFields['events.tag.id'].field,
  isMulti: true,
  unsetValueDisabled: true,
  behaviorConfig: {
    fetchOptions: () =>
      fetchShared().then((res) => {
        return _.get(res, 'data.tags.edges', []).map(({ node }) => ({
          value: node.id,
          label: node.name,
        }));
      }),
  },
});
const eventSeverity = new MultiselectQueryFilter({
  id: 'events.severity',
  controlLabel: 'Severity',
  esField: robotLogDocumentFields['events.severity.id'].field,
  isMulti: true,
  unsetValueDisabled: true,
  behaviorConfig: {
    fetchOptions: () =>
      fetchShared().then((res) => {
        return _.get(res, 'data.severities.edges', []).map(({ node }) => ({
          value: node.id,
          label: node.name,
        }));
      }),
  },
});

const eventCauses = new MultiselectQueryFilter({
  id: 'events.causes',
  controlLabel: 'Causes',
  esField: robotLogDocumentFields['events.cause.id'].field,
  behaviorConfig: {
    fetchOptions: () =>
      fetchShared().then((res) => {
        return _.get(res, 'data.causes.edges', []).map(({ node }) => ({
          value: node.id,
          label: node.name,
        }));
      }),
  },
});

export default new NestedQueryFilter({
  id: 'nestedEvents',
  controlLabel: 'Failures',
  description:
    'Failures (aka Events) assigned during the manual review process. This group contains Severity, Tickets, and Causes as well.',
  esField: robotLogDocumentFields.events.field,
  notExistsControl: {
    enabled: true,
  },
  queryFilters: [eventTags, eventSeverity, eventTickets, eventCauses],
});
