import React from 'react';
import { Theme, alpha } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import _ from 'lodash';
import Tabs from '@mui/material/Tabs';
import Tab, { TabProps } from '@mui/material/Tab';
import { colors } from '@shield-ui/styles';

const MiniTabs = withStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: 'unset',
      display: 'flex',
      alignItems: 'center',
      borderBottom: `1px solid ${alpha(colors.semantic.selected, 0.5)}`,
      marginBottom: theme.spacing(2),
    },
    indicator: {
      backgroundColor: 'transparent',
      height: 0,
    },
  })
)(Tabs);

interface MiniTabProps extends TabProps {
  valueCount?: number;
}

const MiniTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      minHeight: 'unset',
      minWidth: 42,
      letterSpacing: '0.05rem',
      fontSize: 13,
      padding: theme.spacing(0.25, 1),
      borderTopRightRadius: 2,
      borderTopLeftRadius: 2,
      '&:hover': {
        //color: '#fff',
        //backgroundColor: colors.brand.primaryShade[120],
        //opacity: 1,
      },
      '&$selected': {
        color: colors.hues.grays[200],
        background: colors.semantic.selected,
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        //color: colors.brand.primary
      },
    },
    selected: {},
  })
)((props: MiniTabProps) => {
  const { valueCount, label, ...rest } = props;
  const displayLabel = valueCount ? `${label} (${valueCount})` : label;
  return <Tab disableRipple {...rest} label={displayLabel} />;
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function MiniTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && children}
    </div>
  );
}

type MiniTabControllerItem = {
  label: string;
  value: any;
  valueCount?: number;
  renderContent: () => React.ReactNode;
};
type MiniTabsControllerProps = {
  items: MiniTabControllerItem[];
  value: any;
  onChange: (value: MiniTabControllerItem['value']) => void;
  AfterTabsComponent?: React.ReactNode;
};

export default function MiniTabsController(props: MiniTabsControllerProps) {
  const { items, value, onChange, AfterTabsComponent } = props;

  let activeIndex = _.findIndex(items, { value });
  if (activeIndex < 0) {
    activeIndex = 0;
  }

  return (
    <>
      <MiniTabs
        value={activeIndex}
        onChange={(evt, index) => onChange(items[index].value)}
      >
        {items.map((item) => (
          <MiniTab
            key={item.value}
            label={item.label}
            valueCount={item.valueCount}
          />
        ))}
        {AfterTabsComponent}
      </MiniTabs>
      {items.map((item, index) => (
        <MiniTabPanel key={item.value} index={index} value={activeIndex}>
          {item.renderContent()}
        </MiniTabPanel>
      ))}
    </>
  );
}
