import _ from 'lodash';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { Avatar } from 'antd';
import { Account } from '@shield-ui/icons';
import { getShortAcronym, getDynamicColorFromString } from '@shield-ui/utils';
import { User } from '@hmd/sdk/api/iam/users/v1';
import { Team } from '@hmd/sdk/api/iam/teams/v1';
import { AvatarSize } from 'antd/lib/avatar/SizeContext';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    color: '#333',
    marginRight: theme.spacing(1),
    flexShrink: 0,
  },
}));

interface Props {
  user?: User.AsObject;
  team?: Team.AsObject;
  className?: string;
  size?: AvatarSize;
}

export default function ShieldAvatarSDK(props: Props) {
  const { user, team, className, size, ...rest } = props;
  const classes = useStyles();
  const rootClass = cx(classes.container, className);

  if (user && user.pictureUri) {
    return (
      <Avatar
        className={rootClass}
        src={user.pictureUri}
        style={{ backgroundColor: getDynamicColorFromString(user.name) }}
        size={size || 'default'}
      >
        {getShortAcronym(user.name)}
      </Avatar>
    );
  } else if (user && user.name) {
    return (
      <Avatar
        className={rootClass}
        {...rest}
        style={{ backgroundColor: getDynamicColorFromString(user.name) }}
        size={size || 'default'}
      >
        {getShortAcronym(user.name)}
      </Avatar>
    );
  } else if (team && team.name) {
    return (
      <Avatar
        className={rootClass}
        {...rest}
        style={{ backgroundColor: getDynamicColorFromString(team.name) }}
        size={size || 'default'}
      >
        {getShortAcronym(team.name)}
      </Avatar>
    );
  }

  return <Account className={rootClass} {...rest} />;
}
