import React from 'react';
import { uuid4 } from '@shield-ui/utils';
import PropTypes from 'prop-types';
import _ from 'lodash';
import apolloClient from '../../../apollo-client';

export function cancellableQuery(options) {
  const abortController = new AbortController();
  const promise = apolloClient.query({
    ...options,
    context: {
      fetchOptions: { signal: abortController.signal },
      queryDeduplication: false,
    },
  });
  const cancel = () => abortController.abort();
  return { promise, cancel };
}

class QueryExecutor extends React.PureComponent {
  static propTypes = {
    tableCacheKey: PropTypes.string.isRequired,
    processQueryResponse: PropTypes.func.isRequired,
    setTableSearchResult: PropTypes.func.isRequired,
    graphqlQuery: PropTypes.object,
    graphqlVariables: PropTypes.object,
    showErrorSnack: PropTypes.func,
  };
  static defaultProps = {
    showErrorSnack: _.noop,
  };

  diffProps = ['graphqlQuery', 'graphqlVariables', 'forceResultRefreshCounter'];

  pendingQuery = undefined;

  refreshDataDelay = _.debounce(this.refreshData.bind(this), 300);

  componentDidUpdate(prevProps) {
    const diff = _.find(this.diffProps, (key) => {
      return prevProps[key] !== this.props[key];
    });

    if (diff) {
      this.refreshDataDelay();
    }
  }

  componentWillUnmount() {
    this.refreshDataDelay.cancel();
    if (this.pendingQuery) {
      this.pendingQuery.cancel();
    }
  }

  componentDidMount() {
    window.requestAnimationFrame(() => {
      this.refreshData();
    });
  }

  refreshData() {
    const {
      skipTableRender,
      tableCacheKey,
      graphqlQuery,
      graphqlVariables,
      setTableSearchResult,
      showErrorSnack,
      processQueryResponse,
    } = this.props;

    if (skipTableRender || !graphqlQuery || !graphqlVariables) {
      // Someone said we should refresh, but we are skipping right now so
      // queue up the refresh again
      this.refreshDataDelay();
      return;
    }

    // we cancel below if possible, however there are cases where our table
    // might be mounting / unmounting (eg switching tabs) that that would not handle this
    // also we implemented the requestId check in the reducer first so we're gonna keep it
    // adding pendingQuery.cancel may have made it unncessary though
    const requestId = uuid4();

    setTableSearchResult({
      tableCacheKey,
      requestId,
      isLoading: true,
    });

    // if we are runnign a query from before... cancel
    if (this.pendingQuery) {
      this.pendingQuery.cancel();
      this.pendingQuery = undefined;
      // user must have changed filters quickly
      console.info('QueryExecutor, cancelling previous query');
    }

    this.pendingQuery = cancellableQuery({
      query: graphqlQuery,
      variables: graphqlVariables,
      fetchPolicy: 'no-cache',
    });

    this.pendingQuery.promise
      .then((response) => {
        this.pendingQuery = undefined;
        setTableSearchResult({
          tableCacheKey,
          requestId,
          isLoading: false,
          ...processQueryResponse(response),
        });
      })
      .catch((err) => {
        this.pendingQuery = undefined;
        showErrorSnack(err);
        setTableSearchResult({
          tableCacheKey,
          requestId,
          isLoading: false,
        });
      });
  }

  /**
   * RENDER IS NOTHING, JUST USING LIFECYCLES TO TIE INTO REST OF APP
   */
  render() {
    return null;
  }
}

export default QueryExecutor;
