import React from 'react';
import { colors } from '@shield-ui/styles';
import { matchifyString } from '@shield-ui/utils';

export default function HighlightText({ str = '', searchString }) {
  if (!searchString) {
    return <>{str}</>;
  }

  const basicStr = matchifyString(str);
  const basicSearchString = matchifyString(searchString);
  const index = basicStr.indexOf(basicSearchString);

  if (index === -1) {
    return <>{str}</>;
  }

  if (index > -1) {
    const p1 = str.slice(0, index);
    const p2 = str.slice(index, index + searchString.length);
    const p3 = str.slice(index + searchString.length);

    return (
      <>
        {p1}
        <span style={{ backgroundColor: colors.hues.orange, color: 'black' }}>
          {p2}
        </span>
        {p3}
      </>
    );
  }
}
