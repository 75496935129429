import React from 'react';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import { ContainerProps } from './containerEventManager';
import { Props as ChildRowDetailsProps } from './ChildRowDetails';
import LogEventGroup from './LogEventGroup';
import {
  ControlBarContainer,
  ControlBarMultiSelect,
} from '@shield-ui/controls';
import { getErrorLevelOptionsLogEvents } from '../../../services/logEvents';
import DiagnosticsGoToAction from '../../diagnostics/DiagnosticsGoToAction';
import { colors } from '@shield-ui/styles';
import { LogEventFilters } from '@hmd/sdk/api/analysis/log_event/v1';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  controlBar: {
    marginTop: theme.spacing(2),
  },
  noDisplay: {
    fontSize: 10,
    marginTop: theme.spacing(0.5),
    color: colors.hues.grays[80],
    textAlign: 'center',
  },
  logEventContainer: {
    '& ul': {
      margin: 0 /* To remove default bottom margin */,
      padding: 0 /* To remove default left padding */,
    },
    '& a': {
      padding: 8,
      color: colors.hues.blues[70],
    },
  },
}));

interface Props
  extends Pick<
      ContainerProps,
      'logEventDisplayRecords' | 'robotLog' | 'filters' | 'setFilters'
    >,
    Pick<ChildRowDetailsProps, 'timeCodeOnClick'> {}

function LogEventList(props: Props) {
  const classes = useStyles();
  const { logEventDisplayRecords, filters, setFilters, robotLog } = props;

  const logEventFlightNames = _.groupBy(
    logEventDisplayRecords,
    (logEvent) => logEvent.leafLogEvent.sourceExternalId
  );

  const renderLogEventsForEachFlight = (name) => {
    const logEvents = logEventFlightNames[name];

    const logEventGroups = _.groupBy(logEvents, 'groupLabel');
    const sortedGroupLabels = _.sortBy(Object.keys(logEventGroups), (key) => {
      const group = logEventGroups[key];
      return group[0].leafLogEvent.componentOwner + ' ' + key;
    });
    return sortedGroupLabels.map((label) => {
      const group = logEventGroups[label];
      return (
        <LogEventGroup
          key={label}
          groupLogEventDisplayRecords={group}
          groupLabel={label}
          {...props}
        />
      );
    });
  };

  return (
    <>
      <ControlBarContainer
        className={classes.controlBar}
        StartContent={<DiagnosticsGoToAction robotLogId={robotLog.id} />}
        EndContent={
          <ControlBarMultiSelect
            label="Levels"
            value={filters.levels}
            options={getErrorLevelOptionsLogEvents()}
            onChange={(value) =>
              setFilters({
                filters: {
                  levels: value as LogEventFilters.AsObject['levelsList'],
                },
              })
            }
          />
        }
      />
      {!logEventDisplayRecords.length && (
        <div className={classes.noDisplay}>
          No matching diagnostics to display
        </div>
      )}
      {Object.keys(logEventFlightNames).map((name) => {
        return (
          <div className={classes.logEventContainer}>
            <ul key={name}>
              <Link to={`${name}`}>{name}</Link>
              {renderLogEventsForEachFlight(name)}
            </ul>
          </div>
        );
      })}
    </>
  );
}

export default LogEventList;
