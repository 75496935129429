import React, { lazy, Suspense } from 'react';
import { LoadingIndicator } from '@shield-ui/core';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const LinePlot = lazy(() => import('./LinePlot'));

export default function LazyStyledPlot(props) {
  return (
    <Suspense fallback={<LoadingIndicator />}>
      <LinePlot {...props} />
    </Suspense>
  );
}
