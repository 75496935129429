import dataOverTimeDefinition from './plotTypes/dataOverTime';
import boxAndWhiskerDefinition from './plotTypes/boxAndWhisker';
import bubbleRegistry from './plotTypes/bubble';

interface PlotTypeType {
	id: string;
	label: string;
	icon: React.ReactNode;
	loadingLayout:(graphType?:string) => object;
	description: string;
	renderFormElements:()=> React.ReactNode;
	getUpdateValues:(plotConfig:object)=> object;
	getInitialValues:()=> object;
	fetchData:(query:object, plotConfig:object)=> Promise<object[]>;
	renderPlot: (plotConfig:object, response:object[], actions:object[])=>React.ReactNode;
}

const registry:PlotTypeType[] = [
	dataOverTimeDefinition(),
	boxAndWhiskerDefinition(),
	//bubbleRegistry(),
]

class PlotTypeManager {

	getOptions(){
		return registry.map((item)=> {
			return ({
				value:item.id,
				label:item.label,
				icon:item.icon
			})
		})
	}

	getPlotType(plotTypeId){
		return registry.filter(item => item.id === plotTypeId)[0]
	}

	getLabel(plotTypeId){
		return this.getPlotType(plotTypeId).label;
	}

	renderForm(plotTypeId){
		return this.getPlotType(plotTypeId).renderFormElements();
	}

	renderUpdateValues(plotTypeId,plotConfig){
		return this.getPlotType(plotTypeId).getUpdateValues(plotConfig);
	}

	renderInitialValues(plotTypeId){
		return this.getPlotType(plotTypeId).getInitialValues();
	}

	fetchData(query, plotConfig){
		return this.getPlotType(plotConfig.plotTypeId).fetchData(query, plotConfig)
	}

	renderPlot(plotConfig, response, actions){
		return this.getPlotType(plotConfig.plotTypeId).renderPlot(plotConfig, response, actions);
	}

	loadinglayout(plotTypeId, graphType){
		return this.getPlotType(plotTypeId).loadingLayout(graphType)
	}
}

export default new PlotTypeManager();
