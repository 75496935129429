import { MultiselectQueryFilter } from '@shield-ui/query-filters';
import { getAllRobotLogDocumentEvaluationOptions } from "../../../services/evaluations";

export default new MultiselectQueryFilter({
  id: 'testEvaluations',
  controlLabel: 'Test Evaluations',
  esField: 'test_evaluation.evaluation_state',
  description: 'This is the evaluation state calculated for a test or trial (e.g. PASS / FAIL)',
  unsetValueDisabled: true,
  behaviorConfig: {
    fetchOptions: getAllRobotLogDocumentEvaluationOptions
  },
});
