import React, { useRef, useMemo } from 'react';
import { ConfigProvider } from 'antd';

const AntPopoverConfigProvider: React.FC = (props) => {
  const containerRef = useRef(null);

  const getPopupContainer = useMemo(() => {
    return () => containerRef.current;
  }, [containerRef]);

  return (
    <div ref={containerRef}>
      <ConfigProvider getPopupContainer={getPopupContainer}>
        {props.children}
      </ConfigProvider>
    </div>
  );
};

export default AntPopoverConfigProvider;
