import { gql } from '@apollo/client';
import { runQuery } from '../apollo-client';

const measurementDefinitionFragment = `
  id
  name
  description
  unit
  dissectionOptions
  statisticOptions {
    all
    dissected
  }
  definitionSpecificOptions {
    parameter
    dataType
    required
    label
  }
`;

export function searchMeasurementDefinitions(variables, callback) {
  runQuery({
    query: gql`
        query measurementDefinitions {
          measurementDefinitions {
            ${measurementDefinitionFragment}
          }
        }
    `,
    variables,
    callback,
  });
}
