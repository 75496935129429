import React from 'react';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';

const styles = () =>
  createStyles({
    grow: {
      flex: 1,
    },
  });

function Grow(props: WithStyles<typeof styles>) {
  const { classes } = props;

  return <div className={classes.grow} />;
}

export default withStyles(styles)(Grow);
