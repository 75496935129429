import { LoadingIndicator, ErrorMessageContent } from '@shield-ui/core';
import makeStyles from '@mui/styles/makeStyles';
import { Row, Col, Input, Spin } from 'antd';
import { useState, useMemo } from 'react';
import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { ArtifactListView } from './ArtifactListView';
import { ArtifactTypeSelect } from './ArtifactTypeSelect';
import { ArtifactUploadButton } from './ArtifactUploadButton';

import {
  useSessionLogArtifacts,
  SessionLogAndArtifacts,
} from '../../services/newArtifacts';
import { doesInputMatchBase } from '@shield-ui/utils';

type ArtifactManagerProps = {
  sessionLogId: string;
};

const { Search } = Input;

const useStyles = makeStyles((theme) => ({
  // center the loading spinner
  loadingSpinner: {
    paddingTop: theme.spacing(10),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}));

function ArtifactManager({ sessionLogId }: ArtifactManagerProps) {
  const classes = useStyles();

  const [artifactTypeSlug, setArtifactTypeSlug] = useState('');
  const [searchFilter, setSearchFilter] = useState('');

  const { isLoading, error, data } = useSessionLogArtifacts(sessionLogId);

  const artifactSlugs = useMemo(() => {
    if (!data) {
      return [];
    }
    const artifactSlugs = Object.values(data).reduce((acc, sessionLog) => {
      sessionLog.artifacts.forEach((artifactLink) => {
        const artifact = artifactLink.artifact;
        if (artifact) {
          acc.add(artifact.artifactTypeSlug);
        }
      });
      return acc;
    }, new Set<string>());
    return Array.from(artifactSlugs);
  }, [data]);

  const filteredSessionLogArtifacts: SessionLogAndArtifacts = useMemo(() => {
    if (!data) {
      return {};
    }

    const sessionLogs = Object.values(data).reduce((acc, sessionLog) => {
      const artifacts = sessionLog.artifacts.filter((artifactLink) => {
        // If ArtifactLinks exist in the database, but the Artifact cannot be resolved, filter the ArtifactLink.
        // Otherwise, the page will break destructuring the undefined artifact.
        const artifact = artifactLink.artifact;
        if (!artifact) {
          return false;
        }

        if (
          artifactTypeSlug &&
          artifact.artifactTypeSlug !== artifactTypeSlug
        ) {
          return false;
        }
        if (
          searchFilter &&
          !doesInputMatchBase(searchFilter, artifact.filename)
        ) {
          return false;
        }
        return true;
      });
      if (artifacts.length > 0) {
        acc[sessionLog.id] = {
          ...sessionLog,
          artifacts,
        };
      }
      return acc;
    }, {});
    return sessionLogs;
  }, [data, artifactTypeSlug, searchFilter]);

  if (isLoading) {
    return (
      <Spin
        className={classes.loadingSpinner}
        indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}
      />
    );
  }

  if (error) {
    console.error(error);
    return (
      <ErrorMessageContent>{(error as any)?.statusMessage}</ErrorMessageContent>
    );
  }

  return (
    <div>
      <Row gutter={10} style={{ marginBottom: 8 }}>
        <Col flex="200px">
          <Search
            placeholder="Filter"
            allowClear
            value={searchFilter}
            onChange={(e) => setSearchFilter(e.target.value)}
            style={{ width: '100%' }}
          />
        </Col>
        <Col flex="300px">
          <ArtifactTypeSelect
            onChange={setArtifactTypeSlug}
            value={artifactTypeSlug}
            defaultActiveFirstOption={true}
            filteredSlugs={artifactSlugs}
            includeAllOption={true}
          />
        </Col>
        <Col flex="auto" />
        <Col>
          <ArtifactUploadButton sessionLogId={sessionLogId} />
        </Col>
      </Row>
      <ArtifactListView
        sessionLogId={sessionLogId}
        sessionLogs={filteredSessionLogArtifacts}
      />
    </div>
  );
}

export default React.memo(ArtifactManager);
