import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { setPaging } from '../../../redux/flight/actions';
import { runIncludedExcludedMutation } from '../../../redux/collection/actions';

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setPaging,
      runIncludedExcludedMutation,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps);
