import { Tag } from 'antd';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';

const config = {
  columnKey: 'environment',
  label: 'Environment',
  sortKey: 'environments.name.keyword',
  description: 'Environment / Location / Site',
  defaultWidth: 240,
  getValue: function EnvironmentCell({ row }) {
    return (
      <div
        style={{
          wordWrap: 'break-word',
          whiteSpace: 'break-spaces',
        }}
      >
        {_.get(row, 'environments', []).map((env) => (
          <Tag key={env.id} color="blue">
            {env.name}
          </Tag>
        ))}
      </div>
    );
  },
  fragment: `
        environments {
          name
          id
        }
    `,
};

export default config;
