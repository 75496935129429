import { lighten } from '@mui/material/styles';
import { colors as sharedColors } from '@shield-ui/styles';

export const colors = {
  evenColumn: sharedColors.hues.grays[200],
  evenColumnHover: lighten(sharedColors.hues.grays[200], 0.06),
  oddColumn: lighten(sharedColors.hues.grays[200], 0.01),
  oddColumnHover: lighten(sharedColors.hues.grays[200], 0.07),
  border: sharedColors.hues.grays[180],
};
