import _ from 'lodash';
import { useEffect, useState } from 'react';
import sdkClient from '../lib/hmdsdkClient';
import * as requirementsV1 from '@hmd/sdk/api/systems/requirements/v1';
import * as projectsV1 from '@hmd/sdk/api/systems/projects/v1';

const invertedState = _.invert(requirementsV1.State);

export type RequirementsType = {
  id: string;
  name: string;
  description: string;
  state: string;
  projectId: string;
  metadata: object;
  requirementType: string;
};

const fetchAllRequirements = _.once((): Promise<RequirementsType[]> => {
  // Recursively fetch requirements
  const requirements = [];
  const pageSize = 200;

  async function recursiveFetchRequirements(token) {
    // If token is empty, we're done
    if (token === '') {
      return requirements;
    }
    const sr = new requirementsV1.SearchRequest();

    // If token is null, it's the first request
    if (token !== null) {
      sr.setPageToken(token);
    }
    sr.setPageSize(pageSize);

    // Append to requirements list and set nextPageToken
    let nextToken = '';
    await sdkClient
      .unary(requirementsV1.RequirementService.Search, sr)
      .then((res) => {
        nextToken = res.getNextPageToken();
        const fetchedRequirements = res.getRequirementsList();
        for (const r of fetchedRequirements) {
          requirements.push({
            id: r.getId(),
            name: r.getName(),
            description: r.getDescription(),
            state: invertedState[r.getState()],
            projectId: r.getProjectId(),
            metadata: r.getMetadata().toJavaScript(),
            requirementType: r.getRequirementType(),
          });
        }
      });
    return recursiveFetchRequirements(nextToken);
  }

  // Execute fetch request
  return recursiveFetchRequirements(null)
    .then((requirements) => {
      return requirements;
    })
    .catch((e) => {
      console.error(e);
      return [];
    });
});

export type ProjectsType = {
  id: string;
  name: string;
  description: string;
  displayName: string;
  metadata: object;
};
const fetchAllProjects = _.once((): Promise<ProjectsType[]> => {
  // Recursively fetch projects
  const projects = [];
  const pageSize = 200;
  async function recursiveFetchProjects(token) {
    // If token is empty, we're done
    if (token === '') {
      return projects;
    }
    const sr = new projectsV1.ListRequest();

    // If token is null, it's the first request
    if (token !== null) {
      sr.setPageToken(token);
    }
    sr.setPageSize(pageSize);

    // Append to projects list and set nextPageToken
    let nextToken = '';
    await sdkClient.unary(projectsV1.ProjectService.List, sr).then((res) => {
      nextToken = res.getNextPageToken();
      const fetchedProjects = res.getProjectsList();
      for (const p of fetchedProjects) {
        projects.push({
          id: p.getId(),
          name: p.getName(),
          description: p.getDescription(),
          metadata: p.getMetadata().toJavaScript(),
          displayName: p.getDisplayName(),
        });
      }
    });
    return recursiveFetchProjects(nextToken);
  }

  // Execute fetch request
  return recursiveFetchProjects(null)
    .then((res) => {
      const projects = res;
      return projects;
    })
    .catch((e) => {
      console.error(e);
      return [];
    });
});

export const getRequirementOptions = _.once(
  (): Promise<{ value: string; label: string }[]> => {
    return fetchAllRequirements().then((requirements) => {
      return requirements.map((r) => ({ value: r.id, label: r.name }));
    });
  }
);
export const getRequirementTypeOptions = _.once(
  (): Promise<{ value: string; label: string }[]> => {
    return fetchAllRequirements().then((requirements) => {
      return _.sortBy(
        _.uniq(_.map(requirements, 'requirementType')).map((rt) => ({
          value: rt,
          label: rt,
        })),
        'label'
      );
    });
  }
);
export const getRequirementStateOptions = _.once(
  (): Promise<{ value: string; label: string }[]> => {
    return fetchAllRequirements().then((requirements) => {
      return _.sortBy(
        _.uniq(_.map(requirements, 'state')).map((rt) => ({
          value: rt,
          label: rt,
        })),
        'label'
      );
    });
  }
);
export const getProjectOptions = _.once(
  (): Promise<{ value: string; label: string }[]> => {
    return fetchAllProjects().then((projects) => {
      return _.sortBy(
        projects.map((p) => ({ value: p.id, label: p.displayName })),
        'label'
      );
    });
  }
);

function getLinkForRequirement(
  requirement: RequirementsType
): string | undefined {
  if (requirement && requirement.metadata && requirement.metadata['ado_id']) {
    return `https://devops.shield.ai/ShieldAI/ShieldAI/_workitems/edit/${requirement.metadata['ado_id']}`;
  }
}

export const useRequirementProjectPreviews = () => {
  const [data, setData] = useState<
    { name: string; projectName: string; id: string; link?: string }[]
  >([]);
  useEffect(() => {
    Promise.all([fetchAllRequirements(), fetchAllProjects()]).then((res) => {
      const [requirements, projects] = res;

      const projectMap = _.keyBy(projects, 'id');
      const d = requirements.map((req) => {
        return {
          name: req.name,
          projectName: projectMap[req.projectId]
            ? projectMap[req.projectId].displayName
            : req.projectId,
          id: req.id,
          link: getLinkForRequirement(req),
        };
      });
      setData(d);
    });
  }, []);
  return data;
};

interface RequirementGroupOption {
  groupLabel: string;
  options: { label: string; value: string }[];
}
export const useRequirementGroupedOptions = () => {
  const [optionGroups, setOptionGroups] = useState<RequirementGroupOption[]>(
    []
  );

  useEffect(() => {
    Promise.all([fetchAllRequirements(), fetchAllProjects()]).then((res) => {
      const [requirements, projects] = res;

      const projectMap = _.keyBy(projects, 'id');

      const groupedByProjectId = _.groupBy(requirements, 'projectId');

      let results: RequirementGroupOption[] = Object.keys(
        groupedByProjectId
      ).map((projectId) => {
        return {
          groupLabel: projectMap[projectId]
            ? projectMap[projectId].displayName
            : `Unknown ${projectId}`,
          options: _.sortBy(groupedByProjectId[projectId], 'name').map(
            (requirement) => {
              return {
                value: requirement.id,
                label: requirement.name,
              };
            }
          ),
        };
      });
      results = _.sortBy(results, 'groupLabel');
      setOptionGroups(results);
    });
  }, []);

  return optionGroups;
};

export type RequirementsTableType = {
  id: string;
  name: string;
  description: string;
  state: string;
  projectId: string;
  metadata: object;
  key: string;
  requirementType: string;
  projectName: string;
};
export const getRequirementTableValues = _.once(
  (): Promise<RequirementsTableType[]> => {
    return Promise.all([fetchAllRequirements(), fetchAllProjects()]).then(
      (res) => {
        const [requirements, projects] = res;
        const projectsById = _.keyBy(projects, 'id');
        const requirementTableValues = requirements.map((r) => ({
          ...r,
          key: r.id,
          projectName: projectsById[r.projectId].displayName,
          link: getLinkForRequirement(r),
        }));
        return requirementTableValues;
      }
    );
  }
);

export const useRequirementTableValues = () => {
  const [data, setData] = useState<RequirementsTableType[]>([]);

  useEffect(() => {
    getRequirementTableValues().then((opts) => setData(opts));
  }, []);

  return data;
};
