import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { colors } from '@shield-ui/styles';

const useStyles = makeStyles((theme) => ({
  collapsible: {
    marginTop: theme.spacing(1),
  },
  collapsibleHeader: {
    border: '1px solid',
    borderColor: colors.hues.grays[170],
    padding: theme.spacing(1),
    backgroundColor: 'rgba(255, 255, 255, 0.04)',
  },
  collapsibleContent: {
    border: '1px solid',
    borderColor: colors.hues.grays[170],
    borderTop: 'none',
    padding: theme.spacing(1),
  },
}));

interface Props extends React.PropsWithChildren<any> {
  isOpen?: boolean;
  header: string;
  onChange?: (isOpen: boolean) => void;
}

export const Collapsible = ({
  isOpen = false,
  header,
  onChange,
  children,
  ...props
}: Props) => {
  const classes = useStyles();
  const [isOpened, setIsOpened] = useState(isOpen);

  const onToggle = () => {
    setIsOpened(!isOpened);
    if (onChange) onChange(!isOpened);
  };

  useEffect(() => {
    setIsOpened(isOpen);
  }, [isOpen]);

  return (
    <div className={classes.collapsible} {...props}>
      <div className={classes.collapsibleHeader} onClick={onToggle}>
        {isOpened ? <DownOutlined /> : <RightOutlined />} {header}
      </div>
      {isOpened && <div className={classes.collapsibleContent}>{children}</div>}
    </div>
  );
};
