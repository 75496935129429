import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import ModalHeader from '../modals/Header';
import ShieldAvatar from './ShieldAvatar';

import { Paper, List, ListItem, ListItemText } from '@mui/material';

function styles(theme) {
  return {
    container: {
      padding: theme.spacing(3),
      width: 800,
      // not sure why this gets a focus outline
      outline: 'none',
    },
    list: {
      marginTop: 20,
      background: `rgba(0, 0, 0, .2)`,
    },
  };
}

class DeleteCollectionModal extends React.Component {
  static propTypes = {
    users: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        picture: PropTypes.string,
      })
    ).isRequired,
    title: PropTypes.string,
  };

  static defaultProps = {
    title: 'Users',
  };

  render() {
    const { hideModal, classes, title, users } = this.props;

    return (
      <Paper className={classes.container}>
        <ModalHeader onClose={hideModal}>{title}</ModalHeader>

        <List className={classes.list}>
          {users.map((user) => {
            return (
              <ListItem key={user.name}>
                <ShieldAvatar user={user} />
                <ListItemText>{user.name}</ListItemText>
              </ListItem>
            );
          })}
        </List>
      </Paper>
    );
  }
}

export default withStyles(styles)(DeleteCollectionModal);
