import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import Collapse from '@mui/material/Collapse';
import { prettifyString } from '@shield-ui/utils';
import { getTimestampDeltaMs } from '../../../services/logEvents';
import { LogEventDisplayRecord } from '../../../redux/eventManager.slice';
import ChildRow from './ChildRow';
import LogEventExtraDetails from './LogEventExtraDetails';
import ChildRowDetails, {
  Props as ChildRowDetailsProps,
} from './ChildRowDetails';
import { ContainerProps } from './containerEventManager';
import {ErrorLevel} from '@hmd/sdk/api/analysis/log_event/v1';

const useStyles = makeStyles((theme) => ({
  treeIcon: {
    marginLeft: theme.spacing(2),
    fontSize: 20,
    color: 'rgba(255, 255, 255, 0.185)',
    transform: 'scaleX(-1)', // flip
  },
}));

interface Props
  extends Pick<ContainerProps, 'robotLog'>,
    Pick<ChildRowDetailsProps, 'timeCodeOnClick'> {
  logEventDisplayRecord: LogEventDisplayRecord;
}

const highlightMap: object = {
 [ErrorLevel.INFO]: 'info',
 [ErrorLevel.WARN]: 'warn',
 [ErrorLevel.FATAL]: 'fatal',
 [ErrorLevel.DEBUG]: 'debug',
 [ErrorLevel.ERROR]: 'error'
}

function getHighlight(logEvent) {
  return highlightMap[logEvent.level]
}

function LogEvent(props: Props) {
  const [isExpanded, setIsExpanded] = useState(false);
  const classes = useStyles();
  const { logEventDisplayRecord, robotLog, timeCodeOnClick } = props;
  const { leafLogEvent, rootLogEvent } = logEventDisplayRecord;

  let timeCode;
  if (robotLog) {
    timeCode = Math.floor(
      getTimestampDeltaMs(robotLog.startTime, leafLogEvent.timestampTime) / 1000
    );
  }

  return (
    <ChildRow
      isExpanded={isExpanded}
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <>
        <ChildRowDetails
          label={
            <>
              {prettifyString(leafLogEvent.logDefinitionName)}
              {logEventDisplayRecord.hasTreeDetails && (
                <AccountTreeOutlinedIcon className={classes.treeIcon} />
              )}
            </>
          }
          highlight={getHighlight(leafLogEvent)}
          timeCodeAtSeconds={timeCode}
          timeCodeOnClick={timeCodeOnClick}
          description={leafLogEvent.logDefinitionDescription}
        />
        <Collapse in={isExpanded}>
          {/* this is a kind of expensive component so we only render it when expanded */}
          {isExpanded ? (
            <LogEventExtraDetails logEvent={rootLogEvent} />
          ) : (
            <div />
          )}
        </Collapse>
      </>
    </ChildRow>
  );
}

export default LogEvent;
