import React from 'react';
import Cell, { getParsedReviews } from './Cell';

/**
 * @param {string} relationshipKey - robotLogReviews or collectionReviews
 */
function getConfig({ relationshipKey, label }) {
  return {
    columnKey: 'reviewStatus',
    label: label || 'Reviews',
    description: 'Review assignments',
    // for robotLogs v2 (document search v elastic)
    getElasticSort: ({ orderByDirection }) => {
      if (relationshipKey === 'robotLogReviews') {
        return [
          {
            'robot_log_reviews.status': {
              order: orderByDirection,
              missing: '_last',
            },
          },
        ];
      }
    },
    Cell: (props) => {
      return <Cell {...props} relationshipKey={relationshipKey} />;
    },
    getValue: ({ row }) => {
      return getParsedReviews(row, relationshipKey)
        .map((review) => {
          return `${review.name} [${review.displayStatus}]`;
        })
        .join('\n');
    },
    fragment: `
        ${relationshipKey} {
            edges {
                node {
                    createdAt
                    reviewStatus
                    reviewer {
                        name
                    }
                    reviewerTeam {
                        name
                    }
                }
            }
        }
    `,
  };
}

export default getConfig;
