import _ from 'lodash';
import {
  defaultKeywordsListOperator,
  ListOperator,
  MultiselectOption,
  MultiselectValueType,
} from './common';
import { MultiselectQueryFilter, TypeToFindMultiselectQueryFilter } from './';

export const UNSET_OPTION_VALUE = '___UNSET___';

export function getUnsetOption(
  qf: MultiselectQueryFilter | TypeToFindMultiselectQueryFilter
): MultiselectOption | void {
  const { unsetValueLabel, unsetValueDisabled, isMulti, controlLabel } =
    qf.props;

  if (unsetValueDisabled) {
    return;
  }
  let label = unsetValueLabel;

  if (!label) {
    if (isMulti) {
      label = `No ${controlLabel}`;
    } else if (controlLabel.endsWith('s')) {
      label = `No ${controlLabel.slice(0, -1)}`;
    } else {
      label = 'No Value';
    }
  }

  return {
    value: UNSET_OPTION_VALUE,
    label: label,
  };
}

export function getMultiselectMatchPreview(
  optionValues: MultiselectValueType[],
  options: MultiselectOption[] | Record<string, MultiselectOption>,
  extra: { totalCountOverride?: number } = {}
): string {
  if (!optionValues.length) {
    return '';
  }

  // Show the preview of the first value + the count of other selected items
  let match;
  if (Array.isArray(options)) {
    match = _.find(options, (opt) => optionValues.includes(opt.value));
  } else {
    match = options[_.find(optionValues, (v) => !!options[v])];
  }

  const totalCount = extra.totalCountOverride || optionValues.length;

  if (match) {
    if (totalCount > 1) {
      return `${match.label} (+${totalCount - 1})`;
    } else {
      return match.label;
    }
  }
  return '';
}

export function getIncludeExcludeMatchPreview(
  includeOptionValues: MultiselectValueType[],
  excludeOptionValues: MultiselectValueType[],
  options: MultiselectOption[] | Record<string, MultiselectOption>
) {
  if (
    includeOptionValues &&
    includeOptionValues.length &&
    excludeOptionValues &&
    excludeOptionValues.length
  ) {
    return getMultiselectMatchPreview(
      includeOptionValues.concat(excludeOptionValues),
      options
    );
  } else if (excludeOptionValues && excludeOptionValues.length) {
    return `Not: ${getMultiselectMatchPreview(excludeOptionValues, options)}`;
  }

  return getMultiselectMatchPreview(includeOptionValues, options);
}

// no need to add a joiner conditional if we only have one condition
// we should never get here if conditions.length === 0 but maybe we should put in some kevlar
function simplifyBoolQuery(conditions: object[], joiner: 'filter' | 'should') {
  if (conditions.length === 1) {
    return {
      bool: conditions[0],
    };
  }
  // we have more than one condition so join them together with the appropriate operation
  return {
    bool: {
      [joiner]: conditions,
    },
  };
}

export function isSpecialOptionValue(value: MultiselectValueType) {
  return value === UNSET_OPTION_VALUE;
}

// if the optionValue matches a "special" control option, then we need to build a non-standard query
function getSpecialOptionValueQuery(
  optionValue: MultiselectValueType,
  config: MapperConfig
): object | void {
  if (optionValue === UNSET_OPTION_VALUE) {
    const fields = [config.esField];
    if (config.creatableEsField && config.esField !== config.creatableEsField) {
      fields.push(config.creatableEsField);
    }
    const conditions = fields.map((field) => ({
      must_not: {
        exists: {
          field: field,
        },
      },
    }));

    // filter because we must meet both conditions
    return simplifyBoolQuery(conditions, 'filter');
  }
}

type MapperConfig = {
  // the elasticsearch field we are building a query for
  esField: string;
  // will be the same as esField if it does not differ
  creatableEsField: string;
};

/**
 * getMultiselectEsQuery builds wildcard with *pattern* matching instead of default looking for the exact term
 * @param esField
 * @param optionValues
 */
function optionValuesToWildcards(
  optionValues: MultiselectValueType[],
  config: MapperConfig
): object[] {
  return optionValues.map((optionValue) => {
    const special = getSpecialOptionValueQuery(optionValue, config);
    if (special) {
      return special;
    }
    const isCrVal = isCreatableValue(optionValue);
    const esField = isCrVal ? config.creatableEsField : config.esField;
    const plainValue = getPlainValueFromOptionValue(optionValue);

    return {
      wildcard: {
        [esField]: {
          value: `*${plainValue}*`,
        },
      },
    };
  });
}

/**
 * Default mapper for getMultiselectEsQuery
 * @param esField
 * @param optionValues
 */
function optionValuesToTerms(
  optionValues: MultiselectValueType[],
  config: MapperConfig
): object[] {
  return optionValues.map((optionValue) => {
    const special = getSpecialOptionValueQuery(optionValue, config);
    if (special) {
      return special;
    }
    const isCrVal = isCreatableValue(optionValue);
    const esField = isCrVal ? config.creatableEsField : config.esField;
    const plainValue = getPlainValueFromOptionValue(optionValue);

    return {
      term: {
        [esField]: plainValue,
      },
    };
  });
}

/**
 * Build an include / exclude multiselect query
 */
export function getMultiselectEsQuery({
  esField,
  value,
  options = {},
}: {
  esField: string;
  value: {
    includeOptionValues?: MultiselectValueType[];
    excludeOptionValues?: MultiselectValueType[];
    includeOperator?: ListOperator;
    excludeOperator?: ListOperator;
  };
  // Define if we are doing term/keyword match or wildcard searching
  options?: {
    mapper?: 'terms' | 'wildcard';
    creatableEsField?: string;
  };
}) {
  const { includeOptionValues = [], excludeOptionValues = [] } = value;
  const { mapper = 'terms', creatableEsField } = options;

  let mapperFunc;
  if (mapper === 'terms') {
    mapperFunc = optionValuesToTerms;
  } else if (mapper === 'wildcard') {
    mapperFunc = optionValuesToWildcards;
  }
  const mapperConfig: MapperConfig = {
    esField,
    creatableEsField: creatableEsField || esField,
  };

  // or is the default for both
  const includeMode = value.includeOperator || defaultKeywordsListOperator;
  const excludeMode = value.excludeOperator || defaultKeywordsListOperator;

  const queries = [];
  if (includeOptionValues.length && includeMode) {
    const operatorKey = includeMode === ListOperator.and ? 'filter' : 'should';
    queries.push({
      bool: {
        [operatorKey]: mapperFunc(includeOptionValues, mapperConfig),
      },
    });
  }

  if (excludeOptionValues.length && excludeMode) {
    if (excludeMode === ListOperator.and) {
      // nested bool to achieve only exclude if matching all clauses
      queries.push({
        bool: {
          must_not: {
            bool: {
              filter: mapperFunc(excludeOptionValues, mapperConfig),
            },
          },
        },
      });
    } else {
      queries.push({
        bool: {
          must_not: mapperFunc(excludeOptionValues, mapperConfig),
        },
      });
    }
  }

  if (!queries.length) {
    return;
  }

  return {
    bool: {
      filter: queries,
    },
  };
}

// Special prefix prepended in userinput creatable values
const CREATABLE_PREFIX = 'creatable~~';

/**
 * Test if a Multiselect value is a Creatable value
 * returns as a boolean and cast to a string
 */
export function isCreatableValue(v: MultiselectValueType): v is string {
  return typeof v === 'string' && v.indexOf(CREATABLE_PREFIX) == 0;
}

/**
 * Handle a user input value string and convert into our specialize
 * creatable value
 */
export function makeCreatableValueFromUserInput(
  userInputValue: string
): string {
  return CREATABLE_PREFIX + userInputValue;
}

/**
 * Values can be more than simple string | number
 * This function adds a layer of abstraction on getting the raw value for ES purposes
 */
export function getPlainValueFromOptionValue(
  v: MultiselectValueType
): string | number {
  return isCreatableValue(v) ? v.replace(CREATABLE_PREFIX, '') : v;
}
