import React, { Fragment } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import EditIcon from '@mui/icons-material/Edit';
import { Grow } from '@shield-ui/core';
import FlightActionIconButton from '../../../components/buttons/IconButton';
import ShieldAvatar from '../../../components/users/ShieldAvatar';
import NoteTypeDisplay from '../../../components/notes/NoteTypeDisplay';
import { getNoteTypeOptions } from '../../../services/notes';
import { Select } from 'antd';

const { Option } = Select;

const styles = (theme) => {
  return {
    headerStyle: {
      fontSize: '0.9 em',
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
    contentRight: {
      justifyContent: 'flex-end',
    },
    date: {
      paddingLeft: theme.spacing(2),
      fontSize: 14,
      fontWeight: 400,
    },
    noteTypeDisplay: {
      width: 130,
      fontSize: 14,
      fontWeight: 400,
    },
    noteTypeSelectRoot: {
      flexGrow: 0,
    },
  };
};

class NoteHeader extends React.Component {
  state = {
    defaultValue: this.props.robotLogId,
  };

  noteTypeOnChange = (value) => {
    const { onChange } = this.props;
    onChange({ noteType: value });
  };

  render() {
    const {
      user,
      isEditing,
      onEdit,
      dateDisplay,
      classes,
      noteType,
      onEditRequest,
    } = this.props;

    const noteTypes = getNoteTypeOptions();

    return (
      <Fragment>
        <Grid item xs={1} className={classes.headerStyle}>
          <ShieldAvatar user={user} showBorder={isEditing} />
        </Grid>
        <Grid item xs={10} className={classes.headerStyle}>
          <div>{_.get(user, 'name', 'Unknown')}</div>
          <Grow />

          {isEditing && (
            <Select
              defaultValue={noteType}
              onChange={this.noteTypeOnChange}
              size={'large'}
             
            >
              {noteTypes.map((type) => (
                <Option key={type.value} value={type.value}>
                  {type.label}
                </Option>
              ))}
            </Select>
          )}

          {!isEditing && noteType && (
            <div className={classes.noteTypeDisplay} onClick={onEditRequest}>
              <NoteTypeDisplay noteType={noteType} />
            </div>
          )}
          <div className={classes.date}>{dateDisplay}</div>
        </Grid>
        <Grid
          item
          xs={1}
          className={classNames(classes.headerStyle, classes.contentRight)}
        >
          {onEdit && (
            <FlightActionIconButton onClick={onEdit}>
              <EditIcon />
            </FlightActionIconButton>
          )}
        </Grid>
      </Fragment>
    );
  }
}

NoteHeader.propTypes = {
  isEditing: PropTypes.bool,
  dateDisplay: PropTypes.string,
  onEdit: PropTypes.func,
};

export default withStyles(styles)(NoteHeader);
