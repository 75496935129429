import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { CheckSelectWrapper } from '@shield-ui/controls';
import { colors } from '@shield-ui/styles';
import { MultiselectOption, MultiselectValueType } from '../../filterTypes';
import { UNSET_OPTION_VALUE } from '../../filterTypes/multiselectUtils';

const useStyles = makeStyles((theme) => ({
  list: {
    listStyleType: 'none',
    // specific height that nicely cuts off options nicely
    // both options with, and without descriptions
    maxHeight: 304,
    margin: theme.spacing(0, 0),
    padding: 0,
    paddingRight: theme.spacing(1),
    overflowY: 'auto',
  },
  noOptions: {
    textAlign: 'center',
    padding: theme.spacing(1),
    fontSize: '0.9em',
    color: colors.hues.grays[120],
  },
  description: {
    marginTop: 1,
    fontSize: `0.725em`,
    lineHeight: '.7em',
    opacity: 0.6,
  },
  specialValue: {
    fontStyle: 'italic',
    fontSize: '0.9em',
  },
}));

function isSpecial(opt: MultiselectOption) {
  return opt && opt.value && opt.value === UNSET_OPTION_VALUE;
}

type Props = {
  options: MultiselectOption[];
  selectedValues: MultiselectValueType[];
  noResultsMessage?: string;
  onChangeOption: (evt, opt: MultiselectOption) => void;
};

export default function CheckSelectOptions(props: Props) {
  const classes = useStyles();
  const {
    options,
    selectedValues,
    onChangeOption,
    noResultsMessage = 'No options',
  } = props;

  return (
    <div className={classes.list}>
      {options.length === 0 && (
        <div className={classes.noOptions}>{noResultsMessage}</div>
      )}
      {options.map((opt) => (
        <CheckSelectWrapper
          key={opt.label + opt.value}
          item={opt}
          isChecked={selectedValues.includes(opt.value)}
          onChange={onChangeOption}
        >
          <div className={isSpecial(opt) ? classes.specialValue : undefined}>
            {opt.label}
            <div className={classes.description}>{opt.description}</div>
          </div>
        </CheckSelectWrapper>
      ))}
    </div>
  );
}
