import { gql } from '@apollo/client';
import sdkClient from '../lib/hmdsdkClient';
import { TokenService, ValidateRequest } from '@hmd/sdk/api/auth/token/v1';
import { runMutation } from '../apollo-client';
import { useQuery } from '@tanstack/react-query';

const favoriteCollectionsFragment = `
    id
    favoriteCollections {
        edges {
            node {
                id
            }
        }
    }
`;

export function addToMyFavoriteCollections(variables, callback) {
  runMutation({
    variables,
    mutation: gql`
        mutation addToMyFavorites($collectionIds: [UUID]!) {
            addToMyFavorites(collectionIds: $collectionIds) {
                ${favoriteCollectionsFragment}
            }
        }
    `,
    callback,
  });
}

export function removeFromMyFavoriteCollections(variables, callback) {
  runMutation({
    variables,
    mutation: gql`
        mutation removeFromMyFavorites($collectionIds: [UUID]!) {
            removeFromMyFavorites(collectionIds: $collectionIds) {
                ${favoriteCollectionsFragment}
            }
        }
    `,
    callback,
  });
}

const getCurrentUserToken = async () => {
  const validateRequest = new ValidateRequest();

  const request = await sdkClient.query(TokenService.Validate, validateRequest);

  return request.toObject();
};

const CURRENT_USER_QUERY_KEY = 'currentUser';

// eslint-disable-next-line react-hooks/rules-of-hooks
export const useCurrentUser = () =>
  useQuery({
    queryKey: [CURRENT_USER_QUERY_KEY],
    queryFn: getCurrentUserToken,
  });
