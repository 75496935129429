import React from 'react';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Typography from '@mui/material/Typography';
import { colors } from '@shield-ui/styles';
import { successGreen, warningYellow, errorRed } from '../colors';

const WIDTH = 256;
const HEIGHT = 144;
export const CALLOUT_ACTUAL_WIDTH = WIDTH + 8; // margin

const useStyles = makeStyles((theme) => ({
  goodText: {
    color: successGreen,
  },
  badText: {
    color: errorRed,
  },
  positiveBg: {
    backgroundColor: successGreen,
  },
  warningBg: {
    backgroundColor: warningYellow,
  },
  negativeBg: {
    backgroundColor: errorRed,
  },
  root: {
    width: WIDTH,
    height: HEIGHT,
    margin: theme.spacing(0.5, 0.5),
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.background.paper,
    boxShadow: '0px 2px 2px 2px rgba(0, 0, 0, 0.2)',
    color: '#E4E7ED',
    position: 'relative',
    overflow: 'hidden',
  },
  rootClickable: {
    transition: '0.15s ease',
    cursor: 'pointer',
    '&:hover': {
      zIndex: 2,
      transform: 'scale(1.075)',
    },
  },
  rootSelected: {
    backgroundColor: colors.hues.grays[140],
  },
  inner: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: theme.shape.borderRadius,
    transition: '0.15s ease',
  },
  loadingInner: {
    zIndex: 2,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    alignItems: 'center',
    color: colors.hues.gray,
  },
  value: {
    width: '100%',
    padding: theme.spacing(0, 1),
    fontWeight: 'bold',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: 36,
    flexShrink: 0,
  },
  numericalContent: {
    display: 'flex',
    justifyContent: 'space-between',
    height: HEIGHT / 2,
    padding: theme.spacing(0.25),
  },
  numericalLeft: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
  numericalRight: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
  delta: {
    textAlign: 'right',
    padding: theme.spacing(0, 1),
    fontSize: 15,
  },
  deltaDisplay: {
    fontSize: '0.80em',
    fontWeight: 300,
  },
  deltaPercent: {
    marginTop: 2,
  },
  dataAgeDisplay: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 2,
    padding: theme.spacing(0, 1),
    fontSize: 11.5,
    fontWeight: 300,
    color: colors.hues.grays[90],
  },
  dataAgeIcon: {
    fontSize: 14,
    marginLeft: 2,
  },
  label: {
    marginBottom: 'auto',
    padding: theme.spacing(0, 1.25),
    lineHeight: 1.05,
    fontSize: 12.5,
    wordBreak: 'break-word',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
  },
  accent: {
    //backgroundColor: colors.hues.grays[140],
    borderBottomRightRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
    color: 'black',
    textAlign: 'center',
    fontSize: 11.5,
    lineHeight: '16px',
    height: 16,
  },
}));

export type CalloutProps = {
  // formatted value to display
  valueDisplay?: string;
  // description of the value
  label: string;
  selected?: boolean;
  // set the callout to a loading state while fetching data
  loading?: boolean;
  // delta
  deltaDisplay?: string;
  deltaPercentDisplay?: string;
  deltaSemantic?: 'positive' | 'negative';
  // assessment
  assessmentDisplay?: string;
  assessmentSemantic?: 'positive' | 'negative' | 'warning';
  dataAgeDisplay?: string;
  //
  className?: string;
  dataCalloutId?: string;
  onClick?: (evt) => void;
  onContextMenu?: (evt) => void;
};

export function Callout(props: CalloutProps) {
  const {
    label,
    valueDisplay,
    selected,
    loading,
    deltaDisplay,
    deltaPercentDisplay,
    deltaSemantic,
    assessmentSemantic,
    assessmentDisplay,
    dataAgeDisplay,
    className,
    onClick,
    onContextMenu,
    dataCalloutId,
  } = props;
  const classes = useStyles();

  return (
    <div
      className={cx(
        classes.root,
        onClick && classes.rootClickable,
        selected && classes.rootSelected,
        className
      )}
      onClick={onClick}
      onContextMenu={onContextMenu}
      data-callout-id={dataCalloutId}
    >
      {loading && (
        <div
          className={cx(classes.inner, classes.loadingInner)}
          style={{ opacity: loading ? 1 : 0 }}
        >
          Loading...
        </div>
      )}
      <div
        className={classes.inner}
        style={{ transform: loading ? 'scale(0)' : 'scale(1)' }}
      >
        <div className={classes.numericalContent}>
          <div className={classes.numericalLeft}>
            <Typography variant="h4" className={classes.value} color="inherit">
              {valueDisplay}
            </Typography>
          </div>
          <div className={classes.numericalRight}>
            <div
              className={cx(
                classes.delta,
                deltaSemantic === 'positive' && classes.goodText,
                deltaSemantic === 'negative' && classes.badText
              )}
            >
              <div className={classes.deltaDisplay}>{deltaDisplay}</div>
              {deltaPercentDisplay && (
                <div
                  className={classes.deltaPercent}
                >{`(${deltaPercentDisplay})`}</div>
              )}
            </div>
            {dataAgeDisplay && (
              <div className={classes.dataAgeDisplay}>
                {dataAgeDisplay}{' '}
                <AccessTimeIcon className={classes.dataAgeIcon} />
              </div>
            )}
          </div>
        </div>
        <Typography variant="body2" className={classes.label} color="inherit">
          {label}
        </Typography>
        {assessmentSemantic && (
          <div
            className={cx(
              classes.accent,
              assessmentSemantic === 'negative' && classes.negativeBg,
              assessmentSemantic === 'positive' && classes.positiveBg,
              assessmentSemantic === 'warning' && classes.warningBg
            )}
          >
            {assessmentDisplay}
          </div>
        )}
      </div>
    </div>
  );
}
