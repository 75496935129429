import { ValueRangeQueryFilter } from '@shield-ui/query-filters';
import { robotLogDocumentFields } from './fields';

export default new ValueRangeQueryFilter({
  id:'childrenCount',
  controlLabel: 'Children Count',
  esField: robotLogDocumentFields['children.id'].field, 
  esAggField: true,
  minThreshold:0,
  maxThreshold:100,
  enforceMinEdge:true,
  enforceMaxEdge:true,
  numberOfInBetweenMarksToDisplay:9
})