import React from 'react';
import FilterChipBuilder from '../tables/generic/controls/FilterChipBuilder';
import CollectionChip from './CollectionChip';

function renderChip(props) {
  return <CollectionChip {...props} />;
}

function RuleSetPreview(props) {
  const { robotLogSearchVariables, onShowAll, setFilters, maxChipCount } =
    props;

  if (!robotLogSearchVariables) {
    return null;
  }

  return (
    <FilterChipBuilder
      groupChipMemberLimit={1}
      filtersVariables={robotLogSearchVariables}
      maxChipCount={maxChipCount}
      onChangeVariables={setFilters}
      onShowAll={onShowAll}
      renderChip={renderChip}
    />
  );
}

export default RuleSetPreview;
