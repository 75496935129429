export const ticketFragment = `
id
name
link
platform
platformId
status
`;

export const noteFragment = `
id
displayText
sourceType {
  id
  name
}
createdAt
noteType
createdBy {
  id
  name
  email
  picture
  providerName
}
`;
