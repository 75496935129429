import _ from 'lodash';
// import Cell from './Cell'

const config = {
  columnKey: 'uniqueName',
  label: 'Unique Name',
  sortKey: 'uniqueName',
  defaultWidth: 200,
  description: 'The unique name of the log',
  getValue: ({ row }) => {
    return _.get(row, 'name');
  },
  fragment: 'name',
};

export default config;
