import React from 'react';
import cx from 'classnames';
import withStyles from '@mui/styles/withStyles';
import { IntegratedSelect } from '@shield-ui/controls';

const styles = () => ({
  root: {
    fontSize: '0.5em',
    display: 'inherit',
  },
  input: {
    fontSize: '0.7em',
    margin: '0 0 0 0',
    padding: '0 0 0 8px',
    minHeight: 40,
  },
  inputMulti: {
    height: 'auto',
  },
  singleValue: {
    fontSize: '0.8em',
    margin: '0 0 0 0',
    padding: '0 0 0 0',
    lineHeight: '0.8em',
  },
  paper: {
    width: 300,
  },
  placeholder: {
    fontSize: '0.8em',
  },
  valueContainer: {
    minHeight: 24,
    lineHeight: '0.8em',
    paddingLeft: 1,
    paddingTop: 2,
    paddingBottom: 2,
  },
  option: {
    fontSize: '0.8em',
  },
});

function FlightPropsDropdown(props) {
  const { classes, isMulti, value, ...rest } = props;
  const { root: rootClass, input, inputMulti, ...otherClasses } = classes;

  return (
    <IntegratedSelect
      isMulti={isMulti}
      value={value}
      className={rootClass}
      classes={{
        ...otherClasses,
        // style changes if multiple values selected in an isMulti
        input: cx(input, isMulti && inputMulti),
      }}
      {...rest}
    />
  );
}

export default withStyles(styles)(FlightPropsDropdown);
