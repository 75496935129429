import { useState } from 'react';
import {
  getCachedItem,
  setCachedItem,
  CacheableStorageType,
} from '@shield-ui/utils';

/**
 * API Compliant to the generic useState hook but stores and retrieves defaults
 * using localStorage.
 */
export default function useCachedState<ValueType extends CacheableStorageType = CacheableStorageType>(
  storageKey: string,
  defaultValue: ValueType
): [ValueType, (CacheableTypes) => void] {
  const [storedValue, setStoredValue] = useState<ValueType>(() => {
    const cachedValue = getCachedItem<ValueType>(storageKey);
    if (cachedValue === undefined) {
      return defaultValue;
    }
    return cachedValue;
  });

  const setValue = (newValue: ValueType) => {
    setCachedItem(storageKey, newValue);
    setStoredValue(newValue);
  };

  return [storedValue, setValue];
}
