import _ from 'lodash';
import client from '../apollo-client';
import { gql } from '@apollo/client';

export const fetchRobots = _.once(() => {
  const fetchQuery = gql`
    query {
      robots(sort: hostname_asc) {
        edges {
          node {
            id
            hostname
            product
          }
        }
      }
    }
  `;

  return client.query({
    query: fetchQuery,
  });
});
