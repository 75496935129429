import { StyledPlot } from '@shield-ui/analysis';
import {RenderPlotProps, plotConfig, plotOptions,metricAnalysis} from '../common';

interface localProps extends RenderPlotProps{
	plotConfig: localPlotConfig;
}
interface localPlotConfig extends plotConfig{
	plotOptions:localPlotOptions;
}
interface localPlotOptions extends plotOptions{
	metricAnalysis: metricAnalysis
}


export default function BoxAndWhiskerPlot ({plotConfig, response, actions}:localProps) {
	const {metricAnalysis, groupByTime, secondBreakdown, title} = plotConfig.plotOptions;
	const timeDict = {
		day: 'Daily',
		week: 'Weekly',
		month: 'Monthly',
		year: 'Yearly',
	}
	const breakdownStr = secondBreakdown.split(/[,._]/).map((word)=>word.charAt(0).toUpperCase()+word.slice(1)).join(' ');
	const generatedTitle =  groupByTime === 'allTime' ? `Breakdown of '${metricAnalysis.label}' by ${breakdownStr} for All Time`: `${timeDict[groupByTime]} Breakdown of '${metricAnalysis.label}' by ${breakdownStr}`
	
	const layout = {
		title: title.length > 0 ? title : generatedTitle,  
		yaxis:{
			title:'Value',
			zeroline:false
		},
		boxmode: 'group',
	}
	
	const config = {
		scrollZoom: true,
	}
	return (
		<div>
			<StyledPlot 
				key = {plotConfig.id}
				data={response}
				layout={layout}
				moreActions={actions}
				config={config}
			/>
		</div>
	)
}