import PropTypes from 'prop-types';
import { ParentChildSelect } from '@shield-ui/controls';
import { useUserOptions } from '../../services/users';
import { useTeamParentOptions } from '../../services/teams';
import store from '../../redux/store';

function TeamUserCheckSelect(props) {
  const {
    teamIdValues,
    userIdValues,
    onChangeTeamIds,
    onChangeUserIds,
    disabledTeamIds,
    disabledUserIds,
    canSelectTeams,
    ...rest
  } = props;

  const currentUser = store.getState().authUser.currentUser;
  const { data: userOptions } = useUserOptions();
  const { data: teamParentOptions } = useTeamParentOptions();

  if (!userOptions?.length || !teamParentOptions?.length) {
    return null;
  }

  return (
    <ParentChildSelect
      {...rest}
      // parent
      parentItems={teamParentOptions}
      parentValues={teamIdValues}
      onChangeParentValues={onChangeTeamIds}
      disabledParentItemValues={disabledTeamIds}
      // child
      childItems={userOptions}
      disabledChildItemValues={disabledUserIds}
      childValues={userIdValues}
      onChangeChildValues={onChangeUserIds}
      canSelectParent={canSelectTeams}
      headChildItemValues={[currentUser.id]}
    />
  );
}
TeamUserCheckSelect.propTypes = {
  placeholder: PropTypes.string,
  teamIdValues: PropTypes.array,
  userIdValues: PropTypes.array,
  onChangeTeamIds: PropTypes.func,
  onChangeUserIds: PropTypes.func,
  canSelectTeams: PropTypes.bool,
  disabledTeamIds: PropTypes.array,
  disabledUserIds: PropTypes.array,
};

export default TeamUserCheckSelect;
