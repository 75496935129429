import makeStyles from '@mui/styles/makeStyles';
import { Artifact } from '@hmd/sdk/api/artifacts/v1';

const useStyles = makeStyles((theme) => ({
  img: {
    width: '100%',
  },
}));

type Props = {
  artifact: Artifact.AsObject;
};

const ImageExpansion = ({ artifact }: Props) => {
  const classes = useStyles();

  return (
    <img className={classes.img} src={artifact.publicUri} alt="Artifact" />
  );
};

export default ImageExpansion;
