export const extractJson = (str) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return {};
  }
};

export const objectToArray = (o) => {
  if (!o) {
    return [];
  }

  const out = Object.keys(o)
    .filter((f) => f !== 'bag' && f !== 'path')
    .map(function (key) {
      return [key, o[key]];
    });

  return out;
};

export const looksLikeYes = (input) => {
  if (!input) {
    return false;
  }

  switch (input.toLowerCase()) {
    case 'y':
    case 'yes':
    case 'true':
    case 'sure thing!':
      return true;
    default:
      return false;
  }
};

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function getProtocol(path) {
  if (!path) {
    return null;
  }

  const protocolStart = path.indexOf('://');

  if (protocolStart === -1) {
    return null;
  }

  const protocol = path.substring(0, protocolStart);
  return protocol.toUpperCase();
}
