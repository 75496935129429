import React from 'react';
import PropTypes from 'prop-types';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import withStyles from '@mui/styles/withStyles';
import container from './container';

import { Tooltip, IconButton } from '@mui/material';

function styles() {
  return {
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      margin: `0 15px`,
    },
    iconButton: {
      padding: 3,
    },
    icon: {
      fontSize: 32,
      color: '#ccc',
      transition: '0.2s ease',
      '&:hover': {
        color: '#fff',
      },
    },
    countContainer: {
      fontSize: 13,
      color: '#aaa',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: `0 8px`,
    },
  };
}

/**
 * Need to handle wrapping at the edges
 * If the total changes at some point we should update
 *
 * If you go to the flight page and don't have ANY of the filters, how about we initialize a search query sorted by time "after" the beginning of your flight so you will be like index 0 or something
 * However you would not know which "index" you are at in the total list of flights
 */

class FlightPaging extends React.Component {
  static propTypes = {
    index: PropTypes.number,
    total: PropTypes.number,
  };
  static defaultProps = {};

  goToPrev = () => {
    const { pageToFlight } = this.props;
    pageToFlight({ direction: 'prev' });
  };

  goToNext = () => {
    const { pageToFlight } = this.props;
    pageToFlight({ direction: 'next' });
  };

  render() {
    const { index, total, description, pagingIsValid, classes } = this.props;

    if (!pagingIsValid) {
      return null;
    }

    return (
      <div className={classes.container}>
        <IconButton className={classes.iconButton} onClick={this.goToPrev} size="large">
          <ArrowLeftIcon className={classes.icon} />
        </IconButton>
        <Tooltip
          title={description || 'Paging through flight results'}
          enterDelay={500}
        >
          <div className={classes.countContainer}>
            <div>Viewing {index + 1}</div>
            <div>of {total}</div>
          </div>
        </Tooltip>
        <IconButton className={classes.iconButton} onClick={this.goToNext} size="large">
          <ArrowRightIcon className={classes.icon} />
        </IconButton>
      </div>
    );
  }
}

export default container(withStyles(styles)(FlightPaging));
