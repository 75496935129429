import { AutoComplete } from 'antd';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { useCommonPlatformMetadata } from '../../services/testDefinitions';
import { MetadataValueInputProps } from './MetadataEditableRow';
import { arrayToLabels } from '../../services/passThruSearch';
import { VBATMetadata } from '../../services/robotLogs';
import { DefaultOptionType } from 'antd/lib/select';

interface BaseMetadataValueInputProps extends MetadataValueInputProps {
  metadata: VBATMetadata[] | object[];
}

export const MetadataValueInput: React.FC<BaseMetadataValueInputProps> = ({
  value = '',
  field,
  onChange,
  platform_id,
  productType,
  metadata = [],
  ...props
}) => {
  const values: DefaultOptionType[] = useMemo(() => {
    const uniqueValues = new Set<string>();

    metadata.forEach((data) => {
      Object.entries(data).forEach(([key, val]) => {
        if (key === field.metadata_key && !isEmpty(val)) {
          uniqueValues.add(val as string);
        }
      });
    });

    if (uniqueValues.size > 50) {
      return [];
    }

    return arrayToLabels(uniqueValues);
  }, [metadata, value, field]);

  const onValueChange = (value: string) => {
    onChange(value);
  };

  return (
    <AutoComplete
      style={{ width: '100%' }}
      options={values || []}
      value={value}
      filterOption={true}
      onChange={onValueChange}
      {...props}
    />
  );
};
