import { darken } from '@mui/material/styles';
import colors from './colors';

const commonStyles = {
  firefoxScrollContainer: {
    scrollbarWidth: 'thin',
    scrollbarColor: `${colors.hues.gray} transparent`,
  },
  placeholder: {
    fontWeight: 200,
    fontSize: 14.5,
    color: darken(colors.semantic.bodyText, 0.2),
    opacity: 1,
  },
};

export default commonStyles;
