import React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from '@shield-ui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    resultsContainer: {
      display: 'block',
      paddding: theme.spacing(2),
      color: '#fff',
      backgroundColor: colors.hues.grays[180],
      alignItems: 'left',
      fontFamily: 'Proxima Nova',
    },
    resultsHeaderContainer: {
      fontSize: 13,
      margin: theme.spacing(1),
      fontWeight: 'bold',
      color: colors.hues.grays[50],
    },
    groupsContainer: {
      maxHeight: '450px',
      overflowY: 'auto',
    },
    groupContainer: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      padding: theme.spacing(1),
    },
    groupHeader: {
      fontSize: 13,
      fontWeight: 'bold',
      color: colors.hues.grays[40],
      marginBottom: 4,
    },
    itemsContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    itemsLink: {
      color: colors.hues.grays[10],
      padding: theme.spacing(1),
      fontSize: 14,
      textDecoration: 'none',
      borderBottom: '1px solid rgba(255, 255, 255, .1)',
      '&:hover': {
        backgroundColor: colors.hues.grays[170],
        borderBottom: '1px solid rgba(255, 255, 255, 0)',
      },
    },
  })
);

type OmniseachItemProps = {
  label: string;
  link: string;
};
type OmnisearchSearchGroupLabel = {
  groupLabel: string;
  items: OmniseachItemProps[];
};
export type OmnisearchResultsJsonProps = {
  hits: OmnisearchSearchGroupLabel[];
  header: string;
};

function OmnisearchResults(props: OmnisearchResultsJsonProps) {
  const { hits, header } = props;
  const classes = useStyles();
  return (
    <div className={classes.resultsContainer}>
      <div className={classes.resultsHeaderContainer}>{header}</div>
      <div className={classes.groupsContainer}>
        {hits.map((item, i) => (
          <div className={classes.groupContainer} key={i}>
            <div className={classes.groupHeader}> {item.groupLabel} </div>
            <div className={classes.itemsContainer}>
              {' '}
              {item.items.map((label, i) => (
                <a className={classes.itemsLink} key={i} href={label.link}>
                  {' '}
                  {label.label}
                </a>
              ))}{' '}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default OmnisearchResults;
