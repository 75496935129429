import _, { values } from 'lodash';
import {
  BaseQueryFilter,
  BaseFilterProps,
  BaseFilterValue,
} from './baseQueryFilter';
import {
  getPlainValueFromOptionValue,
  getIncludeExcludeMatchPreview,
  getMultiselectEsQuery,
  getUnsetOption,
  isCreatableValue,
  isSpecialOptionValue,
  makeCreatableValueFromUserInput,
} from './multiselectUtils';
import {
  QueryFilterTypes,
  ListOperator,
  MultiselectOption,
  MultiselectValueType,
} from './common';

type CreateHybridOptions = {
  isCreatable: true;
  creatableEsField?: string;
};

export type SyncOptionsConfig = {
  options: MultiselectOption[];
  createHybridOptions?: CreateHybridOptions;
};
export type AsyncOptionsConfig = {
  fetchOptions: () => Promise<MultiselectOption[]>;
  createHybridOptions?: CreateHybridOptions;
};
export type CreatableOptionsConfig = {
  isCreatable: true;
  // Uses match instead of term for the ES query
  match?: boolean;
  wildcard?: boolean;
};
export type MultiselectBehaviorConfig =
  | SyncOptionsConfig
  | AsyncOptionsConfig
  | CreatableOptionsConfig;

export interface MultiselectQueryFilterValue extends BaseFilterValue {
  includeOperator?: ListOperator;
  excludeOperator?: ListOperator;
  includeOptionValues?: MultiselectValueType[];
  excludeOptionValues?: MultiselectValueType[];
}

export interface MultiselectQueryFilterControlData {
  isMulti: boolean;
  isCreatable: boolean;
  isHybrid: boolean;
  addCreatableOptions: (creatableUserInputStrings: string[]) => void;
  placeholder: string;
  disableIncludes: boolean;
  disableExcludes: boolean;
  options: MultiselectOption[];
}

export interface MultiselectQueryFilterProps extends BaseFilterProps {
  // this property distinguishes between different behaviors
  // for how the Multiselect derives it's options
  behaviorConfig: MultiselectBehaviorConfig;
  // placeholder for any behaviorConfig
  placeholder?: string;
  // Is this field an array on the document (i.e. it may have multiple entries)
  isMulti?: boolean;
  // Use to disable automatically creating an unset value
  unsetValueDisabled?: boolean;
  // Use to override the automatically generated unset value
  unsetValueLabel?: string;
  // disable the include search options
  disableIncludes?: boolean;
  // disable the exclude search options
  disableExcludes?: boolean;
}

export class MultiselectQueryFilter extends BaseQueryFilter<
  MultiselectQueryFilterValue,
  MultiselectQueryFilterProps,
  MultiselectQueryFilterControlData
> {
  type = QueryFilterTypes.multiselect;
  _ensurePromise = undefined;
  _asyncFetchOptionsPromise = undefined;
  _getQueryOptions = undefined;
  _isFirstCreatableBackwardsCompatCheck: boolean = undefined;

  constructor(props: MultiselectQueryFilterProps) {
    super(props);

    let creatableEsField: string;
    if (
      this._isHybridCreateable() &&
      !this.isCreatableOptionsDataConfig(props.behaviorConfig)
    ) {
      creatableEsField =
        props.behaviorConfig.createHybridOptions?.creatableEsField;
    }

    let mapperValue = 'terms';

    if (this.isCreatableOptionsDataConfig(props.behaviorConfig)) {
      if (props.behaviorConfig.wildcard) {
        mapperValue = 'wildcard';
      } else if (props.behaviorConfig.match) {
        mapperValue = 'match';
      }
    }

    // map the options for the query builder one time
    this._getQueryOptions = {
      mapper: mapperValue,
      creatableEsField,
    };
  }

  initControlData(
    props: MultiselectQueryFilterProps
  ): MultiselectQueryFilterControlData {
    const { behaviorConfig } = props;

    // map our control values, behavior config
    // causes quite a few different behaviors/values as
    // multiselect has grown up over time
    let isCreatable = false;
    let options = undefined;
    let placeholder = props.placeholder;
    const isHybrid = this._isHybridCreateable();
    if (this.isSyncOptionsDataConfig(behaviorConfig)) {
      options = this._initOptions(behaviorConfig.options);
      isCreatable = behaviorConfig.createHybridOptions?.isCreatable;
    } else if (this.isAsyncOptionsDataConfig(behaviorConfig)) {
      options = this._initOptions([]);
      isCreatable = behaviorConfig.createHybridOptions?.isCreatable;
    } else if (this.isCreatableOptionsDataConfig(behaviorConfig)) {
      options = this._initOptions([]);
      isCreatable = true;
    }

    if (!placeholder) {
      if (isHybrid) {
        placeholder = 'Filter results or add';
      } else if (
        isCreatable &&
        this.isCreatableOptionsDataConfig(behaviorConfig)
      ) {
        if (behaviorConfig.wildcard) {
          placeholder = 'Type to add. Use a partial value + wildcard (*,?)';
        } else {
          placeholder = 'Type to add value';
        }
      } else {
        placeholder = 'Filter';
      }
    }

    return {
      options,
      isCreatable,
      isHybrid,
      addCreatableOptions: this.addCreatableOptions.bind(this),
      isMulti: props.isMulti || false,
      disableIncludes: props.disableIncludes || false,
      disableExcludes: props.disableExcludes || false,
      placeholder,
    };
  }

  _isHybridCreateable() {
    const { behaviorConfig } = this.props;

    if (this.isCreatableOptionsDataConfig(behaviorConfig)) {
      return false;
    }

    return !!behaviorConfig?.createHybridOptions?.isCreatable;
  }

  _initOptions(opts: MultiselectOption[]): MultiselectOption[] {
    const unsetOption = getUnsetOption(this);

    if (unsetOption) {
      return [unsetOption, ...opts];
    } else {
      return opts;
    }
  }

  addCreatableOptions(creatableStrings: string[]) {
    const { options = [] } = this.getControlData();

    const existingValues = _.keyBy(options, 'value');
    const missingStrings = _.uniq(
      creatableStrings.filter((userString) => {
        const value = makeCreatableValueFromUserInput(userString);
        return !existingValues[value];
      })
    );

    if (!missingStrings.length) {
      return;
    }

    const newOptions = options.concat(
      missingStrings.map((str) => ({
        value: makeCreatableValueFromUserInput(str),
        label: str,
      }))
    );

    this.setControlData({
      options: newOptions,
    });
  }

  isSyncOptionsDataConfig(
    config: MultiselectBehaviorConfig
  ): config is SyncOptionsConfig {
    return 'options' in config;
  }

  isAsyncOptionsDataConfig(
    config: MultiselectBehaviorConfig
  ): config is AsyncOptionsConfig {
    return 'fetchOptions' in config;
  }

  isCreatableOptionsDataConfig(
    config: MultiselectBehaviorConfig
  ): config is CreatableOptionsConfig {
    return 'isCreatable' in config;
  }

  // _ensurePromise used to cache the fact we only need to fetch data once and to dedupe works and created promises
  // on subsequent calls
  ensureControlData(
    value: MultiselectQueryFilterValue
  ): Promise<MultiselectQueryFilterControlData> {
    if (this._ensurePromise) {
      return this._ensurePromise;
    }

    const { behaviorConfig } = this.props;

    // if async config, we need to store the async call so we only do it once
    // this is broken out from ensurePromise due to changes in multiselect
    // to accomdodate hybrid async + creatable
    if (!this._asyncFetchOptionsPromise) {
      if (this.isAsyncOptionsDataConfig(behaviorConfig)) {
        this._asyncFetchOptionsPromise = behaviorConfig
          .fetchOptions()
          .then((opts) => {
            const updates = {
              options: this.getControlData().options.concat(opts),
            };
            this.setControlData(updates);
          });
      } else if (this.isSyncOptionsDataConfig(behaviorConfig)) {
        this._asyncFetchOptionsPromise = Promise.resolve({});
      }
    }

    const isCreatableConfig = this.isCreatableOptionsDataConfig(behaviorConfig);
    const isHybrid = this._isHybridCreateable();

    if (!isCreatableConfig && !isHybrid) {
      // if we are not creatable
      // we cache ensurePromise so we never need to come back here again
      this._ensurePromise = Promise.all([this._asyncFetchOptionsPromise]).then(
        () => {
          return this.getControlData();
        }
      );

      return this._ensurePromise;
    }

    // ensure we have an option for every "value"
    let creatablePromise;
    if (value) {
      creatablePromise = new Promise((resolve) => {
        const { includeOptionValues = [], excludeOptionValues = [] } = value;

        // backwards compatible for saved values
        // that predate adding the creatable prefix
        // hybrid didn't exist then so if this a truly a "creatable"
        // definition we can interpret it that way
        // values used to be plain strings
        if (!isHybrid && !this._isFirstCreatableBackwardsCompatCheck) {
          this._isFirstCreatableBackwardsCompatCheck = true;
          const newInclude = [];
          const newExclude = [];
          const check = (source, target) => {
            source.forEach((v) => {
              if (!isCreatableValue(v) && !isSpecialOptionValue(v)) {
                target.push(v);
              }
            });
          };
          // determine if we have any "old" value signatures
          // for creatable multiselect
          check(includeOptionValues, newInclude);
          check(excludeOptionValues, newExclude);
          if (newInclude.length || newExclude.length) {
            this.addCreatableOptions(newInclude.concat(newExclude));

            const newValue: MultiselectQueryFilterValue = {
              ...value,
              includeOptionValues: newInclude.map(
                makeCreatableValueFromUserInput
              ),
              excludeOptionValues: newExclude.map(
                makeCreatableValueFromUserInput
              ),
            };
            this.events.emit('internalValueChange', newValue);
          }
        }

        const optionsToAdd: string[] = includeOptionValues
          .concat(excludeOptionValues)
          .reduce((acc, v) => {
            // addCreatableOption takes a plain user input string
            // so when hydrating from state, we flip it back to a plain value
            // and let the rest take over as normal
            if (isCreatableValue(v)) {
              acc.push(getPlainValueFromOptionValue(v));
            }
            return acc;
          }, []);

        this.addCreatableOptions(optionsToAdd);
        resolve(this.getControlData());
      });
    }

    return Promise.all([this._asyncFetchOptionsPromise, creatablePromise]).then(
      () => {
        return this.getControlData();
      }
    );
  }

  hasControlData(): boolean {
    return _.isArray(_.get(this.getControlData(), ['options']));
  }

  hasValue(value: MultiselectQueryFilterValue = {}): boolean {
    return (
      !_.isEmpty(value.includeOptionValues) ||
      !_.isEmpty(value.excludeOptionValues)
    );
  }

  getImplementsInternalValueChange(): boolean {
    return this.isCreatableOptionsDataConfig(this.props.behaviorConfig);
  }

  getElasticQuery(value: MultiselectQueryFilterValue): object | void {
    const { esField } = this.props;
    if (!esField || !this.hasValue(value)) {
      return;
    }

    return getMultiselectEsQuery({
      esField,
      value,
      options: this._getQueryOptions,
    });
  }

  getValuePreview(value: MultiselectQueryFilterValue): string {
    const { options } = this.getControlData();
    if (!value || _.isEmpty(options)) {
      return '';
    }

    const { includeOptionValues = [], excludeOptionValues = [] } = value;
    return getIncludeExcludeMatchPreview(
      includeOptionValues,
      excludeOptionValues,
      options
    );
  }
}
