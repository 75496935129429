import React, { useCallback, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import _ from 'lodash';
import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Cell } from '../Cell/Cell';
import EmptyCell from '../EmptyCell/EmptyCell';
import CellBackground, {
  CellBackgroundProps,
} from '../CellBackground/CellBackground';
import CornerActions from '../CornerActions/CornerActions';
import { colors } from '@shield-ui/styles';
import {
  LabelIdType,
  LabelType,
  ResultType,
  RenderEmptyCellArgs,
  RenderLabelCellArgs,
  RenderCellArgs,
  SelectionProps,
} from './types';
import { LoadingIndicator } from '@shield-ui/core';

const useStyles = makeStyles(() => {
  const labelBorder = `1.5px solid ${alpha(colors.hues.orange, 0.35)}`;
  const CELL_W = 250;
  const CELL_H = 130;
  const SCROLL_SIZE = 8;

  return {
    container: {
      width: 'calc(100vw - 48px)',
      height: 'calc(100vh - 48px)',
      position: 'relative',
    },
    shape: {
      width: CELL_W,
      height: CELL_H,
      flexShrink: 0,
    },
    overContent: {
      zIndex: 5,
      position: 'absolute',
      overflow: 'hidden',
      background: colors.hues.grays[180],
    },
    corner: {
      width: CELL_W,
      height: CELL_H,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRight: labelBorder,
      borderBottom: labelBorder,
    },
    xLabels: {
      width: `calc(100% - ${CELL_W}px - ${SCROLL_SIZE}px)`,
      height: CELL_H,
      display: 'flex',
      flexDirection: 'row',
      top: 0,
      left: CELL_W,
      borderBottom: labelBorder,
    },
    yLabels: {
      width: CELL_W,
      height: `calc(100% - ${CELL_H}px - ${SCROLL_SIZE}px)`,
      display: 'flex',
      flexDirection: 'column',
      left: 0,
      top: CELL_H,
      borderRight: labelBorder,
    },
    content: {
      position: 'relative',
      top: 0,
      left: 0,
      paddingTop: CELL_H,
      paddingLeft: CELL_W,
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
    },
    contentRow: {
      display: 'flex',
    },
    centerContentMessage: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };
});

export interface HeatGridProps {
  // ordered list of labels for the x-axis
  xLabels: LabelType[];
  // ordered list of labels for the y-axis
  yLabels: LabelType[];
  // results for each cell and references to the axis label they fall under
  results: ResultType[];
  // overload the default emptyCell behavior
  renderEmptyCell?: (args: RenderEmptyCellArgs) => React.ReactNode;
  renderLabelCell?: (args: RenderLabelCellArgs) => React.ReactNode;
  renderCell?: (args: RenderCellArgs) => React.ReactNode;
  // complete width size of the content
  width?: React.CSSProperties['width'];
  // complete height size of the content
  height?: React.CSSProperties['height'];
  // selection props
  selectionProps?: SelectionProps;
  // loading
  isLoading?: boolean;
}

type ResultMapType = Record<LabelIdType, Record<LabelIdType, ResultType>>;

function toggleArray(id: LabelIdType, selected: LabelIdType[]) {
  if (selected.includes(id)) {
    return selected.filter((item) => item !== id);
  } else {
    return selected.concat([id]);
  }
}

function defaultRenderLabelCell(args: RenderLabelCellArgs) {
  return <Cell {...args.cellProps} />;
}

function defaultRenderCell(args: RenderCellArgs) {
  return (
    <Cell
      label={`[VAL] + ${args.xLabel.id}|${args.yLabel.id}`}
      {...args.cellProps}
    />
  );
}

function defaultRenderEmptyCell(args: RenderEmptyCellArgs) {
  return <EmptyCell />;
}

export function HeatGrid(props: HeatGridProps) {
  const {
    xLabels,
    yLabels,
    results,
    renderEmptyCell = defaultRenderEmptyCell,
    renderLabelCell = defaultRenderLabelCell,
    renderCell = defaultRenderCell,
    width,
    height,
    selectionProps,
    isLoading,
  } = props;
  const classes = useStyles();

  const [selectedX, setSelectedX] = useState<LabelIdType[]>([]);
  const [selectedY, setSelectedY] = useState<LabelIdType[]>([]);
  const xLabelRef = useRef<HTMLDivElement>(null);
  const yLabelRef = useRef<HTMLDivElement>(null);

  const onDeselectAll = useCallback(() => {
    setSelectedX([]);
    setSelectedY([]);
  }, [setSelectedX, setSelectedY]);

  const onScroll = useMemo(() => {
    return function (evt: React.UIEvent<HTMLDivElement>) {
      if (xLabelRef.current) {
        xLabelRef.current.scrollLeft = evt.currentTarget.scrollLeft;
      }
      if (yLabelRef.current) {
        yLabelRef.current.scrollTop = evt.currentTarget.scrollTop;
      }
    };
  }, [xLabelRef, yLabelRef]);

  const resultContentMap: ResultMapType = useMemo(() => {
    return results.reduce((acc, result) => {
      if (!acc[result.xLabelId]) {
        acc[result.xLabelId] = {};
      }
      if (!acc[result.xLabelId][result.yLabelId]) {
        acc[result.xLabelId][result.yLabelId] = result;
      }
      return acc;
    }, {} as ResultMapType);
  }, [results]);

  function renderLabels(labels: LabelType[], type: 'x' | 'y') {
    const selectedLabels = type === 'x' ? selectedX : selectedY;
    const setSelected = type === 'x' ? setSelectedX : setSelectedY;

    return (
      <>
        {labels.map((label, labelIndex) => {
          const isSelected = selectedLabels.includes(label.id);
          const bgProps: CellBackgroundProps =
            type === 'x'
              ? {
                  horzDisplay: 'none',
                  vertDisplay: 'bottomOnly',
                  selectedVert: isSelected,
                }
              : {
                  horzDisplay: 'rightOnly',
                  vertDisplay: 'none',
                  selectedHorz: isSelected,
                };

          return (
            <div
              key={`${label}-${labelIndex}`}
              className={classes.shape}
              onClick={() => {
                const newSelected = toggleArray(label.id, selectedLabels);
                setSelected(newSelected);
              }}
            >
              <CellBackground {...bgProps}>
                {renderLabelCell({
                  axis: type,
                  label,
                  cellProps: {
                    label: label.label,
                    contentData: label,
                    labelDisplay: 'axis',
                    isHighlighted: isSelected,
                  },
                })}
              </CellBackground>
            </div>
          );
        })}
      </>
    );
  }

  let content;
  if (isLoading) {
    content = (
      <div className={classes.content}>
        <div className={classes.centerContentMessage}>
          <LoadingIndicator message="Loading Data" />
        </div>
      </div>
    );
  } else if (!isLoading && !results.length) {
    content = (
      <div className={classes.content}>
        <div
          className={classes.centerContentMessage}
          style={{ color: colors.hues.grays['50'], fontSize: '1.2em' }}
        >
          No Results
        </div>
      </div>
    );
  } else {
    content = (
      <div className={classes.content} onScroll={onScroll}>
        {yLabels.map((y, yIndex) => {
          const isLastY = yIndex === yLabels.length - 1;
          return (
            <div key={y.id} className={classes.contentRow}>
              {xLabels.map((x, xIndex) => {
                const isLastX = xIndex === xLabels.length - 1;

                const cell = _.get(resultContentMap, [x.id, y.id]);
                const isXSelected = selectedX.includes(x.id);
                const isYSelected = selectedY.includes(y.id);
                const cellIsHighlighted =
                  selectedX.includes(x.id) || selectedY.includes(y.id);

                return (
                  <div
                    key={`${x.id}-${xIndex}~${y.id}-${yIndex}`}
                    className={classes.shape}
                    onClick={(evt) => {
                      if (
                        (isXSelected && isYSelected) ||
                        (!isXSelected && !isYSelected)
                      ) {
                        setSelectedX(toggleArray(x.id, selectedX));
                        setSelectedY(toggleArray(y.id, selectedY));
                      } else if (isXSelected) {
                        setSelectedY(toggleArray(y.id, selectedY));
                      } else if (isYSelected) {
                        setSelectedX(toggleArray(x.id, selectedX));
                      }
                    }}
                  >
                    <CellBackground
                      selectedVert={selectedX.includes(x.id)}
                      selectedHorz={selectedY.includes(y.id)}
                      horzDisplay={isLastX ? 'leftOnly' : undefined}
                      vertDisplay={isLastY ? 'topOnly' : undefined}
                    >
                      {cell
                        ? renderCell({
                            xLabel: x,
                            yLabel: y,
                            result: cell,
                            cellProps: {
                              labelDisplay: 'body',
                              isHighlighted: cellIsHighlighted,
                              contentData: cell,
                            },
                          })
                        : renderEmptyCell({ xLabel: x, yLabel: y })}
                    </CellBackground>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className={classes.container} style={{ width, height }}>
      <div className={cx(classes.corner, classes.overContent)}>
        <CornerActions
          selectedX={selectedX}
          selectedY={selectedY}
          xLabels={xLabels}
          yLabels={yLabels}
          onDeselectAll={onDeselectAll}
          selectionProps={selectionProps}
        />
      </div>
      <div className={cx(classes.xLabels, classes.overContent)} ref={xLabelRef}>
        {renderLabels(xLabels, 'x')}
      </div>
      <div className={cx(classes.yLabels, classes.overContent)} ref={yLabelRef}>
        {renderLabels(yLabels, 'y')}
      </div>
      {content}
    </div>
  );
}

export default HeatGrid;
