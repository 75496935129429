import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import Range from '../Range/Range';

const MIN = 0; // * 60
const MAX = 24 * 60;
const STEP = 15;

const TIME_FORMAT = 'HH:mm:ss';
const TIME_0 = '00:00:00';
const TIME_24 = '24:00:00';

/*
    PST: 18:30-24:00 | 1110-1440
    UTC: 01:30-07:00

    PST: 12:00-24:00 | 720-1440
    UTC: 19:00-24:00, 00:00-07:00
         12:00-17:00, 17:00-24:00 [PST]

    PST: 00:00-08:00 | 0-480
    UTC: 07:00-15:00

    TODO - Opposite side of the world test... e.g. my Timezone is Japan
 */

function momentToMinutesInDay(mom, maxIfMin) {
  const minutes = mom.hours() * 60 + mom.minutes();
  if (maxIfMin && minutes === 0) {
    return MAX;
  }
  return minutes;
}

function minutesOfDayToTime(value) {
  const min = value % 60;
  const hour = Math.floor(value / 60);

  return `${_.padStart(`${hour}`, 2, '0')}:${_.padStart(`${min}`, 2, '0')}`;
}

export function getValueDescription(apiQueryValue) {
  const [minStart, minEnd] = convertValueToRange(apiQueryValue);

  return `${minutesOfDayToTime(minStart)} - ${minutesOfDayToTime(minEnd)}`;
}

export function convertValueToRange(value) {
  if (!value) {
    return;
  }

  let convertedValue = value.map(({ gte, lte }) => {
    const c = {
      gte: gte
        ? moment.utc(gte, TIME_FORMAT).local().format(TIME_FORMAT)
        : TIME_0,
      lte: lte
        ? moment.utc(lte, TIME_FORMAT).local().format(TIME_FORMAT)
        : TIME_24,
    };

    // 24:00:00 is the same as 00:00:00 really, but for all purposes, it makes our processing a lot more
    // easy to visualize it this way.
    if (c.lte === TIME_0) {
      c.lte = TIME_24;
    }
    return c;
  });
  // ensure they are in order of time for the user, probably will be though
  convertedValue = _.sortBy(convertedValue, 'gte');

  const valueGte = convertedValue[0].gte;
  let valueLte = convertedValue[0].lte;
  if (convertedValue[1] && convertedValue[1].gte === convertedValue[0].lte) {
    valueLte = convertedValue[1].lte;
  }

  const valueStartMom = moment(valueGte, TIME_FORMAT);
  const valueEndMom = moment(valueLte, TIME_FORMAT);

  return [
    momentToMinutesInDay(valueStartMom),
    momentToMinutesInDay(valueEndMom, true),
  ];
}

/**
 * Convert the numeric values which is a description of minutes of the day and turn it into a UTC range of times
 * Tricky part is when the utc offset causes two ranges
 * @param val
 * @returns {{gte: string, lte: string}[]|({gte: string, lte: string}|{gte: string, lte: string})[]|undefined}
 */
export function convertRangeToValue(val) {
  const [minVal, maxVal] = val;
  // unset this with an undefined
  const gteTime = minutesOfDayToTime(minVal);
  const lteTime = minutesOfDayToTime(maxVal);
  const gteUTCMom = moment(gteTime, 'HH:mm').utc();
  const lteUTCMom = moment(lteTime, 'HH:mm').utc();

  // this is our special case when time wraps due to UTCa normal time range
  if (lteUTCMom.hours() < gteUTCMom.hours()) {
    return [
      {
        gte: gteUTCMom.format(TIME_FORMAT),
        lte: TIME_24,
      },
      {
        gte: TIME_0,
        lte: lteUTCMom.format(TIME_FORMAT),
      },
    ];
  } else {
    return [
      {
        gte: gteUTCMom.format(TIME_FORMAT),
        lte: lteUTCMom.format(TIME_FORMAT),
      },
    ];
  }
}

function getRangeValue(value) {
  const temp = convertValueToRange(value);
  if (!temp) {
    return undefined;
  }

  return {
    gte: temp[0],
    lte: temp[1],
  };
}

function getOnChangeValue(val) {
  // we unset form values when they return to defaults, if it is at min/max then it is default
  if ((!val[0] || val[0] === MIN) && (!val[1] || val[1] === MAX)) {
    return undefined;
  }
  return convertRangeToValue(val);
}

function TimeOfDayRanges(props) {
  const { value, onChange, disabled } = props;

  const marks = {
    [MIN]: <strong>00:00</strong>,
    [MAX / 2]: <strong>12:00</strong>,
    [MAX]: <strong>24:00</strong>,
  };

  return (
    <Range
      min={MIN}
      max={MAX}
      step={STEP}
      marks={marks}
      disabled={disabled}
      value={getRangeValue(value)}
      onAfterChange={(val) => {
        onChange(getOnChangeValue(val));
      }}
      tipFormatter={minutesOfDayToTime}
    />
  );
}
TimeOfDayRanges.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      gte: PropTypes.string,
      lte: PropTypes.string,
    })
  ),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

// Note to remap this if you would wrap in a HOC
TimeOfDayRanges.getValueDescription = getValueDescription;
export default TimeOfDayRanges;
