import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    borderTop: 'rgba(255, 255, 255, 0.075)',
    marginBottom: theme.spacing(1),
  },
  space: {
    minWidth: 24,
    flex: 1,
  },
}));

type Props = {
  Left?: React.ReactNode;
  Right?: React.ReactNode;
};

export default function ActionsContainer(props: Props) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {props.Left}
      <div className={classes.space} />
      {props.Right}
    </div>
  );
}
