import React, { Fragment } from 'react';
import _ from 'lodash';
import withStyles from '@mui/styles/withStyles';
import { Grid, Paper } from '@mui/material';
import { Select } from 'antd';
import { getShortDate } from '@shield-ui/utils';
import {
  NOTE_TYPES,
  deleteFlightNote,
  createFlightNote,
  updateFlightNote,
} from '../../../services/notes';
import { SOURCE_TYPE_IDS_HARDCODED } from '../../../services/sourceTypes';
import SectionSubmitContainer from '../../../components/SubmitSection';
import SectionSubmitButton from '../components/SectionSubmitButton';
import NotesList from './NotesList';
import NoteHeader from './Header';
import NoteContent from './NoteContent';
import { showConfirmPrompt } from '../../../lib/messages';
import container from './container';
import { getNoteTypeOptions } from '../../../services/notes';
import { Link } from 'react-router-dom';
import { getSessionLogDisplayName } from '../../../services/robotLogs';

const { Option } = Select;

const styles = (theme) => ({
  addFormPaper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  groupHeader: {
    display: 'flex',
    paddingLeft: 22,
    paddingTop: 14,
    fontSize: 16,
    color: 'gray',
  },
  chip: {
    borderRadius: 14,
    '& .MuiChip-label': { fontSize: 14, fontWeight: 400 },
  },
});

class MainContent extends React.Component {
  state = {
    currentNote: '',
    noteType: NOTE_TYPES.POST_REVIEW,
    noteToDelete: 0,
    editNoteId: 0,
    editDisplayText: undefined,
    editNoteType: undefined,
    flightFilter: undefined,
    noteTypeFilter: undefined,
    editFlightId: this.props.robotLogId,
  };

  addOnChange = (e) => {
    this.setState({ currentNote: e.target.value });
  };

  headerOnChange = (p) => {
    const { noteType } = p;

    if (noteType) {
      this.setState({ noteType });
    }
  };

  editFlightOnChange = (value) => {
    this.setState({ editFlightId: value });
  };

  flightFilterOnChange = (value) => {
    this.setState({ flightFilter: value });

    if (value === undefined) {
      this.setState({ editFlightId: this.props.robotLogId });
    } else {
      this.setState({ editFlightId: value });
    }
  };

  noteFilterOnChange = (value) => {
    this.setState({ noteTypeFilter: value });
  };

  updateEditNote = (p) => {
    const props = _.pick(p, ['editNoteId', 'editDisplayText', 'editNoteType']);
    this.setState(props);
  };

  cancelEditNote = () => {
    this.setState({
      editNoteId: 0,
      editDisplayText: undefined,
      editNoteType: undefined,
    });
  };

  submitEditNote = () => {
    const { editNoteId, editDisplayText, editNoteType } = this.state;

    if (editNoteId === 0) {
      return;
    }

    const variables = {
      id: editNoteId,
      displayText: editDisplayText,
      noteType: editNoteType,
    };

    updateFlightNote(variables, (err, res) => {
      if (err) {
        this.props.showErrorSnack(new Error('An error has occurred'));
        console.error(err);
        return;
      }
      this.props.showSuccessSnack('Flight note updated');
      this.setState({ editNoteId: 0, editDisplayText: '' });
      this.props.refetch();
    });
  };

  createNote = () => {
    const { currentNote, noteType } = this.state;

    if (!currentNote) {
      this.props.showErrorSnack(new Error('Please enter your note'));
      return;
    }

    const variables = {
      robotLogId: this.state.editFlightId,
      displayText: currentNote,
      noteType,
      sourceTypeId: SOURCE_TYPE_IDS_HARDCODED.POST_FLIGHT_REVIEW,
    };
    createFlightNote(variables, (err, res) => {
      if (err) {
        return console.error(err);
      }

      this.props.showSuccessSnack('Flight note added');
      this.setState({ currentNote: '' });
      this.props.refetch();
    });
  };

  onDeleteNote = (noteId) => {
    showConfirmPrompt({
      title: 'Are you sure you want to delete this note?',
      body:
        'If you delete this note, it will not be recoverable.  It will need to be re-added. ' +
        'Are you sure you want to continue?',
      confirmLabel: 'Yes, Delete',
      onConfirm: () => {
        deleteFlightNote(noteId, (err) => {
          if (err) {
            this.props.showErrorSnack(new Error('Problem deleting note'));
            return console.error(err);
          }
          this.setState({ noteToDelete: 0 });
          this.props.showSuccessSnack('Flight note deleted');
          this.props.refetch();
        });
      },
    });
  };

  render() {
    const { classes, currentUser, robotLogId, data } = this.props;
    const noteTypes = getNoteTypeOptions();

    if (!robotLogId) {
      return null;
    }

    const children = _.get(data, ['robotLog', 'children'], []);
    const parentNotes = _.get(data, ['robotLog', 'notes', 'edges'], []);

    const childrenNotes = children.flatMap((child) =>
      child.notes.edges.flatMap((note) => note)
    );

    const allNotes = _.concat(parentNotes, childrenNotes);
    const parentName = getSessionLogDisplayName(data.robotLog);

    const filteredChildren = _.filter(
      children,
      (child) => child.id === this.state.flightFilter
    );

    const filteredChildrenNotes = filteredChildren.flatMap((child) =>
      child.notes.edges.flatMap((note) => note)
    );

    const allNamesID = {
      [robotLogId]: parentName,
    };
    children.forEach((child) => (allNamesID[child.id] = child.name));

    const existingFlightIds = {};
    if (parentNotes.length) {
      existingFlightIds[robotLogId] = parentName;
    }
    children.forEach((child) => {
      if (child.notes.edges.length) {
        existingFlightIds[child.id] = child.name;
      }
    });

    function getExistingFlightIdsByName(existingFlightIds, name) {
      return Object.keys(existingFlightIds).find(
        (id) => existingFlightIds[id] === name
      );
    }

    const orderNotes = (notes) => {
      const postReview = {};
      const observer = {};
      notes.forEach((note) => {
        if (note.node.noteType === 'POST_REVIEW') {
          if (!(note.node.createdBy.id in postReview)) {
            postReview[note.node.createdBy.id] = [note];
          } else {
            postReview[note.node.createdBy.id].push(note);
          }
        } else if (note.node.noteType === 'OBSERVER') {
          if (!(note.node.createdBy.id in observer)) {
            observer[note.node.createdBy.id] = [note];
          } else {
            observer[note.node.createdBy.id].push(note);
          }
        }
      });
      let orderedNotes = [];
      for (let key in postReview) {
        orderedNotes = orderedNotes.concat(postReview[key]);
      }
      for (let key in observer) {
        orderedNotes = orderedNotes.concat(observer[key]);
      }
      return orderedNotes;
    };

    const filterByNoteType = (notes, noteTypeFilter) => {
      return _.filter(notes, (note) => note.node.noteType === noteTypeFilter);
    };

    const getNotes = (flightFilter, noteTypeFilter) => {
      if (!flightFilter) {
        return noteTypeFilter
          ? orderNotes(filterByNoteType(allNotes, noteTypeFilter))
          : orderNotes(allNotes);
      } else if (flightFilter === robotLogId) {
        return noteTypeFilter
          ? orderNote(filterByNoteType(parentNotes, noteTypeFilter))
          : orderNotes(parentNotes);
      } else {
        return noteTypeFilter
          ? orderNotes(filterByNoteType(filteredChildrenNotes, noteTypeFilter))
          : orderNotes(filteredChildrenNotes);
      }
    };

    const getTeamingDefaultNotes = (noteTypeFilter) => {
      const flightNotes = {
        [parentName]: parentNotes,
      };
      children.forEach(
        (child) => (flightNotes[child.name] = child.notes.edges)
      );
      if (noteTypeFilter) {
        Object.keys(flightNotes).forEach(
          (key) =>
            (flightNotes[key] = filterByNoteType(
              flightNotes[key],
              noteTypeFilter
            ))
        );
      }
      for (let key in flightNotes) {
        if (flightNotes[key].length < 1) {
          delete flightNotes[key];
        } else {
          flightNotes[key] = orderNotes(flightNotes[key]);
        }
      }
      return flightNotes;
    };

    const filters = () => {
      return (
        <Fragment>
          <Grid item xs={8}>
            <Select
              placeholder="Filter by session log"
              size={'large'}
              autoWidth={true}
              style={{
                display: 'flex',
                marginRight: 40,
                marginTop: 20,
                marginBottom: 16,
              }}
              onChange={this.flightFilterOnChange}
              allowClear
            >
              {Object.keys(existingFlightIds).map((id) => (
                <Option key={id} value={id}>
                  {existingFlightIds[id]}
                </Option>
              ))}
            </Select>
          </Grid>
          <Grid item xs={4}>
            <Select
              placeholder="Class of Notes"
              size={'large'}
              style={{
                display: 'flex',
                marginLeft: 60,
                marginTop: 20,
                marginBottom: 16,
              }}
              onChange={this.noteFilterOnChange}
              allowClear
            >
              {noteTypes.map((type) => (
                <Option key={type.value} value={type.value}>
                  {type.label}
                </Option>
              ))}
            </Select>
          </Grid>
        </Fragment>
      );
    };

    return (
      <div>
        <Grid container>
          {children.length ? filters() : null}
          {!this.state.flightFilter && children.length ? (
            Object.entries(
              getTeamingDefaultNotes(this.state.noteTypeFilter)
            ).map(([flightName, flightNotes]) => {
              return (
                <>
                  <Grid item xs={12}>
                    <Link
                      className={classes.groupHeader}
                      to={`${getExistingFlightIdsByName(
                        existingFlightIds,
                        flightName
                      )}`}
                    >
                      {flightName}
                    </Link>
                  </Grid>
                  <Grid item xs={12}>
                    <NotesList
                      notes={flightNotes}
                      onDeleteNote={this.onDeleteNote}
                      updateEditNote={this.updateEditNote}
                      editNoteId={this.state.editNoteId}
                      cancelEditNote={this.cancelEditNote}
                      editDisplayText={this.state.editDisplayText}
                      editNoteType={this.state.editNoteType}
                      submitEditNote={this.submitEditNote}
                      hasChildren={true}
                    />
                  </Grid>
                </>
              );
            })
          ) : (
            <Grid item xs={12}>
              <NotesList
                notes={getNotes(
                  this.state.flightFilter,
                  this.state.noteTypeFilter
                )}
                onDeleteNote={this.onDeleteNote}
                updateEditNote={this.updateEditNote}
                editNoteId={this.state.editNoteId}
                cancelEditNote={this.cancelEditNote}
                editDisplayText={this.state.editDisplayText}
                editNoteType={this.state.editNoteType}
                submitEditNote={this.submitEditNote}
                hasChildren={true}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Paper className={classes.addFormPaper}>
              <Grid container>
                <NoteHeader
                  user={currentUser}
                  dateDisplay={getShortDate(new Date())}
                  isEditing
                  onChange={this.headerOnChange}
                  noteType={this.state.noteType}
                  flightFilter={this.state.flightFilter}
                />
                <NoteContent
                  value={this.state.currentNote}
                  onChange={this.addOnChange}
                  isEditing
                />
                <Grid item xs={1} />
                <Grid item xs={10}>
                  <SectionSubmitContainer>
                    {!!children.length && (
                      <Select
                        value={this.state.editFlightId}
                        disabled={this.state.flightFilter}
                        size={'large'}
                        style={{ width: 400, marginRight: 15 }}
                        onChange={this.editFlightOnChange}
                      >
                        {Object.keys(allNamesID).map((id) => (
                          <Option key={id} value={id}>
                            {allNamesID[id]}
                          </Option>
                        ))}
                      </Select>
                    )}
                    <SectionSubmitButton
                      onClick={this.createNote}
                      disabled={!this.state.currentNote}
                    >
                      Save
                    </SectionSubmitButton>
                  </SectionSubmitContainer>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default container(withStyles(styles)(MainContent));
