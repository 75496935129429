import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import CheckSelectWrapper from './CheckSelectWrapper';
import Label from './Label';

function styles(theme) {
  return {
    insetWrapper: {
      marginLeft: 12,
      borderLeft: `2px solid ${theme.palette.action.hover}`,
      paddingLeft: 16,
    },
  };
}

function ChildRow({ label, classes, ...rest }) {
  return (
    <CheckSelectWrapper {...rest}>
      <div className={classes.insetWrapper}>
        <Label {...rest}>{label}</Label>
      </div>
    </CheckSelectWrapper>
  );
}
ChildRow.propTypes = {
  ...CheckSelectWrapper.propTypes,
  ...Label.propTypes,
  label: PropTypes.string.isRequired,
};

export default withStyles(styles)(ChildRow);
