import React, { useState } from 'react';
import Omnisearch, { OmnisearchProps } from '../Omnisearch/Omnisearch';
import SearchIcon from '@mui/icons-material/Search';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import Popover from '@mui/material/Popover';
import { colors } from '@shield-ui/styles';

const StyledPopover = withStyles((theme) => ({
  paper: {
    overflow: 'visible',
  },
}))(Popover);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popoverContainer: {},
    popoverIcon: {
      cursor: 'pointer',
      color: colors.hues.grays[30],
      opacity: 0.8,
      transition: '0.1s ease',
      '&:hover': {
        opacity: 1,
      },
      marginBottom: theme.spacing(5),
      fontSize: 33,
    },
    popoverIconActive: {
      cursor: 'pointer',
      color: theme.palette.primary.light,
      marginBottom: theme.spacing(5),
      fontSize: 33,
    },

    popoverOmnisearchContainer: {
      borderStyle: 'solid',
      borderColor: colors.semantic.inputBorder,
      borderRadius: theme.shape.borderRadius,
      borderWidth: '0.15em',
      padding: theme.spacing(2),
      backgroundColor: colors.hues.grays[180],
      width: 570,
    },
  })
);

function OmnisearchPopover(props: OmnisearchProps) {
  const { onSearchRequest, placeholder } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const PopoverStyle = {
    top: '-30px',
    marginLeft: '20px',
  };

  return (
    <div className={classes.popoverContainer}>
      <SearchIcon
        className={open ? classes.popoverIconActive : classes.popoverIcon}
        onClick={handleClick}
      ></SearchIcon>
      <StyledPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        style={PopoverStyle}
      >
        <div className={classes.popoverOmnisearchContainer}>
          <Omnisearch
            onSearchRequest={onSearchRequest}
            placeholder={placeholder}
          />
        </div>
      </StyledPopover>
    </div>
  );
}

export default OmnisearchPopover;
