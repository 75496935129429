// import { fontName as robotoFontName } from './fonts/roboto';
import { fontName as riftFontName } from './fonts/rift';
import { fontName as proximaNovaFontName } from './fonts/proximaNova';

const fontFallback = '"Helvetica", "Arial", sans-serif';

export const defaultFontFamily = `"${proximaNovaFontName}", ${fontFallback}`;
export const headerFontFamily = `"${riftFontName}", ${fontFallback}`;
//export const defaultFontFamily = `"${proximaNovaFontName}", "${robotoFontName}", ${fontFallback}`;
//export const headerFontFamily = `"${riftFontName}", "${robotoFontName}", ${fontFallback}`;
