import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { colors } from '@shield-ui/styles';
import withStyles from '@mui/styles/withStyles';
import SearchIcon from '@mui/icons-material/Search';
import scrollIntoView from 'dom-scroll-into-view';
import ParentRow from './ParentRow';
import ChildGroupRow from './ChildGroupRow';
import ChildRow from './ChildRow';
import NonInteractiveDropdownBase from './NonInteractiveDropdownBase';
import { Button } from '@mui/material';
import { getScrollParent, doesInputMatchBase } from '@shield-ui/utils';

// Types of "option" rows we support
const ITEM_ROW_RENDER_TYPES = {
  parent: 'parent',
  child: 'child',
  childGroup: 'childGroup',
};
// Number of keyboard focusable elements before the list of options
const FOCUS_COUNT_BEFORE_LIST = 1;

function styles(theme) {
  return {
    root: {
      // fontFamily: theme.typography.fontFamily,
      position: 'relative',
    },
    dropdown: {
      minWidth: '100%',
      padding: 8,
      position: 'absolute',
      zIndex: 1000,
      right: 0,
      top: 0,
      backgroundColor: colors.semantic.inputBackground,
      boxShadow: theme.shadows[2],
    },
    parentGroup: {
      borderBottom: `1px solid rgba(122, 122, 122, .1)`,
      marginBottom: 3,
      paddingBottom: 3,
    },
    itemList: {
      listStyleType: 'none',
      margin: `5px 0`,
      padding: 0,
      maxHeight: 300,
      overflowY: 'scroll',
      borderBottom: `1px solid rgba(0, 0, 0, .2)`,
      paddingRight: 5,
    },
    searchContainer: {
      display: 'flex',
      background:
        theme.palette.mode === 'light'
          ? `rgba(0, 0, 0, .1)`
          : 'rgba(255, 255, 255, .1)',
      padding: 5,
      marginBottom: 5,
    },
    searchIcon: {
      fontSize: 32,
    },
    searchInput: {
      outline: 'none',
      border: 'none',
      background: 'transparent',
      padding: `0 10px`,
      fontSize: 14,
      color: theme.palette.text.primary,
      borderRadius: theme.shape.borderRadius,
      flex: 1,
      '&::placeholder': {
        color: theme.palette.text.hint,
      },
    },
    noResults: {
      padding: `20px 0`,
      color: theme.palette.text.secondary,
      fontSize: 15,
    },
    groupBreak: {
      margin: 7,
      borderTop: `1px solid ${
        theme.palette.mode === 'light'
          ? 'rgba(0, 0, 0, .1)'
          : 'rgba(255, 255, 255, .1)'
      }`,
    },
    bottomContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  };
}

/**
 * TODO
 * CONTROL IS FOCUSABLE (by tabbing / hidden input)
 * HOME / END
 * PAGEUP / PAGEDOWN
 * Focus ClearAll?
 * up and down should skip disabled members
 */
class ParentChildSelect extends React.Component {
  static propTypes = {
    childValues: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    parentValues: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    headChildItemValues: PropTypes.array,
    disabledChildItemValues: PropTypes.array,
    disabledParentItemValues: PropTypes.array,
    onChangeChildValues: PropTypes.func,
    onChangeParentValues: PropTypes.func,
    childItems: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      })
    ).isRequired,
    parentItems: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        childValues: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
      })
    ).isRequired,
    canSelectParent: PropTypes.bool,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    headless: PropTypes.bool,
    includeClearAction: PropTypes.bool,
  };

  static defaultProps = {
    childValues: [],
    parentValues: [],
    headChildItemValues: [],
    disabledChildItemValues: [],
    disabledParentItemValues: [],
    onChangeChildValues: _.noop,
    onChangeParentValues: _.noop,
    canSelectParent: true,
    disabled: false,
    placeholder: 'Select',
    headless: false,
    includeClearAction: true,
  };

  state = {
    dropdownIsOpen: false, // whether or not the interface is open
    searchValue: '', // dropdown search value
    expandedParents: {}, // which parent child groups are expanded
    flatItems: [], // parents and children are combined together
    canClearAll: false, // computed state if things are selected
    focusedIndex: 40, // default is overridden when showing the dropdown
  };

  rootRef = React.createRef();
  dropdownRef = React.createRef();
  listRef = React.createRef();
  inputRef = React.createRef();
  focusedOptionRef = React.createRef();
  scrollToFocusedOptionOnUpdate = false;

  /**
   * Parent child relationships and other various business logic handled here
   * Take our input data and flatten it into a single array of items and various props for each of those options
   * @param props
   * @param state
   * @returns {{parentItemMap: *, canClearAll: *, childItemMap: *, flatItems: *}}
   */
  static getDerivedStateFromProps(props, state) {
    const { searchValue, expandedParents } = state;
    const {
      parentItems,
      childItems,
      parentValues,
      childValues,
      canSelectParent,
      headChildItemValues,
      disabled,
      disabledChildItemValues,
      disabledParentItemValues,
    } = props;

    const parentItemMap = _.keyBy(parentItems, 'value');
    const childItemMap = _.keyBy(childItems, 'value');

    // make sure we show users that may not have a team...
    const orphans = childItems.filter((childItem) => {
      const parent = _.find(parentItems, (parentItem) =>
        parentItem.childValues.includes(childItem.value)
      );
      return !parent;
    });

    const otherGroups = [];
    if (orphans.length) {
      otherGroups.push({
        label: 'Others',
        childValues: _.map(orphans, 'value'),
      });
    }

    let groupCount = 0;
    let expandedGroupCount = 0;

    const flatItems = parentItems
      .concat(otherGroups)
      .reduce((acc, parent) => {
        const parentMatch = !searchValue
          ? true
          : doesInputMatchBase(searchValue, parent.label);

        // when we have a search value, split child into matches and not matches
        let { childMatches, childOther } = parent.childValues.reduce(
          (acc, childValue) => {
            const child = childItemMap[childValue];
            if (!child) {
              return acc;
            }

            if (!searchValue) {
              acc.childOther.push(child);
            } else if (doesInputMatchBase(searchValue, child.label)) {
              acc.childMatches.push(child);
            } else {
              acc.childOther.push(child);
            }
            return acc;
          },
          { childMatches: [], childOther: [] }
        );

        // skip if we don't have anything to show
        if (!parentMatch && !childMatches.length) {
          return acc;
        }

        // sort the children
        childMatches = _.sortBy(childMatches, 'label');
        childOther = _.sortBy(childOther, 'label');

        const groupItems = [];

        // add the parent
        groupItems.push({
          type: ITEM_ROW_RENDER_TYPES.parent,
          key: parent.value,
          label: parent.label,
          parentValue: parent.value,
          isDisabled:
            !canSelectParent ||
            disabledParentItemValues.includes(parent.value) ||
            !parent.value ||
            disabled,
          isChecked: parentValues.indexOf(parent.value) > -1,
          isSearchMatch: !!(searchValue && parentMatch),
        });

        // add all the child matches
        childMatches.forEach((child) => {
          groupItems.push({
            type: ITEM_ROW_RENDER_TYPES.child,
            key: parent.value + child.value,
            label: child.label,
            childValue: child.value,
            isDisabled:
              disabledChildItemValues.includes(child.value) || disabled,
            isSearchMatch: !!searchValue,
            isChecked: childValues.indexOf(child.value) > -1,
          });
        });

        if (childOther.length) {
          // is this child group expanded
          const isExpanded = !!expandedParents[parent.value];

          // Array of all the values of "other" children
          const groupValues = childOther
            .filter((child) => !disabledChildItemValues.includes(child.value))
            .map((child) => child.value);

          groupItems.push({
            type: ITEM_ROW_RENDER_TYPES.childGroup,
            key: parent.value + 'group',
            label: `${childMatches.length ? 'Other' : 'All'} ${
              childOther.length
            } Member${childOther.length === 1 ? '' : 's'}`,
            parentValue: parent.value,
            childValues: groupValues,
            isDisabled: !groupValues.length || disabled,
            isChecked: _.difference(groupValues, childValues).length === 0,
            isExpanded,
          });
          groupCount += 1;
          expandedGroupCount += isExpanded ? 1 : 0;

          // if the child group is expanded, show all the children
          if (isExpanded) {
            childOther.forEach((child) => {
              groupItems.push({
                type: ITEM_ROW_RENDER_TYPES.child,
                key: parent.value + child.value,
                label: child.label,
                childValue: child.value,
                isDisabled:
                  disabledChildItemValues.includes(child.value) || disabled,
                isChecked: childValues.indexOf(child.value) > -1,
              });
            });
          }
        }

        acc.push(...groupItems);

        return acc;
      }, []);

    headChildItemValues.reverse().forEach((childValue) => {
      const child = childItemMap[childValue];
      if (!child) {
        return;
      }
      if (searchValue && !doesInputMatchBase(searchValue, child.label)) {
        return;
      }

      flatItems.unshift({
        type: ITEM_ROW_RENDER_TYPES.parent,
        key: 'headChildItem' + child.value,
        label: child.label,
        childValue: child.value,
        isDisabled: disabledChildItemValues.includes(child.value) || disabled,
        isSearchMatch: !!searchValue,
        isChecked: childValues.indexOf(child.value) > -1,
      });
    });

    return {
      flatItems,
      parentItemMap,
      childItemMap,
      canClearAll: childValues.length || parentValues.length,
      allParentsAreExpanded: groupCount === expandedGroupCount,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleOutsideClick);
    document.addEventListener('keydown', this.handleKeydown);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick);
    document.removeEventListener('keydown', this.handleKeydown);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // The component updated, maybe a user is scrolling with keyboard and we need to show elements
    if (this.scrollToFocusedOptionOnUpdate) {
      const listEl = _.get(this, 'listRef.current');
      const optionEl = _.get(this, 'focusedOptionRef.current');
      if (listEl && optionEl) {
        scrollIntoView(optionEl, listEl, {
          onlyScrollIfNeeded: true,
        });
      }
    }
    // when the list is opened for the first time, scroll he parent if we can't see all the options
    if (this.scrollDropdownIntoView) {
      const dropdownEl = _.get(this, 'dropdownRef.current');
      const parentEl = getScrollParent(dropdownEl);
      if (dropdownEl && parentEl) {
        scrollIntoView(dropdownEl, parentEl, {
          onlyScrollIfNeeded: true,
        });
      }
    }

    this.scrollToFocusedOptionOnUpdate = false;
    this.scrollDropdownIntoView = false;
  }

  /**
   * The list is opened, various state reset
   */
  onShowList = () => {
    this.setState({
      dropdownIsOpen: true,
      focusedIndex: 0,
      rootHeight: this.rootRef.current.offsetHeight,
    });
    this.scrollDropdownIntoView = true;
  };

  /**
   * The list needs closed, various state reset
   */
  onCloseList = () => {
    this.setState({
      dropdownIsOpen: false,
      searchValue: '',
      focusedIndex: 0,
    });
  };

  /**
   * Traversing the list up and down
   * @param evt
   */
  handleKeyNavigation(evt) {
    const { focusedIndex, flatItems } = this.state;
    const maxFocusItems = flatItems.length + FOCUS_COUNT_BEFORE_LIST;
    const maxIndex = maxFocusItems - 1;

    let newIndex;
    // down
    if (evt.keyCode === 40) {
      newIndex = focusedIndex + 1;
      if (newIndex > maxIndex) {
        newIndex = 0;
      }
      // up
    } else if (evt.keyCode === 38) {
      newIndex = focusedIndex - 1;
      if (newIndex === -1) {
        newIndex = maxIndex;
      }
    }

    if (!_.isUndefined(newIndex)) {
      evt.stopPropagation();
      evt.preventDefault();
      evt.returnValue = false;
      evt.cancelBubble = true;

      if (focusedIndex === 0 && newIndex !== 0) {
        this.inputRef.current.blur();
      } else if (focusedIndex !== 0 && newIndex === 0) {
        this.inputRef.current.focus();
      }

      this.scrollToFocusedOptionOnUpdate = true;
      this.setState((prevState) => ({
        ...prevState,
        focusedIndex: newIndex,
      }));
    }
  }

  /**
   * When a user wants to see the members of a ChildGroupRow
   * @param evt
   */
  handleKeyGroupExpansion(evt) {
    const { expandedParents } = this.state;
    const focusedItem = this.getFocusedItem();

    if (focusedItem && focusedItem.type === ITEM_ROW_RENDER_TYPES.childGroup) {
      this.setState({
        expandedParents: {
          ...expandedParents,
          [focusedItem.parentValue]: !focusedItem.isExpanded,
        },
      });
      evt.stopPropagation();
      evt.preventDefault();
    }
  }

  /**
   * When a user "toggles" an option on or off
   * @param evt
   */
  handleKeySelect(evt) {
    const focusedItem = this.getFocusedItem();
    if (!focusedItem) {
      return;
    }

    this.onChangeItem(evt, focusedItem);

    evt.stopPropagation();
    evt.preventDefault();
  }

  /**
   * Keyboard shortcuts (only valid if the dropdown is open)
   * @param evt
   */
  handleKeydown = (evt) => {
    const { dropdownIsOpen } = this.state;
    if (!dropdownIsOpen) {
      return;
    }

    switch (evt.key) {
      case 'ArrowUp':
      case 'ArrowDown':
        this.handleKeyNavigation(evt);
        break;
      case 'ArrowLeft':
      case 'ArrowRight':
        this.handleKeyGroupExpansion(evt);
        break;
      case ' ':
      case 'Space':
      case 'Enter':
        this.handleKeySelect(evt);
        break;
      case 'Tab':
        this.onCloseList();
        break;
    }
  };

  /**
   * When the dropdown is open and a user clicks somewhere else in the window, we need to hide the dropdown
   * @param evt
   */
  handleOutsideClick = (evt) => {
    const { dropdownIsOpen } = this.state;
    if (!dropdownIsOpen) {
      return;
    }

    if (
      this.dropdownRef &&
      this.dropdownRef.current &&
      !this.dropdownRef.current.contains(evt.target)
    ) {
      this.onCloseList();
    }
  };

  /**
   * User is typing in the text input
   * @param evt
   */
  onChangeSearchValue = (evt) => {
    this.setState({
      searchValue: evt.target.value,
      focusedIndex: 0,
    });
  };

  reconcileUpdate(isChecked, values, whichList) {
    const {
      onChangeChildValues,
      onChangeParentValues,
      childValues,
      parentValues,
    } = this.props;
    const updateFunc =
      whichList === 'childValues' ? onChangeChildValues : onChangeParentValues;
    const sourceList = whichList === 'childValues' ? childValues : parentValues;

    if (isChecked) {
      const newValues = _.uniq(sourceList.concat(values));
      updateFunc(newValues);
    } else if (!isChecked) {
      updateFunc(sourceList.filter((v) => values.indexOf(v) === -1));
    }
  }

  onChangeItem = (evt, item) => {
    const { childValues, parentValue, childValue, isChecked } = item;

    if (childValues) {
      this.reconcileUpdate(!isChecked, childValues, 'childValues');
    } else if (parentValue) {
      this.reconcileUpdate(!isChecked, [parentValue], 'parentValues');
    } else if (childValue) {
      this.reconcileUpdate(!isChecked, [childValue], 'childValues');
    }
  };

  /**
   * When a parent's ChildGroupRow expansion is clicked
   * @param evt
   * @param update
   */
  onToggleExpandedChildList = (evt, update) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        focusedIndex: 0,
        expandedParents: {
          ...prevState.expandedParents,
          [update.parentValue]: update.isExpanded,
        },
      };
    });
  };

  collapseAllParents = () => {
    this.setState({
      focusedIndex: 0,
      expandedParents: {},
    });
    this.scrollToFocusedOptionOnUpdate = true;
  };

  expandAllParents = () => {
    const { parentItems } = this.props;
    this.setState({
      focusedIndex: 0,
      expandedParents: parentItems.reduce((acc, parent) => {
        acc[parent.value] = true;
        return acc;
      }, {}),
    });
    this.scrollToFocusedOptionOnUpdate = true;
  };

  /**
   * Remove all child and parent selected values
   */
  clearAll = () => {
    const { onChangeChildValues, onChangeParentValues } = this.props;
    onChangeChildValues([]);
    onChangeParentValues([]);
  };

  /**
   * Given the selected values, drive a display value for the control
   * @returns {string|*}
   */
  getDisplay() {
    const { parentItemMap, childItemMap } = this.state;
    const { parentValues, childValues } = this.props;

    let parentLabel, childLabel;
    if (parentValues.length) {
      const valueMatch = _.find(parentValues, (v) => !!parentItemMap[v]);
      if (valueMatch) {
        parentLabel = parentItemMap[valueMatch].label;
      }
      if (parentValues.length > 1) {
        parentLabel += ` (+${parentValues.length - 1})`;
      }
    }

    if (childValues.length) {
      const valueMatch = _.find(childValues, (v) => !!childItemMap[v]);
      if (valueMatch) {
        childLabel = childItemMap[valueMatch].label;
      }
      if (childValues.length > 1) {
        childLabel += ` (+${childValues.length - 1})`;
      }
    }

    if (childLabel && parentLabel) {
      return `${parentLabel}, ${childLabel}`;
    }
    return parentLabel || childLabel;
  }

  /**
   * Returns which option is currently keyboard focused
   * @returns {*}
   */
  getFocusedItem() {
    const { focusedIndex, flatItems } = this.state;

    return flatItems[focusedIndex - FOCUS_COUNT_BEFORE_LIST];
  }

  renderContent() {
    const { searchValue, flatItems, canClearAll, allParentsAreExpanded } =
      this.state;
    const { classes, disabled } = this.props;
    const focusedItem = this.getFocusedItem();

    return (
      <>
        <div className={classes.searchContainer}>
          <SearchIcon className={classes.searchIcon} />
          <input
            ref={this.inputRef}
            type="text"
            className={classes.searchInput}
            autoFocus
            value={searchValue}
            onChange={this.onChangeSearchValue}
            placeholder="Search"
          />
        </div>
        <ul className={classes.itemList} ref={this.listRef}>
          {flatItems.map((item, index) => {
            const { type, ...rest } = item;

            const props = {
              ...rest,
              onChange: this.onChangeItem,
              item,
              isFocused: item === focusedItem,
            };
            if (props.isFocused) {
              props.focusedOptionRef = this.focusedOptionRef;
            }

            switch (type) {
              case ITEM_ROW_RENDER_TYPES.parent:
                return (
                  <React.Fragment key={props.key}>
                    {index !== 0 && <li className={classes.groupBreak} />}
                    <ParentRow {...props} />
                  </React.Fragment>
                );
              case ITEM_ROW_RENDER_TYPES.childGroup:
                return (
                  <ChildGroupRow
                    onToggleExpanded={this.onToggleExpandedChildList}
                    {...props}
                  />
                );
              case ITEM_ROW_RENDER_TYPES.child:
                return <ChildRow {...props} />;
              default:
                return null;
            }
          })}
          {!flatItems.length && (
            <li className={classes.noResults}>
              No items match the search string
            </li>
          )}
        </ul>
        <div className={classes.bottomContainer}>
          {allParentsAreExpanded && (
            <Button size="small" onClick={this.collapseAllParents}>
              Collapse All
            </Button>
          )}
          {!allParentsAreExpanded && (
            <Button size="small" onClick={this.expandAllParents}>
              Expand All
            </Button>
          )}
          {this.props.includeClearAction && (
            <Button
              disabled={!canClearAll || disabled}
              size="small"
              onClick={this.clearAll}
            >
              Clear Selected
            </Button>
          )}
        </div>
      </>
    );
  }

  render() {
    const { dropdownIsOpen, rootHeight } = this.state;
    const { placeholder, classes, headless, disabled } = this.props;

    if (headless) {
      return this.renderContent();
    }

    return (
      <div ref={this.rootRef} className={classes.root}>
        <NonInteractiveDropdownBase
          onClick={!disabled ? this.onShowList : undefined}
          placeholder={placeholder}
          disabled={disabled}
          valueDisplay={this.getDisplay()}
        />
        {dropdownIsOpen && (
          <div
            ref={this.dropdownRef}
            className={classes.dropdown}
            style={{ top: rootHeight }}
          >
            {this.renderContent()}
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(ParentChildSelect);
