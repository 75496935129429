import React from 'react';
import Link from '@mui/material/Link';
import _ from 'lodash'
import { azureBaseUrl } from '../../../../../lib/mapi.js';

const getValue = ({ row }) => _.get(row, 'softwareInfo.commitHash');
const config = {
    columnKey: 'softwareCommitHash',
    label: 'Commit Hash',
    defaultWidth: 170,
    description: 'Software commit hash the robot was running',
    sortKey: 'software_info.commit_hash',
    getValue,
    Cell: function Cell({ row }) {
      const hash = getValue({ row });
      if (!hash) { return null; }
      return (
        <Link
          href={`${azureBaseUrl}/shieldai/ShieldAI/_git/EdgeAI/commit/${hash}`}
          target="_blank"
          underline="hover">{hash}</Link>
      );
    },
    fragment: `softwareInfo { commitHash }`
}

export default config;
