import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setCurrentUser } from '../redux/authUser/actions';
import { login, logout } from '../redux/settings/actions';
import { showSuccessSnack, showErrorSnack } from '../redux/snackbar/actions';

function mapStateToProps(state) {
  return {
    currentUser: state.authUser.currentUser,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setCurrentUser,
      login,
      logout,
      showErrorSnack,
      showSuccessSnack,
    },
    dispatch
  );
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ContainerProps = ConnectedProps<typeof connector>;
export default connector;
