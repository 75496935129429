import _ from 'lodash';
import sdkClient from '../lib/hmdsdkClient';
import {
  TestDefinition,
  SearchRequest,
  GetRequest,
  UpdateRequest,
  SearchTagsRequest,
  TestDefinitionService,
  TestDefinitionView,
} from '@hmd/sdk/api/tests/definitions/v1';
import * as frequencyGroupsV1 from '@hmd/sdk/api/tests/frequency_groups/v1';
import {
  TestFrequencyGroup,
  TestFrequencyGroupService,
} from '@hmd/sdk/api/tests/frequency_groups/v1';
import * as metricsV1 from '@hmd/sdk/api/tests/definitions/acceptance_criteria/metrics/v1';
import * as metricsGroupsV1 from '@hmd/sdk/api/tests/definitions/acceptance_criteria/metric_groups/v1';
import { FieldMask } from 'google-protobuf/google/protobuf/field_mask_pb';
import tracker from '../lib/tracker';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Struct } from 'google-protobuf/google/protobuf/struct_pb';
import { structValueToBasicStructure } from './passThruSearch';

export type TestDefinitionAsObject = ReturnType<typeof TestDefinition.toObject>;

export type TagSearchResults = {
  popular: string[];
  other: string[];
};

export function fetchQueryFilterOptionsForTagsOfType(tagType) {
  return fetchTestDefinitionTagsOfType(tagType).then((results) => {
    return results.popular
      .map((v) => ({ value: v, label: v }))
      .concat(results.other.map((v) => ({ value: v, label: v })));
  });
}

export async function searchForTestDefinitionTags(
  input: string,
  tagType
): Promise<string[]> {
  const req = new SearchTagsRequest();
  req.setSearchString(input);
  req.setMostPopular(true);
  req.setCount(25);
  req.setTagType(tagType);
  const res = await sdkClient.unary(TestDefinitionService.SearchTags, req);
  return res.getTagsList();
}

export const fetchTestDefinitionTagsOfType = _.memoize(
  (tagType): Promise<TagSearchResults> => {
    const allReq = new SearchTagsRequest();
    allReq.setCount(999);
    allReq.setTagType(tagType); //definitionsV1.TagType.ENVIRONMENTAL)

    const popularReq = new SearchTagsRequest();
    popularReq.setCount(20);
    popularReq.setTagType(tagType); //definitionsV1.TagType.ENVIRONMENTAL)
    popularReq.setMostPopular(true);

    return Promise.all([
      sdkClient.unary(TestDefinitionService.SearchTags, allReq),
      sdkClient.unary(TestDefinitionService.SearchTags, popularReq),
    ])
      .then((results) => {
        const popularTags = results[1].getTagsList();
        const allTags = results[0]
          .getTagsList()
          .filter((tag) => !popularTags.includes(tag));

        return {
          popular: popularTags,
          other: allTags,
        };
      })
      .catch((e) => {
        console.error(e);
        return { popular: [], other: [] };
      });
  }
);

export const fetchAllTestDefinitions = _.once(
  (): Promise<TestDefinitionAsObject[]> => {
    // Recursively fetch requirements
    const testDefinitions = [];
    const pageSize = 200;

    async function recursiveFetchTestDefinitions(token) {
      // If token is empty, we're done
      if (token === '') {
        return testDefinitions;
      }
      const sr = new SearchRequest();

      // If token is null, it's the first request
      if (token !== null) {
        sr.setPageToken(token);
      }
      sr.setPageSize(pageSize);

      // Append to requirements list and set nextPageToken
      let nextToken = '';
      await sdkClient.unary(TestDefinitionService.Search, sr).then((res) => {
        nextToken = res.getNextPageToken();
        const fetchedTestDefinitions = res.getTestDefinitionsList();
        for (const td of fetchedTestDefinitions) {
          testDefinitions.push(td.toObject());
        }
      });
      return recursiveFetchTestDefinitions(nextToken);
    }

    // Execute fetch request
    return recursiveFetchTestDefinitions(null)
      .then((res) => {
        const testDefinitions = res;
        return testDefinitions;
      })
      .catch((e) => {
        console.error(e);
        return [];
      });
  }
);

export const fetchTestDefinitionMetadata = async (platform_id: string) => {
  const req = new SearchRequest();
  req.setPageSize(200);
  req.setPlatformIdsList([platform_id]);
  const res = await sdkClient.unary(TestDefinitionService.Search, req);
  return res
    .toObject()
    .testDefinitionsList.map((td) =>
      structValueToBasicStructure(td.platformMetadata)
    );
};

export const fetchTestDefinition = async (id: string) => {
  const req = new GetRequest();
  req.setId(id);
  req.setView(TestDefinitionView.FULL);
  const res = await sdkClient.unary(TestDefinitionService.Get, req);
  return res.toObject();
};

const TEST_DEFINITIONs_QUERY_KEY = 'testDefinitions';

export const useTestDefinitions = () =>
  useQuery({
    queryKey: [TEST_DEFINITIONs_QUERY_KEY],
    queryFn: () => fetchAllTestDefinitions(),
  });

export const useTestDefinition = (id: string) =>
  useQuery({
    queryKey: [TEST_DEFINITIONs_QUERY_KEY, id],
    queryFn: () => fetchTestDefinition(id),
  });

export const useCommonPlatformMetadata = (platform_id: string) =>
  useQuery({
    queryKey: [TEST_DEFINITIONs_QUERY_KEY, platform_id],
    queryFn: () => fetchTestDefinitionMetadata(platform_id),
  });

// Common strings that describe sections of TestDefinition fields
export const sectionStrings = {
  general: 'General',
  artifacts: 'Artifacts',
  execution: 'Execution',
  ownership: 'Ownership',
  tags: 'Tags',
  acceptanceCriteria: 'Acceptance Criteria',
  acceptanceCriteriaPerTrial: 'Acceptance Criteria per Trial',
  versioning: 'Versioning',
  metadata: 'Metadata',
};

// Common strings that describe TestDefinition fields
export const fieldStrings = {
  metadata: 'Metadata Fields',
  platform: 'Platform',
  platformSubtype: 'Platform Subtype',
  code: 'Test Code',
  name: 'Name',
  description: 'Procedure Description',
  executionCriteria: 'Expected Outcome',
  environments: 'Environments',
  requirements: 'Requirements',
  isExecutionReady: 'Ready to Execute',
  estimatedExecutionDuration: 'Estimated Execution Duration',
  // acceptance criteria
  childRobotLogExpectations: 'Expected Robot Log Children',
  reviewIsRequired: 'Review is Required',
  trialCount: 'Total Trials',
  trialEvaluationTolerance: 'Failure Tolerance',
  // tags
  environmentalTags: 'Environmental Tags',
  equipmentTags: 'Equipment Tags',
  componentTags: 'Component Tags',
  generalTags: 'General Tags',
  //ownership
  ownerUserIds: 'Owner Users',
  ownerTeamIds: 'Owner Teams',
  // artifacts
  artifactCollateral: 'Collateral',
  testFrequencyGroupDetails: 'Test Frequency Groups',

  // metric
  metricIds: 'Metric Assertions',
  metricGroupIds: 'Metric Assertion Groups',

  // diagnostics
  diagnosticAssertions: 'Diagnostic Assertions',

  //versioning
  revisionUpdate: 'Revision Update',
};

export const getTestFrequencyGroupOptions = (): Promise<
  { value: string; label: string }[]
> => {
  return fetchTestFrequencyGroups().then((frequencyGroups) => {
    return frequencyGroups.map((fg) => ({
      value: fg.getId(),
      label: fg.getName(),
    }));
  });
};

export const fetchTestFrequencyGroups = _.once(
  (): Promise<TestFrequencyGroup[]> => {
    const req = new frequencyGroupsV1.ListRequest();

    const getAllPages = (pageToken = '', elements = []) => {
      return new Promise<TestFrequencyGroup[]>((resolve, reject) => {
        req.setPageToken(pageToken);
        sdkClient
          .unary(TestFrequencyGroupService.List, req)
          .then((resp) => {
            const testFrequencyGroups = resp.getTestFrequencyGroupsList();
            const nextPageToken = resp.getNextPageToken();

            const newElements = elements.concat(testFrequencyGroups);
            if (!nextPageToken || nextPageToken.length === 0) {
              resolve(newElements);
            } else {
              getAllPages(nextPageToken, newElements)
                .then(resolve)
                .catch(reject);
            }
          })
          .catch((e) => {
            console.error(e);
            reject([]);
          });
      });
    };

    return getAllPages();
  }
);

export const createTestFrequencyGroup = (
  name: string,
  description: string
): Promise<TestFrequencyGroup> => {
  tracker.trackEvent('TestFrequencyGroup', 'Create', 'Name', name);

  const req = new frequencyGroupsV1.CreateRequest();
  const tfg = new frequencyGroupsV1.TestFrequencyGroup();

  tfg.setName(name);
  tfg.setDescription(description);
  req.setTestFrequencyGroup(tfg);

  return sdkClient.unary(TestFrequencyGroupService.Create, req).then((res) => {
    const testFrequencyGroup = res;
    return testFrequencyGroup;
  });
};

export const updateTestFrequencyGroup = (
  id: string,
  name: string,
  description: string
): Promise<TestFrequencyGroup> => {
  tracker.trackEvent('TestFrequencyGroup', 'Update', 'id', id);
  const req = new frequencyGroupsV1.UpdateRequest();
  const tfg = new frequencyGroupsV1.TestFrequencyGroup();

  // Set Values to be updated
  tfg.setId(id);
  tfg.setName(name);
  tfg.setDescription(description);
  req.setTestFrequencyGroup(tfg);

  // Set updatemask
  const updateMask = new FieldMask();
  const paths = ['name', 'description'];
  updateMask.setPathsList(paths);
  req.setUpdateMask(updateMask);

  return sdkClient
    .unary(frequencyGroupsV1.TestFrequencyGroupService.Update, req)
    .then((res) => {
      const testFrequencyGroup = res;
      return testFrequencyGroup;
    });
};

export const fetchMetricAssertionGroups = (): Promise<
  metricsGroupsV1.AcceptanceCriteriaMetricGroup[]
> => {
  const req = new metricsGroupsV1.ListRequest();

  const getAllPages = (pageToken = '', elements = []) => {
    return new Promise<metricsGroupsV1.AcceptanceCriteriaMetricGroup[]>(
      (resolve, reject) => {
        req.setPageToken(pageToken);
        sdkClient
          .unary(metricsGroupsV1.AcceptanceCriteriaMetricGroupService.List, req)
          .then((resp) => {
            const metricGroups = resp.getAcceptanceCriteriaMetricGroupsList();
            const nextPageToken = resp.getNextPageToken();

            const newElements = elements.concat(metricGroups);
            if (!nextPageToken || nextPageToken.length === 0) {
              resolve(newElements);
            } else {
              getAllPages(nextPageToken, newElements)
                .then(resolve)
                .catch(reject);
            }
          })
          .catch((e) => {
            console.error(e);
            reject([]);
          });
      }
    );
  };

  return getAllPages();
};

export const createMetricAssertionGroup = ({
  name,
  description,
  metricIds,
}): Promise<metricsGroupsV1.AcceptanceCriteriaMetricGroup> => {
  tracker.trackEvent('MetricAssertionGroup', 'Create', 'Name', name);
  const req = new metricsGroupsV1.CreateRequest();
  const mga = new metricsGroupsV1.AcceptanceCriteriaMetricGroup();

  mga.setName(name);
  mga.setDescription(description);
  mga.setAcceptanceCriteriaMetricIdsList(metricIds);
  req.setAcceptanceCriteriaMetricGroup(mga);

  return sdkClient
    .unary(metricsGroupsV1.AcceptanceCriteriaMetricGroupService.Create, req)
    .then((res) => {
      const metricGroup = res;
      return metricGroup;
    });
};

export const updateMetricAssertionGroup = ({
  id,
  name,
  description,
  metricIds,
}): Promise<metricsGroupsV1.AcceptanceCriteriaMetricGroup> => {
  tracker.trackEvent('MetricAssertionGroup', 'Update', 'id', id);
  const req = new metricsGroupsV1.UpdateRequest();
  const mga = new metricsGroupsV1.AcceptanceCriteriaMetricGroup();

  // Set Values to be updated
  mga.setId(id);
  mga.setName(name);
  mga.setDescription(description);
  mga.setAcceptanceCriteriaMetricIdsList(metricIds);
  req.setAcceptanceCriteriaMetricGroup(mga);

  // Set updatemask
  const updateMask = new FieldMask();
  const paths = ['Name', 'Description', 'AcceptanceCriteriaMetricIds'];
  updateMask.setPathsList(paths);
  req.setUpdateMask(updateMask);

  return sdkClient
    .unary(metricsGroupsV1.AcceptanceCriteriaMetricGroupService.Update, req)
    .then((res) => {
      const metricGroup = res;
      return metricGroup;
    });
};

export const fetchMetricAssertions = _.memoize(
  (ids): Promise<metricsV1.AcceptanceCriteriaMetric[]> => {
    const req = new metricsV1.BatchGetRequest();
    req.setIdsList(ids);

    return sdkClient
      .unary(metricsV1.AcceptanceCriteriaMetricService.BatchGet, req)
      .then((resp) => {
        const metrics = resp.getAcceptanceCriteriaMetricsList();
        return metrics;
      });
  }
);

export const createMetricAssertion = ({
  name,
  aggregator,
  operator,
  evaluationState,
  sessionLogTypeSlug,
  value,
  metricKey,
  analysisKey,
}): Promise<metricsV1.AcceptanceCriteriaMetric> => {
  tracker.trackEvent('MetricAssertion', 'Create', 'Name', name);
  const req = new metricsV1.CreateRequest();
  const ma = new metricsV1.AcceptanceCriteriaMetric();

  ma.setName(name);
  ma.setAggregator(aggregator);
  ma.setOperator(operator);
  ma.setSessionLogTypeSlug(sessionLogTypeSlug);
  ma.setEvaluationState(evaluationState);
  if (analysisKey) ma.setAnalysisKey(analysisKey);
  if (metricKey) ma.setMetricKey(metricKey);
  ma.setValue(value);
  req.setAcceptanceCriteriaMetric(ma);

  return sdkClient
    .unary(metricsV1.AcceptanceCriteriaMetricService.Create, req)
    .then((res) => {
      const metric = res;
      return metric;
    });
};

export const updateMetricAssertion = ({
  id,
  name,
  aggregator,
  operator,
  evaluationState,
  sessionLogTypeSlug,
  value,
  metricKey,
  analysisKey,
}): Promise<metricsV1.AcceptanceCriteriaMetric> => {
  tracker.trackEvent('MetricAssertion', 'Update', 'id', id);
  const req = new metricsV1.UpdateRequest();
  const ma = new metricsV1.AcceptanceCriteriaMetric();

  ma.setId(id);
  ma.setName(name);
  ma.setAggregator(aggregator);
  ma.setOperator(operator);
  ma.setSessionLogTypeSlug(sessionLogTypeSlug);
  ma.setEvaluationState(evaluationState);
  if (analysisKey) ma.setAnalysisKey(analysisKey);
  if (metricKey) ma.setMetricKey(metricKey);
  ma.setValue(value);
  req.setAcceptanceCriteriaMetric(ma);

  // Set updatemask
  const updateMask = new FieldMask();
  const paths = [
    'Name',
    'Aggregator',
    'Operator',
    'SessionLogTypeSlug',
    'EvaluationState',
    'DataPoint',
    'Value',
  ];
  updateMask.setPathsList(paths);
  req.setUpdateMask(updateMask);

  return sdkClient
    .unary(metricsV1.AcceptanceCriteriaMetricService.Update, req)
    .then((res) => {
      const metric = res;
      return metric;
    });
};

export const fetchTestDefinitionVersionsByCode = (
  code: string
): Promise<TestDefinition[]> => {
  const req = new SearchRequest();
  const pageSize = 200;
  req.setShowDeleted(true);
  req.setCodesList([code]);
  req.setView(TestDefinitionView.FULL);
  req.setPageSize(pageSize);

  return sdkClient.unary(TestDefinitionService.Search, req).then((res) => {
    const tdList = res.getTestDefinitionsList();
    tdList.sort((a, b) => {
      return (
        b.getVersionInfo().getVersionNumber() -
        a.getVersionInfo().getVersionNumber()
      );
    });
    return tdList;
  });
};

/*
export const fetchTestDefinitionById = (
  id: string
): Promise<TestDefinition> => {
  const req = new GetRequest();
  req.setView(TestDefinitionView.FULL);
  req.setId(id);
  return sdkClient.unary(TestDefinitionService.Get, req).then((td) => {
    return td;
  });
};
 */

export const getRouteLinkForTestDefinition = (
  testDefinition: TestDefinition,
  addVersionIfLatest = false
) => {
  let versionPath = '';
  if (testDefinition.hasVersionInfo()) {
    const versionInfo = testDefinition.getVersionInfo();
    if (!versionInfo.getIsLatestVersion() || addVersionIfLatest) {
      versionPath = `/v${versionInfo.getVersionNumber()}`;
    }
  }

  return `/app/test-definitions/${testDefinition.getCode()}${versionPath}`;
};

export const updateTestDefinitionMetadataMutation = (
  testDefinitionId: string
) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    async (metadata: Record<string, string>) => {
      const testDefinitionRequest = new UpdateRequest();
      const testDefinition = new TestDefinition();

      const cleanedMetadata = Object.entries(metadata).reduce(
        (accumulator, [key, value]) => {
          if (key === '' || value === '') {
            return accumulator;
          }
          accumulator[key] = value;
          return accumulator;
        },
        {}
      );

      testDefinition.setId(testDefinitionId);
      testDefinition.setPlatformMetadata(
        Struct.fromJavaScript(cleanedMetadata)
      );

      testDefinitionRequest.setTestDefinition(testDefinition);

      const response = await sdkClient.unary(
        TestDefinitionService.Update,
        testDefinitionRequest
      );

      return response.toObject();
    },
    {
      onError: (error) => {
        return error;
      },
      onSuccess: (data) => {
        const sessionLog = data;
        queryClient.invalidateQueries({
          queryKey: [TEST_DEFINITIONs_QUERY_KEY, testDefinitionId],
        });
      },
    }
  );
  return mutation;
};
