import React, { useState, Fragment, ElementType, useEffect } from 'react';
import _ from 'lodash';
import cx from 'classnames';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Theme, lighten } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import Link from '@mui/material/Link';
import { colors } from '@shield-ui/styles';
import { Grid } from '@mui/material';

const styles = (theme: Theme) =>
  createStyles({
    tabContainer: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
    },
    vertTabContainer: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#191D23',
      maxWidth: 250,
      minWidth: 180,

      minHeight: '100.1vh',

    },
    tab: {
      display: 'block',
      padding: theme.spacing(0.5, 0.25),
      fontSize: 18,
      letterSpacing: 0.55,
      color: lighten(colors.hues.gray, 0.7),
      transition: '0.15s ease',
      borderBottom: `2px solid transparent`, // spacing
      '&:hover': {
        color: '#fff',
        textDecoration: 'none',
      },
      '&:focus': {
        outline: 'none',
      },
    },
    vertTab: {
      display: 'block',
      lineHeight: '42px',
      padding: '0px 40px 0px 40px',
      margin: `4px 0px 0px 0px`,
      fontSize: 16,
      letterSpacing: 0.65,
      color: lighten(colors.hues.gray, 0.7),
      transition: '0.15s ease',
      '&:hover': {
        color: '#fff',
        textDecoration: 'none',
      },
      '&:focus': {
        outline: 'none',
      },
    },
    divider: {
      height: theme.spacing(2),
      margin: theme.spacing(1),
      width: 1,
      backgroundColor: colors.hues.grays[170],
    },
    tabActive: {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      color: '#fff',
    },
    vertTabActive: {
      fontWeight: 600,
      borderLeft: `2px solid rgb(61, 122, 244)`,
      backgroundColor: colors.hues.grays[160],
    },
    contentContainer: {
      marginTop: 10,
    },
    tabDivider: {
      margin: theme.spacing(0, 3, 1, 3),
      width: 1,
      backgroundColor: colors.hues.grays[160],
    },
    buttonTab: {
      display: 'flex',
    },
    buttonTabIcon: {
      marginRight: theme.spacing(0.5),
      color: colors.brand.primaryShade[70],
    },
  });

export interface SubNavTabType {
  label: string;
  path: string;
  Component: ElementType;
}

interface SubNavButtonTabType {
  label: string;
  path: string;
  Icon: React.ReactNode;
}

interface SubNavTabsProps extends WithStyles<typeof styles> {
  tabs: SubNavTabType[];
  /** prefix all tab "paths" with this prefix, do not use if not all inclusive*/
  pathPrefix?: string;
  autoPathPrefix?: boolean;
  buttonTabs?: SubNavButtonTabType[];
  /** object of props spread and passed down to rendered tab component */
  tabComponentProps?: object;
  onTabChange?: (tab: SubNavTabType) => void;
  vertical?: boolean;
  tabHeader?: JSX.Element;
}

function getRoutePath(tab, prefix) {
  if (prefix) {
    return prefix + tab.path;
  }
  return tab.path;
}

export function calcSubNavTabsPathPrefix(tabs) {
  const matches = tabs.filter((tab) => {
    return window.location.pathname.endsWith(tab.path);
  });

  if (matches.length) {
    return window.location.pathname.replace(matches[0].path, '');
  } else {
    return window.location.pathname;
  }
}

function SubNavTabs(props: SubNavTabsProps) {
  const {
    tabs = [],
    pathPrefix: propsPathPrefix,
    autoPathPrefix,
    buttonTabs = [],
    tabComponentProps = {},
    onTabChange,
    classes,
    vertical,
    tabHeader,
  } = props;

  const pathname = useLocation().pathname.replace(/ /g, '%20'); //spaces get url encoded with '%20'. Pathname needs to match
  const [calcPathPrefix, setCalcPathPrefix] = useState(undefined);

  useEffect(() => {
    if (!autoPathPrefix) {
      return;
    }
    setCalcPathPrefix(calcSubNavTabsPathPrefix(tabs));
  }, []);

  const pathPrefix = propsPathPrefix || calcPathPrefix;

  const tabMatch =
    _.find(tabs, (tab) => getRoutePath(tab, pathPrefix) === pathname) ||
    tabs[0];
  const TabComponent = tabMatch.Component;

  return (
    <>
      {vertical ? (
        <>
          <div>
            <Grid container  wrap="nowrap">
              <Grid item xs={2} className={classes.vertTabContainer}>
                {tabHeader && tabHeader}
                {tabs.map((tab, index) => {
                  const routePath = getRoutePath(tab, pathPrefix);
                  return (
                    <Fragment key={routePath}>
                      <Link
                        tabIndex={-1}
                        component={RouterLink}
                        to={routePath}
                        onClick={
                          onTabChange ? () => onTabChange(tab) : undefined
                        }
                        className={cx(
                          classes.vertTab,
                          tabMatch === tab && classes.vertTabActive
                        )}
                        underline="hover"
                      >
                        {tab.label}
                      </Link>
                    </Fragment>
                  );
                })}
              </Grid>
              <Grid item xs={10}>
                <TabComponent {...tabComponentProps} />
              </Grid>
            </Grid>
          </div>
        </>
      ) : (
        <>
          <div className={classes.tabContainer}>
            {tabs.map((tab, index) => {
              const routePath = getRoutePath(tab, pathPrefix);
              return (
                <Fragment key={routePath}>
                  <Link
                    tabIndex={-1}
                    component={RouterLink}
                    to={routePath}
                    onClick={onTabChange ? () => onTabChange(tab) : undefined}
                    className={cx(
                      classes.tab,
                      tabMatch === tab && classes.tabActive
                    )}
                    underline="hover"
                  >
                    {tab.label}
                  </Link>
                  {index !== tabs.length - 1 && (
                    <div className={classes.divider} />
                  )}
                </Fragment>
              );
            })}
            {buttonTabs.length > 0 && (
              <>
                <div className={classes.tabDivider} />
                {buttonTabs.map((buttonTab) => {
                  const to = getRoutePath(buttonTab, pathPrefix);
                  return (
                    <Link
                      key={to}
                      to={to}
                      tabIndex={-1}
                      component={RouterLink}
                      className={cx(classes.tab, classes.buttonTab)}
                      role="button"
                      underline="hover"
                    >
                      <div className={classes.buttonTabIcon}>
                        {buttonTab.Icon}
                      </div>{' '}
                      {buttonTab.label}
                    </Link>
                  );
                })}
              </>
            )}
          </div>
          <div className={classes.contentContainer}>
            <TabComponent {...tabComponentProps} />
          </div>
        </>
      )}
    </>
  );
}

export default withStyles(styles)(SubNavTabs);
