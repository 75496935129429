import _ from 'lodash';
import {
  BaseFilterValue,
  BaseQueryFilter,
  BaseFilterProps,
} from './baseQueryFilter';
import {
  MultiselectValueType,
  MultiselectOption,
  ListOperator,
  QueryFilterTypes,
} from './common';
import {
  getIncludeExcludeMatchPreview,
  getMultiselectEsQuery,
  UNSET_OPTION_VALUE,
  getUnsetOption,
} from './multiselectUtils';

export interface TypeToFindMultiselectQueryFilterValue extends BaseFilterValue {
  includeOperator?: ListOperator;
  includeOptionValues?: MultiselectValueType[];
  excludeOperator?: ListOperator;
  excludeOptionValues?: MultiselectValueType[];
}

export interface TypeToFindMultiselectControlData {
  isMulti: boolean;
  onSearchSuggestedOptions: () => void;
  onSearch: ({ input }) => void;
  searchInput: string;
  isSearching: boolean;
  optionsCache: Record<string, MultiselectOption>;
  suggestedOptionValues: MultiselectValueType[];
  searchOptionValues: MultiselectValueType[];
  // disable the include search options
  disableIncludes: boolean;
  // disable the exclude search options
  disableExcludes: boolean;
  // disable the AND/OR toggle
  disableListOperator: boolean;
}

export interface TypeToFindMultiselectQueryFilterProps extends BaseFilterProps {
  // AND/OR logic exposed
  isMulti?: boolean;
  // suggestions to show for an "idle" control
  searchSuggestedOptions?: () => Promise<MultiselectOption[]>;
  // loads options, e.g. from hydrating a save state
  getOptions: ({
    values,
  }: {
    values: MultiselectValueType[];
  }) => Promise<MultiselectOption[]>;
  // searches for options based on user input
  searchOptions: ({ input }) => Promise<MultiselectOption[]>;
  // Use to disable automatically creating an unset value
  unsetValueDisabled?: boolean;
  // Use to override the automatically generated unset value
  unsetValueLabel?: string;
  // disable the include search options
  disableIncludes?: boolean;
  // disable the exclude search options
  disableExcludes?: boolean;
  // disable the AND/OR toggle
  disableListOperator?: boolean;
}

export class TypeToFindMultiselectQueryFilter extends BaseQueryFilter<
  TypeToFindMultiselectQueryFilterValue,
  TypeToFindMultiselectQueryFilterProps,
  TypeToFindMultiselectControlData
> {
  type = QueryFilterTypes.typeToFindMultiselect;
  _ensureRequestingIds = {};
  _searchTimeout = undefined;
  _reqId = 0;

  initControlData(
    props: TypeToFindMultiselectQueryFilterProps
  ): TypeToFindMultiselectControlData {
    return {
      isMulti: props.isMulti || false,
      disableIncludes: props.disableIncludes || false,
      disableExcludes: props.disableExcludes || false,
      disableListOperator: props.disableListOperator || false,
      onSearch: this.onSearch.bind(this),
      onSearchSuggestedOptions: this.onSearchSuggestedOptions.bind(this),
      searchInput: '',
      isSearching: false,
      suggestedOptionValues: [],
      searchOptionValues: [],
      optionsCache: {},
    };
  }

  ensureControlData(
    value: TypeToFindMultiselectQueryFilterValue = {}
  ): Promise<TypeToFindMultiselectControlData> {
    const { optionsCache } = this.getControlData();
    const { includeOptionValues = [], excludeOptionValues = [] } = value;

    // check our cache to make sure we have all values in it
    // or we are actively requesting it
    let missingOptionValues = includeOptionValues
      .concat(excludeOptionValues)
      .filter((v) => {
        return !optionsCache[v] && !this._ensureRequestingIds[v];
      });

    // if no missing values, exit
    if (!missingOptionValues.length) {
      return Promise.resolve(this.getControlData());
    }

    // If we are reloading controls based on saved values then suggestions has not been loaded yet
    // stuff unset in the cache
    // remove it from the missing list so don't request it below
    if (missingOptionValues.includes(UNSET_OPTION_VALUE)) {
      const opt = getUnsetOption(this);
      if (opt) {
        this.setControlData({
          optionsCache: this._augmentOptionsCache([opt]),
        });
        missingOptionValues = missingOptionValues.filter(
          (v) => v !== UNSET_OPTION_VALUE
        );
      }
    }

    // set that we are requesting any missing ids so we don't double request
    missingOptionValues.forEach((v) => {
      this._ensureRequestingIds[v] = true;
    });

    // fetch options
    return this.props
      .getOptions({ values: missingOptionValues })
      .then((options) => {
        // set our fetched options, 99.9% of the time we will be done
        // below is some edge case error handling
        this.setControlData({
          optionsCache: this._augmentOptionsCache(options),
        });

        const { optionsCache } = this.getControlData();
        const badOptions = [];
        missingOptionValues.forEach((v) => {
          delete this._ensureRequestingIds[v];
          if (!optionsCache[v]) {
            badOptions.push({
              value: v,
              label: `Missing Option: ${v}`,
            });
          }
        });

        if (badOptions.length) {
          this.setControlData({
            optionsCache: this._augmentOptionsCache(badOptions),
          });
        }

        return this.getControlData();
      });
  }

  _augmentOptionsCache(
    options: MultiselectOption[]
  ): Record<string, MultiselectOption> {
    const { optionsCache } = this.getControlData();

    return {
      ...optionsCache,
      ..._.keyBy(options, 'value'),
    };
  }

  onSearchSuggestedOptions(): void {
    if (!this.props.searchSuggestedOptions) {
      return;
    }

    // fetch suggestions for the first time
    this.props.searchSuggestedOptions().then((opts) => {
      const unsetOption = getUnsetOption(this);
      const options = unsetOption ? [unsetOption].concat(opts) : opts;

      this.setControlData({
        suggestedOptionValues: options.map((o) => o.value),
        optionsCache: this._augmentOptionsCache(options),
      });
    });
  }

  onSearch({ input }: { input: string }): void {
    const reqId = ++this._reqId;

    if (input === '') {
      this.setControlData({
        searchInput: '',
        isSearching: false,
        searchOptionValues: [],
      });
    } else {
      this.setControlData({
        searchInput: input,
        isSearching: true,
      });

      clearTimeout(this._searchTimeout);
      this._searchTimeout = setTimeout(() => {
        // perform a search
        this.props.searchOptions({ input }).then((options) => {
          // noop ignore lots of fast requests, take just the latest
          if (reqId !== this._reqId) {
            return;
          }

          this.setControlData({
            searchOptionValues: options.map((o) => o.value),
            isSearching: false,
            optionsCache: this._augmentOptionsCache(options),
          });
        });
      }, 500);
    }
  }

  hasValue(value: TypeToFindMultiselectQueryFilterValue = {}): boolean {
    return (
      !_.isEmpty(value.includeOptionValues) ||
      !_.isEmpty(value.excludeOptionValues)
    );
  }

  getElasticQuery(value: TypeToFindMultiselectQueryFilterValue): object | void {
    const { esField } = this.props;
    if (!esField || !this.hasValue(value)) {
      return;
    }

    return getMultiselectEsQuery({
      esField,
      value,
    });
  }

  getValuePreview(value: TypeToFindMultiselectQueryFilterValue): string {
    if (!value) {
      return '';
    }
    const { optionsCache } = this.getControlData();
    const { includeOptionValues = [], excludeOptionValues = [] } = value;
    return getIncludeExcludeMatchPreview(
      includeOptionValues,
      excludeOptionValues,
      optionsCache
    );
  }
}
