import { gql } from '@apollo/client';
import _ from 'lodash';
import { noteFragment } from './fragments';
import client from '../apollo-client';
import tracker from '../lib/tracker';

export const NOTE_TYPES = {
  OBSERVER: 'OBSERVER',
  POST_REVIEW: 'POST_REVIEW',
};
export const NOTE_TYPE_DISPLAYS = {
  OBSERVER: 'Observer',
  POST_REVIEW: 'Post Review',
};

export const getNoteTypeOptions = _.memoize(() => {
  return _.map(NOTE_TYPE_DISPLAYS, (value, key) => {
    return { value: key, label: value };
  });
});

const noteListQuery = gql`
    query notes($robotLogId: String) {
        notes(robotLogId: $robotLogId) {
            edges {
                node {
                    ${noteFragment}
                }
            }
        },
        currentUser {
            name
            email
            id
            picture
            providerName
        }
    }
    `;

const createFlightNoteQuery = gql`
    mutation createNote($robotLogId: String!, $displayText: String!, $sourceTypeId: String!, $noteType: NoteTypeChoice) {
        createNote(robotLogId: $robotLogId, displayText: $displayText, sourceTypeId: $sourceTypeId, noteType: $noteType) {
            ${noteFragment}
        }
    }
`;
const updateNotesQuery = gql`
    mutation updateNote ($id: UUID!, $displayText: String, $noteType: NoteTypeChoice) {
        updateNote (id: $id, displayText: $displayText, noteType: $noteType) {
            ${noteFragment}
        }
    }
`;
const deleteFlightNoteQuery = gql`
  mutation deleteNote($id: String!) {
    deleteNote(id: $id) {
      id
    }
  }
`;

export function loadNotes(robotLogId, callback) {
  client
    .query({
      query: noteListQuery,
      variables: { robotLogId: robotLogId },
      fetchPolicy: 'network-only',
    })
    .then(
      (val) => {
        if (typeof callback === 'function') {
          callback(null, val);
        }
      },
      (err) => {
        if (typeof callback === 'function') {
          callback(err);
        }
      }
    );
}

export function deleteFlightNote(noteId, callback) {
  if (!noteId) {
    return callback();
  }

  return client
    .mutate({
      mutation: deleteFlightNoteQuery,
      variables: { id: noteId },
    })
    .then(
      (res) => {
        if (typeof callback === 'function') {
          tracker.trackEvent('Flight', 'NoteDelete', 'id', noteId);
          callback(null, res);
        }
      },
      (err) => {
        if (typeof callback === 'function') {
          callback(err);
        }
      }
    );
}

export function createFlightNote(variables, callback = _.noop) {
  client
    .mutate({
      mutation: createFlightNoteQuery,
      variables: variables,
    })
    .then(
      (res) => {
        tracker.trackEvent('Flight', 'NoteCreate');
        return callback(null, res);
      },
      (err) => {
        return callback(err);
      }
    );
}

export function updateFlightNote(variables, callback = _.noop) {
  client
    .mutate({
      mutation: updateNotesQuery,
      variables,
    })
    .then(
      (res) => {
        tracker.trackEvent('Flight', 'NoteUpdate');
        return callback(null, res);
      },
      (err) => {
        return callback(err);
      }
    );
}

// export const useCreateFlightNote = () => {
//   const [createNote, { data, error, loading }] = useMutation(createFlightNoteQuery);
//   return { createNote, data, error, loading };
// }
