import _ from 'lodash';
import { gql } from '@apollo/client';
import memoizeOne from 'memoize-one';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import {
  HangarErrorLevel,
  HangarRobotLogType,
  HangarQueriesLogEventsArgs,
  HangarLogEventRecordType,
} from '@shield-ui/hangar-service';
import { prettifyString } from '@shield-ui/utils';
import client from '../apollo-client';
import {
  LogEventService,
  SearchFlatRequest,
  ErrorLevel,
  FlatLogEvent,
  LogEventFilters,
} from '@hmd/sdk/api/analysis/log_event/v1';
import sdkClient from '../lib/hmdsdkClient';

/* LogEvents from HMDSDK */
export const getErrorLevelName = (errorLevel: number) => {
  return _.upperCase(
    Object.keys(ErrorLevel).find((key) => ErrorLevel[key] === errorLevel) ||
      'Unknown'
  );
};

/* LogEvents from Hangar */
export const errorLevelOrder = [
  HangarErrorLevel.Fatal,
  HangarErrorLevel.Error,
  HangarErrorLevel.Warn,
  HangarErrorLevel.Info,
  HangarErrorLevel.Debug,
  HangarErrorLevel.ErrorLevelUnspecified,
];
/* LogEvents from GRPC */
export const logEventErrorLevelOrder = [
  ErrorLevel.FATAL,
  ErrorLevel.ERROR,
  ErrorLevel.WARN,
  ErrorLevel.INFO,
  ErrorLevel.DEBUG,
  ErrorLevel.ERROR_LEVEL_UNSPECIFIED,
];

export const getErrorLevelOptions = memoizeOne(() => {
  return Object.keys(HangarErrorLevel)
    .map((errorLevelKey) => {
      return {
        label: errorLevelKey,
        value: HangarErrorLevel[errorLevelKey],
      };
    })
    .filter((opt) => opt.label !== 'NotSet');
});

export const getErrorLevelOptionsLogEvents = memoizeOne(() => {
  return Object.keys(ErrorLevel)
    .map((errorLevelKey) => {
      return {
        label: prettifyString(errorLevelKey),
        value: ErrorLevel[errorLevelKey],
      };
    })
    .filter((opt) => opt.label !== 'Error Level Unspecified');
});

export interface HangarLogEventRecordTypeMerged
  extends HangarLogEventRecordType {
  logEvents?: HangarLogEventRecordTypeMerged[];
}

export interface FlatLogEventObjectMerged extends FlatLogEvent.AsObject {
  logEvents?: FlatLogEvent.AsObject[];
}

export function diagnosticBuildMergedLogEvents(
  logEvents: HangarLogEventRecordType[],
  children: HangarLogEventRecordType[]
): HangarLogEventRecordTypeMerged[] {
  const idToChildren = children.reduce((acc, child) => {
    const { parentId, id } = child;
    if (!acc[parentId]) {
      acc[parentId] = [];
    }
    acc[parentId].push(id);

    return acc;
  }, {});
  const childMap = _.keyBy(children, 'id');

  const mapChildren = (
    list: HangarLogEventRecordType[]
  ): HangarLogEventRecordTypeMerged[] => {
    return list.map((logEvent) => {
      const childIds = idToChildren[logEvent.id] || [];
      const children = childIds.map((id) => childMap[id]);

      return {
        ...logEvent,
        logEvents: mapChildren(children),
      };
    });
  };

  return mapChildren(logEvents);
}

export function buildMergedLogEvents(
  logEvents: FlatLogEvent.AsObject[],
  children: FlatLogEvent.AsObject[]
): FlatLogEventObjectMerged[] {
  const idToChildren = children.reduce((acc, child) => {
    const { parentId, id } = child;
    if (!acc[parentId]) {
      acc[parentId] = [];
    }
    acc[parentId].push(id);

    return acc;
  }, {});
  const childMap = _.keyBy(children, 'id');

  const mapChildren = (
    list: FlatLogEvent.AsObject[]
  ): FlatLogEventObjectMerged[] => {
    return list.map((logEvent) => {
      const childIds = idToChildren[logEvent.id] || [];
      const children = childIds.map((id) => childMap[id]);

      return {
        ...logEvent,
        logEvents: mapChildren(children),
      };
    });
  };

  return mapChildren(logEvents);
}

export function getAllLogEventsForRobotLog(names: string[]): Promise<{
  recordCount: number;
  logEvents: FlatLogEvent.AsObject[];
  children: FlatLogEvent.AsObject[];
}> {
  const req = new SearchFlatRequest();
  const logEventFilter = new LogEventFilters();
  logEventFilter.setSourceExternalIdsList(names);
  req.setFilters(logEventFilter);

  const getAllPages = (pageToken = '', allLogEvents = []) => {
    return new Promise<{
      recordCount: number;
      logEvents: FlatLogEvent.AsObject[];
      children: FlatLogEvent.AsObject[];
    }>((resolve, reject) => {
      req.setPageToken(pageToken);
      sdkClient
        .unary(LogEventService.SearchFlat, req)
        .then((res) => {
          const logEventsList = res.getFlatLogEventsList();
          logEventsList.forEach((l) => {
            allLogEvents.push(l.toObject());
          });
          const nextPageToken = res.getNextPageToken();
          if (nextPageToken) {
            getAllPages(nextPageToken, allLogEvents)
              .then(resolve)
              .catch(reject);
          } else {
            let recordCount = 0;
            const logEvents = [];
            const children = [];
            allLogEvents.forEach((l) => {
              if (l.parentId === 0) {
                recordCount++;
                logEvents.push(l);
              } else {
                children.push(l);
              }
            });
            resolve({
              recordCount,
              logEvents,
              children,
            });
          }
        })
        .catch((e) => {
          console.error('TWO ERROR', e);
        });
    });
  };
  return getAllPages();
}

function getLogEventFragment() {
  return `
    id
    parentId
    componentExternalId
    componentName
    componentOwner
    logDefinitionExternalId
    logDefinitionName
    logDefinitionDescription
    sourceExternalId
    level
    message
    file
    operation
    line
    timestamp { seconds, nanos }
  `;
}

export function searchLogEvents(
  args: Pick<
    HangarQueriesLogEventsArgs,
    'pageNumber' | 'resultsPerPage' | 'sourceExternalIds' | 'componentOwners'
  > = {}
): Promise<{
  recordCount: number;
  logEvents: HangarLogEventRecordType[];
  children: HangarLogEventRecordType[];
}> {
  const query = gql`
    query($pageNumber: Int, $resultsPerPage: Int, $componentOwners: [String], $sourceExternalIds:[String]) {
      logEvents(pageNumber:$pageNumber,resultsPerPage:$resultsPerPage,componentOwners: $componentOwners, sourceExternalIds: $sourceExternalIds) {
        recordCount
        records {
          ${getLogEventFragment()}
        }
        children {
          ${getLogEventFragment()}
        }
      }
    }
  `;
  return client
    .query({
      query,
      variables: args,
    })
    .then((result) => {
      const recordCount = _.get(result, 'data.logEvents.recordCount', 0);
      const logEvents = _.get(result, 'data.logEvents.records', []);
      const children = _.get(result, 'data.logEvents.children', []);
      return {
        recordCount,
        logEvents,
        children,
      };
    });
}

/**
 * Given a robotLog and the timestamp of a logEvent, return the milliseconds difference
 * between the start of the robotLog and the timestamp of this individual logEvent
 * @param robotLogStartTime
 * @param logEventTimestamp
 */
export function getTimestampDeltaMs(
  robotLogStartTime: HangarRobotLogType['startTime'],
  logEventTimestamp: HangarLogEventRecordType['timestamp']
) {
  if (!robotLogStartTime || !logEventTimestamp) {
    return;
  }
  const { seconds, nanos = 0 } = logEventTimestamp;
  const ms = parseInt(nanos.toString().slice(0, 3), 10);

  const log = new Date(seconds * 1000 + ms);
  const rl = new Date(robotLogStartTime);

  return differenceInMilliseconds(log, rl);
}

export function getPrettyLogDefinitionName(nameOrMap, externalId = undefined) {
  let name;
  if (_.isObject(nameOrMap)) {
    name = _.get(nameOrMap, [externalId, 'name']);
  } else if (_.isString(nameOrMap)) {
    name = nameOrMap;
  }

  if (name) {
    return prettifyString(name);
  }
  return `Log Definition ${externalId || 'Unknown'}`;
}

/**
 * Given an array of root logEvents, pluck out all of the leaves in flattened array
 * We clear this cache when the collection context changes (when refresh is called)
 */
export const getLeafLogEventsFromMergedRecord = (
  rootLogEvent: FlatLogEventObjectMerged
): FlatLogEventObjectMerged[] => {
  const leafLogEvents = [];
  const loop = (logEvents) => {
    logEvents.forEach((logEvent: FlatLogEventObjectMerged) => {
      if (logEvent.logEvents.length === 0) {
        leafLogEvents.push(logEvent);
      } else {
        loop(logEvent.logEvents);
      }
    });
  };

  loop([rootLogEvent]);
  return leafLogEvents;
};

export const diagnosticGetLeafLogEventsFromMergedRecord = (
  rootLogEvent: HangarLogEventRecordTypeMerged
): HangarLogEventRecordTypeMerged[] => {
  const leafLogEvents = [];
  const loop = (logEvents) => {
    logEvents.forEach((logEvent: HangarLogEventRecordTypeMerged) => {
      if (logEvent.logEvents.length === 0) {
        leafLogEvents.push(logEvent);
      } else {
        loop(logEvent.logEvents);
      }
    });
  };

  loop([rootLogEvent]);
  return leafLogEvents;
};
