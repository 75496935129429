export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: any;
  /**
   * The `Time` scalar type represents a Time value as
   * specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Time: any;
  /**
   * Leverages the internal Python implmeentation of UUID (uuid.UUID) to provide native UUID objects
   * in fields, resolvers and input.
   */
  UUID: any;
};

/** Name of Framework (Judy, Judith, etc) */
export enum HangarAnalysis_Framework {
  Judy = 'JUDY',
  Judith = 'JUDITH',
  Piper = 'PIPER',
  Manual = 'MANUAL'
}

/** Status of the Doppler running of the analysis */
export enum HangarAnalysis_Run_Status {
  Pending = 'PENDING',
  Running = 'RUNNING',
  Done = 'DONE',
  PendingRetry = 'PENDING_RETRY'
}

/** What file type the artifact is */
export enum HangarArtifact_Artifact_Type {
  File = 'FILE',
  Log = 'LOG',
  Video = 'VIDEO',
  Image = 'IMAGE',
  Junit = 'JUNIT',
  SlammapVideo = 'SLAMMAP_VIDEO',
  SlammapImage = 'SLAMMAP_IMAGE',
  SourceLog = 'SOURCE_LOG',
  Px4Log = 'PX4_LOG',
  Px4Ulog = 'PX4_ULOG',
  Px4LogH5 = 'PX4_LOG_H5',
  ObjectAnnotation = 'OBJECT_ANNOTATION',
  PhoneDat = 'PHONE_DAT',
  PhoneVideo = 'PHONE_VIDEO',
  PhoneSlammapImage = 'PHONE_SLAMMAP_IMAGE',
  PhoneSlammapKmz = 'PHONE_SLAMMAP_KMZ',
  PhoneLog = 'PHONE_LOG',
  PhoneScreenshot = 'PHONE_SCREENSHOT',
  PhoneScreenRecording = 'PHONE_SCREEN_RECORDING',
  PhoneCocopilotVideo = 'PHONE_COCOPILOT_VIDEO',
  PhoneCocopilotAudio = 'PHONE_COCOPILOT_AUDIO',
  PhoneMission = 'PHONE_MISSION',
  PlotlyPlotJson = 'PLOTLY_PLOT_JSON',
  Rosbag = 'ROSBAG',
  Coredump = 'COREDUMP',
  Roslog = 'ROSLOG',
  RosbagTopic = 'ROSBAG_TOPIC',
  RobotBootArchive = 'ROBOT_BOOT_ARCHIVE',
  RobotBootChart = 'ROBOT_BOOT_CHART',
  TraceEventsJson = 'TRACE_EVENTS_JSON',
  JournaldArchive = 'JOURNALD_ARCHIVE',
  PerfRecord = 'PERF_RECORD',
  KernelCrashLog = 'KERNEL_CRASH_LOG',
  AutopilotLogTlv = 'AUTOPILOT_LOG_TLV',
  GcsTelemetryLogsCsv = 'GCS_TELEMETRY_LOGS_CSV',
  GcsTelemetryLogsSplCsv = 'GCS_TELEMETRY_LOGS_SPL_CSV',
  GcsTelemetryLogsFillCsv = 'GCS_TELEMETRY_LOGS_FILL_CSV',
  TrilliumPayloadVideo = 'TRILLIUM_PAYLOAD_VIDEO',
  PlanckAceLogArchive = 'PLANCK_ACE_LOG_ARCHIVE',
  Matlab = 'MATLAB'
}

/** What storage class is used for the artifact */
export enum HangarArtifact_Storage_Class {
  Online = 'ONLINE',
  Glacial = 'GLACIAL',
  Deleted = 'DELETED'
}

/** StorageType - nas, gs, s3 */
export enum HangarArtifact_Storage_Type {
  Nas = 'NAS',
  Gs = 'GS',
  S3 = 'S3'
}

export type HangarAnalysisConnectionTypeConnection = {
  __typename?: 'AnalysisConnectionTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: HangarPageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HangarAnalysisConnectionTypeEdge>>;
  total?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `AnalysisConnectionType` and its cursor. */
export type HangarAnalysisConnectionTypeEdge = {
  __typename?: 'AnalysisConnectionTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<HangarAnalysisType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type HangarAnalysisMeta = {
  __typename?: 'AnalysisMeta';
  names?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** An enumeration. */
export enum HangarAnalysisSortEnum {
  NameAsc = 'name_asc',
  NameDesc = 'name_desc',
  FrameworkAsc = 'framework_asc',
  FrameworkDesc = 'framework_desc',
  DataAsc = 'data_asc',
  DataDesc = 'data_desc',
  RanSuccessfullyAsc = 'ran_successfully_asc',
  RanSuccessfullyDesc = 'ran_successfully_desc',
  TestPassedAsc = 'test_passed_asc',
  TestPassedDesc = 'test_passed_desc',
  RobotLogIdAsc = 'robot_log_id_asc',
  RobotLogIdDesc = 'robot_log_id_desc',
  RunRequestedAtAsc = 'run_requested_at_asc',
  RunRequestedAtDesc = 'run_requested_at_desc',
  RunStartedAtAsc = 'run_started_at_asc',
  RunStartedAtDesc = 'run_started_at_desc',
  RunFinishedAtAsc = 'run_finished_at_asc',
  RunFinishedAtDesc = 'run_finished_at_desc',
  RunStatusAsc = 'run_status_asc',
  RunStatusDesc = 'run_status_desc',
  PipelineExecutionIdAsc = 'pipeline_execution_id_asc',
  PipelineExecutionIdDesc = 'pipeline_execution_id_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  CreatedAtAsc = 'created_at_asc',
  CreatedAtDesc = 'created_at_desc',
  UpdatedAtAsc = 'updated_at_asc',
  UpdatedAtDesc = 'updated_at_desc',
  CreatedByIdAsc = 'created_by_id_asc',
  CreatedByIdDesc = 'created_by_id_desc',
  UpdatedByIdAsc = 'updated_by_id_asc',
  UpdatedByIdDesc = 'updated_by_id_desc'
}

export type HangarAnalysisType = {
  __typename?: 'AnalysisType';
  /** Name of Analysis */
  name: Scalars['String'];
  framework?: Maybe<HangarAnalysis_Framework>;
  /** Semi structured data containing misc robot log information */
  data?: Maybe<Scalars['JSONString']>;
  /** Did the test run to completion? */
  ranSuccessfully?: Maybe<Scalars['Boolean']>;
  /** Did the test pass? */
  testPassed?: Maybe<Scalars['Boolean']>;
  /** Id of Robot log the analysis tested */
  robotLogId: Scalars['String'];
  /** Time the analyzer request for analysis was made at */
  runRequestedAt?: Maybe<Scalars['String']>;
  /** Time the analyzer request for analysis was started */
  runStartedAt?: Maybe<Scalars['String']>;
  /** Time the analyzer request for analysis was completed */
  runFinishedAt?: Maybe<Scalars['String']>;
  runStatus?: Maybe<HangarAnalysis_Run_Status>;
  pipelineExecutionId?: Maybe<Scalars['String']>;
  /** UUID for the object */
  id: Scalars['String'];
  /** Time the object was created */
  createdAt: Scalars['String'];
  /** Time the object was last updated */
  updatedAt: Scalars['String'];
  /** Id of user that created the object */
  createdById?: Maybe<Scalars['String']>;
  /** Id of user that last updated the object */
  updatedById?: Maybe<Scalars['String']>;
  robotLog?: Maybe<HangarRobotLogType>;
  pipelineExecution?: Maybe<HangarPipelineExecutionType>;
  createdBy?: Maybe<HangarUserType>;
  updatedBy?: Maybe<HangarUserType>;
};

export type HangarArtifactConnectionTypeConnection = {
  __typename?: 'ArtifactConnectionTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: HangarPageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HangarArtifactConnectionTypeEdge>>;
  total?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `ArtifactConnectionType` and its cursor. */
export type HangarArtifactConnectionTypeEdge = {
  __typename?: 'ArtifactConnectionTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<HangarArtifactGqlType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type HangarArtifactGqlType = {
  __typename?: 'ArtifactGQLType';
  /** Name of log */
  fileName: Scalars['String'];
  /** CRC check sum of the file */
  checksum?: Maybe<Scalars['String']>;
  /** When the file was last modified */
  lastModifiedTime?: Maybe<Scalars['String']>;
  /** The size of the file in bytes */
  size?: Maybe<Scalars['Float']>;
  artifactType?: Maybe<HangarArtifact_Artifact_Type>;
  /** Full path to file on remote storage */
  path?: Maybe<Scalars['String']>;
  storageType?: Maybe<HangarArtifact_Storage_Type>;
  /** Semi structured data containing artifact metrics */
  artifactData?: Maybe<Scalars['JSONString']>;
  pipelineExecutionId?: Maybe<Scalars['String']>;
  storageClass?: Maybe<HangarArtifact_Storage_Class>;
  /** UUID for the object */
  id: Scalars['String'];
  /** Time the object was created */
  createdAt: Scalars['String'];
  /** Time the object was last updated */
  updatedAt: Scalars['String'];
  /** Id of user that created the object */
  createdById?: Maybe<Scalars['String']>;
  /** Id of user that last updated the object */
  updatedById?: Maybe<Scalars['String']>;
  robotLogs?: Maybe<Array<Maybe<HangarRobotLogType>>>;
  phones?: Maybe<Array<Maybe<HangarPhoneType>>>;
  pipelineExecution?: Maybe<HangarPipelineExecutionType>;
  createdBy?: Maybe<HangarUserType>;
  updatedBy?: Maybe<HangarUserType>;
};

/** An enumeration. */
export enum HangarArtifactSortEnum {
  FileNameAsc = 'file_name_asc',
  FileNameDesc = 'file_name_desc',
  ChecksumAsc = 'checksum_asc',
  ChecksumDesc = 'checksum_desc',
  LastModifiedTimeAsc = 'last_modified_time_asc',
  LastModifiedTimeDesc = 'last_modified_time_desc',
  SizeAsc = 'size_asc',
  SizeDesc = 'size_desc',
  ArtifactTypeAsc = 'artifact_type_asc',
  ArtifactTypeDesc = 'artifact_type_desc',
  PathAsc = 'path_asc',
  PathDesc = 'path_desc',
  StorageTypeAsc = 'storage_type_asc',
  StorageTypeDesc = 'storage_type_desc',
  ArtifactDataAsc = 'artifact_data_asc',
  ArtifactDataDesc = 'artifact_data_desc',
  PipelineExecutionIdAsc = 'pipeline_execution_id_asc',
  PipelineExecutionIdDesc = 'pipeline_execution_id_desc',
  StorageClassAsc = 'storage_class_asc',
  StorageClassDesc = 'storage_class_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  CreatedAtAsc = 'created_at_asc',
  CreatedAtDesc = 'created_at_desc',
  UpdatedAtAsc = 'updated_at_asc',
  UpdatedAtDesc = 'updated_at_desc',
  CreatedByIdAsc = 'created_by_id_asc',
  CreatedByIdDesc = 'created_by_id_desc',
  UpdatedByIdAsc = 'updated_by_id_asc',
  UpdatedByIdDesc = 'updated_by_id_desc'
}

/** An enumeration. */
export enum HangarArtifactType {
  File = 'FILE',
  Log = 'LOG',
  Video = 'VIDEO',
  Image = 'IMAGE',
  Junit = 'JUNIT',
  SlammapVideo = 'SLAMMAP_VIDEO',
  SlammapImage = 'SLAMMAP_IMAGE',
  SourceLog = 'SOURCE_LOG',
  Px4Log = 'PX4_LOG',
  Px4Ulog = 'PX4_ULOG',
  Px4LogH5 = 'PX4_LOG_H5',
  ObjectAnnotation = 'OBJECT_ANNOTATION',
  PhoneDat = 'PHONE_DAT',
  PhoneVideo = 'PHONE_VIDEO',
  PhoneSlammapImage = 'PHONE_SLAMMAP_IMAGE',
  PhoneSlammapKmz = 'PHONE_SLAMMAP_KMZ',
  PhoneLog = 'PHONE_LOG',
  PhoneScreenshot = 'PHONE_SCREENSHOT',
  PhoneScreenRecording = 'PHONE_SCREEN_RECORDING',
  PhoneCocopilotVideo = 'PHONE_COCOPILOT_VIDEO',
  PhoneCocopilotAudio = 'PHONE_COCOPILOT_AUDIO',
  PhoneMission = 'PHONE_MISSION',
  PlotlyPlotJson = 'PLOTLY_PLOT_JSON',
  Rosbag = 'ROSBAG',
  Coredump = 'COREDUMP',
  Roslog = 'ROSLOG',
  RosbagTopic = 'ROSBAG_TOPIC',
  RobotBootArchive = 'ROBOT_BOOT_ARCHIVE',
  RobotBootChart = 'ROBOT_BOOT_CHART',
  TraceEventsJson = 'TRACE_EVENTS_JSON',
  JournaldArchive = 'JOURNALD_ARCHIVE',
  PerfRecord = 'PERF_RECORD',
  KernelCrashLog = 'KERNEL_CRASH_LOG',
  AutopilotLogTlv = 'AUTOPILOT_LOG_TLV',
  GcsTelemetryLogsCsv = 'GCS_TELEMETRY_LOGS_CSV',
  GcsTelemetryLogsSplCsv = 'GCS_TELEMETRY_LOGS_SPL_CSV',
  GcsTelemetryLogsFillCsv = 'GCS_TELEMETRY_LOGS_FILL_CSV',
  TrilliumPayloadVideo = 'TRILLIUM_PAYLOAD_VIDEO',
  PlanckAceLogArchive = 'PLANCK_ACE_LOG_ARCHIVE',
  Matlab = 'MATLAB'
}

export type HangarBackpackConnectionTypeConnection = {
  __typename?: 'BackpackConnectionTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: HangarPageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HangarBackpackConnectionTypeEdge>>;
};

/** A Relay edge containing a `BackpackConnectionType` and its cursor. */
export type HangarBackpackConnectionTypeEdge = {
  __typename?: 'BackpackConnectionTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<HangarBackpackType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type HangarBackpackSoftwareConnectionTypeConnection = {
  __typename?: 'BackpackSoftwareConnectionTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: HangarPageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HangarBackpackSoftwareConnectionTypeEdge>>;
};

/** A Relay edge containing a `BackpackSoftwareConnectionType` and its cursor. */
export type HangarBackpackSoftwareConnectionTypeEdge = {
  __typename?: 'BackpackSoftwareConnectionTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<HangarBackpackSoftwareType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum HangarBackpackSoftwareSortEnum {
  VersionAsc = 'version_asc',
  VersionDesc = 'version_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  CreatedAtAsc = 'created_at_asc',
  CreatedAtDesc = 'created_at_desc',
  UpdatedAtAsc = 'updated_at_asc',
  UpdatedAtDesc = 'updated_at_desc'
}

export type HangarBackpackSoftwareType = {
  __typename?: 'BackpackSoftwareType';
  /** SAI software version of the backpack */
  version: Scalars['String'];
  /** UUID for the object */
  id: Scalars['String'];
  /** Time the object was created */
  createdAt: Scalars['String'];
  /** Time the object was last updated */
  updatedAt: Scalars['String'];
};

/** An enumeration. */
export enum HangarBackpackSortEnum {
  SerialNumberAsc = 'serial_number_asc',
  SerialNumberDesc = 'serial_number_desc',
  HostnameAsc = 'hostname_asc',
  HostnameDesc = 'hostname_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  CreatedAtAsc = 'created_at_asc',
  CreatedAtDesc = 'created_at_desc',
  UpdatedAtAsc = 'updated_at_asc',
  UpdatedAtDesc = 'updated_at_desc'
}

export type HangarBackpackType = {
  __typename?: 'BackpackType';
  /** Unique serial number of the backpack */
  serialNumber: Scalars['String'];
  /** Hostname of the backpack */
  hostname: Scalars['String'];
  /** UUID for the object */
  id: Scalars['String'];
  /** Time the object was created */
  createdAt: Scalars['String'];
  /** Time the object was last updated */
  updatedAt: Scalars['String'];
};

export type HangarBiomeConnectionTypeConnection = {
  __typename?: 'BiomeConnectionTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: HangarPageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HangarBiomeConnectionTypeEdge>>;
};

/** A Relay edge containing a `BiomeConnectionType` and its cursor. */
export type HangarBiomeConnectionTypeEdge = {
  __typename?: 'BiomeConnectionTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<HangarBiomeType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum HangarBiomeSortEnum {
  NameAsc = 'name_asc',
  NameDesc = 'name_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  CreatedAtAsc = 'created_at_asc',
  CreatedAtDesc = 'created_at_desc',
  UpdatedAtAsc = 'updated_at_asc',
  UpdatedAtDesc = 'updated_at_desc'
}

export type HangarBiomeType = {
  __typename?: 'BiomeType';
  /** Name of the biome associated with an environment - desert, manmade, forest */
  name: Scalars['String'];
  /** UUID for the object */
  id: Scalars['String'];
  /** Time the object was created */
  createdAt: Scalars['String'];
  /** Time the object was last updated */
  updatedAt: Scalars['String'];
  environments?: Maybe<Array<Maybe<HangarEnvironmentType>>>;
};

/** High level group or type a collection falls under */
export enum HangarCollection_Collection_Type {
  SystemDefault = 'SYSTEM_DEFAULT',
  UserCreated = 'USER_CREATED',
  Fts = 'FTS',
  Vil = 'VIL',
  BringUpVerification = 'BRING_UP_VERIFICATION'
}

/** The status of the bag review */
export enum HangarCollection_Review_Review_Status {
  NotStarted = 'NOT_STARTED',
  InProgress = 'IN_PROGRESS',
  Complete = 'COMPLETE',
  Approved = 'APPROVED'
}

/** Special case for system presets with our own custom resolution strategy */
export enum HangarCollection_System_Default_Resolution_Scheme {
  MyReviewBacklog = 'MY_REVIEW_BACKLOG',
  MyReviewedFlights = 'MY_REVIEWED_FLIGHTS',
  MyPilotedFlights = 'MY_PILOTED_FLIGHTS',
  MyObservedFlights = 'MY_OBSERVED_FLIGHTS',
  MyRecentlyViewedFlights = 'MY_RECENTLY_VIEWED_FLIGHTS',
  MyTeamReviewBacklog = 'MY_TEAM_REVIEW_BACKLOG'
}

export type HangarCauseConnectionTypeConnection = {
  __typename?: 'CauseConnectionTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: HangarPageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HangarCauseConnectionTypeEdge>>;
};

/** A Relay edge containing a `CauseConnectionType` and its cursor. */
export type HangarCauseConnectionTypeEdge = {
  __typename?: 'CauseConnectionTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<HangarCauseType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum HangarCauseSortEnum {
  NameAsc = 'name_asc',
  NameDesc = 'name_desc',
  DescriptionAsc = 'description_asc',
  DescriptionDesc = 'description_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  CreatedAtAsc = 'created_at_asc',
  CreatedAtDesc = 'created_at_desc',
  UpdatedAtAsc = 'updated_at_asc',
  UpdatedAtDesc = 'updated_at_desc'
}

export type HangarCauseType = {
  __typename?: 'CauseType';
  /** Name of cause. Ex: 'Aviate Segfault' */
  name: Scalars['String'];
  /** Description of what this cause means */
  description: Scalars['String'];
  /** UUID for the object */
  id: Scalars['String'];
  /** Time the object was created */
  createdAt: Scalars['String'];
  /** Time the object was last updated */
  updatedAt: Scalars['String'];
  events?: Maybe<Array<Maybe<HangarEventType>>>;
};

export type HangarCollectionConnectionTypeConnection = {
  __typename?: 'CollectionConnectionTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: HangarPageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HangarCollectionConnectionTypeEdge>>;
  total?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `CollectionConnectionType` and its cursor. */
export type HangarCollectionConnectionTypeEdge = {
  __typename?: 'CollectionConnectionTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<HangarCollectionType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type HangarCollectionReviewConnectionTypeConnection = {
  __typename?: 'CollectionReviewConnectionTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: HangarPageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HangarCollectionReviewConnectionTypeEdge>>;
  total?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `CollectionReviewConnectionType` and its cursor. */
export type HangarCollectionReviewConnectionTypeEdge = {
  __typename?: 'CollectionReviewConnectionTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<HangarCollectionReviewType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum HangarCollectionReviewSortEnum {
  CollectionIdAsc = 'collection_id_asc',
  CollectionIdDesc = 'collection_id_desc',
  RequesterIdAsc = 'requester_id_asc',
  RequesterIdDesc = 'requester_id_desc',
  ReviewerIdAsc = 'reviewer_id_asc',
  ReviewerIdDesc = 'reviewer_id_desc',
  ReviewerTeamIdAsc = 'reviewer_team_id_asc',
  ReviewerTeamIdDesc = 'reviewer_team_id_desc',
  ReviewStatusAsc = 'review_status_asc',
  ReviewStatusDesc = 'review_status_desc',
  ReviewedTimeAsc = 'reviewed_time_asc',
  ReviewedTimeDesc = 'reviewed_time_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  CreatedAtAsc = 'created_at_asc',
  CreatedAtDesc = 'created_at_desc',
  UpdatedAtAsc = 'updated_at_asc',
  UpdatedAtDesc = 'updated_at_desc',
  CreatedByIdAsc = 'created_by_id_asc',
  CreatedByIdDesc = 'created_by_id_desc',
  UpdatedByIdAsc = 'updated_by_id_asc',
  UpdatedByIdDesc = 'updated_by_id_desc'
}

export type HangarCollectionReviewType = {
  __typename?: 'CollectionReviewType';
  /** Id of Robot Log this Event occured in */
  collectionId: Scalars['String'];
  /** Id of User that added the review request */
  requesterId: Scalars['String'];
  /** Id of User that will review the request */
  reviewerId?: Maybe<Scalars['String']>;
  /** Id of User that will review the request */
  reviewerTeamId?: Maybe<Scalars['String']>;
  reviewStatus?: Maybe<HangarCollection_Review_Review_Status>;
  /** Time the the review was set to complete. */
  reviewedTime?: Maybe<Scalars['String']>;
  /** UUID for the object */
  id: Scalars['String'];
  /** Time the object was created */
  createdAt: Scalars['String'];
  /** Time the object was last updated */
  updatedAt: Scalars['String'];
  /** Id of user that created the object */
  createdById?: Maybe<Scalars['String']>;
  /** Id of user that last updated the object */
  updatedById?: Maybe<Scalars['String']>;
  collection?: Maybe<HangarCollectionType>;
  requester?: Maybe<HangarUserType>;
  reviewer?: Maybe<HangarUserType>;
  reviewerTeam?: Maybe<HangarTeamType>;
  createdBy?: Maybe<HangarUserType>;
  updatedBy?: Maybe<HangarUserType>;
};

/** An enumeration. */
export enum HangarCollectionSortEnum {
  NameAsc = 'name_asc',
  NameDesc = 'name_desc',
  DescriptionAsc = 'description_asc',
  DescriptionDesc = 'description_desc',
  CollectionTypeAsc = 'collection_type_asc',
  CollectionTypeDesc = 'collection_type_desc',
  LastRobotLogsRefreshAtAsc = 'last_robot_logs_refresh_at_asc',
  LastRobotLogsRefreshAtDesc = 'last_robot_logs_refresh_at_desc',
  IsSystemDefaultAsc = 'is_system_default_asc',
  IsSystemDefaultDesc = 'is_system_default_desc',
  IsPrivateForCreatorAsc = 'is_private_for_creator_asc',
  IsPrivateForCreatorDesc = 'is_private_for_creator_desc',
  IsPubliclyEditableAsc = 'is_publicly_editable_asc',
  IsPubliclyEditableDesc = 'is_publicly_editable_desc',
  SystemDefaultResolutionSchemeAsc = 'system_default_resolution_scheme_asc',
  SystemDefaultResolutionSchemeDesc = 'system_default_resolution_scheme_desc',
  UiDataAsc = 'ui_data_asc',
  UiDataDesc = 'ui_data_desc',
  RuleSetIdAsc = 'rule_set_id_asc',
  RuleSetIdDesc = 'rule_set_id_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  CreatedAtAsc = 'created_at_asc',
  CreatedAtDesc = 'created_at_desc',
  UpdatedAtAsc = 'updated_at_asc',
  UpdatedAtDesc = 'updated_at_desc',
  CreatedByIdAsc = 'created_by_id_asc',
  CreatedByIdDesc = 'created_by_id_desc',
  UpdatedByIdAsc = 'updated_by_id_asc',
  UpdatedByIdDesc = 'updated_by_id_desc',
  CreatedByNameAsc = 'created_by_name_asc',
  CreatedByNameDesc = 'created_by_name_desc'
}

export type HangarCollectionType = {
  __typename?: 'CollectionType';
  /** Awesome smoothness flights */
  name: Scalars['String'];
  /** The cream of the crop when it comes to smooth flights */
  description: Scalars['String'];
  collectionType?: Maybe<HangarCollection_Collection_Type>;
  /** The last time the list of robot logs was refreshed for this collection */
  lastRobotLogsRefreshAt?: Maybe<Scalars['String']>;
  /** System default collections are managed by the team and have special rules associated with them */
  isSystemDefault?: Maybe<Scalars['Boolean']>;
  /** Represents whether or not this is only meant to be shown to the creator user */
  isPrivateForCreator: Scalars['Boolean'];
  /** Represents whether or not a user other than the creator can update the collection properties */
  isPubliclyEditable?: Maybe<Scalars['Boolean']>;
  systemDefaultResolutionScheme?: Maybe<HangarCollection_System_Default_Resolution_Scheme>;
  /** Object structure of the filters needed to query robot logs */
  uiData?: Maybe<Scalars['JSONString']>;
  /** Id of Environment this robot log flies in */
  ruleSetId?: Maybe<Scalars['String']>;
  /** UUID for the object */
  id: Scalars['String'];
  /** Time the object was created */
  createdAt: Scalars['String'];
  /** Time the object was last updated */
  updatedAt: Scalars['String'];
  /** Id of user that created the object */
  createdById?: Maybe<Scalars['String']>;
  /** Id of user that last updated the object */
  updatedById?: Maybe<Scalars['String']>;
  robotLogs?: Maybe<HangarRobotLogConnectionTypeConnection>;
  includedRobotLogs?: Maybe<Array<Maybe<HangarRobotLogType>>>;
  excludedRobotLogs?: Maybe<Array<Maybe<HangarRobotLogType>>>;
  ruleSet?: Maybe<HangarRuleSetType>;
  favoriteForUsers?: Maybe<Array<Maybe<HangarUserType>>>;
  labels?: Maybe<HangarLabelConnectionTypeConnection>;
  collectionReviews?: Maybe<HangarCollectionReviewConnectionTypeConnection>;
  measurements?: Maybe<Array<Maybe<HangarMeasurementType>>>;
  createdBy?: Maybe<HangarUserType>;
  updatedBy?: Maybe<HangarUserType>;
  robotLogsTotalCount?: Maybe<Scalars['Int']>;
  dashboardEmbedUrls?: Maybe<Array<Maybe<HangarDashboardEmbedUrlType>>>;
  favoriteForUsersTotalCount?: Maybe<Scalars['Int']>;
};


export type HangarCollectionTypeRobotLogsArgs = {
  names?: Maybe<Array<Maybe<Scalars['String']>>>;
  robotIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  robotProducts?: Maybe<Array<Maybe<HangarProduct>>>;
  environmentIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  tagIdsPresent?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  causeIdsPresent?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  ticketIdsPresent?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  ticketPlatformIdsPresent?: Maybe<Array<Maybe<Scalars['String']>>>;
  involvedUserIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  softwareVersions?: Maybe<Array<Maybe<Scalars['String']>>>;
  softwareBranches?: Maybe<Array<Maybe<Scalars['String']>>>;
  softwareCommitHashes?: Maybe<Array<Maybe<Scalars['String']>>>;
  robotLogTestIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  testDefinitionIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  labelIdsPresent?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  robotLogTypes?: Maybe<Array<Maybe<HangarRobotLogTypeChoice>>>;
  robotLogReviewReviewStatuses?: Maybe<Array<Maybe<HangarReviewStatus>>>;
  robotLogReviewReviewStatusesNotIn?: Maybe<Array<Maybe<HangarReviewStatus>>>;
  robotLogReviewReviewerIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  robotLogReviewReviewerTeamIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  robotLogReviewRequesterIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  parentIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  robotLogIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  phoneIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  phoneSoftwareIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  backpackIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  backpackSoftwareIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  startTimeOfDayRangeIn?: Maybe<Array<Maybe<HangarTimeRange>>>;
  artifactArtifactTypeIn?: Maybe<Array<Maybe<HangarArtifactType>>>;
  logEventComponentOwners?: Maybe<Array<Maybe<Scalars['String']>>>;
  logEventComponentExternalIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  logEventLogDefinitionExternalIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  startTime_Range?: Maybe<HangarDateTimeRange>;
  endTime_Range?: Maybe<HangarDateTimeRange>;
  createdAt_Range?: Maybe<HangarDateTimeRange>;
  totalTime_Range?: Maybe<HangarIntRange>;
  textLike?: Maybe<Scalars['String']>;
  ticketPlatform?: Maybe<Scalars['String']>;
  flightType?: Maybe<Scalars['String']>;
  collectionId?: Maybe<Scalars['UUID']>;
  id?: Maybe<Scalars['String']>;
  originalName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarRobotLogSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarCollectionTypeLabelsArgs = {
  ids?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  names?: Maybe<Array<Maybe<Scalars['String']>>>;
  robotLogIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  collectionIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  textLike?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarLabelSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarCollectionTypeCollectionReviewsArgs = {
  collectionIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  reviewStatus?: Maybe<HangarReviewStatus>;
  id?: Maybe<Scalars['String']>;
  collectionId?: Maybe<Scalars['String']>;
  reviewerId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarCollectionReviewSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum HangarCollectionTypeChoice {
  SystemDefault = 'SYSTEM_DEFAULT',
  UserCreated = 'USER_CREATED',
  Fts = 'FTS',
  Vil = 'VIL',
  BringUpVerification = 'BRING_UP_VERIFICATION'
}

export type HangarComponentType = {
  __typename?: 'ComponentType';
  name?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['Int']>;
};

export type HangarDashboardEmbedUrlType = {
  __typename?: 'DashboardEmbedUrlType';
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
};


/** GraphQL Input type for querying on a range of Datetime values */
export type HangarDateTimeRange = {
  gte?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
};

/** An enumeration. */
export enum HangarDissectionEnum {
  All = 'ALL',
  ByDay = 'BY_DAY',
  ByWeek = 'BY_WEEK',
  ByMonth = 'BY_MONTH',
  BySwCommitHash = 'BY_SW_COMMIT_HASH',
  BySwBranchName = 'BY_SW_BRANCH_NAME',
  BySwReleaseVersion = 'BY_SW_RELEASE_VERSION',
  ByRobotLog = 'BY_ROBOT_LOG',
  ByAncestorTestInstance = 'BY_ANCESTOR_TEST_INSTANCE',
  ByAncestorTestTrial = 'BY_ANCESTOR_TEST_TRIAL',
  ByAncestorBootSession = 'BY_ANCESTOR_BOOT_SESSION'
}

export type HangarDocumentSearchRobotLogConnectionEdge = {
  __typename?: 'DocumentSearchRobotLogConnectionEdge';
  node?: Maybe<HangarRobotLogType>;
};

export type HangarDocumentSearchRobotLogConnectionType = {
  __typename?: 'DocumentSearchRobotLogConnectionType';
  pageInfo?: Maybe<HangarPageInfo>;
  robotLogIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  edges?: Maybe<Array<Maybe<HangarDocumentSearchRobotLogConnectionEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type HangarDopplerJobType = {
  __typename?: 'DopplerJobType';
  description?: Maybe<Scalars['String']>;
  jobName?: Maybe<Scalars['String']>;
};

export type HangarEnvironmentConnectionTypeConnection = {
  __typename?: 'EnvironmentConnectionTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: HangarPageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HangarEnvironmentConnectionTypeEdge>>;
};

/** A Relay edge containing a `EnvironmentConnectionType` and its cursor. */
export type HangarEnvironmentConnectionTypeEdge = {
  __typename?: 'EnvironmentConnectionTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<HangarEnvironmentType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum HangarEnvironmentSortEnum {
  NameAsc = 'name_asc',
  NameDesc = 'name_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  CreatedAtAsc = 'created_at_asc',
  CreatedAtDesc = 'created_at_desc',
  UpdatedAtAsc = 'updated_at_asc',
  UpdatedAtDesc = 'updated_at_desc'
}

export type HangarEnvironmentType = {
  __typename?: 'EnvironmentType';
  /** Name of the environment where a test occurs or a robot flies */
  name: Scalars['String'];
  /** UUID for the object */
  id: Scalars['String'];
  /** Time the object was created */
  createdAt: Scalars['String'];
  /** Time the object was last updated */
  updatedAt: Scalars['String'];
  biomes?: Maybe<Array<Maybe<HangarBiomeType>>>;
};

/** An enumeration. */
export enum HangarErrorLevel {
  ErrorLevelUnspecified = 'ERROR_LEVEL_UNSPECIFIED',
  Debug = 'DEBUG',
  Info = 'INFO',
  Warn = 'WARN',
  Error = 'ERROR',
  Fatal = 'FATAL'
}

export type HangarEventConnectionTypeConnection = {
  __typename?: 'EventConnectionTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: HangarPageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HangarEventConnectionTypeEdge>>;
  total?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `EventConnectionType` and its cursor. */
export type HangarEventConnectionTypeEdge = {
  __typename?: 'EventConnectionTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<HangarEventType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum HangarEventSortEnum {
  RobotLogIdAsc = 'robot_log_id_asc',
  RobotLogIdDesc = 'robot_log_id_desc',
  StartTimeAsc = 'start_time_asc',
  StartTimeDesc = 'start_time_desc',
  EndTimeAsc = 'end_time_asc',
  EndTimeDesc = 'end_time_desc',
  EventDataAsc = 'event_data_asc',
  EventDataDesc = 'event_data_desc',
  NotesAsc = 'notes_asc',
  NotesDesc = 'notes_desc',
  TagIdAsc = 'tag_id_asc',
  TagIdDesc = 'tag_id_desc',
  CauseIdAsc = 'cause_id_asc',
  CauseIdDesc = 'cause_id_desc',
  SourceTypeIdAsc = 'source_type_id_asc',
  SourceTypeIdDesc = 'source_type_id_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  CreatedAtAsc = 'created_at_asc',
  CreatedAtDesc = 'created_at_desc',
  UpdatedAtAsc = 'updated_at_asc',
  UpdatedAtDesc = 'updated_at_desc',
  CreatedByIdAsc = 'created_by_id_asc',
  CreatedByIdDesc = 'created_by_id_desc',
  UpdatedByIdAsc = 'updated_by_id_asc',
  UpdatedByIdDesc = 'updated_by_id_desc'
}

export type HangarEventType = {
  __typename?: 'EventType';
  /** Id of Robot Log this Event occured in */
  robotLogId: Scalars['String'];
  /** Time in Robot Log this event started or happened */
  startTime: Scalars['String'];
  /** Time in Robot Log this event ended (optional). Leave blank if event only occurred at start_time. */
  endTime?: Maybe<Scalars['String']>;
  /** Unstructured data containing misc event information */
  eventData?: Maybe<Scalars['JSONString']>;
  /** Freeform notes attached to an event */
  notes?: Maybe<Scalars['String']>;
  /** Id of Tag that describes this Event */
  tagId?: Maybe<Scalars['String']>;
  /** Id of Cause that is suspected to have caused this Event */
  causeId?: Maybe<Scalars['String']>;
  /** Id of the Source Type for this Event */
  sourceTypeId: Scalars['String'];
  /** UUID for the object */
  id: Scalars['String'];
  /** Time the object was created */
  createdAt: Scalars['String'];
  /** Time the object was last updated */
  updatedAt: Scalars['String'];
  /** Id of user that created the object */
  createdById?: Maybe<Scalars['String']>;
  /** Id of user that last updated the object */
  updatedById?: Maybe<Scalars['String']>;
  robotLog?: Maybe<HangarRobotLogType>;
  tag?: Maybe<HangarTagType>;
  cause?: Maybe<HangarCauseType>;
  sourceType?: Maybe<HangarSourceTypeType>;
  tickets?: Maybe<Array<Maybe<HangarTicketType>>>;
  createdBy?: Maybe<HangarUserType>;
  updatedBy?: Maybe<HangarUserType>;
};

/** An enumeration. */
export enum HangarFramework {
  Judy = 'JUDY',
  Judith = 'JUDITH',
  Piper = 'PIPER',
  Manual = 'MANUAL'
}

/** GraphQL Input type for querying on a range of Int values */
export type HangarIntRange = {
  gte?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
};

export type HangarInvalidateCacheMeasurement = {
  __typename?: 'InvalidateCacheMeasurement';
  ok?: Maybe<Scalars['Boolean']>;
};


export type HangarLabelConnectionTypeConnection = {
  __typename?: 'LabelConnectionTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: HangarPageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HangarLabelConnectionTypeEdge>>;
};

/** A Relay edge containing a `LabelConnectionType` and its cursor. */
export type HangarLabelConnectionTypeEdge = {
  __typename?: 'LabelConnectionTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<HangarLabelType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum HangarLabelSortEnum {
  NameAsc = 'name_asc',
  NameDesc = 'name_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  CreatedAtAsc = 'created_at_asc',
  CreatedAtDesc = 'created_at_desc',
  UpdatedAtAsc = 'updated_at_asc',
  UpdatedAtDesc = 'updated_at_desc',
  CreatedByIdAsc = 'created_by_id_asc',
  CreatedByIdDesc = 'created_by_id_desc',
  UpdatedByIdAsc = 'updated_by_id_asc',
  UpdatedByIdDesc = 'updated_by_id_desc',
  AppliedToRobotLogsCountAsc = 'applied_to_robot_logs_count_asc',
  AppliedToRobotLogsCountDesc = 'applied_to_robot_logs_count_desc',
  AppliedToCollectionsCountAsc = 'applied_to_collections_count_asc',
  AppliedToCollectionsCountDesc = 'applied_to_collections_count_desc'
}

export type HangarLabelType = {
  __typename?: 'LabelType';
  /** Raw name of the participant. Should be used when not mapped to a user_id */
  name?: Maybe<Scalars['String']>;
  /** UUID for the object */
  id: Scalars['String'];
  /** Time the object was created */
  createdAt: Scalars['String'];
  /** Time the object was last updated */
  updatedAt: Scalars['String'];
  /** Id of user that created the object */
  createdById?: Maybe<Scalars['String']>;
  /** Id of user that last updated the object */
  updatedById?: Maybe<Scalars['String']>;
  robotLogs?: Maybe<Array<Maybe<HangarRobotLogType>>>;
  collections?: Maybe<Array<Maybe<HangarCollectionType>>>;
  createdBy?: Maybe<HangarUserType>;
  updatedBy?: Maybe<HangarUserType>;
};

export type HangarLogDefinitionType = {
  __typename?: 'LogDefinitionType';
  product?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['Int']>;
};

export type HangarLogEventRecordType = {
  __typename?: 'LogEventRecordType';
  id?: Maybe<Scalars['Int']>;
  parentId?: Maybe<Scalars['Int']>;
  sourceExternalId?: Maybe<Scalars['String']>;
  sourceTypeName?: Maybe<Scalars['String']>;
  componentExternalId?: Maybe<Scalars['Int']>;
  componentName?: Maybe<Scalars['String']>;
  componentOwner?: Maybe<Scalars['String']>;
  componentProduct?: Maybe<Scalars['String']>;
  subsystem?: Maybe<Scalars['String']>;
  logDefinitionName?: Maybe<Scalars['String']>;
  logDefinitionDescription?: Maybe<Scalars['String']>;
  logDefinitionExternalId?: Maybe<Scalars['Int']>;
  timestamp?: Maybe<HangarTimestampType>;
  level?: Maybe<HangarErrorLevel>;
  file?: Maybe<Scalars['String']>;
  operation?: Maybe<Scalars['String']>;
  line?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  children?: Maybe<Array<Maybe<HangarLogEventType>>>;
};

/** An enumeration. */
export enum HangarLogEventSortOptionsEnum {
  LogEventSortOptionsUnspecified = 'LOG_EVENT_SORT_OPTIONS_UNSPECIFIED',
  Level = 'LEVEL'
}

export type HangarLogEventType = {
  __typename?: 'LogEventType';
  recordCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<HangarLogEventRecordType>>>;
  children?: Maybe<Array<Maybe<HangarLogEventRecordType>>>;
};

/** interval to split the measurement */
export enum HangarMeasurement_Dissection {
  All = 'ALL',
  ByDay = 'BY_DAY',
  ByWeek = 'BY_WEEK',
  ByMonth = 'BY_MONTH',
  BySwCommitHash = 'BY_SW_COMMIT_HASH',
  BySwBranchName = 'BY_SW_BRANCH_NAME',
  BySwReleaseVersion = 'BY_SW_RELEASE_VERSION',
  ByRobotLog = 'BY_ROBOT_LOG',
  ByAncestorTestInstance = 'BY_ANCESTOR_TEST_INSTANCE',
  ByAncestorTestTrial = 'BY_ANCESTOR_TEST_TRIAL',
  ByAncestorBootSession = 'BY_ANCESTOR_BOOT_SESSION'
}

/** statistic to be calculated */
export enum HangarMeasurement_Statistic {
  CountTotal = 'COUNT_TOTAL',
  CountAvg = 'COUNT_AVG',
  CountMin = 'COUNT_MIN',
  CountMax = 'COUNT_MAX',
  ValueSum = 'VALUE_SUM',
  ValueAvg = 'VALUE_AVG',
  ValueMin = 'VALUE_MIN',
  ValueMax = 'VALUE_MAX',
  ValueMedian = 'VALUE_MEDIAN',
  OccurrenceTotal = 'OCCURRENCE_TOTAL',
  OccurrencePercent = 'OCCURRENCE_PERCENT',
  OccurrenceTotalAvg = 'OCCURRENCE_TOTAL_AVG',
  OccurrencePercentAvg = 'OCCURRENCE_PERCENT_AVG',
  CountCumsum = 'COUNT_CUMSUM',
  CountCumavg = 'COUNT_CUMAVG',
  CountCummin = 'COUNT_CUMMIN',
  CountCummax = 'COUNT_CUMMAX',
  ValueCumsum = 'VALUE_CUMSUM',
  ValueCumavg = 'VALUE_CUMAVG',
  ValueCummin = 'VALUE_CUMMIN',
  ValueCummax = 'VALUE_CUMMAX'
}

export type HangarMeasurementAxis = {
  __typename?: 'MeasurementAxis';
  title?: Maybe<Scalars['String']>;
};

export type HangarMeasurementConnectionTypeConnection = {
  __typename?: 'MeasurementConnectionTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: HangarPageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HangarMeasurementConnectionTypeEdge>>;
};

/** A Relay edge containing a `MeasurementConnectionType` and its cursor. */
export type HangarMeasurementConnectionTypeEdge = {
  __typename?: 'MeasurementConnectionTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<HangarMeasurementType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type HangarMeasurementDefinitionType = {
  __typename?: 'MeasurementDefinitionType';
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  unit?: Maybe<HangarUnitEnum>;
  dissectionOptions?: Maybe<Array<Maybe<HangarDissectionEnum>>>;
  statisticOptions?: Maybe<HangarMeasurementStatisticOptions>;
  definitionSpecificOptions?: Maybe<Array<Maybe<HangarMeasurementSpecificOption>>>;
};

export type HangarMeasurementResultTrace = {
  __typename?: 'MeasurementResultTrace';
  robotLogIds?: Maybe<Array<Maybe<Array<Maybe<Scalars['String']>>>>>;
  text?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  /** X array represents the labels of a result trace, item at index corresponds to y array */
  x?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** When the x array comes back in a sorted order that is not reproducible by an alphanumeric sort this will be supplied. This really only becomes necessary to support when dealing with comparing multiple traces with this type of x value (e.g. comparing multiple traces of commit hashes). The index in this array corresponds with the value in the x array at the same index */
  xSortValues?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Y array represents the values of those labels associated in the x array. */
  y?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** Certain measurements will return an array of standard deviation,  plottable against x and corresponding to the y values */
  stdDev?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** 5th percentile of Y */
  percentile5th?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** 95th percentile of Y */
  percentile95th?: Maybe<Array<Maybe<Scalars['Float']>>>;
  lastDataPointCreatedAt?: Maybe<Scalars['String']>;
};

export type HangarMeasurementResultType = {
  __typename?: 'MeasurementResultType';
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  callout?: Maybe<Scalars['String']>;
  xaxis?: Maybe<HangarMeasurementAxis>;
  yaxis?: Maybe<HangarMeasurementAxis>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  traces?: Maybe<Array<Maybe<HangarMeasurementResultTrace>>>;
  cached?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum HangarMeasurementSortEnum {
  MeasurementDefinitionIdAsc = 'measurement_definition_id_asc',
  MeasurementDefinitionIdDesc = 'measurement_definition_id_desc',
  DissectionAsc = 'dissection_asc',
  DissectionDesc = 'dissection_desc',
  StatisticAsc = 'statistic_asc',
  StatisticDesc = 'statistic_desc',
  DefinitionSpecificValuesAsc = 'definition_specific_values_asc',
  DefinitionSpecificValuesDesc = 'definition_specific_values_desc',
  AssessmentCriteriaAsc = 'assessment_criteria_asc',
  AssessmentCriteriaDesc = 'assessment_criteria_desc',
  UiDataAsc = 'ui_data_asc',
  UiDataDesc = 'ui_data_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  CreatedAtAsc = 'created_at_asc',
  CreatedAtDesc = 'created_at_desc',
  UpdatedAtAsc = 'updated_at_asc',
  UpdatedAtDesc = 'updated_at_desc',
  CreatedByIdAsc = 'created_by_id_asc',
  CreatedByIdDesc = 'created_by_id_desc',
  UpdatedByIdAsc = 'updated_by_id_asc',
  UpdatedByIdDesc = 'updated_by_id_desc'
}

export type HangarMeasurementSpecificOption = {
  __typename?: 'MeasurementSpecificOption';
  parameter?: Maybe<Scalars['String']>;
  dataType?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
};

export type HangarMeasurementStatisticOptions = {
  __typename?: 'MeasurementStatisticOptions';
  dissected?: Maybe<Array<Maybe<HangarStatisticEnum>>>;
  all?: Maybe<Array<Maybe<HangarStatisticEnum>>>;
};

export type HangarMeasurementType = {
  __typename?: 'MeasurementType';
  /** UUID for the measurement_definition */
  measurementDefinitionId: Scalars['String'];
  dissection?: Maybe<HangarMeasurement_Dissection>;
  statistic?: Maybe<HangarMeasurement_Statistic>;
  definitionSpecificValues?: Maybe<Scalars['JSONString']>;
  assessmentCriteria?: Maybe<Scalars['JSONString']>;
  uiData?: Maybe<Scalars['JSONString']>;
  /** UUID for the object */
  id: Scalars['String'];
  /** Time the object was created */
  createdAt: Scalars['String'];
  /** Time the object was last updated */
  updatedAt: Scalars['String'];
  /** Id of user that created the object */
  createdById?: Maybe<Scalars['String']>;
  /** Id of user that last updated the object */
  updatedById?: Maybe<Scalars['String']>;
  collections?: Maybe<Array<Maybe<HangarCollectionType>>>;
  createdBy?: Maybe<HangarUserType>;
  updatedBy?: Maybe<HangarUserType>;
  measurementDefinition?: Maybe<HangarMeasurementDefinitionType>;
  measurementResult?: Maybe<HangarMeasurementResultType>;
};


export type HangarMeasurementTypeMeasurementResultArgs = {
  skipCache?: Maybe<Scalars['Boolean']>;
  invalidateCache?: Maybe<Scalars['Boolean']>;
};

export type HangarMetricConnectionTypeConnection = {
  __typename?: 'MetricConnectionTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: HangarPageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HangarMetricConnectionTypeEdge>>;
  total?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `MetricConnectionType` and its cursor. */
export type HangarMetricConnectionTypeEdge = {
  __typename?: 'MetricConnectionTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<HangarMetricType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type HangarMetricMeta = {
  __typename?: 'MetricMeta';
  names?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** An enumeration. */
export enum HangarMetricSortEnum {
  NameAsc = 'name_asc',
  NameDesc = 'name_desc',
  RobotLogIdAsc = 'robot_log_id_asc',
  RobotLogIdDesc = 'robot_log_id_desc',
  DataTypeAsc = 'data_type_asc',
  DataTypeDesc = 'data_type_desc',
  ValueAsc = 'value_asc',
  ValueDesc = 'value_desc',
  PipelineExecutionIdAsc = 'pipeline_execution_id_asc',
  PipelineExecutionIdDesc = 'pipeline_execution_id_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  CreatedAtAsc = 'created_at_asc',
  CreatedAtDesc = 'created_at_desc',
  CreatedByIdAsc = 'created_by_id_asc',
  CreatedByIdDesc = 'created_by_id_desc'
}

export type HangarMetricType = {
  __typename?: 'MetricType';
  /** Name of the output metric */
  name: Scalars['String'];
  /** Which RobotLog this metric relates to */
  robotLogId: Scalars['String'];
  /** The type of data the value field reprsents */
  dataType?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['JSONString']>;
  pipelineExecutionId?: Maybe<Scalars['String']>;
  /** UUID for the object */
  id: Scalars['String'];
  /** Time the object was created */
  createdAt: Scalars['String'];
  /** Id of user that created the object */
  createdById?: Maybe<Scalars['String']>;
  robotLog?: Maybe<HangarRobotLogType>;
  pipelineExecution?: Maybe<HangarPipelineExecutionType>;
  createdBy?: Maybe<HangarUserType>;
};

export type HangarMissionConnectionTypeConnection = {
  __typename?: 'MissionConnectionTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: HangarPageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HangarMissionConnectionTypeEdge>>;
};

/** A Relay edge containing a `MissionConnectionType` and its cursor. */
export type HangarMissionConnectionTypeEdge = {
  __typename?: 'MissionConnectionTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<HangarMissionType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum HangarMissionSortEnum {
  NameAsc = 'name_asc',
  NameDesc = 'name_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  CreatedAtAsc = 'created_at_asc',
  CreatedAtDesc = 'created_at_desc',
  UpdatedAtAsc = 'updated_at_asc',
  UpdatedAtDesc = 'updated_at_desc',
  CreatedByIdAsc = 'created_by_id_asc',
  CreatedByIdDesc = 'created_by_id_desc',
  UpdatedByIdAsc = 'updated_by_id_asc',
  UpdatedByIdDesc = 'updated_by_id_desc'
}

export type HangarMissionType = {
  __typename?: 'MissionType';
  /** The type of mission based on mode sequence */
  name: Scalars['String'];
  /** UUID for the object */
  id: Scalars['String'];
  /** Time the object was created */
  createdAt: Scalars['String'];
  /** Time the object was last updated */
  updatedAt: Scalars['String'];
  /** Id of user that created the object */
  createdById?: Maybe<Scalars['String']>;
  /** Id of user that last updated the object */
  updatedById?: Maybe<Scalars['String']>;
  createdBy?: Maybe<HangarUserType>;
  updatedBy?: Maybe<HangarUserType>;
};

export type HangarMutations = {
  __typename?: 'Mutations';
  addToMyFavorites?: Maybe<HangarUserType>;
  removeFromMyFavorites?: Maybe<HangarUserType>;
  userRobotLogViewed?: Maybe<HangarUserRobotLogDetailType>;
  createTrait?: Maybe<HangarTraitType>;
  updateTrait?: Maybe<HangarTraitType>;
  deleteTrait?: Maybe<HangarTraitType>;
  createTicket?: Maybe<HangarTicketType>;
  updateTicket?: Maybe<HangarTicketType>;
  createOrUpdateTicket?: Maybe<HangarTicketType>;
  addEventToTicket?: Maybe<HangarTicketType>;
  removeEventFromTicket?: Maybe<HangarTicketType>;
  deleteTicket?: Maybe<HangarTicketType>;
  createTeam?: Maybe<HangarTeamType>;
  updateTeam?: Maybe<HangarTeamType>;
  deleteTeam?: Maybe<HangarTeamType>;
  addUsersToTeam?: Maybe<HangarTeamType>;
  removeUsersFromTeam?: Maybe<HangarTeamType>;
  createTag?: Maybe<HangarTagType>;
  updateTag?: Maybe<HangarTagType>;
  deleteTag?: Maybe<HangarTagType>;
  createSourceType?: Maybe<HangarSourceTypeType>;
  updateSourceType?: Maybe<HangarSourceTypeType>;
  deleteSourceType?: Maybe<HangarSourceTypeType>;
  createSoftwareInfo?: Maybe<HangarSoftwareInfoType>;
  updateSoftwareInfo?: Maybe<HangarSoftwareInfoType>;
  deleteSoftwareInfo?: Maybe<HangarSoftwareInfoType>;
  createSeverity?: Maybe<HangarSeverityType>;
  updateSeverity?: Maybe<HangarSeverityType>;
  deleteSeverity?: Maybe<HangarSeverityType>;
  runDopplerJob?: Maybe<HangarRunDopplerJob>;
  createRuleSet?: Maybe<HangarRuleSetType>;
  updateRuleSet?: Maybe<HangarRuleSetType>;
  deleteRuleSet?: Maybe<HangarRuleSetType>;
  createRobot?: Maybe<HangarRobotType>;
  createOrUpdateRobot?: Maybe<HangarRobotType>;
  updateRobot?: Maybe<HangarRobotType>;
  createRobotLogTest?: Maybe<HangarRobotLogTestType>;
  updateRobotLogTest?: Maybe<HangarRobotLogTestType>;
  addTraitToRobotLogTest?: Maybe<HangarRobotLogTestType>;
  removeTraitFromRobotLogTest?: Maybe<HangarRobotLogTestType>;
  deleteRobotLogTest?: Maybe<HangarRobotLogTestType>;
  createRobotLog?: Maybe<HangarRobotLogType>;
  updateRobotLog?: Maybe<HangarRobotLogType>;
  createOrUpdateRobotLog?: Maybe<HangarRobotLogType>;
  createRobotLogReview?: Maybe<HangarRobotLogReviewType>;
  updateRobotLogReview?: Maybe<HangarRobotLogReviewType>;
  deleteRobotLogReview?: Maybe<HangarRobotLogReviewType>;
  createRobotLogParentRelationship?: Maybe<HangarRobotLogType>;
  removeRobotLogParentRelationship?: Maybe<HangarRobotLogType>;
  addLabelsToRobotLog?: Maybe<HangarRobotLogType>;
  removeLabelsFromRobotLog?: Maybe<HangarRobotLogType>;
  createOrUpdatePipelineExecution?: Maybe<HangarPipelineExecutionType>;
  createPhoneSoftware?: Maybe<HangarPhoneSoftwareType>;
  updatePhoneSoftware?: Maybe<HangarPhoneSoftwareType>;
  deletePhoneSoftware?: Maybe<HangarPhoneSoftwareType>;
  createPhone?: Maybe<HangarPhoneType>;
  updatePhone?: Maybe<HangarPhoneType>;
  deletePhone?: Maybe<HangarPhoneType>;
  createParticipant?: Maybe<HangarParticipantType>;
  updateParticipant?: Maybe<HangarParticipantType>;
  deleteParticipant?: Maybe<HangarParticipantType>;
  createOrganization?: Maybe<HangarOrganizationType>;
  updateOrganization?: Maybe<HangarOrganizationType>;
  deleteOrganization?: Maybe<HangarOrganizationType>;
  createNote?: Maybe<HangarNoteType>;
  deleteNote?: Maybe<HangarNoteType>;
  updateNote?: Maybe<HangarNoteType>;
  createMission?: Maybe<HangarMissionType>;
  updateMission?: Maybe<HangarMissionType>;
  deleteMission?: Maybe<HangarMissionType>;
  createOrUpdateMetric?: Maybe<HangarMetricType>;
  createMeasurement?: Maybe<HangarMeasurementType>;
  updateMeasurement?: Maybe<HangarMeasurementType>;
  deleteMeasurement?: Maybe<HangarMeasurementType>;
  invalidateCacheMeasurement?: Maybe<HangarInvalidateCacheMeasurement>;
  addMeasurementToCollection?: Maybe<HangarCollectionType>;
  removeMeasurementFromCollection?: Maybe<HangarCollectionType>;
  createLabel?: Maybe<HangarLabelType>;
  updateLabel?: Maybe<HangarLabelType>;
  deleteLabel?: Maybe<HangarLabelType>;
  createEvent?: Maybe<HangarEventType>;
  deleteEvent?: Maybe<HangarEventType>;
  addTicketToEvent?: Maybe<HangarEventType>;
  removeTicketFromEvent?: Maybe<HangarEventType>;
  updateEvent?: Maybe<HangarEventType>;
  createEnvironment?: Maybe<HangarEnvironmentType>;
  updateEnvironment?: Maybe<HangarEnvironmentType>;
  addBiomeToEnvironment?: Maybe<HangarEnvironmentType>;
  removeBiomeFromEnvironment?: Maybe<HangarEnvironmentType>;
  deleteEnvironment?: Maybe<HangarEnvironmentType>;
  createCollectionReview?: Maybe<HangarCollectionReviewType>;
  updateCollectionReview?: Maybe<HangarCollectionReviewType>;
  deleteCollectionReview?: Maybe<HangarCollectionReviewType>;
  createCollection?: Maybe<HangarCollectionType>;
  updateCollection?: Maybe<HangarCollectionType>;
  deleteCollection?: Maybe<HangarCollectionType>;
  cloneCollection?: Maybe<HangarCollectionType>;
  addIncludedRobotLogsToCollection?: Maybe<HangarCollectionType>;
  removeIncludedRobotLogsFromCollection?: Maybe<HangarCollectionType>;
  addExcludedRobotLogsToCollection?: Maybe<HangarCollectionType>;
  removeExcludedRobotLogsFromCollection?: Maybe<HangarCollectionType>;
  refreshRobotLogsForCollection?: Maybe<HangarCollectionType>;
  addLabelsToCollection?: Maybe<HangarCollectionType>;
  removeLabelsFromCollection?: Maybe<HangarCollectionType>;
  createCause?: Maybe<HangarCauseType>;
  updateCause?: Maybe<HangarCauseType>;
  deleteCause?: Maybe<HangarCauseType>;
  createBiome?: Maybe<HangarBiomeType>;
  updateBiome?: Maybe<HangarBiomeType>;
  deleteBiome?: Maybe<HangarBiomeType>;
  createBackpackSoftware?: Maybe<HangarBackpackSoftwareType>;
  updateBackpackSoftware?: Maybe<HangarBackpackSoftwareType>;
  deleteBackpackSoftware?: Maybe<HangarBackpackSoftwareType>;
  createBackpack?: Maybe<HangarBackpackType>;
  updateBackpack?: Maybe<HangarBackpackType>;
  deleteBackpack?: Maybe<HangarBackpackType>;
  createAnalysis?: Maybe<HangarAnalysisType>;
  updateAnalysis?: Maybe<HangarAnalysisType>;
  deleteAnalysis?: Maybe<HangarAnalysisType>;
  createOrUpdateAnalysis?: Maybe<HangarAnalysisType>;
  runAnalyzers?: Maybe<Array<Maybe<HangarAnalysisType>>>;
  createArtifact?: Maybe<HangarArtifactGqlType>;
  updateArtifact?: Maybe<HangarArtifactGqlType>;
  deleteArtifact?: Maybe<HangarArtifactGqlType>;
  createOrUpdateArtifact?: Maybe<HangarArtifactGqlType>;
};


export type HangarMutationsAddToMyFavoritesArgs = {
  collectionIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
};


export type HangarMutationsRemoveFromMyFavoritesArgs = {
  collectionIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
};


export type HangarMutationsUserRobotLogViewedArgs = {
  robotLogId: Scalars['UUID'];
};


export type HangarMutationsCreateTraitArgs = {
  name: Scalars['String'];
};


export type HangarMutationsUpdateTraitArgs = {
  id: Scalars['UUID'];
  name?: Maybe<Scalars['String']>;
};


export type HangarMutationsDeleteTraitArgs = {
  id: Scalars['String'];
};


export type HangarMutationsCreateTicketArgs = {
  eventIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  link: Scalars['String'];
  name: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  platform: Scalars['String'];
  platformId: Scalars['String'];
  platformCreatedAt?: Maybe<Scalars['String']>;
  platformSeverity?: Maybe<Scalars['String']>;
  ticketData?: Maybe<Scalars['JSONString']>;
  dead?: Maybe<Scalars['Boolean']>;
};


export type HangarMutationsUpdateTicketArgs = {
  id: Scalars['UUID'];
  eventIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  platformId?: Maybe<Scalars['String']>;
  platformCreatedAt?: Maybe<Scalars['String']>;
  platformSeverity?: Maybe<Scalars['String']>;
  ticketData?: Maybe<Scalars['JSONString']>;
  dead?: Maybe<Scalars['Boolean']>;
};


export type HangarMutationsCreateOrUpdateTicketArgs = {
  id?: Maybe<Scalars['UUID']>;
  eventIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  platformId?: Maybe<Scalars['String']>;
  platformCreatedAt?: Maybe<Scalars['String']>;
  platformSeverity?: Maybe<Scalars['String']>;
  ticketData?: Maybe<Scalars['JSONString']>;
  dead?: Maybe<Scalars['Boolean']>;
};


export type HangarMutationsAddEventToTicketArgs = {
  id: Scalars['UUID'];
  eventId: Scalars['UUID'];
  platformCreatedAt?: Maybe<Scalars['String']>;
  platformSeverity?: Maybe<Scalars['String']>;
  ticketData?: Maybe<Scalars['JSONString']>;
  dead?: Maybe<Scalars['Boolean']>;
};


export type HangarMutationsRemoveEventFromTicketArgs = {
  id: Scalars['UUID'];
  eventId: Scalars['UUID'];
  platformCreatedAt?: Maybe<Scalars['String']>;
  platformSeverity?: Maybe<Scalars['String']>;
  ticketData?: Maybe<Scalars['JSONString']>;
  dead?: Maybe<Scalars['Boolean']>;
};


export type HangarMutationsDeleteTicketArgs = {
  id: Scalars['String'];
};


export type HangarMutationsCreateTeamArgs = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};


export type HangarMutationsUpdateTeamArgs = {
  id: Scalars['UUID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};


export type HangarMutationsDeleteTeamArgs = {
  id: Scalars['String'];
};


export type HangarMutationsAddUsersToTeamArgs = {
  teamId: Scalars['UUID'];
  userIds: Array<Maybe<Scalars['UUID']>>;
};


export type HangarMutationsRemoveUsersFromTeamArgs = {
  teamId: Scalars['UUID'];
  userIds: Array<Maybe<Scalars['UUID']>>;
};


export type HangarMutationsCreateTagArgs = {
  name: Scalars['String'];
  description: Scalars['String'];
  severityId?: Maybe<Scalars['String']>;
};


export type HangarMutationsUpdateTagArgs = {
  id: Scalars['UUID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  severityId?: Maybe<Scalars['String']>;
};


export type HangarMutationsDeleteTagArgs = {
  id: Scalars['String'];
};


export type HangarMutationsCreateSourceTypeArgs = {
  name: Scalars['String'];
};


export type HangarMutationsUpdateSourceTypeArgs = {
  id: Scalars['UUID'];
  name?: Maybe<Scalars['String']>;
};


export type HangarMutationsDeleteSourceTypeArgs = {
  id: Scalars['String'];
};


export type HangarMutationsCreateSoftwareInfoArgs = {
  repoUrl: Scalars['String'];
  commitHash?: Maybe<Scalars['String']>;
  branchName?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  committedAt?: Maybe<Scalars['String']>;
};


export type HangarMutationsUpdateSoftwareInfoArgs = {
  id: Scalars['UUID'];
  repoUrl?: Maybe<Scalars['String']>;
  commitHash?: Maybe<Scalars['String']>;
  branchName?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  committedAt?: Maybe<Scalars['String']>;
};


export type HangarMutationsDeleteSoftwareInfoArgs = {
  id: Scalars['String'];
};


export type HangarMutationsCreateSeverityArgs = {
  name: Scalars['String'];
  description: Scalars['String'];
};


export type HangarMutationsUpdateSeverityArgs = {
  id: Scalars['UUID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};


export type HangarMutationsDeleteSeverityArgs = {
  id: Scalars['String'];
};


export type HangarMutationsRunDopplerJobArgs = {
  collectionId?: Maybe<Scalars['UUID']>;
  jobArgs?: Maybe<Scalars['JSONString']>;
  jobName: Scalars['String'];
  robotLogIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  robotLogNames?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type HangarMutationsCreateRuleSetArgs = {
  searchVersion?: Maybe<HangarRuleSetSearchVersion>;
  name?: Maybe<Scalars['String']>;
  robotLogSearchVariables: Scalars['JSONString'];
};


export type HangarMutationsUpdateRuleSetArgs = {
  id: Scalars['UUID'];
  name?: Maybe<Scalars['String']>;
  robotLogSearchVariables?: Maybe<Scalars['JSONString']>;
};


export type HangarMutationsDeleteRuleSetArgs = {
  id: Scalars['String'];
};


export type HangarMutationsCreateRobotArgs = {
  softwareVersion?: Maybe<Scalars['String']>;
  product?: Maybe<HangarProduct>;
  generation?: Maybe<Scalars['String']>;
  hostname: Scalars['String'];
  softwareInfoId?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
};


export type HangarMutationsCreateOrUpdateRobotArgs = {
  softwareVersion?: Maybe<Scalars['String']>;
  product?: Maybe<HangarProduct>;
  generation?: Maybe<Scalars['String']>;
  hostname: Scalars['String'];
  softwareInfoId?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
};


export type HangarMutationsUpdateRobotArgs = {
  id: Scalars['UUID'];
  product?: Maybe<HangarProduct>;
  name?: Maybe<Scalars['String']>;
  generation?: Maybe<Scalars['String']>;
  softwareInfoId?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
};


export type HangarMutationsCreateRobotLogTestArgs = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  testData?: Maybe<Scalars['JSONString']>;
  qualityGate?: Maybe<Scalars['String']>;
  environmentId?: Maybe<Scalars['String']>;
  intendedMissionId?: Maybe<Scalars['String']>;
};


export type HangarMutationsUpdateRobotLogTestArgs = {
  id: Scalars['UUID'];
  traitIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  testData?: Maybe<Scalars['JSONString']>;
  qualityGate?: Maybe<Scalars['String']>;
  environmentId?: Maybe<Scalars['String']>;
  intendedMissionId?: Maybe<Scalars['String']>;
};


export type HangarMutationsAddTraitToRobotLogTestArgs = {
  id: Scalars['UUID'];
  traitId: Scalars['UUID'];
  qualityGate?: Maybe<Scalars['String']>;
  environmentId?: Maybe<Scalars['String']>;
  intendedMissionId?: Maybe<Scalars['String']>;
};


export type HangarMutationsRemoveTraitFromRobotLogTestArgs = {
  id: Scalars['UUID'];
  traitId: Scalars['UUID'];
  qualityGate?: Maybe<Scalars['String']>;
  environmentId?: Maybe<Scalars['String']>;
  intendedMissionId?: Maybe<Scalars['String']>;
};


export type HangarMutationsDeleteRobotLogTestArgs = {
  id: Scalars['String'];
};


export type HangarMutationsCreateRobotLogArgs = {
  robotHostname?: Maybe<Scalars['String']>;
  commitHash?: Maybe<Scalars['String']>;
  robotLogType?: Maybe<HangarRobotLogTypeChoice>;
  parentName?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  robotId?: Maybe<Scalars['String']>;
  robotLogTestId?: Maybe<Scalars['String']>;
  environmentId?: Maybe<Scalars['String']>;
  robotLogData?: Maybe<Scalars['JSONString']>;
  phoneId?: Maybe<Scalars['String']>;
  phoneSoftwareId?: Maybe<Scalars['String']>;
  backpackId?: Maybe<Scalars['String']>;
  backpackSoftwareId?: Maybe<Scalars['String']>;
  testDefinitionId?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
};


export type HangarMutationsUpdateRobotLogArgs = {
  id?: Maybe<Scalars['UUID']>;
  robotHostname?: Maybe<Scalars['String']>;
  commitHash?: Maybe<Scalars['String']>;
  robotLogType?: Maybe<HangarRobotLogTypeChoice>;
  parentName?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  robotId?: Maybe<Scalars['String']>;
  robotLogTestId?: Maybe<Scalars['String']>;
  environmentId?: Maybe<Scalars['String']>;
  robotLogData?: Maybe<Scalars['JSONString']>;
  phoneId?: Maybe<Scalars['String']>;
  phoneSoftwareId?: Maybe<Scalars['String']>;
  backpackId?: Maybe<Scalars['String']>;
  backpackSoftwareId?: Maybe<Scalars['String']>;
  testDefinitionId?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
};


export type HangarMutationsCreateOrUpdateRobotLogArgs = {
  id?: Maybe<Scalars['UUID']>;
  robotHostname?: Maybe<Scalars['String']>;
  commitHash?: Maybe<Scalars['String']>;
  robotLogType?: Maybe<HangarRobotLogTypeChoice>;
  parentName?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  robotId?: Maybe<Scalars['String']>;
  robotLogTestId?: Maybe<Scalars['String']>;
  environmentId?: Maybe<Scalars['String']>;
  robotLogData?: Maybe<Scalars['JSONString']>;
  phoneId?: Maybe<Scalars['String']>;
  phoneSoftwareId?: Maybe<Scalars['String']>;
  backpackId?: Maybe<Scalars['String']>;
  backpackSoftwareId?: Maybe<Scalars['String']>;
  testDefinitionId?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
};


export type HangarMutationsCreateRobotLogReviewArgs = {
  robotLogId: Scalars['UUID'];
  reviewStatus?: Maybe<HangarReviewStatus>;
  reviewerId?: Maybe<Scalars['UUID']>;
  reviewerTeamId?: Maybe<Scalars['UUID']>;
};


export type HangarMutationsUpdateRobotLogReviewArgs = {
  id: Scalars['UUID'];
  reviewStatus: HangarReviewStatus;
};


export type HangarMutationsDeleteRobotLogReviewArgs = {
  id: Scalars['UUID'];
};


export type HangarMutationsCreateRobotLogParentRelationshipArgs = {
  id: Scalars['UUID'];
  parentRobotLogId: Scalars['UUID'];
};


export type HangarMutationsRemoveRobotLogParentRelationshipArgs = {
  id: Scalars['UUID'];
  parentRobotLogId: Scalars['UUID'];
};


export type HangarMutationsAddLabelsToRobotLogArgs = {
  robotLogId: Scalars['UUID'];
  labelIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
};


export type HangarMutationsRemoveLabelsFromRobotLogArgs = {
  robotLogId: Scalars['UUID'];
  labelIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
};


export type HangarMutationsCreateOrUpdatePipelineExecutionArgs = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['JSONString']>;
};


export type HangarMutationsCreatePhoneSoftwareArgs = {
  version: Scalars['String'];
};


export type HangarMutationsUpdatePhoneSoftwareArgs = {
  id: Scalars['UUID'];
  version?: Maybe<Scalars['String']>;
};


export type HangarMutationsDeletePhoneSoftwareArgs = {
  id: Scalars['String'];
};


export type HangarMutationsCreatePhoneArgs = {
  name?: Maybe<Scalars['String']>;
  serialNumber: Scalars['String'];
  model?: Maybe<Scalars['String']>;
  caseSerialNumber?: Maybe<Scalars['String']>;
};


export type HangarMutationsUpdatePhoneArgs = {
  id: Scalars['UUID'];
  name?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  caseSerialNumber?: Maybe<Scalars['String']>;
};


export type HangarMutationsDeletePhoneArgs = {
  id: Scalars['String'];
};


export type HangarMutationsCreateParticipantArgs = {
  participantRole: HangarParticipantRoleChoice;
  robotLogId: Scalars['UUID'];
  userId?: Maybe<Scalars['UUID']>;
  userFreeform?: Maybe<Scalars['String']>;
  sourceTypeId: Scalars['String'];
};


export type HangarMutationsUpdateParticipantArgs = {
  id: Scalars['UUID'];
  participantRole?: Maybe<HangarParticipantRoleChoice>;
  userId?: Maybe<Scalars['UUID']>;
  robotLogId?: Maybe<Scalars['UUID']>;
  userFreeform?: Maybe<Scalars['String']>;
  sourceTypeId?: Maybe<Scalars['String']>;
};


export type HangarMutationsDeleteParticipantArgs = {
  id: Scalars['String'];
};


export type HangarMutationsCreateOrganizationArgs = {
  name: Scalars['String'];
};


export type HangarMutationsUpdateOrganizationArgs = {
  id: Scalars['UUID'];
  name?: Maybe<Scalars['String']>;
};


export type HangarMutationsDeleteOrganizationArgs = {
  id: Scalars['String'];
};


export type HangarMutationsCreateNoteArgs = {
  noteType?: Maybe<HangarNoteTypeChoice>;
  displayText?: Maybe<Scalars['String']>;
  robotLogId: Scalars['String'];
  sourceTypeId: Scalars['String'];
};


export type HangarMutationsDeleteNoteArgs = {
  id: Scalars['String'];
};


export type HangarMutationsUpdateNoteArgs = {
  id: Scalars['UUID'];
  noteType?: Maybe<HangarNoteTypeChoice>;
  displayText?: Maybe<Scalars['String']>;
  sourceTypeId?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
};


export type HangarMutationsCreateMissionArgs = {
  name: Scalars['String'];
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
};


export type HangarMutationsUpdateMissionArgs = {
  id: Scalars['UUID'];
  name?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
};


export type HangarMutationsDeleteMissionArgs = {
  id: Scalars['String'];
};


export type HangarMutationsCreateOrUpdateMetricArgs = {
  robotLogName?: Maybe<Scalars['String']>;
  pipelineExecutionName?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['JSONString']>;
  name: Scalars['String'];
  robotLogId?: Maybe<Scalars['String']>;
  dataType?: Maybe<Scalars['String']>;
  pipelineExecutionId?: Maybe<Scalars['String']>;
};


export type HangarMutationsCreateMeasurementArgs = {
  definitionSpecificValues?: Maybe<Scalars['JSONString']>;
  assessmentCriteria?: Maybe<Scalars['JSONString']>;
  uiData?: Maybe<Scalars['JSONString']>;
  dissection?: Maybe<HangarDissectionEnum>;
  statistic?: Maybe<HangarStatisticEnum>;
  collectionIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  measurementDefinitionId: Scalars['String'];
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
};


export type HangarMutationsUpdateMeasurementArgs = {
  id: Scalars['UUID'];
  definitionSpecificValues?: Maybe<Scalars['JSONString']>;
  assessmentCriteria?: Maybe<Scalars['JSONString']>;
  uiData?: Maybe<Scalars['JSONString']>;
  dissection?: Maybe<HangarDissectionEnum>;
  statistic?: Maybe<HangarStatisticEnum>;
  collectionIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  measurementDefinitionId: Scalars['String'];
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
};


export type HangarMutationsDeleteMeasurementArgs = {
  id: Scalars['String'];
};


export type HangarMutationsInvalidateCacheMeasurementArgs = {
  id: Scalars['UUID'];
};


export type HangarMutationsAddMeasurementToCollectionArgs = {
  id: Scalars['UUID'];
  measurementId: Scalars['UUID'];
  name: Scalars['String'];
  description: Scalars['String'];
  lastRobotLogsRefreshAt?: Maybe<Scalars['String']>;
  isSystemDefault?: Maybe<Scalars['Boolean']>;
  isPrivateForCreator: Scalars['Boolean'];
  isPubliclyEditable?: Maybe<Scalars['Boolean']>;
  uiData?: Maybe<Scalars['JSONString']>;
  ruleSetId?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
};


export type HangarMutationsRemoveMeasurementFromCollectionArgs = {
  id: Scalars['UUID'];
  measurementId: Scalars['UUID'];
  name: Scalars['String'];
  description: Scalars['String'];
  lastRobotLogsRefreshAt?: Maybe<Scalars['String']>;
  isSystemDefault?: Maybe<Scalars['Boolean']>;
  isPrivateForCreator: Scalars['Boolean'];
  isPubliclyEditable?: Maybe<Scalars['Boolean']>;
  uiData?: Maybe<Scalars['JSONString']>;
  ruleSetId?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
};


export type HangarMutationsCreateLabelArgs = {
  name?: Maybe<Scalars['String']>;
};


export type HangarMutationsUpdateLabelArgs = {
  id: Scalars['UUID'];
  name?: Maybe<Scalars['String']>;
};


export type HangarMutationsDeleteLabelArgs = {
  id: Scalars['String'];
};


export type HangarMutationsCreateEventArgs = {
  ticketIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  tagName?: Maybe<Scalars['String']>;
  robotLogId: Scalars['String'];
  startTime: Scalars['String'];
  endTime?: Maybe<Scalars['String']>;
  eventData?: Maybe<Scalars['JSONString']>;
  notes?: Maybe<Scalars['String']>;
  tagId?: Maybe<Scalars['String']>;
  causeId?: Maybe<Scalars['String']>;
  sourceTypeId: Scalars['String'];
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
};


export type HangarMutationsDeleteEventArgs = {
  id: Scalars['String'];
};


export type HangarMutationsAddTicketToEventArgs = {
  id: Scalars['UUID'];
  ticketId: Scalars['UUID'];
  notes?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
};


export type HangarMutationsRemoveTicketFromEventArgs = {
  id: Scalars['UUID'];
  ticketId: Scalars['UUID'];
  notes?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
};


export type HangarMutationsUpdateEventArgs = {
  id: Scalars['UUID'];
  ticketIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  eventData?: Maybe<Scalars['JSONString']>;
  notes?: Maybe<Scalars['String']>;
  tagId?: Maybe<Scalars['String']>;
  causeId?: Maybe<Scalars['String']>;
  sourceTypeId?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
};


export type HangarMutationsCreateEnvironmentArgs = {
  name: Scalars['String'];
  biomeIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
};


export type HangarMutationsUpdateEnvironmentArgs = {
  id: Scalars['UUID'];
  biomeIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  name?: Maybe<Scalars['String']>;
};


export type HangarMutationsAddBiomeToEnvironmentArgs = {
  id: Scalars['UUID'];
  biomeId: Scalars['UUID'];
};


export type HangarMutationsRemoveBiomeFromEnvironmentArgs = {
  id: Scalars['UUID'];
  biomeId: Scalars['UUID'];
  name: Scalars['String'];
};


export type HangarMutationsDeleteEnvironmentArgs = {
  id: Scalars['String'];
};


export type HangarMutationsCreateCollectionReviewArgs = {
  collectionId: Scalars['UUID'];
  reviewStatus?: Maybe<HangarReviewStatus>;
  reviewerId?: Maybe<Scalars['UUID']>;
  reviewerTeamId?: Maybe<Scalars['UUID']>;
};


export type HangarMutationsUpdateCollectionReviewArgs = {
  id: Scalars['UUID'];
  reviewStatus: HangarReviewStatus;
};


export type HangarMutationsDeleteCollectionReviewArgs = {
  id: Scalars['UUID'];
};


export type HangarMutationsCreateCollectionArgs = {
  ruleSetId?: Maybe<Scalars['UUID']>;
  collectionType?: Maybe<HangarCollectionTypeChoice>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isPrivateForCreator?: Maybe<Scalars['Boolean']>;
  isPubliclyEditable?: Maybe<Scalars['Boolean']>;
  uiData?: Maybe<Scalars['JSONString']>;
};


export type HangarMutationsUpdateCollectionArgs = {
  id: Scalars['UUID'];
  ruleSetId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isPrivateForCreator?: Maybe<Scalars['Boolean']>;
  isPubliclyEditable?: Maybe<Scalars['Boolean']>;
  uiData?: Maybe<Scalars['JSONString']>;
};


export type HangarMutationsDeleteCollectionArgs = {
  id: Scalars['String'];
};


export type HangarMutationsCloneCollectionArgs = {
  id: Scalars['UUID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isPrivateForCreator?: Maybe<Scalars['Boolean']>;
  isPubliclyEditable?: Maybe<Scalars['Boolean']>;
};


export type HangarMutationsAddIncludedRobotLogsToCollectionArgs = {
  collectionId: Scalars['UUID'];
  robotLogIds: Array<Maybe<Scalars['UUID']>>;
};


export type HangarMutationsRemoveIncludedRobotLogsFromCollectionArgs = {
  collectionId: Scalars['UUID'];
  robotLogIds: Array<Maybe<Scalars['UUID']>>;
};


export type HangarMutationsAddExcludedRobotLogsToCollectionArgs = {
  collectionId: Scalars['UUID'];
  robotLogIds: Array<Maybe<Scalars['UUID']>>;
};


export type HangarMutationsRemoveExcludedRobotLogsFromCollectionArgs = {
  collectionId: Scalars['UUID'];
  robotLogIds: Array<Maybe<Scalars['UUID']>>;
};


export type HangarMutationsRefreshRobotLogsForCollectionArgs = {
  collectionId: Scalars['UUID'];
};


export type HangarMutationsAddLabelsToCollectionArgs = {
  collectionId: Scalars['UUID'];
  labelIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
};


export type HangarMutationsRemoveLabelsFromCollectionArgs = {
  collectionId: Scalars['UUID'];
  labelIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
};


export type HangarMutationsCreateCauseArgs = {
  name: Scalars['String'];
  description: Scalars['String'];
};


export type HangarMutationsUpdateCauseArgs = {
  id: Scalars['UUID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};


export type HangarMutationsDeleteCauseArgs = {
  id: Scalars['String'];
};


export type HangarMutationsCreateBiomeArgs = {
  name: Scalars['String'];
};


export type HangarMutationsUpdateBiomeArgs = {
  id: Scalars['UUID'];
  name?: Maybe<Scalars['String']>;
};


export type HangarMutationsDeleteBiomeArgs = {
  id: Scalars['String'];
};


export type HangarMutationsCreateBackpackSoftwareArgs = {
  version: Scalars['String'];
};


export type HangarMutationsUpdateBackpackSoftwareArgs = {
  id: Scalars['UUID'];
  version?: Maybe<Scalars['String']>;
};


export type HangarMutationsDeleteBackpackSoftwareArgs = {
  id: Scalars['String'];
};


export type HangarMutationsCreateBackpackArgs = {
  serialNumber: Scalars['String'];
  hostname: Scalars['String'];
};


export type HangarMutationsUpdateBackpackArgs = {
  id: Scalars['UUID'];
  serialNumber?: Maybe<Scalars['String']>;
  hostname?: Maybe<Scalars['String']>;
};


export type HangarMutationsDeleteBackpackArgs = {
  id: Scalars['String'];
};


export type HangarMutationsCreateAnalysisArgs = {
  robotLogName?: Maybe<Scalars['String']>;
  framework: HangarFramework;
  runStatus?: Maybe<HangarRunStatusChoice>;
  runRequestedAt?: Maybe<Scalars['DateTime']>;
  runStartedAt?: Maybe<Scalars['DateTime']>;
  runFinishedAt?: Maybe<Scalars['DateTime']>;
  pipelineExecutionName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  data?: Maybe<Scalars['JSONString']>;
  ranSuccessfully?: Maybe<Scalars['Boolean']>;
  testPassed?: Maybe<Scalars['Boolean']>;
  robotLogId?: Maybe<Scalars['String']>;
  pipelineExecutionId?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
};


export type HangarMutationsUpdateAnalysisArgs = {
  id?: Maybe<Scalars['UUID']>;
  robotLogName?: Maybe<Scalars['String']>;
  framework?: Maybe<HangarFramework>;
  runStatus?: Maybe<HangarRunStatusChoice>;
  runRequestedAt?: Maybe<Scalars['DateTime']>;
  runStartedAt?: Maybe<Scalars['DateTime']>;
  runFinishedAt?: Maybe<Scalars['DateTime']>;
  pipelineExecutionName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['JSONString']>;
  ranSuccessfully?: Maybe<Scalars['Boolean']>;
  testPassed?: Maybe<Scalars['Boolean']>;
  robotLogId?: Maybe<Scalars['String']>;
  pipelineExecutionId?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
};


export type HangarMutationsDeleteAnalysisArgs = {
  id: Scalars['String'];
};


export type HangarMutationsCreateOrUpdateAnalysisArgs = {
  id?: Maybe<Scalars['UUID']>;
  framework?: Maybe<HangarFramework>;
  robotLogName?: Maybe<Scalars['String']>;
  runStatus?: Maybe<HangarRunStatusChoice>;
  runRequestedAt?: Maybe<Scalars['DateTime']>;
  runStartedAt?: Maybe<Scalars['DateTime']>;
  runFinishedAt?: Maybe<Scalars['DateTime']>;
  pipelineExecutionName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['JSONString']>;
  ranSuccessfully?: Maybe<Scalars['Boolean']>;
  testPassed?: Maybe<Scalars['Boolean']>;
  robotLogId?: Maybe<Scalars['String']>;
  pipelineExecutionId?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
};


export type HangarMutationsRunAnalyzersArgs = {
  robotLogId: Scalars['UUID'];
  analyzerNames: Array<Maybe<Scalars['String']>>;
};


export type HangarMutationsCreateArtifactArgs = {
  robotLogIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  phoneIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  robotLogNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  robotLogName?: Maybe<Scalars['String']>;
  robotLogId?: Maybe<Scalars['String']>;
  artifactType?: Maybe<HangarArtifactType>;
  storageType?: Maybe<HangarStorageType>;
  artifactDataUri?: Maybe<Scalars['String']>;
  pipelineExecutionName?: Maybe<Scalars['String']>;
  fileName: Scalars['String'];
  checksum?: Maybe<Scalars['String']>;
  lastModifiedTime?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  path?: Maybe<Scalars['String']>;
  artifactData?: Maybe<Scalars['JSONString']>;
  pipelineExecutionId?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
};


export type HangarMutationsUpdateArtifactArgs = {
  robotLogIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  phoneIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  robotLogNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  robotLogName?: Maybe<Scalars['String']>;
  robotLogId?: Maybe<Scalars['String']>;
  artifactType?: Maybe<HangarArtifactType>;
  storageType?: Maybe<HangarStorageType>;
  artifactDataUri?: Maybe<Scalars['String']>;
  pipelineExecutionName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  fileName?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  lastModifiedTime?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  path?: Maybe<Scalars['String']>;
  artifactData?: Maybe<Scalars['JSONString']>;
  pipelineExecutionId?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
};


export type HangarMutationsDeleteArtifactArgs = {
  id: Scalars['String'];
};


export type HangarMutationsCreateOrUpdateArtifactArgs = {
  robotLogIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  phoneIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  robotLogNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  robotLogName?: Maybe<Scalars['String']>;
  robotLogId?: Maybe<Scalars['String']>;
  artifactType?: Maybe<HangarArtifactType>;
  storageType?: Maybe<HangarStorageType>;
  artifactDataUri?: Maybe<Scalars['String']>;
  pipelineExecutionName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  fileName?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  lastModifiedTime?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  path?: Maybe<Scalars['String']>;
  artifactData?: Maybe<Scalars['JSONString']>;
  pipelineExecutionId?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
};

/** The type of note this is */
export enum HangarNote_Note_Type {
  Observer = 'OBSERVER',
  PostReview = 'POST_REVIEW'
}

export type HangarNoteConnectionTypeConnection = {
  __typename?: 'NoteConnectionTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: HangarPageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HangarNoteConnectionTypeEdge>>;
  total?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `NoteConnectionType` and its cursor. */
export type HangarNoteConnectionTypeEdge = {
  __typename?: 'NoteConnectionTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<HangarNoteType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum HangarNoteSortEnum {
  DisplayTextAsc = 'display_text_asc',
  DisplayTextDesc = 'display_text_desc',
  RobotLogIdAsc = 'robot_log_id_asc',
  RobotLogIdDesc = 'robot_log_id_desc',
  SourceTypeIdAsc = 'source_type_id_asc',
  SourceTypeIdDesc = 'source_type_id_desc',
  NoteTypeAsc = 'note_type_asc',
  NoteTypeDesc = 'note_type_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  CreatedAtAsc = 'created_at_asc',
  CreatedAtDesc = 'created_at_desc',
  UpdatedAtAsc = 'updated_at_asc',
  UpdatedAtDesc = 'updated_at_desc',
  CreatedByIdAsc = 'created_by_id_asc',
  CreatedByIdDesc = 'created_by_id_desc',
  UpdatedByIdAsc = 'updated_by_id_asc',
  UpdatedByIdDesc = 'updated_by_id_desc'
}

export type HangarNoteType = {
  __typename?: 'NoteType';
  /** Text note attached to a robot log */
  displayText?: Maybe<Scalars['String']>;
  /** Id of Robot Log this note occured in */
  robotLogId: Scalars['String'];
  /** Id of the Source Type for this Note */
  sourceTypeId: Scalars['String'];
  noteType?: Maybe<HangarNote_Note_Type>;
  /** UUID for the object */
  id: Scalars['String'];
  /** Time the object was created */
  createdAt: Scalars['String'];
  /** Time the object was last updated */
  updatedAt: Scalars['String'];
  /** Id of user that created the object */
  createdById?: Maybe<Scalars['String']>;
  /** Id of user that last updated the object */
  updatedById?: Maybe<Scalars['String']>;
  robotLog?: Maybe<HangarRobotLogType>;
  sourceType?: Maybe<HangarSourceTypeType>;
  createdBy?: Maybe<HangarUserType>;
  updatedBy?: Maybe<HangarUserType>;
};

/** An enumeration. */
export enum HangarNoteTypeChoice {
  Observer = 'OBSERVER',
  PostReview = 'POST_REVIEW'
}

export type HangarOmnisearchType = {
  __typename?: 'OmnisearchType';
  collections?: Maybe<Array<Maybe<HangarCollectionType>>>;
  robotLogs?: Maybe<Array<Maybe<HangarRobotLogType>>>;
};

export type HangarOrganizationConnectionTypeConnection = {
  __typename?: 'OrganizationConnectionTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: HangarPageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HangarOrganizationConnectionTypeEdge>>;
};

/** A Relay edge containing a `OrganizationConnectionType` and its cursor. */
export type HangarOrganizationConnectionTypeEdge = {
  __typename?: 'OrganizationConnectionTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<HangarOrganizationType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum HangarOrganizationSortEnum {
  NameAsc = 'name_asc',
  NameDesc = 'name_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  CreatedAtAsc = 'created_at_asc',
  CreatedAtDesc = 'created_at_desc',
  UpdatedAtAsc = 'updated_at_asc',
  UpdatedAtDesc = 'updated_at_desc'
}

export type HangarOrganizationType = {
  __typename?: 'OrganizationType';
  /** Name of organization */
  name: Scalars['String'];
  /** UUID for the object */
  id: Scalars['String'];
  /** Time the object was created */
  createdAt: Scalars['String'];
  /** Time the object was last updated */
  updatedAt: Scalars['String'];
  users?: Maybe<Array<Maybe<HangarUserType>>>;
  robots?: Maybe<Array<Maybe<HangarRobotType>>>;
};

/** Which role this participant should be classified as */
export enum HangarParticipant_Participant_Role {
  Pilot = 'PILOT',
  Observer = 'OBSERVER'
}

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type HangarPageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

export type HangarParticipantConnectionTypeConnection = {
  __typename?: 'ParticipantConnectionTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: HangarPageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HangarParticipantConnectionTypeEdge>>;
  total?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `ParticipantConnectionType` and its cursor. */
export type HangarParticipantConnectionTypeEdge = {
  __typename?: 'ParticipantConnectionTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<HangarParticipantType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum HangarParticipantRoleChoice {
  Pilot = 'PILOT',
  Observer = 'OBSERVER'
}

/** An enumeration. */
export enum HangarParticipantSortEnum {
  RobotLogIdAsc = 'robot_log_id_asc',
  RobotLogIdDesc = 'robot_log_id_desc',
  ParticipantRoleAsc = 'participant_role_asc',
  ParticipantRoleDesc = 'participant_role_desc',
  UserIdAsc = 'user_id_asc',
  UserIdDesc = 'user_id_desc',
  UserFreeformAsc = 'user_freeform_asc',
  UserFreeformDesc = 'user_freeform_desc',
  SourceTypeIdAsc = 'source_type_id_asc',
  SourceTypeIdDesc = 'source_type_id_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  CreatedAtAsc = 'created_at_asc',
  CreatedAtDesc = 'created_at_desc',
  UpdatedAtAsc = 'updated_at_asc',
  UpdatedAtDesc = 'updated_at_desc',
  CreatedByIdAsc = 'created_by_id_asc',
  CreatedByIdDesc = 'created_by_id_desc',
  UpdatedByIdAsc = 'updated_by_id_asc',
  UpdatedByIdDesc = 'updated_by_id_desc'
}

export type HangarParticipantType = {
  __typename?: 'ParticipantType';
  /** Which flight this member is a participant of */
  robotLogId: Scalars['String'];
  participantRole?: Maybe<HangarParticipant_Participant_Role>;
  /** Id of a user who is a participant */
  userId?: Maybe<Scalars['String']>;
  /** Raw name of the participant. Should be used when not mapped to a user_id */
  userFreeform?: Maybe<Scalars['String']>;
  /** Id of the Source Type for this Note */
  sourceTypeId: Scalars['String'];
  /** UUID for the object */
  id: Scalars['String'];
  /** Time the object was created */
  createdAt: Scalars['String'];
  /** Time the object was last updated */
  updatedAt: Scalars['String'];
  /** Id of user that created the object */
  createdById?: Maybe<Scalars['String']>;
  /** Id of user that last updated the object */
  updatedById?: Maybe<Scalars['String']>;
  robotLog?: Maybe<HangarRobotLogType>;
  user?: Maybe<HangarUserType>;
  sourceType?: Maybe<HangarSourceTypeType>;
  createdBy?: Maybe<HangarUserType>;
  updatedBy?: Maybe<HangarUserType>;
};

export type HangarPhoneConnectionTypeConnection = {
  __typename?: 'PhoneConnectionTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: HangarPageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HangarPhoneConnectionTypeEdge>>;
};

/** A Relay edge containing a `PhoneConnectionType` and its cursor. */
export type HangarPhoneConnectionTypeEdge = {
  __typename?: 'PhoneConnectionTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<HangarPhoneType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type HangarPhoneSoftwareConnectionTypeConnection = {
  __typename?: 'PhoneSoftwareConnectionTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: HangarPageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HangarPhoneSoftwareConnectionTypeEdge>>;
};

/** A Relay edge containing a `PhoneSoftwareConnectionType` and its cursor. */
export type HangarPhoneSoftwareConnectionTypeEdge = {
  __typename?: 'PhoneSoftwareConnectionTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<HangarPhoneSoftwareType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum HangarPhoneSoftwareSortEnum {
  VersionAsc = 'version_asc',
  VersionDesc = 'version_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  CreatedAtAsc = 'created_at_asc',
  CreatedAtDesc = 'created_at_desc',
  UpdatedAtAsc = 'updated_at_asc',
  UpdatedAtDesc = 'updated_at_desc'
}

export type HangarPhoneSoftwareType = {
  __typename?: 'PhoneSoftwareType';
  /** SAI software version of the phone. E.g - nova-master-rc1.7.4.0-25-g8e3e64f3.1585 */
  version: Scalars['String'];
  /** UUID for the object */
  id: Scalars['String'];
  /** Time the object was created */
  createdAt: Scalars['String'];
  /** Time the object was last updated */
  updatedAt: Scalars['String'];
};

/** An enumeration. */
export enum HangarPhoneSortEnum {
  NameAsc = 'name_asc',
  NameDesc = 'name_desc',
  SerialNumberAsc = 'serial_number_asc',
  SerialNumberDesc = 'serial_number_desc',
  ModelAsc = 'model_asc',
  ModelDesc = 'model_desc',
  CaseSerialNumberAsc = 'case_serial_number_asc',
  CaseSerialNumberDesc = 'case_serial_number_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  CreatedAtAsc = 'created_at_asc',
  CreatedAtDesc = 'created_at_desc',
  UpdatedAtAsc = 'updated_at_asc',
  UpdatedAtDesc = 'updated_at_desc'
}

export type HangarPhoneType = {
  __typename?: 'PhoneType';
  /** Human readable name of phone: e.g. phone-0001 */
  name?: Maybe<Scalars['String']>;
  /** Unique serial number of the phone */
  serialNumber: Scalars['String'];
  /** Model of phone e.g. Samsung A305 */
  model?: Maybe<Scalars['String']>;
  /** Juggernaught case id */
  caseSerialNumber?: Maybe<Scalars['String']>;
  /** UUID for the object */
  id: Scalars['String'];
  /** Time the object was created */
  createdAt: Scalars['String'];
  /** Time the object was last updated */
  updatedAt: Scalars['String'];
  artifacts?: Maybe<HangarArtifactConnectionTypeConnection>;
};


export type HangarPhoneTypeArtifactsArgs = {
  artifactTypes?: Maybe<Array<Maybe<HangarArtifactType>>>;
  robotLogIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  phoneIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  robotIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  fileNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  storageType?: Maybe<HangarStorageType>;
  artifactType?: Maybe<HangarArtifactType>;
  sort?: Maybe<Array<Maybe<HangarArtifactSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type HangarPipelineExecutionConnectionTypeConnection = {
  __typename?: 'PipelineExecutionConnectionTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: HangarPageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HangarPipelineExecutionConnectionTypeEdge>>;
  total?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `PipelineExecutionConnectionType` and its cursor. */
export type HangarPipelineExecutionConnectionTypeEdge = {
  __typename?: 'PipelineExecutionConnectionTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<HangarPipelineExecutionType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum HangarPipelineExecutionSortEnum {
  NameAsc = 'name_asc',
  NameDesc = 'name_desc',
  DescriptionAsc = 'description_asc',
  DescriptionDesc = 'description_desc',
  DataAsc = 'data_asc',
  DataDesc = 'data_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  CreatedAtAsc = 'created_at_asc',
  CreatedAtDesc = 'created_at_desc',
  CreatedByIdAsc = 'created_by_id_asc',
  CreatedByIdDesc = 'created_by_id_desc'
}

export type HangarPipelineExecutionType = {
  __typename?: 'PipelineExecutionType';
  /** Submitted name of the pipeline execution, or an internal id. Is unique */
  name: Scalars['String'];
  /** Unique description of the pipeline execution */
  description?: Maybe<Scalars['String']>;
  /** JSON object with any metadata about the execution */
  data: Scalars['JSONString'];
  /** UUID for the object */
  id: Scalars['String'];
  /** Time the object was created */
  createdAt: Scalars['String'];
  /** Id of user that created the object */
  createdById?: Maybe<Scalars['String']>;
  createdBy?: Maybe<HangarUserType>;
};

/** An enumeration. */
export enum HangarProduct {
  Nova_1 = 'NOVA_1',
  Nova_2 = 'NOVA_2',
  Skywalker = 'SKYWALKER',
  Unknown = 'UNKNOWN',
  Vbat_118 = 'VBAT_118',
  Vbat_128 = 'VBAT_128',
  Vbat_228 = 'VBAT_228'
}

export type HangarQueries = {
  __typename?: 'Queries';
  omnisearch?: Maybe<HangarOmnisearchType>;
  userRobotLogDetail?: Maybe<HangarUserRobotLogDetailType>;
  userRobotLogDetails?: Maybe<HangarUserRobotLogDetailConnectionTypeConnection>;
  ruleSet?: Maybe<HangarRuleSetType>;
  ruleSets?: Maybe<HangarRuleSetConnectionTypeConnection>;
  participants?: Maybe<HangarParticipantConnectionTypeConnection>;
  participant?: Maybe<HangarParticipantType>;
  measurement?: Maybe<HangarMeasurementType>;
  previewMeasurement?: Maybe<HangarMeasurementType>;
  measurements?: Maybe<HangarMeasurementConnectionTypeConnection>;
  measurementDefinition?: Maybe<HangarMeasurementDefinitionType>;
  measurementDefinitions?: Maybe<Array<Maybe<HangarMeasurementDefinitionType>>>;
  mission?: Maybe<HangarMissionType>;
  missions?: Maybe<HangarMissionConnectionTypeConnection>;
  notes?: Maybe<HangarNoteConnectionTypeConnection>;
  note?: Maybe<HangarNoteType>;
  currentUser?: Maybe<HangarUserType>;
  user?: Maybe<HangarUserType>;
  users?: Maybe<HangarUserConnectionTypeConnection>;
  trait?: Maybe<HangarTraitType>;
  traits?: Maybe<HangarTraitConnectionTypeConnection>;
  ticket?: Maybe<HangarTicketType>;
  tickets?: Maybe<HangarTicketConnectionTypeConnection>;
  team?: Maybe<HangarTeamType>;
  teams?: Maybe<HangarTeamConnectionTypeConnection>;
  /** <graphene.types.scalars.String object at 0x7fd7e02e6ef0> */
  tag?: Maybe<HangarTagType>;
  /** <graphene.types.scalars.String object at 0x7fd7e02e6f98> */
  tags?: Maybe<HangarTagConnectionTypeConnection>;
  sourceTypes?: Maybe<HangarSourceTypeConnectionTypeConnection>;
  sourceType?: Maybe<HangarSourceTypeType>;
  softwareInfo?: Maybe<HangarSoftwareInfoType>;
  softwareInfos?: Maybe<HangarSoftwareInfoConnectionTypeConnection>;
  softwareInfoMeta?: Maybe<HangarSoftwareInfoMeta>;
  severities?: Maybe<HangarSeverityConnectionTypeConnection>;
  severity?: Maybe<HangarSeverityType>;
  dopplerJobs?: Maybe<Array<Maybe<HangarDopplerJobType>>>;
  robots?: Maybe<HangarRobotConnectionTypeConnection>;
  robot?: Maybe<HangarRobotType>;
  robotLogTest?: Maybe<HangarRobotLogTestType>;
  robotLogTests?: Maybe<HangarRobotLogTestConnectionTypeConnection>;
  robotLogs?: Maybe<HangarRobotLogConnectionTypeConnection>;
  robotLog?: Maybe<HangarRobotLogType>;
  documentSearchRobotLogs?: Maybe<HangarDocumentSearchRobotLogConnectionType>;
  robotLogReview?: Maybe<HangarRobotLogReviewType>;
  robotLogReviews?: Maybe<HangarRobotLogReviewConnectionTypeConnection>;
  pipelineExecutions?: Maybe<HangarPipelineExecutionConnectionTypeConnection>;
  pipelineExecution?: Maybe<HangarPipelineExecutionType>;
  phoneSoftware?: Maybe<HangarPhoneSoftwareType>;
  phoneSoftwares?: Maybe<HangarPhoneSoftwareConnectionTypeConnection>;
  phone?: Maybe<HangarPhoneType>;
  phones?: Maybe<HangarPhoneConnectionTypeConnection>;
  organization?: Maybe<HangarOrganizationType>;
  organizations?: Maybe<HangarOrganizationConnectionTypeConnection>;
  metrics?: Maybe<HangarMetricConnectionTypeConnection>;
  metric?: Maybe<HangarMetricType>;
  metricMeta?: Maybe<HangarMetricMeta>;
  logEvent?: Maybe<HangarLogEventRecordType>;
  logEvents?: Maybe<HangarLogEventType>;
  logDefinition?: Maybe<HangarLogDefinitionType>;
  logDefinitions?: Maybe<Array<Maybe<HangarLogDefinitionType>>>;
  label?: Maybe<HangarLabelType>;
  labels?: Maybe<HangarLabelConnectionTypeConnection>;
  events?: Maybe<HangarEventConnectionTypeConnection>;
  event?: Maybe<HangarEventType>;
  environment?: Maybe<HangarEnvironmentType>;
  environments?: Maybe<HangarEnvironmentConnectionTypeConnection>;
  component?: Maybe<HangarComponentType>;
  components?: Maybe<Array<Maybe<HangarComponentType>>>;
  collectionReview?: Maybe<HangarCollectionReviewType>;
  collectionReviews?: Maybe<HangarCollectionReviewConnectionTypeConnection>;
  collection?: Maybe<HangarCollectionType>;
  collections?: Maybe<HangarCollectionConnectionTypeConnection>;
  /** <graphene.types.scalars.String object at 0x7fd7e058db00> */
  causes?: Maybe<HangarCauseConnectionTypeConnection>;
  /** <graphene.types.scalars.String object at 0x7fd7e05182e8> */
  cause?: Maybe<HangarCauseType>;
  biome?: Maybe<HangarBiomeType>;
  biomes?: Maybe<HangarBiomeConnectionTypeConnection>;
  backpackSoftware?: Maybe<HangarBackpackSoftwareType>;
  backpackSoftwares?: Maybe<HangarBackpackSoftwareConnectionTypeConnection>;
  backpack?: Maybe<HangarBackpackType>;
  backpacks?: Maybe<HangarBackpackConnectionTypeConnection>;
  analyses?: Maybe<HangarAnalysisConnectionTypeConnection>;
  analysis?: Maybe<HangarAnalysisType>;
  analysisMeta?: Maybe<HangarAnalysisMeta>;
  artifact?: Maybe<HangarArtifactGqlType>;
  artifacts?: Maybe<HangarArtifactConnectionTypeConnection>;
};


export type HangarQueriesOmnisearchArgs = {
  inputValue?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};


export type HangarQueriesUserRobotLogDetailArgs = {
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  robotLogId?: Maybe<Scalars['String']>;
};


export type HangarQueriesUserRobotLogDetailsArgs = {
  userIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  robotLogIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  robotLogId?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarUserRobotLogDetailSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarQueriesRuleSetArgs = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type HangarQueriesRuleSetsArgs = {
  ids?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  names?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarRuleSetSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarQueriesParticipantsArgs = {
  robotLogIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  userIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  userFreeformLike?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarParticipantSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarQueriesParticipantArgs = {
  id?: Maybe<Scalars['String']>;
};


export type HangarQueriesMeasurementArgs = {
  id?: Maybe<Scalars['String']>;
};


export type HangarQueriesPreviewMeasurementArgs = {
  definitionSpecificValues?: Maybe<Scalars['JSONString']>;
  assessmentCriteria?: Maybe<Scalars['JSONString']>;
  uiData?: Maybe<Scalars['JSONString']>;
  dissection?: Maybe<HangarDissectionEnum>;
  statistic?: Maybe<HangarStatisticEnum>;
  collectionIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  measurementDefinitionId: Scalars['String'];
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
};


export type HangarQueriesMeasurementsArgs = {
  ids?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  collectionIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  measurementDefinitionIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  id?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarMeasurementSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarQueriesMeasurementDefinitionArgs = {
  id: Scalars['String'];
};


export type HangarQueriesMissionArgs = {
  id?: Maybe<Scalars['String']>;
};


export type HangarQueriesMissionsArgs = {
  id?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarMissionSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarQueriesNotesArgs = {
  noteType?: Maybe<HangarNoteTypeChoice>;
  id?: Maybe<Scalars['String']>;
  robotLogId?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarNoteSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarQueriesNoteArgs = {
  id?: Maybe<Scalars['String']>;
  robotLogId?: Maybe<Scalars['String']>;
};


export type HangarQueriesCurrentUserArgs = {
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};


export type HangarQueriesUserArgs = {
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};


export type HangarQueriesUsersArgs = {
  accountTypes?: Maybe<Array<Maybe<HangarUserAccountType>>>;
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarUserSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarQueriesTraitArgs = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type HangarQueriesTraitsArgs = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarTraitSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarQueriesTicketArgs = {
  id?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  platformId?: Maybe<Scalars['String']>;
};


export type HangarQueriesTicketsArgs = {
  platformIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  ids?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  eventTagIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  statusesIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  statusesNotIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  textLike?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  platformId?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarTicketSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarQueriesTeamArgs = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type HangarQueriesTeamsArgs = {
  ids?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  names?: Maybe<Array<Maybe<Scalars['String']>>>;
  userIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarTeamSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarQueriesTagArgs = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  severityId?: Maybe<Scalars['String']>;
};


export type HangarQueriesTagsArgs = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  severityId?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarTagSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarQueriesSourceTypesArgs = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarSourceTypeSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarQueriesSourceTypeArgs = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type HangarQueriesSoftwareInfoArgs = {
  id?: Maybe<Scalars['String']>;
  commitHash?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};


export type HangarQueriesSoftwareInfosArgs = {
  id?: Maybe<Scalars['String']>;
  commitHash?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarSoftwareInfoSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarQueriesSeveritiesArgs = {
  nameLike?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarSeveritySortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarQueriesSeverityArgs = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type HangarQueriesRobotsArgs = {
  generation?: Maybe<Scalars['String']>;
  lastFlightTime_Range?: Maybe<HangarDateTimeRange>;
  id?: Maybe<Scalars['String']>;
  hostname?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarRobotSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarQueriesRobotArgs = {
  id?: Maybe<Scalars['String']>;
  hostname?: Maybe<Scalars['String']>;
};


export type HangarQueriesRobotLogTestArgs = {
  id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};


export type HangarQueriesRobotLogTestsArgs = {
  environmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarRobotLogTestSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarQueriesRobotLogsArgs = {
  names?: Maybe<Array<Maybe<Scalars['String']>>>;
  robotIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  robotProducts?: Maybe<Array<Maybe<HangarProduct>>>;
  environmentIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  tagIdsPresent?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  causeIdsPresent?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  ticketIdsPresent?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  ticketPlatformIdsPresent?: Maybe<Array<Maybe<Scalars['String']>>>;
  involvedUserIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  softwareVersions?: Maybe<Array<Maybe<Scalars['String']>>>;
  softwareBranches?: Maybe<Array<Maybe<Scalars['String']>>>;
  softwareCommitHashes?: Maybe<Array<Maybe<Scalars['String']>>>;
  robotLogTestIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  testDefinitionIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  labelIdsPresent?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  robotLogTypes?: Maybe<Array<Maybe<HangarRobotLogTypeChoice>>>;
  robotLogReviewReviewStatuses?: Maybe<Array<Maybe<HangarReviewStatus>>>;
  robotLogReviewReviewStatusesNotIn?: Maybe<Array<Maybe<HangarReviewStatus>>>;
  robotLogReviewReviewerIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  robotLogReviewReviewerTeamIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  robotLogReviewRequesterIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  parentIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  robotLogIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  phoneIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  phoneSoftwareIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  backpackIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  backpackSoftwareIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  startTimeOfDayRangeIn?: Maybe<Array<Maybe<HangarTimeRange>>>;
  artifactArtifactTypeIn?: Maybe<Array<Maybe<HangarArtifactType>>>;
  logEventComponentOwners?: Maybe<Array<Maybe<Scalars['String']>>>;
  logEventComponentExternalIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  logEventLogDefinitionExternalIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  startTime_Range?: Maybe<HangarDateTimeRange>;
  endTime_Range?: Maybe<HangarDateTimeRange>;
  createdAt_Range?: Maybe<HangarDateTimeRange>;
  totalTime_Range?: Maybe<HangarIntRange>;
  textLike?: Maybe<Scalars['String']>;
  ticketPlatform?: Maybe<Scalars['String']>;
  flightType?: Maybe<Scalars['String']>;
  collectionId?: Maybe<Scalars['UUID']>;
  id?: Maybe<Scalars['String']>;
  originalName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarRobotLogSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarQueriesRobotLogArgs = {
  id?: Maybe<Scalars['String']>;
  originalName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type HangarQueriesDocumentSearchRobotLogsArgs = {
  sort?: Maybe<Scalars['JSONString']>;
  query?: Maybe<Scalars['JSONString']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarQueriesRobotLogReviewArgs = {
  id?: Maybe<Scalars['String']>;
  robotLogId?: Maybe<Scalars['String']>;
  reviewerId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  reviewStatus?: Maybe<HangarReviewStatus>;
};


export type HangarQueriesRobotLogReviewsArgs = {
  reviewStatus?: Maybe<HangarReviewStatus>;
  id?: Maybe<Scalars['String']>;
  robotLogId?: Maybe<Scalars['String']>;
  reviewerId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarRobotLogReviewSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarQueriesPipelineExecutionsArgs = {
  ids?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  names?: Maybe<Array<Maybe<Scalars['String']>>>;
  sort?: Maybe<Array<Maybe<HangarPipelineExecutionSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarQueriesPipelineExecutionArgs = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type HangarQueriesPhoneSoftwareArgs = {
  id?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};


export type HangarQueriesPhoneSoftwaresArgs = {
  id?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarPhoneSoftwareSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarQueriesPhoneArgs = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
};


export type HangarQueriesPhonesArgs = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarPhoneSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarQueriesOrganizationArgs = {
  id?: Maybe<Scalars['String']>;
};


export type HangarQueriesOrganizationsArgs = {
  id?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarOrganizationSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarQueriesMetricsArgs = {
  ids?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  names?: Maybe<Array<Maybe<Scalars['String']>>>;
  robotLogIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  pipelineExecutionIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  pipelineNameAliases?: Maybe<Array<Maybe<Scalars['String']>>>;
  sort?: Maybe<Array<Maybe<HangarMetricSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarQueriesMetricArgs = {
  id?: Maybe<Scalars['String']>;
};


export type HangarQueriesLogEventArgs = {
  id: Scalars['Int'];
};


export type HangarQueriesLogEventsArgs = {
  pageNumber?: Maybe<Scalars['Int']>;
  resultsPerPage?: Maybe<Scalars['Int']>;
  recordCount?: Maybe<Scalars['Int']>;
  sortField?: Maybe<HangarLogEventSortOptionsEnum>;
  sortDirection?: Maybe<HangarSortDirectionEnum>;
  sourceExternalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  componentOwners?: Maybe<Array<Maybe<Scalars['String']>>>;
  componentExternalIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  logDefinitionExternalIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  levels?: Maybe<Array<Maybe<HangarErrorLevel>>>;
};


export type HangarQueriesLogDefinitionArgs = {
  externalId: Scalars['Int'];
};


export type HangarQueriesLabelArgs = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type HangarQueriesLabelsArgs = {
  ids?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  names?: Maybe<Array<Maybe<Scalars['String']>>>;
  robotLogIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  collectionIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  textLike?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarLabelSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarQueriesEventsArgs = {
  startTime_Range?: Maybe<HangarDateTimeRange>;
  endTime_Range?: Maybe<HangarDateTimeRange>;
  sourceTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarEventSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarQueriesEventArgs = {
  id?: Maybe<Scalars['String']>;
};


export type HangarQueriesEnvironmentArgs = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type HangarQueriesEnvironmentsArgs = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarEnvironmentSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarQueriesComponentArgs = {
  externalId: Scalars['Int'];
};


export type HangarQueriesCollectionReviewArgs = {
  id?: Maybe<Scalars['String']>;
  collectionId?: Maybe<Scalars['String']>;
  reviewerId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  reviewStatus?: Maybe<HangarReviewStatus>;
};


export type HangarQueriesCollectionReviewsArgs = {
  collectionIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  reviewStatus?: Maybe<HangarReviewStatus>;
  id?: Maybe<Scalars['String']>;
  collectionId?: Maybe<Scalars['String']>;
  reviewerId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarCollectionReviewSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarQueriesCollectionArgs = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type HangarQueriesCollectionsArgs = {
  ids?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  robotLogIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  createdByIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  names?: Maybe<Array<Maybe<Scalars['String']>>>;
  labelIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  collectionTypes?: Maybe<Array<Maybe<HangarCollectionTypeChoice>>>;
  collectionReviewReviewStatuses?: Maybe<Array<Maybe<HangarReviewStatus>>>;
  collectionReviewReviewStatusesNotIn?: Maybe<Array<Maybe<HangarReviewStatus>>>;
  collectionReviewReviewerIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  collectionReviewReviewerTeamIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  collectionReviewRequesterIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  textLike?: Maybe<Scalars['String']>;
  isMyFavorite?: Maybe<Scalars['Boolean']>;
  isSystemDefault?: Maybe<Scalars['Boolean']>;
  includeAllPrivateForCreator?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarCollectionSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarQueriesCausesArgs = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarCauseSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarQueriesCauseArgs = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type HangarQueriesBiomeArgs = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type HangarQueriesBiomesArgs = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarBiomeSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarQueriesBackpackSoftwareArgs = {
  id?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};


export type HangarQueriesBackpackSoftwaresArgs = {
  id?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarBackpackSoftwareSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarQueriesBackpackArgs = {
  id?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  hostname?: Maybe<Scalars['String']>;
};


export type HangarQueriesBackpacksArgs = {
  id?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  hostname?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarBackpackSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarQueriesAnalysesArgs = {
  analysisIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  names?: Maybe<Array<Maybe<Scalars['String']>>>;
  robotLogIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  pipelineNameAliases?: Maybe<Array<Maybe<Scalars['String']>>>;
  nameLike?: Maybe<Scalars['String']>;
  robotLogId?: Maybe<Scalars['UUID']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarAnalysisSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarQueriesAnalysisArgs = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  robotLogId?: Maybe<Scalars['String']>;
};


export type HangarQueriesArtifactArgs = {
  id?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
};


export type HangarQueriesArtifactsArgs = {
  artifactTypes?: Maybe<Array<Maybe<HangarArtifactType>>>;
  robotLogIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  phoneIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  robotIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  fileNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  storageType?: Maybe<HangarStorageType>;
  artifactType?: Maybe<HangarArtifactType>;
  sort?: Maybe<Array<Maybe<HangarArtifactSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The status of the bag review */
export enum HangarRobot_Log_Review_Review_Status {
  NotStarted = 'NOT_STARTED',
  InProgress = 'IN_PROGRESS',
  Complete = 'COMPLETE',
  Approved = 'APPROVED'
}

/** What type of robot log is it, not just a flight anymore */
export enum HangarRobot_Log_Robot_Log_Type {
  TestInstance = 'TEST_INSTANCE',
  TestTrial = 'TEST_TRIAL',
  Flight = 'FLIGHT',
  BootSession = 'BOOT_SESSION',
  TeamFlight = 'TEAM_FLIGHT'
}

/** Product name of the robot (i.e. pikachu, domo) */
export enum HangarRobot_Product {
  Nova_1 = 'NOVA_1',
  Nova_2 = 'NOVA_2',
  Skywalker = 'SKYWALKER',
  Unknown = 'UNKNOWN',
  Vbat_118 = 'VBAT_118',
  Vbat_128 = 'VBAT_128',
  Vbat_228 = 'VBAT_228'
}

/** Describes the version of robot_log_search_variables, which informs the backend on how to resolve the object for searching */
export enum HangarRule_Set_Search_Version {
  RobotLogsV1 = 'ROBOT_LOGS_V1',
  DocumentSearchV1 = 'DOCUMENT_SEARCH_V1'
}

/** An enumeration. */
export enum HangarReviewStatus {
  NotStarted = 'NOT_STARTED',
  InProgress = 'IN_PROGRESS',
  Complete = 'COMPLETE',
  Approved = 'APPROVED'
}

export type HangarRobotConnectionTypeConnection = {
  __typename?: 'RobotConnectionTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: HangarPageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HangarRobotConnectionTypeEdge>>;
  total?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `RobotConnectionType` and its cursor. */
export type HangarRobotConnectionTypeEdge = {
  __typename?: 'RobotConnectionTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<HangarRobotType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type HangarRobotLogConnectionTypeConnection = {
  __typename?: 'RobotLogConnectionTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: HangarPageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HangarRobotLogConnectionTypeEdge>>;
  total?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `RobotLogConnectionType` and its cursor. */
export type HangarRobotLogConnectionTypeEdge = {
  __typename?: 'RobotLogConnectionTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<HangarRobotLogType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type HangarRobotLogReviewConnectionTypeConnection = {
  __typename?: 'RobotLogReviewConnectionTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: HangarPageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HangarRobotLogReviewConnectionTypeEdge>>;
  total?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `RobotLogReviewConnectionType` and its cursor. */
export type HangarRobotLogReviewConnectionTypeEdge = {
  __typename?: 'RobotLogReviewConnectionTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<HangarRobotLogReviewType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum HangarRobotLogReviewSortEnum {
  RobotLogIdAsc = 'robot_log_id_asc',
  RobotLogIdDesc = 'robot_log_id_desc',
  RequesterIdAsc = 'requester_id_asc',
  RequesterIdDesc = 'requester_id_desc',
  ReviewerIdAsc = 'reviewer_id_asc',
  ReviewerIdDesc = 'reviewer_id_desc',
  ReviewerTeamIdAsc = 'reviewer_team_id_asc',
  ReviewerTeamIdDesc = 'reviewer_team_id_desc',
  ReviewStatusAsc = 'review_status_asc',
  ReviewStatusDesc = 'review_status_desc',
  ReviewedTimeAsc = 'reviewed_time_asc',
  ReviewedTimeDesc = 'reviewed_time_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  CreatedAtAsc = 'created_at_asc',
  CreatedAtDesc = 'created_at_desc',
  UpdatedAtAsc = 'updated_at_asc',
  UpdatedAtDesc = 'updated_at_desc',
  CreatedByIdAsc = 'created_by_id_asc',
  CreatedByIdDesc = 'created_by_id_desc',
  UpdatedByIdAsc = 'updated_by_id_asc',
  UpdatedByIdDesc = 'updated_by_id_desc'
}

export type HangarRobotLogReviewType = {
  __typename?: 'RobotLogReviewType';
  /** Id of Robot Log this Event occured in */
  robotLogId: Scalars['String'];
  /** Id of User that added the review request */
  requesterId: Scalars['String'];
  /** Id of User that will review the request */
  reviewerId?: Maybe<Scalars['String']>;
  /** Id of User that will review the request */
  reviewerTeamId?: Maybe<Scalars['String']>;
  reviewStatus?: Maybe<HangarRobot_Log_Review_Review_Status>;
  /** Time the the review was set to complete. */
  reviewedTime?: Maybe<Scalars['String']>;
  /** UUID for the object */
  id: Scalars['String'];
  /** Time the object was created */
  createdAt: Scalars['String'];
  /** Time the object was last updated */
  updatedAt: Scalars['String'];
  /** Id of user that created the object */
  createdById?: Maybe<Scalars['String']>;
  /** Id of user that last updated the object */
  updatedById?: Maybe<Scalars['String']>;
  robotLog?: Maybe<HangarRobotLogType>;
  requester?: Maybe<HangarUserType>;
  reviewer?: Maybe<HangarUserType>;
  reviewerTeam?: Maybe<HangarTeamType>;
  createdBy?: Maybe<HangarUserType>;
  updatedBy?: Maybe<HangarUserType>;
};

/** An enumeration. */
export enum HangarRobotLogSortEnum {
  LatitudeAsc = 'latitude_asc',
  LatitudeDesc = 'latitude_desc',
  LongitudeAsc = 'longitude_asc',
  LongitudeDesc = 'longitude_desc',
  NameAsc = 'name_asc',
  NameDesc = 'name_desc',
  EndTimeAsc = 'end_time_asc',
  EndTimeDesc = 'end_time_desc',
  RobotIdAsc = 'robot_id_asc',
  RobotIdDesc = 'robot_id_desc',
  RobotLogTestIdAsc = 'robot_log_test_id_asc',
  RobotLogTestIdDesc = 'robot_log_test_id_desc',
  SoftwareInfoIdAsc = 'software_info_id_asc',
  SoftwareInfoIdDesc = 'software_info_id_desc',
  EnvironmentIdAsc = 'environment_id_asc',
  EnvironmentIdDesc = 'environment_id_desc',
  RobotLogDataAsc = 'robot_log_data_asc',
  RobotLogDataDesc = 'robot_log_data_desc',
  PhoneIdAsc = 'phone_id_asc',
  PhoneIdDesc = 'phone_id_desc',
  PhoneSoftwareIdAsc = 'phone_software_id_asc',
  PhoneSoftwareIdDesc = 'phone_software_id_desc',
  BackpackIdAsc = 'backpack_id_asc',
  BackpackIdDesc = 'backpack_id_desc',
  BackpackSoftwareIdAsc = 'backpack_software_id_asc',
  BackpackSoftwareIdDesc = 'backpack_software_id_desc',
  TestDefinitionIdAsc = 'test_definition_id_asc',
  TestDefinitionIdDesc = 'test_definition_id_desc',
  RobotLogTypeAsc = 'robot_log_type_asc',
  RobotLogTypeDesc = 'robot_log_type_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  ParentIdAsc = 'parent_id_asc',
  ParentIdDesc = 'parent_id_desc',
  CreatedAtAsc = 'created_at_asc',
  CreatedAtDesc = 'created_at_desc',
  UpdatedAtAsc = 'updated_at_asc',
  UpdatedAtDesc = 'updated_at_desc',
  CreatedByIdAsc = 'created_by_id_asc',
  CreatedByIdDesc = 'created_by_id_desc',
  UpdatedByIdAsc = 'updated_by_id_asc',
  UpdatedByIdDesc = 'updated_by_id_desc',
  EnvironmentNameAsc = 'environment_name_asc',
  EnvironmentNameDesc = 'environment_name_desc',
  RobotLogTestCodeAsc = 'robot_log_test_code_asc',
  RobotLogTestCodeDesc = 'robot_log_test_code_desc',
  RobotNameAsc = 'robot_name_asc',
  RobotNameDesc = 'robot_name_desc',
  RobotHostnameAsc = 'robot_hostname_asc',
  RobotHostnameDesc = 'robot_hostname_desc',
  RobotProductsAsc = 'robot_products_asc',
  RobotProductsDesc = 'robot_products_desc',
  TotalTimeAsc = 'total_time_asc',
  TotalTimeDesc = 'total_time_desc',
  MyUserRobotLogDetailViewedLastAtAsc = 'my_user_robot_log_detail_viewed_last_at_asc',
  MyUserRobotLogDetailViewedLastAtDesc = 'my_user_robot_log_detail_viewed_last_at_desc',
  StartTimeAsc = 'start_time_asc',
  StartTimeDesc = 'start_time_desc'
}

export type HangarRobotLogTestConnectionTypeConnection = {
  __typename?: 'RobotLogTestConnectionTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: HangarPageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HangarRobotLogTestConnectionTypeEdge>>;
};

/** A Relay edge containing a `RobotLogTestConnectionType` and its cursor. */
export type HangarRobotLogTestConnectionTypeEdge = {
  __typename?: 'RobotLogTestConnectionTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<HangarRobotLogTestType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum HangarRobotLogTestSortEnum {
  NameAsc = 'name_asc',
  NameDesc = 'name_desc',
  DescriptionAsc = 'description_asc',
  DescriptionDesc = 'description_desc',
  CodeAsc = 'code_asc',
  CodeDesc = 'code_desc',
  TestDataAsc = 'test_data_asc',
  TestDataDesc = 'test_data_desc',
  QualityGateAsc = 'quality_gate_asc',
  QualityGateDesc = 'quality_gate_desc',
  EnvironmentIdAsc = 'environment_id_asc',
  EnvironmentIdDesc = 'environment_id_desc',
  IntendedMissionIdAsc = 'intended_mission_id_asc',
  IntendedMissionIdDesc = 'intended_mission_id_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  CreatedAtAsc = 'created_at_asc',
  CreatedAtDesc = 'created_at_desc',
  UpdatedAtAsc = 'updated_at_asc',
  UpdatedAtDesc = 'updated_at_desc'
}

export type HangarRobotLogTestType = {
  __typename?: 'RobotLogTestType';
  /** Short name of the test */
  name: Scalars['String'];
  /** What this test does */
  description?: Maybe<Scalars['String']>;
  /** Short form identifier for this test */
  code: Scalars['String'];
  /** Unstructured data containing misc event information */
  testData?: Maybe<Scalars['JSONString']>;
  /** Quality gate for verification testing */
  qualityGate?: Maybe<Scalars['String']>;
  /** Id of Environment this test is conducted in */
  environmentId?: Maybe<Scalars['String']>;
  /** Id of the intended mission for this robot log test */
  intendedMissionId?: Maybe<Scalars['String']>;
  /** UUID for the object */
  id: Scalars['String'];
  /** Time the object was created */
  createdAt: Scalars['String'];
  /** Time the object was last updated */
  updatedAt: Scalars['String'];
  traits?: Maybe<Array<Maybe<HangarTraitType>>>;
  robotLogs?: Maybe<Array<Maybe<HangarRobotLogType>>>;
  environment?: Maybe<HangarEnvironmentType>;
  intendedMission?: Maybe<HangarMissionType>;
};

export type HangarRobotLogType = {
  __typename?: 'RobotLogType';
  /** Latitude, must be between -90 and 90 */
  latitude?: Maybe<Scalars['Float']>;
  /** Longitude, must be between -180 and 180 */
  longitude?: Maybe<Scalars['Float']>;
  /** Name of log */
  name: Scalars['String'];
  /** Start date time of log */
  startTime?: Maybe<Scalars['String']>;
  /** End date time of log */
  endTime?: Maybe<Scalars['String']>;
  /** Id of Robot that flew this log */
  robotId?: Maybe<Scalars['String']>;
  /** Id of Robot Log Test done on this Robot Log */
  robotLogTestId?: Maybe<Scalars['String']>;
  /** Id of Software information for this Robot Log */
  softwareInfoId?: Maybe<Scalars['String']>;
  /** Id of Environment this robot log flies in */
  environmentId?: Maybe<Scalars['String']>;
  /** Semi structured data containing misc robot log information */
  robotLogData?: Maybe<Scalars['JSONString']>;
  /** Id of the phone used to fly the robot */
  phoneId?: Maybe<Scalars['String']>;
  /** Id of phone software used to fly the robot */
  phoneSoftwareId?: Maybe<Scalars['String']>;
  /** Id of the backpack used to fly the robot */
  backpackId?: Maybe<Scalars['String']>;
  /** Id of backpack software used to fly the robot */
  backpackSoftwareId?: Maybe<Scalars['String']>;
  /** Id of the test definition */
  testDefinitionId?: Maybe<Scalars['String']>;
  robotLogType?: Maybe<HangarRobot_Log_Robot_Log_Type>;
  /** UUID for the object */
  id: Scalars['String'];
  /** Deprecated: Use parents */
  parentId?: Maybe<Scalars['String']>;
  /** Time the object was created */
  createdAt: Scalars['String'];
  /** Time the object was last updated */
  updatedAt: Scalars['String'];
  /** Id of user that created the object */
  createdById?: Maybe<Scalars['String']>;
  /** Id of user that last updated the object */
  updatedById?: Maybe<Scalars['String']>;
  totalTime?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  originalName?: Maybe<Scalars['String']>;
  mgrs?: Maybe<Scalars['String']>;
  robot?: Maybe<HangarRobotType>;
  robotLogTest?: Maybe<HangarRobotLogTestType>;
  softwareInfo?: Maybe<HangarSoftwareInfoType>;
  environment?: Maybe<HangarEnvironmentType>;
  events?: Maybe<HangarEventConnectionTypeConnection>;
  participants?: Maybe<HangarParticipantConnectionTypeConnection>;
  artifacts?: Maybe<HangarArtifactConnectionTypeConnection>;
  analyses?: Maybe<HangarAnalysisConnectionTypeConnection>;
  notes?: Maybe<HangarNoteConnectionTypeConnection>;
  robotLogReviews?: Maybe<HangarRobotLogReviewConnectionTypeConnection>;
  labels?: Maybe<HangarLabelConnectionTypeConnection>;
  collections?: Maybe<Array<Maybe<HangarCollectionType>>>;
  includedInCollections?: Maybe<Array<Maybe<HangarCollectionType>>>;
  excludedFromCollections?: Maybe<Array<Maybe<HangarCollectionType>>>;
  userRobotLogDetails?: Maybe<HangarUserRobotLogDetailConnectionTypeConnection>;
  phone?: Maybe<HangarPhoneType>;
  phoneSoftware?: Maybe<HangarPhoneSoftwareType>;
  backpack?: Maybe<HangarBackpackType>;
  backpackSoftware?: Maybe<HangarBackpackSoftwareType>;
  parents?: Maybe<Array<Maybe<HangarRobotLogType>>>;
  children?: Maybe<Array<Maybe<HangarRobotLogType>>>;
  createdBy?: Maybe<HangarUserType>;
  updatedBy?: Maybe<HangarUserType>;
  myUserRobotLogDetail?: Maybe<HangarUserRobotLogDetailType>;
  metrics?: Maybe<HangarMetricConnectionTypeConnection>;
  childrenCount?: Maybe<Scalars['Int']>;
  /** Deprecated: Use parents */
  parent?: Maybe<HangarRobotLogType>;
  logEvents?: Maybe<HangarLogEventType>;
};


export type HangarRobotLogTypeEventsArgs = {
  startTime_Range?: Maybe<HangarDateTimeRange>;
  endTime_Range?: Maybe<HangarDateTimeRange>;
  sourceTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarEventSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarRobotLogTypeParticipantsArgs = {
  robotLogIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  userIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  userFreeformLike?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarParticipantSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarRobotLogTypeArtifactsArgs = {
  artifactTypes?: Maybe<Array<Maybe<HangarArtifactType>>>;
  robotLogIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  phoneIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  robotIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  fileNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  storageType?: Maybe<HangarStorageType>;
  artifactType?: Maybe<HangarArtifactType>;
  sort?: Maybe<Array<Maybe<HangarArtifactSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarRobotLogTypeAnalysesArgs = {
  analysisIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  names?: Maybe<Array<Maybe<Scalars['String']>>>;
  robotLogIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  pipelineNameAliases?: Maybe<Array<Maybe<Scalars['String']>>>;
  nameLike?: Maybe<Scalars['String']>;
  robotLogId?: Maybe<Scalars['UUID']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarAnalysisSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarRobotLogTypeNotesArgs = {
  noteType?: Maybe<HangarNoteTypeChoice>;
  id?: Maybe<Scalars['String']>;
  robotLogId?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarNoteSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarRobotLogTypeRobotLogReviewsArgs = {
  reviewStatus?: Maybe<HangarReviewStatus>;
  id?: Maybe<Scalars['String']>;
  robotLogId?: Maybe<Scalars['String']>;
  reviewerId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarRobotLogReviewSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarRobotLogTypeLabelsArgs = {
  ids?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  names?: Maybe<Array<Maybe<Scalars['String']>>>;
  robotLogIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  collectionIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  textLike?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarLabelSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarRobotLogTypeUserRobotLogDetailsArgs = {
  userIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  robotLogIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  robotLogId?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarUserRobotLogDetailSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarRobotLogTypeMyUserRobotLogDetailArgs = {
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  robotLogId?: Maybe<Scalars['String']>;
};


export type HangarRobotLogTypeMetricsArgs = {
  ids?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  names?: Maybe<Array<Maybe<Scalars['String']>>>;
  robotLogIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  pipelineExecutionIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  pipelineNameAliases?: Maybe<Array<Maybe<Scalars['String']>>>;
  sort?: Maybe<Array<Maybe<HangarMetricSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarRobotLogTypeLogEventsArgs = {
  pageNumber?: Maybe<Scalars['Int']>;
  resultsPerPage?: Maybe<Scalars['Int']>;
  recordCount?: Maybe<Scalars['Int']>;
  sortField?: Maybe<HangarLogEventSortOptionsEnum>;
  sortDirection?: Maybe<HangarSortDirectionEnum>;
  sourceExternalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  componentOwners?: Maybe<Array<Maybe<Scalars['String']>>>;
  componentExternalIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  logDefinitionExternalIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  levels?: Maybe<Array<Maybe<HangarErrorLevel>>>;
};

/** An enumeration. */
export enum HangarRobotLogTypeChoice {
  TestInstance = 'TEST_INSTANCE',
  TestTrial = 'TEST_TRIAL',
  Flight = 'FLIGHT',
  BootSession = 'BOOT_SESSION',
  TeamFlight = 'TEAM_FLIGHT'
}

/** An enumeration. */
export enum HangarRobotSortEnum {
  NameAsc = 'name_asc',
  NameDesc = 'name_desc',
  GenerationAsc = 'generation_asc',
  GenerationDesc = 'generation_desc',
  ProductAsc = 'product_asc',
  ProductDesc = 'product_desc',
  HostnameAsc = 'hostname_asc',
  HostnameDesc = 'hostname_desc',
  SoftwareInfoIdAsc = 'software_info_id_asc',
  SoftwareInfoIdDesc = 'software_info_id_desc',
  OrganizationIdAsc = 'organization_id_asc',
  OrganizationIdDesc = 'organization_id_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  CreatedAtAsc = 'created_at_asc',
  CreatedAtDesc = 'created_at_desc',
  UpdatedAtAsc = 'updated_at_asc',
  UpdatedAtDesc = 'updated_at_desc'
}

export type HangarRobotType = {
  __typename?: 'RobotType';
  /** Name of robot */
  name: Scalars['String'];
  /** Hardware generation of the robot (i.e. 16) */
  generation: Scalars['String'];
  product?: Maybe<HangarRobot_Product>;
  /** Hostname of the robot */
  hostname: Scalars['String'];
  /** Id of Software information for this robot */
  softwareInfoId?: Maybe<Scalars['String']>;
  /** Id of Organization this robot belongs to */
  organizationId?: Maybe<Scalars['String']>;
  /** UUID for the object */
  id: Scalars['String'];
  /** Time the object was created */
  createdAt: Scalars['String'];
  /** Time the object was last updated */
  updatedAt: Scalars['String'];
  totalFlightTime?: Maybe<Scalars['String']>;
  lastFlightTime?: Maybe<Scalars['String']>;
  robotLogs?: Maybe<HangarRobotLogConnectionTypeConnection>;
  softwareInfo?: Maybe<HangarSoftwareInfoType>;
  organization?: Maybe<HangarOrganizationType>;
  artifacts?: Maybe<HangarArtifactConnectionTypeConnection>;
};


export type HangarRobotTypeRobotLogsArgs = {
  names?: Maybe<Array<Maybe<Scalars['String']>>>;
  robotIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  robotProducts?: Maybe<Array<Maybe<HangarProduct>>>;
  environmentIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  tagIdsPresent?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  causeIdsPresent?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  ticketIdsPresent?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  ticketPlatformIdsPresent?: Maybe<Array<Maybe<Scalars['String']>>>;
  involvedUserIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  softwareVersions?: Maybe<Array<Maybe<Scalars['String']>>>;
  softwareBranches?: Maybe<Array<Maybe<Scalars['String']>>>;
  softwareCommitHashes?: Maybe<Array<Maybe<Scalars['String']>>>;
  robotLogTestIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  testDefinitionIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  labelIdsPresent?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  robotLogTypes?: Maybe<Array<Maybe<HangarRobotLogTypeChoice>>>;
  robotLogReviewReviewStatuses?: Maybe<Array<Maybe<HangarReviewStatus>>>;
  robotLogReviewReviewStatusesNotIn?: Maybe<Array<Maybe<HangarReviewStatus>>>;
  robotLogReviewReviewerIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  robotLogReviewReviewerTeamIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  robotLogReviewRequesterIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  parentIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  robotLogIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  phoneIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  phoneSoftwareIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  backpackIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  backpackSoftwareIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  startTimeOfDayRangeIn?: Maybe<Array<Maybe<HangarTimeRange>>>;
  artifactArtifactTypeIn?: Maybe<Array<Maybe<HangarArtifactType>>>;
  logEventComponentOwners?: Maybe<Array<Maybe<Scalars['String']>>>;
  logEventComponentExternalIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  logEventLogDefinitionExternalIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  startTime_Range?: Maybe<HangarDateTimeRange>;
  endTime_Range?: Maybe<HangarDateTimeRange>;
  createdAt_Range?: Maybe<HangarDateTimeRange>;
  totalTime_Range?: Maybe<HangarIntRange>;
  textLike?: Maybe<Scalars['String']>;
  ticketPlatform?: Maybe<Scalars['String']>;
  flightType?: Maybe<Scalars['String']>;
  collectionId?: Maybe<Scalars['UUID']>;
  id?: Maybe<Scalars['String']>;
  originalName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarRobotLogSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type HangarRobotTypeArtifactsArgs = {
  artifactTypes?: Maybe<Array<Maybe<HangarArtifactType>>>;
  robotLogIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  phoneIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  robotIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  fileNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  storageType?: Maybe<HangarStorageType>;
  artifactType?: Maybe<HangarArtifactType>;
  sort?: Maybe<Array<Maybe<HangarArtifactSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type HangarRuleSetConnectionTypeConnection = {
  __typename?: 'RuleSetConnectionTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: HangarPageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HangarRuleSetConnectionTypeEdge>>;
};

/** A Relay edge containing a `RuleSetConnectionType` and its cursor. */
export type HangarRuleSetConnectionTypeEdge = {
  __typename?: 'RuleSetConnectionTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<HangarRuleSetType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum HangarRuleSetSearchVersion {
  RobotLogsV1 = 'ROBOT_LOGS_V1',
  DocumentSearchV1 = 'DOCUMENT_SEARCH_V1'
}

/** An enumeration. */
export enum HangarRuleSetSortEnum {
  NameAsc = 'name_asc',
  NameDesc = 'name_desc',
  RobotLogSearchVariablesAsc = 'robot_log_search_variables_asc',
  RobotLogSearchVariablesDesc = 'robot_log_search_variables_desc',
  SearchVersionAsc = 'search_version_asc',
  SearchVersionDesc = 'search_version_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  CreatedAtAsc = 'created_at_asc',
  CreatedAtDesc = 'created_at_desc',
  UpdatedAtAsc = 'updated_at_asc',
  UpdatedAtDesc = 'updated_at_desc',
  CreatedByIdAsc = 'created_by_id_asc',
  CreatedByIdDesc = 'created_by_id_desc',
  UpdatedByIdAsc = 'updated_by_id_asc',
  UpdatedByIdDesc = 'updated_by_id_desc'
}

export type HangarRuleSetType = {
  __typename?: 'RuleSetType';
  /** Optional name for rule sets, might be useful for discovery or sharing */
  name?: Maybe<Scalars['String']>;
  /** Object structure of the filters needed to query robot logs */
  robotLogSearchVariables: Scalars['JSONString'];
  searchVersion?: Maybe<HangarRule_Set_Search_Version>;
  /** UUID for the object */
  id: Scalars['String'];
  /** Time the object was created */
  createdAt: Scalars['String'];
  /** Time the object was last updated */
  updatedAt: Scalars['String'];
  /** Id of user that created the object */
  createdById?: Maybe<Scalars['String']>;
  /** Id of user that last updated the object */
  updatedById?: Maybe<Scalars['String']>;
  collections?: Maybe<Array<Maybe<HangarCollectionType>>>;
  createdBy?: Maybe<HangarUserType>;
  updatedBy?: Maybe<HangarUserType>;
};

export type HangarRunDopplerJob = {
  __typename?: 'RunDopplerJob';
  allRobotLogNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  requestId?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum HangarRunStatusChoice {
  Pending = 'PENDING',
  Running = 'RUNNING',
  Done = 'DONE',
  PendingRetry = 'PENDING_RETRY'
}

export type HangarSeverityConnectionTypeConnection = {
  __typename?: 'SeverityConnectionTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: HangarPageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HangarSeverityConnectionTypeEdge>>;
};

/** A Relay edge containing a `SeverityConnectionType` and its cursor. */
export type HangarSeverityConnectionTypeEdge = {
  __typename?: 'SeverityConnectionTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<HangarSeverityType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum HangarSeveritySortEnum {
  NameAsc = 'name_asc',
  NameDesc = 'name_desc',
  DescriptionAsc = 'description_asc',
  DescriptionDesc = 'description_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  CreatedAtAsc = 'created_at_asc',
  CreatedAtDesc = 'created_at_desc',
  UpdatedAtAsc = 'updated_at_asc',
  UpdatedAtDesc = 'updated_at_desc'
}

export type HangarSeverityType = {
  __typename?: 'SeverityType';
  /** Name of Severity. Ex: Minor, Major, Critical */
  name: Scalars['String'];
  /** description of what this tag means */
  description: Scalars['String'];
  /** UUID for the object */
  id: Scalars['String'];
  /** Time the object was created */
  createdAt: Scalars['String'];
  /** Time the object was last updated */
  updatedAt: Scalars['String'];
};

export type HangarSoftwareInfoConnectionTypeConnection = {
  __typename?: 'SoftwareInfoConnectionTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: HangarPageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HangarSoftwareInfoConnectionTypeEdge>>;
};

/** A Relay edge containing a `SoftwareInfoConnectionType` and its cursor. */
export type HangarSoftwareInfoConnectionTypeEdge = {
  __typename?: 'SoftwareInfoConnectionTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<HangarSoftwareInfoType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type HangarSoftwareInfoMeta = {
  __typename?: 'SoftwareInfoMeta';
  branchNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  versions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** An enumeration. */
export enum HangarSoftwareInfoSortEnum {
  RepoUrlAsc = 'repo_url_asc',
  RepoUrlDesc = 'repo_url_desc',
  CommitHashAsc = 'commit_hash_asc',
  CommitHashDesc = 'commit_hash_desc',
  BranchNameAsc = 'branch_name_asc',
  BranchNameDesc = 'branch_name_desc',
  VersionAsc = 'version_asc',
  VersionDesc = 'version_desc',
  CommittedAtAsc = 'committed_at_asc',
  CommittedAtDesc = 'committed_at_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  CreatedAtAsc = 'created_at_asc',
  CreatedAtDesc = 'created_at_desc',
  UpdatedAtAsc = 'updated_at_asc',
  UpdatedAtDesc = 'updated_at_desc'
}

export type HangarSoftwareInfoType = {
  __typename?: 'SoftwareInfoType';
  /** URL to Gitlab repo for this code */
  repoUrl: Scalars['String'];
  /** Hash of commit this software corresponds to */
  commitHash?: Maybe<Scalars['String']>;
  /** Hash of branch name this software corresponds to */
  branchName?: Maybe<Scalars['String']>;
  /** Version tag this software corresponds to */
  version?: Maybe<Scalars['String']>;
  /** The creation time of the commit */
  committedAt?: Maybe<Scalars['String']>;
  /** UUID for the object */
  id: Scalars['String'];
  /** Time the object was created */
  createdAt: Scalars['String'];
  /** Time the object was last updated */
  updatedAt: Scalars['String'];
  robotLogs?: Maybe<Array<Maybe<HangarRobotLogType>>>;
  robots?: Maybe<Array<Maybe<HangarRobotType>>>;
};

/** An enumeration. */
export enum HangarSortDirectionEnum {
  SortDirectionUnspecified = 'SORT_DIRECTION_UNSPECIFIED',
  Asc = 'ASC',
  Desc = 'DESC'
}

export type HangarSourceTypeConnectionTypeConnection = {
  __typename?: 'SourceTypeConnectionTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: HangarPageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HangarSourceTypeConnectionTypeEdge>>;
};

/** A Relay edge containing a `SourceTypeConnectionType` and its cursor. */
export type HangarSourceTypeConnectionTypeEdge = {
  __typename?: 'SourceTypeConnectionTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<HangarSourceTypeType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum HangarSourceTypeSortEnum {
  NameAsc = 'name_asc',
  NameDesc = 'name_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc'
}

export type HangarSourceTypeType = {
  __typename?: 'SourceTypeType';
  /** A designation used to how, where, or when the event was recorded. Ex: POST_REVIEW, IN_PERSON, etc */
  name: Scalars['String'];
  /** UUID for the object */
  id: Scalars['String'];
};

/** An enumeration. */
export enum HangarStatisticEnum {
  CountTotal = 'COUNT_TOTAL',
  CountAvg = 'COUNT_AVG',
  CountMin = 'COUNT_MIN',
  CountMax = 'COUNT_MAX',
  ValueSum = 'VALUE_SUM',
  ValueAvg = 'VALUE_AVG',
  ValueMin = 'VALUE_MIN',
  ValueMax = 'VALUE_MAX',
  ValueMedian = 'VALUE_MEDIAN',
  OccurrenceTotal = 'OCCURRENCE_TOTAL',
  OccurrencePercent = 'OCCURRENCE_PERCENT',
  OccurrenceTotalAvg = 'OCCURRENCE_TOTAL_AVG',
  OccurrencePercentAvg = 'OCCURRENCE_PERCENT_AVG',
  CountCumsum = 'COUNT_CUMSUM',
  CountCumavg = 'COUNT_CUMAVG',
  CountCummin = 'COUNT_CUMMIN',
  CountCummax = 'COUNT_CUMMAX',
  ValueCumsum = 'VALUE_CUMSUM',
  ValueCumavg = 'VALUE_CUMAVG',
  ValueCummin = 'VALUE_CUMMIN',
  ValueCummax = 'VALUE_CUMMAX'
}

/** An enumeration. */
export enum HangarStorageType {
  Nas = 'NAS',
  Gs = 'GS',
  S3 = 'S3'
}

export type HangarTagConnectionTypeConnection = {
  __typename?: 'TagConnectionTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: HangarPageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HangarTagConnectionTypeEdge>>;
};

/** A Relay edge containing a `TagConnectionType` and its cursor. */
export type HangarTagConnectionTypeEdge = {
  __typename?: 'TagConnectionTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<HangarTagType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum HangarTagSortEnum {
  NameAsc = 'name_asc',
  NameDesc = 'name_desc',
  DescriptionAsc = 'description_asc',
  DescriptionDesc = 'description_desc',
  SeverityIdAsc = 'severity_id_asc',
  SeverityIdDesc = 'severity_id_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  CreatedAtAsc = 'created_at_asc',
  CreatedAtDesc = 'created_at_desc',
  UpdatedAtAsc = 'updated_at_asc',
  UpdatedAtDesc = 'updated_at_desc'
}

export type HangarTagType = {
  __typename?: 'TagType';
  /** Name of tag. Ex: 'Twitchy', 'Crashed' */
  name: Scalars['String'];
  /** description of what this tag means */
  description: Scalars['String'];
  /** Id of Severity of Tag. If null, tag is NOT a failure */
  severityId?: Maybe<Scalars['String']>;
  /** UUID for the object */
  id: Scalars['String'];
  /** Time the object was created */
  createdAt: Scalars['String'];
  /** Time the object was last updated */
  updatedAt: Scalars['String'];
  severity?: Maybe<HangarSeverityType>;
  events?: Maybe<Array<Maybe<HangarEventType>>>;
};

export type HangarTeamConnectionTypeConnection = {
  __typename?: 'TeamConnectionTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: HangarPageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HangarTeamConnectionTypeEdge>>;
  total?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `TeamConnectionType` and its cursor. */
export type HangarTeamConnectionTypeEdge = {
  __typename?: 'TeamConnectionTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<HangarTeamType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum HangarTeamSortEnum {
  NameAsc = 'name_asc',
  NameDesc = 'name_desc',
  DescriptionAsc = 'description_asc',
  DescriptionDesc = 'description_desc',
  PictureAsc = 'picture_asc',
  PictureDesc = 'picture_desc',
  ExternalSourceDataAsc = 'external_source_data_asc',
  ExternalSourceDataDesc = 'external_source_data_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  CreatedAtAsc = 'created_at_asc',
  CreatedAtDesc = 'created_at_desc',
  UpdatedAtAsc = 'updated_at_asc',
  UpdatedAtDesc = 'updated_at_desc',
  CreatedByIdAsc = 'created_by_id_asc',
  CreatedByIdDesc = 'created_by_id_desc',
  UpdatedByIdAsc = 'updated_by_id_asc',
  UpdatedByIdDesc = 'updated_by_id_desc'
}

export type HangarTeamType = {
  __typename?: 'TeamType';
  /** AI Team 1 */
  name: Scalars['String'];
  /** Does the AI work */
  description: Scalars['String'];
  /** The teams image url */
  picture?: Maybe<Scalars['String']>;
  /** UUID for the object */
  id: Scalars['String'];
  /** Time the object was created */
  createdAt: Scalars['String'];
  /** Time the object was last updated */
  updatedAt: Scalars['String'];
  /** Id of user that created the object */
  createdById?: Maybe<Scalars['String']>;
  /** Id of user that last updated the object */
  updatedById?: Maybe<Scalars['String']>;
  users?: Maybe<Array<Maybe<HangarUserType>>>;
  createdBy?: Maybe<HangarUserType>;
  updatedBy?: Maybe<HangarUserType>;
  externalSourceName?: Maybe<Scalars['String']>;
  externalSourceUrl?: Maybe<Scalars['String']>;
};

export type HangarTicketConnectionTypeConnection = {
  __typename?: 'TicketConnectionTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: HangarPageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HangarTicketConnectionTypeEdge>>;
  total?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `TicketConnectionType` and its cursor. */
export type HangarTicketConnectionTypeEdge = {
  __typename?: 'TicketConnectionTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<HangarTicketType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum HangarTicketSortEnum {
  LinkAsc = 'link_asc',
  LinkDesc = 'link_desc',
  NameAsc = 'name_asc',
  NameDesc = 'name_desc',
  StatusAsc = 'status_asc',
  StatusDesc = 'status_desc',
  PlatformAsc = 'platform_asc',
  PlatformDesc = 'platform_desc',
  PlatformIdAsc = 'platform_id_asc',
  PlatformIdDesc = 'platform_id_desc',
  PlatformCreatedAtAsc = 'platform_created_at_asc',
  PlatformCreatedAtDesc = 'platform_created_at_desc',
  PlatformSeverityAsc = 'platform_severity_asc',
  PlatformSeverityDesc = 'platform_severity_desc',
  TicketDataAsc = 'ticket_data_asc',
  TicketDataDesc = 'ticket_data_desc',
  DeadAsc = 'dead_asc',
  DeadDesc = 'dead_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  CreatedAtAsc = 'created_at_asc',
  CreatedAtDesc = 'created_at_desc',
  UpdatedAtAsc = 'updated_at_asc',
  UpdatedAtDesc = 'updated_at_desc',
  AppliedToEventsCountAsc = 'applied_to_events_count_asc',
  AppliedToEventsCountDesc = 'applied_to_events_count_desc'
}

export type HangarTicketType = {
  __typename?: 'TicketType';
  /** Link to external ticket for tracking i.e. JIRA / Azure Link */
  link: Scalars['String'];
  /** Name representation of the ticket */
  name: Scalars['String'];
  /** Status of the ticket */
  status?: Maybe<Scalars['String']>;
  /** The source platform the ticket came from, e.g. jira */
  platform: Scalars['String'];
  /** The tickets source platform id. Stored as a string so it can be generic, may need to change it to an integer for some cases */
  platformId: Scalars['String'];
  /** The creation date of the work item in the system of record */
  platformCreatedAt?: Maybe<Scalars['String']>;
  /** Severity of the ticket in the system of record */
  platformSeverity?: Maybe<Scalars['String']>;
  /** Semi structured data containing misc ticket */
  ticketData?: Maybe<Scalars['JSONString']>;
  /** Tickets missing from Azure ingestion. e.g. https://dev.azure.com/shieldai/ShieldAI/_workitems/edit/14336/ */
  dead?: Maybe<Scalars['Boolean']>;
  /** UUID for the object */
  id: Scalars['String'];
  /** Time the object was created */
  createdAt: Scalars['String'];
  /** Time the object was last updated */
  updatedAt: Scalars['String'];
  events?: Maybe<Array<Maybe<HangarEventType>>>;
  eventsTotalCount?: Maybe<Scalars['Int']>;
};


/**
 * TimeRange defines a range of time in the hours of the day
 * e.g. "{"gte":"12:00:00", "lte":"18:00:00"}
 */
export type HangarTimeRange = {
  gte?: Maybe<Scalars['Time']>;
  lte?: Maybe<Scalars['Time']>;
};

export type HangarTimestampType = {
  __typename?: 'TimestampType';
  seconds?: Maybe<Scalars['Int']>;
  nanos?: Maybe<Scalars['Int']>;
};

export type HangarTraitConnectionTypeConnection = {
  __typename?: 'TraitConnectionTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: HangarPageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HangarTraitConnectionTypeEdge>>;
};

/** A Relay edge containing a `TraitConnectionType` and its cursor. */
export type HangarTraitConnectionTypeEdge = {
  __typename?: 'TraitConnectionTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<HangarTraitType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum HangarTraitSortEnum {
  NameAsc = 'name_asc',
  NameDesc = 'name_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  CreatedAtAsc = 'created_at_asc',
  CreatedAtDesc = 'created_at_desc',
  UpdatedAtAsc = 'updated_at_asc',
  UpdatedAtDesc = 'updated_at_desc'
}

export type HangarTraitType = {
  __typename?: 'TraitType';
  /** Traits of the flight or the test, such as Big Room, Dusty, Outdoors */
  name: Scalars['String'];
  /** UUID for the object */
  id: Scalars['String'];
  /** Time the object was created */
  createdAt: Scalars['String'];
  /** Time the object was last updated */
  updatedAt: Scalars['String'];
  robotLogTests?: Maybe<Array<Maybe<HangarRobotLogTestType>>>;
};

/** An enumeration. */
export enum HangarUser_Account_Type {
  ServiceAccount = 'SERVICE_ACCOUNT',
  OauthAccount = 'OAUTH_ACCOUNT'
}


/** An enumeration. */
export enum HangarUnitEnum {
  Second = 'SECOND',
  Count = 'COUNT'
}

/** An enumeration. */
export enum HangarUserAccountType {
  ServiceAccount = 'SERVICE_ACCOUNT',
  OauthAccount = 'OAUTH_ACCOUNT'
}

export type HangarUserConnectionTypeConnection = {
  __typename?: 'UserConnectionTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: HangarPageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HangarUserConnectionTypeEdge>>;
  total?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UserConnectionType` and its cursor. */
export type HangarUserConnectionTypeEdge = {
  __typename?: 'UserConnectionTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<HangarUserType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type HangarUserRobotLogDetailConnectionTypeConnection = {
  __typename?: 'UserRobotLogDetailConnectionTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: HangarPageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HangarUserRobotLogDetailConnectionTypeEdge>>;
  total?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UserRobotLogDetailConnectionType` and its cursor. */
export type HangarUserRobotLogDetailConnectionTypeEdge = {
  __typename?: 'UserRobotLogDetailConnectionTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<HangarUserRobotLogDetailType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum HangarUserRobotLogDetailSortEnum {
  UserIdAsc = 'user_id_asc',
  UserIdDesc = 'user_id_desc',
  RobotLogIdAsc = 'robot_log_id_asc',
  RobotLogIdDesc = 'robot_log_id_desc',
  ViewedCountAsc = 'viewed_count_asc',
  ViewedCountDesc = 'viewed_count_desc',
  ViewedLastAtAsc = 'viewed_last_at_asc',
  ViewedLastAtDesc = 'viewed_last_at_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc'
}

export type HangarUserRobotLogDetailType = {
  __typename?: 'UserRobotLogDetailType';
  userId: Scalars['String'];
  robotLogId: Scalars['String'];
  viewedCount: Scalars['Int'];
  viewedLastAt?: Maybe<Scalars['String']>;
  /** UUID for the object */
  id: Scalars['String'];
  robotLog?: Maybe<HangarRobotLogType>;
  user?: Maybe<HangarUserType>;
};

/** An enumeration. */
export enum HangarUserSortEnum {
  EmailAsc = 'email_asc',
  EmailDesc = 'email_desc',
  NameAsc = 'name_asc',
  NameDesc = 'name_desc',
  AccessTokenAsc = 'access_token_asc',
  AccessTokenDesc = 'access_token_desc',
  AccountTypeAsc = 'account_type_asc',
  AccountTypeDesc = 'account_type_desc',
  PhoneNumberAsc = 'phone_number_asc',
  PhoneNumberDesc = 'phone_number_desc',
  ProviderUserIdAsc = 'provider_user_id_asc',
  ProviderUserIdDesc = 'provider_user_id_desc',
  ProviderNameAsc = 'provider_name_asc',
  ProviderNameDesc = 'provider_name_desc',
  OrganizationIdAsc = 'organization_id_asc',
  OrganizationIdDesc = 'organization_id_desc',
  PictureAsc = 'picture_asc',
  PictureDesc = 'picture_desc',
  IdAsc = 'id_asc',
  IdDesc = 'id_desc',
  CreatedAtAsc = 'created_at_asc',
  CreatedAtDesc = 'created_at_desc',
  UpdatedAtAsc = 'updated_at_asc',
  UpdatedAtDesc = 'updated_at_desc'
}

export type HangarUserType = {
  __typename?: 'UserType';
  /** Email address of the user */
  email: Scalars['String'];
  /** The user's name */
  name: Scalars['String'];
  accountType?: Maybe<HangarUser_Account_Type>;
  /** Unique id provided by the OAuth Provider for the user */
  providerUserId?: Maybe<Scalars['String']>;
  /** OAuth provider used to auth the user i.e. google, gitlab */
  providerName?: Maybe<Scalars['String']>;
  /** Id of Organization the user belongs to */
  organizationId?: Maybe<Scalars['String']>;
  /** The user's image url */
  picture?: Maybe<Scalars['String']>;
  /** UUID for the object */
  id: Scalars['String'];
  /** Time the object was created */
  createdAt: Scalars['String'];
  /** Time the object was last updated */
  updatedAt: Scalars['String'];
  organization?: Maybe<HangarOrganizationType>;
  favoriteCollections?: Maybe<HangarCollectionConnectionTypeConnection>;
  teams?: Maybe<Array<Maybe<HangarTeamType>>>;
};


export type HangarUserTypeFavoriteCollectionsArgs = {
  ids?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  robotLogIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  createdByIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  names?: Maybe<Array<Maybe<Scalars['String']>>>;
  labelIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  collectionTypes?: Maybe<Array<Maybe<HangarCollectionTypeChoice>>>;
  collectionReviewReviewStatuses?: Maybe<Array<Maybe<HangarReviewStatus>>>;
  collectionReviewReviewStatusesNotIn?: Maybe<Array<Maybe<HangarReviewStatus>>>;
  collectionReviewReviewerIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  collectionReviewReviewerTeamIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  collectionReviewRequesterIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  textLike?: Maybe<Scalars['String']>;
  isMyFavorite?: Maybe<Scalars['Boolean']>;
  isSystemDefault?: Maybe<Scalars['Boolean']>;
  includeAllPrivateForCreator?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<HangarCollectionSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};
