import _ from 'lodash';
import { createSelector } from '@reduxjs/toolkit';

export function isSortable(def) {
  return !!(def && (def.sortKey || def.getElasticSort));
}

export function getExpandedActiveColumns({
  columns = [],
  getColumnDefinition,
}) {
  return columns.reduce((acc, col) => {
    const def = getColumnDefinition({ columnKey: col.columnKey });
    if (def) {
      acc.push({
        column: col,
        uid: col.columnUid || col.columnKey,
        computedLabel: def.getLabel ? def.getLabel({ column: col }) : def.label,
        columnDefinition: def,
      });
    }
    return acc;
  }, []);
}

export function getExpandedDynamicColumns({ getAllColumnDefinitions }) {
  const defs = getAllColumnDefinitions().filter((col) => {
    return col.isDynamic;
  });
  return _.sortBy(defs, 'label').map((def) => ({
    computedLabel: def.label,
    columnDefinition: def,
  }));
}

export function getExpandedInactiveColumns({
  columns = [],
  getAllColumnDefinitions,
}) {
  const activeMap = _.keyBy(columns, 'columnKey');

  const defs = getAllColumnDefinitions().filter((col) => {
    return !activeMap[col.columnKey] && !col.isDynamic && !col.isFixed;
  });

  return _.sortBy(defs, 'label').map((def) => ({
    computedLabel: def.label,
    columnDefinition: def,
  }));
}

function getColumnTitle(getColumnDefinition, column) {
  const def = getColumnDefinition({ columnKey: column.columnKey });
  if (def.getLabel) {
    return def.getLabel({ column });
  }
  return def.label || 'No Label';
}

/**
 * Get a selector that takes this.props for a table and returns a bunch of specific table props
 * Return the selector so we can support having multiple tables mounted at one time
 */
export function getCalculateTablePropsSelector() {
  // simple selectors for memoization
  const getGetColumnDefinition = (props) => props.getColumnDefinition;
  const getColumns = (props) => props.mergedColumns;
  const getSort = (props) => props.mergedSort;
  const getFixedColumns = (props) => props.fixedColumns;
  const getPinnedColumns = (props) => props.pinnedColumns;

  /**
   * Translate our stored shape of things into specific data structures that the table components care about
   * Ensure memoization to reduce renders
   * @param props
   * @returns {{tableColumns: [], pinnedColumns:{}, tableSorting: [], tableColumnOrder: []}}
   */
  return createSelector(
    getGetColumnDefinition,
    getColumns,
    getSort,
    getFixedColumns,
    getPinnedColumns,
    (getColumnDefinition, columns, sort, fixedColumns, pinnedColumns) => {
      const tableSorting = [
        { id: sort.orderByColumn, desc: sort.orderByDirection === 'desc' ? true : false },
      ];

      return columns.reduce(
        (acc, column) => {
          const def = getColumnDefinition({ columnKey: column.columnKey });

          if (!def) {
            return acc;
          }
          const colName = column.columnUid || column.columnKey;

          acc.tableColumns.push({
            name: colName,
            title: getColumnTitle(getColumnDefinition, column),
            // our internal struct, separate from the table component generics
            columnData: column,
          });
          acc.tableColumnOrder.push(colName);

          return acc;
        },
        {
          pinnedColumns,
          tableColumns: [],
          tableColumnOrder: [],
          tableSorting,
          tableFixedColumnNames: _.map(
            fixedColumns,
            (col) => col.columnUid || col.columnKey
          ),
        }
      );
    }
  );
}
