import {
  fetchParticipantsForSL,
  useBatchCreateParticipants,
  deleteSingleParticipant,
} from '../../services/participants';
import _ from 'lodash';
import { showSuccess, showError } from '../../lib/messages';
import { detectSessionLogProduct } from '../../services/robotLogs';
import ParticipantEditView from '../../components/participants/ParticipantsEditView';

type ParticipantCardProps = {
  sessionLogId: string;
  pageRobotLog: any;
};

export default function ParticipantCard(props: ParticipantCardProps) {
  const { sessionLogId, pageRobotLog } = props;

  const { isLoading: loadingParticipants, data: participants } =
    fetchParticipantsForSL(sessionLogId);

  const { mutate: batchCreate } = useBatchCreateParticipants({
    onSuccess: () => showSuccess('Participant(s) added!'),
    onError: () => showError('Unable to add participant(s).'),
  });
  const { mutate: deleteParticipant } = deleteSingleParticipant({
    onSuccess: () => showSuccess('Participant deleted!'),
    onError: () => showError('Unable to delete participant.'),
  });

  return (
    <ParticipantEditView
      sessionLogId={sessionLogId}
      flightType={detectSessionLogProduct(pageRobotLog.name)}
      participants={participants || []}
      onDelete={(participant) => deleteParticipant(participant)}
      onAdd={(participants) => batchCreate(participants)}
      cardView={true}
    />
  );
}
