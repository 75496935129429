import React from 'react';
import PropTypes from 'prop-types';
import CheckSelectWrapper from './CheckSelectWrapper';
import Label from './Label';

function ParentRow({ label, ...rest }) {
  return (
    <CheckSelectWrapper {...rest}>
      <Label {...rest}>{label}</Label>
    </CheckSelectWrapper>
  );
}

ParentRow.propTypes = {
  ...CheckSelectWrapper.propTypes,
  ...Label.propTypes,
  label: PropTypes.string.isRequired,
};

export default ParentRow;
