import cx from 'classnames';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.75),
    backgroundColor: 'rgba(255, 255, 255, 0.01)',
    marginBottom: 1,
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(255, 255, 255, 0.06)',
    },
  },
}));

export default function PipelineRow({ children, ...rest }) {
  const classes = useStyles();
  return (
    <div {...rest} className={cx(classes.row, rest.className)}>
      {children}
    </div>
  );
}
