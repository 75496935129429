import React from 'react';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { MoreOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Tooltip } from 'antd';
import { colors, fonts } from '@shield-ui/styles';
import { stopEventPropagation } from '@shield-ui/utils';
import { ResultType, LabelType } from '../HeatGrid/types';

type ContentDataType = ResultType | LabelType;

export interface CellProps {
  label: string | ((contentData: ContentDataType) => string);
  contentData: ContentDataType;
  labelDisplay?: 'axis' | 'body';
  borderColor?: React.CSSProperties['color'];
  isHighlighted?: boolean;
  background?:
    | React.CSSProperties['background']
    | { color: string; percent: number }[]
    | ((contentData: ContentDataType) => string);
  actions?: {
    label: string;
    onClick: (contentData: ContentDataType) => void;
  }[];
  renderTooltip?: (contentData: ContentDataType) => React.ReactNode;
}

const useStyles = makeStyles({
  // Transparent background on a div with a gradient background didn't quite work
  borderContainer: {
    border: `2.5px solid transparent`,
    background: 'transparent',
    width: '100%',
    height: '100%',
    transition: '.15s ease',
    position: 'relative',
    '&:hover > $menuPosition': {
      opacity: 1,
    },
  },
  borderContainerActive: {
    borderColor: colors.hues.blues['80'],
  },
  cellContainer: {
    display: 'flex',
    fontSize: 14,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 4,
    width: '100%',
    height: '100%',
    transition: '0.15s ease',
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    color: 'white',
    textAlign: 'center',
  },
  labelBody: {
    fontFamily: fonts.headerFontFamily,
    fontSize: 24,
    letterSpacing: '1.1px',
    fontWeight: 600,
    textShadow: '#000 2px 2px 1px',
  },
  labelAxis: {
    fontSize: 14,
    fontWeight: 400,
  },
  menuPosition: {
    opacity: 0,
    position: 'absolute',
    right: 8,
    top: 'calc(50% - 14px)',
    zIndex: 2,
    transition: '0.1s ease',
    width: 28,
    height: 28,
  },
  icon: {
    position: 'absolute',
    top: 0,
    left: 0,
    fontSize: 24,
    padding: 2,
  },
  iconMain: {
    zIndex: 2,
    //borderRadius: '50%',
    transition: '0.1s ease',
    color: colors.hues.grays[30],
    '&:hover': {
      color: 'white',
      //background: 'rgba(255,255,255,0.1)',
      //border: `1px solid rgba(255,255,255, 0.2)`,
      //borderColor: 'rgba(255, 255, 255, 0.4)'
    },
  },
  iconBg: {
    color: 'black',
    top: 1.2,
    left: 1.2,
    zIndex: 1,
  },
});

const computeLabel = (
  label: CellProps['label'],
  data: CellProps['contentData']
): string => {
  if (label instanceof Function) {
    return label(data);
  }
  return label;
};

//({backgroundColor}) => backgroundColor
//"linear-gradient(to right, #FE6B8B 0% 30%, #FF8E53 30% 90%)"
const computeBackgroundString = (
  background: CellProps['background'],
  data: CellProps['contentData']
): React.CSSProperties['background'] => {
  // convert from function to string or array
  if (background instanceof Function) {
    background = background(data);
  }

  if (background instanceof Array) {
    const colorStrings = [];
    let currentPercent = 0;
    for (let i = 0; i < background.length; i++) {
      const endPercent = currentPercent + background[i].percent;
      const stringBuilder = ''.concat(
        background[i].color,
        ' ',
        currentPercent.toString(),
        '% ',
        endPercent.toString(),
        '%'
      );
      colorStrings.push(stringBuilder);
      currentPercent = endPercent;
    }

    return 'linear-gradient(to right,'.concat(colorStrings.join(' , '), ')');
  } else {
    return background;
  }
};

function defaultRenderTooltip() {
  return null;
}

export function Cell({
  label,
  labelDisplay,
  contentData,
  borderColor,
  isHighlighted,
  background = 'black',
  actions = [],
  renderTooltip = defaultRenderTooltip,
}: CellProps) {
  const classes = useStyles();

  return (
    <div
      className={cx(
        classes.borderContainer,
        isHighlighted && classes.borderContainerActive
      )}
      style={
        borderColor && !isHighlighted
          ? { background: isHighlighted ? undefined : borderColor }
          : undefined
      }
    >
      <Tooltip
        placement="top"
        title={renderTooltip(contentData)}
        color={colors.hues.grays[190]}
        mouseEnterDelay={0.25}
        overlayStyle={{ maxWidth: 1000 }}
      >
        <div
          className={classes.cellContainer}
          style={{
            background: computeBackgroundString(background, contentData),
          }}
        >
          <div
            className={cx(
              classes['labelContainer'],
              labelDisplay === 'body'
                ? classes['labelBody']
                : classes['labelAxis']
            )}
          >
            {computeLabel(label, contentData)}
          </div>
        </div>
      </Tooltip>
      {actions.length > 0 && (
        <div className={classes.menuPosition}>
          <Dropdown
            destroyPopupOnHide={true}
            trigger={['click']}
            placement="topLeft"
            overlay={
              <Menu>
                {actions.map((action, i) => {
                  return (
                    <Menu.Item
                      key={i}
                      icon={<CaretRightOutlined />}
                      onClick={(info) => {
                        const evt = info.domEvent;
                        evt.stopPropagation();
                        evt.preventDefault();
                        action.onClick(contentData);
                      }}
                    >
                      {action.label}
                    </Menu.Item>
                  );
                })}
              </Menu>
            }
          >
            <MoreOutlined
              className={cx(classes.icon, classes.iconMain)}
              rotate={90}
              onClick={stopEventPropagation}
            />
          </Dropdown>
          <MoreOutlined
            className={cx(classes.icon, classes.iconBg)}
            rotate={90}
            onClick={stopEventPropagation}
          />
        </div>
      )}
    </div>
  );
}

export default Cell;
