import { GroupQueryFilter, MultiselectQueryFilter } from '@shield-ui/query-filters';
import { getUserOptions } from '../../../services/users';
import { getTeamOptions } from '../../../services/teams';

export const testDefinitionOwnerUsers = new MultiselectQueryFilter({
  id: 'testDefinitionOwnerUsers',
  controlLabel: 'Users',
  esField: 'test_definition.ownership.users.id',
  description: '',
  isMulti: true,
  behaviorConfig: {
    fetchOptions: getUserOptions,
  },
});
export const testDefinitionOwnerTeams = new MultiselectQueryFilter({
  id: 'testDefinitionOwnerTeams',
  controlLabel: 'Teams',
  esField: 'test_definition.ownership.teams.id',
  description: '',
  isMulti: true,
  behaviorConfig: {
    fetchOptions: getTeamOptions,
  },
});

export default new GroupQueryFilter({
  id: 'testDefinitionOwners',
  controlLabel: 'Test Owners',
  queryFilters: [
    testDefinitionOwnerUsers,
    testDefinitionOwnerTeams,
  ]
});
