import React from 'react';
import { Button, Input, Select } from 'antd';
import { AntButton } from '@shield-ui/buttons';
import makeStyles from '@mui/styles/makeStyles';
import {
  AttributeValueCollectorControlData,
  AttributeValueCollectorQueryFilterValue,
} from '../../filterTypes';
import { ControlProps } from '../common';
import ResetButton from '../shared/ResetButton';
import ActionsContainer from '../shared/ActionsContainer';
import ListOperatorSwitch from '../shared/ListOperatorSwitch';

interface Props
  extends ControlProps<AttributeValueCollectorQueryFilterValue>,
    AttributeValueCollectorControlData {}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
  },
  row: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    alignItems: 'center',
  },
  field: {
    margin: theme.spacing(0, 1, 0, 0),
    flex: 1,
    minWidth: 300,
  },
  operator: {
    margin: theme.spacing(0, 1, 0, 0),
    '& .ant-select-selection-item': {
      fontWeight: 900,
      fontSize: 20,
    },
  },
  operatorOption: {
    fontWeight: 900,
    fontSize: 20,
  },
  value: {
    margin: theme.spacing(0, 1, 0, 0),
    width: 72,
  },
}));

export default function AttributeValueCollector(props: Props) {
  const classes = useStyles();

  const {
    onChange,
    onClear,
    hasValue,
    value = {},
    fieldPlaceholder,
    valuePlaceholder,
    defaultItemOperator,
    itemOperators,
    fieldOptions,
    getItemOperatorDisplay,
    getValidItemCountFromValue,
    partialQuery,
  } = props;
  const { items = [], listOperator } = value;

  if (items.length === 0) {
    items.push({
      operator: defaultItemOperator,
    });
  }

  function setItemProperty(index, key, v) {
    const existingItem = items[index] || {};

    const newItems = [...items];
    newItems.splice(index, 1, {
      ...existingItem,
      [key]: v,
    });
    onChange({ items: newItems });
  }

  return (
    <div className={classes.container}>
      {items.map((item, index) => (
        <div key={index} className={classes.row}>
          {fieldOptions.length > 0 && (
            <Select
              showSearch
              className={classes.field}
              size="large"
              value={item.field}
              onChange={(v) => setItemProperty(index, 'field', v)}
              placeholder={fieldPlaceholder}
            >
              {fieldOptions.map((opt) => (
                <Select.Option key={opt.value} value={opt.value}>
                  {opt.label}
                </Select.Option>
              ))}
            </Select>
          )}
          {fieldOptions.length === 0 && (
            <Input
              placeholder={fieldPlaceholder}
              className={classes.field}
              value={item.field}
              onChange={(evt) =>
                setItemProperty(index, 'field', evt.target.value)
              }
              size="large"
            />
          )}
          <Select
            className={classes.operator}
            value={item.operator}
            onChange={(v) => setItemProperty(index, 'operator', v)}
            showArrow={false}
            size="large"
          >
            {itemOperators.map((operator) => (
              <Select.Option
                key={operator}
                className={classes.operatorOption}
                value={operator}
              >
                {getItemOperatorDisplay(operator)}
              </Select.Option>
            ))}
          </Select>
          <Input
            placeholder={valuePlaceholder}
            className={classes.value}
            value={item.value}
            onChange={(evt) =>
              setItemProperty(index, 'value', evt.target.value)
            }
            size="large"
          />
          <AntButton
            //disabled={items.length === 1}
            shape="circle"
            onClick={() => {
              const newItems = [...items];
              newItems.splice(index, 1);
              onChange({ items: newItems });
            }}
          >
            X
          </AntButton>
        </div>
      ))}
      <ActionsContainer
        Left={
          <>
            <Button
              size="large"
              type="primary"
              onClick={() => {
                onChange({
                  items: items.concat([{ operator: defaultItemOperator }]),
                });
              }}
            >
              Add Row
            </Button>
            <div style={{ width: 16 }} />
            {getValidItemCountFromValue(value) > 1 && (
              <ListOperatorSwitch
                value={listOperator}
                onChange={(v) => onChange({ listOperator: v })}
              />
            )}
          </>
        }
        Right={<ResetButton onClick={onClear} disabled={!hasValue} />}
      />
    </div>
  );
}
