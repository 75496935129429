import {
  BaseFilterValue,
  BaseQueryFilter,
  BaseFilterProps,
} from './baseQueryFilter';
import { QueryFilterTypes } from './common';

export interface AdHocEsQueryFilterValue extends BaseFilterValue {
  rawQuery: string;
}

export type AdHocEsQueryFilterControlData = Record<string, unknown>;

export type AdHocEsQueryFilterProps = Omit<BaseFilterProps, 'esField'>;

export class AdHocEsQueryFilter extends BaseQueryFilter<
  AdHocEsQueryFilterValue,
  AdHocEsQueryFilterProps,
  AdHocEsQueryFilterControlData
> {
  type = QueryFilterTypes.adHocEs;

  initControlData(
    props: AdHocEsQueryFilterProps
  ): AdHocEsQueryFilterControlData {
    return {};
  }

  hasValue(value: AdHocEsQueryFilterValue): boolean {
    return !!(value && value.rawQuery);
  }

  getElasticQuery(value: AdHocEsQueryFilterValue): object | void {
    if (!this.hasValue(value)) {
      return;
    }

    try {
      return JSON.parse(value.rawQuery);
    } catch (e) {
      return undefined;
    }
  }

  getValuePreview(value: AdHocEsQueryFilterValue): string {
    if (!this.hasValue(value)) {
      return '';
    }

    return 'Ad-Hoc Manual Query';
  }
}
