import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Stethoscope } from '@shield-ui/icons';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from '@shield-ui/styles';
import { lighten } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    padding: theme.spacing(0.5, 0.25),
    fontSize: 18,
    letterSpacing: 0.55,
    color: lighten(colors.hues.gray, 0.7),
    transition: '0.15s ease',
    borderBottom: `2px solid transparent`, // spacing
    '&:hover': {
      color: '#fff',
      textDecoration: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  icon: {
    marginRight: theme.spacing(0.5),
    color: colors.brand.primaryShade[70],
  },
}));

function DiagnosticsGoToAction({ robotLogId }) {
  const classes = useStyles();
  return (
    <Link
      className={classes.container}
      to={`/app/rl/${robotLogId}/diagnostics`}
      component={RouterLink}
      role="button"
      underline="hover">
      <Stethoscope className={classes.icon} /> Diagnostics
    </Link>
  );
}

export default DiagnosticsGoToAction;
