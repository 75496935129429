import { useMemo, useEffect, useState } from 'react';
import { TestDefinition } from '@hmd/sdk/api/tests/definitions/v1';
import TagsPreview, { TagType } from './TagsPreview';
import Field from './TestDefinitionForm/PreviewField';
import Section from './TestDefinitionForm/PreviewSection';
import PreviewText from './TestDefinitionForm/PreviewText';
import {
  fieldStrings,
  sectionStrings,
  fetchTestDefinitionVersionsByCode,
  getRouteLinkForTestDefinition,
} from '../../services/testDefinitions';
import { protoToForm } from './TestDefinitionForm/common';
import UserMultiSelect from './TestDefinitionForm/UserMultiSelect';
import TeamMultiSelect from './TestDefinitionForm/TeamMultiSelect';
import EnvironmentMultiSelect from './TestDefinitionForm/EnvironmentMultiSelect';
import RequirementsMultiSelect from './TestDefinitionForm/RequirementsMultiSelect';
import ChildRobotLogExpectations from './TestDefinitionForm/ChildRobotLogExpectations';
import Duration from './TestDefinitionForm/Duration';
import { showError } from '../../lib/messages';
import history from '../../routes/history';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from '@shield-ui/styles';
import { structValueToBasicStructure } from '../../services/passThruSearch';
import { MetadataView } from '../metadata/MetadataView';

const useStyles = makeStyles(() => ({
  link: {
    color: colors.hues.blues[80],
    marginBottom: 5,
    '&:hover': {
      cursor: 'pointer',
      color: colors.hues.blues[120],
    },
  },
}));

interface Props {
  testDefinition: TestDefinition;
}

export default function TestDefinitionPreviewCard(props: Props) {
  const { testDefinition } = props;
  const [testDefinitionVersionsList, setTestDefinitionVersionsList] = useState(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();

  const td = useMemo(() => {
    return protoToForm(testDefinition);
  }, [testDefinition]);

  const metadata = useMemo(() => {
    return structValueToBasicStructure(
      testDefinition.getPlatformMetadata().toObject()
    );
  }, [testDefinition]);

  useEffect(() => {
    setIsLoading(true);
    fetchTestDefinitionVersionsByCode(testDefinition.getCode())
      .then((tdList) => {
        tdList.splice(
          tdList.findIndex(function (i) {
            return i.getId() === testDefinition.getId();
          }),
          1
        );
        setTestDefinitionVersionsList(tdList);
        setIsLoading(false);
      })
      .catch(showError);
  }, [testDefinition]);

  const tags: { label: string; tagType: TagType; list: string[] }[] = [
    {
      label: fieldStrings.environmentalTags,
      tagType: 'environmental',
      list: td.environmentalTags,
    },
    {
      label: fieldStrings.equipmentTags,
      tagType: 'equipment',
      list: td.equipmentTags,
    },
    {
      label: fieldStrings.componentTags,
      tagType: 'component',
      list: td.componentTags,
    },
    {
      label: fieldStrings.generalTags,
      tagType: 'general',
      list: td.generalTags,
    },
  ];

  /*
  Complex controls that take extra data are using their "Form" control with a "isPreview" flag which switches from control to readonly preview view
  E.g. all of the "smart" MultiSelects use the same preview system.
   */

  return (
    <div>
      <Section label={sectionStrings.general}>
        <Field label={fieldStrings.description}>
          <PreviewText>{td.description}</PreviewText>
        </Field>
      </Section>

      <Section label={sectionStrings.execution}>
        <Field label={fieldStrings.executionCriteria}>
          <PreviewText>{td.executionCriteria}</PreviewText>
        </Field>
        <Field
          label={fieldStrings.environments}
          show={td.environmentIds.length > 0}
        >
          <EnvironmentMultiSelect isPreview value={td.environmentIds} />
        </Field>
        <Field label={fieldStrings.isExecutionReady}>
          {td.isExecutionReady ? 'Yes' : 'No'}
        </Field>

        <Field
          label={fieldStrings.estimatedExecutionDuration}
          show={td.estimatedExecutionDuration > 0}
        >
          <Duration isPreview value={td.estimatedExecutionDuration} />
        </Field>
        <Field
          label={fieldStrings.requirements}
          show={td.requirementIds.length > 0}
        >
          <RequirementsMultiSelect isPreview value={td.requirementIds} />
        </Field>
      </Section>

      <Section label={sectionStrings.acceptanceCriteria}>
        <Field label={fieldStrings.reviewIsRequired}>
          {td.reviewIsRequired ? 'Yes' : 'No'}
        </Field>

        <Field label={fieldStrings.childRobotLogExpectations}>
          <ChildRobotLogExpectations
            isPreview
            value={td.childRobotLogExpectations}
          />
        </Field>
      </Section>
      <Section label={sectionStrings.metadata}>
        <Field label={fieldStrings.metadata}>
          <MetadataView metadata={metadata} includeTitle={false} />
        </Field>
      </Section>
      <Section label={sectionStrings.tags}>
        {tags.map(({ tagType, label, list }) => (
          <Field key={tagType} label={label} show={list.length > 0}>
            <TagsPreview tags={list} tagType={tagType} />
          </Field>
        ))}
      </Section>

      <Section label={sectionStrings.ownership}>
        <Field
          label={fieldStrings.ownerUserIds}
          show={td.ownerUserIds.length > 0}
        >
          <UserMultiSelect isPreview value={td.ownerUserIds} />
        </Field>
        <Field
          label={fieldStrings.ownerTeamIds}
          show={td.ownerTeamIds.length > 0}
        >
          <TeamMultiSelect isPreview value={td.ownerTeamIds} />
        </Field>
      </Section>
      <Section label={'Other Versions'}>
        {testDefinitionVersionsList.length > 0 && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {testDefinitionVersionsList.map((s) => (
              <div
                className={classes.link}
                onClick={() => history.push(getRouteLinkForTestDefinition(s))}
              >{`Version ${s.getVersionInfo().getVersionNumber()}`}</div>
            ))}
          </div>
        )}
      </Section>
    </div>
  );
}
