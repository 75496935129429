/**
 * Scroll the main content container to the top
 */
export function scrollMainContentToTop() {
  scrollMainContentTo(0);
}

/**
 * The "Frame" component of our main app is a scrolling content container.
 * This keeps the side bar locked in place. Rather than programmatically scrolling the window,
 * we need to scroll this element to reveal things in the "page".
 * @param offset
 */
export function scrollMainContentTo(offset: number) {
  // This container has a "scroll-behavior: smooth" CSS declaration which is awesome... no more doing this in JS!
  const main = getMainContentContainer();
  if (!main) {
    return console.warn('main element does not exist');
  }
  main.scrollTop = offset;
}

export function getMainContentContainer() {
  return document.getElementsByTagName('main')[0];
}
