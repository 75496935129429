import React from 'react';
import cx from 'classnames';
import withStyles from '@mui/styles/withStyles';
import _ from 'lodash';
import { analysisInProgress } from '../../../../../services/analysis';

function styles(theme) {
  return {
    container: {},
    good: {
      color: theme.palette.success.main,
    },
    error: {
      color: theme.palette.error.light,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 4,
    },
    count: {
      width: 30,
      textAlign: 'right',
      marginRight: 5,
    },
  };
}

export function parseResultsFromRow(row) {
  const analyses = _.get(row, 'analyses.edges', []);

  return analyses.reduce(
    (acc, edge) => {
      const analysis = edge.node;

      if (analysisInProgress(analysis)) {
        acc.progress++;
        return acc;
      }
      acc.done++;

      if (analysis.testPassed) {
        acc.passed++;
      } else if (analysis.testPassed === false) {
        acc.error++;
      }

      return acc;
    },
    {
      error: 0,
      ran: 0,
      passed: 0,
      progress: 0,
      done: 0,
      total: analyses.length,
    }
  );
}

function AnalyzerOverviewCell({ classes, row }) {
  const results = parseResultsFromRow(row);
  if (results.total === 0) {
    return null;
  }

  const successPercent = Math.round((results.passed / results.done) * 100);

  return (
    <div className={cx(classes.container)}>
      <div className={classes.row}>
        <div className={classes.count}>{successPercent}%</div>
        <div>of {results.done} passed</div>
      </div>
      {results.progress > 0 && (
        <div className={classes.row}>
          <div className={classes.count}>{results.progress}</div>
          <div>in progress</div>
        </div>
      )}
    </div>
  );
}
export default withStyles(styles)(AnalyzerOverviewCell);
