import {
  AttributeItemOperator,
  AttributeValueCollectorQueryFilter,
} from '@shield-ui/query-filters';

export default new AttributeValueCollectorQueryFilter({
  id: 'robot_log_data',
  esField: 'robot_log_data',
  controlLabel: 'Metadata',
  // robot_log_data currently flattened. Should we unflatten it?
  // https://www.elastic.co/guide/en/elasticsearch/reference/master/flattened.html
  // range queries not supported on flattened
  // select distinct(key) from (select jsonb_object_keys(robot_log_data) as key from robot_log WHERE jsonb_typeof(robot_log_data) = 'object') as keys order by key;
  // Currently at least 122, as we don't delve into nested objects
  itemOperators: [
    AttributeItemOperator.equals,
    AttributeItemOperator.notEquals,
  ],
  description:
    "Metadata is a flexible data storage mechanism attached to SessionLogs. Data that doesn't fit into other resources can be captured as key/value pairs",
});
