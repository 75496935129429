import React from 'react';
import cx from 'classnames';
import { lighten } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { severityColors, logEventLevelColors } from '../../../lib/sharedStyles';
import Tooltip from '@mui/material/Tooltip';
import { colors } from '@shield-ui/styles';
import Typography from '@mui/material/Typography';
import { minFormatDuration } from '@shield-ui/utils';
import { Grow } from '@shield-ui/core';

/**
 * Event Severities and Log Event Levels
 * NOT_SET, DEBUG, INFO, WARN, ERROR, FATAL
 */
const highlightColors = {
  critical: severityColors.CRITICAL,
  major: severityColors.MAJOR,
  moderate: severityColors.MODERATE,
  minor: severityColors.MINOR,

  fatal: logEventLevelColors.FATAL,
  error: logEventLevelColors.ERROR,
  warn: logEventLevelColors.WARN,
  info: logEventLevelColors.INFO,
  debug: logEventLevelColors.DEBUG,
};

export interface Props {
  label: string | React.ReactNode;
  highlight?:
    | 'fatal'
    | 'critical'
    | 'major'
    | 'moderate'
    | 'minor'
    | 'debug'
    | string; // string for easy typings if transforming value from a string
  timeCodeAtSeconds?: number;
  timeCodeOnClick?: (timeCodeSeconds) => void;
  description?: string;
  EndNode?: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    paddingRight: theme.spacing(6),
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  label: {
    color: colors.hues.grays[20],
    textTransform: 'initial',
    display: 'flex',
    alignItems: 'center',
  },
  highlight: {
    height: theme.spacing(4),
    width: theme.shape.borderRadius,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: colors.hues.grays[50],
    marginRight: theme.spacing(1),
  },
  time: {
    color: lighten(colors.brand.primary, 0.6),
    fontWeight: 'bold',
    fontSize: 16,
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid transparent`,
  },
  timeClickable: {
    cursor: 'pointer',
    transition: '.15s ease',
    '&:hover': {
      borderColor: lighten(colors.brand.primary, 0.6),
    },
  },
  endNodeContainer: {
    marginLeft: theme.spacing(1),
    width: theme.spacing(19),
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

function ChildRowDetails(props: Props) {
  const {
    highlight,
    label,
    timeCodeAtSeconds,
    EndNode,
    description,
    timeCodeOnClick,
  } = props;

  const classes = useStyles();
  return (
    <Tooltip
      title={description}
      placement="right"
      disableFocusListener
      disableTouchListener
      enterDelay={500}
    >
      <div className={classes.container}>
        <div className={classes.mainContent}>
          <div
            className={classes.highlight}
            style={{ backgroundColor: highlightColors[highlight] }}
          />
          <Typography variant="h6" className={classes.label}>
            {label}
          </Typography>
          <Grow />
          {!!timeCodeAtSeconds && (
            <div
              className={cx(
                classes.time,
                timeCodeOnClick && classes.timeClickable
              )}
              onClick={
                timeCodeOnClick
                  ? (evt) => {
                      evt.preventDefault();
                      evt.stopPropagation();
                      timeCodeOnClick(timeCodeAtSeconds);
                    }
                  : undefined
              }
            >
              {minFormatDuration(timeCodeAtSeconds)}
            </div>
          )}
        </div>
        <div className={classes.endNodeContainer}>{EndNode}</div>
      </div>
    </Tooltip>
  );
}

export default ChildRowDetails;
