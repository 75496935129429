import { useSessionLogMetadata } from '../../services/passThruSearch';
import { MetadataValueInputProps } from './MetadataEditableRow';
import { MetadataValueInput } from './MetadataValueInput';

export const SessionLogMetadataValueInput: React.FC<
  MetadataValueInputProps
> = ({ value = '', field, onChange, productType, platform_id, ...props }) => {
  const { data: vbatMetadata } = useSessionLogMetadata(productType);

  return (
    <MetadataValueInput
      metadata={vbatMetadata}
      field={field}
      style={{ width: '100%' }}
      value={value}
      filterOption={true}
      onChange={onChange}
      platform_id={platform_id}
      productType={productType}
      {...props}
    />
  );
};
