import _ from 'lodash';
import { semanticDataTypes } from '../../../../forms/defs';
import { makeKeySafe } from '../../../../../lib/documentSearch';
import { ValueTypes } from 'apps/vision/src/app/lib/dcoAttributes';

const config = {
  columnKey: 'metric',
  label: 'Piper Metric',
  getLabel: ({ column }) =>
    _.get(column, 'dataInputValues.metricName', '? Metric'),
  dataInputOptions: [
    {
      parameter: 'metricName',
      dataType: semanticDataTypes['Metric.name'],
      required: true,
    },
  ],
  getElasticSort: ({ column, orderByDirection }) => {
    const key = _.get(column, 'dataInputValues.metricName');
    if (!key) {
      return;
    }

    return [
      {
        [`metrics.${makeKeySafe(key)}`]: {
          order: orderByDirection,
          missing: '_last',
        },
      },
    ];
  },
  getValue: (p) => {
    const { row, column } = p;
    const { columnUid } = column;
    return _.get(row, [columnUid, 'edges', 0, 'node', 'value']);
  },
  valueType: ValueTypes.number,
  description:
    'Select a Piper Metric to display values for. The most recent Piper Execution will be displayed.',
  getFragment: ({ column }) => {
    const { columnUid, dataInputValues = {} } = column;
    if (!columnUid || !dataInputValues) {
      return '';
    }

    return `
         ${columnUid}: metrics(first:1,names:["${dataInputValues.metricName}"],sort:created_at_desc) {
          edges {
            node {
              value
            }
          }
         }
      `;
  },
};

export default config;
