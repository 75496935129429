import _ from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import { ANALYSIS_PROCESS_STATUS_CATEGORIES } from './constants';
import {
  ANALYSIS_RUN_STATUSES,
  analysisInProgress,
} from '../../services/analysis';
import { doesInputMatchBase } from '@shield-ui/utils';

export const getAnalysisMap = (state) => state.flight.analysisMap;
export const getAnalyzers = (state) => state.flight.analyzers;
export const getAnalysesFilters = (state) => state.flight.analysesFilters;

export const getPageRobotLogId = (state) => state.flight.robotLogId;
const selectPageRobotLog = (state) => state.flight.robotLog;
export const getPaging = (state) => state.flight.paging;

export const getPagingIsValid = createSelector(
  getPageRobotLogId,
  getPaging,
  (robotLogId, paging) => {
    return (
      robotLogId === paging.robotLogId &&
      paging.ids.includes(robotLogId) &&
      paging.total > 1
    );
  }
);

export const getPageRobotLog = createSelector(
  getPageRobotLogId,
  selectPageRobotLog,
  (robotLogId, robotLog) => {
    if (robotLog && robotLog.id === robotLogId) {
      return robotLog;
    }
  }
);

export const getAnalysesMergedWithAnalyzers = createSelector(
  getAnalysisMap,
  getAnalyzers,
  getAnalysesFilters,
  (analysisMap, analyzers = [], analysesFilters = {}) => {
    if (!analysisMap) {
      return undefined;
    }
    const { textLike, processStatusCategory, specificFramework } =
      analysesFilters;

    // key analyzers by the analysis defined on them
    const analysisToAnalyzer = analyzers.reduce((acc, analyzer) => {
      // XXX - 12/12 - Judy is disabled because pipeline won't support the size of payload
      // if (analyzer.framework === FRAMEWORKS.JUDY) { return acc }

      const analysisNames = analyzer.analysis_names || analyzer.analysis || [];

      analysisNames.forEach((analysisName) => {
        if (!analysisName) {
          return;
        }
        acc[analysisName] = analyzer;
      });
      return acc;
    }, {});

    // build out expanded analyses for return
    let analyses = Object.values(analysisMap).map((analysis) => {
      const analyzer = analysisToAnalyzer[analysis.name];
      delete analysisToAnalyzer[analysis.name];

      return {
        ...analysis,
        analyzerName: _.get(analyzer, 'name'),
      };
    });

    // fill out analyses with any analysis that don't exist but we have an analyzer that can create
    _.forEach(analysisToAnalyzer, (analyzer, analysisName) => {
      analyses.push({
        name: analysisName,
        analyzerName: _.get(analyzer, 'name'),
        framework: _.get(analyzer, 'framework'),
      });
    });

    // filter analyses based on filters
    if (textLike) {
      analyses = analyses.filter((analysis) => {
        return (
          doesInputMatchBase(textLike, analysis.name) ||
          doesInputMatchBase(textLike, analysis.analyzerName) ||
          doesInputMatchBase(textLike, analysis.summary)
        );
      });
    }

    if (specificFramework) {
      analyses = analyses.filter(
        (analysis) => analysis.framework === specificFramework
      );
    }

    if (
      !textLike &&
      processStatusCategory !== ANALYSIS_PROCESS_STATUS_CATEGORIES.ALL
    ) {
      analyses = analyses.filter((analysis) => {
        switch (processStatusCategory) {
          case ANALYSIS_PROCESS_STATUS_CATEGORIES.NO_RESULTS:
            return !analysis.id || analysis.ranSuccessfully === null;
          case ANALYSIS_PROCESS_STATUS_CATEGORIES.IS_RUNNING:
            return analysisInProgress(analysis);
          case ANALYSIS_PROCESS_STATUS_CATEGORIES.RUN_FAILED:
            return analysis.ranSuccessfully === false;
          case ANALYSIS_PROCESS_STATUS_CATEGORIES.TEST_FAILED:
            return analysis.testPassed === false;
          case ANALYSIS_PROCESS_STATUS_CATEGORIES.ANY_FAILURE:
            return (
              analysis.ranSuccessfully === false ||
              analysis.testPassed === false
            );
          case ANALYSIS_PROCESS_STATUS_CATEGORIES.SUCCESSFUL:
            return (
              analysis.ranSuccessfully === true && analysis.testPassed === true
            );
          case ANALYSIS_PROCESS_STATUS_CATEGORIES.ALL_WITH_RESULTS:
            return analysis.id && !analysisInProgress(analysis);
          case ANALYSIS_PROCESS_STATUS_CATEGORIES.ALL:
            return true;
          default:
            return true;
        }
      });
    }

    if (
      processStatusCategory === ANALYSIS_PROCESS_STATUS_CATEGORIES.ALL &&
      !textLike
    ) {
      // sort analyses
      return _.sortBy(
        analyses,
        // processing, running, and faked analyzers
        (a) =>
          !a.id || (a.runStatus && a.runStatus !== ANALYSIS_RUN_STATUSES.DONE)
            ? 1
            : 0,
        // if they ran succesfull
        (a) => !!a.ranSuccessfully,
        // if they passed
        (a) => !!a.testPassed,
        // alpha
        (a) => a.name.toLowerCase()
      );
    } else {
      return _.sortBy(analyses, (analysis) => analysis.name.toLowerCase());
    }
  }
);
