import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { ensureAllMetricNames, ensureAllAnalysisNames } from '../../../redux/lists/actions';
import { useEffect } from "react";
import {Select, Form} from 'antd';

export const MetricAnalysisSelect = (isMultiple?:string) => {
  const analysisNames = useSelector((state:RootStateOrAny) => state.lists.listTypeMap.analysisNames);
  const metricNames = useSelector((state:RootStateOrAny) => state.lists.listTypeMap.metricNames);
  const dispatch = useDispatch();
  useEffect(() => {
      dispatch(ensureAllMetricNames());
      dispatch(ensureAllAnalysisNames());
  }, [])

  return (
    <Form.Item label="Metric or Analysis Value(s)" name="metricAnalysis" rules={[{ required: true, message: 'Please choose at least one metric' }]}>
				<Select
          mode={isMultiple==="multiple" ? "multiple": null}
					allowClear
					labelInValue
          optionFilterProp="children"
          autoClearSearchValue={false}
          showSearch={true}
					placeholder="Metric or Analysis Value"
					style={{
							width: '100%',
					}}
          filterOption={(input, option) =>
            option.props.children &&
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
           }
        >
        <Select.OptGroup key={"metrics"} label="Metrics">
          {metricNames.map((name) => {
            return (
              <Select.Option key={name} value={`metrics.${name.replace(/\./g, '_')}`}>
                {name}
              </Select.Option>
            );})}
        </Select.OptGroup>
        <Select.OptGroup key={"analysis"} label="Analysis">
          {analysisNames.map((name)=> {
            return (
              <Select.Option key={name} value={`analyses.${name.replace(/\./g, '_')}`}>
                {name}
              </Select.Option>
            );})}
        </Select.OptGroup>
      </Select>
    </Form.Item>
  );
}
