import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import QueryFilterControl from '../QueryFilterControl';
import { GroupControlData, GroupQueryFilterValue } from '../../filterTypes';
import { ControlProps } from '../common';
import ModalControlLayout from '../ControlBar/ModalControlLayout';
import { colors } from '@shield-ui/styles';
import ActionsContainer from '../shared/ActionsContainer';
import ListOperatorSwitch from '../shared/ListOperatorSwitch';
import ResetButton from '../shared/ResetButton';

interface Props extends GroupControlData, ControlProps<GroupQueryFilterValue> {}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
  labelsColumn: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 100,
    maxWidth: 220,
    paddingRight: theme.spacing(1),
    marginRight: theme.spacing(1),
    borderRight: '1px solid rgba(255, 255, 255, 0.1)',
  },
  label: {
    height: 48,
    fontSize: 14,
    color: colors.hues.grays[10],
    padding: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
    textAlign: 'right',
    justifyContent: 'flex-end',
  },
  controlWrap: {
    padding: theme.spacing(0.5),
    height: 48,
    display: 'flex',
    alignItems: 'center',
  },
}));

export default function Group(props: Props) {
  const {
    onChange,
    onClear,
    hasValue,
    value = {},
    getQueryFilterControlComponent,
    queryFilters,
  } = props;
  const { individualValues = {} } = value;
  const classes = useStyles();

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.labelsColumn}>
          {queryFilters.map((qf) => (
            <div key={`label-${qf.getId()}`} className={classes.label}>
              {qf.getControlLabel()}
            </div>
          ))}
          <br />
        </div>
        <div>
          {queryFilters.map((qf) => (
            <div key={qf.getId()} className={classes.controlWrap}>
              <QueryFilterControl
                key={qf.getId()}
                queryFilter={qf}
                values={individualValues}
                setValues={(value = {}) => {
                  const newValues = {
                    ...individualValues,
                    ...value,
                  };
                  onChange({ individualValues: newValues });
                }}
                LayoutComponent={ModalControlLayout}
                getQueryFilterControlComponent={getQueryFilterControlComponent}
              />
            </div>
          ))}
        </div>
      </div>
      <ActionsContainer
        Right={<ResetButton onClick={onClear} disabled={!hasValue} />}
      />
    </div>
  );
}
