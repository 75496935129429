import _ from 'lodash';
import { createReducer } from "@reduxjs/toolkit";
import update from 'immutability-helper';
import {
  RESET_COLLECTION_PAGE,
  SET_COLLECTION,
  UPDATE_COLLECTION,
  UPDATE_EDIT_RULE_SET_VARIABLES,
  FORCE_METABASE_REFRESH,
  SET_REVIEWS,
  SET_REVIEW_STATS,
  CLEAR_EDIT_RULE_SET_VARIABLES,
  SET_SIDE_PANEL_CONTENT_SCREEN,
  SET_MEASUREMENT_DEFINITIONS,
  SET_MEASUREMENT_EDITOR_VARIABLES,
  RESET_MEASUREMENT_EDITOR_VARIABLES,
  SET_COLLECTION_IDS_TO_COMPARE,
  SET_MEASUREMENTS,
  SET_RULE_SET_SELECT_OPTION_ID,
} from './actions';
import { SIDE_PANEL_CONTENT_SCREEN } from './constants';
import { convertMeasurementsJson } from '../../services/measurements';
import {
  HangarCollectionType,
  HangarMeasurementDefinitionType,
  HangarCollectionReviewType,
  HangarMeasurementType
} from "@shield-ui/hangar-service";

type InitialState = {
  measurementDefinitions: HangarMeasurementDefinitionType[];
  collectionId?: string;
  collection?: HangarCollectionType;
  ruleSetEditingVariables: Record<string, unknown>;
  ruleSetEditingOptionId?: string;
  metabaseRefreshCount: number;
  reviews: HangarCollectionReviewType[];
  measurements: HangarMeasurementType[];
  results: [];
  collectionIdsToCompare: string[];
  reviewStats?: [];
  measurementEditorVariables: Record<string, unknown>;
  sidePanelContentScreen: SIDE_PANEL_CONTENT_SCREEN;
}

// defaults that should not be mutated on reset
const DEFAULT_PRESERVE_STATE: Partial<InitialState>= {
  measurementDefinitions: [],
};

// all defaults, including things that can be reset
const DEFAULT_RESET_STATE: Partial<InitialState> = {
  collectionId: undefined,
  collection: undefined,
  ruleSetEditingVariables: {},
  ruleSetEditingOptionId: undefined,
  metabaseRefreshCount: 0,
  reviews: [],
  measurements: [],
  results: [],
  collectionIdsToCompare: [],
  reviewStats: undefined,
  measurementEditorVariables: {},
  sidePanelContentScreen: SIDE_PANEL_CONTENT_SCREEN.MAIN,
};

export default createReducer(
  {
    ...DEFAULT_PRESERVE_STATE,
    ...DEFAULT_RESET_STATE,
  },
  {
    [SET_MEASUREMENT_EDITOR_VARIABLES]: (state, action) => {
      return update(state, {
        measurementEditorVariables: { $merge: action.payload },
      });
    },
    [SET_COLLECTION_IDS_TO_COMPARE]: (state, action) => {
      return update(state, {
        collectionIdsToCompare: { $set: action.payload.collectionIds },
      });
    },
    [RESET_MEASUREMENT_EDITOR_VARIABLES]: (state) => {
      return update(state, {
        measurementEditorVariables: {
          $set: DEFAULT_RESET_STATE.measurementEditorVariables,
        },
      });
    },
    [RESET_COLLECTION_PAGE]: (state, action) => {
      return update(state, {
        $merge: {
          ...DEFAULT_RESET_STATE,
          // collectionId importantly
          ...action.payload,
        },
      });
    },
    [SET_SIDE_PANEL_CONTENT_SCREEN]: (state, action) => {
      return update(state, {
        sidePanelContentScreen: { $set: action.payload.screen },
      });
    },
    [FORCE_METABASE_REFRESH]: (state) => {
      return update(state, {
        metabaseRefreshCount: { $set: state.metabaseRefreshCount + 1 },
      });
    },
    [SET_COLLECTION]: (state, action) => {
      const { collection: originalCollection } = action.payload;

      // can't mutate items from the apollo cache
      // probably should find a cleaner way to store this on another prop
      const collection = _.cloneDeep(originalCollection);

      const robotLogSearchVariables = _.get(
        collection,
        'ruleSet.robotLogSearchVariables'
      );

      if (robotLogSearchVariables && _.isString(robotLogSearchVariables)) {
        try {
          collection.ruleSet.robotLogSearchVariables = JSON.parse(
            robotLogSearchVariables
          );
        } catch (e) {
          console.error(e);
          collection.ruleSet.robotLogSearchVariables = {};
        }
      }
      if (collection.uiData && _.isString(collection.uiData)) {
        try {
          collection.uiData = JSON.parse(collection.uiData);
        } catch (e) {
          console.error(e);
          collection.uiData = null;
        }
      }

      return update(state, {
        collection: { $set: collection },
        collectionId: { $set: collection.id },
      });
    },
    [UPDATE_COLLECTION]: (state, action) => {
      const { collection } = state;
      const { collection: collectionUpdates } = action.payload;

      return update(state, {
        collection: { $set: { ...collection, ...collectionUpdates } },
      });
    },
    [CLEAR_EDIT_RULE_SET_VARIABLES]: (state) => {
      return update(state, {
        ruleSetEditingVariables: {
          $set: {
            ...DEFAULT_RESET_STATE.ruleSetEditingVariables,
          },
        },
      });
    },
    [UPDATE_EDIT_RULE_SET_VARIABLES]: (state, action) => {
      const { variables } = action.payload;
      return update(state, {
        ruleSetEditingVariables: {
          $set: {
            // $merge doesn't like nested objects it seams
            ...state.ruleSetEditingVariables,
            ...variables,
          },
        },
      });
    },
    [SET_RULE_SET_SELECT_OPTION_ID]: (state, action) => {
      const { queryFilterId } = action.payload;
      return update(state, {
        ruleSetEditingOptionId: { $set: queryFilterId },
      });
    },
    [SET_REVIEWS]: (state, action) => {
      const { reviews } = action.payload;

      return update(state, {
        reviews: { $set: reviews },
      });
    },
    [SET_REVIEW_STATS]: (state, action) => {
      const { stats } = action.payload;

      return update(state, {
        reviewStats: { $set: stats },
      });
    },
    [SET_MEASUREMENT_DEFINITIONS]: (state, action) => {
      const { measurementDefinitions } = action.payload;

      return update(state, {
        measurementDefinitions: { $set: measurementDefinitions },
      });
    },
    [SET_MEASUREMENTS]: (state, action) => {
      const { measurements, results } = action.payload;

      if (measurements) {
        state = update(state, {
          measurements: { $set: convertMeasurementsJson(measurements) },
        });
      }
      if (results) {
        state = update(state, { results: { $set: results } });
      }
      return state;
    },
  }
);
