import React from 'react';
import { Select } from '@shield-ui/controls';
import { useDidMount } from '@shield-ui/hooks';
import container from './logDefinitionsContainer';
import { Option, filterOption } from '../TagSelect/TagSelect';

function LogDefinitionMulti(props) {
  const { withDescriptiveOptions, ensureLogDefinitions, ...rest } = props;

  useDidMount(ensureLogDefinitions);

  const extra = !props.logDefinitionOptions.length
    ? {
        placeholder: 'Loading...',
        disabled: true,
      }
    : undefined;

  const components = withDescriptiveOptions ? { Option } : undefined;

  return (
    <Select
      suggestions={props.logDefinitionOptions}
      filterOption={filterOption}
      components={components}
      placeholder="Diagnostic Types"
      {...rest}
      {...extra}
    />
  );
}

export default container(LogDefinitionMulti);
