import { createReducer } from 'redux-nano';
import _ from 'lodash';
import update from 'immutability-helper';
import { SHOW_ERROR, SHOW_SUCCESS, RESOLVE_SNACK_MESSAGES } from './actions';

let MESSAGE_ID_COUNTER = 1;
const initialState = {
  snackbarQueue: [],
};

export const SNACKBAR_VARIANTS = {
  ERROR: 'error',
  SUCCESS: 'success',
};

function coerceError(err) {
  if (_.isString(err)) {
    return { message: err };
  } else if (err.message) {
    return err;
  } else if (err.statusMessage) {
    return {message: err.statusMessage};
  } else if (_.get(err, 'result.error.message')) {
    return err.result.error.message;
  } else {
    if (_.isObject(err)) {
      return { message: JSON.stringify(err) }
    }
    return err;
  }
}

/**
 *
 * @param payload: { message : {string} }
 * @param variant
 * @returns {{variant: *, messageId: number, message: *}}
 */
function preparePayload(payload, variant) {
  const message = _.isString(payload) ? payload : payload.message;

  return {
    messageId: MESSAGE_ID_COUNTER++,
    variant,
    message,
  };
}

export default createReducer(initialState, {
  [SHOW_ERROR]: (state, { payload }) => {
    return update(state, {
      snackbarQueue: {
        $push: [preparePayload(coerceError(payload), SNACKBAR_VARIANTS.ERROR)],
      },
    });
  },
  [SHOW_SUCCESS]: (state, { payload }) => {
    return update(state, {
      snackbarQueue: {
        $push: [preparePayload(payload, SNACKBAR_VARIANTS.SUCCESS)],
      },
    });
  },
  [RESOLVE_SNACK_MESSAGES]: (state, { payload }) => {
    const { messageIds } = payload;
    const { snackbarQueue } = state;

    return update(state, {
      snackbarQueue: {
        $set: snackbarQueue.filter((message) => {
          return messageIds.indexOf(message.messageId) === -1;
        }),
      },
    });
  },
});
