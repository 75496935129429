import config from './config';

class TicketApi {
  constructor() {
    this.urlBase = config.api.base + '/rest/ticket/search';
  }

  _headers() {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  }

  get(text) {
    const url = `${this.urlBase}?text=${encodeURIComponent(text)}`;

    return fetch(url, {
      credentials: 'include',
      headers: this._headers(),
    }).then((r) => r.json());
  }

  post(text) {
    return fetch(this.urlBase, {
      method: 'POST',
      credentials: 'include',
      headers: this._headers(),
      body: JSON.stringify({
        text,
      }),
    }).then((r) => r.json());
  }
}

export default new TicketApi();
