import _ from 'lodash';
import { gql } from '@apollo/client';
import {
  HangarEventType,
  HangarMutationsCreateEventArgs,
  HangarMutationsUpdateEventArgs,
  HangarMutationsDeleteEventArgs,
  HangarRobotLogType,
} from '@shield-ui/hangar-service';
import client from '../apollo-client';

import {
  Failure,
  FailureService,
  ListRequest as failureListRequest,
} from '@hmd/sdk/api/session_log/event/failure/v1';
import {
  CreateRequest,
  CauseService,
  ListRequest as causeListRequest,
} from '@hmd/sdk/api/session_log/event/cause/v1';

import sdkClient from '../lib/hmdsdkClient';
import { useQuery } from '@tanstack/react-query';

function getEventFragment() {
  return `
  id,
  notes,
  tagId
  causeId
  tag {
    severity {
      name,
      id
    },
    id
    description,
    name
  },
  cause {
    name,
    description,
    id
  },
  tickets {
    id
    platform
    platformId
  },
  sourceTypeId,
  robotLogId,
  startTime,
  endTime,
  createdAt,
  createdBy {
    id,
    name,
    picture
    providerName
  }
`;
}

export function deleteEvent(args: HangarMutationsDeleteEventArgs) {
  const deleteEventMutation = gql`
    mutation deleteEvent($id: String!) {
      deleteEvent(id: $id) {
        id
      }
    }
  `;

  return client.mutate({
    mutation: deleteEventMutation,
    variables: args,
  });
}

export function createEvent(
  args: HangarMutationsCreateEventArgs
): Promise<{ event: HangarEventType }> {
  const createEventMutation = gql`
    mutation createEvent(
      $robotLogId: String!,
      $startTime: String!,
      $endTime: String,
      $sourceTypeId: String!,
      $causeId: String,
      $tagId: String,
      $notes: String
      $ticketIds: [UUID]
    ) {
      createEvent(
        robotLogId: $robotLogId,
        startTime: $startTime,
        endTime: $endTime,
        sourceTypeId: $sourceTypeId,
        causeId: $causeId,
        tagId: $tagId,
        notes: $notes,
        ticketIds: $ticketIds
      ) {
        ${getEventFragment()}
      }
    }
  `;

  return client
    .mutate({
      mutation: createEventMutation,
      variables: args,
    })
    .then((r) => ({ event: r.data.createEvent }));
}

export function updateEvent(
  args: HangarMutationsUpdateEventArgs
): Promise<{ event: HangarEventType }> {
  const updateEventMutation = gql`
    mutation updateEvent(
      $id: UUID!,
      $startTime: String,
      $endTime: String,
      $causeId: String,
      $tagId: String,
      $notes: String,
      $ticketIds: [UUID]
    ) {
      updateEvent(
        id: $id,
        startTime: $startTime,
        endTime: $endTime,
        causeId: $causeId,
        tagId: $tagId
        notes: $notes,
        ticketIds: $ticketIds
      ) {
        ${getEventFragment()}
      }
    }
  `;

  return client
    .mutate({
      mutation: updateEventMutation,
      variables: args,
    })
    .then((r) => ({ event: r.data.updateEvent }));
}

export function searchEvents(args: { robotLogId: string }): Promise<{
  events: HangarEventType[];
  robotLog: HangarRobotLogType;
  eventsChildren: HangarRobotLogType[];
}> {
  const query = gql`
    query($robotLogId: String!) {
      robotLog(id: $robotLogId) {
        id
        name
        startTime
        endTime
        events(sort: created_at_desc) {
          edges {
            node {
              ${getEventFragment()}
            }
          }
        }
        children {
            id
            name
            startTime
            endTime
          events(sort: created_at_desc) {
            edges {
              node {
                ${getEventFragment()}
              }
            }
          }
        }
      }
    }
  `;

  return client
    .query({
      query,
      variables: args,
      fetchPolicy: 'no-cache',
    })
    .then((result) => {
      const events = _.get(result, 'data.robotLog.events.edges', []).map(
        (e) => e.node
      );
      const robotLog = _.get(result, 'data.robotLog');
      const eventsChildren = _.get(result, 'data.robotLog.children');
      delete robotLog.events;
      delete robotLog.children;
      return { events, eventsChildren, robotLog };
    });
}

function getInvalidTimeMessage(variables, robotLog): string {
  const { startTime: flightStartTime, endTime: flightEndTime } = robotLog;
  const { startTime, endTime } = variables;

  if (!startTime) {
    return 'Every event must have a start time as seconds from the beginning of the log';
  }

  if (startTime < flightStartTime) {
    return `Event start time can't be before the flight start time, are your seconds negative?`;
  } else if (startTime > flightEndTime) {
    return `Event start time can't be after the end of the flight, Make sure your seconds are less than the total duration of the flight`;
  } else if (startTime && endTime && endTime < startTime) {
    return `The event endTime can't be before the event's start time`;
  } else if (endTime && endTime > flightEndTime) {
    return `The event endTime can't be after the end of the flight. Make sure your seconds are less than the total duration of the flight`;
  }
}

export function validateUpdate(
  variables: HangarMutationsUpdateEventArgs,
  robotLog: HangarRobotLogType
): void | Error {
  if (!variables) {
    return new Error('No variables to validate');
  }
  if (!variables.id) {
    return new Error('An id for the event to update is required');
  }
  const timeError = getInvalidTimeMessage(variables, robotLog);
  if (timeError) {
    return new Error(timeError);
  }
}

export function validateCreate(
  variables: HangarMutationsCreateEventArgs,
  robotLog: HangarRobotLogType
): void | Error {
  if (!variables) {
    return new Error('No variables to validate');
  }
  if (!variables.robotLogId) {
    return new Error('A robotLogId to attach this event to is required');
  }

  if (!variables.tagId) {
    return new Error(
      'Failure type is required. Please choose a failure type for your event.'
    );
  }

  const timeError = getInvalidTimeMessage(variables, robotLog);
  if (timeError) {
    return new Error(timeError);
  }
}

const FAILURES_QUERY_KEY = 'failures';

export const fetchFailures = async () => {
  const req = new failureListRequest();
  req.setPageSize(200);
  const response = await sdkClient.query(FailureService.List, req);
  if (response.getFailuresList().length > 0) {
    return response.getFailuresList();
  }
  return null;
};

function getFetchFailuresQueryClientParams() {
  return {
    queryKey: [FAILURES_QUERY_KEY],
    queryFn: () => fetchFailures(),
  };
}

// hook for getting all participant types
export const useFailures = () => useQuery(getFetchFailuresQueryClientParams());

const CAUSES_QUERY_KEY = 'causes';

export const fetchCauses = async () => {
  const req = new causeListRequest();
  req.setPageSize(200);
  const response = await sdkClient.query(CauseService.List, req);
  if (response.getCausesList().length > 0) {
    return response.getCausesList();
  }
  return null;
};

function getFetchCausesQueryClientParams() {
  return {
    queryKey: [CAUSES_QUERY_KEY],
    queryFn: () => fetchCauses(),
  };
}

// hook for getting all participant types
export const useCauses = () => useQuery(getFetchCausesQueryClientParams());
