import React from 'react';
import Frame from './Frame';
import { ErrorBoundaryPageContent } from '@shield-ui/core';

export default function AppShell({ children }) {
  return (
    <Frame>
      <ErrorBoundaryPageContent>{children}</ErrorBoundaryPageContent>
    </Frame>
  );
}
