import _ from 'lodash';
import async from 'async';
import { gql } from '@apollo/client';
import { runMutation, runQuery } from '../apollo-client';

export const robotLogReviewFragment = `
  id,
  reviewer {
    id,
    name,
    email,
    picture
    providerName
  },
  reviewerTeam {
    id
    name
    picture
    users { id }
  }
  requester {
    id, 
    name,
    email,
  }
  reviewStatus,
  reviewedTime,
  createdAt
`;
// Everything is common
export const collectionReviewFragment = robotLogReviewFragment;

export const REVIEW_STATUSES = {
  APPROVED: 'APPROVED',
  COMPLETE: 'COMPLETE',
  IN_PROGRESS: 'IN_PROGRESS',
  NOT_STARTED: 'NOT_STARTED',
};
export const REVIEW_STATUS_DISPLAYS = {
  [REVIEW_STATUSES.NOT_STARTED]: 'Pending',
  [REVIEW_STATUSES.IN_PROGRESS]: 'In Progress',
  [REVIEW_STATUSES.COMPLETE]: 'Completed',
  [REVIEW_STATUSES.APPROVED]: 'Approved',
};

export const UNASSIGNED_FAKE_REVIEW_STATUS = 'Not Assigned';

export const getReviewStatusFilterOptions = _.memoize(() => {
  return _.map(REVIEW_STATUS_DISPLAYS, (display, status) => {
    return {
      value: status,
      label: display,
    };
  });
});

export function createRobotLogReview(variables, callback) {
  const mutation = gql`
      mutation createRobotLogReview (
          $robotLogId: UUID!,
          $reviewStatus: ReviewStatus,
          $reviewerId: UUID
          $reviewerTeamId: UUID
      ) {
          createRobotLogReview (
              robotLogId: $robotLogId,
              reviewerId: $reviewerId,
              reviewerTeamId: $reviewerTeamId,
              reviewStatus: $reviewStatus
          ) {
              ${robotLogReviewFragment}
          }
      }
  `;

  runMutation({
    mutation,
    variables,
    tracking: (tracker) => tracker.trackEvent('Flight', 'ReviewCreate'),
    callback,
  });
}

export function updateRobotLogReview(variables, callback) {
  const mutation = gql`
      mutation updateRobotLogReview  (
          $id: UUID!,
          $reviewStatus: ReviewStatus!,
      ) {
          updateRobotLogReview (
              id: $id,
              reviewStatus: $reviewStatus,
          )  {
              ${robotLogReviewFragment}
          }
      }
  `;

  runMutation({
    mutation,
    variables,
    tracking: [
      'Flight',
      'ReviewUpdate',
      'reviewStatus',
      variables.reviewStatus,
    ],
    callback,
  });
}

export function deleteRobotLogReview(variables, callback) {
  const mutation = gql`
      mutation deleteRobotLogReview  (
          $id: UUID!
      ) {
          deleteRobotLogReview (
              id: $id,
          )  {
              ${robotLogReviewFragment}
          }
      }
  `;

  runMutation({
    mutation,
    variables,
    tracking: ['Flight', 'ReviewDelete', 'id', variables.id],
    callback,
  });
}

// ------------------------------------------------------------------
// Collections... basically the same
// ------------------------------------------------------------------
export function createCollectionReview(variables, callback) {
  const mutation = gql`
      mutation createCollectionReview (
          $collectionId: UUID!,
          $reviewStatus: ReviewStatus,
          $reviewerId: UUID
          $reviewerTeamId: UUID
      ) {
          createCollectionReview (
              collectionId: $collectionId,
              reviewerId: $reviewerId,
              reviewerTeamId: $reviewerTeamId,
              reviewStatus: $reviewStatus
          )
          {
              ${collectionReviewFragment}
          }
      }
  `;
  runMutation({
    mutation,
    variables,
    tracking: (tracker) => tracker.trackEvent('Collection', 'ReviewCreate'),
    callback,
  });
}

export function updateCollectionReview(variables, callback) {
  const mutation = gql`
      mutation updateCollectionReview  (
          $id: UUID!,
          $reviewStatus: ReviewStatus!,
      ) {
          updateCollectionReview (
              id: $id,
              reviewStatus: $reviewStatus,
          )  {
              ${collectionReviewFragment}
          }
      }
  `;

  runMutation({
    mutation,
    variables,
    tracking: [
      'Collection',
      'ReviewUpdate',
      'reviewStatus',
      variables.reviewStatus,
    ],
    callback,
  });
}

export function deleteCollectionReview(variables, callback) {
  const mutation = gql`
      mutation deleteCollectionReview  (
          $id: UUID!
      ) {
          deleteCollectionReview (
              id: $id,
          )  {
              ${collectionReviewFragment}
          }
      }
  `;

  runMutation({
    mutation,
    variables,
    tracking: ['Collection', 'ReviewDelete', 'id', variables.id],
    callback,
  });
}

export function getCollectionReviews(variables, callback) {
  const query = gql`
    query collectionReviews (
        $collectionIds: [UUID],
    ) {
        collectionReviews (
            collectionIds:$collectionIds,
        ) {
            edges {
                node {
                    ${collectionReviewFragment}
                }
            }
        }
    }
  `;

  runQuery({
    variables,
    query,
    fetchPolicy: 'network-only',
    callback,
  });
}

function getTotalQuery(variables, callback) {
  const totalQuery = gql`
    query robotLogs(
      $collectionId: UUID!
      $robotLogReviewReviewStatuses: [ReviewStatus]
      $robotLogReviewReviewerIds: [UUID]
      $robotLogReviewReviewerTeamIds: [UUID]
    ) {
      robotLogs(
        collectionId: $collectionId
        robotLogReviewReviewStatuses: $robotLogReviewReviewStatuses
        robotLogReviewReviewerIds: $robotLogReviewReviewerIds
        robotLogReviewReviewerTeamIds: $robotLogReviewReviewerTeamIds
      ) {
        total
      }
    }
  `;

  runQuery({
    variables,
    query: totalQuery,
    fetchPolicy: 'network-only',
    callback: (err, r) => {
      if (err) {
        return callback(err);
      }

      return callback(null, _.get(r, 'data.robotLogs.total', 0));
    },
  });
}

function getReviewProgress({
  review,
  collectionId,
  robotLogReviewReviewStatuses,
  callback,
}) {
  const variables = {
    collectionId,
  };
  const reviewerId = review.reviewerId || _.get(review, 'reviewer.id');
  const reviewerTeamId =
    review.reviewerTeamId || _.get(review, 'reviewerTeam.id');
  const reviewerTeamUserIds = _.get(review, 'reviewerTeam.users', []).map(
    (user) => user.id
  );

  if (reviewerId) {
    variables.robotLogReviewReviewerIds = [reviewerId];
  } else if (reviewerTeamId) {
    variables.robotLogReviewReviewerTeamIds = [reviewerTeamId];
    variables.robotLogReviewReviewerIds = reviewerTeamUserIds;
  }

  async.series(
    {
      reviewId: (callback) => callback( null, review.id),
      progressCount: (callback) => {
        getTotalQuery(
          {
            ...variables,
            robotLogReviewReviewStatuses: [
              REVIEW_STATUSES.COMPLETE,
              REVIEW_STATUSES.APPROVED,
            ],
          },
          callback
        );
      },
      assignedCount: (callback) => {
        getTotalQuery(
          {
            ...variables,
            robotLogReviewReviewStatuses: [
              REVIEW_STATUSES.NOT_STARTED,
              REVIEW_STATUSES.IN_PROGRESS,
            ],
          },
          callback
        );
      },
    },
    callback
  );
}

export function getCollectionReviewProgressStats({
  collectionId,
  reviews,
  callback,
}) {
  const robotLogReviewReviewStatuses = [
    REVIEW_STATUSES.COMPLETE,
    REVIEW_STATUSES.APPROVED,
  ];

  async.series(
    {
      totalCount: (callback) => getTotalQuery({ collectionId }, callback),
      totalProgressCount: (callback) =>
        getTotalQuery({ collectionId, robotLogReviewReviewStatuses }, callback),
      byReviewBreakdown: (callback) => {
        async.mapSeries(
          reviews,
          (review, callback) => {
            getReviewProgress({
              review,
              collectionId,
              callback,
            });
          },
          callback
        );
      },
    },
    callback
  );
}
