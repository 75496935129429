import React, { useCallback } from 'react';
import QueryFilterControl from '../QueryFilterControl';
import ControlBarLayout from './ControlBarLayout';
import { ContextMenuState, QueryFilterItem } from './types';

interface Props {
  queryFilterItem: QueryFilterItem;
  values: object;
  setValues: (args: object) => void;
  setContextMenu: (s: ContextMenuState) => void;
}

export default function StaticQueryFilterControl(props: Props) {
  const { queryFilterItem, values, setValues, setContextMenu } = props;
  const { queryFilter, ControlBarLayoutComponent } = queryFilterItem;

  const onContextMenu = useCallback(
    (evt) => {
      evt.stopPropagation();
      evt.preventDefault();
      setContextMenu({
        anchorEl: evt.target,
        queryFilterId: queryFilter.getId(),
      });
    },
    [queryFilterItem]
  );

  return (
    <div onContextMenu={onContextMenu}>
      <QueryFilterControl
        queryFilter={queryFilter}
        values={values}
        setValues={setValues}
        LayoutComponent={ControlBarLayoutComponent || ControlBarLayout}
      />
    </div>
  );
}
