import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    margin: theme.spacing(3, 0),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    // don't butt up the button items in here against each other
    '& > a': {
      marginLeft: theme.spacing(1),
    },
    '& > button': {
      marginLeft: theme.spacing(1),
    },
    '& > div': {
      marginLeft: theme.spacing(1),
    },
  },
}));

function SectionSubmitContainer({ children }) {
  const classes = useStyles();
  return <div className={classes.buttonContainer}>{children}</div>;
}

export default SectionSubmitContainer;
