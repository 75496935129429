import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  clearResultsForRobotLog,
  refreshResultsForRobotLog,
  getContextForRobotLogId,
} from '../../../redux/pipelineExplorer.slice';
import { showModal } from '../../../redux/modal/actions';

function mapStateToProps(state, ownProps) {
  const { robotLogId } = ownProps;

  return {
    ...getContextForRobotLogId(state, robotLogId),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      clearResultsForRobotLog,
      refreshResultsForRobotLog,
      showModal,
    },
    dispatch
  );
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ContainerProps = ConnectedProps<typeof connector>;
export default connector;
