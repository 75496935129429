export const SOURCE_TYPE_NAMES = {
  AUTOGENERATED: 'AUTOGENERATED',
  IN_PERSON_OBSERVATION: 'IN_PERSON_OBSERVATION',
  POST_FLIGHT_REVIEW: 'POST_FLIGHT_REVIEW',
};

export const SOURCE_TYPE_IDS_HARDCODED = {
  AUTOGENERATED: '63382bb3-9097-4bcd-b72e-20d9b4af336a',
  IN_PERSON_OBSERVATION: 'ab276ba7-c40c-4b78-a930-ae6c2be1f060',
  POST_FLIGHT_REVIEW: 'ac3b3ebe-5f24-495a-a8e2-b989ce979087',
};

/**
 * sourceType is a bit overloaded
 * IN_PERSON_OBSERVATION - imported from the FTS sheet, if subsequent imports happen these are intended to be "replaced" with the new data
 * AUTOGENERATED - not used as far as I can tell - intention is analyzers create some things I think
 * POST_FLIGHT_REVIEW - something created by the user in the GUI of Vision
 *
 * FTS_IMPORT_SOURCE_TYPE_NAME - translates this obscure reference into something more human readable
 */

export const FTS_IMPORT_SOURCE_TYPE_NAME =
  SOURCE_TYPE_NAMES.IN_PERSON_OBSERVATION;
