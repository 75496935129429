import React from 'react';
import _ from 'lodash';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { TextField, InputGroup } from '@shield-ui/controls';
import { ProgressSubmitButton } from '@shield-ui/buttons';
import { getFormattedDateTime } from '@shield-ui/utils';
import { HangarEventType, HangarRobotLogType } from '@shield-ui/hangar-service';
import TagSelect from '../../connected-controls/TagSelect/TagSelect';
import CauseSelect from '../../connected-controls/CauseSelect/CauseSelect';
import TicketCollector, { TicketKey } from '../../connected-controls/tickets/TicketCollector';
import DateFromDateTimeSecondsInput from './DateFromDateTimeSecondsInput';
import container, { ContainerProps } from './containerAddUpdate';
import { SOURCE_TYPE_IDS_HARDCODED } from '../../../services/sourceTypes';
import { validateCreate, validateUpdate } from '../../../services/events';
import SectionSubmitContainer from '../../SubmitSection';

const styles = (theme) =>
  createStyles({
    container: {
      marginRight: theme.spacing(9),
    },
    firstRow: {
      display: 'flex',
    },
    failureGroup: {
      marginRight: theme.spacing(1),
      flex: 1,
    },
    timeControls: {
      width: 180,
      display: 'flex',
      flexDirection: 'row',
    },
  });

interface State {
  isDirty: boolean;
  isSubmitting: boolean;
  tagId: string;
  causeId: string;
  notes: string;
  startTime: string;
  endTime: string;
  ticketKeys: TicketKey[];
  ticketIds: string[];
}

interface Props extends WithStyles<typeof styles>, ContainerProps {
  eventToUpdate?: HangarEventType;
  robotLog: HangarRobotLogType;
  eventsChildren: HangarRobotLogType[];
  onCancel?: () => void;
  onFormStateChange?: (state: State) => void;
}

class AddUpdateEventForm extends React.Component<Props, State> {
  state = {
    isDirty: false,
    isSubmitting: false,
    tagId: undefined,
    causeId: undefined,
    notes: '',
    startTime: undefined,
    endTime: undefined,
    ticketKeys: [],
    ticketIds: [],
  };
  _defaultStateSave = {};

  constructor(props) {
    super(props);

    this._defaultStateSave = this.state;

    if (props.eventToUpdate) {
      const { eventToUpdate } = props;
      this.state.tagId = eventToUpdate.tagId;
      this.state.causeId = eventToUpdate.causeId;
      this.state.notes = eventToUpdate.notes;
      this.state.startTime = eventToUpdate.startTime;
      this.state.endTime = eventToUpdate.endTime;
      this.state.ticketKeys = _.get(eventToUpdate, 'tickets', []).map(
        ticketKey => {
          return {
            platform: ticketKey.platform,
            platformId: ticketKey.platformId,
          }
        }
      );
      this.state.ticketIds = _.map(_.get(eventToUpdate, 'tickets', []), 'id');
    } else {
      this.state.startTime = _.get(props, 'robotLog.startTime');
    }
  }

  onResult = (result) => {
    const { eventToUpdate, showErrorSnack, showSuccessSnack } = this.props;

    if (result.error) {
      showErrorSnack(result.error);
    } else {
      showSuccessSnack({
        message: `Event ${eventToUpdate ? 'updated' : 'created'}`,
      });

      if (!eventToUpdate) {
        this.setState({ ...this._defaultStateSave });
      }
    }

    setTimeout(() => {
      this.setState({
        isSubmitting: false,
      });
    }, 500);
  };

  onSubmit = (evt) => {
    this.preventClick(evt);
    const {
      createEvent,
      updateEvent,
      robotLog,
      eventToUpdate,
      showErrorSnack,
    } = this.props;

    this.setState({
      isSubmitting: true,
    });

    const commonProps = _.pick(this.state, [
      'tagId',
      'causeId',
      'notes',
      'startTime',
      'endTime',
      'ticketIds',
    ]);
    let req;
    if (eventToUpdate) {
      // update properties
      const args = {
        id: eventToUpdate.id,
        ...commonProps,
      };
      const error = validateUpdate(args, robotLog);
      if (error) {
        showErrorSnack(error);
        setTimeout(() => this.setState({ isSubmitting: false }), 300);
        return true;
      }
      req = updateEvent(args);
    } else {
      // create properties
      const args = {
        robotLogId: robotLog.id,
        sourceTypeId: SOURCE_TYPE_IDS_HARDCODED.POST_FLIGHT_REVIEW,
        ...commonProps,
      };
      const error = validateCreate(args, robotLog);
      if (error) {
        showErrorSnack(error);
        setTimeout(() => this.setState({ isSubmitting: false }), 300);
        return true;
      }
      req = createEvent(args);
    }

    req.then(this.onResult);
  };

  onChangeTicketCollector = ({ value, tickets }) => {
    this.setState({
      isDirty: true,
      ticketKeys: value,
      ticketIds: _.map(tickets, 'id'),
    });
  };

  onStartTime = ({ value }) => {
    this.setState({
      isDirty: true,
      startTime: value,
    });
  };

  onEndTime = ({ value }) => {
    this.setState({
      isDirty: true,
      endTime: value,
    });
  };

  onChangeTag = (opt) => {
    this.setState({
      isDirty: true,
      tagId: _.get(opt, 'value'),
    });
  };

  onChangeCause = (opt) => {
    this.setState({
      isDirty: true,
      causeId: _.get(opt, 'value'),
    });
  };

  onChangeNotes = (evt) => {
    this.setState({
      isDirty: true,
      notes: evt.target.value,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    // onFormStateChange only really used for updates
    if (prevState !== this.state && this.props.onFormStateChange) {
      this.props.onFormStateChange(this.state);
    }
  }

  preventClick = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
  };

  render() {
    const { onCancel, classes, eventToUpdate, eventsChildren } = this.props;
    const {
      isDirty,
      tagId,
      notes,
      causeId,
      startTime,
      endTime,
      ticketKeys,
      isSubmitting,
    } = this.state;

    return (
      <div className={classes.container}>
        <div onClick={this.preventClick}>
          <div className={classes.firstRow}>
            <InputGroup label="Failure Type" className={classes.failureGroup}>
              <TagSelect
                value={tagId}
                onChange={this.onChangeTag}
                placeholder="Find a failure"
                withDescriptiveOptions
              />
            </InputGroup>
            <InputGroup label="Time Range">
              <div className={classes.timeControls}>
                <DateFromDateTimeSecondsInput
                  value={startTime}
                  placeholder="Start secs"
                  dateTimeBase={startTime}
                  dateRange={{
                    gte: startTime,
                    lte: endTime,
                  }}
                  onChange={this.onStartTime}
                />
                <DateFromDateTimeSecondsInput
                  value={endTime}
                  placeholder="End secs"
                  dateTimeBase={startTime}
                  dateRange={{
                    gte: startTime,
                    lte: endTime,
                  }}
                  onChange={this.onEndTime}
                />
              </div>
            </InputGroup>
          </div>
          <InputGroup label="ADO Tickets">
            <TicketCollector
              value={ticketKeys}
              onChange={this.onChangeTicketCollector}
              suggestionsTagId={tagId}
            />
          </InputGroup>
          <InputGroup label="Cause">
            <CauseSelect
              value={causeId}
              onChange={this.onChangeCause}
              placeholder="Find a root cause"
              withDescriptiveOptions
            />
          </InputGroup>
          <InputGroup label="Additional Details">
            <TextField
              placeholder="Notes observed about this failure event"
              multiline
              rows={4}
              value={notes}
              onChange={this.onChangeNotes}
            />
          </InputGroup>
          {eventToUpdate && (
            <Typography variant="body2" align="right">
              Created {getFormattedDateTime(eventToUpdate.createdAt)}
            </Typography>
          )}
        </div>
        <SectionSubmitContainer>
          {onCancel && (
            <Button
              onClick={(evt) => {
                this.preventClick(evt);
                onCancel();
              }}
            >
              Cancel
            </Button>
          )}
          {eventToUpdate && !!eventsChildren.length && !onCancel && (
            <Typography color="textSecondary" variant="subtitle1" >
              {eventToUpdate.createdBy.name}
            </Typography>
          )}
          <ProgressSubmitButton
            onClick={this.onSubmit}
            // when it is submitting, not dirty, or doesn't have required values
            disabled={isSubmitting || !isDirty || !(tagId && startTime)}
            isSubmitting={isSubmitting}
            variant="contained"
            color="primary"
          >
            {eventToUpdate ? 'Update Event' : 'Add Event'}
          </ProgressSubmitButton>
        </SectionSubmitContainer>
      </div>
    );
  }
}

export default container(withStyles(styles)(AddUpdateEventForm));
