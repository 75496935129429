/*
Harvested dataset name
Number of harvested frames
Number of annotated frames
Number of unique portals
Number of unique people
 */

export enum ValueTypes {
  string = 0,
  number = 1,
}

export type GenericRobotLogDataAttribute = {
  key: string;
  label: string;
  description: string;
  valueType: ValueTypes;
};

export const genericRobotLogDataAttributes: GenericRobotLogDataAttribute[] = [
  // Generic things (FTS and such)
  {
    key: 'flight_scope',
    label: 'Flight Scope',
    description: 'Flight Scope',
    valueType: ValueTypes.string,
  },
  {
    key: 'flight_type',
    label: 'Flight Type',
    description: 'Flight Type',
    valueType: ValueTypes.string,
  },
  {
    key: 'Model',
    label: 'Model',
    description: 'Model',
    valueType: ValueTypes.string,
  },
  {
    key: 'Aircraft',
    label: 'Aircraft',
    description: 'Aircraft',
    valueType: ValueTypes.string,
  },
  {
    key: 'Configuration',
    label: 'Configuration',
    description: 'Configuration',
    valueType: ValueTypes.string,
  },
];
