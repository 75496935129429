export const ANALYSIS_PROCESS_STATUS_CATEGORIES = {
  ALL: 'ALL',
  ALL_WITH_RESULTS: 'ALL_WITH_RESULTS',
  RUN_FAILED: 'RUN_FAILED',
  TEST_FAILED: 'TEST_FAILED',
  ANY_FAILURE: 'ANY_FAILURE',
  SUCCESSFUL: 'SUCCESSFUL',
  IS_RUNNING: 'IS_RUNNING',
  NO_RESULTS: 'NO_RESULTS',
};
