import { StyledPlot } from '@shield-ui/analysis';
import {RenderPlotProps, plotConfig, plotOptions,metricAnalysis} from '../common';

interface localProps extends RenderPlotProps{
	plotConfig: localPlotConfig;
}
interface localPlotConfig extends plotConfig{
	plotOptions:localPlotOptions;
}
interface localPlotOptions extends plotOptions{
	metricAnalysis: metricAnalysis[];
	aggType: string[];
	graphType:string;
}

export default function DataOverTimePlot ({plotConfig, response, actions}:localProps) {
	const {graphType, groupByTime, aggType, title, metricAnalysis} = plotConfig.plotOptions;
	const timeUpperCase = groupByTime.charAt(0).toUpperCase() + groupByTime.slice(1)

	const upperCasedAggs = aggType.map((agg)=> {
		if(!isNaN(Number(agg))){
			return `${agg}th Percentile`
		}else{
			return agg.charAt(0).toUpperCase() + agg.slice(1)
		}
	})

	let generatedTitle = ''
	if(metricAnalysis.length===1 && aggType.length===1){
		generatedTitle = `${upperCasedAggs[0]} of '${metricAnalysis[0].label}' vs. ${timeUpperCase}s`
	}else{
		generatedTitle =  `Data Aggregated by (${upperCasedAggs.join(', ')}) over Time (${timeUpperCase}s)`;
	}

	const layout = {
		title: title.length > 0 ? title : generatedTitle,  
		hovermode: "closest",
		yaxis: {
			title: aggType.length === 1 ? upperCasedAggs[0] +' Aggregate Value' : 'Aggregate Value'
		},
		...(graphType === "bar" && {barmode:"stack"})
	}
	
	const config = {
		scrollZoom: true,
	}

	return (
		<div>
			<StyledPlot 
				key = {plotConfig.id}
				data={response}
				layout={layout.barmode}
				moreActions={actions}
				config={config}
			/>
		</div>
	);
		
}
                                                       