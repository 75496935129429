import { useRequirementTableValues } from '../../../services/requirements';
import { Link } from '@mui/material';
import { Table, Input } from 'antd';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import AntPopoverConfigProvider from './AntPopoverConfigProvider';
import { prettifyString } from '@shield-ui/utils';
import { State } from '@hmd/sdk/api/systems/requirements/v1';

const useStyles = makeStyles((theme) => ({
  search: {
    width: 400,
    marginBottom: theme.spacing(1),
  },
  table: {
    height: '70vh',
  },
}));

type RequirementsTableProps = {
  onRowSelect: (value: any, option: any) => void;
  selected: string[];
};

export default function RequirementsTable(props: RequirementsTableProps) {
  const { onRowSelect, selected } = props;
  const dataSource = useRequirementTableValues();
  const classes = useStyles();

  // Handle project search filter
  const requirementTypes = dataSource
    .filter((r) => r.requirementType)
    .map((r) => r.requirementType);
  const requirementTypesSet = new Set([...requirementTypes]);
  const requirementTypesFilter = Array.from(requirementTypesSet).map((pn) => ({
    text: pn,
    value: pn,
  }));

  // Handle name search filters
  const [search, setSearch] = useState<string>('');
  const filteredDataSource = dataSource.filter((r) =>
    search === '' ? true : r.name.toLowerCase().includes(search.toLowerCase())
  );
  const inputOnChange = (e) => setSearch(e.target.value);

  // Handle project search filter
  const projectNames = dataSource
    .filter((r) => r.projectName)
    .map((r) => r.projectName);
  const projectNamesSet = new Set([...projectNames]);
  const projectNamesFilter = Array.from(projectNamesSet).map((pn) => ({
    text: pn,
    value: pn,
  }));

  // Handle state search filter
  const stateKeys = Object.keys(State);
  const stateKeysFilter = stateKeys.map((s) => ({
    text: prettifyString(s),
    value: s,
  }));

  // Handle columns
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Project',
      dataIndex: 'projectName',
      key: 'projectName',
      width: 300,
      filters: projectNamesFilter,
      filterSearch: true,
      onFilter: (value, record) => {
        return record.projectName === value;
      },
    },
    {
      title: 'Type',
      dataIndex: 'requirementType',
      key: 'requirementType',
      width: 140,
      filters: requirementTypesFilter,
      onFilter: (value, record) => {
        return record.requirementType === value;
      },
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      width: 140,
      filters: stateKeysFilter,
      render: (text) => prettifyString(text),
      onFilter: (value, record) => {
        return record.state === value;
      },
    },
    {
      title: '',
      dataIndex: 'link',
      key: 'link',
      width: 60,
      render: (text) =>
        text ? (
          <Link href={text} target="_blank" underline="hover">
            view
          </Link>
        ) : null,
    },
  ];

  return (
    <AntPopoverConfigProvider>
      <Input
        autoFocus
        className={classes.search}
        onChange={inputOnChange}
        placeholder={'Filter by name ...'}
      />
      <Table
        dataSource={filteredDataSource}
        columns={columns}
        style={{ height: window.innerHeight * 0.7 + 110 }}
        scroll={{
          y: window.innerHeight * 0.7,
        }}
        pagination={{
          defaultPageSize: 20,
        }}
        rowSelection={{
          hideSelectAll: true,
          type: 'checkbox',
          onSelect: (r, select) => {
            let nextSelectedRows;
            if (select) {
              nextSelectedRows = [...selected, r.id];
            } else {
              nextSelectedRows = selected.filter((s) => s !== r.id);
            }
            onRowSelect(nextSelectedRows, undefined);
          },
          selectedRowKeys: selected,
        }}
      />
    </AntPopoverConfigProvider>
  );
}
