import _ from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import {
  HangarCollectionType,
  HangarMeasurementDefinitionType,
  HangarMeasurementType,
  HangarRuleSetSearchVersion,
} from '@shield-ui/hangar-service';
import { MeasurementDashboard, MeasurementDashboardGroup } from './types';
import { uuid4 } from '@shield-ui/utils';
import { RootState } from '../store';

const getCurrentUser = (state: RootState) => state.authUser.currentUser;
const getCollection = (state: RootState): HangarCollectionType =>
  state.collection.collection;
const getRuleSetEditingVariables = (state: RootState) =>
  state.collection.ruleSetEditingVariables;

export const getCanEditCollection = createSelector(
  getCurrentUser,
  getCollection,
  (currentUser, collection) => {
    return (
      _.get(collection, 'isPubliclyEditable') ||
      _.get(currentUser, 'id') === _.get(collection, 'createdById')
    );
  }
);

export const getMergedRuleSetEditingVariables = createSelector(
  getCollection,
  getRuleSetEditingVariables,
  (collection, ruleSetEditingVariables) => {
    const savedVariables = _.get(collection, 'ruleSet.robotLogSearchVariables');
    return {
      ...savedVariables,
      ...ruleSetEditingVariables,
    };
  }
);

const hangarRobotLogTypeToSessionLogTypeSlug = (rlt: string) => {
  if (rlt === 'TEST_TRIAL') return 'trial';
  else if (rlt === 'TEST_INSTANCE') return 'test';
  else if (rlt === 'FLIGHT') return 'flight';
  else if (rlt === 'BOOT_SESSION') return 'boot-session';
  else if (rlt === 'TEAM_FLIGHT') return 'team-flight';
  else return '';
};

export const getCollectionSessionLogTypeSlugs = createSelector(
  getCollection,
  (collection) => {
    if (!collection) {
      return [];
    }

    // Default for FTS import whitelist only collections
    if (collection && !collection.ruleSet) {
      return ['flight'];
    }

    const variables = collection.ruleSet.robotLogSearchVariables;

    //for collections with old esQueries/ using old robotLogTypes
    if (_.isArray(variables.robotLogTypes)) {
      console.log('Old Collection');
      const convertedSlugs = variables.robotLogTypes.map((rlt) =>
        hangarRobotLogTypeToSessionLogTypeSlug(rlt)
      );
      return convertedSlugs;
    }

    if (
      String(collection.ruleSet.searchVersion) ===
      String(HangarRuleSetSearchVersion.DocumentSearchV1)
    ) {
      //console.log('final if statement');
      //console.log(variables.robotLogTypes);

      //if the collection ruleSet.robotLogSearchVariables has old robotLogTypes values convert them into sessionLogTypeSlugs
      if (variables.robotLogTypes) {
        const val = _.get(variables, 'robotLogTypes');

        // TODO - remove this if we migrate the data for all existing collections.
        const convertedSlugs = val.includeOptionValues.map((rlt) =>
          hangarRobotLogTypeToSessionLogTypeSlug(rlt)
        );
        return convertedSlugs;
      } else if (variables.sessionLogTypes) {
        const val = _.get(variables, 'sessionLogTypes');
        return val.includeOptionValues;
      }
      // if robotLogTypes or sessionLogTypes don't exist
      return [];
    }
  }
);

export const getShouldShowDiagnostics = createSelector(
  getCollectionSessionLogTypeSlugs,
  (sessionLogTypeSlugs) => {
    return sessionLogTypeSlugs && sessionLogTypeSlugs.includes('flight');
  }
);

export const getRuleSetEditingIsDirty = createSelector(
  getCollection,
  getRuleSetEditingVariables,
  (collection, ruleSetEditingVariables) => {
    const savedVariables = _.get(
      collection,
      'ruleSet.robotLogSearchVariables',
      {}
    );

    return !!_.findKey(ruleSetEditingVariables, (value, key) => {
      // Has a value set OR is undefined now and previously had a value
      return (
        !_.isUndefined(value) ||
        (_.isUndefined(value) && !_.isUndefined(savedVariables[key]))
      );
    });
  }
);

export const getLabelIdsForCollectionPage = createSelector(
  getCollection,
  (collection) => {
    return _.get(collection, 'labels.edges', []).map((edge) => edge.node.id);
  }
);

const getMeasurementDefinitions = (
  state: RootState
): HangarMeasurementDefinitionType[] => state.collection.measurementDefinitions;
const getMeasurements = (state: RootState): HangarMeasurementType[] =>
  state.collection.measurements;
const getResults = (state: RootState): HangarMeasurementType[] =>
  state.collection.results;
const getMeasurementEditorVariables = (state: RootState) =>
  state.collection.measurementEditorVariables;

export const getMeasurementDefinitionWizardOptions = createSelector(
  getMeasurementDefinitions,
  (measurementDefinitions) => {
    interface Category {
      label: string;
      options: { value: string; label: string; description: string }[];
      matcher: (opt) => boolean;
    }
    const categories: Category[] = [
      {
        label: 'Piper',
        matcher: (opt) => opt.label.includes('Piper'),
        options: [],
      },
      {
        label: 'Analysis',
        matcher: (opt) => opt.label.includes('Analysis'),
        options: [],
      },
      {
        label: 'Diagnostics',
        matcher: (opt) => opt.label.includes('Diagnostic'),
        options: [],
      },
      {
        label: 'General',
        matcher: () => true,
        options: [],
      },
    ];

    // This is a stop-gap implemented during robotLogType -> sessionLogType conversion.
    // Proper way may be to map from server. Used to come from server and map to enums.
    const contentTypeDisplay = 'Session Log';

    const lowerRegex = /flight/g;
    const upperRegex = /Flight/g;
    const lowerContent = contentTypeDisplay.toLocaleLowerCase();
    function makeContentGeneric(str) {
      return str
        .replace(lowerRegex, lowerContent)
        .replace(upperRegex, contentTypeDisplay);
    }

    measurementDefinitions.forEach((def) => {
      const opt = {
        value: def.id,
        label: makeContentGeneric(def.name),
        // secondaryLabel: def.name.split(' ')[0],
        description: makeContentGeneric(def.description),
      };
      const match = _.find(categories, (cat) => cat.matcher(opt));
      if (match) {
        match.options.push(opt);
      }
    });

    return categories.filter((cat) => cat.options.length > 0);
  }
);

export const getSelectedMeasurementDefinition = createSelector(
  getMeasurementDefinitions,
  getMeasurementEditorVariables,
  (defs, vars) => {
    if (vars.measurementDefinitionId) {
      return _.find(defs, { id: vars.measurementDefinitionId });
    }
  }
);

const getCompleteMeasurements = createSelector(
  getMeasurementDefinitions,
  getMeasurements,
  getResults,
  (defs, measurements, results) => {
    const defMap = _.keyBy(defs, 'id');
    const resultMap = _.keyBy(results, 'id');

    const completeMeasurements: HangarMeasurementType[] = measurements.reduce(
      (acc, measurement) => {
        const def = defMap[measurement.measurementDefinitionId];
        const result = resultMap[measurement.id] || { measurementResult: {} };
        acc.push({
          ...measurement,
          measurementDefinition: def,
          measurementResult: {
            ...measurement.measurementResult,
            ...result.measurementResult,
          },
        });
        return acc;
      },
      []
    );
    return completeMeasurements;
  }
);

export type MeasurementsMap = {
  [key: string]: HangarMeasurementType;
};

export const getMeasurementsMap = createSelector(
  getMeasurements,
  (measurements): MeasurementsMap => {
    return _.keyBy(measurements, 'id');
  }
);

export type CompleteMeasurementsMap = {
  [key: string]: HangarMeasurementType;
};

export const getCompleteMeasurementsMap = createSelector(
  getCompleteMeasurements,
  (completeMeasurements): CompleteMeasurementsMap => {
    return _.keyBy(completeMeasurements, 'id');
  }
);

export const getDashboardLayout = createSelector(
  getCollection,
  getMeasurements,
  (collection, measurements): MeasurementDashboard => {
    const measurementMap = _.keyBy(measurements, 'id');

    let defaultGroup;

    const dashboardGroups: MeasurementDashboardGroup[] = _.get(
      collection,
      'uiData.measurementsDashboardGroups',
      []
    )
      .filter((group) => !!group)
      .map((group) => {
        const mappedGroup = {
          ...group,
          items: group.items.filter((item) => {
            // remove from the map, meaning it isn't part of the default group
            // we are filtering because our UI data might contain deleted measurements
            // so this ensures all of our items are valid measurements
            const measurement = measurementMap[item.measurementId];
            delete measurementMap[item.measurementId];
            return !!measurement;
          }),
        };
        if (mappedGroup.isDefaultGroup) {
          defaultGroup = mappedGroup;
        }
        return mappedGroup;
      });

    if (!defaultGroup) {
      defaultGroup = {
        id: uuid4(),
        name: 'Default Group',
        description: '',
        isDefaultGroup: true,
        items: [],
      };
      dashboardGroups.push(defaultGroup);
    }

    measurements.forEach((m) => {
      if (!measurementMap[m.id]) {
        return;
      }
      defaultGroup.items.push({
        measurementId: m.id,
      });
    });

    return {
      groups: dashboardGroups,
    };
  }
);

type FlatDashboardItem = {
  measurementId: string;
  groupIndex: number;
  groupName: string;
  groupItemCount: number;
};

export const getFlatDashboardLayout = createSelector(
  getDashboardLayout,
  (dashboard): FlatDashboardItem[] => {
    const { groups } = dashboard;

    const ret: FlatDashboardItem[] = [];

    groups.forEach((group) => {
      group.items.forEach((item, index) => {
        ret.push({
          measurementId: item.measurementId,
          groupIndex: index,
          groupName: groups.length > 1 ? group.name : '',
          groupItemCount: group.items.length,
        });
      });
    });
    return ret;
  }
);
