import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { Snackbar, SnackbarContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import SuccessIcon from '@mui/icons-material/CheckCircle';
import cx from 'classnames';

const styles = (theme) => ({
  message: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  icon: {
    paddingRight: 10,
    display: 'flex',
    alignItems: 'center',
  },
  snackbarContent: {
    maxWidth: 1000,
  },
  error: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.error.main,
  },
  success: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.success.main,
  },
});

function ShieldSnackbar({
  classes,
  open,
  handleClose,
  message,
  error,
  key,
  autoHideDuration,
}) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      key={key}
      open={open}
      autoHideDuration={autoHideDuration || (error ? 6000 : 2800)}
      onClose={(event, reason) => {
        if (reason !== 'clickaway') handleClose();
      }}
    >
      <SnackbarContent
        className={error ? classes.error : classes.success}
        classes={{ root: classes.snackbarContent }}
        message={
          <span id="message-id" className={cx(classes.message)}>
            <span className={classes.icon}>
              {error ? <ErrorIcon /> : <SuccessIcon />}
            </span>
            <span> {`${error ? 'Failure' : 'Success'} -`} </span>
            &nbsp;
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={handleClose}
            size="large">
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
}

export default withStyles(styles)(ShieldSnackbar);
