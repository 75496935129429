import { AttributeValueCollectorQueryFilter } from '@shield-ui/query-filters';
import client from '../../../apollo-client';
import { gql } from '@apollo/client';

export default new AttributeValueCollectorQueryFilter({
  id: 'metrics',
  esField: 'metrics',
  controlLabel: 'Metrics',
  description: 'Analysis Metric Outputs, most commonly from Piper',
  fetchFieldOptions: () => {
    return client
      .query({
        query: gql`
          query {
            metricMeta {
              names
            }
          }
        `,
      })
      .then((result) => {
        const names = result.data.metricMeta.names;
        return names.map((name) => {
          return {
            label: name,
            value: name.replace(/\./g, '_'),
          };
        });
      });
  },
});
