import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import CloseIcon from '@mui/icons-material/Close';

import { Typography, IconButton } from '@mui/material';

function styles(theme) {
  return {
    headlineWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(4),
    },
    headline: {
      flex: 1,
      marginRight: 10,
    },
  };
}

function ModalHeader({ classes, onClose, children }) {
  return (
    <div className={classes.headlineWrapper}>
      <Typography variant="h5" className={classes.headline}>
        {children}
      </Typography>
      <IconButton onClick={onClose} size="large">
        <CloseIcon />
      </IconButton>
    </div>
  );
}
ModalHeader.propTypes = {
  onClose: PropTypes.func,
};

export default withStyles(styles)(ModalHeader);
