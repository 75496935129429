import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { alpha } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import CheckBox from '@mui/icons-material/CheckBox';
import CheckBoxOutline from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import { colors } from '@shield-ui/styles';

function styles(theme) {
  return {
    row: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      verticalAlign: 'middle',
      overflow: 'hidden',
      padding: `3px 7px`,
      cursor: 'pointer',
      border: `1px solid transparent`,
      borderRadius: theme.shape.borderRadius,
      color: colors.hues.grays[30],
      '&:hover': {
        backgroundColor: colors.brand.primary,
        color: '#fff',
        '& > $iconChecked': {
          color: colors.brand.primaryShade[40],
        },
      },
      // reset button stuff
      // converted this to a button for keyboard accessibility
      background: 'transparent',
      outline: 0,
      textAlign: 'left',
      '&:focus': {
        borderColor: alpha(colors.brand.primaryShade[80], 0.2),
      },
    },
    rowChecked: {
      color: '#fff',
    },
    rowDisabled: {
      color: colors.hues.grays[80],
      '&:hover': {
        backgroundColor: 'unset',
      },
    },
    rowFocused: {
      border: `1px solid ${theme.palette.action.active}`,
      backgroundColor: theme.palette.action.hover,
    },
    icon: {
      marginRight: 10,
      color: colors.hues.grays[80],
    },
    iconDisabled: {
      opacity: 0.001,
    },
    iconChecked: {
      color: theme.palette.primary.light,
    },
  };
}

class CheckSelectWrapper extends React.Component {
  static propTypes = {
    isChecked: PropTypes.bool,
    isFocused: PropTypes.bool,
    isDisabled: PropTypes.bool,
    onChange: PropTypes.func,
    item: PropTypes.any,
    children: PropTypes.node,
  };

  static defaultProps = {
    isChecked: false,
  };

  onChange = (evt) => {
    const { item, onChange, isDisabled } = this.props;
    if (isDisabled) {
      return;
    }

    onChange(evt, item);
  };

  render() {
    const {
      isChecked,
      isFocused,
      isDisabled,
      classes,
      children,
      focusedOptionRef,
    } = this.props;

    const Icon = isChecked ? CheckBox : CheckBoxOutline;

    return (
      <button
        ref={focusedOptionRef}
        className={cx(
          classes.row,
          isChecked && classes.rowChecked,
          isFocused && classes.rowFocused,
          isDisabled && classes.rowDisabled
        )}
        onClick={this.onChange}
      >
        <Icon
          className={cx(
            classes.icon,
            isChecked && classes.iconChecked,
            isDisabled && classes.iconDisabled
          )}
        />
        {children}
      </button>
    );
  }
}

export default withStyles(styles)(CheckSelectWrapper);
