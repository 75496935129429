import { colors } from '@shield-ui/styles';
import makeStyles from '@mui/styles/makeStyles';
import { ArtifactUploadItem } from './ArtifactUploadItem';
import { UploadArtifactType } from '../UploadFlights/common';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      padding: theme.spacing(0, 2, 4, 2),
      marginBottom: theme.spacing(4),
      '&:last-child': {
        borderBottom: 0,
      },
    },
    formHeader: {
      display: 'flex',
      alignItems: 'center',
      background: 'rgba(0, 0, 0, 0.5)',
      padding: theme.spacing(1),
      minHeight: 48,
      marginLeft: theme.spacing(-1),
    },
    formNumber: {
      fontWeight: 800,
      color: colors.hues.blues[40],
      marginRight: theme.spacing(4),
    },
    label: {
      whiteSpace: 'nowrap',
      fontSize: 16,
      marginTop: theme.spacing(3),
      color: colors.hues.grays[40],
      marginBottom: theme.spacing(1),
    },
    artifactContainer: {
      '& > :first-child': {
        marginTop: theme.spacing(2),
      },
    },
    section: {
      // marginTop: theme.spacing(2),

      maxWidth: 520,
      marginLeft: theme.spacing(2),
    },
    completeCheck: {
      color: colors.hues.green,
      fontSize: 24,
      marginRight: theme.spacing(2),
    },
    logsButton: {
      marginRight: theme.spacing(2),
    },
    goToFlightLink: {
      marginRight: theme.spacing(2),
    },
  };
});

// function getNameTag(fields: UploadArtifactType): React.ReactNode {
//   const { name } = fields;

//   if (!name) {
//     return <Tag color="red">Required</Tag>;
//   } else if (_.find(artifacts.artifacts, (a) => a.file.name === fields.name)) {
//     return <Tag color="blue">Automatic from File</Tag>;
//   } else {
//     return <Tag color="cyan">User Entered</Tag>;
//   }
// }

type ArtifactUploadListProps = {
  artifacts: UploadArtifactType[];
  onChange: (artifacts: UploadArtifactType[]) => void;
  isSubmitting: boolean;
  showProgress: boolean;
};

export const ArtifactUploadList = ({
  artifacts,
  onChange,
  isSubmitting,
  showProgress,
}: ArtifactUploadListProps) => {
  const classes = useStyles();

  return (
    <>
      {artifacts.map((artifact, index) => {
        return (
          <ArtifactUploadItem
            key={index}
            artifact={artifact}
            isSubmitting={isSubmitting}
            showProgress={showProgress}
            onRemove={() => {
              const newArtifacts = artifacts.filter((_, j) => j !== index);
              onChange(newArtifacts);
            }}
            onChangeArtifactType={(artifactTypeSlug: string) => {
              const newArtifacts = artifacts.map((artifact, j) => {
                if (j !== index) {
                  return artifact;
                }

                return {
                  ...artifact,
                  artifactTypeSlug: artifactTypeSlug,
                };
              });
              onChange(newArtifacts);
            }}
          />
        );
      })}
    </>
  );
};
