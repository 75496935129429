import React from 'react';
import { Menu, ListItemIcon, MenuItem, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from '@shield-ui/styles';

const useStyles = makeStyles((theme) => ({
  menu: {
    // above tooltip
    zIndex: 1600,
  },
  menuItem: {
    padding: theme.spacing(0.5, 1),
  },
  listItemText: {
    fontSize: '1rem',
  },
  listItemIcon: {
    minWidth: 36,
    color: colors.hues.blues[40],
  },
  title: {
    fontSize: 12,
    color: colors.hues.grays[50],
    padding: theme.spacing(0, 3, 0.5, 1.5),
    marginBottom: theme.spacing(1.5),
    borderBottom: `1px solid rgba(255, 255, 255, 0.075)`,
  },
}));

export interface SimpleMenuItem {
  label: string;
  onClick: () => void;
  Icon?: React.ElementType;
  description?: string;
  disabled?: boolean;
}

interface Props {
  title?: string;
  anchorEl?: HTMLElement;
  menuItems: SimpleMenuItem[];
  onClose: (evt) => void;
}

export function SimpleMenu(props: Props) {
  const { anchorEl, title, menuItems, onClose } = props;

  const classes = useStyles();

  return (
    <Menu
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      className={classes.menu}
      disableAutoFocusItem
    >
      {title && <div className={classes.title}>{title}</div>}
      {menuItems.map((menuItem) => {
        return (
          <MenuItem
            className={classes.menuItem}
            key={menuItem.label}
            disabled={menuItem.disabled}
            onClick={(evt) => {
              onClose(evt);
              menuItem.onClick();
            }}
          >
            {menuItem.Icon && (
              <ListItemIcon className={classes.listItemIcon}>
                <menuItem.Icon />
              </ListItemIcon>
            )}
            <div className={classes.listItemText}>
              <div>{menuItem.label}</div>
              {menuItem.description && (
                <Typography variant="body2">{menuItem.description}</Typography>
              )}
            </div>
          </MenuItem>
        );
      })}
    </Menu>
  );
}
