import { gql } from '@apollo/client';
import _ from 'lodash';
import client from '../apollo-client';
import { FetchResult } from '@apollo/client';
import tracker from '../lib/tracker';
import {
  HangarMutations,
  HangarMutationsCreateRuleSetArgs,
  HangarMutationsDeleteRuleSetArgs,
  HangarMutationsUpdateRuleSetArgs,
} from '@shield-ui/hangar-service';

const ruleSetFragment = `
  id
  name
  robotLogSearchVariables
  searchVersion
`;

const commonCreateUpdateVariablesFragment = `
  name: $name,
  robotLogSearchVariables: $robotLogSearchVariables,
`;

const createRuleSetMutation = gql`
  mutation createRuleSet(
    $name: String,
    $robotLogSearchVariables: JSONString!,
    $searchVersion: RuleSetSearchVersion,
  ) {
    createRuleSet(${commonCreateUpdateVariablesFragment}, searchVersion: $searchVersion) {
      ${ruleSetFragment}
    }
  }
`;
const updateRuleSetMutation = gql`
  mutation updateRuleSet(
    $id: UUID!,
    $name: String,
    $robotLogSearchVariables: JSONString,
  ) {
    updateRuleSet(id: $id,${commonCreateUpdateVariablesFragment}) {
      ${ruleSetFragment}
    }
  }
`;
const deleteRuleSetMutation = gql`
  mutation deleteRuleSet($id: String!) {
    deleteRuleSet(id: $id) {
      id
    }
  }
`;

export const mutations = {
  create: (
    variables: HangarMutationsCreateRuleSetArgs
  ): Promise<FetchResult<Pick<HangarMutations, 'createRuleSet'>>> =>
    client.mutate({
      mutation: createRuleSetMutation,
      variables,
    }),
};

function runMutation({ variables, mutation, event, callback = _.noop }) {
  return client
    .mutate({ mutation, variables })
    .then((res) => {
      callback(null, res);
      tracker.trackEvent('RuleSet', event);
      return res;
    })
    .catch((err) => {
      callback(err);
    });
}

export function createRuleSet(
  variables: HangarMutationsCreateRuleSetArgs,
  callback = _.noop
) {
  return runMutation({
    variables,
    mutation: createRuleSetMutation,
    event: 'RuleSetCreate',
    callback,
  });
}

export function updateRuleSet(
  variables: HangarMutationsUpdateRuleSetArgs,
  callback
) {
  return runMutation({
    variables,
    mutation: updateRuleSetMutation,
    event: 'RuleSetUpdate',
    callback,
  });
}

export function deleteRuleSet(
  variables: HangarMutationsDeleteRuleSetArgs,
  callback
) {
  return runMutation({
    variables,
    mutation: deleteRuleSetMutation,
    event: 'RuleSetDelete',
    callback,
  });
}
