import {Select, Radio, Form, Input} from 'antd';
import { MetricAnalysisSelect } from '../metricAnalysisSelect';

export const DataOverTimeForm = () => {
	return (
		<div>			
			<Form.Item label="Plot Title" name="title" requiredMark={"optional"}>
				<Input/>
			</Form.Item>
			{MetricAnalysisSelect("multiple")}
			<Form.Item label="Aggregation Type" name="aggType" rules={[{ required: true, message: 'Please choose at least one aggregation method'}]}>
				<Select
					mode="multiple"
					allowClear
					placeholder="Aggregations"
					style={{
						width: '100%'
					}}
				>
					<Select.OptGroup key={"stats"} label="Stats">
						<Select.Option value="sum">Sum</Select.Option>
						<Select.Option value="avg">Avg</Select.Option>
						<Select.Option value="min">Min</Select.Option>
						<Select.Option value="max">Max</Select.Option>
					</Select.OptGroup>
					<Select.OptGroup key={"percentiles"} label="Percentiles">
						<Select.Option value="99">99th</Select.Option>
						<Select.Option value="95">95th</Select.Option>
						<Select.Option value="50">50th</Select.Option>
						<Select.Option value="5">5th</Select.Option>
						<Select.Option value="1">1st</Select.Option>
					</Select.OptGroup>
				</Select>
			</Form.Item>
			<Form.Item label="Group By" name="groupByTime">
				<Radio.Group name="radioTime" >
					<Radio.Button value="year">Year</Radio.Button>
					<Radio.Button value="month">Month</Radio.Button>
					<Radio.Button value="week">Week</Radio.Button>
					<Radio.Button value="day">Day</Radio.Button>
				</Radio.Group>
			</Form.Item>
			<Form.Item label="Second Breakdown" name="secondBreakdown" requiredMark={"optional"} >
				<Select allowClear>
					<Select.Option value="robots.name">Robot Name</Select.Option>
					<Select.Option value="phones.name">Phone Name</Select.Option>
					<Select.Option value="software_info.branch_name">Software Branch Name</Select.Option>
					<Select.Option value="test_definition.code">Test Definition Code</Select.Option>
				</Select>
			</Form.Item>
			<Form.Item label="Graph Type" name="graphType">
				<Radio.Group name="radioGraphType"  >
					<Radio.Button value="line">Line</Radio.Button>
					{/* <Radio.Button value="scatter">Scatter</Radio.Button> */}
					<Radio.Button value="area">Area</Radio.Button>
					<Radio.Button value="bar">Bar</Radio.Button>
				</Radio.Group>
			</Form.Item>
		</div>
	)
}