import React from 'react';
import cx from 'classnames';
import withStyles from '@mui/styles/withStyles';

function styles() {
  return {
    label: {},
    labelIsDisabled: {
      opacity: 0.8,
    },
    labelIsHighlighted: {
      fontWeight: 600,
    },
  };
}

function Label(props) {
  const { isSearchMatch, isDisabled, classes, children } = props;

  return (
    <span
      className={cx(
        classes.label,
        isSearchMatch && classes.labelIsHighlighted,
        isDisabled && classes.labelIsDisabled
      )}
    >
      {children}
    </span>
  );
}

export default withStyles(styles)(Label);
