import { MultiselectQueryFilter } from '@shield-ui/query-filters';
import { robotLogDocumentFields } from './fields';

export default new MultiselectQueryFilter({
  id: 'parentNames',
  controlLabel: 'Parents',
  esField: robotLogDocumentFields['parents.name'].field,
  isMulti: true,
  behaviorConfig: {
    isCreatable: true,
  },
});
