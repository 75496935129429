import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { LoadingIndicator } from '@shield-ui/core';
import AppShell from '../components/AppShell/AppShell';
import PrivateRouteGate from './PrivateRouteGate';
//import ReleaseNotesController from '../components/AppShell/ReleaseNotesController';

import RobotLogsPage from './RobotLogsPage/Page';
import RobotLogPage from './RobotLog/RobotLogPage';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const CollectionCreatePage = lazy(
  () => import('./CollectionCreate/CollectionCreatePage')
);
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const CollectionsPage = lazy(() => import('./Collections/CollectionsPage'));
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const CollectionDiagnosticsPage = lazy(
  () => import('./CollectionDiagnostics/CollectionDiagnosticsPage')
);
const CollectionPage = lazy(() => import('./Collection/CollectionPage'));

const ConfigurationPage = lazy(
  () => import('./Configuration/ConfigurationPage')
);
const ConfigurationCreatePage = lazy(
  () => import('./Configuration/ConfigurationCreatePage')
);
const ConfigurationEditPage = lazy(
  () => import('./Configuration/ConfigurationEditPage')
);
const PolicyEditPage = lazy(
  () => import('./Configuration/Policy/ConfigPolicyEditPage')
);
const PolicyPage = lazy(
  () => import('./Configuration/Policy/ConfigPolicyPage')
);
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const RobotLogDiagnosticsPage = lazy(
  () => import('./RobotLogDiagnostics/RobotLogDiagnosticsPage')
);
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const AdminPage = lazy(() => import('./Admin/AdminPage'));
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const ImportPage = lazy(() => import('./Import/ImportPage'));
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const UploadFlightsPage = lazy(() => import('./UploadFlights/Page'));
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const VBATFlightUploadPage = lazy(() => import('./VBATFlightUpload/Page'));
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const ArtifactsExplorerPage = lazy(() => import('./ArtifactsExplorer/Page'));
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const FeatureFlagsPage = lazy(() => import('./FeatureFlags/FeatureFlags'));

const TestDefinitionsPage = lazy(
  () => import('./TestDefinitions/TestDefinitionsPage')
);
const TestDefinitionPage = lazy(() => import('./TestDefinition/Page'));

const FlightRedirect = ({ match }) => {
  if (match.params.tabRoute) {
    return (
      <Redirect
        to={`/app/rl/${match.params.robotLogIdOrName}/${match.params.tabRoute}`}
      />
    );
  }
  return <Redirect to={`/app/rl/${match.params.robotLogIdOrName}`} />;
};
const RobotLogsToSessionLogsRedirect = ({ match }) => {
  const robotLogType = match.params.robotLogType;

  const rlOverideTypeToSlug = {
    'test-trial': 'trial',
    'test-instance': 'test',
  };

  const slug = rlOverideTypeToSlug[robotLogType] || robotLogType;
  return <Redirect to={`/app/session-logs/${slug}`} />;
};

function Routes() {
  return (
    <PrivateRouteGate>
      <AppShell>
        {/*<ReleaseNotesController />*/}
        <Suspense fallback={<LoadingIndicator />}>
          <Switch>
            {/* COLLECTION BASED CONTENT */}
            <Route
              path="/app/collections/create"
              component={CollectionCreatePage}
            />
            <Route
              path="/app/collections/(my-favorites|assigned|all|vil|bring-up-verification|fts|system-presets)"
              component={CollectionsPage}
            />
            <Route
              path="/app/collections/:collectionId/diagnostics"
              component={CollectionDiagnosticsPage}
            />
            <Route
              path="/app/collections/:collectionId/:subRoute?"
              component={CollectionPage}
            />
            <Route path="/app/collections" component={CollectionsPage} />
            {/* CONFIGURATION BASED CONTENT */}

            <Route
              path="/app/configuration/policy/:policyId"
              component={PolicyEditPage}
            />
            <Route path="/app/configuration/policy" component={PolicyPage} />
            <Route
              path="/app/configuration/:configId/version/:configVersionId"
              component={ConfigurationEditPage}
            />
            <Route
              path="/app/configuration/create"
              component={ConfigurationCreatePage}
            />

            <Route
              path="/app/configuration/:configId"
              component={ConfigurationEditPage}
            />

            <Route path="/app/configuration" component={ConfigurationPage} />
            {/* ROBOT LOG CONTENT */}
            <Route
              path="/app/rl/:robotLogId/diagnostics"
              component={RobotLogDiagnosticsPage}
            />
            <Route
              path="/app/session-logs/:sessionLogTypeSlug"
              component={RobotLogsPage}
            />
            <Route
              path="/app/robot-logs/:robotLogType"
              component={RobotLogsToSessionLogsRedirect}
            />
            <Route
              path="/app/rl/:robotLogIdOrName/:tabRoute?"
              component={RobotLogPage}
            />
            <Route
              path="/app/flights/:robotLogIdOrName/:tabRoute?"
              component={FlightRedirect}
            />
            <Route
              path="/app/flights/:robotLogIdOrName"
              component={FlightRedirect}
            />
            <Route
              path="/app/test-definitions/:code/:version?"
              component={TestDefinitionPage}
            />
            <Route
              path="/app/test-definitions"
              component={TestDefinitionsPage}
            />
            {/* OTHER PAGES */}
            <Route path="/app/import" component={ImportPage} />
            <Route path="/app/upload-flights" component={UploadFlightsPage} />
            <Route
              path="/app/upload-vbat-flights"
              component={VBATFlightUploadPage}
            />
            <Route
              path="/app/artifacts-explorer"
              component={ArtifactsExplorerPage}
            />
            <Route path="/app/admin/:subRoute?" component={AdminPage} />
            <Route path="/app/feature-flags" component={FeatureFlagsPage} />

            {/* REDIRECT OLD ROBOT_LOGS pages to new common one, still need to move Flights to this list as well*/}
            <Redirect
              path="/app/test-results"
              to="/app/robot-logs/test-instance"
            />
            <Redirect
              path="/app/boot-sessions"
              to="/app/robot-logs/boot-session"
            />
            <Redirect path="/app/flights" to="/app/robot-logs/flight" />
            <Redirect to="/app/flights" />
          </Switch>
        </Suspense>
      </AppShell>
    </PrivateRouteGate>
  );
}

export default Routes;
