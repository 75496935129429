import { MultiselectQueryFilter } from '@shield-ui/query-filters';
import { getSessionLogTypeOptions } from '../../../services/sessionLogTypes';

export default new MultiselectQueryFilter({
  id: 'sessionLogTypes',
  controlLabel: 'Session Log Types',
  esField: 'session_log_type_slug',
  unsetValueDisabled: true,
  behaviorConfig: {
    fetchOptions: () => {
      return getSessionLogTypeOptions();
    },
  },
});
