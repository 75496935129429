import _ from 'lodash';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { ensureAllMetricNames } from '../../../redux/lists/actions';

function mapStateToProps(state) {
  return {
    metricNames: _.get(state, 'lists.listTypeMap.metricNames', []),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ensureAllMetricNames,
    },
    dispatch
  );
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ContainerProps = ConnectedProps<typeof connector>;
export default connector;
