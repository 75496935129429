import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '500px',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

const EmptyExpansion = () => {
  const classes = useStyles();
  return <div className={classes.container} />;
};

export default EmptyExpansion;
