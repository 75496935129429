import React, { useCallback } from 'react';
import Switch from '@mui/material/Switch';
import { InputGroup, Select, TextField } from '@shield-ui/controls';
import TicketSelect from '../connected-controls/tickets/TicketSelect';
import TeamSelect from '../connected-controls/TeamSelect/TeamSelect';
import { VALID_FLIGHT_TYPES_OPTIONS } from '../../services/robotLogs';
import { getReviewStatusFilterOptions } from '../../services/reviews';
import TagSelect from '../connected-controls/TagSelect/TagSelect';
import SeveritySelect from '../connected-controls/SeveritySelect/SeveritySelect';
import AnalysisNameSelect from '../connected-controls/AnalysisNameSelect/AnalysisNameSelect';
import SessionLogTypeSelect from '../connected-controls/SessionLogTypeSelect';
import MetricNameSelect from '../connected-controls/MetricNameSelect/MetricNameSelect';
import LogDefinitionSelect from '../connected-controls/diagnostics/LogDefinitionSelect';
import ComponentSelect from '../connected-controls/diagnostics/ComponentSelect';
import ComponentOwnerSelect from '../connected-controls/diagnostics/ComponentOwnerSelect';
import { semanticDataTypes, getSemanticDataLabel } from './defs';
import _ from 'lodash';

type Props = {
  options?: {
    parameter?: string;
    dataType?: string;
    required?: boolean;
    label?: string;
  }[];
  values?: object;
  onChange: (args: object) => void;
};

function SemanticForm(props: Props) {
  const { options = [], values = {}, onChange } = props;

  if (!options.length) {
    return null;
  }

  const setValue = (parameter, value) => {
    onChange({
      ...values,
      [parameter]: value,
    });
  };

  return (
    <>
      {options.map((option) => {
        const { parameter, dataType, required } = option;

        if (!parameter || !dataType) {
          return null;
        }

        const value = values[parameter];
        const label = option.label || getSemanticDataLabel(dataType);

        const selectOnChange = (opt) => {
          setValue(parameter, opt ? opt.value : undefined);
        };

        let control;

        switch (dataType) {
          case semanticDataTypes.string:
            control = (
              <TextField
                onChange={(evt) => setValue(parameter, evt.target.value)}
                value={value || ''}
              />
            );
            break;
          case semanticDataTypes.bool:
            control = (
              <Switch
                color="primary"
                checked={value || false}
                onChange={(evt, checked) => setValue(parameter, checked)}
              />
            );
            break;
          case semanticDataTypes['RobotLog.session_log_type_slug']:
            control = (
              <SessionLogTypeSelect onChange={selectOnChange} value={value} />
            );
            break;
          case semanticDataTypes['RobotLogReview.review_status']:
            control = (
              <Select
                suggestions={getReviewStatusFilterOptions()}
                placeholder="Review Status"
                onChange={selectOnChange}
                value={value}
              />
            );
            break;
          case semanticDataTypes['RobotLogData.flightType']:
            control = (
              <Select
                placeholder="Flight Type"
                suggestions={VALID_FLIGHT_TYPES_OPTIONS}
                onChange={selectOnChange}
                value={value}
              />
            );
            break;
          case semanticDataTypes['Team.id']:
            control = (
              <TeamSelect
                placeholder="Select a team"
                onChange={selectOnChange}
                value={value}
              />
            );
            break;
          case semanticDataTypes['Ticket.platform_id']:
            control = (
              <TicketSelect
                placeholder="Find a ticket"
                onChange={(value) => setValue(parameter, value)}
                value={value}
              />
            );
            break;
          case semanticDataTypes['Tag.id']:
            control = (
              <TagSelect
                placeholder="Select a failure type"
                onChange={selectOnChange}
                value={value}
              />
            );
            break;
          case semanticDataTypes['Severity.id']:
            control = (
              <SeveritySelect
                placeholder={`Select a ${label.toLowerCase()}`}
                onChange={selectOnChange}
                value={value}
              />
            );
            break;
          case semanticDataTypes['Analysis.name']:
            control = (
              <AnalysisNameSelect
                placeholder={`Select an ${label.toLowerCase()}`}
                value={value}
                onChange={selectOnChange}
              />
            );
            break;
          case semanticDataTypes['Metric.name']:
            control = (
              <MetricNameSelect
                placeholder={`Select a ${label.toLowerCase()}`}
                value={value}
                onChange={selectOnChange}
              />
            );
            break;
          case semanticDataTypes['LogEvent.component_external_id']:
            control = (
              <ComponentSelect value={value} onChange={selectOnChange} />
            );
            break;
          case semanticDataTypes['LogEvent.component_owner']:
            control = (
              <ComponentOwnerSelect value={value} onChange={selectOnChange} />
            );
            break;
          case semanticDataTypes['LogEvent.log_definition_external_id']:
            control = (
              <LogDefinitionSelect value={value} onChange={selectOnChange} />
            );
            break;
        }

        // to capture new things that have been added but not implemented
        if (!control) {
          control = (
            <div>
              Needs Implemented: {parameter}:{dataType}
            </div>
          );
        }

        return (
          <InputGroup key={parameter} label={label} required={required}>
            {control}
          </InputGroup>
        );
      })}
    </>
  );
}

export default SemanticForm;
