import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { logDefinitionOptionsSelector } from '../../../redux/lists/selectors';
import { ensureLogDefinitions } from '../../../redux/lists/actions';

function mapStateToProps(state) {
  return {
    logDefinitionOptions: logDefinitionOptionsSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ensureLogDefinitions,
    },
    dispatch
  );
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ContainerProps = ConnectedProps<typeof connector>;
export default connector;
