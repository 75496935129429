import { makeStyles } from '@mui/styles';
import { Col, Row, Space, Typography } from 'antd';
import React from 'react';
import { useMemo } from 'react';
import { convertMetadataToFields } from '../../services/passThruSearch';
import { VBATMetadata } from '../../services/robotLogs';

const useStyles = makeStyles((theme) => {
  return {
    metadataContainer: {
      paddingBottom: theme.spacing(2),
    },
    metadataTable: {
      tableLayout: 'fixed',
      width: '100%',
      borderCollapse: 'collapse',
      fontSize: 12,
      '& tr': {
        borderBottom: '1px solid',
        borderBottomColor: theme.palette.divider,
        height: 40,
        '& td': {
          padding: '8px 8px',
          overflowWrap: 'break-word',
          '&:first-child': {
            width: '30%',
          },
        },
      },
    },
  };
});

type MetadataTableViewProps = {
  metadata: VBATMetadata | any;
  includeTitle?: boolean;
};

export const MetadataView: React.FC<MetadataTableViewProps> = ({
  metadata,
  includeTitle = true,
}) => {
  const classes = useStyles();

  const metadataFields = useMemo(() => {
    return convertMetadataToFields(metadata);
  }, [metadata]);

  return (
    <Row gutter={16} className={classes.metadataContainer}>
      <Col className="gutter-row" span={24}>
        {includeTitle && (
          <>
            <Typography.Title level={3}>Metadata</Typography.Title>
          </>
        )}
        {metadataFields.length === 0 ? (
          <Typography.Text type="secondary">
            No metadata available
          </Typography.Text>
        ) : (
          <table className={classes.metadataTable}>
            <tbody>
              {metadataFields.map((field) => (
                <tr key={field.metadata_key}>
                  <td>{field.metadata_key}</td>
                  <td>{field.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </Col>
    </Row>
  );
};
