import React from 'react';
import _ from 'lodash';
import withStyles from '@mui/styles/withStyles';
import { Link } from '@mui/material';

function styles() {
  return {
    row: {
      marginTop: 8,
      marginBottom: 8,
    },
  };
}

export function getTicketsForRow(row) {
  const events = _.get(row, 'events.edges', []);

  return events.reduce((acc, edge) => {
    const event = edge.node;
    if (event.tickets) {
      acc = acc.concat(event.tickets);
    }
    return acc;
  }, []);
}

function TicketCell({ row, classes }) {
  const tickets = getTicketsForRow(row);

  return tickets.map((ticket, i) => (
    <div key={i} className={classes.row}>
      <Link href={ticket.link} target="_blank" underline="hover">
        {ticket.name}
      </Link>
    </div>
  ));
}

export default withStyles(styles)(TicketCell);
