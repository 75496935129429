import { TypedEventEmitter } from '@shield-ui/utils';
import { QueryFilterTypes } from './common';

/*
  - How to render the control is part of the QueryFilter definition, but can be overloaded? How to overload.
  - Do we need a "universal controller" or is that on an integrator to build
 */

export interface BaseFilterValue {
  esQuery?: object | void;
}

export interface BaseFilterProps {
  id: string;
  controlLabel: string;
  description?: string;
  esField?: string;
}

export interface BaseQueryFilterEvents {
  updatedControlData: object | any;
  internalValueChange: object;
}

function abstractError(methodName: string) {
  throw new Error(
    `BaseQueryFilter method ${methodName} should be considered abstract and must be overloaded by each queryFilter implementation`
  );
}

export class BaseQueryFilter<
  Value extends BaseFilterValue = BaseFilterValue,
  Props extends BaseFilterProps = BaseFilterProps,
  ControlData = Record<string, never> // default is empty object, overloaded by specific query filters
> {
  type = QueryFilterTypes.unset;

  // controlData represents additional fields necessary to draw user inputs, the classic   example is a list of items for a multiselect
  _controlData: ControlData = undefined;
  // all, un-mutated properties as they were passed in
  props: Partial<Props> = {};
  // an event emitter for notifying integrators when internal state changes
  events: TypedEventEmitter<BaseQueryFilterEvents>;

  constructor(props: Props) {
    this.props = props;
    this._controlData = this.initControlData(props);
    this.events = new TypedEventEmitter<BaseQueryFilterEvents>();
  }

  // fired on instantiation to compute all controlData known at creation time
  // subsequent updates will call setControlData and which fires updates on our event emitter
  // anything can be exposed in controlData, properties pass in, defaults, methods to calculating things
  initControlData(props: Props): ControlData {
    abstractError('initControlData');
    return {} as ControlData;
  }

  getType(): QueryFilterTypes {
    return this.type;
  }

  // if a query filter type has a chance of firing this event
  // it should implement this method and return true
  // Note: that this function must return the same value for the life-time of the query filter
  // it cannot have any runtime changes (e.g. true for a while and then changes to false)
  getImplementsInternalValueChange(): boolean {
    return false;
  }

  getId(): string {
    return this.props.id;
  }

  getDescription(): string {
    return this.props.description || '';
  }

  getControlData(): ControlData {
    return this._controlData;
  }

  hasControlData(): boolean {
    return true;
  }

  setControlData(d: Partial<ControlData>): void {
    // merge. A control should only fetch once but why not
    if (d === this._controlData) {
      return;
    }

    this._controlData = {
      ...this._controlData,
      ...d,
    };
    this.events.emit('updatedControlData', this.getControlData());
  }

  // memoized so multiple implementers only trigger fetch one time
  ensureControlData(value: Value): Promise<ControlData> {
    return Promise.resolve(this.getControlData());
  }

  getControlLabel(): string {
    return this.props.controlLabel;
  }

  // This should return void/undefined if we "do not have a value"
  getElasticQuery(value: Value): object | void {
    return;
  }

  hasValue(value: Value): boolean {
    return !!value;
  }

  getUnsetValue() {
    return undefined;
  }

  /**
   * Value + ControlData allow us to intelligently render a preview or a chip based on this control.
   */
  getValuePreview(value: Value): string {
    abstractError('getValuePreview');
    return '';
  }
}
