import _ from 'lodash';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { queryClient } from '../queryClient';
import { UserService, ListRequest, User } from '@hmd/sdk/api/iam/users/v1';
import sdkClient from '../lib/hmdsdkClient';
import store from '../redux/store';
import { MultiselectOption } from '@shield-ui/query-filters';

// memoized because things in common should have multiple filters that use
// this ensures we only make the request once

const fetchAllUsers = async (): Promise<User.AsObject[]> => {
  const users: User.AsObject[] = [];
  const pageSize = 200;

  let token = null;

  while (token !== '') {
    // If token is empty, we're done
    if (token === '') {
      return users;
    }
    const sr = new ListRequest();

    // If token is null, it's the first request
    if (token !== null) {
      sr.setPageToken(token);
    }
    sr.setPageSize(pageSize);

    // Append to user list and set nextPageToken
    let nextToken = '';
    await sdkClient.unary(UserService.List, sr).then((res) => {
      nextToken = res.getNextPageToken();
      const fetchedUsers = res.getUsersList();
      for (const u of fetchedUsers) {
        users.push(u.toObject());
      }
    });
    token = nextToken;
  }
  return _.sortBy(users, 'name');
};

const USERS_QUERY_KEY = 'users';

//-----------------
// Keep the params dry between the two places that use this
// so cache is clean
function getFetchUsersQueryClientParams() {
  return {
    queryKey: [USERS_QUERY_KEY],
    queryFn: () => fetchAllUsers(),
  };
}

// hook for getting all users
export const useUsers = (): UseQueryResult<User.AsObject[]> =>
  useQuery(getFetchUsersQueryClientParams());

// for working with this as a promise
export const getUsers = (): Promise<User.AsObject[]> =>
  queryClient.fetchQuery(getFetchUsersQueryClientParams());

//-----------------
function mapUserOptions() {
  return {
    queryKey: [USERS_QUERY_KEY, 'options'],
    queryFn: async () => {
      const res = await getUsers();
      const currentUser = store.getState().authUser.currentUser;

      const opts = res.reduce((result, user) => {
        if (currentUser.id === user.id) {
          result.unshift({
            value: user.id,
            label: `${user.name} (Me)`,
          });
        } else {
          result.push({
            value: user.id,
            label: user.name,
          });
        }
        return result;
      }, []);

      return opts;
    },
  };
}

//Promise for user options
export const getUserOptions = (): Promise<MultiselectOption[]> =>
  queryClient.fetchQuery(mapUserOptions());

//Hook for working with user options
export const useUserOptions = (): UseQueryResult<MultiselectOption[]> =>
  useQuery(mapUserOptions());
