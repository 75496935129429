import { useSessionLogMetadata } from '../../services/passThruSearch';
import { SessionLogProduct } from '../../services/robotLogs';
import { MetadataKeyInputProps } from './MetadataEditableRow';
import { MetadataKeyInput } from './MetadataKeyInput';

export const SessionLogMetadataKeyInput: React.FC<MetadataKeyInputProps> = ({
  value = '',
  onChange,
  excludedKeys = [],
  productType = SessionLogProduct.UNKNOWN,
  platform_id,
  ...props
}) => {
  const { data: metadata } = useSessionLogMetadata(productType);

  return (
    <MetadataKeyInput
      metadata={metadata}
      value={value}
      filterOption={true}
      onChange={onChange}
      platform_id={platform_id}
      productType={productType}
      excludedKeys={excludedKeys}
      {...props}
    />
  );
};
