import { TypeToFindMultiselectQueryFilter } from '@shield-ui/query-filters';
import { queryCollections } from '../../../services/collections';
//import { ellipsesStringAt } from "@shield-ui/utils";

function processResponse(res) {
  return res.data.collections.edges.map((e) => {
    return {
      value: e.node.id,
      label: e.node.name,
      //description: e.node.name !== e.node.description ? ellipsesStringAt(e.node.description, 80) : undefined,
    };
  });
}

function getOptions({ values }) {
  return queryCollections({
    ids: values,
  }).then((res) => {
    return processResponse(res);
  });
}

function searchSuggestedOptions() {
  return queryCollections({
    sort: 'last_robot_logs_refresh_at_desc',
    first: 10,
  }).then((res) => {
    return processResponse(res);
  });
}

function searchOptions({ input }) {
  return queryCollections({
    textLike: input,
    first: 10,
  }).then((res) => {
    return processResponse(res);
  });
}

export default new TypeToFindMultiselectQueryFilter({
  id: 'collections',
  controlLabel: 'Collections',
  esField: 'collections.id',
  isMulti: true,
  getOptions: getOptions,
  searchSuggestedOptions: searchSuggestedOptions,
  searchOptions: searchOptions,
});
