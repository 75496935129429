import PlotlyPlotArtifact from '../../../components/pipelines/PlotlyPlotArtifact';
import { Artifact } from '@hmd/sdk/api/artifacts/v1';

type Props = {
  artifact: Artifact.AsObject;
};

export default function PlotlyPlotJSONExpansion({ artifact }: Props) {
  return <PlotlyPlotArtifact artifactId={artifact.id} />;
}
