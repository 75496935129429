import {
  StorageTierRuleSet,
  StorageTierRule,
  StorageTier,
  StorageTierRuleSetService,
  UpdateRequest,
  SearchRequest,
} from '@hmd/sdk/api/artifacts/storage_tier_rule_set/v1';
import sdkClient from '../lib/hmdsdkClient';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { FieldMask } from 'google-protobuf/google/protobuf/field_mask_pb';
import _ from 'lodash';

const STORAGE_TIER_RULE_SETS_QUERY_KEY = 'storageTierRuleSets';

export const getStorageTier = (storageTier: number) => {
  return _.upperCase(
    Object.keys(StorageTier).find((key) => StorageTier[key] === storageTier) ||
      'Unknown'
  );
};

export const fetchStorageTierRuleSets = async () => {
  const req = new SearchRequest();
  req.setPageSize(200);
  const response = await sdkClient.query(StorageTierRuleSetService.Search, req);
  const responseObj = response.toObject();
  const storageTierRuleSets = responseObj.ruleSetsList;
  
  let potentialNextPageToken = responseObj.nextPageToken;
  while (potentialNextPageToken) {
    req.setPageToken(potentialNextPageToken);
    const moreResults = await sdkClient.query(
      StorageTierRuleSetService.Search,
      req
    );
    const moreResultsObj = moreResults.toObject();
    const moreRuleSets = moreResultsObj.ruleSetsList;
    storageTierRuleSets.push(...moreRuleSets);
    potentialNextPageToken = moreResultsObj.nextPageToken;
  }
  
  return storageTierRuleSets;
};

export const useStorageTierRuleSets = () =>
  useQuery({
    queryKey: [STORAGE_TIER_RULE_SETS_QUERY_KEY],
    queryFn: () => fetchStorageTierRuleSets(),
  });

type NewStorageTierRuleSet = {
  id?: string;
  rulesList?: Array<StorageTierRule>;
};

//this updates the storageTierRules THEMSELVES, not the storageTiers on the artifacts
export const updateStorageTierRuleSets = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({ id, rulesList = [] }: NewStorageTierRuleSet) => {
      const req = new UpdateRequest();
      const storageTierRuleSet = new StorageTierRuleSet();
      storageTierRuleSet.setId(id);
      storageTierRuleSet.setStorageTierRulesList(rulesList);
      req.setRuleSet(storageTierRuleSet);

      const mask = new FieldMask();
      mask.setPathsList(['storage_tier_rules']);
      req.setUpdateMask(mask);

      const response = await sdkClient.query(
        StorageTierRuleSetService.Update,
        req
      );
      return response;
    },
    onError: (error) => {
      return error;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [STORAGE_TIER_RULE_SETS_QUERY_KEY],
      });
    },
  });
  return mutation;
};

