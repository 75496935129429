import React from 'react';
import withStyles from '@mui/styles/withStyles';
import FileCopy from '@mui/icons-material/FileCopy';

const styles = () => {
  return {
    container: {
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: 4,
      marginRight: 2,
      fontSize: 10,
      transition: '.2s ease',
      '&:hover': {
        textShadow: `0 0 4px rgba(255, 255, 255, .6)`,
      },
    },
    icon: {
      marginLeft: 3,
      fontSize: 16,
    },
  };
};

function CopyTimeButton({ onClick, classes }) {
  return (
    <div onClick={onClick} className={classes.container}>
      <div>
        <div>Copy</div>
        <div>Secs</div>
      </div>
      <FileCopy fontSize="inherit" classes={{ root: classes.icon }} />
    </div>
  );
}

export default withStyles(styles)(CopyTimeButton);
