import InfoSection from '../../components/InfoSection';
import { formatBytes } from '@shield-ui/utils';
import { getLocalDateTime } from '../../lib/connectedUtils';
import { Artifact } from '@hmd/sdk/api/artifacts/v1';
import { User } from '@hmd/sdk/api/iam/users/v1';
import { useArtifactTypes, useUser } from '../../services/newArtifacts';
import { ArtifactType } from '@hmd/sdk/api/artifacts/type/v1';

function getRows(
  artifact: Artifact.AsObject,
  user: User.AsObject,
  showPipelineExecution: (id: string) => void,
  artifactTypes: ArtifactType.AsObject[]
) {
  const rows = [];

  const artifactTypeName =
    artifactTypes.find((type) => type.slug === artifact.artifactTypeSlug)
      ?.name ?? 'File';

  if (artifactTypeName !== '') {
    rows.push({
      display: 'Type',
      value: artifactTypeName,
    });
  }
  if (artifact.size) {
    rows.push({
      display: 'Size',
      value: formatBytes(artifact.size),
    });
  }
  if (user) {
    rows.push({
      display: 'Creator',
      value: user.name,
    });
  }

  return rows;
}

type ArtifactMetaType = {
  artifact: Artifact.AsObject;
  showPipelineExecution?: (id: string) => void;
};

function ArtifactMeta(props: ArtifactMetaType) {
  const { data: user, error } = useUser(
    props.artifact.lifeCycleDetails.createUserId
  );

  const { data: artifactTypes } = useArtifactTypes();

  return (
    <InfoSection
      title="Details"
      rows={getRows(
        props.artifact,
        user,
        props.showPipelineExecution,
        artifactTypes
      )}
    />
  );
}

export default ArtifactMeta;
