import React, { useState } from 'react';
import MiniTabsController from './MiniTabsController';

interface Props {
  isMulti: boolean;
  renderIncludes: () => React.ReactNode | void;
  renderExcludes: () => React.ReactNode | void;
  includeOptionValues: any[];
  excludeOptionValues: any[];
  disableIncludes?: boolean;
  disabledExcludes?: boolean;
}

export default function IncludeExcludeMultiselectTabs(props: Props) {
  const {
    isMulti,
    renderIncludes,
    renderExcludes,
    disableIncludes,
    disabledExcludes,
    includeOptionValues = [],
    excludeOptionValues = [],
  } = props;
  const [tabView, setTabView] = useState(undefined);

  const items = [];
  if (!disableIncludes && (isMulti || !excludeOptionValues.length)) {
    items.push({
      label: 'Include',
      value: 'include',
      valueCount: includeOptionValues ? includeOptionValues.length : 0,
      renderContent: renderIncludes,
    });
  }
  if (!disabledExcludes && (isMulti || !includeOptionValues.length)) {
    items.push({
      label: 'Exclude',
      value: 'exclude',
      valueCount: excludeOptionValues ? excludeOptionValues.length : 0,
      renderContent: renderExcludes,
    });
  }

  return (
    <MiniTabsController items={items} value={tabView} onChange={setTabView} />
  );
}
