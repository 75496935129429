import React from 'react';
import ErrorBoundary from 'react-error-boundary';
import { colors, spacing } from '@shield-ui/styles';

const AppFallbackComponent = ({ componentStack, error }) => {
  /*
  Important that no material ui components are used here because this catches
  outside the material-ui provider
   */
  return (
    <div style={{ padding: spacing.unit * 4, fontSize: 16 }}>
      <h3>An unexpected error has occurred.</h3>
      <p>
        Try refreshing or{' '}
        <a href="/">
          <button>going home</button>
        </a>
        . Please contact us if this error persists.
      </p>
      <br />
      <p
        style={{ borderLeft: `5px solid ${colors.hues.red}`, paddingLeft: 10 }}
      >
        {error.message}
      </p>
      <br />
      <code style={{ fontSize: 13 }}>{componentStack}</code>
    </div>
  );
};

export default function ErrorBoundaryApp(props) {
  const { children } = props;
  return (
    <ErrorBoundary
      FallbackComponent={AppFallbackComponent}
      onError={(err, stack) => {
        if (stack) {
          console.error(stack);
        }
        console.error(err);
      }}
    >
      {children}
    </ErrorBoundary>
  );
}
