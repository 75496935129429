import _ from 'lodash';
import { LabelIdType, LabelType } from '@shield-ui/heatgrid';
import {
  MultiselectQueryFilterValue,
  GroupQueryFilterValue,
  UNSET_OPTION_VALUE,
} from '@shield-ui/query-filters';
import {
  default as platformGroupQueryFilter,
  testDefinitionPlatformSubtypeQueryFilter as platformSubtypeQueryFilter,
} from '../../../lib/queryFilters/robotLog/testDefinitionPlatformsAndSubTypes';
import {
  ensurePlatformSubtypes,
  selectPlatformSubtypes,
} from '../../../redux/testDefinitions.slice';
import store from '../../../redux/store';
import { AxisDefinition, AxisOption } from './AxisDefinition';

export const platformSubtypeAxisDefinition: AxisDefinition = {
  id: 'PlatformSubtype',
  getDropdownLabel: () => 'Platform Subtypes',
  buildAggsDsl: () => ({
    terms: {
      field: 'test_definition.platform_subtype.id',
      missing: 'N/A',
    },
  }),
  prepareAxisLabels: (labelIds: LabelIdType[]) => {
    return store.dispatch(ensurePlatformSubtypes()).then(() => {
      const platformSubtypes = selectPlatformSubtypes(store.getState());
      const labelMap = platformSubtypes.reduce((acc, platformSubtype) => {
        return {
          ...acc,
          [platformSubtype.id]: `${platformSubtype.name} (${platformSubtype.abbreviation})`,
        };
      }, {});

      let emptyLabel: LabelType = undefined;
      const labels: LabelType[] = _.sortBy(
        labelIds.reduce((acc, platformSubtypeId) => {
          if (platformSubtypeId === 'N/A') {
            emptyLabel = {
              id: platformSubtypeId,
              label: '-',
            };
            return acc;
          }
          return [
            ...acc,
            {
              id: platformSubtypeId,
              label: labelMap[platformSubtypeId],
            },
          ];
        }, []),
        (label) => label.label
      );

      if (emptyLabel) {
        return [...labels, emptyLabel];
      }
      return labels;
    });
  },
  buildQueryFilter: (
    filterValues: object,
    selectedLabels: LabelType[],
    axisOption?: AxisOption
  ) => {
    // current individual values or new object
    const individualValues = _.get(
      filterValues,
      [platformGroupQueryFilter.getId(), 'individualValues'],
      {}
    );

    // build the QF value for this grouped QF.
    const newPlatformSubtypeValues: MultiselectQueryFilterValue = {
      includeOptionValues: selectedLabels.map((label) => {
        if (label.id === 'N/A') {
          return UNSET_OPTION_VALUE;
        }
        return label.id;
      }),
    };
    individualValues[platformSubtypeQueryFilter.getId()] =
      newPlatformSubtypeValues;

    platformSubtypeQueryFilter.ensureControlData(newPlatformSubtypeValues);

    // Build the Groups value and compute esQuery
    const newValue: GroupQueryFilterValue = {
      individualValues,
      esQuery: undefined,
    };
    newValue.esQuery = platformGroupQueryFilter.getElasticQuery(newValue);

    // set the new value for this and return any other existing filter values
    return {
      ...filterValues,
      [platformGroupQueryFilter.getId()]: newValue,
    };
  },
};
