import React, { useEffect } from 'react';
import { ErrorMessageContent } from '@shield-ui/core';
import HeaderContent from './HeaderContent';
import { ensureListsAreLoaded } from '../../../redux/lists/util';
import useRobotLogCommonPropsQuery from './useRobotLogCommonPropsQuery';

export default function NotesSection({ robotLogId }) {
  useEffect(ensureListsAreLoaded);
  const { loading, error, data, refetch, robotLogDataParsed } =
    useRobotLogCommonPropsQuery(robotLogId, 'cache-first');

  if (loading) {
    return null;
  }
  if (error) {
    return <ErrorMessageContent>{error.message}</ErrorMessageContent>;
  }

  return (
    <HeaderContent
      robotLogId={robotLogId}
      robotLog={data.robotLog}
      refetch={refetch}
      robotLogDataParsed={robotLogDataParsed}
    />
  );
}
