import React from 'react';
import _ from 'lodash';
import { Link } from '@mui/material';

export function getFtsLink(row) {
  const ftsLink = _.get(row, 'robotLogData.fts_sheet_link', '');

  if (!ftsLink) {
    return '';
  }

  return ftsLink;
}

function FtsSheetCell({ row }) {
  const link = getFtsLink(row);

  if (!link) {
    return null;
  }

  return (
    <Link href={link} target="_blank" underline="hover">
      FTS Sheet
    </Link>
  );
}

export default FtsSheetCell;
