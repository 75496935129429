import _ from 'lodash';
import { LabelIdType, LabelType } from '@shield-ui/heatgrid';
import client from '../../../apollo-client';
import { gql } from '@apollo/client';
import {
  MultiselectQueryFilterValue,
  UNSET_OPTION_VALUE,
} from '@shield-ui/query-filters';
import { default as commitHashQueryFilter } from '../../../lib/queryFilters/robotLog/softwareInfoCommitHashes';
import { AxisDefinition, AxisOption } from './AxisDefinition';

export const commitHashAxisDefinition: AxisDefinition = {
  id: 'CommitHash',
  getDropdownLabel: () => 'Commit Hashes',
  buildAggsDsl: () => ({
    terms: {
      field: 'software_info.commit_hash',
      missing: 'N/A',
    },
  }),
  labelCellProps: {
    renderTooltip: (contentData: LabelType) => {
      if (!contentData.data) {
        return null;
      }
      // TODO - type this once axis defs are moved into individual files
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { repo, branch, createdAt } = contentData.data;
      return (
        <div>
          <h3>
            <a href={repo}>
              {repo} ({branch})
            </a>
          </h3>
          <h4>{createdAt}</h4>
        </div>
      );
    },
  },
  prepareAxisLabels: (labelIds: LabelIdType[]) => {
    const queries = labelIds.map((commitHash) => {
      return client.query({
        query: gql`
        query {
          softwareInfo(commitHash: "${commitHash}") {
            commitHash
            createdAt
            branchName
            repoUrl
          }
        }
      `,
      });
    });
    return Promise.all(queries).then((results) => {
      const sorted = _.orderBy(
        results,
        (result) => {
          if (result.data && result.data.softwareInfo) {
            return new Date(result.data.softwareInfo.createdAt);
          }
          return 0;
        },
        'asc'
      );

      return sorted.reduce((acc, result) => {
        if (result.data && result.data.softwareInfo) {
          const commitHash = result.data.softwareInfo.commitHash;
          const shortCommitHash = commitHash.substring(0, 8);
          const repo = result.data.softwareInfo.repoUrl;
          const branch = result.data.softwareInfo.branchName;
          const createdAt = new Date(
            result.data.softwareInfo.createdAt
          ).toLocaleString();
          return [
            ...acc,
            {
              id: commitHash,
              label: `${shortCommitHash}`,
              data: {
                repo,
                branch,
                createdAt,
              },
            },
          ];
        }
        return acc;
      }, []);
    });
  },
  buildQueryFilter: (
    filterValues: object,
    selectedLabels: LabelType[],
    axisOption?: AxisOption
  ) => {
    // build the QF value.
    const newCommitHashValues: MultiselectQueryFilterValue = {
      includeOptionValues: selectedLabels.map((label) => {
        if (label.id === 'N/A') {
          return UNSET_OPTION_VALUE;
        }
        return label.id;
      }),
    };

    newCommitHashValues.esQuery =
      commitHashQueryFilter.getElasticQuery(newCommitHashValues);

    // set the new value for this and return any other existing filter values
    return {
      ...filterValues,
      [commitHashQueryFilter.getId()]: newCommitHashValues,
    };
  },
};
