import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from "@shield-ui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: theme.spacing(3),
  },
  sectionHeader: {
    color: colors.hues.grays[140],
    textTransform: 'uppercase',
    margin: 0,
    fontSize: 10,
    letterSpacing: '1px',
    borderBottom: `1px solid ${colors.hues.grays[190]}`,
    marginBottom: theme.spacing(1),
  },
  content: {
  }
}))

const PreviewSection: React.FC<{label:string}> = ({label, children}) => {
  const c = useStyles();

  return (
    <div className={c.container}>
      <div className={c.sectionHeader}>{label}</div>
      <div className={c.content}>
        {children}
      </div>
    </div>
  );
}

export default PreviewSection;
