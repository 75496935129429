import React from 'react';
import moment from 'moment';
import withStyles from '@mui/styles/withStyles';
import container from '../../../../../containers/dateTimeSettings';

function styles() {
  return {
    cellTime: {
      marginLeft: 10,
    },
  };
}

function FlightTimeLocalCell({ row, hour12, classes }) {
  const start = moment.utc(row.startTime).local();
  const end = moment.utc(row.endTime).local();

  const timeFormat = hour12 ? 'hh:mma' : 'HH:mm';

  if (start.isValid() && end.isValid()) {
    return (
      <span>
        <span>{start.format('MM/DD/YYYY')}</span>
        <span className={classes.cellTime}>
          {start.format(timeFormat)}
          {' - '}
          {end.format(timeFormat)}
        </span>
      </span>
    );
  } else if (start.isValid() || end.isValid()) {
    const valid = start.isValid() ? start : end;

    return <span>{valid.format(`MM/DD/YYYY ${timeFormat}`)}</span>;
  }

  return <span />;
}

export default withStyles(styles)(container(FlightTimeLocalCell));
