import React from 'react';
import _ from 'lodash';
import RobotLogLink from '../common/RobotLogLink';

function ParentsCell({ row }) {
  const { parents } = row;

  if (_.isEmpty(parents)) {
    return null;
  }

  return parents.map((parentRobotLog, i) => {
    return (
      <div key={i}>
        <RobotLogLink robotLog={parentRobotLog} />
      </div>
    );
  });
}
export default ParentsCell;
