import React from 'react';
import { Helmet } from 'react-helmet';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from '@mui/material';

function styles(theme) {
  const sideGutter = theme.spacing(5);
  const topGutter = theme.spacing(3);

  return createStyles({
    pageContainer: {
      display: 'flex',
      flexDirection: 'row',
      minHeight: '100.1vh',
    },
    leftGutter: {
      paddingTop: topGutter,
      width: sideGutter,
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      flexDirection: 'row',
    },
    pageContent: {
      flex: 1,
      paddingTop: topGutter,
      paddingBottom: topGutter,
      paddingRight: sideGutter,
      // page hack to keep it from expanding larger than page
      width: 0,
    },
    backButton: {
      marginTop: 8,
      padding: 5,
    },
    backIcon: {
      color: '#bbbdc0',
      fontSize: 26,
    },
  });
}

interface Props extends WithStyles<typeof styles> {
  pageTitle?: string; // Helmet - <head><title>
  backTo?: string;
}

const PageContent: React.FC<Props> = (props) => {
  const { classes, children, pageTitle, backTo } = props;
  return (
    <div className={classes.pageContainer}>
      {pageTitle && (
        <Helmet>
          <title>{pageTitle}: Vision</title>
        </Helmet>
      )}
      <div className={classes.leftGutter}>
        {backTo && (
          <IconButton className={classes.backButton} component={Link} to={backTo} size="large">
            <ArrowBackIcon className={classes.backIcon} />
          </IconButton>
        )}
        {!backTo && ' '}
      </div>
      <div className={classes.pageContent}>{children}</div>
    </div>
  );
}

export default withStyles(styles)(PageContent);
