import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { Button, Tooltip } from '@mui/material';

const styles = (theme) => ({
  root: {
    borderRadius: 25,
    fontSize: 13,
    minWidth: 100,
    paddingLeft: 50,
    paddingRight: 50,
  },
});

function SectionSubmitButton({
  children,
  classes,
  disabledMessage,
  disabled,
  ...rest
}) {
  const button = (
    <Button
      variant="contained"
      color="primary"
      size="large"
      classes={classes}
      disabled={disabled}
      {...rest}
    >
      {children}
    </Button>
  );

  if (disabled && disabledMessage) {
    return (
      <Tooltip
        title={disabledMessage}
        placement="top"
        disableFocusListener
        disableTouchListener
      >
        <div>{button}</div>
      </Tooltip>
    );
  }

  return button;
}
SectionSubmitButton.propTypes = Button.propTypes;

export default withStyles(styles)(SectionSubmitButton);
