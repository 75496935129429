import _ from 'lodash';
import { useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import client from '../apollo-client';
import tracker from '../lib/tracker';

import {
  Environment,
  EnvironmentService,
  ListRequest,
} from '@hmd/sdk/api/systems/environment/v1';
import sdkClient from '../lib/hmdsdkClient';
import { useQuery } from '@tanstack/react-query';

const environmentListQuery = gql`
  query environments {
    environments {
      edges {
        node {
          id
          name
          biomes {
            id
            name
          }
        }
      }
    }
  }
`;

const createEnvironmentMutation = gql`
  mutation createEnvironment($name: String!, $biome_ids: [UUID]) {
    createEnvironment(name: $name, biomeIds: $biome_ids) {
      id
      name
      biomes {
        name
        id
      }
    }
  }
`;

const updateEnvironmentMutation = gql`
  mutation updateEnvironment($id: UUID!, $name: String, $biome_ids: [UUID]) {
    updateEnvironment(id: $id, name: $name, biomeIds: $biome_ids) {
      id
      name
      biomes {
        name
        id
      }
    }
  }
`;

const deleteEnvironmentMutation = gql`
  mutation deleteEnvironment($id: String!) {
    deleteEnvironment(id: $id) {
      id
      name
    }
  }
`;

export function loadAllEnvironments(callback, error) {
  client
    .query({
      query: environmentListQuery,
      fetchPolicy: 'network-only',
    })
    .then(
      (val) => {
        if (typeof callback === 'function') {
          callback(val);
        }
      },
      (err) => {
        if (typeof error === 'function') {
          error(err);
        }
      }
    );
}

export function createEnvironment(variables, callback, error) {
  client
    .mutate({
      mutation: createEnvironmentMutation,
      variables,
    })
    .then(
      (res) => {
        if (typeof callback === 'function') {
          callback(res);
          tracker.trackEvent(
            'AdminLists',
            'EnvironmentCreate',
            'name',
            variables.name
          );
        }
      },
      (err) => {
        if (typeof error === 'function') {
          error(err);
        }
      }
    );
}

export function deleteEnvironment(id, callback, error) {
  client
    .mutate({
      mutation: deleteEnvironmentMutation,
      variables: { id },
    })
    .then(
      (res) => {
        if (typeof callback === 'function') {
          callback(res);
          tracker.trackEvent('AdminLists', 'EnvironmentDelete', 'id', id);
        }
      },
      (err) => {
        if (typeof error === 'function') {
          error(err);
        }
      }
    );
}

export function updateEnvironment(variables, callback, error) {
  client
    .mutate({
      mutation: updateEnvironmentMutation,
      variables,
    })
    .then(
      (res) => {
        if (typeof callback === 'function') {
          callback(res);
          tracker.trackEvent(
            'AdminLists',
            'EnvironmentUpdate',
            'id',
            _.get(variables, 'id')
          );
        }
      },
      (err) => {
        if (typeof error === 'function') {
          error(err);
        }
      }
    );
}

export const fetchEnvironments = _.once(() => {
  const fetchQuery = gql`
    query {
      environments(sort: name_asc) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `;

  return client.query({
    query: fetchQuery,
  });
});

type EnvironmentOption = { value: string; label: string };
export const fetchEnvironmentOptions = _.once(
  (): Promise<EnvironmentOption[]> => {
    return fetchEnvironments().then((res) => {
      return _.get(res, 'data.environments.edges', []).map((u) => ({
        value: u.node.id,
        label: u.node.name,
      }));
    });
  }
);

export const useEnvironmentOptions = () => {
  const [data, setData] = useState<EnvironmentOption[]>([]);

  useEffect(() => {
    fetchEnvironmentOptions().then((opts) => setData(opts));
  }, []);

  return data;
};

const fetchAllEnvironments = async (): Promise<Environment.AsObject[]> => {
  const environments: Environment.AsObject[] = [];
  const pageSize = 200;

  let token = null;
  while (token !== '') {
    const sr = new ListRequest();
    if (token !== null) {
      sr.setPageToken(token);
    }
    sr.setPageSize(pageSize);

    const res = await sdkClient.unary(EnvironmentService.List, sr);
    const fetchedEnvironments = res.getEnvironmentsList();
    for (const p of fetchedEnvironments) {
      environments.push(p.toObject());
    }

    token = res.getNextPageToken();
  }

  return environments;
};

const ENVIRONMENTS_QUERY_KEY = 'environments';

export const useEnvironments = () =>
  useQuery({
    queryKey: [ENVIRONMENTS_QUERY_KEY],
    queryFn: () => fetchAllEnvironments(),
  });
