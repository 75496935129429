import _ from 'lodash';
import { createReducer } from 'redux-nano';
import { createSelector } from '@reduxjs/toolkit';
import update from 'immutability-helper';
import { getCachedItem, setCachedItem } from '@shield-ui/utils';
import { TABLE_MODAL_TYPES, serializeStateKeys } from './constants';

import {
  SET_SORT,
  SET_PAGINATION,
  RESET_PAGINATION,
  SET_COLUMNS,
  RESET_COLUMNS,
  UPDATE_COLUMN_WIDTHS,
  UPDATE_COLUMN_ORDER,
  UPDATE_PINNED_COLUMNS,
  SET_PAGE_SIZE,
  SET_TABLE_SEARCH_RESULT,
  RESET_TABLE_SEARCH_RESULT,
  INITIALIZE_TABLE_CACHE,
  REMOVE_TABLE_CACHE,
  SET_VISIBLE_MODAL,
  TOGGLE_FILTERS_PANEL,
  SET_FILTERS,
  RESET_FILTERS,
  FORCE_RESULT_REFRESH,
  PROMPT_LOAD_SAVE_STATE,
  LOAD_SAVE_STATE,
} from './actions';

const storageKeys = {
  pageSize: 'tables-global-pageSize',
};

const defaultFilters = {};

const defaultPagination = {
  after: '',
  before: '',
  page: 0,
  lastPage: 0,
};

export const defaultResult = {
  isLoading: false,
  requestId: undefined, // randomized to ensure we don't load request 1 after request 2
  rows: [],
  total: 0,
  count: 0,
  endCursor: undefined,
  startCursor: undefined,
};

/**
 * Initializes a fresh cache object for a new table
 * @param payload
 */
function getNewTableCache(payload) {
  const {
    fixedColumns = [],
    // overload any top level key like filtersVisible, columns, controls, etc...
    initializeProps = {},
  } = payload;
  const { defaultSort, sort } = initializeProps;

  return {
    forceResultRefreshCounter: 0,
    filtersVisible: false,
    filtersVariables: defaultFilters,
    visibleControlsModal: undefined,
    pendingSaveState: undefined,
    columns: undefined,
    pinnedColumns: {},
    fixedColumns,
    defaultSort,
    widths: undefined,
    pagination: defaultPagination,
    result: defaultResult,
    // columns, defaultColumns, fixedColumns, prependContextColumns
    // sort, defaultSort
    // filtersVariables
    ...initializeProps,
    sort: sort ||
      defaultSort || { orderByColumn: '', orderByDirection: 'desc' },
  };
}

function basicUpdateValidation(state, tableCacheKey, action) {
  if (!tableCacheKey) {
    console.warn(`tableCacheKey is required when calling ${SET_FILTERS}`);
    console.info(action);
    return false;
  }
  if (!state.cache[tableCacheKey]) {
    console.warn(
      `table cache for tableCacheKey: ${tableCacheKey} has not been initialized... cannot update it`
    );
    console.info(action);
    return false;
  }
  return true;
}

export default createReducer(
  {
    global: {
      pageSize: getCachedItem(storageKeys.pageSize) || 25,
    },
    cache: {},
  },
  {
    [INITIALIZE_TABLE_CACHE]: (state, action) => {
      const { tableCacheKey } = action.payload;
      // no-op if already initialized
      if (state.cache[tableCacheKey]) {
        return state;
      }

      return update(state, {
        cache: {
          [tableCacheKey]: { $set: getNewTableCache(action.payload) },
        },
      });
    },
    [REMOVE_TABLE_CACHE]: (state, action) => {
      const { tableCacheKey } = action.payload;

      // RESET MEMOIZATION cache, might be sub optimal if more than one table but oh well
      // frees up memory for funcs that won't be used anymore
      getCalcStateBuilder.cache.clear();

      return update(state, {
        cache: {
          [tableCacheKey]: { $set: undefined },
        },
      });
    },
    // GLOBAL
    [SET_PAGE_SIZE]: (state, action) => {
      const { pageSize } = action.payload;

      setCachedItem(storageKeys.pageSize, pageSize);

      // Reset pagination for all tables when global page size changes
      const cacheUpdates = _.reduce(
        state.cache,
        (acc, v, tableCacheKey) => {
          // make sure the key doesn't still exist but we have cleared out the data related
          if (!_.isObject(v)) {
            return acc;
          }

          acc[tableCacheKey] = {
            pagination: { $set: defaultPagination },
          };
          return acc;
        },
        {}
      );

      return update(state, {
        global: {
          pageSize: { $set: pageSize },
        },
        cache: cacheUpdates,
      });
    },
    // TABLE CACHE SPECIFIC
    [RESET_TABLE_SEARCH_RESULT]: (state, action) => {
      const { tableCacheKey } = action.payload;
      if (!basicUpdateValidation(state, tableCacheKey, action)) {
        return state;
      }

      return update(state, {
        cache: {
          [tableCacheKey]: {
            result: { $set: defaultResult },
          },
        },
      });
    },
    [SET_TABLE_SEARCH_RESULT]: (state, action) => {
      const { tableCacheKey, ...rest } = action.payload;
      if (!basicUpdateValidation(state, tableCacheKey, action)) {
        return state;
      }

      // QueryExecutor generates a requestId that we pass here and save
      // If we are done loading (e.g., just got data), and the requestId doesn't match the one we have saved
      // we drop this info on the floor
      // future it would be good if QueryExecutor would cancel the request outright but AbortController is kind of a pain in the butt
      if (rest.requestId && rest.isLoading === false) {
        const currentRequestId = state.cache[tableCacheKey].result.requestId;
        if (currentRequestId !== rest.requestId) {
          console.warn(
            `Request finished loading ${rest.requestId} but it doesn't match our latest request ${currentRequestId}. Dropping this on the floor`
          );
          console.debug(rest);
          return state;
        }
      }

      return update(state, {
        cache: {
          [tableCacheKey]: {
            result: { $merge: rest },
          },
        },
      });
    },
    [SET_PAGINATION]: (state, action) => {
      const { tableCacheKey, ...rest } = action.payload;
      if (!basicUpdateValidation(state, tableCacheKey, action)) {
        return state;
      }

      const newPagination = _.assignIn({}, rest, {
        lastPage: _.get(state, ['cache', tableCacheKey, 'pagination', 'page']),
      });

      return update(state, {
        cache: {
          [tableCacheKey]: {
            pagination: { $merge: newPagination },
          },
        },
      });
    },
    [RESET_PAGINATION]: (state, action) => {
      const { tableCacheKey } = action.payload;
      if (!basicUpdateValidation(state, tableCacheKey, action)) {
        return state;
      }

      return update(state, {
        cache: {
          [tableCacheKey]: {
            pagination: { $set: defaultPagination },
          },
        },
      });
    },
    // By incrementing the forceResultRefreshCounter the QueryExector component will execute again
    [FORCE_RESULT_REFRESH]: (state, action) => {
      const { tableCacheKey } = action.payload;
      if (!basicUpdateValidation(state, tableCacheKey, action)) {
        return state;
      }
      const currentCounter = _.get(state, [
        'cache',
        tableCacheKey,
        'forceResultRefreshCounter',
      ]);

      return update(state, {
        cache: {
          [tableCacheKey]: {
            forceResultRefreshCounter: { $set: currentCounter + 1 },
            pagination: { $merge: defaultPagination },
          },
        },
      });
    },
    [SET_SORT]: (state, action) => {
      const { tableCacheKey, ...sort } = action.payload;
      if (!basicUpdateValidation(state, tableCacheKey, action)) {
        return state;
      }

      const currentSort = _.get(state, ['cache', tableCacheKey, 'sort'], {});

      return update(state, {
        cache: {
          [tableCacheKey]: {
            sort: { $set: { ...currentSort, ...sort } },
          },
        },
      });
    },
    [TOGGLE_FILTERS_PANEL]: (state, action) => {
      const { tableCacheKey, isVisible } = action.payload;
      if (!basicUpdateValidation(state, tableCacheKey, action)) {
        return state;
      }

      let actualIsVisible = isVisible;
      if (_.isUndefined(actualIsVisible)) {
        actualIsVisible = !_.get(state, [
          'cache',
          [tableCacheKey],
          'filtersVisible',
        ]);
      }

      return update(state, {
        cache: {
          [tableCacheKey]: {
            filtersVisible: { $set: actualIsVisible },
          },
        },
      });
    },
    [SET_FILTERS]: (state, action) => {
      const { tableCacheKey, filtersVariables } = action.payload;

      if (!basicUpdateValidation(state, tableCacheKey, action)) {
        return state;
      }
      if (!filtersVariables) {
        console.warn(
          `filtersVariables is required when calling ${SET_FILTERS}`
        );
        return state;
      }
      const currentFiltersVariables = _.get(
        state,
        ['cache', tableCacheKey, 'filtersVariables'],
        {}
      );

      return update(state, {
        cache: {
          [tableCacheKey]: {
            pagination: { $set: defaultPagination },
            filtersVariables: {
              $set: {
                ...currentFiltersVariables,
                ...filtersVariables,
              },
            },
          },
        },
      });
    },
    [RESET_FILTERS]: (state, action) => {
      const { tableCacheKey } = action.payload;
      if (!basicUpdateValidation(state, tableCacheKey, action)) {
        return state;
      }

      return update(state, {
        cache: {
          [tableCacheKey]: {
            pagination: { $set: defaultPagination },
            filtersVariables: { $set: defaultFilters },
          },
        },
      });
    },
    [SET_COLUMNS]: (state, action) => {
      const { tableCacheKey, columns, movedColumn } = action.payload;
      const movedCol = movedColumn ? movedColumn.columnKey : undefined;

      if (!basicUpdateValidation(state, tableCacheKey, action)) {
        return state;
      }

      if (!_.isArray(columns)) {
        console.warn('Columns were not an array', action.payload);
        return state;
      }

      //if a movedColumn from columnConfigModal was a pinned column, then unpin that column
      const oldPinnedColumns = _.get(state, [
        'cache',
        tableCacheKey,
        'pinnedColumns',
      ]);
      const newPinnedColumns = {}; //must be returned as {left: [], right: []} shape
      for (const side in oldPinnedColumns) {
        if (oldPinnedColumns[side].includes(movedCol)) {
          newPinnedColumns[side] = oldPinnedColumns[side].filter(
            (c) => c !== movedCol
          );
        } else {
          newPinnedColumns[side] = oldPinnedColumns[side];
        }
      }

      return update(state, {
        cache: {
          [tableCacheKey]: {
            columns: { $set: columns },
            pinnedColumns: { $set: newPinnedColumns },
          },
        },
      });
    },
    [UPDATE_COLUMN_WIDTHS]: (state, action) => {
      const { tableCacheKey, colSizingState } = action.payload;
      if (!basicUpdateValidation(state, tableCacheKey, action)) {
        return state;
      }
      /*
        const updateWidthMap = _.keyBy(columnNameWidths, 'columnName');

        const oldColumns = _.get(state, ['cache', tableCacheKey, 'columns']) ||
          _.get(state, ['cache', tableCacheKey, 'defaultColumns']) || []

        const newColumns = oldColumns.map((column) => {
            const match = updateWidthMap[column.columnUid] || updateWidthMap[column.columnKey];

            if (match) {
                return {
                    ...column,
                  width: match.width,
                }
            }

            return column
        })
         */

      return update(state, {
        cache: {
          [tableCacheKey]: {
            widths: { $set: colSizingState },
          },
        },
      });
    },
    [UPDATE_PINNED_COLUMNS]: (state, action) => {
      const { tableCacheKey, newPinnedState } = action.payload;
      if (!basicUpdateValidation(state, tableCacheKey, action)) {
        return state;
      }
      //we want to update the 'columns' global state order whenever a column is pinned
      const leftPinned = newPinnedState.left || [];
      const rightPinned = newPinnedState.right || [];
      const allPinnedColumns = [...leftPinned, ...rightPinned];
      const oldColumns =
        _.get(state, ['cache', tableCacheKey, 'columns']) ||
        _.get(state, ['cache', tableCacheKey, 'defaultColumns']) ||
        [];
      const filteredColumns = oldColumns.filter(
        (col) => !allPinnedColumns.includes(col.columnUid || col.columnKey)
      );
      const newLeftColumns = leftPinned.flatMap((id) =>
        oldColumns.filter((col) => col.columnUid === id || col.columnKey === id)
      );
      const newRightColumns = rightPinned.flatMap((id) =>
        oldColumns.filter((col) => col.columnUid === id || col.columnKey === id)
      );

      const newColumnOrder = [
        ...newLeftColumns,
        ...filteredColumns,
        ...newRightColumns,
      ];
      return update(state, {
        cache: {
          [tableCacheKey]: {
            pinnedColumns: { $set: newPinnedState },
            columns: { $set: newColumnOrder },
          },
        },
      });
    },
    [UPDATE_COLUMN_ORDER]: (state, action) => {
      const { tableCacheKey, columnNameOrder } = action.payload;
      if (!basicUpdateValidation(state, tableCacheKey, action)) {
        return state;
      }

      // columnNameOrder is the internal name of columns in our generic component
      // this may be columnUid (because of dynamic columns) or columnKey (past notion before dynamic columns)

      const oldColumns =
        _.get(state, ['cache', tableCacheKey, 'columns']) ||
        _.get(state, ['cache', tableCacheKey, 'defaultColumns']) ||
        [];

      const newColumns = columnNameOrder.reduce((acc, columnName) => {
        const match = _.find(
          oldColumns,
          (col) => col.columnUid === columnName || col.columnKey === columnName
        );
        // the columnNameOrder array contains fixed and prepend columns, we don't want to double set them in our struct
        // so we make sure they exist in our columns/defaultColumns array
        if (match) {
          acc.push(match);
        }
        return acc;
      }, []);
      return update(state, {
        cache: {
          [tableCacheKey]: {
            columns: { $set: newColumns },
          },
        },
      });
    },
    [SET_VISIBLE_MODAL]: (state, action) => {
      const { tableCacheKey, visibleControlsModal } = action.payload;
      if (!basicUpdateValidation(state, tableCacheKey, action)) {
        return state;
      }

      return update(state, {
        cache: {
          [tableCacheKey]: {
            visibleControlsModal: { $set: visibleControlsModal },
          },
        },
      });
    },
    [RESET_COLUMNS]: (state, action) => {
      const { tableCacheKey, defaultPinnedColumns } = action.payload;
      if (!basicUpdateValidation(state, tableCacheKey, action)) {
        return state;
      }

      // clear them out and they will go back to the "defaults"
      return update(state, {
        cache: {
          [tableCacheKey]: {
            columns: { $set: undefined },
            pinnedColumns: { $set: defaultPinnedColumns },
          },
        },
      });
    },
    [PROMPT_LOAD_SAVE_STATE]: (state, action) => {
      const { saveState } = action.payload;
      const { tableCacheKey } = saveState;

      if (!basicUpdateValidation(state, tableCacheKey, action)) {
        return state;
      }

      return update(state, {
        cache: {
          [tableCacheKey]: {
            visibleControlsModal: { $set: TABLE_MODAL_TYPES.promptSaveState },
            pendingSaveState: { $set: saveState },
          },
        },
      });
    },
    [LOAD_SAVE_STATE]: (state, action) => {
      const { saveState } = action.payload;
      const { tableCacheKey } = saveState;
      if (!basicUpdateValidation(state, tableCacheKey, action)) {
        return state;
      }

      const updates = serializeStateKeys.reduce(
        (acc, key) => {
          if (saveState[key]) {
            acc[key] = { $set: saveState[key] };
          }
          return acc;
        },
        {
          pendingSaveState: { $set: undefined },
        }
      );

      return update(state, {
        cache: {
          [tableCacheKey]: updates,
        },
      });
    },
  }
);

const getCalcStateBuilder = _.memoize((tableCacheKey) => {
  const getColumns = (state) =>
    _.get(state.tables.cache, [tableCacheKey, 'columns']);
  const getFixedColumns = (state) =>
    _.get(state.tables.cache, [tableCacheKey, 'fixedColumns']);
  const getDefaultColumns = (state) =>
    _.get(state.tables.cache, [tableCacheKey, 'defaultColumns']);
  const getPrependContextColumns = (state) =>
    _.get(state.tables.cache, [tableCacheKey, 'prependContextColumns']);
  const getSort = (state) => _.get(state.tables.cache, [tableCacheKey, 'sort']);
  const getDefaultSort = (state) =>
    _.get(state.tables.cache, [tableCacheKey, 'defaultSort']);
  const getWidths = (state) =>
    _.get(state.tables.cache, [tableCacheKey, 'widths']);

  const selectColumns = createSelector(
    getColumns,
    getFixedColumns,
    getDefaultColumns,
    getPrependContextColumns,
    getWidths,
    (
      columns,
      fixedColumns = [],
      defaultColumns = [],
      prependContextColumns = [],
      widths = {}
    ) => {
      const startColumns = fixedColumns.concat(prependContextColumns);
      const afterColumns = (columns || defaultColumns).filter((afterCol) => {
        // dynamic columns... we can have have multiple
        if (afterCol.columnUid) {
          return true;
        }

        return !_.find(startColumns, (startCol) => {
          return afterCol.columnKey === startCol.columnKey;
        });
      });

      // concat our arrays together and override with user defined widths
      return startColumns.concat(afterColumns).map((column) => {
        const id = column.columnUid || column.columnKey;
        // column.width may be from an old version, loaded from save state or in cache. Keeping this for now
        // but can be removed. the widths map is the only real one.
        const width = widths[id] || column.width;

        return {
          ...column,
          width,
        };
      });
    }
  );

  const selectSort = createSelector(
    getSort,
    getDefaultSort,
    (sort, defaultSort) => {
      return sort || defaultSort;
    }
  );

  return (state) => {
    return {
      mergedColumns: selectColumns(state),
      mergedSort: selectSort(state),
    };
  };
});

export function selectTablePropsForTableCacheKey(state, tableCacheKey) {
  const tableState = state.tables.cache[tableCacheKey];
  const calcState = getCalcStateBuilder(tableCacheKey)(state);

  return {
    isCacheInitialized: !!tableState,
    ...state.tables.global,
    ...(tableState || {}),
    // this is the list of user controlled columns and differs in that it is a subset of mergedColumns
    // to the user, this is what they can control
    columns: tableState ? tableState.columns || tableState.defaultColumns : [],
    ...calcState,
  };
}
