import React from 'react';
import _ from 'lodash';
import masterNotesConfig from '../notes';
import { NOTE_TYPES } from '../../../../../services/notes';

function noteFilter(note) {
  return _.get(note, 'noteType') === NOTE_TYPES.OBSERVER;
}

const config = {
  ...masterNotesConfig,
  columnKey: 'notesObserver',
  label: 'Notes (Observer)',
  description: 'Observer notes that happened at flight time',
  Cell: ({ row }) => {
    return <masterNotesConfig.Cell row={row} noteFilter={noteFilter} />;
  },
  getValue: ({ row }) => {
    return masterNotesConfig.getValue({ row, noteFilter });
  },
};

export default config;
