export const TABLE_MODAL_TYPES = {
  columnConfig: 'columnConfig',
  exportResults: 'exportResults',
  promptSaveState: 'promptSaveState',
};

export const serializeStateKeys = [
  'columns',
  'prependContextColumns',
  'filtersVariables',
  'sort',
  'widths',
];
