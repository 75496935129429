import React from 'react';
import { lighten } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import { Card } from 'antd';
import Grid from '@mui/material/Grid';
import { alpha } from '@mui/material/styles';
import { colors } from '@shield-ui/styles';
import { getCachedItem, setCachedItem } from '@shield-ui/utils';
import NotesSection from './NotesSection/NotesSection';
import ReviewSection from './ReviewSection/ReviewSection';
import AnalysisSection from './AnalysisSection/AnalysisSection';
import { RelationshipsSectionFlights } from './RelationshipsSection/RelationshipsSectionFlights';
import { SessionLogMetadataEditor } from '../../components/metadata/SessionLogMetadataEditor';
import EventManager from '../../components/events/EventManager';
import VideoPlayer from './VideoPlayer/VideoPlayer';
import ParticipantCard from './ParticipantCard';
import RobotLogTestDefinitionPreview from '../../components/test-definitions/RobotLogTestDefinitionPreview';
import ArtifactManager from '../ArtifactManager/ArtifactManager';
import { SubNavTabs } from '@shield-ui/core';
import history from '../history';
import { EntityCard } from './EntityCard';

const styles = (theme) => ({
  tabsRoot: {
    color: '#5E6063',
    height: 40,
    minHeight: 'initial',
    backgroundColor: lighten(colors.semantic.darkBackground, 0.1),
  },
  tabRoot: {
    backgroundColor: colors.semantic.darkBackground,
    paddingTop: 2,
    paddingBottom: 2,
    minHeight: 40,
    color: '#5E6063',
    fontSize: 13,
    flex: 1 /* grow to fill */,
    minWidth: 'auto',
    marginRight: 1,
    '&:last-of-type': {
      marginRight: 0,
    },
  },
  activeTab: {
    color: '#fff',
    fontWeight: '500',
  },
  bottomLeftContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  bottomLeftScroll: {
    height: '80%',
    minHeight: '80%',
    padding: '0 3px 0 0',
  },
  tabLabelBadge: {
    top: 3,
    right: -20,
    fontSize: 12,
    backgroundColor: alpha(theme.palette.primary.light, 0.175),
    [theme.breakpoints.down('xl')]: {
      display: 'none',
    },
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  topContent: {
    flex: 1,
    marginRight: 15,
  },
  rightActions: {
    display: 'flex',
    flexDirection: 'row',
    height: 60,
    alignItems: 'center',
    marginRight: 32,
  },
  sideContent: {
    margin: theme.spacing(2, 0),
  },
});

const STORAGE_KEYS = {
  tabRoute: 'flightPage:tabRoute',
};

class FlightPage extends React.Component {
  videoPlayerRef = React.createRef();

  onTabChange = (tab) => {
    setCachedItem(STORAGE_KEYS.tabRoute, tab.path);
  };

  componentDidMount() {
    //Default to cached 'sticky' tab when not redirected to a url with different tab
    const { tabRoute } = this.props.match.params;
    if (tabRoute === undefined) {
      const flightUrl = this.props.location.pathname;
      const cachedTab = getCachedItem(STORAGE_KEYS.tabRoute);
      if (cachedTab) {
        history.push(`${flightUrl}${cachedTab}`);
      }
    }
  }

  render() {
    const { classes, robotLogId, pageRobotLog } = this.props;

    return (
      <Grid container spacing={0} justifyContent="center">
        <Grid item xs={8} lg={7} xl={6} style={{ padding: '0 21px 0 0' }}>
          <SubNavTabs
            autoPathPrefix
            onTabChange={this.onTabChange}
            tabs={[
              {
                label: 'Events',
                path: '/events',
                Component: () => (
                  <EventManager
                    robotLogId={robotLogId}
                    timeCodeOnClick={this.seekAndPlay}
                  />
                ),
              },
              {
                label: 'Notes',
                path: '/notes',
                Component: () => <NotesSection robotLogId={robotLogId} />,
              },
              {
                label: 'Reviews',
                path: '/reviews',
                Component: () => <ReviewSection robotLogId={robotLogId} />,
              },
              {
                label: 'Analysis',
                path: '/analysis',
                Component: () => <AnalysisSection robotLogId={robotLogId} />,
              },
              {
                label: 'Artifacts',
                path: '/artifacts',
                Component: () => <ArtifactManager sessionLogId={robotLogId} />,
              },
              {
                label: 'Relationships',
                path: '/relationships',
                Component: () => (
                  <RelationshipsSectionFlights robotLogId={robotLogId} />
                ),
              },
            ]}
          />
        </Grid>
        <Grid item xs={4} lg={5} xl={6}>
          <VideoPlayer robotLogId={robotLogId} innerRef={this.videoPlayerRef} />
          <div className={classes.sideContent}>
            <RobotLogTestDefinitionPreview robotLogId={robotLogId} />
          </div>
          <div className={classes.sideContent}>
            <ParticipantCard
              sessionLogId={robotLogId}
              pageRobotLog={pageRobotLog}
            />
          </div>
          <div className={classes.sideContent}>
            <Card>
              <SessionLogMetadataEditor sessionLogId={robotLogId} />
            </Card>
          </div>
          <div className={classes.sideContent}>
            <EntityCard sessionLogId={robotLogId} />
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(FlightPage);
