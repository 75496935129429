import React from 'react';
import _ from 'lodash';
import cx from 'classnames';
import Typography from '@mui/material/Typography';
import { lighten } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Tooltip } from '@mui/material';
import { HangarUserType } from '@shield-ui/hangar-service';
import { colors } from '@shield-ui/styles';
import { Star, StarOutlined } from '@shield-ui/icons';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 48,
    height: 48,
    color: colors.hues.grays[60],
    fontSize: 28,
    '&:hover': {
      cursor: 'pointer',
      transformOrigin: 'center',
      transform: 'scale(1.1)',
      color: lighten(colors.hues.yellow, 0.6),
    },
    transition: '0.1s ease',
  },
  containerSmall: {
    width: 32,
    height: 32,
    fontSize: 18,
    color: colors.hues.grays[20],
  },
  favorite: {
    color: lighten(colors.hues.yellow, 0.4),
  },
  ttMain: {
    textAlign: 'center',
    fontSize: 13.5,
    fontWeight: 500,
  },
  ttUsers: {
    marginTop: theme.spacing(1),
  },
}));

export interface FavoriteButtonProps
  extends React.HTMLAttributes<HTMLDivElement> {
  isFavorite?: boolean;
  favoriteForUsers?: HangarUserType[];
  size?: 'small' | 'normal';
  includeTooltip?: boolean;
  className?: string;
}

function FavoriteButton(props: FavoriteButtonProps) {
  const {
    className,
    size = 'normal',
    favoriteForUsers = [],
    isFavorite = false,
    includeTooltip = true,
    ...rest
  } = props;
  const classes = useStyles();

  const cn = cx(
    classes.container,
    size === 'small' && classes.containerSmall,
    isFavorite && classes.favorite,
    className
  );

  const content = (
    <div className={cn} {...rest}>
      {isFavorite ? (
        <Star fontSize="inherit" />
      ) : (
        <StarOutlined fontSize="inherit" />
      )}
    </div>
  );

  if (!includeTooltip) {
    return content;
  }

  const tt = (
    <div>
      <div className={classes.ttMain}>
        {isFavorite ? 'Un-Favorite' : 'Favorite'}
      </div>
      {!_.isEmpty(favoriteForUsers) && (
        <Typography variant="body2" component="div" className={classes.ttUsers}>
          Favorite for: {_.map(favoriteForUsers, 'name').join(', ')}
        </Typography>
      )}
    </div>
  );

  return (
    <Tooltip title={tt} enterDelay={300}>
      {content}
    </Tooltip>
  );
}

export default FavoriteButton;
