import _ from 'lodash';
import React from 'react';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { HangarPipelineExecutionType } from '@shield-ui/hangar-service';
import {
  PipelineExecution,
  PipelineExecutionService,
} from '@hmd/sdk/api/orchestration/pipeline_execution/v1';
import { colors } from '@shield-ui/styles';
import { getCachedItem, setCachedItem } from '@shield-ui/utils';
import {
  PipelineGroup,
  PipelineRun,
  SolidOutput,
  SolidOutputType,
} from './data';
import MoreMenu from '../../MoreMenu';
import ViewerHeader from './PipelineViewer/ViewerHeader';
import GroupList from './PipelineViewer/GroupList';
import RunList from './PipelineViewer/RunList';
import SolidGroups, { SolidGroupsProps } from './PipelineViewer/SolidGroups';
import ControlsHeader, { ViewMode } from './PipelineViewer/ControlsHeader';

export interface PipelineViewerProps extends WithStyles<typeof styles> {
  treeOutput: PipelineGroup[];
  flatOutput: SolidOutput[];
  initialViewMode: ViewMode;
  onShowPipelineExecution: (
    pipelineExecution: HangarPipelineExecutionType
  ) => void;
  onToggleFavorite: SolidGroupsProps['onToggleFavorite'];
  onCreateMetricOrExpectation: () => void;
  onEditMetricOrExpectation: (type: SolidOutputType, id: string) => void;
}

const styles = (theme) =>
  createStyles({
    container: {
      fontSize: 14,
      height: '100%',
      padding: 8,
      display: 'flex',
      flexDirection: 'column',
    },
    list: {
      overflowY: 'auto',
      height: '100%',
      paddingBottom: 1, //overflow hack?
    },
    noResults: {
      color: colors.hues.grays[90],
      margin: theme.spacing(1),
      textAlign: 'center',
    },
  });

interface State {
  selectedPipelineGroup: PipelineGroup | void;
  selectedPipelineRun: PipelineRun | void;
  searchString: string;
  viewMode: ViewMode;
}

class PipelineViewer extends React.Component<PipelineViewerProps, State> {
  static viewModeCacheKey = 'pipelineExplorerViewMode';

  state = {
    selectedPipelineGroup: undefined,
    selectedPipelineRun: undefined,
    searchString: '',
    viewMode:
      (getCachedItem(PipelineViewer.viewModeCacheKey) as ViewMode) ||
      ViewMode.tree,
  };

  constructor(props) {
    super(props);
    if (props.initialViewMode) {
      this.state.viewMode = props.initialViewMode;
    }
  }

  setSearchString = (str) => {
    this.setState({
      searchString: str,
    });
  };

  setViewMode = (str) => {
    setCachedItem(PipelineViewer.viewModeCacheKey, str);
    this.setState({
      viewMode: str,
    });
  };

  setPipelineGroup = (group, pipelineRun) => {
    const updates = {
      selectedPipelineGroup: group.label,
      selectedPipelineRun: undefined,
    };
    if (pipelineRun) {
      updates.selectedPipelineRun = pipelineRun.label;
    }
    this.setState(updates);
  };

  clearPipelineGroup = () => {
    this.setState({
      selectedPipelineGroup: undefined,
      selectedPipelineRun: undefined,
    });
  };

  setPipelineRun = (pipelineRun) => {
    this.setState({
      selectedPipelineRun: pipelineRun.label,
    });
  };

  clearPipelineRun = () => {
    this.setState({
      selectedPipelineRun: undefined,
    });
  };

  renderNoResults() {
    const { classes } = this.props;
    return <div className={classes.noResults}>No results</div>;
  }

  render() {
    const {
      searchString,
      selectedPipelineRun,
      selectedPipelineGroup,
      viewMode,
    } = this.state;
    const {
      treeOutput,
      flatOutput,
      onShowPipelineExecution,
      onToggleFavorite,
      classes,
    } = this.props;

    let searchPlaceholder =
      'Filter pipelines by searching for your output names or the pipeline name';
    let header, list;
    if (flatOutput.length === 0) {
      list = this.renderNoResults();
    } else if (viewMode === ViewMode.flat) {
      list = (
        <SolidGroups
          outputs={flatOutput}
          searchString={searchString}
          onToggleFavorite={onToggleFavorite}
          onEditClick={this.props.onEditMetricOrExpectation}
        />
      );
    } else if (selectedPipelineRun) {
      // or treeOutput[0] because we might skip selecting a group if we only have one
      const group =
        _.find(treeOutput, { label: selectedPipelineGroup }) || treeOutput[0];
      const run = _.find(group.pipelineRuns, { label: selectedPipelineRun });
      const { solids, outputs, pipelineExecution } = run;

      header = (
        <ViewerHeader
          label={`${group.label} | ${run.label}`}
          // if there is only one run, we go back up to the top otherwise we let the user see the runs again
          goBack={
            group.pipelineRuns.length > 1
              ? this.clearPipelineRun
              : this.clearPipelineGroup
          }
          EndNode={
            run.pipelineExecution && (
              <MoreMenu
                menuItems={[
                  {
                    label: 'View Pipeline Execution',
                    onClick: () => onShowPipelineExecution(pipelineExecution),
                  },
                ]}
              />
            )
          }
        />
      );
      list = (
        <SolidGroups
          solids={solids}
          outputs={outputs}
          searchString={searchString}
          onToggleFavorite={onToggleFavorite}
          onEditClick={this.props.onEditMetricOrExpectation}
        />
      );
      searchPlaceholder = 'Highlight pipeline outputs';
    } else if (selectedPipelineGroup) {
      searchPlaceholder = 'Filter executions by searching output names';
      const group = _.find(treeOutput, { label: selectedPipelineGroup });
      header = (
        <ViewerHeader
          label={selectedPipelineGroup}
          goBack={this.clearPipelineGroup}
        />
      );
      list = (
        <RunList
          pipelineRuns={group.pipelineRuns}
          onRunClick={this.setPipelineRun}
          searchString={searchString}
        />
      );
    } else if (treeOutput.length === 1) {
      // Only one unique Description for a PipelineGroup so we skip to the Pipeline Run Tier
      const group = treeOutput[0];

      list = (
        <RunList
          pipelineRuns={group.pipelineRuns}
          onRunClick={this.setPipelineRun}
          searchString={searchString}
        />
      );
    } else if (treeOutput.length === 0) {
      list = this.renderNoResults();
    } else {
      // List of pipeline names
      list = (
        <GroupList
          groups={treeOutput}
          onGroupClick={this.setPipelineGroup}
          searchString={searchString}
        />
      );
    }

    return (
      <div className={classes.container}>
        <ControlsHeader
          onChangeSearchString={this.setSearchString}
          onChangeViewMode={this.setViewMode}
          searchPlaceholder={searchPlaceholder}
          viewMode={viewMode}
          onCreateMetric={this.props.onCreateMetricOrExpectation}
        />
        {header}
        <div className={classes.list}>{list}</div>
      </div>
    );
  }
}

export default withStyles(styles)(PipelineViewer);
