import { Download } from '@shield-ui/icons';

import { Artifact, FileState } from '@hmd/sdk/api/artifacts/v1';

import { Collapse, Button, Tag } from 'antd';
import ArtifactIcon from '../../components/artifacts/NewArtifactIcon';
import FavoriteButton from '../../components/buttons/FavoriteButton';
import hmdsdkClient from '../../lib/hmdsdkClient';
import { ArtifactView } from './ArtifactView';
import { SessionLogAndArtifacts } from '../../services/newArtifacts';
import { makeStyles } from '@mui/styles';
import { DeleteOutlined } from '@ant-design/icons';
import { useCurrentUser } from '../../services/authUser';
import { colors } from '@shield-ui/styles';
import { useState } from 'react';
import { Collapsible } from '../../components/Collapsible';

const { Panel } = Collapse;

const useStyles = makeStyles((theme) => ({
  artifactIcon: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.5),
  },
  artifactName: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  favoriteButton: {
    display: 'inline-block',
    height: '100%',
    position: 'relative',
    top: 3,
    width: 18,
    marginLeft: theme.spacing(0.5),
  },
  artifactPanel: {
    boxSizing: 'border-box',
    marginRight: 3,
  },
  deleteButton: {
    marginRight: 8,
  },
  downloadButton: {
    color: '#fff',
    // i do not like antd buttons...
    '&:hover': {
      color: colors.semantic.linkColorHover,
    },
    '&:focus': {
      color: 'white',
      '&:hover': {
        color: colors.semantic.linkColorHover,
      },
    },
  },
}));

type NestedArtifactListProps = {
  sessionLogId?: string;
  sessionLogs: SessionLogAndArtifacts;
  favorites?: object;
  IsFavorite?: (artifact: Artifact.AsObject) => boolean;
  removeFavorite?: (artifact: Artifact.AsObject) => void;
  addFavorite?: (artifact: Artifact.AsObject) => void;
  onDelete?: (artifact: Artifact.AsObject) => void;
  [key: string]: any;
};

export const NestedArtifactList = ({
  sessionLogId,
  sessionLogs,
  favorites,
  IsFavorite,
  removeFavorite,
  addFavorite,
  onDelete,
  ...props
}: NestedArtifactListProps) => {
  const classes = useStyles();
  const { data: currentUser } = useCurrentUser();
  const [isOpen, setIsOpen] = useState(false);

  const sortedSessionLogs = Object.values(sessionLogs).sort((a, b) =>
    a.id === sessionLogId ? -1 : 1
  );
  return (
    <>
      {sortedSessionLogs.map((sessionLog, index) => {
        const artifacts = sessionLog.artifacts;
        return (
          <Collapsible key={index} header={sessionLog.name} isOpen={index === 0}>
            <Collapse
              accordion
              expandIconPosition="right"
              className={classes.artifactPanel}
            >
              {artifacts.map((artifactItem, index) => {
                const artifact = artifactItem.artifact;
                const ArtifactIsFavorite = IsFavorite
                  ? IsFavorite(artifact)
                  : false;
                const header = (
                  <div>
                    <ArtifactIcon
                      className={classes.artifactIcon}
                      artifact={artifact}
                      includeTooltip
                    />
                    <span className={classes.artifactName}>
                      {artifact.filename}
                    </span>
                    {artifact.fileState === FileState.DELETED && (
                      <Tag color="warning">DELETED</Tag>
                    )}
                    {artifact.fileState === FileState.PENDING && (
                      <Tag color="processing">PENDING</Tag>
                    )}
                  </div>
                );
                return (
                  <ArtifactView
                    header={header}
                    sessionLogId={sessionLog.id}
                    artifact={artifact}
                    open={true}
                    key={index}
                    extra={
                      <>
                        {onDelete &&
                          //artifact.fileState !== FileState.DELETED &&
                          artifact.fileState === FileState.RECEIVED &&
                          currentUser?.email ===
                            artifact.lifeCycleDetails.createUserId && (
                            <Button
                              type="link"
                              danger
                              className={classes.deleteButton}
                              icon={<DeleteOutlined />}
                              size={'large'}
                              onClick={(evt) => {
                                evt.preventDefault();
                                evt.stopPropagation();
                                onDelete(artifact);
                              }}
                            />
                          )}
                        {artifact.fileState === FileState.RECEIVED && (
                          <a href={artifact.publicUri}>
                            <Button
                              type="link"
                              tabIndex={-1}
                              shape="circle"
                              className={classes.downloadButton}
                              icon={<Download />}
                              size={'large'}
                              onClick={(evt) => {
                                evt.preventDefault();
                                evt.stopPropagation();
                                hmdsdkClient.artifacts.download(artifact.id);
                              }}
                            />
                          </a>
                        )}
                        {addFavorite && removeFavorite && (
                          <FavoriteButton
                            isFavorite={ArtifactIsFavorite}
                            size="small"
                            className={classes.favoriteButton}
                            includeTooltip={false}
                            onClick={(evt) => {
                              evt.preventDefault();
                              evt.stopPropagation();
                              if (ArtifactIsFavorite) {
                                removeFavorite(artifact);
                              } else {
                                addFavorite(artifact);
                              }
                            }}
                          />
                        )}
                      </>
                    }
                  />
                );
              })}
            </Collapse>
          </Collapsible>
        );
      })}
    </>
  );
};
