import React, { useState, useCallback } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { colors } from '@shield-ui/styles';
import { LayoutProps } from '../common';
import { useDidMount } from '@shield-ui/hooks';
import { stopEventPropagation } from '@shield-ui/utils';
import ControlBarLayoutPreview from './ControlBarLayoutPreview';

const useStyles = makeStyles((theme) => ({
  popper: {
    zIndex: 1000,
  },
  loadingMessage: {
    textAlign: 'center',
    minWidth: 180,
    color: colors.hues.grays[120],
    fontSize: 10,
    padding: theme.spacing(2, 0),
  },
  dropdown: {
    marginTop: -1,
    display: 'flex',
    flexDirection: 'column',
    minWidth: 200,
    maxWidth: 800,
    background: colors.hues.grays[190],
    border: `1px solid ${colors.hues.grays[180]}`,
    padding: theme.spacing(2),
    boxShadow:
      '0 6.4px 14.4px 0 rgba(0,0,0,.132), 0 1.2px 3.6px 0 rgba(0,0,0,.108)',
  },
}));

function getPlacement(isOpened, el) {
  if (!el || !isOpened) {
    return 'bottom-start';
  }
  const w = window.innerWidth;
  const { left } = el.getBoundingClientRect();
  return left / w < 0.5 ? 'bottom-start' : 'bottom-end';
}

interface Props extends LayoutProps {
  onContextMenu?: (evt) => void;
}

export default function ControlBarLayout(props: Props) {
  const {
    label,
    valuePreview,
    ControlElement,
    queryFilter,
    value,
    onForceUpdate,
    onContextMenu,
  } = props;
  const classes = useStyles();
  const [isOpenAnchor, setIsOpened] = useState(null);

  const onOpen = useCallback((evt) => {
    queryFilter.ensureControlData(value);
    setIsOpened(evt.target);
  }, []);
  const onClose = useCallback(() => {
    setIsOpened(null);
  }, []);

  const isOpened = !!isOpenAnchor;

  useDidMount(() => {
    queryFilter.events.on('updatedControlData', onForceUpdate);

    // when we mount, we ensure control data only if we currently have a value
    if (queryFilter.hasValue(value)) {
      queryFilter.ensureControlData(value);
    }
    return () => {
      queryFilter.events.off('updatedControlData', onForceUpdate);
    };
  });

  return (
    <>
      <ControlBarLayoutPreview
        label={label}
        isOpen={isOpened}
        valuePreview={valuePreview}
        onClick={isOpened ? onClose : onOpen}
        onContextMenu={isOpened ? undefined : onContextMenu}
      />
      {isOpened && (
        <Popper
          className={classes.popper}
          open={isOpened}
          anchorEl={isOpenAnchor}
          placement={getPlacement(isOpened, isOpenAnchor)}
        >
          <ClickAwayListener onClickAway={onClose}>
            <div
              className={classes.dropdown}
              // this stops dnd-kit from interpreting drag events
              // while the popover is open
              onPointerDown={stopEventPropagation}
            >
              {isOpened && queryFilter.hasControlData() && ControlElement}
              {isOpened && !queryFilter.hasControlData() && (
                <div className={classes.loadingMessage}>Loading...</div>
              )}
            </div>
          </ClickAwayListener>
        </Popper>
      )}
    </>
  );
}
