import _ from 'lodash';
import { gql, useQuery } from '@apollo/client';
import { LoadingIndicator, ErrorMessageContent } from '@shield-ui/core';
import { Typography, Paper, Grid, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { robotLogReviewFragment } from '../../../services/reviews';
import CommonReviewSection from '../../../components/reviews/ReviewSection';
import ReviewStats from '../../../components/reviews/ReviewStats';
import ChildReview from './ChildReview';
import { getSessionLogDisplayName } from '../../../services/robotLogs';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
}));

const query = gql`
    query($robotLogId:String!){
      robotLog(id: $robotLogId) {
        id
        name
        robotLogReviews (sort: [review_status_asc, reviewed_time_desc]) {
          edges {
            node {
              ${robotLogReviewFragment}
            }
          }
        }
        children {
          id
          name
          robotLogReviews (sort: [review_status_asc, reviewed_time_desc]) {
            edges {
              node {
                ${robotLogReviewFragment}
              }
            }
          }
        }
      }
    }
`;

function ReviewSection({ robotLogId }) {
  const classes = useStyles();
  const { loading, error, data, refetch } = useQuery(query, {
    variables: { robotLogId },
    fetchPolicy: 'cache-first',
  });

  if (loading) {
    return <LoadingIndicator />;
  }
  if (error) {
    return <ErrorMessageContent>{error.message}</ErrorMessageContent>;
  }

  const reviews = _.get(data, ['robotLog', 'robotLogReviews', 'edges'], []).map(
    (e) => e.node
  );

  const children = _.get(data, ['robotLog', 'children']);

  let completedReviews = 0;
  children.forEach((c) => {
    let hasComplete = c.robotLogReviews.edges.some(
      (r) =>
        r.node.reviewStatus === 'COMPLETE' || r.node.reviewStatus === 'APPROVED'
    );
    if (hasComplete) {
      completedReviews++;
    }
  });

  const reviewStats = {
    byReviewBreakdown: [], //unneccessary for teaming component
    totalCount: children.length,
    totalProgressCount: completedReviews,
  };

  return (
    <div>
      {!!children.length && (
        <Typography variant="overline">
          {getSessionLogDisplayName(data.robotLog)}
        </Typography>
      )}
      <CommonReviewSection
        robotLogId={robotLogId}
        reviews={reviews}
        onRefresh={refetch}
      />

      {!!children.length && (
        <Box style={{ marginTop: 10 }}>
          <Typography variant="overline">CHILDREN</Typography>
          <Grid>
            <Paper className={classes.container}>
              <ReviewStats
                reviews={[]}
                reviewStats={reviewStats}
                type={'review'}
              />
              {children.map((c) => {
                const reviews = _.get(c, ['robotLogReviews', 'edges'], []).map(
                  (r) => r.node
                );
                return (
                  <ChildReview
                    key={c.id}
                    reviews={reviews}
                    name={c.name}
                    childId={c.id}
                    refetch={refetch}
                  />
                );
              })}
            </Paper>
          </Grid>
        </Box>
      )}
    </div>
  );
}

export default ReviewSection;
