import React from 'react';
import { MarkdownContent } from '@shield-ui/core';
import withStyles from '@mui/styles/withStyles';
import _ from 'lodash';
import moment from 'moment';
import NoteTypeDisplay from '../../../../notes/NoteTypeDisplay';

function styles(theme) {
  return {
    container: {
      whiteSpace: 'initial',
    },
    row: {
      marginTop: 10,
      marginBottom: 10,
    },
    header: {
      margin: ` 5px`,
      marginBottom: 2,
      display: 'flex',
      justifyContent: 'space-between',
    },
    name: {
      flex: 1,
    },
    noteType: {
      marginLeft: 5,
      fontWeight: 300,
    },
    date: {
      marginLeft: 12,
      fontWeight: 300,
    },
    noteDisplay: {
      background: `rgba(0, 0, 0, 0.2)`,
      borderRadius: 5,
      marginRight: 3,
      padding: `2px 8px`,
    },
  };
}

export function parseForNotes(row, noteFilter) {
  let notes = _.get(row, 'notes.edges', []).map((edge) => edge.node);

  if (noteFilter) {
    notes = notes.filter(noteFilter);
  }

  return notes;
}

function NotesCell({ classes, row, noteFilter }) {
  const notes = parseForNotes(row, noteFilter);

  return (
    <div className={classes.container}>
      {notes.map((note, i) => {
        const { displayText, createdAt, noteType } = note;
        const name = _.get(note, 'createdBy.name', 'Unknown');

        return (
          <div key={i} className={classes.row}>
            <div className={classes.header}>
              <div className={classes.name}>{name}</div>
              <div className={classes.noteType}>
                {/* Only show noteType if filter is not passed in */}
                {noteFilter && <NoteTypeDisplay noteType={noteType} />}
              </div>
              {createdAt && (
                <div className={classes.date}>
                  {moment.utc(createdAt).format('MM/DD')}
                </div>
              )}
            </div>
            <div className={classes.noteDisplay}>
              <MarkdownContent md={displayText} />
            </div>
          </div>
        );
      })}
    </div>
  );
}
export default withStyles(styles)(NotesCell);
