import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Input } from 'antd';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  AdHocEsQueryFilterControlData,
  AdHocEsQueryFilterValue,
} from '../../filterTypes';
import { ControlProps } from '../common';
import ActionsContainer from '../shared/ActionsContainer';
import ResetButton from '../shared/ResetButton';
import { colors } from '@shield-ui/styles';

interface Props
  extends ControlProps<AdHocEsQueryFilterValue>,
    AdHocEsQueryFilterControlData {}

const useStyles = makeStyles((theme) => ({
  textarea: {
    marginRight: theme.spacing(2),
    minWidth: 340,
    fontFamily: 'monospace',
  },
  inputGroup: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(1),
  },
  errorMessage: {
    display: 'flex',
    alignItems: 'center',
  },
  errorIcon: {
    fontSize: 16,
    color: colors.hues.red,
    marginRight: theme.spacing(0.5),
  },
}));

export default function AdHocEs(props: Props) {
  const classes = useStyles();
  const { onChange, onClear, value, hasValue } = props;

  const isInvalid = hasValue && !value.esQuery;

  return (
    <div className={classes.inputGroup}>
      <Input.TextArea
        autoSize={{ minRows: 10, maxRows: 20 }}
        placeholder="Advanced Feature: Type a JSON query that will evaluate against the Elasticsearch Document"
        value={value ? value.rawQuery : ''}
        onChange={(evt) => onChange({ rawQuery: evt.target.value })}
        className={classes.textarea}
      />
      <ActionsContainer
        Left={
          isInvalid && (
            <div className={classes.errorMessage}>
              <ErrorOutlineIcon className={classes.errorIcon} /> Invalid JSON
            </div>
          )
        }
        Right={<ResetButton onClick={onClear} disabled={!hasValue} />}
      />
    </div>
  );
}
