import _ from 'lodash';

/**
 * Takes an input string and standardizes it for match comparision (normalizes case, etc...)
 * @param {string} str
 */
const matchingRegex1 = /[^\s|@|a-zA-Z0-9]/g;
const matchingRegex2 = /\s+/g;
export function matchifyString(str = '') {
  return str
    .toLowerCase()
    .replace(matchingRegex1, ' ')
    .replace(matchingRegex2, ' ')
    .trim();
}

export function doesInputMatchBase(input, base) {
  if (!base) {
    return false;
  }

  return matchifyString(base).indexOf(matchifyString(input)) > -1;
}

export function countAndPluralize(count, string) {
  if (count === 1) {
    return `${count} ${string}`;
  } else {
    return `${count} ${string}s`;
  }
}

export function getShortAcronym(input, count = 2) {
  let ret = '';

  const searchString = input
    .replace(/(the|and|of)/gi, ' ')
    .replace(/\s/g, '')
    .replace(/[^a-zA-Z0-9]/g, '');

  for (let i = 0; i < searchString.length; i++) {
    const char = searchString[i];
    if (ret.length < count && char === char.toUpperCase()) {
      ret += char;
    }
  }
  if (ret.length < count) {
    ret += searchString.slice(ret.length, count).toUpperCase();
  }

  return ret;
}

export function getNumberDisplayToDecimalPlace(
  value: number,
  numberOfDecimals = 1
) {
  if (!_.isNumber(value)) {
    return;
  }
  const str = value.toFixed(numberOfDecimals);
  return parseFloat(str).toString();
}

export function getStringFromErrorLike(errorLike): string {
  if (_.isString(errorLike)) {
    return errorLike;
  }

  return (
    _.get(errorLike, 'message') ||
    _.get(errorLike, 'result.error.message') ||
    ''
  );
}

export function ellipsesStringAt(str = '', maxLen = 100): string {
  if (str.length > maxLen) {
    return str.substr(0, maxLen - 3) + '...';
  }
  return str;
}

/**
 * Take a coarse, input string from ENUMS or internal and cryptic and format it for a more pretty user experience
 * E.G. cant_take_off = 'Cant take off'
 *
 * @param inputString
 */
export function prettifyString(inputString = '') {
  return inputString
    .toLowerCase()
    .replace(/_/g, ' ')
    .replace(/['"]+/g, '')
    .split(' ')
    .map(_.capitalize)
    .join(' ');
}

export function slugify(inputString = '') {
  return inputString.toLowerCase().replace(/[^0-9a-z]+]/g, '_');
}
