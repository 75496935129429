import React, { useEffect, useState } from 'react';
import { uuid4 } from '@shield-ui/utils';
import { CreateOrUpdateModal } from './CreateOrUpdateModal';
import { ControlBar } from './Controls';
import PlotWrapper from './PlotWrapper';
import { getCachedItem, setCachedItem } from '@shield-ui/utils';

interface Props {
  query?: object;
}

interface plotConfig {
  id: string;
  plotTypeId: string;
  plotOptions: object;
}

const DEFAULT_MATCH_ALL_QUERY = { match_all: {} };

function MetricExplorer(props: Props) {
  const { query = DEFAULT_MATCH_ALL_QUERY } = props;
  const cachedItem = getCachedItem('metricExplorerPlotConfig') as plotConfig[];
  const cachedState =
    cachedItem === undefined ? ([] as plotConfig[]) : cachedItem;

  const [plotConfigs, setPlotConfigs] = useState<plotConfig[]>(cachedState);
  const [plotTypeId, setPlotTypeId] = useState(null);
  const [plotConfigToUpdate, setPlotConfigToUpdate] = useState(null);
  //localStorage.removeItem("ai.shield.vision--metricExplorerPlotConfig");

  const isUpdate = !!plotConfigToUpdate;

  useEffect(() => {
    setCachedItem('metricExplorerPlotConfig', plotConfigs);
  }, [plotConfigs]);

  const onSave = (plotOptions) => {
    if (isUpdate === true) {
      const updatedPlotConfig = {
        id: plotConfigToUpdate.id,
        plotTypeId: plotConfigToUpdate.plotTypeId,
        plotOptions: plotOptions,
      };
      const updatedPlotConfigs = plotConfigs.map((obj) => {
        if (obj.id === plotConfigToUpdate.id) {
          return updatedPlotConfig;
        }
        return obj;
      });
      setPlotConfigs(updatedPlotConfigs);
    } else {
      const newPlot = {
        id: uuid4(),
        plotTypeId: plotTypeId,
        plotOptions: plotOptions,
      };
      const newPlotConfigs = plotConfigs.concat([newPlot]);
      setPlotConfigs(newPlotConfigs);
    }
    setPlotTypeId(null); //make modal invisible again
  };
  const handleDelete = (plotConfig) => {
    setPlotConfigs(plotConfigs.filter((e) => e.id !== plotConfig.id));
  };
  const handleUpdate = (plotConfig) => {
    setPlotConfigToUpdate(plotConfig);
    setPlotTypeId(plotConfig.plotTypeId);
  };

  return (
    <div>
      <ControlBar
        onCreateRequest={(plotTypeId) => {
          setPlotTypeId(plotTypeId);
        }}
      />
      {plotTypeId && (
        <CreateOrUpdateModal
          plotTypeId={plotTypeId}
          onCancel={() => {
            setPlotTypeId(null);
            setPlotConfigToUpdate(undefined);
          }}
          onSave={onSave}
          updatePlotConfig={plotConfigToUpdate}
          isUpdate={isUpdate}
        />
      )}
      {plotConfigs.map((plotConfig) => (
        <PlotWrapper
          key={plotConfig.id}
          plotConfig={plotConfig}
          query={query}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
        />
      ))}
    </div>
  );
}

export default MetricExplorer;
