import React, { useCallback, useState, useMemo } from 'react';
import _ from 'lodash';
import { Slider } from 'antd';
import {
  ValueRangeControlData,
  ValueRangeQueryFilterValue,
} from '../../filterTypes';
import { ControlProps } from '../common';
import ResetButton from '../shared/ResetButton';
import ActionsContainer from '../shared/ActionsContainer';

interface Props
  extends ControlProps<ValueRangeQueryFilterValue>,
    ValueRangeControlData {}

export default function ValueRange(props: Props) {
  const [slidingValue, setSlidingValue] = useState(undefined);
  const {
    onChange,
    onClear,
    hasValue,
    value,
    minThreshold,
    maxThreshold,
    numberOfInBetweenMarksToDisplay,
    formatValue,
  } = props;

  const marks = useMemo(() => {
    if (
      !numberOfInBetweenMarksToDisplay ||
      typeof minThreshold !== 'number' ||
      typeof maxThreshold !== 'number' ||
      !formatValue
    ) {
      return undefined;
    }

    const ret = {
      [minThreshold]: formatValue(minThreshold),
      [maxThreshold]: formatValue(maxThreshold),
    };

    let i;
    for (i = 1; i < numberOfInBetweenMarksToDisplay + 1; i++) {
      const p = i / (numberOfInBetweenMarksToDisplay + 1);
      const delta = maxThreshold - minThreshold;
      const k = Math.round(p * delta + minThreshold);
      ret[k] = formatValue(k);
    }
    return ret;
  }, [
    minThreshold,
    maxThreshold,
    numberOfInBetweenMarksToDisplay,
    formatValue,
  ]);

  const sliderOnChange = useCallback(
    (v) => {
      setSlidingValue(v);
    },
    [setSlidingValue]
  );

  const sliderOnAfterChange = useCallback(
    (v) => {
      onChange({
        minValue: v[0] !== minThreshold ? v[0] : undefined,
        maxValue: v[1] !== maxThreshold ? v[1] : undefined,
      });
      setSlidingValue(undefined);
    },
    [onChange, setSlidingValue, minThreshold, maxThreshold]
  );

  let val;
  if (slidingValue) {
    val = slidingValue;
  } else if (value) {
    val = [
      _.get(value, 'minValue', minThreshold),
      _.get(value, 'maxValue', maxThreshold),
    ];
  }

  return (
    <div style={{ minWidth: 500, padding: `8px` }}>
      <Slider
        range
        marks={marks}
        min={minThreshold}
        max={maxThreshold}
        value={val}
        defaultValue={[minThreshold, maxThreshold]}
        onChange={sliderOnChange}
        onAfterChange={sliderOnAfterChange}
        tipFormatter={formatValue}
      />
      <ActionsContainer
        Right={<ResetButton onClick={onClear} disabled={!hasValue} />}
      />
    </div>
  );
}
