import _ from 'lodash';
import { setCachedItem, getCachedItem } from '@shield-ui/utils';

export enum FeatureFlags {
  metricExplorer = 'metricExplorer',
}

export type FeatureFlag = {
  key: FeatureFlags;
  title: string;
  description: string;
  defaultValue: boolean;
};

export const featureFlagList: FeatureFlag[] = [
  {
    key: FeatureFlags.metricExplorer,
    title: "Metric Explorer",
    description: "Enabling the Metric Explorer will enable a new content discovery experience for any search results, allowing you to switch between tabular views and metric plots defined by the user",
    defaultValue: false,
  }
];

export function setFeatureFlag(key: FeatureFlags, value: boolean) {
  setCachedItem(`feature-flag-${key}`, value);
}

export function getFeatureFlag(key: FeatureFlags): boolean {
  const valueFromCache = getCachedItem(`feature-flag-${key}`) as boolean;

  if (valueFromCache === undefined) {
    const rule = _.find(featureFlagList, { key });
    if (rule) {
      return rule.defaultValue;
    }
    return false;
  }

  return valueFromCache;
}
