import React from 'react';
import _ from 'lodash';
import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import AsyncCreatable from 'react-select/async-creatable';
import Async from 'react-select/async';
import { lighten } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import { Typography, Chip, MenuItem } from '@mui/material';
import TextField from '../TextField/TextField';
import { colors, commonStyles } from '@shield-ui/styles';
import { doesInputMatchBase } from '@shield-ui/utils';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: 'flex',
    height: 'auto',
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
  },
  singleValueDisabled: {
    color: colors.semantic.inputTextDisabled,
  },
  placeholder: {
    zIndex: 0,
    whiteSpace: 'nowrap',
    ...commonStyles.placeholder,
  },
  paper: {
    position: 'absolute',
    zIndex: 99999,
    left: 0,
    right: 0,
  },
  // need to be defined here so it can be over-ridden without warning
  option: {
    fontSize: 14,
    height: 'auto', // remove fixed size
  },
  optionSelected: {
    fontWeight: 600,
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      variant="body2"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function isButton(node, depth = 0) {
  const role = (node.getAttribute('role') || '').toUpperCase();
  const { nodeName } = node;

  if (role === 'BUTTON' || nodeName === 'BUTTON') {
    return true;
  }
  if (depth < 4) {
    return isButton(node.parentNode, depth + 1);
  }
  return false;
}

function shouldSkipMouseDown(evt) {
  const { target } = evt;
  return isButton(target);
}

function Control(props) {
  return (
    <TextField
      error={props.selectProps.error}
      placeholder={props.selectProps.placeholder}
      inputComponent="div"
      inputRef={props.innerRef}
      inputProps={{
        className: props.selectProps.classes.input,
        children: props.children,
        ...props.innerProps,
        onMouseDown: (evt) => {
          // This is a workaround for our chips we use as multi-select items
          // If the select menu causes the window to scroll when it is clicked on.
          // This behavior would cause the delete function of the chip to not be called
          // resulting in the behavior where if selects were on the bottom of the screen, you could not remove items in the select
          if (shouldSkipMouseDown(evt)) {
            return;
          }
          props.innerProps.onMouseDown(evt);
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

export function SelectOptionBase(props) {
  const { classes } = props.selectProps;
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      className={cx(classes.option, props.isSelected && classes.optionSelected)}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Option({ children, ...rest }) {
  return <SelectOptionBase {...rest}>{children}</SelectOptionBase>;
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography
      className={cx(
        props.selectProps.classes.singleValue,
        props.isDisabled && props.selectProps.classes.singleValueDisabled
      )}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  const { selectProps, children } = props;

  return <div className={selectProps.classes.valueContainer}>{children}</div>;
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      onDelete={props.removeProps.onClick}
    />
  );
}

const defaultComponents = {
  Control,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

const getStyles = memoizeOne((theme) => ({
  input: (base) => ({
    ...base,
    color: theme.palette.text.primary,
    '& input': {
      font: 'inherit',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  menu: (base) => ({
    ...base,
    backgroundColor: colors.semantic.inputBackground,
    marginTop: 0,
  }),
  indicatorsContainer: (base) => ({
    ...base,
    cursor: 'pointer',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: colors.semantic.inputIcon,
  }),
  clearIndicator: (base) => ({
    ...base,
    color: lighten(colors.semantic.inputIcon, 0.5),
  }),
}));

// default react-select value matches on value also which is really weird with UUIDS
function defaultFilterOption(opt, input) {
  if (!input) {
    return true;
  }
  const { label } = opt.data;
  // if we don't use a standard "label" in your input suggestions, then you will need to provide
  // your own custom filterOption function
  // you can also provide your own filterOption function to match on multiple fields eg "label" and "description"
  if (!_.isString(label)) {
    return true;
  }
  return doesInputMatchBase(input, label);
}

class IntegrationReactSelect extends React.PureComponent {
  static defaultProps = {
    suggestions: [],
  };

  onChange = (opt, meta) => {
    const { onChange } = this.props;

    if (onChange) {
      onChange(opt, meta);
    }
  };

  render() {
    const {
      suggestions,
      value,
      classes,
      theme,
      placeholder,
      label,
      isMulti,
      isCreatable,
      isAsync,
      notClearable,
      components,
      loadOptions,
      defaultOptions,
      noOptionsMessage,
      isDisabled,
      filterOption = defaultFilterOption,
      disabled, // to make this more consistant with other material-ui props
      error,
    } = this.props;

    let SelectComponent = Select;
    if (isAsync && isCreatable) {
      SelectComponent = AsyncCreatable;
    } else if (isAsync) {
      SelectComponent = Async;
    } else if (isCreatable) {
      SelectComponent = CreatableSelect;
    }

    const multiSelectComponents = components
      ? { ...defaultComponents, ...components }
      : defaultComponents;

    const standardProps = {
      styles: getStyles(theme),
      classes,
      placeholder,
      options: suggestions,
      components: multiSelectComponents,
      onChange: this.onChange,
      isClearable: !notClearable,
      // async
      loadOptions,
      defaultOptions,
      noOptionsMessage,
      isDisabled: isDisabled || disabled,
      error,
    };

    if (isMulti) {
      const selectedValue = _.isArray(value)
        ? suggestions.filter((sug) => value.indexOf(sug.value) > -1)
        : null;

      return (
        <div className={classes.root}>
          <SelectComponent
            {...standardProps}
            textFieldProps={{
              label: label,
            }}
            value={selectedValue}
            isMulti
            getOptionLabel={this.props.getOptionLabel}
            filterOption={filterOption}
          />
        </div>
      );
    } else {
      let selectedValue = null;
      if (!_.isUndefined(value)) {
        suggestions.forEach((sug) => {
          if (selectedValue) {
            return;
          }
          if (sug.value === value) {
            selectedValue = [sug];
          }
          if (sug.options && !selectedValue) {
            sug.options.forEach((opt) => {
              if (opt.value === value) {
                selectedValue = [opt];
              }
            });
          }
        });
      }

      return (
        <div className={classes.root}>
          <SelectComponent
            {...standardProps}
            value={selectedValue}
            getOptionLabel={this.props.getOptionLabel}
            filterOption={filterOption}
          />
        </div>
      );
    }
  }
}

IntegrationReactSelect.propTypes = {
  classes: PropTypes.object,
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.any,
    })
  ).isRequired,
  disabled: PropTypes.bool,
  getOptionLabel: PropTypes.func,
  filterOption: PropTypes.func,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool,
  isCreatable: PropTypes.bool,
  isAsync: PropTypes.bool,
  notClearable: PropTypes.bool,
  loadOptions: PropTypes.func,
  //cacheOptions: PropTypes.bool,
  //defaultOptions: PropTypes.bool,
  noOptionsMessage: PropTypes.func,
};
IntegrationReactSelect.defaultProps = {
  isMulti: false,
};

export default withStyles(styles, { withTheme: true })(IntegrationReactSelect);
