import React from 'react';
import _ from 'lodash';
import cx from 'classnames';
import {
  Player,
  ControlBar,
  PlaybackRateMenuButton,
  BigPlayButton,
  ReplayControl,
  LoadingSpinner,
} from 'video-react';
import withStyles from '@mui/styles/withStyles';
import CopyTimeButton from './CopyTimeButton';
import { copyToClipboard } from '@shield-ui/utils';
import { colors } from '@shield-ui/styles';
import 'video-react/dist/video-react.css';
import './video-react-overrides.css';

const styles = () => {
  return {
    container: {
      transition: '0.15s ease',
    },
    containerHidden: {
      height: 0,
      opacity: 0,
    },
    containerLoading: {
      margin: 16,
      height: '1em',
      textAlign: 'center',
      fontSize: 11,
      color: colors.hues.grays[120],
    },
    containerError: {
      margin: 16,
      padding: 32,
      height: '1.5em',
      textAlign: 'center',
    },
    videoContainer: {
      fontFamily: 'inherit',
    },
  };
};

class PlayerContent extends React.Component {
  state = {
    error: undefined,
  };

  constructor(props) {
    super(props);

    this.playerRef = React.createRef();
  }

  getVideoUrl() {
    const { videoPath } = this.props;
    if (!videoPath) {
      return null;
    }
    return videoPath;
  }

  handleVideoError = (err) => {
    this.setState({
      error: err,
    });
  };

  onCopyTimeCode = () => {
    if (this.playerRef && this.playerRef.current) {
      const time = _.get(
        this.playerRef.current.getState(),
        'player.currentTime'
      );
      if (_.isNumber(time)) {
        // video player time display floors as well so this is consistent
        copyToClipboard(Math.floor(time) + '');
      }
    }
  };

  seekAndPlay = (time) => {
    if (this.playerRef && this.playerRef.current) {
      this.playerRef.current.seek(time);
      this.playerRef.current.play();
    }
  };

  render() {
    const { classes, isLoading, serviceError } = this.props;
    const { error } = this.state;

    const videoUrl = this.getVideoUrl();
    if (!videoUrl && !isLoading) {
      return <div className={cx(classes.container, classes.containerHidden)} />;
    }
    if (serviceError || error) {
      return (
        <div className={cx(classes.container, classes.containerError)}>
          Unable to load video
        </div>
      );
    }
    if (isLoading) {
      return (
        <div className={cx(classes.container, classes.containerLoading)}>
          Checking for video...
        </div>
      );
    }

    return (
      <div className={classes.container}>
        <Player
          ref={this.playerRef}
          onError={this.handleVideoError}
          className={classes.videoContainer}
          onPlay={this.onPlay}
          onSeeked={this.onSeeked}
        >
          <source src={videoUrl} type="video/mp4" />
          <LoadingSpinner />
          <BigPlayButton position="center" />
          <ControlBar autoHide={false}>
            <ReplayControl seconds={5} order={2.1} />
            <ReplayControl seconds={10} order={2.2} />
            <ReplayControl seconds={30} order={2.3} />
            <CopyTimeButton order={7.4} onClick={this.onCopyTimeCode} />
            <PlaybackRateMenuButton rates={[4, 2, 1]} />
          </ControlBar>
        </Player>
      </div>
    );
  }
}

export default withStyles(styles)(PlayerContent);
