import React from 'react';
import { Helmet } from 'react-helmet';
import makeStyles from '@mui/styles/makeStyles';
import IconButton from '@mui/material/IconButton';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import { LoadingIndicator } from '@shield-ui/core';
import { colors } from '@shield-ui/styles';
import { copyToClipboard } from '@shield-ui/utils';
import RobotLogLabels from './RobotLogLabels';
import RobotLogBackHeader from './RobotLogBackHeader';
import HeaderProps from './HeaderProps/HeaderProps';
import FlightPaging from './FlightPaging/FlightPaging';
import FlightPage from './FlightPage';
import DiagnosticsAction from '../../components/diagnostics/DiagnosticsGoToAction';
import tracker from '../../lib/tracker';
import { Tooltip } from 'antd';
import container from './container';

const useStyles = makeStyles((theme) => ({
  pageRoot: {
    padding: '16px 11px',
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  topContent: {
    flex: 1,
    marginRight: 15,
  },
  rightActions: {
    display: 'flex',
    flexDirection: 'row',
    height: 60,
    alignItems: 'center',
    marginRight: 32,
  },
  typeContent: {
    padding: theme.spacing(4, 4),
  },
  copyButton: {
    padding: 1,
    marginLeft: theme.spacing(0.5),
  },
  copyIcon: {
    fontSize: 12,
    color: colors.hues.grays[90],
  },
}));

function showDiagnosticsAction(sessionLogTypeSlug) {
  return (
    sessionLogTypeSlug === 'team_flight' ||
    sessionLogTypeSlug === 'trial' ||
    sessionLogTypeSlug === 'instance'
  );
}

function RobotLogInnerPage(props) {
  const classes = useStyles();
  const { backTo, robotLogId, pageRobotLog } = props;

  if (!pageRobotLog) {
    return <LoadingIndicator />;
  }

  tracker.trackEvent(
    'SessionLogTypePage',
    pageRobotLog.sessionLogTypeSlug,
    'RobotLogId',
    robotLogId
  );

  return (
    <div className={classes.pageRoot}>
      <div className={classes.topContainer}>
        <div className={classes.topContent}>
          <Helmet>
            <title>{pageRobotLog.name}: Vision</title>
          </Helmet>
          <RobotLogBackHeader
            robotLog={pageRobotLog}
            backTo={backTo}
            CopyUniqueNameButton={
              <Tooltip key={0} placement="bottom" title={'copy unique name'}>
                <IconButton
                  className={classes.copyButton}
                  onClick={() => copyToClipboard(pageRobotLog.name)}
                  size="large"
                >
                  <CopyIcon className={classes.copyIcon} />
                </IconButton>
              </Tooltip>
            }
            RobotLogLabelSelect={
              <RobotLogLabels key={1} robotLogId={robotLogId} />
            }
          ></RobotLogBackHeader>
        </div>
        <div className={classes.rightActions}>
          <FlightPaging />
          {showDiagnosticsAction(pageRobotLog.sessionLogTypeSlug) && (
            <DiagnosticsAction robotLogId={robotLogId} />
          )}
        </div>
      </div>
      <HeaderProps robotLogId={robotLogId} />
      <div className={classes.typeContent}>
        <FlightPage {...props} />
      </div>
    </div>
  );
}

export default container(RobotLogInnerPage);
