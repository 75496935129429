import React from 'react';
import _ from 'lodash';
import masterNotesConfig from '../notes';
import { NOTE_TYPES } from '../../../../../services/notes';

function noteFilter(note) {
  return _.get(note, 'noteType') === NOTE_TYPES.POST_REVIEW;
}

const config = {
  ...masterNotesConfig,
  columnKey: 'notesPostReview',
  label: 'Notes (Post Review)',
  description:
    'Observer notes that happened at after a flight (e.g. during playback analysis)',
  Cell: ({ row }) => {
    return <masterNotesConfig.Cell row={row} noteFilter={noteFilter} />;
  },
  getValue: ({ row }) => {
    return masterNotesConfig.getValue({ row, noteFilter });
  },
};

export default config;
