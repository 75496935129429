import _ from 'lodash';
import { Radio } from 'antd';
import { OwnerTypes } from '../common';
import { LabelIdType, LabelType } from '@shield-ui/heatgrid';
import {
  MultiselectQueryFilterValue,
  GroupQueryFilterValue,
  UNSET_OPTION_VALUE,
} from '@shield-ui/query-filters';
import {
  default as ownerGroupQueryFilter,
  testDefinitionOwnerTeams as teamQueryFilter,
  testDefinitionOwnerUsers as userQueryFilter,
} from '../../../lib/queryFilters/robotLog/testDefinitionOwners';
import { getUsers } from '../../../services/users';
import { getTeams} from '../../../services/teams';
import {
  AxisDefinition,
  AxisOption,
  RenderDropdownFormProps,
} from './AxisDefinition';

export const ownerAxisDefinition: AxisDefinition = {
  id: 'Owner',
  getDropdownLabel: (axisOption?: AxisOption) => {
    if (axisOption && axisOption.ownerType) {
      return `Owners - ${axisOption.ownerType}`;
    }
    return 'Owners';
  },
  buildAggsDsl: (axisOption?: AxisOption) => {
    if (axisOption && axisOption.ownerType === OwnerTypes.Users) {
      return {
        terms: {
          field: 'test_definition.ownership.users.id',
          missing: 'N/A',
        },
      };
    } else {
      return {
        terms: {
          field: 'test_definition.ownership.teams.id',
          missing: 'N/A',
        },
      };
    }
  },
  prepareAxisLabels: (labelIds: LabelIdType[], axisOption: AxisOption) => {
    switch (axisOption.ownerType) {
      case OwnerTypes.Teams:
        return getTeams().then((res) => {
          const teamMap = res.reduce(
            (acc, t) => ({
              ...acc,
              [t.id]: t,
            }),
            {}
          );
          let emptyLabel: LabelType = undefined;
          const labels: LabelType[] = _.sortBy(
            labelIds.reduce((acc, ownerId) => {
              if (ownerId === 'N/A') {
                emptyLabel = {
                  id: ownerId,
                  label: '-',
                };
              } else if (teamMap[ownerId]) {
                acc.push({
                  id: ownerId,
                  label: teamMap[ownerId].name,
                });
              } else {
                acc.push({
                  id: ownerId,
                  label: ownerId,
                });
              }
              return acc;
            }, []),
            (label) => label.label
          );

          if (emptyLabel) {
            return [...labels, emptyLabel];
          }
          return labels;
        });
      case OwnerTypes.Users:
        return getUsers().then((res) => {
          const userMap = res.reduce(
            (acc, u) => ({
              ...acc,
              [u.id]: u,
            }),
            {}
          );

          let emptyLabel: LabelType = undefined;
          const labels: LabelType[] = _.sortBy(
            labelIds.reduce((acc, ownerId) => {
              if (ownerId === 'N/A') {
                emptyLabel = {
                  id: ownerId,
                  label: '-',
                };
              } else if (userMap[ownerId]) {
                acc.push({
                  id: ownerId,
                  label: userMap[ownerId].name,
                });
              } else {
                acc.push({
                  id: ownerId,
                  label: ownerId,
                });
              }
              return acc;
            }, []),
            (label) => label.label
          );

          if (emptyLabel) {
            return [...labels, emptyLabel];
          }
          return labels;
        });
    }
  },
  buildQueryFilter: (
    filterValues: object,
    selectedLabels: LabelType[],
    axisOption?: AxisOption
  ) => {
    // current individual values or new object
    const individualValues = _.get(
      filterValues,
      [ownerGroupQueryFilter.getId(), 'individualValues'],
      {}
    );

    // build the QF value for this grouped QF.
    const newOwnerValues: MultiselectQueryFilterValue = {
      includeOptionValues: selectedLabels.map((label) => {
        if (label.id === 'N/A') {
          return UNSET_OPTION_VALUE;
        }
        return label.id;
      }),
    };

    switch (axisOption.ownerType) {
      case OwnerTypes.Teams:
        individualValues[teamQueryFilter.getId()] = newOwnerValues;
        break;
      case OwnerTypes.Users:
        individualValues[userQueryFilter.getId()] = newOwnerValues;
        break;
    }

    // Build the Groups value and compute esQuery
    const newValue: GroupQueryFilterValue = {
      individualValues,
      esQuery: undefined,
    };
    newValue.esQuery = ownerGroupQueryFilter.getElasticQuery(newValue);

    // set the new value for this and return any other existing filter values
    return {
      ...filterValues,
      [ownerGroupQueryFilter.getId()]: newValue,
    };
  },
  defaultAxisOption: { ownerType: OwnerTypes.Users },
  renderForm: (p: RenderDropdownFormProps) => {
    return (
      <Radio.Group key={'owners'} value={p.axisOption?.ownerType}>
        {Object.keys(OwnerTypes).map((ownerType: OwnerTypes) => (
          <Radio.Button
            key={`owner-${ownerType}`}
            value={ownerType}
            onClick={() => p.onChange({ ownerType })}
          >
            {ownerType}
          </Radio.Button>
        ))}
      </Radio.Group>
    );
  },
};
