import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Input } from 'antd';
import { AntButton } from '@shield-ui/buttons';
import { MultiselectOption } from '../../filterTypes';

const useStyles = makeStyles((theme) => ({
  textarea: {
    marginRight: theme.spacing(2),
    minWidth: 340,
  },
  inputGroup: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
}));

interface Props {
  onAdd: (optionValues: string[]) => void;
  onInputChange?: (str: string) => void;
  placeholder: string;
}

export default function CreatableControls(props: Props) {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState('');
  const { onAdd, placeholder, onInputChange } = props;

  const onSubmit = () => {
    setInputValue('');
    if (onInputChange) {
      onInputChange('');
    }

    const additions = inputValue
      .split('\n')
      .map((l) => l.trim())
      .filter((l) => !!l);

    if (additions.length > 0) {
      onAdd(additions);
    }
  };
  const onEnter = (evt) => {
    if (evt.ctrlKey) {
      onSubmit();
    }
  };

  return (
    <div className={classes.inputGroup}>
      <Input.TextArea
        size="large"
        autoFocus
        autoSize={{ minRows: 1, maxRows: 6 }}
        placeholder={placeholder}
        value={inputValue}
        onChange={(evt) => {
          setInputValue(evt.target.value);
          if (onInputChange) {
            onInputChange(evt.target.value);
          }
        }}
        onPressEnter={onEnter}
        className={classes.textarea}
      />
      <AntButton
        size="large"
        type="primary"
        disabled={inputValue.length === 0}
        onClick={onSubmit}
      >
        Add
      </AntButton>
    </div>
  );
}
