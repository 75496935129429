import React from 'react';
import cx from 'classnames';
import { Button, ButtonProps } from 'antd';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from '@shield-ui/styles';

const useStyles = makeStyles((theme) => {
  const hoverSolidResting = {
    background: 'transparent',
    color: colors.hues.grays[40],
    borderColor: colors.hues.grays[70],
  };

  return {
    hoverSolid: {
      ...hoverSolidResting,
      '&:focus': {
        ...hoverSolidResting,
      },
      '&:hover, &:hover:focus': {
        color: colors.hues.grays[200],
        background: colors.hues.grays[20],
        borderColor: colors.hues.grays[20],
      },
    },
  };
});

export interface Props extends ButtonProps {
  customVariant?: 'hoverSolid';
}

export default function AntButton(props: Props) {
  const { customVariant = 'hoverSolid', children, className, ...rest } = props;
  const classes = useStyles();

  return (
    <Button className={cx(classes[customVariant], className)} {...rest}>
      {children}
    </Button>
  );
}
