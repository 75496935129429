import React from 'react';
import PropTypes from 'prop-types';
import RedoIcon from '@mui/icons-material/Redo';
import GenericTableManager from './generic/TableManager';
import {
  getColumnDefinition,
  getAllColumnDefinitions,
  DEFAULT_COLUMN_STATE,
  FIXED_COLUMN_STATE,
  DEFAULT_SORT_STATE,
  DEFAULT_PINNED_STATE,
  DEFAULT_COLUMN_WIDTHS,
} from './robotLogs2/columns';
import {
  getGraphqlPropsSelector,
  processQueryResponse,
} from './robotLogs2/utils';
import { getCachedItem, setCachedItem } from '@shield-ui/utils';
import container from './robotLogs2/container';
import {
  getQueryFilterItems,
  getDefaultVisibleIds,
  categoryOrder,
  filterValuesforDeprecatedFilters,
  getQueryFilterItemsWithEntities,
} from '../../lib/queryFilters/robotLog';
import { getFeatureFlag, FeatureFlags } from '../../lib/featureFlags';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';
import MetricExplorer from '../metric-explorer/MetricExplorer';
import Heatmap from '../test-heatmap/Heatmap';

function getStorageKey(localStorageKey, storageSuffix) {
  return `${localStorageKey}-${storageSuffix}`;
}

const CACHE_KEYS = {
  sort: 'sortv2',
  columns: 'columnsv2',
  filters: 'filtersv2',
  pinned: 'pinnedv2',
  widths: 'widthsv2',
};

export function getDefaultColumns() {
  return DEFAULT_COLUMN_STATE;
}

export function getDefaultSort() {
  return DEFAULT_SORT_STATE;
}

export function getDefaultPinnedColumns() {
  return DEFAULT_PINNED_STATE;
}

export function getDefaultWidths() {
  return DEFAULT_COLUMN_WIDTHS;
}

class RobotLogsTablev2 extends React.Component {
  static propTypes = {
    skipControls: PropTypes.bool, // turn of controls (i.e. create a collection preview page)
    tableCacheKey: PropTypes.string.isRequired,
    onChangeColumns: PropTypes.func,
    onChangeSort: PropTypes.func,
    // robotLogs special sauce
    localStorageKey: PropTypes.string,
    removeTableCacheOnUnmount: PropTypes.bool,
    title: PropTypes.string /* Descriptive name / title of the results */,
    // default if there is no columns from local storage OR if there are not columns passed in explicitly
    defaultColumns: PropTypes.array,
    // if passed in, overload the local storage cached value for this table
    columns: PropTypes.array,
    contextMenuOptionProps: PropTypes.shape({
      blacklistCollectionId: PropTypes.string,
    }),
    showHeatGrid: PropTypes.bool,
    tableContextProps: PropTypes.shape({
      sessionLogTypeSlugs: PropTypes.array.isRequired,
    }),
    onResult: PropTypes.func,
    additionalElasticQueries: PropTypes.arrayOf(PropTypes.object), // extra queries that get added into the built list for elastic
    // this message replaces the table render. Currently used for relationship section. Don't pass in if you want the table skeleton to render even if no results.
    overrideEmptyTableMsg: PropTypes.string,
    enableTopToolbar: PropTypes.bool,
    renderExtraTableControls: PropTypes.func,
  };

  static defaultProps = {
    localStorageKey: 'robotLogs',
    removeTableCacheOnUnmount: false,
    contextMenuOptionProps: {},
  };

  state = {
    controlBarQueryFilterIds: undefined,
  };

  componentDidMount() {
    if (!this.props.skipControls) {
      (async () => {
        this.setState({
          controlBarProps: {
            queryFilterItems: await getQueryFilterItemsWithEntities(
              this.sessionLogTypeSlugs
            ),
            defaultQueryFilterIds: getDefaultVisibleIds(
              this.sessionLogTypeSlugs
            ),
            categoryOrder,
            /*
        extraMenuItems: [{
          Icon: FunctionsIcon,
          label: "Explore Metrics",
          // this is very hacky, but we get all props from the controlBar
          // and one of those props we calculate with "getGraphqlPropsSelector" as elasticQuery
          onClick: (controlBarProps) => {
            const {elasticQuery} = controlBarProps;
            if (!elasticQuery) {
              alert('we dont have an elastic query!');
            }
            setCachedItem('metricExplorerQuery', elasticQuery);
            history.push('/app/metrics-explorer');
          },
        }],
         */
          },
        });
      })();
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      rowId: '',
      controlBarProps: {},
    };
    if (props.tableContextProps) {
      this.sessionLogTypeSlugs = props.tableContextProps.sessionLogTypeSlugs;
    }
    if (!this.props.skipControls) {
      this.state.controlBarProps = {
        queryFilterItems: getQueryFilterItems(this.sessionLogTypeSlugs),
        defaultQueryFilterIds: getDefaultVisibleIds(this.sessionLogTypeSlugs),
        categoryOrder,
      };
    }
    if (
      this.props.tableCacheKey !== 'parent-relationships' &&
      this.props.tableCacheKey !== 'children-relationships'
    ) {
      this.additionalContents = [];
      if (getFeatureFlag(FeatureFlags.metricExplorer)) {
        // TODO - should this only show if flights?
        this.additionalContents.push({
          label: 'Metric Explorer',
          path: '/metric-explorer',
          renderContent: ({ elasticQuery }) => {
            return <MetricExplorer query={elasticQuery} />;
          },
        });
      }
    }

    // TODO - conditionally show these things based on robotLogTypes (NOTE, collections does not pass in robotLogTypes so we need to solve that too)
    if (props.showHeatGrid) {
      this.additionalContents.push({
        label: 'Heat Grid',
        path: '/heat-grid',
        renderContent: (p) => {
          const { elasticQuery, filterValues, setFilterValues, jumpToContent } =
            p;
          return (
            <Heatmap
              query={elasticQuery}
              filterValues={filterValues}
              setFilterValues={setFilterValues}
              jumpToContent={jumpToContent}
            />
          );
        },
      });
    }

    const getColumns = () => {
      const { localStorageKey } = this.props;
      let columns = getCachedItem(
        getStorageKey(props.localStorageKey, CACHE_KEYS.columns)
      );
      //artifact columns no longer work moving forward. Removing them from cache
      console.log(
        'columns are not being passed in, getting from cache',
        columns
      );
      if (Array.isArray(columns)) {
        if (columns.length > 0) {
          columns = columns.filter(
            (c) =>
              c.columnKey !== 'artifactsBag' && c.columnKey !== 'artifactCount'
          );
        } else {
          columns = undefined;
        }
      }
      setCachedItem(
        getStorageKey(localStorageKey, CACHE_KEYS.columns),
        columns
      );

      return columns;
    };
    this.initializeProps = {
      columns:
        props.columns ||
        getCachedItem(getStorageKey(props.localStorageKey, CACHE_KEYS.columns)),
      defaultColumns: props.defaultColumns || DEFAULT_COLUMN_STATE,
      defaultSort: props.defaultSort || DEFAULT_SORT_STATE,
      fixedColumns: props.fixedColumns || FIXED_COLUMN_STATE, // these are prepended into the front
      pinnedColumns:
        props.pinnedColumns ||
        getCachedItem(
          getStorageKey(props.localStorageKey, CACHE_KEYS.pinned)
        ) ||
        DEFAULT_PINNED_STATE,
      widths:
        props.widths ||
        getCachedItem(
          getStorageKey(props.localStorageKey, CACHE_KEYS.widths)
        ) ||
        DEFAULT_COLUMN_WIDTHS,
      defaultPinnedColumns: props.defaultPinnedColumns || DEFAULT_PINNED_STATE,
      prependContextColumns: props.prependContextColumns || [],
      sort:
        props.sort ||
        getCachedItem(getStorageKey(props.localStorageKey, CACHE_KEYS.sort)) ||
        DEFAULT_SORT_STATE,
      filtersVariables:
        props.filtersVariables ||
        filterValuesforDeprecatedFilters(
          getCachedItem(
            getStorageKey(props.localStorageKey, CACHE_KEYS.filters)
          )
        ),
    };
    this.getGraphqlProps = getGraphqlPropsSelector();
  }

  showModal = (rowId) => {
    this.setState({ showModal: true, rowId: rowId });
  };

  hideModal = () => {
    this.setState({ showModal: false, rowId: '' });
  };

  /**
   * Default behavior but onChangeSort can be passed in to overload
   * @param columns
   */
  onChangeSort = ({ sort }) => {
    const { localStorageKey } = this.props;
    setCachedItem(getStorageKey(localStorageKey, CACHE_KEYS.sort), sort);
  };

  /**
   * Default behavior but onChangeColumns can be passed in to overload
   * @param columns
   */
  onChangeColumns = ({ columns }) => {
    const { localStorageKey } = this.props;
    setCachedItem(getStorageKey(localStorageKey, CACHE_KEYS.columns), columns);
  };

  onChangeFiltersVariables = ({ filtersVariables }) => {
    const { localStorageKey } = this.props;
    setCachedItem(
      getStorageKey(localStorageKey, CACHE_KEYS.filters),
      filtersVariables
    );
  };

  onChangePinnedColumns = ({ pinnedColumns }) => {
    const { localStorageKey } = this.props;
    setCachedItem(
      getStorageKey(localStorageKey, CACHE_KEYS.pinned),
      pinnedColumns
    );
  };

  onChangeWidths = ({ widths }) => {
    const { localStorageKey } = this.props;
    setCachedItem(getStorageKey(localStorageKey, CACHE_KEYS.widths), widths);
  };
  onRowClick = ({ row, tableManagerProps }) => {
    const { setPaging, title } = this.props;
    const { pagination, pageSize, result } = tableManagerProps;
    const index = pagination.page * pageSize + result.rows.indexOf(row);

    // This is getting a little murky because we have data flowing into TableManager then back out here but I think it is okay for now
    // Captures a bunch of stuff pertinent to the table from which the user came from

    const { graphqlVariables } =
      tableManagerProps.getGraphqlProps(tableManagerProps);

    const flightPaging = {
      description: title ? `Paging through ${title} results` : undefined,
      startCursor: result.startCursor,
      endCursor: result.endCursor,
      total: result.total,
      ids: result.rows.map((row) => row.id),
      robotLogId: row.id,
      index,
      backTo: window.location.pathname,
      searchVersion: 'documentSearchV1',
      queryVariables: {
        sort: graphqlVariables.sort,
        query: graphqlVariables.query,
      },
    };

    setPaging({
      paging: flightPaging,
    });
  };

  buildContextMenu = ({ row, onClose, tableManagerProps }) => {
    const { contextMenuOptionProps, runIncludedExcludedMutation } = this.props;
    const { blacklistCollectionId } = contextMenuOptionProps;
    return (
      <List>
        <ListItemButton
          dense
          onClick={() => {
            this.onRowClick({ row, tableManagerProps });
            window.open(row.url);
            onClose();
          }}
        >
          <ListItemText>Open</ListItemText>
          <ListItemIcon>
            <RedoIcon />
          </ListItemIcon>
        </ListItemButton>
        <ListItemButton
          dense
          onClick={() => {
            this.showModal(row.id);
            onClose();
          }}
        >
          <ListItemText>Artifacts</ListItemText>
        </ListItemButton>
        {blacklistCollectionId && row.id && (
          <React.Fragment>
            <Divider />
            <ListItemButton
              dense
              onClick={() => {
                onClose();
                runIncludedExcludedMutation({
                  collectionId: blacklistCollectionId,
                  robotLogIds: [row.id],
                  mutation: 'addExcludedRobotLogsToCollection',
                });
              }}
            >
              <ListItemText>Exclude from Collection</ListItemText>
            </ListItemButton>
          </React.Fragment>
        )}
      </List>
    );
  };

  render() {
    // Specific configuration to this TYPE of table (model)
    const specificProps = {
      getColumnDefinition,
      getAllColumnDefinitions,
      getGraphqlProps: this.getGraphqlProps,
      processQueryResponse,
      initializeProps: this.initializeProps,
      onRowClick: this.onRowClick,
      onChangeColumns: this.props.onChangeColumns || this.onChangeColumns,
      onChangePinnedColumns:
        this.props.onChangePinnedColumns || this.onChangePinnedColumns,
      onChangeWidths: this.props.onChangeWidths || this.onChangeWidths,
      onChangeSort: this.props.onChangeSort || this.onChangeSort,
      onChangeFiltersVariables: this.onChangeFiltersVariables,
      buildContextMenu: this.buildContextMenu,
      controlBar: this.state.controlBarProps,
      additionalContents: this.additionalContents,
      artifactModal: this.state.showModal,
      hideModal: this.hideModal,
      rowId: this.state.rowId,
    };
    return <GenericTableManager {...this.props} {...specificProps} />;
  }
}

export default container(RobotLogsTablev2);
