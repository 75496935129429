export class TypedEventEmitter<T> {
  private readonly events: Partial<Record<keyof T, any[]>> = {};

  public on<K extends keyof T>(
    event: K,
    listener: (args: T[K]) => void
  ): () => void {
    if (typeof this.events[event] !== 'object') {
      this.events[event] = [];
    }

    this.events[event].push(listener);
    return () => this.off(event, listener);
  }

  public off<K extends keyof T>(
    event: K,
    listener: (args: T[K]) => void
  ): void {
    if (typeof this.events[event] !== 'object') return;

    const idx: number = this.events[event].indexOf(listener);
    if (idx > -1) this.events[event].splice(idx, 1);
  }

  public removeAllListeners(): void {
    Object.keys(this.events).forEach((event: string) =>
      this.events[event].splice(0, this.events[event].length)
    );
  }

  public emit<K extends keyof T>(event: K, payload: T[K]): void {
    if (typeof this.events[event] !== 'object') return;

    this.events[event].forEach((listener) => listener(payload));
  }

  public once<K extends keyof T>(
    event: K,
    listener: (args: T[K]) => void
  ): void {
    const cancel = this.on(event, (payload) => {
      cancel();
      listener(payload);
    });
  }
}
