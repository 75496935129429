import { Button, Collapse, CollapsePanelProps } from 'antd';
import { Artifact } from '@hmd/sdk/api/artifacts/v1';
import VideoExpansion from './artifactPreviews/VideoExpansion';
import ImageExpansion from './artifactPreviews/ImageExpansion';
import TextExpansion from './artifactPreviews/TextExpansion';
import JSONExpansion from './artifactPreviews/JSONExpansion';
import PlotlyPlotJSONExpansion from './artifactPreviews/PlotlyPlotJSONExpansion';
import TraceEventsJSON from './artifactPreviews/TraceEventsJSON';
import EmptyExpansion from './artifactPreviews/EmptyExpansion';
import ArtifactMeta from './ArtifactMeta';
import { structValueToBasicStructure } from '../../services/passThruSearch';
import { MetadataView } from '../../components/metadata/MetadataView';
import { useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { ArtifactExtraInfo } from './ArtifactExtraInfo';

const useStyles = makeStyles((theme) => ({
  moreInfoBtn: {
    marginBottom: theme.spacing(2),
  },
}));

const ARTIFACT_TYPE_DISPLAYS = {
  'plotly-json': PlotlyPlotJSONExpansion,
  'trace-events-json': TraceEventsJSON,
  // not all roslogs end in ".log" so this picks up things like ".log.1"
  roslog: TextExpansion,
};

const FILE_TYPE_DISPLAYS = {
  VIDEO: VideoExpansion,
  IMAGE: ImageExpansion,
  TEXT: TextExpansion,
  JSON: JSONExpansion,
};

const getFileType = (mimeType: string) => {
  // What type of artifact?
  let fileType: string;
  switch (mimeType) {
    // png, jpg, gif
    case 'image/png':
      fileType = 'IMAGE';
      break;
    case 'image/jpeg':
      fileType = 'IMAGE';
      break;
    case 'image/gif':
      fileType = 'IMAGE';
      break;
    // .mp4, .webm
    case 'video/mp4':
      fileType = 'VIDEO';
      break;
    case 'video/webm':
      fileType = 'VIDEO';
      break;
    // .log, .txt, .csv
    case 'text/csv':
      fileType = 'TEXT';
      break;
    case 'text/plain':
      fileType = 'TEXT';
      break;
    // .json
    case 'application/json':
      fileType = 'JSON';
      break;
    default:
      fileType = 'GENERIC';
  }

  // If generic, attempt to coerce unexpected type
  if (fileType === 'GENERIC' && mimeType) {
    const [type] = mimeType.split('/');
    switch (type) {
      case 'video':
        fileType = 'VIDEO';
        break;
      case 'audio':
        fileType = 'VIDEO';
        break;
      case 'image':
        fileType = 'IMAGE';
        break;
      case 'text':
        fileType = 'TEXT';
        break;
      default:
        fileType = 'GENERIC';
    }
  }

  return fileType;
};

const { Panel } = Collapse;

const Preview = ({ open, artifact, sessionLogId }) => {
  if (!open) return <EmptyExpansion />;

  const ContentPreview =
    ARTIFACT_TYPE_DISPLAYS[artifact.artifactTypeSlug] ||
    FILE_TYPE_DISPLAYS[getFileType(artifact.mimeType)];

  if (ContentPreview) {
    return <ContentPreview artifact={artifact} robotLogId={sessionLogId} />;
  }

  return null;
};

interface ArtifactViewType extends CollapsePanelProps {
  artifact: Artifact.AsObject;
  sessionLogId?: string;
  open?: boolean;
}

export const ArtifactView = ({
  artifact,
  sessionLogId,
  open,
  ...props
}: ArtifactViewType) => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Panel {...props}>
      <ArtifactMeta artifact={artifact} />
      {isOpen ? (
        <ArtifactExtraInfo artifact={artifact} sessionLogId={sessionLogId} />
      ) : (
        <Button
          block
          type="ghost"
          className={classes.moreInfoBtn}
          onClick={() => setIsOpen(true)}
        >
          More Info
        </Button>
      )}
      <Preview open={open} artifact={artifact} sessionLogId={sessionLogId} />
    </Panel>
  );
};
