import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

type Props = {
  ids: string[];
  renderItem: (id) => React.ReactNode;
};

const useStyles = makeStyles((theme) => ({
  controlsWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    minHeight: theme.spacing(6),
  },
}));

export default function StaticBarContainer(props: Props) {
  const { ids, renderItem } = props;
  const classes = useStyles();

  return <div className={classes.controlsWrapper}>{ids.map(renderItem)}</div>;
}
