import { connect } from 'react-redux';
import { getTagOptions } from '../../../redux/lists/selectors';

function mapStateToProps(state) {
  return {
    tagOptions: getTagOptions(state),
  };
}

export default connect(mapStateToProps);
