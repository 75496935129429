import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import Datepicker from '../DatePicker/DatePicker';

const defaultDatePickerOptions = {
  enableTime: true,
};

// gte, lte
// infinite updates without pure here... danger!
class DateRange extends React.PureComponent {
  static defaultProps = {
    onError: _.noop,
    onChange: _.noop,
  };

  alterValueProperty(valueKey, dateObject) {
    const { value, onChange } = this.props;

    const newValue = _.assignIn({}, value);

    if (!dateObject) {
      delete newValue[valueKey];

      // If we deleted the last key, the value is now Undefined as following the logic of our filters
      if (Object.keys(newValue).length === 0) {
        onChange(undefined);
        return;
      }
    } else {
      newValue[valueKey] = dateObject.toISOString();

      // wasn't actually a change...
      // don't fire because object mutations causes issues in applications
      // e.g. the value didn't change but the object did
      if (newValue[valueKey] === _.get(value, valueKey)) {
        return;
      }
    }

    // submit a new value with a property added or removed
    onChange(newValue);
  }

  // Beginning of time range
  handleGTEChange = (date) => {
    const { onError } = this.props;
    const lte = _.get(this.props, 'value.lte');

    if (lte && moment.utc(date) > moment.utc(lte)) {
      onError(
        new Error(
          `Invalid date time range: beginning of time range needs to be before the end of the range`
        )
      );
      return;
    }

    this.alterValueProperty('gte', date);
  };

  // end of time range
  handleLTEChange = (date) => {
    const { onError } = this.props;
    const gte = _.get(this.props, 'value.gte');

    if (gte && moment.utc(date) < moment.utc(gte)) {
      onError(
        new Error(
          `Invalid date time range: end of time range needs to be after the beginning`
        )
      );
      return;
    }

    this.alterValueProperty('lte', date);
  };

  handleGTEClear = () => {
    this.alterValueProperty('gte', undefined);
  };

  handleLTEClear = () => {
    this.alterValueProperty('lte', undefined);
  };

  render() {
    const { value, disabled } = this.props;

    return (
      <React.Fragment>
        <Datepicker
          placeholder="From date"
          options={defaultDatePickerOptions}
          onValueUpdate={this.handleGTEChange}
          onClear={this.handleGTEClear}
          value={_.get(value, 'gte')}
          disabled={disabled}
        />
        <Datepicker
          placeholder="To date"
          options={defaultDatePickerOptions}
          onValueUpdate={this.handleLTEChange}
          onClear={this.handleLTEClear}
          value={_.get(value, 'lte')}
          disabled={disabled}
        />
      </React.Fragment>
    );
  }
}

export default DateRange;
