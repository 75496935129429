import React, { useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import PageContent from '../../components/layout/PageContent';
import PageHeader from '../../components/layout/PageHeader';
import RobotLogsTable2 from '../../components/tables/RobotLogsTable';
import { getHasAnyFilterSet } from '../../redux/tables/utils';
import CreateCollectionCTA from '../../components/collections/CreateCollectionCTA';
import history from '../history';
import { collectionCreateSlice } from '../../redux/collectionCreate.slice';
import _ from 'lodash';

export interface Props {
  cacheKey: string;
  display: string;
  sessionLogTypeSlug: string;
  defaultColumns?: { columnKey: string }[];
  accentColor?: string;
}

function InnerRobotLogsPage(props: Props) {
  const { display, sessionLogTypeSlug, accentColor, defaultColumns, cacheKey } =
    props;

  const dispatch = useAppDispatch();
  const filtersVariables = useAppSelector((state) => {
    return _.get(state, `tables.cache.${cacheKey}.filtersVariables`);
  });

  const hasAnyFilterSet = useMemo(() => {
    return getHasAnyFilterSet(filtersVariables);
  }, [filtersVariables]);

  const onCreate = useCallback(() => {
    const { createCollectionFromTablePage } = collectionCreateSlice.actions;
    
    dispatch(
      createCollectionFromTablePage({
        ruleSetValues: filtersVariables,
        sessionLogTypeSlugs: [sessionLogTypeSlug],
      })
    );
    
    history.push('/app/collections/create');
  }, [sessionLogTypeSlug, filtersVariables]);

  const tableContextProps = useMemo(() => {
    return { sessionLogTypeSlugs: [sessionLogTypeSlug] };
  }, [sessionLogTypeSlug]);

  const showHeatGrid = ['test', 'trial'].includes(sessionLogTypeSlug);

  return (
    <PageContent pageTitle={display}>
      <PageHeader title={`${display}s`} accentColor={accentColor}>
        <CreateCollectionCTA onClick={onCreate}>
          {hasAnyFilterSet
            ? 'Create Collection from Filters'
            : 'Create Collection'}
        </CreateCollectionCTA>
      </PageHeader>

      <RobotLogsTable2
        key={cacheKey}
        title={`${display} Page`}
        tableCacheKey={cacheKey}
        localStorageKey={cacheKey}
        defaultColumns={defaultColumns}
        tableContextProps={tableContextProps}
        showHeatGrid={showHeatGrid}
      />
    </PageContent>
  );
}

export default InnerRobotLogsPage;
