import _ from 'lodash';

const config = {
  columnKey: 'id',
  label: 'ID',
  description: 'Flight ID (robotLogId)',
  getValue: function ({ row }) {
    return _.get(row, 'id', '');
  },
  fragment: 'id',
  defaultWidth: 320,
};

export default config;
