import React, { Component } from 'react';
import _ from 'lodash';
import { Redirect, withRouter, RouteChildrenProps } from 'react-router-dom';
import { gql } from '@apollo/client';
import client from '../apollo-client';
import tracker from '../lib/tracker';
import container, { ContainerProps } from '../containers/authUser';
import config from '../lib/config';

const authQuery = gql`
  query {
    currentUser {
      id
      name
      email
      picture
      providerName
      teams {
        id
      }
      favoriteCollections {
        edges {
          node {
            id
          }
        }
      }
    }
  }
`;

class PrivateRouteGate extends Component<RouteChildrenProps & ContainerProps> {
  state = {
    initialIsValid: false,
    shouldRedirect: false,
  };

  authInterval = undefined;

  constructor(props) {
    super(props);

    // If a SystemUser is configured for a network denied environment, do not redirect to /app/login
    if (config.systemUser) {
      // Auth test validates the graphql connection and sets the currentUser
      this.authTest();
      this.state = {
        initialIsValid: true,
        shouldRedirect: false,
      };
    }

    if (!config.useAuth) {
      this.state = {
        initialIsValid: true,
        shouldRedirect: false,
      };
    }
    const { initialIsValid, shouldRedirect } = this.state;

    if (!initialIsValid && !shouldRedirect) {
      this.authTest();
      this.authInterval = setInterval(() => {
        this.authTest();
      }, 1000 * 60 * 10);
    }
  }

  componentWillUnmount() {
    clearInterval(this.authInterval);
  }

  handleAuthError() {
    const { showErrorSnack } = this.props;
    const { initialIsValid } = this.state;

    console.warn(
      `could not authenticate user token against the graphql endpoint`,
      initialIsValid
    );

    if (initialIsValid) {
      showErrorSnack(
        new Error(
          `it appears your session has ended, refresh the page to check this or login again`
        )
      );
    } else {
      this.setState({
        shouldRedirect: true,
      });
    }
  }

  authTest() {
    const { initialIsValid } = this.state;
    const { setCurrentUser } = this.props;

    client
      .query({
        query: authQuery,
        fetchPolicy: 'no-cache', // for some reason network-only make the favoriteCollections part not come back...
      })
      .then((response) => {
        const currentUser = _.get(response, 'data.currentUser');
        const userId = _.get(currentUser, 'id');
        console.info(`current user id ${userId}`);

        if (!userId) {
          console.warn(
            `could not authenticate user token against the graphql endpoint`,
            initialIsValid
          );
          this.handleAuthError();
          return;
        }

        setCurrentUser({ currentUser });

        tracker.setUserId(currentUser.email);

        this.setState({
          initialIsValid: true,
          lastValidAuthTest: new Date().toDateString(),
        });
      })
      .catch((e) => {
        this.handleAuthError();
      });
  }

  render() {
    const { children, location } = this.props;
    const { initialIsValid, shouldRedirect } = this.state;

    if (shouldRedirect) {
      // state gets stored in history so when we log in and we can use it to redirect
      return (
        <Redirect
          to={{
            pathname: '/app/login',
            search: '',
            state: { referrer: location.pathname },
          }}
        />
      );
    } else if (initialIsValid) {
      return children;
    }

    return null;
  }
}

export default container(withRouter(PrivateRouteGate));
