import React from 'react';
import { SelectProps } from 'antd';
import TagsPreview from "../TagsPreview";
import _ from 'lodash';

interface Props {
  value: SelectProps['value'];
  options: SelectProps['options'];
  style?: object;
}

const SimpleMultiSelectPreview: React.FC<Props> = ({value, options= [], style}) => {
  const optionMap = _.keyBy(options, 'value');

  const display = [];

  if (_.isArray(value)) {
    value.forEach(v => {
      const opt = optionMap[v];
      if (opt) {
        display.push(opt.label);
      }
    })
  } else if (optionMap[value]) {
    display.push(optionMap[value].label);
  }

  return (
    <TagsPreview style={style} tags={display} />
  );
}

export default SimpleMultiSelectPreview;
