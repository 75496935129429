import React from 'react';
import { Select, SelectOptionBase } from '@shield-ui/controls';
import makeStyles from '@mui/styles/makeStyles';
import { useEnsureListsAreLoaded } from '../../../redux/lists/util';
import container from './container';
import Typography from '@mui/material/Typography';
import { doesInputMatchBase } from '@shield-ui/utils';

const useStyles = makeStyles({
  label: { fontSize: 13 },
  desc: { whiteSpace: 'normal', fontSize: 11, marginTop: 3 },
});

export function filterOption(item, searchTerm) {
  if (!searchTerm || !item || !item.data) {
    return true;
  }

  return (
    doesInputMatchBase(searchTerm, item.data.label) ||
    doesInputMatchBase(searchTerm, item.data.description)
  );
}

export function Option(props) {
  const classes = useStyles();
  const { data } = props;
  const { label, description } = data;
  return (
    <SelectOptionBase {...props}>
      <div>
        <Typography variant="body1" className={classes.label}>
          {label}
        </Typography>
        <Typography variant="body2" className={classes.desc}>
          {description}
        </Typography>
      </div>
    </SelectOptionBase>
  );
}

function TagSelect(props) {
  const { withDescriptiveOptions, ...rest } = props;

  useEnsureListsAreLoaded();

  const extra = !props.tagOptions.length
    ? {
        placeholder: 'Loading...',
        disabled: true,
      }
    : undefined;

  const components = withDescriptiveOptions ? { Option } : undefined;

  return (
    <Select
      suggestions={props.tagOptions}
      filterOption={filterOption}
      components={components}
      {...rest}
      {...extra}
    />
  );
}

export default container(TagSelect);
