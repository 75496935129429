import React, { useCallback } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { colors } from '@shield-ui/styles';
import QueryFilterControl from '../QueryFilterControl';
import ControlBarLayout from './ControlBarLayout';
import { ContextMenuState, QueryFilterItem } from './types';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    borderLeft: '2px solid transparent',
    borderRight: '2px solid transparent',
    position: 'relative',
    '&:hover': {
      '& $draggable': {
        opacity: 0.5,
      },
    },
  },
  overLeft: {
    borderLeft: `2px solid ${colors.brand.primaryShade['80']}`,
  },
  overRight: {
    borderRight: `2px solid ${colors.brand.primaryShade['80']}`,
  },
  overlay: {
    position: 'absolute',
    borderRadius: 2,
    width: '100%',
    height: '100%',
    zIndex: 2,
    backgroundColor: colors.hues.grays[140],
    opacity: 0.8,
  },
  draggable: {
    opacity: 0,
    position: 'absolute',
    zIndex: 3,
    left: -2,
    top: 11,
    fontSize: 18,
    transition: '0.1s ease',
    // cursor: 'move', - can't combine with pointerEvents
    pointerEvents: 'none',
  },
}));

interface Props {
  id: string;
  index: number;
  activeIndex?: number;
  queryFilterItem: QueryFilterItem;
  values: object;
  setValues: (args: object) => void;
  setContextMenu: (s: ContextMenuState) => void;
}

export default function SortableQueryFilterControl(props: Props) {
  const {
    id,
    index,
    activeIndex,
    queryFilterItem,
    values,
    setValues,
    setContextMenu,
  } = props;
  const { queryFilter, ControlBarLayoutComponent } = queryFilterItem;
  const classes = useStyles();

  // hook from dnd-kit that turns this into a sortable element
  // exposes many more property for customization including transitions, transforms, etc...
  const { attributes, listeners, setNodeRef, over, isDragging } = useSortable({
    id,
  });

  const onContextMenu = useCallback(
    (evt) => {
      evt.stopPropagation();
      evt.preventDefault();
      setContextMenu({
        anchorEl: evt.target,
        queryFilterId: queryFilter.getId(),
      });
    },
    [queryFilterItem]
  );

  const isOver = over && over.id === id;

  return (
    <div
      ref={setNodeRef}
      className={cx(
        classes.wrapper,
        isOver && index < activeIndex && classes.overLeft,
        isOver && index > activeIndex && classes.overRight
      )}
      data-queryfilterid={queryFilter.getId()}
      {...attributes}
      {...listeners}
    >
      {isDragging && <div className={classes.overlay} />}
      {!ControlBarLayoutComponent && (
        <DragIndicatorIcon className={classes.draggable} />
      )}
      <QueryFilterControl
        queryFilter={queryFilter}
        values={values}
        setValues={setValues}
        LayoutComponent={ControlBarLayoutComponent || ControlBarLayout}
        layoutProps={{
          onContextMenu,
        }}
      />
    </div>
  );
}
