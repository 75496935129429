import _ from 'lodash';
import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  createSelector,
} from '@reduxjs/toolkit';
import { getStringFromErrorLike, prettifyString } from '@shield-ui/utils';
import {
  HangarEventType,
  HangarMutationsCreateEventArgs,
  HangarMutationsDeleteEventArgs,
  HangarMutationsUpdateEventArgs,
  HangarRobotLogType,
} from '@shield-ui/hangar-service';
import * as eventsService from '../services/events';
import * as logEventsService from '../services/logEvents';
import { FlatLogEventObjectMerged } from '../services/logEvents';
import {
  LogEventFilters,
  ErrorLevel,
  FlatLogEvent,
} from '@hmd/sdk/api/analysis/log_event/v1';
import { RootState } from './store';

export const EVENT_MANAGER_FEATURE_KEY = 'eventManager';

interface EventManagerContext {
  fetching: boolean;
  fetched: boolean;
  robotLog: HangarRobotLogType;
  events: HangarEventType[];
  //eventsChildren are child robotLogs that also hold event info while events and robotLog are split in state for parent robotLog/ single flights
  eventsChildren: HangarRobotLogType[];
  //logEvents and childLogEvents are associated on a single robotLog
  logEvents: FlatLogEvent.AsObject[];
  childLogEvents: FlatLogEvent.AsObject[];
  errorMessage?: string;
}

const defaultEventManagerContext: EventManagerContext = {
  fetching: false,
  fetched: false,
  robotLog: undefined,
  events: [],
  eventsChildren: [],
  logEvents: [],
  childLogEvents: [],
};

interface EventManagerState {
  filters: {
    levels: LogEventFilters.AsObject['levelsList'];
  };
  forRobotLog: Record<HangarRobotLogType['id'], EventManagerContext>;
}

const initialEventManagerState: EventManagerState = {
  filters: {
    levels: [ErrorLevel.FATAL, ErrorLevel.ERROR],
  },
  forRobotLog: {},
};

/**
 * Create
 */
type CreateEventArgs = HangarMutationsCreateEventArgs;
type CreateEventPayload = {
  event: HangarEventType;
};
type CreateEventPayloadAction = PayloadAction<
  CreateEventPayload,
  string,
  { arg: CreateEventArgs }
>;
export const createEvent = createAsyncThunk(
  `${EVENT_MANAGER_FEATURE_KEY}/createEvent`,
  async (args: CreateEventArgs) => {
    return eventsService.createEvent(args);
  }
);
/**
 * Update
 */
type UpdateEventArgs = HangarMutationsUpdateEventArgs;
type UpdateEventPayload = {
  event: HangarEventType;
};
type UpdateEventPayloadAction = PayloadAction<
  UpdateEventPayload,
  string,
  { arg: UpdateEventArgs }
>;
export const updateEvent = createAsyncThunk(
  `${EVENT_MANAGER_FEATURE_KEY}/updateEvent`,
  async (args: UpdateEventArgs) => {
    return eventsService.updateEvent(args);
  }
);

/**
 * Delete
 */
type DeleteEventArgs = HangarMutationsDeleteEventArgs;
type DeleteEventPayload = {
  event: HangarEventType;
};
type DeleteEventPayloadAction = PayloadAction<
  DeleteEventPayload,
  string,
  { arg: DeleteEventArgs }
>;
export const deleteEvent = createAsyncThunk(
  `${EVENT_MANAGER_FEATURE_KEY}/deleteEvent`,
  async (args: DeleteEventArgs) => {
    return eventsService.deleteEvent(args);
  }
);

/**
 * Refresh
 * This "Searches" and loads everything needed to drive the EventManager
 */
export interface RefreshResultsForRobotLogArgs {
  robotLogId: HangarRobotLogType['id'];
  skipClearOldResults?: boolean;
}
type RefreshResultsForRobotLogPayload = Pick<
  EventManagerContext,
  'events' | 'eventsChildren' | 'robotLog' | 'logEvents' | 'childLogEvents'
>;
type RefreshResultsForRobotLogPayloadAction = PayloadAction<
  RefreshResultsForRobotLogPayload,
  string,
  { arg: RefreshResultsForRobotLogArgs }
>;
export const refreshResultsForRobotLog = createAsyncThunk(
  `${EVENT_MANAGER_FEATURE_KEY}/refreshResultsForRobotLog`,
  async (args: RefreshResultsForRobotLogArgs) => {
    const { robotLogId } = args;
    const eventResult = await eventsService.searchEvents({ robotLogId });
    let logEvents = [];
    let childLogEvents = [];
    try {
      // const bagName = await getNameForRobotLogId(robotLogId);
      // const childrenNames = await getChildrenNamesForRobotLogId(robotLogId);
      const bagName = eventResult.robotLog.name;
      const childrenNames = eventResult.eventsChildren.map((c) => c.name);
      const logEventResult = await logEventsService.getAllLogEventsForRobotLog([
        ...childrenNames,
        bagName,
      ]);
      logEvents = logEventResult.logEvents;
      childLogEvents = logEventResult.children;
    } catch (e) {
      console.error('problem getting log events from analysis service', e);
    }
    return {
      ...eventResult,
      logEvents,
      childLogEvents,
    };
  }
);

export const eventManagerSlice = createSlice({
  name: EVENT_MANAGER_FEATURE_KEY,
  initialState: initialEventManagerState,
  reducers: {
    // delete this from state, say when you are unmounting a component, changing pages, etc...
    clearResultsForRobotLog: (
      state,
      action: PayloadAction<{ robotLogId: HangarRobotLogType['id'] }>
    ) => {
      const { robotLogId } = action.payload;
      delete state.forRobotLog[robotLogId];
    },
    setFilters: (
      state,
      action: PayloadAction<{
        filters: Partial<EventManagerState['filters']>;
      }>
    ) => {
      const { filters } = action.payload;
      state.filters = {
        ...state.filters,
        ...filters,
      };
    },
  },
  extraReducers: {
    [refreshResultsForRobotLog.pending.type]: (
      state,
      action: PayloadAction<
        object,
        string,
        { arg: RefreshResultsForRobotLogArgs }
      >
    ) => {
      const { robotLogId } = action.meta.arg;

      state.forRobotLog[robotLogId] = {
        ...defaultEventManagerContext,
        fetching: true,
      };
    },
    [refreshResultsForRobotLog.rejected.type]: (
      state,
      action: PayloadAction<
        object,
        string,
        { arg: RefreshResultsForRobotLogArgs },
        Error
      >
    ) => {
      const { error } = action;
      const { robotLogId } = action.meta.arg;

      state.forRobotLog[robotLogId].fetching = false;
      state.forRobotLog[robotLogId].errorMessage =
        getStringFromErrorLike(error);
    },
    [refreshResultsForRobotLog.fulfilled.type]: (
      state,
      action: RefreshResultsForRobotLogPayloadAction
    ) => {
      const { robotLogId, skipClearOldResults } = action.meta.arg;
      const { events, eventsChildren, logEvents, robotLog, childLogEvents } =
        action.payload;

      // when we get the results, clear out old results unless a flag was passed in
      // this is the default behavior since most UIs would be built around displaying an
      // event manager for a single robot log
      if (!skipClearOldResults) {
        Object.keys(state.forRobotLog).forEach((id) => {
          if (id === robotLogId) {
            return;
          }
          state.forRobotLog[id] = undefined;
        });
      }

      state.forRobotLog[robotLogId] = {
        fetching: false,
        fetched: true,
        robotLog,
        events,
        eventsChildren,
        logEvents,
        childLogEvents,
      };
    },
    [createEvent.fulfilled.type]: (state, action: CreateEventPayloadAction) => {
      const { event } = action.payload;
      const { robotLogId } = event;
      if (state.forRobotLog[robotLogId]) {
        // add to front of array (top of list)
        state.forRobotLog[robotLogId].events.unshift(event);
      } else {
        //robotLogId is a child flight
        for (const parentId in state.forRobotLog) {
          state.forRobotLog[parentId].eventsChildren.forEach((child, idx) => {
            if (child.id === robotLogId) {
              //add event to front of list
              state.forRobotLog[parentId].eventsChildren[
                idx
              ].events.edges.unshift({ node: event, cursor: '' });
            }
          });
        }
      }
    },
    [updateEvent.fulfilled.type]: (state, action: UpdateEventPayloadAction) => {
      const { event } = action.payload;
      const { id, robotLogId } = event;

      if (state.forRobotLog[robotLogId]) {
        // replace
        state.forRobotLog[robotLogId].events = state.forRobotLog[
          robotLogId
        ].events.map((e) => (e.id === id ? event : e));
      } else {
        // robotLogId is a child flight
        for (const parentId in state.forRobotLog) {
          state.forRobotLog[parentId].eventsChildren.forEach((child, idx) => {
            if (child.id === robotLogId) {
              state.forRobotLog[parentId].eventsChildren[idx].events.edges =
                state.forRobotLog[parentId].eventsChildren[
                  idx
                ].events.edges.map((e) => {
                  return e.node.id === id ? { node: event, cursor: '' } : e;
                });
            }
          });
        }
      }
    },
    [deleteEvent.fulfilled.type]: (state, action: DeleteEventPayloadAction) => {
      const { id } = action.meta.arg;
      Object.keys(state.forRobotLog).forEach((robotLogId) => {
        state.forRobotLog[robotLogId].events = state.forRobotLog[
          robotLogId
        ].events.filter((e) => {
          return e.id !== id;
        });
      });
      //for child robotLog events
      Object.keys(state.forRobotLog).forEach((robotLogId) => {
        state.forRobotLog[robotLogId].eventsChildren.forEach((child, idx) => {
          state.forRobotLog[robotLogId].eventsChildren[idx].events.edges =
            child.events.edges.filter((e) => {
              return e.node.id !== id;
            });
        });
      });
    },
  },
});

export const { clearResultsForRobotLog, setFilters } =
  eventManagerSlice.actions;

// selectors
export const selectFilters = (state: RootState) =>
  state[EVENT_MANAGER_FEATURE_KEY].filters;
const selectForRobotLogContexts = (state: RootState) =>
  state[EVENT_MANAGER_FEATURE_KEY].forRobotLog;

export interface LogEventDisplayRecord {
  groupLabel: string;
  hasTreeDetails: boolean;
  leafLogEvent: FlatLogEventObjectMerged;
  rootLogEvent: FlatLogEventObjectMerged;
}

const calculateDisplayRecordsMap = createSelector(
  selectForRobotLogContexts,
  (forRobotLogs): Record<HangarRobotLogType['id'], LogEventDisplayRecord[]> => {
    return Object.keys(forRobotLogs).reduce((acc, robotLogId) => {
      const context = forRobotLogs[robotLogId];
      // failsafe
      if (!context) {
        acc[robotLogId] = [];
        return acc;
      }

      const { logEvents, childLogEvents } = context;
      let displayRecords: LogEventDisplayRecord[] = [];

      const mergedLogEvents = logEventsService.buildMergedLogEvents(
        logEvents,
        childLogEvents
      );
      mergedLogEvents.forEach((rootLogEvent) => {
        const leafLogEvents =
          logEventsService.getLeafLogEventsFromMergedRecord(rootLogEvent);
        leafLogEvents.forEach((leafLogEvent) => {
          displayRecords.push({
            groupLabel: `${prettifyString(leafLogEvent.componentName)} (${
              leafLogEvent.componentOwner
            })`,
            hasTreeDetails: leafLogEvent !== rootLogEvent,
            leafLogEvent,
            rootLogEvent,
          });
        });
      });

      // severity, then time in groups
      displayRecords = _.sortBy(
        displayRecords,
        (displayRecord) => {
          return (
            logEventsService.logEventErrorLevelOrder.indexOf(
              displayRecord.leafLogEvent.level
            ) || 99
          );
        },
        (displayRecord) => {
          return `${displayRecord.leafLogEvent.timestampTime.seconds}.${displayRecord.leafLogEvent.timestampTime.nanos}`;
        }
      );

      acc[robotLogId] = displayRecords;

      return acc;
    }, {});
  }
);

export const calculateFilteredDisplayRecordsForRobotLog = createSelector(
  calculateDisplayRecordsMap,
  selectFilters,
  (
    displayRecordsMap,
    filters
  ): Record<HangarRobotLogType['id'], LogEventDisplayRecord[]> => {
    return Object.keys(displayRecordsMap).reduce((acc, robotLogId) => {
      acc[robotLogId] = displayRecordsMap[robotLogId].filter(
        (displayRecord) => {
          return (
            !filters.levels.length ||
            filters.levels.includes(displayRecord.leafLogEvent.level)
          );
        }
      );
      return acc;
    }, {});
  }
);

export function getDisplayRecordsForLogEvents(
  state: RootState,
  robotLogId: HangarRobotLogType['id']
): LogEventDisplayRecord[] {
  return calculateFilteredDisplayRecordsForRobotLog(state)[robotLogId] || [];
}

export function getContextForRobotLogId(
  state,
  robotLogId
): EventManagerContext {
  return state[EVENT_MANAGER_FEATURE_KEY].forRobotLog[robotLogId];
}

export function getTotalEventCountForRobotLogId(
  state,
  robotLogId: HangarRobotLogType['id']
): number {
  return (
    _.get(getContextForRobotLogId(state, robotLogId), 'events', []).length +
    _.get(getContextForRobotLogId(state, robotLogId), 'logEvents', []).length
  );
}
