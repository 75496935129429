import React from 'react';
import ErrorBoundary from 'react-error-boundary';
import Typography from '@mui/material/Typography';
import { spacing } from '@shield-ui/styles';
import ErrorMessageContent from '../ErrorMessageContent/ErrorMessageContent';

const PageFallbackComponent = ({ componentStack, error }) => {
  return (
    <div style={{ padding: spacing.unit * 4 }}>
      <Typography variant="h5">An unexpected error has occurred.</Typography>
      <Typography variant="body1" gutterBottom>
        Please contact us if this error persists.
      </Typography>
      <br />
      <ErrorMessageContent>{error.message}</ErrorMessageContent>
      <br />
      <Typography variant="body2">{componentStack}</Typography>
    </div>
  );
};

export default function ErrorBoundaryPageContent(props) {
  const { children } = props;
  return (
    <ErrorBoundary
      FallbackComponent={PageFallbackComponent}
      onError={(err, stack) => {
        if (stack) {
          console.error(stack);
        }
        console.error(err);
      }}
    >
      {children}
    </ErrorBoundary>
  );
}
