import _ from 'lodash';

const config = {
  columnKey: 'phoneSoftwareVersion',
  defaultWidth: 320,
  label: 'Phone Software',
  sortKey: 'phone_software_info.version',
  description: 'Phone Software App Version',
  getValue: function ({ row }) {
    return _.get(row, 'phoneSoftware.version', '');
  },
  fragment: `
        phoneSoftware {
            version
        }
    `,
};
export default config;
